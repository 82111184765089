const compoundFeedV1toV2IngredientMappings = [
  {
    oldName: 'Potato protein',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Potato protein',
  },
  {
    oldName: 'Crude rapeseed oil (pressing)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude rapeseed oil (pressing)',
  },
  {
    oldName: 'Phosphate rock (32% P2O5, 50% CaO)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Salt',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sodium Chloride (Salt)',
  },
  {
    oldName: 'Limestone',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Limestone',
  },
  {
    oldName: 'Liquid whey (Gouda 48+)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Liquid whey',
  },
  {
    oldName: 'Lucerne, dried',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Dried forages (alfalfa)',
  },
  {
    oldName: 'Lupins protein-concentrate',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins protein-concentrate',
  },
  {
    oldName: 'Lupins hull (protein-concentrate)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Lupins hull',
  },
  {
    oldName: 'Lupins fibre',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins fibre',
  },
  {
    oldName: 'Lupins meal',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins meal',
  },
  {
    oldName: 'Lupins hull (meal)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins hull',
  },
  {
    oldName: 'Lupins okara',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins okara',
  },
  {
    oldName: 'Lupins oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins oil',
  },
  {
    oldName: 'Lupins protein-isolate',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins protein-isolate',
  },
  {
    oldName: 'Lupins',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins, dried',
  },
  {
    oldName: 'Lupins straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins straw',
  },
  {
    oldName: 'Maize bran',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn bran',
  },
  {
    oldName: 'Maize distillers grains dried, at plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn DDG (distillers grains dried)',
  },
  {
    oldName: 'Maize fibre/bran',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn fibre/bran',
  },
  {
    oldName: 'Maize germ dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn germ dried',
  },
  {
    oldName: 'Crude maize germ oil (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude Maize/Corn germ oil (pressing)',
  },
  {
    oldName: 'Maize germ meal expeller (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Maize/Corn germ meal expeller (pressing)',
  },
  {
    oldName: 'Crude maize germ oil (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude Maize/Corn germ oil (solvent)',
  },
  {
    oldName: 'Maize germ meal extracted (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Maize/Corn germ meal extracted (solvent)',
  },
  {
    oldName: 'Maize gluten meal dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn gluten meal dried',
  },
  {
    oldName: 'Potato starch dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Potato starch dried',
  },
  {
    oldName: 'Beef co-product, feed grade',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Animal (meat and bone) meal, beef',
  },
  {
    oldName: 'Broad bean meal',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Broad bean meal',
  },
  {
    oldName: 'Broad bean hull',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Broad bean hull',
  },
  {
    oldName: 'Broad beans',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Broad beans, dried',
  },
  {
    oldName: 'Broad beans, horse beans, dry straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Broad beans, horse beans, straw',
  },
  {
    oldName: 'Tapioca starch (with use of co-products)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Tapioca starch (with use of co-products)',
  },
  {
    oldName: 'Cassava pomace (fibrous residue) (with use of co-products)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Cassava pomace (fibrous residue) (with use of co-products)',
  },
  {
    oldName: 'Cassava peel (with use of co-products)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Cassava peel (with use of co-products)',
  },
  {
    oldName: 'Cassava root, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Cassava root, dried',
  },
  {
    oldName: 'Cassava',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Cassava',
  },
  {
    oldName: 'Chick peas',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Chick peas',
  },
  {
    oldName: 'Chick peas straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Chick peas straw',
  },
  {
    oldName: 'Chicory roots',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Other',
  },
  {
    oldName: 'Citrus pulp dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Citrus pulp dried',
  },
  {
    oldName: 'Crude coconut oil',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude coconut oil',
  },
  {
    oldName: 'Coconut copra meal',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Coconut copra meal',
  },
  {
    oldName: 'Milk powder (full fat)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Whole milk powder',
  },
  {
    oldName: 'Milk powder (skimmed)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Skimmed milk powder',
  },
  {
    oldName: 'Oat straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Oat straw',
  },
  {
    oldName: 'Oat grain, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Oat grain, dried',
  },
  {
    oldName: 'Oat grain peeled',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Oat grain peeled',
  },
  {
    oldName: 'Oat husk meal',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Oat hull meal',
  },
  {
    oldName: 'Oat mill feed meal high grade',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Oat mill feed meal high grade',
  },
  {
    oldName: 'Crude palm kernel oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude palm kernel oil',
  },
  {
    oldName: 'Palm kernel expeller',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Palm kernel meal expeller',
  },
  {
    oldName: 'Pea protein-isolate',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pea protein-isolate',
  },
  {
    oldName: 'Pea slurry',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pea slurry',
  },
  {
    oldName: 'Pea starch slurry',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pea starch slurry',
  },
  {
    oldName: 'Pea wet animal feed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pea wet animal feed',
  },
  {
    oldName: 'Pea protein-concentrate',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Pea protein-concentrate',
  },
  {
    oldName: 'Pea starch-concentrate',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pea starch-concentrate',
  },
  {
    oldName: 'Barley grain, dried, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Barley grain, dried',
  },
  {
    oldName: 'Cottonseed, production mix',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Cottonseed',
  },
  {
    oldName: 'Wheat grain, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Wheat grain, dried',
  },
  {
    oldName: 'Maize, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Maize/Corn, dried',
  },
  {
    oldName: 'Wheat gluten feed, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Wheat gluten feed',
  },
  {
    oldName: 'Citrus pulp dried, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Citrus pulp dried',
  },
  {
    oldName: 'Soybean hulls, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Soybean hull (solvent)',
  },
  {
    oldName: 'Wheat bran, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Wheat bran, from wet milling',
  },
  {
    oldName: 'OBSOLETE - Milk powder whole, from drying, at plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Whole milk powder',
  },
  {
    oldName: 'Sugar beet pulp, dried, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Sugar beet pulp dried',
  },
  {
    oldName: 'Wheat grain, production mix',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Wheat grain, dried',
  },
  {
    oldName: 'Maize gluten meal, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Maize/Corn gluten meal dried',
  },
  {
    oldName: 'OBSOLETE - Maize, market mix, at regional storage',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Maize/Corn, dried',
  },
  {
    oldName: 'Soybean meal, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Soybean meal (solvent)',
  },
  {
    oldName: 'Sugar beet molasses, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Sugar beet molasses',
  },
  {
    oldName: 'Palm kernel expeller, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Palm kernel meal expeller',
  },
  {
    oldName: 'Rapeseed meal, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Rapeseed meal (solvent)',
  },
  {
    oldName: 'Peas, dry straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Peas straw',
  },
  {
    oldName: 'Pigeon peas',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pigeon peas, dried',
  },
  {
    oldName: 'Pigeon peas straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Pigeon peas straw',
  },
  {
    oldName: 'Potato juice concentrated',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Potato juice concentrated',
  },
  {
    oldName: 'Potato pulp pressed fresh+silage',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Potato pulp pressed fresh+silage',
  },
  {
    oldName: 'Potato pulp dried',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Potato pulp dried',
  },
  {
    oldName: 'Wheat straw, winter',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Wheat straw, winter',
  },
  {
    oldName: 'Potatoes',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Potatoes, cooled',
  },
  {
    oldName: 'Rapeseed expeller (pressing)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Rapeseed meal expeller (pressing)',
  },
  {
    oldName: 'Crude rapeseed oil (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude rapeseed oil (solvent)',
  },
  {
    oldName: 'Rapeseed straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rapeseed straw',
  },
  {
    oldName: 'Rapeseed, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Rapeseed, dried',
  },
  {
    oldName: 'Refined linseed oil (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined linseed oil (solvent)',
  },
  {
    oldName: 'Soap stock (linseed) (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (linseed) (solvent)',
  },
  {
    oldName: 'Rice bran (mixed)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice bran (mixed)',
  },
  {
    oldName: 'Rice bran (parboiled)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice bran (parboiled)',
  },
  {
    oldName: 'Rice husk (parboiled)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice husk (parboiled)',
  },
  {
    oldName: 'Rice brokens (parboiled)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice brokens (parboiled)',
  },
  {
    oldName: 'Rice bran (raw)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice bran (raw)',
  },
  {
    oldName: 'Rice husk (raw)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice husk (raw)',
  },
  {
    oldName: 'Rice brokens (raw)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice brokens (raw)',
  },
  {
    oldName: 'Crude rice bran oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude rice bran oil',
  },
  {
    oldName: 'Rice bran meal',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Rice bran meal',
  },
  {
    oldName: 'Rice brokens (mixed)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice brokens (mixed)',
  },
  {
    oldName: 'Rice feed meal',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice feed meal',
  },
  {
    oldName: 'Rice husk (mixed)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice husk (mixed)',
  },
  {
    oldName: 'Rice husk meal (mixed)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice husk meal (mixed)',
  },
  {
    oldName: 'Rice husk meal (parboiled)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice husk meal (parboiled)',
  },
  {
    oldName: 'Rice husk meal (raw)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice husk meal (raw)',
  },
  {
    oldName: 'Rice straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rice straw',
  },
  {
    oldName: 'Rice',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Rice, dried',
  },
  {
    oldName: 'Rye straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rye straw',
  },
  {
    oldName: 'Rye grain, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Rye grain, dried',
  },
  {
    oldName: 'Rye middlings',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rye middlings',
  },
  {
    oldName: 'Sesame seed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sesame seed, dried',
  },
  {
    oldName: 'Refined coconut oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined coconut oil',
  },
  {
    oldName: 'Soap stock (coconut)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (coconut)',
  },
  {
    oldName: 'Refined maize germ oil (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined Maize/Corn germ oil (pressing)',
  },
  {
    oldName: 'Soap stock (maize germ) (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (Maize/Corn germ) (pressing)',
  },
  {
    oldName: 'Refined maize germ oil (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined Maize/Corn germ oil (solvent)',
  },
  {
    oldName: 'Soap stock (maize germ) (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (Maize/Corn germ) (solvent)',
  },
  {
    oldName: 'Refined palm kernel oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined palm kernel oil',
  },
  {
    oldName: 'Soap stock (palm kernel)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (palm kernel)',
  },
  {
    oldName: 'Refined rapeseed oil (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined rapeseed oil (pressing)',
  },
  {
    oldName: 'Soap stock (rapeseed) (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (rapeseed) (pressing)',
  },
  {
    oldName: 'Refined rapeseed oil (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Refined rapeseed oil (solvent)',
  },
  {
    oldName: 'Soap stock (rapeseed) (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (rapeseed) (solvent)',
  },
  {
    oldName: 'Refined rice bran oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined rice bran oil',
  },
  {
    oldName: 'Soap stock (rice bran)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (rice bran)',
  },
  {
    oldName: 'Refined sunflower oil (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined sunflower oil (pressing)',
  },
  {
    oldName: 'Soap stock (sunflower) (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (sunflower) (pressing)',
  },
  {
    oldName: 'Refined sunflower oil (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Refined sunflower oil (solvent)',
  },
  {
    oldName: 'Soap stock (sunflower) (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (sunflower) (solvent)',
  },
  {
    oldName: 'Cottonseed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Cottonseed',
  },
  {
    oldName: 'Lupins protein slurry',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lupins protein slurry',
  },
  {
    oldName: 'Lupins hull (protein-isolate)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Lupins hull',
  },
  {
    oldName: 'Sorghum straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sorghum straw',
  },
  {
    oldName: 'Crude soybean oil (pressing)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude soybean oil (pressing)',
  },
  {
    oldName: 'Soybean expeller (pressing)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean meal expeller (pressing)',
  },
  {
    oldName: 'Mustard seed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Mustard seed, dried',
  },
  {
    oldName: 'Peas, dry',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Peas, dried',
  },
  {
    oldName: 'Rye flour',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Rye flour',
  },
  {
    oldName: 'Biolys®, 54.6% L-Lysine',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Biolys®, 54.6% L-Lysine',
  },
  {
    oldName: 'Soybean protein-concentrate',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean protein-concentrate',
  },
  {
    oldName: 'Soybean fines',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soybean fines',
  },
  {
    oldName: 'Soybean molasses',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soybean molasses',
  },
  {
    oldName: 'Soybean hull (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean hull (solvent)',
  },
  {
    oldName: 'Refined soybean oil (pressing)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Refined soybean oil (pressing)',
  },
  {
    oldName: 'Soap stock (soybean) (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (soybean) (pressing)',
  },
  {
    oldName: 'Soybean lecithin (pressing)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean lecithin (pressing)',
  },
  {
    oldName: 'Refined soybean oil (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Refined soybean oil (solvent)',
  },
  {
    oldName: 'Soap stock (soybean) (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soap stock (soybean) (solvent)',
  },
  {
    oldName: 'Soybean lecithin (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean lecithin (solvent)',
  },
  {
    oldName: 'Crude soybean oil (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude soybean oil (solvent)',
  },
  {
    oldName: 'Soybean meal (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean meal (solvent)',
  },
  {
    oldName: 'Soybean okara',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soybean okara',
  },
  {
    oldName: 'Soybean protein-isolate',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soybean protein-isolate',
  },
  {
    oldName: 'Soybeans',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean, dried, full fat',
  },
  {
    oldName: 'Soybean straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Soybean straw',
  },
  {
    oldName: 'Maize gluten feed dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn gluten feed dried',
  },
  {
    oldName: 'Rapeseed meal (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Rapeseed meal (solvent)',
  },
  {
    oldName: 'Sorghum grain',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sorghum/Milo grain, dried',
  },
  {
    oldName: 'Whey powder dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Whey powder dried',
  },
  {
    oldName: 'MetAMINO®, 99% DL-Methionine',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'MetAMINO®, 99% DL-Methionine',
  },
  {
      oldName: 'Mineral premix grower pigs 0.5 kg/ton',
      client: undefined,
      isUsedByDsm: true,
      isReportedAsOld: true,
      newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'ThreAMINO®, 98.5% L-Threonine',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'ThreAMINO®, 98.5% L-Threonine',
  },
  {
    oldName: 'TrypAMINO®, 98.0% L-Tryptophan',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'TrypAMINO®, 98.0% L-Tryptophan',
  },
  {
    oldName: 'ValAMINO®, 98.0% L-Valine',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Minerals, additives, vitamins',
    Comment: 'Not in GFLI',
  },
  {
    oldName: 'Animal meal',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Animal (meat and bone) meal',
  },
  {
    oldName: 'Fat from animals',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Fat from animals',
  },
  {
    oldName: 'OBSOLETE - Sugar cane molasses, from sugar production, at plant',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Sugar cane molasses',
  },
  {
    oldName: 'Wheat grain, winter, dried',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Wheat grain, winter, dried',
  },
  {
    oldName: 'Fodder beet (fresh)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Fodder beet (fresh)',
  },
  {
    oldName: 'Lentils',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lentils',
  },
  {
    oldName: 'Lentils straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Lentils straw',
  },
  {
    oldName: 'Crude linseed oil (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude linseed oil (pressing)',
  },
  {
    oldName: 'Linseed expeller (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Linseed meal expeller (pressing)',
  },
  {
    oldName: 'Crude linseed oil (solvent)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude linseed oil (solvent)',
  },
  {
    oldName: 'Linseed meal (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Linseed meal (solvent)',
  },
  {
    oldName: 'Linseed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Linseed, dried',
  },
  {
    oldName: 'Linseed straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Linseed straw',
  },
  {
    oldName: 'Vitamin Premix LEAP Roadtesting pigs',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Soybean',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Soybean, dried, full fat',
  },
  {
    oldName: 'Sugar beet pulp dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sugar beet pulp dried',
  },
  {
    oldName: 'Sugar, from sugar beet',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sugar, from sugar beet',
  },
  {
    oldName: 'Sugar beet pulp wet',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sugar beet pulp wet',
  },
  {
    oldName: 'Sugar beet molasses',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sugar beet molasses',
  },
  {
    oldName: 'Sugar beet',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sugar beet',
  },
  {
    oldName: 'Sugar, from sugar cane',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sugar, from sugar cane',
  },
  {
    oldName: 'Sugar cane molasses',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sugar cane molasses',
  },
  {
    oldName: 'Vitamin Premix LEAP Roadtesting Broilers',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Sugar cane',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sugar cane',
  },
  {
    oldName: 'Sunflower seed dehulled (full)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower seed fully dehulled',
  },
  {
    oldName: 'Sunflower hull (full)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower hull from full dehulling',
  },
  {
    oldName: 'Sunflower seed dehulled (partial)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower seed partially dehulled',
  },
  {
    oldName: 'Sunflower hull (partial)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower hull from partial dehulling',
  },
  {
    oldName: 'Crude sunflower oil (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude sunflower oil (pressing)',
  },
  {
    oldName: 'Sunflower seed expelled dehulled (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower seed expelled dehulled (pressing)',
  },
  {
    oldName: 'Sunflower hull (pressing)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower hull (pressing)',
  },
  {
    oldName: 'Crude sunflower oil (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude sunflower oil (solvent)',
  },
  {
    oldName: 'Sunflower seed meal (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sunflower seed meal (solvent)',
  },
  {
    oldName: 'Sunflower hull (solvent)',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sunflower hull (solvent)',
  },
  {
    oldName: 'Sunflower seed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Sunflower seed, dried',
  },
  {
    oldName: 'Crude palm oil',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Crude palm oil',
  },
  {
    oldName: 'Crude peanut oil',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Crude peanut oil (solvent)',
  },
  {
    oldName: 'Groundnut meal',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Groundnut meal (solvent)',
  },
  {
    oldName: 'Dairy cow co-product, feed grade',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Animal (meat and bone) meal, beef',
  },
  {
    oldName: 'Triticale straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Triticale straw',
  },
  {
    oldName: 'Triticale grain, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Triticale grain, dried',
  },
  {
    oldName: 'Vinasse dried, at plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Vinasse dried',
  },
  {
    oldName: 'Wheat flour',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat flour',
  },
  {
    oldName: 'Wheat germ',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Wheat germ',
  },
  {
    oldName: 'Wheat middlings & feed',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat middlings',
  },
  {
    oldName: 'Wheat bran, from dry milling',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat bran, from dry milling',
  },
  {
    oldName: 'Wheat starch',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat starch',
  },
  {
    oldName: 'Wheat bran, from wet milling',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat bran, from wet milling',
  },
  {
    oldName: 'Wheat gluten feed',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat gluten feed',
  },
  {
    oldName: 'Wheat gluten meal',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat gluten meal',
  },
  {
    oldName: 'Maize gluten meal',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Maize/Corn gluten meal dried',
  },
  {
    oldName: 'Minerals Premix LEAP roadtesting pigs',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Wheat straw',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat straw',
  },
  {
    oldName: 'Wheat grain, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat grain, dried',
  },
  {
    oldName: 'Wheat straw, durum',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Wheat straw, durum',
  },
  {
    oldName: 'Wheat grain, durum, dried',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Wheat grain, durum, dried',
  },
  {
    oldName: 'Wheat straw, spring',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Wheat straw, spring',
  },
  {
    oldName: 'Wheat grain, spring, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Wheat grain, spring, dried',
  },
  {
    oldName: 'Wheat starch slurry',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Other',
  },
  {
    oldName: 'Vinasse wet (sugar cane), at plant',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Vinasse wet',
  },
  {
    oldName: 'Refined palm oil',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Refined palm oil',
  },
  {
    oldName: 'Fatty acid distillates (palm oil)',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Fatty acid distillates (palm oil)',
  },
  {
    oldName: 'Fish oil',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Fish oil',
  },
  {
    oldName: 'Fish meal',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Fish meal',
  },
  {
    oldName: 'Urea',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Urea feed grade',
  },
  {
    oldName: 'Bread meal for feed',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Coproducts of the food and fuel industries',
    Comment: 'Nijsen',
  },
  {
    oldName: 'Frozen chips for feed',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Coproducts of the food and fuel industries',
    Comment: 'Nijsen',
  },
  {
    oldName: 'Potato scraps for feed',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Coproducts of the food and fuel industries',
    Comment: 'Nijsen',
  },
  {
    oldName: 'Residual food flows for feed',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Coproducts of the food and fuel industries',
    Comment: 'Nijsen',
  },
  {
    oldName: 'Rice bran - from Thailand',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Rice bran (raw)',
  },
  {
    oldName: 'Rice brokens - from Thailand',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Rice brokens (raw)',
  },
  {
    oldName: 'Steam peelings',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Coproducts of the food and fuel industries',
    Comment: 'Nijsen',
  },
  {
    oldName: 'Average of minerals, additives and vitamins',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Sodium Bicarbonate',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Sodium Bicarbonate (NaHCO3)',
  },
  {
    oldName: 'Minerals Premix LEAP roadtesting broilers',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Maize flour',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn flour',
  },
  {
    oldName: 'Maize middlings',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn middlings',
  },
  {
    oldName: 'Triticale, at feed compound plant',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Triticale grain, dried',
  },
  {
    oldName: 'Choline chloride 70% dry',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Soybean, production mix',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Soybean, dried, full fat',
  },
  {
    oldName: 'Maize starch dried',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Maize/Corn starch dried',
  },
  {
    oldName: 'Coccidostatic',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Maize',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Maize/Corn, dried',
  },
  {
    oldName: "Brewer's grains (cut-off)",
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: "Brewer's grains dried",
    Comment: 'ok for US, wet for NL',
  },
  {
    oldName: 'Average vitamins',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Water',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Water',
  },
  {
    oldName: 'Vitamins and Minerals Premix',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Monocalcium Phosphate',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Dicalcium phosphate',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Barley straw',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Barley straw',
  },
  {
    oldName: 'Barley grain, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Barley grain, dried',
  },
  {
    oldName: 'Beans, dry',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Beans, dried',
  },
  {
    oldName: 'Beans, dry straw',
    client: undefined,
    isUsedByDsm: false,
    isReportedAsOld: true,
    newName: 'Beans straw',
  },
  {
    oldName: 'Soybean, heat treated',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Soybean, heat treated',
  },
  {
    oldName: 'Almond hulls, dried',
    client: undefined,
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Almond shell/Almond hull',
  },
  {
    oldName: 'Soybeans (Donau Soja)',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Soybean, dried, full fat (Donau Soja)',
    Comment: 'added (check naming)',
  },
  {
    oldName: 'RONOZYME® RumiStar 900 (L) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® RumiStar 900 (L) DSM',
  },
  {
    oldName: 'ROVIMIX® HyD® DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® HyD® DSM',
  },
  {
    oldName: 'DHA Gold® DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DHA Gold® DSM',
  },
  {
    oldName: 'RONOZYME® WX 2000 (CT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (CT) DSM',
  },
  {
    oldName: 'ROVIMIX® Calpan DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Calpan DSM',
  },
  {
    oldName: 'Bovaer® DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Bovaer® DSM',
  },
  {
    oldName: 'ROVIMIX® Niacinamide DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Niacinamide DSM',
  },
  {
    oldName: 'ROVIMIX® Niacin DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Niacin DSM',
  },
  {
    oldName: 'ROVIMIX® E-50 Adsorbate DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® E-50 Adsorbate DSM',
  },
  {
    oldName: 'ROVIMIX® AD3 1000/200 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® AD3 1000/200 DSM',
  },
  {
    oldName: 'ROVIMIX® A 1000 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® A 1000 DSM',
  },
  {
    oldName: 'ROVIMIX® B2 80-SD DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® B2 80-SD DSM',
  },
  {
    oldName: 'ROVIMIX® β-Carotene 10% DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® β-Carotene 10% DSM',
  },
  {
    oldName: 'ROVIMIX® Stay C-35 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Stay C-35 DSM',
  },
  {
    oldName: 'ROVIMIX® Folic 80-SD DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Folic 80-SD DSM',
  },
  {
    oldName: 'ROVIMIX® D3 500 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® D3 500 DSM',
  },
  {
    oldName: 'CAROPHYLL® Yellow 10% DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'CAROPHYLL® Yellow 10% DSM',
  },
  {
    oldName: 'CAROPHYLL® Red 10% DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'CAROPHYLL® Red 10% DSM',
  },
  {
    oldName: 'CAROPHYLL® Pink 8% DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'CAROPHYLL® Pink 8% DSM',
  },
  {
    oldName: 'CAROPHYLL® Pink 10% DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'CAROPHYLL® Pink 10% DSM',
  },
  {
    oldName: 'RONOZYME® WX 5000 (CT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 5000 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® WX 2000 (L) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (L) DSM',
  },
  {
    oldName: 'RONOZYME® RumiStar 600 (CT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® RumiStar 600 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® ProAct (L) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (L) DSM',
  },
  {
    oldName: 'RONOZYME® ProAct (CT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (CT) DSM',
  },
  {
    oldName: 'RONOZYME® Multigrain (L) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® Multigrain (L) DSM',
  },
  {
    oldName: 'RONOZYME® HiStarch (CT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiStarch (CT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos 5000 (GT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos 5000 (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (M) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (M) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (L) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (L) DSM',
  },
  {
    oldName: 'Balancius® (L) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Balancius® (L) DSM',
  },
  {
    oldName: 'VevoVitall® DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'VevoVitall® DSM',
  },
  {
    oldName: 'ROVIMIX® B6 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® B6 DSM',
  },
  {
    oldName: 'ROVIMIX® A 500 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® A 500 DSM',
  },
  {
    oldName: 'Blood meal (Bovine and Porcine)',
    client: 'Vitapro',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Blood meal, mixed species',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [Seara]',
    client: 'Seara',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Maize silage, grown on farm BR',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Dried forages (alfalfa)',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'ROVIMIX® E-50 Adsorbate DSM [agrofab] - Obsolete',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® E-50 Adsorbate DSM',
  },
  {
    oldName: 'ROVIMIX® β-Carotene 10% DSM [agrofab] - Obsolete',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® β-Carotene 10% DSM',
  },
  {
    oldName: 'ROVIMIX® D3 500 DSM [agrofab] - Obsolete',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® D3 500 DSM',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'ROVIMIX® Biotin HP DSM [agrofab] - Obsolete',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Biotin HP DSM',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [agrofab]',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'CRINA® Ruminants DSM [agrofab] - Obsolete',
    client: 'Agro Fabiani',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'CRINA® Ruminants DSM',
  },
  {
    oldName: 'Barley grain, dried BR',
    client: 'Fazenda Colorado',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Barley grain, dried',
  },
  {
    oldName: 'Refined Palm Oil BR',
    client: 'Fazenda Colorado',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Refined palm oil',
  },
  {
    oldName: 'RONOZYME® HiStarch 900 (CT) DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiStarch 900 (CT) DSM',
  },
  {
    oldName: 'ProAct 360™ DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'ProAct 360™ DSM',
  },
  {
    oldName: 'HiPhorius™ 40 DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'HiPhorius™ 40 DSM',
  },
  {
    oldName: 'RONOZYME® ProAct (CT) DSM [CP] - Obsolete',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (CT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [CP] - Obsolete',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'RONOZYME® Multigrain (GT) DSM [CP] - Obsolete',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® Multigrain (GT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [CP]',
    client: 'CP',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [Vall]',
    client: 'Vall Companys',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'ROVIMIX® Biotin HP DSM [Agrindus] - Obsolete',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Biotin HP DSM',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [Agrindus]',
    client: 'Agrindus',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [La Calera] - Obsolete',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos 20000 (GT) DSM [La Calera] - Obsolete',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos 20000 (GT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [La Calera]',
    client: 'La Calera',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Soybean hull',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Soybean hull (solvent)',
  },
  {
    oldName: 'Soybean meal',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Soybean meal (solvent)',
  },
  {
    oldName: 'Soybean oil',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Crude soybean oil (solvent)',
  },
  {
    oldName: 'RONOZYME® HiPhos 20000 (GT) DSM [VoergroepZuid] - Obsolete',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos 20000 (GT) DSM',
  },
  {
    oldName: 'RONOZYME® WX 2000 (CT) DSM [VoergroepZuid] - Obsolete',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (CT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [VoergroepZuid]',
    client: 'Voergroep Zuid',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [Leievoeders] - Obsolete',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [Leievoeders]',
    client: 'Leievoeders',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [DosPinos]',
    client: 'Dos Pinos',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'RONOZYME® ProAct (CT) DSM [Aurora] - Obsolete',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (CT) DSM',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'RONOZYME® WX 2000 (CT) DSM [Aurora] - Obsolete',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® Multigrain (GT) DSM [Aurora] - Obsolete',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® Multigrain (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [Aurora] - Obsolete',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [Aurora]',
    client: 'Aurora',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'RONOZYME® ProAct (CT) DSM [CMI] - Obsolete',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (CT) DSM',
  },
  {
    oldName: 'Balancius® (C) DSM [CMI] - Obsolete',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Balancius® (C) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [CMI] - Obsolete',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8) [CMI]',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'Choline chloride (70% dry) [CMI]',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton [CMI]',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton [CMI]',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton [CMI]',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4) [CMI]',
    client: 'CMI',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'RONOZYME® ProAct (CT) DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (CT) DSM',
  },
  {
    oldName: 'Balancius® (C) DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Balancius® (C) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos 20000 (GT) DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos 20000 (GT) DSM',
  },
  {
    oldName: 'RONOZYME® VP (CT) DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® VP (CT) DSM',
  },
  {
    oldName: 'RONOZYME® WX 2000 (CT) DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (CT) DSM',
  },
  {
    oldName: 'Monocalcium Phosphate (CaH4P2O8)',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'MCP (Monocalcium Phosphate)',
  },
  {
    oldName: 'ROVIMIX® Stay C-35 DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Stay C-35 DSM',
  },
  {
    oldName: 'Mineral premix broilers 0.5 kg/ton',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Mineral premix grower pigs 0.5 kg/ton',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'ROVIMIX® E-50 Adsorbate DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® E-50 Adsorbate DSM',
  },
  {
    oldName: 'ROVIMIX® β-Carotene 10% DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® β-Carotene 10% DSM',
  },
  {
    oldName: 'ROVIMIX® D3 500 DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® D3 500 DSM',
  },
  {
    oldName: 'Vitamin premix broilers OVN 0.5 kg/ton',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin premix grower pigs OVN 0.5 kg/ton',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'ROVIMIX® Biotin HP DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Biotin HP DSM',
  },
  {
    oldName: 'Dicalcium Phosphate (CaHPO4)',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'DCP (Dicalcium Phosphate)',
  },
  {
    oldName: 'VevoVitall® DSM [Sustell] - Obsolete',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'VevoVitall® DSM',
  },
  {
    oldName: 'VevoWin® DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'VevoWin® DSM',
  },
  {
    oldName: 'VevoVitall® DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'VevoVitall® DSM',
  },
  {
    oldName: 'CRINA® Ruminants DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'CRINA® Ruminants DSM',
  },
  {
    oldName: 'Brenneis Mineral premix',
    client: 'Lactalis',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'VevoVitall® DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'VevoVitall® DSM',
  },
  {
    oldName: 'Vitamin Premix, LEAP Roadtesting pigs',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Vitamin Premix, LEAP Roadtesting Broilers',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Victus DSM',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Victus DSM',
  },
  {
    oldName: 'ROVIMIX® D3 500 DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® D3 500 DSM',
  },
  {
    oldName: 'ROVIMIX® β-Carotene 10% DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® β-Carotene 10% DSM',
  },
  {
    oldName: 'RONOZYME® WX 2000 (CT) DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® Rumistar 600 (CT) DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® Rumistar 600 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® Multigrain (GT) DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® Multigrain (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos 20000 (GT) DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos 20000 (GT) DSM',
  },
  {
    oldName: 'Premix Ian Hollows',
    client: 'Rivalea',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Minerals Premix, LEAP roadtesting pigs',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Minerals Premix, LEAP roadtesting broilers',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Full fat soya, from crushing',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Soybean, dried, full fat',
  },
  {
    oldName: 'CRINA® Ruminants DSM [Rivalea] - Obsolete',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'CRINA® Ruminants DSM',
  },
  {
    oldName: 'Coccidostatic (DSM)',
    client: 'Rivalea',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Minerals, additives, vitamins',
  },
  {
    oldName: 'Choline chloride (70% dry)',
    client: 'Sustell',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'Bleaching earth',
    client: 'Rivalea',
    isUsedByDsm: true,
    isReportedAsOld: true,
    newName: 'Activated bleaching earth (activated calcium bentonite)',
  },
  {
    oldName: 'Choline chloride 70%, dry',
    client: 'DSM Nutritional Products - Cal Maine',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Choline chloride 70% dry',
  },
  {
    oldName: 'ROVIMIX® Biotin HP DSM',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Biotin HP DSM',
  },
  {
    oldName: 'ROVIMIX® D3 500 DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'ROVIMIX® D3 500 DSM',
  },
  {
    oldName: 'ROVIMIX® β-Carotene 10% DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'ROVIMIX® β-Carotene 10% DSM',
  },
  {
    oldName: 'ROVIMIX® HyD® DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'ROVIMIX® HyD® DSM',
  },
  {
    oldName: 'ROVIMIX® E-50 Adsorbate DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'ROVIMIX® E-50 Adsorbate DSM',
  },
  {
    oldName: 'Full fat soya from crushing',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Soybean, dried, full fat',
  },
  {
    oldName: 'CRINA® Poultry Plus DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'CRINA® Poultry Plus DSM',
  },
  {
    oldName: 'ROVIMIX® Stay C-35 DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'ROVIMIX® Stay C-35 DSM',
  },
  {
    oldName: 'RONOZYME® WX 2000 (CT) DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® WX 2000 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® Multigrain (GT) DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® Multigrain (GT) DSM',
  },
  {
    oldName: 'RONOZYME® VP (CT) DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® VP (CT) DSM',
  },
  {
    oldName: 'Victus (DSM)',
    client: 'DSM Nutritional Products - Cal Maine',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Victus DSM',
  },
  {
    oldName: 'Victus',
    client: 'DSM',
    isUsedByDsm: false,
    isReportedAsOld: false,
    newName: 'Victus DSM',
  },
  {
    oldName: 'RONOZYME® Rumistar 600 (CT) DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® Rumistar 600 (CT) DSM',
  },
  {
    oldName: 'RONOZYME® ProAct (CT) DSM - Obsolete',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® ProAct (CT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos 20000 (GT) DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos 20000 (GT) DSM',
  },
  {
    oldName: 'RONOZYME® HiPhos (GT) DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'RONOZYME® HiPhos (GT) DSM',
  },
  {
    oldName: 'Balancius® (C) DSM',
    client: 'DSM',
    isUsedByDsm: true,
    isReportedAsOld: false,
    newName: 'Balancius® (C) DSM',
  },
];


const getIngredientMappingV1toV2 = (nameV1: string) => 
  compoundFeedV1toV2IngredientMappings.find(item => item.oldName === nameV1);

export default getIngredientMappingV1toV2;