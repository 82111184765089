import { FC, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  SalmonBaseline,
  SalmonInput,
} from '../../../../models/Baseline/SalmonBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';


const SInputFormDialog: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  const currResetValue = useRef<SalmonInput>();

  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { reset, trigger, getValues, setValue, control } =
    useFormContext<SalmonBaseline>();
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE',
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const numOfSmolts = useWatch({
    control,
    name: `${fieldItemPrefix}.numberOfStockedAnimals`,
  });

  const avgWeightOfSmolt = useWatch({
    control,
    name: `${fieldItemPrefix}.averageLiveWeightStockedAnimals`,
  });

  if (numOfSmolts && avgWeightOfSmolt) {
    setValue(
      `${fieldItemPrefix}.weightOfStockedAnimals`,
      Math.round(Number(numOfSmolts) * Number(avgWeightOfSmolt) * 100) / 100
    );
    // We canot have just else {... here because it would set value for stages.0.stageData.input.weightOfStockedAnimals
    // immediately, which means already having  value for the first stage, before it's actually added..so after the real stage is apended
    // if in some moment getValues() include weightOfStockedAnimals field (here on handleResetClick() or saveStage() - for second stage)
    // it would return two times two stages.0.stageData...
  } else if (
    (numOfSmolts && !avgWeightOfSmolt) ||
    (!numOfSmolts && avgWeightOfSmolt)
  ) {
    setValue(`${fieldItemPrefix}.weightOfStockedAnimals`, '');
  }

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as SalmonBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.input) {
        resetObject.stages[itemIndex].stageData.input = { ...currResetValue.current };
        reset(resetObject, {
          errors: true,
        });
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      iconCode="spiecies/aquaculture"
    >
      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberOfStockedAnimals`}
          type="number"
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.NUMBER_SMOLT_STOCKED',
          })}`}
          margin="none"
          required
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.NUMBER_SMOLT_STOCKED_TT',
          })}
          disabled={formType === 'view'}
          defaultValue={getValues(`${fieldItemPrefix}.numberOfStockedAnimals`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageLiveWeightStockedAnimals`}
          type="number"
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.AVG_STOCK_ANIMAL_MASS',
          })}`}
          margin="none"
          required
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.AVG_STOCK_ANIMAL_MASS_TT',
          })}
          adornment={
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
          userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
          }
          disabled={formType === 'view'}
          defaultValue={getValues(
            `${fieldItemPrefix}.averageLiveWeightStockedAnimals`
          )}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.weightOfStockedAnimals`}
          type="number"
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.STOCKED_BIOMASS',
          })}`}
          tooltip={`${intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.STOCKED_BIOMASS_TT',
          })}`}
          margin="none"
          adornment={
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
          userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
          }
          readonly
          disabled={formType === 'view'}
          defaultValue={getValues(`${fieldItemPrefix}.weightOfStockedAnimals`)}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await trigger(`stages.${itemIndex}.stageData.input`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SInputFormDialog;
