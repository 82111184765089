import { TableHead, TableRow } from '@material-ui/core';
import { HeaderGroup } from '@tanstack/react-table';
import { v4 as uuidv4 } from 'uuid';
import TableHeaderItem from './TableRowHeaderItem';

interface TableHeaderProps<T> {
  items: HeaderGroup<T>[];
}

const TableHeader = <T,>({ items }: TableHeaderProps<T>) => (
  <TableHead>
    {items.map((headerGroup) => (
      <TableRow key={uuidv4()}>
        {headerGroup.headers.map((header) => (
          <TableHeaderItem
            key={uuidv4()}
            handleSort={header.column.getToggleSortingHandler()}
            label={header.column.columnDef.header as string}
            hasSort={header.column.getCanSort()}
            isDescending={header.column.getIsSorted() === 'desc'}
            isSortActive={!!header.column.getIsSorted()}
            index={header.index}
          />
        ))}
      </TableRow>
    ))}
  </TableHead>
);

export default TableHeader;
