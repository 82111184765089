import React, { useEffect, useState } from 'react';
import { Checkbox, List, ListItem, ListItemText, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { CustomerIngredientTypes, getCustomerIngredients } from '../ingredientList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  selectAllCheckBox: {
    fontWeight: 'bold',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const CustomerIngredientsList = (props) => {
  const { type, ingredientList, setIngredientList, assignedIngredients } = props;
  const [ingredients, setIngredients] = useState([]);
  const classes = useStyles();
  const intl = useIntl();
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    const ingredients =
      type === CustomerIngredientTypes.CF_INGREDIENTS
        ? getCustomerIngredients(CustomerIngredientTypes.CF_INGREDIENTS)
        : getCustomerIngredients(CustomerIngredientTypes.SINGLE_INGREDIENTS);
    setIngredients(ingredients.sort((a, b) => a.name.localeCompare(b.name)));

    const resultMap = new Map();
    ingredients.forEach(ingredient => {
      const existsIningredientList = ingredientList.some(ingredientListItem => ingredientListItem.id === ingredient.id);
      resultMap.set(ingredient.id, existsIningredientList);
    });
    const resultObject = Object.fromEntries(resultMap);
    const initialState = !Object.values(resultObject).includes(false)
    setChecked(resultObject)
    setCheckedAll(initialState);
  }, [])

  useEffect(() => {
    let allChecked = true;
    Object.keys(checked).forEach(ingredientId => {
      if (checked[ingredientId] === false) {
        allChecked = false;
      }
    });
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);
  
  const handleToggle = (ingredientId) => {
    const currentIndex = ingredientList.indexOf(ingredientId);
    const newChecked = [...ingredientList];

    if (currentIndex === -1) {
      newChecked.push(ingredientId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setIngredientList(newChecked);
  };

  const toggleCheck = (ingredientId) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[ingredientId] = !prevState[ingredientId];
      return newState;
    });
    handleToggle(ingredientId);
  };

  const selectAll = (value) => {
    if(value) setIngredientList(ingredients.map((ing) => ing.id));
    else setIngredientList(assignedIngredients);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((ingredientId) => {
        newState[ingredientId] = value;
      });
      return newState;
    });
  };
  
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <React.Fragment key={`fragment-ingredients-${type}`}>
      <Typography>
        {(type === CustomerIngredientTypes.CF_INGREDIENTS) ?
          intl.formatMessage({id: "CUSTOMERS.CARD.INGREDIENTS_LIST.CF" }) :
          intl.formatMessage({id: "CUSTOMERS.CARD.INGREDIENTS_LIST.SINGLE" }) 
        }
      </Typography>
      <List className={classes.root} key={type}>
        <ListItem dense>
          <Checkbox
            onChange={(event) => selectAll(event.target.checked)}
            checked={checkedAll}
            edge="start"
          />
          <ListItemText 
            className={classes.selectAllCheckBox} 
            primary={intl.formatMessage({id: "USERS.GROUPS.SELECT_ALL" })}
            classes={{ primary: classes.selectAllCheckBox }}          />
        </ListItem>
        {ingredients.map((ingredient) => {
          const labelId = `checkbox-list-label-${ingredient.id}-${type}`;
          return (
            <Tooltip key={`customer-ingredients-${type}-tooltip-${ingredient.name}`} 
              title={(assignedIngredients && assignedIngredients?.includes(ingredient.id)) ?
                intl.formatMessage({id: "CUSTOMERS.FORM.FIELD.INGREDIENTS_TOOLTTIP_CHECKED" })
              : intl.formatMessage({id: "CUSTOMERS.FORM.FIELD.INGREDIENTS_TOOLTTIP_NOT_CHECKED" })
              }
            >
              <ListItem key={`${ingredient.id}li`} dense>
                  <Checkbox
                    onChange={() => toggleCheck(ingredient.id)}
                    checked={ingredientList?.includes(ingredient.id)}
                    edge="start"
                    disabled={assignedIngredients?.includes(ingredient.id)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                <ListItemText id={labelId} primary={ingredient.name} />
              </ListItem>
            </Tooltip>

          );
        })}
      </List>
    </ React.Fragment>
  );
}