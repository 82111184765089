/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from "@reduxjs/toolkit";
import { InterventionsMappedDataType } from "../../sustell_15/components/FarmFlow/Intervention/types";

// Use object instead of Map (suggestion from redux team, non-serializable types should not be put inside states)
export type DraftsMapType = {
  [draftKey: string]: InterventionsMappedDataType
}

export const initialState: {value: DraftsMapType} = {
  value: {},
}

export interface SetInterventionDraftAction {
  readonly type: "interventionDrafts/setInterventionDraftAction";
  payload: {key: string, newInterventionValue: InterventionsMappedDataType};
}

export interface DeleteDraftAction {
  readonly type: "interventionDrafts/clearInterventionDraftAction";
  payload: {key: string};
}

export const interventionDraftsSlice = createSlice({
  name: 'interventionDrafts',
  initialState,
  reducers: {
    setInterventionDraftAction: (state, action: SetInterventionDraftAction) => {
      const newState = action.payload.newInterventionValue;
      // eslint-disable-next-line no-param-reassign
      state.value[action.payload.key] = newState;
    },
    // On form sucesful submit we should clear the existing draft
    clearInterventionDraftAction: (state, action: DeleteDraftAction) => {
      if (!action.payload.key) {
        console.warn('cannot clear intervention draft for undefined key')
      }
      // eslint-disable-next-line no-param-reassign
      delete state.value[action.payload.key];
    },
  }
})

export const { setInterventionDraftAction, clearInterventionDraftAction } = interventionDraftsSlice.actions;

export default interventionDraftsSlice.reducer;
