import * as Yup from 'yup';
import { StageType } from '../../../../../graphql/types';
import {
  numericOptionalWithMinMax,
  numericOptionalWithGreaterThanMin,
  numericRequiredWithMin,
  numericRequiredWithMinMax,
  numericOptionalWithMin,
  numericRequiredWithGreaterThanMin,
  feedItemsTest,
} from './validationObjectBuilderFunctions';
import {
  usedResourcesPart,
  resourceItems,
} from './baselineValidationSchemaGeneralPart';
import { defaultUnitsV2 } from '../../../../sustell_15/utils/unit-utils';
import { processingStageData } from './processingBaselineValidation';
import { FacilitySpecies } from '../../../../sustell_15/models/Facility/FacilityTypes';

export const stageInputBreeding = (intl) =>
  Yup.object({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    henInternalSource: Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
    henExternalSource: Yup.object({
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
    roosterInternalSource: Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
    roosterExternalSource: Yup.object({
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
  });

export const stageInputGrowing = (intl) =>
  Yup.object({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    durationOfProductionCycle: numericRequiredWithGreaterThanMin(intl, 0),
    emptyPeriod: numericOptionalWithMin(intl, 0),
    animalsPresentAtStart: numericOptionalWithMin(intl, 0),
    animalsPresentAtEnd: numericOptionalWithMin(intl, 0),
    averageWeightAtStart: numericOptionalWithGreaterThanMin(intl, 0),
    averageWeightAtEnd: numericOptionalWithGreaterThanMin(intl, 0),
    selection: Yup.object({
      internalSelected: Yup.bool(),
      externalSelected: Yup.bool(),
    }).test('checkWhatIsSelected', '', function test(value, testContext) {
      const { path, createError } = this;
      const internalSelected = value?.internalSelected;
      const externalSelected = value?.externalSelected;
      if (!internalSelected && !externalSelected)
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.NO_SELECTION',
          }),
        });
      const input = testContext.from[1].value;
      const stageTypes = input?.internalSources
        ?.filter((internalSource) => internalSource.stageType)
        .map((internalSource) => internalSource.stageType);
      const differentStageTypes = [...new Set(stageTypes)];
      if (
        internalSelected &&
        externalSelected &&
        differentStageTypes.length > 1
      )
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.NO_BOTH',
          }),
        });
      if (
        internalSelected &&
        externalSelected &&
        differentStageTypes.length === 1 &&
        differentStageTypes.includes('GROWING')
      )
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.NO_GROWING',
          }),
        });
      return true;
    }),
    internalSources: Yup.array().of(
      Yup.object({
        farmId: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        originStageId: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' }))
          .test('singleAnimalTypeSelected', '', function test(_, testContext) {
            const { path, createError } = this;
            const currentStageType = testContext.from[0].value.stageType;
            const input = testContext.from[1].value;
            const stageTypes = input?.internalSources
              ?.filter((internalSource) => internalSource.stageType)
              .map((internalSource) => internalSource.stageType);
            if (currentStageType && [...new Set(stageTypes)].length > 1) {
              if (currentStageType === 'HATCHING')
                return createError({
                  path,
                  message: intl.formatMessage({
                    id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.HATCHING_MIXED',
                  }),
                });
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.GROWING_MIXED',
                }),
              });
            }
            return true;
          }),
        averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
        numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
        transportDistance: numericOptionalWithMin(intl, 0),
      })
    ),
    externalSources: Yup.array().of(
      Yup.object({
        numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
        averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
        transportDistance: numericOptionalWithMin(intl, 0),
      })
    ),
  });

export const stageInputLaying = (intl) =>
  Yup.object({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    internalSource: Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
    externalSource: Yup.object({
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
  });

export const stageInputHatching = (intl) =>
  Yup.object({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    eggsPresentAtStart: numericOptionalWithMin(intl, 0),
    eggsPresentAtEnd: numericOptionalWithMin(intl, 0),
    internalSource: Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
    externalSource: Yup.object({
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
      transportDistance: numericOptionalWithMin(intl, 0),
    }).nullable(),
  });

export const stageFeed = (intl) =>
  Yup.object({
    compoundFeeds: feedItemsTest(intl, 0),
    singleIngredients: feedItemsTest(intl, 0),
  }).test('somethingSelected', '', (value, testContext) => {
    const { path, createError } = testContext;
    const { compoundFeeds } = value;
    const { singleIngredients } = value;
    const compoundFeedsExist = compoundFeeds?.filter(
      (compoundFeed) => compoundFeed.feedType && compoundFeed.kgPerAnimal
    ).length;
    const singleIngredientsExist = singleIngredients?.filter(
      (singleIngredient) =>
        singleIngredient.feedType &&
        singleIngredient.kgPerAnimal &&
        singleIngredient.origin
    ).length;
    if (!compoundFeedsExist && !singleIngredientsExist)
      return createError({
        path,
        message: intl.formatMessage({ id: 'SUSTELL.STAGE.POULTRY.FEED.ERROR' }),
      });
    return true;
  });

export const stageHousing = (intl) =>
  Yup.object({
    resourceUse: Yup.object({
      electricityUse: numericOptionalWithGreaterThanMin(intl, 0),
      gasUse: numericOptionalWithGreaterThanMin(intl, 0),
      waterUse: numericOptionalWithGreaterThanMin(intl, 0),
      selfGeneratedRenewables: resourceItems(intl),
      energyTypes: resourceItems(intl),
    }),
    housingType: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
    ),
    timeInFreeRange: numericOptionalWithMinMax(intl, 0, 100),
    timeInHousing: numericOptionalWithMinMax(intl, 0, 100),
    manureSystems: Yup.array().of(
      Yup.object({
        mmsType: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        mmsHoldingDuration: Yup.string().when('mmsType', {
          is: (val) =>
            val === 'LIQUID_COVER' ||
            val === 'LIQUID_CRUST' ||
            val === 'LIQUID_NO_CRUST' ||
            val === 'PIT' ||
            val === 'DEEP_BEDDING_ACTIVE_MIXING' ||
            val === 'DEEP_BEDDING_NO_MIXING',
          then: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          otherwise: Yup.string().nullable(),
        }),
        share: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .test('sumOK', '', function test(value, testContext) {
            const { path, createError } = this;
            if (!value && value !== 0)
              return createError({
                path,
                message: intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }),
              });
            if (value === 0)
              return createError({
                path,
                message: intl.formatMessage(
                  { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
                  { value: '0' }
                ),
              });
            const [, parent2] = testContext.from;
            const MMSSystemsList = parent2?.value?.manureSystems || [];
            const sum = MMSSystemsList.reduce((acc, item) => {
              if (item.share && !Number.isNaN(item.share))
                return acc + Number(item.share);
              return acc;
            }, 0);
            if (sum !== 100)
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'SUSTELL.STAGE.POULTRY.MANURE.MMS.PERCENT_SUM_ERROR',
                }),
              });
            return true;
          }),
      })
    ),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
  });

export const stageOutputBreeding = (intl) =>
  Yup.object({
    totalEggsToStage: numericOptionalWithMin(intl, 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        if (
          Number(parent.totalEggsToStage) + Number(parent.totalEggsSold) ===
          0
        ) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.BREEDING.BREEDING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
    totalEggsSold: numericOptionalWithMin(intl, 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        if (
          Number(parent.totalEggsToStage) + Number(parent.totalEggsSold) ===
          0
        ) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.BREEDING.BREEDING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    priceEggs: numericOptionalWithGreaterThanMin(intl, 0),
    totalHensSold: numericRequiredWithGreaterThanMin(intl, 0),
    averageHensWeight: numericRequiredWithGreaterThanMin(intl, 0),
    mortalityHensAtPercent: numericRequiredWithMinMax(intl, 0, 100),
    weightHensAtMortality: numericOptionalWithMin(intl, 0),
    totalRoostersSold: numericRequiredWithGreaterThanMin(intl, 0),
    averageRoostersWeight: numericRequiredWithGreaterThanMin(intl, 0),
    mortalityRoostersAtPercent: numericRequiredWithMinMax(intl, 0, 100),
    weightRoostersAtMortality: numericOptionalWithMin(intl, 0),
    price: numericOptionalWithMin(intl, 0),
  });

export const stageOutputGrowing = (intl) =>
  Yup.object({
    totalToStage: numericOptionalWithMin(intl, 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        if (Number(parent.totalToStage) + Number(parent.totalSold) === 0) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.GROWING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    averageWeight: numericRequiredWithMin(intl, 0),
    totalSold: numericOptionalWithMin(intl, 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        if (Number(parent.totalToStage) + Number(parent.totalSold) === 0) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.GROWING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    mortalityAtPercent: numericRequiredWithMinMax(intl, 0, 100),
    weightAtMortality: numericOptionalWithMin(intl, 0),
  });

export const stageOutputHatching = (intl) =>
  Yup.object({
    totalToStage: numericRequiredWithMin(intl, 0),
    averageWeight: numericRequiredWithMin(intl, 0),
    totalSold: numericRequiredWithMin(intl, 0),
    price: numericOptionalWithMin(intl, 0),
    lossRate: numericRequiredWithMin(intl, 0),
    priceOfLossesSold: numericOptionalWithMin(intl, 0),
    totalEggShellsSold: numericOptionalWithMin(intl, 0),
  });

export const stageOutputLaying = (intl) =>
  Yup.object({
    totalSold: numericRequiredWithGreaterThanMin(intl, 0),
    averageWeight: numericRequiredWithGreaterThanMin(intl, 0),
    priceEggs: numericOptionalWithGreaterThanMin(intl, 0),
    totalHensToStage: numericRequiredWithMin(intl, 0),
    averageHensWeight: numericRequiredWithGreaterThanMin(intl, 0),
    priceHens: numericOptionalWithGreaterThanMin(intl, 0),
    mortalityAtPercent: numericRequiredWithMinMax(intl, 0, 100),
    weightAtMortality: numericOptionalWithMin(intl, 0),
  });

export const stageEmissions = (intl) =>
  Yup.object({
    methane: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideIndirect: numericOptionalWithMinMax(intl, -100, 100),
    amonia: numericOptionalWithMinMax(intl, -100, 100),
    nitricOxide: numericOptionalWithMinMax(intl, -100, 100),
    nonMethaneVolatile: numericOptionalWithMinMax(intl, -100, 100),
    PM10: numericOptionalWithMinMax(intl, -100, 100),
    PM25: numericOptionalWithMinMax(intl, -100, 100),
    totalSuspendedParticles: numericOptionalWithMinMax(intl, -100, 100),
  });

const checkIfAtLeastOneResourceFilled = (parent, path, createError, intl) => {
  const electricityUsed = parent.electricityUse || parent.electricityUse === 0;
  const gasUsed = parent.gasUse || parent.gasUse === 0;
  const selfGeneratedRenewablesUsed = parent?.selfGeneratedRenewables?.find(
    (item) => item.type && (item.value || item.value === 0)
  );
  const energyTypesUsed = parent?.energyTypes?.find(
    (item) => item.type && (item.value || item.value === 0)
  );
  if (
    !electricityUsed &&
    !gasUsed &&
    !selfGeneratedRenewablesUsed &&
    !energyTypesUsed
  ) {
    const message = intl.formatMessage({
      id: 'SUSTELL.STAGE.POULTRY.HOUSING.HATCHING.RESOURCE_ERROR',
    });
    return createError({ path, message });
  }
  return true;
};

export const stageHousingHatching = (intl) =>
  Yup.object({
    resourceUse: Yup.object({
      electricityUse: numericOptionalWithGreaterThanMin(intl, 0).test(
        'checkIfAtLeastOneResourceFilled',
        '',
        function test(_, testContext) {
          return checkIfAtLeastOneResourceFilled(
            testContext.parent,
            this.path,
            this.createError,
            intl
          );
        }
      ),
      selfGeneratedRenewables: resourceItems(intl).test(
        'checkIfAtLeastOneResourceFilled',
        '',
        function test(_, testContext) {
          return checkIfAtLeastOneResourceFilled(
            testContext.from[1].value.resourceUse,
            `${this.path}.type`,
            this.createError,
            intl
          );
        }
      ),
      gasUse: numericOptionalWithGreaterThanMin(intl, 0).test(
        'checkIfAtLeastOneResourceFilled',
        '',
        function test(_, testContext) {
          return checkIfAtLeastOneResourceFilled(
            testContext.parent,
            this.path,
            this.createError,
            intl
          );
        }
      ),
      energyTypes: resourceItems(intl).test(
        'checkIfAtLeastOneResourceFilled',
        '',
        function test(_, testContext) {
          return checkIfAtLeastOneResourceFilled(
            testContext.from[1].value.resourceUse,
            `${this.path}.type`,
            this.createError,
            intl
          );
        }
      ),
      waterUse: numericOptionalWithGreaterThanMin(intl, 0),
    }),
  });

export const stageDataPartPoultry = ({ intl, userUOM = defaultUnitsV2 }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          type: Yup.string()
            .oneOf([
              StageType.Breeding,
              StageType.Hatching,
              StageType.Growing,
              StageType.Laying,
              StageType.Processing,
            ])
            .required(),
          stageData: Yup.object()
            .when('type', {
              is: StageType.Breeding,
              then: Yup.object({
                housing: stageHousing(intl),
                input: stageInputBreeding(intl),
                feed: Yup.object({
                  henFeed: stageFeed(intl),
                  roosterFeed: stageFeed(intl),
                }),
                output: stageOutputBreeding(intl),
                emissions: stageEmissions(intl),
              }),
            })
            .when('type', {
              is: StageType.Hatching,
              then: Yup.object({
                input: stageInputHatching(intl),
                output: stageOutputHatching(intl),
                emissions: stageEmissions(intl),
                housing: stageHousingHatching(intl),
              }),
            })
            .when('type', {
              is: StageType.Growing,
              then: Yup.object({
                input: stageInputGrowing(intl),
                housing: stageHousing(intl),
                feed: stageFeed(intl),
                output: stageOutputGrowing(intl),
                emissions: stageEmissions(intl),
              }),
            })
            .when('type', {
              is: StageType.Laying,
              then: Yup.object({
                input: stageInputLaying(intl),
                housing: stageHousing(intl),
                feed: stageFeed(intl),
                output: stageOutputLaying(intl),
                emissions: stageEmissions(intl),
              }),
            })
            .when('type', {
              is: StageType.Processing,
              then: processingStageData(intl, FacilitySpecies.Poultry),
            }),
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

// merge all necessary parts to baseSchema
const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ resourceUse: usedResourcesPart(confObj) }))
    .concat(stageDataPartPoultry(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
