import { Card } from "@material-ui/core";
import { FC, useRef } from "react";

import { footprintMainCardStyles } from "../../../_metronic/layout";
import SustellDashboard from "../components/dashboard/SustellDashboard";


const SustellDashboardPage: FC = () => {
  // const subheader = useSubheader();

  const pageRef = useRef(null);

  const classes = footprintMainCardStyles();
  return (
  <Card 
    id="mainDashboardWrapper"
    ref={pageRef}
    elevation={4}
    className={classes.mainCard}
    style={{minHeight: '2048px'}}
  >
    <SustellDashboard
      dashboardPageRef={pageRef}
    />
  </Card>
  );
}

export default SustellDashboardPage;