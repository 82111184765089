import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  HelpOutline,
  MoreVert,
  Equalizer,
  TrackChangesRounded,
} from '@material-ui/icons';
import { API } from 'aws-amplify';
import { withStyles } from '@material-ui/styles';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { footprintCategoryTableStyles } from '../../../../_metronic/layout';
import { ExactNumber } from '../helpers/ExactNumber';
import FootprintCategoryDetail from './FootprintCategoryDetail';
import { FootprintCategoryModel, FootprintModel } from '../../models/Footprint';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import FootprintTableIcon from './common/FootprintIcon';
import { translateKeyMapping } from '../../utils/string-utils';

import FootprintCategoryTarget from './FootprintCategoryTarget';
import {
  getFarmBaselineByReference,
  getInterventionByReference,
  getOrigins,
} from '../../../../graphql/queries';
import {
  FarmBaseline,
  FootPrintType,
  Intervention,
} from '../../../../graphql/types';

import Can from '../../../modules/Auth/Can';

interface FootprintCategoryTableProps {
  type: string;
  baselineFarmID: string;
  footprint: FootprintModel;
  customerID: string;
}

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: '12px !important',
    borderBottom: '1px solid #f0f0f0',
  },
  body: {
    border: 'none',
  },
  root: {
    '&:nth-of-type(1)': {
      Width: '298px',
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    height: '100px',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9FAFB',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

const FootprintCategoryTable = ({
  type,
  baselineFarmID,
  footprint,
  customerID,
}: FootprintCategoryTableProps) => {
  const [categoryDetailVisible, setCategoryDetailVisible] =
    useState<boolean>(false);
  const [footprintCategorySelected, setFootprintCategorySelected] =
    useState<FootprintCategoryModel>({} as FootprintCategoryModel);
  const [anchorEls, setAnchorEls] = useState<HTMLElement[]>([]);
  const [, updateRenderState] = useState<object>();
  const forceUpdate = () => updateRenderState({});
  const [origins, setOrigins] = useState<{ value: string; text: string }[]>([]);
  const [personalTargetDialogVisible, setPersonalTargetDialogVisible] =
    useState<boolean>(false);
  const classes = footprintCategoryTableStyles();
  const intl = useIntl();

  /**
   * To open footprint details view  for selected category
   * @param category
   */
  const openCategoryDetail = (category: FootprintCategoryModel) => {
    setFootprintCategorySelected(category);
    setCategoryDetailVisible(true);
    setAnchorEls([]);
  };
  const isComparison = !!footprint.comparison;

  const handleActionClose = () => {
    setAnchorEls([]);
    forceUpdate();
  };

  /**
   * To set target for selected category
   * @param category
   */
  const setPersonalTarget = (category: FootprintCategoryModel) => {
    setFootprintCategorySelected(category);
    setPersonalTargetDialogVisible(true);
    handleActionClose();
  };

  const footprintTargetSuccessHandler = async () => {
    const queryWithVars = {
      query:
        type === 'b' ? getFarmBaselineByReference : getInterventionByReference,
      variables: {
        farmId: baselineFarmID,
        reference: footprint.reference,
      },
    };

    const dataSetResult = await (API.graphql(queryWithVars) as Promise<{
      data: {
        getFarmBaselineByReference: FarmBaseline;
        getInterventionByReference: Intervention;
      };
    }>);
    const target = dataSetResult?.data?.getFarmBaselineByReference?.target
      ? dataSetResult?.data?.getFarmBaselineByReference?.target
      : dataSetResult?.data?.getInterventionByReference?.target;
    if (target) {
      footprint.updateCategoryTargets(target);
      forceUpdate();
    }
  };

  const handleActionClick = (
    id: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    anchorEls[id] = event.currentTarget;
    setAnchorEls(anchorEls);
    forceUpdate();
  };

  const loadOrigins = async () => {
    const result = await (API.graphql({
      query: getOrigins,
      variables: {
        customerId: customerID,
      },
    }) as Promise<{
      data: {
        getOrigins: { isoCode: string; name: string }[];
      };
    }>);
    const originsList =
      result?.data?.getOrigins?.map(
        (item: { isoCode: string; name: string }) => ({
          value: item.isoCode,
          text: item.name,
        })
      ) || [];
    setOrigins(originsList);
  };

  useEffect(() => {
    loadOrigins();
  }, [customerID]);

  return (
    <>
      {categoryDetailVisible && (
        <FootprintCategoryDetail
          footprintCategory={footprintCategorySelected}
          formVisible={categoryDetailVisible}
          handleClose={() => setCategoryDetailVisible(false)}
          origins={origins}
        />
      )}
      {personalTargetDialogVisible && (
        <FootprintCategoryTarget
          footprintCategory={footprintCategorySelected}
          formVisible={personalTargetDialogVisible}
          footprint={footprint}
          type={type}
          handleClose={() => setPersonalTargetDialogVisible(false)}
          footprintTargetSuccessHandler={footprintTargetSuccessHandler}
        />
      )}
      <TableContainer>
        <Table aria-label="customized table" className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.CATEGORY',
                })}
              </StyledTableCell>
              <StyledTableCell>&nbsp;</StyledTableCell>
              {!isComparison && (
                <StyledTableCell>
                  {intl.formatMessage({
                    id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.VALUE',
                  })}
                </StyledTableCell>
              )}
              {isComparison && (
                <StyledTableCell>
                  {intl.formatMessage({
                    id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.DATASETA',
                  })}
                </StyledTableCell>
              )}
              {isComparison && (
                <StyledTableCell>
                  {intl.formatMessage({
                    id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.DATASETB',
                  })}
                </StyledTableCell>
              )}
              {isComparison && (
                <StyledTableCell>
                  {intl.formatMessage({
                    id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.CHANGE',
                  })}
                </StyledTableCell>
              )}
              <StyledTableCell>
                {intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.PERSONALTARGET',
                })}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'SUSTELL.DASHBOARD.TARGET.SET_A_TARGET_TOOLTIP',
                  })}
                >
                  <HelpOutline fontSize="medium" />
                </Tooltip>
              </StyledTableCell>
              {!isComparison && (
                <StyledTableCell>
                  {intl.formatMessage({
                    id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.TARGETMATCH',
                  })}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {footprint.categories &&
              footprint.categories.map((row, i) => (
                <StyledTableRow key={row.key}>
                  <StyledTableCell
                    style={{ width: '45%', cursor: 'pointer' }}
                    onClick={() => openCategoryDetail(row)}
                  >
                    <Box style={{ display: 'flex' }}>
                      <FootprintTableIcon
                        image={
                          toAbsoluteUrl(
                            `/media/sustell_15/icons/production_process_icons/${row.compartments[0]}_icon.svg`
                          ) as string
                        }
                        compartment={row.compartments[0]}
                        isIconBackGround
                      />
                      <Box>
                        <Typography
                          className={classes.tableCellTitle}
                          style={{ paddingLeft: '10px', fontWeight: '500' }}
                        >
                          {intl.formatMessage({
                            id: `SUSTELL.FOOTPRINT.${translateKeyMapping(
                              row.key
                            )}`,
                            defaultMessage: row.key,
                          })}
                        </Typography>
                        <Typography
                          className={classes.tableCellDescription}
                          style={{ paddingLeft: '10px', fontWeight: '400' }}
                        >
                          {intl.formatMessage({
                            id: `SUSTELL.FOOTPRINT.DESCRIPTION.${translateKeyMapping(
                              row.key
                            )}`,
                            defaultMessage: row.description,
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      aria-controls={
                        anchorEls[i] ? `category-table-menu-${i}` : undefined
                      }
                      aria-expanded={anchorEls[i] ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleActionClick(i, e)}
                    >
                      <MoreVert />
                    </Button>
                    {anchorEls && anchorEls[i] && (
                      <Menu
                        id={`category-table-menu-${i}`}
                        anchorEl={anchorEls[i]}
                        open={Boolean(anchorEls[i])}
                        onClose={handleActionClose}
                        className={classes.menuStyles}
                      >
                        <MenuItem
                          disabled={
                            // No targets for compound feeds currently
                            footprint.type === FootPrintType.CompoundFeed ||
                            !Can('export', 'Dashboard')
                          }
                          onClick={() => setPersonalTarget(row)}
                        >
                          <TrackChangesRounded />
                          <Typography className={classes.menuItemText}>
                            {intl.formatMessage({
                              id: 'SUSTELL.FOOTPRINT.TABLE.MENU.SET_PERSONAL_TARGET',
                            })}
                          </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => openCategoryDetail(row)}>
                          <Equalizer />
                          <Typography className={classes.menuItemText}>
                            {intl.formatMessage({
                              id: 'SUSTELL.FOOTPRINT.TABLE.MENU.DETAILED_OVERVIEW',
                            })}
                          </Typography>
                        </MenuItem>
                        {/* Commented the due to no functionality enable for this item */}
                        {/* <MenuItem onClick={handleActionClose}>
                          <HelpOutline />
                          <Typography className={classes.menuItemText}>
                            {intl.formatMessage({
                              id: 'SUSTELL.FOOTPRINT.TABLE.MENU.TIPS_TO_REDUCE',
                            })}
                          </Typography>
                        </MenuItem> */}
                      </Menu>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      className={classes.tableCellTitle}
                      style={{ fontWeight: '500' }}
                    >
                      <ExactNumber value={row.amount} />
                    </Typography>
                    <Typography
                      className={classes.tableCellDescription}
                      style={{ fontWeight: '400' }}
                    >
                      {row.unit}
                    </Typography>
                  </StyledTableCell>
                  {isComparison && (
                    <StyledTableCell>
                      <Typography
                        className={classes.tableCellTitle}
                        style={{ fontWeight: '500' }}
                      >
                        <ExactNumber value={row.comparison?.amount} />
                      </Typography>
                      <Typography
                        className={classes.tableCellDescription}
                        style={{ fontWeight: '400' }}
                      >
                        {row.comparison?.unit}
                      </Typography>
                    </StyledTableCell>
                  )}
                  {isComparison && (
                    <StyledTableCell>
                      <Typography
                        className={
                          row.comparisonChangePercentage < 0
                            ? classes.tableSuccessCell
                            : classes.tableErrorCell
                        }
                      >
                        {row.comparisonChangePercentage}%
                      </Typography>
                    </StyledTableCell>
                  )}
                  {row.targetValue && (
                    <StyledTableCell>
                      <Typography
                        className={classes.tableCellTitle}
                        style={{ fontWeight: '500' }}
                      >
                        <ExactNumber value={row.targetValue} />
                      </Typography>
                      <Typography
                        className={classes.tableCellDescription}
                        style={{ fontWeight: '400' }}
                      >
                        {row.unit}
                      </Typography>
                    </StyledTableCell>
                  )}
                  {!row.targetValue && <StyledTableCell>-</StyledTableCell>}
                  {!isComparison && (
                    <StyledTableCell>
                      {row.targetMatch !== undefined && (
                        <Typography
                          className={
                            row.targetMatch < 0 || row.targetMatch === 0
                              ? classes.tableSuccessCell
                              : classes.tableErrorCell
                          }
                        >
                          {row.targetMatch < 0 || row.targetMatch === 0
                            ? intl.formatMessage(
                                {
                                  id:
                                    row.targetMatch < 0
                                      ? 'SUSTELL.FOOTPRINT.TABLE.TARGET_MATCH_UNDER'
                                      : 'SUSTELL.FOOTPRINT.TABLE.TARGET_MATCH_ON_TARGET',
                                },
                                { value: row.targetMatch }
                              )
                            : intl.formatMessage(
                                {
                                  id: 'SUSTELL.FOOTPRINT.TABLE.TARGET_MATCH_OVER',
                                },
                                { value: row.targetMatch }
                              )}
                        </Typography>
                      )}
                      {row.targetMatch === undefined && '-'}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FootprintCategoryTable;
