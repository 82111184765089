import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { MasterDataOption, Maybe } from '../../../../../../graphql/types';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import lookupValues from '../../../../helpers/lookupValues';
import { BaselineFeed } from '../../../../models/Baseline';
import { SalmonBaseline, SalmonFeed } from '../../../../models/Baseline/SalmonBaseline';
import { translateKeyMapping } from '../../../../utils/string-utils';
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';
import { BaselineDialogProps } from '../../common';

interface SFeedsFormDialogProps extends BaselineDialogProps {
  masterDataEnums: MasterDataEnumType;
  compoundFeeds: Maybe<Array<MasterDataOption>>;
}

const SFeedsFormDialog: FC<SFeedsFormDialogProps> = ({
  formVisible,
  itemIndex = 0,
  handleCancel,
  handleSave = handleCancel,
  formType = 'add',
  masterDataEnums,
  compoundFeeds
}) => {

  const intl = useIntl();
  const currResetValue = useRef('');

  const feedItem = { feedType: '', kgPerAnimal: '' };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getValues, setValue, reset, trigger } = useFormContext<SalmonBaseline>();

  const baselineCompoundFeeds = getValues(`stages.${itemIndex}.stageData.feed.compoundFeeds`);
    
  const [compoundFeedData, setCompoundFeedData] = useState<BaselineFeed[]>(
    baselineCompoundFeeds && baselineCompoundFeeds !== null && (baselineCompoundFeeds as BaselineFeed[])?.length > 0
      ? (baselineCompoundFeeds as BaselineFeed[])
      : [{ ...feedItem }]
  );

  useEffect(() => {
    if (formVisible) {
      // to faster deep copy all potential arrays and subobjects
      const serializedData = JSON.stringify(getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  useEffect(()=> {
    setCompoundFeedData((baselineCompoundFeeds || []) as BaselineFeed[]);
  },[baselineCompoundFeeds])

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as SalmonBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.feed) {
        const val = JSON.parse(currResetValue.current) as SalmonFeed;
        resetObject.stages[itemIndex].stageData.feed = val;
        reset(resetObject,{
          errors: true
        });
        // reset compound feeds and single ingredients data
        // to previously valid (confirmed)
        setCompoundFeedData(val?.compoundFeeds || []);
      }
    }
    if (handleCancel) handleCancel();
  };

  const updateTotalFeedIntake = useCallback(() => {
    const currFormValues = getValues();
    const compoundFeeds1 =
      currFormValues.stages?.[itemIndex]?.stageData?.feed?.compoundFeeds;
   
    let totalIntake = 0;
    if (compoundFeeds1) {
      totalIntake += compoundFeeds1.reduce((acc, item) => (
          acc +
          (item.feedType && !Number.isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0)
        ), 0);
    }

    setValue(
      `${fieldItemPrefix  }.totalFeedIntake`,
      totalIntake !== 0 ? totalIntake.toFixed(precision) : ''
    );

    if(formVisible) {
      trigger(`${fieldItemPrefix  }.totalFeedIntake`)
        .then(() => undefined)
        .catch(() => undefined);
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex, fieldItemPrefix, setValue, getValues, precision, formVisible]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  // console.log("DATA ", getTranslatedEnumOrDefaultLookupValues(
  //   masterDataEnums?.NutrientType,
  //   lookupValues.nutrient_type,
  //   intl,
  //   "MASTERDATA.ENUM.NUTRIENT_TYPE", 
  //   false, //keep the order as it comes
  //   true
  // ));

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleCancel}
      iconCode="feedIcon"
      formTitle={formTitle}
      variant="wide"
    >
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            <SustellRationFeeds
              key="compoundFeeds"
              label={intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS'})}
              availableFeedItems={compoundFeeds}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="compoundFeeds"
              updateTotalFeedIntake={updateTotalFeedIntake}
              tooltip={intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP'})}
              formState={formType}
              compoundFeed
              feedData={compoundFeedData}
              setFeedData={setCompoundFeedData}
              animalType="SALMON"
            />
            <Grid item >
              <RowTextFieldWithInfo
                name={`${fieldItemPrefix  }.totalFeedIntake`}
                label={`${intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE',
                })  } *`}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE.TOOLTIP',
                })}
                addornment={intl.formatMessage(
                  { id:  'ADDORNMENT.UNITS.WITH_PER_YEAR_SUFIX' },
                  { unit: barnOutputMassUnit }
                )}
                disabled
                defaultValue = {getValues(`${fieldItemPrefix  }.totalFeedIntake`)}
              />
            </Grid>
            {
              getTranslatedEnumOrDefaultLookupValues(
                masterDataEnums?.NutrientType || [],
                lookupValues.nutrient_type,
                intl,
                "MASTERDATA.ENUM.NUTRIENT_TYPE", 
                false, // keep the order as it comes
                true
              )?.filter( item => item.mandatory === true).map( el => (
                <Grid item key={`${fieldItemPrefix}.mandatoryNutritional_${el.value}`} >
                  <RowTextFieldWithInfo
                    name={ `${fieldItemPrefix}.requiredNutritionalContent.${el.value }` }
                    label={ `${el.display_name } *` }
                    margin="none"
                    variant="outlined"
                    tooltip={ intl.formatMessage({id: `BASELINE.FORM.SALMON.${translateKeyMapping(el.display_name)}.TOOLTIP`, defaultMessage: el.display_name }) } 
                    addornment="%"
                    disabled={formType === "view"}
                    type="number"
                    defaultValue = {getValues(`${fieldItemPrefix}.requiredNutritionalContent.${el.value }`)}
                  />
                </Grid>
              ))
            }

            
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
              {
                getTranslatedEnumOrDefaultLookupValues(
                  masterDataEnums.NutrientType || [],
                  lookupValues.nutrient_type,
                  intl,
                  "MASTERDATA.ENUM.NUTRIENT_TYPE", 
                  false, // keep the order as it comes
                  true
                )?.filter( item => item.mandatory !== true).map( el => (
                  <Grid item key={`${fieldItemPrefix}.optionalNutrition_${el.value}`}>
                    <RowTextFieldWithInfo
                      name={ `${fieldItemPrefix}.optionalNutritionalContent.${el.value }` }
                      label={ el.display_name }
                      margin="none"
                      variant="outlined"
                      tooltip={ intl.formatMessage({id: `BASELINE.FORM.SALMON.${translateKeyMapping(el.display_name)}.TOOLTIP`, defaultMessage: el.display_name }) } 
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                      addornment={ el.value !== 'gross_energy' ? el.unit : `${ userUOM.unitNutritionalGrossEnergy?userUOM.unitNutritionalGrossEnergy:'MJ' }/${ userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg"}` }
                      disabled={formType === "view"}
                      type="number"
                      defaultValue = {getValues(`${fieldItemPrefix}.optionalNutritionalContent.${el.value }`)}
                    />
                  </Grid>
                  ))
              }
              <Grid item >
                <RowTextFieldWithInfo
                  name={`${fieldItemPrefix  }.efcr`}
                  label={intl.formatMessage({id: "BASELINE.FORM.SALMON.ECFR"})}
                  margin="none"
                  variant="outlined"
                  tooltip={intl.formatMessage({id: "BASELINE.FORM.SALMON.EFCR_TT"}, {min: 0.9, max: 2})}
                  addornment="Number"
                  disabled={formType === "view"}
                  type="number"
                  defaultValue = {getValues(`${fieldItemPrefix  }.efcr`)}
                />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default SFeedsFormDialog;