import { IntlShape } from 'react-intl';
import featureFlags from '../utils/featureFlags'
import { AnimalType, StageType } from '../../../graphql/types';

const MARINE_FISH_ANIMALS = [
  AnimalType.SalmonV2,
  AnimalType.SeaBass,
  AnimalType.SeaBream,
];

const POULTRY_ANIMALS = [
  AnimalType.BroilerV2,
  AnimalType.LayingHensV2,
  AnimalType.Turkey,
];

const SHRIMP_ANIMALS = [
  AnimalType.LitopenaeusVannamei,
  AnimalType.PenaeusMonodon,
];

const POULTRY_PROCESSING_ANIMALS = [AnimalType.BroilerV2, AnimalType.Turkey];

// eslint-disable-next-line import/prefer-default-export
export const isPoultryAvailableForProcessing = (animalType?: AnimalType) =>
  !!animalType && POULTRY_PROCESSING_ANIMALS.includes(animalType);

// eslint-disable-next-line import/prefer-default-export
export const isPig = (animalType?: AnimalType): boolean =>
  !!animalType && animalType === AnimalType.Pig;

export const isMarineFish = (animalType?: AnimalType): boolean =>
  !!animalType && MARINE_FISH_ANIMALS.includes(animalType);

export const isBeef = (animalType?: AnimalType): boolean =>
  !!animalType && animalType === AnimalType.Beef;

export const isPoultry = (animalType?: AnimalType): boolean =>
  !!animalType && POULTRY_ANIMALS.includes(animalType);

// eslint-disable-next-line import/prefer-default-export
export const isShrimp = (animalType?: AnimalType): boolean =>
  !!animalType && SHRIMP_ANIMALS.includes(animalType);

export const isDairy = (animalType?: AnimalType): boolean =>
  !!animalType && animalType === AnimalType.DairyV2;

export const isPigFootprint = (animalType: string): boolean =>
  ['Pig', 'Piglet', 'Sow'].includes(animalType);

export const isPoultryFootprint = (animalType: string): boolean =>
  [
    'Turkey broiler',
    'Broiler or pullet',
    'Spent laying hen',
    'Consumption egg',
    'Day old chick',
    'Hatching egg',
    'Spent breeding hen',
    'Spent breeding rooster',
    'Losses',
    'Growing broiler or pullet',
  ].includes(animalType);

export const isBeefFootprint = (animalType: string): boolean =>
  ['cow', 'calf'].includes(animalType.toLowerCase());

type AllowedStageTypes = {
  [key in AnimalType]: Array<StageType | string>;
};

const getBeefStageTypes = () => {
  const beefStages = [StageType.Breeding, StageType.Growing, StageType.Processing];

  return beefStages;
};

const allowedStageTypes = {
  [AnimalType.Beef]: getBeefStageTypes(),
  [AnimalType.Pig]: [
    StageType.Breeding,
    StageType.Fattening,
    StageType.Processing,
  ],
  [AnimalType.BroilerV2]: [
    StageType.Breeding,
    StageType.Hatching,
    StageType.Growing,
    StageType.Processing,
  ],
  [AnimalType.LayingHensV2]: [
    StageType.Breeding,
    StageType.Hatching,
    StageType.Laying,
    StageType.Growing,
  ],
  [AnimalType.Turkey]: [StageType.Growing, StageType.Processing],
  [AnimalType.Dairy]: [
    'Bulls',
    'Calves 1-2 year',
    'Calves <1 year',
    'Dairy cows',
    'Heifers',
  ],
  [AnimalType.DairyV2]: [StageType.DairyCow, StageType.ContinuousGrowing],
  [AnimalType.SalmonV2]: [StageType.Growing, StageType.Processing],
  [AnimalType.SeaBass]: [StageType.Growing, StageType.Processing],
  [AnimalType.SeaBream]: [StageType.Growing, StageType.Processing],
  [AnimalType.LitopenaeusVannamei]: [
    StageType.Hatching,
    StageType.Nursery,
    StageType.Growing,
  ],
  [AnimalType.PenaeusMonodon]: [
    StageType.Hatching,
    StageType.Nursery,
    StageType.Growing,
  ],
} as AllowedStageTypes;

const applyShrimpStageRules = (
  stageTypes: StageType[],
  existingStageTypes?: StageType[]
) => {
  if (existingStageTypes?.length) {
    switch (existingStageTypes[0]) {
      case StageType.Growing:
        return [StageType.Growing];
      case StageType.Nursery:
        return [StageType.Nursery];
      default:
        return stageTypes;
    }
  }
  return stageTypes;
};

const applyStageRulesByAnimalType = (
  animalType: string,
  stageTypes: StageType[],
  existingStageTypes?: StageType[]
): StageType[] => {
  let stageTypesResult: StageType[] = stageTypes;
  if (isShrimp(animalType as AnimalType))
    stageTypesResult = applyShrimpStageRules(stageTypes, existingStageTypes);
  return stageTypesResult;
};

export const filterStageTypesByAnimalType = (
  animalType: AnimalType,
  existingStageTypes?: StageType[]
): Array<StageType | string> =>
  applyStageRulesByAnimalType(
    animalType,
    (allowedStageTypes[animalType] as Array<StageType>) || [],
    existingStageTypes
  );

const getAnimalAnalysisGroup = (
  animalType: string | null,
  intl: IntlShape
): string => {
  switch (animalType) {
    case AnimalType.SalmonV2:
    case AnimalType.SeaBass:
    case AnimalType.SeaBream:
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.SALMON',
      });
    case AnimalType.LitopenaeusVannamei:
    case AnimalType.PenaeusMonodon:
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.SHRIMP',
      });
    default:
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.OTHERS',
      });
  }
};

const getRationAnalysisGroup = (
  animalType: string | null,
  intl: IntlShape
): string => {
  switch (animalType) {
    case AnimalType.SalmonV2:
    case AnimalType.SeaBass:
    case AnimalType.SeaBream:
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.RATION.SALMON',
      });
    case AnimalType.LitopenaeusVannamei:
    case AnimalType.PenaeusMonodon:
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.RATION.SHRIMP',
      });
    default:
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.RATION.OTHERS',
      });
  }
};

export const getAdjustedLabel = (
  analysisGroup: string,
  animal: string | null,
  intl: IntlShape
) => {
  switch (analysisGroup) {
    case 'Purchased animals':
    case 'Animal inputs':
      return getAnimalAnalysisGroup(animal, intl);
    case 'Ration':
    case 'Feed':
      return getRationAnalysisGroup(animal, intl);
    case 'Emissions':
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.FARM',
      });
    case 'Ingredients':
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FEED.INGREDIENTS_INBOUND_TRANSPORT',
      });
    case 'Transport':
      return intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.FEED.OUTBOUND_TRANSPORT',
      });
    default:
      return analysisGroup.replaceAll('_', ' ');
  }
};

// List of values to exclude
const getExcludedValues = () => {
  const excludeValues = [
    AnimalType.Salmon, 
    AnimalType.Broiler, 
    AnimalType.PigFattening, 
    AnimalType.LayingHens
  ];

  if (featureFlags.dairyV2) {
    return [...excludeValues, AnimalType.Dairy];
  }

  return [...excludeValues, AnimalType.DairyV2];
}

// Create selectOptions array with custom text based on requirements
export const selectOptions = Object.entries(AnimalType)
  .filter(([, value]) => !getExcludedValues().includes(value))
  .map(([key, value]) => {
    let text = key;
    const isShrimpType = isShrimp(value);

    // Customize Shrimp animals names
    if (isShrimpType) {
      text = `Shrimp - ${text}`;
    }
    // Customize text based on specific conditions
    if (key.endsWith('V2')) {
      text = text.slice(0, -2); // Remove 'V2' from keys
    }
    if (/([a-z])([A-Z])/.test(key)) {
      // Insert space before each uppercase letter in camelCase keys
      text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    // Remove s from text
    if (text === 'Laying Hens') {
      text = 'Laying Hen';
    }
    return {
      text,
      value,
    };
  });
