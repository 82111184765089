import React, { FC } from 'react';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';

import {
  PrimaryPackagingType,
  SecondaryPackagingType,
  TertiaryPackagingType,
} from '../../../../../../graphql/types';
import { FormType } from '../../common';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import {
  Packaging as BaselinePackaging,
  ProcessingStageData,
  ProcessingPackagingItem,
} from '../../../../models/Facility/Processing';
import RowSelectWithMetricsV2 from '../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2';
import { PigBaseline } from '../../../../models/Baseline/PigBaseline';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

interface PackagingForOutputCategoryProps {
  stageIndex: number;
  intlPart: string;
  outputCategory: string;
  formType: FormType;
  index?: number;
  isAddition?: boolean;
  barnOutputMassUnit: string;
  removeHandler?: (index?: number | number[]) => void;
  baseline: PigBaseline;
}

const SHInterventionPackagingForOutputCategory: FC<
  PackagingForOutputCategoryProps
> = ({
  stageIndex,
  outputCategory,
  intlPart,
  formType,
  index,
  isAddition = false,
  baseline,
  barnOutputMassUnit,
  removeHandler,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.processingPackaging.${outputCategory}`;

  const packagingBaseline = (
    baseline?.stages?.at(stageIndex)?.stageData as ProcessingStageData
  )?.processingPackaging;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const getBaselinePackaging = (
    outputType: string
  ): BaselinePackaging | undefined | null =>
    Object.entries(packagingBaseline)
      .find(([key]) => key === outputType)
      ?.at(1);

  const {
    fields: additionalPrimaryPackaging,
    append: appendAdditionalPrimaryPackaging,
    remove: removeAdditionalPrimaryPackaging,
  } = useFieldArray({
    name: `${fieldItemPrefix}.primaryAdditions`,
    control: formContext.control,
    keyName: 'keyId',
  });
  const {
    fields: additionalTertiaryPackaging,
    append: appendAdditionalTertiaryPackaging,
    remove: removeAdditionalTertiaryPackaging,
  } = useFieldArray({
    name: `${fieldItemPrefix}.tertiaryAdditions`,
    control: formContext.control,
    keyName: 'keyId',
  });
  const outputCategoryMsg = intl.formatMessage({
    id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.${intlPart}`,
  });
  return (
    <>
      {formType !== FormType.View && isAddition && (
        <DsmButton
          variant="text"
          style={{ position: 'relative', width: '100%' }}
          onClick={() => {
            if (removeHandler) removeHandler(index);
          }}
        >
          <DsmIcon
            name="general/x-close"
            style={{
              position: 'absolute',
              color: 'var(--dsm-color-neutral-darker',
              right: '0',
            }}
          />
        </DsmButton>
      )}
      <b>{outputCategoryMsg}</b> <br />
      {getBaselinePackaging(outputCategory)?.primary?.length &&
        getBaselinePackaging(outputCategory)?.primary?.map(
          (
            item: ProcessingPackagingItem<PrimaryPackagingType>,
            ind: number
          ) => (
            <>
              {formType !== FormType.View && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => removeAdditionalPrimaryPackaging(ind)}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn2To3}>
                <RowSelectWithMetricsV2
                  name={`${fieldItemPrefix}.primary[${ind}].type`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PRIMARY_PACKAGING_TYPE',
                  })}
                  disabled
                  options={enumToOptionsArrayWithTranslatedStrings(
                    PrimaryPackagingType as object,
                    intl,
                    'SUSTELL.TRANSPORT_MODE.'
                  )}
                  baseline={item?.type}
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.primary[${ind}].type`
                  )}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.primary[${ind}].amount`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PACKAGING_AMOUNT',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PACKAGING_AMOUNT',
                  })}
                  metricUnit={intl.formatMessage({
                    id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING.AMOUNT.UNIT',
                  })}
                  baseline={item?.amount}
                  disabled={formType === FormType.View}
                />
              </DsmGrid>
            </>
          )
        )}
      {additionalPrimaryPackaging.map((item, ind) => (
        <React.Fragment key={`primary_pckg_${item.keyId || ''}`}>
          {formType !== FormType.View && (
            <DsmButton
              variant="text"
              style={{ position: 'relative', width: '100%' }}
              onClick={() => removeAdditionalPrimaryPackaging(ind)}
            >
              <DsmIcon
                name="general/x-close"
                style={{
                  position: 'absolute',
                  color: 'var(--dsm-color-neutral-darker',
                  right: '0',
                }}
              />
            </DsmButton>
          )}
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <ReactHookDsmSelect
              name={`${fieldItemPrefix}.primaryAdditions.${ind}.type`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PRIMARY_PACKAGING_TYPE',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
              })}
              options={enumToOptionsArrayWithTranslatedStrings(
                PrimaryPackagingType,
                intl,
                'SUSTELL.TRANSPORT_MODE.'
              )}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PRIMARY_PACKAGING_TYPE.TT',
              })}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.primaryAdditions.${ind}.type`
                ) as string
              }
            />
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.primaryAdditions[${ind}].amount`}
              label={intl.formatMessage({
                id: 'SUSTELL.AMOUNT',
              })}
              type="number"
              placeholder={barnOutputMassUnit}
              disabled={formType === FormType.View}
              adornment={barnOutputMassUnit}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.primaryAdditions.${ind}.amount`
                ) as string
              }
            />
          </DsmGrid>
        </React.Fragment>
      ))}
      <DsmButton
        variant="text"
        disabled={formType === FormType.View}
        style={{ width: 'fit-content' }}
        onClick={() =>
          appendAdditionalPrimaryPackaging({
            type: '',
            amount: '',
          })
        }
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage(
          { id: 'SUSTELL.STAGES.PROCESSING.ADD_FOR_ANOTHER' },
          { tier: 'primary' }
        )}
      </DsmButton>
      <br />
      {getBaselinePackaging(outputCategory)?.secondary?.type && (
        <DsmGrid className={classes.dsmGridTwoColumn2To3}>
          <RowSelectWithMetricsV2
            name={`${fieldItemPrefix}.secondary.type`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.SECONDARY_PACKAGING_TYPE',
            })}
            disabled
            options={enumToOptionsArrayWithTranslatedStrings(
              SecondaryPackagingType,
              intl,
              'SUSTELL.TRANSPORT_MODE.'
            )}
            baseline={getBaselinePackaging(outputCategory)?.secondary?.type}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.secondary.type`
            )}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.secondary.amount`}
            label={intl.formatMessage({
              id: 'SUSTELL.AMOUNT',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PACKAGING_AMOUNT',
            })}
            metricUnit={intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING.AMOUNT.UNIT',
            })}
            baseline={getBaselinePackaging(outputCategory)?.secondary?.amount}
          />
        </DsmGrid>
      )}
      {!getBaselinePackaging(outputCategory)?.secondary?.type && (
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.secondaryAdditions[0].type`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.SECONDARY_PACKAGING_TYPE',
            })}
            placeholder={intl.formatMessage({
              id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
            })}
            disabled={formType === FormType.View}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
            })}
            options={[
              {
                text: intl.formatMessage({
                  id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.EMPTY_PACKAGING',
                }),
                value: '',
              },
              ...enumToOptionsArrayWithTranslatedStrings(
                SecondaryPackagingType,
                intl,
                'SUSTELL.TRANSPORT_MODE.'
              ),
            ]}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.secondaryAdditions[0].type`
              ) as string
            }
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.secondaryAdditions[0].amount`}
            label={intl.formatMessage({
              id: 'SUSTELL.AMOUNT',
            })}
            type="number"
            placeholder={barnOutputMassUnit}
            disabled={formType === FormType.View}
            adornment={barnOutputMassUnit}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.secondaryAdditions[0].amount`
              ) as string
            }
          />
        </DsmGrid>
      )}
      {getBaselinePackaging(outputCategory)?.tertiary?.length &&
        getBaselinePackaging(outputCategory)?.tertiary?.map(
          (
            item: ProcessingPackagingItem<TertiaryPackagingType>,
            ind: number
          ) => (
            <>
              {formType !== FormType.View && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => removeAdditionalTertiaryPackaging(ind)}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn2To3}>
                <RowSelectWithMetricsV2
                  name={`${fieldItemPrefix}.tertiary[${ind}].type`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.TERTIARY_PACKAGING_TYPE',
                  })}
                  disabled
                  options={enumToOptionsArrayWithTranslatedStrings(
                    TertiaryPackagingType,
                    intl,
                    'SUSTELL.TRANSPORT_MODE.'
                  )}
                  baseline={item?.type}
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.tertiary[${ind}].type`
                  )}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.tertiary[${ind}].amount`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PACKAGING_AMOUNT',
                  })}
                  disabled={formType === FormType.View}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PACKAGING_AMOUNT',
                  })}
                  metricUnit={intl.formatMessage({
                    id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING.AMOUNT.UNIT',
                  })}
                  baseline={item?.amount}
                />
              </DsmGrid>
            </>
          )
        )}
      {additionalTertiaryPackaging?.map((item, pckgIndex) => (
        <React.Fragment key={item.keyId}>
          {formType !== FormType.View && pckgIndex !== 0 && (
            <DsmButton
              variant="text"
              style={{ position: 'relative', width: '100%' }}
              onClick={() => removeAdditionalTertiaryPackaging(pckgIndex)}
            >
              <DsmIcon
                name="general/x-close"
                style={{
                  position: 'absolute',
                  color: 'var(--dsm-color-neutral-darker',
                  right: '0',
                }}
              />
            </DsmButton>
          )}
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <ReactHookDsmSelect
              name={`${fieldItemPrefix}.tertiaryAdditions[${pckgIndex}].type`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.TERTIARY_PACKAGING_TYPE',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
              })}
              options={enumToOptionsArrayWithTranslatedStrings(
                TertiaryPackagingType,
                intl,
                'SUSTELL.TRANSPORT_MODE.'
              )}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.TERTIARY_PACKAGING_TYPE.TT',
              })}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.tertiaryAdditions[${pckgIndex}].type`
                ) as string
              }
            />
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.tertiaryAdditions[${pckgIndex}].amount`}
              label={intl.formatMessage({
                id: 'SUSTELL.AMOUNT',
              })}
              type="number"
              placeholder={barnOutputMassUnit}
              disabled={formType === FormType.View}
              adornment={barnOutputMassUnit}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.tertiaryAdditions[${pckgIndex}].amount`
                ) as string
              }
            />
          </DsmGrid>
        </React.Fragment>
      ))}
      <DsmButton
        variant="text"
        disabled={formType === FormType.View}
        style={{ width: 'fit-content' }}
        onClick={() =>
          appendAdditionalTertiaryPackaging({
            type: '',
            amount: '',
          })
        }
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage(
          { id: 'SUSTELL.STAGES.PROCESSING.ADD_FOR_ANOTHER' },
          { tier: 'tertiary' }
        )}
      </DsmButton>
    </>
  );
};

export default SHInterventionPackagingForOutputCategory;
