/* eslint-disable no-param-reassign */
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import { floatValueInUserUnits } from "../../Baseline/mapper/inCommonMapperSustell";
import { defaultUnitsV2 as defaultMetric, explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';
import { OUTPUT_PACKAGING_PROPS, TRANSPORT_DISTANCE_UOM_MAP } from "../../Baseline/mapper/outProcessingMapperSustell";

const mapProcessingInput = (
  stageDataInputObj,
  formStageDataInputPart
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const formInputData = formStageDataInputPart;

  if (stageDataInputObj?.quantity) {
    formInputData.quantity = stageDataInputObj.quantity.value;
    formInputData.quantity_changeMetric = stageDataInputObj.quantity.change_type;
  }

  if (stageDataInputObj?.transport?.length) {
    formInputData.transport = stageDataInputObj.transport.map(item => ({
        transportMode: item.transportMode,
        distance: floatValueInUserUnits(
          item.distance.value,
          defaultMetric[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]],
          userUOM[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]],
        ),
        distance_changeMetric: item.distance.change_type
     }));
  }

  if (stageDataInputObj?.transportAdditions?.length) {
    formInputData.transportAdditions = stageDataInputObj.transportAdditions.map(item => ({
      transportMode: item.transportMode,
      distance: explicitConvertValue(
        item.distance,
        defaultMetric[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]],
        userUOM[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]],
      )
    }) );
  }
    
}

const mapProcessingOutput = (
  stageDataOutputObj,
  formStageDataOutputPart
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  OUTPUT_PACKAGING_PROPS.forEach((prop) => {
    if (stageDataOutputObj?.[prop]) {
      formStageDataOutputPart[prop] = {};
      if (
        stageDataOutputObj?.[prop]?.weight?.value ||
        stageDataOutputObj?.[prop]?.weight?.value === 0
      ) {

        formStageDataOutputPart[prop].weight = floatValueInUserUnits(
          stageDataOutputObj?.[prop]?.weight.value,
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass,
        );

        formStageDataOutputPart[prop].weight_changeMetric
           = stageDataOutputObj?.[prop]?.weight.change_type;

      }

      if (
        stageDataOutputObj?.[prop]?.price?.value ||
        stageDataOutputObj?.[prop]?.price?.value === 0
      ) {

        formStageDataOutputPart[prop].price = floatValueInUserUnits(
          stageDataOutputObj?.[prop]?.price.value
        );

        formStageDataOutputPart[prop].price_changeMetric
           = stageDataOutputObj?.[prop]?.price.change_type;

      }
    }
  });
}

const mapInterventionPackagingItem = (dbPackagingItem) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const formPackagingItem = {
    type: dbPackagingItem.type,
    amount: floatValueInUserUnits(
      dbPackagingItem.amount.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass,
    ),
    amount_changeMetric: dbPackagingItem.amount.change_type
  }

  return formPackagingItem;
}

const mapPackagingSet = (
  stageDataPackagingObj,
  formStageDataPackagingPart
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (stageDataPackagingObj.primary?.length) {
    formStageDataPackagingPart.primary = stageDataPackagingObj.primary
    .map((item) =>  mapInterventionPackagingItem(item));
  }

  if (stageDataPackagingObj.secondary?.length) {
    formStageDataPackagingPart.secondary = mapInterventionPackagingItem(stageDataPackagingObj.secondary[0])
  }

  if (stageDataPackagingObj.tertiary?.length) {
    formStageDataPackagingPart.tertiary = stageDataPackagingObj.tertiary
    .map((item) =>  mapInterventionPackagingItem(item));
  }

  if (stageDataPackagingObj.primaryAdditions?.length) {
    formStageDataPackagingPart.primaryAdditions = stageDataPackagingObj.primaryAdditions
    .map((item) =>  ({
      type: item.type,
      amount: explicitConvertValue(
        item.amount,
        defaultMetric.unitBarnOutputMass,
        userUOM.unitBarnOutputMass,
      )
    }));
  }

  if (stageDataPackagingObj.secondaryAdditions?.length) {
    formStageDataPackagingPart.secondaryAdditions = stageDataPackagingObj.secondaryAdditions
    .map((item) =>  ({
      type: item.type,
      amount: explicitConvertValue(
        item.amount,
        defaultMetric.unitBarnOutputMass,
        userUOM.unitBarnOutputMass,
      )
    }));
  }

  if (stageDataPackagingObj.tertiaryAdditions?.length) {
    formStageDataPackagingPart.tertiaryAdditions = stageDataPackagingObj.tertiaryAdditions
    .map((item) =>  ({
      type: item.type,
      amount: explicitConvertValue(
        item.amount,
        defaultMetric.unitBarnOutputMass,
        userUOM.unitBarnOutputMass,
      )
    }));
  }
}

const mapProcessingPackaging = (
  stageDataPackagingObj,
  formStageDataPackagingPart) => {
    OUTPUT_PACKAGING_PROPS.forEach((prop) => {
    if (stageDataPackagingObj?.[prop]) {
      formStageDataPackagingPart[prop] = {};
      mapPackagingSet(stageDataPackagingObj[prop], formStageDataPackagingPart[prop]);
    }
  });
}

const mapProcessingInStage = (
  stageDataObj,
  formStageData
  ) => {

    if (stageDataObj.processingInput) {
      mapProcessingInput(stageDataObj.processingInput, formStageData.processingInput)
    }
    if (stageDataObj.processingOutput) {
      mapProcessingOutput(stageDataObj.processingOutput, formStageData.processingOutput)
    }
    if (stageDataObj.processingPackaging) {
      mapProcessingPackaging(stageDataObj.processingPackaging, formStageData.processingPackaging)
    }

    return formStageData;
}

export default mapProcessingInStage;