import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import InfoToolTip from "../../Helpers/InfoToolTip";
import { resolvePath } from "../../Helpers/resolvePathFn";
// import { useIntl } from "react-intl";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";


const SustellTotalIntakeTextField = (props) => {

  const{ name, label, addornment, tooltip, metricUnit, baseline, disabled, defaultValue='', precision=2} = props;
  let { margin, variant, type } = props;

  const { control, errors } = useFormContext();
  const intl = useIntl();
  const numOldValue = !Number.isNaN(parseFloat(baseline)) ? parseFloat(baseline) : null;
  const localError = resolvePath(errors, name, null);
  
  if (!margin)
    margin = 'normal';
  if (!variant)
    variant = 'outlined';
  if (!type)
    type = 'text';


  return (
    <>
      <Grid container spacing={1} style={{marginBottom:0}}>
        <Grid item xs={11}>
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value, ref }) => (
              <>
                <TextField
                  style={{marginTop:0}}
                  label={label}
                  margin={margin}
                  variant={variant}
                  value={value}

                  fullWidth
                  inputRef={ref}
                  type={type}
                  error={localError ? true : false}
                  helperText={localError ? (localError.message) : ''}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{addornment}</InputAdornment>,
                  }}
                />
                <div>{
                  intl.formatMessage({id: "INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE" })} 
                  <strong>{numOldValue ? numOldValue.toFixed(precision)+" "+( metricUnit || "kg per animal" ): " - "}</strong> 
                </div>
              </>
            )
            }
          />
        </Grid>
        <Grid item xs={1} pr={0} className="d-flex" style={{ paddingTop: '20px' }}>
          <InfoToolTip tooltip={tooltip} />
        </Grid>
      </Grid>
    </>
  );
};
export default SustellTotalIntakeTextField;