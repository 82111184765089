import clsx from 'clsx';
import { FC } from 'react';

import { Box, LinearProgress, Typography } from '@material-ui/core';

import { processAndStageStyles } from '../../../../_metronic/layout';
import { IconProvider } from '../helpers/IconProvider';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

export interface StageButtonBoxProps {
  iconCode: string;
  title?: string;
  titleCode: string;
  completness?: number;
  error: any;
  handleOpen: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  showCompletness?: boolean;
  optional?: boolean;
}

const StageButtonBox: FC<StageButtonBoxProps> = ({
  iconCode,
  title,
  titleCode = 'TITLE.PLACEHOLDER',
  completness = 0,
  error,
  handleOpen,
  disabled = false,
  showCompletness = true,
  optional = false,
}) => {
  const intl = useIntl();
  const classes = processAndStageStyles() as any;
  if (!title)
    title = intl.formatMessage({ id: titleCode }, { br: <br /> }) as string;

  return (
    <Box
      className={clsx(
        disabled ? classes.stageBtnBoxDisabled : classes.stageBtnBox,
        !showCompletness || completness === 100 ? 'completed' : '',
        error ? 'error' : ''
      )}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      onClick={(e) => handleOpen(e)}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ height: '85px' }}
      >
        <Typography variant="h5" className={classes.stageBtnTitle}>
          {title}
        </Typography>
        <IconProvider iconCode={iconCode} height='50px' />
      </Box>
      {showCompletness && !disabled && (
        <Box display="flex" flexDirection="column" justifyContent="flex-end">
          <Typography variant="body1" className={classes.mediumProgressText}>
            {!optional && (
              <>
                {completness}% {intl.formatMessage({ id: 'SUSTELL.COMPLETE' })}
              </>
            )}
            {optional && (
              <>
                {intl.formatMessage({
                  id: 'SUSTELL.PROCESS.DIALOG.STAGE.OPTIONAL',
                })}
              </>
            )}
          </Typography>
          {!optional && (
            <LinearProgress
              variant="determinate"
              value={completness}
              className={classes.whiteProgress}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default StageButtonBox;
