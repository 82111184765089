/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import { DsmFieldset } from '@dsm-dcs/design-system-react';

import { Grid, Box } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { translateKeyMapping } from '../../utils/string-utils';
import { unitLong } from '../../utils/unit-utils';
import { FormType, UserProfileAlias } from './types';
import { CompoundFeedInputComponentsData } from '../../../../graphql/types';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { isFeedV2CFDatabaseFoundation, masterDataV2 } from './utils';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import { CFDatabaseFoundation } from '../../models/CompoundFeed';

interface OutboundTransportProps {
  formType: FormType;
  compoundFeedInputData: CompoundFeedInputComponentsData;
  userProfile: UserProfileAlias;
  databaseFoundation: CFDatabaseFoundation | undefined;
}

const OutboundTransport: FC<OutboundTransportProps> = ({
  formType,
  compoundFeedInputData,
  userProfile,
  databaseFoundation,
}) => {
  const intl = useIntl();
  let outboundTransportTypes =
    compoundFeedInputData?.outbound_transport_types || [];
  if (isFeedV2CFDatabaseFoundation(databaseFoundation)) {
    outboundTransportTypes = masterDataV2.outbound_transport_types;
  }
  const { control } = useFormContext();
  // TODO: userUOM, translateKeyMapping, unitLong until converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const feedMillLocationCode = useWatch({
    control,
    name: `feedmill_location.iso_code`,
    defaultValue: '',
  });

  return (
    <Grid item container spacing={1} xs={12} style={{ marginTop: 30 }}>
      <Grid item container xs={12} direction="column">
        <Box mb={1} fontSize={17} fontWeight={700}>
          {intl.formatMessage({ id: 'COMPOUNDFEEDS.OUTBOUND_TRANSPORT.TITLE' })}
        </Box>
        <Box fontSize={16} mb={3} fontWeight={400}>
          {intl.formatMessage({
            id: 'COMPOUNDFEEDS.OUTBOUND_TRANSPORT.DESCRIPTION',
          })}
        </Box>
      </Grid>
      <Grid item container xs={12} direction="row">
        {outboundTransportTypes?.map((item: string) => {
          const label = item.charAt(0).toUpperCase() + item.slice(1);
          let formatedLabel = intl.formatMessage({
            id: `COMPOUND_FEED.TRANSPORT.${translateKeyMapping(label)}`,
            defaultMessage: label,
          })
          formatedLabel = formatedLabel.length > 25 ? `${formatedLabel.substring(0, 25)}.` : formatedLabel;
          let addornment = userUOM.unitTransportDistanceTerrestrial;
          if (item === 'seaship' || item === 'sea ship')
            addornment = userUOM.unitTransportDistanceSea;
          else if (item === 'inland ship' || item === 'inland waterways ship')
            addornment = userUOM.unitTransportDistanceInlandWaterways;
          return (
            <Grid
              item
              container
              xs={3}
              direction="column"
              key={`out_transport_${label}`}
            >
              <DsmFieldset style={{ width: '85%' }}>
                <ReactHookDsmInput
                  name={`outbound_transport_input.${item.replace(/ /g, '_')}`}
                  label={formatedLabel}
                  type="number"
                  disabled={formType === 'view' ? true : !feedMillLocationCode}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  adornment={unitLong(addornment)}
                  size="medium"
                />
              </DsmFieldset>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default OutboundTransport;
