/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { Maybe, StageType } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

interface PoultryInputPartExternalSourceProps {
  stageIndex: number;
  itemIndex?: number;
  propName: string;
  formType: FormType;
  intlPrefix: string;
  removeHandler?: Maybe<(index: number) => void>;
  sourceSelected: boolean;
  smallUnits?: boolean;
  stageType: StageType;
}

const PoultryInputPartExternalSource: FC<
  PoultryInputPartExternalSourceProps
> = ({
  stageIndex = 0,
  itemIndex,
  propName = 'externalSources',
  intlPrefix,
  formType,
  removeHandler,
  sourceSelected,
  smallUnits = false,
  stageType
}) => {
  const intl = useIntl();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const barnSmallQuantityUnits = unitLong(
    userUOM.unitBarnSmallQuantityUnits as string
  );
  const distanceUnit = unitLong(
    userUOM.unitTransportDistanceTerrestrial as string
  );
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();
  const fieldItemPrefix =
    itemIndex || itemIndex === 0
      ? `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`
      : `stages.${stageIndex}.stageData.input.${propName}`;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={fc.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && itemIndex !== undefined && itemIndex !== null && itemIndex !== 0 && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
            disabled={!sourceSelected}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberAnimals`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE`,
          })}
          adornment={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE.ADORNMENT`,
          })}
          disabled={formType === FormType.View || !sourceSelected}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE.TOOLTIP`,
          })}
          required
          type="number"
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.numberAnimals`) as string | number
          }
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT`,
          })}
          adornment={smallUnits ? barnSmallQuantityUnits : barnOutputMassUnit}
          disabled={formType === FormType.View || !sourceSelected}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT.TOOLTIP`,
          })}
          required
          type="number"
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.averageWeight`) as string | number
          }
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.transportDistance`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.DISTANCE',
          })}
          disabled={formType === FormType.View || !sourceSelected}
          adornment={distanceUnit}
          type="number"
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.DISTANCE.TOOLTIP`,
          },
          { stageType: stageType.toLocaleLowerCase()})}
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.transportDistance`) as
              | string
              | number
          }
        />
      </DsmGrid>
    </>
  );
};

export default PoultryInputPartExternalSource;
