import { FC } from 'react';

import { Grid, Tooltip } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmFieldset, DsmIcon } from '@dsm-dcs/design-system-react';
import {
  FeedFormFields,
  FormType,
  IngredientField,
  IngredientNamesAndOriginsV2,
  UserProfileAlias,
} from '../types';
import { translateKeyMapping } from '../../../utils/string-utils';
import { unitLong } from '../../../utils/unit-utils';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';

import ReactHookDsmInput from '../../../../modules/Helpers/ReactHookDsmInput2';
import { getCountryLabel } from '../utils';
import { CFDatabaseFoundation } from '../../../models/CompoundFeed';
import { DSM_DISABLED_LABEL } from '../../../../../_metronic/layout';

interface InboundTransportInputGroupProps {
  formType: FormType;
  value: { name?: string | null; origin?: string; ingredientId?: string };
  // eslint-disable-next-line react/require-default-props
  databaseFoundation?: CFDatabaseFoundation | undefined;
  index: number;
  inbound_transport_types: string[] | [];
  getDefaultDistanceHandler: (
    ingredientId: string,
    ingredientOrigin: string,
    feedMillLocationCode: string,
    index: number
  ) => void;
  userProfile: UserProfileAlias;
  field: IngredientField;
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2;
}

const InboundTransportInputGroup: FC<InboundTransportInputGroupProps> = ({
  formType,
  value,
  index,
  databaseFoundation,
  inbound_transport_types,
  getDefaultDistanceHandler,
  userProfile,
  field,
  ingredientOriginsNamesV2,
}) => {
  const intl = useIntl();
  const { control } = useFormContext<FeedFormFields>();
  // TODO: userUOM until converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const feedMillLocationCode = useWatch({
    control,
    name: `feedmill_location.iso_code`,
    defaultValue: '',
  });

  const ingredientOrigin = useWatch({
    control,
    name: `ingredient_uses.${index}.origin`,
    defaultValue: '',
  });

  const ingredientId = useWatch({
    control,
    name: `ingredient_uses.${index}.name`,
    defaultValue: '',
  });

  const disabled = !(
    ingredientOrigin &&
    ingredientOrigin !== 'NaN' &&
    ingredientId &&
    feedMillLocationCode
  );

  const labelDisabled =
    formType === FormType.View ? { color: DSM_DISABLED_LABEL as string } : {};

  return (
    <Grid container direction="row" spacing={1}>
      <Grid
        item
        container
        xs={12}
        direction="column"
        justifyContent="flex-start"
        style={{ paddingTop: 12 }}
      >
        <strong style={labelDisabled}>
          {index + 1}. {value.name},{' '}
          {getCountryLabel(
            ingredientOrigin,
            databaseFoundation,
            intl
          )}
        </strong>
      </Grid>
      <Grid container style={{ justifyContent: 'space-between' }}>
        {inbound_transport_types.map((item) => {
          let label = item.charAt(0).toUpperCase() + item.slice(1);
          let fieldVal;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          let addornment = unitLong(userUOM.unitTransportDistanceTerrestrial);
          if (
            item === 'seaship' ||
            item === 'sea ship' ||
            item === 'sea shipping'
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            addornment = unitLong(userUOM.unitTransportDistanceSea);
            label = intl.formatMessage({
              id: 'COMPOUND_FEED.TRANSPORT.SEA_SHIPPING',
            });
            fieldVal = field.inbound_transport_input?.sea_ship;
          } else if (
            item === 'inland waterways ship' ||
            item === 'inland ship'
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            addornment = unitLong(userUOM.unitTransportDistanceInlandWaterways);
            label = intl.formatMessage({
              id: 'COMPOUND_FEED.TRANSPORT.INLAND_WATERWAYS_SHIP',
            });
            fieldVal = field.inbound_transport_input?.inland_ship;
          } else if (item === 'train') {
            label = intl.formatMessage({
              id: `COMPOUND_FEED.TRANSPORT.${translateKeyMapping(item)}`,
            });
            fieldVal = field.inbound_transport_input?.train;
          } else {
            label = intl.formatMessage({
              id: `COMPOUND_FEED.TRANSPORT.${translateKeyMapping(item)}`,
            });
            fieldVal = field.inbound_transport_input?.truck;
          }

          label = label.length > 25 ? `${label.substring(0, 25)}.` : label;

          const formFieldName = `ingredient_uses.${index}.inbound_transport_input.${item.replace(
            / /g,
            '_'
          )}`;

          return (
            <DsmFieldset key={item} style={{ width: '21%' }}>
              <ReactHookDsmInput
                InputProps={{ width: '384px' }}
                defaultValue={fieldVal}
                name={formFieldName}
                label={label}
                type="number"
                disabled={formType === FormType.View}
                adornment={addornment}
                size="medium"
              />
            </DsmFieldset>
          );
        })}
        {formType !== FormType.View && (
          <Grid
            item
            style={{ alignSelf: 'center' }}
            key={`inboundDistanceTooltip-${index}`}
          >
            <Tooltip
              title={
                !disabled
                  ? intl.formatMessage({
                      id: 'COMPOUND_FEED.INBOUND_TRANSPORT.CALCULATE_DISTANCE',
                    })
                  : ''
              }
              style={{ justifyContent: 'start' }}
            >
              <span>
                <DsmIcon
                  name="education/calculator"
                  onClick={() =>
                    !disabled &&
                    getDefaultDistanceHandler(
                      ingredientId,
                      ingredientOrigin,
                      feedMillLocationCode,
                      index
                    )
                  }
                  style={{
                    marginLeft: '-20px',
                    color: '#0070BA',
                    height: '32px',
                    width: '32px',
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default InboundTransportInputGroup;
