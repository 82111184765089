import { FC } from 'react';

import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { StageType } from '../../../../../../graphql/types';
import lookupValues from '../../../../helpers/lookupValues';

type MMSDescriptionDialogProps = {
  open: boolean;
  stageType: StageType.Breeding | StageType.Growing | StageType.Laying,
  handleClose: () => void;
};

// a static dialog loading and showing descirption text for MMS Systems
const PoultryMMSDescriptionDialog: FC<MMSDescriptionDialogProps> = ({
  open=false,
  stageType,
  handleClose
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.POULTRY.MMS.DESCRIPTIONS.TITLE"}) ;
  const formRefs = intl.formatMessage({"id": "SUSTELL.PROCESS.MMS.DESCRIPTIONS.REFS"}) ;
  const prefix="SUSTELL.PROCESS.POULTRY.MMS.DESCRIPTIONS";

  // list of descritption item keys
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const items: string[] | undefined = lookupValues.manureManagementDescriptionsPoultry[stageType];
  return (
    <DialogContainer
      formVisible = { open }
      variant = 'descriptionbox'
      handleClose = { handleClose }
      withIcon={false}
      formTitle = { formTitle }
      introText = { formRefs }
    > 
      <DsmGrid className={classes.dsmGridTwoColumn_2_3_NoRowSpacingWithLines} style={{overflow: 'unset'}}>
        <div><h6>{ intl.formatMessage({id: "BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM"}) }</h6></div>
        <div><h6>{ intl.formatMessage({id: "GENERAL.DESCRIPTION"}) }</h6></div>
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumn_2_3_NoRowSpacingWithLines} style={{overflow: 'unset'}}>
        {
          items && items.map( item => 
            <>
              <div><strong>{ intl.formatMessage({id: `${prefix}.${item}.TITLE`},{br: <br/>}) }</strong></div>
              <div>{ intl.formatMessage({id: `${prefix}.${item}.DESCRIPTION`},{br: <br/>})  }</div>
            </>
          )
        }
      </DsmGrid>
    </DialogContainer>
	);
};

export default PoultryMMSDescriptionDialog;