import { ThemeProvider } from "@material-ui/core";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import dsmTheme from "../MUITheme";
import { NotificationData } from "./NotificationData";
import { listAllNotifications } from '../../../graphql/queries';

export function Notifications() {
  const [notificationList, setNotificationList] = useState([]);
  const [activePage, setActivePage] = useState(0);

  const onItemAdded = (item, rowsPerPage) => {
    if(item){
      let newList = [...notificationList];
      newList.push(item);
      // console.log("Item added", newList);
      newList.sort( itemSorter );
      let currentIndex = newList.indexOf( el => el.id === item.id );
      //set correct table page
      setActivePage(Math.floor(currentIndex / rowsPerPage));
      setNotificationList(newList);
    }
  }

  const itemSorter = (a,b) => {
    return (a.publishAt < b.publishAt) ?  1 : ((a.publishAt > b.publishAt) ? -1 : 0) ;
  };

  useEffect( () => {
    const listNotifications = async () => {
      try {    
        const result = await API.graphql({
          query: listAllNotifications
        });
        //console.log("RECEVIED NOTIFICATION DATA " + JSON.stringify(result, null, 2));
        let newList = result.data?.listAllNotifications?.items?.filter(n => n.notificationType === "Notification").map( item => 
          { 
            // console.log("TARGET GROUPS: ",item.targetGroups)
            return {
              id: item.id,
              title: item.title,
              message: item.message,
              publishAt: new Date(item.startDateTime),
              expiresAt: item.expireDateTime ? new Date(item.expireDateTime) : undefined,
              category: item.notificationType,
              groups: item.targetGroups,
              active: item.active,
              isWelcome:item.isWelcome,
              attachementKeys:item.attachementKeys
            }
          }
        );
        newList.sort( itemSorter );
        setNotificationList(newList);
      } catch (err) {
        console.log('error: ', err)
        if (err.errors?.length > 0) {
          let error = err.errors[0];
          if (error.errorType === "ValidationError") {
            alert({ errorMsg: error.message })
          }
        }
      }
    }
    listNotifications();
  },[])
  
  return(
      <ThemeProvider theme={dsmTheme}>
         <NotificationData data={notificationList} onItemAdded={ onItemAdded } activePage={ activePage } />
      </ThemeProvider>
  )
}