import { IntlShape } from 'react-intl';
import {
  AnimalType,
  Facility,
  FacilityType,
  StageType,
} from '../../../graphql/types';
import { FacilitySpecies } from '../models/Facility/FacilityTypes';
import { isBeef, isMarineFish, isPoultryAvailableForProcessing } from './animals';

export const isMarineFishFacility = (
  faciltySpecies?: FacilitySpecies
): boolean => faciltySpecies === FacilitySpecies.MarineFish;

export const isPoultryFacility = (faciltySpecies?: FacilitySpecies): boolean =>
  faciltySpecies === FacilitySpecies.Poultry;

export const isBeefFacility = (faciltySpecies?: FacilitySpecies): boolean =>
  faciltySpecies === FacilitySpecies.Beef;

export const isProcessingStage = (stageType?: StageType): boolean =>
  stageType === StageType.Processing;

export const isFacilitySuitableForAnimalType = (
  facilitySpecies: FacilitySpecies,
  animalType: AnimalType
) => {
  if (isMarineFishFacility(facilitySpecies)) {
    return isMarineFish(animalType);
  }
  if (isPoultryFacility(facilitySpecies)) {
    return isPoultryAvailableForProcessing(animalType);
  }
  if (isBeefFacility(facilitySpecies)) {
    return isBeef(animalType);
  }

  return animalType.toString() === FacilitySpecies.Pig;
};

export const isCuttingFacility = (facilityType?: FacilityType) =>
  facilityType === FacilityType.Cutting;

export const isSlaughteringFacility = (facilityType?: FacilityType) =>
  facilityType === FacilityType.Slaughtering;

export const isSlaugteringAndCuttingFacility = (facilityType?: FacilityType) =>
  facilityType === FacilityType.SlaughteringAndCutting;

export const mountAddNewFacilityOption = (intl: IntlShape) => ({
  text: `\u2A01 ${intl.formatMessage({
    id: 'SUSTELL.STAGES.PROCESSING.NEW_FACILITY',
  })}`,
  value: 'ADD_NEW',
});

export const mountFacilityOption = (
  type: FacilityType,
  facilityId: string,
  name: string,
  intl: IntlShape
) => {
  const facilityTypeText = intl.formatMessage({
    id: `SUSTELL.STAGE.${type}`,
  });
  return {
    text: `${name} (${facilityTypeText})`,
    value: facilityId,
  };
};

export const filterFacilitiesByType = (
  facilities: Array<Facility>,
  type: FacilityType
) => {
  if (!facilities || !facilities?.length) return [];

  return facilities.filter((facility) => facility.type === type);
};

export const mountFacilityOptions = (
  facilities: Array<Facility>,
  intl: IntlShape,
  includeAddNew = false
) => {
  if (!facilities || !facilities?.length) return [];

  const options = facilities.map((facility) =>
    mountFacilityOption(facility.type, facility.id, facility.name, intl)
  );

  return includeAddNew
    ? [...options, mountAddNewFacilityOption(intl)]
    : options;
};
