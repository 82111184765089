import { FC, useRef, useState } from "react";

import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import { Check, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { DashboardImpactCategory } from "../../models/Dashboard/DashboardTypes";
import { IconProvider } from "../helpers/IconProvider";
import { CompartmentType } from "../../models/Footprint/internal";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { IMPACT_CATEGORY_ICON_COLORS, IMPACT_CATEGORY_SELECT_COLORS } from "./helper";

interface ImpactCategorySelectProps {
  dashboard: DashboardModel | undefined;
  renderDependantComponents: () => void;
}

const ImpactCategorySelect: FC<ImpactCategorySelectProps> = ({
  dashboard,
  renderDependantComponents,
}) => { 
  const intl = useIntl();
  const [menuOpened, setMenuOpened] = useState(false);
  const menuAnchorEl = useRef<HTMLDivElement | null>(null);

  const allCategoriesWithDulicates = dashboard?.unfilteredFootprints.flatMap(f => f.categories) || [];

  const disticntCategs = allCategoriesWithDulicates
            .filter((item, index) => allCategoriesWithDulicates.findIndex(el => el.key === item.key) === index )
            .map(item => ({key: item.key, unit: item.unit, description: item.description, compartments: item.compartments}));


  const handleCategoryChanged = ( key: string,
                                  type: CompartmentType,
                                  unit: string
                                  ) => {
    let name = intl.formatMessage({id: "REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.CLIMATE_CHANGE"});
    if (type === CompartmentType.LAND) {
      name = intl.formatMessage({id: "REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.LAND_USE"});
    } else if (type === CompartmentType.HUMAN) {
      name = intl.formatMessage({id: "SUSTELL.DASHBOARD.IMPACT.HUMAN"});
    } else if (type === CompartmentType.WATER) {
      name = intl.formatMessage({id: "SUSTELL.DASHBOARD.IMPACT.WATER"});
    } 

    const newCategory: DashboardImpactCategory = {
      key,
      name,
      compartmentType: type,
      unit
    };
    dashboard?.setActiveImpactCategory(newCategory);
    renderDependantComponents();
  }

  const getListElementsByCompartment = (compartment: CompartmentType) => {
    const retVal = disticntCategs.filter(item => item.compartments.includes(compartment)).map(item => {
      const isSelected = item.key === dashboard?.activeImpactCategory.key && item.compartments.includes(dashboard?.activeImpactCategory.compartmentType || '');
      const iconColor = IMPACT_CATEGORY_ICON_COLORS[compartment.toLocaleLowerCase() || 'air'];
  
      return (
        <MenuItem 
          style={{whiteSpace: 'normal', background: (isSelected ? '#F0F7FB' : '')}}
          onClick={() => handleCategoryChanged(item.key, compartment, item.unit)}
          key={`dashboard-impact-categ-select${item.key}`}
        >
          <IconProvider iconCode={`dashboard${compartment}Icon`} height="16px" color={iconColor} />
          <span style={{marginLeft: '10px', marginRight: '4px'}}>{item.key}</span>
          { isSelected &&
            <ListItemIcon style={{minWidth: '22px', color: '#0070BA'}}>
              <Check />
            </ListItemIcon>
          }
        </MenuItem>
      );
    });
    return retVal;
  }

  const iconCode = dashboard?.activeImpactCategory.compartmentType?.toLowerCase() || 'air';

  return (
    <>
      <Box 
        mt="32px"
        p="32px" 
        style={{
          background: IMPACT_CATEGORY_SELECT_COLORS[dashboard?.activeImpactCategory.compartmentType.toLowerCase() || 'air'],
          color: 'white',
          borderRadius:'4px',
          cursor: 'pointer'
        }}
        onClick={() => setMenuOpened(!menuOpened)}
      >
        <Grid container alignContent="flex-start" alignItems="center">
          <Grid item xs={1} id="dashboardImpcatCategoryIcon">
            <IconProvider iconCode={iconCode} color="white" width="40px"/>
          </Grid>
          <Grid item xs={10}>
            <Typography color="inherit" style={{fontWeight: 500, fontSize: '20px', lineHeight: '24px'}}>
              { dashboard?.activeImpactCategory.name || '' }
            </Typography>
            <Typography color="inherit" style={{fontSize: '20px'}}>
              { dashboard?.activeImpactCategory.key || '' }
            </Typography>
          </Grid>
          <Grid item container xs={1} style={{display: 'flex'}} direction="row-reverse" id="dashboardImpcatCategoryArrow">
            <IconButton 
              aria-label="expand row"
              size="small"
              color="inherit"
            >
              { menuOpened ? 
                <KeyboardArrowUp /> :
                <KeyboardArrowDown />
              }
            </IconButton>
          </Grid>
        </Grid>

        <Grid container direction="row" alignContent="flex-start" alignItems="center">
          <Menu
            id="fade-menu"
            anchorEl={menuAnchorEl.current}
            keepMounted
            open={menuOpened}
            onClose={() => setMenuOpened(false)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
              style: {
                maxWidth: '1046px',
                marginRight: '32px'
              }
            }}
          >
            <Grid container direction="row" alignContent="flex-start" alignItems="flex-start" style={{ paddingTop: '10px', background: 'white'}} spacing={1}>
              <Grid item xs={3} >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box>
                    { getListElementsByCompartment(CompartmentType.AIR) }
                  </Box>
              </Box>
              </Grid>
              <Grid item xs={3} >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box>
                      { getListElementsByCompartment(CompartmentType.LAND) }
                    </Box>
                </Box>
              </Grid>
              <Grid item xs={3} >
               <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box>
                      { getListElementsByCompartment(CompartmentType.HUMAN) }
                    </Box>
                </Box>
              </Grid>
              <Grid item xs={3} >
              <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box>
                      { getListElementsByCompartment(CompartmentType.WATER) }
                    </Box>
                </Box>
              </Grid>
            </Grid>
          </Menu>
        </Grid>
      </Box>
      <div ref={menuAnchorEl} style={{width: '100%', marginBottom: '32px'}} / > 
    </>
  );
}

export default ImpactCategorySelect;
