import { Grid } from '@material-ui/core';
import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { LayingHensBaseline } from '../../../../models/Baseline';
import { LayingHensIntervention } from '../../../../models/Intervention';
import StageButtonBox from '../../StageButtonBox';
import InterventionFeedsFormDialog from '../CommonDataParts/InterventionFeedsFormDialog';
import { InterventionStageProps } from '../CommonDataParts/InterventionTypes';
import LHInterventionEmissionsFormDialog from './LHInterventionEmissionsFormDialog';
import LHInterventionFeedsVariablePart from './LHInterventionFeedsVariablePart';
import LHInterventionInputFormDialog from './LHInterventionInputFormDialog';
import LHInterventionManureFormDialog from './LHInterventionManureFormDialog';
import LHInterventionOutputFormDialog from './LHInterventionOutputFormDialog';

interface LayingHensInterventionStageProps extends InterventionStageProps {
  intervention: LayingHensIntervention | null;
  baseline: LayingHensBaseline;
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  MANURE = 'manure',
  EMISSIONS = 'emissions',
  OUTPUT = 'output',
}

const LayingHensInterventionStages = ({
  stageIndex,
  baseline,
  manureManagementSystems,
  singleIngredients,
  compoundFeeds,
  intervention,
  formType,
}: LayingHensInterventionStageProps) => {
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(
    null
  );

  const showDialog = (dialog: StageDialogType | null) => {
    setActiveDialog(dialog);
  };

  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.productionSystem`}
        value={`${baseline?.stages?.[stageIndex]?.productionSystem}`}
      />

      <LHInterventionInputFormDialog
        formVisible={activeDialog === 'input'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <InterventionFeedsFormDialog
        formVisible={activeDialog === 'feed'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        intervention={intervention}
        formType={formType}
      >
        <LHInterventionFeedsVariablePart
          stageIndex={stageIndex}
          baseline={baseline}
          formType={formType}
        />
      </InterventionFeedsFormDialog>
      <LHInterventionManureFormDialog
        formVisible={activeDialog === 'manure'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        manureManagementSystems={manureManagementSystems}
        formType={formType}
      />
      <LHInterventionEmissionsFormDialog
        formVisible={activeDialog === 'emissions'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <LHInterventionOutputFormDialog
        formVisible={activeDialog === 'output'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="LAYING_HENS_INPUT"
          showCompletness={false}
          error={
            // this usafe assignment/access shouldn't be a problem as it represesnts a form hierachy
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="manureIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.manure
          }
          handleOpen={() => showDialog(StageDialogType.MANURE)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="LAYING_HENS"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        <StageButtonBox
          showCompletness={false}
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.emissions
          }
          handleOpen={() => showDialog(StageDialogType.EMISSIONS)}
        />
      </Grid>
    </>
  );
};

export default LayingHensInterventionStages;
