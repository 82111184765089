/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/prefer-default-export */
import { UseFormMethods } from 'react-hook-form';

export const getInitialDatabaseValue = <D>(
  formContext: UseFormMethods,
  fieldItemPrefix: string,
  userProfile: any
) => {
  let initValue: D = formContext.getValues(
    `${fieldItemPrefix}.databaseFoundation`
  );
  if (!initValue)
    initValue = userProfile.getUserDatabasePrefs().databaseType as D;
  return initValue;
};
