import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';
import selectedCustomerReducer from "./selected-customer/selectedCustomerSlice";
import feedDraftsReducer from "./drafts/feedDraftsSlice";
import baselineDraftsReducer from './drafts/baselineDraftsSlice';
import interventionDraftsReducer from './drafts/interventionDraftsSlice';
import dashboardFiltersDraftReducer from './drafts/dashboardFiltersDraftSlice';

const persistedSelectedCustomerReducer = persistReducer({
  key: 'selected-customer-storage',
  storage, // * Wil be persisted in local storage with the provided key
}, selectedCustomerReducer);

const persistedFeedDraftsReducer = persistReducer({
  key: 'drafts-storage',
  storage, 
}, feedDraftsReducer);

const persistedBaselineDraftsReducer = persistReducer({
  key: 'baseline-drafts-storage',
  storage, 
}, baselineDraftsReducer);

const persistedInterventionDraftsReducer = persistReducer({
  key: 'intervention-drafts-storage',
  storage, 
}, interventionDraftsReducer);

const persistedDashboardDraft = persistReducer({
  key: 'dashboard-draft-storage',
  storage
}, dashboardFiltersDraftReducer)

export const store = configureStore({
  reducer: {
    selectedCustomer: persistedSelectedCustomerReducer,
    feedDrafts: persistedFeedDraftsReducer,
    baselineDrafts: persistedBaselineDraftsReducer,
    interventionDrafts: persistedInterventionDraftsReducer,
    dashboardDrafts: persistedDashboardDraft
  },
  devTools: process.env.NODE_ENV === 'development',
  // * Thunk allows us to dispatch actions manually, which gives us the ability to incorporate some logic or run some asynchronous code before dispatching an action.
  middleware: [thunk]
});

export const persistor = persistStore(store, null);


