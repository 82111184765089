import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonProductionDataOutSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustell';
import { floatValueInDefaultUnit } from './floatHelperFunctions'; 


const map3NOPtoDefaultUnitsForOneStage = formEmissionsDataForOneStage => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const isStageOutputInKg =
    userUOM.unitBarnOutputMass === defaultMetric.unitBarnOutputMass;
  const isBarnSmallQuantityUnitsInGrams =
    userUOM.unitBarnSmallQuantityUnits ===
    defaultMetric.unitBarnSmallQuantityUnits;
  
  if (
    formEmissionsDataForOneStage?.supplement3NOP?._3nopDosePerCowPerDay &&
    !isBarnSmallQuantityUnitsInGrams
  ) {
    formEmissionsDataForOneStage.supplement3NOP._3nopDosePerCowPerDay = floatValueInDefaultUnit(
      formEmissionsDataForOneStage.supplement3NOP._3nopDosePerCowPerDay,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits,
      8
    );
  }
  if (formEmissionsDataForOneStage?.supplement3NOP?.dmiAmountPerKg && !isStageOutputInKg) {
    formEmissionsDataForOneStage.supplement3NOP.dmiAmountPerKg = floatValueInDefaultUnit(
      formEmissionsDataForOneStage.supplement3NOP.dmiAmountPerKg,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      8
    );
  }

  return formEmissionsDataForOneStage;
};

// overrides common mapping for emmission for 3NOP
const correctEmissionForOneStage = (
  stageData3NOP, // stageData,
  stageEmissionsData, // emissionDataFortheStage
  stageEmissionFormData
) => {
  // check if there is supplements that affect emmissions, for now only 3-NOP
  let methaneReduction3NOP = null;
  if (
    stageData3NOP?.calculatedResult ||
    stageData3NOP?.calculatedResult === 0
  ) {
    methaneReduction3NOP = stageData3NOP?.calculatedResult;
  }

  // fix calculation if needed
  if (methaneReduction3NOP && stageEmissionsData) {
    let valueNum = parseFloat(stageEmissionFormData?.methaneEntericFermentation);
    if (!isNaN(valueNum)) {
      valueNum += parseFloat(methaneReduction3NOP);
    } else {
      valueNum = parseFloat(methaneReduction3NOP);
    }

    const emmission = stageEmissionsData.find(
      el => el.key === 'methane_enteric_fermentation'
    );

    // update result with 3NOP
    if (emmission) emmission.value.value = valueNum;
    else {
      stageEmissionsData.push({
        "key": "methane_enteric_fermentation",
        "action": "update",
        "value": {
            "value": valueNum,
            "change_type": "relative"
        }
      });
    }
  }
};

const mapDairyProductionDataOutSustell = (formData, isDraft = false ) => {
  if (!formData) return {}

  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  let payloadObj

  try {
    payloadObj = mapCommonProductionDataOutSustell(formData, isDraft);
    payloadObj = mapResourceUseSustell(formData, payloadObj);
  
    if (formData.stages)
      formData.stages.forEach((formDataForOneStage, index) => {
        if(payloadObj.stages[index]!==undefined){
          const formStageData = formDataForOneStage.stageData;
          const stageGeneralData = payloadObj.stages[index];
          const {stageData} = stageGeneralData;
          stageData.animal_group.animal_type = formDataForOneStage.type || formDataForOneStage.productionSystem;
          stageData.type = formDataForOneStage.type || formDataForOneStage.productionSystem;
          stageData.animal_group.number_of_animals = {
              value:
                parseFloat(formStageData?.manure?.averageAnimalsPresent) || null,
          };
          
          const outputBlockData = formStageData?.output;
          
          if(outputBlockData){
            stageData.milk_produced = {
              amount: {
                value: floatValueInDefaultUnit(
                  outputBlockData.milk,
                  userUOM.unitOutputMass,
                  defaultMetric.unitOutputMass,
                  outputMassPrecision
                ),
              },
              protein_content: {
                value: parseFloat(outputBlockData.proteinContent),
              },
              fat_content: {
                value: parseFloat(outputBlockData.fatContent),
              },
            };
  
            stageData.livestock = {
              total_weight: {
                value: floatValueInDefaultUnit(
                  outputBlockData.liveWeight,
                  userUOM.unitOutputMass,
                  defaultMetric.unitOutputMass,
                  outputMassPrecision
                ),
              },
            };
          }
  
          const feedBlockData = formStageData?.feed;
          if(feedBlockData)
          {
            stageData.housing_description = {
              housing_type: feedBlockData.housingType,
            };
  
            stageData.time_spent_distribution = {
              grazing: {
                value: parseInt(feedBlockData.timeSpentGrazing),
              },
              yard: {
                value: parseInt(feedBlockData.timeSpentInOpenYardAreas),
              },
              building: {
                value: parseInt(feedBlockData.timeSpentInBuildings),
              },
            };
  
            stageData.housing_system = formDataForOneStage.type || formDataForOneStage.productionSystem;
            // update feedBlockData with 3NOP mapping
            map3NOPtoDefaultUnitsForOneStage(feedBlockData);
          }
  
          const emmissionBlockDat = formStageData?.emissions;
  
          if(isDraft)
            stageData.is3NOPSelected = emmissionBlockDat?.is3NOPSelected || false;
          stageData.supplement3NOP = emmissionBlockDat?.supplement3NOP || {};
  
          correctEmissionForOneStage(
            stageData.supplement3NOP,
            stageData.emissions,
            formStageData?.emissions
          );
        }
        
      });
    payloadObj.stages.forEach( item => {
      const {stageData} = item;
      item.stageData = JSON.stringify(stageData);
    })
  } catch (err) {
    console.error('Error on outDairyMapperSustell: ', err)
  }
  return payloadObj;
};

export default mapDairyProductionDataOutSustell;
