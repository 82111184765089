import React from "react";
import {
  Grid,
  InputAdornment,
} from "@material-ui/core";

import ReactHookTextField from "../../Helpers/ReactHookTextField";
import InfoToolTip from "../../Helpers/InfoToolTip";

const RowTextFieldWithInfo = (props) => {

  const { name, label, tooltip, multiline, disabled, readonly, onChange, defaultValue = '', itemWidth=11, onFocusHandler=undefined } = props;
  let { addornment, margin, variant, type } = props;
  
  if (!margin)
    margin = 'normal';
  if (!variant)
    variant = 'outlined';
  if (!addornment)
    addornment = '';
  if (!type)
    type = 'text';

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={itemWidth}>
        <ReactHookTextField
          name={name}
          label={label}
          margin={margin}
          variant={variant}
          multiline={multiline}
          disabled={disabled}
          defaultValue={defaultValue}
          type={type}
          changeHandler={onChange}
          onFocusHandler={onFocusHandler}
          InputProps={{
            endAdornment: <InputAdornment position="end">{addornment}</InputAdornment>,
            readOnly: readonly ? true : false,
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <div >
          {tooltip &&
          <InfoToolTip tooltip={tooltip} />
          }
        </div>
      </Grid>
    </Grid>
  );
};
export default RowTextFieldWithInfo;
