import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getCustomerWithBaselinesAndStageTypes,
  listFeedsV1andV2,
} from '../../../../graphql/queries';
import {
  AnimalType,
  CompoundFeedDatabase,
  Customer,
  FootPrintType,
  Maybe,
  AnimalSystem,
  StageType,
} from '../../../../graphql/types';
import { footprintURL } from '../../utils/footprint-url-utils';
import { FootprintModel } from '../../models/Footprint';
import { CFDatabaseFoundation } from '../../models/CompoundFeed';
import { getUIDatabaseFoundation } from '../../utils/compound-feed-utils';
import { isShrimp } from '../../helpers/animals';

interface FootprintCompareProps {
  footprint: FootprintModel;
  customerID: string;
  type: string;
  comparisonReference?: string;
}

export interface DatasetOption {
  value: string; // footprint reference id
  label: string; // dataset name
  customerID: string;
  farmID?: string;
  farmName?: string;
  processID?: string;
  processName?: string;
  type: string;
  animalType?: AnimalType;
  databaseName?: CFDatabaseFoundation;
  startDate?: Maybe<Date> | undefined;
  endDate?: Maybe<Date> | undefined;
  year?: number;
  stageName?: string;
  stageType?: Maybe<StageType>;
}

export interface FootprintCompareOptions {
  label: string;
  options: DatasetOption[];
}

/**
 * return prodcuton process datasets into @type DatasetOption[]
 * @param animalSystems
 * @returns DatasetOption[]
 */
const getProductionProcessItems = (
  animalSystems: AnimalSystem[],
  customerID: string,
  farmName: string
) => {
  const datasets: DatasetOption[] = [];
  animalSystems.forEach((pp) => {
    if (!pp) return;
    const baselineItems = pp?.baselines?.items;
    const interventionItems = pp?.interventions?.items;
    (baselineItems || []).forEach((b) => {
      if (!b) return;

      const dataset: DatasetOption = {
        value: b.reference,
        label: b.name,
        customerID,
        farmID: pp.farmId,
        farmName,
        processID: pp.id,
        processName: pp.name,
        type: 'b',
        animalType: pp.animalType,
        startDate: b.validFrom,
        endDate: b.validTo,
        year: b.year
      };

      if (isShrimp(pp.animalType) && b.stages) {
        dataset.stageType = b.stages[0]?.type;
      }

      datasets.push(dataset);
    });
    (interventionItems || []).forEach((b) => {
      if (!b) return;
      datasets.push({
        value: b.reference,
        label: b.name,
        customerID,
        farmID: pp.farmId,
        farmName,
        processID: pp.id,
        processName: pp.name,
        type: 'i',
        animalType: pp.animalType,
        year: baselineItems?.find(item => item?.reference === b.baseline)?.year,
        startDate: baselineItems?.find(item => item?.reference === b.baseline)?.validFrom,
        endDate: baselineItems?.find(item => item?.reference === b.baseline)?.validTo,
      });
    });
  });
  return datasets;
};

/**
 *
 * @param footprintCompareDatasets
 * @returns default dataset
 */
const findDataset = (
  footprintCompareDatasets: FootprintCompareOptions[],
  reference: string
) => {
  let defaultObj;
  for (let i = 0; i < footprintCompareDatasets.length; i += 1) {
    defaultObj = footprintCompareDatasets[i].options.find(
      (item) => item.value === reference
    );
    if (defaultObj) break;
  }
  return defaultObj;
};

/**
 * Fetching all the farms for customer
 * @param customerId
 */
const fetchFarmsData = async (customerId: string) => {
  const queryWithVars = {
    query: getCustomerWithBaselinesAndStageTypes,
    variables: {
      id: customerId,
    },
  };
  const result = await (API.graphql(queryWithVars) as Promise<{
    data: {
      getCustomer: Customer;
    };
  }>);
  return {
    farms: result.data?.getCustomer?.farms?.items,
  };
};

/**
 * Fetching all the feeds for customer
 * @param customerId
 */
const fetchCompoundFeedsData = async (customerId: string) => {
  const queryWithVars = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    query: listFeedsV1andV2,
    variables: {
      customerId,
    },
  };
  const result = await (API.graphql(queryWithVars) as Promise<{
    data: {
      getCompoundFeedList: { items: {value: string, display_name: string, databaseFoundation?: CompoundFeedDatabase, year?: {value: number}}[] };
      listCompoundFeedsV2: {items: {value: string, display_name: string, databaseFoundation?: CompoundFeedDatabase, year: number}[] };
    };
  }>);
  return {
    compoundFeeds: result.data?.getCompoundFeedList?.items?.map(item => ({...item, year: item.year?.value})),
    compoundFeedsV2: result.data?.listCompoundFeedsV2?.items,
  };
};

const useFootprintCompare = ({
  footprint,
  customerID,
  comparisonReference,
  type,
}: FootprintCompareProps) => {
  const [datasets, setDatasets] = useState<FootprintCompareOptions[]>([]);
  const [comparisonDatasets, setComparisonDatasets] = useState<
    FootprintCompareOptions[]
  >([]);
  const [isCompare, setCompare] = useState(false);
  const [baseline, setBaseline] = useState<DatasetOption | null>();
  const [comparison, setComparison] = useState<DatasetOption | null>();
  const history = useHistory();

  /**
   *
   * @param footprintCompareDatasets
   * @param animalType
   * @param stageType
   * @returns filtered datasets by animal type and stage type
   */
  const getFilteredDataByAnimalAndStageType = (
    animalType: AnimalType | undefined,
    stageType: Maybe<StageType> | undefined,
    footprintCompareDatasets: FootprintCompareOptions[]
  ) => {
    if (!animalType) {
      return footprintCompareDatasets;
    }
    const filteredDatasets = [];
    for (let i = 0; i < footprintCompareDatasets.length; i += 1) {
      let datasetOptions = footprintCompareDatasets[i].options.filter(
        (item) => item.animalType === animalType
      );
      if (stageType) {
        datasetOptions = datasetOptions?.filter(
          (item) => item.stageType === stageType
        );
      }
      if (datasetOptions) {
        filteredDatasets.push({
          label: footprintCompareDatasets[i].label,
          options: datasetOptions,
        });
      }
    }
    return filteredDatasets;
  };

  /**
   * retrun current footprint baseline options
   * @returns DatasetOption
   */
  const getDefaultValue = () =>
    findDataset(datasets, footprint.reference) || datasets[0]?.options[0];

  /**
   * set default baseline and compare view open
   */
  const compareButtonClick = () => {
    setCompare(true);
    setBaseline(getDefaultValue);
  };

  useEffect(() => {
    if (type === 'f') {
      fetchCompoundFeedsData(customerID)
        .then((data) => {
          const mergedListFeeds = (data.compoundFeeds || [])
            .concat(data.compoundFeedsV2 || [])
            .sort((a, b) => (a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1));

          const footprintDataSets: FootprintCompareOptions[] = [
            {
              label: 'Compound feeds',
              options: mergedListFeeds.map((compoundFeed) => ({
                value: compoundFeed.value,
                label: compoundFeed.display_name,
                customerID,
                year: compoundFeed.year,
                type: 'f',
                databaseName: getUIDatabaseFoundation(compoundFeed.databaseFoundation),
              })),
            },
          ];

          setDatasets(footprintDataSets);
          setComparisonDatasets(footprintDataSets);
          if (comparisonReference) {
            setCompare(true);
            setComparison(findDataset(footprintDataSets, comparisonReference));
            setBaseline(findDataset(footprintDataSets, footprint.reference));
          }
        })
        .catch((reason) => console.error(reason));
    } else {
      fetchFarmsData(customerID)
        .then((data) => {
          const footprintDataSets: FootprintCompareOptions[] = [];
          (data.farms || []).forEach((farm) => {
            if (!farm) return;
            const dataSet = {
              label: farm.name,
              options: getProductionProcessItems(
                farm?.animalSystems?.items as AnimalSystem[],
                customerID,
                farm.name
              ),
            };
            footprintDataSets.push(dataSet);
          });
          setDatasets(footprintDataSets);
          const filteredComparisonDatasets = getFilteredDataByAnimalAndStageType(
            findDataset(footprintDataSets, footprint.reference)?.animalType,
            findDataset(footprintDataSets, footprint.reference)?.stageType,
            footprintDataSets
          );
          setComparisonDatasets(filteredComparisonDatasets);
          if (comparisonReference) {
            setCompare(true);
            setComparison(findDataset(footprintDataSets, comparisonReference));
            setBaseline(findDataset(footprintDataSets, footprint.reference));
          }
        })
        .catch((reason) => console.error(reason));
    }
  }, [
    customerID,
    type,
    comparisonReference,
    footprint.reference,
  ]);

  /**
   * Build footprint baseline | baseline & comparison url
   * @param baselineOption
   * @param comparisonOption
   * @returns url
   */
  const buildURL = (
    baselineOption: DatasetOption,
    comparisonOption?: DatasetOption
  ) => {
    const {
      label: dataSetName,
      value: baselineReference,
      farmID: baselineFarmID,
      farmName,
      processID: baselineProcessID,
      processName,
      customerID: baselineCustomerID,
      type: baselineType,
      databaseName,
      animalType
    } = baselineOption;

    const variables = {
      farmName,
      processName,
      dataSetName,
      databaseName,
      animalType
    };

    if (comparisonOption) {
      const {
        label: comparisonName,
        value: comparisonOptionReference,
        farmID: comparisonFarmID,
        type: comparisonType,
        processID: comparisonProcessID,
        customerID: comparisonCustomerID,
      } = comparisonOption;

      return {
        path: footprintURL({
          baselineCustomerID,
          baselineFarmID: baselineFarmID || '',
          baselineProcessID: baselineProcessID || '',
          baselineType,
          baselineReference,
          comparisonFarmID,
          comparisonProcessID,
          comparisonType,
          comparisonReference: comparisonOptionReference,
          comparisonCustomerID,
        }),
        variables: {
          ...variables,
          comparisonName,
          animalType: baselineOption.animalType
        },
      };
    }
    return {
      path: footprintURL({
        baselineCustomerID,
        baselineFarmID: baselineFarmID || '',
        baselineProcessID: baselineProcessID || '',
        baselineType,
        baselineReference,
      }),
      variables,
    };
  };

  /**
   * Navigate to current footprint view
   * @param baselineOption
   */
  const navigateToCurrentFootprint = (baselineOption: DatasetOption) => {
    const { path, variables } = buildURL(baselineOption);
    history.push(path, variables);
  };

  /**
   * Navigates to compare footprint view
   * @param baselineOption
   * @param comparisonOption
   */
  const navigateToCompareFootprints = (
    baselineOption: DatasetOption,
    comparisonOption: DatasetOption
  ) => {
    const { path, variables } = buildURL(baselineOption, comparisonOption);
    history.push(path, variables);
  };

  /**
   * Close compare view
   */
  const closeCompareView = () => {
    setCompare(false);
    if (baseline) {
      navigateToCurrentFootprint(baseline);
    }
    setBaseline(null);
    setComparison(null);
  };

  /**
   * Swaping compare footprint datasets
   */
  const swapButtonClick = () => {
    if (baseline && comparison) {
      setBaseline(comparison);
      setComparison(baseline);
      navigateToCompareFootprints(comparison, baseline);
    }
  };

  /**
   * set baseline and navigate to selected baseline footprint view
   * @param baselineOption
   */
  const handleDatasetAChange = (baselineOption: DatasetOption) => {
    if (baselineOption) {
      setBaseline(baselineOption);
      navigateToCurrentFootprint(baselineOption);
      const filteredComparisonDatasets = getFilteredDataByAnimalAndStageType(
        baselineOption.animalType,
        baselineOption.stageType,
        datasets
      );
      setComparisonDatasets(filteredComparisonDatasets);
    } else if (comparisonReference && comparison) {
      setBaseline(comparison);
      navigateToCurrentFootprint(comparison);
      setComparison(null);
    } else {
      setBaseline(null);
    }
  };

  /**
   * set comparison baseline and navigate to compare footprint view
   * @param comparisonOption
   */
  const handleDatasetBChange = (comparisonOption: DatasetOption) => {
    if (comparisonOption && baseline) {
      setComparison(comparisonOption);
      navigateToCompareFootprints(baseline, comparisonOption);
    } else {
      setComparison(null);
    }
  };

  const getDatasets = (isComparison=false) => {
    if ((!baseline && !comparison) || footprint.type !== FootPrintType.CompoundFeed) {
      return isComparison ? comparisonDatasets : datasets;
    }

    let dbToFilter: CFDatabaseFoundation | undefined;
    let datasetsFilter;
    if (baseline && !comparison) {
      dbToFilter = baseline.databaseName;
      datasetsFilter = JSON.parse(JSON.stringify(datasets, null, 2)) as FootprintCompareOptions[];
    }
    if (!baseline && comparison) {
      dbToFilter = comparison.databaseName;
      datasetsFilter = JSON.parse(JSON.stringify(comparisonDatasets, null, 2)) as FootprintCompareOptions[];
    }

    if (dbToFilter && datasetsFilter) {
      datasetsFilter.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.options = item.options.filter(i => i.databaseName === dbToFilter);
      });
      return datasetsFilter;
    }
    return isComparison ? comparisonDatasets : datasets;
  }
  return {
    datasets,
    comparisonDatasets,
    getDefaultValue,
    isCompare,
    baseline,
    comparison,
    compareButtonClick,
    closeCompareView,
    swapButtonClick,
    handleDatasetBChange,
    handleDatasetAChange,
    getDatasets
  };
};

export default useFootprintCompare;
