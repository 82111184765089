import {
  Grid,
} from "@material-ui/core";

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { PigFatteningBaseline } from '../../../../models/Baseline';
import { FormType } from '../../common';

interface PFInterventionFeedsVariablePartProps {
  formType: FormType; 
  stageIndex: number;
  baseline: PigFatteningBaseline;
}

const PFInterventionFeedsVariablePart = ({
  formType = FormType.Add,
  stageIndex=0,
  baseline
}: PFInterventionFeedsVariablePartProps) => {
  const intl = useIntl();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  const feed = baseline?.stages?.[stageIndex]?.stageData?.feed;
  
  return (
    <>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix  }.digestibility`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.DIGESTIBILITY"}) } 
          type="number"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.DIGESTIBILITY.TOOLTIP"})}
          metricUnit="%"
          baseline={feed?.digestibility}
          disabled={formType === "view"}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix  }.grossEnergyIntake`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.GROSS_ENERGY_INTAKE"}) }
          type="number"
          tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.BARN.RATION.GROSS_ENERGY_INTAKE.TOOLTIP"}) } 
          metricUnit={ intl.formatMessage( {id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX"},{unit: 'MJ' }) }
          baseline={feed?.grossEnergyIntake}
          disabled={formType === "view"}
        />
      </Grid>
      <Grid item>
         <RowTextFieldWithMetrics
          name={`${fieldItemPrefix  }.nitrogenContent`}
          label={ intl.formatMessage({id:"BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT"}) }
          type="number"
          tooltip={ intl.formatMessage({id:"INTERVENTION.FORM.BARN.RATION.NITROGEN_CONTENT.TOOLTIP" })}
          metricUnit="%"
          baseline={feed?.nitrogenContent}
          disabled={formType === "view"}
        />
      </Grid>
    </>
  );
};

export default PFInterventionFeedsVariablePart;