import { FC, useRef } from "react";

import { Box, Grid } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
import Pagination from "@material-ui/lab/Pagination";
import { AnalysisGroupsFarm, AnalysisGroupsFeed, DashboardType } from "../../models/Dashboard/DashboardTypes";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { useIntl } from "react-intl";
import { AnimalType } from "../../../../graphql/types";
import {
  chartColors
} from '../../../../_metronic/layout';
import { isSpecificAnimalType } from "../helpers/AnimalSpecificLabels";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  annotationPlugin,
);

// const externalTooltipHandler = (context: any) => {
//   // console.log('obj:', context);
//   const {chart, tooltip} = context;


//   return <p>asdasdasdas</p>
// }


const getColorByAnalysisGroup = (analysisGroup: string) => {
  switch (analysisGroup) {
    case AnalysisGroupsFarm.Ration: 
      return chartColors.get('DSM_LIME_GREEN');
    case AnalysisGroupsFarm.Farm: 
      return chartColors.get('DSM_CYAN_LIGHTER');
    case AnalysisGroupsFarm.PurchasedAnimals : 
      return chartColors.get('DSM_BLUE');
    case AnalysisGroupsFeed.Ingredients: 
      return chartColors.get('DSM_DARK_PURPLE');
    case AnalysisGroupsFeed.Transport : 
      return chartColors.get('DSM_GREEN_DARKEST');
    case AnalysisGroupsFarm.Resources: 
      return chartColors.get('DSM_DARK_ORANGE');
    case AnalysisGroupsFarm.AuxiliaryInputs: 
      return chartColors.get('DSM_MAGENTA');
    case AnalysisGroupsFarm.Processing: 
      return chartColors.get('DSM_OCEAN_GREEN');
    default: 
      return '';
  };
}

interface DashboardDatasetDiagramProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
}

const DashboardDatasetChart: FC<DashboardDatasetDiagramProps> = ({
  dashboard,
  renderDashboard,
}) => { 
  const intl = useIntl();
  
  const animalType: AnimalType[] | undefined = dashboard?.filters?.animals;

  const getAnalysisGroupLabel = (analysisGroup: string): string => {
    const hasAnimalTypeSpecificLabels = animalType?.some((animal) => isSpecificAnimalType(animal));

    switch (analysisGroup) {
      case AnalysisGroupsFarm.PurchasedAnimals:
        if (hasAnimalTypeSpecificLabels) {
          return intl.formatMessage({ 
            id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.SHRIMP' 
          });
        }
        return intl.formatMessage({ 
          id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.OTHERS' 
        });
      case AnalysisGroupsFarm.Ration:
        if (hasAnimalTypeSpecificLabels) {
          return intl.formatMessage({ 
            id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.RATION.SHRIMP' 
          });
        }
        return analysisGroup;
      case AnalysisGroupsFeed.Ingredients:
        return intl.formatMessage({ 
          id: 'SUSTELL.FOOTPRINT.FEED.INGREDIENTS_INBOUND_TRANSPORT' 
        });
      case AnalysisGroupsFeed.Transport:
        return intl.formatMessage({ 
          id: 'SUSTELL.FOOTPRINT.FEED.OUTBOUND_TRANSPORT' 
        });
      default:
        return analysisGroup;
    }
  }

  const chartRef = useRef(null);

  const chartOptions = (unit = 'kg CO2 eq', 
                      target: number | undefined = undefined,
                      xAxisLabelColors: string[] = []): ChartOptions<'bar'> => ({
  plugins: {
    annotation: {
      annotations: [
        {
          type: 'line',
          scaleID: 'y',
          value: target,
          borderColor: '#EE7203',
          borderWidth: 2,
         },
      ],
   },
    title: {
      display: false,
    },
    legend: {
      align: 'end' as const,
      labels: {
        usePointStyle: true,
        // pointStyle: 'circle'
        
      }
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,     
      grid: {
        color: 'white',
      },
      ticks: { 
        color: xAxisLabelColors,
        textStrokeWidth: 2,
      }
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: `${unit}`
      },
      
    },
  },
});

  const handlePageChange = (event: React.ChangeEvent<unknown> | React.MouseEvent<HTMLButtonElement> | null, pageNo: number) => {
    if (event) {
      event.stopPropagation();
    }
    if (dashboard?.currentPage === pageNo) {
      return;
    }
    dashboard?.setCurrenPage(pageNo);
    renderDashboard();
  }



  const createDatasetsOptions = () => {
    const data: ChartData<'bar'> = {
      labels: dashboard?.dashboardFootprints.map(f => f.name),
      datasets: []
    };
    const analysisGroups = 
      dashboard?.type === DashboardType.Feeds ? 
        AnalysisGroupsFeed 
      : AnalysisGroupsFarm;

    Object.values(analysisGroups).forEach( analysysGroup => {
      const dataset = {
        label: getAnalysisGroupLabel(analysysGroup),
        backgroundColor: getColorByAnalysisGroup(analysysGroup as string),
        data: [],
        borderRadius: 8,
        // barThickness: 109
      }
      dashboard?.dashboardFootprints.forEach(footprint => {
        const value = footprint
                        .getDistributionByImpactCateg(dashboard.activeImpactCategory.key)
                        ?.find(dist => dist.analysisGroup === analysysGroup)?.amount || 0;
        dataset.data.push(value as never);
      })
      data.datasets.push(dataset);
    });
    
    const simulationLabels = (dashboard?.dashboardFootprints || []).map(() => 'gray');
    dashboard?.dashboardFootprints.forEach((f, index) => {
      if (f.isSimulation) {
        simulationLabels[index] = 'black';
      }
    })

    const options = chartOptions(
      dashboard?.activeImpactCategory.unit,
      dashboard?.targetByActiveCategory,
      simulationLabels
    );

    return {
      data,
      options
    }
  }

  const chartDataAndOptions = createDatasetsOptions();

  return (
    <>
      <Grid style={{'height': '535px'}} container direction="row" alignContent="center" alignItems="center" spacing={3}>
        <Bar options={chartDataAndOptions.options} data={chartDataAndOptions.data} ref={chartRef} />
      </Grid>
      <Box display="flex" justifyContent="space-around" mt="32px">
        <Pagination 
          count={dashboard?.totalPages || 0} 
          variant="outlined" 
          color="secondary"
          shape="rounded" 
          page={dashboard?.currentPage || 1} onChange={handlePageChange} 

        />
      </Box>
    </>
  );
}

export default DashboardDatasetChart;