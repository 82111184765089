/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { cloneDeep } from 'lodash';
import { useEffect, useRef } from 'react';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { PoultryInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { PoultryIntervention, PoultryLayingStageData, PoultryOutputLaying as PoultryOutputInterventionLaying } from '../../../../models/Intervention/PoultryIntervention';
import { PoultryOutputLaying } from '../../../../models/Baseline/PoultryBaseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
}

const PoultryOutputLayingDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PoultryInterventionFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const barnSmallQuantityUnits = unitLong(userUOM.unitBarnSmallQuantityUnits as string);
  const currResetValue = useRef<PoultryOutputInterventionLaying>();
  const formTitle = intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',});
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  const output = baseline?.stages?.[stageIndex]?.stageData?.output as PoultryOutputLaying;

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, baseline]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData as PoultryLayingStageData;
      if (stageData?.output) {
        stageData.output = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel();
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant='wide'
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.EGGS_TITLE'})}
            </span>
          </div>
          <DsmGrid className={classes.dsmGridOneColumnIntervention}>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalSold`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_SOLD'})}
              type="number"
              tooltip=' '
              metricUnit=''
              baseline={output?.totalSold}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageWeight`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.AVERAGE_EGGS_WEIGHT'})}
              type="number"
              tooltip=' '
              metricUnit={barnSmallQuantityUnits}
              precision={barnOutputMassPrecision}
              baseline={output?.averageWeight}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.priceEggs`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_EGGS"})}
              type="number"
              tooltip=' '
              metricUnit={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_EGGS.PLACEHOLDER"})}
              baseline={output?.priceEggs}
              disabled={formType === FormType.View}
            />
          </DsmGrid>
        </div>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.HENS_TITLE'})}
            </span>
          </div>
          <DsmGrid className={classes.dsmGridOneColumnIntervention}>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalHensToStage`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_HENS_TO_STAGE'})}
              type="number"
              tooltip=' '
              metricUnit=''
              baseline={output?.totalHensToStage}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageHensWeight`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.AVERAGE_HENS_WEIGHT'})}
              type="number"
              tooltip=' '
              metricUnit={barnOutputMassUnit}
              baseline={output?.averageHensWeight}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.priceHens`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_HENS'})}
              type="number"
              tooltip=' '
              metricUnit={intl.formatMessage({ id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_HENS.PLACEHOLDER"})}
              baseline={output?.priceHens}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.mortalityAtPercent`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.MORTALITY_RATE'})}
              type="number"
              tooltip=' '
              metricUnit='%'
              baseline={output?.mortalityAtPercent}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.weightAtMortality`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.WEIGHT_AT_MORTALITY'})}
              type="number"
              tooltip=' '
              metricUnit={barnOutputMassUnit}
              baseline={output?.weightAtMortality}
              disabled={formType === FormType.View}
            />
          </DsmGrid>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryOutputLayingDialog;
