import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { cloneDeep } from 'lodash';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { MarineFishInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { FormType } from '../../common';
import {
  SalmonIntervention,
  SalmonOutput,
} from '../../../../models/Intervention';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';

const MarineFishInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: MarineFishInterventionFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const outputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const unitOutputMass = userUOM?.unitOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  const currResetValue = useRef<SalmonOutput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as SalmonIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.output) {
        stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output;

  useEffect(() => {
    if (formVisible) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {};
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant="wide"
    >
      <DsmGrid className={classes.dsmGridOneColumnIntervention}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.production`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.PRODUCTION',
          })}
          tooltip={
            <div>
              {intl.formatMessage({
                id: 'INTERVENTION.FORM.OUTPUT.SALMON.PRODUCTION_TT.TOOLTIP',
              })}
            </div>
          }
          metricUnit={unitOutputMass}
          precision={outputMassPrecision}
          baseline={output?.production}
          disabled={formType === 'view'}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.AVG_WEIGHT',
          })}
          tooltip={
            <div>
              {intl.formatMessage({
                id: 'INTERVENTION.FORM.OUTPUT.SALMON.AVG_WEIGHT.TOOLTIP',
              })}
            </div>
          }
          metricUnit={unitOutputMass}
          precision={outputMassPrecision}
          baseline={output?.averageWeight}
          disabled={formType === 'view'}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.mortalities`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.MORTALITIES',
          })}
          tooltip={
            <div>
              {intl.formatMessage({
                id: 'INTERVENTION.FORM.OUTPUT.SALMON.MORTALITIES.TOOLTIP',
              })}
            </div>
          }
          metricUnit={unitOutputMass}
          precision={outputMassPrecision}
          baseline={output?.mortalities}
          disabled={formType === 'view'}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.escapees`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.ESCAPEES',
          })}
          tooltip={
            <div>
              {intl.formatMessage({
                id: 'INTERVENTION.FORM.OUTPUT.SALMON.ESCAPEES.TOOLTIP',
              })}
            </div>
          }
          metricUnit={unitOutputMass}
          precision={outputMassPrecision}
          baseline={output?.escapees}
          disabled={formType === 'view'}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default MarineFishInterventionOutputFormDialog;
