import { Grid } from '@material-ui/core';
import { FC } from 'react';
import { DsmFieldset } from '@dsm-dcs/design-system-react';
import { FormType } from './types';
import PigNutrition from './Nutrition/PigNutrition';
import PoultryNutrition from './Nutrition/PoultryNutrition';
import ShrimpNutrition from './Nutrition/ShrimpNutrition';
import { isPoultrySystem } from './utils';
import lookupValues from '../../helpers/lookupValues';
import BeefNutrition from './Nutrition/BeefNutrition';
import DairyNutrition from './Nutrition/DairyNutrition';

interface NutritionProps {
  formType: FormType;
  targetSpecies: string;
}

const Nutrition: FC<NutritionProps> = ({ formType, targetSpecies }) => (
    <div style={{ marginTop: `var(--dsm-spacing-px-4)` }}>
      <Grid item xs={12}>
      <DsmFieldset style={{ width: '100%'}}>
        { targetSpecies === lookupValues.animalTypeFeedAnimalSystem.PIG &&
          <PigNutrition 
            formType={formType} 
            targetSpecies={targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault} 
          />
        }
        { isPoultrySystem(targetSpecies) &&
          <PoultryNutrition 
            formType={formType} 
            targetSpecies={targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault} 
            />
        }
        { targetSpecies === lookupValues.animalTypeFeedAnimalSystem.LITOPENAEUS_VANNAMEI &&
          <ShrimpNutrition 
            formType={formType}
          />
        }
        { targetSpecies === lookupValues.animalTypeFeedAnimalSystem.BEEF &&
          <BeefNutrition 
            formType={formType} 
            targetSpecies={targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault} 
          />
        }
        { targetSpecies === lookupValues.animalTypeFeedAnimalSystem.DAIRY_V2 &&
          <DairyNutrition 
            formType={formType} 
            targetSpecies={targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault} 
          />
        }
        </DsmFieldset>
      </Grid>
    </div>
  );
export default Nutrition;
