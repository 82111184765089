import { FC } from 'react';
import { FormType } from '../types';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import ReactHookDsmInput from '../../../../modules/Helpers/ReactHookDsmInput2';

interface ShrimpNutritionProps {
  formType: FormType;
}

const ShrimpNutrition: FC<ShrimpNutritionProps> = ({ formType }) => {
  const intl = useIntl();
  const classes: any = processAndStageStylesV2();

  return (
    <DsmGrid className={classes.dsmGridThreeColumnNoRowSpacing}>
      <ReactHookDsmInput
        name="nutritional_analysis_data.crudeProteinContent"
        label={intl.formatMessage({id: 'COMPOUNDFEEDS.NUTRITION_LABEL'})}
        type="number"
        disabled={formType === FormType.View}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        adornment="%"
        size="medium"
        tooltip={intl.formatMessage({id: 'COMPOUND_FEED.NUTRITION.CRUDE_PROTEIN_CONTENT.SHRIMP.TOOLTIP'})}
      />
    </DsmGrid>
  );
}

export default ShrimpNutrition;
