
interface DefaultDatabaseType{
    databaseType: string;
}

// eslint-disable-next-line import/prefer-default-export
export const initialDBSelection : DefaultDatabaseType = {
    databaseType: 'GFLI'
};

