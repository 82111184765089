/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import useAdobeDataLayer from '../../../../../app/sustell_15/analytics/adobeDataLayer';

// import useUserData from "../../../../../app/modules/Auth/getAuthUserData";
// import Auth from "@aws-amplify/auth";
// import { useIntl } from 'react-intl';
import { useIntl } from "../../../../i18n/customUseIntl";
import { headerMenusStyles } from "../../../_core/MaterialThemeProvider";

 const UserProfileDropdown = () => {
  // const user = useUserData();
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = headerMenusStyles() as any;
  const { ctaClickEvent } = useAdobeDataLayer();
  return (
    <Dropdown drop="down" alignRight className={`${classes.mainMenuItemIcon}`}>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
      <div >
        <AccountCircleIcon className={`${classes.mainMenuItemProfile}`}/>
      </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0" style={{lineHeight:'18px'}}>
        <div className="navi navi-spacer-x-0 pt-5">
          <Link
            to="/profile"
            onClick={() =>
              ctaClickEvent(
                `/profile`,
                'link',
                'User Profile',
                'Main Menu',
                'Header'
              )
            }
            className="navi-item px-8"
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{ intl.formatMessage({id: "PROFILE.DROPDOWN.MY_PROFILE"})}</div>
                <div className="text-muted">
                  { intl.formatMessage({id: "PROFILE.DROPDOWN.ACC_SETTINGS"})}
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="separator separator-solid" />

        <div className="navi navi-spacer-x-0 pt-0">
          <div className="navi-footer  px-8 py-5">
            <Link
              className="btn btn-light-primary font-weight-bold"
              to="/signout"
              onClick={() =>
                ctaClickEvent(
                  `/signout`,
                  'link',
                  'User Profile',
                  'Main Menu',
                  'Header'
                )
              }
            >
              { intl.formatMessage({id: "PROFILE.DROPDOWN.SIGN_OUT"})}
            </Link>
          </div>
        </div>

      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserProfileDropdown;