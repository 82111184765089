import React from "react";
import { Col, Row } from "react-bootstrap";
import useUserData from '../Auth/getAuthUserData';
// import { useIntl } from 'react-intl'

import { UserGroups } from "./Users";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { useIntl } from "../../../_metronic/i18n/customUseIntl";


export const UserBaseForm = (props) => {
  const intl = useIntl();
  const usersData = useUserData();
  let {
    values: { email, firstName, lastName, enabled, groups },
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    onGroupChange,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    if (!e.target.checked) {
      setOpen(true);
    } else {
      setFieldValue("enabled", true)
    }
  };

  const handleDisagree = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setFieldValue("enabled", false)
    setOpen(false);
  };

  const change = (fieldName, e) => {
    if (fieldName === "groups") {
      onGroupChange(e);
    }

    e.persist();
    handleChange(e);
    setFieldTouched(fieldName, true, false);
  };

  let GroupsList = [];

  if(usersData!==null){
   if(usersData.signInUserSession.accessToken.payload["cognito:groups"].includes('Administrators'))
    {
      GroupsList = UserGroups;
    }
    else
    {
      GroupsList = ["Editor", "ViewerAll", "ViewerDashboard"];
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={11}>
            <TextField
              id="email"
              name="email"
              label={ intl.formatMessage({id: "GENERAL.EMAIL"})}
              placeholder={ intl.formatMessage({id: "GENERAL.EMAIL"})}
              disabled={props.formState==='view'}
              value={email}
              onChange={change.bind(null, "email")}
              helperText={touched.email ? errors.email : ""}
              error={touched.email && Boolean(errors.email)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="firstName"
              name="firstName"
              label={ intl.formatMessage({id: "GENERAL.FIRST_NAME"})}
              placeholder={ intl.formatMessage({id: "GENERAL.FIRST_NAME"})}
              disabled={props.formState==='view'}
              value={firstName}
              onChange={change.bind(null, "firstName")}
              helperText={touched.firstName ? errors.firstName : ""}
              error={touched.firstName && Boolean(errors.firstName)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="lastName"
              name="lastName"
              label={ intl.formatMessage({id: "GENERAL.LAST_NAME"})}
              placeholder={ intl.formatMessage({id: "GENERAL.LAST_NAME"})}
              disabled={props.formState==='view'}
              value={lastName}
              onChange={change.bind(null, "lastName")}
              helperText={touched.lastName ? errors.lastName : ""}
              error={touched.lastName && Boolean(errors.lastName)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <FormControl variant="outlined" fullWidth style={{ marginTop: 16 }}>
              <InputLabel htmlFor="groups">{ intl.formatMessage({id: "USERS.GROUPS"})}</InputLabel>
              <Select
                fullWidth
                input={
                  <OutlinedInput
                    label={ intl.formatMessage({id: "USERS.GROUPS"})}
                    name="groups"
                    id="groups"
                    disabled={props.formState==='view'}
                    value={groups}
                    onBlur={handleBlur}
                    onChange={change.bind(null, "groups")}
                    error={touched.groups && Boolean(errors.groups)}
                  />
                }
              >
                {Object.entries(GroupsList).map(([i, label]) => (
                  <MenuItem key={i} value={label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {touched.groups && Boolean(errors.groups) &&
                <FormHelperText error={true}>{errors.groups}</FormHelperText>
              }
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enabled}
                  disabled={props.formState === "new" || props.formState==='view' }
                  onClick={handleClickOpen}
                  name="enabled"
                />
              }
              label={ intl.formatMessage({id:"GENERAL.ENABLED"})}
            />
            <Dialog
              open={open}
              onClose={handleDisagree}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                { intl.formatMessage({ id:"USERS.DISABLE.DIALOG.TITLE" })}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  { intl.formatMessage({id: "USERS.DISABLE"},{ user: (firstName && lastName ? `${firstName} ${lastName}` : 'this user') }) }
                  
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDisagree} color="primary">
                  { intl.formatMessage({id:"USERS.DISABLE.DIALOG.DISAGREE"})}
                </Button>
                <Button onClick={handleAgree} color="primary" autoFocus>
                { intl.formatMessage({id:"USERS.DISABLE.DIALOG.AGREE"})}
                </Button>
              </DialogActions>
            </Dialog>
          </Col>
        </Row>
      </form>
    </>
  );
};
