import { Col } from "react-bootstrap";
import { useSubheader } from "../../_metronic/layout";
import Can from "../modules/Auth/Can";

import Drawer from "../../_metronic/layout/components/drawer/Drawer";
import Users from "../modules/Users/Users";
// import { useIntl } from "react-intl";
import { useIntl } from "../../_metronic/i18n/customUseIntl";

export const AdminPage = () => {
  const intl=useIntl();
  const subheader = useSubheader();

  if (Can("access", "Admin pages")) {
    subheader.setTitle(intl.formatMessage({id:"USERS" }));
  }

  return Can("access", "Admin pages") ? (
    <Drawer>
      <Col>{ <Users />}</Col>
    </Drawer>
  ) : (
    <></>
  );
};
