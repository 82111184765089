/** Add two valies that are possibly undefined */
const safeAdd = (val0?: number, val1?: number): number | undefined => {
  if (!val0 && val1) {
    return val1;
  } 
  if (val0 && val1) {
    return val0 + val1;
  } 
  if (val0 && !val1) {
    return val0;
  }
  return undefined;
}

export default safeAdd;