/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import {
  createCorePayloadSustell,
  mapResourceUseSustell,
  mapRationForOneStage,
} from './outCommonMapperSustellV2';
import { emissionFieldsMapOutPoultryV2 } from '../../Intervention/mapper/emissionFields';
import { AnimalType, StageType } from '../../../../../graphql/types';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { floatValueInDefaultUnit } from './floatHelperFunctions';
import { dashToCamelCaseString } from '../../../../sustell_15/utils/string-utils';
import { mapOutProcessingStage } from './outProcessingMapperSustell';
import {
  AnimalInputType,
  AnimalOutputType,
} from '../../../../../graphql/generated/blonk/poultry';

const mapInternalSource = (
  id,
  internalSource,
  userUOM,
  kgUnitsDefault = true
) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  const retVal = {
    id: id || uuidv4(),
    origin: 'PRODUCTION_SYSTEM',
    originFarmId: internalSource.farmId,
    originFarmName: internalSource.farmName,
    originStageId: internalSource.originStageId,
    originStageName: internalSource.stageName,
    stageType: internalSource.stageType,
    number: Number(internalSource.numberAnimals),
    averageWeight: floatValueInDefaultUnit(
      internalSource.averageWeight,
      defaultUserUnit,
      defaultUnit
    ),
    transportDistance: floatValueInDefaultUnit(
      internalSource.transportDistance,
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    ),
  };
  if (internalSource.baselineRef)
    retVal.baselineRef = internalSource.baselineRef;
  return retVal;
};

const mapExternalSource = (
  id,
  externalSource,
  userUOM,
  kgUnitsDefault = true
) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  return {
    id: id || uuidv4(),
    origin: 'BACKGROUND_DATA',
    number: externalSource.numberAnimals,
    averageWeight: floatValueInDefaultUnit(
      externalSource.averageWeight,
      defaultUserUnit,
      defaultUnit
    ),
    transportDistance: floatValueInDefaultUnit(
      externalSource.transportDistance,
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    ),
  };
};

const mapInputForOneStageBreeding = (inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.henInput = {};
  stageData.roosterInput = {};

  stageData.henInput.animals = [];
  stageData.roosterInput.animals = [];

  if (inputStage?.henSelected) {
    stageData.henInput.animalType = 'BREEDING_PULLET';
    stageData.henInput.averageWeight = floatValueInDefaultUnit(
      inputStage.henInternalSource.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.henInput.animals.push(
      mapInternalSource(inputStage.id, inputStage.henInternalSource, userUOM)
    );
  } else {
    stageData.henInput.animalType = 'BREEDING_PULLET';
    stageData.henInput.averageWeight = floatValueInDefaultUnit(
      inputStage.henExternalSource.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.henInput.animals.push(
      mapExternalSource(inputStage.id, inputStage.henExternalSource, userUOM)
    );
  }

  if (inputStage?.roosterSelected) {
    stageData.roosterInput.animalType = 'BREEDING_PULLET';
    stageData.roosterInput.averageWeight = floatValueInDefaultUnit(
      inputStage.roosterInternalSource.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.roosterInput.animals.push(
      mapInternalSource(
        inputStage.id,
        inputStage.roosterInternalSource,
        userUOM
      )
    );
  } else {
    stageData.roosterInput.animalType = 'BREEDING_PULLET';
    stageData.roosterInput.averageWeight = floatValueInDefaultUnit(
      inputStage.roosterExternalSource.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.roosterInput.animals.push(
      mapExternalSource(
        inputStage.id,
        inputStage.roosterExternalSource,
        userUOM
      )
    );
  }
};

const mapInputForOneStageGrowing = (inputStage, oneStageData, animalType) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  const internalSources = inputStage?.internalSources || [];
  const isGrowingStageInternalSource = internalSources.some(
    (source) => source?.stageType === StageType.Growing
  );

  stageData.input = {};
  stageData.input.animals = [];

  // If a growing stage is used as internal source for another growing stage
  // the input animal type should be set as the grown animal
  if (animalType === AnimalType.Turkey) {
    stageData.input.animalType = isGrowingStageInternalSource
      ? AnimalInputType.BroilerTurkey
      : AnimalInputType.DayOldTurkey;
  } else {
    stageData.input.animalType = isGrowingStageInternalSource
      ? AnimalInputType.Broiler
      : AnimalInputType.DayOldChick;
  }

  let totalAverageWeight = 0;
  let numberOfAnimals = 0;
  inputStage?.internalSources?.forEach((internalSource) => {
    numberOfAnimals += internalSource.numberAnimals;
    totalAverageWeight +=
      floatValueInDefaultUnit(
        internalSource.averageWeight,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      ) * internalSource.numberAnimals;
    stageData.input.animals.push(
      mapInternalSource(inputStage.id, internalSource, userUOM)
    );
  });

  inputStage?.externalSources?.forEach((externalSource) => {
    numberOfAnimals += externalSource.numberAnimals;
    totalAverageWeight +=
      floatValueInDefaultUnit(
        externalSource.averageWeight,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      ) * externalSource.numberAnimals;
    stageData.input.animals.push(
      mapExternalSource(inputStage.id, externalSource, userUOM)
    );
  });
  if (totalAverageWeight && numberOfAnimals)
    stageData.input.averageWeight = totalAverageWeight / numberOfAnimals;
};

const mapInputForOneStageLaying = (inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.input = {};

  stageData.input.animals = [];

  if (inputStage?.selected) {
    stageData.input.animalType = 'LAYING_PULLET';
    stageData.input.averageWeight = floatValueInDefaultUnit(
      inputStage.internalSource.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.input.animals.push(
      mapInternalSource(inputStage.id, inputStage.internalSource, userUOM)
    );
  } else {
    stageData.input.animalType = 'LAYING_PULLET';
    stageData.input.averageWeight = floatValueInDefaultUnit(
      inputStage.externalSource.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.input.animals.push(
      mapExternalSource(inputStage.id, inputStage.externalSource, userUOM)
    );
  }
};

const mapInputForOneStageHatching = (inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.input = {};

  stageData.input.animals = [];

  if (inputStage?.selected) {
    stageData.input.animalType = 'HATCHING_EGG';
    stageData.input.averageWeight = floatValueInDefaultUnit(
      inputStage.internalSource.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
    stageData.input.animals.push(
      mapInternalSource(
        inputStage.id,
        inputStage.internalSource,
        userUOM,
        false
      )
    );
  } else {
    stageData.input.animalType = 'HATCHING_EGG';
    stageData.input.averageWeight = floatValueInDefaultUnit(
      inputStage.externalSource.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
    stageData.input.animals.push(
      mapExternalSource(
        inputStage.id,
        inputStage.externalSource,
        userUOM,
        false
      )
    );
  }
};

const mapInputForOneStage = (
  stageType,
  inputStage,
  oneStageData,
  animalType
) => {
  if (stageType === StageType.Breeding) {
    mapInputForOneStageBreeding(inputStage, oneStageData);
  } else if (stageType === StageType.Growing) {
    mapInputForOneStageGrowing(inputStage, oneStageData, animalType);
  } else if (stageType === StageType.Laying) {
    mapInputForOneStageLaying(inputStage, oneStageData);
  } else {
    mapInputForOneStageHatching(inputStage, oneStageData);
  }
};

const mapStockChangeForOneStage = (stageType, inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;

  if (stageType === StageType.Growing || stageType === StageType.Hatching) {
    stageData.stockChange = {};
    if (
      inputStage.durationOfProductionCycle ||
      inputStage.durationOfProductionCycle === 0
    )
      stageData.averageProductionRoundLength = Number(
        inputStage.durationOfProductionCycle
      );
    if (inputStage.emptyPeriod || inputStage.emptyPeriod === 0)
      stageData.totalEmptyDays = Number(inputStage.emptyPeriod);
    if (inputStage.eggsPresentAtStart || inputStage.eggsPresentAtStart === 0)
      stageData.stockChange.initialAnimalStock = Number(
        inputStage.eggsPresentAtStart
      );
    if (inputStage.eggsPresentAtEnd || inputStage.eggsPresentAtEnd === 0)
      stageData.stockChange.finalAnimalStock = Number(
        inputStage.eggsPresentAtEnd
      );
    if (
      inputStage.animalsPresentAtStart ||
      inputStage.animalsPresentAtStart === 0
    )
      stageData.stockChange.initialAnimalStock = Number(
        inputStage.animalsPresentAtStart
      );
    if (inputStage.animalsPresentAtEnd || inputStage.animalsPresentAtEnd === 0)
      stageData.stockChange.finalAnimalStock = Number(
        inputStage.animalsPresentAtEnd
      );
    if (
      inputStage.averageWeightAtStart ||
      inputStage.averageWeightAtStart === 0
    )
      stageData.stockChange.initialAnimalAverageWeight =
        floatValueInDefaultUnit(
          inputStage.averageWeightAtStart,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
    if (inputStage.averageWeightAtEnd || inputStage.averageWeightAtEnd === 0)
      stageData.stockChange.finalAnimalAverageWeight = floatValueInDefaultUnit(
        inputStage.averageWeightAtEnd,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
  }
};

const initStageData = (stageType) => {
  if (stageType === StageType.Breeding) {
    return {
      resourceUse: {},
      henFeedingProgram: { compoundFeedUses: [], singleIngredientUses: [] },
      roosterFeedingProgram: { compoundFeedUses: [], singleIngredientUses: [] },
    };
  }
  if (stageType !== StageType.Hatching) {
    return {
      resourceUse: {},
      feedingProgram: { compoundFeedUses: [], singleIngredientUses: [] },
    };
  }
  return { resourceUse: {} };
};

const mapFeedForOneStage = (stageType, feedStage, oneStageData) => {
  if (stageType === StageType.Breeding && feedStage?.henFeed) {
    mapRationForOneStage(
      feedStage.henFeed,
      oneStageData.stageData.henFeedingProgram
    );
  }
  if (stageType === StageType.Breeding && feedStage?.roosterFeed) {
    mapRationForOneStage(
      feedStage.roosterFeed,
      oneStageData.stageData.roosterFeedingProgram
    );
  }
  if (stageType !== StageType.Breeding && feedStage) {
    mapRationForOneStage(feedStage, oneStageData.stageData.feedingProgram);
  }
};

const mapHousingForOneStage = (housingStage, oneStageData) => {
  const { stageData } = oneStageData;
  stageData.housingType = housingStage.housingType;
  if (stageData.housingType === 'FREE_RANGE')
    stageData.freeRangeTimePercent = housingStage.timeInFreeRange;
};

const mapManureManagemenetSystems = (housingStage, oneStageData) => {
  const { stageData } = oneStageData;
  stageData.manureManagementSystems = [];
  if ('manureSystems' in housingStage) {
    housingStage.manureSystems.forEach((item) => {
      const outputItem = {
        id: item.id || uuidv4(),
        share:
          (item.share || item.share === 0) && item.share !== ''
            ? item.share
            : 100,
        mmsType:
          item.mmsHoldingDuration && item.mmsHoldingDuration !== ''
            ? item.mmsHoldingDuration
            : item.mmsType,
      };
      stageData.manureManagementSystems.push(outputItem);
    });
  }
};

const mapBeddingForOneStage = (housingStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;

  stageData.beddingUse = {};

  housingStage.beddingSystems?.forEach((item) => {
    if (
      item.beddingType &&
      item.beddingAmount &&
      !Number.isNaN(item.beddingAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.beddingType)}Use`;
      const currValue = stageData.beddingUse[`${propName}`] || 0;
      const value = floatValueInDefaultUnit(
        Number(item.beddingAmount),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      stageData.beddingUse[`${propName}`] = currValue + value;
    }
  });
};

const mapOutputForOneStageBreeding = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  stageData.output.spentHenToFacility = 0;
  stageData.output.spentRoosterToFacility = 0;
  if (outputStage.totalEggsToStage)
    stageData.output.totalToStage = Number(outputStage.totalEggsToStage);
  if (outputStage.averageWeight)
    stageData.output.averageWeight = floatValueInDefaultUnit(
      outputStage.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (outputStage.totalEggsSold)
    stageData.output.totalSold = Number(outputStage.totalEggsSold);
  if (outputStage.priceEggs)
    stageData.output.price = Number(outputStage.priceEggs);
  if (outputStage.totalHensSold)
    stageData.output.spentHenSold = Number(outputStage.totalHensSold);
  if (outputStage.averageHensWeight)
    stageData.output.spentHenAverageWeight = floatValueInDefaultUnit(
      outputStage.averageHensWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (
    outputStage.mortalityHensAtPercent ||
    outputStage.mortalityHensAtPercent === 0
  )
    stageData.output.spentHenMortalityPercent = Number(
      outputStage.mortalityHensAtPercent
    );
  if (
    outputStage.weightHensAtMortality ||
    outputStage.weightHensAtMortality === 0
  )
    stageData.output.spentHenWeightAtMortality = floatValueInDefaultUnit(
      outputStage.weightHensAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.totalRoostersSold)
    stageData.output.spentRoosterSold = Number(outputStage.totalRoostersSold);
  if (outputStage.averageRoostersWeight)
    stageData.output.spentRoosterAverageWeight = floatValueInDefaultUnit(
      outputStage.averageRoostersWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (
    outputStage.mortalityRoostersAtPercent ||
    outputStage.mortalityRoostersAtPercent === 0
  )
    stageData.output.spentRoosterMortalityPercent = Number(
      outputStage.mortalityRoostersAtPercent
    );
  if (
    outputStage.weightRoostersAtMortality ||
    outputStage.weightRoostersAtMortality === 0
  )
    stageData.output.spentRoosterWeightAtMortality = floatValueInDefaultUnit(
      outputStage.weightRoostersAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.price || outputStage.price === 0) {
    stageData.output.spentHenPrice = Number(outputStage.price);
    stageData.output.spentRoosterPrice = Number(outputStage.price);
  }
};

const mapOutputForOneStageGrowing = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  if (outputStage.totalToStage || outputStage.totalToStage === 0)
    stageData.output.totalToStage = Number(outputStage.totalToStage);
  if (outputStage.averageWeight || outputStage.averageWeight === 0)
    stageData.output.averageWeight = floatValueInDefaultUnit(
      outputStage.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.totalSold || outputStage.totalSold === 0)
    stageData.output.totalSold = Number(outputStage.totalSold);
  if (outputStage.price || outputStage.price === 0) stageData.output.price = 1;
  if (outputStage.mortalityAtPercent || outputStage.mortalityAtPercent === 0)
    stageData.output.mortalityPercent = Number(outputStage.mortalityAtPercent);
  if (outputStage.weightAtMortality || outputStage.weightAtMortality === 0)
    stageData.output.weightAtMortality = floatValueInDefaultUnit(
      outputStage.weightAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStageLaying = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  stageData.output.totalToStage = 0;
  if (outputStage.totalSold)
    stageData.output.totalSold = Number(outputStage.totalSold);
  if (outputStage.averageWeight)
    stageData.output.averageWeight = floatValueInDefaultUnit(
      outputStage.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (outputStage.priceEggs)
    stageData.output.price = Number(outputStage.priceEggs);
  stageData.output.spentHenToFacility = 0;
  if (outputStage.totalHensToStage)
    stageData.output.spentHenSold = Number(outputStage.totalHensToStage);
  if (outputStage.averageHensWeight)
    stageData.output.spentHenAverageWeight = floatValueInDefaultUnit(
      outputStage.averageHensWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.priceHens)
    stageData.output.spentHenPrice = Number(outputStage.priceHens);
  if (outputStage.mortalityAtPercent || outputStage.mortalityAtPercent === 0)
    stageData.output.spentHenMortalityPercent = Number(
      outputStage.mortalityAtPercent
    );
  if (outputStage.weightAtMortality || outputStage.weightAtMortality === 0)
    stageData.output.spentHenWeightAtMortality = floatValueInDefaultUnit(
      outputStage.weightAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStageHatching = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  if (outputStage.totalToStage || outputStage.totalToStage === 0)
    stageData.output.totalToStage = Number(outputStage.totalToStage);
  if (outputStage.totalSold || outputStage.totalSold === 0)
    stageData.output.totalSold = Number(outputStage.totalSold);
  if (outputStage.averageWeight || outputStage.averageWeight === 0)
    stageData.output.averageWeight = floatValueInDefaultUnit(
      outputStage.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (outputStage.price || outputStage.price === 0)
    stageData.output.price = Number(outputStage.price);
  if (outputStage.lossRate || outputStage.lossRate === 0)
    stageData.output.totalWeightLosses = floatValueInDefaultUnit(
      outputStage.lossRate,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.priceOfLossesSold || outputStage.priceOfLossesSold === 0)
    stageData.output.lossesPrice = Number(outputStage.priceOfLossesSold);
  if (outputStage.totalEggShellsSold || outputStage.totalEggShellsSold === 0)
    stageData.output.totalWeightEggShellSoldOrWaste = floatValueInDefaultUnit(
      outputStage.totalEggShellsSold,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStage = (stageType, outputStage, oneStageData) => {
  if (stageType === StageType.Breeding)
    mapOutputForOneStageBreeding(outputStage, oneStageData);
  else if (stageType === StageType.Growing)
    mapOutputForOneStageGrowing(outputStage, oneStageData);
  else if (stageType === StageType.Laying)
    mapOutputForOneStageLaying(outputStage, oneStageData);
  else mapOutputForOneStageHatching(outputStage, oneStageData);
};

const mapEmissionForOneStage = (emissionFormData, emissionDataForOneStage) => {
  emissionFieldsMapOutPoultryV2.forEach((value, key) => {
    if (key in emissionFormData) {
      const valueNum = parseFloat(emissionFormData[key]);
      emissionDataForOneStage.push({ emissionType: value, change: valueNum });
    }
  });
};

const getOriginStage = (formData, dbDataForOneStage) =>
  formData.stages.find(
    (st) => st.id === dbDataForOneStage.stageData.processingInput?.originStageId
  );

const mapAnimalOuputTypeIntoFacilityInputs = (
  formData,
  generalDataForStage,
  animalType
) => {
  const originStage = getOriginStage(formData, generalDataForStage);

  if (!originStage) return generalDataForStage;
  const stageToAddAnimalOutputType = { ...generalDataForStage };

  if (animalType === AnimalType.Turkey) {
    stageToAddAnimalOutputType.stageData.processingInput.name =
      AnimalOutputType.BroilerTurkey;
  } else {
    stageToAddAnimalOutputType.stageData.processingInput.name =
      AnimalOutputType.Broiler;
  }

  return stageToAddAnimalOutputType;
};

const mapAverageWeightForProcessingStage = (formData, generalDataForStage) => {
  if (!generalDataForStage?.stageData?.processingInput)
    return generalDataForStage;

  const originStage = getOriginStage(formData, generalDataForStage);
  const stageToSetAvgWeight = { ...generalDataForStage };
  // if processing input -> origin stage is set (needed when data is saved to local storage as a draft)
  if (originStage && originStage.type === StageType.Growing) {
    const averageWeightOrigin = originStage?.stageData?.output?.averageWeight;

    stageToSetAvgWeight.stageData.processingInput.averageWeight =
      averageWeightOrigin;
    // For processing stage the avgWeight is the freshMeatAndEdibles output of the linked slaughtering stage, and quantity set to 1
  } else {
    const averageWeightOrigin =
      originStage?.stageData?.processingOutput?.freshMeatAndEdibleOffal?.weight;
    stageToSetAvgWeight.stageData.processingInput.averageWeight =
      averageWeightOrigin;
    stageToSetAvgWeight.stageData.processingInput.quantity = 1;
  }

  return stageToSetAvgWeight;
};

const mapStageDataForProcessingStage = (
  formData,
  generalDataForStage,
  animalType
) => {
  const stageWithAvgWeight = mapAverageWeightForProcessingStage(
    formData,
    generalDataForStage
  );
  const stageWithAnimalOutput = mapAnimalOuputTypeIntoFacilityInputs(
    formData,
    stageWithAvgWeight,
    animalType
  );
  return mapOutProcessingStage(stageWithAnimalOutput);
};

const mapPoultryDataOutSustell = (formData, animalType, isDraft = false) => {
  let payloadObj = createCorePayloadSustell(formData, isDraft);

  if (formData.stages && Array.isArray(formData.stages)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formData.stages.length; i++) {
      const generalDataForStage = formData.stages[i];

      if (generalDataForStage.type === StageType.Processing) {
        const processingStageMappedData = mapStageDataForProcessingStage(
          formData,
          generalDataForStage,
          animalType
        );
        payloadObj.stages.push(processingStageMappedData);
      } else {
        const formDataForStage = generalDataForStage.stageData || null;
        if (formDataForStage) {
          const oneStageData = {
            name: generalDataForStage.name,
            id: generalDataForStage.id || uuidv4(),
            type: generalDataForStage.type,
            startDate: formDataForStage.input?.startDate,
            endDate: formDataForStage.input?.endDate,
            stageData: { ...initStageData(generalDataForStage.type) }, // Init different structure for breeding stage
          };
          // Mapping inputs
          mapInputForOneStage(
            generalDataForStage.type,
            formDataForStage.input,
            oneStageData,
            animalType
          );
          // Mapping stock changes
          mapStockChangeForOneStage(
            generalDataForStage.type,
            formDataForStage.input,
            oneStageData
          );
          // Mapping feeds
          if (generalDataForStage.type !== StageType.Hatching)
            mapFeedForOneStage(
              generalDataForStage.type,
              formDataForStage.feed,
              oneStageData
            );
          // Map resource use on stage level
          mapResourceUseSustell(
            formDataForStage.housing,
            oneStageData.stageData
          );
          // Mapping Housing type
          if (generalDataForStage.type !== StageType.Hatching)
            mapHousingForOneStage(formDataForStage.housing, oneStageData);
          // Mapping Manure Management Systems
          if (generalDataForStage.type !== StageType.Hatching)
            mapManureManagemenetSystems(formDataForStage.housing, oneStageData);
          // Mapping Bedding types
          if (generalDataForStage.type !== StageType.Hatching)
            mapBeddingForOneStage(formDataForStage.housing, oneStageData);
          // Mapping outputs
          mapOutputForOneStage(
            generalDataForStage.type,
            formDataForStage.output,
            oneStageData
          );
          // Mapping emissions
          if (
            'emissions' in formDataForStage &&
            generalDataForStage.type !== StageType.Hatching
          ) {
            oneStageData.stageData.emissionMitigations = [];
            if (formDataForStage.emissions)
              mapEmissionForOneStage(
                formDataForStage.emissions,
                oneStageData.stageData.emissionMitigations
              );
          }
          payloadObj.stages.push(oneStageData);
        }
      }
    }
  }
  payloadObj = mapResourceUseSustell(formData, payloadObj);
  payloadObj.stages.forEach((item) => {
    const { stageData } = item;
    // eslint-disable-next-line no-param-reassign
    item.stageData = JSON.stringify(stageData);
  });
  return payloadObj;
};

export default mapPoultryDataOutSustell;
