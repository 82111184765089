import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmIcon, DsmButton, DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType, ListEntry, MMSSystemsBlockProps } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { ManureManagementSystem } from '../../../../models/Baseline/PoultryBaseline';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

type ReactChangedType = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const PoultryMMSBlockComponent: FC<MMSSystemsBlockProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType = FormType.View,
  manureManagementSystems,
  filteredMMSList,
  removeHandler,
  mmsChangeHandler,
  isAdditions = false,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${stageIndex}.stageData.housing.manureSystems${
    isAdditions ? 'Additions' : ''
  }.${itemIndex}`;
  const [matchingStorages, setMatchingStorages] = useState<ListEntry[]>([]);
  const fc = useFormContext();

  const showShare = (): boolean => {
    const manureSystems =
      (fc.getValues(
        `stages.${stageIndex}.stageData.housing.manureSystems`
      ) as ManureManagementSystem[]) || [];
    if (isAdditions) {
      const manureSystemsAdditions =
        (fc.getValues(
          `stages.${stageIndex}.stageData.housing.manureSystemsAdditions`
        ) as ManureManagementSystem[]) || [];
      return manureSystems.length + manureSystemsAdditions.length > 1;
    }
    return manureSystems.length > 1;
  };

  const filterMatchingStorage = (value: string) => {
    const selBaseName = value;
    if (selBaseName) {
      const newMatchingStorages =
        manureManagementSystems?.filter(
          (item) =>
            item.value.startsWith(selBaseName) && item.value.endsWith('MONTH')
        ) || [];
      newMatchingStorages.forEach((item) => {
        const currItem = item;
        const firstDigit = item.text.search(/\d/);
        let substringIndex = firstDigit;
        const overIndex = item.text.lastIndexOf(' under');
        const underIndex = item.text.lastIndexOf(' over');
        if (overIndex > -1) substringIndex = overIndex;
        if (underIndex > -1) substringIndex = underIndex;
        currItem.text = item.text
          .substring(substringIndex)
          .replace('month', ' month');
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setMatchingStorages(newMatchingStorages);
      if (newMatchingStorages.length === 0)
        fc.setValue(`${fieldItemPrefix}.mmsHoldingDuration`, '');
    }
  };

  const revalidateMMS = () => {
    fc.trigger(
      `stages.${stageIndex}.stageData.housing.manureSystems${
        isAdditions ? 'Additions' : ''
      }`
    )
      .then(() => {})
      .catch(() => {});
  };

  const onMMSChange2 = () => {
    if (mmsChangeHandler) mmsChangeHandler();
    revalidateMMS();
  };

  const onMMSChange = (e: ReactChangedType) => {
    const eventTarget = e.target;
    filterMatchingStorage(eventTarget.value as string);
    if (mmsChangeHandler) mmsChangeHandler();
    // if not showin share, set 100
    if (!showShare()) fc.setValue(`${fieldItemPrefix}.share`, '100');
    revalidateMMS();
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const currentMMSCount = ((
    fc.getValues(
      `stages.${stageIndex}.stageData.housing.manureSystems${
        isAdditions ? 'Additions' : ''
      }`
    ) || []
  )?.length || 0) as number;
  const className =
    currentMMSCount > 1 || isAdditions
      ? classes.additionalEntriesBlockHolder
      : '';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    filterMatchingStorage(fc.getValues(`${fieldItemPrefix}.mmsType`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {removeHandler && (isAdditions || currentMMSCount > 1) && (
        <DsmButton
          variant="text"
          style={{ position: 'relative', width: '2%', left: '98%' }}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          onClick={() => removeHandler(itemIndex)}
        >
          <DsmIcon
            name="general/x-close"
            style={{
              position: 'absolute',
              color: 'var(--dsm-color-neutral-darker',
              right: '0',
            }}
          />
        </DsmButton>
      )}
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue={fc.getValues(`${fieldItemPrefix}.id`) || ''}
      />
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <ReactHookDsmSelect
          label={intl.formatMessage({ id: 'SUSTELL.STAGE.POULTRY.MANURE.MMS' })}
          name={`${fieldItemPrefix}.mmsType`}
          options={filteredMMSList}
          changeHandler={onMMSChange}
          required
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.mmsType`) || ('' as string)
          }
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.MANURE.MMS.TOOLTIP',
          })}
        />
        {matchingStorages && matchingStorages.length > 0 && (
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.MANURE.HOLDING_PERIOD',
            })}
            name={`${fieldItemPrefix}.mmsHoldingDuration`}
            options={matchingStorages}
            required
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={
              fc.getValues(`${fieldItemPrefix}.mmsHoldingDuration`) || ''
            }
            disabled={formType === FormType.View}
            changeHandler={onMMSChange2}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.MANURE.HOLDING_PERIOD.TOOLTIP',
            })}
          />
        )}
        {showShare() && (
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.share`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.HOUSING.MMS.SHARE',
            })}
            disabled={formType === FormType.View}
            adornment="%"
            required
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={fc.getValues(`${fieldItemPrefix}.share`) || ''}
            changeHandler={revalidateMMS}
          />
        )}
      </DsmGrid>
    </div>
  );
};

export default PoultryMMSBlockComponent;
