import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  defaultValuesForMortalityData,
  nameMapping,
} from '../../validationSchemaRestrictions';
import {
  floatValueInUserUnits,
  mapCommonProductionDataIn,
  mapResourceUse,
} from './inCommonMapperSustell';

const ANIMAL_TYPE='Broiler';

const mapBroilerProductionDataInSustell = (
  inData,
  isDraft = false
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  // console.log("inData: ",inData);

  const formData = mapCommonProductionDataIn(inData, isDraft);
  mapResourceUse(inData.resourceUse, formData.resourceUse);

  if(inData.stages && Array.isArray(inData.stages)){
    inData.stages.forEach( (stage, index) => {
      const stageFormData = formData.stages[index]?.stageData;
      if(stageFormData){
        const stageData =JSON.parse(stage.stageData)
        
        // map output
        stageFormData.output.liveWeight = floatValueInUserUnits(
          stageData.broilers_to_slaughter?.total_weight?.value,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );

        stageFormData.animalType = nameMapping[stageData.animal_group.animal_type];

        stageFormData.input.purchasedOneDayChickens =
          stageData.purchased_one_day_chickens?.number_of_animals.value;

        const defaultMortalityRate = !isDraft && defaultValuesForMortalityData[ANIMAL_TYPE]
        ? defaultValuesForMortalityData[ANIMAL_TYPE].mortalityRate
        : undefined;

        stageFormData.input.mortalityRate =
          stageData?.mortality?.mortality_rate?.value ||
          stageData?.mortality?.mortality_rate?.value === 0
            ? stageData?.mortality?.mortality_rate?.value
            : defaultMortalityRate;
        
        // set loaded value or default if not draft
        const defaultAverageLiveWeightMortalities = 
          !isDraft && defaultValuesForMortalityData[ANIMAL_TYPE]
          ? defaultValuesForMortalityData[ANIMAL_TYPE].averageLiveweightMortality
          : undefined;
          
        stageFormData.input.averageLiveWeightMortalities =
          stageData?.mortality?.average_live_weight_mortalities?.value ||
          defaultAverageLiveWeightMortalities;
        
        // do conversion
        if (
          stageFormData.input.averageLiveWeightMortalities ||
          stageFormData.input.averageLiveWeightMortalities === 0
        ) {
          stageFormData.input.averageLiveWeightMortalities = floatValueInUserUnits(
            stageFormData.input.averageLiveWeightMortalities,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            outputMassPrecision
          );
        }

        stageFormData.input.housingType =
          stageData?.housing_description?.housing_type;
      
      }
    })
  }


  return formData;
};

export default mapBroilerProductionDataInSustell