/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from '@reduxjs/toolkit';
import { TypeUnitValue } from '../../../graphql/types';
import { defaultUnits } from '../../sustell_15/utils/unit-utils';
import {
  CompoundFeedFormExtended,
  FeedFormFields,
} from '../../sustell_15/components/CompoundFeedWizard/types';
import { isFeedV2CFDatabaseFoundation } from '../../sustell_15/components/CompoundFeedWizard/utils';
import { CFDatabaseFoundation } from '../../sustell_15/models/CompoundFeed';

// Use object instead of Map (suggestion from redux team, non-serializable types should not be put inside states)
export type DraftsMapType = {
  [draftKey: string]: CompoundFeedFormExtended;
};

export const initialState: { value: DraftsMapType } = {
  value: {},
};

export interface SetFeedDraftAction {
  readonly type: 'feedDrafts/setFeedDraftAction';
  payload: {
    key: string;
    formValues: CompoundFeedFormExtended;
    beforeConversionFormFields: FeedFormFields;
  };
}

export interface DeleteDraftAction {
  readonly type: 'feedDrafts/clearDraftAction';
  payload: { key: string };
}

export const feedDraftsSlice = createSlice({
  name: 'feedDrafts',
  initialState,
  reducers: {
    setFeedDraftAction: (state, action: SetFeedDraftAction) => {
      const newState = action.payload.formValues;
      // console.log('drafting')
      // populate empty fields for proper restore
      newState.ingredient_uses?.forEach((ing: any) => {
        const fullIngredientUse = (
          action.payload.beforeConversionFormFields.ingredient_uses || []
        ).find(
          (ing1) => ing1.name === ing.requested_ingredient?.ingredient_name?.id
        );

        if (
          isFeedV2CFDatabaseFoundation(
            action.payload.beforeConversionFormFields.databaseFoundation
          ) &&
          ing.requested_ingredient?.ingredient_name?.id
        ) {
          ing.requested_ingredient.ingredient_name.name =
            ing.requested_ingredient.ingredient_name.id;
          // ing.requested_ingredient.ingredient_name.id = ing.requested_ingredient.origin;
        }

        if (
          fullIngredientUse?.proxyOrigin &&
          fullIngredientUse.proxyOriginCode
        ) {
          // eslint-disable-next-line no-param-reassign
          // ing.ingredient_id = fullIngredientUse.proxyOriginCode;
          ing.proxyOrigin = true;
          // const originalOrigin = ing.requested_ingredient.origin;
          // ing.requested_ingredient.origin = fullIngredientUse?.proxyOrigin;
          // ing.requested_ingredient.id = fullIngredientUse?.proxyOrigin;
          ing.ingredient = {
            ingredient_name: {
              name: fullIngredientUse.name,
            },
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            origin: fullIngredientUse?.proxyOriginCode,
          };
          // if (action.payload.beforeConversionFormFields.databaseFoundation === CFDatabaseFoundation.GFLI) {
          //   ing.ingredient.ingredient_name.id = fullIngredientUse?.proxyOriginCode;
          // }
        }
        if (!ing.inbound_transport || ing.inbound_transport === null) {
          // eslint-disable-next-line no-param-reassign
          ing.inbound_transport = [];
        }

        if (
          ing.inbound_transport?.findIndex(
            (el: { type: string }) => el.type === 'truck'
          ) === -1
        ) {
          ing.inbound_transport.push({
            type: 'truck',
            value: null,
            unit: defaultUnits.unitTransportDistanceTerrestrial,
          });
        }
        if (
          ing.inbound_transport?.findIndex(
            (el: { type: string }) => el.type === 'sea ship'
          ) === -1
        ) {
          ing.inbound_transport.push({
            type: 'sea ship',
            value: null,
            unit: defaultUnits.unitTransportDistanceSea,
          });
        }
        if (
          ing.inbound_transport?.findIndex(
            (el: { type: string }) => el.type === 'train'
          ) === -1
        ) {
          ing.inbound_transport.push({
            type: 'train',
            value: null,
            unit: defaultUnits.unitTransportDistanceTerrestrial,
          });
        }
        if (
          ing.inbound_transport?.findIndex(
            (el: { type: string }) => el.type === 'inland ship'
          ) === -1
        ) {
          ing.inbound_transport.push({
            type: 'inland ship',
            value: null,
            unit: defaultUnits.unitTransportDistanceInlandWaterways,
          });
        }
      });

      // console.log(newState.on_site_activities)
      if (
        !newState.on_site_activities ||
        newState.on_site_activities === null
      ) {
        // eslint-disable-next-line no-param-reassign
        newState.on_site_activities = [];
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'electricity'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'electricity',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'electricityPhotovoltaic'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'electricityPhotovoltaic',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'electricityWind'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'electricityWind',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'diesel'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'diesel',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'gasoline'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'gasoline',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'propaneOrLpg'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'propaneOrLpg',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'coal'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'coal',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedElectricity}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'gas'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'gas',
          value: null,
          unit: `${defaultUnits.unitNaturalGasUse}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'heat'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'heat',
          value: null,
          unit: `${defaultUnits.unitHeatUse}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }
      if (
        newState.on_site_activities?.findIndex(
          (el: { type: string }) => el.type === 'water'
        ) === -1
      ) {
        (newState.on_site_activities as TypeUnitValue[]).push({
          type: 'water',
          value: null,
          unit: `${defaultUnits.unitCompoundFeedWaterUse}/${defaultUnits.unitCompoundFeedOutputMass} feed`,
        });
      }

      if (
        action.payload.beforeConversionFormFields.databaseFoundation ===
        CFDatabaseFoundation.GFLI
      ) {
        newState.nutritional_analysis_data = {
          crudeProteinContent:
            action.payload.beforeConversionFormFields.nutritional_analysis_data
              ?.crudeProteinContent,
        };
      }
      newState.databaseFoundation = action.payload.beforeConversionFormFields
        .databaseFoundation
        ? action.payload.beforeConversionFormFields.databaseFoundation
        : undefined;
      // console.log('saved to draft', newState);
      // eslint-disable-next-line no-param-reassign
      state.value[action.payload.key] = newState;
    },
    // On form sucesful submit we should clear the existing draft
    clearDraftAction: (state, action: DeleteDraftAction) => {
      if (!action.payload.key) {
        console.warn('cannot clear draft for undefined key');
      }
      // eslint-disable-next-line no-param-reassign
      delete state.value[action.payload.key];
    },
  },
});

export const { setFeedDraftAction, clearDraftAction } = feedDraftsSlice.actions;

export default feedDraftsSlice.reducer;
