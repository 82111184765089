/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
import { DsmIcon } from '@dsm-dcs/design-system-react';
import {
  CardContent,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Popover,
} from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { type FC, MouseEvent } from 'react';
import { Card } from 'react-bootstrap';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { DsmButtonV2 } from '../helpers/DsmButton';
import { compoundFeedsStyles } from '../../../../_metronic/layout';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

interface TableSearchHeaderProps {
  title: string;
  description: string;
  showCompareFootprints: boolean;
  showTableFilter?: boolean;
  tableFilterComponent?: JSX.Element;
  isCompareFootprintsDisabled: boolean;
  compareButtonText: string;
  searchPlaceholder: string;
  showSearch: boolean;
  search: (value: string) => void;
  handleCompareFootprint?: () => Promise<void>;
}

const TableSearchHeader: FC<TableSearchHeaderProps> = ({
  title,
  description,
  showCompareFootprints = false,
  showTableFilter,
  tableFilterComponent,
  isCompareFootprintsDisabled,
  compareButtonText,
  searchPlaceholder,
  showSearch,
  search,
  handleCompareFootprint = () => Promise.resolve(),
}) => {
  const classes = compoundFeedsStyles();
  const intl = useIntl();

  const compareFootprintsHandler = (
    e: MouseEvent<HTMLDsmButtonElement, MouseEvent>
  ) => {
    if (showCompareFootprints) {
      e.preventDefault();
      handleCompareFootprint().catch((e) => console.error(e));
    }
  };

  const alignCentered = { display: 'flex', alignItems: 'center' };

  return (
    <Card
      className={classes.table}
      style={{ borderBottom: 'none' }}
      key="title-search-button"
    >
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs>
            <Typography
              className={classes.tableTitle}
              variant="h6"
              style={{ color: 'black' }}
            >
              {title}
            </Typography>
            <Typography className={classes.tableDescription}>
              {description}
            </Typography>
          </Grid>
          <>
            {showCompareFootprints && (
              <Grid item xs={2} style={alignCentered}>
                <DsmButtonV2
                  onClick={compareFootprintsHandler}
                  disabled={isCompareFootprintsDisabled}
                >
                  <DsmIcon
                    name="arrows/switch-horizontal-01"
                    style={{
                      paddingRight: '5px',
                      height: '24px',
                      width: '24px',
                    }}
                  />
                  {compareButtonText}
                </DsmButtonV2>
              </Grid>
            )}
            {showTableFilter && (
              <PopupState variant="popover" popupId="table-popup-popover">
                {(popupState) => (
                  <>
                    <Grid item xs={1} style={alignCentered}>
                      <DsmButtonV2
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindTrigger(popupState)}
                      >
                        <DsmIcon
                          name="general/filter-lines"
                          style={{
                            paddingRight: '5px',
                            height: '24px',
                            width: '24px',
                          }}
                        />
                        {intl.formatMessage({
                          id: 'GENERAL.FILTER',
                        })}
                      </DsmButtonV2>
                    </Grid>
                    <Popover
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {tableFilterComponent}
                    </Popover>
                  </>
                )}
              </PopupState>
            )}
            {showSearch && (
              <Grid item xs={3} style={alignCentered}>
                <TextField
                  className={classes.searchInput}
                  size="small"
                  name="search"
                  placeholder={`${searchPlaceholder}...`}
                  onChange={(e) => search(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined htmlColor="darkgray" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            )}
          </>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TableSearchHeader;
