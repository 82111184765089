import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from "@material-ui/core";
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { SalmonBaseline, SalmonOutput } from '../../../../models/Baseline/SalmonBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup";
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { BaselineDialogProps, FormType } from '../../common';

const SOutputFormDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel,
  handleSave=handleCancel
} ) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<SalmonOutput>();

  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getValues, reset } = useFormContext<SalmonBaseline>();

  useEffect(() => {
    if(formVisible) {
      currResetValue.current={...getValues(fieldItemPrefix)} as SalmonOutput; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    // console.log("resetValue output:", currResetValue.current);
    if(currResetValue.current){
      const resetObject = {...getValues()};
      if(resetObject.stages[itemIndex]?.stageData?.output){
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        reset(resetObject, {
          errors: true
        });
      }
    }
    handleCancel();
  }

  return (
    <DialogContainer
      formVisible = { formVisible }
      handleClose = { handleResetClick }
      iconCode="SALMON"
      formTitle = { formTitle }
      variant="wide"
    > 

      <Grid container spacing={3} direction="row" justifyContent="space-between" >
        <Grid item container direction="column" xs={6} spacing={3} >
          <Grid item>
            <RowTextFieldWithInfo
                name={`${fieldItemPrefix}.production`}
                label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.PRODUCTION"})} *`}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.PRODUCTION_TT"})}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
                disabled={formType ===FormType.View}
                type="number"
                defaultValue = { getValues(`${fieldItemPrefix}.production`) }
            />
          </Grid>
          <Grid item>  
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.averageWeight`}
              label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.AVG_WEIGHT"})} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.AVG_WEIGHT_TT"})}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType ===FormType.View}
              type="number"
              defaultValue = { getValues(`${fieldItemPrefix}.averageWeight`) }
          />
          </Grid>
          <Grid item>  
            <RowTextFieldWithInfo
                name={`${fieldItemPrefix}.mortalities`}
                label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.MORTALITIES"})} *`}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.MORTALITIES_TT"})}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
                disabled={formType ===FormType.View}
                type="number"
                defaultValue = { getValues(`${fieldItemPrefix}.mortalities`) }
            />
          </Grid>
          <Grid item>  
            <RowTextFieldWithInfo
                name={`${fieldItemPrefix}.escapees`}
                label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.ESCAPEES"})} *`}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.ESCAPEES_TT"})}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
                disabled={formType ===FormType.View}
                type="number"
                defaultValue = { getValues(`${fieldItemPrefix}.escapees`) }
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={3} >
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.animalBiomassStartOfYear`}
              label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS"})} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS_TT"})}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType ===FormType.View}
              type="number"
              defaultValue = { getValues(`${fieldItemPrefix}.animalBiomassStartOfYear`) }
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.liveWeight`}
              label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.AVG_SIZE_AGE"})} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.AVG_SIZE_AGE_TT"})}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType === "view"}
              type="number"
              defaultValue = { getValues(`${fieldItemPrefix}.liveWeight`) }
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.animalBiomasEndOfYear`}
              label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS_PRESENT"})} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS_PRESENT_TT"})}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType ===FormType.View}
              type="number"
              defaultValue = { getValues(`${fieldItemPrefix}.animalBiomasEndOfYear`) }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleSave }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" })  }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default SOutputFormDialog;