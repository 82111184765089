import { DsmCheckbox, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { Box, Tooltip } from '@material-ui/core';
import { DsmCheckboxCustomEvent } from '@dsm-dcs/design-system/dist/types/components';
import { CheckboxChangeEvent } from '@dsm-dcs/design-system/dist/types/types/form-change-event';

interface DairyV2FeedMothersMilkComponentProps {
  fieldItemPrefix: string;
  formType: FormType;
}

const DairyV2FeedMothersMilkComponent: FC<DairyV2FeedMothersMilkComponentProps> = ({
  fieldItemPrefix,
  formType,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const mothersMilkAmountWatch = useWatch({
    name: `${fieldItemPrefix}.amount`,
  });
  const [isMotherMilk, setIsMotherMilk] = useState<boolean>(
    formContext.getValues(`${fieldItemPrefix}.isMothersMilk`)
  );

  const updateInputDisplay = (e: DsmCheckboxCustomEvent<CheckboxChangeEvent>) => {
    const newState = e.target?.checked;
    formContext.setValue(`${fieldItemPrefix}.isMothersMilk`, newState);
    setIsMotherMilk(newState);
  }

  useEffect(() => {
    if (mothersMilkAmountWatch) {
      formContext.setValue(`${fieldItemPrefix}.milkFromMother`, 'true');
    } else {
      formContext.setValue(`${fieldItemPrefix}.milkFromMother`, 'false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mothersMilkAmountWatch]);

  useEffect(() => {
    if (!isMotherMilk) {
      formContext.setValue(`${fieldItemPrefix}.amount`, undefined);
      formContext.setValue(`${fieldItemPrefix}.dryMatterContent`, undefined);
      formContext.setValue(`${fieldItemPrefix}.crudeProteinContent`, undefined);
      formContext.setValue(`${fieldItemPrefix}.digestibility`, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMotherMilk]);

  return (
    <div
      style={{
        border: '1px solid var(--dsm-color-neutral-lighter)',
        padding: '16px 16px 0px 16px',
        marginBottom: '16px',
      }}
    >
      <div>
        <input
          ref={formContext.register()}
          type="hidden"
          name={`${fieldItemPrefix}.milkFromMother`}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.milkFromMother`) || 'false'
          }
        />
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <div className={classes.resetButtonContainer}>
            <DsmCheckbox
              name={`${fieldItemPrefix}.isMothersMilk`}
              defaultValue={
                formContext.getValues(`${fieldItemPrefix}.isMothersMilk`) as string
              }
              checked={formContext.getValues(`${fieldItemPrefix}.isMothersMilk`) as boolean}
              disabled={formType === FormType.View}
              onDsmChange={(e) => updateInputDisplay(e)}
              label={intl.formatMessage({
                id: `SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.MOTHERS_MILK_TO_CALVES`,
              })}
              style={{
                marginBottom: '16px',
              }}
            />
            <Tooltip
              title={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.MOTHERS_MILK_TO_CALVES',
              })}
              placement="left"
              className='ml-3'
              style={{transform: 'translateY(10%)'}}
            >
              <DsmIcon
                name="general/help-circle"
                className={classes.inlineTooltipClass}
                title=""
              />
            </Tooltip>
          </div>
          <Box/>
          {isMotherMilk && (
            <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
              <ReactHookDsmInput
                type="number"
                name={`${fieldItemPrefix}.amount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT.TOOLTIP',
                })}
                adornment={barnOutputMassUnit}
                disabled={formType === FormType.View}
                defaultValue={
                  formContext.getValues(`${fieldItemPrefix}.amount`) as string
                }
                required
              />
              <ReactHookDsmInput
                type="number"
                name={`${fieldItemPrefix}.dryMatterContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.PLACEHOLDER',
                })}
                disabled={formType === FormType.View}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.dryMatterContent`
                  ) as string
                }
                required
              />
            </DsmGrid>
          )}
          {isMotherMilk && (
            <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
              <ReactHookDsmInput
                type="number"
                name={`${fieldItemPrefix}.crudeProteinContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.PLACEHOLDER',
                })}
                disabled={formType === FormType.View}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.crudeProteinContent`
                  ) as string
                }
                required
              />
              <ReactHookDsmInput
                type="number"
                name={`${fieldItemPrefix}.digestibility`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.PLACEHOLDER',
                })}
                disabled={formType === FormType.View}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.digestibility`
                  ) as string
                }
                required
              />
            </DsmGrid>
          )}
        </DsmGrid>
      </div>
    </div>
  );
};

export default DairyV2FeedMothersMilkComponent;
