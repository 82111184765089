import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from "@material-ui/core";

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { DairyBaseline, DairyOutput } from '../../../../models/Baseline/DairyBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup";
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { BaselineDialogProps } from '../../common';

const DAIRY_COWS = 'Dairy cows';

interface DOutputFormDialogProps extends BaselineDialogProps {
  stageType: string;
}

const DOutputFormDialog: FC<DOutputFormDialogProps> = ({
  formType='add',
  itemIndex=0,
  formVisible,
  handleCancel,
  handleSave=handleCancel,
  stageType,
} ) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<DairyOutput>();

  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  // const { getValues, reset } = useFormContext();
  const formContext = useFormContext();

  useEffect(() => {
    if(formVisible){
      currResetValue.current={...formContext.getValues(fieldItemPrefix)} as DairyOutput; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as DairyBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.output){
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        formContext.reset(resetObject, {
          errors: true
        });
      }
    }
    handleCancel();
  }

  return (
    <DialogContainer
      formVisible = { formVisible }
      handleClose = { handleResetClick }
      iconCode="DAIRY"
      formTitle = { formTitle }
    > 
      <Grid container direction="column" spacing={3} justifyContent="space-between" >
        { stageType === DAIRY_COWS && 
        <>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.milk`}
              label={ `${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.MILK"})} *` }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.MILK.TOOLTIP" }) }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType === "view"}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.milk`)}
          />
        </Grid>
        <Grid item xs={12} >  
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.proteinContent`}
              label={ `${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.PROTEIN_CONTENT"})} *` }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.PROTEIN_CONTENT.TOOLTIP" }) }
              addornment="%"
              disabled={formType === "view"}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.proteinContent`)}
          />
        </Grid>
        <Grid item xs={12} >  
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.fatContent`}
              label={ `${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.FAT_CONTENT"})} *`}
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage( {id:"BASELINE.FORM.OUTPUT.DAIRY.FAT_CONTENT.TOOLTIP" })}
              addornment="%"
              disabled={formType === "view"}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.fatContent`)}
          />
        </Grid>
        </>
        }
        <Grid item xs={12} >  
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.liveWeight`}
              label={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.LIVEWEIGHT"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.DAIRY.LIVEWEIGHT.TOOLTIP"}) }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType === "view"}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.liveWeight`)}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleSave }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" })  }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default DOutputFormDialog;