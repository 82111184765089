/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { AnimalType } from '../../../../../../graphql/types';
import {
  PoultryHatchingStageData,
  PoultryIntervention,
} from '../../../../models/Intervention/PoultryIntervention';
import { InterventionResourceUseV2 } from '../../../../models/Intervention';
import InterventionResources from '../CommonDataParts/InterventionResources';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

export interface PoultryHousingHatchingDialogProps extends BaselineDialogProps {
  animalType: AnimalType;
}

const PoultryHousingHatchingDialog: FC<PoultryHousingHatchingDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  animalType,
  baseline,
}) => {
  const intl = useIntl();
  const currResetValue = useRef<InterventionResourceUseV2>();
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.HOUSING.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject?.stages?.[itemIndex]
        ?.stageData as PoultryHatchingStageData;
      if (stageData?.housing) {
        stageData.housing = { ...currResetValue.current };
        fc.reset(resetObject, { errors: true });
      }
    }
    handleCancel();
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      variant="demiWide"
    >
      <InterventionResources
        formType={formType}
        inputType={`stages.${itemIndex}.stageData.housing.resourceUse`}
        animalType={animalType}
        baseline={baseline}
        stageDataResourceUse={
          baseline.stages[itemIndex].stageData.housing.resourceUse
        }
        isInternventionDialog
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await fc.trigger(`stages.${itemIndex}.stageData.housing`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryHousingHatchingDialog;
