import { FC, useState } from 'react';
import Select from 'react-select';

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { Close, SwapHoriz } from '@material-ui/icons';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  DSM_MAIN_SELECTED_COLOR,
  footprintDetailsStyles,
} from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import { FootprintModel } from '../../models/Footprint';
import {
  CalcType,
  EmissionOptions,
  PeriodType,
} from '../../models/Footprint/FootprintTypes';
import FootprintEmissionTable from './FootprintEmissionTable';
import useFootprintCompare, { DatasetOption } from './useFootprintCompare';

interface FootprintEmissionsProps {
  footprint: FootprintModel;
  customerID: string;
  type: string;
  // eslint-disable-next-line react/require-default-props
  comparisonReference?: string;
}

const FootprintEmissions: FC<FootprintEmissionsProps> = ({
  footprint,
  customerID,
  type,
  comparisonReference,
}) => {
  const intl = useIntl();
  const [options, setOptions] = useState<EmissionOptions>({
    periodType: footprint.periodBaseline?.unit,
    calcType: CalcType.Total,
  });
  const classes = footprintDetailsStyles();
  const {
    getDefaultValue,
    baseline,
    isCompare,
    comparison,
    compareButtonClick,
    closeCompareView,
    swapButtonClick,
    handleDatasetBChange,
    handleDatasetAChange,
    getDatasets
  } = useFootprintCompare({
    customerID,
    footprint,
    type,
    comparisonReference,
  });

  return (
    <Box>
      <Typography className={classes.footprintDetailsTitle}>
        {intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.DETAILS.TITLE',
        })}
      </Typography>
      <Typography
        className={classes.footprintDetailsDescription}
        style={{ marginBottom: 15 }}
      >
        {intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.DETAILS.DESCRIPTION',
        })}
      </Typography>

      <Grid container direction="row" style={{ marginBottom: 15 }}>
        <Grid item xs={2}>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.buttonStyles}
            onClick={compareButtonClick}
          >
            {intl.formatMessage({ id: 'SUSTELL.FOOTPRINT.BUTTONS.COMPARE' })}
          </Button>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            {/* <Button
              color="secondary"
              variant="outlined"
              startIcon={<FilterList />}
              className={classes.buttonStyles}
              style={{ marginRight: '15px' }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.BUTTONS.SHOWIMPACTFROM',
              })}
            </Button> */}
            <ButtonGroup>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.buttonStyles}
                // TODO: will be resolved after conversion to ts
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                style={{
                  background:
                    options.calcType === CalcType.Total
                      ? DSM_MAIN_SELECTED_COLOR
                      : '',
                }}
                onClick={() =>
                  options.calcType === CalcType.Total
                    ? undefined
                    : setOptions({
                        calcType: CalcType.Total,
                        periodType: options.periodType,
                      })
                }
              >
                {intl.formatMessage({ id: 'GENERAL.TOTAL' })}
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.buttonStyles}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                style={{
                  marginRight: '15px',
                  background:
                    options.calcType === CalcType.PerAnimal
                      ? DSM_MAIN_SELECTED_COLOR
                      : '',
                }}
                onClick={() =>
                  options.calcType !== CalcType.Total
                    ? undefined
                    : setOptions({
                        calcType: CalcType.PerAnimal,
                        periodType: options.periodType,
                      })
                }
              >
                {intl.formatMessage({
                  id: 'REPORT.EMISSIONS.PER_AVG_ANIMALS_PRESENT',
                })}
              </Button>
            </ButtonGroup>

            {!footprint?.stageEmission && (
              <ButtonGroup>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.buttonStyles}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  style={{
                    background:
                      options.periodType === PeriodType.Year
                        ? DSM_MAIN_SELECTED_COLOR
                        : '',
                  }}
                  onClick={() =>
                    options.periodType === PeriodType.Year
                      ? undefined
                      : setOptions({
                          calcType: options.calcType,
                          periodType: PeriodType.Year,
                        })
                  }
                >
                  {intl.formatMessage({ id: 'GENERAL.YEAR' })}
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.buttonStyles}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  style={{
                    background:
                      options.periodType !== PeriodType.Year
                        ? DSM_MAIN_SELECTED_COLOR
                        : '',
                  }}
                  onClick={() =>
                    options.periodType !== PeriodType.Year
                      ? undefined
                      : setOptions({
                          calcType: options.calcType,
                          periodType: PeriodType.Round,
                        })
                  }
                >
                  {intl.formatMessage({
                    id: 'BASELINE.FORM.FIELD.TIME_UNIT.ROUND',
                  })}
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </Grid>
      </Grid>
      {isCompare && (
        <Grid container spacing={1} style={{ marginTop: 15, marginBottom: 15 }}>
          <Grid item xs={5}>
            <InputLabel
              htmlFor="age-customized-select"
              style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.DATASETA',
              })}
            </InputLabel>
            <Select
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.SUGGESTIONS',
              })}
              isSearchable
              isClearable
              defaultValue={getDefaultValue as unknown as DatasetOption}
              value={baseline}
              onChange={handleDatasetAChange}
              options={getDatasets()}
            />
          </Grid>
          <Grid item xs={1} className={classes.compareButtonsStyles}>
            <Button
              variant="outlined"
              className={classes.swapButtonStyle}
              onClick={swapButtonClick}
            >
              <SwapHoriz />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <InputLabel
              htmlFor="age-customized-select"
              style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.DATASETB',
              })}
            </InputLabel>
            <Select
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.SUGGESTIONS',
              })}
              isSearchable
              isClearable
              value={comparison}
              onChange={handleDatasetBChange}
              options={getDatasets(true)}
            />
          </Grid>
          <Grid item xs={1} className={classes.compareButtonsStyles}>
            <Button
              variant="outlined"
              className={classes.closeButtonStyle}
              onClick={closeCompareView}
            >
              <Close />
            </Button>
          </Grid>
        </Grid>
      )}

      <FootprintEmissionTable
        title={intl.formatMessage({ id: 'EMISSIONS.TITLE' })}
        subtitle={intl.formatMessage({ id: 'EMISSIONS.TITLE.DESCRIPTION' })}
        aggregatedEmissions={footprint.emissionContainer?.agregatedEmissions}
        baselineDatasetName={footprint.name}
        comparisonDatasetName={footprint.comparison?.name}
        hasComparison={!!footprint.comparison}
        options={options}
      />

      {footprint.emissionContainer?.mmsAgregatedEmissions && footprint.emissionContainer?.mmsTypes.length > 0 && (
        <FootprintEmissionTable
          title={intl.formatMessage({ id: 'MMS.EMISSIONS.TITLE' })}
          subtitle={intl.formatMessage({ id: 'MMS.EMISSIONS.TITLE.DESCRIPTION' })}
          aggregatedEmissions={
            footprint.emissionContainer?.mmsAgregatedEmissions
          }
          baselineDatasetName={footprint.name}
          comparisonDatasetName={footprint.comparison?.name}
          hasComparison={!!footprint.comparison}
          options={options}
          mmsTypes={footprint.emissionContainer?.mmsTypes}
          comparisonMMSTypes = {footprint?.comparison?.emissionContainer?.mmsTypes}
        />
      )}
    </Box>
  );
};

export default FootprintEmissions;
