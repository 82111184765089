import SVG from "react-inlinesvg";

import { Badge, Box, Popover, Typography } from "@material-ui/core";
import React from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { makeStyles } from "@material-ui/styles";
import { toAbsoluteUrl } from "../../../../../_helpers";
import BaseNotificationDropdownCard from "./BaseNotificationDropdownCard";
import NotificationsModal from "./NotificationsModal";
import { UserProfilePrefs } from "../../../../../../app/modules/Helpers/UserProfilePrefs";
// import { useIntl } from 'react-intl';
import { useIntl } from "../../../../../i18n/customUseIntl";
import { headerMenusStyles } from "../../../../_core/MaterialThemeProvider";

const useStyles = makeStyles({
  root: {
    width: 360,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 28,
    paddingBottom: 28,
    borderRadius: 0,
    backgroundColor: "#49AB6C",
    backgroundImage: "url(" + toAbsoluteUrl("/media/bg/wave.svg") + ")",
  },
  notificationsContainer: {
    // overflow: "scroll",
    height: 360,
    // '&>*:not(:last-child)': {
    //   marginBottom: 12
    // },
  },
  noNotifications: {
    // overflow: "scroll",
    height: 360,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
});



export default function NotificationDropdown() {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const classes = useStyles();
  const commonClasses = headerMenusStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    setSelected([notification]);
    setOpenModal(true);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const notifications = UserProfilePrefs.getInstance().getNotifications();

  return (
    <React.Fragment>
      <div
        className={commonClasses.mainMenuItemIcon} 
        onClick={handleClick}
      >
      <Badge badgeContent={ notifications?.length } color="error" overlap="rectangular">
        <NotificationsIcon  fontSize="medium"/>
      </Badge>
      </div>
      
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Typography style={{color: 'white'}} variant="h5">
            { intl.formatMessage({id: "NOTIFICATIONS"})}
            </Typography>
          </Box>
          {notifications?.length > 0 ? (
            <Box className={classes.notificationsContainer}>
              {notifications.map((notification) => {
                return (
                  <BaseNotificationDropdownCard
                    //change key
                    key={notification.title + notification.publishAt}
                    title={notification.title}
                    message={notification.message}
                    attachements={notification.attachementKeys}
                    onClick={() => handleNotificationClick(notification)}
                  />
                );
              })}
            </Box>
          ) : (
            <Box className={classes.noNotifications}>
              <Typography variant="h5">{ intl.formatMessage({id: "NOTIFICATIONS.NO_NOTIFICATIONS"})}</Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                style={{ maxWidth: 260, textAlign: "center" }}
              >
                { intl.formatMessage({id: "NOTIFICATIONS.NO_NOTIFICATIONS.INFO"})}
                
              </Typography>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/mailbox.svg")}
              />
            </Box>
          )}
        </Box>
      </Popover>
      <NotificationsModal data={selected} open={openModal} onClose={()=> setOpenModal(false)}/>
    </React.Fragment>
  );
}
