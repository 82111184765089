/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { BreadCrumbMenu } from "./BreadCrumbMenu";
import {BreadCrumbMenuV2 } from "../headerV2/BreadCrumbMenuV2"
import { getBreadcrumbsAndTitle, useSubheader } from "../../../../_metronic/layout";


export function SubHeaderSustell() {
  const location = useLocation();
  const subheader = useSubheader();

  // const layoutProps = useMemo(() => {
  //   return {
  //     config: uiService.config,
  //     subheaderMobileToggle: objectPath.get(
  //       uiService.config,
  //       "subheader.mobile-toggle"
  //     ),
  //     subheaderCssClasses: uiService.getClasses("subheader", true),
  //     subheaderContainerCssClasses: uiService.getClasses(
  //       "subheader_container",
  //       true
  //     ),
  //     viewSubheaderToolbar: objectPath.get(uiService.config, "extras.toolbar.display"),
  //   };
  // }, [uiService]);

  useLayoutEffect(() => {
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =  header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(header.title);
     // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
  }, [subheader]);

  return (
    <div
      id="kt_subheader">
      {/* <BreadCrumbMenu items={subheader.breadcrumbs} /> */}
      <BreadCrumbMenuV2 items={subheader.breadcrumbs} />
    </div>
  );
}
