import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { isEqual } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import PBAnimalInputFormDialog from './v2.0/PBreadingAnimalInputFormDialog';
import PFAnimalInputFormDialog from './v2.0/PFatteningAnimalInputFormDialog';
import PFOutputFormDialog from './v2.0/PFOutputFormDialog';
import PHousingManureFormDialog from './v2.0/PHousingManureFormDialog';
import { FeedsFormDialog } from '../../CommonDataParts/FeedsFormDialog_v2';
import PFEmissionsFormDialog from './v2.0/PFEmissionsFormDialog';
import { BaselineStageProps } from '../../common';
import { PigFatteningBaseline } from '../../../../models/Baseline';
import StageButtonBox from '../../StageButtonBox_v2';
import {
  AnimalType,
  MasterDataOption,
  StageType,
} from '../../../../../../graphql/types';
import PBOutputFormDialog from './v2.0/PBOutputFormDialog';
import { defaultValues } from '../../../../components/FarmFlow/baselineDefaultValues';
import { stageDataPartPig } from '../../../../../modules/Farms/Baseline/validationSchema/pigV2BaselineValidation';

interface PigStagesProps extends Omit<BaselineStageProps, 'baseline'> {
  baseline: PigFatteningBaseline;
  origins: MasterDataOption[];
  animalType: AnimalType;
  calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

interface CloseDialogData {
  type: string;
  setFunc: Dispatch<SetStateAction<number>>;
  prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions';
}

interface InitialCalcuationData {
  prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions';
}

const PigStages: FC<PigStagesProps> = ({
  stageIndex,
  baseline,
  getCompleteness,
  manureManagementSystems,
  compoundFeeds,
  singleIngredients,
  origins,
  item,
  formType,
  stageType = StageType.Fattening,
  animalType,
  calculateCompleteness,
}) => {
  const [activeDialog, setActiveDialog] = useState<string | null>('');

  const formContext = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;
  const [inputBreedingCompletness, setInputBreedingCompletness] = useState(0);
  const [inputFatteningCompletness, setInputFatteningCompletness] = useState(0);
  const [feedCompletness, setFeedCompleteness] = useState(0);
  const [housingCompletness, setHousingCompleteness] = useState(0);
  const [outputBreedingCompletness, setOutputBreedingCompleteness] =
    useState(0);
  const [outputFatteningCompletness, setOutputFatteningCompleteness] =
    useState(0);
  const [emissionCompletness, setEmissionCompletness] = useState(0);

  const showDialog = (dialog: SetStateAction<string | null>) => {
    setActiveDialog(dialog);
  };

  const closeDialog = (closeDialogData: CloseDialogData) => {
    const completeness = calculateCompleteness(
      stageDataPartPig,
      `stages[${stageIndex}].stageData.${closeDialogData.prefix}`
    );
    if (formType !== 'view' && closeDialogData.type === 'confirm') {
      closeDialogData.setFunc(completeness);
    } else if (closeDialogData.type === 'reset') {
      if (!completeness)
        formContext.trigger(`${stagePrefix}.stageData.${activeDialog}`);
      else formContext.clearErrors(`${stagePrefix}.stageData.${activeDialog}`);
    }
    setActiveDialog(null);
  };

  const initialCalculation = (initialCalcuationData: InitialCalcuationData) => {
    const validationObject = item.stageData[initialCalcuationData.prefix];
    const defaultObject =
      defaultValues['PIG']?.stages[0]?.stageData[initialCalcuationData.prefix];
    if (item.newStage && isEqual(validationObject, defaultObject)) return 0;
    else
      return calculateCompleteness(
        stageDataPartPig,
        `stages[${stageIndex}].stageData.${initialCalcuationData.prefix}`
      );
  };

  useEffect(() => {
    if (formType === 'edit' && !item.newStage) {
      // TODO: may need to put for processing
      if (stageType === StageType.Breeding) {
        setInputBreedingCompletness(initialCalculation({ prefix: 'input' }));
        setOutputBreedingCompleteness(initialCalculation({ prefix: 'output' }));
      } else {
        setInputFatteningCompletness(initialCalculation({ prefix: 'input' }));
        setOutputFatteningCompleteness(
          initialCalculation({ prefix: 'output' })
        );
      }
      setFeedCompleteness(initialCalculation({ prefix: 'feed' }));
      setHousingCompleteness(initialCalculation({ prefix: 'housing' }));
      setEmissionCompletness(initialCalculation({ prefix: 'emissions' }));
    }
  }, [formType]);

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        defaultValue={item.type}
      />
      {stageType === StageType.Breeding && activeDialog === 'input' && (
        <PBAnimalInputFormDialog
          formVisible={activeDialog === 'input'}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setInputBreedingCompletness,
              prefix: 'input',
            })
          }
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {stageType === StageType.Fattening && activeDialog === 'input' && (
        <PFAnimalInputFormDialog
          formVisible={activeDialog === 'input'}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setInputFatteningCompletness,
              prefix: 'input',
            })
          }
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {activeDialog === 'feed' && (
        <FeedsFormDialog
          formVisible={activeDialog === 'feed'}
          handleCancel={(type: string) =>
            closeDialog({ type, setFunc: setFeedCompleteness, prefix: 'feed' })
          }
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
          animalType={animalType}
        />
      )}
      {activeDialog === 'manure' && (
        <PHousingManureFormDialog
          formVisible={activeDialog === 'manure'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setHousingCompleteness,
              prefix: 'housing',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          manureManagementSystems={manureManagementSystems}
        />
      )}
      {activeDialog === 'emissions' && (
        <PFEmissionsFormDialog
          formVisible={activeDialog === 'emissions'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setEmissionCompletness,
              prefix: 'emissions',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {stageType === StageType.Fattening && activeDialog === 'output' && (
        <PFOutputFormDialog
          formVisible={activeDialog === 'output'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputFatteningCompleteness,
              prefix: 'output',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {stageType === StageType.Breeding && activeDialog === 'output' && (
        <PBOutputFormDialog
          formVisible={activeDialog === 'output'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputBreedingCompleteness,
              prefix: 'output',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      <Box
        style={{ display: 'flex', width: '100%' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={
            stageType === StageType.Breeding
              ? inputBreedingCompletness
              : inputFatteningCompletness
          }
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog('input')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={feedCompletness}
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog('feed')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="general/building-06"
          completness={housingCompletness}
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.housing
          }
          handleOpen={() => showDialog('manure')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={
            stageType === StageType.Breeding
              ? outputBreedingCompletness
              : outputFatteningCompletness
          }
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog('output')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="maps-travel/globe-04"
          // optional
          descriptionTitle="SUSTELL.STAGE.MITTIGATION"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={emissionCompletness}
          // error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.emissions}
          handleOpen={() => showDialog('emissions')}
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.emissions
          }
        />
      </Box>
    </>
  );
};

export default PigStages;
