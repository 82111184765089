import { FC, useState } from "react";
import { useHistory } from "react-router";

import { Pagination } from "@material-ui/lab";
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@material-ui/icons";
import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { Box,  Table, TableBody, TableContainer, TableFooter, TableHead, TableRow, Typography, Tooltip as MUITooltip} from "@material-ui/core";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { footprintCategoryTableStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import { FootprintCategoryModel } from "../../models/Footprint/internal";
import { StyledTableCell, StyledTableRow } from "../footprints/FootprintEmissionTable";
import { AnalysisGroupsFarm, SortOption, DashboardType, AnalysisGroupsFeed, DistributionByAnalysisGroups } from "../../models/Dashboard/DashboardTypes";
import FootprintCategoryDetail from "../footprints/FootprintCategoryDetail";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { ExactNumber } from "../helpers/ExactNumber";
import { getOpenFootprintObj } from "./helper";
import { DashboardFootprintModel } from "../../models/Dashboard";
import { AnimalType } from "../../../../graphql/types";
import { getAnimalAnalysisGroup } from "../helpers/AnimalSpecificLabels";
import Can from '../../../modules/Auth/Can';

interface DashboardOverviewTableProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
}

type SortFields = AnalysisGroupsFarm | AnalysisGroupsFeed | 'Total' | 'Name';

const DashboardOverviewTable: FC<DashboardOverviewTableProps> = ({
  dashboard,
  renderDashboard,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = footprintCategoryTableStyles();

  const animalType: AnimalType | undefined = dashboard?.filters?.animals?.find(
    (animal) => animal === AnimalType.LitopenaeusVannamei || 
    animal === AnimalType.PenaeusMonodon ||
    animal === AnimalType.SalmonV2 ||
    animal === AnimalType.SeaBass ||
    animal === AnimalType.SeaBream);

  const [categoryDetailVisible, setCategoryDetailVisible] =
    useState<boolean>(false);
  const [footprintCategorySelected, setFootprintCategorySelected] =
    useState<FootprintCategoryModel>({} as FootprintCategoryModel);
  
  const handlePageChange = (event: React.ChangeEvent<unknown> | React.MouseEvent<HTMLButtonElement> | null, pageNo: number) => {
    if (event) {
      event.stopPropagation();
    }
    if (dashboard?.currentPage === pageNo) {
      return;
    }
    dashboard?.setCurrenPage(pageNo);
    renderDashboard();
  }

  /**
   *  Need to to unregister BarChart components because it mess up the pie chart 
   *  register on close
   * */
  const barChartRegister = (register = true) => {
      if (register) {
        Chart.register( 
          CategoryScale,
          LinearScale,
          BarElement,
          Title,
          Tooltip,
          Legend
        );
      } else {
        Chart.unregister( 
          CategoryScale,
          LinearScale,
          BarElement,
          Title,
          Tooltip,
          Legend
        );
      }
  }

  const openFootprint = (footprint: DashboardFootprintModel) => {
    if (!dashboard) {
      return;
    }
    const {pathname, historyStateProps} 
      = getOpenFootprintObj(dashboard.customerId, dashboard.customerName, footprint);

    if (!pathname) {
      return;
    }
    history.push(pathname, historyStateProps);
  };

  const openCategoryDetail = (category: FootprintCategoryModel | undefined) => {
    if (!category) {
      return;
    }
    barChartRegister(false);
    setFootprintCategorySelected(category);
    setCategoryDetailVisible(true);
  };

  const handleSortChanged = (sortField: SortFields ) => {
    if(!dashboard) {
      return;
    }
    const currOption = dashboard.sortOptions[sortField];

    let option: SortOption;
    if (!currOption) {
      option = 'Desc';
    } else if (currOption === 'Desc') {
      option = 'Asc';
    } else {
      option = undefined;
    }

    dashboard.setSortOption(option, sortField);
    renderDashboard();
  }

  const groups = dashboard?.type === DashboardType.Feeds ? AnalysisGroupsFeed : AnalysisGroupsFarm;

  return (
    <>
    { categoryDetailVisible && (
        <FootprintCategoryDetail
          footprintCategory={footprintCategorySelected}
          formVisible={categoryDetailVisible}
          handleClose={
            () => {
              setCategoryDetailVisible(false);
              barChartRegister(true);
            }
          }
          selectedAnimalType={animalType}
        />
      )
    }
      <Box display="flex"  mt="64px" mb="12px">
        <Box>
          <Typography variant="h6" color="textPrimary">
          { intl.formatMessage({id:"SUSTELL.DASHBOARD.OVERVIEW" }) }
          </Typography>
          <Typography color="textSecondary">
            { intl.formatMessage({id:"SUSTELL.DASHBOARD.OVERVIEW.DECSRIPTION" }) }
          </Typography>
        </Box>
      </Box>
      <Box>
      <TableContainer>
          <Table aria-label="customized table" className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{cursor: 'pointer'}}
                  onClick={() => handleSortChanged('Name')}
                >
                  {
                    dashboard?.type === DashboardType.Feeds ? 
                    'Feed name'
                  : 'Production proces name'
                  }
                  {
                    dashboard?.sortOptions['Name' as const] === 'Desc' 
                      ? <ArrowDownwardOutlined />
                      : <ArrowUpwardOutlined htmlColor={!dashboard?.sortOptions['Name' as const] ? 'gray' : ''}/> 
                  }
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                {
                  Object.values(groups).map(analysisGroup => 
                    <StyledTableCell
                      style={{cursor: 'pointer'}}
                      onClick={() => handleSortChanged(analysisGroup as SortFields)}
                    >
                      {getAnimalAnalysisGroup(animalType, analysisGroup, intl)}
                      {
                        dashboard?.sortOptions[analysisGroup as SortFields] === 'Desc' 
                          ? <ArrowDownwardOutlined />
                          : <ArrowUpwardOutlined htmlColor={!dashboard?.sortOptions[analysisGroup as SortFields] ? 'gray' : ''}/> 
                      }
                    </StyledTableCell>
                  )
                }
                <StyledTableCell style={{cursor: 'pointer'}}
                  onClick={() => handleSortChanged('Total')}
                >
                  Total
                  {
                    dashboard?.sortOptions['Total' as keyof DistributionByAnalysisGroups] === 'Desc' 
                    ? <ArrowDownwardOutlined />
                    : <ArrowUpwardOutlined htmlColor={!dashboard?.sortOptions['Total' as keyof DistributionByAnalysisGroups] ? 'gray' : ''}/> 
                  }
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboard?.dashboardFootprints &&
                (dashboard?.dashboardFootprints || []).map(footprint => {

                  const distribution = footprint.distributionByAnalysisGroups;

                  return (
                    <StyledTableRow>
                      <StyledTableCell
                          style={{ width: '21%', cursor: 'pointer' }}
                          onClick={() => undefined}
                        >
                        <MUITooltip title={ footprint.longName } placement="top" >
                          <Box style={{ display: 'flex' }}>
                            <Typography
                              style={{ paddingLeft: '10px', fontWeight: footprint.isSimulation ? '600' : '400' }}
                            >
                               {footprint.name}
                            </Typography>
                          </Box>
                        </MUITooltip>
                      </StyledTableCell>

                      {(!Can('onlyAccess', 'Dashboard') && (
                      <StyledTableCell  >
                        <Typography
                          onClick={() => openFootprint(footprint)}
                          style={{ fontWeight: '500', color: 'steelblue', textDecorationLine: 'underline', cursor: 'pointer', }}
                        >
                           {intl.formatMessage({id: "SUSTELL.DASHBOARD.VIEW.FOOTPINT"})}
                        </Typography>
                      </StyledTableCell>))}


                      <StyledTableCell >
                      <Typography
                          style={{ fontWeight: '500', color: 'steelblue', cursor: 'pointer', textDecorationLine: 'underline' }}
                          onClick={() => openCategoryDetail(footprint.categories.find(categ => categ.key === dashboard.activeImpactCategory.key))}>
                              {intl.formatMessage({id: "SUSTELL.DASHBOARD.DETAILS"})}
                        </Typography>
                      </StyledTableCell>
                      {
                        Object.values(groups).map(analysisGroup => 
                          <StyledTableCell >
                            <Typography
                            >
                              {
                                distribution[analysisGroup as keyof DistributionByAnalysisGroups] === 0 ? 
                                  0:
                                <ExactNumber value={distribution[analysisGroup as keyof DistributionByAnalysisGroups]} /> 
                              }                    
                            </Typography>
                          </StyledTableCell>
                        )
                      }
                      <StyledTableCell >
                        <Typography
                          className={classes.tableCellTitle}
                          style={{ fontWeight: '500' }}
                        >
                           {
                              distribution['Total' as const] === 0 ? 
                              0:
                            <ExactNumber value={distribution['Total' as const]} /> 
                            }
                        </Typography>
                        <Typography
                          className={classes.tableCellDescription}
                          style={{ fontWeight: '400' }}
                        >
                        { dashboard.activeImpactCategory.unit || '' }
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                    )
                  
            }) }
            </TableBody>
            <TableFooter>
              <TableRow>
{/*               DSM paginations not showing corectly previos, next labeles
                <DsmPagination 
                  pageCount={dashboard?.totalPages || 0}
                  currentPage={dashboard?.currentPage || 0}
                  onDsmChangePage={e => handlePageChange1(e)}
                  
                /> */}
              </TableRow>
            </TableFooter>
          </Table>
      </TableContainer>
      <Box flexDirection="row" display="flex" justifyContent="center" mt="8px">
        <Pagination 
          count={dashboard?.totalPages || 0} 
          variant="outlined" 
          color="secondary"
          shape="rounded" 
          page={dashboard?.currentPage || 1} onChange={handlePageChange} 

        />
      </Box>
    </Box>
    </>
  );
}

export default DashboardOverviewTable;