import React from "react";

import { API } from "aws-amplify";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import { listCustomers, listUsers } from "../../../graphql/queries";
import UserData from "./UserData";
import { UserForm } from "./UserForm";
import paginateQuery from "../../sustell_15/helpers/paginate";

export let UserGroups = ["Administrators", "Support", "Editor", "ViewerAll", "ViewerDashboard"];

export function getUserGroup(groups) {
  if(groups?.includes("Administrators")) {
    return "Administrators";
  }

  else if(groups?.includes("Support")) {
    return "Support";
  }
  else if(groups?.includes("Editor")) {
    return "Editor";
  }

  else if(groups?.includes("ViewerAll")) {
    return "ViewerAll";
  }

  else if (groups?.includes("ViewerDashboard") || groups?.length === 0) {
    return "ViewerDashboard";
  }
  else {
    return null;
  }
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      filteredUserData: [],
      customerData: [],
      selectedUser: null,
      showUserForm: false,
      formState: 'view'
    };

    this.onSelectedUserChanged = this.onSelectedUserChanged.bind(this);
    this.showUserForm = this.showUserForm.bind(this);
    this.onUserAdded = this.onUserAdded.bind(this);
    this.onNewUserBtnClick = this.onNewUserBtnClick.bind(this);
    this.onUsersFilterChanged = this.onUsersFilterChanged.bind(this);
  }

  onNewUserBtnClick() {
    this.setState({selectedUser: null, });
  }

  componentDidMount() {
    this.fetchUserData();
    this.fetchCustomerData();
  }

  onUserAdded(newData) {
    if (newData) {
      let newUser = {...newData};
      let users = this.state.userData;
      let existingUser = users.find(item => item.id === newUser.id);
      if(!existingUser){
        users.splice(0, 0, newUser);
        this.setState({ showUserForm: false, selectedUser:newUser });
      }else{
        Object.assign(existingUser,newUser);
        this.setState({ showUserForm: false, selectedUser:existingUser });
      }
    }else{
      //returned via cancel
      this.setState({ showUserForm:false })
    }

  }

  onSelectedUserChanged(selectedUserData) {
    if (
      selectedUserData &&
      !this.state.showUserForm
    ) {
      this.props.history.push("/admin/users/" + selectedUserData.id);
      this.setState({ selectedUser: selectedUserData });
    } else {
      this.setState({ selectedUser: null });
    }
  }

  onUsersFilterChanged(filterAttribute, filterValue) {
    let results;
    // if filtering is on, do it
    if(filterAttribute && filterValue) {
      results = this.state.userData?.filter(user => {
        if(filterAttribute === 'groups')
          return user.groups?.some(group => group?.toLowerCase().includes(filterValue.toLowerCase()))
        return user?.[filterAttribute]?.toLowerCase().includes(filterValue.toLowerCase())
      });
    }
    else //filtering is off, use all data
      results = this.state.userData;

      const selectedUser = results.find((el) => el.id === this.state.selectedUser?.id);
      this.setState({ filteredUserData: results, selectedUser: selectedUser });

    
  }

  async fetchUserData(filterAttribute, filterValue) {
    try {
      const params = {
        query: listUsers,
      };
      let results = await paginateQuery(params, 'listUsers');

      const selectedUser = results.find((el) => el.id === this.state.selectedUser?.id);
      this.setState({ userData: results, selectedUser: selectedUser, filteredUserData: results });
    } catch (err) {
      console.log("error: ", err);
    }
  }

  async fetchCustomerData() {
    try {
      const params = {
        query: listCustomers,
      };
      const result = await paginateQuery(params, 'listCustomers');
      this.setState({ customerData: result });
    } catch (err) {
      console.log('error: ', err)
    }
  }

  showUserForm(event, formState) {
    this.setState({ showUserForm: true, formState: formState });
  }

  render() {
    return (
      <Row>
        {this.state.showUserForm ? (
          <Col>
            {this.state.showUserForm && (
              <UserForm
                customerData={this.state.customerData}
                selectedUser={this.state.selectedUser}
                changeHandler={this.onUserAdded}
                formState={ this.state.formState }
              />
            )}
          </Col>
        ) : (
          <Col>
            <UserData
              formOpened={this.state.showUserForm}
              userData={this.state.filteredUserData}
              selectedUser={this.state.selectedUser}
              onSelectionChangedHandler={this.onSelectedUserChanged}
              onShowUserForm={this.showUserForm}
              onNewUserBtnClick={this.onNewUserBtnClick}
              onUsersFilterChanged={this.onUsersFilterChanged}
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default withRouter(Users);
