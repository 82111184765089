import { TextField, Box, Button, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { API } from 'aws-amplify';
import { useState } from 'react';
import { updateFootprintTarget } from '../../../../graphql/mutations';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  footprintCategoryTargetStyles,
  impactBackgroundIconColors,
  impactColors,
} from '../../../../_metronic/layout';
import { getFootprintType } from '../../utils/footprint-url-utils';
import { translateKeyMapping } from '../../utils/string-utils';
import { FootprintModel } from '../../models/Footprint';
import FootprintCategoryModel from '../../models/Footprint/FootprintCategory';
import { DialogContainer } from '../FarmFlow/CommonDataParts/DialogContainer';

interface FootprintCategorTargetProps {
  footprintCategory: FootprintCategoryModel;
  formVisible: boolean;
  handleClose: () => void;
  footprint: FootprintModel;
  type: string;
  footprintTargetSuccessHandler: () => Promise<void>;
}
const FootprintCategoryTarget = ({
  footprintCategory,
  formVisible,
  footprint,
  type,
  handleClose,
  footprintTargetSuccessHandler,
}: FootprintCategorTargetProps) => {
  const intl = useIntl();
  const {
    key,
    description,
    compartments,
    targetValue: currentTargetValue,
  } = footprintCategory;
  const compartment = compartments[0].toLowerCase();
  const classes = footprintCategoryTargetStyles();
  const [target, setTarget] = useState(currentTargetValue);
  const [error, serError] = useState(Boolean);

  const footprintCategoryKeysWithTargets = Object.assign(
    {},
    ...footprint.unFilteredCategories.map((item) => ({
      [item.key]: item.targetValue,
    }))
  ) as { [key: string]: number | undefined };

  const handleSubmit = (isCleaning = false) => {
    (async () => {
      if (!target && !isCleaning) {
        serError(true);
        return;
      }
      Object.keys(footprintCategoryKeysWithTargets).forEach((eachKey) => {
        if (eachKey === key) {
          footprintCategoryKeysWithTargets[eachKey] = isCleaning
            ? undefined
            : target;
        } else {
          footprintCategoryKeysWithTargets[eachKey] =
            footprintCategoryKeysWithTargets[eachKey]
              ? footprintCategoryKeysWithTargets[eachKey]
              : undefined;
        }
      });
      try {
        await API.graphql({
          query: updateFootprintTarget,
          variables: {
            input: {
              type: getFootprintType(type),
              reference: footprint.reference,
              target: JSON.stringify(footprintCategoryKeysWithTargets),
            },
          },
        });
        handleClose();
        await footprintTargetSuccessHandler();
      } catch (err) {
        serError(true);
      }
    })().catch(() => serError(true));
  };

  const setTargetValue = (value: number) => {
    setTarget(value);
    serError(false);
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleClose}
      datasetType="footprint"
      width="512px"
      iconCode={compartment}
      iconColor={impactColors[compartment as keyof typeof impactColors]}
      iconWidth="33px"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TITLE',
      })}
      introText={intl.formatMessage({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        id: `SUSTELL.FOOTPRINT.DESCRIPTION.${translateKeyMapping(key)}`,
        defaultMessage: description,
      })}
      introTextStyle={{
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
      }}
      dialogContentStyles={{
        marginTop: 1,
      }}
      iconBackgroundSize="65px"
      iconBackgroundColor={
        impactBackgroundIconColors[
          compartment as keyof typeof impactBackgroundIconColors
        ]
      }
      borderRadius="52px"
    >
      <Box className={classes.textBoxTitle}>
        {intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_TITILE',
        })}
      </Box>
      <TextField
        id="target"
        name="target"
        label={intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_LABEL',
        })}
        placeholder={intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_LABEL',
        })}
        InputProps={{
          endAdornment: (
            <Tooltip
              title={intl.formatMessage({
                id: 'SUSTELL.DASHBOARD.TARGET.SET_A_TARGET_TOOLTIP',
              })}
              placement="left"
            >
              <HelpOutline />
            </Tooltip>
          ),
        }}
        type="number"
        value={target || undefined}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTargetValue(Number(e.target.value));
        }}
        helperText={
          !target
            ? intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_LABEL_HELP',
              })
            : ''
        }
        error={error || false}
        margin="none"
        variant="outlined"
        fullWidth
      />
      <br />
      <br />
      <br />
      <Box className={classes.buttonsBox}>
        {currentTargetValue && (
          <Button
            variant="outlined"
            type="button"
            onClick={() => handleSubmit(true)}
            className={classes.clearTargetButton}
          >
            {intl.formatMessage({
              id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.BUTTON_LABEL_CLEAR',
            })}
          </Button>
        )}
        <Button
          fullWidth={!currentTargetValue}
          variant="contained"
          type="button"
          onClick={() => handleSubmit()}
          className={classes.buttonStyles}
        >
          {intl.formatMessage({
            id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.BUTTON_LABEL',
          })}
        </Button>
      </Box>
    </DialogContainer>
  );
};

export default FootprintCategoryTarget;
