/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Extend this later for all units

import { Maybe } from 'graphql/jsutils/Maybe';
import {
  OnSiteActivity,
  ResourceUseType,
  Transport,
  TransportMode,
} from '../../../../../graphql/types';
import { defaultUnitsV2 as defaultMetrics, explicitConvertValue } from '../../../utils/unit-utils';
import { convertDistanceUnits } from '../../../../modules/Helpers/UnitConverter';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';

const math = require('mathjs');
// converts mass units
// direction: 0 (default) default => userUOM
//            1 userUOM => default
export const convertOnSiteActivityUnitsV2 = (
  value: number | undefined,
  type: ResourceUseType,
  userUOM: any,
  direction = 0,
  isFeed=false
) => {
  if (!value) {
    return undefined;
  }

  const userPrefs = UserProfilePrefs.getInstance();
  // eslint-disable-next-line no-param-reassign
  // value = toNum(value);
  const inOutputMass =
    direction === 0
      ? defaultMetrics.unitCompoundFeedOutputMass
      : userUOM.unitCompoundFeedOutputMass;
  const outOutputMass =
    direction === 0
      ? userUOM.unitCompoundFeedOutputMass
      : defaultMetrics.unitCompoundFeedOutputMass;

  let inUnit = null;
  let outUnit = null;
  // console.log("ACTIVITIES: ", type);
  if (
    type === ResourceUseType.Electricity ||
    type === ResourceUseType.ElectricityPhotovoltaic ||
    type === ResourceUseType.ElectricityWind 
  ) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitCompoundFeedElectricity
        : userUOM.unitElectricityUse;
    outUnit =
      direction === 0
        ? userUOM.unitElectricityUse
        : defaultMetrics.unitCompoundFeedElectricity;
  } else if (
    type === ResourceUseType.Diesel
  ) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitDieselUse
        : userUOM.unitDieselUse;
    outUnit =
      direction === 0
        ? userUOM.unitDieselUse
        : defaultMetrics.unitDieselUse;
  } else if (
    type === ResourceUseType.Gasoline 
  ) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitGasolineUse
        : userUOM.unitGasolineUse;
    outUnit =
      direction === 0
        ? userUOM.unitGasolineUse
        : defaultMetrics.unitGasolineUse;
  } else if (
    type === ResourceUseType.HeavyFuelOil
  ) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitHeavyFuelOilUse
        : userUOM.unitHeavyFuelOilUse;
    outUnit =
      direction === 0
        ? userUOM.unitHeavyFuelOilUse
        : defaultMetrics.unitHeavyFuelOilUse;
  }  
  else if (
    type === ResourceUseType.NaturalGas  || type === ResourceUseType.PropaneOrLpg
  ) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitNaturalGasUse
        : userUOM.unitNaturalGasUse;
    outUnit =
      direction === 0
        ? userUOM.unitNaturalGasUse
        : defaultMetrics.unitNaturalGasUse;
  } else if (type === ResourceUseType.Coal) {
    inUnit = direction === 0 ? defaultMetrics.unitHeatUse : userUOM.unitHeatUse;
    outUnit = direction === 0 ? userUOM.unitHeatUse : defaultMetrics.unitHeatUse;
  } else if (type === ResourceUseType.Water && isFeed) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitCompoundFeedWaterUse
        : userUOM.unitCompoundFeedWaterUse;
    outUnit =
      direction === 0
        ? userUOM.unitCompoundFeedWaterUse
        : defaultMetrics.unitCompoundFeedWaterUse;
  } else if (type === ResourceUseType.Water && !isFeed) {
    inUnit =
      direction === 0
        ? defaultMetrics.unitWaterUse
        : userUOM.unitWaterUse;
    outUnit =
      direction === 0
        ? userUOM.unitWaterUse
        : defaultMetrics.unitWaterUse;
  }
  let result;
  if (value && inUnit && outUnit) {
    inUnit = `${inUnit}/${inOutputMass}`;
    outUnit = `${outUnit}/${outOutputMass}`;
    // console.log("INUNIT => OUTUNIT",inUnit, outUnit)
    // value = explicitConvertValue(value, inUnit, outUnit);
    result = explicitConvertValue(value, inUnit, outUnit);
    if (result !== null) result = math.round(result, userPrefs.getUnitResourcePrecision());
  }
  return { value: result, unit: outUnit };
};

export const getConvertedOnSiteAcitivityInputV2 = (
  values: Maybe<Maybe<OnSiteActivity>[]> | undefined,
  userUOM: any
) => {
  const gas_use = convertOnSiteActivityUnitsV2(
    (values || []).find((oa) => oa?.resourceType === ResourceUseType.NaturalGas)
      ?.amount,
    ResourceUseType.NaturalGas,
    userUOM
  )?.value;

  const electricity_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.Electricity
    )?.amount,
    ResourceUseType.Electricity,
    userUOM
  )?.value;

  const water_use = convertOnSiteActivityUnitsV2(
    (values || []).find((oa) => oa?.resourceType === ResourceUseType.Water)
      ?.amount,
    ResourceUseType.Water,
    userUOM, 0, true
  )?.value;

  const heat_use = convertOnSiteActivityUnitsV2(
    (values || []).find((oa) => oa?.resourceType === ResourceUseType.Propane)
      ?.amount,
    ResourceUseType.Propane,
    userUOM
  )?.value;

  const selfGeneratedRenewables = [];

  const electricityPhotovoltaic_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.ElectricityPhotovoltaic
    )?.amount,
    ResourceUseType.ElectricityPhotovoltaic,
    userUOM
  );

  if (electricityPhotovoltaic_use?.value 
    || electricityPhotovoltaic_use?.value === 0) {
    selfGeneratedRenewables.push({
      type: 'electricityPhotovoltaic_use',
      value: electricityPhotovoltaic_use?.value,
    });
  }

  const electricityWind_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.ElectricityWind
    )?.amount,
    ResourceUseType.ElectricityWind,
    userUOM
  );
  if (electricityWind_use?.value || electricityWind_use?.value === 0) {
    selfGeneratedRenewables.push({
      type: 'electricityWind_use',
      value: electricityWind_use?.value,
    });
  }

  const energyTypes = [];
  const diesel_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.Diesel
    )?.amount,
    ResourceUseType.Diesel,
    userUOM
  );
  if (diesel_use?.value || diesel_use?.value === 0) {
    energyTypes.push({
      type: 'diesel_use',
      value: diesel_use?.value,
    });
  }

  const gasoline_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.Gasoline
    )?.amount,
    ResourceUseType.Gasoline,
    userUOM
  );
  if (gasoline_use?.value || gasoline_use?.value === 0) {
    energyTypes.push({
      type: 'gasoline_use',
      value: gasoline_use?.value,
    });
  }

  const heavyFuelOil_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.HeavyFuelOil
    )?.amount,
    ResourceUseType.HeavyFuelOil,
    userUOM
  );
  if (heavyFuelOil_use?.value || heavyFuelOil_use?.value === 0) {
    energyTypes.push({
      type: 'heavyFuelOil_use',
      value: heavyFuelOil_use?.value,
    });
  }

  const propaneOrLpg_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.PropaneOrLpg
    )?.amount,
    ResourceUseType.PropaneOrLpg,
    userUOM
  );
  if (propaneOrLpg_use?.value || propaneOrLpg_use?.value === 0) {
    energyTypes.push({
      type: 'propaneOrLpg_use',
      value: propaneOrLpg_use?.value,
    });
  }

  const coal_use = convertOnSiteActivityUnitsV2(
    (values || []).find(
      (oa) => oa?.resourceType === ResourceUseType.Coal
    )?.amount,
    ResourceUseType.Coal,
    userUOM
  );
  if (coal_use?.value || coal_use?.value === 0) {
    energyTypes.push({
      type: 'coal_use',
      value: coal_use?.value,
    });
  }
// console.log(selfGeneratedRenewables)
  return {
    gas_use,
    water_use,
    electricity_use,
    heat_use,
    energyTypes,
    selfGeneratedRenewables
  };
};

export const convertDistanceUnitsV2 = (
  values: Maybe<Array<Maybe<Transport>>>,
  userUOM: any,
  isOutbound = false
) => {
  if (isOutbound) {
    return {
      inland_ship: convertDistanceUnits(
        values?.find((t) => t?.mode === TransportMode.InlandShip)?.distance,
        'inland ship',
        userUOM,
        defaultMetrics.unitTransportDistanceInlandWaterways
      )?.value,
      truck: convertDistanceUnits(
        values?.find((t) => t?.mode === TransportMode.Truck)?.distance,
        'truck',
        userUOM,
        defaultMetrics.unitTransportDistanceTerrestrial
      )?.value,
    };
  }
  return {
    inland_ship: convertDistanceUnits(
      values?.find((t) => t?.mode === TransportMode.InlandShip)?.distance,
      'inland ship',
      userUOM,
      defaultMetrics.unitTransportDistanceInlandWaterways
    )?.value,
    truck: convertDistanceUnits(
      values?.find((t) => t?.mode === TransportMode.Truck)?.distance,
      'truck',
      userUOM,
      defaultMetrics.unitTransportDistanceTerrestrial
    )?.value,
    sea_ship: convertDistanceUnits(
      values?.find((t) => t?.mode === TransportMode.SeaShip)?.distance,
      'seaship',
      userUOM,
      defaultMetrics.unitTransportDistanceSea
    )?.value,
    train: convertDistanceUnits(
      values?.find((t) => t?.mode === TransportMode.Train)?.distance,
      'train',
      userUOM,
      defaultMetrics.unitTransportDistanceTerrestrial
    )?.value,
  };
};

// converts mass units
// direction: 0 (default) default => userUOM
//            1 userUOM => default
export const convertIngredientInputMassV2 = (value: any, userUOM: any, direction = 0) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const inInputMass =
    direction === 0
      ? defaultMetrics.unitIngredientQuantityInputMass
      : userUOM.unitIngredientQuantityInputMass;
  const outInputMass =
    direction === 0
      ? userUOM.unitIngredientQuantityInputMass
      : defaultMetrics.unitIngredientQuantityInputMass;

  const inOutputMass =
    direction === 0
      ? defaultMetrics.unitCompoundFeedOutputMass
      : userUOM.unitCompoundFeedOutputMass;
  const outOutputMass =
    direction === 0
      ? userUOM.unitCompoundFeedOutputMass
      : defaultMetrics.unitCompoundFeedOutputMass;

  if (inInputMass !== outInputMass) {
    const inUnit = `${inInputMass  }/${  inOutputMass}`;
    const outUnit = `${outInputMass  }/${  outOutputMass}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-param-reassign
    value = explicitConvertValue(value, inUnit, outUnit);
    // eslint-disable-next-line no-param-reassign
    if (value !== null) value = math.round(value, userPrefs.getUnitBarnOutputMassPrecision());
  }
  return value;
};

export const getUnitForResourceTypeName = (
  type: string,
  userUOM: any,
  isFeed = false
) => {
  switch (type) {
    case 'electricityUse':
    case 'electricity_use':
      return {
        userUnit: userUOM.unitElectricityUse,
        defaultUnit: defaultMetrics.unitElectricityUse,
      };
    case 'electricityPhotovoltaicUse':
    case 'electricityPhotovoltaic_use':
      return {
        userUnit: userUOM.unitElectricityUse,
        defaultUnit: defaultMetrics.unitElectricityUse,
      };
    case 'waterUse':
    case 'water_use':
      return {
        userUnit: isFeed
          ? userUOM.unitCompoundFeedWaterUse
          : userUOM.unitWaterUse,
        defaultUnit: isFeed
          ? defaultMetrics.unitCompoundFeedWaterUse
          : defaultMetrics.unitWaterUse,
      };
    case 'electricityWindUse':
    case 'electricityWind_use':
      return {
        userUnit: userUOM.unitElectricityUse,
        defaultUnit: defaultMetrics.unitElectricityUse,
      };
    case 'coalUse':
    case 'coal_use':
      return {
        userUnit: userUOM.unitHeatUse,
        defaultUnit: defaultMetrics.unitHeatUse,
      };
    case 'dieselUse':
    case 'diesel_use':
      return {
        userUnit: userUOM.unitDieselUse,
        defaultUnit: defaultMetrics.unitDieselUse,
      };
    case 'gasolineUse':
    case 'gasoline_use':
      return {
        userUnit: userUOM.unitGasolineUse,
        defaultUnit: defaultMetrics.unitGasolineUse,
      };
    case 'gasUse':
    case 'gas_use':
      return {
        userUnit: userUOM.unitNaturalGasUse,
        defaultUnit: defaultMetrics.unitNaturalGasUse,
      };
    case 'propaneOrLpg_use':
    case 'propaneOrLpgUse':
      return {
        userUnit: userUOM.unitNaturalGasUse,
        defaultUnit: defaultMetrics.unitNaturalGasUse,
      };
    case 'heavyFuelOilUse':
    case 'heavyFuelOil_use':
      return {
        userUnit: userUOM.unitHeavyFuelOilUse,
        defaultUnit: defaultMetrics.unitHeavyFuelOilUse,
      };
    default:
      return undefined;
  }
};