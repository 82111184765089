/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import UserProfileDropdown from "./dropdowns/UserProfileDropdown";
import useUserData from "../../../../app/modules/Auth/getAuthUserData";
import { useIntl } from "../../../i18n/customUseIntl";

const QuickUserToggler = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const user = useUserData() as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const uiService = useHtmlClassService();
  const intl = useIntl();

  const layoutProps = useMemo(() => ({
       // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
       offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
      }), [uiService]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const firstName = (user?.fullname && user?.fullname !== null) ? user.fullname as unknown as string : 'Unknown';
  return (
  <>
    {layoutProps.offcanvas && (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">{intl.formatMessage({id: "GENERAL.VIEW_USER"})}</Tooltip>}
    >
      <div className="topbar-item">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle">
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">{intl.formatMessage({id: "GENERAL.HI"})},</span>
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {firstName}
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">{(firstName.length > 0) ? firstName[0] : ''}</span>
            </span>
        </div>
      </div>
    </OverlayTrigger>)}

    {!layoutProps.offcanvas && (<UserProfileDropdown />)}
  </>
  );
}

export default QuickUserToggler;