/* eslint-disable react/require-default-props */
import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';
import { resolvePath } from './resolvePathFn';

interface ReactHookPreviewFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  hidden?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  type?: React.InputHTMLAttributes<unknown>['type'];
  addornment?: string;
  precision?: number;
  changeHandler?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
}

const ReactHookPreviewField = ({
  name,
  label = '',
  disabled = false,
  hidden = false,
  margin = 'none',
  type = 'number',
  addornment = '%',
  precision = 2,
  changeHandler,
  textAlign = 'left',
}: ReactHookPreviewFieldProps) => {
  const fc = useFormContext();

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const localError = resolvePath(fc.errors, name, null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currResetValue = resolvePath(
    fc.control.defaultValuesRef.current,
    name,
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isTouched = resolvePath(fc.formState.dirtyFields, name, false);

  return (
    <Controller
      control={fc.control}
      name={name}
      defaultValue={fc.getValues(name) as string}
      render={({ onChange, onBlur, value, ref }) => {
        // allow zero to be displayed when needed
        if (
          value === '' &&
          currResetValue === 0 &&
          !isTouched &&
          type === 'number'
        )
          // eslint-disable-next-line no-param-reassign
          value = 0;
        if (precision && !Number.isNaN(value) && value !== '') {
          // eslint-disable-next-line no-param-reassign
          value = parseFloat(value as string).toFixed(precision);
        }
        return (
          <TextField
            hidden={hidden}
            label={label}
            margin={margin}
            inputRef={ref}
            onChange={(e) => {
              onChange(e);
              if (changeHandler) changeHandler(e);
            }}
            onBlur={onBlur}
            value={(value as string) || ''}
            type={type}
            error={!!localError}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            helperText={localError ? localError.message : ''}
            InputProps={{
              disableUnderline: true,
              readOnly: true,
              endAdornment: (
                <InputAdornment position="start">
                  <b>{!Number.isNaN(value) ? addornment : '-'}</b>
                </InputAdornment>
              ),
            }}
            // It's not duplicated, according TextField API there is InputProps and inputProps
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              style: {
                textAlign,
                padding: '0',
                width: '65px',
                fontWeight: 'bold',
                marginRight: '5px',
              },
            }}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
export default ReactHookPreviewField;
