/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { FieldError, useFormContext, useFieldArray } from 'react-hook-form';
import {
  DsmFieldset,
  DsmIcon,
  DsmButton,
  DsmGrid,
} from '@dsm-dcs/design-system-react';
import { v4 as uuidv4 } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType, ListEntry } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { resolvePath } from '../../../../../modules/Helpers/resolvePathFn';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import { getSingleEnumEntryTranslation } from '../../../../utils/translation-utils';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import {
  BreedingHousingType,
  GrowingHousingType,
  LayingHousingType,
  BeddingType,
} from '../../../../../../graphql/generated/blonk/poultry';
import {
  BreedingManureManagementSystemType,
  GrowingManureManagementSystemType,
  LayingManureManagementSystemType,
  ManureManagementSystem,
  PoultryBaseline,
  PoultryHousingAndManure,
} from '../../../../models/Baseline/PoultryBaseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import Resources from '../../CommonDataParts/v2.0/Resources';
import PoultryMMSBlockComponent from './PoultryMMSBlockComponent';
import PoultryMMSDescriptionDialog from './PoultryMMSDescriptionDialog';

type ReactChangedType = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

export interface PoultryHousingAndManureDialogProps
  extends BaselineDialogProps {
  animalType: AnimalType;
  stageType: string;
}

const getBaseTypeForMonthEndingMMS = (mms: string) => {
  let result = mms;
  const matches = mms.match(/(_OVER|_UNDER)?_(\d){1,}MONTH$/);
  if (matches) result = mms.substring(0, matches.index);
  return result;
};

const PoultryHousingAndManureDialog: FC<PoultryHousingAndManureDialogProps> = ({
  formVisible,
  itemIndex = 0,
  formType = FormType.Add,
  handleCancel,
  animalType,
  stageType,
}) => {
  const intl = useIntl();
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE' },
    { br: ' ' }
  );
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<PoultryHousingAndManure>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const fc = useFormContext();
  const { control } = fc;
  const {
    fields: mmsSystems,
    append: appendMMS,
    remove: removeMMS,
  } = useFieldArray({
    name: `${fieldItemPrefix}.manureSystems`,
    control,
    keyName: 'keyId',
  });
  const {
    fields: beddingSystems,
    append: appendBedding,
    remove: removeBedding,
  } = useFieldArray({
    name: `${fieldItemPrefix}.beddingSystems`,
    control,
    keyName: 'keyId',
  });
  const [, forceUpdate] = useState({});
  const [activeList, setActiveList] = useState<Array<ListEntry>>([]);

  useEffect(() => {
    if (formVisible)
      currResetValue.current = cloneDeep(
        fc.getValues(fieldItemPrefix)
      ) as PoultryHousingAndManure;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.housing) {
        resetObject.stages[itemIndex].stageData.housing =
          currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  const addMMS = () => {
    appendMMS({ id: uuidv4(), mmsType: '', mmsHoldingDuration: '', share: '' });
  };

  // sets share to 100% if for the given kinde (solid or slurry) only one left
  const setShare = () => {
    const currentMMS = (fc.getValues(
      `stages.${itemIndex}.stageData.housing.manureSystems`
    ) || []) as ManureManagementSystem[];
    if (currentMMS?.length === 1) {
      fc.setValue(
        `stages.${itemIndex}.stageData.housing.manureSystems.0.share`,
        100
      );
    }
  };

  const removeMMSHandler = (index: number) => {
    removeMMS(index);
    setShare();
  };

  const addBedding = () =>
    appendBedding({ beddingType: '', beddingAmount: '' });

  const getManureManagementSystemTypes = () => {
    switch (stageType) {
      case StageType.Growing:
        return GrowingManureManagementSystemType;
      case StageType.Laying:
        return LayingManureManagementSystemType;
      case StageType.Breeding:
        return BreedingManureManagementSystemType;
      default:
        return [];
    }
  };

  const mms = enumToOptionsArrayWithTranslatedStrings(
    getManureManagementSystemTypes(),
    intl,
    'SUSTELL.POULTRY.ENUMS.MMS'
  );

  const getFilteredList = (entries: ListEntry[]) => {
    const uniqueMMS: ListEntry[] = entries.reduce(
      (accumulator: ListEntry[], current: ListEntry) => {
        if (!accumulator.find((item) => item.value === current.value))
          accumulator.push(current);
        return accumulator;
      },
      []
    );
    return uniqueMMS;
  };

  const filteredMMSList = getFilteredList(
    mms.map((item) => {
      const base = getBaseTypeForMonthEndingMMS(item.value);
      return {
        value: base,
        text:
          base === item.value
            ? item.text
            : getSingleEnumEntryTranslation(
                base,
                intl,
                'SUSTELL.POULTRY.ENUMS.MMS'
              ),
      };
    })
  );

  const beddingsWithEmptyOption = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      'SUSTELL.POULTRY.ENUMS.BEDDING_TYPE'
    );
    const defaultOption = { text: 'No bedding', value: '' };
    return [defaultOption].concat(beddings);
  };

  const emptyBeddingSelected = (index: number) =>
    !fc.getValues(`${fieldItemPrefix}.beddingSystems.${index}.beddingType`);

  const resetBeddingAmount = (index: number) =>
    fc.setValue(
      `${fieldItemPrefix}.beddingSystems.${index}.beddingAmount`,
      '',
      { shouldValidate: true }
    );

  const beddingsChanged = (e: ReactChangedType, index: number) => {
    forceUpdate({});
    if (!e.target.value) resetBeddingAmount(index);
  };

  const getHousingType = () => {
    switch (stageType) {
      case StageType.Growing:
        return GrowingHousingType;
      case StageType.Laying:
        return LayingHousingType;
      case StageType.Breeding:
        return BreedingHousingType;
      default:
        return [];
    }
  };

  const housingTypeIsFreeRange = () =>
    fc.getValues(`${fieldItemPrefix}.housingType`) === 'FREE_RANGE';

  const classBorderBeddings =
    beddingSystems.length > 1 ? classes.additionalEntriesBlockHolder : '';

  const updateManureFormOnMMSChange = () => {
    forceUpdate({});
  };

  const showDescription = () => setOpenDescriptionDialog(true);
  const closeDescriptionDialog = () => setOpenDescriptionDialog(false);

  const manureSystemsError: FieldError | null = resolvePath(
    fc.errors,
    `${fieldItemPrefix}.manureSystems`,
    null
  ) as FieldError | null;
  const beddingSystemsError: FieldError | null = resolvePath(
    fc.errors,
    `${fieldItemPrefix}.beddingSystem`,
    null
  ) as FieldError | null;

  const calculateTimeInHousing = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const timeInFreeRange = parseInt(
      fc.getValues(`${fieldItemPrefix}.timeInFreeRange`),
      10
    );
    if (
      !Number.isNaN(timeInFreeRange) &&
      timeInFreeRange >= 0 &&
      timeInFreeRange <= 100
    ) {
      fc.setValue(`${fieldItemPrefix}.timeInFreeRange`, timeInFreeRange);
      fc.setValue(`${fieldItemPrefix}.timeInHousing`, 100 - timeInFreeRange);
    } else fc.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
  };

  const changeHousingType = (e: ReactChangedType) => {
    if (e.target.value !== 'FREE_RANGE') {
      fc.setValue(`${fieldItemPrefix}.timeInFreeRange`, '');
      fc.setValue(`${fieldItemPrefix}.timeInHousing`, '');
    } else {
      fc.setValue(`${fieldItemPrefix}.timeInFreeRange`, 100);
      fc.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
    }
    forceUpdate({});
  };

  // should only be called once on entry
  useEffect(() => {
    setActiveList(filteredMMSList);
    calculateTimeInHousing();
    if (mmsSystems.length === 0) addMMS();
    if (beddingSystems.length === 0) addBedding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="demiWide"
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      introText={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.HOUSING_AND_MANURE.RESOURCES.DESCRIPTION',
      })}
    >
      {openDescriptionDialog && (
        <PoultryMMSDescriptionDialog
          open
          stageType={
            stageType as
              | StageType.Breeding
              | StageType.Growing
              | StageType.Laying
          }
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      <DsmGrid
        className={classes.additionalEntriesBlockHolder}
        style={{ marginBottom: 'var(--dsm-spacing-px-6)' }}
      >
        <Resources
          formType={formType}
          inputType={`stages.${itemIndex}.stageData.housing.resourceUse`}
          animalType={animalType}
          isBaselineDialog
        />
      </DsmGrid>
      <DsmGrid
        className={classes.dsmGridOneColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}
      >
        {intl.formatMessage({
          id: 'SUSTELL.PROCESS.DIALOG.STAGE.HOUSING_AND_MANURE.HOUSING_TYPE.DESCRIPTION',
        })}
      </DsmGrid>
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}
      >
        <ReactHookDsmSelect
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.MANURE.HOUSING_TYPE',
          })}
          name={`${fieldItemPrefix}.housingType`}
          options={enumToOptionsArrayWithTranslatedStrings(
            getHousingType(),
            intl,
            'SUSTELL.POULTRY.ENUMS.HOUSING_TYPE'
          )}
          changeHandler={(e: ReactChangedType) => changeHousingType(e)}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.HOUSING.HOUSING_TYPE.TOOLTIP',
          })}
          required
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={fc.getValues(`${fieldItemPrefix}.housingType`)}
        />
      </DsmGrid>
      {housingTypeIsFreeRange() && (
        <DsmGrid className={classes.dsmGridTwoColumn}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.timeInFreeRange`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.HOUSING.TIME_IN_FREE_RANGE',
            })}
            changeHandler={() => calculateTimeInHousing()}
            adornment="%"
            disabled={formType === FormType.View}
            required
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={fc.getValues(`${fieldItemPrefix}.timeInFreeRange`)}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.timeInHousing`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.HOUSING.TIME_IN_HOUSING',
            })}
            adornment="%"
            disabled
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={fc.getValues(`${fieldItemPrefix}.timeInHousing`) || 0}
          />
        </DsmGrid>
      )}
      {!!manureSystemsError && manureSystemsError.type === 'min' && (
        <DsmGrid className={classes.dsmGridOneColumn}>
          <Alert severity="error">
            <AlertTitle>
              {intl.formatMessage({ id: 'GENERAL.ERROR' })}
            </AlertTitle>
            {manureSystemsError.message}
          </Alert>
        </DsmGrid>
      )}
      {mmsSystems &&
        mmsSystems?.map((item, index: number) => (
          <PoultryMMSBlockComponent
            key={item.keyId}
            filteredMMSList={activeList}
            manureManagementSystems={mms}
            formType={formType}
            stageIndex={itemIndex}
            itemIndex={index}
            removeHandler={removeMMSHandler}
            mmsChangeHandler={updateManureFormOnMMSChange}
          />
        ))}
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton variant="text" onClick={addMMS} disabled={formType === FormType.View}>
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.HOUSING.ADD_ANOTHER_MMS',
          })}
        </DsmButton>
        <DsmButton variant="text" onClick={showDescription}>
          <DsmIcon slot="before" name="general/eye" />
          {intl.formatMessage({ id: 'DATABASE_FOUNDATION_DEFINITIONS' })}
        </DsmButton>
      </DsmGrid>
      <div style={{ paddingTop: 'var(--dsm-spacing-px-4' }}>
        {!!beddingSystemsError && beddingSystemsError.type === 'min' && (
          <DsmGrid className={classes.dsmGridOneColumn}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'GENERAL.ERROR' })}
              </AlertTitle>
              {beddingSystemsError.message}
            </Alert>
          </DsmGrid>
        )}
        {beddingSystems &&
          beddingSystems?.map((item, index) => (
            <div className={classBorderBeddings} key={item.keyId}>
              {removeBedding && beddingSystems.length > 1 && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => removeBedding(index)}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING_TYPE',
                  })}
                  name={`${fieldItemPrefix}.beddingSystems.${index}.beddingType`}
                  options={beddingsWithEmptyOption()}
                  disabled={formType === FormType.View}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={fc.getValues(
                    `${fieldItemPrefix}.beddingSystems.${index}.beddingType`
                  )}
                  changeHandler={(e: ReactChangedType) =>
                    beddingsChanged(e, index)
                  }
                />
                <div>
                  <DsmFieldset>
                    <ReactHookDsmInput
                      label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT',
                      })}
                      name={`${fieldItemPrefix}.beddingSystems.${index}.beddingAmount`}
                      adornment={barnOutputMassUnit}
                      type="number"
                      disabled={
                        formType === FormType.View ||
                        emptyBeddingSelected(index)
                      }
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      defaultValue={fc.getValues(
                        `${fieldItemPrefix}.beddingSystems.${index}.beddingAmount`
                      )}
                      tooltip={intl.formatMessage({
                        id: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT.TOOLTIP',
                      })}
                    />
                    <div />
                  </DsmFieldset>
                </div>
              </DsmGrid>
            </div>
          ))}
      </div>
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton variant="text" onClick={addBedding} disabled={formType === FormType.View}>
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.HOUSING.ADD_ANOTHER_BEDDING',
          })}
        </DsmButton>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await fc.trigger(`stages.${itemIndex}.stageData.housing`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryHousingAndManureDialog;
