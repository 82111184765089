import React, { useEffect, useState } from 'react';

import {
  convertValue,
  defaultUnits,
  explicitConvertValue,
} from '../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from './UserProfilePrefs';

const math = require('mathjs');

export const UnitConverter = ({ value, field, unitToConvert }) => {
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const [userUnits, setUserUnits] = useState(defaultUnits);

  useEffect(() => {
    if (userUOM) {
      setUserUnits(userUOM);
    }
  }, [userUOM]);

  const convertedValue = () => {
    if (!value) {
      return;
    }

    const typedValue = parseFloat(value);
    if (unitToConvert) {
      return `${convertValue(
        field,
        typedValue,
        unitToConvert
      )} ${unitToConvert}`;
    } else {
      if (userUnits && userUnits[field]) {
        return `${convertValue(field, typedValue, userUnits[field])} ${
          userUnits[field]
        }`;
      } else {
        return typedValue.toString();
      }
    }
  };

  return <>{convertedValue()}</>;
};

const toNum = (val) => {
  if (val != null && typeof val !== 'number') {
    // eslint-disable-next-line no-param-reassign
    val = parseFloat(val);
  }
  return val;
};

// Until converted to TS, craeted as a wrapper fun, so it could be called from TS files with optianl params provided

/** converts distance units
      direction: 0 (default) from default to userUOM
      1 userUOM to default */
export const convertDistanceUnits = (
  value,
  type,
  userUOM,
  originUnit,
  direction = 0
) => {
  return convertDistanceUnitsOld(value, type, userUOM, originUnit, direction);
};

/** converts distance units
      direction: 0 (default) from default to userUOM
      1 userUOM to default */
const convertDistanceUnitsOld = (
  value,
  type,
  userUOM,
  originUnit = null,
  direction = 0
) => {
  // eslint-disable-next-line no-param-reassign
  value = toNum(value);

  let inUnit =
    direction === 0
      ? originUnit || defaultUnits.unitTransportDistanceTerrestrial
      : userUOM.unitTransportDistanceTerrestrial;
  let outUnit =
    direction === 0
      ? userUOM.unitTransportDistanceTerrestrial
      : defaultUnits.unitTransportDistanceTerrestrial;
  let distance = value;
  if (distance) {
    if (type === 'inland ship' || type === 'inland waterways ship') {
      inUnit =
        direction === 0
          ? originUnit || defaultUnits.unitTransportDistanceInlandWaterways
          : userUOM.unitTransportDistanceInlandWaterways;
      outUnit =
        direction === 0
          ? userUOM.unitTransportDistanceInlandWaterways
          : defaultUnits.unitTransportDistanceInlandWaterways;
    } else if (
      type === 'seaship' ||
      type === 'sea ship' ||
      type === 'sea shipping'
    ) {
      inUnit =
        direction === 0
          ? originUnit || defaultUnits.unitTransportDistanceSea
          : userUOM.unitTransportDistanceSea;
      outUnit =
        direction === 0
          ? userUOM.unitTransportDistanceSea
          : defaultUnits.unitTransportDistanceSea;
    }
    if (value && inUnit !== outUnit) {
      // console.log("transport units: ", type, inUnit, outUnit);
      distance = explicitConvertValue(distance, inUnit, outUnit);
      if (distance !== null) distance = math.round(distance, 5);
    }
  }
  return { value: distance, unit: outUnit };
};

// converts nutritional units
// direction: 0 (default) default => userUOM
//            1 userUOM => default
export const convertNutritionalUnits = (
  value,
  type,
  userUOM,
  direction = 0
) => {
  const inInputMass =
    direction === 0
      ? defaultUnits.unitIngredientQuantityInputMass
      : userUOM.unitIngredientQuantityInputMass;
  const outInputMass =
    direction === 0
      ? userUOM.unitIngredientQuantityInputMass
      : defaultUnits.unitIngredientQuantityInputMass;

  let inUnit =
    direction === 0
      ? defaultUnits.unitCompoundFeedNutritionalDataContent
      : userUOM.unitCompoundFeedNutritionalDataContent;
  let outUnit =
    direction === 0
      ? userUOM.unitCompoundFeedNutritionalDataContent
      : defaultUnits.unitCompoundFeedNutritionalDataContent;

  if (type === 'gross energy') {
    // console.log("Found gross energy")
    inUnit =
      (direction === 0
        ? defaultUnits.unitNutritionalGrossEnergy
        : userUOM.unitNutritionalGrossEnergy) +
      '/' +
      inInputMass;
    outUnit =
      (direction === 0
        ? userUOM.unitNutritionalGrossEnergy
        : defaultUnits.unitNutritionalGrossEnergy) +
      '/' +
      outInputMass;
  } else if (type === 'digestible energy fraction') inUnit = outUnit = '%';

  if (value && inUnit !== outUnit) {
    value = explicitConvertValue(value, inUnit, outUnit);
    if (value !== null) value = math.round(value, 5);
  }
  return { value: value, unit: outUnit };
};

// converts mass units
// direction: 0 (default) default => userUOM
//            1 userUOM => default
export const convertIngredientInputMass = (value, userUOM, direction = 0) => {
  const inInputMass =
    direction === 0
      ? defaultUnits.unitIngredientQuantityInputMass
      : userUOM.unitIngredientQuantityInputMass;
  const outInputMass =
    direction === 0
      ? userUOM.unitIngredientQuantityInputMass
      : defaultUnits.unitIngredientQuantityInputMass;

  let inOutputMass =
    direction === 0
      ? defaultUnits.unitCompoundFeedOutputMass
      : userUOM.unitCompoundFeedOutputMass;
  let outOutputMass =
    direction === 0
      ? userUOM.unitCompoundFeedOutputMass
      : defaultUnits.unitCompoundFeedOutputMass;

  if (inInputMass !== outInputMass) {
    const inUnit = inInputMass + '/' + inOutputMass;
    const outUnit = outInputMass + '/' + outOutputMass;
    value = explicitConvertValue(value, inUnit, outUnit);
    if (value !== null) value = math.round(value, 5);
  }
  return value;
};

// converts mass units
// direction: 0 (default) default => userUOM
//            1 userUOM => default
export const convertOnSiteActivityUnits = (
  value,
  type,
  userUOM,
  direction = 0
) => {
  // eslint-disable-next-line no-param-reassign
  value = toNum(value);
  const inOutputMass =
    direction === 0
      ? defaultUnits.unitCompoundFeedOutputMass
      : userUOM.unitCompoundFeedOutputMass;
  const outOutputMass =
    direction === 0
      ? userUOM.unitCompoundFeedOutputMass
      : defaultUnits.unitCompoundFeedOutputMass;

  let inUnit = null;
  let outUnit = null;
  // console.log('ACTIVITIES: ', type);
  if (
    type === 'electricity use' ||
    type === 'electricity' ||
    type === 'electricityPhotovoltaic' ||
    type === 'electricityWind' ||
    type === 'diesel' ||
    type === 'gasoline' ||
    type === 'propaneOrLpg'
  ) {
    inUnit =
      direction === 0
        ? defaultUnits.unitCompoundFeedElectricity
        : userUOM.unitElectricityUse;
    outUnit =
      direction === 0
        ? userUOM.unitElectricityUse
        : defaultUnits.unitCompoundFeedElectricity;
  } else if (type === 'gas use' || type === 'gas') {
    inUnit =
      direction === 0
        ? defaultUnits.unitNaturalGasUse
        : userUOM.unitNaturalGasUse;
    outUnit =
      direction === 0
        ? userUOM.unitNaturalGasUse
        : defaultUnits.unitNaturalGasUse;
  } else if (type === 'heat use' || type === 'heat' || type === 'coal') {
    inUnit = direction === 0 ? defaultUnits.unitHeatUse : userUOM.unitHeatUse;
    outUnit = direction === 0 ? userUOM.unitHeatUse : defaultUnits.unitHeatUse;
  } else if (type === 'water use' || type === 'water') {
    inUnit =
      direction === 0
        ? defaultUnits.unitCompoundFeedWaterUse
        : userUOM.unitCompoundFeedWaterUse;
    outUnit =
      direction === 0
        ? userUOM.unitCompoundFeedWaterUse
        : defaultUnits.unitCompoundFeedWaterUse;
  }
  if (value && inUnit && outUnit) {
    inUnit = inUnit + '/' + inOutputMass;
    outUnit = outUnit + '/' + outOutputMass;
    // console.log("INUNIT => OUTUNIT",inUnit, outUnit)
    value = explicitConvertValue(value, inUnit, outUnit);
    if (value !== null) value = math.round(value, 5);
  }
  return { value: value, unit: outUnit };
};
