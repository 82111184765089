import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch, } from "react-hook-form";
import { unitLong } from '../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
// import { useIntl } from 'react-intl';
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { masterDataSorter } from '../../../sustell_15/helpers/sortingFunctions';
// import { ClosedCaptionOutlined } from "@material-ui/icons";

const NewRationInput = (props) => {
  const { fieldItemPrefix, subFieldName, label, index, updateTotalFeedIntake, formState, animalType } = props;
  const { control, errors, getValues } = useFormContext();
  const intl = useIntl();

  const resolvePath = (object, path, defaultValue) => path
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => o ? o[p] : defaultValue, object);
  const ftName = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].feedType`;

  // if open the feed dialog, select feed/ingredient, then close it, and open it again, 
  // useWatch will return undefiend although the feed type is selected, while getValues will work properly
  // const watch = useWatch({control, name: ftName});
  //const watch = getValues(ftName);
  useWatch({control});
  let watch = getValues(ftName);
  
  // will not rename field itself just labels and placeholders for salmon, correct usage is handled by the mapper
  const name   = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].kgPerAnimal`;

  useWatch({control, name: ftName });
  useWatch({control, name: name });
  useEffect(() => () => {
    updateTotalFeedIntake(name, 0);
  }, [name, updateTotalFeedIntake])
 
  const localError = resolvePath(errors, name, null);
  const feedItemsLoaded = props.availableFeedItems?.some(x => x.type !== '');
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  let selectedItemValue = getValues(ftName);
  selectedItemValue = selectedItemValue !== undefined ? selectedItemValue : "";
  let amountValue =getValues(name);
  amountValue = amountValue !== undefined ? amountValue : "";
  
  return (
    <>
      <Grid item xs={7} key={fieldItemPrefix + "." + subFieldName + "[" + index + "]_inputPart1"}>
        <FormControl variant="outlined" style={{ marginBottom: 0, width: '95%', paddingBottom: 0 }}>
          <InputLabel >
            {label}
          </InputLabel>
          <Controller
            control={control}
            name={ftName}
            defaultValue={selectedItemValue}
            render={ ({ onChange, onBlur, value, name, ref }) => (
                <Select
                  fullWidth
                  label={label}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={ (value && feedItemsLoaded ) ? value : '' }
                  disabled={formState === "view" ? true : false}
                
                >
                  {
                    props.availableFeedItems?.sort(masterDataSorter).map((compoundFeed) =>
                      <MenuItem key={compoundFeed.value} value={compoundFeed.value}>{compoundFeed.display_name}</MenuItem>
                    )
                  }
                </Select>
            )
          }
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} key={`${fieldItemPrefix  }.${  subFieldName  }[${  index  }]_inputPart2`} >
        <Controller
          name={name}
          control={control}
          defaultValue={amountValue}
          render={({ onChange, onBlur, value, name, ref }) => {
            // updateTotalFeedIntake(name, value || 0);
            const labelKey = animalType === 'SALMON' ? "ADDORNMENT.UNITS.WITH_PER_YEAR_SUFIX" : "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX";
            return (
              <TextField
                name={name}
                onChange={(event) => {
                  onChange(event);
                  updateTotalFeedIntake(name, value || 0);
                }}
                inputRef={ref}
                label={ intl.formatMessage( {id: labelKey },{unit: (userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg") }) }
                onBlur={onBlur}
                value={ ( value !== undefined && feedItemsLoaded ) ? value : '' }
                helperText={(localError && localError.message) ? localError.message : ""}
                error={Boolean(localError)}
                variant="outlined"
                fullWidth
                disabled={formState === "view" || !watch}
              />
            )
          }}
        />
      </Grid>
    </>
  )
};
export default NewRationInput;