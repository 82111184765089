import { Grid } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import {
  BroilerEmission,
  BroilerIntervention,
} from '../../../../models/Intervention';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { BrolierInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const BInterventionEmissionsFormDialog = ({
  formVisible,
  formType,
  stageIndex,
  handleCancel,
  baseline,
}: BrolierInterventionFormDialogProps) => {
  const intl = useIntl();

  const currResetValue = useRef<BroilerEmission>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.emissions`;

  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...(fc.getValues(fieldItemPrefix) as BroilerEmission),
      }; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as BroilerIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.emissions) {
        stageData.emissions = { ...currResetValue.current };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const welcomeString = intl.formatMessage({ id: 'EMISSIONS.WELCOME' });
  const emissions = baseline?.stages?.[stageIndex]?.stageData?.emissions;

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      datasetType="intervention"
      handleClose={handleResetClick}
      iconCode="emissionIcon"
      formTitle={formTitle}
      introText={intl.formatMessage(
        { id: 'EMISSIONS.INTRO' },
        { boldedWelcome: <strong>{welcomeString}</strong>, br: <br /> }
      )}
    >
      <Grid container spacing={2} direction="row" justifyContent="space-between">
        <Grid item container direction="column" xs={6} spacing={2}>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.methane`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.METHANE',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.METHANE.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.methane}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitrousOxideDirect`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NOx_DIRECT',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.nitrousOxideDirect}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitrousOxideIndirect`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.nitrousOxideIndirect}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.amonia`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.amonia}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nitricOxide`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NOx_MANURE',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NOx_MANURE.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.nitricOxide}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={2}>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.nonMethaneVolatile`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.nonMethaneVolatile}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.PM10`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.PM10',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.PM10.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.PM10}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.PM25`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.PM2_5',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.PM2_5.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.PM25}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalSuspendedParticles`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES.TOOLTIP',
              })}
              metricUnit="%"
              baseline={emissions?.totalSuspendedParticles}
              disabled={formType === 'view'}
              minAllowedValue={-100}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default BInterventionEmissionsFormDialog;
