import { API } from 'aws-amplify';

import { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCompoundFeedList,
  getCustomerDashboardTargets,
  getCustomerWithBaselines,
  getFootprint,
  listCompoundFeedsV2,
} from '../../../../graphql/queries';
import {
  CompoundFeed,
  CompoundFeedV2,
  Customer,
  Footprint,
  FootPrintType,
  Maybe,
} from '../../../../graphql/types';
import DashboardModel from '../../models/Dashboard/Dashboard';
import DashboardFootprintModel from '../../models/Dashboard/DashboardFootprint';
import {
  DashboardType,
  DateRange,
  SelectBaselinesInterventions,
} from '../../models/Dashboard/DashboardTypes';
import {
  extractFarmData,
  buildFarmFootprintsQuery,
  getBaselineReferences,
  getInterventionReferences,
  referencesChunks,
  createDashboardFarmFootprints,
  createDashboardFeedFootprints,
  createImpactCategoryObj,
} from './helper';
import { FootprintCategoryKey } from '../../models/Footprint';
import { getDashboardOptionsState } from '../../../state/drafts/additional';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { getDateDeltaYears } from '../../utils/datetime-utils';
import { clearDashboardOptionsDraft } from '../../../state/drafts/dashboardFiltersDraftSlice';

export enum DraftLoadingState {
  None = 'None',
  LoadedQueryDependantOptions = 'LoadedQueryDependantOptions',
  LoadedDashboardOptions = 'LoadedDashboardOptions',
  Finished = 'Finished'
}

// TODO: adjust size if needed
/**
 * Number of footprint items per graphql request
 */
export const ITEMS_PER_REQUEST =  30;

interface DashboardProps {
  customerID: string | undefined;
  customerName: string;
  dashboardType: DashboardType;
  setDashboardType: Dispatch<DashboardType>;
  draftLoading: DraftLoadingState;
  setDraftLoading: Dispatch<DraftLoadingState>;
}

/**
 *
 * @param customerID - current selected customer
 * @param dashboardType - Farm or Feeds dashboard
 * @param withInterventions - include interventions footprints
 * @returns
 */
const useDashboard = ({
  customerID,
  customerName,
  dashboardType,
  setDashboardType,
  draftLoading,
  setDraftLoading,
}: DashboardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState<DashboardModel>();
  const [baselineInterventionOptions, setBaselineIntervention] = useState<
    SelectBaselinesInterventions[]
  >([SelectBaselinesInterventions.CurrentBaselines]);

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: getDateDeltaYears(new Date(), -10),
    endDate: getDateDeltaYears(new Date(), 10),
    key: 'selection',
  });
  const [loading, setLoading] = useState<boolean>(true);

  /**
   *
   * @param range from date picker
   */
  const setDashboardDateRange = (range: DateRange) => {
    setDateRange({ ...range });
  };

  /**
   *
   * @param options select active/archive baselines and interventions
   */
  const setBaselineInterventionOptions = (
    options: SelectBaselinesInterventions[]
  ) => {
    // console.log('here bl int', options);
    if (dashboardType !== DashboardType.Farm) {
      return;
    }

    if (
      options.some((el) => !baselineInterventionOptions.includes(el)) ||
      baselineInterventionOptions.some((el) => !options.includes(el))
    ) {
      setBaselineIntervention(options);
    } else if (options.length === 0) {
      dashboard?.setSelectedBaselinesInterventions([SelectBaselinesInterventions.CurrentBaselines])
      setBaselineIntervention([SelectBaselinesInterventions.CurrentBaselines]);
    }
  };

  /**
   * Used to set states of query dependant options of dashboard from draft
   * before sending request
   */
  useEffect(() => {
    if (draftLoading === DraftLoadingState.None) {
      const dashboardOptionsState = getDashboardOptionsState();
      if (!dashboardOptionsState) {
        setDraftLoading(DraftLoadingState.Finished);
      }
      else if ( (!!dashboardOptionsState.customerId && dashboardOptionsState.customerId !== customerID)) {
        dispatch(clearDashboardOptionsDraft());
        setDraftLoading(DraftLoadingState.Finished);
      }
       else{
        setLoading(true);
        if (dashboardOptionsState.selectedType) {
          setDashboardType(dashboardOptionsState.selectedType);
        }
        if (dashboardOptionsState.dateRange){
          setDashboardDateRange(dashboardOptionsState.dateRange);
        }
        if (dashboardOptionsState.selectedBaselinesInterventions) {
          const blIntOptions =
            (dashboardOptionsState.selectedBaselinesInterventions
            && dashboardOptionsState.selectedBaselinesInterventions.length > 0) ?
            dashboardOptionsState.selectedBaselinesInterventions
            : [SelectBaselinesInterventions.CurrentBaselines]
          setBaselineInterventionOptions(blIntOptions);
        }
        setDraftLoading(DraftLoadingState.LoadedQueryDependantOptions);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If possible try to restore filter and other options from draft
  const restoreOptions = (dashboard1: DashboardModel) => {
    if (draftLoading !== DraftLoadingState.LoadedQueryDependantOptions) {
      return;
    }

    const dashboardOptionsState = getDashboardOptionsState();
    if (!dashboardOptionsState) {
      return;
    }

    // If customer changed, clear draft to avoid invalid filters setting
    if ( (!!dashboardOptionsState.customerId && dashboardOptionsState.customerId !== customerID)) {
      dispatch(clearDashboardOptionsDraft());
      return;
    }
    const {simulationOptions, impactCategoryKey, filters, footprintCompare, sort, impactType, selectedType} = dashboardOptionsState;
          if (simulationOptions) {
            dashboard1.restoreSimulations(simulationOptions, selectedType);
          }
          if (impactCategoryKey) {
            const newCategory = createImpactCategoryObj(impactCategoryKey, intl, dashboard1);
            dashboard1.setActiveImpactCategory(newCategory);
          }
          if (impactType) {
            dashboard1.setImpactType(impactType);
          }
          if (filters) {
            dashboard1.restoreAvailableFilters(filters);
          }
          if (sort) {
            dashboard1.setSortOptions(sort);
          }

          if (footprintCompare && footprintCompare.baseline) {
            const baseline = dashboard1.unfilteredFootprints.find(f => f.reference === footprintCompare.baseline);
            const compare = dashboard1.unfilteredFootprints.find(f => f.reference === footprintCompare.compare);
            if (baseline) {
              dashboard1.setBaselineFootprint(baseline);
            }
            if (compare) {
              dashboard1.setComparisonFootprint(compare);
            }
          }
        setDraftLoading(DraftLoadingState.LoadedQueryDependantOptions);
  }

  type DashboardTargets = {
    farm: {
      [key: string]: number | undefined;
    }
    feed: {
      [key: string]: number | undefined;
    }
  }

  const setTargetsForDashboard = (dashboardModel: DashboardModel, targetsParsed: DashboardTargets) => {
    if (!targetsParsed || targetsParsed === null) {
      return;
    }
    let targets: { [x: string]: number | undefined };
    if (dashboardType === DashboardType.Feeds) {
      targets = targetsParsed.feed;
    } else {
      targets = targetsParsed.farm;
    }
    Object.keys(targets || {}).forEach((key) => {
      if (!targets[key]) {
        return;
      }
      dashboardModel.addTarget(
        key as FootprintCategoryKey,
        targets[key] as number
      );
    });
  }

  const afterLoadSetTargets = async (dashboardModel: DashboardModel) => {
    const queryWithVars = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      query: getCustomerDashboardTargets,
      variables: {
        id: customerID,
      },
    };
    const result = await (API.graphql(queryWithVars) as Promise<{
      data: {
        getCustomer: { dashboardTargets: string };
      };
    }>);
    if (!result.data.getCustomer?.dashboardTargets || !dashboardModel) {
      return;
    }
    const targetsParsed = JSON.parse(
      result.data.getCustomer.dashboardTargets
    ) as DashboardTargets;
    setTargetsForDashboard(dashboardModel, targetsParsed);
  }

  useEffect(() => {

    const loadFarmFoorptintsDashboard = () => {
      const queryWithVars = {
        query: getCustomerWithBaselines,
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          id: customerID,
        },
      };
      // Get all baseline/intervention data for current customer
      (
        API.graphql(queryWithVars) as Promise<{
          data: {
            getCustomer: Customer;
          };
        }>
      )
        .then((result) => {
          // Extract only baselines for the current date range
          const activeBaselinesExtended = extractFarmData(
            result.data.getCustomer,
            dateRange
          );
        const dashboardTargetsParsed =  JSON.parse(
          result.data.getCustomer.dashboardTargets as string
        ) as DashboardTargets;

        const isCurrentBl = baselineInterventionOptions?.some(el => el === SelectBaselinesInterventions.CurrentBaselines);
        const isArchivedBl = baselineInterventionOptions?.some(el => el === SelectBaselinesInterventions.ArchivedBaselines);
        const baselineReferences = getBaselineReferences(activeBaselinesExtended, isCurrentBl, isArchivedBl);

        const isCurrentInt = baselineInterventionOptions?.some(el => el === SelectBaselinesInterventions.CurrentInterventions);
        const isArchivedInt = baselineInterventionOptions?.some(el => el === SelectBaselinesInterventions.ArchivedInterventions);
        const interventionsReferences = getInterventionReferences(activeBaselinesExtended, isCurrentInt, isArchivedInt);

        const baselineRefChunks = referencesChunks(baselineReferences, ITEMS_PER_REQUEST);
        const interventionRefChunks = referencesChunks(interventionsReferences, ITEMS_PER_REQUEST);

        const iterationsArr = [...Array(Math.max(baselineRefChunks.length, interventionRefChunks.length)).keys()];
        const promises = iterationsArr.map(index => {
          // For active baselines (and interventions) get their footrpints
          const footprintsQuery = buildFarmFootprintsQuery(
            baselineRefChunks.at(index) as string[] || [""],
            interventionRefChunks.at(index) as string[] || [""],
            customerID as string
          );

          return (
            API.graphql(footprintsQuery) as Promise<{
              data: {
                baselines: Maybe<Footprint[]> | undefined;
                interventions: Maybe<Footprint[]> | undefined;
              };
            }>
          )
        });
          Promise.all(promises).then((footprintsResponses) => {
              let dashboardFootprints: DashboardFootprintModel[] = [];

              footprintsResponses.forEach(footprintsResponse => {
                dashboardFootprints = dashboardFootprints.concat(createDashboardFarmFootprints(footprintsResponse, activeBaselinesExtended));
              })

              const newDashboard =  new DashboardModel({
                dashboardFootprints,
                type: dashboardType,
                customerId: customerID as string,
                customerName,
                dateRange,
              });
              newDashboard.setSelectedBaselinesInterventions(baselineInterventionOptions);

              setTargetsForDashboard(newDashboard, dashboardTargetsParsed);
              restoreOptions(newDashboard);
              setDashboard(newDashboard);
              setLoading(false);

            })
            .catch((errF) => {
              console.log('error fetching farm footprints', errF);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log('err fetching farm data', err);
          setLoading(false);
        });
    }

    const loadFeedFootprintsDashboard = () => {
      const queryWithVarsV1 = {
        query: getCompoundFeedList,
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          customerId: customerID,
        },
      };
      const queryWithVarsV2 = {
        query: listCompoundFeedsV2,
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          customerId: customerID,
        },
      };

      const promisesFeeds = [
        (
          API.graphql(queryWithVarsV1) as Promise<{
            data: {
              getCompoundFeedList: { items: CompoundFeed[] };
            };
          }>
        ),
        (
          API.graphql(queryWithVarsV2) as Promise<{
            data: {
              listCompoundFeedsV2: { items: CompoundFeedV2[] };
            };
          }>
        )
      ];

      Promise.all(promisesFeeds)
        .then((results) => {
          let feeds: CompoundFeed[] = [];
          if (results[0]?.data) {
            feeds = (results[0].data as {getCompoundFeedList: { items: CompoundFeed[] }}).getCompoundFeedList.items;
          }

          const feedReferences = feeds
            .filter((f) => {
              const { year } = f;
              const activeYearDate = new Date(year.value.toString());
              const startDate = dateRange.startDate
                ? dateRange.startDate
                : new Date(0);
              return startDate <= activeYearDate;
            })
            .map((f) => f.id);

          let feedsV2: CompoundFeedV2[] = [];
          if (results[1]?.data) {
            feedsV2 = (results[1].data as {listCompoundFeedsV2: { items: CompoundFeedV2[] }})
              .listCompoundFeedsV2.items || [];

            feedsV2.filter((f) => {
                const { year } = f;
                const activeYearDate = new Date(year.toString());
                const startDate = dateRange.startDate
                  ? dateRange.startDate
                  : new Date(0);
                return startDate <= activeYearDate;
              })
              .forEach(cf => {
                feedReferences.push(cf.id);
              });
          }
          const chunks = referencesChunks(feedReferences, ITEMS_PER_REQUEST);
          const promises = chunks.map(refChunk => {
            const footprintQueryWithVars = {
              query: getFootprint,
              variables: {
                customerId: customerID,
                references: refChunk,
                type: FootPrintType.CompoundFeed,
              },
            };
            return (
              API.graphql(footprintQueryWithVars) as Promise<{
                data: {
                  getFootprint: Footprint[];
                };
              }>
            );
          });
          Promise.all(promises).then((multipleResponse) => {
            let dashboardFootprints: DashboardFootprintModel[] = [];
            multipleResponse.forEach(footprintsResponse => {
              dashboardFootprints = dashboardFootprints.concat(createDashboardFeedFootprints(footprintsResponse, feeds, feedsV2));
            });

            const dashboardModel =  new DashboardModel({
              dashboardFootprints,
              type: dashboardType,
              customerId: customerID as string,
              customerName,
              dateRange
            });

          afterLoadSetTargets(dashboardModel)
            .then(() => undefined)
            .catch(() =>  undefined)
            .finally(() => {
              restoreOptions(dashboardModel);
              setDashboard(dashboardModel);
              setLoading(false);
            });
          })
          .catch((err) => {
            console.log('error fetching feed footprints: ', err);
            setLoading(false);
          });
        })
        .catch((err) => {
          console.log('err loading dashboard feeds', err);
          setLoading(false);
        });
    }

    if (!customerID) {
      return;
    }
    setLoading(true);

    if (draftLoading === DraftLoadingState.None) {
      return;
    }

    if (dashboardType === DashboardType.Farm) {
      loadFarmFoorptintsDashboard();
    } else {
      loadFeedFootprintsDashboard();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, dashboardType, dateRange, baselineInterventionOptions, draftLoading]);

  return {
    dashboard,
    setDashboardDateRange,
    loading,
    baselineInterventionOptions: dashboardType !== DashboardType.Farm ? [] : baselineInterventionOptions,
    setBaselineInterventionOptions,
  };
};

export default useDashboard;
