// This is disabled to avoid used of defaultProps property and to use classic destructing with default values for props
/* eslint-disable react/require-default-props */
import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  DetailedHTMLProps,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import ReactHookDsmSelect from '../../Helpers/ReactHookDsmSelect2';
import lookupValues from '../../../sustell_15/helpers/lookupValues';
import { resolvePath } from '../../Helpers/resolvePathFn';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DSM_DISABLED_LABEL, processAndStageStylesV2 } from '../../../../_metronic/layout';
import { BaselineFeedWithOrigin } from '../../../sustell_15/models/Baseline';
import { ListEntry } from '../../../sustell_15/components/FarmFlow/common';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute',
  height: '16px',
  width: '16px',
  top: '29px',
  right: '7px',
};

const useStyles = makeStyles({
  root: {
    // minHeight: '74px',
    '& input': {
      marginTop: '-4px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      lineHeight: '28px',
    },
    '& label': {
      position: 'relative',
      width: '125% !important',
      paddingLeft: '0px',
      marginBottom: '9px',
      fontSize: '16px',
      fontWeight: '500',
    },
    '& legend': {
      display: 'none',
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-root': {
      '& div.MuiInputAdornment-positionEnd': {
        '& p': {
          fontSize: '13px',
          marginBottom: '3px',
        },
      },
      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px !important',
    },
  },
});

interface SustellInterventionSingleIngredientProps {
  fieldItemPrefix: string;
  // label: string;
  tooltip?:
    | string
    | DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  metricUnit: string;
  // baseline: number | string | null | undefined;
  baseline?: BaselineFeedWithOrigin | null;
  disabled: boolean;
  readonly?: boolean;
  minAllowedValue?: number;
  maxAllowedValue?: number;
  precision?: number;
  onlySetMetricIfNoBaseline?: boolean | null;
  availableOrigins?: ListEntry[];
  shortLabel?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  displayFormattedUnit?: boolean;
  originalMetricUnit?: string;
}

const SustellInterventionSingleIngredient = ({
  fieldItemPrefix,
  // label,
  tooltip,
  metricUnit,
  baseline,
  disabled,
  readonly,
  minAllowedValue = 0,
  maxAllowedValue,
  precision = 2,
  onlySetMetricIfNoBaseline = false,
  availableOrigins,
  shortLabel = false,
  type = 'text',
  displayFormattedUnit = true,
  originalMetricUnit = '',
}: SustellInterventionSingleIngredientProps) => {
  const intl = useIntl();
  const fc = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;

  const classesLocal = useStyles();
  let numOldValue: number | null = null;
  if (typeof baseline?.kgPerAnimal === 'number')
    numOldValue = baseline?.kgPerAnimal;
  else if (typeof baseline?.kgPerAnimal === 'string')
    numOldValue = parseFloat(baseline?.kgPerAnimal);

  if (Number.isNaN(numOldValue)) numOldValue = null;

  const amountName = `${fieldItemPrefix}.kgPerAnimal`;
  const originName = `${fieldItemPrefix}.origin`;
  const baselineOriginName = `${fieldItemPrefix}.origin_old`;

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const localError = resolvePath(fc.errors, amountName, null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currResetValue = resolvePath(
    fc.control.defaultValuesRef.current,
    amountName,
    null
  );

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isTouched = resolvePath(fc.formState.dirtyFields, amountName, false);

  const [metricValue, setMetricValue] = useState<string | null>();
  const initialRender = useRef(true);

  const getCorrectDisplayText = (
    formattedUnit: string , 
    originalUnit: string
  ) => {
    return displayFormattedUnit ? formattedUnit : originalUnit;
  }

  let addornmentTxt =
    metricValue === 'relative' || metricValue === undefined 
    ? '%' 
    : getCorrectDisplayText(metricUnit, originalMetricUnit);

  const calculateNewValues = (newValueFromForm: string): number | '' => {
    const metric = fc.getValues(`${amountName}_changeMetric`) as string;
    // addornmentTxt must also be updated here if values are set after loading data
    addornmentTxt =
      metric === 'relative' || metric === undefined 
      ? '%' 
      : getCorrectDisplayText(metricUnit, originalMetricUnit);

    const newValue = parseFloat(newValueFromForm);

    if (Number.isNaN(newValue)) {
      fc.setValue(`${amountName}_newValue`, '');
      return '';
    }
    if (metric === 'set') {
      fc.setValue(`${amountName}_newValue`, newValue);
      return newValue;
    }
    if ((numOldValue || numOldValue === 0) && metric !== '') {
      let result: string | number = '';
      if (metric === 'absolute') {
        result = numOldValue + newValue;
        fc.setValue(`${amountName}_newValue`, result);
        return result;
      }
      // relative
      result = numOldValue * (1 + newValue / 100);
      fc.setValue(`${amountName}_newValue`, result);
      return result;
    }

    return '';
  };

  const checkMetricUnit = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value && !Number.isNaN(e.target.value) && numOldValue === null)
      fc.setValue(`${amountName}_changeMetric`, 'set');
  };

  const getMetricOptions = () => {
    // if baseline not defined, only set option is possible
    if (onlySetMetricIfNoBaseline)
      return baseline?.kgPerAnimal || baseline?.kgPerAnimal === 0
        ? lookupValues.interventionValueMetrics
        : { set: lookupValues.interventionValueMetrics.set };

    return lookupValues.interventionValueMetrics;
  };

  const getDefaultValue = () => {
    if (onlySetMetricIfNoBaseline)
      return baseline?.kgPerAnimal || baseline?.kgPerAnimal === 0
        ? 'relative'
        : 'set';

    return 'relative';
  };

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else {
      // eslint-disable-next-line no-void
      void fc.trigger(amountName);
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [metricValue, fc, fc.trigger, amountName]);

  let originalDisplayName = baseline?.origin;
  if (
    baseline?.origin &&
    availableOrigins &&
    availableOrigins?.findIndex((e) => e.value === baseline?.origin) > -1
  ) {
    originalDisplayName = availableOrigins?.filter(
      (e) => e.value === baseline?.origin
    )[0]?.text;
  }

  const shortLabelSiffix = shortLabel ? '_SHORT' : '';

  const labelDisabled = disabled
  ? { color: DSM_DISABLED_LABEL as string, width: 'max-content' }
  : { width: 'max-content' };

  return (
    <DsmGrid
      className={classes.dsmGridTwoColumnsIngredientInterventionComponent}
    >
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <Controller
          control={fc.control}
          name={amountName}
          defaultValue=""
          render={({ onChange, onBlur, value, ref }) => {
            // allows zeroes to be visible
            let localVal: string =
              typeof value === 'string' ? value : String(value);

            if (localVal === '' && currResetValue === 0 && !isTouched)
              localVal = '0';

            const localNew = calculateNewValues(localVal);
            const style =
              !Number.isNaN(localNew) &&
              (Number(localNew) < minAllowedValue ||
                (Number(localNew) > 100 &&
                  (metricUnit === 'percentage' || metricUnit === '%')) ||
                (maxAllowedValue && Number(localNew) > maxAllowedValue))
                ? { color: 'red' }
                : {};
            const metricUnitShort =
              metricUnit === 'number'
                ? ''
                : metricUnit.replace(/p[o,a,e]r animal/, getCorrectDisplayText('p.a.', '')); // will work for english, spanish, portuguese and french
            const minValue = metricValue === 'relative' ? -100 : 0;

            return (
              <>
                <TextField
                  className={classesLocal.root}
                  label={baseline?.feedType}
                  variant="outlined"
                  value={localVal || localVal === '0' ? localVal : ''}
                  fullWidth
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                    checkMetricUnit(e);
                  }}
                  onBlur={onBlur}
                  type={type}
                  error={!!localError}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  helperText={localError ? localError.message : ''}
                  InputProps={{
                    style: {
                      height: '28px',
                      lineHeight: '28px',
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: '12px' }}
                      >
                        {addornmentTxt}
                      </InputAdornment>
                    ),
                    readOnly: readonly,
                    inputProps: {
                      min: minValue,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    variant: 'standard',
                    disableAnimation: true,
                    style: {
                      width: '100%',
                    },
                  }}
                  disabled={disabled}
                />
                <div style={labelDisabled}>
                  {intl.formatMessage({
                    id: `INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE${shortLabelSiffix}`,
                  })}{' '}
                  <strong>
                    {typeof numOldValue === 'number'
                      ? `${numOldValue.toFixed(precision)} ${metricUnitShort}`
                      : ' - '}
                  </strong>
                  /
                  {intl.formatMessage({
                    id: `INTERVENTION.FORM.CHANGE.NEW_VALUE${shortLabelSiffix}`,
                  })}{' '}
                  <strong style={style}>
                    {' '}
                    {typeof localNew === 'number' && !Number.isNaN(localNew)
                      ? `${localNew.toFixed(precision)} ${metricUnitShort}`
                      : ' - '}{' '}
                  </strong>
                </div>
              </>
            );
          }}
        />
        <Controller
          control={fc.control}
          name={`${amountName}_newValue`}
          defaultValue=""
          // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unsafe-assignment
          render={({ value, name }) => (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <input type="hidden" name={name} value={value} />
          )}
        />
        {tooltip && (
          <Tooltip title={tooltip} placement="left">
            <DsmIcon
              name="general/help-circle"
              style={inlineTooltipClass}
              title=""
            />
          </Tooltip>
        )}
      </div>
      <div>
        <input
          type="hidden"
          value={baseline?.origin}
          ref={fc.register()}
          name={baselineOriginName}
        />
        <ReactHookDsmSelect
          name={originName}
          defaultValue={
            (fc.getValues(originName) as string) || baseline?.origin
          }
          // label="&nbsp;"
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.CHANGE.ORIGIN',
          })}
          // control={fc.control}
          // variant={variant}
          // fullWidth
          style={{ marginBottom: 0, minWidth: '100%', width: '100%' }}
          // tooltip={tooltip || ''}
          disabled={disabled}
          options={
            (availableOrigins || []).map(item =>(
              {
                value: item.value,
                text: item.text
              }
            ))
          }
          // changeHandler={e => {
          //   // console.log(e.target.value, baseline)

          //   // if onChange event provided, trigger it
          //   // if(changeHandler) {
          //   //   changeHandler(e);
          //   //   return;
          //   // }

          //   // if (e.target.value !== baseline && originalChanged) {
          //   //   originalChanged(true)
          //   // } else {
          //   //   originalChanged(false)
          //   // }
          // }}
        >
          {(availableOrigins || []).map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </ReactHookDsmSelect>
        <div style={labelDisabled}>
          {intl.formatMessage({
            id: `INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE${shortLabelSiffix}`,
          })}{' '}
          <strong>{originalDisplayName}</strong>
        </div>
      </div>
      <div>
        <ReactHookDsmSelect
          name={`${amountName}_changeMetric`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.CHANGE.CHANGE_TYPE',
          })}
          defaultValue={getDefaultValue()}
          style={{ marginBottom: 0, width: '100%' }}
          changeHandler={(e: ChangeEvent<HTMLSelectElement>) =>
            setMetricValue(e.target.value)
          }
          disabled={!!disabled}
          options={Object.entries(getMetricOptions()).map(([i]) => ({
            value: i,
            text: intl.formatMessage({
              id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`
              })
            })
            )
          }
        >
          {/* besiede i was label */}
          {Object.entries(getMetricOptions()).map(([i]) => (
            <MenuItem key={i} value={i}>
              {intl.formatMessage({
                id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`,
              })}
            </MenuItem>
          ))}
        </ReactHookDsmSelect>
      </div>
    </DsmGrid>
  );
};

// RowControlledTextFieldWithMetrics.defaultProps = defaultProps;

export default SustellInterventionSingleIngredient;
