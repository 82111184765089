import React from "react";
import { useSelector } from "react-redux";
import { Grid }  from '@material-ui/core';

import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { TextBox } from "../components/pageparts/TextBox";
import { HomeButton } from "../components/pageparts/HomeButton";
import { useIntl} from "../../../_metronic/i18n/customUseIntl";

const myFarms = toAbsoluteUrl("/media/sustell_15/bkgs/Farm_Bckgrnd_1.png");
const myFarmsIcon = toAbsoluteUrl("/media/sustell_15/section_icons/my_farms.svg");
const myFeeds = toAbsoluteUrl("/media/sustell_15/bkgs/Feed_Bckgrnd_1.png");
const myFeedsIcon = toAbsoluteUrl("/media/sustell_15/section_icons/my_feeds.svg");


export const SustellHomePage = (props) => {
  const intl = useIntl();
  const subheader = useSubheader();
  subheader.setTitle(intl.formatMessage({id:"SUBHEADER.HOME"}));
  const selectedCustomer = useSelector((state) => state.selectedCustomer?.value);

  const title = intl.formatMessage({id: "SUBHEADER.HOME" });
  const content = intl.formatMessage({id: "SUBHEADER.CONTENT" });
  return (
    <React.Fragment>
      <TextBox 
        title = {title}
        content = {content}
      /> 
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <HomeButton 
            bkgImg={myFarms}
            icon={myFarmsIcon}
            title={intl.formatMessage({id: "SUSTELL.MY_FARMS" })}
            intro={intl.formatMessage({id: "SUSTELL.MY_FARMS.INTRO" })}
            href={`/customers/${selectedCustomer.id}/myfarms`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeButton 
            bkgImg={myFeeds}
            icon={myFeedsIcon}
            title={intl.formatMessage({id: "SUSTELL.MY_FEEDS" })}
            intro={intl.formatMessage({id: "SUSTELL.MY_FEEDS.INTRO" })}
            href={`/customers/${selectedCustomer.id}/myfeeds`}
          />
        </Grid>
      </Grid>   
    </React.Fragment>
  )
};