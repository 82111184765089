import { Box,Link,Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { getSignedUrl } from '../../../../../../graphql/queries';
import { API } from "aws-amplify";
import axios from 'axios';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
// import { useIntl } from "react-intl";
import { useIntl } from "../../../../../i18n/customUseIntl";

const useStyles = makeStyles({
    root: {
        width: "100%"
    }
});

export default function NotificationModalCard({title, message, attachments}) {
  const intl = useIntl();
  const classes = useStyles();

  function createMarkup() {
    return {
       __html: message    };
    }; 


  const fetchForDownload = (ev, item)=>{
    ev.preventDefault();
    console.log("item called")
    downloadFile(ev.target, item);
  }

  const downloadFile = async (linkEl, item) => {
    console.log("called getSignedUrl", item);
    try {    
      const result = await API.graphql({
        query: getSignedUrl,
        variables:{
          key: item,
          method: 'GET'
        }
      });
      // now do a PUT request to the pre-signed URL
      console.log(result.data.getSignedUrl)
      if(result.data?.getSignedUrl){
        axios({
          url: result.data?.getSignedUrl?.signedUrl,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', item.substring(21));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    } catch (err) {
      console.log('error: ', err);
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        console.log(error.message);
        alert(intl.formatMessage({id: "NOTIFICATIONS.DOWNLOAD_ERROR"})+item.name);
      }
    }
  }

  return (
    <Box className={classes.root}>
        <Typography  variant="h6">
            {title}
        </Typography>
        <Box dangerouslySetInnerHTML={createMarkup()}/>
        { attachments && attachments?.length>0 &&
          <>
          <Typography  variant="body1" >
              <CloudDownloadOutlinedIcon fontSize="large" style={{paddingBottom: "5px"}}/> <strong> { intl.formatMessage({id: "GENERAL.ATTACHEMENTS"})} </strong>
          </Typography>
          <ul style={{padding: "0"}}>
          {
            attachments?.map(item => {
              return (       
                <li key={item} style={{margin:'0.5em', listStyle: 'none'}} >
                  <Link href="#" onClick={ (e) => { fetchForDownload(e, item) } } style={{ color:"black" }}>
                    {item.substring(21)}
                  </Link>
                </li>
              );
            })
          }
          </ul>
          </>
        }
    
    </Box>
  );
}
