import { DsmGrid } from '@dsm-dcs/design-system-react';
import React, { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { CSSClassesList } from '../../../../helpers/helperTypes';

interface BeefFeedMothersMilkComponentProps {
  fieldItemPrefix: string;
  formType: FormType;
}

const BeefFeedMothersMilkComponent: FC<BeefFeedMothersMilkComponentProps> = ({
  fieldItemPrefix,
  formType,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const mothersMilkAmountWatch = useWatch({
    name: `${fieldItemPrefix}.amount`,
  });

  useEffect(() => {
    if (mothersMilkAmountWatch) {
      formContext.setValue(`${fieldItemPrefix}.milkFromMother`, 'true');
    } else {
      formContext.setValue(`${fieldItemPrefix}.milkFromMother`, 'false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mothersMilkAmountWatch]);

  return (
    <div
      style={{
        border: '1px solid var(--dsm-color-neutral-lighter)',
        padding: '16px 16px 0px 16px',
        marginBottom: '16px',
      }}
    >
      <div>
        <input
          ref={formContext.register()}
          type="hidden"
          name={`${fieldItemPrefix}.milkFromMother`}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.milkFromMother`) || 'false'
          }
        />
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <ReactHookDsmInput
              type="number"
              name={`${fieldItemPrefix}.amount`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.AMOUNT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.AMOUNT.TOOLTIP',
              })}
              adornment={barnOutputMassUnit}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(`${fieldItemPrefix}.amount`) as string
              }
            />
            <ReactHookDsmInput
              type="number"
              name={`${fieldItemPrefix}.dryMatterContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.dryMatterContent`
                ) as string
              }
              required={!!mothersMilkAmountWatch}
            />
          </DsmGrid>
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <ReactHookDsmInput
              type="number"
              name={`${fieldItemPrefix}.crudeProteinContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.crudeProteinContent`
                ) as string
              }
              required={!!mothersMilkAmountWatch}
            />
            <ReactHookDsmInput
              type="number"
              name={`${fieldItemPrefix}.digestibility`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DIGESTIBILITY',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DIGESTIBILITY.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DIGESTIBILITY.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.MOTHERS_MILK.DIGESTIBILITY.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(
                  `${fieldItemPrefix}.digestibility`
                ) as string
              }
              required={!!mothersMilkAmountWatch}
            />
          </DsmGrid>
        </DsmGrid>
      </div>
    </div>
  );
};

export default BeefFeedMothersMilkComponent;
