import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
// import { injectIntl } from "react-intl";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  IconButton,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  ThemeProvider,
  TableFooter,
  TablePagination,
  OutlinedInput,
  InputLabel,
  FormControl
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import dsmTheme from "../MUITheme";
import Can from '../Auth/Can';
import { setSelectedCustomer } from "../../state/selected-customer/selectedCustomerSlice";
import { UserProfilePrefs } from "../Helpers/UserProfilePrefs";
import { injectIntl } from "../../../_metronic/i18n/customInjectIntl";
import { formStart, ctaClick } from '../../sustell_15/analytics/adobeDataLayer';

class AdminCustomerData extends React.Component {

  constructor(props) {
    super(props);

    this.state = { 
      currPage: 0,
      rowsPerPage: 20,
      searchText: '',
    };
    this.onNewCustomerBtnClick = this.onNewCustomerBtnClick.bind(this);
    this.onTablePageChange = this.onTablePageChange.bind(this);
    this.onTableRowsPerPageChange = this.onTableRowsPerPageChange.bind(this);
  }

  componentDidMount() {
    const toPageNo = this.findPage(this.props.selectedCustomer?.id);
    if (this.state.currPage !== toPageNo) {
      this.onTablePageChange(null, toPageNo);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerData !== this.props.customerData) {
      const toPageNo = this.findPage(this.props.selectedCustomer?.id);
      if (this.state.currPage !== toPageNo) {
        this.onTablePageChange(null, toPageNo);
      }
    }
  }

  feedsVisible(customer) {
    return !(!customer?.feedsVisible && UserProfilePrefs.getInstance())
  }

  findPage(customerId) {
    const customers = this.props.customerData?.sort((a,b) => a.name?.toLowerCase()<b.name?.toLowerCase() ? -1 : (a.name?.toLowerCase()>b.name?.toLowerCase() ? 1 : 0))
    if(customers) {
      const index = customers.findIndex((element) => element.id === customerId);
      if(index > 0)
        return Math.floor(index / this.state.rowsPerPage)
    }
    return 0;
  }

  refreshCustomerDetails(item, e) {
    ctaClick(window.location.href, 'link', 'Select Customer', 'Admin', 'Customers');
    e.preventDefault();
    if (item.id !== this.props.selectedCustomer?.id && !this.props.editorsActive) {
      // Change state of selected customer
      this.props.dispatchSelectedCustomer(item);
    }
  }

  onTablePageChange(event, page){
    this.setState({
      currPage: page
    });
  }

  onTableRowsPerPageChange(event){
    const newVal = event.target.value;
    this.setState({
      currPage: 0,
      rowsPerPage: newVal
    });
  }

  onNewCustomerBtnClick(e) {
    ctaClick(window.location.href, 'button', 'Add Customer', 'Admin', 'Customers');
    formStart('Add Customer', 'new');
    this.props.onShowCustomerForm(e);
  }

  editCustomer(event, customer) {
    event.stopPropagation();
    ctaClick(window.location.href, 'icon', 'Edit Customer', 'Admin', 'Customers');
    formStart('Edit Customer', 'edit');
    this.props.onShowCustomerForm(event, {formMode: 'edit', clickedOnCustomer: customer});
  }

  // Apply search text to customers list
  getFilteredCustomers() {
    const {searchText} = this.state;
    return (this.props?.customerData || [])
      .filter(customer => 
          !searchText || customer.name.toLowerCase().includes(searchText.toLowerCase())
      );
  }
  
  setFilter(searchText) {
    this.setState({ searchText })
    if (this.state.currPage)
      this.onTablePageChange(null, 0);
  }

  render() {
    const listItems = this.getFilteredCustomers().sort((a,b) => a.name?.toLowerCase()<b.name?.toLowerCase() ? -1 : (a.name?.toLowerCase()>b.name?.toLowerCase() ? 1 : 0)).map((item, index) =>
      <TableRow
        key={item.id}
        selected={item.id === this.props.selectedCustomer?.id}
        onClick={(e) => this.refreshCustomerDetails(item, e)}
        hover={true}
        style={{ cursor: 'pointer' }}
      >
        <TableCell>{item.name}</TableCell>
        <TableCell style={{ padding: 0, textAlign: 'right', width: '10%' }}>
          {Can("update", "Customer") &&
            <IconButton
              aria-label="Edit customer"
              onClick={(e) => this.editCustomer(e, item)}
            >
              <Edit />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    );

    return (
        <ThemeProvider theme={dsmTheme}>
          <Card>
            <CardHeader
              title={ this.props.intl.formatMessage({ id: "CUSTOMERS.CARD.TITLE" }) }
              titleTypographyProps={{ color: "secondary", variant: "h4" }}
              action={Can("create", "Customer") ? <Button variant="outlined" onClick={(e) => this.onNewCustomerBtnClick(e)}>{ this.props.intl.formatMessage({ id: "CUSTOMERS.CARD.BUTTON.NEW_CUSTOMER" }) }</Button> : null}
            />
            <CardContent>
            <FormControl variant="outlined" fullWidth >
              <InputLabel htmlFor="searchCustomer">
                {  this.props.intl.formatMessage({ id: "GENERAL.SEARCH"})}
              </InputLabel>
              <OutlinedInput
                label={ this.props.intl.formatMessage({ id: "GENERAL.SEARCH"})}
                name="searchCustomer"
                id="searchCustomer"
                onChange={(e) => { this.setFilter(e.target.value) }}
                value={this.state.searchText}
              />
            </FormControl>
              <Table
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{ this.props.intl.formatMessage({ id: "CUSTOMERS.CARD.TABLE.NAME" }) }</TableCell>
                    {Can("update", "Customer") &&
                    <TableCell></TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                { 
                  listItems?.filter( (item, index) => (index >= this.state.currPage*this.state.rowsPerPage && index<this.state.currPage*this.state.rowsPerPage+this.state.rowsPerPage)) 
                }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                      count = { listItems?.length || 0 }
                      rowsPerPage = { this.state.rowsPerPage }
                      labelRowsPerPage = { this.props.intl.formatMessage({id: "GENERAL.TABLE_PAGINATION.ROWS_PER_PAGE"})}
                      page = { this.state.currPage }
                      onChangePage = { this.onTablePageChange }
                      rowsPerPageOptions = { [5,8,10,15,20] }
                      onChangeRowsPerPage = { this.onTableRowsPerPageChange }
                      labelDisplayedRows = { ({ from, to, count }) => this.props.intl.formatMessage({id: "GENERAL.TABLE_PAGINATION.PAGE_OF_PAGES"},{from: from, to: to, count:count }) }
                    /> 
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </Card>
        </ThemeProvider>
    );
  }
};

// * Let's use prefixes when using actions (dispatchSomething) and for states (stateSomething)

// const mapStateToProps = (state) => {return {stateSelectedCustomer : state.selectedCustomer?.value}};
const mapDispatchToProps = {dispatchSelectedCustomer :setSelectedCustomer};
export default connect(null, mapDispatchToProps)(withRouter(injectIntl(AdminCustomerData)));
