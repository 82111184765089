import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { MasterDataOption, Maybe } from '../../../../../../graphql/types';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import RowSelectWithMetrics from '../../../../../modules/Farms/Intervention/RowSelectWithMetrics';
import {
  avgLiveWeightMaxs,
  avgLiveWeightMins,
} from '../../../../../modules/Farms/validationSchemaRestrictions';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  BroilerIntervention,
  BroilerManure,
} from '../../../../models/Intervention';
import {
  defaultUnits,
  explicitConvertValue,
  unitLong,
} from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { FormType } from '../../common';
import { BrolierInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

interface BInterventionManureFormDialogProps
  extends BrolierInterventionFormDialogProps {
  manureManagementSystems: Maybe<Array<MasterDataOption>>;
}

const BInterventionManureFormDialog = ({
  baseline,
  formVisible,
  stageIndex = 0,
  formType = FormType.Add,
  handleCancel,
  manureManagementSystems,
}: BInterventionManureFormDialogProps) => {
  const intl = useIntl();

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const currResetValue = useRef<BroilerManure>();

  let minAvgLiveWeightMortality = avgLiveWeightMins.Broiler;
  let maxAvgLiveWeightMortality = avgLiveWeightMaxs.Broiler;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;

  // TODO fix userUOM & defaultUnits stuff after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass) {
    if (minAvgLiveWeightMortality)
      minAvgLiveWeightMortality = Number(
        explicitConvertValue(
          minAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          userUOM.unitBarnOutputMass as string
        )?.toFixed(precision)
      );
    if (maxAvgLiveWeightMortality)
      maxAvgLiveWeightMortality = Number(
        explicitConvertValue(
          maxAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          userUOM.unitBarnOutputMass as string
        )?.toFixed(precision)
      );
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    userUOM?.unitBarnOutputMass
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        unitLong(userUOM.unitBarnOutputMass)
      : 'kg';
  const formTitle = intl.formatMessage(
    {
      id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE',
    },
    { br: ' ' }
  );

  const fieldItemPrefix = `stages.${stageIndex}.stageData.manure`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...(fc.getValues(fieldItemPrefix) as BroilerManure),
      }; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as BroilerIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.manure) {
        stageData.manure = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const manure = baseline?.stages?.[stageIndex]?.stageData?.manure;

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="manureIcon"
      datasetType="intervention"
      formTitle={formTitle}
    >
      <Grid container direction="row" spacing={2} justifyContent="space-between">
        <Grid item container direction="column" xs={6} spacing={2}>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageAnimalsPresent`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.AVERAGE_ANIMALS_PRESENT',
              })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.BARN.AVERAGE_ANIMALS_PRESENT.TOOLTIP',
              })}
              metricUnit="number"
              margin="none"
              baseline={manure?.averageAnimalsPresent}
              disabled={formType === 'view'}
            />
          </Grid>
          <Grid item>
            <RowSelectWithMetrics
              name={`${fieldItemPrefix}.manureManagementSystem`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM',
              })}
              variant="outlined"
              disabled={formType === 'view'}
              items={manureManagementSystems}
              baseline={manure?.manureManagementSystem}
              margin="none"
              tooltip={
                <div>
                  {intl.formatMessage(
                    {
                      id: 'BASELINE.FORM.BARN.BROILERS.MANURE_MANAGEMENT_SYSTEM.TOOLTIP',
                    },
                    { br: <br /> }
                  )}
                </div>
              }
            />
          </Grid>
          <Grid item style={{ marginTop: '1.75rem' }}>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.solidManureStorage`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SOLID_MANURE',
              })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.BARN.SOLID_MANURE.TOOLTIP',
              })}
              metricUnit="percentage"
              baseline={manure?.solidManureStorage}
              disabled={formType === 'view'}
              minAllowedValue={0}
              maxAllowedValue={100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.slurryManureStorage`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SLURRY_MANURE',
              })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.BARN.SLURRY_MANURE.TOOLTIP',
              })}
              metricUnit="percentage"
              margin="none"
              baseline={manure?.slurryManureStorage}
              disabled={formType === 'view'}
              minAllowedValue={0}
              maxAllowedValue={100}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={2}>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.ashContent`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.ASH' })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.BARN.ASH.TOOLTIP',
              })}
              metricUnit="percentage"
              margin="none"
              baseline={manure?.ashContent}
              disabled={formType === 'view'}
              minAllowedValue={0}
              maxAllowedValue={100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.strawForBedding`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.STRAW_FOR_BEDDING',
              })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.BARN.STRAW_FOR_BEDDING.TOOLTIP',
              })}
              metricUnit={intl.formatMessage(
                { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                { unit: barnOutputMassUnit }
              )}
              precision={precision}
              margin="none"
              baseline={manure?.strawForBedding}
              disabled={formType === 'view'}
              minAllowedValue={0}
              maxAllowedValue={100}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.sawDust`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.SAW_DUST' })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.BARN.SAW_DUST.TOOLTIP',
              })}
              metricUnit={intl.formatMessage(
                { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                { unit: barnOutputMassUnit }
              )}
              precision={precision}
              margin="none"
              baseline={manure?.sawDust}
              disabled={formType === 'view'}
              minAllowedValue={0}
              maxAllowedValue={100}
            />
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default BInterventionManureFormDialog;
