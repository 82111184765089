import { useState, useEffect, useRef, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import lookupValues from '../../../../helpers/lookupValues';
import { translateKeyMapping } from '../../../../utils/string-utils';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import SustellTotalIntakeTextField from '../../../../../modules/Farms/Intervention/SustellTotalIntakeTextField';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { InterventionDialogProps } from '../CommonDataParts/InterventionTypes';
import {
  BaselineFeed,
  OptionalNutritionalContent,
  RequiredNutritionalContent,
  SalmonBaseline,
} from '../../../../models/Baseline';
import { MasterDataOption, Maybe } from '../../../../../../graphql/types';
import {
  CommonInterventionFeed,
  InterventionFeed,
  SalmonIntervention,
} from '../../../../models/Intervention';
import { FormType } from '../../common';

interface SInterventionFeedFormDialogProps extends InterventionDialogProps {
  baseline: SalmonBaseline;
  // eslint-disable-next-line react/require-default-props
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  nutrientTypes?: Maybe<Array<MasterDataOption>>;
  intervention: SalmonIntervention | null;
}

const SInterventionFeedsFormDialog = ({
  formVisible,
  stageIndex = 0,
  handleCancel,
  formType = FormType.Add,
  baseline,
  nutrientTypes,
  compoundFeeds,
  intervention,
}: SInterventionFeedFormDialogProps) => {

  const intl = useIntl();
  const currResetValue = useRef<string>();
  const feedValuesRef = useRef<CommonInterventionFeed>(
    {} as CommonInterventionFeed
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;

  const existingFeedsSet = useRef<boolean>(false);

  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  // const { getValues, setValue, reset, register, trigger } = useFormContext();
  const fc = useFormContext();

  const [compoundFeedsAdditions, setCompoundFeedsAdditions] = useState<
    BaselineFeed[] | null
  >([]);

  useEffect(() => {
    if (formVisible) {
      // to faster deep copy all potential arrays and subobjects
      const serializedData = JSON.stringify(fc.getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as SalmonIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData;
      if (stageData?.feed) {
        const val = JSON.parse(
          currResetValue.current
        ) as CommonInterventionFeed;
        stageData.feed = val;
        fc.reset(resetObject, {
          errors: true,
        });
        // reset compound feeds and single ingredients data
        // to previously valid (confirmed)
        setCompoundFeedsAdditions(val?.compoundFeedsAdditions || null);
      }
    }
    if (handleCancel) handleCancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : 'kg';

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  const updateTotalFeedIntake = useCallback(
    (name?: string, value?: number | string | null) => {
      const feedValues = feedValuesRef.current;
      let totalIntake = 0;

      const isAdditionalFeed = !name || name.includes('compoundFeedsAdditions');

      const currFormValues = fc.getValues() as SalmonIntervention;

      // take all addditional fields, and calculate sum
      const compoundFeedsAdd =
        currFormValues.stages?.[stageIndex]?.stageData?.feed
          ?.compoundFeedsAdditions;
      if (compoundFeedsAdd) {
        totalIntake += compoundFeedsAdd.reduce(
          (acc: number, item: BaselineFeed) =>
            acc +
            (item.feedType && !Number.isNaN(item.kgPerAnimal)
              ? Number(item.kgPerAnimal)
              : 0),
          0
        );
      }

      // do it for all baseline fields
      if (
        !isAdditionalFeed &&
        feedValues &&
        feedValues[name as keyof CommonInterventionFeed] !== value
      ) {
        if (value != null && typeof value !== 'number') {
          // eslint-disable-next-line no-param-reassign
          value = parseFloat(value);
        }

        if (Number.isNaN(value)) {
          // eslint-disable-next-line no-param-reassign
          value = 0;
        }

        const updvals = {
          ...feedValues,
          [name]: value,
        };
        feedValuesRef.current = updvals;
      }

      totalIntake += Object.values(feedValuesRef.current).reduce(
        (a: number, b: number) => a + b,
        0
      );

      fc.setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntake.toFixed(2));
      if (formVisible)
        fc.trigger(`${fieldItemPrefix}.totalFeedIntake`)
          .then(() => {})
          .catch(() => {});
    },
    [fc, fieldItemPrefix, stageIndex, formVisible]
  );

  const recalculateTotalFeedIntake = useCallback(() => {
    // calculate intervention feed value
    const calculateFeedValue = (
      baselineFeed: BaselineFeed,
      interventionFeeds?: Maybe<InterventionFeed[]>
    ) => {
      const interventionFeed = interventionFeeds?.find(
        (item) => item.feedType === baselineFeed.feedType
      );

      if (interventionFeed) {
        if (interventionFeed.kgPerAnimal_changeMetric === 'set')
          return Number(interventionFeed.kgPerAnimal) || 0;

        if (interventionFeed.kgPerAnimal_changeMetric === 'absolute')
          return (
            (Number(interventionFeed.kgPerAnimal) || 0) +
            (Number(baselineFeed.kgPerAnimal) || 0)
          );

        return (
          (Number(baselineFeed.kgPerAnimal) || 0) *
          (1 + (Number(interventionFeed.kgPerAnimal) || 0) / 100)
        );
      }
      // intervention for feed is not set
      return Number(baselineFeed.kgPerAnimal) || 0;
    };

    let totalIntake = 0;

    const currFormValues = fc.getValues() as SalmonIntervention;

    // take all addditional fields, and calculate sum
    const compoundFeedsAdd =
      currFormValues.stages?.[stageIndex]?.stageData?.feed
        ?.compoundFeedsAdditions;

    if (compoundFeedsAdd) {
      totalIntake += compoundFeedsAdd.reduce(
        (acc: number, item: BaselineFeed) =>
          acc +
          (item.feedType && !Number.isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0),
        0
      );
    }

    const baselineCompoundFeeds =
      baseline?.stages?.[stageIndex]?.stageData?.feed?.compoundFeeds;
    if (baselineCompoundFeeds) {
      const compoundFeedsForm =
        currFormValues.stages?.[stageIndex]?.stageData?.feed?.compoundFeeds;
      totalIntake += baselineCompoundFeeds.reduce(
        (acc: number, item: BaselineFeed) =>
          acc + calculateFeedValue(item, compoundFeedsForm),
        0
      );
    }

    fc.setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntake.toFixed(2));
    if (formVisible)
      fc.trigger(`${fieldItemPrefix}.totalFeedIntake`)
        .then(() => {})
        .catch(() => {});
  }, [fc, stageIndex, fieldItemPrefix, formVisible, baseline]);

  useEffect(() => {
    // we need to refresh when previously saved is returned by API call, but only once
    const feed = intervention?.stages?.[stageIndex]?.stageData?.feed;

    if (existingFeedsSet.current === false) {
      if (feed?.compoundFeedsAdditions?.some((item) => item.feedType !== '')) {
        setCompoundFeedsAdditions(feed.compoundFeedsAdditions);
        existingFeedsSet.current = true;
      }

      recalculateTotalFeedIntake();
    }
  }, [intervention, stageIndex, baseline, recalculateTotalFeedIntake]);

  const feed = baseline?.stages?.[stageIndex]?.stageData?.feed;

  const baselineFeedTotal = () =>
    feed?.compoundFeeds?.reduce(
      (acc: number, item: BaselineFeed) =>
        acc +
        (item.feedType && !Number.isNaN(item.kgPerAnimal)
          ? Number(item.kgPerAnimal)
          : 0),
      0
    ) || 0;

  const availableNutrients = getTranslatedEnumOrDefaultLookupValues(
    nutrientTypes || [],
    lookupValues.nutrient_type,
    intl,
    'MASTERDATA.ENUM.NUTRIENT_TYPE',
    false, // keep the order as it comes
    true // copy additional fields from lookup if existing
  );

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleCancel}
      iconCode="feedIcon"
      formTitle={formTitle}
      variant="wide"
      datasetType="intervention"
    >
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ marginTop: 0 }}
          >
            {feed?.compoundFeeds?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`${fieldItemPrefix}_cf_${i}`}>
                <input
                  type="hidden"
                  value={item.feedType}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].feedType`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
                  label={
                    compoundFeeds?.find((el) => el.value === item.feedType)
                      ?.display_name || ''
                  }
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
                  })}
                  metricUnit={intl.formatMessage(
                    { id: 'ADDORNMENT.UNITS.WITH_PER_YEAR_SUFIX' },
                    { unit: barnOutputMassUnit }
                  )}
                  precision={precision}
                  // defaultValue={item.kgPerAnimal}
                  baseline={item.kgPerAnimal}
                  updateTotalFeedIntake={updateTotalFeedIntake}
                  disabled={formType === 'view'}
                  displayFormattedUnit={false}
                  originalMetricUnit={barnOutputMassUnit}
                />
              </Grid>
            ))}

            <SustellRationFeeds
              key={`${fieldItemPrefix}.compoundFeedsAdditions`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS',
              })}
              availableFeedItems={compoundFeeds}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="compoundFeedsAdditions"
              updateTotalFeedIntake={updateTotalFeedIntake}
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
              })}
              formState={formType}
              compoundFeed
              feedData={compoundFeedsAdditions}
              setFeedData={setCompoundFeedsAdditions}
              animalType="SALMON"
            />

            <Grid item>
              <SustellTotalIntakeTextField
                key={`${fieldItemPrefix}.totalFeedIntake`}
                name={`${fieldItemPrefix}.totalFeedIntake`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE',
                })}
                type="number"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE.TOOLTIP',
                })}
                addornment={intl.formatMessage(
                  { id: 'ADDORNMENT.UNITS.WITH_PER_YEAR_SUFIX' },
                  { unit: barnOutputMassUnit }
                )}
                metricUnit={intl.formatMessage(
                  { id: 'ADDORNMENT.UNITS.WITH_PER_YEAR_SUFIX' },
                  { unit: barnOutputMassUnit }
                )}
                baseline={baselineFeedTotal()}
                disabled
                margine="none"
              />
            </Grid>

            {availableNutrients
              ?.filter((item) => item.mandatory === true)
              .map((el) => (
                <Grid item key={`${fieldItemPrefix}_mand_nut_${el.value}`}>
                  <RowTextFieldWithMetrics
                    key={`${fieldItemPrefix}.mandatoryNutritional_${el.value}`}
                    name={`${fieldItemPrefix}.requiredNutritionalContent.${el.value}`}
                    label={intl.formatMessage({
                      id: `BASELINE.FORM.SALMON.${translateKeyMapping(
                        el.display_name
                      )}`,
                      defaultMessage: el.display_name,
                    })}
                    type="number"
                    tooltip={intl.formatMessage({
                      id: `INTERVENTION.FORM.SALMON.${translateKeyMapping(
                        el.display_name
                      )}.TOOLTIP`,
                      defaultMessage: el.display_name,
                    })}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    metricUnit={el.unit!}
                    baseline={
                      feed?.requiredNutritionalContent?.[
                        el.value as keyof RequiredNutritionalContent
                      ]
                    }
                    disabled={formType === 'view'}
                    maxAllowedValue={100}
                    minAllowedValue={0}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ marginTop: 0 }}
          >
            {availableNutrients
              ?.filter(
                (item) =>
                  item.mandatory === undefined || item.mandatory === false
              )
              .map((el) => (
                <Grid item key={`${fieldItemPrefix}_opt_nut_${el.value}`}>
                  <RowTextFieldWithMetrics
                    key={`${fieldItemPrefix}.optionalNutritionalContent_${el.value}`}
                    name={`${fieldItemPrefix}.optionalNutritionalContent.${el.value}`}
                    label={intl.formatMessage({
                      id: `BASELINE.FORM.SALMON.${translateKeyMapping(
                        el.display_name
                      )}`,
                      defaultMessage: el.display_name,
                    })}
                    type="number"
                    tooltip={intl.formatMessage({
                      id: `INTERVENTION.FORM.SALMON.${translateKeyMapping(
                        el.display_name
                      )}.TOOLTIP`,
                      defaultMessage: el.display_name,
                    })}
                    metricUnit={
                      el.value !== 'gross_energy'
                        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          el.unit!
                        : `${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                            userUOM.unitNutritionalGrossEnergy
                              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                userUOM.unitNutritionalGrossEnergy
                              : 'MJ'
                          }/${
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            userUOM?.unitOutputMass
                              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                unitLong(userUOM.unitOutputMass as string)
                              : 'kg'
                          }`
                    }
                    baseline={
                      feed?.optionalNutritionalContent?.[
                        el.value as keyof OptionalNutritionalContent
                      ]
                    }
                    disabled={formType === 'view'}
                    maxAllowedValue={100}
                    minAllowedValue={0}
                    // if dataset is provided, then only the set metric shall be available if the value is not set in the baseline
                    onlySetMetricIfNoBaseline={
                      feed?.optionalNutritionalContent &&
                      Object.keys(feed?.optionalNutritionalContent).length >
                        0 &&
                      true
                    }
                  />
                </Grid>
              ))}
            <Grid item>
              <RowTextFieldWithMetrics
                key={`${fieldItemPrefix}.efcr`}
                name={`${fieldItemPrefix}.efcr`}
                label={intl.formatMessage({ id: 'BASELINE.FORM.SALMON.ECFR' })}
                type="number"
                tooltip={intl.formatMessage(
                  { id: 'INTERVENTION.FORM.SALMON.EFCR.TOOLTIP' },
                  { min: 0.9, max: 2 }
                )}
                metricUnit=""
                baseline={feed?.efcr}
                disabled={formType === 'view'}
                maxAllowedValue={2}
                minAllowedValue={0.9}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default SInterventionFeedsFormDialog;
