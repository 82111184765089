import React, {FC, useState} from "react";
import { useFormContext} from "react-hook-form";

import { Grid } from '@material-ui/core';
import StageButtonBox from "../../StageButtonBox";
import BInputFormDialog from "./BInputFormDialog";
import BOutputFormDialog from "./BOutputFormDialog";
import BManureFormDialog from "./BManureFormDialog";
import { FeedsFormDialog } from "../../CommonDataParts/FeedsFormDialog";
import BEmissionsFormDialog from "./BEmissionsFormDialog";
import BFeedsVariablePart from "./BFeedsVariablePart";
import { BaselineStageProps } from "../../common";

const BroilerStages: FC<BaselineStageProps> = ({
  stageIndex,
  baseline,
  getCompleteness,
  manureManagementSystems,
  compoundFeeds,
  singleIngredients,
  item,
  formType
}) => {
  const [activeDialog, setActiveDialog] = useState<string | null>('');
  const showDialog = (dialog: React.SetStateAction<string | null>) => {
    setActiveDialog(dialog)
  }
  const formContext = useFormContext();
  const stagePrefix =`stages.${stageIndex}`

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        defaultValue={item.animalType}
      />
      <BInputFormDialog formVisible={ activeDialog==='input' } 
        formType={formType}
        handleCancel={ () => setActiveDialog(null)} 
        baseline={baseline} itemIndex={stageIndex} 
      />
      <FeedsFormDialog formVisible={ activeDialog==='feed' } 
        handleCancel={ () => setActiveDialog(null)} 
        formType={formType}
        baseline={baseline} 
        itemIndex={stageIndex}
        compoundFeeds={compoundFeeds}  
        singleIngredients={singleIngredients}
        >
        <BFeedsVariablePart 
          itemIndex={stageIndex}
          formType={formType} 
        />
      </FeedsFormDialog>
      <BManureFormDialog formVisible={ activeDialog==='manure' } 
        formType={formType}
        handleCancel={ () => setActiveDialog(null)} 
        baseline={baseline} itemIndex={stageIndex}
        manureManagementSystems={manureManagementSystems}  
      />
      <BEmissionsFormDialog formVisible={ activeDialog==='emissions' } 
        formType={formType}
        handleCancel={ () => setActiveDialog(null)} 
        baseline={baseline} 
        itemIndex={stageIndex}/>
      <BOutputFormDialog formVisible={ activeDialog==='output' } 
        formType={formType}
        handleCancel={() => setActiveDialog(null)} 
        baseline={baseline} itemIndex={stageIndex}/>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: '1rem'}}   >
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE" iconCode='BROILER_INPUT' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.input`) : -1 } 
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.input}
                          handleOpen={()=>showDialog('input')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE" iconCode='feedIcon' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.feed`) : 0 }  
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.feed}
                          handleOpen={()=>showDialog('feed')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"  iconCode='manureIcon' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.manure`) : 0 } 
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.manure}
                          handleOpen={()=>showDialog('manure')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE" iconCode='BROILER' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.output`) : 0 } 
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.output}
                          handleOpen={()=>showDialog('output')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE" iconCode='emissionIcon' 
                          optional
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.emissions}
                          handleOpen={()=>showDialog('emissions')}/>
      </Grid>
    </>
  );
};

export default BroilerStages;