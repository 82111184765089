import { isProcessingStage } from '../../../../sustell_15/helpers/facilities';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { floatValueInDefaultUnit } from './floatHelperFunctions';
import {
  mapCommonProductionDataOutSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustellV2';

const mapMarineFishProductionDataOutSustell = (formData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  let payloadObj = mapCommonProductionDataOutSustell(formData, isDraft);
  payloadObj = mapResourceUseSustell(formData, payloadObj);

  if (formData.stages) {
    formData.stages.forEach((formDataForOneStage, index) => {
      if (
        payloadObj.stages[index] !== undefined &&
        !isProcessingStage(formDataForOneStage.type)
      ) {
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const { stageData } = stageGeneralData;
        stageData.animal_group.animal_type = formData.animalType;

        // process input block
        const inputBlockData = formStageData?.input;
        if (inputBlockData) {
          stageData.stocking = {
            stockedAnimalsNumber: parseFloat(
              inputBlockData.numberOfStockedAnimals
            ),
            stockedAnimalsWeight: floatValueInDefaultUnit(
              inputBlockData.weightOfStockedAnimals,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            averageWeight: floatValueInDefaultUnit(
              inputBlockData.averageLiveWeightStockedAnimals,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };
        }

        // process output block
        const outputBlockData = formStageData?.output;

        if (outputBlockData) {
          stageData.production = {
            amount: floatValueInDefaultUnit(
              outputBlockData.production,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            averageWeight: floatValueInDefaultUnit(
              outputBlockData.averageWeight,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };

          stageData.lostAnimals = {
            mortalities: floatValueInDefaultUnit(
              outputBlockData.mortalities,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            escapees: floatValueInDefaultUnit(
              outputBlockData.escapees,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };
        }

        // set housing block of data
        stageData.housing_system = formDataForOneStage.productionSystem;

        stageData.transport = [];
        // stageData.emissions = [];
        stageData.materials = [];
        stageData.antibiotics = [];
        stageData.liceTreatment = [];

        // map activities - operations
        const operationsBlockData = formStageData?.operations;
        if (operationsBlockData) {
          if (
            operationsBlockData.antifouling ||
            operationsBlockData.antifouling === 0
          ) {
            stageData.antifouling = floatValueInDefaultUnit(
              operationsBlockData.antifouling,
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass
            );
          }

          // materials
          if (
            operationsBlockData.materialsFarmConstruction &&
            Array.isArray(operationsBlockData.materialsFarmConstruction)
          ) {
            operationsBlockData.materialsFarmConstruction.forEach((item) => {
              if (item.type && 'amount' in item) {
                const input = {
                  type: item.type,
                  amount: floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitBarnOutputMass,
                    defaultMetric.unitBarnOutputMass
                  ),
                };
                stageData.materials.push(input);
              }
            });
          }

          if (
            operationsBlockData.liceTreatment &&
            Array.isArray(operationsBlockData.liceTreatment)
          ) {
            operationsBlockData.liceTreatment.forEach((item) => {
              if (item.type && 'amount' in item) {
                const input = {
                  type: item.type,
                  amount: floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitBarnOutputMass,
                    defaultMetric.unitBarnOutputMass
                  ),
                };
                stageData.liceTreatment.push(input);
              }
            });
          }

          if (
            operationsBlockData.transport &&
            Array.isArray(operationsBlockData.transport)
          ) {
            operationsBlockData.transport.forEach((item) => {
              if (item.type && 'amount' in item) {
                let value;
                if (item.type === 'electricity')
                  value = floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitElectricityUse,
                    defaultMetric.unitElectricityUse
                  );
                else
                  value = floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitDieselUse,
                    defaultMetric.unitDieselUse
                  );

                const input = {
                  boatName: item?.boatName,
                  type: item.type,
                  amount: value,
                };
                stageData.transport.push(input);
              }
            });
          }
        }
        // feed items
        if (!stageData.feedingProgram) stageData.feedingProgram = {};

        stageData.feedingProgram.nutrientComposition = {};

        // additional feed processing for Salmon
        const feedBlockData = formStageData?.feed;
        if (feedBlockData) {
          if (Array.isArray(feedBlockData.antibioticsUse)) {
            feedBlockData.antibioticsUse.forEach((item) => {
              if (item.type && 'amount' in item) {
                const input = {
                  type: item.type,
                  amount: floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitBarnOutputMass,
                    defaultMetric.unitBarnOutputMass
                  ),
                };
                stageData.antibiotics.push(input);
              }
            });
          }

          if (feedBlockData.nutrientComposition) {
            const { nutrientComposition } = feedBlockData;

            if (
              nutrientComposition.crudeProtein ||
              nutrientComposition.crudeProtein === 0
            )
              stageData.feedingProgram.nutrientComposition.crudeProtein =
                nutrientComposition.crudeProtein;

            if (
              nutrientComposition.crudeLipid ||
              nutrientComposition.crudeLipid === 0
            )
              stageData.feedingProgram.nutrientComposition.crudeLipid =
                nutrientComposition.crudeLipid;

            if (
              nutrientComposition.crudeFibre ||
              nutrientComposition.crudeFibre === 0
            )
              stageData.feedingProgram.nutrientComposition.crudeFibre =
                nutrientComposition.crudeFibre;

            if (nutrientComposition.starch || nutrientComposition.starch === 0)
              stageData.feedingProgram.nutrientComposition.starch =
                nutrientComposition.starch;

            if (nutrientComposition.sugar || nutrientComposition.sugar === 0)
              stageData.feedingProgram.nutrientComposition.sugar =
                nutrientComposition.sugar;

            if (
              nutrientComposition.phosphorous ||
              nutrientComposition.phosphorous === 0
            )
              stageData.feedingProgram.nutrientComposition.phosphorous =
                nutrientComposition.phosphorous;

            if (
              nutrientComposition.feedSpill ||
              nutrientComposition.feedSpill === 0
            )
              stageData.feedingProgram.nutrientComposition.feedSpill =
                nutrientComposition.feedSpill;

            if (
              nutrientComposition.dryMatter ||
              nutrientComposition.dryMatter === 0
            )
              stageData.feedingProgram.nutrientComposition.dryMatter =
                nutrientComposition.dryMatter;

            if (
              nutrientComposition.solubleNitrogen ||
              nutrientComposition.solubleNitrogen === 0
            )
              stageData.feedingProgram.nutrientComposition.solubleNitrogen =
                nutrientComposition.solubleNitrogen;

            if (
              nutrientComposition.solublePhosphorous ||
              nutrientComposition.solublePhosphorous === 0
            )
              stageData.feedingProgram.nutrientComposition.solublePhosphorous =
                nutrientComposition.solublePhosphorous;

            if (
              nutrientComposition.solubleCarbon ||
              nutrientComposition.solubleCarbon === 0
            )
              stageData.feedingProgram.nutrientComposition.solubleCarbon =
                nutrientComposition.solubleCarbon;

            if (
              nutrientComposition.digestibleNitrogen ||
              nutrientComposition.digestibleNitrogen === 0
            )
              stageData.feedingProgram.nutrientComposition.digestibleNitrogen =
                nutrientComposition.digestibleNitrogen;

            if (
              nutrientComposition.digestibleCarbon ||
              nutrientComposition.digestibleCarbon === 0
            )
              stageData.feedingProgram.nutrientComposition.digestibleCarbon =
                nutrientComposition.digestibleCarbon;

            if (
              nutrientComposition.digestiblePhosphorous ||
              nutrientComposition.digestiblePhosphorous === 0
            )
              stageData.feedingProgram.nutrientComposition.digestiblePhosphorous =
                nutrientComposition.digestiblePhosphorous;
          }
        } // end of feedBlockData processing
      }
    });
  }
  payloadObj.stages.forEach((item) => {
    const { stageData } = item;
    // eslint-disable-next-line no-param-reassign
    item.stageData = JSON.stringify(stageData);
  });
  return payloadObj;
};

export default mapMarineFishProductionDataOutSustell;
