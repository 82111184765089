import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormHelperText, TextField } from "@material-ui/core";

const ControlledAutocomplete = (props) => {
  const { name, control, options, getOptionLabel, label, multiple, disabled, defaultValue, onChange: onChangeEvent = null, className = '', classes = null} = props;
  const { errors } = useFormContext();
  // console.log(errors);
  // console.log('name, vals', { name, control, options, getOptionLabel, label, multiple, disabled, defaultValue });
  return (
    <Controller
      name={name}
      render={({ onChange, ...props }) => (
        <React.Fragment>
        <Autocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={getOptionLabel}
          filterSelectedOptions
          renderInput={ params => <TextField {...params} error={errors[name] ? true : false} label={label} variant="outlined" />}
          error={errors[name]}
          onChange={(e, data) => {onChange(data); if(onChangeEvent) onChangeEvent(data); }}
          disabled={disabled}
          className={className}
          classes={classes}
          {...props}
        />
        <FormHelperText error={errors[name] ? true : false} >{ errors[name] ? (errors[name].message) : '' }</FormHelperText>
        </React.Fragment>
      )}
      defaultValue={(multiple && !defaultValue) ? [] : defaultValue}
      control={control}
      onChange={([, data]) => { return data }}
    />
  );
};
export default ControlledAutocomplete;