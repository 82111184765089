import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, BeefFarms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import BeefInputPartExternalSource from './BeefInputPartExternalSource';
import BeefInputPartInternalSource from './BeefInputPartInternalSource';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import {
  AnimalProductivity,
  GrowingPurpose,
} from '../../../../../../graphql/generated/blonk/cattle';
import { defaultValues } from '../../baselineDefaultValues';
import {
  BeefBaseline,
  BeefGrowingStageData,
  BeefInputGrowing,
} from '../../../../models/Baseline/BeefBaseline';
import ReactHookDsmRadioGroup, {
  RadioGroupOption,
} from '../../../../../modules/Helpers/ReactHookDsmRadioGroup';
import { Tooltip } from '@material-ui/core';
import DefinitionsDialog from '../../CommonDataParts/v2.0/DefinitionsDialog';
import lookupValues from '../../../../helpers/lookupValues';

const beefInputStageDefaultValues =
  defaultValues.BEEF.stages[0].stageData.input;

export interface BeefInputDialogProps extends BaselineDialogProps {
  farms: BeefFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

const BeefInputGrowingDialog: FC<BeefInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<BeefInputGrowing>();
  const formContext = useFormContext<BeefBaseline>();
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [sourcesAnimalType] = useState<{
    stageType:StageType;
    animalTypeOption: string;
  }[]>([]);
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const yesValue = intl.formatMessage({
    id: 'GENERAL.YES',
  });
  const noValue = intl.formatMessage({
    id: 'GENERAL.NO',
  });
  const isStageRepeatedOptions: RadioGroupOption[] = [
    { value: yesValue, label: yesValue },
    { value: noValue, label: noValue },
  ];

  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control: formContext.control,
    keyName: 'keyId',
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const addInternalSource = () => {
    appendInternal({ ...beefInputStageDefaultValues.internalSources[0] });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
  };

  const addExternalSource = () => {
    appendExternal({ ...beefInputStageDefaultValues.externalSources[0] });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as BeefBaseline;
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as BeefGrowingStageData;
      if (stageData?.input) {
        stageData.input = { ...currResetValue.current } as BeefInputGrowing;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel('reset');
  };

  const handleSaveClick = async () => {
    await formContext.trigger(fieldItemPrefix);
    handleCancel('confirm');
  };

  const cattleProductivityOptions = () =>
    Object.entries(AnimalProductivity).map(([key, value]) => ({
      text: key,
      value,
    }));

  const growingPurposeOptions = () =>
    Object.entries(GrowingPurpose).map(([key, value]) => ({
      // Separate words with space
      text: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      value,
    }));

  const resetToDefaultValues = () => {
    // reset cattleProductivity
    formContext.setValue(
      `${fieldItemPrefix}.cattleProductivity`,
      beefInputStageDefaultValues.cattleProductivity
    );
    formContext.clearErrors(`${fieldItemPrefix}.cattleProductivity`);
    // reset isStageRepeated
    formContext.setValue(
      `${fieldItemPrefix}.isStageRepeated`,
      beefInputStageDefaultValues.isStageRepeated
    );
    formContext.clearErrors(`${fieldItemPrefix}.isStageRepeated`);
    // reset numberOfRepetitions
    formContext.setValue(
      `${fieldItemPrefix}.numberOfRepetitions`,
      beefInputStageDefaultValues.numberOfRepetitions
    );
    formContext.clearErrors(`${fieldItemPrefix}.numberOfRepetitions`);
    // reset shrinkingRate from internalSources
    internalSources?.forEach((item, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.internalSources.${index}.shrinkingRate`,
        beefInputStageDefaultValues.internalSources[0].shrinkingRate
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.internalSources.${index}.shrinkingRate`
      );
      formContext.setValue(
        `${fieldItemPrefix}.internalSources.${index}.animalType`,
        beefInputStageDefaultValues.internalSources[0].animalType
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.internalSources.${index}.animalType`
      );
    });
    externalSources?.forEach((item, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.externalSources.${index}.shrinkingRate`,
        beefInputStageDefaultValues.externalSources[0].shrinkingRate
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.externalSources.${index}.shrinkingRate`
      );
    });
  };

  const updateSourceAnimalType = (
    indexSource: number, 
    stageTypeSource: StageType,
    animalTypeOptionSource: string
  ) => {
    const source = sourcesAnimalType.at(indexSource);

    if (source) {
      source.stageType = stageTypeSource;
      source.animalTypeOption = animalTypeOptionSource;

      const firstSource = sourcesAnimalType.find((source) => source.stageType === StageType.Breeding);

      if (firstSource) {
        if (sourcesAnimalType.indexOf(firstSource) === indexSource) {
          sourcesAnimalType.map((item, index) => {
            if (item.stageType === StageType.Breeding) {
              item.animalTypeOption = animalTypeOptionSource;
              formContext.setValue(
                `${fieldItemPrefix}.internalSources.${index}.animalType`,
                animalTypeOptionSource
              );
            }
          });
        }
      }
    }
    else {
      sourcesAnimalType.push({
        stageType: stageTypeSource,
        animalTypeOption: animalTypeOptionSource
      });
    }
  }

  const removeSourceAnimalType = (
    indexSource: number
  ) => {
    const source = sourcesAnimalType.at(indexSource);
    
    if (source) {
      sourcesAnimalType.splice(indexSource, 1);
    }
  }

  const shouldLockAnimalTypeSelection = (
    index: number
  ): boolean => {
    const source = sourcesAnimalType.find((source) => source.stageType === StageType.Breeding);

    if (source)
      return sourcesAnimalType.indexOf(source) !== index;

    return false;
  }

  const getAnimalTypeSelection = (): string | undefined => {
    const source = sourcesAnimalType.find((source) => source.stageType === StageType.Breeding);

    if (source)
      return source.animalTypeOption;

    return undefined;
  }

  const updateRepetitionRadioButton = (value: string) => {
    formContext.setValue(`${fieldItemPrefix}.isStageRepeated`, value);
  }

  return (
    <div>
      {openDescriptionDialog && (
        <DefinitionsDialog
          open
          prefix='BASELINE.FORM.INPUT.BEEF.CATTLE'
          itemHeader='BASELINE.FORM.INPUT.BEEF.CATTLE'
          items={lookupValues.cattleInputDefinitions}
          handleClose={() => setOpenDescriptionDialog(false)}
        />
      )}
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleResetClick}
        iconCode="general/check-heart"
        formTitle={intl.formatMessage({
          id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
        })}
        introText={intl.formatMessage({
          id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.BEEF_DESCRIPTION',
        })}
        variant="wide"
      >
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmPicker
            name={`${fieldItemPrefix}.startDate`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.START_DATE',
            })}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.BEEF.INPUT.START_DATE.TOOLTIP' 
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(`${fieldItemPrefix}.startDate`)}
          />
          <ReactHookDsmPicker
            name={`${fieldItemPrefix}.endDate`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.END_DATE',
            })}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.BEEF.INPUT.END_DATE.TOOLTIP' 
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(`${fieldItemPrefix}.endDate`)}
          />
          <ReactHookDsmRadioGroup
            name={`${fieldItemPrefix}.isStageRepeated`}
            label={`${intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.IS_STAGE_REPEATED',
            })}`}
            options={isStageRepeatedOptions}
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.isStageRepeated`
            )}
            changeHandler={updateRepetitionRadioButton}
          />
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.cattleProductivity`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.CATTLE_PRODUCTIVITY',
            })}
            options={cattleProductivityOptions()}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.BEEF.INPUT.CATTLE_PRODUCTIVITY.TOOLTIP' 
            })}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.cattleProductivity`
            )}
          />
          <div/>
          <DsmButton variant="text" className="mb-5" onClick={() => setOpenDescriptionDialog(true)}>
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({ id: 'DATABASE_FOUNDATION_DEFINITIONS' })}
          </DsmButton>
        </DsmGrid>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.numberOfRepetitions`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.NUMBER_OF_REPETITIONS',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.NUMBER_OF_REPETITIONS.TOOLTIP',
            })}
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.numberOfRepetitions`
            )}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.NUMBER_OF_REPETITIONS.ADORNMENT',
            })}
          />
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.growingPurpose`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.GROWING_PURPOSE',
            })}
            options={growingPurposeOptions()}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.GROWING_PURPOSE.TOOLTIP',
            })}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.growingPurpose`
            )}
            required
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageAgeAtStart`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_AGE_NEW_ANIMALS',
            })}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_AGE_NEW_ANIMALS.TOOLTIP' 
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageAgeAtStart`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_AGE_NEW_ANIMALS.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightNewAnimals`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.TOOLTIP',
            })}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightNewAnimals`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <div>
            <label
              htmlFor="internalSources"
              className={classes.headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.INPUT.INTERNAL_SOURCE',
              })}
            </label>
            <div>
              {internalSources &&
                internalSources?.map((item, index) => (
                  <BeefInputPartInternalSource
                    key={item.keyId}
                    farms={farms}
                    farmId={farmId}
                    farmName={farmName}
                    allowedStagesForFarm={[StageType.Breeding, StageType.Growing]}
                    productionProcessName={productionProcessName}
                    stageIndex={itemIndex}
                    itemIndex={index}
                    fieldPrefix={fieldItemPrefix}
                    removeHandler={removeInternalSource}
                    stageType={StageType.Growing}
                    formType={formType}
                    updateSourceAnimalType={updateSourceAnimalType}
                    removeSourceAnimalType={removeSourceAnimalType}
                    shouldLockAnimalTypeSelection={shouldLockAnimalTypeSelection}
                    getAnimalTypeSelection={getAnimalTypeSelection}
                  />
                ))}
              <DsmButton
                variant="text"
                onClick={addInternalSource}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.STAGE.BEEF.INPUT.ADD_INTERNAL_SOURCE',
                })}
              </DsmButton>
            </div>
          </div>
          <div>
            <label
              htmlFor="externalSources"
              className={classes.headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
            <div>
              {externalSources &&
                externalSources?.map((item, index) => (
                  <BeefInputPartExternalSource
                    key={item.keyId}
                    stageIndex={itemIndex}
                    itemIndex={index}
                    propName="externalSources"
                    removeHandler={removeExternalSource}
                    formType={formType}
                    stageType={StageType.Growing}
                  />
                ))}
              <DsmButton
                variant="text"
                onClick={addExternalSource}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.STAGE.BEEF.INPUT.ADD_EXTERNAL_SOURCE',
                })}
              </DsmButton>
            </div>
          </div>
        </DsmGrid>
        <div className={classes.resetButtonContainer}>
          <DsmButton
            widthFull
            variant="text"
            onClick={() => resetToDefaultValues()}
            disabled={formType === FormType.View}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(85%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => handleSaveClick()}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </DialogContainer>
    </div>
  );
};

export default BeefInputGrowingDialog;
