import "jodit/build/jodit.min.css";
import { Jodit } from "jodit";
import { func, number, object, string } from "prop-types";
import React, { useEffect, useRef, forwardRef } from "react";

const EditorConfig = {
	buttons: [
	  "source",
	  "|",
	  "bold",
	  "italic",
	  "|",
	  "ul",
	  "ol",
	  "|",
	  "font",
	  "fontsize",
	  "brush",
	  "paragraph",
	  "|",
	  "link",
	  "|",
	  "left",
	  "center",
	  "right",
	  "justify",
	  "|",
	  "undo",
	  "redo",
	],
	readonly: false,
	toolbarAdaptive: false,
	colorPickerDefaultTab: 'text'
  };

const Editor = forwardRef(({ id, name, onBlur, onChange, tabIndex, value }, _) => {
    const textArea = useRef();

    useEffect(() => {
      const element = textArea.current;
      textArea.current = Jodit.make(element, EditorConfig);
      textArea.current.workplace.tabIndex = tabIndex || -1;

      textArea.current.events.on(
        "blur",
        (e) => onBlur && onBlur(textArea.current.value, e)
      );
      textArea.current.events.on(
        "change",
        (value) => onChange && onChange(value)
      );

      if (id) element.id = id;
      if (name) element.name = name;

      return () => {
        if (textArea?.current) {
          textArea.current.destruct();
        }

        textArea.current = element;
      };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (textArea?.current?.value !== value) {
        textArea.current.value = value;
      }
    }, [value]);

    return <textarea ref={textArea} />;
  }
);

Editor.propTypes = {
  config: object,
  id: string,
  name: string,
  onBlur: func,
  onChange: func,
  editorRef: func,
  tabIndex: number,
  value: string,
};

export default Editor;