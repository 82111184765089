import { Link } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const TitleLink = styled(Link)({
  color: '#000',
  '&:hover': {
    textDecoration: 'underline !important',
    color: '#000'
  }
});

export default TitleLink;
