import React, { FC, useState } from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
  Tooltip
} from "@material-ui/core";
import { DsmIcon } from "@dsm-dcs/design-system-react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import { Maybe } from "graphql/jsutils/Maybe";
import { resolvePath } from "./resolvePathFn";
import { ListEntry } from "../../sustell_15/components/FarmFlow/common";

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute', 
  height: '16px', 
  width: '16px',
  top:'28px',
  right:'24px'
}

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root':{
      marginTop: '-2px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      height: '36px',
      lineHeight: '38px'
    },
    '& label': {
      position: 'relative !important',
      marginBottom: '4px',
      fontSize: '16px',
      fontWeight: '500'
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-root': {

      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },
    },
  }
})

interface ReactHookDsmMultiSelectProps{
  name:string;
  label:string;
  defaultValue?:string;
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  adornment?: string;
  readonly?: boolean;
  required?: boolean;
  
  options?:Array<ListEntry>;
  children?:Array<typeof MenuItem>;

  changeHandler?:Maybe<Function>;
  onFocusHandler?:Maybe<Function>;
  style?: any;
}

const ReactHookDsmMultiSelect: FC<ReactHookDsmMultiSelectProps> = ({
  name,
  label,
  defaultValue='',
  children=[],
  options=[],
  required=false,
  tooltip='',
  disabled=false,
  changeHandler,
  placeholder='',
  ...props
}) => {
  const classes = useStyles();
  const labelId = `${name}-label`;
  const optionsAvailable = options?.length>0 || children?.length > 0;
  const { errors, control } = useFormContext();
  const localError:FieldError|null = resolvePath(errors, name, null) as FieldError | null;
  const [open, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line prefer-destructuring
  // console.log("local error", localError)
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, name, value }) => 
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FormControl className={classes.root} style={{ ...props.style }}>
        {/* <FormControl {...props} className={classes.root} > */}
          <InputLabel 
            id={labelId} 
            disabled={disabled}
            disableAnimation
            error={!!localError}
            shrink
            variant='standard'
            >
              {`${label} ${required ? '*' : ''}`}              
          </InputLabel>
          <Select
            name={name}
            variant="outlined"
            value={ (value && optionsAvailable ? value : []) as string[]}
            onBlur={onBlur}
            error={!!localError}
            placeholder={placeholder}
            multiple
            disabled={disabled}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onChange={
              (e: any) => {
                if (!e.target?.value?.length) e.target.value = value;
                onChange(e);
                if(changeHandler) changeHandler(e);
                setOpen(false)
              }
            }

          >
            {/* values can be passed as array of { text, value } objects */}
            { options && options.length>0 && options.map(item => 
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              )
            } 
            {/* values can be passed also as a list of child <MenuItem> nodes */}
            { (!options || options.length<1) &&
              children
            }
          </Select>
          <FormHelperText error={!!localError} >
            { localError ? (localError.message) : '' }
          </FormHelperText>
          {tooltip && 
            <Tooltip title={ tooltip } placement="left">
              <DsmIcon name='general/help-circle' style={inlineTooltipClass} title=""/>
            </Tooltip>
          }
        </FormControl>
      }
    />
  );
};
export default ReactHookDsmMultiSelect;