/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { DsmGrid } from "@dsm-dcs/design-system-react";

import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { DialogContainer} from "../../CommonDataParts/DialogContainer2";

interface OutputDefinitionsDialogProps {
  open: boolean;
  handleClose: () => void;
}

const OutputDefinitionsDialog: FC<OutputDefinitionsDialogProps> = ({
  open=false,
  handleClose
}) => {

  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;

  const title = intl.formatMessage({id: "SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.TITLE"});
  const subtitle = intl.formatMessage({id: "SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.SUBTITLE"})
  const description = intl.formatMessage({id: "SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.DESCRIPTION"});
  const linkSubstances = 'http://eur-lex.europa.eu/legal-content/EN/AUTO/?uri=uriserv:l12032b';
  const rowIndexes = [1, 2, 3, 4, 5, 6];

  return (
    <DialogContainer
      formVisible={open}
      variant="ultrawide"
      handleClose={handleClose}
      withIcon={false}
      formTitle={title}
      introText={subtitle}
    >
      <div>
        {description} <br />
      </div>
      <DsmGrid
        className={classes.dsmGridTwoColumn2nd60}
        style={{ border: '1px solid darkgray' }}
      >
        <div
          style={{ marginTop: '12px', marginLeft: '8px', fontSize: 'large' }}
        >
          <b>
            {' '}
            {intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.TABLE_HEAD.1',
            })}
          </b>
        </div>
        <div
          style={{ marginTop: '12px', marginLeft: '8px', fontSize: 'large' }}
        >
          <b>
            {intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.TABLE_HEAD.2',
            })}
          </b>
        </div>
      </DsmGrid>
     
        {rowIndexes.map((index) => (
        <DsmGrid
            className={classes.dsmGridTwoColumn2nd60}
            style={{ border: '1px solid darkgray' }}
          >
            <div
              style={{
                // marginTop: 'auto',
                // marginBottom: 'auto',
                padding: '8px',
                fontSize: 'medium',
              }}
            >
              {intl.formatMessage(
                {
                  id: `SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.ROW.${index}.COLUMN.1`,
                },
                {
                  br: (
                    <>
                      {' '}
                      <br />
                      <br />
                    </>
                  ),
                  a: <a href={linkSubstances}>prohibited substances</a>,
                }
              )}
            </div>
            <div style={{ padding: '12px' }}>
              {intl.formatMessage(
                {
                  id: `SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.ROW.${index}.COLUMN.2`,
                },
                {
                  br: (
                    <>
                      {' '}
                      <br />
                      <br />
                    </>
                  ),
                  linkSubstances: <a href={linkSubstances} target="_blank" rel="noreferrer">{intl.formatMessage({id: "SUSTELL.STAGES.PROCESSING.OUTPUT.DEFINITIONS.PROHIBITED_SUBSTANCES"})}</a>,
                }
              )}
            </div>
          
          </DsmGrid>
        ))}
    </DialogContainer>
  );
}

export default OutputDefinitionsDialog;