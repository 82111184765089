import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DsmIcon } from '@dsm-dcs/design-system-react';

export type FileUploadProps = {
  imageButton?: boolean;
  accept: string;
  multiple: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  image?: {
    url: string;
    imageStyle?: {
      width?: string;
      height?: string;
    };
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
};

const useStyle = makeStyles({
  root: {
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    border: '1px solid #F0F0F0',
    '&:hover p,&:hover svg,& img': {
      opacity: 1,
    },
    '& p, svg': {
      opacity: 0.4,
    },
    '&:hover img': {
      opacity: 0.3,
    },
  },
  noMouseEvent: {
    pointerEvents: 'none',
  },
  iconText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  onDragOver: {
    '& img': {
      opacity: 0.3,
    },
    '& p, svg': {
      opacity: 1,
    },
  },
});

export const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  multiple,
  imageButton = false,
  //   dropLabel = 'Click to upload ',
  width = '100%',
  height = '114px',
  backgroundColor = '#fff',
  image: {
    url = '',
    imageStyle = {
      height: 'inherit',
    },
  } = {},
  onChange,
  onDrop,
}) => {
  const classes = useStyle();
  const intl = useIntl();
  const [imageUrl, setImageUrl] = React.useState(url);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setIsDragOver(false);
      if (imageButton && e.dataTransfer.files[0]) {
        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
      onDrop(e);
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      imageButton &&
      event.target &&
      event.target.files &&
      event.target.files[0]
    ) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
    }

    onChange(event);
  };

  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        className={classes.hidden}
        id="file-upload"
        type="file"
        multiple={multiple}
      />

      <label
        htmlFor="file-upload"
        {...dragEvents}
        className={clsx(classes.root, isDragOver && classes.onDragOver)}
      >
        <Box
          width={width}
          height={height}
          bgcolor={backgroundColor}
          className={classes.noMouseEvent}
        >
          {imageButton && (
            <Box position="absolute" height={height} width={width}>
              <img alt="file upload" src={imageUrl} style={imageStyle} />
            </Box>
          )}

          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box height={height} width={width} className={classes.iconText}>
                <DsmIcon
                  slot="before"
                  name="general/upload-cloud-02"
                  style={{ height: '30px', width: '30px', color: '#0070BA' }}
                />
                <Typography>
                  {' '}
                  <span style={{ color: '#0070BA', fontWeight: 500 }}>
                    {intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.FILEUPLOAD.TEXT',
                    })}
                  </span>
                  <span>
                    {intl.formatMessage(
                      {
                        id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.FILEUPLOAD.TEXT.PART2',
                      },
                      { br: <br /> }
                    )}
                  </span>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </label>
    </>
  );
};
