import * as Yup from 'yup';
import {
  numericRequiredWithMinMax,
  numericRequiredWithMin,
  numericOptionalWithMin,
} from './validationObjectBuilderFunctions';
import { forbiddenChars } from '../../../../sustell_15/helpers/validations';

export const baselineValidationSchemaGeneralPart = ({ intl }) =>
  Yup.object({
    animalType: Yup.string().oneOf(
      ['Laying hens', 'Dairy', 'Broiler', 'Pig fattening', 'Salmon', 'PIG', 'BEEF' ],
      'Invalid animalType.'
    ),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
      )
      // prevent use of quotation marks in the name
      .concat(
        forbiddenChars(
          intl,
          ['"', "'"],
          'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
        )
      ),
    year: Yup.number()
      .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
      .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
    validFrom: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    validTo: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('validFrom'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    description: Yup.string().when(`animalType`, {
      is: (val) => val !== 'PIG',
      then: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
      ),
    }),
  });

export const baselineValidationSchemaGeneralPartV2 = ({ intl }) =>
  Yup.object({
    animalType: Yup.string().oneOf(
      ['PIG', 'SALMON_V2', 'SEA_BASS', 'SEA_BREAM', 'LAYING_HENS_V2', 'BROILER_V2', 'TURKEY', 'BEEF'],
      'Invalid animalType.'
    ),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
      ),
    year: Yup.number()
      .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
      .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
    validFrom: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    validTo: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('validFrom'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    description: Yup.string(),
  });

export const baselineValidationSchemaAvgTemperaturePart = ({
  intl,
  minTemp = -20,
  maxTemp = 60,
}) =>
  Yup.object({
    avgAnualTemperature: numericRequiredWithMinMax(intl, minTemp, maxTemp),
  });

// validation for time unit and cycle/year option, when required for animal type
export const baselineValidationSchemaGeneralPartTimeCycle = ({ intl }) =>
  Yup.object({
    timeUnit: Yup.string()
      .oneOf(['ROUND', 'YEAR'], 'Invalid time unit.')
      .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
    numOfCyclesYear: numericRequiredWithMin(intl, 0.3),
  });

export const resourceItems = (intl, minValue = 0) => Yup.array()
  .of(
    Yup.object({
      type: Yup.string('').required(intl.formatMessage({ id: 'VALIDATION.TYPE.REQUIRED' })),
      value: Yup.number()
        .transform((changed, original) =>  original === '' ? undefined : changed)
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .required(intl.formatMessage({id:'VALIDATION.NUMERIC.INPUT'}))
        .moreThan(
            minValue,
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
              { value: minValue }
            )
          ),
    })
  );

// common resources validation, if necessary additional, or completely separate may be used.
export const usedResourcesPart = ({ intl }) =>
  Yup.object({
    electricityUse: numericOptionalWithMin(intl),
    gasUse: numericOptionalWithMin(intl),
    waterUse: numericOptionalWithMin(intl),
    dieselUse: numericOptionalWithMin(intl),
    selfGeneratedRenewables: resourceItems(intl),
    energyTypes: resourceItems(intl),
  });
