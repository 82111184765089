import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { PigBreedingOutput, PigBreedingStageData } from '../../../../models/Baseline/PigBaseline';
import {
  PigIntervention,
  PigOutputBreeding,
} from '../../../../models/Intervention';
import { unitLong } from '../../../../utils/unit-utils';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { PigInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const PBInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PigInterventionFormDialogProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitBarnOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass as string)
    : ('kg' as string);

  const currResetValue = useRef<PigOutputBreeding>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PigIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.output) {
        stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const output = (baseline?.stages?.[stageIndex]?.stageData as PigBreedingStageData)
    ?.output;

  useEffect(() => {
    if (formVisible) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {};
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="wide"
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridTwoColumnIntervention}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeightPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS.INTERVENTION.TOOLTIP',
          })}
          type="number"
          metricUnit={barnOutputMassUnit}
          precision={barnOutputMassPrecision}
          baseline={output?.averageWeightPigs}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeightPiglets`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS.INTERVENTION.TOOLTIP',
          })}
          type="number"
          metricUnit={barnOutputMassUnit}
          precision={barnOutputMassPrecision}
          baseline={output?.averageWeightPiglets}
          disabled={formType === FormType.View}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.mortalityPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.SOWS',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.SOWS.INTERVENTION.TOOLTIP',
          })}
          type="number"
          metricUnit=""
          baseline={output?.mortalityPigs}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.mortalityPiglets`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.PIGLETS',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.MORTALITY.PIGLETS.INTERVENTION.TOOLTIP',
          })}
          type="number"
          metricUnit=""
          baseline={output?.mortalityPiglets}
          disabled={formType === FormType.View}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.pigsToStage`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS.TO_ANOTHER_STAGE',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.SOWS.TO_ANOTHER_STAGE.INTERVENTION.TOOLTIP',
          })}
          type="number"
          metricUnit=""
          baseline={output?.pigsToStage}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.pigletsToStage`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS.TO_ANOTHER_STAGE',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.WEIGHT.PIGLETS.TO_ANOTHER_STAGE.INTERVENTION.TOOLTIP',
          })}
          metricUnit=""
          baseline={output?.pigletsToStage}
          disabled={formType === FormType.View}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.soldPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.SOLD',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.SOLD.INTERVENTION.TOOLTIP',
          })}
          metricUnit=""
          baseline={output?.soldPigs}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.soldPiglets`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.SOLD',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.SOLD.INTERVENTION.TOOLTIP',
          })}
          metricUnit=""
          baseline={output?.soldPiglets}
          disabled={formType === FormType.View}
        />

        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.priceSoldPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.PRICE_SOLD',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.PRICE_SOLD.INTERVENTION.TOOLTIP',
          })}
          metricUnit={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.SOWS.PRICE_SOLD.UNIT',
          })}
          baseline={output?.priceSoldPigs}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.priceSoldPiglets`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.PRICE_SOLD',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.PRICE_SOLD.INTERVENTION.TOOLTIP',
          })}
          metricUnit={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.BREEDING.OUTPUT.PIGLETS.PRICE_SOLD.UNIT',
          })}
          baseline={output?.priceSoldPiglets}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PBInterventionOutputFormDialog;
