import { FC } from "react";
import { Maybe } from "graphql/jsutils/Maybe";

import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { DsmButton, DsmGrid } from "@dsm-dcs/design-system-react";
import { processAndStageStylesV2 } from "../../../../_metronic/layout";

interface ButtonControlGroupProps {
  cancelHandler: Maybe<Function>;
  cancelLabel?: string;
  fullWidth?: boolean|undefined;
  saveHandler?: Maybe<Function>;
  saveLabel: string;
  additionalControls?: any;
  additionalButtons?: any[];
  draftHandler?: Maybe<Function>;
}

const DsmButtonControlGroup: FC<ButtonControlGroupProps> = ({cancelHandler, cancelLabel, saveHandler, saveLabel, additionalControls, draftHandler, fullWidth=undefined}) => {
  const classes = processAndStageStylesV2() as any;
  
  /*
    Component will always render cancel button
    Component uses space-beetween to justify content
    Props:
        - cancelHandler - function to handle cancel btn click
        - cancelLabel (optional)
        - saveHandler - function to handle save btn click - if not passed save btn will not be rendered
        - saveLabel (optional)
        - additionalControls - list of object in the form of
                                {btnClkHandler:functionObj,btnLabel:labelString}
                                Will render additional buttons after cancel (and save)
  */

  const intl = useIntl();
    
  if(!cancelLabel)
      cancelLabel = intl.formatMessage({ id: "GENERAL.CANCEL" })
  if(!saveLabel)
      saveLabel = intl.formatMessage({ id: "GENERAL.SAVE"})
  
  let additionalButtons = null;
  if(additionalControls){
      additionalButtons = additionalControls?.map((item: any, i: number) =>
          <DsmButton variant="secondary" onClick={item.btnClkHandler} slot="action">{item.btnLabel} </DsmButton>
      );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <DsmGrid className={fullWidth ? classes.dsmGridOneColumn : classes.dsmGridTwoColumn} style={{marginTop:"var(--dsm-spacing-px-6"}}>
      {cancelHandler &&
        <DsmButton variant="secondary" widthFull onClick={(e:any) => cancelHandler(e)} >{cancelLabel}</DsmButton>
      }
      {draftHandler &&
        <DsmButton variant={saveHandler === null ? "primary" : "secondary"}  onClick={(e:any) => draftHandler(e)} >
          { intl.formatMessage({ id: "GENERAL.DISCARD_DRAFT"}) }
        </DsmButton>
      }
      
      {additionalButtons}
      <DsmButton variant="primary" widthFull disabled={ saveHandler ? false : true} onClick={(e: any) => saveHandler ? saveHandler(e) : ()=>{} } >{saveLabel}</DsmButton>
    </DsmGrid>
  );
};

export default DsmButtonControlGroup;