import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from "@material-ui/core";

import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup"
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { BaselinePropertyValue, PigFatteningBaseline, PigFatteningEmission } from '../../../../models/Baseline';

const PFEmissionsFormDialog: FC<BaselineDialogProps> = ({
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  formVisible=false
}) => {

  const intl = useIntl();
  const formContext = useFormContext();
  
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"}) ;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions`; 
  const welcomeString = intl.formatMessage({id: "EMISSIONS.WELCOME"});

  const currResetValue = useRef<PigFatteningEmission>();
  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {...formContext.getValues(fieldItemPrefix)}  as PigFatteningEmission; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    // console.log("resetValue emission:", currResetValue.current);
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as PigFatteningBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.emissions){
        resetObject.stages[itemIndex].stageData.emissions = {...currResetValue.current};
        formContext.reset(resetObject, { errors: true });
      }
    }
    
    if(handleCancel)
      handleCancel();
  }

  return (
    <DialogContainer
      formVisible = { formVisible }
      variant = 'wide'
      handleClose = { handleResetClick }
      iconCode="emissionIcon"
      formTitle = { formTitle }
      introText = { intl.formatMessage({id: "EMISSIONS.INTRO"}, {boldedWelcome: <strong>{welcomeString}</strong>, br: <br/>}) }
    > 
      <Grid container spacing={3} direction="row">
        <Grid item container direction="column" xs={6} spacing={3} >
          <Grid item>
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.methaneEntericFermentation`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP"})}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.methaneEntericFermentation`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.methane`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE.TOOLTIP"})}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.methane`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item >
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.nitrousOxideDirect`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP"})}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrousOxideDirect`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item >
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.nitrousOxideIndirect`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrousOxideIndirect`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item  >
	            <RowTextFieldWithInfo
	              control={formContext.control}
	              name={`${fieldItemPrefix  }.amonia`}
	              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.AMMONIA"})}
	              margin="none"
	              variant="outlined"
	              tooltip={intl.formatMessage({id:  "BASELINE.FORM.EMISSIONS.AMMONIA.TOOLTIP"})}
	              addornment="%"
                disabled={formType === FormType.View}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.amonia`) as BaselinePropertyValue}
	            />
	          </Grid>
	        </Grid>
	        <Grid item container direction="column" xs={6} spacing={3} >
            <Grid item  >
                <RowTextFieldWithInfo
                  control={formContext.control}
                  name={`${fieldItemPrefix  }.nitricOxide`}
                  label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE"})}
                  margin="none"
                  variant="outlined"
                  tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE.TOOLTIP"})}
                  addornment="%"
                  disabled={formType === FormType.View}
                  defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitricOxide`) as BaselinePropertyValue}
                />
	          </Grid>
	          <Grid item >
	            <RowTextFieldWithInfo
	              control={formContext.control}
	              name={`${fieldItemPrefix  }.nonMethaneVolatile`}
	              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_ORGANIC"})}
	              margin="none"
	              variant="outlined"
	              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
	              addornment="%"
                disabled={formType === FormType.View}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nonMethaneVolatile`) as BaselinePropertyValue}
	            />
	          </Grid>
	          <Grid item >
	            <RowTextFieldWithInfo
	              control={formContext.control}
	              name={`${fieldItemPrefix  }.PM10`}
                label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10"})}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10.TOOLTIP"})}
                addornment="%"
                disabled={formType === FormType.View}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.PM10`) as BaselinePropertyValue}
              />
          </Grid>
          <Grid item  >
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.PM25`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5.TOOLTIP"})}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.PM25`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.totalSuspendedParticles`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES.TOOLTIP"})}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.totalSuspendedParticles`) as BaselinePropertyValue}
	            />
	          </Grid>
	        </Grid>
	        <Grid item xs={12}>
	          <ButtonControlGroup
	            cancelHandler={handleResetClick}
	            saveHandler={ handleSave }
	            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
	          />
	        </Grid>
	      </Grid>
	    </DialogContainer>
	);
};

export default PFEmissionsFormDialog;