import { numericFormatter } from 'react-number-format';

type NumericFormatOptions = {
  decimalScale: number;
};

const commaPatternRegex = /^\d{1,3}(\.\d{3})*(,\d+)$/;
const specialCharactersRegex = /(?<!^)-|[a-zA-Z,;:?!()[\]{}<>/\\@#$%^&*+\=|~_`'"]/;
const specialCharactersGlobalRegex = /(?<!^)-|[a-zA-Z,;:?!()[\]{}<>/\\@#$%^&*+\=|~_`'"]/g;

const handleTypeNumber = (value: string) => {
  let numericInput = value;
  // early return if invalid character or special character is filled in
  // only 5 decimals and dots are allowed
  if (
    value &&
    !value.match(/^\d+(\.\d{1,5})?$/) &&
    !value.match(commaPatternRegex) &&
    value.match(specialCharactersRegex)
  ) {
    return numericFormatter(
      numericInput.replaceAll(specialCharactersGlobalRegex, ''),
      { decimalScale: 5 }
    );
  }
  if (
    value.match(commaPatternRegex) &&
    !value.match(/^\d*(.)?$/) &&
    (value.includes(',') || value.includes('.'))
  ) {
    let [before, after] = value.split(',');
    before = before.replaceAll('.', '');
    if (after.length > 4) {
      after = after.slice(0, 5);
    }
    numericInput = `${before}.${after}`;
  }
  return numericFormatter<NumericFormatOptions>(numericInput, {
    decimalScale: 5,
  });
};

export default handleTypeNumber;
