import { API } from 'aws-amplify';
import {
  createAnimalSystem,
  updateAnimalSystem,
  createFarmBaseline,
  updateFarmBaseline,
  createIntervention,
  updateIntervention,
  deleteIntervention,
  deleteProductionDataset,
  copyBaselineToV2,
} from '../../../graphql/mutations';
import {
  getFarmSinglePPWithBaselineInfo,
  getFarmBaselineByReference,
  getFarmBaselineByReferenceV2,
  getInterventionByReference,
  getFarm,
  getFarmWithAllBaselineInfo,
} from '../../../graphql/queries';

export const saveProductionProcess = (
  formType,
  farmId,
  inputData,
  successHandler,
  errorHandler,
  formSubmissionEvent
) => {
  (async () => {
    try {
      formSubmissionEvent(
        formType === 'edit'
          ? 'Edit Production Process'
          : 'Add Production Process',
        formType,
        inputData.animalType
      );
      const result = await API.graphql({
        query:
          formType === 'edit'
            ? updateAnimalSystem
            : createAnimalSystem,
        variables: {
          farmId,
          input: inputData,
        },
      });
      const savedData = {
        ...inputData,
        id: result.data?.createAnimalSystem?.id,
      };
      successHandler(savedData);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const saveBaseline = (
  formType,
  customerId,
  farmId,
  productionProcessId,
  inputData,
  animalType,
  successHandler,
  errorHandler,
  formSubmissionEvent
) => {
  (async () => {
    try {
      formSubmissionEvent(
        formType === 'edit' ? 'Edit Baseline' : 'Add Baseline',
        formType,
        animalType,
        inputData?.stages?.map(s => s.type)?.join(',')
      );
      const vars = {
        customerId,
        farmId,
        input: inputData,
      };
      if (formType !== 'edit') vars.animalSystemId = productionProcessId;
 
      if (formType === 'add') {
        if (vars.input.oldName)
          // this is possible cause oldName is set by mapper so if loaded from draft it can be set
          delete vars.input.oldName;
        if (vars.input.initialV2Copy !== undefined)
          delete vars.input.initialV2Copy;
      }
      
      const result = await API.graphql({
        query: formType === 'edit' ? updateFarmBaseline : createFarmBaseline,
        variables: vars,
      });
      const savedData = { ...inputData, id: result.id };
      successHandler(savedData);
    } catch (err) {
      if (err.errors?.length > 0) {
        // assemble single error message if there are more than one
        const asmErrorMsg = err.errors.reduce(
          (finalMsg, error, index) =>
            `${finalMsg} ${error.message}${
              index !== err.errors.length - 1 ? '.' : ''
            }`,
          ''
        );
        if (asmErrorMsg && errorHandler) {
          errorHandler(asmErrorMsg);
        }
      }
    }
  })();
};

export const fetchProductionProcessBaselines = (
  farmId,
  productionProcessId,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getFarmSinglePPWithBaselineInfo,
        variables: {
          farmId: farmId,
          productionProcessId: productionProcessId,
        },
      });
      successHandler(result?.data?.getFarm);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const fetchProductionProcessBaselinesWithDetailedInfo = (
  farmId,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getFarmWithAllBaselineInfo,
        variables: {
          farmId,
        },
      });
      successHandler(result?.data?.getFarm);
    } catch (err) {
      if (err.errors?.length > 0) {
        const error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};


export const fetchPPDataset = (
  farmId,
  reference,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getFarmBaselineByReference,
        variables: {
          farmId: farmId,
          reference: reference,
        },
      });
      successHandler(result?.data?.getFarmBaselineByReference);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const getFarmBaseData = (farmID, successHandler, errorHandler) => {
  (async () => {
    try {
      const response = await API.graphql({
        query: getFarm,
        variables: {
          farmId: farmID,
        },
      });
      successHandler(response.data?.getFarm);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const getFarmAnimalType = async (farmID, stateAnimalType) => {
  const response = await API.graphql({
    query: getFarm,
    variables: {
      farmId: farmID,
    },
  });
  if (
    response.data?.getFarm?.animalSystems &&
    response.data?.getFarm?.animalSystems.items.length > 0
  ) {
    console.log(stateAnimalType, 'stateAnimalType')
    if (stateAnimalType) {
      const animalSystem =  response.data?.getFarm?.animalSystems.items.find(pp => pp.animalType === stateAnimalType);
      if (animalSystem) {
        return animalSystem.animalType;
      }
    }
    return response.data?.getFarm?.animalSystem?.items[0]?.animalType;
  }
};

export const fetchPPDatasetV2 = (
  farmId,
  reference,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getFarmBaselineByReferenceV2,
        variables: {
          farmId: farmId,
          reference: reference,
        },
      });
      successHandler(result?.data?.getFarmBaselineByReference);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const fetchPPDatasetV2ForInterventions = (
  farmId,
  reference,
  farmAnimalType,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getFarmBaselineByReferenceV2,
        variables: {
          farmId: farmId,
          reference: reference,
        },
      });
      successHandler(result?.data?.getFarmBaselineByReference, farmAnimalType);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const deleteBaseline = (
  farmId,
  datasetName,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: deleteProductionDataset,
        variables: {
          farmId: farmId,
          name: datasetName,
        },
      });
      successHandler(result);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const copyBaseline = (
  customerId,
  datasetReferenceId,
  processId,
  databaseFoundation,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: copyBaselineToV2,
        variables: {
          input: {
            customerId,
            productionProcessId: processId,
            baselineReferenceId: datasetReferenceId,
            databaseFoundation,
          },
        },
      });
      successHandler(result);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const deleteInterventionDataset = (
  customerId,
  farmId,
  productionProcessId,
  interventionName,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: deleteIntervention,
        variables: {
          customerId: customerId,
          SK: `${farmId}#${productionProcessId}#${interventionName}`,
        },
      });
      successHandler(result);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const saveIntervention = (
  formType,
  customerId,
  farmId,
  productionProcessId,
  inputData,
  animalType,
  successHandler,
  errorHandler,
  formSubmissionEvent,
  oldName = null
) => {
  (async () => {
    try {
      formSubmissionEvent(
        formType === 'edit' ? 'Edit Intervention' : 'Add Intervention',
        formType,
        animalType,
        inputData?.stages?.map(s => s.type)?.join(',')
      );
      const vars = {
        customerId: customerId,
        farmId: farmId,
        animalSystemId: productionProcessId,
        input: inputData,
      };
      if (formType === 'edit' && oldName) {
        vars.oldName = oldName;
      }

      if (vars.input.oldName)
        // this is possible cause oldName is set by mapper so if loaded from draft it can be set
        delete vars.input.oldName;

      const result = await API.graphql({
        query: formType === 'edit' ? updateIntervention : createIntervention,
        variables: vars,
      });
      const savedData = { ...inputData, id: result.id };
      successHandler(savedData);
    } catch (err) {
      if (err.errors?.length > 0) {
        // assemble single error message if there are more than one
        const asmErrorMsg = err.errors.reduce(
          (finalMsg, error, index) =>
            `${finalMsg} ${error.message}${
              index !== err.errors.length - 1 ? '.' : ''
            }`,
          ''
        );
        if (asmErrorMsg && errorHandler) {
          errorHandler(asmErrorMsg);
        }
      }
    }
  })();
};

export const fetchInterventionDataset = (
  farmId,
  reference,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getInterventionByReference,
        variables: {
          farmId: farmId,
          reference: reference,
        },
      });
      successHandler(result?.data?.getInterventionByReference);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};

export const fetchInterventionDatasetForInterventions = (
  farmId,
  reference,
  farmAnimalType,
  successHandler,
  errorHandler
) => {
  (async () => {
    try {
      const result = await API.graphql({
        query: getInterventionByReference,
        variables: {
          farmId: farmId,
          reference: reference,
        },
      });
      successHandler(result?.data?.getInterventionByReference, farmAnimalType);
    } catch (err) {
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        if (error && errorHandler) {
          errorHandler(error.message);
        }
      }
    }
  })();
};
