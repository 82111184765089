/**
 * Entry application component used to compose providers and render Routes.
 * */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Routes } from './Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { ConfirmDialog } from './modules/Helpers/ConfirmDialog';
import useAdobeDataLayer from '../app/sustell_15/analytics/adobeDataLayer';

import { initializeProfileListener } from './modules/Auth/UserSettings';

import { AwsRum } from 'aws-rum-web';
import featureFlags from './sustell_15/utils/featureFlags';

const App = (props) => {
  const queryClient = new QueryClient();
  const [confirm, setConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(
    'There is unsaved data. If you leave the page, current changes will be lost.'
  );
  const [confirmCallback, setConfirmCallback] = useState(null);

  function getConfirmation(message, callback) {
    // console.log("Inside getConfirmation function...", message);
    setConfirmMessage(message);
    setConfirmCallback(() => callback);
    setConfirm(true);
  }

  const { pageLoadEvent } = useAdobeDataLayer();

  const profileListenerInitialized = React.useRef(false);

  if (!profileListenerInitialized.current) {
    // console.log("profile listener needs to initialize");
    profileListenerInitialized.current = true;
    const userPrefs = initializeProfileListener();
    const entries = performance.getEntriesByType('navigation');

    if (entries && entries[0]?.type === 'reload') {
      // console.log("page reloaded - sign in will be missing");
      // if page is reloaded, the userPrefs will be reloaded, but lastLogin timestamp will not be changed
      userPrefs.getUserProfile();
    }
  }

  if (featureFlags.cloudWatchRUM) {
    try {
      const config = {
        sessionSampleRate: 1,
        identityPoolId: "eu-west-1:75b89929-f814-4c0b-8163-b8738a692810",
        endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
        telemetries: ["performance","errors","http"],
        allowCookies: true,
        enableXRay: true
      };
    
      const APPLICATION_ID = '61fe2441-8c01-4b8c-9c7c-4287829f3bc3';
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'eu-west-1';
    
      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

  useEffect(() => {
    pageLoadEvent();
  }, []);

  return (
    /* Add high level `Suspense` in case if was not handled inside the React tree. */
    <React.Suspense fallback={<LayoutSplashScreen />}>
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter
        basename={props.basename}
        getUserConfirmation={getConfirmation}
        forceRefresh={false}
      >
        {/* This library only returns the location that has been active before the recent location change in the current window lifetime. */}
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <MaterialThemeProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
                {confirm && (
                  <ConfirmDialog
                    confirmCallback={confirmCallback}
                    setConfirm={setConfirm}
                    message={confirmMessage}
                  />
                )}
              </I18nProvider>
            </MuiPickersUtilsProvider>
          </MaterialThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
