import { Tab } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import { IntlShape } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import {
  FeedFormFields,
  IngredientNamesAndOriginsV2,
  IngredientUses,
  OnSiteActivitiesInput,
  OnSiteActivitiesKey,
  OutboundTransportInput,
  OutboundTransportKey,
} from './types';
import {
  CompoundFeedAddonsV2,
  CompoundFeedDatabase,
  CompoundFeedIngredientName,
  InputMaybe,
  TypeValueFloatInput,
} from '../../../../graphql/types';
import {
  convertDistanceUnits,
  convertOnSiteActivityUnits,
} from '../../../modules/Helpers/UnitConverter';
import lookupValues from '../../helpers/lookupValues';
import { CFDatabaseFoundation } from '../../models/CompoundFeed';
import countryCodes from './countries';

export const BASESUM = 1;
export const MID_BLUE_LIGHTER_HEX = '#CCE3F1';
export const MID_BLUE_HEX = '#0070BA';
// TODO: until converted to TS
export const CALCULATOR_IMG_PATH = toAbsoluteUrl(
  '/media/sustell_15/icons/Calculator.svg'
);

/** Util function, to avoid nested ternary operators (eslint requeirement) , currently used in .sort() to return number.. TODO: extend for other types when needed */
export const iff = (
  condition: boolean,
  then: number,
  otherwise: number
): number => (condition ? then : otherwise);

export const useStyles = makeStyles(() => ({
  root: {
    marginRight: 8,
    marginLeft: 8,
    boxShadow: 'none !important',
    '& .MuiStepConnector-alternativeLabel': {
      top: '18px',
      left: 'calc(-50% + 20px)',
      right: 'calc(50% + 20px)',
      '& .MuiStepConnector-line': {
        borderColor: '#F0F0F0',
        borderTopWidth: '3px',
      },
    },
    '& .MuiStepConnector-active': {
      '& .MuiStepConnector-line': {
        borderColor: `${MID_BLUE_LIGHTER_HEX} !important`,
      },
    },
    '& .MuiStepConnector-completed': {
      '& .MuiStepConnector-line': {
        borderColor: `${MID_BLUE_HEX} !important`,
      },
    },
    '& .MuiStepIcon-active:not(.Mui-error)': {
      borderRadius: '50%',
      border: `2px solid ${MID_BLUE_LIGHTER_HEX}`,
      fill: MID_BLUE_LIGHTER_HEX,
      '& .MuiStepIcon-text': { fill: MID_BLUE_HEX },
    },
    '& .MuiStepIcon-completed': { fill: MID_BLUE_HEX },
    '& .MuiStepIcon-active.MuiStepIcon-completed': {
      borderRadius: '50%',
      border: `2px solid ${MID_BLUE_HEX}`,
      fill: MID_BLUE_HEX,
      stroke: MID_BLUE_HEX,
      strokeWidth: 2,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '30px !important',
      fontWeight: 700,
      width: '32px',
      height: '32px',
    },
    '& .MuiStepLabel-labelContainer': {
      textAlign: 'center',
    },
    '& .Mui-error': {
      '& .MuiStepLabel-iconContainer': {
        background: '#E51F22',
        '& dsm-icon': {
          background: '#FFFFFF',
          color: '#FFFFFF',
        },
      },
    },
  },
  stepLabel: {
    '& .MuiStepLabel-iconContainer': {
      padding: '10px !important',
      background: MID_BLUE_LIGHTER_HEX,
      borderRadius: '50%',
      width: '32px',
      height: '32px',
    },
  },
  stepLabelCompleted: {
    '& .MuiStepLabel-iconContainer': {
      padding: '5px !important',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      background: MID_BLUE_HEX,
    },
  },
  stepIcon: {
    color: MID_BLUE_HEX,
    background: MID_BLUE_HEX,
    borderRadius: '50%',
  },
}));

export const emptyFormDefaults: FeedFormFields = {
  id: '',
  name: '',
  year: {
    value: new Date().getFullYear(),
  },
  feedmill_location: {
    iso_code: '',
  },
  target_species: [],
  outbound_transport: [],
  ingredient_uses: [
    {
      id: '',
      ingredient_id: '',
      name: '',
      inbound_transport: [],
    },
  ],
  quantitySum: 0,
};

export const WizardStepsLanguageKeys = [
  'COMPOUNDFEED_WIZARD.STEP.FEED_INFO',
  'COMPOUNDFEED_WIZARD.STEP.INGREDIENTS',
  'COMPOUNDFEED_WIZARD.STEP.TRANSPORTATION',
  'COMPOUNDFEED_WIZARD.STEP.ON_SITE_RESOURCES',
  'COMPOUNDFEED_WIZARD.STEP.NUTRITION',
];

export const enum StepStatus {
  Valid = 'VALID',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export const StyledTab = styled(Tab)({
  fontWeight: 600,
  fontSize: 14,
  textTransform: 'none',
});

// used for custom ingerient to create its ID by concatenating ingredient category ID, this separator and the cusstom ingredient name
export const CUSTOM_INGREDIENT_ID_SEPARATOR = '##';
// used to mark addons unique ids, since some single ingredient have same exact name as addons
export const ADDON_INGREDIENT_SEPARATOR = '%%%';

// Uaed for mapping V1 feeds
export const remapInputToArrayOfTypeValueObj = (
  typeOfEntry: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userUOM: any,
  inputObj?: OnSiteActivitiesInput | OutboundTransportInput
): Array<InputMaybe<TypeValueFloatInput>> | [] => {
  const resultArray: Array<InputMaybe<TypeValueFloatInput>> = [];

  if (!inputObj) {
    return [];
  }

  Object.keys(inputObj || {})?.forEach(
    (prop: OnSiteActivitiesKey | OutboundTransportKey) => {
      const key = prop as keyof typeof inputObj;
      if (inputObj[key]) {
        if (typeOfEntry === 'on_site_activities') {
          const type = prop.replace(/_/g, ' ').replace(' use', '');
          const mappedUnit = convertOnSiteActivityUnits(
            inputObj[key],
            type,
            userUOM,
            1
          ) as { value: number; unit: string };
          const { value } = mappedUnit;
          let { unit } = mappedUnit;
          const item: InputMaybe<TypeValueFloatInput> = { type, value };
          if (item.type === 'gas' || item.type === 'heat') {
            unit = unit.replace('/', ' LHV/');
          }
          item.unit = `${unit} feed`; // not sure why we add feed at the unit
          resultArray.push(item);
        } else if (
          typeOfEntry === 'outbound_transport' ||
          typeOfEntry === 'inbound_transport'
        ) {
          let type = prop.replace(/_/g, ' ');
          const { value, unit } = convertDistanceUnits(
            inputObj[key],
            type,
            userUOM,
            null,
            1
          ) as { value: number; unit: string };
          if (type === 'seaship' || type === 'sea shipping') type = 'sea ship';
          if (type === 'inland waterways ship') type = 'inland ship';
          const item: InputMaybe<TypeValueFloatInput> = { type, value };
          if (unit) {
            item.unit = unit;
          }
          resultArray.push(item);
        }
      }
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return resultArray;
};

// Uaed for mapping V1 feeds
export const mapOnSiteActivitiesInput = (inputObj?: any) => {
  if (
    inputObj.selfGeneratedRenewables &&
    inputObj.selfGeneratedRenewables.length > 0
  ) {
    inputObj.selfGeneratedRenewables.forEach(
      (item: { type: string; value: number }) => {
        inputObj = { ...inputObj, [item.type]: Number(item.value) };
      }
    );
    delete inputObj.selfGeneratedRenewables;
  }
  if (inputObj.energyTypes && inputObj.energyTypes.length > 0) {
    inputObj.energyTypes.forEach((item: { type: string; value: number }) => {
      inputObj = { ...inputObj, [item.type]: Number(item.value) };
    });
    delete inputObj.energyTypes;
  }
  return inputObj;
};

export const masterDataV2 = {
  inbound_transport_types: ['truck', 'sea ship', 'train', 'inland ship'],
  on_site_activities_types: [
    {
      type: 'electricity use',
      unit: 'kWh/tonne feed',
    },
    {
      type: 'gas use',
      unit: 'MJ LVH/tonne feed',
    },
    {
      type: 'heat use',
      unit: 'MJ LVH/tonne feed',
    },
    {
      type: 'water use',
      unit: 'm³/tonne feed',
    },
  ],
  outbound_transport_types: ['truck', 'inland ship'],
  target_species: {
    'GFLI 2.0': [
      {
        display_name: 'Broiler',
        value: 'Broiler system',
      },
      {
        display_name: 'Dairy',
        value: 'Dairy system',
      },
      {
        display_name: 'Dairy',
        value: 'DairyV2 system',
      },
      {
        display_name: 'Laying hen',
        value: 'Layer system',
      },
      {
        display_name: 'Pig',
        value: 'Pig system',
      },
      {
        display_name: 'Marine fish',
        value: 'Marine fish system',
      },
      {
        display_name: 'Turkey',
        value: 'Turkey system',
      },
      {
        display_name: 'Shrimp',
        value: 'Shrimp system',
      },
      {
        display_name: 'Beef',
        value: 'Beef system',
      },
    ],
    'AFP 6.3': [
      {
        display_name: 'Broiler',
        value: 'Broiler system',
      },
      {
        display_name: 'Dairy',
        value: 'Dairy system',
      },
      {
        display_name: 'Dairy',
        value: 'DairyV2 system',
      },
      {
        display_name: 'Laying hen',
        value: 'Layer system',
      },
      {
        display_name: 'Pig',
        value: 'Pig system',
      },
      {
        display_name: 'Marine fish',
        value: 'Marine fish system',
      },
      {
        display_name: 'Turkey',
        value: 'Turkey system',
      },
      {
        display_name: 'Shrimp',
        value: 'Shrimp system',
      },
      {
        display_name: 'Beef',
        value: 'Beef system',
      },
    ],
  },
};

export const isPoultrySystem = (targetSpecie: string) => {
  return (
    targetSpecie === 'Broiler system' ||
    targetSpecie === 'Layer system' ||
    targetSpecie === 'Turkey system'
  );
};

export const totalSteps = (targetSpecie: string) =>
  // only pig, poultry, shrimp and beef have nutritional data steps
  targetSpecie !== 'Pig system' 
  && !isPoultrySystem(targetSpecie)
  && targetSpecie !== 'Beef system'
  && targetSpecie !== 'Shrimp system'
  && targetSpecie !== 'DairyV2 system'
    ? WizardStepsLanguageKeys.length - 1
    : WizardStepsLanguageKeys.length;

export const getFeedVersion = (
  databaseFoundation?: CFDatabaseFoundation | null
) => {
  if (databaseFoundation)
    return lookupValues.databaseFoundationsForFeedsV2.includes(
      databaseFoundation
    )
      ? 2
      : 1;
  return 1;
};

export const isFeedV2CFDatabaseFoundation = (
  databaseFoundation?: CFDatabaseFoundation | null
) =>
  databaseFoundation
    ? lookupValues.databaseFoundationsForFeedsV2.includes(databaseFoundation)
    : false;

export const isFeedV2Database = (database?: CompoundFeedDatabase | null) =>
  database ? lookupValues.databasesForFeedsV2.includes(database) : false;

export const getDatabaseOptions = () =>
  Object.entries(CFDatabaseFoundation)
    .filter((i) => i[1] !== CFDatabaseFoundation.AFP_5)
    .map(([key, value]) => ({ text: value, value: key }));

export const prepareIngredientsV2 = (
  ingredientsV2: CompoundFeedIngredientName[],
  addons: CompoundFeedAddonsV2[]
) => {
  // console.log('enter prepareIngredientsV2', ingredientsV2);
  let retVal: IngredientUses[] = ingredientsV2.map((ing) => ({
    ingredient_id: '',
    id: ing.name,
    name: ing.name,
  }));
  addons.forEach((addonGroup) => {
    addonGroup.ingredients.forEach((addon) => {
      if (
        retVal.findIndex(
          (ing) =>
            ing.name ===
            `${addon.name}${ADDON_INGREDIENT_SEPARATOR}${addonGroup.addonType}`
        ) > -1
      ) {
        return;
      }
      retVal.push({
        ingredient_id: '',
        id: `${addon.name}${ADDON_INGREDIENT_SEPARATOR}${addonGroup.addonType}`,
        name: `${addon.name}${ADDON_INGREDIENT_SEPARATOR}${addonGroup.addonType}`,
        addonType: addonGroup.addonType,
      });
    });
  });

  retVal = retVal.sort((a, b) => ((a.name || '') < (b.name || '') ? -1 : 1));
  retVal.push({
    ingredient_id: 'Add your own ingredient',
    id: 'Add your own ingredient',
    name: '\u2A01 Add your own ingredient',
  });
  return retVal;
};

export const getAddonGroup = (
  ingredientId: string | null,
  databaseFoundation: CFDatabaseFoundation | undefined,
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2 | undefined
): CompoundFeedAddonsV2 | undefined => {
  if (
    !databaseFoundation ||
    !isFeedV2CFDatabaseFoundation(databaseFoundation) ||
    ingredientId === null ||
    !ingredientOriginsNamesV2
  ) {
    return undefined;
  }
  let retVal;
  ingredientOriginsNamesV2?.addons.every((addonGroup) => {
    if (addonGroup.ingredients.findIndex((a) => a.name === ingredientId) > -1) {
      retVal = addonGroup;
      return false;
    }
    return true;
  });
  return retVal;
};

export const getIngredientLabel = (
  ingredientId: string,
  intl: IntlShape,
  databaseFoundation: CFDatabaseFoundation | undefined,
  ingredientOriginCombinations: IngredientUses[] | undefined,
  ingredientOriginCombinationsCategories: IngredientUses[] | undefined
) => {
  if (isFeedV2CFDatabaseFoundation(databaseFoundation)) {
    if (ingredientId === 'Add your own ingredient') {
      return '\u2A01 Add your own ingredient';
    }
    if (ingredientId.indexOf(ADDON_INGREDIENT_SEPARATOR) !== -1) {
      return `${ingredientId.replace(
        ADDON_INGREDIENT_SEPARATOR,
        ` - ${intl.formatMessage({ id: 'GENERAL.ADDON' })} (`
      )})`;
    }
    if (ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) === -1) {
      return ingredientId;
    }
    const splitCustom = ingredientId.split(CUSTOM_INGREDIENT_ID_SEPARATOR);
    return `${splitCustom[1]} (${splitCustom[0]})`;
  }

  let categoryName = '';
  // check is selected custom feed
  if (ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1) {
    // find category name
    const categoryId = ingredientId.substring(
      0,
      ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR)
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    categoryName =
      ingredientOriginCombinationsCategories?.find(
        (ingredient: IngredientUses) => ingredient.id === categoryId
      )?.name || '';
    if (categoryName) categoryName = ` (${categoryName})`;
  }
  const selectedIngredientName =
    ingredientOriginCombinations?.find(
      (ingredient: IngredientUses) => ingredient.id === ingredientId
    )?.name || '';
  // return 'ingredient name' in regular ingredient or 'ingredient name (category name)' if custom ingredient
  return `${selectedIngredientName}${categoryName}`;
};

export const joinCustomerIngredeintsAddonsWithDefault = (
  customerAddons: CompoundFeedAddonsV2[] | null | undefined
): CompoundFeedAddonsV2[] => {
  // Make a deep copy
  const allAddons = JSON.parse(
    JSON.stringify(lookupValues.defaultIngredientsAddons)
  ) as CompoundFeedAddonsV2[];

  // add customer addons to allAddons
  customerAddons?.forEach((item) => {
    const group = allAddons.find((addon) => addon.addonType === item.addonType);
    // if addon group exists
    if (group) {
      // skip duplicated in case that default is allready added to the customer's list before
      item.ingredients?.forEach((customerIngredient) => {
        if (
          !group?.ingredients.find(
            (existing) =>
              existing.name === customerIngredient.name &&
              existing.origin === customerIngredient.origin
          )
        )
          group.ingredients.push(customerIngredient);
      });
    }
    // no group, create new item
    else
      allAddons.push({
        addonType: item.addonType,
        ingredients: [...item.ingredients],
      });
  });

  return allAddons;
};

export const getCountryLabel = (
  countryCode: string,
  databaseFoundation: CFDatabaseFoundation | undefined,
  intl: IntlShape,
) => {
  if (!countryCode) return '';
  if (isFeedV2CFDatabaseFoundation(databaseFoundation)) {
    const translationId = `SUSTELL.GEOGRAPHY.${countryCode.toUpperCase()}`;
    return intl.formatMessage({id: translationId}) || '';
  }
  return (
    countryCodes.find((country) => country.value === countryCode)
      ?.display_name || ''
  );
};

export const getSortedCountryCodes = () =>
  countryCodes?.sort((a, b) => {
    // unknwon last
    if (a.value === 'NaN') return 1;
    if (b.value === 'NaN') return -1;
    return a.display_name?.toLocaleLowerCase() <
      b.display_name?.toLocaleLowerCase()
      ? -1
      : iff(
          a.display_name?.toLocaleLowerCase() >
            b.display_name?.toLocaleLowerCase(),
          1,
          0
        );
  });
