import React from "react";

import { NotificationForm } from "./NotificationForm";
import {
  Box,
  Button,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { UserProfilePrefs } from '../Helpers/UserProfilePrefs' 
// import {useIntl} from 'react-intl';
import { useIntl } from "../../../_metronic/i18n/customUseIntl";
import {dateDisplayOptionsYMDHM} from '../../sustell_15/utils/datetime-utils';
import { ctaClick, formStart } from '../../sustell_15/analytics/adobeDataLayer';

const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 24,
  },
  table: {
    minWidth: 650,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 12,
  },
});


export const ConfirmationCategories = ["Update", "Instruction"];


export function NotificationData(props) {
  const intl = useIntl();
  const classes = useStyles();
  // const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" }; 

  const [activeView, setActiveView] = React.useState("list");
  const [selectedNotification, setSelectedNotification] = React.useState(null);
  const [page, setPage] = React.useState(props.activePage?props.activePage:0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onNewNotificationBtnClick = () => {
    ctaClick(window.location.href, 'button', 'New Notification', 'Admin', 'Notifications');
    setSelectedNotification(null);
    setActiveView("new");
    formStart('Add Notification', 'new');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseNotification = (returnedValue) => {
    if(returnedValue){
      if(returnedValue.updateNotification){
        // retuned data is updated value of existing notification
        let returnedData = returnedValue.updateNotification;
        let changedItem = props.data?.find( item => item.id === returnedValue.updateNotification.id);
        changedItem.id = returnedData.id;
        changedItem.title = returnedData.title;
        changedItem.message = returnedData.message;
        changedItem.active = returnedData.active;
        changedItem.isWelcome = returnedData.isWelcome;
        changedItem.publishAt = new Date(returnedData.startDateTime);
        changedItem.expiresAt = returnedData.expireDateTime ? new Date(returnedData.expireDateTime) : undefined;
        changedItem.category = returnedData.notificationType;
        changedItem.groups = returnedData.targetGroups;
        changedItem.attachementKeys = returnedData.attachementKeys;
        setSelectedNotification(changedItem);
      }else if(returnedValue.createNotification){
        let returnedData = returnedValue.createNotification;
        returnedData.publishAt = new Date(returnedData.startDateTime);
        returnedData.expiresAt = returnedData.expireDateTime ? new Date(returnedData.expireDateTime) : undefined;
        returnedData.category = returnedData.notificationType;
        returnedData.groups = returnedData.targetGroups;
        props.onItemAdded(returnedData, rowsPerPage);
        delete returnedData.startDateTime;
        delete returnedData.expireDateTime;
        delete returnedData.notificationType;
        delete returnedData.targetGroups;
        setSelectedNotification(returnedData);
      }
      //this may already affect this user notification list
      UserProfilePrefs.getInstance().reloadNotifications();
      setActiveView('list')
    }
  }

  const handleRowClicked = (notification) => {
    ctaClick(window.location.href, 'link', 'Edit Notification', 'Admin', 'Notifications');
    setSelectedNotification(notification);
    setActiveView('edit');
    formStart('Edit Notification', 'edit');
  }

  return (
    <>
      {activeView === 'list' && <Card className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="h5"
            id="tableTitle"
            color="secondary"
            component="div"
          >
            { intl.formatMessage({id:"NOTIFICATIONS" })}
          </Typography>
          <Button variant="outlined" onClick={onNewNotificationBtnClick}>{ intl.formatMessage({id:"NOTIFICATIONS.NEW_NOTIFICATION" })}</Button>
        </Box>

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{ intl.formatMessage({id:"NOTIFICATIONS.TITLE" })}</TableCell>
                <TableCell align="right">{ intl.formatMessage({id:"NOTIFICATIONS.PUBLISH_TIME" })}</TableCell>
                <TableCell align="right">{ intl.formatMessage({id:"NOTIFICATIONS.EXPIRATION_TIME" })}</TableCell>
                <TableCell align="right">{ intl.formatMessage({id:"NOTIFICATIONS.WELCOME_MESSAGE" })}</TableCell>
                <TableCell align="right">{ intl.formatMessage({id:"NOTIFICATIONS.ACTIVE" })}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(props.data || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.id + row.publishAt}
                  hover={true}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClicked(row)}
                  selected = {selectedNotification === row}
                >
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="right">{row.publishAt ? intl.formatDate(row.publishAt, dateDisplayOptionsYMDHM) : ''}</TableCell>
                  <TableCell align="right">{row.expiresAt ? intl.formatDate(row.expiresAt, dateDisplayOptionsYMDHM) : ''}</TableCell>
                  <TableCell align="right">
                    {row.isWelcome ? <CheckIcon /> : <CloseIcon /> }
                  </TableCell>
                  <TableCell align="right">
                    {row.active ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          labelRowsPerPage={ intl.formatMessage({id:"GENERAL.ROWS_PER_PAGE"})}
          component="div"
          count={(props.data || []).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>}
      {(activeView === 'new' || activeView==='edit') &&
        <NotificationForm onClose={handleCloseNotification} notification={selectedNotification}/>
      }
    </>
  );
}
