import { Dispatch, FC, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useFormContext, useWatch } from "react-hook-form";
import {
  DsmButton,
  DsmFieldset,
  DsmIcon,
  DsmModal,
} from "@dsm-dcs/design-system-react";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { translateKeyMapping } from "../../utils/string-utils";

import { FormType, FeedMillLocation, TargetSpeciesEntry } from "./types";
import { iff } from "./utils";
import ReactHookDsmInput from "../../../modules/Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../../modules/Helpers/ReactHookDsmSelect2";
import ControlledAutocompleteDsm2 from "../../../modules/Helpers/ControlledAutocompleteDsm2";
import ReactHookDsmMultiSelect from "../../../modules/Helpers/ReactHookDsmMultiSelect2";
import lookupValues from "../../helpers/lookupValues";
import { CFDatabaseFoundation } from "../../models/CompoundFeed";
import { DsmButtonV2, useButtonStyles } from "../helpers/DsmButton";
import { CompoundFeedDatabase } from "../../../../graphql/types";
import IngredientDescriptionDialog from "./IngredientsDescriptionDialog";
import { UserProfilePrefs } from "../../../modules/Helpers/UserProfilePrefs";
import featureFlags from "../../utils/featureFlags";
import { emptyItem as emptyIngredient } from "./Ingredients/FeedIngredients";

interface CompoundFeedBaseDataProps {
  formType: FormType;
  feedmill_locations: [FeedMillLocation];
  target_species: TargetSpeciesEntry[];
  setDatabaseFoundation: Dispatch<CFDatabaseFoundation>;
  databaseFoundation: CFDatabaseFoundation | undefined;
  customerName: string | undefined;
  activeStep: number | undefined;
  isUpdateMode: boolean;
  copyV1toV2: CompoundFeedDatabase | undefined;
  copyV2: boolean;
}

const CompoundFeedBaseData: FC<CompoundFeedBaseDataProps> = ({
  formType,
  feedmill_locations,
  target_species,
  setDatabaseFoundation,
  databaseFoundation,
  customerName,
  activeStep,
  isUpdateMode,
  copyV1toV2,
  copyV2,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const fc = useFormContext();
  const currYear = new Date().getFullYear();
  const buttonClasses = useButtonStyles();
  const userProfile = UserProfilePrefs.getInstance();
  const [originalTargetSpecies, setOriginalTargetSpecies] = useState("");
  const [originalDatabaseFoundation, setOriginalDatabaseFoundation] =
    useState("");
  const [targetDatabaseFoundation, setTargetDatabaseFoundation] = useState("");
  useWatch({ name: "databaseFoundation", control: fc.control });
  useWatch({ name: "target_species", control: fc.control });
  const feedmillLocation = useWatch({
    name: "feedmill_location",
    control: fc.control,
  });
  const selectedSpecies = fc.getValues("target_species") as string[];

  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);

  const getOptionLabel = (option: string): string => {
    const translationKey = `COMPOUND_FEED.${translateKeyMapping(option)}`;
    return intl.formatMessage({ id: translationKey });
  };

  const closeDatabaseSelectionChangedDialog = () => {
    fc.setValue("target_species", [originalTargetSpecies]); // Return the previous selected target specie
    fc.setValue("databaseFoundation", originalDatabaseFoundation); // Return the previous selected DB
    setTargetDatabaseFoundation("");
  };

  const confirmDatabaseChanging = () => {
    setOriginalDatabaseFoundation(targetDatabaseFoundation); // Adjust new db
    fc.setValue("databaseFoundation", targetDatabaseFoundation);
    setDatabaseFoundation(targetDatabaseFoundation as CFDatabaseFoundation);
    setTargetDatabaseFoundation("");
    fc.setValue("ingredient_uses", [{...emptyIngredient}])
  };

  const ingredientsNotEmpty = () => {
    const ingredients = fc
      .getValues()
      .ingredient_uses?.filter((ingredient: any) => ingredient.name);
    return ingredients && ingredients.length > 0;
  };

  const databaseChanged = (currentSelectedDatabase: string) =>
    currentSelectedDatabase !== originalDatabaseFoundation &&
    ingredientsNotEmpty();

  const addForm = () => {
    return formType === FormType.New && !copyV1toV2;
  };

  const shouldWarnBeforeDeletion = (currentSelectedDatabase: string) =>
    addForm() &&
    databaseChanged(currentSelectedDatabase) &&
    ingredientsNotEmpty();

  const handleChangeDatabaseFoundation = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    const option = event.target.value as CFDatabaseFoundation;
    if (option !== databaseFoundation) {
      history.push({
        search: `?step=${activeStep || "0"}&database=${option}`,
        state: { customerName: customerName || "unknown" },
      });
      if (shouldWarnBeforeDeletion(option)) {
        setOriginalTargetSpecies(selectedSpecies[0]); // Remember the current target species selection
        setTargetDatabaseFoundation(option); // Set the target DB foundation to change to after confirmation
      } else {
        setOriginalDatabaseFoundation(option); // Remember the current selection of database foundation
        setDatabaseFoundation(option); // Set to the parent component
        fc.setValue("databaseFoundation", option);
      }
    }
  };

  const handleChangeTargetSpecies = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string[] | undefined;
    }>
  ) => {
    event.stopPropagation();

    // Leave multiselect for existing feeds with multiple species, but allow only single selection when modifying
    // species, last selected is always last in values array
    const selectedSpecies = [event.target.value?.at(-1)];
    if (selectedSpecies[0]) {
      setOriginalTargetSpecies(selectedSpecies[0]); // Remember the target species selection
      fc.setValue("target_species", selectedSpecies);

      // Check if it has default DB, and if it has, select it
      let supportedDB: string | undefined;
      if (lookupValues.oldSystems.includes(selectedSpecies[0]))
        supportedDB =
          lookupValues.defaultDatabaseFoundationPerSpecie[
            selectedSpecies[0] as keyof typeof lookupValues.defaultDatabaseFoundationPerSpecie
          ];
      else
        supportedDB =
          CFDatabaseFoundation[
            userProfile.getUserDatabasePrefs().databaseType as
              | "AFP_6_3"
              | "GFLI"
          ];
      if (copyV1toV2 || formType === FormType.Edit) {
        // If edit or copy, then do not change the initially selected db
        supportedDB = fc.getValues("databaseFoundation");
      }
      // If no default db, reset value
      if (supportedDB) {
        if (shouldWarnBeforeDeletion(supportedDB)) {
          setTargetDatabaseFoundation(supportedDB); // Set the target DB foundation to change to after confirmation
        } else {
          setOriginalDatabaseFoundation(supportedDB);
          setDatabaseFoundation(supportedDB as CFDatabaseFoundation); // Set to the parent component
          fc.setValue("databaseFoundation", supportedDB);
        }
      } else {
        fc.setValue("databaseFoundation", ""); // If not deafault database
      }
    }
  };

  const databaseSelectionDisabled = () =>
    (formType === FormType.View || isUpdateMode || !selectedSpecies?.length) &&
    !copyV2;

  const getDatabaseOptions = () => {
    // if more then one selected, then just AFP supported
    if (selectedSpecies?.length > 1) {
      return [
        {
          value: CFDatabaseFoundation.AFP_5,
          text: CFDatabaseFoundation.AFP_5,
        },
      ];
    }
    // if just one, get available DBs for that type
    if (selectedSpecies?.length && selectedSpecies?.length === 1) {
      const dbs =
        lookupValues.databaseFoundationsPerSpecie[
          selectedSpecies[0] as keyof typeof lookupValues.databaseFoundationsPerSpecie
        ];

      const databaseOptions = dbs.map((value) => ({
        value,
        text: value,
      }));

      const selectedDb = fc.getValues("databaseFoundation") as string;
      if (
        databaseFoundation === CFDatabaseFoundation.AFP_5 &&
        !dbs.some((db) => db === selectedDb)
      ) {
        // If the compound feed was created using AFP 5 and databaseFoundationsPerSpecie doesn't support it anymore
        // then it's necessary to add AFP 5 into the available options to maintain support for view and edition
        databaseOptions.push({
          value: databaseFoundation,
          text: databaseFoundation,
        });
      }

      return databaseOptions;
    }

    // nothing seelcted, retur all options
    return Object.values(CFDatabaseFoundation).map((value) => ({
      value,
      text: value,
    }));
  };

  const showDescription = () => {
    setOpenDescriptionDialog(true);
  };

  const closeDescriptionDialog = () => {
    setOpenDescriptionDialog(false);
  };

  const shouldReplaceLegacyDairySystem = (animalSystem: string) => {
    if (featureFlags.dairyV2) {
      return animalSystem !== "Dairy system";
    }

    return animalSystem !== "DairyV2 system";
  };

  const getTargetSpeciesOptions = () =>
    target_species
      ?.filter((t) => !t.value.startsWith("All animal"))
      ?.filter((t) => shouldReplaceLegacyDairySystem(t.value))
      ?.map((item) => ({
        value: item.value,
        text: getOptionLabel(item.value),
      }))
      .sort((a, b) => {
        if ((a.text || "")?.toLowerCase() < (b.text || "")?.toLowerCase())
          return -1;
        else
          return iff(
            (a.text || "")?.toLowerCase() > (b.text || "")?.toLowerCase(),
            1,
            0
          );
      });

  const yearsOptions = Array.from({ length: currYear - 2014 }, (_, i) => ({
    value: `${currYear - i}`,
    text: `${currYear - i}`,
  }));

  const getCountryOptionsLabels = (isoCode: string) => {
    if (!isoCode || typeof isoCode !== "string") return "";
    const translationId = `SUSTELL.GEOGRAPHY.${isoCode.toUpperCase()}`;
    return intl.formatMessage({ id: translationId });
  };

  useEffect(() => {
    if (copyV2 && databaseFoundation) {
      setOriginalDatabaseFoundation(databaseFoundation);
    }
  }, [copyV2, databaseFoundation]);

  const targetSpeciesOptions = getTargetSpeciesOptions();

  return (
    <>
      {openDescriptionDialog && (
        <IngredientDescriptionDialog
          open
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      {targetDatabaseFoundation && (
        <DsmModal
          open={!!targetDatabaseFoundation}
          icon="general/info-square"
          header={intl.formatMessage({
            id: "SUSTELL.STAGE.FEED.DATABASE_SELETION_WARNING_HEADER",
          })}
          onDsmClosed={() => closeDatabaseSelectionChangedDialog()}
        >
          <p slot="content">
            {intl.formatMessage({
              id: "SUSTELL.STAGE.FEED.DATABASE_SELETION_WARNING_BODY",
            })}
          </p>
          <>
            <DsmButtonV2
              slot="actions"
              onClick={() => closeDatabaseSelectionChangedDialog()}
              className={buttonClasses.secondaryButton}
            >
              {intl.formatMessage({ id: "GENERAL.CANCEL" })}
            </DsmButtonV2>
            <DsmButtonV2
              variant="secondary"
              slot="actions"
              onClick={() => confirmDatabaseChanging()}
            >
              {intl.formatMessage({ id: "GENERAL.CONFIRM" })}
            </DsmButtonV2>
          </>
        </DsmModal>
      )}
      <DsmFieldset
        style={{ width: "70%", marginTop: `var(--dsm-spacing-px-4)` }}
      >
        <ReactHookDsmInput
          InputProps={{ width: "384px" }}
          name="name"
          label={intl.formatMessage({
            id: "COMPOUND_FEED.NAME",
          })}
          placeholder={intl.formatMessage({
            id: "COMPOUND_FEED.NAME",
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({ id: "COMPOUND_FEED.NAME.TOOLTIP" })}
          required
        />
        <ReactHookDsmSelect
          name="year.value"
          label={`${intl.formatMessage({
            id: "GENERAL.YEAR",
          })}`}
          disabled={formType === FormType.View}
          tooltip={`${intl.formatMessage({
            id: "COMPOUND_FEED.YEAR.TOOLTIP",
          })} *`}
          placeholder={intl.formatMessage({ id: "GENERAL.YEAR" })}
          options={yearsOptions}
          required
        />
      </DsmFieldset>
      <DsmFieldset
        style={{ width: "70%", marginTop: `var(--dsm-spacing-px-4)` }}
      >
        <ReactHookDsmMultiSelect
          name="target_species"
          label={`${intl.formatMessage({
            id: "COMPOUND_FEED.TARGET_SPECIES",
          })}`}
          disabled={
            formType === FormType.View ||
            (copyV2 && !copyV1toV2) ||
            targetSpeciesOptions.length === 0
          }
          placeholder={intl.formatMessage({
            id: "COMPOUND_FEED.TARGET_SPECIES",
          })}
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.TARGET_SPECIES.TOOLTIP",
          })}
          options={targetSpeciesOptions}
          changeHandler={handleChangeTargetSpecies}
          required
        />
        <ControlledAutocompleteDsm2
          name="feedmill_location.iso_code"
          multiple={false}
          control={fc.control}
          defaultValue={feedmillLocation}
          required
          options={
            feedmill_locations
              ?.sort((a, b) =>
                a.name < b.name ? -1 : iff(a.name > b.name, 1, 0)
              )
              .map(({ iso_code }) => iso_code) || []
          }
          placeholder={intl.formatMessage({
            id: "COMPOUND_FEED.FEEDMILL_LOCATION",
          })}
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.FEEDMILL_LOCATION.TOOLTIP",
          })}
          disabled={formType === FormType.View}
          label={`${intl.formatMessage({
            id: "COMPOUND_FEED.FEEDMILL_LOCATION",
          })}`}
          getOptionLabel={getCountryOptionsLabels}
        />
      </DsmFieldset>
      <DsmFieldset
        style={{ width: "34.6%", marginTop: `var(--dsm-spacing-px-4)` }}
      >
        <ReactHookDsmSelect
          name="databaseFoundation"
          label={`${intl.formatMessage({
            id: "COMPOUND_FEED.DATABASE_SELETION",
          })}`}
          disabled={databaseSelectionDisabled()}
          options={getDatabaseOptions()}
          changeHandler={(
            e: React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => handleChangeDatabaseFoundation(e)}
          tooltip={intl.formatMessage({
            id: "COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP",
          })}
          required
        />
      </DsmFieldset>
      <DsmButton variant="text" onClick={showDescription}>
        <DsmIcon slot="before" name="general/eye" />
        {intl.formatMessage({
          id: "DATABASE_FOUNDATION_DEFINITIONS",
        })}
      </DsmButton>
    </>
  );
};

export default CompoundFeedBaseData;
