/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { headerMenusStyles } from '../../../../_metronic/layout';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import useAdobeDataLayer from '../../../sustell_15/analytics/adobeDataLayer';
import { DsmIcon } from '@dsm-dcs/design-system-react';
import { makeStyles } from '@material-ui/styles';

const breadCrumbStyles = makeStyles(() => ({
  breadCrumbMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: '30px',
    marginBottom: '30px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  breadCrumbMenuItem: {
    boxSizing: 'border-box',
    color: '#0070BA !important',
    paddingRight: '10px',
    textDecoration: 'underline',
    '&:hover': {
      color: '#0070BA',
    },
  },
  breadCrumbMenuDivider: {
    color: '#0070BA !important',
    paddingRight: '10px',
  },
}));

interface BreadCrumbMenuProps {
  items: {
    pathname: string;
    title: string;
    state: boolean;
  }[];
}

export const BreadCrumbMenuV2: React.FC<BreadCrumbMenuProps> = ({ items }) => {
  const classes = breadCrumbStyles();
  const intl = useIntl();
  const { ctaClickEvent } = useAdobeDataLayer();

  if (!items || !items.length) {
    return <div className={`container ${classes.breadCrumbMenu}`} />; // ${classes.breadCrumbMenu}
  }

  return (
    <div className={`container ${classes.breadCrumbMenu}`}>
      {/* ${classes.breadCrumbMenu} */}
      <Link className={classes.breadCrumbMenuItem} to="/">
        {/* className={classes.breadCrumbMenuItem} */}
        <DsmIcon
          name="general/home-line"
          style={{
            width: '20px',
            height: '20px',
            color: '#0070BA',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        />
        {/* { intl.formatMessage({"id": "SUSTELL.HOME"}) } */}
      </Link>
      {items.length > 0 && (
        <span className={classes.breadCrumbMenuDivider}>
          <DsmIcon
            name="arrows/chevron-right"
            style={{ width: '20px', height: '20px' }}
          />
        </span>
      )}
      {/* className={classes.breadCrumbMenuDivider} */}
      {items.map((item, index) => (
        <React.Fragment key={`breadcrumb_link_${index}`}>
          {items.length > 0 && index !== items.length - 1 && (
            <React.Fragment>
              <Link
                className={classes.breadCrumbMenuItem}
                onClick={() =>
                  ctaClickEvent(
                    item.pathname,
                    'link',
                    item?.title,
                    'Sub Header',
                    'Sub Header'
                  )
                }
                to={{ pathname: item.pathname, state: item?.state }}
              >
                {item?.title}
              </Link>
              <span className={classes.breadCrumbMenuDivider}>
                <DsmIcon
                  name="arrows/chevron-right"
                  style={{ width: '20px', height: '20px' }}
                />
              </span>
              {/* className={classes.breadCrumbMenuDivider} */}
            </React.Fragment>
          )}
          {items.length > 0 && index === items.length - 1 && (
            <span className={classes.breadCrumbMenuDivider}>{item?.title}</span>
            // className={classes.breadCrumbMenuDivider}
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
