import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import { unitLong } from '../../../sustell_15/utils/unit-utils';

const SustellNewDynamicInput = props => {
  const { subFieldName, label, index, formState, fieldItemPrefix, onChangeSelect: selectHandler, onChangeInput: inputHandler } = props;
  const { control, errors, getValues } = useFormContext();
  const [unit, setUnit] = useState('kg');

  const resolvePath = (object, path, defaultValue) =>
    path
      .split(/[.[\]'"]/)
      .filter(p => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  const ftName = fieldItemPrefix + '.' + subFieldName + '[' + index + '].type';
  const name = fieldItemPrefix + '.' + subFieldName + '[' + index + '].amount';

  const setOtherFieldUnit = value => {
    const selItem = props.availableOptions?.find(x => x.value === value);
    if (selItem) {
      let newUnit =
        selItem.unit ||
        (userUOM?.unitBarnOutputMass
          ? unitLong(userUOM.unitBarnOutputMass)
          : 'kg');
      if (value === 'gross_energy')
        newUnit =
          userUOM?.unitBarnOutputMass && userUOM?.unitNutritionalGrossEnergy
            ? userUOM?.unitNutritionalGrossEnergy +
              '/' + 
              userUOM?.unitBarnOutputMass
            : 'MJ/kg';
      setUnit(newUnit);
    }
  };

  useEffect(() => {
    const selType = getValues(ftName);
    setOtherFieldUnit(selType);
    // only needs to update when options become available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.availableOptions]);

  const localNameError = resolvePath(errors, name, null);
  const localTypeError = resolvePath(errors, ftName, null);
  const itemsLoaded = props.availableOptions?.some(x => x.type !== '');
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const fieldValue = (fieldName) => {
    const value = getValues(fieldName);
    if(value === null || value === undefined) return '';
    return value;
  }

  return (
    <>
      <Grid
        item
        xs={7}
        className="d-flex align-items-center"
        key={
          fieldItemPrefix + '.' + subFieldName + '[' + index + ']_inputPart1'
        }
      >
        <FormControl
          variant="outlined"
          style={{ marginBottom: 0, width: '95%', paddingBottom: 0 }}
        >
          <InputLabel error={Boolean(localTypeError)}>{label}</InputLabel>

          <Controller
            control={control}
            name={ftName}
            defaultValue={fieldValue(ftName)}
            render={({ onChange, onBlur, value, name, ref }) => (
              <>
                <Select
                  fullWidth
                  label={label}
                  name={name}
                  onChange={e => {
                    setOtherFieldUnit(e.target.value);
                    if (selectHandler) selectHandler(e.target.value);
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  value={value && itemsLoaded ? value : ''}
                  disabled={formState === 'view' ? true : false}
                >
                  {props.availableOptions.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.display_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(localTypeError)}>
                  {localTypeError && localTypeError.message
                    ? localTypeError.message
                    : ''}
                </FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={3}
        className="d-flex align-items-center"
        key={
          fieldItemPrefix + '.' + subFieldName + '[' + index + ']_inputPart2'
        }
      >
        <Controller
          name={name}
          defaultValue={fieldValue(name)}
          control={control}
          render={({ onChange, onBlur, value, name, ref }) => {
            return (
              <TextField
                name={name}
                onChange={e => {
                  if (inputHandler) inputHandler(e.target.value)
                  onChange(e)
                }}
                inputRef={ref}
                style={{ marginBottom: '1.2rem' }}
                label={`${unit} *`}
                onBlur={onBlur}
                value={value && itemsLoaded ? value : ''}
                helperText={
                  localNameError && localNameError.message
                    ? localNameError.message
                    : ''
                }
                error={Boolean(localNameError)}
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={formState === 'view' ? true : false}
                type="number"
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
export default SustellNewDynamicInput;
