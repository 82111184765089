import { FC, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import {
  BaselineDialogProps,
  FormType,
  LayingHensEmmisions,
  LayingHensForm,
} from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';

const LHEmissionsFormDialog: FC<BaselineDialogProps> = ({
  formVisible,
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
}) => {
  const intl = useIntl();

  const currResetValue = useRef<LayingHensEmmisions | null>(null);

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions`;

  const methods = useFormContext<LayingHensForm>();
  const getValues = (key?: string): ReturnType<typeof methods.getValues> =>
    key ? methods.getValues(key) : methods.getValues();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...getValues(fieldItemPrefix),
      } as LayingHensEmmisions; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() };
      if (resetObject.stages[itemIndex]?.stageData?.emissions) {
        resetObject.stages[itemIndex].stageData.emissions = {
          ...currResetValue.current,
        };
        methods.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const welcomeString = intl.formatMessage({ id: 'EMISSIONS.WELCOME' });

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="emissionIcon"
      formTitle={formTitle}
      introText={intl.formatMessage(
        { id: 'EMISSIONS.INTRO' },
        {
          boldedWelcome: (
            <strong key="DialogContainer-emmissions-intro-key">
              {welcomeString}
            </strong>
          ),
          br: <br />,
        }
      )}
    >
      <Grid container spacing={3} direction="row" justifyContent="space-between">
        <Grid item container direction="column" xs={6} spacing={3}>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.methane`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.METHANE',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.EMISSIONS.METHANE.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.methane`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.nitrousOxideDirect`}
              label={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT" }) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP" }) }
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.nitrousOxideDirect`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.nitrousOxideIndirect`}
              label={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT" })}
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP" })}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(
                `${fieldItemPrefix}.nitrousOxideIndirect`
              )}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.amonia`}
              label={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.AMMONIA" })}
	            margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.AMMONIA.TOOLTIP" })}
	            addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.amonia`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.nitricOxide`}
              label={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.NOx_MANURE"})}
	            margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.NOx_MANURE.TOOLTIP"})}
	            addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.nitricOxide`)}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={3}>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.nonMethaneVolatile`}
              label={ intl.formatMessage({id:"BASELINE.FORM.EMISSIONS.NON_METHANE_ORGANIC"}) }
	            margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id:"BASELINE.FORM.EMISSIONS.NON_METHANE_ORGANIC.TOOLTIP" }) }
	            addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.nonMethaneVolatile`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.PM10`}
              label={ intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10"}) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10.TOOLTIP"}) }
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.PM10`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.PM25`}
              label={ intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5"})}
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.PM2_5.TOOLTIP" })}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(`${fieldItemPrefix}.PM25`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              control={methods.control}
              name={`${fieldItemPrefix}.totalSuspendedParticles`}
              label={ intl.formatMessage({id:"BASELINE.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES"})}
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({ id: "BASELINE.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES.TOOLTIP"}) }
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={getValues(
                `${fieldItemPrefix}.totalSuspendedParticles`
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default LHEmissionsFormDialog;
