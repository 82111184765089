/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DsmModal } from '@dsm-dcs/design-system-react';
import React, { type FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FeedUsageData, Maybe } from '../../../../graphql/types';
import { DsmButtonV2 } from '../helpers/DsmButton';

interface DeleteCompoundFeedDialogProps {
  openDialog: boolean;
  header: string;
  description: string;
  customerId: string;
  feedUsageItems: Maybe<FeedUsageData>[] | null;
  handleOk: () => void;
}

const DeleteCompoundFeedDialog: FC<DeleteCompoundFeedDialogProps> = React.memo(({
  openDialog,
  header,
  description,
  customerId,
  feedUsageItems,
  handleOk,
}) => {
  const intl = useIntl();
  return (
    <DsmModal
      open={openDialog}
      header={header}
      icon="general/info-square"
      centred
    >
      <p slot="content">{description}</p>
      {feedUsageItems && feedUsageItems.length &&
        feedUsageItems.map(
          (item) =>
            item && (
              <React.Fragment key={`${item.type ?? ''}-${item.reference}`}>
                <strong>{item.name}</strong> Ref. id:
                {item.type === 'baseline' && (
                  <Link
                    to={{
                      pathname: `/farms/${item.farmId}/dataset`,
                      search: `?${new URLSearchParams({
                        customerId,
                        formState: 'view',
                        oldName: item.name,
                      })}`,
                    }}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {item.reference}
                  </Link>
                )}
                {item.type === 'intervention' && (
                  <Link
                    to={{
                      pathname: `/farms/${item.farmId}/intervention`,
                      search: `?${new URLSearchParams({
                        customerId,
                        formState: 'view',
                        oldName: item.name,
                      })}`,
                    }}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {item.reference}
                  </Link>
                )}{' '}
                &nbsp; (<em>{item.type}</em>) <br />
              </React.Fragment>
            )
        )}
      <DsmButtonV2 slot="actions" onClick={handleOk}>
        {intl.formatMessage({
          id: 'GENERAL.OK',
        })}
      </DsmButtonV2>
    </DsmModal>
  );
});

export default DeleteCompoundFeedDialog;
