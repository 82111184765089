import { DsmLoadingIndicator } from '@dsm-dcs/design-system-react';
import { TableRow, TableCell } from '@material-ui/core';
import { type FC } from 'react';

interface TableLoadingSkeletonProps {
  description: string;
}

const TableLoadingSkeleton: FC<TableLoadingSkeletonProps> = ({
  description,
}) => (
  <TableRow>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell>
      <DsmLoadingIndicator size="md" description={description} />
    </TableCell>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
  </TableRow>
);

export default TableLoadingSkeleton;
