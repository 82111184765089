import { mapResourceUse } from "./inCommonMapperSustellV2";
import {
  BeefBaselineDBModel,
  BeefBreedingStageDataDBModel,
  BeefGrowingStageDataDBModel,
  BeefStageDBModel,
  BreedingOutputDBModel,
} from "../../../../sustell_15/models/TempBackendModels/TempBeefDB";
import {
  BeefBreedingStageData,
  BeefGrowingStageData,
  BeefStage,
  BeefOutput,
  BeefOutputCalves,
  BeefBaseline,
} from "../../../../sustell_15/models/Baseline/BeefBaseline";
import {
  ResourceUse,
} from "../../../../sustell_15/models/Baseline/Baseline";
import {
  AnimalType,
  ReportingPeriod,
  StageType,
} from "../../../../../graphql/types";
import {
  formatInMassInput,
} from "./inMapperHelpers";
import { mapInProcessingStage } from "./inProcessingMapperSustell";
import { ProcessingStageDBModel } from "../../../../sustell_15/models/TempBackendModels/TempProcessingDB";
import { ProcessingStage } from "../../../../sustell_15/models/Facility/Processing";
import {
  CattleBeddingDBModel,
  EmissionMitigationDBModel,
} from "../../../../sustell_15/models/TempBackendModels/TempCattleDB";
import {
  CattleEmission,
} from "../../../../sustell_15/models/Baseline/CattleBaseline";
import { mapCattleAnimalSources, mapCattleBeddingSystems, mapCattleManureSystems, mapCattleTimeSpent, mapFeedingProgram } from "./Helpers/inCattleMapperHelpers";


function mapCommonOutputCows(incomingData: BreedingOutputDBModel): BeefOutput {
  return {
    numberOfMortalitiesCows: incomingData?.mortalities,
    averageWeightAtMortalityCows: formatInMassInput(
      incomingData?.weightAtMortality
    ),
    averageWeightOfCowsLeavingTheStage: formatInMassInput(
      incomingData?.averageWeight
    ),
    cowsSold: incomingData?.soldExternally,
    cowsToAnotherStage: incomingData?.animalsOutgoing,
  };
}

function mapOutputCalves(
  incomingData: BreedingOutputDBModel
): BeefOutputCalves {
  return {
    numberOfMortalitiesCalves: incomingData?.mortalities,
    weanedCalvesSold: incomingData?.soldExternally,
    weanedCalvesToAnotherStage: incomingData?.animalsOutgoing,
    averageWeightAtMortalityCalves: formatInMassInput(
      incomingData?.weightAtMortality
    ),
    averageWeightOfCalvesLeavingTheStage: formatInMassInput(
      incomingData?.averageWeight
    ),
  };
}

function mapEmissionsAndMitigations(
  incomingData?: Array<EmissionMitigationDBModel>
): CattleEmission {
  if (incomingData && incomingData.length) {
    return incomingData.reduce(
      (mappedData, emission) =>
        ({
          ...mappedData,
          [emission.emissionType]: emission.change,
        } as CattleEmission),
      {}
    );
  }
  return {};
}

type StageInfo = {
  startDate: string;
  endDate?: string;
};

function mapBreedingStageData(
  incomingData: BeefBreedingStageDataDBModel,
  stageInfo: StageInfo
): BeefBreedingStageData {
  // Bedding systems are the same for cows and calves, therefore only one of them is required to map data.
  const beddingSystems =
    incomingData.beddingCalves as Array<CattleBeddingDBModel>;
  return {
    feed: {
      calves: {
        ...mapFeedingProgram(incomingData.feedingProgramCalves),
      },
      cows: {
        ...mapFeedingProgram(incomingData.feedingProgramCows),
      },
    },
    housing: {
      beddingSystems: mapCattleBeddingSystems(beddingSystems),
      calves: {
        manureSystems: mapCattleManureSystems(incomingData.mmsCalves),
        ...mapCattleTimeSpent(incomingData.timeSpentCalves),
      },
      cows: {
        manureSystems: mapCattleManureSystems(incomingData.mmsCows),
        ...mapCattleTimeSpent(incomingData.timeSpentCows),
      },
    },
    input: {
      startDate: stageInfo.startDate,
      cattleProductivity: incomingData.animalProductivity as "HIGH" | "LOW",
      animalsPresentAtEnd: incomingData.stockChange?.cowsPresentAtEnd,
      animalsPresentAtStart: incomingData.stockChange?.cowsPresentAtStart,
      averageWeightAtStart: formatInMassInput(
        incomingData.stockChange?.averageWeightCowsStart
      ),
      averageWeightNewAnimals: formatInMassInput(
        incomingData.input?.averageWeight
      ),
      averageWeightAtBirth: formatInMassInput(incomingData?.calvesBirthWeight),
      ...mapCattleAnimalSources(incomingData.input?.animals),
      permanencePeriod: Number(incomingData?.lengthStageCalves) || 0,
    },
    output: {
      ...mapCommonOutputCows(incomingData.outputCows),
      ...mapOutputCalves(incomingData.outputCalves),
    },
    emissions: {
      cows: mapEmissionsAndMitigations(incomingData.emissionMitigationsCows),
      calves: mapEmissionsAndMitigations(
        incomingData.emissionMitigationsCalves
      ),
    },
  };
}

function mapGrowingStageData(
  incomingData: BeefGrowingStageDataDBModel,
  stageInfo: StageInfo
): BeefGrowingStageData {
  return {
    feed: {
      ...mapFeedingProgram(incomingData.feedingProgramCows),
    },
    housing: {
      manureSystems: mapCattleManureSystems(incomingData.mmsCows),
      ...mapCattleTimeSpent(incomingData.timeSpentCows),
      beddingSystems: mapCattleBeddingSystems(
        incomingData.beddingCows as Array<CattleBeddingDBModel>
      ),
    },
    input: {
      startDate: stageInfo.startDate,
      endDate: stageInfo.endDate || "",
      growingPurpose: String(incomingData.growingPurpose),
      isStageRepeated: String(incomingData.isStageRepeated),
      numberOfRepetitions: Number(incomingData.numberOfRepetitions),
      averageAgeAtStart: incomingData.input.averageAge,
      averageWeightNewAnimals: formatInMassInput(
        incomingData.input.averageWeight
      ),
      cattleProductivity: incomingData.animalProductivity as "HIGH" | "LOW",
      ...mapCattleAnimalSources(incomingData.input.animals),
    },
    output: {
      averageAge: incomingData.outputCows.averageAge,
      ...mapCommonOutputCows(incomingData.outputCows),
    },
    emissions: mapEmissionsAndMitigations(incomingData.emissionMitigationsCows),
  };
}

function mapStageData(
  incomingData: BeefStageDBModel | ProcessingStageDBModel
): BeefStage | ProcessingStage {
  const commonStageData: Pick<BeefStage, "id" | "name" | "type"> = {
    id: incomingData.id,
    type: incomingData.type,
    name: incomingData.name,
  };

  if (incomingData.type === StageType.Processing) {
    const processingStage = incomingData as ProcessingStageDBModel;
    return mapInProcessingStage(processingStage);
  }

  const beefStage = incomingData as BeefStageDBModel;
  const stageInfo: StageInfo = {
    startDate: beefStage.startDate || "",
    endDate: beefStage.endDate || "",
  };

  if (beefStage.type === StageType.Breeding) {
    const mappedStage = mapBreedingStageData(
      JSON.parse(String(beefStage.stageData)) as BeefBreedingStageDataDBModel,
      stageInfo
    );

    return {
      ...commonStageData,
      stageData: mappedStage,
    } as BeefStage;
  }

  const mappedStageData = mapGrowingStageData(
    JSON.parse(String(beefStage.stageData)) as BeefGrowingStageDataDBModel,
    stageInfo
  );

  return {
    ...commonStageData,
    stageData: mappedStageData,
  } as BeefStage;
}

function mapBeefProductionDataInSustell(
  inData: BeefBaselineDBModel
): BeefBaseline {
  const mappedData: BeefBaseline = {
    info: {
      name: inData.name,
      oldName: inData.oldName || inData.name,
      databaseFoundation: inData?.databaseFoundation,
      description: inData.description,
      numOfCyclesYear: Number(inData.roundsPerYear),
      timeUnit: inData?.reportingPeriod as ReportingPeriod,
      year: inData.year,
      validFrom: inData.validFrom,
      validTo: inData.validTo,
    },
    copyFrom: "New",
    stages: [],
    animalType: AnimalType.Beef,
    resourceUse: {} as ResourceUse,
  };

  mappedData.resourceUse = inData.resourceUse
    ? (mapResourceUse(inData.resourceUse, mappedData.resourceUse, [
        "electricityUse",
        "gasUse",
        "waterUse",
      ]) as ResourceUse)
    : ({} as ResourceUse);

  if (inData?.stages && !Array.isArray(inData.stages)) {
    return mappedData;
  }

  return inData.stages.reduce(
    (payload, stage: BeefStageDBModel) => {
      const mappedStage = mapStageData(stage);

      return {
        ...payload,
        stages: [...payload.stages, mappedStage],
      };
    },
    { ...mappedData }
  );
}

export default mapBeefProductionDataInSustell;
