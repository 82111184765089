export const translateKeyMapping = (stringVal: string) =>
  stringVal ? stringVal.toUpperCase().replace(/\s/g, '_') : stringVal;

export const dashToSpacesString = (stringVal: string) =>
  stringVal
    ? (
        stringVal.charAt(0).toUpperCase() +
        (stringVal.length > 1 ? stringVal.slice(1) : '')
      ).replace(/_/g, ' ')
    : '';

export const dashToCamelCaseString = (stringVal: string) =>
    stringVal
      ? stringVal.toLowerCase().replace(/_([a-z])/g, (g) => { return g[1].toUpperCase(); })
      : '';

export const dashToSpacesStringLowerCase = (stringVal: string) =>{
  // console.log('stringVal', stringVal);
  const converted = stringVal
    ? (
        stringVal.charAt(0).toUpperCase() +
        (stringVal.length > 1 ? stringVal.slice(1) : '').toLowerCase()
      ).replace(/_/g, ' ')
    : '';
  // console.log("converted: ",converted);
  return converted;
  }

export const capitalizeString = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();


export const getBaseTypeForMonthEndingMMS = (mms: string) =>{
    let baseName = mms;
    const matches = mms.match( /(_OVER|_UNDER)?_(\d){1,}MONTH$/)
    if( matches ){
      baseName = mms.substring(0,matches.index);
      // eslint-disable-next-line prefer-destructuring
    }
    return baseName;
  }


export const convertDateToAWS = (inputDate:Date)=>{
  const correctedDate = new Date(
      inputDate.getTime() -
        inputDate.getTimezoneOffset() * 60000
    );
  const dateParts = correctedDate.toISOString().split('T');
  if(dateParts && dateParts[0])
    return dateParts[0];
  return correctedDate;
}
