export const dateDisplayOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const dateDisplayOptionsYMDHM = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};
export const dateDisplayOptionsShort = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const dateOnlyDisplayOptionsShort = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

// For the obj properties in the properties array add a new property named propertyDate converted to the date
// properties can be a string (one property) or list of properties
export const addDateProperties = (
  obj: { [x: string]: string | number | Date },
  properties: string[]
) => {
  const assigedObj = obj;
  if (obj && properties) {
    let assignedProperties;
    if (typeof properties === 'string') {
      // if a single property
      assignedProperties = [properties];
    } else {
      assignedProperties = properties;
    }
    assignedProperties.forEach((property: string) => {
      if (assigedObj[property]) {
        assigedObj[`${property}Date`] = new Date(assigedObj[property]);
      }
    });
  }
  return assigedObj;
};


export const getDateDeltaYears = (date: Date, deltaYears: number): Date => {
  const retVal = date;
  retVal.setFullYear(retVal.getFullYear() + deltaYears);
  
  return retVal;
}