import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonInterventionDataOutSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustellV2';
import { setInterventionFloatValue } from './outCommonMapperSustell';
import { floatValueInDefaultUnit } from '../../Baseline/mapper/floatHelperFunctions';

const mapStocking = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  let weightOfStockedAnimalsValue = parseFloat(formData.weightOfStockedAnimals);
  if (
    (weightOfStockedAnimalsValue || weightOfStockedAnimalsValue === 0) &&
    userUOM.unitOutputMass !== defaultMetric.unitOutputMass
  ) {
    // only convert value when changeMetric is set and value exists
    weightOfStockedAnimalsValue = floatValueInDefaultUnit(
      weightOfStockedAnimalsValue,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      3
    );
  }

  payloadObj.stocking = {
    stockedAnimalsNumber: {
      value: parseFloat(formData.numberOfStockedAnimals),
      change_type: formData.numberOfStockedAnimals_changeMetric,
    },
    stockedAnimalsWeight: weightOfStockedAnimalsValue,
  };
  setInterventionFloatValue(
    payloadObj.stocking,
    'averageWeight',
    formData,
    'averageLiveWeightStockedAnimals',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapProduction = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (!payloadObj.production) payloadObj.production = {};

  setInterventionFloatValue(
    payloadObj.production,
    'amount',
    formData,
    'production',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.production,
    'averageWeight',
    formData,
    'averageWeight',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapAnimalsPresent = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (!payloadObj.animalsPresent) payloadObj.animalsPresent = {};

  setInterventionFloatValue(
    payloadObj.animalsPresent,
    'animalBiomassStartOfYear',
    formData,
    'animalBiomassStartOfYear',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.animalsPresent,
    'averageLiveWeightAnimalsPresent',
    formData,
    'liveWeight',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.animalsPresent,
    'animalBiomassEndOfYear',
    formData,
    'animalBiomasEndOfYear',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapLostAnimals = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (!payloadObj.lostAnimals) payloadObj.lostAnimals = {};

  setInterventionFloatValue(
    payloadObj.lostAnimals,
    'mortalities',
    formData,
    'mortalities',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.lostAnimals,
    'escapees',
    formData,
    'escapees',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapNutrient = (formData, payloadObj) => {
  const { nutrientComposition } = formData;

  if (
    nutrientComposition.crudeProtein ||
    nutrientComposition.crudeProtein === 0
  )
    payloadObj.feedingProgram.nutrientComposition.crudeProtein = {
      value: parseFloat(nutrientComposition.crudeProtein),
      change_type: nutrientComposition.crudeProtein_changeMetric,
    };

  if (nutrientComposition.crudeLipid || nutrientComposition.crudeLipid === 0)
    payloadObj.feedingProgram.nutrientComposition.crudeLipid = {
      value: parseFloat(nutrientComposition.crudeLipid),
      change_type: nutrientComposition.crudeLipid_changeMetric,
    };

  if (nutrientComposition.crudeFibre || nutrientComposition.crudeFibre === 0)
    payloadObj.feedingProgram.nutrientComposition.crudeFibre = {
      value: parseFloat(nutrientComposition.crudeFibre),
      change_type: nutrientComposition.crudeFibre_changeMetric,
    };

  if (nutrientComposition.starch || nutrientComposition.starch === 0)
    payloadObj.feedingProgram.nutrientComposition.starch = {
      value: parseFloat(nutrientComposition.starch),
      change_type: nutrientComposition.starch_changeMetric,
    };

  if (nutrientComposition.sugar || nutrientComposition.sugar === 0)
    payloadObj.feedingProgram.nutrientComposition.sugar = {
      value: parseFloat(nutrientComposition.sugar),
      change_type: nutrientComposition.sugar_changeMetric,
    };

  if (nutrientComposition.phosphorous || nutrientComposition.phosphorous === 0)
    payloadObj.feedingProgram.nutrientComposition.phosphorous = {
      value: parseFloat(nutrientComposition.phosphorous),
      change_type: nutrientComposition.phosphorous_changeMetric,
    };

  if (nutrientComposition.feedSpill || nutrientComposition.feedSpill === 0)
    payloadObj.feedingProgram.nutrientComposition.feedSpill = {
      value: parseFloat(nutrientComposition.feedSpill),
      change_type: nutrientComposition.feedSpill_changeMetric,
    };

  if (nutrientComposition.dryMatter || nutrientComposition.dryMatter === 0)
    payloadObj.feedingProgram.nutrientComposition.dryMatter = {
      value: parseFloat(nutrientComposition.dryMatter),
      change_type: nutrientComposition.dryMatter_changeMetric,
    };

  if (
    nutrientComposition.solubleNitrogen ||
    nutrientComposition.solubleNitrogen === 0
  )
    payloadObj.feedingProgram.nutrientComposition.solubleNitrogen = {
      value: parseFloat(nutrientComposition.solubleNitrogen),
      change_type: nutrientComposition.solubleNitrogen_changeMetric,
    };

  if (
    nutrientComposition.solublePhosphorous ||
    nutrientComposition.solublePhosphorous === 0
  )
    payloadObj.feedingProgram.nutrientComposition.solublePhosphorous = {
      value: parseFloat(nutrientComposition.solublePhosphorous),
      change_type: nutrientComposition.solublePhosphorous_changeMetric,
    };

  if (
    nutrientComposition.solubleCarbon ||
    nutrientComposition.solubleCarbon === 0
  )
    payloadObj.feedingProgram.nutrientComposition.solubleCarbon = {
      value: parseFloat(nutrientComposition.solubleCarbon),
      change_type: nutrientComposition.solubleCarbon_changeMetric,
    };

  if (
    nutrientComposition.digestibleNitrogen ||
    nutrientComposition.digestibleNitrogen === 0
  )
    payloadObj.feedingProgram.nutrientComposition.digestibleNitrogen = {
      value: parseFloat(nutrientComposition.digestibleNitrogen),
      change_type: nutrientComposition.digestibleNitrogen_changeMetric,
    };

  if (
    nutrientComposition.digestibleCarbon ||
    nutrientComposition.digestibleCarbon === 0
  )
    payloadObj.feedingProgram.nutrientComposition.digestibleCarbon = {
      value: parseFloat(nutrientComposition.digestibleCarbon),
      change_type: nutrientComposition.digestibleCarbon_changeMetric,
    };

  if (
    nutrientComposition.digestiblePhosphorous ||
    nutrientComposition.digestiblePhosphorous === 0
  )
    payloadObj.feedingProgram.nutrientComposition.digestiblePhosphorous = {
      value: parseFloat(nutrientComposition.digestiblePhosphorous),
      change_type: nutrientComposition.digestiblePhosphorous_changeMetric,
    };
};
const mapFeed = (formData, payloadObj) => {
  mapNutrient(formData, payloadObj);
};

const mapAntifouling = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (formData.antifouling || formData.antifouling === 0) {
    setInterventionFloatValue(
      payloadObj,
      'antifouling',
      formData,
      'antifouling',
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      3
    );
  }
};

const mapAntibioticsUse = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  if (!payloadObj.antibiotics) payloadObj.antibiotics = [];
  if (formData?.antibioticsUse && Array.isArray(formData.antibioticsUse)) {
    formData.antibioticsUse.forEach((item) => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            3
          );
        }

        if (value || value === 0) {
          const input = {
            type: item.type,
            amount: {
              value,
              change_type: item.amount_changeMetric,
            },
          };
          payloadObj.antibiotics.push(input);
        }
      }
    });
  }

  // additional antibiotics
  if (
    formData.antibioticsUseAddition &&
    Array.isArray(formData.antibioticsUseAddition)
  ) {
    const antibioticsUseAddition = [];
    formData.antibioticsUseAddition.forEach((item) => {
      const changeMetric = item.quantity?.change_type || 'set'; // but for addiotional feeds it must be 'set'
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute') &&
        userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
      ) {
        value = floatValueInDefaultUnit(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          3
        );
      }

      if (value || value === 0) {
        const input = {
          type: item.type,
          amount: value,
        };
        antibioticsUseAddition.push(input);
      }
    });
    payloadObj.antibioticsAdditions = antibioticsUseAddition;
  }
};

const mapTransport = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  if (!payloadObj.transport) payloadObj.transport = [];
  if (formData.transport && Array.isArray(formData.transport)) {
    formData.transport.forEach((item) => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute')
        ) {
          if (item.type === 'electricity') {
            if (userUOM.unitElectricityUse !== defaultMetric.unitElectricityUse)
              value = floatValueInDefaultUnit(
                value,
                userUOM.unitElectricityUse,
                defaultMetric.unitElectricityUse,
                3
              );
          } else if (userUOM.unitDieselUse !== defaultMetric.unitDieselUse)
            value = floatValueInDefaultUnit(
              value,
              userUOM.unitDieselUse,
              defaultMetric.unitDieselUse,
              3
            );
        }

        if (value || value === 0) {
          const input = {
            boatName: item?.boatName,
            type: item.type,
            amount: {
              value,
              change_type: item.amount_changeMetric,
            },
          };

          payloadObj.transport.push(input);
        }
      }
    });
  }

  // additional transportAddition
  if (formData.transportAddition && Array.isArray(formData.transportAddition)) {
    const transportAddition = [];
    formData.transportAddition.forEach((item) => {
      const changeMetric = item.quantity?.change_type || 'set';
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute')
      ) {
        if (item.type === 'electricity') {
          if (userUOM.unitElectricityUse !== defaultMetric.unitElectricityUse)
            value = floatValueInDefaultUnit(
              value,
              userUOM.unitElectricityUse,
              defaultMetric.unitElectricityUse,
              3
            );
        } else if (userUOM.unitDieselUse !== defaultMetric.unitDieselUse)
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitDieselUse,
            defaultMetric.unitDieselUse,
            3
          );
      }

      if (value || value === 0) {
        const input = {
          boatName: item?.boatName,
          type: item.type,
          amount: value,
        };
        transportAddition.push(input);
      }
    });
    payloadObj.transportAdditions = transportAddition;
  }
};

const mapMaterialsForFarmConstruction = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  if (!payloadObj.materials) payloadObj.materials = [];
  if (
    formData.materialsFarmConstruction &&
    Array.isArray(formData.materialsFarmConstruction)
  ) {
    formData.materialsFarmConstruction.forEach((item) => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          //only convert value when changeMetric is set and value exists
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            3
          );
        }

        if (value || value === 0) {
          const input = {
            type: item.type,
            amount: {
              value,
              change_type: item.amount_changeMetric,
            },
          };
          payloadObj.materials.push(input);
        }
      }
    });
  }

  // additional materials
  if (
    formData.materialsFarmConstructionAddition &&
    Array.isArray(formData.materialsFarmConstructionAddition)
  ) {
    payloadObj.materialsAdditions = [];
    formData.materialsFarmConstructionAddition.forEach((item) => {
      const value = parseFloat(item.amount);
      if (value || value === 0) {
        const input = {
          type: item.type,
          amount: value,
        };
        payloadObj.materialsAdditions.push(input);
      }
    });
  }
};

const mapLiceTreatment = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  if (!payloadObj.liceTreatment) payloadObj.liceTreatment = [];
  if (formData.liceTreatment && Array.isArray(formData.liceTreatment)) {
    formData.liceTreatment.forEach((item) => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            3
          );
        }
        if (value || value === 0) {
          const input = {
            type: item.type,
            amount: {
              value,
              change_type: item.amount_changeMetric,
            },
          };
          payloadObj.liceTreatment.push(input);
        }
      }
    });
  }

  if (
    formData.liceTreatmentAddition &&
    Array.isArray(formData.liceTreatmentAddition)
  ) {
    const liceTreatmentAddition = [];
    formData.liceTreatmentAddition.forEach((item) => {
      const changeMetric = item.quantity?.change_type || 'set'; // but for addiotional feeds it must be 'set'
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute') &&
        userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
      ) {
        // only convert value when changeMetric is set and value exists
        value = floatValueInDefaultUnit(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          3
        );
      }

      if (value || value === 0) {
        const input = {
          type: item.type,
          amount: value,
        };
        liceTreatmentAddition.push(input);
      }
    });
    payloadObj.liceTreatmentAdditions = liceTreatmentAddition;
  }
};

const mapMarineFishInterventionDataOutSustell = (formData) => {
  let payloadObj = mapCommonInterventionDataOutSustell(formData);
  payloadObj = mapResourceUseSustell(formData, payloadObj);
  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if (payloadObj.stages[index] !== undefined) {
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const { stageData } = stageGeneralData;
        if (formStageData?.input) {
          mapStocking(formStageData?.input, stageData);
        }
        if (formStageData?.output) {
          mapProduction(formStageData?.output, stageData);
          mapAnimalsPresent(formStageData?.output, stageData);
          mapLostAnimals(formStageData?.output, stageData);
        }
        stageData.feedingProgram = {
          compoundFeedUses: stageData?.feedingProgram?.compoundFeedUses
            ? stageData.feedingProgram.compoundFeedUses
            : [],
          compoundFeedUsesAdditions: stageData?.feedingProgram
            ?.compoundFeedUsesAdditions
            ? stageData.feedingProgram.compoundFeedUsesAdditions
            : [],
          nutrientComposition: {},
        };
        if (formStageData?.feed) mapFeed(formStageData?.feed, stageData);
        if (formStageData?.operations) {
          mapAntifouling(formStageData?.operations, stageData);
          mapAntibioticsUse(formStageData?.feed, stageData);
          mapTransport(formStageData?.operations, stageData);
          mapMaterialsForFarmConstruction(formStageData?.operations, stageData);
          mapLiceTreatment(formStageData?.operations, stageData);
        }
      }
    });
  payloadObj.stages.forEach((item) => {
    const { stageData } = item;
    item.stageData = JSON.stringify(stageData);
  });
  return payloadObj;
};

export default mapMarineFishInterventionDataOutSustell;
