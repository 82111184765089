/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { API } from 'aws-amplify';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  Box,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  ThemeProvider,
} from '@material-ui/core';

import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { CustomerBaseForm } from './CustomerBaseForm';

import { injectIntl } from '../../../_metronic/i18n/customInjectIntl';
import dsmTheme from '../MUITheme';
import { createCustomer, updateCustomer } from '../../../graphql/mutations';
import defaultValues from '../../sustell_15/helpers/defaultValues';
import {
  getCustomerIngredients,
  getCompoundFeedV2Addons,
} from '../../../graphql/queries';
import { formSubmission } from '../../sustell_15//analytics/adobeDataLayer';
import { getDefaultIngredientsAddonsNames } from '../../sustell_15/utils/compound-feed-utils';

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      id: this.props.selectedCustomer?.id || '',
      name: this.props.selectedCustomer?.name || '',
      token: this.props.selectedCustomer?.token || 'default',
      tokenName: null,
      tokenValue: null,
      ingredientsLoading: true,
      ingredientsAddonsLoading: true,
      changeToken: false,
      farmsNumberLimit:
        this.props.selectedCustomer?.farmsNumberLimit ||
        defaultValues.farmsNumberLimit,
      SAPCustomerId: this.props.selectedCustomer?.SAPCustomerId || '',
      type: this.props.selectedCustomer?.type || 'FARMER',
      license: this.props.selectedCustomer?.license || 'BASIC',
      licenseStartDate: this.props.selectedCustomer?.licenseStartDate || null,
      licenseEndDate: this.props.selectedCustomer?.licenseEndDate || null,
      contractNumber: this.props.selectedCustomer?.contractNumber || '',
      reference: this.props.selectedCustomer?.reference || false,
      feedsVisible: this.props.selectedCustomer?.feedsVisible || false,
      partnerApiAccess: this.props.selectedCustomer?.partnerApiAccess ?? false,
      canAccessPartner: this.props.selectedCustomer?.canAccessPartner ?? [],
      allowedPartnerQueries:
        this.props.selectedCustomer?.allowedPartnerQueries ?? [],
      allowedPartnerMutations:
        this.props.selectedCustomer?.allowedPartnerMutations ?? [],
      isInError: false,
      errorMsg: '',
      parentComponent: props.parent,
      singleIngredients: [],
      compoundFeedIngredients: [],
      compoundFeedIngredientsAddons: [],
      compoundFeedIngredientsAddonsList: [],
    };
    this.submitMyForm = null;

    this.validationSchema = Yup.object({
      name: Yup.string(
        this.props.intl.formatMessage({ id: 'CUSTOMERS.FORM.VALIDATION.NAME' })
      )
        .required(
          this.props.intl.formatMessage({
            id: 'CUSTOMERS.FORM.VALIDATION.NAME.REQUIRED',
          })
        )
        .min(
          3,
          this.props.intl.formatMessage({
            id: 'CUSTOMERS.FORM.VALIDATION.NAME.INVALID',
          })
        ),
      tokenName: Yup.string()
        .nullable(true)
        .when('token', {
          is: 'new',
          then: Yup.string().required(
            this.props.intl.formatMessage({
              id: 'CUSTOMERS.FORM.VALIDATION.TOKENNAME.REQUIRED',
            })
          ),
        }),
      tokenValue: Yup.string()
        .nullable(true)
        .when('token', {
          is: 'new',
          then: Yup.string().required(
            this.props.intl.formatMessage({
              id: 'CUSTOMERS.FORM.VALIDATION.TOKENVALUE.REQUIRED',
            })
          ),
        })
        .when('changeToken', {
          is: true,
          then: Yup.string().required(
            this.props.intl.formatMessage({
              id: 'CUSTOMERS.FORM.VALIDATION.TOKENVALUE.REQUIRED',
            })
          ),
        }),
      farmsNumberLimit: Yup.number(
        this.props.intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 1 }
        )
      )
        .transform((changed, original) => {
          return original === '' ? undefined : changed;
        })
        .typeError(
          this.props.intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })
        )
        .min(
          1,
          this.props.intl.formatMessage(
            { id: 'VALIDATION.FIELD.MIN_VALUE' },
            { value: 1 }
          )
        )
        .required(
          this.props.intl.formatMessage({
            id: 'CUSTOMERS.FORM.VALIDATION.FARMS_NUMBER_LIMIT.REQUIRED',
          })
        ),
    });

    this.initialValues = {
      id: this.props.selectedCustomer?.id || '',
      name: this.props.selectedCustomer?.name || '',
      token: this.props.selectedCustomer?.token || 'default',
      tokenName: null,
      tokenValue: null,
      changeToken: false,
      farmsNumberLimit:
        this.props.selectedCustomer?.farmsNumberLimit ||
        defaultValues.farmsNumberLimit,
      SAPCustomerId: this.props.selectedCustomer?.SAPCustomerId || '',
      type: this.props.selectedCustomer?.type || 'FARMER',
      license: this.props.selectedCustomer?.license || 'BASIC',
      licenseStartDate: this.props.selectedCustomer?.licenseStartDate || null,
      licenseEndDate: this.props.selectedCustomer?.licenseEndDate || null,
      contractNumber: this.props.selectedCustomer?.contractNumber || '',
      reference: this.props.selectedCustomer?.reference || false,
      feedsVisible: this.props.selectedCustomer?.feedsVisible || false,
      partnerApiAccess: this.props.selectedCustomer?.partnerApiAccess ?? false,
      canAccessPartner: this.props.selectedCustomer?.canAccessPartner ?? [],
      allowedPartnerQueries:
        this.props.selectedCustomer?.allowedPartnerQueries ?? [],
      allowedPartnerMutations:
        this.props.selectedCustomer?.allowedPartnerMutations ?? [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelSave = this.cancelSave.bind(this);
    this.triggerSubmitForm = this.triggerSubmitForm.bind(this);
    this.bindSubmitForm = this.bindSubmitForm.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedCustomer?.id) {
      API.graphql({
        query: getCustomerIngredients(),
        variables: { id: this.props.selectedCustomer?.id },
      })
        .then((customerIngredients) => {
          this.setState({
            singleIngredients:
              customerIngredients.data?.getCustomerIngredients
                ?.singleIngredients,
            compoundFeedIngredients:
              customerIngredients.data?.getCustomerIngredients
                ?.compoundFeedIngredients,
            compoundFeedIngredientsAddons:
              customerIngredients.data?.getCustomerIngredients
                ?.compoundFeedIngredientsAddons || [],
            assignedSingleIngredients: [
              ...(customerIngredients.data?.getCustomerIngredients
                ?.singleIngredients || []),
            ],
            assignedCompoundFeedIngredients: [
              ...(customerIngredients.data?.getCustomerIngredients
                ?.compoundFeedIngredients || []),
            ],
            assignedCompoundFeedIngredientsAddons: [
              ...(customerIngredients.data?.getCustomerIngredients
                ?.compoundFeedIngredientsAddons || []),
            ],
            ingredientsLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ ingredientsLoading: false });
        });
      // Database needs to be selected, but here it does not make the difference, because
      // ingredients should be the same for both GFLI 2 and AFP 6.3
    } else {
      this.setState({ ingredientsLoading: false });
    }

    // if new customer, i need some random UUID to get the list of addons?
    API.graphql({
      query: getCompoundFeedV2Addons,
      variables: { databaseFoundation: 'GFLI' },
    })
      .then((customerCompoundFeedIngredientsAddons) => {
        const compoundFeedIngredientsAddonsList = [];
        customerCompoundFeedIngredientsAddons.data.getCompoundFeedV2Addons.forEach(
          (addons) => {
            addons.ingredients.forEach((ingredient) => {
              if (!compoundFeedIngredientsAddonsList.includes(ingredient.name))
                compoundFeedIngredientsAddonsList.push(ingredient.name);
            });
          }
        );
        this.setState({
          compoundFeedIngredientsAddonsList,
          ingredientsAddonsLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ ingredientsAddonsLoading: false });
      });
  }

  handleSubmit(submitData) {
    formSubmission(
      this.props.formMode === 'edit' ? 'Edit Customer' : 'Add Customer',
      this.props.formMode
    );
    // will be called ONLY AFTER Formik validation is successfull
    // console.log('handleSubmit: ', submitData);
    this.setState({
      name: submitData.name,
      token: submitData.token,
      tokenName: submitData.tokenName,
      tokenValue: submitData.tokenValue,
      farmsNumberLimit: submitData.farmsNumberLimit,
      SAPCustomerId: submitData.SAPCustomerId,
      type: submitData.type,
      license: submitData.license,
      licenseStartDate: submitData.licenseStartDate,
      licenseEndDate: submitData.licenseEndDate,
      contractNumber: submitData.contractNumber,
      reference: submitData.reference,
      feedsVisible: submitData.feedsVisible,
      partnerApiAccess: submitData.partnerApiAccess ?? submitData?.canAccessPartner.length > 0,
      canAccessPartner: submitData?.canAccessPartner ?? [],
      allowedPartnerQueries: submitData?.allowedPartnerQueries ?? [],
      allowedPartnerMutations: submitData?.allowedPartnerMutations ?? [],
    });

    (async () => {
      try {
        let input = {
          name: this.state.name,
          token: this.state.token,
          tokenName: this.state.tokenName,
          tokenValue: this.state.tokenValue,
          reference: this.state.reference,
          farmsNumberLimit: this.state.farmsNumberLimit,
          SAPCustomerId: this.state.SAPCustomerId,
          type: this.state.type,
          license: this.state.license,
          licenseStartDate: this.state.licenseStartDate,
          licenseEndDate: this.state.licenseEndDate,
          contractNumber: this.state.contractNumber,
          feedsVisible: this.state.feedsVisible,
          partnerApiAccess: this.state.partnerApiAccess,
          canAccessPartner: this.state.canAccessPartner,
          allowedPartnerQueries: this.state.allowedPartnerQueries,
          allowedPartnerMutations: this.state.allowedPartnerMutations,
        };

        if (this.props.formMode === 'edit') {
          input.id = this.props.selectedCustomer.id;
          input.old_name = this.props.selectedCustomer.name;
        }

        const defaultIngredientsAddonsNames =
          getDefaultIngredientsAddonsNames();

        input.ingredients = {
          singleIngredients: this.state.singleIngredients || [],
          compoundFeedIngredients: this.state.compoundFeedIngredients || [],
          // set addons, by removing default ones
          compoundFeedIngredientsAddons:
            this.state.compoundFeedIngredientsAddons?.filter(
              (item) => !defaultIngredientsAddonsNames.includes(item)
            ) || [],
        };

        const result = await API.graphql({
          query:
            this.props.formMode === 'edit' ? updateCustomer : createCustomer,
          variables: {
            input: input,
          },
        });
        this.setState({ isInError: false });
        let newCustomerData =
          this.props.formMode === 'edit'
            ? result.data.updateCustomer
            : result.data.createCustomer; //object containg only the id of inserted/updated entity
        if (submitData?.token === 'new')
          submitData.token = submitData.tokenName;
        Object.assign(newCustomerData, submitData);
        //newCustomerData.updatedAt = new Date().toString();
        this.setState({
          name: '',
          token: '',
          reference: false,
          farmsNumberLimit: '',
          feedsVisible: false,
          partnerApiAccess: false,
          canAccessPartner: [],
          allowedPartnerQueries: [],
          allowedPartnerMutations: [],
        });
        this.props.changeHandler(newCustomerData);
      } catch (err) {
        if (err.errors?.length > 0) {
          let error = err.errors[0];
          this.setState({
            errorMsg: error.message,
            isInError: true,
          });
        }
      }
    })();
  }

  cancelSave(ev) {
    this.props.changeHandler(null);
  }

  triggerSubmitForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  //allow sending submit "signal" to Formik form
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  render() {
    const { intl } = this.props;
    const titleMessageID =
      this.props.formMode === 'edit'
        ? 'CUSTOMERS.FORM.TITLE.EDIT'
        : 'CUSTOMERS.FORM.TITLE.NEW';
    const formTitle = intl.formatMessage({ id: titleMessageID });
    return (
      <ThemeProvider theme={dsmTheme}>
        <Card>
          <CardHeader
            title={formTitle}
            titleTypographyProps={{ color: 'secondary', variant: 'h4' }}
          />
          <CardContent>
            {this.state.isInError && (
              <Row>
                <Col md={11}>
                  <Alert severity="error">
                    <AlertTitle>
                      {intl.formatMessage({ id: 'GENERAL.ERROR' })}
                    </AlertTitle>
                    {this.state.errorMsg}
                  </Alert>
                </Col>
              </Row>
            )}
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {(formikProps) => {
                //this provides outside binding for externalized submit buttons
                this.bindSubmitForm(formikProps.submitForm);

                return (
                  <CustomerBaseForm
                    {...formikProps}
                    compoundFeedIngredientsAddons={
                      this.state.compoundFeedIngredientsAddons
                    }
                    compoundFeedIngredients={this.state.compoundFeedIngredients}
                    singleIngredients={this.state.singleIngredients}
                    setCompoundFeedIngredientsAddons={(ing) =>
                      this.setState({ compoundFeedIngredientsAddons: ing })
                    }
                    setCompoundFeedIngredients={(ing) =>
                      this.setState({ compoundFeedIngredients: ing })
                    }
                    setSingleIngredients={(ing) =>
                      this.setState({ singleIngredients: ing })
                    }
                    assignedCompoundFeedIngredientsAddons={
                      this.state.assignedCompoundFeedIngredientsAddons
                    }
                    assignedCompoundFeedIngredients={
                      this.state.assignedCompoundFeedIngredients
                    }
                    assignedSingleIngredients={
                      this.state.assignedSingleIngredients
                    }
                    compoundFeedIngredientsAddonsList={
                      this.state.compoundFeedIngredientsAddonsList
                    }
                    formMode={this.props.formMode}
                    ingredientsLoading={this.state.ingredientsLoading}
                    ingredientsAddonsLoading={
                      this.state.ingredientsAddonsLoading
                    }
                    handleCancel={this.cancelSave}
                  />
                );
              }}
            </Formik>
          </CardContent>
          <CardActions></CardActions>
        </Card>
        <Box mt={3} mb={2} display="flex" justifyContent="flex-end">
          <Box ml={1}>
            <Button variant="outlined" type="button" onClick={this.cancelSave}>
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="button"
              onClick={this.triggerSubmitForm}
            >
              {intl.formatMessage({ id: 'GENERAL.SAVE' })}
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default injectIntl(CustomerForm);
