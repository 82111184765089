import { FC} from "react";

import { Box, Grid } from "@material-ui/core";
import DashboardModel from "../../../models/Dashboard/Dashboard";
import ImpactTargetInfo from "./ImpactTargetInfo";
import { useIntl } from "../../../../../_metronic/i18n/customUseIntl";
import { translateKeyMapping } from "../../../utils/string-utils";
import { DashboardImpactType } from "../../../models/Dashboard/DashboardTypes";
// import TargetGauge from "./TargetGauge";

interface ImpactGaugesOverviewProps {
  dashboard: DashboardModel | undefined;
}

const ImpactGaugesOverview: FC<ImpactGaugesOverviewProps> = ({
  dashboard,
}) => {
  const intl = useIntl();

  const totalValue = dashboard?.totalValue || 0;
  const totalTargetValue = (dashboard?.targetByActiveCategory || 0) * (dashboard?.totalItems || 0);

  const totalAvgValue = totalValue / (dashboard?.totalItems || 0);
  const totalAvgTargetValue = totalTargetValue / (dashboard?.totalItems || 0);

  const dontShowClimateTotal: boolean =  dashboard?.impactType === DashboardImpactType.PerUnit;

  const categoryDescription = intl.formatMessage({
    id: `SUSTELL.FOOTPRINT.DESCRIPTION.${translateKeyMapping(dashboard?.activeImpactCategory.key || '')}`,
    defaultMessage: dashboard?.activeImpactCategory.key || '',
});

  return (
    <Box mt="32px" mb="24px">
      <Grid container direction="row" alignContent="center" alignItems="center" spacing={3}>
         <Grid item xs={6}>
          <Box 
            display="flex"
            style={{
              background: '#F0F7FB',
              justifyContent: 'space-between'
            }} 
            p="43px 30px 43px 30px"
          >
            <ImpactTargetInfo 
              impactCategoryName={dashboard?.activeImpactCategory.key || ''}
              categoryDescription={categoryDescription}
              unit={dashboard?.activeImpactCategory.unit || ''}
              value={dontShowClimateTotal ? undefined : totalValue}
              targetValue={dontShowClimateTotal ? undefined : totalTargetValue}
              impactType={dashboard?.impactType}
              type="Total"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box 
            display="flex"
            style={{
              background: '#F0F7FB',
              justifyContent: 'space-between'
            }} 
            p="43px 30px 43px 30px"
          >
            <ImpactTargetInfo 
              impactCategoryName={dashboard?.activeImpactCategory.key || ''}
              categoryDescription={categoryDescription}
              unit={dashboard?.activeImpactCategory.unit || ''}
              value={totalAvgValue}
              targetValue={totalAvgTargetValue}
              impactType={dashboard?.impactType}
              type="Average"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ImpactGaugesOverview;