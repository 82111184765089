import { Grid } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  PigFatteningIntervention,
  PigFatteningOutput,
} from '../../../../models/Intervention';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { FormType } from '../../common';
import { PigFatteningInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const PFInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PigFatteningInterventionFormDialogProps) => {
  const intl = useIntl();

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const outputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access    
  const unitOutputMass = userUOM?.unitOutputMass
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ? unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  const currResetValue = useRef<PigFatteningOutput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...(fc.getValues(fieldItemPrefix) as PigFatteningOutput),
      }; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PigFatteningIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.output) {
        stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output;

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="PIG_FATTENING"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <Grid container direction="column" spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.liveWeight`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.PIGS.LIVEWEIGHT',
            })}
            margin="none"
            tooltip={
              <div>
                {intl.formatMessage(
                  { id: 'INTERVENTION.FORM.OUTPUT.PIGS.LIVEWEIGHT.TOOLTIP' },
                  { br: <br /> }
                )}
              </div>
            }
            metricUnit={unitOutputMass}
            precision={outputMassPrecision}
            baseline={output?.liveWeight}
            disabled={formType === 'view'}
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.inorganicNFertilizerReplacementRate`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.PIGS.INORGANIC_N_REPL_RATE',
            })}
            type="number"
            margin="none"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.OUTPUT.PIGS.INORGANIC_N_REPL_RATE.TOOLTIP',
            })}
            metricUnit="%"
            baseline={output?.inorganicNFertilizerReplacementRate}
            disabled={formType === 'view'}
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.inorganicPFertilizerReplacementRate`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.PIGS.INORGANIC_P_REPL_RATE',
            })}
            type="number"
            margin="none"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.OUTPUT.PIGS.INORGANIC_P_REPL_RATE.TOOLTIP',
            })}
            metricUnit="%"
            baseline={output?.inorganicPFertilizerReplacementRate}
            disabled={formType === 'view'}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default PFInterventionOutputFormDialog;
