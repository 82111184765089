import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Grid, Typography } from '@material-ui/core';

import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { helpCenterStyles, useSubheader } from '../../../_metronic/layout';
import useStyles from '../../modules/UserProfile/profileStyles/myProfileStyles';
import TechnicalSupport from '../components/TechnicalSupport/TechnicalSupport';
import KnowledgeBase from '../components/TechnicalSupport/KnowledgeBase';
import useAdobeDataLayer from '../analytics/adobeDataLayer';

const HelpCenter: React.FC = () => {
  //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const subheader = useSubheader();
  const intl = useIntl();
  const classes = useStyles();
  const specificClasses = helpCenterStyles();
  const [activeView, setActiveView] = useState(0);
  const title = intl.formatMessage({ id: 'SUSTELL.HELP_CENTER' });
  const description = intl.formatMessage({
    id: 'SUSTELL.HELP_CENTER.DESCRIPTION',
  });
  const tabsContent = [
    intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE' }),
    intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.TECHNICAL_SUPPORT' }),
  ];
  const { formStartEvent } = useAdobeDataLayer();

  const handleTabChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: number
  ) => {
    setActiveView(newValue);
    if (activeView === 1) {
      formStartEvent('Technical support - Report an incident', 'add');
    }
  };

  useEffect(() => {
    const breadCrumbs: { pathname?: string; title: string }[] = [];
    breadCrumbs.push({
      pathname: `/helpcenter`,
      title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER' }),
    });
    //Disable eslint due to non-converted (js) subheader
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    subheader.setBreadcrumbs(breadCrumbs);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={specificClasses.helpCenterContainer}>
      <Grid className={specificClasses.helpCenterSubContainer}>
        <Typography className={classes.profileTitle}>{title}</Typography>
        <Typography className={classes.profileDescription}>
          {description}
        </Typography>
      </Grid>
      <Tabs
        className={classes.profileTabs}
        value={activeView}
        onChange={handleTabChange}
      >
        {tabsContent.map((tab, index) => (
          <Tab
            key={`${tab}-key`}
            className={classes.profileTab}
            label={tab}
            value={index}
          />
        ))}
      </Tabs>
      {activeView === 0 && <KnowledgeBase />}
      {activeView === 1 && <TechnicalSupport />}
    </div>
  );
};

export default HelpCenter;
