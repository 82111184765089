export const addtnlAmplifyTranslations = {
  es: {
    'Sign In': 'Registrarse',
    'Email': 'Correo electrónico',
    'Enter your Email': 'Introduce tu correo electrónico',
    'Enter your Password': 'Ingresa tu contraseña',
    // 'Reset password': 'Restablecer la contraseña',
    'Reset password': '¿Olvidaste tu contraseña?',
    'Custom auth lambda trigger is not configured for the user pool.':'Por favor complete ambos campos para iniciar sesión.', // this is actually error when password is left empty
    // 'Forgot your password? ': '¿Olvidaste tu contraseña?',
    'Forgot your password? ': ' ',
    'Code *': 'Código *',
  },
  pt: {
    'Sign In': 'Iniciar sessão',
    'Email': 'E-mail',
    'Enter your Email': 'Insira seu email',
    'Enter your Password': 'Insira sua senha',
    // 'Reset password': 'Redefinir senha',
    'Reset password': 'Esqueceu sua senha?',
    'Custom auth lambda trigger is not configured for the user pool.':'Preencha os dois campos para fazer login.', // this is actually error when password is left empty
    // 'Forgot your password? ': 'Esqueceu sua senha?',
    'Forgot your password? ': ' ',
    'Code *': 'Código *',
    'New Password': 'Nova senha'
  },
  en:{
    'Reset password': 'Forgot password?',
    'Forgot your password? ': ' ',
    'Custom auth lambda trigger is not configured for the user pool.':'Please fill both fields to sign in.' // this is actually error when password is left empty
  }
}