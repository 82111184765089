import { FC } from 'react';

import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../../helpers/helperTypes';
import { DialogContainer } from '../../../CommonDataParts/DialogContainer2';

type MMSDescriptionDialogProps = {
  open: boolean;
  handleClose: () => void;
};

// a static dialog loading and showing descirption text for MMS Systems
const MMSDescriptionDialog: FC<MMSDescriptionDialogProps> = ({
  open = false,
  handleClose,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.MMS.DESCRIPTIONS.TITLE',
  });
  const formRefs = intl.formatMessage({
    id: 'SUSTELL.PROCESS.MMS.DESCRIPTIONS.REFS',
  });
  const prefix = 'SUSTELL.PROCESS.PIG.MMS.DESCRIPTIONS';

  // list of descritption item keys
  const items = [
    'AEROBIC_TREATMENT',
    'ANAEROBIC_DIGESTER_HIGH_LEAKAGE',
    'ANAEROBIC_DIGESTER_LOW_LEAKAGE',
    'CATTLE_AND_SWINE_DEEP_BEDDING',
    'COMPOSTING_STATIC_PILE_FORCED_AERATION',
    'COMPOSTING_INTENSIVE_WINDROW_FREQUENT_TURNING',
    'COMPOSTING_IN_VESSEL',
    'COMPOSTING_PASSIVE_WINDROW_INFREQUENT_TURNING',
    'DAILY_SPREAD',
    'DRY_LOT',
    'LIQUID_SLURRY',
    'PIT_STORAGE_BELOW_ANIMAL_CONFINEMENTS',
    'SOLID_STORAGE',
    'UNCOVERED_ANAEROBIC_LAGOON',
  ];
  return (
    <DialogContainer
      formVisible={open}
      variant="descriptionbox"
      handleClose={handleClose}
      withIcon={false}
      formTitle={formTitle}
      introText={formRefs}
    >
      <DsmGrid
        className={classes.dsmGridTwoColumn_2_3_NoRowSpacingWithLines}
        style={{ overflow: 'unset' }}
      >
        <div>
          <h6>
            {intl.formatMessage({
              id: 'BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM',
            })}
          </h6>
        </div>
        <div>
          <h6>{intl.formatMessage({ id: 'GENERAL.DESCRIPTION' })}</h6>
        </div>
      </DsmGrid>
      <DsmGrid
        className={classes.dsmGridTwoColumn_2_3_NoRowSpacingWithLines}
        style={{ overflow: 'unset' }}
      >
        {items.map((item) => (
          <>
            <div>
              <strong>
                {intl.formatMessage(
                  { id: `${prefix}.${item}.TITLE` },
                  { br: <br /> }
                )}
              </strong>
            </div>
            <div>
              {intl.formatMessage(
                { id: `${prefix}.${item}.DESCRIPTION` },
                { br: <br /> }
              )}
            </div>
          </>
        ))}
      </DsmGrid>
    </DialogContainer>
  );
};

export default MMSDescriptionDialog;
