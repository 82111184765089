import { SvgIcon } from "@material-ui/core";

const Customers = ({customColor}:{ customColor:string }) => {
    const greenColorHex = "#45ac34";
    const fillColor = customColor || greenColorHex;
    const strokeColor = customColor === 'primary' ? "#007E4B" : "rgba(0, 0, 0, 0.54)";
    return (
        <SvgIcon width="22" height="20" viewBox="0 0 22 20">
           <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 19L21 16M21 16L18 13M21 16H15M11 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z" 
              stroke={strokeColor} 
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"  fill={fillColor}/>
          </svg>
        </SvgIcon>
    );
}

export default  Customers;