import { FC } from "react";
import {
  Button,
  Box
} from "@material-ui/core";
import { Maybe } from "graphql/jsutils/Maybe";

import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import InfoToolTip from "../../../modules/Helpers/InfoToolTip";

interface ButtonControlGroupProps {
  cancelHandler: Maybe<Function>;
  cancelLabel?: string;
  saveHandler?: Maybe<Function>;
  saveLabel: string;
  additionalControls?: any;
  additionalButtons?: any[];
  draftHandler?: Maybe<Function>;
}

export const ButtonControlGroup: FC<ButtonControlGroupProps> = ({cancelHandler, cancelLabel, saveHandler, saveLabel, additionalControls, draftHandler}) => {
  
  /*
    Component will always render cancel button
    Component uses space-beetween to justify content
    Props:
        - cancelHandler - function to handle cancel btn click
        - cancelLabel (optional)
        - saveHandler - function to handle save btn click - if not passed save btn will not be rendered
        - saveLabel (optional)
        - additionalControls - list of object in the form of
                                {btnClkHandler:functionObj,btnLabel:labelString}
                                Will render additional buttons after cancel (and save)
  */

  const intl = useIntl();
    
  if(!cancelLabel)
      cancelLabel = intl.formatMessage({ id: "GENERAL.CANCEL" })
  if(!saveLabel)
      saveLabel = intl.formatMessage({ id: "GENERAL.SAVE"})
  
  let additionalButtons = null;
  if(additionalControls){
      additionalButtons = additionalControls?.map((item: any, i: number) =>
          <Box ml={1} key={`addl_btn_${i}`}>
            <Button variant="contained" color="primary" disableElevation onClick={item.btnClkHandler} style={ item.tooltip ? {marginRight: '10px'}: {}}>{item.btnLabel} </Button>
            {item.tooltip &&
              <InfoToolTip tooltip={item.tooltip} />
            }
          </Box>
      );
  }
  return (
      <Box display="flex" justifyContent="space-between">
        {cancelHandler &&
          <Box>
            <Button variant="contained"  type="button"  onClick={e => cancelHandler(e)} style={{color:'white', backgroundColor:"#A7A9AB"}}>
              {cancelLabel}
            </Button>
          </Box>
        }
        {additionalButtons}
        <Box display="flex" justifyContent="flex-end">
          {draftHandler &&
            <Box ml={1} >
                <Button variant={saveHandler === null ? "contained" : "outlined"}  type="button" disableElevation onClick={e => draftHandler(e)} >{ intl.formatMessage({ id: "GENERAL.DISCARD_DRAFT"}) }</Button>
            </Box>
          }
          {saveHandler &&
            <Box ml={1} >
                <Button variant="contained" color="primary"   type="button" onClick={e => saveHandler(e)}>{saveLabel}</Button>
            </Box>
          }
        </Box>
      </Box>
  );
};