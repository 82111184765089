import { BaselineMappedDataType } from "../../sustell_15/components/FarmFlow/Baseline/types";
import { store } from "../store";

/** Helper function to get draft with key directly from store */
const getBaselineDraftByKey = (key: string): BaselineMappedDataType | undefined => {
  if (!store) {
    console.error('store is not defiend');
    return;
  }
  return store.getState().baselineDrafts.value[key]
};

export default getBaselineDraftByKey;