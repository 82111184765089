// This is disabled to avoid used of defaultProps property and to use classic destructing with default values for props
/* eslint-disable react/require-default-props */
import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  MutableRefObject,
  DetailedHTMLProps,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { InputAdornment, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import ReactHookDsmSelect from '../../Helpers/ReactHookDsmSelect2';
import lookupValues from '../../../sustell_15/helpers/lookupValues';
import { resolvePath } from '../../Helpers/resolvePathFn';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';


const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute', 
  height: '16px', 
  width: '16px',
  top:'29px',
  right:'7px'
}

const useStyles = makeStyles({
  root: {
    // minHeight: '74px',
    '& input':{
      marginTop: '-4px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      lineHeight: '28px'
    },
    '& label': {
      position: 'relative',
      width: '125% !important',
      paddingLeft: '0px',
      marginBottom: '9px',
      fontSize: '16px',
      fontWeight: '500'
    },
    '& legend': {
      display: 'none'
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-root': {
      '& div.MuiInputAdornment-positionEnd': {
        '& p':{
          fontSize: '13px',
          marginBottom: '3px'
        },
      },      
      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },  
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px !important'
    },
  },
});

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: any;
  children: any;
}

const ConditionalWrapper = ({ condition, wrapper, children } :ConditionalWrapperProps) => 
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  condition ? wrapper(children) : children;

interface RowControlledTextFieldWithMetricsProps {
  name: string;
  label: string;
  tooltip?:
    | string
    | DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  updateTotalMethane?: () => void;
  metricUnit: string;
  baseline: number | string | null | undefined;
  updateTotalFeedIntake?: (name: string, value: number | null) => void;
  disabled: boolean;
  readonly?: boolean;
  minAllowedValue?: number;
  maxAllowedValue?: number;
  precision?: number;
  onlySetMetricIfNoBaseline?: boolean | null;
  inputRef?: MutableRefObject<number | string | null>;
  updateRef?: (
    name: string,
    inputRef: MutableRefObject<number | string | null>,
    newValue: number | string
  ) => void;
  clearDefaultStyle?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
}

const ProcessingControlledTextFieldWithMetrics = ({
  name,
  label,
  tooltip,
  updateTotalMethane,
  metricUnit,
  baseline,
  updateTotalFeedIntake,
  disabled,
  readonly,
  minAllowedValue = 0,
  maxAllowedValue,
  precision = 2,
  onlySetMetricIfNoBaseline = false,
  inputRef = undefined,
  updateRef = undefined,
  clearDefaultStyle = false,
  type = 'text',
}: RowControlledTextFieldWithMetricsProps) => {
  const intl = useIntl();
  const fc = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;

  const classesLocal = useStyles();
  let numOldValue: number | null = null;
  if (typeof baseline === 'number') numOldValue = baseline;
  else if (typeof baseline === 'string') numOldValue = parseFloat(baseline);

  if (Number.isNaN(numOldValue)) numOldValue = null;

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const localError = resolvePath(fc.errors, name, null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currResetValue = resolvePath(
    fc.control.defaultValuesRef.current,
    name,
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isTouched = resolvePath(fc.formState.dirtyFields, name, false);

  const [showChangeType, setShowChangeType] = useState<boolean>(false);

  const [metricValue, setMetricValue] = useState<string | null>();
  const initialRender = useRef(true);

  let addornmentTxt = metricValue === 'relative' || metricValue === undefined ? '%' : metricUnit;

  const calculateNewValues = (newValueFromForm: string): number | '' => {
    if (!showChangeType) {
      if (baseline) {
        fc.setValue(`${name}_newValue`, '');
      }
      return '';
    }

    const metric = fc.getValues(`${name}_changeMetric`) as string;
    // addornmentTxt must also be updated here if values are set after loading data
    addornmentTxt =
      metric === 'relative' || metric === undefined ? '%' : metricUnit;

    const newValue = parseFloat(newValueFromForm);

    if (metric === 'set') {
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, newValue);
      fc.setValue(`${name}_newValue`, newValue);
      if (inputRef && updateRef && inputRef.current !== newValue) {
        updateRef(name, inputRef, newValue);
      }
      if (updateTotalMethane) updateTotalMethane();
      return newValue;
    }
    if ((numOldValue || numOldValue === 0) && metric !== '') {
      let result: string | number = '';
      if (metric === 'absolute') {
        result = numOldValue + newValue;
        if (updateTotalFeedIntake) updateTotalFeedIntake(name, result);
        fc.setValue(`${name}_newValue`, result);
        if (inputRef && updateRef && inputRef.current !== result) {
          updateRef(name, inputRef, result);
        }
        if (updateTotalMethane) updateTotalMethane();
        return result;
      }
      // relative
      result = numOldValue * (1 + newValue / 100);
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, result);
      fc.setValue(`${name}_newValue`, result);
      if (inputRef && updateRef && inputRef.current !== result) {
        updateRef(name, inputRef, result);
      }
      if (updateTotalMethane) updateTotalMethane();
      return result;
    }

    return '';
  };

  const checkMetricUnit = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value && !Number.isNaN(e.target.value) && numOldValue === null)
      fc.setValue(`${name}_changeMetric`, 'set');
  };

  const getMetricOptions = () => {
    // if baseline not defined, only set option is possible
    if (onlySetMetricIfNoBaseline)
      return baseline || baseline === 0
        ? lookupValues.interventionValueMetrics
        : { set: lookupValues.interventionValueMetrics.set };

    return lookupValues.interventionValueMetrics;
  };

  const getDefaultValue = () => {
    if (onlySetMetricIfNoBaseline)
      return baseline || baseline === 0 ? 'relative' : 'set';

    return 'relative';
  };

// If change type is off, remove intervention values and restart field values
const removeChangeType = () => {
    fc.setValue(`${name}`, '');
    fc.setValue(`${name}_changeMetric`, 'relative');
    fc.setValue(`${name}_newValue`, '');
    (async() => await fc.trigger([`${name}`, `${name}_newValue`, `${name}_changeMetric`]))();
    setShowChangeType(false);
  }

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else {
      // eslint-disable-next-line no-void
      void fc.trigger(name);
    }
    // For fields that have intervention values show
    if (fc.getValues(name)) {
      setShowChangeType(true);
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [metricValue, fc, fc.trigger, name]);



  return (
    // <DsmGrid className={ classes.dsmGridTwoColumnsInterventionComponent}> 
    <ConditionalWrapper
        condition={!clearDefaultStyle}
        wrapper={(children: any )=>
          <DsmGrid className={ classes.dsmGridTwoColumnsInterventionComponent}>
            {children}
          </DsmGrid>}
      >
      <DsmGrid style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
        <div style={{position: "relative", display: "inline-block"}}>
          <Controller
            control={fc.control}
            name={name}
            defaultValue=""
            render={({ onChange, onBlur, value, ref }) => {
              // allows zeroes to be visible
              let localVal: string =
                typeof value === 'string' ? value : String(value);

              if (localVal === '' && currResetValue === 0 && !isTouched)
                localVal = '0';

              let onlyOriginalValue;
              if (!showChangeType) {
                onlyOriginalValue = baseline ? baseline.toString() : '';
              }

              let localNew = calculateNewValues(localVal);
              const style =
                !Number.isNaN(localNew) &&
                (localNew < minAllowedValue ||
                  (localNew > 100 &&
                    (metricUnit === 'percentage' || metricUnit === '%')) ||
                  (maxAllowedValue && localNew > maxAllowedValue))
                  ? { color: 'red' }
                  : {};
              const metricUnitShort =
                metricUnit === 'number'
                  ? ''
                  : metricUnit.replace(/p[o,a,e]r animal/, 'p.a.'); // will work for english, spanish, portuguese and french
              const minValue = metricValue === 'relative' ? -100 : 0;

              // If no change type, don't use new calculated value
              if (!showChangeType) {
                localNew = '';
                fc.setValue(`${name}_newValue`, '');
              }
              return (
                <>
                  <TextField
                    className={classesLocal.root}
                    label={label}
                    variant='outlined'
                    value={
                      onlyOriginalValue ? 
                      onlyOriginalValue:
                      (localVal || localVal === '0' ? localVal : '')}
                    fullWidth
                    inputRef={ref}
                    onChange={(e) => {
                      onChange(e);
                      checkMetricUnit(e);
                    }}
                    onBlur={onBlur}
                    type={type}
                    error={!!localError}
                    // TODO Fix when resolvePath is noverted to TS
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    helperText={localError ? localError.message : ''}
                    InputProps={{
                      style: {
                        height: '28px',
                        lineHeight: "28px"
                      },
                      endAdornment: (
                        <InputAdornment position="end" style={{marginRight: '12px'}}>
                          {metricUnit}
                        </InputAdornment>
                      ),
                      readOnly: readonly,
                      inputProps: {
                        min: minValue,
                      },
                    }}
                    InputLabelProps={{ 
                      shrink: true,
                      variant: 'standard',
                      disableAnimation: true,
                      style:{
                        width: '100%'
                      }
                    }}
                    disabled={disabled || !showChangeType}
                  />
                  <div style={{ width: 'max-content', color: '#808080', fontSize: '11.3px', display: !showChangeType ? 'none' : '' }}>
                    {intl.formatMessage({
                      id: 'INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE',
                    })}{' '}
                    <strong>
                      {typeof numOldValue === 'number'
                        ? `${numOldValue.toFixed(precision)} ${metricUnitShort}`
                        : ' - '}
                    </strong>
                    /{' '}
                    {intl.formatMessage({
                      id: 'INTERVENTION.FORM.CHANGE.NEW_VALUE',
                    })}{' '}
                    <strong style={style}>
                      {' '}
                      {typeof localNew === 'number' && !Number.isNaN(localNew)
                        ? `${localNew.toFixed(precision)} ${metricUnitShort}`
                        : ' - '}{' '}
                    </strong>
                  </div>
                </>
              );
            }}
          />
          <Controller
            control={fc.control}
            name={`${name}_newValue`}
            defaultValue=""
            // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unsafe-assignment
            render={({ value, name }) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <input type="hidden" name={name} value={value} />
            )}
          />
          {tooltip && 
            <Tooltip title={ tooltip } placement="left">
              <DsmIcon name='general/help-circle' style={inlineTooltipClass} title=""/>
            </Tooltip>
          }
        </div>
        {
          showChangeType && 
          <>

            {/* <br /> */}
            <ReactHookDsmSelect
              name={`${name}_changeMetric`}
              label={intl.formatMessage({
                id: 'INTERVENTION.FORM.CHANGE.CHANGE_TYPE',
              })}
              defaultValue={getDefaultValue()}
              changeHandler={(e: ChangeEvent<HTMLSelectElement>) =>
                setMetricValue(e.target.value)
              }
              disabled={!!disabled}
              options={Object.entries(getMetricOptions()).map(([i]) => ({
                value: i,
                text: intl.formatMessage({
                  id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`
                  })
                })
                )
              }
            >
              {/* besiede i was label */}
              {Object.entries(getMetricOptions()).map(([i]) => (
                <MenuItem key={i} value={i}>
                  {intl.formatMessage({
                    id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`,
                  })}
                </MenuItem>
              ))}
            </ReactHookDsmSelect>
          </>
        }
        
        {
          !showChangeType ? 
            <DsmButton 
              variant='text' 
              onClick={() => setShowChangeType(true)} 
              disabled={disabled} 
              style={{width: '120px'}}>
              
              <DsmIcon slot="before"  name="general/edit-02" />
              <u>Edit your input</u>
            </DsmButton>
          : 
          <DsmButton 
            variant='text' 
            destructive 
            onClick={() => removeChangeType()
              } 
            disabled={disabled} 
            style={{width: '160px'}}
          >
            <DsmIcon slot="before"  name="general/minus-circle" />
            <u>Remove change type</u>
          </DsmButton>
        }
        
    </DsmGrid>
    </ConditionalWrapper>
  );
};

export default ProcessingControlledTextFieldWithMetrics;
