import * as Yup from 'yup';
import {
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  numericRequiredWithMinMax,
} from '../../../modules/Farms/Baseline/validationSchema/validationObjectBuilderFunctions';
import { resourceItems } from '../../../modules/Farms/Baseline/validationSchema/baselineValidationSchemaGeneralPart';
import { isPoultrySystem } from '../CompoundFeedWizard/utils';
import lookupValues from '../../helpers/lookupValues';

const noValue = 'GENERAL.NO';

const compoundFeedValidationSchema = ({
  intl,
  maxSumValue = 1000,
  unit = 'kg',
}) =>
  Yup.object({
    name: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
      )
      .max(
        256,
        intl.formatMessage(
          { id: 'VALIDATION.FIELD.MAX_LENGTH' },
          { count: 256 }
        )
      ),
    year: Yup.object({
      value: Yup.number()
        .required()
        .min(
          1950,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MIN_VALUE' },
            { value: 1950 }
          )
        )
        .max(
          2050,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MAX_VALUE' },
            { value: 1950 }
          )
        ),
    }),
    quantitySum: Yup.number().test('quantitySum', '', function (value) {
      const { path, createError } = this;
      if (value !== maxSumValue && value !== 0) {
        return createError({
          path,
          message: intl.formatMessage(
            { id: 'VALIDATION.FIELD.SUM_VALUE_ERROR' },
            { value: maxSumValue, unit }
          ),
        });
      }
      return true;
    }),
    databaseFoundation: Yup.string().required(),
    feedmill_location: Yup.object({
      iso_code: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
    }),
    // is_simulation: Yup.boolean(),
    target_species: Yup.array()
      .of(Yup.string())
      .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' }))
      .min(1, intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
    ingredient_uses: Yup.array().of(
      Yup.object({
        name: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        origin: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        quantity: Yup.number(
          intl.formatMessage(
            { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
            { value: 0 }
          )
        )
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .required(
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_VALUE' },
              { value: 0 }
            )
          )
          .min(
            0,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_VALUE' },
              { value: 0 }
            )
          )
          .max(
            maxSumValue,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MAX_VALUE' },
              { value: maxSumValue }
            )
          ),
        quantityPercentage: Yup.number(
          intl.formatMessage(
            { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
            { value: 0 }
          )
        )
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .required(
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_VALUE' },
              { value: 0 }
            )
          )
          .min(
            0,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_VALUE' },
              { value: 0 }
            )
          )
          .max(
            100,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MAX_VALUE' },
              { value: 100 }
            )
          ),
        inbound_transport_input: Yup.object({
          truck: Yup.number(
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
              { value: 0 }
            )
          )
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .min(
              0,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_VALUE' },
                { value: 0 }
              )
            ),
          sea_shipping: Yup.number(
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
              { value: 0 }
            )
          )
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .min(
              0,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_VALUE' },
                { value: 0 }
              )
            ),
          train: Yup.number(
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
              { value: 0 }
            )
          )
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .min(
              0,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_VALUE' },
                { value: 0 }
              )
            ),
          inland_waterways_shipping: Yup.number(
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
              { value: 0 }
            )
          )
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .min(
              0,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_VALUE' },
                { value: 0 }
              )
            ),
        }),
      })
    ),
    outbound_transport_input: Yup.object({
      truck: Yup.number(
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 0 }
        )
      )
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          0,
          intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_VALUE' }, { value: 0 })
        ),
      inland_waterways_shipping: Yup.number(
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 0 }
        )
      )
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          0,
          intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_VALUE' }, { value: 0 })
        ),
    }),
    on_site_activities_input: Yup.object({
      selfGeneratedRenewables: resourceItems(intl),
      energyTypes: resourceItems(intl),
      electricity_use: Yup.number(
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 0 }
        )
      )
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          0,
          intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_VALUE' }, { value: 0 })
        ),
      gas_use: Yup.number(
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 0 }
        )
      )
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          0,
          intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_VALUE' }, { value: 0 })
        ),
      heat_use: Yup.number(
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 0 }
        )
      )
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          0,
          intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_VALUE' }, { value: 0 })
        ),
      water_use: Yup.number(
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
          { value: 0 }
        )
      )
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          0,
          intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_VALUE' }, { value: 0 })
        ),
    }),
    nutritional_analysis_data: Yup.object().when(
      'target_species',
      (target_species, schema) => {
        if (target_species?.length && target_species[0] === 
            lookupValues.animalTypeFeedAnimalSystem.PIG
          ) {
          return Yup.object({
            crudeProteinContent: numericRequiredWithMinMax(intl, 0, 100),
            phosphorusContent: numericOptionalWithMinMax(intl, 0, 100),
            dryMatterContent: numericOptionalWithMinMax(intl, 0, 100),
            ashContent: numericOptionalWithMinMax(intl, 0, 100),
            digestibleEnergyContent: numericOptionalWithMin(intl, 0),
            grossEnergyContent: numericOptionalWithMin(intl, 0),
            urinaryEnergyContent: numericOptionalWithMin(intl, 0),
          });
        }
        if (target_species?.length && isPoultrySystem(target_species[0])) {
          return Yup.object({
            grossEnergyContent: numericOptionalWithMin(intl, 0),
            dryMatterContent: numericOptionalWithMinMax(intl, 0, 100),
            metabolizableEnergyContent: numericOptionalWithMin(intl, 0, 100),
            crudeProteinContent: numericRequiredWithMinMax(intl, 0, 100),
            ashContent: numericOptionalWithMin(intl, 0, 100),
            pContent: numericOptionalWithMinMax(intl, 0, 100),
          });
        }
        if (target_species?.length && target_species[0] === 
            lookupValues.animalTypeFeedAnimalSystem.BEEF
          ) {
          return Yup.object({
            crudeProteinContent: numericRequiredWithMinMax(intl, 0, 100),
            grossEnergyContent: numericOptionalWithMin(intl, 0),
            dryMatterContent: numericOptionalWithMinMax(intl, 0, 100),
            digestibleEnergyContent: numericOptionalWithMinMax(intl, 0, 100),
            urinaryEnergyContent:numericOptionalWithMinMax(intl, 0, 100),
            ashContent: numericOptionalWithMinMax(intl, 0, 100),
            containsMilk: Yup.string(),
            milk: Yup.number().when('containsMilk', {
              is: (containsMilk) =>
                !containsMilk ||
                containsMilk === intl.formatMessage({
                  id: noValue,
                }),
              then: numericOptionalWithMinMax(intl, 0, 100),
              otherwise: numericRequiredWithMinMax(intl, 0, 100),
            }),
          });
        }
        if (target_species?.length && target_species[0] === 
            lookupValues.animalTypeFeedAnimalSystem.DAIRY_V2
          ) {
          return Yup.object({
            crudeProteinContent: numericRequiredWithMinMax(intl, 0, 100),
            grossEnergyContent: numericOptionalWithMin(intl, 0),
            dryMatterContent: numericOptionalWithMinMax(intl, 0, 100),
            digestibleEnergyContent: numericOptionalWithMinMax(intl, 0, 100),
            ashContent: numericOptionalWithMinMax(intl, 0, 100),
            neutralDetergentFiber: numericOptionalWithMinMax(intl, 0, 100),
            containsMilk: Yup.string(),
            milk: Yup.number().when('containsMilk', {
              is: (containsMilk) =>
                !containsMilk ||
                containsMilk === intl.formatMessage({
                  id: noValue,
                }),
              then: numericOptionalWithMinMax(intl, 0, 100),
              otherwise: numericRequiredWithMinMax(intl, 0, 100),
            }),
          });
        }
        return schema;
      }
    ),
  });

export default compoundFeedValidationSchema;
