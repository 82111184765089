import React, { FC, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import { AnimalType, Maybe } from '../../../../../../graphql/types';
import { SalmonBaseline } from '../../../../models/Baseline/SalmonBaseline';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';
import { BaselineStageProps } from '../../common';
import { CompoundFeedNameDatabase } from '../types';
import { stageDataPartMarine } from '../../../../../modules/Farms/Baseline/validationSchema/marineBaselineValidation';
import { defaultValues } from '../../baselineDefaultValues';
import SFeedsFormDialog from './SFeedsFormDialog';
import SInputFormDialog from './SInputFormDialog';
import SOperationsFormDialog from './SOperationsFormDialog';
import SOutputFormDialog from './SOutputFormDialog';
import StageButtonBox from '../../StageButtonBox_v2';

interface MarineFishStagesProps
  extends Omit<BaselineStageProps, 'baseline' | 'item' | 'compoundFeeds'> {
  baseline: SalmonBaseline;
  masterDataEnums: MasterDataEnumType;
  item: any;
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  animalType: AnimalType.SalmonV2 | AnimalType.SeaBream | AnimalType.SeaBass;
  calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

interface CloseDialogData {
  type: string;
  setFunc: React.Dispatch<React.SetStateAction<number>>;
  prefix: 'input' | 'output' | 'feed' | 'operations';
}

interface InitialCalcuationData {
  prefix: 'input' | 'output' | 'feed' | 'operations';
}

const MarineFishStages: FC<MarineFishStagesProps> = ({
  item,
  stageIndex,
  baseline,
  compoundFeeds,
  animalType,
  masterDataEnums,
  formType,
  calculateCompleteness,
}) => {
  const [activeDialog, setActiveDialog] = useState<string | null>('');
  const [stockingCompleteness, setStockingCompleteness] = useState(0);
  const [feedCompleteness, setFeedCompleteness] = useState(0);
  const [operationsCompleteness, setOperationsCompleteness] = useState(0);
  const [outputCompleteness, setOutputCompleteness] = useState(0);

  const showDialog = (dialog: React.SetStateAction<string>) => {
    setActiveDialog(dialog);
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, errors, trigger, clearErrors } = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;

  const initialCalculation = (initialCalcuationData: InitialCalcuationData) => {
    const validationObject = item.stageData[initialCalcuationData.prefix];
    const defaultObject =
      defaultValues[animalType]?.stages[0]?.stageData[
        initialCalcuationData.prefix
      ];

    if (item.newStage && isEqual(validationObject, defaultObject)) return 0;
    else
      return calculateCompleteness(
        stageDataPartMarine,
        `stages[${stageIndex}].stageData.${initialCalcuationData.prefix}`
      );
  };

  const closeDialog = (closeDialogData: CloseDialogData) => {
    const completeness = calculateCompleteness(
      stageDataPartMarine,
      `stages[${stageIndex}].stageData.${closeDialogData.prefix}`
    );
    if (formType !== 'view' && closeDialogData.type === 'confirm') {
      closeDialogData.setFunc(completeness);
    } else if (closeDialogData.type === 'reset') {
      if (!completeness) trigger(`${stagePrefix}.stageData.${activeDialog}`);
      else clearErrors(`${stagePrefix}.stageData.${activeDialog}`);
    }
    setActiveDialog(null);
  };

  useEffect(() => {
    if (formType === 'edit' && !item.newStage) {
      setStockingCompleteness(initialCalculation({ prefix: 'input' }));
      setFeedCompleteness(initialCalculation({ prefix: 'feed' }));
      setOperationsCompleteness(initialCalculation({ prefix: 'operations' }));
      setOutputCompleteness(initialCalculation({ prefix: 'output' }));
    }
  }, [formType]);

  return (
    <>
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        defaultValue={item?.stageData?.animalType}
      />
      {activeDialog === 'input' && (
        <SInputFormDialog
          formVisible={activeDialog === 'input'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setStockingCompleteness,
              prefix: 'input',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {activeDialog === 'feed' && (
        <SFeedsFormDialog
          formVisible={activeDialog === 'feed'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({ type, setFunc: setFeedCompleteness, prefix: 'feed' })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          masterDataEnums={masterDataEnums}
          animalType={animalType}
        />
      )}
      {activeDialog === 'operations' && (
        <SOperationsFormDialog
          formVisible={activeDialog === 'operations'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOperationsCompleteness,
              prefix: 'operations',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          masterDataEnums={masterDataEnums}
        />
      )}
      {activeDialog === 'output' && (
        <SOutputFormDialog
          formVisible={activeDialog === 'output'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputCompleteness,
              prefix: 'output',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      <Box
        style={{ display: 'flex', width: '80%' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE"
          iconCode="spiecies/aquaculture"
          descriptionTitle="SUSTELL.SALMON.STAGE.STOCKING_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={stockingCompleteness}
          error={
            !stockingCompleteness &&
            errors?.stages &&
            errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog('input')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.SALMON.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={feedCompleteness}
          error={
            !feedCompleteness &&
            errors?.stages &&
            errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog('feed')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"
          iconCode="general/tool-02"
          descriptionTitle="SUSTELL.SALMON.STAGE.OPERATIONS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={operationsCompleteness}
          error={
            !operationsCompleteness &&
            errors?.stages &&
            errors?.stages[stageIndex]?.stageData?.operations
          }
          handleOpen={() => showDialog('operations')}
        />
        <StageButtonBox
          titleCode="SUSTELL.SALMON.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.SALMON.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={outputCompleteness}
          error={
            !outputCompleteness &&
            errors?.stages &&
            errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog('output')}
        />
      </Box>
    </>
  );
};

export default MarineFishStages;
