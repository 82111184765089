import { FC } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { processAndStageStylesV2 } from '../../../_metronic/layout';
import { CSSClassesList } from '../../sustell_15/helpers/helperTypes';
import { resolvePath } from './resolvePathFn';

interface ReactHookDsmRadioGroupProps {
  name: string;
  label: string;
  options: RadioGroupOption[];
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  changeHandler?: (option: string) => void;
}

export interface RadioGroupOption {
  value: string;
  label: string;
}

const ReactHookDsmRadioGroup: FC<ReactHookDsmRadioGroupProps> = ({
  name,
  label,
  options,
  defaultValue,
  required = false,
  disabled = false,
  changeHandler
}) => {
  const { errors, control } = useFormContext();
  const localError: FieldError | null = resolvePath(
    errors,
    name,
    null
  ) as FieldError | null;
  const labelValue = `${label}${required ? ' *' : ''}`;
  const classes = processAndStageStylesV2() as CSSClassesList;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ onChange }) => {
        return (
          <div className={classes.radioGroupContainer}>
            <p className={`${classes.radioGroupLabel} ${localError ? classes.radioGroupLabelError : ''}`}>{labelValue}</p>
            <div className={classes.radioGroupInputList}>
              {options.map((option) => (
                <div key={option.value} className={classes.radioGroupInputContainer}>
                  <input
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={(defaultValue || '') === option.value}
                    onChange={(e) => { 
                      onChange(e);
                      if (changeHandler)
                        changeHandler(option.value);
                    }}
                    disabled={disabled}
                  />
                  <p className={classes.radioGroupInputLabel}>{option.label}</p>
                </div>
              ))}
            </div>
            <p className={classes.radioGroupErrorMessage}>{localError && localError.message}</p>
          </div>
        );
      }}
    />
  );
};

export default ReactHookDsmRadioGroup;
