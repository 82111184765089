import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { Tooltip } from '@material-ui/core';

import { DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import lookupValues from '../../../../helpers/lookupValues';
import {
  SalmonBaseline,
  SalmonOperations,
} from '../../../../models/Baseline/SalmonBaseline';
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';
import { BaselineDialogProps, FormType } from '../../common';
import SustellBoatInput from './SustellBoatInput';
import SustellDynamicInput from './SustellDynamicInput';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute',
  height: '16px',
  width: '16px',
  top: '28px',
  right: '7px',
};

interface SOperationsFormDialogProps
  extends Omit<BaselineDialogProps, 'baseline'> {
  masterDataEnums: MasterDataEnumType | null;
  baseline: SalmonBaseline;
}

const SOperationsFormDialog: FC<SOperationsFormDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  handleCancel,
  masterDataEnums,
  baseline,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  const currResetValue = useRef<SalmonOperations>();

  const dynamicItem = { type: '', amount: '' };
  const boatItem = { boatName: '', type: '', amount: '' };

  const fieldItemPrefix = `stages.${itemIndex}.stageData.operations`;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { reset, trigger, getValues } = useFormContext<SalmonBaseline>();

  const baselineOperations =
    baseline?.stages?.[itemIndex]?.stageData?.operations;

  const prepopulateEmptyField = () => {
    return formType === FormType.Add ? [{...dynamicItem}] : []; 
  }

  const [materialsFarmConstruction, setMaterialsFarmConstruction] = useState(
    baselineOperations?.materialsFarmConstruction &&
      baselineOperations?.materialsFarmConstruction?.length > 0
      ? baselineOperations.materialsFarmConstruction
      : [{ ...dynamicItem }]
  );

  const [liceTreatment, setLiceTreatment] = useState(
    baselineOperations?.liceTreatment &&
      baselineOperations?.liceTreatment?.length > 0
      ? baselineOperations.liceTreatment
      : [prepopulateEmptyField()]
  );

  const [transport, setTransport] = useState(
    baselineOperations?.transport && baselineOperations?.transport?.length > 0
      ? baselineOperations.transport
      : [{ ...boatItem }]
  );

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE',
  });

  useEffect(() => {
    setTransport(
      baseline?.stages?.[itemIndex]?.stageData?.operations?.transport || [
        { ...boatItem },
      ]
    );
    setMaterialsFarmConstruction(
      baseline?.stages?.[itemIndex]?.stageData?.operations
        ?.materialsFarmConstruction || [{ ...dynamicItem }]
    );
    setLiceTreatment(
      baseline?.stages?.[itemIndex]?.stageData?.operations?.liceTreatment || prepopulateEmptyField()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as SalmonBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.operations) {;
        resetObject.stages[itemIndex].stageData.operations = {
          ...currResetValue.current,
        };
        reset(resetObject, {
          errors: true,
        });
        // reset dynamic list fields
        // to previously valid (confirmed)
        setMaterialsFarmConstruction(resetObject.stages[itemIndex].stageData.operations?.materialsFarmConstruction || []);
        setLiceTreatment(resetObject.stages[itemIndex].stageData.operations?.liceTreatment || []);
        setTransport(resetObject.stages[itemIndex].stageData.operations?.transport || []);
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      variant="demiWide"
      iconCode="general/tool-02"
    >
      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
        <div>
          {intl.formatMessage({ id: 'SUSTELL.SALMON.STAGE.OPERATIONS.NOTE'})}
        </div>
        <br />
        <SustellBoatInput
          key={`${fieldItemPrefix}.boats`}
          label={intl.formatMessage({ id: 'BASELINE.FORM.SALMON.BOATS' })}
          availableOptions={getTranslatedEnumOrDefaultLookupValues(
            masterDataEnums?.FuelType || [],
            lookupValues.fuelTypes,
            intl,
            'MASTERDATA.ENUM.ENERGY_CARRIER_TYPE'
          )}
          // existingItems={props.baseline?.transport ? props.baseline.transport : [boatItem]}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="transport"
          // handleLoadMasterData={props.handleLoadMasterData}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.BOATS.FUEL_USE_FOR.TOOLTIP',
          })}
          formState={formType}
          data={transport}
        />

        <SustellDynamicInput
          key={`${fieldItemPrefix}.materialsFarmConstruction`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.MATERIAL_FARM',
          })}
          availableOptions={getTranslatedEnumOrDefaultLookupValues(
            masterDataEnums?.MaterialType || [],
            lookupValues.materials,
            intl,
            'MASTERDATA.ENUM.MATERIALS'
          )}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="materialsFarmConstruction"
          // handleLoadMasterData={props.handleLoadMasterData}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.MATERIAL_FARM_TT',
          })}
          formState={formType}
          data={materialsFarmConstruction}
          setData={setMaterialsFarmConstruction}
          disableAddButtonWhenAllOptionSelected
          atLeastOneRequired
          required
        />

        <SustellDynamicInput
          key={`${fieldItemPrefix}.liceTreatment`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.LICE_TREATMENT',
          })}
          availableOptions={getTranslatedEnumOrDefaultLookupValues(
            masterDataEnums?.LiceTreatmentType || [],
            lookupValues.liceTreatment,
            intl,
            'MASTERDATA.ENUM.LICE_TREATMENT'
          )}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="liceTreatment"
          // handleLoadMasterData={props.handleLoadMasterData}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.LICE_TREATMENT_TT',
          })}
          formState={formType}
          data={liceTreatment}
          setData={setLiceTreatment}
          disableAddButtonWhenAllOptionSelected
        />
        <DsmGrid
          style={{
            width: '80%',
            position: 'relative',
            grid: 'auto/auto 24px',
            gap: 'var(--dsm-spacing-px-4)',
            padding: '0px',
            alignItems: 'start',
            rowGap: 'var(--dsm-spacing-px-05)',
          }}
        >
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.antifouling`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.ANTIFOULING',
            })}
            adornment="kg"
            disabled={formType === FormType.View}
            type="number"
            defaultValue={getValues(`${fieldItemPrefix}.antifouling`)}
          />
          <Tooltip
            title={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.ANTIFOULING_TT',
            })}
            placement="left"
          >
            <DsmIcon
              name="general/help-circle"
              style={inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </DsmGrid>
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => {await trigger(`stages.${itemIndex}.stageData.operations`); handleCancel('confirm')}}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </DsmGrid>
    </DialogContainer>
  );
};

export default SOperationsFormDialog;
