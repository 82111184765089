export const avgLiveWeightMins = {
  'Laying hens': 1.29,
  'Pig fattening':25,
  'Broiler':0.042,
  'Pig-Pigs': 100,
  'Pig-Piglets': 2
}

export const avgLiveWeightMaxs = {
  'Laying hens': 6,
  'Pig fattening': undefined, // TODO: check, no max val?
  'Broiler': 6,
  'Pig-Pigs': 350,
  'Pig-Piglets': 15
}


export const defaultValuesForMortalityData = {
  'Laying hens': { mortalityRate: 0, averageLiveweightMortality: 1.4},
  'Pig fattening':{ mortalityRate: 0, averageLiveweightMortality: 75},
  'Broiler':{ mortalityRate: 0, averageLiveweightMortality: 1.2}
};

export const nameMapping = {
  'Laying hens':'Laying hens',
  'Pig':'Pig fattening',
  'Broilers':'Broiler',
  'Dairy':'Dairy'
}