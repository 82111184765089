import { FC, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Grid } from '@material-ui/core';
import { PigFatteningBaseline } from "../../../../models/Baseline";
import { FeedsFormDialog } from "../../CommonDataParts/FeedsFormDialog";
import StageButtonBox from "../../StageButtonBox";
import { BaselineStageProps } from "../../common";
import PFEmissionsFormDialog from "./PFEmissionsFormDialog";
import PFFeedsVariablePart from "./PFFeedsVariablePart";
import PFInputFormDialog from "./PFInputFormDialog";
import PFManureFormDialog from "./PFManureFormDialog";
import PFOutputFormDialog from "./PFOutputFormDialog";

interface PigFatteningStagesProps extends Omit<BaselineStageProps, 'baseline'> {
  baseline: PigFatteningBaseline;
}

const PigFatteningStages: FC<PigFatteningStagesProps> = ({
  stageIndex,
  baseline,
  getCompleteness,
  manureManagementSystems,
  compoundFeeds,
  singleIngredients, 
  item,
  formType
}) => {

  const [activeDialog, setActiveDialog] = useState<string | null>('');

  const showDialog = (dialog: SetStateAction<string|null>) =>{
    setActiveDialog(dialog)
  }
  const formContext = useFormContext();
  const stagePrefix =`stages.${stageIndex}`

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        defaultValue={item.animalType}
      />
      <PFInputFormDialog formVisible={ activeDialog==='input' } 
        handleCancel={ () => setActiveDialog(null)} 
        formType={formType}
        baseline={baseline} itemIndex={stageIndex} />
      <FeedsFormDialog formVisible={ activeDialog==='feed' } 
        handleCancel={ () => setActiveDialog(null)} 
        formType={formType}
        baseline={baseline} 
        itemIndex={stageIndex}
        compoundFeeds={compoundFeeds}  
        singleIngredients={singleIngredients}
        >
        <PFFeedsVariablePart 
          itemIndex={stageIndex}
          formType={formType}
           handleCancel={() => undefined}        
          />
      </FeedsFormDialog>
      <PFManureFormDialog formVisible={ activeDialog==='manure' } 
        formType={formType}
        handleCancel={ () => setActiveDialog(null)} 
        baseline={baseline} itemIndex={stageIndex}
        manureManagementSystems = {manureManagementSystems}  
      />
      <PFEmissionsFormDialog 
        formVisible={ activeDialog==='emissions' } 
        formType={formType}
        handleCancel={ () => setActiveDialog(null)} 
        baseline={baseline} 
        itemIndex={stageIndex}
        />
      <PFOutputFormDialog formVisible={ activeDialog==='output' } 
        formType={formType}
        handleCancel={ () => setActiveDialog(null)} 
        baseline={baseline}
        itemIndex={stageIndex}
        />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"  iconCode='PIG_FATTENING_INPUT' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.input`) : 0 } 
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.input}
                          handleOpen={()=>showDialog('input')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE" iconCode='feedIcon' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.feed`) : 0 }  
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.feed}
                          handleOpen={()=>showDialog('feed')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"  iconCode='manureIcon' 
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.manure`) : 0 } 
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.manure}
                          handleOpen={()=>showDialog('manure')} />
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE" iconCode='PIG_FATTENING'  
                          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.output`) : 0 } 
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.output}
                          handleOpen={()=>showDialog('output')}/>
          <StageButtonBox titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE" iconCode='emissionIcon' 
                          optional
                          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.emissions}
                          handleOpen={()=>showDialog('emissions')}/>
      </Grid>
    </>
  );
};

export default PigFatteningStages;