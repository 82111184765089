import { FC, SetStateAction, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Grid } from "@material-ui/core";
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup"
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import SustellBovaerInput from './SustellBovaerInput';
import { BaselineDialogProps } from '../../common';
import { DairyBaseline, DairyEmissions, LatestCalc3NOP } from '../../../../models/Baseline/DairyBaseline';
import { Maybe } from '../../../../../../graphql/types';

interface DEmissionsFormDialogProps extends BaselineDialogProps {
  // eslint-disable-next-line react/require-default-props
  latestBovaerCalc?: Maybe<LatestCalc3NOP>;
  setLatestBovaerCalc: (latestBovaerCalc?: SetStateAction<LatestCalc3NOP | null>) => void;
}

const DEmissionsFormDialog: FC<DEmissionsFormDialogProps> = ({
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  formVisible=false,
  latestBovaerCalc=undefined,
  setLatestBovaerCalc,
}) => {
  const intl = useIntl();
  
  const formTitle = intl.formatMessage({id: "SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions`; 
  const formContext = useFormContext<DairyBaseline>();

  const is3NOPChecked = useWatch({
    control: formContext.control,
    name: `${fieldItemPrefix}.is3NOPSelected`,
  });


  const watchTotalMethaneFromF = useWatch({
    name: `${fieldItemPrefix  }.methaneEntericFermentation`,
    defaultValue: ''
  });

  const watchCalc3NOPResult = useWatch({
    name: `stages.${itemIndex}.stageData.emissions.supplement3NOP.calculatedResult`,
    defaultValue:''
  });

  const totalMethaneWithEmmission = () => {
    let retVal = 0;
    if (watchTotalMethaneFromF && !Number.isNaN(watchTotalMethaneFromF)) {
      retVal += parseFloat(watchTotalMethaneFromF);
    }
    if (watchCalc3NOPResult && !Number.isNaN(watchCalc3NOPResult)) {
      retVal += parseFloat(watchCalc3NOPResult);
    }
    return retVal.toFixed(2);
  }

  const currResetValue = useRef<DairyEmissions>();

  useEffect(() => {
    if(formVisible){
      currResetValue.current={...formContext.getValues(fieldItemPrefix)} as DairyEmissions; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as DairyBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.emissions){
        resetObject.stages[itemIndex].stageData.emissions = {...currResetValue.current};
        formContext.reset(resetObject, { errors: true });
      }
    }
    
    if(handleCancel)
      handleCancel();
  }

  const welcomeString = intl.formatMessage({id: "EMISSIONS.WELCOME"});
  
  return (
    <DialogContainer
      formVisible = { formVisible }
      variant = 'wide'
      handleClose = { handleResetClick }
      iconCode="emissionIcon"
      formTitle = { formTitle }
      introText = { intl.formatMessage({id: "EMISSIONS.INTRO"}, {boldedWelcome: <strong>{welcomeString}</strong>, br: <br/>}) }
    > 
      <Grid container spacing={3} direction="row" justifyContent="space-between" >
        <Grid item container direction="column" xs={6} spacing={3} >
          <Grid item>
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.methaneEntericFermentation`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP"})}
              addornment="%"
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.methaneEntericFermentation`)}
            />
          </Grid>
          { is3NOPChecked && 
            <Grid item>
              <div style={{ marginLeft: '4px', marginBottom: '1px', marginTop: '-10px' }}>
                <span>{intl.formatMessage({ id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.EMMISION_CORRECTION' })}:
                   <b>{(watchCalc3NOPResult && !Number.isNaN(watchCalc3NOPResult)) ? (`${parseFloat(watchCalc3NOPResult).toFixed(2)  }%`) : ''}</b> 
                </span>
                <span
                  style={{ marginLeft: '8px' }}>{intl.formatMessage({ id: 'BASELINE.FORM.BARN.EMISSIONS.TOTAL_METHANE_FROM_E_F' })}:
                  <b>
                    {`${totalMethaneWithEmmission()  }%`}
                  </b>
                </span>
              </div>
            </Grid>
          }
          <Grid item>
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.methane`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE.TOOLTIP"})}
              addornment="%"
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.methane`)}
            />
          </Grid>
          <Grid item >
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.nitrousOxideDirect`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP"})}
              addornment="%"
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrousOxideDirect`)}
            />
          </Grid>
          <Grid item >
            <RowTextFieldWithInfo
              control={formContext.control}
              name={`${fieldItemPrefix  }.nitrousOxideIndirect`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
              addornment="%"
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrousOxideIndirect`)}
            />
          </Grid>
          <Grid item  >
	            <RowTextFieldWithInfo
	              control={formContext.control}
	              name={`${fieldItemPrefix  }.amonia`}
	              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.AMMONIA"})}
	              margin="none"
	              variant="outlined"
	              tooltip={intl.formatMessage({id:  "BASELINE.FORM.EMISSIONS.AMMONIA.TOOLTIP"})}
	              addornment="%"
	              disabled={formType === "view"}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.amonia`)}
	            />
	          </Grid>
	        </Grid>
	        <Grid item container direction="column" xs={6} spacing={3} >
            <Grid item  >
                <RowTextFieldWithInfo
                  control={formContext.control}
                  name={`${fieldItemPrefix  }.nitricOxide`}
                  label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE"})}
                  margin="none"
                  variant="outlined"
                  tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE.TOOLTIP"})}
                  addornment="%"
                  disabled={formType === "view"}
                  defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitricOxide`)}
                />
	          </Grid>
	          <Grid item >
	            <RowTextFieldWithInfo
	              control={formContext.control}
	              name={`${fieldItemPrefix  }.nonMethaneVolatile`}
	              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_ORGANIC"})}
	              margin="none"
	              variant="outlined"
	              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
	              addornment="%"
	              disabled={formType === "view"}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nonMethaneVolatile`)}
	            />
	          </Grid>
	          <Grid item >
	            <RowTextFieldWithInfo
	              control={formContext.control}
	              name={`${fieldItemPrefix  }.nonMethaneVolatileSilage`}
                label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_SILAGE"})}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_SILAGE.TOOLTIP"})}
                addornment="%"
                disabled={formType === "view"}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nonMethaneVolatileSilage`)}
              />
            </Grid>
            <Grid item  >
              <RowTextFieldWithInfo
                control={formContext.control}
                name={`${fieldItemPrefix  }.PM25`}
                label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5"})}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5.TOOLTIP"})}
                addornment="%"
                disabled={formType === "view"}
                defaultValue = {formContext.getValues(`${fieldItemPrefix  }.PM25`)}
              />
            </Grid>
            <Grid item>
              <SustellBovaerInput
                name={fieldItemPrefix}
                formType={formType}
                disabled={formType === 'view'}
                animalType="DAIRY"
                country="NL" // TODO This need to be fixed, depending will farm be kept in redux
                itemIndex={itemIndex}
                latestBovaerCalc={latestBovaerCalc}
                setLatestBovaerCalc={setLatestBovaerCalc}
              />
            </Grid>
	        </Grid>
	        <Grid item xs={12}>
	          <ButtonControlGroup
	            cancelHandler={handleResetClick}
	            saveHandler={ handleSave }
	            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
	          />
	        </Grid>
	      </Grid>
	    </DialogContainer>
	);
};

export default DEmissionsFormDialog;