import { InterventionsMappedDataType } from "../../sustell_15/components/FarmFlow/Intervention/types";
import { store } from "../store";

/** Helper function to get draft with key directly from store */
const getInterventionDraftByKey = (key: string): InterventionsMappedDataType | undefined => {
  if (!store) {
    console.error('store is not defiend');
    return;
  }
  return store.getState().interventionDrafts.value[key]
};

export default getInterventionDraftByKey;