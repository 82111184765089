import {
  mapCommonInterventionDataIn,
  mapResourceUse,
} from './inCommonMapperSustellV2';
import { interventionFloatValueInUserUnits } from './inCommonMapperSustell';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';

const mapInput = (stageData, stageInputFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  const stocking = stageData?.stocking;
  if (
    stocking?.stockedAnimalsNumber?.value ||
    stocking?.stockedAnimalsNumber?.value === 0
  ) {
    stageInputFormData.numberOfStockedAnimals =
      stocking.stockedAnimalsNumber?.value;
    stageInputFormData.numberOfStockedAnimals_changeMetric =
      stocking.stockedAnimalsNumber?.change_type || 'relative';
  }

  if (stocking?.averageWeight?.value || stocking?.averageWeight?.value === 0) {
    stageInputFormData.averageLiveWeightStockedAnimals =
      interventionFloatValueInUserUnits(
        stocking?.averageWeight.value,
        userUOM.unitOutputMass,
        defaultMetric.unitOutputMass,
        stocking.averageWeight.change_type,
        outputMassPrecision
      );
    stageInputFormData.averageLiveWeightStockedAnimals_changeMetric =
      stocking.averageWeight.change_type || 'relative';
  }

  if (stocking?.stockedAnimalsWeight || stocking?.stockedAnimalsWeight === 0) {
    stageInputFormData.weightOfStockedAnimals =
      interventionFloatValueInUserUnits(
        stocking?.stockedAnimalsWeight,
        userUOM.unitOutputMass,
        defaultMetric.unitOutputMass,
        'set',
        outputMassPrecision
      );
  }
};

const mapUsedFeed = (stageData, stageFeedFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const feed = stageData?.feedingProgram;

  stageFeedFormData.compoundFeeds = [];
  if (feed?.compoundFeedUses && Array.isArray(feed?.compoundFeedUses)) {
    feed?.compoundFeedUses.forEach((oneFeedData) => {
      const feedChangeMetric = oneFeedData.quantity?.change_type || 'relative';
      let value = oneFeedData.quantity?.value;
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      const oneCompoundFeed = {
        feedType: oneFeedData.compoundFeedId,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      stageFeedFormData.compoundFeeds.push(oneCompoundFeed);
    });
  }

  //check feeds additional ration
  if (feed?.compoundFeedUsesAdditions && Array.isArray(feed?.compoundFeedUsesAdditions)) {
    stageFeedFormData.compoundFeedsAdditions = [];
    feed?.compoundFeedUsesAdditions.forEach((addnlFeedData) => {
      const oneCompoundFeed = {
        feedType: addnlFeedData.compoundFeedId,
        kgPerAnimal: addnlFeedData.quantity,
      };
      stageFeedFormData.compoundFeedsAdditions.push(oneCompoundFeed);
    });
  }
};

const mapNutrient = (stageData, stageFeedFormData) => {
  const feed = stageData?.feedingProgram;
  const nutrientComposition = feed.nutrientComposition || {};
  if (!stageFeedFormData.nutrientComposition)
    stageFeedFormData.nutrientComposition = {};

  if (
    nutrientComposition?.crudeProtein?.value ||
    nutrientComposition?.crudeProtein?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.crudeProtein =
      nutrientComposition?.crudeProtein?.value;
    stageFeedFormData.nutrientComposition.crudeProtein_changeMetric =
      nutrientComposition?.crudeProtein?.change_type || 'relative';
  }

  if (
    nutrientComposition?.crudeLipid?.value ||
    nutrientComposition?.crudeLipid?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.crudeLipid =
      nutrientComposition?.crudeLipid?.value;
    stageFeedFormData.nutrientComposition.crudeLipid_changeMetric =
      nutrientComposition?.crudeLipid?.change_type || 'relative';
  }

  if (
    nutrientComposition?.crudeFibre?.value ||
    nutrientComposition?.crudeFibre?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.crudeFibre =
      nutrientComposition?.crudeFibre?.value;
    stageFeedFormData.nutrientComposition.crudeFibre_changeMetric =
      nutrientComposition?.crudeFibre?.change_type || 'relative';
  }

  if (
    nutrientComposition?.starch?.value ||
    nutrientComposition?.starch?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.starch =
      nutrientComposition?.starch?.value;
    stageFeedFormData.nutrientComposition.starch_changeMetric =
      nutrientComposition?.starch?.change_type || 'relative';
  }

  if (
    nutrientComposition?.sugar?.value ||
    nutrientComposition?.sugar?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.sugar =
      nutrientComposition?.sugar?.value;
    stageFeedFormData.nutrientComposition.sugar_changeMetric =
      nutrientComposition?.sugar?.change_type || 'relative';
  }

  if (
    nutrientComposition?.phosphorous?.value ||
    nutrientComposition?.phosphorous?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.phosphorous =
      nutrientComposition?.phosphorous?.value;
    stageFeedFormData.nutrientComposition.phosphorous_changeMetric =
      nutrientComposition?.phosphorous?.change_type || 'relative';
  }

  if (
    nutrientComposition?.feedSpill?.value ||
    nutrientComposition?.feedSpill?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.feedSpill =
      nutrientComposition?.feedSpill?.value;
    stageFeedFormData.nutrientComposition.feedSpill_changeMetric =
      nutrientComposition?.feedSpill?.change_type || 'relative';
  }

  if (
    nutrientComposition?.dryMatter?.value ||
    nutrientComposition?.dryMatter?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.dryMatter =
      nutrientComposition?.dryMatter?.value;
    stageFeedFormData.nutrientComposition.dryMatter_changeMetric =
      nutrientComposition?.dryMatter?.change_type || 'relative';
  }

  if (
    nutrientComposition?.solubleNitrogen?.value ||
    nutrientComposition?.solubleNitrogen?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.solubleNitrogen =
      nutrientComposition?.solubleNitrogen?.value;
    stageFeedFormData.nutrientComposition.solubleNitrogen_changeMetric =
      nutrientComposition?.solubleNitrogen?.change_type || 'relative';
  }

  if (
    nutrientComposition?.solublePhosphorous?.value ||
    nutrientComposition?.solublePhosphorous?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.solublePhosphorous =
      nutrientComposition?.solublePhosphorous?.value;
    stageFeedFormData.nutrientComposition.solublePhosphorous_changeMetric =
      nutrientComposition?.solublePhosphorous?.change_type || 'relative';
  }

  if (
    nutrientComposition?.solubleCarbon?.value ||
    nutrientComposition?.solubleCarbon?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.solubleCarbon =
      nutrientComposition?.solubleCarbon?.value;
    stageFeedFormData.nutrientComposition.solubleCarbon_changeMetric =
      nutrientComposition?.solubleCarbon?.change_type || 'relative';
  }

  if (
    nutrientComposition?.digestibleNitrogen?.value ||
    nutrientComposition?.digestibleNitrogen?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.digestibleNitrogen =
      nutrientComposition?.digestibleNitrogen?.value;
    stageFeedFormData.nutrientComposition.digestibleNitrogen_changeMetric =
      nutrientComposition?.digestibleNitrogen?.change_type || 'relative';
  }

  if (
    nutrientComposition?.digestibleCarbon?.value ||
    nutrientComposition?.digestibleCarbon?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.digestibleCarbon =
      nutrientComposition?.digestibleCarbon?.value;
    stageFeedFormData.nutrientComposition.digestibleCarbon_changeMetric =
      nutrientComposition?.digestibleCarbon?.change_type || 'relative';
  }

  if (
    nutrientComposition?.digestiblePhosphorous?.value ||
    nutrientComposition?.digestiblePhosphorous?.value === 0
  ) {
    stageFeedFormData.nutrientComposition.digestiblePhosphorous =
      nutrientComposition?.digestiblePhosphorous?.value;
    stageFeedFormData.nutrientComposition.digestiblePhosphorous_changeMetric =
      nutrientComposition?.digestiblePhosphorous?.change_type || 'relative';
  }
};

const mapFeed = (stageData, stageFeedFormData) => {
  mapUsedFeed(stageData, stageFeedFormData);
  mapNutrient(stageData, stageFeedFormData);
};

const mapTransport = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const transport = [];
  if (stageData?.transport && Array.isArray(stageData?.transport)) {
    stageData.transport.forEach((oneTransport) => {
      let value = oneTransport.amount;
      if (oneTransport.type === 'electricity') {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitElectricityUse,
          defaultMetric.unitElectricityUse,
          value.change_type,
          3
        );
      } else {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitDieselUse,
          defaultMetric.unitDieselUse,
          value.change_type,
          3
        );
      }
      const oneTransportFormData = {
        boatName: oneTransport.boatName,
        type: oneTransport.type,
        amount: value.value,
        amount_changeMetric: value.change_type,
      };

      transport.push(oneTransportFormData);
    });
  }
  stageOperationsFormData.transport = transport;

  const transportAddition = [];
  if (
    stageData?.transportAdditions &&
    Array.isArray(stageData.transportAdditions)
  ) {
    stageData.transportAdditions.forEach((oneTransport) => {
      let value = oneTransport.amount;
      if (oneTransport.type === 'electricity') {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitElectricityUse,
          defaultMetric.unitElectricityUse,
          'set',
          3
        );
      } else {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitDieselUse,
          defaultMetric.unitDieselUse,
          'set',
          3
        );
      }

      const oneTransportFormData = {
        boatName: oneTransport.boatName,
        type: oneTransport.type,
        amount: value,
      };

      transportAddition.push(oneTransportFormData);
    });
  }
  stageOperationsFormData.transportAddition = transportAddition;
};

const mapLiceTretament = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const liceTretaments = [];
  if (
    stageData?.liceTreatment &&
    Array.isArray(stageData.liceTreatment)
  ) {
    stageData.liceTreatment.forEach((oneLiceTreatment) => {
      const value = interventionFloatValueInUserUnits(
        oneLiceTreatment.amount.value,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        oneLiceTreatment.amount.change_type,
        precision
      );

      const oneLiceTreatmentFormData = {
        type: oneLiceTreatment.type,
        amount: value,
        amount_changeMetric: oneLiceTreatment.amount.change_type,
      };

      liceTretaments.push(oneLiceTreatmentFormData);
    });
  }
  stageOperationsFormData.liceTreatment = liceTretaments;

  const liceTreatmentAddition = [];
  if (
    stageData?.liceTreatmentAdditions &&
    Array.isArray(stageData.liceTreatmentAdditions)
  ) {
    stageData.liceTreatmentAdditions.forEach((oneLiceTreatment) => {
      const value = interventionFloatValueInUserUnits(
        oneLiceTreatment.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        'set',
        precision
      );

      const oneLiceFormData = {
        type: oneLiceTreatment.type,
        amount: value,
      };

      liceTreatmentAddition.push(oneLiceFormData);
    });
  }
  stageOperationsFormData.liceTreatmentAddition = liceTreatmentAddition;
};

const mapAntibioticsUse = (stageData, stageFeedFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const antibiotics = [];
  if (stageData?.antibiotics && Array.isArray(stageData.antibiotics)) {
    stageData.antibiotics.forEach((oneAntibiotic) => {
      const value = interventionFloatValueInUserUnits(
        oneAntibiotic.amount.value,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        oneAntibiotic.amount.change_type,
        precision
      );

      const oneAntibioticFormData = {
        type: oneAntibiotic.type,
        amount: value,
        amount_changeMetric: oneAntibiotic.amount.change_type,
      };

      antibiotics.push(oneAntibioticFormData);
    });
  }
  stageFeedFormData.antibioticsUse = antibiotics;

  const antibioticsAddition = [];
  if (
    stageData?.antibioticsAdditions &&
    Array.isArray(stageData.antibioticsAdditions)
  ) {
    stageData.antibioticsAdditions.forEach((oneAntibiotic) => {
      const value = interventionFloatValueInUserUnits(
        oneAntibiotic.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        'set',
        precision
      );

      const oneAntibioticFormData = {
        type: oneAntibiotic.type,
        amount: value,
      };

      antibioticsAddition.push(oneAntibioticFormData);
    });
  }
  stageFeedFormData.antibioticsUseAddition = antibioticsAddition;
};

const mapMaterialsForFarmConstruction = (
  stageData,
  stageOperationsFormData
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const materials = [];
  if (stageData?.materials && Array.isArray(stageData.materials)) {
    stageData.materials.forEach((oneMaterial) => {
      const value = interventionFloatValueInUserUnits(
        oneMaterial.amount.value,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        oneMaterial.amount.change_type,
        precision
      );

      const oneMaterialFormData = {
        type: oneMaterial.type,
        amount: value,
        amount_changeMetric: oneMaterial.amount.change_type || 'relative',
      };

      materials.push(oneMaterialFormData);
    });
  }
  stageOperationsFormData.materialsFarmConstruction = materials;

  const materialsAddition = [];
  if (
    stageData?.materialsAdditions &&
    Array.isArray(stageData.materialsAdditions)
  ) {
    stageData.materialsAdditions.forEach((oneMaterial) => {
      const value = interventionFloatValueInUserUnits(
        oneMaterial.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        'set',
        precision
      );

      const oneMaterialFormData = {
        type: oneMaterial.type,
        amount: value,
      };

      materialsAddition.push(oneMaterialFormData);
    });
  }
  stageOperationsFormData.materialsFarmConstructionAddition = materialsAddition;
};

const mapOperations = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  if (stageData?.antifouling?.value || stageData?.antifouling?.value === 0) {
    stageOperationsFormData.antifouling_changeMetric =
      stageData.antifouling.change_type || 'relative';
    stageOperationsFormData.antifouling = interventionFloatValueInUserUnits(
      stageData.antifouling.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOperationsFormData.antifouling_changeMetric,
      outputMassPrecision
    );
  }
  
  mapTransport(stageData, stageOperationsFormData);
  mapLiceTretament(stageData, stageOperationsFormData);
  mapMaterialsForFarmConstruction(stageData, stageOperationsFormData);
};

const mapOutput = (stageData, stageOutputFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  const production = stageData?.production;
  if (production?.amount?.value || production?.amount?.value === 0) {
    stageOutputFormData.production = interventionFloatValueInUserUnits(
      production?.amount?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.production_changeMetric,
      outputMassPrecision
    );
    stageOutputFormData.production_changeMetric =
      production?.amount?.change_type || 'relative';
  }

  if (
    production?.averageWeight?.value ||
    production?.averageWeight?.value === 0
  ) {
    stageOutputFormData.averageWeight = interventionFloatValueInUserUnits(
      production?.averageWeight?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.production_changeMetric,
      outputMassPrecision
    );
    stageOutputFormData.averageWeight_changeMetric =
      production?.averageWeight?.change_type || 'relative';
  }

  const animals = stageData?.animalsPresent;
  if (
    animals?.animalBiomassStartOfYear?.value ||
    animals?.animalBiomassStartOfYear?.value === 0
  ) {
    stageOutputFormData.animalBiomassStartOfYear_changeMetric =
      animals?.animalBiomassStartOfYear?.change_type || 'relative';
    stageOutputFormData.animalBiomassStartOfYear =
      interventionFloatValueInUserUnits(
        animals?.animalBiomassStartOfYear?.value,
        userUOM.unitOutputMass,
        defaultMetric.unitOutputMass,
        stageOutputFormData.animalBiomassStartOfYear_changeMetric,
        outputMassPrecision
      );
  }

  if (
    animals?.averageLiveWeightAnimalsPresent?.value ||
    animals?.averageLiveWeightAnimalsPresent?.value === 0
  ) {
    stageOutputFormData.liveWeight_changeMetric =
      animals?.averageLiveWeightAnimalsPresent?.change_type || 'relative';
    stageOutputFormData.liveWeight = interventionFloatValueInUserUnits(
      animals?.averageLiveWeightAnimalsPresent?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.liveWeight_changeMetric,
      outputMassPrecision
    );
  }

  if (
    animals?.animalBiomassEndOfYear?.value ||
    animals?.animalBiomassEndOfYear?.value === 0
  ) {
    stageOutputFormData.animalBiomasEndOfYear_changeMetric =
      animals?.animalBiomassEndOfYear?.change_type || 'relative';
    stageOutputFormData.animalBiomasEndOfYear =
      interventionFloatValueInUserUnits(
        animals?.animalBiomassEndOfYear?.value,
        userUOM.unitOutputMass,
        defaultMetric.unitOutputMass,
        stageOutputFormData.animalBiomasEndOfYear_changeMetric,
        outputMassPrecision
      );
  }

  const lostAnimals = stageData?.lostAnimals;
  if (
    lostAnimals?.mortalities?.value ||
    lostAnimals?.mortalities?.value === 0
  ) {
    stageOutputFormData.mortalities_changeMetric =
      lostAnimals?.mortalities?.change_type || 'relative';
    stageOutputFormData.mortalities = interventionFloatValueInUserUnits(
      lostAnimals?.mortalities?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.mortalities_changeMetric,
      outputMassPrecision
    );
  }

  if (lostAnimals?.escapees?.value || lostAnimals?.escapees?.value === 0) {
    stageOutputFormData.escapees_changeMetric =
      lostAnimals?.escapees?.change_type || 'relative';

    stageOutputFormData.escapees = interventionFloatValueInUserUnits(
      lostAnimals?.escapees?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.escapees_changeMetric,
      outputMassPrecision
    );
  }
};

const mapMarineFishInterventionDataInSustell = (inData) => {
  
  const formData = mapCommonInterventionDataIn(inData);
  if (inData.resourceUse)
    mapResourceUse(inData.resourceUse, formData.resourceUse);
  inData.stages.forEach((stage, index) => {
    const stageFormData = formData.stages[index]?.stageData;
    const stageData = JSON.parse(stage.stageData) || {};
    if (stageFormData) {
      const stageInputFormPart = stageFormData?.input;
      if (stageInputFormPart) mapInput(stageData, stageInputFormPart);

      const stageFeedFormPart = stageFormData?.feed;
      if (stageFeedFormPart) {
        mapFeed(stageData, stageFeedFormPart);
        mapAntibioticsUse(stageData, stageFeedFormPart);
      };

     
      const stageOperationsFormPart = stageFormData?.operations || {};
      if (stageOperationsFormPart) mapOperations(stageData, stageOperationsFormPart);
      if (!stageFormData?.operations) {
        stageFormData.operations = stageOperationsFormPart;
      }

      const stageOutputFormPart = stageFormData?.output;
      if (stageOutputFormPart) mapOutput(stageData, stageOutputFormPart);
    }
  });
  return formData;
};

export default mapMarineFishInterventionDataInSustell;
