import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { defaultValuesForMortalityData } from '../../validationSchemaRestrictions';
import {
  mapCommonProductionDataOutSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustell';
import { floatValueInDefaultUnit, setFloatValue } from './floatHelperFunctions'; 
import { nameMapping } from '../../validationSchemaRestrictions';

const ANIMAL_TYPE = 'Pig fattening';

const mapPigFatteningProductionDataOutSustell = (formData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  let payloadObj = mapCommonProductionDataOutSustell(formData);
  payloadObj = mapResourceUseSustell(formData, payloadObj);

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if(payloadObj.stages[index]!==undefined){
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const {stageData} = stageGeneralData;

        stageData.animal_group.animal_type = Object.keys(nameMapping).find(key => nameMapping[key] === ANIMAL_TYPE) // Blonk uses this value

        const manureBlockData = formStageData?.manure;
        if(manureBlockData){
          stageData.animal_group.number_of_animals = {
              value:
                parseFloat(formStageData.manure?.averageAnimalsPresent) || null,
          };
        }
        
        // process input block
        // (purchasedAnimalsLayingHens, mortalityRate, averageLiveweightMortality)
        const inputBlockData = formStageData?.input;
        if(inputBlockData){
          stageData.purchased_piglets = {
            number_of_animals: {
              value: parseFloat(inputBlockData.purchasedPiglets),
            },
            average_live_weight: {
              value: floatValueInDefaultUnit(
                inputBlockData.averageLiveWeight,
                userUOM.unitOutputMass,
                defaultMetric.unitOutputMass
              ),
            },
          };
          // set default values for mortality
          stageData.mortality = {
            mortality_rate: {
              value: !isDraft ? defaultValuesForMortalityData[ANIMAL_TYPE].mortalityRate : null,
            },
            average_live_weight_mortalities: {
              value: !isDraft ? defaultValuesForMortalityData[ANIMAL_TYPE].averageLiveweightMortality : null,
            },
          };

          // if existing this will replace defaults with properly calculated input values
          setFloatValue(
            stageData.mortality.mortality_rate,
            'value',
            inputBlockData,
            'mortalityRate'
          );
    
          setFloatValue(
            stageData.mortality.average_live_weight_mortalities,
            'value',
            inputBlockData,
            'averageLiveWeightMortalities',
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          );
        }
        
        // process output block
        // (eggs, liveWeight )
        const outputBlockData = formStageData?.output;
        if(outputBlockData){
          stageData.fattened_pigs = {
            total_weight: {
              value: floatValueInDefaultUnit(
                outputBlockData.liveWeight,
                userUOM.unitOutputMass,
                defaultMetric.unitOutputMass,
                outputMassPrecision
              ),
            },
          };

          const averageInorganicNFertilizerReplacementRate = !isDraft ? 50 : null;
          stageData.inorganic_n_fertilizer_replacement_rate = {
            value:
              outputBlockData.inorganicNFertilizerReplacementRate ||
              outputBlockData.inorganicNFertilizerReplacementRate === 0
                ? outputBlockData.inorganicNFertilizerReplacementRate
                : averageInorganicNFertilizerReplacementRate,
          };
          const averageInorganicPFertilizerReplacementRate = !isDraft ? 100 : null;
          stageData.inorganic_p_fertilizer_replacement_rate = {
            value:
              outputBlockData.inorganicPFertilizerReplacementRate ||
              outputBlockData.inorganicPFertilizerReplacementRate === 0
                ? outputBlockData.inorganicPFertilizerReplacementRate
                : averageInorganicPFertilizerReplacementRate,
          };
        }

        // set housing block of data
        stageData.housing_description = {
          housing_type: 'housed',
        };
        stageData.housing_system = formDataForOneStage.productionSystem;
      }
      
    });
  payloadObj.stages.forEach( item => {
    const {stageData} = item;
    item.stageData = JSON.stringify(stageData);
  })
  return payloadObj;
};

export default mapPigFatteningProductionDataOutSustell;
