import React from 'react';
import { Menu, MenuItem, Fade } from '@material-ui/core';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DSM_BLACK } from '../../../../_metronic/layout';
import { FormType } from '../FarmFlow/common';

type StageActionsMenuProps = {
  editHandler?: (() => void) | undefined,
  deleteHandler?: (() => void) | undefined,
  reorderUpHandler?:(() => void) | undefined,
  reorderDownHandler?:(() => void) | undefined,
  formType?: FormType
}

const StageActionsMenu = ({
  editHandler,
  deleteHandler,
  reorderUpHandler,
  reorderDownHandler,
  formType
}: StageActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
  const open = Boolean(anchorEl);

  const intl = useIntl();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // console.log(event);
    event.stopPropagation();
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleEdit = () => {
    if (editHandler) editHandler();
  };

  const handleDelete = () => {
    if (deleteHandler) deleteHandler();
  };

  const handleMoveUpHandler = () => {
    if (reorderUpHandler) reorderUpHandler();
  }

  const handleMoveDownHandler = () => {
    if (reorderDownHandler) reorderDownHandler();
  };

  return (
    <div style={{ justifySelf: "end"}} >
      <DsmButton 
          aria-controls="fade-menu"
          style={{ alignSelf: 'end'}}
          onClick={(e: any)=>handleClick(e)}
          variant="secondary"
          disabled={ formType === FormType.View}
        >
          {intl.formatMessage({ id: 'GENERAL.EDIT' })}
      </DsmButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {editHandler && (
          <MenuItem
            disabled={handleEdit === null}
            onClick={(e) => {
              e.stopPropagation()
              handleEdit();
              handleClose();
            }}
          >
            <DsmIcon
                name="editor/pencil-01"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({"id": "GENERAL.EDIT"})}
          </MenuItem>
        )}
        {reorderUpHandler && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              handleMoveUpHandler();
              handleClose();
            }}
          >
            <DsmIcon
                name="arrows/arrow-up"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({"id": "GENERAL.MOVE_UP"})}
          </MenuItem>
        )}
        {reorderDownHandler && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              handleMoveDownHandler();
              handleClose();
            }}
          >
            <DsmIcon
                name="arrows/arrow-down"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({"id": "GENERAL.MOVE_DOWN"})}
          </MenuItem>
        )}
        { deleteHandler && 
          <MenuItem
            style={{color: 'var(--dsm-color-error'}}
            disabled={handleDelete === null}
            onClick={(e) => {
              e.stopPropagation()
              handleDelete();
              handleClose();
            }}
          >
            <DsmIcon
              name="general/trash-01"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
              }}
            />
              {intl.formatMessage({"id": "GENERAL.DELETE"})}
          </MenuItem>
        }
      </Menu>
    </div>
  )
};

StageActionsMenu.defaultProps = {
  editHandler: undefined,
  deleteHandler: undefined,
  reorderUpHandler: undefined,
  reorderDownHandler:undefined,
  formType:FormType.View
}

export default StageActionsMenu;