import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';

import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import SustellRationFeeds from '../../../../modules/Farms/Baseline/SustellRationFeeds';
import RowTextFieldWithInfo from '../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../utils/unit-utils';
import { ButtonControlGroup } from '../../helpers/ButtonControlGroup';
import { DialogContainer } from './DialogContainer';

export const FeedsFormDialog = props => {
  let {
    formVisible,
    itemIndex = 0,
    handleCancel,
    handleSave = handleCancel,
    formType = 'add',
    additionalSet,
    additionalPropertyName
  } = props;

  let intl = useIntl();
  const currResetValue = useRef();

  const feedItem = { feedType: '', kgPerAnimal: '' };

  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const precision = userProfile.getUnitBarnOutputMassPrecision();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  const [baselineLoaded, setBaselineLoaded] = useState(false);

  const { getValues, setValue, reset, trigger } = useFormContext();

  const baselineCompoundFeeds = getValues(`stages.${itemIndex}.stageData.feed.compoundFeeds`);
    
  const [compoundFeedData, setCompoundFeedData] = useState(
    baselineCompoundFeeds && baselineCompoundFeeds?.length > 0
      ? baselineCompoundFeeds
      : [{ ...feedItem }]
  );

  const baselineSingleIngredients = getValues(`stages.${itemIndex}.stageData.feed.singleIngredients`)
  
  const [singleIngredientsData, setSingleIngredientsData] = useState(
    baselineSingleIngredients && baselineSingleIngredients?.length > 0
      ? baselineSingleIngredients
      : [{ ...feedItem }]
  );

  useEffect(()=> {
    if (baselineCompoundFeeds && baselineSingleIngredients && !baselineLoaded) {
      setCompoundFeedData([...baselineCompoundFeeds]);
      setSingleIngredientsData([...baselineSingleIngredients]);
      setBaselineLoaded(true);
    }
  }, [baselineCompoundFeeds, baselineSingleIngredients])

  useEffect(() => {
    if (formVisible) {
      // to faster deep copy all potential arrays and subobjects
      const serializedData = JSON.stringify(getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() };
      if (resetObject.stages[itemIndex]?.stageData?.feed) {
        const val = JSON.parse(currResetValue.current);
        resetObject.stages[itemIndex].stageData.feed = val;
        reset(resetObject,{
          errors: true
        });
        // reset compound feeds and single ingredients data
        // to previously valid (confirmed)
        // setCompoundFeedData(val?.compoundFeeds);
        // setSingleIngredientsData(val?.singleIngredients);

        // reset additional property if needed
        if(additionalSet && additionalPropertyName && val)
          additionalSet(val[additionalPropertyName])
      }
    }
    if (handleCancel) handleCancel();
  };

  const updateTotalFeedIntake = useCallback(() => {
    const currFormValues = getValues();
    const compoundFeeds =
      currFormValues.stages?.[itemIndex]?.stageData?.feed?.compoundFeeds;
    const singleIngredients =
      currFormValues.stages?.[itemIndex]?.stageData?.feed?.singleIngredients;
    let totalIntake = 0;
    if (compoundFeeds) {
      totalIntake += compoundFeeds.reduce((acc, item) => {
        return (
          acc +
          (item.feedType && !isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0)
        );
      }, 0);
    }

    if (singleIngredients) {
      totalIntake += singleIngredients.reduce((acc, item) => {
        return (
          acc +
          (item.feedType && !isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0)
        );
      }, 0);
    }

    setValue(
      fieldItemPrefix + '.totalFeedIntake',
      totalIntake !== 0 ? totalIntake.toFixed(precision) : ''
    );

    if(formVisible)
      trigger(fieldItemPrefix + '.totalFeedIntake');

  }, [itemIndex, fieldItemPrefix, setValue, getValues, precision, formVisible]);

  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  let formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleCancel}
      iconCode={'feedIcon'}
      formTitle={formTitle}
      variant="wide"
    >
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            <SustellRationFeeds
              key="compoundFeeds"
              label={intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS'})}
              availableFeedItems={props.compoundFeeds}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="compoundFeeds"
              updateTotalFeedIntake={updateTotalFeedIntake}
              tooltip={intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP'})}
              formState={formType}
              compoundFeed={true}
              feedData={compoundFeedData}
              setFeedData={setCompoundFeedData}
            />
            <SustellRationFeeds
              key="singleIngredients"
              label={intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS'})}
              barnItemIndex={itemIndex}
              availableFeedItems={props.singleIngredients}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="singleIngredients"
              updateTotalFeedIntake={updateTotalFeedIntake}
              tooltip={<div key={"feeds-form-dialog-singleIngredients"}>{intl.formatMessage({id:'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP'},{ br: <br /> })}</div>}
              formState={formType}
              feedData={singleIngredientsData}
              setFeedData={setSingleIngredientsData}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <RowTextFieldWithInfo
                name={fieldItemPrefix + '.totalFeedIntake'}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE',
                }) + ' *'}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE.TOOLTIP',
                })}
                addornment={intl.formatMessage(
                  { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                  { unit: barnOutputMassUnit }
                )}
                disabled={true}
                defaultValue = {getValues(fieldItemPrefix + '.totalFeedIntake')}
              />
            </Grid>
            {/* load additional fields for specific animal type - this is added in stage for specific type as subelement */}
            {props.children}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};
