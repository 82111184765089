import { AnimalType, StageType } from "../../../../../graphql/types";
import { ResourceUse } from "../../../../sustell_15/models/Baseline/Baseline";
import { ReportingPeriod } from "../../../../sustell_15/models/TempBackendModels/TempCattleDB";
import {
  DairyV2BaselineDBModel,
  DairyV2ContinuousGrowingStageDataDBModel,
  DairyV2DairyCowStageDataDBModel,
  DairyV2StageDBModel,
  EmissionsDBModel,
} from "../../../../sustell_15/models/TempBackendModels/TempDairyDB";
import { mapResourceUse } from "./inCommonMapperSustellV2";
import { ProcessingStageDBModel } from "../../../../sustell_15/models/TempBackendModels/TempProcessingDB";
import {
  DairyV2Baseline,
  DairyV2CommonStageData,
  DairyV2ContinuousGrowingStageData,
  DairyV2DairyCowStageData,
  DairyV2Emissions,
  DairyV2Stage,
} from "../../../../sustell_15/models/Baseline/DairyV2Baseline";
import { ProcessingStage } from "../../../../sustell_15/models/Facility/Processing";
import {
  mapCattleAnimalSources,
  mapCattleBeddingSystems,
  mapCattleEmissionsAndMitigations,
  mapCattleManureSystems,
  mapCattleTimeSpent,
  mapFeedingProgram,
} from "./Helpers/inCattleMapperHelpers";
import { formatInMassInput } from "./inMapperHelpers";

function mapEmissionsData(
  incomingData?: EmissionsDBModel
): DairyV2Emissions {
  const emissionMitgations = mapCattleEmissionsAndMitigations(
    incomingData?.emissionMitigations
  );
  return {
    emissionMitigations: emissionMitgations,
    NOP: incomingData?.emissionNOP?.NOP,
    NOPdose: incomingData?.emissionNOP?.NOPdose,
    averageFatDMI: incomingData?.emissionNOP?.averageFatDMI,
    cowsFedSupplement: incomingData?.emissionNOP?.cowsFedSupplement,
    calculatedMethaneFermentation: incomingData?.emissionNOP?.calculatedMethaneFermentation,
    totalMethaneFermentation: incomingData?.emissionNOP?.totalMethaneFermentation,
  };
}

function mapCommonStageData(
  incomingData:
    | DairyV2DairyCowStageDataDBModel
    | DairyV2ContinuousGrowingStageDataDBModel
): DairyV2CommonStageData {
  return {
    feed: mapFeedingProgram(incomingData.feedingProgram),
    housing: {
      manureSystems: mapCattleManureSystems(incomingData.mms),
      ...mapCattleTimeSpent(incomingData.timeSpent),
      beddingSystems: mapCattleBeddingSystems(incomingData.bedding),
    },
    emissions: mapEmissionsData(incomingData.emissions),
  };
}

function mapDairyCowStageData(
  incomingData: DairyV2DairyCowStageDataDBModel,
  startDate: Date | string
): DairyV2DairyCowStageData {
  return {
    ...mapCommonStageData(incomingData),
    input: {
      startDate,
      cattleProductivity: incomingData?.animalProductivity,
      animalsPresentAtStart: Number(incomingData.stockChange?.cowsPresentAtStart),
      animalsPresentAtEnd: Number(incomingData.stockChange?.cowsPresentAtEnd),
      averageWeightAtStart: formatInMassInput(
        incomingData.stockChange?.averageWeightCowsStart
      ),
      averageWeightNewAnimals: formatInMassInput(
        incomingData.input?.averageWeight
      ),
      ...mapCattleAnimalSources(incomingData.input?.animals),
    },
    output: {
      averageMilkFat: Number(incomingData.milkProduction?.fatContent),
      averageMilkProtein: Number(incomingData.milkProduction?.proteinContent),
      yearlyMilkProduction: Number(incomingData.milkProduction?.amount),
      averageWeightAtMortalityCows: formatInMassInput(incomingData.outputCows?.weightAtMortality),
      averageWeightOfCowsLeavingTheStage: formatInMassInput(incomingData.outputCows?.averageWeight),
      cowsSold: Number(incomingData.outputCows?.soldExternally),
      cowsToAnotherStage: Number(incomingData.outputCows?.animalsOutgoing),
      numberOfMortalitiesCows: Number(incomingData.outputCows?.mortalities),
      weanedCalvesSold: Number(incomingData.outputCalves?.soldExternally),
      weanedCalvesToAnotherStage: Number(incomingData.outputCalves?.animalsOutgoing),
      averageWeightAtBirth: formatInMassInput(incomingData?.calvesBirthWeight)
    },
  } as DairyV2DairyCowStageData;
}

function mapContinousGrowingStageData(
  incomingData: DairyV2ContinuousGrowingStageDataDBModel,
  startDate: Date | string
): DairyV2ContinuousGrowingStageData {
  return {
    ...mapCommonStageData(incomingData),
    input: {
      startDate,
      cattleProductivity: incomingData?.animalProductivity || 'HIGH',
      animalsPresentAtStart: Number(incomingData.stockChange?.cowsPresentAtStart),
      animalsPresentAtEnd: Number(incomingData.stockChange?.cowsPresentAtEnd),
      averageWeightAtStart: formatInMassInput(
        incomingData.stockChange?.averageWeightCowsStart
      ),
      averageWeightNewAnimals: formatInMassInput(
        incomingData.input?.averageWeight
      ),
      ...mapCattleAnimalSources(incomingData.input?.animals),
      averageWeightAtEnd: formatInMassInput(incomingData.stockChange?.averageWeightCowsEnd),
      averageAge: Number(incomingData.input?.averageAge)
    },
    output: {
      averageAge: Number(incomingData.outputCows?.averageAge),
      averageWeightAtMortalityCows: formatInMassInput(incomingData.outputCows?.weightAtMortality),
      averageWeightOfCowsLeavingTheStage: formatInMassInput(incomingData.outputCows?.averageWeight),
      cowsSold: Number(incomingData.outputCows?.soldExternally),
      cowsToAnotherStage: Number(incomingData.outputCows?.animalsOutgoing),
      numberOfMortalitiesCows: Number(incomingData.outputCows?.mortalities),
    },
  } as DairyV2ContinuousGrowingStageData;
}

function mapStageData(
  incomingData: DairyV2StageDBModel | ProcessingStageDBModel
): DairyV2Stage | ProcessingStage {
  const commonStageData: Pick<DairyV2Stage, "id" | "name" | "type"> = {
    id: incomingData.id,
    type: incomingData.type,
    name: incomingData.name,
  };

  if (incomingData.type === StageType.Processing) {
    return {} as ProcessingStage;
  }

  const dairyStage = incomingData as DairyV2StageDBModel;
  const startDate = dairyStage?.startDate || ''

  if (dairyStage.type === StageType.ContinuousGrowing) {
    const mappedStage = mapContinousGrowingStageData(
      JSON.parse(
        String(dairyStage.stageData)
      ) as DairyV2ContinuousGrowingStageDataDBModel,
      startDate
    );

    return {
      ...commonStageData,
      stageData: mappedStage,
    } as DairyV2Stage;
  }

  const mappedStageData = mapDairyCowStageData(
    JSON.parse(String(dairyStage.stageData)) as DairyV2DairyCowStageDataDBModel,
    startDate
  );

  return {
    ...commonStageData,
    stageData: mappedStageData,
  } as DairyV2Stage;
}

function mapDairyProductionDataInSustellV2(
  inData: DairyV2BaselineDBModel
): DairyV2Baseline {
  const mappedData: DairyV2Baseline = {
    info: {
      name: inData.name,
      oldName: inData.oldName || inData.name,
      databaseFoundation: inData?.databaseFoundation,
      description: inData.description,
      numOfCyclesYear: Number(inData.roundsPerYear),
      timeUnit: inData?.reportingPeriod as ReportingPeriod,
      year: inData.year,
      validFrom: inData.validFrom,
      validTo: inData.validTo,
    },
    copyFrom: "New",
    stages: [],
    animalType: AnimalType.DairyV2,
    resourceUse: {} as ResourceUse,
  };

  mappedData.resourceUse = inData.resourceUse
    ? (mapResourceUse(inData.resourceUse, mappedData.resourceUse, [
        "electricityUse",
        "gasUse",
        "waterUse",
      ]) as ResourceUse)
    : ({} as ResourceUse);

  if (inData?.stages && !Array.isArray(inData.stages)) {
    return mappedData;
  }

  return inData.stages.reduce(
    (payload, stage: DairyV2StageDBModel) => {
      const mappedStage = mapStageData(stage);

      return {
        ...payload,
        stages: [...payload.stages, mappedStage],
      };
    },
    { ...mappedData }
  );
}

export default mapDairyProductionDataInSustellV2;
