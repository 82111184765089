import { IntlShape } from "react-intl";
import { AnimalType } from "../../../../graphql/types";

export const isSpecificAnimalType = (animalType: AnimalType | string | null | undefined) => {
  return (animalType === AnimalType.LitopenaeusVannamei || 
  animalType === AnimalType.PenaeusMonodon ||
  animalType === AnimalType.SalmonV2 || 
  animalType === AnimalType.SeaBass ||
  animalType === AnimalType.SeaBream);
}

export const getAnimalAnalysisGroup = (
  animalType: AnimalType | string | null | undefined, 
  analisysGroup: string, intl: IntlShape
): string => {
  const hasAnimalTypeSpecificGroup = isSpecificAnimalType(animalType);

  switch (analisysGroup) {
    case "Purchased animals":
      if (hasAnimalTypeSpecificGroup) {
        return intl.formatMessage({ 
          id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.SHRIMP' 
        });
      }
      return intl.formatMessage({ 
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.OTHERS' 
      });
    case "Ration":
      if (hasAnimalTypeSpecificGroup) {
        return intl.formatMessage({ 
          id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.RATION.SHRIMP' 
        });
      }
      return analisysGroup;
    case "Animal inputs":
      return intl.formatMessage({ 
        id: 'SUSTELL.FOOTPRINT.FARM.LEGEND.ANIMAL.OTHERS' 
      });
    case "Ingredients":
    case "Ingredients & Inbound Transport":
      return intl.formatMessage({ 
        id: 'SUSTELL.FOOTPRINT.FEED.INGREDIENTS_INBOUND_TRANSPORT' 
      });
    case "Transport":
    case "Outbound Transport":
      return intl.formatMessage({ 
        id: 'SUSTELL.FOOTPRINT.FEED.OUTBOUND_TRANSPORT' 
      });
    default:
      return analisysGroup;
  }
}