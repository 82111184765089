import * as Yup from 'yup';
import { StageType } from '../../../../../graphql/types';
import { defaultUnits } from '../../../../sustell_15/utils/unit-utils';
import { usedResourcesPart } from './baselineValidationSchemaGeneralPart';
import {
  numericRequiredWithMin,
  numericRequiredWithGreaterThanMin,
  fertilizerItemsTest,
  fertilizerItemsTestMandatory,
  shrimpFeedItemsTestMandatory,
  feedItemsTest,
  naturalFeedsItemsTest,
} from './validationObjectBuilderFunctions';

let stageFeeds = [];
let stageInputSelections = [];
let isEnergyFieldRequired = false;

export const stageInputGrowing = (intl) =>
  Yup.object().shape({
    totalPondArea: numericRequiredWithMin(intl, 0),
    emptyPeriod: numericRequiredWithMin(intl, 0),
    stageDuration: numericRequiredWithMin(intl, 0),
    selection: Yup.object({
      internalSelected: Yup.bool(),
      externalSelected: Yup.bool()
    })
    .test('checkWhatIsSelectedGrowing', '', function test(value, testContext) { 
      const { path, createError } = this;
      const internalSelected = value?.internalSelected;
      const externalSelected = value?.externalSelected;
      if (!internalSelected && !externalSelected) return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.NO_SELECTION"})});
      const input = testContext.from[1].value;
      const stageId = testContext.from[3].value.id;
      const stageTypes = input?.internalSources?.filter((internalSource) => internalSource.stageType)
                                                .map((internalSource) => internalSource.stageType);
      const differentStageTypes = [...new Set(stageTypes)];

      let stageSelect = stageInputSelections.find((stageSelects) => stageSelects.id === stageId);

      if (!stageSelect) {
        stageInputSelections.push({
          id: stageId,
          internalSelect: internalSelected,
        });
        stageSelect = stageInputSelections.find((stageSelects) => stageSelects.id === stageId);
      }

      if (internalSelected && externalSelected && differentStageTypes.length > 0 && (differentStageTypes.includes('GROWING') || differentStageTypes.includes('NURSERY'))) {
        if (internalSelected !== stageSelect.internalSelect) {
          stageSelect.internalSelect = internalSelected;
          return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.GROWING.INTERNAL.NO_BOTH"})});
        }
        return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.GROWING.EXTERNAL.NO_BOTH"})});
      }

      stageSelect.internalSelect = internalSelected;
      return true;
    }),
    internalSources: Yup.array()
      .of(
        Yup.object({
          comingFromFarm: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          comingFromStage: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          )
          .test('stageTypeSelectedGrowing', '', function test(_, testContext) {
            const { path, createError } = this;
            const currentStageType = testContext.from[0].value.stageType;
            const input = testContext.from[1].value;
            const stageTypes = input?.internalSources?.filter((internalSource) => internalSource.stageType)
                                                      .map((internalSource) => internalSource.stageType);
            const differentStageTypes = [...new Set(stageTypes)];
            if (currentStageType && differentStageTypes.length > 1) {
              if (currentStageType === 'HATCHING') 
                return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.GROWING.HATCHING_MIXED"})});
              if (currentStageType === 'NURSERY' && differentStageTypes.includes('HATCHING')) 
                return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.GROWING.NURSERY_MIXED"})});
              if (currentStageType === 'GROWING' && differentStageTypes.includes('HATCHING'))
                return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.GROWING.GROWING_MIXED"})});
            }
            return true;
          }),
          outputName: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          totalWeight: numericRequiredWithMin(intl, 0),
        })
      ),
    externalSources: Yup.array()
      .of(
        Yup.object({
          totalWeight: numericRequiredWithMin(intl, 0),
        })
      ),
  });

export const stageInputNursery = (intl) =>
  Yup.object().shape({
    totalPondArea: numericRequiredWithMin(intl, 0),
    emptyPeriod: numericRequiredWithMin(intl, 0),
    stageDuration: numericRequiredWithMin(intl, 0),
    selection: Yup.object({
      internalSelected: Yup.bool(),
      externalSelected: Yup.bool()
    })
    .test('checkWhatIsSelectedNursery', '', function test(value, testContext) { 
      const { path, createError } = this;
      const internalSelected = value?.internalSelected;
      const externalSelected = value?.externalSelected;
      if (!internalSelected && !externalSelected) return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.NO_SELECTION"})});
      const input = testContext.from[1].value;
      const stageId = testContext.from[3].value.id;
      const stageTypes = input?.internalSources?.filter((internalSource) => internalSource.stageType)
                                                .map((internalSource) => internalSource.stageType);
      const differentStageTypes = [...new Set(stageTypes)];

      let stageSelect = stageInputSelections.find((stageSelects) => stageSelects.id === stageId);

      if (!stageSelect) {
        stageInputSelections.push({
          id: stageId,
          internalSelect: internalSelected,
        });
        stageSelect = stageInputSelections.find((stageSelects) => stageSelects.id === stageId);
      }

      if (internalSelected && externalSelected && differentStageTypes.length > 0 && differentStageTypes.includes('NURSERY')) {
        if (internalSelected !== stageSelect.internalSelect) {
          stageSelect.internalSelect = internalSelected;
          return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.NURSERY.INTERNAL.NO_BOTH"})});
        }
        return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.NURSERY.EXTERNAL.NO_BOTH"})});
      }

      stageSelect.internalSelect = internalSelected;
      return true;
    }),
    internalSources: Yup.array()
      .of(
        Yup.object({
          comingFromFarm: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          comingFromStage: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          )
          .test('stageTypeSelectedNursery', '', function test(_, testContext) {
            const { path, createError } = this;
            const currentStageType = testContext.from[0].value.stageType;
            const input = testContext.from[1].value;
            const stageTypes = input?.internalSources?.filter((internalSource) => internalSource.stageType)
                                                      .map((internalSource) => internalSource.stageType);
            if (currentStageType && [...new Set(stageTypes)].length > 1) {
              if (currentStageType === 'HATCHING') 
                return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.NURSERY.HATCHING_MIXED"})});
              return createError({path, message: intl.formatMessage({id: "BASELINE.FORM.INPUT.SHRIMP.NURSERY.NURSERY_MIXED"})});
            }
            return true;
          }),
          outputName: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          totalWeight: numericRequiredWithMin(intl, 0),
        })
      ),
    externalSources: Yup.array()
      .of(
        Yup.object({
          totalWeight: numericRequiredWithMin(intl, 0),
        })
      ),
  });

const stageInputHatching = (intl) =>
  Yup.object({
    totalPondArea: numericRequiredWithMin(intl, 0),
    emptyPeriod: numericRequiredWithMin(intl, 0),
    stageDuration: numericRequiredWithMin(intl, 0),
  });

const checkIfAtLeastOneFeedFilled = (parent) => {
  const stageData = parent?.stageData;
  const stageId = parent?.id;
  
  const compoundFeedsFilled = stageData?.feed?.compoundFeeds?.find(
    (item) =>
      item.feedType && !Number.isNaN(item.kgPerAnimal) && item.kgPerAnimal > 0
  );
  const singleIngredientsFilled = stageData?.feed?.singleIngredients?.find(
    (item) =>
      item.feedType && !Number.isNaN(item.kgPerAnimal) && item.kgPerAnimal > 0
  );
  const fertilizersFilled = stageData?.operations?.fertilizerTypes?.find(
    (item) => item.type && !Number.isNaN(item.amount) && item.amount > 0
  );

  let stageFeedRequirement = stageFeeds.find((requiredFeed) => requiredFeed.id === stageId);

  if (!compoundFeedsFilled && !singleIngredientsFilled && !fertilizersFilled) {
    if (!stageFeedRequirement) {
      stageFeeds.push({
        id: stageId,
        requireFeed: 1,
      });
    }
    else {
      stageFeedRequirement.requireFeed = 1;
    }

    return true;
  }

  if (!stageFeedRequirement) {
    stageFeeds.push({
      id: stageId,
      requireFeed: 0,
    });
  }
  else {
    stageFeedRequirement.requireFeed = 0;
  }

  return true;
};

const isAtLeastOneFeedRequired = (from) => {
  if (from)
    return stageFeeds.find((requiredFeed) => requiredFeed.id === from[2]?.value?.id)?.requireFeed === 1;

  return true;
}

export const stageFeed = (intl) =>
  Yup.object().shape({
    compoundFeeds: Yup.lazy((_, { from }) => isAtLeastOneFeedRequired(from)
    ? shrimpFeedItemsTestMandatory(intl, 0)
    : feedItemsTest(intl, 0)),
    singleIngredients: Yup.lazy((_, { from }) => isAtLeastOneFeedRequired(from)
    ? shrimpFeedItemsTestMandatory(intl, 0)
    : feedItemsTest(intl, 0)),
  });

export const stageFeedHatching = (intl) =>
  Yup.object().shape({
    compoundFeeds: Yup.lazy((_, { from }) => isAtLeastOneFeedRequired(from)
    ? shrimpFeedItemsTestMandatory(intl, 0)
    : feedItemsTest(intl, 0)),
    singleIngredients: Yup.lazy((_, { from }) => isAtLeastOneFeedRequired(from)
    ? shrimpFeedItemsTestMandatory(intl, 0)
    : feedItemsTest(intl, 0)),
    naturalFeed: naturalFeedsItemsTest(intl, 0),
  });

export const stageOutput = (intl) =>
  Yup.object({
    outputs: Yup.array().of(
      Yup.object({
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT' }))
          .test('outputsUniqueNameTest', (value, context) => {
            const { path, createError, from } = context;
            const outputs = from[1]?.value?.outputs;
            let nameCount = 0;

            outputs.map((output) => {
              if (value && output.name === value) nameCount++;
            });

            if (nameCount > 1) {
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.SHRIMP.NAME_ERROR',
                }),
              });
            }

            return true;
          }),
        price: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .test('outputsLengthTest', (value, context) => {
            const { path, createError, from } = context;
            const outputs = from[1]?.value?.outputs;
            const outputsLength = outputs?.length;

            if ((outputsLength > 1 && !value) || (value && Number.isNaN(value)))
              return createError({
                path,
                message: intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }),
              });
            return true;
          }),
        soldExternally: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        totalWeight: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
      })
    ),
  });

export const stageOperations = (intl) =>
  Yup.object({
    resourceUse: Yup.object({
      electricityUse: isEnergyFieldRequired ? 
        Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FACILITY.ENERGY_TYPES.REQUIRED' })
        ) : 
        Yup.string(),
      gasUse: isEnergyFieldRequired ? 
        Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FACILITY.ENERGY_TYPES.REQUIRED' })
        ) : 
        Yup.string(),
      watersalinity: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      waterEnteringPondIn: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .when('nitrogenConcentrationIn', {
          is: (value) => !Number.isNaN(value) && value > 0,
          then: Yup.number()
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
        }),
      nitrogenConcentrationIn: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
      waterEnteringPondOut: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .when('nitrogenConcentrationOut', {
          is: (value) => !Number.isNaN(value) && value > 0,
          then: Yup.number()
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
        }),
      nitrogenConcentrationOut: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
    }),
    materialTypes: Yup.array().of(
      Yup.object({
        amount: numericRequiredWithMin(intl, 0),
        lifetimeOfMaterial: numericRequiredWithGreaterThanMin(intl, 0),
      })
    ),
    fertilizerTypes: Yup.lazy((_, { from }) => isAtLeastOneFeedRequired(from)
    ? fertilizerItemsTestMandatory(intl, 0)
    : fertilizerItemsTest(intl, 0)),
  });

// stage fields validation rules
export const stageDataPartShrimp = ({ intl, userUOM = defaultUnits }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          pondName: Yup.string()
            .nullable(true),
          type: Yup.string()
            .oneOf([StageType.Growing, StageType.Hatching, StageType.Nursery])
            .required(),
          stageData: Yup.object()
            .when('type', {
              is: StageType.Growing,
              then: Yup.object({
                input: stageInputGrowing(intl),
                feed: stageFeed(intl),
                output: stageOutput(intl),
                operations: stageOperations(intl),
              }),
            })
            .when('type', {
              is: StageType.Hatching,
              then: Yup.object({
                input: stageInputHatching(intl),
                feed: stageFeedHatching(intl),
                output: stageOutput(intl),
                operations: stageOperations(intl),
              }),
            })
            .when('type', {
              is: StageType.Nursery,
              then: Yup.object({
                input: stageInputNursery(intl),
                feed: stageFeed(intl),
                output: stageOutput(intl),
                operations: stageOperations(intl),
              }),
            }),
        }).test('checkIfAtLeastOneFeedFilled', '', function test(value, _) {
          return checkIfAtLeastOneFeedFilled(value);
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

// Age validations rules
export const ageValidations = ({ intl }) =>
  Yup.object({
    age: Yup.number()
      .transform((changed, original) => (original === '' ? undefined : changed))
      .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
      .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
  });

export const isAmountAndTypeFilled = (items) => {
  if (!items || !items.length) return false;
  return items.some(({ value, type }) => value && type);
};

const isAnyStageDataResourceFilled = (stageData) => {
  const resources = stageData?.operations?.resourceUse;
  
  if (resources) {
    const { gasUse, electricityUse, energyTypes, selfGeneratedRenewables } = resources;
    const isAnyAdditionalEnergyFilled =
        energyTypes && isAmountAndTypeFilled(energyTypes);
    const isAnyRenewablesFilled =
      selfGeneratedRenewables &&
      isAmountAndTypeFilled(selfGeneratedRenewables);
    
    return gasUse || electricityUse || isAnyAdditionalEnergyFilled || isAnyRenewablesFilled;
  }
  
  return false;
}

// merge all necessary parts to baseSchema
const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ info: ageValidations(confObj) }))
    .concat(Yup.object({ resourceUse: usedResourcesPart(confObj) }))
    .concat(stageDataPartShrimp(confObj));
  return combinedSchema.test(
    'checkIfAtLeastOneResourceFilled',
    '',
    (values, testContext) => {
      const { createError } = testContext;
      const { intl } = confObj;
      const { gasUse, electricityUse, energyTypes, selfGeneratedRenewables } =
        values.resourceUse;
      const isAnyAdditionalEnergyFilled =
        energyTypes && isAmountAndTypeFilled(energyTypes);
      const isAnyRenewablesFilled =
        selfGeneratedRenewables &&
        isAmountAndTypeFilled(selfGeneratedRenewables);
      const isAnyStageResourceFilled = 
        values.stages?.some((stage) => isAnyStageDataResourceFilled(stage?.stageData));
      
      isEnergyFieldRequired = !electricityUse &&
      !gasUse &&
      !isAnyAdditionalEnergyFilled &&
      !isAnyRenewablesFilled &&
      !isAnyStageResourceFilled;
      
      if (isEnergyFieldRequired) {
        const electricityError = createError({
          path: 'resourceUse.electricityUse',
          message: intl.formatMessage({
            id: 'VALIDATION.FACILITY.ENERGY_TYPES.REQUIRED',
          }),
        });
        const gasUseError = createError({
          path: 'resourceUse.gasUse',
          message: intl.formatMessage({
            id: 'VALIDATION.FACILITY.ENERGY_TYPES.REQUIRED',
          }),
        });

        return new Yup.ValidationError([electricityError, gasUseError]);
      }
      return true;
    }
  );
};

export default assembleValidationSchemaSustell;
