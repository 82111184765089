export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalDietInput = {
  knownIntake?: InputMaybe<KnownIntakeDietInput>;
  timeSpent: TimeSpent;
  unknownIntake: UnknownIntakeDietInput;
};

export type AnimalInput = {
  /**  # Specify the origin facility ID of the animals that are coming from another facility in the system. */
  originId: Scalars['ID'];
  /**  # The number of animals. */
  quantity: Scalars['Float'];
  /**  # Percentage weight reduction during transport. Advised default: 2%, in case there is transport. Default: 0%. */
  shrinkingRate?: InputMaybe<Scalars['Float']>;
  /**  # The transport of the animal input to go to the facility. */
  transport: Array<Transport>;
};

export type AnimalProcessing = {
  /**  # A list of facilities for processing the animals. */
  facilities: Array<Facility>;
};

export enum AnimalProductivity {
  High = 'HIGH',
  Low = 'LOW'
}

export enum AnimalType {
  /**  If coming from Breeding or Dairy stage */
  Calf = 'CALF',
  /**  If coming from Growing, Breeding or Dairy stage */
  Cow = 'COW'
}

export type AnimalsIncoming = {
  /**  # The distance of transport by truck, in kilometers. */
  distanceTransport?: InputMaybe<Scalars['Float']>;
  /**  # The number of animals coming into the stage from a certain origin. */
  number: Scalars['Float'];
  /**  # The type of origin of the animals. */
  origin: InputAnimalOrigin;
  /**  # The UUID of the stage the animals are coming from, necessary when the the origin is a stage within the same production system. */
  originStageId?: InputMaybe<Scalars['ID']>;
  /**  # Percentage weight reduction during transport. Advised default: 2%, in case there is transport. Default: 0%. */
  shrinkingRate?: InputMaybe<Scalars['Float']>;
};

export type AuxiliaryInputs = {
  /**  # A list of chemicals that are used to clean the facility. */
  chemicals?: InputMaybe<Array<Chemical>>;
  /**  # A list of refrigerants that are used to cool the facility. */
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  /**  # The amount of wastewater that will be discharged to the sewage treatment plant. In cubic meter per year. */
  wastewaterAmount: Scalars['Float'];
};

export type Bedding = {
  /**  # Total of the whole herd over the whole duration of the stage. In kilogram. */
  amount: Scalars['Float'];
  type: BeddingType;
};

export enum BeddingType {
  Sand = 'SAND',
  SawDust = 'SAW_DUST',
  WheatStraw = 'WHEAT_STRAW',
  WoodShavings = 'WOOD_SHAVINGS'
}

export type BreedingOutput = {
  /**  # The total number of animals that leave the stage to another stage or facility in the production system. */
  animalsToStage: Scalars['Float'];
  /**  # The average weight of animals leaving the stage, in kilograms. */
  averageWeight: Scalars['Float'];
  /**  # Indicate if an impact result is needed, even if there are no sold animals in this stage. */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # The total number of mortalities over the whole duration of the stage. */
  mortalities: Scalars['Float'];
  /**  # Number of animals sold externally. This is an output of the production system for which a climate impact will be calculated. */
  soldExternally: Scalars['Float'];
  /**  # The average weight of an animal in this stage at mortality. */
  weightAtMortality: Scalars['Float'];
};

export type BreedingStageInput = {
  /**  # A list of the animals coming into this stage. For each group of animals coming from a certain origin (a stage or background) specify an AnimalsIncomingInput. */
  animalsIncoming: Array<AnimalsIncoming>;
  /**  # The average weight of animals entering the stage, in kilograms. */
  averageWeight: Scalars['Float'];
};

export type CattleBreedingStage = {
  /**  # Default: high. */
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCalves?: InputMaybe<Array<InputMaybe<Bedding>>>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # Average weight in kilograms of the calves at birth */
  calvesBirthWeight?: InputMaybe<Scalars['Float']>;
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigationsCalves?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The feed used for the calves. */
  feedingProgramCalves: FeedingProgram;
  /**  # The feed used for the cows. */
  feedingProgramCows: FeedingProgram;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of the cows entering the stage. */
  input: BreedingStageInput;
  /**  # The period length the calves stay in the system with their mothers, in days. */
  lengthStageCalves: Scalars['Float'];
  /**  # Milk production */
  milkProduction: MilkProduction;
  /**
   *  # A list of all the used manure management systems used in the stage in parallel (not consecutively).
   * # A manure management system is required when time is spent in housing.
   */
  mmsCalves?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**
   *  # A list of all the used manure management systems used in the stage in parallel (not consecutively).
   * # A manure management system is required when time is spent in housing.
   */
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**  # A name of the type of animals may be provided. */
  name?: InputMaybe<Scalars['String']>;
  /**
   *  # CALVES
   * # A description of the calves leaving the stage.
   */
  outputCalves: BreedingOutput;
  /**
   *  # COWS
   * # A description of the cows leaving the stage.
   */
  outputCows: BreedingOutput;
  stock: Stock;
  timeSpentCalves: TimeSpent;
  timeSpentCows: TimeSpent;
  /**  # Each water and energy carrier may be specified on either farm or stage level, where stage level is preferred. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type Chemical = {
  /**  # The used amount of the chemical. In kilogram per year. */
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  ChemicalOrganic = 'CHEMICAL_ORGANIC',
  EthanolFromMaize = 'ETHANOL_FROM_MAIZE',
  Glycerine = 'GLYCERINE',
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  Isopropanol = 'ISOPROPANOL',
  NitrogenLiquid = 'NITROGEN_LIQUID',
  OxygenLiquid = 'OXYGEN_LIQUID',
  PhosphoricAcid = 'PHOSPHORIC_ACID',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumChlorate = 'SODIUM_CHLORATE',
  SodiumChlorideAsBrineSolution = 'SODIUM_CHLORIDE_AS_BRINE_SOLUTION',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE',
  SulfuricAcid = 'SULFURIC_ACID'
}

export enum ClimateType {
  BorealDry = 'BOREAL_DRY',
  BorealMoist = 'BOREAL_MOIST',
  CoolTemperateDry = 'COOL_TEMPERATE_DRY',
  CoolTemperateMoist = 'COOL_TEMPERATE_MOIST',
  TropicalDry = 'TROPICAL_DRY',
  TropicalMoist = 'TROPICAL_MOIST',
  /**  climate types as specified in in the IPCC */
  TropicalMontane = 'TROPICAL_MONTANE',
  TropicalWet = 'TROPICAL_WET',
  WarmTemperateDry = 'WARM_TEMPERATE_DRY',
  WarmTemperateMoist = 'WARM_TEMPERATE_MOIST'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type CompoundFeedInfo = {
  /**  # Weight percentage ash content as a fraction of the dry matter feed weight. Default: 10% */
  ashContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage crude protein content as a fraction of the dry matter feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Percentage digestible energy over the gross energy intake. Default: 78.5% */
  digestibleEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage dry matter content as a fraction of the feed weight. Default: 86% */
  dryMatterContent?: InputMaybe<Scalars['Float']>;
  /**  # Gross energy content, in MJ/kg dry matter of feed. Default: 18.45 */
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # ID to find compound feed in compound feed API. */
  id: Scalars['ID'];
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # Indicate if this feed contains milk or a milk replacer. Default: 0% */
  milk?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage of natural detergent fiber as a fraction of the dry matter feed weight. Default: 30% Only used for dairy stage. */
  naturalDetergentFiberContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage phosphorus content as a fraction of the dry matter feed weight. Default: 0.7% */
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram dry matter. */
  quantity: Scalars['Float'];
  /**  # Percentage urinary energy over the gross energy intake. Default: 4% */
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type ContinuousGrowingStage = {
  /**  # Default: high. */
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The feed used for the animals. */
  feedingProgramCows: FeedingProgram;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the stage. */
  input: GrowingStageInput;
  /**
   *  # A list of all the used manure management systems used in the stage in parallel (not consecutively).
   * # A manure management system is required when time is spent in housing.
   */
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**  # A name of the type of animals may be provided. */
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the stage. */
  outputCows: GrowingOutput;
  /**  # Stock parameters */
  stock: Stock;
  timeSpentCows: TimeSpent;
  /**  # Each water and energy carrier may be specified on either farm or stage level, where stage level is preferred. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type CowCalfFeedIntakeEstimation = {
  __typename?: 'CowCalfFeedIntakeEstimation';
  calvesDiet?: Maybe<KnownIntakeDiet>;
  cowsDiet?: Maybe<KnownIntakeDiet>;
};

export type CowCalfFeedIntakeEstimationInput = {
  calvesBirthWeight?: InputMaybe<Scalars['Float']>;
  calvesDiet: AnimalDietInput;
  calvesFemaleShare?: InputMaybe<Scalars['Float']>;
  calvesLeavingNumber: Scalars['Float'];
  calvesLeavingWeight: Scalars['Float'];
  calvesMaleCastratesShare?: InputMaybe<Scalars['Float']>;
  calvesMaleNonCastratesShare?: InputMaybe<Scalars['Float']>;
  calvesMatureWeight?: InputMaybe<Scalars['Float']>;
  calvesMortalities: Scalars['Float'];
  calvesPermanencePeriod: Scalars['Float'];
  cowsDiet: AnimalDietInput;
  cowsEnteringWeight: Scalars['Float'];
  /**   Cows: */
  cowsLeavingNumber: Scalars['Float'];
  cowsLeavingWeight: Scalars['Float'];
  cowsMatureWeight?: InputMaybe<Scalars['Float']>;
  cowsMilkFatShare: Scalars['Float'];
  cowsMortalities: Scalars['Float'];
};

export type DairyCowStage = {
  /**  # Default: high. */
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # Average weight in kilograms of the calves at birth */
  calvesBirthWeight: Scalars['Float'];
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The feed used for the animals. */
  feedingProgramCows: FeedingProgram;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the stage. */
  input: BreedingStageInput;
  /**  # Milk production */
  milkProduction: DairyMilkProduction;
  /**
   *  # A list of all the used manure management systems used in the stage in parallel (not consecutively).
   * # A manure management system is required when time is spent in housing.
   */
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**  # A name of the type of animals may be provided. */
  name?: InputMaybe<Scalars['String']>;
  outputCalves: DairyOutputCalves;
  /**  # A description of all the animals leaving the stage. */
  outputCows: BreedingOutput;
  stock: Stock;
  timeSpentCows: TimeSpent;
  /**  # Each water and energy carrier may be specified on either farm or stage level, where stage level is preferred. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type DairyFeedIntakeEstimationInput = {
  calvesLeavingNumber: Scalars['Float'];
  cowsDiet: AnimalDietInput;
  cowsEnteringWeight: Scalars['Float'];
  cowsLeavingNumber: Scalars['Float'];
  cowsLeavingWeight: Scalars['Float'];
  cowsMilkFatShare?: InputMaybe<Scalars['Float']>;
  cowsMilkProduction?: InputMaybe<Scalars['Float']>;
  cowsMortalities: Scalars['Float'];
};

export type DairyMilkProduction = {
  /**  # Milk production in kg milk/year. */
  amount: Scalars['Float'];
  /**  # Fat content of the milk in percentage */
  fatContent: Scalars['Float'];
  /**  # Protein content of the milk in percentage */
  proteinContent: Scalars['Float'];
};

export type DairyOutputCalves = {
  /**  # The total number of animals that leave the stage to another stage or facility in the production system. */
  animalsToStage: Scalars['Float'];
  /**  # Indicate if an impact result is needed, even if there are no sold animals in this stage. */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Number of animals sold externally. This is an output of the production system for which a climate impact will be calculated. */
  soldExternally: Scalars['Float'];
};

export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

export type EmissionMitigation = {
  /**  # Increase in %, use a negative value to decrease the calculated emission value. */
  change: Scalars['Float'];
  emissionType: EmissionType;
};

export type EmissionResult = {
  __typename?: 'EmissionResult';
  annotation?: Maybe<Scalars['String']>;
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export enum EmissionType {
  AmmoniaHousing = 'AMMONIA_HOUSING',
  AmmoniaStorage = 'AMMONIA_STORAGE',
  AmmoniaYard = 'AMMONIA_YARD',
  DinitrogenStorage = 'DINITROGEN_STORAGE',
  MethaneEntericFermentation = 'METHANE_ENTERIC_FERMENTATION',
  MethaneMms = 'METHANE_MMS',
  NitrogenOxidesStorage = 'NITROGEN_OXIDES_STORAGE',
  NitrousOxideDirect = 'NITROUS_OXIDE_DIRECT',
  NitrousOxideLeaching = 'NITROUS_OXIDE_LEACHING',
  NitrousOxideVolatilization = 'NITROUS_OXIDE_VOLATILIZATION',
  Nmvoc = 'NMVOC',
  Pm2_5 = 'PM2_5',
  Pm10 = 'PM10',
  Tsp = 'TSP'
}

export type EnergyCarrier = {
  /**  # The amount of the energy carrier. */
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  /**  The burning of coal, in Mega Joule. */
  Coal = 'COAL',
  /**  The burning of diesel, in Mega Joule. */
  Diesel = 'DIESEL',
  /**  The electricity use from the grid, in kWh. */
  Electricity = 'ELECTRICITY',
  /**  The electricity use from the solar panels, in kWh. */
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  /**  The electricity use from the wind, in kWh. */
  ElectricityWind = 'ELECTRICITY_WIND',
  /**  The burning of gasoline, in Mega Joule. */
  Gasoline = 'GASOLINE',
  HeavyFuelOil = 'HEAVY_FUEL_OIL',
  /**  The burning of natural gas, in Mega Joule. */
  NaturalGas = 'NATURAL_GAS',
  /**  The burning of propane or Liquefied Petroleum Gas, in Mega Joule. */
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  /**  # The auxiliary inputs for the entire facility. */
  auxiliaryInputs: AuxiliaryInputs;
  /**  # The utilities used in the facility, divided in Energy Carriers and Water */
  energyCarriers: Array<EnergyCarrier>;
  /**  # The location of the facility, typically the country. */
  geography: Scalars['String'];
  /**  # A UUID that should be provided for each facility, in order to link the facilities between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the facility. */
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the facility. */
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  /**  # A list of animal inputs to go into the facility. */
  animalInputs: Array<AnimalInput>;
  /**  # The average weight of all animals that go into the facility in kg/animal. */
  averageWeight: Scalars['Float'];
  /**  # Required when origin stage has multiple outputs */
  name?: InputMaybe<AnimalType>;
  /**  # The total live weight entering the entire facility, this can be more than the target animal input flow. In kilogram per year. */
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type Farm = {
  breedingStages?: InputMaybe<Array<InputMaybe<CattleBreedingStage>>>;
  /**  # The IPCC climate type, it can be found on the map of IPCC climate types */
  climateType: ClimateType;
  continuousGrowingStages?: InputMaybe<Array<InputMaybe<ContinuousGrowingStage>>>;
  dairyStages?: InputMaybe<Array<InputMaybe<DairyCowStage>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The country where the farm is located. */
  geography: Scalars['String'];
  /**  # A list of stages in the farm. Each stage represents a homogeneous group of animals with a single target output weight. */
  growingStages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  /**
   *  # Each water and energy carrier may be specified on either farm or stage level, where stage level is preferred.
   * # If a utility is specified on farm level, it is allocated to the stages where it is not specified, based on feed intake.
   */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type FeedIntakeEstimatorInput = {
  cowCalfInputs?: InputMaybe<Array<InputMaybe<CowCalfFeedIntakeEstimationInput>>>;
  dairyInputs?: InputMaybe<Array<InputMaybe<DairyFeedIntakeEstimationInput>>>;
  growingInputs?: InputMaybe<Array<InputMaybe<GrowingFeedIntakeEstimationInput>>>;
};

export type FeedIntakeEstimatorResult = {
  __typename?: 'FeedIntakeEstimatorResult';
  cowCalfDiets?: Maybe<Array<Maybe<CowCalfFeedIntakeEstimation>>>;
  dairyDiets?: Maybe<Array<Maybe<KnownIntakeDiet>>>;
  growingDiets?: Maybe<Array<Maybe<KnownIntakeDiet>>>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export enum FeedStatus {
  /**  Feedlot diet with steam flaked corn, ionophore supplement and 0-10% forage */
  FeedlotCorn = 'FEEDLOT_CORN',
  /**  Feedlot diets with grains other than corn and 0-15% forage */
  FeedlotOtherGrain = 'FEEDLOT_OTHER_GRAIN',
  /**  Diets with more than 75% forage of high quality */
  HighForageHighQuality = 'HIGH_FORAGE_HIGH_QUALITY',
  /**  Diets with more than 75% forage and low or medium quality */
  HighForageLowQuality = 'HIGH_FORAGE_LOW_QUALITY',
  /**  Mixed diets with 15%-75% forage, mixed with grain, and/or silage */
  MixedRations = 'MIXED_RATIONS'
}

export type FeedingProgram = {
  /**  # List of used compound feeds. At least one of compound feed or single ingredient is required. */
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedInfo>>>;
  /**  # Feed type, as defined by IPCC. Required for growing and breeding stages. */
  feedStatus?: InputMaybe<FeedStatus>;
  /**  # List of used grass. At least one of compound feed, single ingredient or fresh grass is required. */
  freshGrass?: InputMaybe<Array<InputMaybe<FreshGrass>>>;
  /**  # List of used single ingredients. At least one of compound feed or single ingredient is required. */
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type ForegroundTreeResult = {
  __typename?: 'ForegroundTreeResult';
  emissionResults?: Maybe<Array<Maybe<StageEmissionResult>>>;
  /**  # json dump of BlonkSchema Dataset */
  lci?: Maybe<Scalars['String']>;
  performanceParameters?: Maybe<SystemPerformanceParameters>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type FreshGrass = {
  /**  # Weight percentage ash content as a fraction of the feed dry matter weight. Default: 17.9% */
  ashContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage crude protein content as a fraction of the feed dry matter weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Percentage digestible energy over the gross energy intake. */
  digestibleEnergyContent: Scalars['Float'];
  /**  # Weight percentage dry matter content as a fraction of the feed weight. */
  dryMatterContent: Scalars['Float'];
  /**  # Gross energy content (MJ / kg dry matter feed). Default: 18.45 MJ/kg */
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  /**  # Weight percentage of natural detergent fiber as a fraction of the dry matter feed weight. Required and only used for the dairy stage. */
  naturalDetergentFiberContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage phosphorus content as a fraction of the feed dry matter weight. */
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram dry matter. */
  quantity: Scalars['Float'];
  /**  # Percentage urinary energy over the gross energy intake. Default: 4% */
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type GrowingFeedIntakeEstimationInput = {
  diet: AnimalDietInput;
  enteringWeight: Scalars['Float'];
  femaleNonPregnantShare: Scalars['Float'];
  femalePregnantShare?: InputMaybe<Scalars['Float']>;
  leavingNumber: Scalars['Float'];
  leavingWeight: Scalars['Float'];
  maleCastratesShare: Scalars['Float'];
  maleNonCastratesShare: Scalars['Float'];
  matureWeight: Scalars['Float'];
  mortalities: Scalars['Float'];
  stageDuration?: InputMaybe<Scalars['Float']>;
  timeSpentPregnantShare?: InputMaybe<Scalars['Float']>;
};

export type GrowingOutput = {
  /**  # The total number of animals that leave the stage to another stage or facility in the production system. */
  animalsToStage: Scalars['Float'];
  /**  # The average age of animals leaving the stage, in months. */
  averageAge: Scalars['Float'];
  /**  # The average weight of animals leaving the stage, in kilograms. */
  averageWeight: Scalars['Float'];
  /**  # Indicate if an impact result is needed, even if there are no sold animals in this stage. */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # The total number of mortalities over the whole duration of the stage. */
  mortalities: Scalars['Float'];
  /**  # Number of animals sold externally. This is an output of the production system for which a climate impact will be calculated. */
  soldExternally: Scalars['Float'];
  /**  # The average weight of an animal in this stage at mortality. */
  weightAtMortality: Scalars['Float'];
};

export enum GrowingPurpose {
  BeefProduction = 'BEEF_PRODUCTION',
  Replacement = 'REPLACEMENT'
}

export type GrowingStage = {
  /**  # Default: high. */
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # End date in the following format "yyyy-mm-dd" */
  endDate: Scalars['String'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The feed used for the animals. */
  feedingProgramCows: FeedingProgram;
  growingPurpose: GrowingPurpose;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the stage. */
  input: GrowingStageInput;
  /**
   *  # A list of all the used manure management systems used in the stage in parallel (not consecutively).
   * # A manure management system is required when time is spent in housing.
   */
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**  # A name of the type of animals may be provided. */
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the stage. */
  outputCows: GrowingOutput;
  /**  # The number of times this stage is repeated in one year. Default: 1. */
  repetitions?: InputMaybe<Scalars['Float']>;
  /**  # Start date in the following format "yyyy-mm-dd" */
  startDate: Scalars['String'];
  timeSpentCows: TimeSpent;
  /**  # Each water and energy carrier may be specified on either farm or stage level, where stage level is preferred. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStageInput = {
  /**  # Type of animal entering the stage. */
  animalType: AnimalType;
  /**  # A list of the animals coming into this stage. For each group of animals coming from a certain origin (a stage or background) specify an AnimalsIncomingInput. */
  animalsIncoming: Array<AnimalsIncoming>;
  /**  # The average age of animals entering the stage, in months. */
  averageAge: Scalars['Float'];
  /**  # The average weight of animals entering the stage, in kilograms. */
  averageWeight: Scalars['Float'];
};

export type Impact = {
  category: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type ImportedImpact = {
  id: Scalars['ID'];
  impacts: Array<Impact>;
  productName: Scalars['String'];
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  /**  # Unit: kg */
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  /**  Animals bought externally, the impact of these animals is derived from background data. */
  BackgroundData = 'BACKGROUND_DATA',
  /**  Animals coming from a stage modelled in the same production system. */
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export type KnownIntakeDiet = {
  __typename?: 'KnownIntakeDiet';
  feeds?: Maybe<Array<KnownIntakeFeed>>;
};

export type KnownIntakeDietInput = {
  feeds?: InputMaybe<Array<KnownIntakeFeedInput>>;
};

export type KnownIntakeFeed = {
  __typename?: 'KnownIntakeFeed';
  digestibility: Scalars['Float'];
  energyContent: Scalars['Float'];
  intake: Scalars['Float'];
  milkFromMother?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/**   Feed intake estimator types */
export type KnownIntakeFeedInput = {
  digestibility: Scalars['Float'];
  energyContent: Scalars['Float'];
  intake: Scalars['Float'];
  milkFromMother?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type Mms = {
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # Only required if the manure management system is of type DAILY_SPREAD. */
  manurePhase?: InputMaybe<ManurePhase>;
  /**  # The type of manure management system. */
  mmsType: ManureManagementSystemType;
  /**  # Percentage of the total manure of this stage that goes into this manure management system in dry matter. */
  share: Scalars['Float'];
};

export type MmsEmissionResult = {
  __typename?: 'MMSEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsType?: Maybe<Scalars['String']>;
};

export enum ManureManagementSystemType {
  /**  Aerobic treatment; Forced aeration systems */
  AerobicForced = 'AEROBIC_FORCED',
  /**  Aerobic treatment; Natural aeration systems */
  AerobicNatural = 'AEROBIC_NATURAL',
  /**  Anaerobic digester; High leakage, low quality technology, high quality gastight storage technology */
  AnaerobicHighLeakHighGastight = 'ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT',
  /**  Anaerobic digester; High leakage, low quality technology, low quality gastight storage technology */
  AnaerobicHighLeakLowGastight = 'ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT',
  /**  Anaerobic digester; High leakage, low quality technology, open storage */
  AnaerobicHighLeakOpen = 'ANAEROBIC_HIGH_LEAK_OPEN',
  /**  Anaerobic digester; Low leakage, High quality gastight storage, best complete industrial technology */
  AnaerobicLowLeakHighGastight = 'ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT',
  /**  Anaerobic digester; Low leakage, High quality industrial technology, low quality gastight storage technology */
  AnaerobicLowLeakLowGastight = 'ANAEROBIC_LOW_LEAK_LOW_GASTIGHT',
  /**  Anaerobic digester; Low leakage, High quality industrial technology, open storage */
  AnaerobicLowLeakOpen = 'ANAEROBIC_LOW_LEAK_OPEN',
  /**  Composting; Intensive Windrow (Frequent turning) */
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  /**  Composting; Passive windrow (infrequent turning) */
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  /**  Composting; Static Pile (Forced aeration) */
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  /**  Daily spread */
  DailySpread = 'DAILY_SPREAD',
  DeepBeddingActiveMixingOver_1Month = 'DEEP_BEDDING_ACTIVE_MIXING_OVER_1MONTH',
  DeepBeddingActiveMixingUnder_1Month = 'DEEP_BEDDING_ACTIVE_MIXING_UNDER_1MONTH',
  /**  Cattle deep bedding */
  DeepBeddingNoMixingOver_1Month = 'DEEP_BEDDING_NO_MIXING_OVER_1MONTH',
  DeepBeddingNoMixingUnder_1Month = 'DEEP_BEDDING_NO_MIXING_UNDER_1MONTH',
  /**  Liquid/Slurry; Cover */
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  /**  Liquid/Slurry; With natural crust cover */
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  /**  Liquid/Slurry; Without natural crust cover */
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  /**  Pit storage below animal confinements */
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  /**  Solid storage; Additives */
  SolidAdditives = 'SOLID_ADDITIVES',
  /**  Solid storage; Bulking agent addition */
  SolidBulking = 'SOLID_BULKING',
  /**  Solid storage; Covered/compacted */
  SolidCovered = 'SOLID_COVERED',
  /**  Solid storage */
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export enum ManurePhase {
  LiquidSlurry = 'LIQUID_SLURRY',
  Solid = 'SOLID'
}

export type MilkProduction = {
  /**  # Milk production in kg milk/year, default is calculated. */
  amount?: InputMaybe<Scalars['Float']>;
  /**  # Fat content of the milk in percentage, default is 4% */
  fatContent?: InputMaybe<Scalars['Float']>;
  /**  # Protein content of the milk in percentage, default is calculated. */
  proteinContent?: InputMaybe<Scalars['Float']>;
};

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  /**
   *  # The use_quantity of the specific output. Used for economic allocation. In euro per kg.
   * # Default values are available for pigs and cattle, it is a required field for the other animal types.
   */
  price?: InputMaybe<Scalars['Float']>;
  /**  # The weight of the specific output. In kilogram. */
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

export type PerformanceParameter = {
  __typename?: 'PerformanceParameter';
  magnitude: Scalars['Float'];
  name: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

/**   ANIMAL PROCESSING MODELS */
export type PrimaryPackaging = {
  /**  # The amount of primary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

/**   ANIMAL PROCESSING ENUMS */
export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

/**   The impacts associated with an output stage */
export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  /**  # Summation of impacts occurring in supply chain (i.e. LCA results) */
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  /**  # Impacts occurring in this node */
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  /**  # A list of tags that can be used for grouping of the results */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /**  # Product nodes feeding into this node */
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

export type ProductionSystem = {
  /**  # When the animals in the modelled production system will be processed too, the animal processing can be defined. */
  animalProcessing?: InputMaybe<AnimalProcessing>;
  /**  # A list of farms in the modelled production system. A farm is in a single location. */
  farms: Array<InputMaybe<Farm>>;
};

export type ProductionSystemInput = {
  /**  # A list of compound feeds that are used in the production system */
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  /**  # The foundational database that must be used for the impact calculations */
  databaseFoundation: DatabaseFoundation;
  /**  # Precalculated impacts to be used in LCA calculation */
  importedImpacts?: InputMaybe<Array<InputMaybe<ImportedImpact>>>;
  productionSystem: ProductionSystem;
  /**  # Split ecotoxicity in EF3.1. Default is True. */
  splitEcotoxicity?: InputMaybe<Scalars['Boolean']>;
};

/**   The impacts and emissions associated with the production system */
export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  /**  # Emissions occurring in this production system. Emissions results are per stage and not scaled. */
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  /**  # A list of climate impact assessment results for all stages with an external output. All results of the upstream products are per kilogram of the output. */
  impactResults?: Maybe<Array<ProductResultNode>>;
  /**  # The performance parameters of the system. */
  performanceParameters?: Maybe<SystemPerformanceParameters>;
  /**  # None code terminating warnings */
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Query = {
  __typename?: 'Query';
  getFeedIntakeEstimation?: Maybe<FeedIntakeEstimatorResult>;
  getLciResultByProductionSystemInput?: Maybe<ForegroundTreeResult>;
  /**  # queries cattle system with a production system as input, and returns  the results in terms of impact and emissions */
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationErrorCode>>>;
};


export type QueryGetFeedIntakeEstimationArgs = {
  feedIntakeEstimatorInput?: InputMaybe<FeedIntakeEstimatorInput>;
};


export type QueryGetLciResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};


export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};

export type Refrigerant = {
  /**  # The used amount of the refrigerant. In kilogram per year. */
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export type SecondaryPackaging = {
  /**  # The amount of secondary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type SingleIngredientUse = {
  /**  # Weight percentage ash content as a fraction of the feed dry matter weight. Default: 10% */
  ashContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage crude protein content as a fraction of the feed dry matter weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Percentage digestible energy over the gross energy intake. */
  digestibleEnergyContent: Scalars['Float'];
  /**  # The distance of transport by truck, in kilometers. */
  distanceTransport?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage dry matter content as a fraction of the feed weight. */
  dryMatterContent: Scalars['Float'];
  /**  # Gross energy content (MJ / kg dry matter feed). Default: 18.45 MJ/kg */
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # Single ingredient used */
  ingredient: Ingredient;
  /**  # Indicate if this feed is externally bought milk or replacer. Default: false. */
  milk?: InputMaybe<Scalars['Boolean']>;
  /**  # Indicate if this feed is milk from the mother cow. Only relevant for calves in breeding stage. Default: false. */
  milkFromMother?: InputMaybe<Scalars['Boolean']>;
  /**  # Weight percentage of natural detergent fiber as a fraction of the dry matter feed weight. Required and only used for the dairy stage. */
  naturalDetergentFiberContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage phosphorus content as a fraction of the feed dry matter weight. */
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram dry matter. */
  quantity: Scalars['Float'];
  /**  # Specify weather this single ingredient is a silage. */
  silage: Scalars['Boolean'];
  /**  # Percentage urinary energy over the gross energy intake. Default: 4% */
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsEmissionResults?: Maybe<Array<MmsEmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type StagePerformanceParameters = {
  __typename?: 'StagePerformanceParameters';
  stageId: Scalars['String'];
  stageName?: Maybe<Scalars['String']>;
  stageParameters?: Maybe<Array<Maybe<PerformanceParameter>>>;
};

export type Stock = {
  /**  # Weight of the cows at the end of the period, defaults to output weight, required for continuous growing stage */
  averageWeightCowsEnd?: InputMaybe<Scalars['Float']>;
  /**  # Weight of the cows at the start of the period */
  averageWeightCowsStart: Scalars['Float'];
  /**  # Number of cows present at the end of the period */
  cowsPresentAtEnd: Scalars['Float'];
  /**  # Number of cows present at the start of the period */
  cowsPresentAtStart: Scalars['Float'];
};

export type SystemPerformanceParameters = {
  __typename?: 'SystemPerformanceParameters';
  /**  # For each stage in the modelled system some performance parameters are calculated per stage. */
  stages?: Maybe<Array<Maybe<StagePerformanceParameters>>>;
  /**  # The system performance parameters contain the net commercial off-take of the whole modelled production system */
  systemParameters?: Maybe<Array<Maybe<PerformanceParameter>>>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  /**  # The amount of tertiary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  Pallet = 'PALLET',
  PlasticFilm = 'PLASTIC_FILM'
}

export type TimeSpent = {
  /**  # Percentage of time spent in feedlot. The total of time spent should be 100 percent. */
  timeInFeedlot: Scalars['Float'];
  /**  # Percentage of time spent in housing. The total of time spent should be 100 percent. */
  timeInHousing: Scalars['Float'];
  /**  # The percentage of the time spent in house, that the animals are tied. Required and only used for the dairy stage. */
  timeInHousingTied?: InputMaybe<Scalars['Float']>;
  /**  # Percentage of time spent grazing large areas. The total of time spent should be 100 percent. */
  timeOnLargeGrazingAreas: Scalars['Float'];
  /**  # Percentage of time spent on grazing small areas. The total of time spent should be 100 percent. */
  timeOnSmallGrazingAreas: Scalars['Float'];
};

export type Transport = {
  /**  # In kilometer */
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type UnknownIntakeDietInput = {
  feeds?: InputMaybe<Array<UnknownIntakeFeedInput>>;
};

export type UnknownIntakeFeedInput = {
  digestibility: Scalars['Float'];
  energyContent: Scalars['Float'];
  milkFromMother?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  share: Scalars['Float'];
};

export type ValidationErrorCode = {
  __typename?: 'ValidationErrorCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Water = {
  /**  # The amount water. In cubic meter per year. */
  amount: Scalars['Float'];
  type: WaterType;
};

export enum WaterType {
  /**  The water use, in cubic meter. */
  Water = 'WATER'
}
