export interface FootprintURLProps {
  baselineCustomerID: string;
  baselineFarmID: string;
  baselineProcessID: string;
  baselineType: string;
  baselineReference: string;
  comparisonFarmID?: string;
  comparisonProcessID?: string;
  comparisonType?: string;
  comparisonReference?: string;
  comparisonCustomerID?: string;
}

/**
 *
 * construct the footprint url based on dataset properties
 * @returns the url string
 */

export const footprintURL = ({
  baselineCustomerID,
  baselineFarmID,
  baselineProcessID,
  baselineType,
  baselineReference,
  comparisonFarmID,
  comparisonProcessID,
  comparisonType,
  comparisonReference,
  comparisonCustomerID,
}: FootprintURLProps) => {
  let url = '';
  if (baselineType === 'f')
    url += `/feed-footprints/${baselineCustomerID}/${baselineType}/${baselineReference}`;
  else
    url += `/footprints/${baselineCustomerID}/${baselineFarmID}/${baselineProcessID}/${baselineType}/${baselineReference}`;

  if (
    baselineType === 'f' &&
    comparisonType === 'f' &&
    comparisonReference &&
    comparisonCustomerID
  )
    url += `/${comparisonType}/${comparisonReference}/${comparisonCustomerID}`;
  else if (
    comparisonFarmID &&
    comparisonProcessID &&
    comparisonReference &&
    comparisonType &&
    comparisonReference &&
    comparisonCustomerID
  )
    url += `/${comparisonFarmID}/${comparisonProcessID}/${comparisonType}/${comparisonReference}/${comparisonCustomerID}`;

  return url;
};

/**
 * Get the footprint type for the api
 *
 * @param footprintType the footprint type, it should be b(aseline), i(ntervetion) or f(eed)
 * @returns the matching GraphQL footprintType
 */
export const getFootprintType = (footprintType?: string) => {
  if (footprintType === 'b') return 'FARM_BASELINE';
  if (footprintType === 'i') return 'FARM_INTERVENTION';
  if (footprintType === 'f') return 'COMPOUND_FEED';
  return undefined;
};
