import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from "@material-ui/core";

import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup"
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs'; 
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { unitLong, defaultUnits, explicitConvertValue } from '../../../../utils/unit-utils';
import { avgLiveWeightMaxs, avgLiveWeightMins } from '../../../../../modules/Farms/validationSchemaRestrictions';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { PigFatteningBaseline, PigFatteningInput } from '../../../../models/Baseline';

const PFInputFormDialog: FC<BaselineDialogProps> = ({
  handleCancel,
  formType=FormType.Add,
  itemIndex=0,
  formVisible=false
}) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
 
  
  let minAvgLiveWeightMortality = avgLiveWeightMins['Pig fattening'];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let maxAvgLiveWeightMortality: number | undefined = avgLiveWeightMaxs['Pig fattening']; // No max provided
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if(userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass){
    if(minAvgLiveWeightMortality)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      minAvgLiveWeightMortality = Number(explicitConvertValue(minAvgLiveWeightMortality, defaultUnits.unitBarnOutputMass, userUOM.unitBarnOutputMass)?.toFixed(precision));
    if(maxAvgLiveWeightMortality)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      maxAvgLiveWeightMortality = Number(explicitConvertValue(maxAvgLiveWeightMortality, defaultUnits.unitBarnOutputMass, userUOM.unitBarnOutputMass)?.toFixed(precision));
  }
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"}) ;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`; 

  const currResetValue = useRef<PigFatteningInput>();
  const formContext = useFormContext<PigFatteningBaseline>();

  useEffect(() => {
    if(formVisible){
      currResetValue.current={...formContext.getValues(fieldItemPrefix)} as PigFatteningInput; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as PigFatteningBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.input){
        resetObject.stages[itemIndex].stageData.input = {...currResetValue.current} as PigFatteningInput;
        formContext.reset(resetObject, { errors: true });
      }
    }
    
    if(handleCancel)
      handleCancel();
  }

  return (
    <DialogContainer
      formVisible ={ formVisible }
      handleClose = { handleResetClick }
      iconCode="PIG_FATTENING_INPUT"
      formTitle = {formTitle}
    > 
      <Grid container direction="column" spacing={3} justifyContent="space-between" >
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.purchasedPiglets`}
            type="number"
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.PURCHASED_PIGLETS"})} *`}
            margin="none"
            variant="outlined"
            tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.PURCHASED_PIGLETS.TOOLTIP"}) }
            disabled={formType === "view"}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.purchasedPiglets`) }
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.averageLiveWeight`}
            type="number"
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.AVERAGE_LIVEWEIGHT"})} *`}
            margin="none"
            variant="outlined"
            addornment={ barnOutputMassUnit }
            // tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.PURCHASED_PIGLETS.TOOLTIP"}) }
            disabled={formType === "view"}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.averageLiveWeight`) }
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.mortalityRate`}
            type="number"
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.MORTALITY"})} *`}
            margin="none"
            variant="outlined"
            tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.MORTALITY.TOOLTIP"}) }
            addornment="%"
            disabled={formType === "view"}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.mortalityRate`) }
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.averageLiveWeightMortalities`}
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.AVG_LIVEWEIGHT_MORTALITY"}, {min: minAvgLiveWeightMortality, max: maxAvgLiveWeightMortality, unit: barnOutputMassUnit })} *`}
            margin="none"
            variant="outlined"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.PIGS.AVG_LIVEWEIGHT_MORTALITY.TOOLTIP"}, {min: minAvgLiveWeightMortality, max: maxAvgLiveWeightMortality, unit: barnOutputMassUnit }) }
            addornment={ barnOutputMassUnit }
            disabled={formType === "view"}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.averageLiveWeightMortalities`) }
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleCancel }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default PFInputFormDialog;