// TODO: check -  move or remove if needed..didn't wanted to make additional API call only for country name
const countryIsoCodes = {
  'AF': 'Afghanistan',
  'RAF': 'Africa',
  'US-AL': 'Alabama',
  'US-AK': 'Alaska',
  'AL': 'Albania',
  'CA-AB': 'Alberta province of Canada',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'UN-AMERICAS': 'Americas',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AG': 'Antigua and Barbuda',
  'AR': 'Argentina',
  'US-AZ': 'Arizona',
  'US-AR': 'Arkansas',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'UN-ASIA': 'Asia',
  'CA-AT': 'Atlantic region of Canada',
  'AU': 'Australia',
  'UN-AUSTRALIANZ': 'Australia and New Zealand',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BY': 'Belarus',
  'BE': 'Belgium',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia (Plurinational State of)',
  'BA': 'Bosnia and Herzegovina',
  'BW': 'Botswana',
  'BR': 'Brazil',
  'VG': 'British Virgin Islands',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'CV': 'Cabo Verde',
  'US-CA': 'California',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'UN-CARIBBEAN': 'Caribbean',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'UN-CAMERICA': 'Central America',
  'UN-CASIA': 'Central Asia',
  'TD': 'Chad',
  'CL': 'Chile',
  'HK': 'China, Hong Kong SAR',
  'MO': 'China, Macao SAR',
  'TW': 'China, Taiwan Province of',
  'CN': 'China, mainland',
  'CO': 'Colombia',
  'US-CO': 'Colorado',
  'FSU': 'Commonwealth of Independent States',
  'KM': 'Comoros',
  'CG': 'Congo',
  'US-CT': 'Connecticut',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CY': 'Cyprus',
  'CZ': 'Czechia',
  'CI': "C\u00f4te d'Ivoire",
  'US-DE': 'Delaware',
  'KP': "Democratic People's Republic of Korea",
  'CD': 'Democratic Republic of the Congo',
  'DK': 'Denmark',
  'US-DC': 'District of Columbia',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'UN-EAFRICA': 'Eastern Africa',
  'UN-EASIA': 'Eastern Asia',
  'UN-EEUROPE': 'Eastern Europe',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'SZ': 'Eswatini',
  'ET': 'Ethiopia',
  'RER': 'Europe',
  'FK': 'Falkland Islands (Malvinas)',
  'FO': 'Faroe Islands (Associate Member)',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'US-FL': 'Florida',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'US-GA': 'Georgia (U.S. State)',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GLO': 'Global',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'US-HI': 'Hawaii',
  'VA': 'Holy See',
  'HN': 'Honduras',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'US-ID': 'Idaho',
  'US-IL': 'Illinois',
  'IN': 'India',
  'US-IN': 'Indiana',
  'ID': 'Indonesia',
  'US-IA': 'Iowa',
  'IR': 'Iran (Islamic Republic of)',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IM': 'Isle of Man',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JO': 'Jordan',
  'US-KS': 'Kansas',
  'KZ': 'Kazakhstan',
  'US-KY': 'Kentucky',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': "Lao People's Democratic Republic",
  'RLA': 'Latin America and the Caribbean',
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'US-LA': 'Louisiana',
  'LU': 'Luxembourg',
  'MG': 'Madagascar',
  'US-ME': 'Maine',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'CA-MB': 'Manitoba province of Canada',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'US-MD': 'Maryland',
  'US-MA': 'Massachusetts',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'UN-MELANESIA': 'Melanesia',
  'MX': 'Mexico',
  'US-MI': 'Michigan',
  'UN-MICRONESIA': 'Micronesia',
  'FM': 'Micronesia (Federated States of)',
  'UN-MAFRICA': 'Middle Africa',
  'RME': 'Middle East',
  'US-MN': 'Minnesota',
  'US-MS': 'Mississippi',
  'US-MO': 'Missouri',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'US-MT': 'Montana',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'US-NE': 'Nebraska',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'AN': 'Netherlands Antilles (former)',
  'US-NV': 'Nevada',
  'NC': 'New Caledonia',
  'US-NH': 'New Hampshire',
  'US-NJ': 'New Jersey',
  'US-NM': 'New Mexico',
  'US-NY': 'New York',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'US-NC': 'North Carolina',
  'US-ND': 'North Dakota',
  'UN-NAFRICA': 'Northern Africa',
  'RNA': 'Northern America',
  'UN-NEUROPE': 'Northern Europe',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OCE': 'Oceania',
  'US-OH': 'Ohio',
  'US-OK': 'Oklahoma',
  'OM': 'Oman',
  'CA-ON': 'Ontario province of Canada',
  'US-OR': 'Oregon',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'US-PA': 'Pennsylvania',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn Islands',
  'PL': 'Poland',
  'UN-POLYNESIA': 'Polynesia',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'CA-QC': 'Quebec province of Canada',
  'KR': 'Republic of Korea',
  'MD': 'Republic of Moldova',
  'US-RI': 'Rhode Island',
  'RO': 'Romania',
  'RU': 'Russian Federation',
  'RW': 'Rwanda',
  'RE': 'R\u00e9union',
  'SH': 'Saint Helena, Ascension and Tristan da Cunha',
  'KN': 'Saint Kitts and Nevis',
  'LC': 'Saint Lucia',
  'PM': 'Saint Pierre and Miquelon',
  'VC': 'Saint Vincent and the Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome and Principe',
  'CA-SK': 'Saskatchewan province of Canada',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'CS': 'Serbia and Montenegro',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'UN-SAMERICA': 'South America',
  'US-SC': 'South Carolina',
  'US-SD': 'South Dakota',
  'SS': 'South Sudan',
  'UN-SEASIA': 'South-eastern Asia',
  'UN-SAFRICA': 'Southern Africa',
  'SAS': 'Southern Asia',
  'UN-SEUROPE': 'Southern Europe',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard and Jan Mayen Islands',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'SY': 'Syrian Arab Republic',
  'TJ': 'Tajikistan',
  'US-TN': 'Tennessee',
  'US-TX': 'Texas',
  'TH': 'Thailand',
  'MK': 'The former Yugoslav Republic of Macedonia',
  'TL': 'Timor-Leste',
  'TG': 'Togo',
  'TK': 'Tokelau (Associate Member)',
  'TO': 'Tonga',
  'TT': 'Trinidad and Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks and Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom',
  'TZ': 'United Republic of Tanzania',
  'VI': 'United States Virgin Islands',
  'US': 'United States of America',
  'UY': 'Uruguay',
  'US-UT': 'Utah',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VE': 'Venezuela (Bolivarian Republic of)',
  'US-VT': 'Vermont',
  'VN': 'Viet Nam',
  'US-VI': 'Virgin Islands',
  'US-VA': 'Virginia',
  'WF': 'Wallis and Futuna Islands',
  'US-WA': 'Washington',
  'US-WV': 'West Virginia',
  'UN-WAFRICA': 'Western Africa',
  'UN-WASIA': 'Western Asia',
  'WEU': 'Western Europe',
  'CA-WE': 'Western region of Canada',
  'US-WI': 'Wisconsin',
  'US-WY': 'Wyoming',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe'
}
export default countryIsoCodes;
