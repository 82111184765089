import { type FC } from 'react';
import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

export interface TableHeaderItemProps {
  label: string;
  hasSort: boolean;
  isSortActive: boolean;
  isDescending: boolean;
  handleSort: ((event: unknown) => void) | undefined;
  index: number;
}

const TableHeaderItem: FC<TableHeaderItemProps> = ({
  label,
  hasSort,
  isSortActive,
  isDescending,
  handleSort,
  index,
}) => {
  const intl = useIntl();
  if (hasSort) {
    return (
      <TableCell
        style={{
          cursor: 'pointer',
          padding: index === 0 ? '16px 0 16px 16px' : '16px 0 16px 8px',
        }}
      >
        <Tooltip title={intl.formatMessage({ id: 'SUSTELL.DASHBOARD.SORT' })}>
          <TableSortLabel
            active={isSortActive}
            direction={isSortActive && isDescending ? 'desc' : 'asc'}
            onClick={handleSort}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    );
  }
  return <TableCell>{label}</TableCell>;
};

export default TableHeaderItem;
