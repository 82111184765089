import { FC, useRef, useState } from "react";

import { Box, Button, Divider, Grid, List, ListItem, ListItemText, Menu, Typography, ListItemIcon } from "@material-ui/core";
import { ImportExportOutlined, Check } from "@material-ui/icons";
import { DSM_BLUE, footprintDetailsStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { DashboardType, SortOption } from "../../models/Dashboard/DashboardTypes";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";

interface DashboardSortControlProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
}

const DashboardSortControl: FC<DashboardSortControlProps> = ({
  dashboard,
  renderDashboard,
}) => {
  const intl = useIntl();
  const classes = footprintDetailsStyles();
  const [sortOpen, setSortOpen] = useState(false);

  const menuAnchorEl = useRef<HTMLButtonElement | null>(null);

  let title = intl.formatMessage({id:"SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_PROD_PROCESSES"});
  if (dashboard && dashboard.type === DashboardType.Farm && dashboard.filters?.farms?.length &&
    dashboard.filters?.farms?.length > 0) {
    title = intl.formatMessage({id: "SUSTELL.DASHBOARD.DETAILS.SELECT.SELECTED_PROD_PROCESSES"});
  }
  else if (dashboard?.type === DashboardType.Feeds) {
    title = intl.formatMessage({id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_FEEDS"});
  }

  const handleOpenSortDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    menuAnchorEl.current = event.currentTarget as HTMLButtonElement;
    setSortOpen(!sortOpen);
  };

  const handleClose = () => {
    menuAnchorEl.current = null;
    setSortOpen(false);
  };

  const handleSortSelected = (option: SortOption ,field: 'Name' | 'Total') => {
    if(dashboard?.sortOptions[field] === option) {
      return;
    }
    dashboard?.setSortOption(option, field);
    renderDashboard();
  }
  
  return (
   <Grid container direction="row" alignContent="center" alignItems="center" spacing={3}>
    <Grid item xs={10}>
      <Typography variant="h6" style={{color: DSM_BLUE}}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={2} style={{display: 'flex', flexDirection: 'row-reverse'}} > 
      <Button
        color="secondary"
        variant="outlined"
        aria-expanded={
          sortOpen
            ? 'true'
            : undefined
        }
        className={classes.buttonStyles}
        startIcon={<ImportExportOutlined />}
        onClick={handleOpenSortDialog}
        >
          {intl.formatMessage({id: "SUSTELL.DASHBOARD.SORT"})}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={menuAnchorEl.current}
        keepMounted
        open={sortOpen}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={classes.menuPaperStyles}
      >
        <Box>
          <Box style={{ paddingLeft: 16 }}>
            <Typography
              className={classes.customizeViewFilterMenuTitleStyles}
              style={{ fontWeight: '500' }}
            >
              {intl.formatMessage({id: "SUSTELL.DASHBOARD.SORT_ALPH"})}
            </Typography>
          </Box>
          <Divider className={classes.dividerStyles} />
          <Box style={{ marginLeft: '16px', marginRight: '16px' }} className={classes.accordionStyles}>
            <List key="sort-list-seleciton">
              <ListItem
                key="sort-list-seleciton-alp-asc"
                button 
                selected={dashboard?.sortOptions['Name' as const] === 'Asc'}
                style={{padding: '4px 2px 4px 2px', borderRadius: '6px', background: (dashboard?.sortOptions['Name' as const]  === 'Asc' ? '#F0F7FB' : '')}}
                onClick={() => dashboard?.sortOptions['Name' as const]  === 'Asc' ? handleSortSelected(undefined, 'Name') : handleSortSelected('Asc', 'Name')}
              >
                <ListItemText primary="A-Z" />
                { dashboard?.sortOptions['Name' as const] === 'Asc' &&
                  <ListItemIcon style={{minWidth: '22px', color: '#0070BA'}}>
                    <Check />
                  </ListItemIcon>
                }
              </ListItem>
              <ListItem
                key="sort-list-seleciton-alp-desc"
                button 
                selected={dashboard?.sortOptions['Name' as const]  === 'Desc'}
                style={{padding: '4px 2px 4px 2px', borderRadius: '6px', background: (dashboard?.sortOptions['Name' as const]  === 'Desc' ? '#F0F7FB' : '')}}
                onClick={() => dashboard?.sortOptions['Name' as const] === 'Desc' ? handleSortSelected(undefined, 'Name') : handleSortSelected('Desc', 'Name')}
              >
                <ListItemText primary="Z-A" />
                { dashboard?.sortOptions['Name' as const] === 'Desc' &&
                  <ListItemIcon style={{minWidth: '22px', color: '#0070BA'}}>
                    <Check />
                  </ListItemIcon>
                }
              </ListItem>
              
              <Typography
                className={classes.customizeViewFilterMenuTitleStyles}
                style={{ fontWeight: '500' }}
              >
                {intl.formatMessage({id: "SUSTELL.DASHBOARD.SORT_NUM"})}
              </Typography>
              <ListItem
                key="sort-list-seleciton-num-asc"
                button 
                selected={dashboard?.sortOptions['Total' as const]  === 'Asc'}
                style={{padding: '4px 2px 4px 2px', borderRadius: '6px', background: (dashboard?.sortOptions['Total' as const] === 'Asc' ? '#F0F7FB' : '')}}
                onClick={() => dashboard?.sortOptions['Total' as const] === 'Asc' ? handleSortSelected(undefined, 'Total') : handleSortSelected('Asc', 'Total')}
              >
                <ListItemText primary="Ascending" />
                { dashboard?.sortOptions['Total' as const] === 'Asc' &&
                  <ListItemIcon style={{minWidth: '22px', color: '#0070BA'}}>
                    <Check />
                  </ListItemIcon>
                }
              </ListItem>
              <ListItem
                key="sort-list-seleciton-num-desc"
                button 
                selected={dashboard?.sortOptions['Total' as const] === 'Desc'}
                style={{padding: '4px 2px 4px 2px', borderRadius: '6px', background: (dashboard?.sortOptions['Total' as const] === 'Desc' ? '#F0F7FB' : '')}}
                onClick={() => dashboard?.sortOptions['Total' as const] === 'Desc' ? handleSortSelected(undefined, 'Total') : handleSortSelected('Desc', 'Total')}
              >
                <ListItemText primary="Descending" />
                { dashboard?.sortOptions['Total' as const] === 'Desc' &&
                  <ListItemIcon style={{minWidth: '22px', color: '#0070BA'}}>
                    <Check />
                  </ListItemIcon>
                }
              </ListItem>
            </List>
        </Box>
        </Box>
      </Menu>
    </Grid>
  </Grid>
  );
}

export default DashboardSortControl;