// import { multicastChannel } from '@redux-saga/core';
import * as Yup from 'yup';

import { interventionFieldWithMin } from './interventionValidationObjectBuilderFunctions';
import { forbiddenChars } from '../../../../sustell_15/helpers/validations';
import { resourceItems } from '../../Baseline/validationSchema/baselineValidationSchemaGeneralPart';

// Yup validation schema for intervension form data
export const interventionValidationSchemaGeneralPart = ({ intl }) =>
  Yup.object({
    animalType: Yup.string(),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
      )
      // prevent use of quotation marks in the name
      .concat(
        forbiddenChars(
          intl,
          ['"', "'"],
          'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
        )
      ),
    interventionCategory: Yup.string(),
    description: Yup.string()
  });

export const interventionValidationResourseUsagePart = ({ intl }) =>
  Yup.object({
    electricityUse: interventionFieldWithMin(intl, 'electricityUse', 0),
    gasUse: interventionFieldWithMin(intl, 'gasUse', 0),
    dieselUse: interventionFieldWithMin(intl, 'dieselUse', 0),
    waterUse: interventionFieldWithMin(intl, 'waterUse', 0),
  });

export const interventionValidationResourseUsagePartV2 = ({ intl }) =>
  Yup.object({
    electricityUse: interventionFieldWithMin(intl, 'electricityUse', 0),
    gasUse: interventionFieldWithMin(intl, 'gasUse', 0),
    dieselUse: interventionFieldWithMin(intl, 'dieselUse', 0),
    waterUse: interventionFieldWithMin(intl, 'waterUse', 0),
    coalUse: interventionFieldWithMin(intl, 'coalUse', 0),
    propaneOrLpgUse:  interventionFieldWithMin(intl, 'propaneOrLpgUse', 0),
    gasolineUse: interventionFieldWithMin(intl, 'gasolineUse', 0),
    heavyFuelOilUse: interventionFieldWithMin(intl, 'heavyFuelOilUse', 0),
    electricityPhotovoltaicUse: interventionFieldWithMin(intl, 'electricityPhotovoltaicUse', 0),
    electricityWindUse:  interventionFieldWithMin(intl, 'electricityWindUse', 0),
    selfGeneratedRenewables: resourceItems(intl),
    energyTypes: resourceItems(intl),
  });
