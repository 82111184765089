// TODO Typescript depends on MaterialThemeProvider.js
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import Can from '../../../modules/Auth/Can';
import FadeMenu from '../pageparts/TableMenu';
import { ExactNumber } from '../helpers/ExactNumber';
import { getImpactData } from '../helpers/ImpactNumbers';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import TitleLink from '../helpers/TitleLink';
import { footprintURL } from '../../utils/footprint-url-utils';
import { ImpactSummary } from '../../models/Dataset';
import getBaselineDraftByKey from '../../../state/drafts/baselineGetFromDraft';
import getInterventionDraftByKey from '../../../state/drafts/interventionGetFromDraft';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { AnimalType } from '../../../../graphql/types';
import { useState } from 'react';
import { SortDirection } from 'aws-amplify';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { isReadOnlyAnimalSystem } from './common';

type DatasetListTableProps = {
  customerID: string;
  farmID: string;
  processID: string;
  farmName: string;
  processName: string;
  animalType: AnimalType;
  variant: string;
  archive: boolean;
  datasets: Dataset[];
  openDataset: (
    datasetID: string,
    formType: string,
    reference?: string
  ) => void;
  deleteHandler: (itemName: string) => void;
  duplicateDataset: ((item: object) => void) | null;
  benchmark: boolean;
  activeBaselineYear: string;
};

type Dataset = {
  baseline: string;
  id: string;
  impactSummary: string;
  name: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  lastUpdatedBy: string;
  calculationInProgress?: boolean;
  year: string;
};

enum SortColumn {
  Name = 'name',
  LastModified = 'updatedAt',
  Status = 'country',
  TotalClimateChange = 'animalSystems',
  CreatedAt = 'createdAt',
  ModifiedBy = 'lastUpdatedBy',
}

interface TableSort {
  column: SortColumn | undefined;
  direction: SortDirection | undefined;
}

export const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: '12px !important',
    borderBottom: '1px solid #f0f0f0 !important',
  },
  body: {
    border: 'none',
  },
  root: {
    '&:nth-of-type(1)': {
      Width: '298px',
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    border: '1px solid var(--dsm-color-neutral)',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9FAFB',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

export const inlineStyleComplete = {
  lineHeight: '2em',
  fontWeight: '500',
  padding: '0 15px',
  borderRadius: '1em',
  color: 'var(--dsm-color-success',
  backgroundColor: 'var(--dsm-color-success-lighter)',
  border: '1px solid var(--dsm-color-success',
};

export const inlineStyleIncomplete = {
  lineHeight: '2em',
  fontWeight: '500',
  borderRadius: '1em',
  padding: '0 10px',
  color: 'var(--dsm-color-neutral-darker',
  backgroundColor: 'var(--dsm-color-neutral-lighter)',
  border: '1px solid var(--dsm-color-neutral-darker',
};

export const inlineStyleError = {
  lineHeight: '2em',
  fontWeight: '500',
  borderRadius: '1em',
  padding: '0 10px',
  color: 'var(--dsm-color-error)',
  backgroundColor: 'var(--dsm-color-error-lightest)',
  border: '1px solid var(--dsm-color-error',
};

const DatasetListTableV2 = ({
  customerID,
  farmID,
  processID,
  farmName,
  processName,
  animalType = AnimalType.Pig,
  variant = 'baseline',
  archive = false,
  datasets,
  openDataset,
  deleteHandler,
  duplicateDataset = null,
  benchmark,
  activeBaselineYear,
}: DatasetListTableProps) => {
  const intl = useIntl();
  const history = useHistory();

  const type = variant === 'baseline' ? 'Baseline' : 'Intervention';
  const { ctaClickEvent } = useAdobeDataLayer();
  const [, upateLocalState] = useState<object>({});
  const [sort, setSort] = useState<TableSort>({
    column: undefined,
    direction: undefined,
  });
  const [currPage, setCurrPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const countElements = (datasets || [])?.length;
  // for now, archive only for baseline
  const emptyKey = archive ? 'ARCHIVE' : variant?.toUpperCase();

  const onTablePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrPage(page);
  };

  const onTableRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal = event.target.value;
    setCurrPage(0);
    setRowsPerPage(Number(newVal));
  };

  const toggleSort = (column: SortColumn) => {
    // If same toggle asc,desc, clear
    if (sort.column === column) {
      let newSort;
      if (!sort.direction) {
        newSort = SortDirection.DESCENDING;
      } else if (sort.direction === SortDirection.DESCENDING) {
        newSort = SortDirection.ASCENDING;
      } else {
        newSort = undefined;
      }
      setSort({
        column,
        direction: newSort,
      });
    } else {
      setSort({
        column,
        direction: SortDirection.DESCENDING,
      });
    }
    upateLocalState({});
  };

  const getSortedPaginatedDatasets = (): Dataset[] =>
    datasets
      .filter(
        (item, index) =>
          index >= currPage * rowsPerPage &&
          index < currPage * rowsPerPage + rowsPerPage
      )
      .sort((f0, f1) => {
        let comp0 = 0;
        let comp1 = 0;
        if (sort.column === SortColumn.Name) {
          comp0 = f0.name.localeCompare(f1.name);
          comp1 = f1.name.localeCompare(f0.name);
        } else if (sort.column === SortColumn.LastModified) {
          comp0 =
            new Date(f0.updatedAt.toString()).getTime() -
            new Date(f1.updatedAt.toString()).getTime();
          comp1 =
            new Date(f1.updatedAt.toString()).getTime() -
            new Date(f0.updatedAt.toString()).getTime();
        } else if (sort.column === SortColumn.CreatedAt) {
          comp0 =
            new Date(f0.createdAt.toString()).getTime() -
            new Date(f1.createdAt.toString()).getTime();
          comp1 =
            new Date(f1.createdAt.toString()).getTime() -
            new Date(f0.createdAt.toString()).getTime();
        } else if (sort.column === SortColumn.TotalClimateChange) {
          const impactSummary0 = getImpactData(f0) as ImpactSummary;
          const impactSummary1 = getImpactData(f1) as ImpactSummary;
          const airValue0 = impactSummary0?.air?.value || 0;
          const airValue1 = impactSummary1?.air?.value || 0;
          comp0 = airValue0 - airValue1;
          comp1 = airValue1 - airValue0;
        }
        if (sort.column === SortColumn.ModifiedBy) {
          comp0 = f0.name.localeCompare(f1.lastUpdatedBy);
          comp1 = f1.name.localeCompare(f0.lastUpdatedBy);
        }
        if (sort.column === SortColumn.Status) {
          comp0 = f0.name.localeCompare(f1.lastUpdatedBy);
          comp1 = f1.name.localeCompare(f0.lastUpdatedBy);
        }
        if (!sort.direction) {
          return 0;
        }
        if (sort.direction === SortDirection.ASCENDING) {
          return comp0;
        }
        return comp1;
      });

  const open = (item: Dataset, formType: string) => {
    if (openDataset)
      if (variant === 'baseline') openDataset(item.id, formType);
      // intervention has two params, baseline id and intervention reference
      else openDataset(item.baseline, formType, item.reference);
  };

  const openFootprint = (
    baselineReference: string,
    dataSetName: string,
    openIfError: boolean = false
  ) => {
    let path = footprintURL({
      baselineCustomerID: customerID,
      baselineFarmID: farmID,
      baselineProcessID: processID,
      baselineType: variant === 'baseline' ? 'b' : 'i',
      baselineReference,
    });
    if (openIfError) path = `${path}/?initialTabOpened=error`;
    ctaClickEvent(
      path,
      'link',
      `Open ${variant} footprint`,
      'Baseline interventions',
      'Production processes'
    );
    history.push(path, {
      farmName,
      processName,
      dataSetName,
      animalType,
    });
  };

  const calculationCompleted = (item: Dataset) => {
    if (!item.calculationInProgress && item.impactSummary) return true;
    return false;
  };

  const completionStyle = (item: Dataset) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return inlineStyleIncomplete;
    if (!item.calculationInProgress && item.impactSummary)
      return inlineStyleComplete;
    if (!item.calculationInProgress && !item.impactSummary)
      return inlineStyleError;
    return inlineStyleError;
  };

  const completionMessage = (item: Dataset) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.INCOMPLETE' });
    if (!item.calculationInProgress && item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.COMPLETE' });
    if (!item.calculationInProgress && !item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR' });
    return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR' });
  };

  const completionTooltip = (item: Dataset) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.INCOMPLETE.TOOLTIP' });
    if (!item.calculationInProgress && item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.COMPLETE.TOOLTIP' });
    if (!item.calculationInProgress && !item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR.TOOLTIP' });
    return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR' });
  };

  const openFootprintIfError = (item: Dataset) => {
    if (!item.calculationInProgress && !item.impactSummary) {
      openFootprint(item.id, item.name, true);
    }
    if (calculationCompleted(item)) openFootprint(item.id, item.name, false);
  };

  return (
    <Table size="medium">
      <TableHead
        style={{
          border: '1px solid var(--dsm-color-neutral)',
          fontSize: '12px !important',
        }}
      >
        <TableRow>
          {type === 'Intervention' && (
            <StyledTableCell
              style={{ width: '20%', cursor: 'pointer' }}
              onClick={() => toggleSort(SortColumn.Name)}
            >
              {intl.formatMessage({ id: 'SUSTELL.INTERVENTION.NAME' })}
              {sort?.column === SortColumn.Name &&
              sort.direction === SortDirection.DESCENDING ? (
                <ArrowDownwardOutlined />
              ) : (
                <ArrowUpwardOutlined
                  htmlColor={sort?.column !== SortColumn.Name ? 'gray' : ''}
                />
              )}
            </StyledTableCell>
          )}
          {type !== 'Intervention' && (
            <StyledTableCell style={{ width: '20%', cursor: 'pointer' }}>
              {intl.formatMessage({ id: 'SUSTELL.BASELINE.NAME' })}
            </StyledTableCell>
          )}
          <StyledTableCell>&nbsp;</StyledTableCell>
          {type === 'Intervention' && (
            <StyledTableCell
              style={{ textAlign: 'center' }}
              onClick={() => toggleSort(SortColumn.Status)}
            >
              {intl.formatMessage({ id: 'GENERAL.STATUS' })}
              {sort?.column === SortColumn.Status &&
              sort.direction === SortDirection.DESCENDING ? (
                <ArrowDownwardOutlined />
              ) : (
                <ArrowUpwardOutlined
                  htmlColor={sort?.column !== SortColumn.Status ? 'gray' : ''}
                />
              )}
            </StyledTableCell>
          )}
          {type !== 'Intervention' && (
            <StyledTableCell style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'GENERAL.STATUS' })}
            </StyledTableCell>
          )}
          {type === 'Intervention' && (
            <StyledTableCell
              style={{ width: '170px', cursor: 'pointer' }}
              onClick={() => toggleSort(SortColumn.TotalClimateChange)}
            >
              {intl.formatMessage({
                id: 'REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.TOTAL_CLIMATE_CHANGE',
              })}
              {sort?.column === SortColumn.TotalClimateChange &&
              sort.direction === SortDirection.DESCENDING ? (
                <ArrowDownwardOutlined />
              ) : (
                <ArrowUpwardOutlined
                  htmlColor={
                    sort?.column !== SortColumn.TotalClimateChange ? 'gray' : ''
                  }
                />
              )}
            </StyledTableCell>
          )}

          {type !== 'Intervention' && (
            <StyledTableCell style={{ width: '170px', cursor: 'pointer' }}>
              {intl.formatMessage({
                id: 'REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.TOTAL_CLIMATE_CHANGE',
              })}
            </StyledTableCell>
          )}

          <StyledTableCell style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'GENERAL.YEAR' })}
          </StyledTableCell>

          {type === 'Intervention' && (
            <StyledTableCell
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => toggleSort(SortColumn.LastModified)}
            >
              {intl.formatMessage({ id: 'GENERAL.LAST_MODIFIED' })}
              {sort?.column === SortColumn.LastModified &&
              sort.direction === SortDirection.DESCENDING ? (
                <ArrowDownwardOutlined />
              ) : (
                <ArrowUpwardOutlined
                  htmlColor={
                    sort?.column !== SortColumn.LastModified ? 'gray' : ''
                  }
                />
              )}
            </StyledTableCell>
          )}
          {type !== 'Intervention' && (
            <StyledTableCell style={{ textAlign: 'center', cursor: 'pointer' }}>
              {intl.formatMessage({ id: 'GENERAL.LAST_MODIFIED' })}
            </StyledTableCell>
          )}

          {type === 'Intervention' && (
            <StyledTableCell
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => toggleSort(SortColumn.ModifiedBy)}
            >
              {intl.formatMessage({ id: 'GENERAL.MODIFIED_BY' })}
              {sort?.column === SortColumn.ModifiedBy &&
              sort.direction === SortDirection.DESCENDING ? (
                <ArrowDownwardOutlined />
              ) : (
                <ArrowUpwardOutlined
                  htmlColor={
                    sort?.column !== SortColumn.ModifiedBy ? 'gray' : ''
                  }
                />
              )}
            </StyledTableCell>
          )}
          {type !== 'Intervention' && (
            <StyledTableCell style={{ textAlign: 'center', cursor: 'pointer' }}>
              {intl.formatMessage({ id: 'GENERAL.MODIFIED_BY' })}
            </StyledTableCell>
          )}
          <StyledTableCell>&nbsp;</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(!datasets || datasets?.length === 0) && (
          <TableRow>
            <TableCell colSpan={7}>
              {intl.formatMessage({
                id: `SUSTELL.${emptyKey}.NO_DATA`,
              })}
            </TableCell>
          </TableRow>
        )}
        {datasets &&
          datasets?.length > 0 &&
          getSortedPaginatedDatasets().map((item) => {
            // TODO typescript depends on ImpactNumbers.js
            const impactSummary = getImpactData(item) as ImpactSummary;
            return (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  <TitleLink onClick={() => open(item, 'view')}>
                    <span style={{ fontWeight: '500' }}>{item.name}</span>
                    {((variant === 'baseline' &&
                      getBaselineDraftByKey(
                        `${variant.toUpperCase()}_${processID}_${item.id}`
                      )) ||
                      (variant === 'intervention' &&
                        getInterventionDraftByKey(
                          `${variant.toUpperCase()}_${processID}_${
                            item.baseline
                          }_${item.id}`
                        ))) && (
                      <span
                        style={{
                          fontStyle: 'italic',
                          marginLeft: '16px',
                          color: 'gray',
                        }}
                      >
                        {intl.formatMessage({ id: 'GENERAL.UNSAVED_DRAFT' })}
                      </span>
                    )}
                  </TitleLink>
                </StyledTableCell>
                <StyledTableCell
                  style={{ cursor: 'pointer' }}
                  onClick={() => open(item, 'view')}
                >
                  <DsmIcon name="arrows/chevron-right" size="sm" />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={
                    calculationCompleted(item)
                      ? { cursor: 'pointer', width: '160px' }
                      : { width: '160px' }
                  }
                >
                  <Tooltip title={completionTooltip(item)}>
                    <Box
                      onClick={() => openFootprintIfError(item)}
                      style={completionStyle(item)}
                    >
                      {completionMessage(item)}
                    </Box>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {impactSummary?.air?.value && (
                    <>
                      <ExactNumber value={impactSummary.air?.value} />
                      <br />
                      <Typography style={{ fontSize: '12px' }}>
                        {impactSummary.air?.unit}
                      </Typography>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {type === 'Intervention'
                    ? activeBaselineYear
                    : item.year
                    ? item.year
                    : ''}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.updatedAt
                    ? new Date(item.updatedAt).toLocaleDateString()
                    : ''}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.lastUpdatedBy}
                </StyledTableCell>
                <StyledTableCell style={{ display: 'flex' }}>
                  {!benchmark &&
                    Can('update', type) &&
                    !isReadOnlyAnimalSystem(animalType) &&
                    type === 'Intervention' && (
                      <Tooltip
                        title={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                      >
                        <DsmButton
                          variant="text"
                          iconOnly
                          style={{ marginTop: '8px' }}
                          onClick={(e) => open(item, 'edit')}
                        >
                          <DsmIcon
                            slot="before"
                            name="general/edit-01"
                            style={{ color: 'black' }}
                          />
                        </DsmButton>
                      </Tooltip>
                    )}
                  {!benchmark && !isReadOnlyAnimalSystem(animalType) && (Can('delete', type) || Can('create', type)) && (
                    <FadeMenu
                      placeHolder="vertical"
                      deleteHandler={
                        !benchmark &&
                        Can('delete', type) &&
                        !isReadOnlyAnimalSystem(animalType)
                          ? () => {
                              deleteHandler(item.name);
                            }
                          : null
                      }
                      duplicateHandler={
                        Can('create', type) &&
                        Can('read', type) &&
                        duplicateDataset !== null
                          ? () => {
                              duplicateDataset(item);
                            }
                          : null
                      }
                      showDuplicate={
                        !benchmark &&
                        !isReadOnlyAnimalSystem(animalType) &&
                        duplicateDataset !== null
                      }
                    />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
      </TableBody>
      <TableFooter>
        <TableRow>
          {type === 'Intervention' && countElements && (
            <TablePagination
              count={countElements}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={intl.formatMessage({
                id: 'GENERAL.TABLE_PAGINATION.ROWS_PER_PAGE',
              })}
              page={currPage}
              onPageChange={onTablePageChange}
              rowsPerPageOptions={[5, 8, 10, 15, 20]}
              onRowsPerPageChange={onTableRowsPerPageChange}
              labelDisplayedRows={({ from, to }) =>
                intl.formatMessage(
                  { id: 'GENERAL.TABLE_PAGINATION.PAGE_OF_PAGES' },
                  { from, to, count: countElements }
                )
              }
              style={{ borderBottom: 'none' }}
            />
          )}
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default DatasetListTableV2;
