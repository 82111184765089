import {  useCallback, useState } from 'react';
import {  useFormContext } from 'react-hook-form';

import {
  Grid,
} from "@material-ui/core";
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import RowSelectWithMetrics from '../../../../../modules/Farms/Intervention/RowSelectWithMetrics';
import lookupValues from '../../../../helpers/lookupValues';
import TimeSpentInBuildingsTextField from '../../../../../modules/Farms/Intervention/TimeSpentInBuildingsTextField';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { DairyBaseline } from '../../../../models/Baseline';

interface DInterventionFeedsVariablePartProps {
  formType: FormType;
  stageIndex: number;
  baseline: DairyBaseline;
}

interface TimeSpentValuesType {
  [key: string] : number;
}

const DInterventionFeedsVariablePart = ({
  formType = FormType.Add,
  stageIndex = 0,
  baseline,
}: DInterventionFeedsVariablePartProps) => {

  const intl = useIntl();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  const feed = baseline?.stages?.[stageIndex]?.stageData?.feed;
  const formContext = useFormContext();
  const fc = formContext;

  const [timeSpentValues, setTimeSpentValues] = useState<TimeSpentValuesType>({} as TimeSpentValuesType);

  const housingTypesItems = Object.entries(lookupValues.housingTypes.Dairy).map(([i, item]) => ({
      value: i,
      display_name: item
    }));

  const updateTimeSpent = useCallback((name: string, value?: number | string | null) => {
    if (value != null && typeof value !== 'number') {
      // eslint-disable-next-line no-param-reassign
      value = parseFloat(value);
    }

    if (value == null || Number.isNaN(value)) {
      // eslint-disable-next-line no-param-reassign
      value = 0;
    }

    if (timeSpentValues[name] !== value) {
      const updvals = {
        ...timeSpentValues,
        [name]: value,
      };
      setTimeSpentValues(updvals);
      fc.setValue(`${fieldItemPrefix}.timeSpentInBuildings`, 
        (
          100 - updvals[`${fieldItemPrefix}.timeSpentGrazing`] - 
          updvals[`${fieldItemPrefix}.timeSpentInOpenYardAreas`]).toFixed(2)
        );
    }
  }, [fc, fieldItemPrefix, timeSpentValues]);


  return (
    <>
      <Grid item >
        <RowSelectWithMetrics
          name={`${fieldItemPrefix  }.housingType`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.HOUSING_TYPE"})}
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.HOUSING_TYPE.TOOLTIP"})}
          control={fc.control}
          disabled={formType === 'view'}
          items={housingTypesItems}
          baseline={feed?.housingType}
          margin="none"
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix  }.timeSpentGrazing`}
          label={ intl.formatMessage( {id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_GRAZING"}) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage( {id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_GRAZING.TOOLTIP"}) }
          type="number"
          metricUnit="%"
          baseline={feed?.timeSpentGrazing}
          updateTotalFeedIntake={updateTimeSpent}
          disabled={formType === "view"}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix  }.timeSpentInOpenYardAreas`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_OPEN_YARDS"}) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_OPEN_YARDS.TOOLTIP"}) }
          type="number"
          metricUnit="%"
          baseline={feed?.timeSpentInOpenYardAreas}
          updateTotalFeedIntake={updateTimeSpent}
          disabled={formType === "view"}
        />
        <Grid item>
         <TimeSpentInBuildingsTextField
          name={`${fieldItemPrefix  }.timeSpentInBuildings`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_BUILDINGS"}) }
          type="number"
          tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.BARN.DAIRY.TIME_SPENT_IN_BUILDINGS.TOOLTIP"}) }
          baseline={feed && feed.timeSpentInBuildings}
          disabled={formType === "view"}
        />
        </Grid>
        <Grid item>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix  }.digestibility`}
            label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.RATION.DIGESTIBILITY'})}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.BARN.RATION.DIGESTIBILITY.TOOLTIP' })}
            type="number"
            metricUnit="%"
            baseline={feed?.digestibility}
            disabled={formType === "view"}
          />
        </Grid>
        <Grid item>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix  }.grossEnergyIntake`}
            label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.RATION.GROSS_ENERGY_INTAKE'})}
            margin="none"
            variant="outlined"
            type="number"
            metricUnit="MJ"
            baseline={feed?.grossEnergyIntake}
            disabled={formType === "view"}
          />
        </Grid>
        <Grid item>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix  }.crudeProteinContent`}
            type="number"
            label={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.CRUDE_PROTEIN_CONTENT',
            })}
            margin="none"
            variant="outlined"
            metricUnit="%"
            baseline={feed?.crudeProteinContent}
            disabled={formType === "view"}
          />
        </Grid>
        <Grid item>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix  }.percentageOfSilage`}
            type="number"
            label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.RATION.SILAGE_PERCENTAGE'})}
            margin="none"
            variant="outlined"
            metricUnit="%"
            baseline={feed?.percentageOfSilage}
            disabled={formType === "view"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DInterventionFeedsVariablePart;