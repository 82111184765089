import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';

// loads all items when pagitaion is ON
// params - graphql quey params
// queryKey - graphql query key
// itemsKey - graphql items key
// tokenKey - graphql token key
const paginateQuery = async (
  params: GraphQLOptions,
  queryKey: string,
  itemsKey = 'items',
  nextTokenKey = 'nextToken'
) => {
  const results: never[] = [];
  let nextToken = null;

  if (!params) throw new Error('Missing graphql params');
  const updateParms = { ...params };
  do {
    updateParms.variables = updateParms.variables || {};
    updateParms.variables[nextTokenKey as keyof typeof updateParms.variables] =
      nextToken as keyof typeof updateParms.variables;
    const result = await (API.graphql(updateParms) as Promise<{
      data: {
        [key: string]: {
          [key: string]: [];
        };
      };
    }>);
    if (result?.data && result.data[queryKey]) {
      if (result.data[queryKey][itemsKey])
        results.push(...result.data[queryKey][itemsKey]);
      nextToken = result.data[queryKey][nextTokenKey];
    } else nextToken = null;
  } while (nextToken);

  return results;
};

export default paginateQuery;
