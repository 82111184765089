// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  profileSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  profileHeaderContainer: {
    display: 'flex',
    width: '1216px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
  },
  lineBorder: {
    height: '1px',
    backgroundColor: '#F0F0F0',
    width: '100%',
    border: 'none',
    marginBottom: '0rem',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    flex: '1 0 0',
  },
  userInitials: {
    display: 'flex',
    width: '64px',
    height: '64px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '200px',
    // background: var(--avatar-user-square-olivia-rhye, url(<path-to-image>), lightgray 50% / cover no-repeat);
    backgroundColor: '#808080',
    fontSize: '3em',
    color: theme.palette.common.white,
  },
  userNameEmail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  userName: {
    color: 'var(--dsm-blue-dsm-blue, #003478)',
    alignSelf: 'stretch',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '-0.68px',
  },
  userEmail: {
    color: '#808080',
    // var(--neutrals-dsm-dark-gray, #808080);
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  profileTitle: {
    color: 'var(--neutrals-dsm-black, #1B1B1B)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    alignSelf: 'stretch',
  },
  profileDescription: {
    color: 'var(--neutrals-dsm-dark-gray, #808080)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    alignSelf: 'stretch',
  },
  profileTabs: {
    display: 'flex',
    height: '36px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--neutrals-dsm-light-gray, #F0F0F0)',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    '& button': {
      minWidth: 'auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    
  },
  profileTab: {
    display: 'flex',
    displayDirection:'reverse-column',
    height: '40px',
    padding: '1px 4px 4px 4px',
    alignItems: 'center',
    gap: '8px',
    color: 'var(--neutrals-dsm-dark-gray, #808080)',
    textTransform: 'none',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 500,
    '&.Mui-selected': {
      color: 'var(--primary-dsm-mid-blue-darkest, #005A95)',
      borderBottom: `1px solid var(--primary-dsm-mid-blue-darkest, #005A95)`,
    },
    "& .MuiTab-wrapper":{
      alignItems:'flex-start'
    }
  },
  formContainer: {
    border: 'none',
  },
  formSection: {
    marginBottom: '20px',
  },
  formHeader: {
    marginTop: '32px',
    marginBottom: '20px',
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#E51F22',
    color: '#FFFFFF',
    border: '#E51F22',
    '::hover': {
      backgroundColor: '#E51F22 !important',
    },
  },
  blankSpace: {
    width: theme.spacing(8),
  },
  btnDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '32px',
  },
  cancelBtn: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    background: 'var(--error-dsm-red, #E51F22)',
    color: 'var(--neutrals-dsm-white, #FFF)',
    border: 'var(--error-dsm-red, #E51F22)',
  },
  skipBtn: {
    // display: "flex",
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '0px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  skipBtnTxt: {
    color: '#0070BA',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    textDecorationLine: 'underline',
    textTransform: 'none',
  },
  saveBtn: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    border: '1px solid var(--dsm-mid-blue-dsm-mid-blue, #0070BA)',
    background: 'var(--dsm-mid-blue-dsm-mid-blue, #0070BA)',
  },
  saveBtnTxt: {
    color: 'var(--neutrals-dsm-white, #FFF)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px' /* 171.429% */,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '384px',
    padding: '0px',
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 0',
    border: '1px solid var(--neutrals-dsm-dark-gray, #808080)',
    padding: '8px 0px 8px 12px;',
    width: '384px',
  },
  inputText: {
    flex: '1 0 0',
    color: 'var(--neutrals-dsm-dark-gray, #808080)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  label: {
    color: 'var(--neutrals-dsm-black, #1B1B1B)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  subLabel: {
    color: 'var(--neutrals-dsm-black, #1B1B1B)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  selectOption: {
    display: 'flex',
    padding: '8px 14px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '2px',
    border: '1px solid var(--neutrals-dsm-dark-gray, #808080)',
    background: 'var(--neutrals-dsm-white, #FFF)',
    width: '384px',
  },
  userForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
    alignSelf: 'stretch',
  },
  phoneInputText: {
    color: 'var(--neutrals-dsm-dark-gray, #808080)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    border: 'none', 
    outline: 'none',
    paddingLeft:"2px"
  },
  phoneNumberLabel: {
    fontSize: '12px',
    fontWeight: 500,
    display: 'flex',
    marginBottom: '2px',
    width: '125%',
  },
  phoneInputContainer:{
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px var(--dsm-color-neutral-darker) solid',
    borderRadius: '2px',
    padding: '3px 3px 3px 8px',
  },
  knowledgeBaseVerticalTabs:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    height: '100%',
    borderRight: '1px solid var(--neutrals-dsm-light-gray, #F0F0F0)',
    marginRight: theme.spacing(2),
    '& .MuiTabs-indicator':{
      left: 0
    }
  },
  knowledgeBaseVerticalTab: {
    display: 'flex',
    displayDirection:'column',
    height: '40px',
    padding: '1px 4px 4px 10px',
    alignItems: 'center',
    gap: '8px',
    color: 'var(--neutrals-dsm-dark-gray, #808080)',
    textTransform: 'none',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 500,
    '&.Mui-selected': {
      color: 'var(--primary-dsm-mid-blue-darkest, #005A95)',
    },
    "& .MuiTab-wrapper":{
      alignItems:'flex-start'
    }
  },
  deleteModalIconHolder: {
    backgroundColor: 'var(--error-dsm-red, #FCE9E9)',
    borderRadius: '24px',
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  deleteModalIcon: {
    height: '24px', 
    width: '24px', 
    color: 'var(--error-dsm-red, #E51F22)'
  },
  deleteModalTitle: {
    color: 'var(--neutrals-dsm-black, #1B1B1B)',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px'
  }
}));

export default useStyles;
