/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { PoultryInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { AllInterventionStages, PoultryEmission, PoultryIntervention } from '../../../../models/Intervention/PoultryIntervention';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';

const PoultryEmissionsDialog = ({
  formVisible,
  formType,
  stageIndex,
  handleCancel,
  baseline,
}: PoultryInterventionFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<PoultryEmission>();
  const formTitle = intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE'});
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const fieldItemPrefix = `stages.${stageIndex}.stageData.emissions`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, baseline]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData as AllInterventionStages;
      if (stageData?.emissions) {
        stageData.emissions = { ...currResetValue.current };
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel();
  };

  const welcomeString = intl.formatMessage({ id: 'EMISSIONS.WELCOME' });
  const emissions = baseline?.stages?.[stageIndex]?.stageData?.emissions;

  return (
    <DialogContainer
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      formVisible={formVisible}
      variant="wide"
      datasetType="intervention"
      handleClose={handleResetClick}
      iconCode="maps-travel/globe-04"
      formTitle={formTitle}
      introText={intl.formatMessage(
        { id: 'EMISSIONS.INTRO' },
        { boldedWelcome: <strong>{welcomeString}</strong>, br: <br /> }
      )}
    >
      <DsmGrid className={classes.dsmGridTwoColumnIntervention} >
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.methane`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.METHANE'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.METHANE.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.methane}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitrousOxideDirect`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NOx_DIRECT'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.nitrousOxideDirect}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitrousOxideIndirect`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.nitrousOxideIndirect}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.amonia`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.amonia}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitricOxide`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NOx_MANURE'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NOx_MANURE.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.nitricOxide}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nonMethaneVolatile`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC.TOOLTIP',})}
          type="number"
          metricUnit="%"
          baseline={emissions?.nonMethaneVolatile}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.PM10`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.PM10'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.PM10.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.PM10}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.PM25`}
          label={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.PM2_5'})}
          tooltip={intl.formatMessage({id: 'INTERVENTION.FORM.EMISSIONS.PM2_5.TOOLTIP'})}
          type="number"
          metricUnit="%"
          baseline={emissions?.PM25}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={ handleCancel }
        saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
      />
    </DialogContainer>
  );
};

export default PoultryEmissionsDialog;
