/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Auth } from 'aws-amplify';

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

const {
  graphql_endpoint,
  region,
  user_pool_id,
  web_client_id,
  identity_pool_id,
  analytics_app_id,
  compound_feed_edit_url,
} = window['awsConfig'];

const awsmobile = {
  aws_appsync_graphqlEndpoint: graphql_endpoint || process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: region || process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_project_region: region || process.env.REACT_APP_REGION,
  aws_cognito_region: region || process.env.REACT_APP_REGION,
  aws_user_pools_id: user_pool_id || process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: web_client_id || process.env.REACT_APP_WEB_CLIENT_ID,
  aws_cognito_identity_pool_id: identity_pool_id || process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_app_analytics: 'enabled',
  aws_mobile_analytics_app_id: analytics_app_id || process.env.REACT_APP_ANALYTICS_APP_ID,
  aws_mobile_analytics_app_region: region || process.env.REACT_APP_REGION,
  graphql_headers: getIdToken,
};

export default awsmobile;

export const blonkConfig = {
    'compound_feed_edit_url': compound_feed_edit_url || process.env.REACT_APP_COMPOUND_FEED_EDIT_URL,
}
