import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  defaultValuesForMortalityData,
  nameMapping,
} from '../../validationSchemaRestrictions';
import {
  floatValueInUserUnits,
  mapCommonProductionDataIn,
  mapResourceUse,
} from './inCommonMapperSustell';

const ANIMAL_TYPE = 'Laying hens'; // used for mapping shared with old code

const mapLayingHensProductionDataInSustell = (inData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  // console.log("inData: ",inData);

  const formData = mapCommonProductionDataIn(inData, isDraft);
  mapResourceUse(inData.resourceUse, formData.resourceUse);

  if (inData.stages && Array.isArray(inData.stages)) {
    inData.stages.forEach((stage, index) => {
      const stageFormData = formData.stages[index]?.stageData;
      if (stageFormData) {
        const stageData = JSON.parse(stage.stageData);

        // map output
        stageFormData.output.eggs = floatValueInUserUnits(
          stageData.eggs?.total_weight?.value,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        stageFormData.output.liveWeight = floatValueInUserUnits(
          stageData.spent_hens?.total_weight?.value,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );

        stageFormData.animalType =
          nameMapping[stageData.animal_group.animal_type];

        stageFormData.input.purchasedAnimalsLayingHens =
          stageData.purchased_laying_hens?.number_of_purchased_animals.value;

        // set loaded value or default if not draft
        const defaultMortalityRate =
          !isDraft && defaultValuesForMortalityData[ANIMAL_TYPE]
            ? defaultValuesForMortalityData[ANIMAL_TYPE].mortalityRate
            : undefined;
        
        stageFormData.input.mortalityRate =
          stageData?.mortality?.mortality_rate?.value ||
          stageData?.mortality?.mortality_rate?.value === 0
            ? stageData?.mortality?.mortality_rate?.value
            : defaultMortalityRate;

        const defaultAverageLIveweightMortality =
          !isDraft && defaultValuesForMortalityData[ANIMAL_TYPE]
            ? defaultValuesForMortalityData[ANIMAL_TYPE]
                .averageLiveweightMortality
            : undefined;
        
        stageFormData.input.averageLiveWeightMortalities =
          stageData?.mortality?.average_live_weight_mortalities?.value ||
          defaultAverageLIveweightMortality;

        // do conversion
        if (
          stageFormData.input.averageLiveWeightMortalities ||
          stageFormData.input.averageLiveWeightMortalities === 0
        ) {
          stageFormData.input.averageLiveWeightMortalities =
            floatValueInUserUnits(
              stageFormData.input.averageLiveWeightMortalities,
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass,
              outputMassPrecision
            );
        }

        // console.log('loaded input data:', stageFormData.input);

        stageFormData.input.housingType =
          stageData?.housing_description?.housing_type;
      }
    });
  }

  return formData;
};

export default mapLayingHensProductionDataInSustell;
