import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { listCustomers } from "../../../graphql/queries";
import paginateQuery from "../../sustell_15/helpers/paginate";
import CustomerForm from "../Customers/CustomerForm";
import AdminCustomerData from "./AdminCustomerData";

export const AdminCustomersDashboard = () => {
  const [customerFormMode, setCustomerFormMode] = useState(null);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [unfilteredCustomerData, setUnnfilteredCustomerData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [clickedOnCustomer, setClickedOnCustomer] = useState(null);
  const selectedCustomer = useSelector((state) => state.selectedCustomer?.value);

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const handleShowCustomerForm = (event, editObj = null) => {
    if(!showCustomerForm){
      setShowCustomerForm(true);
      if(editObj) {
        setCustomerFormMode(editObj.formMode);
        setClickedOnCustomer(editObj.clickedOnCustomer);
      } else {
        setClickedOnCustomer(null);
      }
    }
  };

  const fetchCustomerData = async() => {
    try {
      const params = {
        query: listCustomers,
      };
      const result = await paginateQuery(params, 'listCustomers');
      setCustomerData(result);
      setUnnfilteredCustomerData([...result]);
    } catch (err) {
      console.log('error: ', err)
    }
  }

  const onCustomerUpdated = (newData) => {
    if (newData) {
      let changedData = clickedOnCustomer;
      changedData.name = newData.name;
      changedData.token = newData.token;
      changedData.farmsNumberLimit = newData.farmsNumberLimit;
      changedData.reference = newData.reference;
      changedData.feedsVisible = newData.feedsVisible;
      changedData.SAPCustomerId = newData.SAPCustomerId;
      changedData.type = newData.type;
      changedData.license = newData.license;
      changedData.licenseStartDate = newData.licenseStartDate;
      changedData.licenseEndDate = newData.licenseEndDate;
      changedData.contractNumber = newData.contractNumber;
      changedData.allowedPartnerMutations=newData.allowedPartnerMutations;
      changedData.allowedPartnerQueries=newData.allowedPartnerQueries;
      changedData.partnerApiAccess=newData.partnerApiAccess;
      changedData.canAccessPartner=newData.canAccessPartner;
    }
    setShowCustomerForm(false);
    setCustomerFormMode('view');
    setClickedOnCustomer(null);
  }

  // TODO: change fields to match new data model 
  const onCustomerAdded = (newData) => {
    // console.log("This is invoked newData " + JSON.stringify(newData));
    if (newData) {
      let newCustomer = {};
      newCustomer.id = newData.id;
      newCustomer.name = newData.name;
      newCustomer.token = newData.token;
      newCustomer.farmsNumberLimit = newData.farmsNumberLimit;
      newCustomer.reference = newData.reference;
      newCustomer.SAPCustomerId = newData.SAPCustomerId;
      newCustomer.type = newData.type;
      newCustomer.license = newData.license;
      newCustomer.licenseStartDate = newData.licenseStartDate;
      newCustomer.licenseEndDate = newData.licenseEndDate;
      newCustomer.contractNumber = newData.contractNumber;
      newCustomer.feedsVisible = newData.feedsVisible; 
      newCustomer.allowedPartnerMutations=newData.allowedPartnerMutations;
      newCustomer.allowedPartnerQueries=newData.allowedPartnerQueries;
      newCustomer.partnerApiAccess=newData.partnerApiAccess;
      newCustomer.canAccessPartner=newData.canAccessPartner;
      newCustomer.farms = newData.farms ? newData.farms : { items: [] }; //adding empty farm list to new customer
      newCustomer.projects = newData.projects ? newData.projects : { items: [] }; //adding empty projects list to new customer
      //add in unfiltered list
      setUnnfilteredCustomerData([newCustomer, ...unfilteredCustomerData]);
      //if in the customer context, referesh list, to show a new custoemr if marked as reference
      setCustomerData([newCustomer, ...customerData])
    }
    setShowCustomerForm(false);
  }

  return (
    <>
      { showCustomerForm &&
        <CustomerForm
          changeHandler={customerFormMode==='edit' ? onCustomerUpdated : onCustomerAdded}
          formMode={customerFormMode}
          selectedCustomer={customerFormMode==='edit' ? clickedOnCustomer : {} }
        />
      }
        <AdminCustomerData
          customerData={customerData}
          onShowCustomerForm={handleShowCustomerForm}
          selectedCustomer={selectedCustomer}
          editorsActive={showCustomerForm}
        />
    </>    
  );
};
