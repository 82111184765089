import { useState, useEffect, useRef, useCallback, ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import SustellTotalIntakeTextField from '../../../../../modules/Farms/Intervention/SustellTotalIntakeTextField';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { InterventionDialogProps } from './InterventionTypes';
import { Maybe, MasterDataOption } from '../../../../../../graphql/types';
import { AllBaselines, BaselineFeed} from '../../../../models/Baseline';
import {
  CommonInterventionFeed,
  InterventionFeed,
  AllInterventions,
} from '../../../../models/Intervention';
import { FormType } from '../../common';

interface InterventionFeedFormDialogProps extends InterventionDialogProps {
  baseline: AllBaselines;
  // eslint-disable-next-line react/require-default-props
  singleIngredients?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  intervention: AllInterventions | null;
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
}

const InterventionFeedsFormDialog = ({
  formVisible,
  stageIndex,
  handleCancel,
  formType = FormType.Add,
  baseline,
  compoundFeeds,
  singleIngredients = null,
  intervention,
  children,
}: InterventionFeedFormDialogProps) => {
  const intl = useIntl();
  const currResetValue = useRef<string>();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const feedValuesRef = useRef<CommonInterventionFeed>(
    {} as CommonInterventionFeed
  );
  const existingFeedsSet = useRef<boolean>(false);

  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  const fc = useFormContext();

  const [compoundFeedsAdditions, setCompoundFeedsAdditions] = useState<
    BaselineFeed[] | null
  >([]);
  const [singleIngredientsAdditions, setSingleIngredientsAdditions] = useState<
    BaselineFeed[] | null
  >([]);

  const updateTotalFeedIntake = useCallback(
    (name?: string, value?: number | string | null) => {

      const feedValues = feedValuesRef.current;
      let totalIntake = 0;

      const isAdditionalFeed =
        !name ||
        name.includes('compoundFeedsAdditions') ||
        name.includes('singleIngredientsAdditions');

      const currFormValues = fc.getValues() as AllInterventions;

      // take all addditional fields, and calculate sum
      const compoundFeedsAdd =
        currFormValues.stages?.[stageIndex]?.stageData?.feed
          ?.compoundFeedsAdditions;
      const singleIngredientsAdd =
        currFormValues.stages?.[stageIndex]?.stageData?.feed
          ?.singleIngredientsAdditions;
      if (compoundFeedsAdd) {
        totalIntake += compoundFeedsAdd.reduce(
          (acc: number, item: BaselineFeed) =>
            acc +
            (item.feedType && !Number.isNaN(item.kgPerAnimal)
              ? Number(item.kgPerAnimal)
              : 0),
          0
        );
      }
      if (singleIngredientsAdd) {
        totalIntake += singleIngredientsAdd.reduce(
          (acc: number, item: BaselineFeed) =>
            acc +
            (item.feedType && !Number.isNaN(item.kgPerAnimal)
              ? Number(item.kgPerAnimal)
              : 0),
          0
        );
      }
      
      if (
        !isAdditionalFeed &&
        feedValues &&
        feedValues[name as keyof CommonInterventionFeed] !== value
      ) {
        if (value != null && typeof value !== 'number') {
          // eslint-disable-next-line no-param-reassign
          value = parseFloat(value);
        }

        if (Number.isNaN(value)) {
          // eslint-disable-next-line no-param-reassign
          value = 0;
        }

        const updvals = {
          ...feedValues,
          [name]: value,
        };
        feedValuesRef.current = updvals;
      }

      totalIntake += Object.values(feedValuesRef.current).reduce(
        (a: number, b: number) => a + b,
        0
      );

      fc.setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntake.toFixed(2));
    },
    [fc, stageIndex, fieldItemPrefix, formVisible]
  );

  const recalculateTotalFeedIntake = useCallback(() => {

      // calculate intervention feed value
    const calculateFeedValue = (baselineFeed: BaselineFeed, interventionFeeds?: Maybe<InterventionFeed[]>) => {
      const interventionFeed = interventionFeeds?.find(
        (item) => item.feedType === baselineFeed.feedType
      );

      if (interventionFeed) {
        if (interventionFeed.kgPerAnimal_changeMetric === 'set')
          return Number(interventionFeed.kgPerAnimal) || 0;

        if (interventionFeed.kgPerAnimal_changeMetric === 'absolute')
          return (
            (Number(interventionFeed.kgPerAnimal) || 0) +
            (Number(baselineFeed.kgPerAnimal) || 0)
          );

        return (
          (Number(baselineFeed.kgPerAnimal) || 0) *
          (1 + (Number(interventionFeed.kgPerAnimal) || 0) / 100)
        );
      }
      // intervention for feed is not set
      return Number(baselineFeed.kgPerAnimal) || 0;
    };

      let totalIntake = 0;

      const currFormValues = fc.getValues() as AllInterventions;

      // take all addditional fields, and calculate sum
      const compoundFeedsAdd =
        currFormValues.stages?.[stageIndex]?.stageData?.feed
          ?.compoundFeedsAdditions;
      const singleIngredientsAdd =
        currFormValues.stages?.[stageIndex]?.stageData?.feed
          ?.singleIngredientsAdditions;
      if (compoundFeedsAdd) {
        totalIntake += compoundFeedsAdd.reduce(
          (acc: number, item: BaselineFeed) =>
            acc +
            (item.feedType && !Number.isNaN(item.kgPerAnimal)
              ? Number(item.kgPerAnimal)
              : 0),
          0
        );
      }
      if (singleIngredientsAdd) {
        totalIntake += singleIngredientsAdd.reduce(
          (acc: number, item: BaselineFeed) =>
            acc +
            (item.feedType && !Number.isNaN(item.kgPerAnimal)
              ? Number(item.kgPerAnimal)
              : 0),
          0
        );
      }

      
      const baselineCompoundFeeds = baseline?.stages?.[stageIndex]?.stageData?.feed?.compoundFeeds;
      if(baselineCompoundFeeds) {
        const compoundFeedsForm = currFormValues.stages?.[stageIndex]?.stageData?.feed?.compoundFeeds;
        totalIntake += baselineCompoundFeeds.reduce(
          (acc: number, item: BaselineFeed) =>
            acc + calculateFeedValue(item, compoundFeedsForm),
          0
        );
      }

      const baselineSingleIngredients = baseline?.stages?.[stageIndex]?.stageData?.feed?.singleIngredients;
      if(baselineSingleIngredients) {
        const singleIngredientsForm = currFormValues.stages?.[stageIndex]?.stageData?.feed?.singleIngredients;
        totalIntake += baselineSingleIngredients.reduce(
          (acc: number, item: BaselineFeed) =>
            acc + calculateFeedValue(item, singleIngredientsForm),
          0
        );
      }

      fc.setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntake.toFixed(2));
      if (formVisible)
        fc.trigger(`${fieldItemPrefix}.totalFeedIntake`)
          .then(() => {})
          .catch(() => {});
    },
    [fc, stageIndex, fieldItemPrefix, formVisible, baseline]
  );


  useEffect(() => {
    // we need to refresh when previously saved is returned by API call, but only once
    const feed = intervention?.stages?.[stageIndex]?.stageData?.feed;
    if (existingFeedsSet.current === false) {
      if (feed?.compoundFeedsAdditions?.some((item) => item.feedType !== '')) {
        setCompoundFeedsAdditions(feed.compoundFeedsAdditions);
        existingFeedsSet.current = true;
      }
      if (
        feed?.singleIngredientsAdditions?.some((item) => item.feedType !== '')
      ) {
        setSingleIngredientsAdditions(feed.singleIngredientsAdditions);
        existingFeedsSet.current = true;
      }
      recalculateTotalFeedIntake();
    }
  }, [intervention, recalculateTotalFeedIntake, stageIndex, baseline]);

  useEffect(() => {
    if (formVisible) {
      // to faster deep copy all potential arrays and subobjects
      const serializedData = JSON.stringify(fc.getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as AllInterventions;
      const stageData = resetObject.stages?.[stageIndex]?.stageData
      if (stageData?.feed) {
        const val = JSON.parse(
          currResetValue.current
        ) as CommonInterventionFeed;
        stageData.feed = val;
        fc.reset(resetObject, {
          errors: true,
        });
        // reset compound feeds and single ingredients data
        // to previously valid (confirmed)
        setCompoundFeedsAdditions(val?.compoundFeedsAdditions || null);
        setSingleIngredientsAdditions(val?.singleIngredientsAdditions || null);
      }
    }
    if (handleCancel) handleCancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  const feed = baseline?.stages?.[stageIndex]?.stageData?.feed;

  
  const baselineFeedTotal = () => {
    let total =
      feed?.compoundFeeds?.reduce(
        (acc: number, item: BaselineFeed) =>
          acc +
          (item.feedType && !Number.isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0),
        0
      ) || 0;
    total +=
      feed?.singleIngredients?.reduce(
        (acc: number, item: BaselineFeed) =>
          acc +
          (item.feedType && !Number.isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0),
        0
      ) || 0;

    return total;
  };


  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleCancel}
      iconCode="feedIcon"
      formTitle={formTitle}
      variant="wide"
      datasetType="intervention"
    >
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            {/* Filter potential fields with not selected cf/si  */}
            {feed?.compoundFeeds?.filter(item => !!item.feedType)?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`cf_${item.feedType}_${i}`}>
                <input
                  type="hidden"
                  value={item.feedType}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].feedType`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
                  label={
                    compoundFeeds?.find((el) => el.value === item.feedType)
                      ?.display_name || ''
                  }
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
                  })}
                  // margine="none"
                  metricUnit={intl.formatMessage(
                    { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                    { unit: barnOutputMassUnit }
                  )}
                  precision={precision}
                  // defaultValue={item.kgPerAnimal}
                  baseline={item.kgPerAnimal}
                  updateTotalFeedIntake={updateTotalFeedIntake}
                  disabled={formType === 'view'}
                  displayFormattedUnit={false}
                  originalMetricUnit={barnOutputMassUnit}
                />
              </Grid>
            ))}

            <SustellRationFeeds
              key="compoundFeedsAdditions"
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS',
              })}
              availableFeedItems={compoundFeeds}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="compoundFeedsAdditions"
              updateTotalFeedIntake={updateTotalFeedIntake}
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
              })}
              formState={formType}
              compoundFeed
              feedData={compoundFeedsAdditions}
              setFeedData={setCompoundFeedsAdditions}
            />

            {feed?.singleIngredients?.filter(item => !!item.feedType)?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`cf_${item.feedType}_${i}`}>
                <input
                  type="hidden"
                  value={item.feedType}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.singleIngredients[${i}].feedType`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.singleIngredients[${i}].kgPerAnimal`}
                  label={
                    singleIngredients?.find((el) => el.value === item.feedType)
                      ?.display_name || ''
                  }
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP',
                  })}
                  // margine="none"
                  metricUnit={intl.formatMessage(
                    { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                    { unit: barnOutputMassUnit }
                  )}
                  precision={precision}
                  // defaultValue={item.kgPerAnimal}
                  baseline={item.kgPerAnimal}
                  updateTotalFeedIntake={updateTotalFeedIntake}
                  disabled={formType === 'view'}
                  displayFormattedUnit={false}
                  originalMetricUnit={barnOutputMassUnit}
                />
              </Grid>
            ))}

            <SustellRationFeeds
              key="singleIngredientsAdditions"
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS',
              })}
              barnItemIndex={stageIndex}
              availableFeedItems={singleIngredients}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="singleIngredientsAdditions"
              updateTotalFeedIntake={updateTotalFeedIntake}
              tooltip={
                <div>
                  {intl.formatMessage(
                    {
                      id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP',
                    },
                    { br: <br /> }
                  )}
                </div>
              }
              formState={formType}
              feedData={singleIngredientsAdditions}
              setFeedData={setSingleIngredientsAdditions}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SustellTotalIntakeTextField
                name={`${fieldItemPrefix}.totalFeedIntake`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE',
                })}
                type="number"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE.TOOLTIP',
                })}
                addornment={intl.formatMessage(
                  { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                  { unit: barnOutputMassUnit }
                )}
                metricUnit={intl.formatMessage(
                  { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                  { unit: barnOutputMassUnit }
                )}
                baseline={baselineFeedTotal()}
                disabled
                margine="none"
              />
            </Grid>
            {/* load additional fields for specific animal type - this is added in stage for specific type as subelement */}
            {children}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default InterventionFeedsFormDialog;
