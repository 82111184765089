import { FC, useState, MutableRefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DsmLoadingIndicator } from '@dsm-dcs/design-system-react';

import { Box } from '@material-ui/core';
import { SelectedCustomerAlias } from '../CompoundFeedWizard/types';
import DashboardDetails from './DashboardDetails';
import DashboardHeader from './DashboardHeader';
import useDashboard, { DraftLoadingState } from './useDashboard';
import { DashboardType } from '../../models/Dashboard/DashboardTypes';
import useUnload from './useUnload';
import { setDashboardOptionsDraft } from '../../../state/drafts/dashboardFiltersDraftSlice';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

interface SustellDashboardProps {
  dashboardPageRef: MutableRefObject<any>;
}

const SustellDashboard: FC<SustellDashboardProps> = ({ dashboardPageRef }) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  const selectedCustomer = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    (state: SelectedCustomerAlias) => state.selectedCustomer?.value
  );
  const [dashboardType, setDashboardType] = useState<DashboardType>(
    DashboardType.Farm
  );
  const [draftLoading, setDraftLoading] = useState<DraftLoadingState>(DraftLoadingState.None);

  const {
    dashboard,
    setDashboardDateRange,
    loading,
    setBaselineInterventionOptions,
  } = useDashboard({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    customerID: selectedCustomer?.id,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    customerName: (selectedCustomer?.name as unknown as string) || 'unknown',
    dashboardType,
    setDashboardType,
    draftLoading,
    setDraftLoading
  });
  const dispatch = useDispatch();
  const [, forceUpdate] = useState({});

  useUnload(() => {
    if (!dashboard) {
      return;
    }
    dispatch(setDashboardOptionsDraft(dashboard));
  })

  useEffect(() => () => {
      if (!dashboard) {
        return;
      }
      dispatch(setDashboardOptionsDraft(dashboard));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" mt="16px">
          <DsmLoadingIndicator  size='xl' description={intl.formatMessage({id: "SUSTELL.DASHBOARD.LOADINGDESCRIPTION"})}/>
        </Box>
      ) : (
        <>
          <DashboardHeader
            dashboard={dashboard}
            setDashboardDateRange={setDashboardDateRange}
            renderDashboard={() => forceUpdate({})}
            setDashboardType={setDashboardType}
            setBaselineInterventionOptions={setBaselineInterventionOptions}
            loading={loading}
          />
          <DashboardDetails
            dashboard={dashboard}
            dashboardPageRef={dashboardPageRef}
            renderDashboard={() => forceUpdate({})}
          />
        </>
      )}
    </>
  );
};

export default SustellDashboard;
