import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { styled } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import Can from '../../../modules/Auth/Can';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { ImpactSummary } from '../../models/Dataset';
import { footprintURL } from '../../utils/footprint-url-utils';
import { ExactNumber } from '../helpers/ExactNumber';
import { getImpactData } from '../helpers/ImpactNumbers';
import TitleLink from '../helpers/TitleLink';
import FadeMenu from '../pageparts/TableMenu';
import { StyledTableCell, StyledTableRow } from './DatasetListTableV2';
import { DatasetType, FormType, isReadOnlyAnimalSystem } from './common';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { SortDirection } from 'aws-amplify';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { AnimalType } from '../../../../graphql/types';

// make a intervention row little lower than baseline's
const InterventionTableCell = styled(TableCell)({
  paddingTop: 6,
  paddingBottom: 6,
});

type CompositeDatasetListTableProps = {
  customerID: string;
  farmID: string;
  processID: string;
  farmName: string;
  processName: string;
  archive: boolean;
  datasets: BaselineDataset[];
  openDataset: (
    datasetType: DatasetType,
    baselineId: string,
    formType: string,
    interventionReference?: string
  ) => void;
  deleteDataset: (datasetType: DatasetType, itemName: string) => void;
  duplicateDataset: (datasetType: DatasetType, item: object) => void;
  benchmark: boolean;
  animalType: AnimalType
};

type InterventionDataset = {
  baseline: string;
  id: string;
  impactSummary: string;
  name: string;
  reference: string;
  updatedAt: string;
  lastUpdatedBy: string;
  calculationInProgress?: boolean;
  createdAt: string;
};

type BaselineDataset = {
  id: string;
  impactSummary: string;
  name: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  lastUpdatedBy: string;
  calculationInProgress?: boolean;
  interventions: InterventionDataset[];
  year: string;
};

type Dataset = BaselineDataset | InterventionDataset;

enum SortColumn {
  Name = 'name',
  LastModified = 'updatedAt',
  Status = 'country',
  TotalClimateChange = 'animalSystems',
  CreatedAt = 'createdAt',
  ModifiedBy = 'lastUpdatedBy',
  Year = 'year',
}

interface TableSort {
  column: SortColumn | undefined;
  direction: SortDirection | undefined;
}

export const inlineStyleComplete = {
  lineHeight: '2em',
  fontWeight: '500',
  padding: '0 15px',
  borderRadius: '1em',
  color: 'var(--dsm-color-success',
  backgroundColor: 'var(--dsm-color-success-lighter)',
  border: '1px solid var(--dsm-color-success',
};

export const inlineStyleIncomplete = {
  lineHeight: '2em',
  fontWeight: '500',
  borderRadius: '1em',
  padding: '0 10px',
  color: 'var(--dsm-color-neutral-darker',
  backgroundColor: 'var(--dsm-color-neutral-lighter)',
  border: '1px solid var(--dsm-color-neutral-darker',
};

export const inlineStyleError = {
  lineHeight: '2em',
  fontWeight: '500',
  borderRadius: '1em',
  padding: '0 10px',
  color: 'var(--dsm-color-error)',
  backgroundColor: 'var(--dsm-color-error-lightest)',
  border: '1px solid var(--dsm-color-error',
};

const CompositeDatasetListTableV2 = ({
  customerID,
  farmID,
  processID,
  farmName,
  processName,
  archive = false,
  datasets,
  openDataset,
  deleteDataset,
  duplicateDataset,
  benchmark,
  animalType
}: CompositeDatasetListTableProps) => {
  const intl = useIntl();
  const history = useHistory();

  const emptyKey = archive ? 'ARCHIVE' : 'BASELINE';
  const { ctaClickEvent } = useAdobeDataLayer();
  const [, upateLocalState] = useState<object>({});
  const [sort, setSort] = useState<TableSort>({
    column: undefined,
    direction: undefined,
  });
  const [currPage, setCurrPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const toggleSort = (column: SortColumn) => {
    // If same toggle asc,desc, clear
    if (sort.column === column) {
      let newSort;
      if (!sort.direction) {
        newSort = SortDirection.DESCENDING;
      } else if (sort.direction === SortDirection.DESCENDING) {
        newSort = SortDirection.ASCENDING;
      } else {
        newSort = undefined;
      }
      setSort({
        column,
        direction: newSort,
      });
    } else {
      setSort({
        column,
        direction: SortDirection.DESCENDING,
      });
    }
    upateLocalState({});
  };

  const getSortedPaginatedDatasets = (): BaselineDataset[] =>
    datasets
      .filter(
        (item, index) =>
          index >= currPage * rowsPerPage &&
          index < currPage * rowsPerPage + rowsPerPage
      )
      .sort((f0, f1) => {
        let comp0 = 0;
        let comp1 = 0;
        if (sort.column === SortColumn.Name) {
          comp0 = f0.name.localeCompare(f1.name);
          comp1 = f1.name.localeCompare(f0.name);
        } else if (sort.column === SortColumn.LastModified) {
          comp0 =
            new Date(f0.updatedAt.toString()).getTime() -
            new Date(f1.updatedAt.toString()).getTime();
          comp1 =
            new Date(f1.updatedAt.toString()).getTime() -
            new Date(f0.updatedAt.toString()).getTime();
        } else if (sort.column === SortColumn.CreatedAt) {
          comp0 =
            new Date(f0.createdAt.toString()).getTime() -
            new Date(f1.createdAt.toString()).getTime();
          comp1 =
            new Date(f1.createdAt.toString()).getTime() -
            new Date(f0.createdAt.toString()).getTime();
        } else if (sort.column === SortColumn.TotalClimateChange) {
          const impactSummary0 = getImpactData(f0) as ImpactSummary;
          const impactSummary1 = getImpactData(f1) as ImpactSummary;
          const airValue0 = impactSummary0.air?.value || 0;
          const airValue1 = impactSummary1.air?.value || 0;
          comp0 = airValue0 - airValue1;
          comp1 = airValue1 - airValue0;
        }
        if (sort.column === SortColumn.ModifiedBy) {
          comp0 = f0.name.localeCompare(f1.lastUpdatedBy);
          comp1 = f1.name.localeCompare(f0.lastUpdatedBy);
        }
        if (sort.column === SortColumn.Status) {
          comp0 = f0.name.localeCompare(f1.lastUpdatedBy);
          comp1 = f1.name.localeCompare(f0.lastUpdatedBy);
        }
        if (sort.column === SortColumn.Year) {
          comp0 = f0.name.localeCompare(f1.year);
          comp1 = f1.name.localeCompare(f0.year);
        }
        if (!sort.direction) {
          return 0;
        }
        if (sort.direction === SortDirection.ASCENDING) {
          return comp0;
        }
        return comp1;
      });

  const openFootprint = (
    baselineReference: string,
    dataSetName: string,
    type: DatasetType
  ) => {
    const path = footprintURL({
      baselineCustomerID: customerID,
      baselineFarmID: farmID,
      baselineProcessID: processID,
      baselineType: type === DatasetType.BASELINE ? 'b' : 'i',
      baselineReference,
    });
    ctaClickEvent(
      path,
      'link',
      `Open ${type} footprint`,
      'Baseline interventions',
      'Production processes'
    );
    history.push(path, {
      farmName,
      processName,
      dataSetName,
      animalType
    });
  };

  const calculationCompleted = (item: Dataset) => {
    if (!item.calculationInProgress && item.impactSummary) return true;
    return false;
  };

  const completionStyle = (item: Dataset) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return inlineStyleIncomplete;
    if (!item.calculationInProgress && item.impactSummary)
      return inlineStyleComplete;
    if (!item.calculationInProgress && !item.impactSummary)
      return inlineStyleError;
    return inlineStyleError;
  };

  const completionMessage = (item: Dataset) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.INCOMPLETE' });
    if (!item.calculationInProgress && item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.COMPLETE' });
    if (!item.calculationInProgress && !item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR' });
    return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR' });
  };

  const completionTooltip = (item: Dataset) => {
    if (item.calculationInProgress || item.calculationInProgress === null)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.INCOMPLETE.TOOLTIP' });
    if (!item.calculationInProgress && item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.COMPLETE.TOOLTIP' });
    if (!item.calculationInProgress && !item.impactSummary)
      return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR.TOOLTIP' });
    return intl.formatMessage({ id: 'SUSTELL.BASELINE.ERROR' });
  };

  const openFootprintIfError = (item: Dataset) => {
    let datasetType: DatasetType;
    if ('interventions' in item) {
      datasetType = DatasetType.BASELINE;
    } else {
      datasetType = DatasetType.INTERVENTION;
    }

    if (!item.calculationInProgress && !item.impactSummary) {
      openFootprint(item.id, item.name, datasetType);
    }
    if (calculationCompleted(item))
      openFootprint(item.id, item.name, datasetType);
  };

  const createInterventionRows = (interventions: InterventionDataset[]) =>
    interventions &&
    interventions.length > 0 &&
    interventions.map((intervention) => {
      const impactSummary = getImpactData(intervention) as ImpactSummary;
      return (
        <StyledTableRow key={`intervention_${intervention.id}`}>
          <InterventionTableCell
            style={{ textIndent: '25px', fontStyle: 'italic' }}
          >
            <TitleLink
              onClick={() =>
                openDataset(
                  DatasetType.INTERVENTION,
                  intervention.baseline,
                  FormType.View,
                  intervention.reference
                )
              }
            >
              {intervention.name}
            </TitleLink>
          </InterventionTableCell>
          <InterventionTableCell
            style={{ cursor: 'pointer' }}
            onClick={() =>
              openDataset(
                DatasetType.INTERVENTION,
                intervention.baseline,
                FormType.View,
                intervention.reference
              )
            }
          >
            <DsmIcon name="arrows/chevron-right" size="sm" />
          </InterventionTableCell>
          <InterventionTableCell
            align="center"
            style={
              calculationCompleted(intervention) ? { cursor: 'pointer' } : {}
            }
          >
            <Tooltip title={completionTooltip(intervention)}>
              <Box
                onClick={() => openFootprintIfError(intervention)}
                style={completionStyle(intervention)}
              >
                {completionMessage(intervention)}
              </Box>
            </Tooltip>
          </InterventionTableCell>
          <InterventionTableCell align="center">
            {impactSummary?.air?.value && (
              <>
                <ExactNumber value={impactSummary.air?.value} />
                <br />
                <Typography style={{ fontSize: '12px' }}>
                  {impactSummary.air?.unit}
                </Typography>
              </>
            )}
          </InterventionTableCell>
          <InterventionTableCell align="center"></InterventionTableCell>
          <InterventionTableCell align="center">
            {intervention.updatedAt
              ? new Date(intervention.updatedAt).toLocaleDateString()
              : ''}
          </InterventionTableCell>
          <InterventionTableCell align="center">
            {intervention.lastUpdatedBy}
          </InterventionTableCell>
          <InterventionTableCell
            style={{ display: 'flex', borderBottom: '0px' }}
          >
            {!benchmark && Can('update', 'Intervention') && !isReadOnlyAnimalSystem(animalType) && (
              <Tooltip title={intl.formatMessage({ id: 'GENERAL.EDIT' })}>
                <DsmButton
                  variant="text"
                  iconOnly
                  style={{ marginTop: '8px' }}
                  onClick={(e) =>
                    openDataset(
                      DatasetType.INTERVENTION,
                      intervention.baseline,
                      FormType.Edit,
                      intervention.reference
                    )
                  }
                >
                  <DsmIcon
                    slot="before"
                    name="general/edit-01"
                    style={{ color: 'black' }}
                  />
                </DsmButton>
              </Tooltip>
            )}
            {!benchmark && !isReadOnlyAnimalSystem(animalType) && (Can('create', 'Intervention') || Can('delete', 'Intervention')) && <FadeMenu
              placeHolder="vertical"
              deleteHandler={
                !benchmark && Can('delete', 'Intervention') && !isReadOnlyAnimalSystem(animalType)
                  ? () => {
                      deleteDataset(
                        DatasetType.INTERVENTION,
                        intervention.name
                      );
                    }
                  : null
              }
              duplicateHandler={
                Can('create', 'Intervention') &&
                Can('read', 'Intervention') &&
                duplicateDataset !== null
                  ? () => {
                      duplicateDataset(DatasetType.INTERVENTION, intervention);
                    }
                  : null
              }
              showDuplicate = {!benchmark && !isReadOnlyAnimalSystem(animalType) && duplicateDataset !== null}
            />}
          </InterventionTableCell>
        </StyledTableRow>
      );
    });

  const baselineRows = (getSortedPaginatedDatasets() || []).map((item) => {
    // TODO typescript depends on ImpactNumbers.js
    const impactSummary = getImpactData(item) as ImpactSummary;
    return (
      <React.Fragment key={`dsfragment_${item.id}`}>
        <StyledTableRow key={`baseline_${item.id}`}>
          <StyledTableCell>
            <TitleLink
              onClick={() =>
                openDataset(DatasetType.BASELINE, item.id, FormType.View)
              }
            >
              <span style={{ fontWeight: '500' }}>{item.name}</span>
            </TitleLink>
          </StyledTableCell>
          <StyledTableCell
            style={{ cursor: 'pointer' }}
            onClick={() =>
              openDataset(DatasetType.BASELINE, item.id, FormType.View)
            }
          >
            <DsmIcon name="arrows/chevron-right" size="sm" />
          </StyledTableCell>
          <StyledTableCell
            align="center"
            style={calculationCompleted(item) ? { cursor: 'pointer' } : {}}
          >
            <Tooltip title={completionTooltip(item)}>
              <Box
                onClick={() => openFootprintIfError(item)}
                style={completionStyle(item)}
              >
                {completionMessage(item)}
              </Box>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            {impactSummary?.air?.value && (
              <>
                <ExactNumber value={impactSummary.air?.value} />
                <br />
                <Typography style={{ fontSize: '12px' }}>
                  {impactSummary.air?.unit}
                </Typography>
              </>
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {item.year ? item.year : ''}
          </StyledTableCell>
          <StyledTableCell align="center">
            {item.updatedAt
              ? new Date(item.updatedAt).toLocaleDateString()
              : ''}
          </StyledTableCell>
          <StyledTableCell align="center">{item.lastUpdatedBy}</StyledTableCell>
          <StyledTableCell style={{ display: 'flex' }}>
            {!benchmark && Can('update', 'Baseline') && !isReadOnlyAnimalSystem(animalType) && (
              <Tooltip title={intl.formatMessage({ id: 'GENERAL.EDIT' })}>
                <DsmButton
                  variant="text"
                  iconOnly
                  style={{ marginTop: '8px' }}
                  onClick={(e) =>
                    openDataset(DatasetType.BASELINE, item.id, FormType.Edit)
                  }
                >
                  <DsmIcon
                    slot="before"
                    name="general/edit-01"
                    style={{ color: 'black' }}
                  />
                </DsmButton>
              </Tooltip>
            )}
            {!benchmark && !isReadOnlyAnimalSystem(animalType) && (Can('create', 'Baseline') || Can('delete', 'Baseline') ) && <FadeMenu
              placeHolder="vertical"
              deleteHandler={
                !benchmark && Can('delete', 'Baseline') && !isReadOnlyAnimalSystem(animalType)
                  ? () => {
                      deleteDataset(DatasetType.BASELINE, item.name);
                    }
                  : null
              }
              duplicateHandler={
                Can('create', 'Baseline') &&
                Can('read', 'Baseline') &&
                duplicateDataset !== null
                  ? () => {
                      duplicateDataset(DatasetType.BASELINE, item);
                    }
                  : null
              }
              showDuplicate={!benchmark && !isReadOnlyAnimalSystem(animalType) && duplicateDataset !== null}
            />}
          </StyledTableCell>
        </StyledTableRow>
        {item.interventions &&
          item.interventions.length > 0 &&
          createInterventionRows(item.interventions)}
      </React.Fragment>
    );
  });

  return (
    <Table size="medium">
      <TableHead
        style={{
          border: '1px solid var(--dsm-color-neutral)',
          fontSize: '12px !important',
        }}
      >
        <TableRow>
          <StyledTableCell
            style={{ width: '20%' }}
            onClick={() => toggleSort(SortColumn.Name)}
          >
            {intl.formatMessage({ id: 'SUSTELL.BASELINE.NAME' })}
            {sort?.column === SortColumn.Name &&
            sort.direction === SortDirection.DESCENDING ? (
              <ArrowDownwardOutlined />
            ) : (
              <ArrowUpwardOutlined
                htmlColor={sort?.column !== SortColumn.Name ? 'gray' : ''}
              />
            )}
          </StyledTableCell>
          <StyledTableCell>&nbsp;</StyledTableCell>
          <StyledTableCell
            style={{ textAlign: 'center' }}
            onClick={() => toggleSort(SortColumn.Status)}
          >
            {intl.formatMessage({ id: 'GENERAL.STATUS' })}
            {sort?.column === SortColumn.Status &&
            sort.direction === SortDirection.DESCENDING ? (
              <ArrowDownwardOutlined />
            ) : (
              <ArrowUpwardOutlined
                htmlColor={sort?.column !== SortColumn.Status ? 'gray' : ''}
              />
            )}
          </StyledTableCell>
          <StyledTableCell
            style={{ width: '180px' }}
            onClick={() => toggleSort(SortColumn.TotalClimateChange)}
          >
            {intl.formatMessage({
              id: 'REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.TOTAL_CLIMATE_CHANGE',
            })}
            {sort?.column === SortColumn.TotalClimateChange &&
            sort.direction === SortDirection.DESCENDING ? (
              <ArrowDownwardOutlined />
            ) : (
              <ArrowUpwardOutlined
                htmlColor={
                  sort?.column !== SortColumn.TotalClimateChange ? 'gray' : ''
                }
              />
            )}
          </StyledTableCell>
          <StyledTableCell
            style={{ textAlign: 'center' }}
            onClick={() => toggleSort(SortColumn.Year)}
          >
            {intl.formatMessage({ id: 'GENERAL.YEAR' })}
            {sort?.column === SortColumn.Year &&
            sort.direction === SortDirection.DESCENDING ? (
              <ArrowDownwardOutlined />
            ) : (
              <ArrowUpwardOutlined
                htmlColor={sort?.column !== SortColumn.Year ? 'gray' : ''}
              />
            )}
          </StyledTableCell>
          <StyledTableCell
            style={{ textAlign: 'center' }}
            onClick={() => toggleSort(SortColumn.LastModified)}
          >
            {intl.formatMessage({ id: 'GENERAL.LAST_MODIFIED' })}
            {sort?.column === SortColumn.LastModified &&
            sort.direction === SortDirection.DESCENDING ? (
              <ArrowDownwardOutlined />
            ) : (
              <ArrowUpwardOutlined
                htmlColor={
                  sort?.column !== SortColumn.LastModified ? 'gray' : ''
                }
              />
            )}
          </StyledTableCell>
          <StyledTableCell
            style={{ textAlign: 'center' }}
            onClick={() => toggleSort(SortColumn.ModifiedBy)}
          >
            {intl.formatMessage({ id: 'GENERAL.MODIFIED_BY' })}
            {sort?.column === SortColumn.ModifiedBy &&
            sort.direction === SortDirection.DESCENDING ? (
              <ArrowDownwardOutlined />
            ) : (
              <ArrowUpwardOutlined
                htmlColor={sort?.column !== SortColumn.ModifiedBy ? 'gray' : ''}
              />
            )}
          </StyledTableCell>
          <StyledTableCell style={{ width: '60px' }}>&nbsp;</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(!datasets || datasets?.length === 0) && (
          <TableRow>
            <TableCell colSpan={7}>
              {intl.formatMessage({
                id: `SUSTELL.${emptyKey}.NO_DATA`,
              })}
            </TableCell>
          </TableRow>
        )}
        {datasets && datasets?.length > 0 && baselineRows}
      </TableBody>
    </Table>
  );
};

export default CompositeDatasetListTableV2;
