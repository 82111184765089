import React from "react";
import { Drawer as MUIDrawer } from "@material-ui/core";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
// import { useIntl } from "react-intl";
import { useIntl } from "../../../i18n/customUseIntl";
import Can from "../../../../app/modules/Auth/Can";
import Customers from "../../../../app/icons/Customers";

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
  },
  content: {
    paddingLeft: theme.spacing(3),
    width: "100%",
    height: "100%",
  },
}));

const Drawer = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { children } = props;

  const canReadCustomers = Can("read", "Customer");
  const canAccessAdmin=Can("access", "Admin pages");

  const isActivePath = (path) => location.pathname.includes(path);
  
  let menuItems = [];

  if (canAccessAdmin) {
    menuItems = [
      {
        text: intl.formatMessage({id: "USERS"}),
        icon: (
          <SupervisorAccountIcon
            color={isActivePath("/users") ? "primary" : "action"}
          />
        ),
        path: "/admin/users",
      },
      {
        text: intl.formatMessage({id: "NOTIFICATIONS"}),
        icon: (
          <NotificationsIcon
            color={isActivePath("/notifications") ? "primary" : "action"} 
          />
        ),
        path: "/admin/notifications",
      },
      {
        text:intl.formatMessage({id: "HELP_CENTER"}),
        icon: (
          <HelpIcon
            color={isActivePath("/helpcenter") ? "primary" : "action"} 
          />
        ),
        path: "/admin/helpcenter",
      }
    ];
  }

  if (canReadCustomers) {
    menuItems.unshift( {
      text: intl.formatMessage({id: "CUSTOMERS.CARD.TITLE"}),
      icon: (
        <Customers
          customColor={isActivePath("/customers") ? "primary" : "action"}
        />
      ),
      path: "/admin/customers",
    });
  }

  const renderItem = (item) => (
    <ListItem
      button
      key={item.text}
      onClick={() => history.push(item.path)}
      className={isActivePath(item.path) ? classes.active : null}
    >
      <ListItemIcon style={{ minWidth: "36px" }}>{item.icon}</ListItemIcon>
      <ListItemText primary={item.text} />
    </ListItem>
  );

  return (
      <div style={{ display: "flex" }}>
        <MUIDrawer
          variant="permanent"
          style={{ width: "20%", height: "450px" }}
          PaperProps={{
            elevation: 3,
            style: {
              position: "unset",
              borderRadius: 4,
            },
          }}
          anchor="left"
        >
          <List>
            {menuItems.map((item) => {
              return renderItem(item);
            })}
          </List>
        </MUIDrawer>
        <div className={classes.content}>{children}</div>
      </div>
  );
};

export default Drawer;
