import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType } from '../../../common';
import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../../graphql/generated/blonk/pigs';
import { StageType } from '../../../../../../../graphql/types';
import { CSSClassesList } from '../../../../../helpers/helperTypes';

interface ExternalSourcesInputProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  stageType: StageType;
  isIntervention?: boolean;
  barnOutputMassUnit: string;
  distanceUnit: string;
  removeHandler?: Maybe<Function>;
}

const ExternalSourcesInput: FC<ExternalSourcesInputProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  stageType,
  isIntervention,
  barnOutputMassUnit,
  distanceUnit,
  removeHandler,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const propName = isIntervention
    ? 'externalSourcesAdditions'
    : 'externalSources';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`;

  const newAnimalTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.NEW_ANIMALS.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.NEW_ANIMALS.TOOLTIP';
  const avgAgeTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.AVG_AGE.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.TOOLTIP';
  const avgWeightTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.AVG_WEIGHT.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.TOOLTIP';
  const distanceTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.TRANSPORT_DISTANCE.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.TRANSPORT_DISTANCE.TOOLTIP';

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NEW_ANIMALS',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER',
          })}
          tooltip={intl.formatMessage({ id: newAnimalTooltip })}
          required
          defaultValue={formContext.getValues(`${fieldItemPrefix}.numberPigs`)}
          type="number"
        />
        <ReactHookDsmInput
          style={{ marginBottom: '16px' }}
          name={`${fieldItemPrefix}.averageAgeOfPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVG_AGE',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({ id: avgAgeTooltip })}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.AGE.PLACEHOLDER',
          })}
          required
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.averageAgeOfPigs`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeightOfPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVG_WEIGHT',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({ id: avgWeightTooltip })}
          adornment={barnOutputMassUnit}
          required
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.averageWeightOfPigs`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTransport`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.TRANSPORT_DISTANCE',
          })}
          disabled={formType === FormType.View}
          adornment={distanceUnit}
          tooltip={intl.formatMessage({ id: distanceTooltip })}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.distanceTransport`) as
              | string
              | number
              | undefined
          }
          type="number"
        />
      </DsmGrid>
    </>
  );
};

export default ExternalSourcesInput;
