import React, { useState, useEffect, FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import useUserData from '../Auth/getAuthUserData';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import useStyles from './profileStyles/myProfileStyles';
import { DsmButtonV2 } from '../../sustell_15/components/helpers/DsmButton';
import ReactHookDsmInput from '../Helpers/ReactHookDsmInput2';
import { Auth } from 'aws-amplify';
import dsmTheme from '../MUITheme';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import useAdobeDataLayer from '../../sustell_15/analytics/adobeDataLayer';

const UserPersonalData: FC = () => {
  const classes = useStyles();
  const user = useUserData();
  const intl = useIntl();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [msg, setMsg] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isInError, setIsInError] = useState(false);
  const [dialogActive, setDialogActive] = useState(false);
  const [touched, setTouched] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [validNumber, setValidNumber] = useState(true);
  const { formSubmissionEvent } = useAdobeDataLayer();

  useEffect(() => {
    setTouched(false);
    user && user['attributes'] && user['attributes']['given_name']
      ? methods.setValue('firstName', user['attributes']['given_name'])
      : methods.setValue('firstName', '');
    user && user['attributes'] && user['attributes']['family_name']
      ? methods.setValue('lastName', user['attributes']['family_name'])
      : methods.setValue('lastName', '');
    user && user['attributes'] && user['attributes']['email']
      ? methods.setValue('email', user['attributes']['email'])
      : methods.setValue('email', '');
    if (user && user['attributes'] && user['attributes']['phone_number']) {
      setPhoneValue(user['attributes']['phone_number']);
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [user]);

  const history = useHistory();

  const onSubmit = () => {
    (async () => {
      formSubmissionEvent('Edit User PersonalData', 'edit');
      try {
        await Auth.updateUserAttributes(user, {
          phone_number: phoneValue,
        });
        setIsInError(false);
        setMsg(intl.formatMessage({ id: 'PROFILE.CONTACT.SAVED' }));
        setDialogActive(true);
      } catch (err) {
        if (err.errors?.length > 0) {
          const error = err.errors[0];
          if (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setMsg(error.message);
            setIsInError(true);
            setDialogActive(true);
          }
        }
      }
    })()
      .then(() => { })
      .catch(() => { });
  };

  const closeDialog = () => {
    setDialogActive(false);
    setMsg('');
    setTouched(false);
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const handleTouched = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (touched) {
      setUnsavedChanges(true);
    } else {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push('/');
      }
    }
  };

  const close = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const updatePhoneNum = (value: any) => {
    handleTouched();
    let phoneNumVal = '',
      isValid = false;
    if (value) {
      phoneNumVal = value;
      isValid = isValidPhoneNumber(value);
    }
    setPhoneValue(phoneNumVal);
    setValidNumber(isValid);
  };

  return (
    <>
      <Dialog open={unsavedChanges} aria-labelledby="draft-dialog">
        <DialogTitle id="draft-dialog">
          {intl.formatMessage({ id: 'GENERAL.UNSAVED_CHANGES' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'UNSAVEDSTATE.PROMPT.CONFIRM' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            {intl.formatMessage({ id: 'GENERAL.YES' })}
          </Button>
          <Button
            autoFocus
            onClick={() => setUnsavedChanges(false)}
            color="default"
          >
            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={dsmTheme}>
        <Dialog
          open={dialogActive}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {isInError && (
              <>
                <ErrorOutlineIcon fontSize="large" color="error" />
                &nbsp;&nbsp;Error
              </>
            )}
            {!isInError && (
              <>
                <CheckCircleOutlineIcon
                  fontSize="large"
                  style={{ color: 'green' }}
                />
                &nbsp;&nbsp;Success
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {isInError && (
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
              </Button>
            )}
            {!isInError && (
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'GENERAL.OK' })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <form
          className={classes.formContainer}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="card-body p-0">
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({ id: 'PROFILE.PERSONAL_DETAILS' })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmInput
              name="firstName"
              label={intl.formatMessage({
                id: 'GENERAL.FIRST_NAME',
              })}
              placeholder={intl.formatMessage({
                id: 'GENERAL.FIRST_NAME',
              })}
              readonly
              disabled
              required
            />
            <ReactHookDsmInput
              name="lastName"
              label={intl.formatMessage({
                id: 'GENERAL.LAST_NAME',
              })}
              placeholder={intl.formatMessage({
                id: 'GENERAL.LAST_NAME',
              })}
              readonly
              disabled
              required
            />
          </DsmGrid>
          <div>
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({ id: 'PROFILE.CONTACT_INFO' })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmInput
              name="email"
              label={intl.formatMessage({
                id: 'PROFILE.CONTACT_EMAIL',
              })}
              placeholder={intl.formatMessage({
                id: 'GENERAL.EMAIL',
              })}
              readonly
              disabled
              required
            />
            <div className="form-field">
              <label htmlFor="phoneNumber" className={classes.phoneNumberLabel}>
                {intl.formatMessage({
                  id: 'PROFILE.PHONE',
                })}
              </label>
              <div className={classes.phoneInputContainer}>
                <PhoneInput
                  id="phoneNumber"
                  name="phoneNumber"
                  international
                  placeholder={intl.formatMessage({
                    id: 'GENERAL.PHONE_NUMBER',
                  })}
                  onChange={(value) => (value ? updatePhoneNum(value) : '')}
                  value={phoneValue}
                  numberInputProps={{
                    autoFocus: false,
                    disabled: false,
                    readOnly: false,
                    className: classes.phoneInputText,
                  }}
                />
              </div>
              {!validNumber && (
                <p style={{ color: 'var(--error-dsm-red, #E51F22)' }}>
                  {intl.formatMessage({ id: 'PROFILE.CONTACT.ERR' })}{' '}
                </p>
              )}
            </div>
          </DsmGrid>
          {/* Buttons */}
          <div className={classes.btnDiv}>
            <DsmButtonV2
              variant="secondary"
              type="button"
              onClick={handleClose}
              className={classes.cancelBtn}
              style={
                {
                  background: '#FFFFFF',
                  color: '#0070BA',
                  '::hover': {
                    backgroundColor: '#FFFFFF !important',
                  },
                }
              }
            >
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </DsmButtonV2>
            <div className="text-right">
              <DsmButtonV2
                variant="contained"
                disableElevation
                type="submit"
                disabled={
                  methods.formState.isSubmitting ||
                  (methods.formState.isDirty && !methods.formState.isValid) ||
                  !touched ||
                  !validNumber
                }
              >
                <span className={classes.saveBtnTxt}>
                  {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                </span>
                <DsmIcon
                  slot="after"
                  name="arrows/arrow-right"
                  style={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    height: '25px',
                    width: '25px',
                    color: 'var(--neutrals-dsm-white, #FFF)',
                  }}
                />
              </DsmButtonV2>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export { UserPersonalData };
