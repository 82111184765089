import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Grid,
} from "@material-ui/core";

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { BaselinePropertyValue } from '../../../../models/Baseline';
import { BaselineDialogProps, FormType } from '../../common';

const PFFeedsVariablePart: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
}) => {

  const intl = useIntl();
  const formContext = useFormContext();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  
  return (
    <>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix}.digestibility`}
          label= { intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.DIGESTIBILITY"}) } 
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.DIGESTIBILITY.TOOLTIP"}) } 
          addornment={ intl.formatMessage({ id: "ADDORNMENT.UNITS.PERCENT_OF_GA" })}
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.digestibility`) as BaselinePropertyValue}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
            name={`${fieldItemPrefix}.grossEnergyIntake`}
            label={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.GROSS_ENERGY_INTAKE"})}
            margin="none"
            variant="outlined"
            addornment={ intl.formatMessage( {id: "ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX"},{unit: 'MJ' }) }
            disabled={formType === FormType.View}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.grossEnergyIntake`) as BaselinePropertyValue}
          />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix}.nitrogenContent`}
          type="number"
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT" }) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT.TOOLTIP" }) }
          addornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrogenContent`) as BaselinePropertyValue}
        />
      </Grid>
    </>
  );
};

export default PFFeedsVariablePart;