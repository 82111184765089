/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import { BaselineStageProps, ShrimpFarms } from '../../common';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import StageButtonBox from '../../StageButtonBox_v2';
import SInputGrowingFormDialog from './SInputGrowingFormDialog';
import SOperationsFormDialog from './SOperationsGrowingFormDialog';
import { Origin } from '../types';
import SOutputGrowingDialog from './SOutputGrowingDialog';
import FeedsFormDialog from '../../CommonDataParts/FeedsFormDialog_v2';
import { stageDataPartShrimp } from '../../../../../modules/Farms/Baseline/validationSchema/shrimpBaselineValidation';
import { defaultValues } from '../../baselineDefaultValues';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  OPERATIONS = 'operations',
  OUTPUT = 'output',
}

interface ShrimpStagesProps extends Omit<BaselineStageProps, 'baseline'> {
  farms: ShrimpFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
  baseline: ShrimpBaseline;
  stageType: StageType;
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
  origins: Origin[];
  masterDataEnums: MasterDataEnumType | null;
  calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

interface CloseDialogData {
  type: string;
  setFunc: React.Dispatch<React.SetStateAction<number>>;
  prefix: 'input' | 'output' | 'feed' | 'emissions' | 'operations';
}

interface InitialCalcuationData {
  prefix: 'input' | 'output' | 'feed' | 'emissions' | 'operations';
}

const ShrimpStages: FC<ShrimpStagesProps> = ({
  farms,
  farmName,
  farmId,
  productionProcessName,
  stageIndex,
  baseline,
  item,
  formType,
  stageType = StageType.Growing,
  animalType,
  compoundFeeds,
  singleIngredients,
  origins,
  masterDataEnums,
  calculateCompleteness,
}) => {
  const fc = useFormContext();
  const [activeDialog, setActiveDialog] = useState<string>('');

  const showDialog = (dialog: SetStateAction<string>) => {
    setActiveDialog(dialog);
  };

  const formContext = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;
  const [inputGrowingCompleteness, setInputGrowingCompleteness] = useState(0);
  const [feedGrowingCompleteness, setFeedGrowingCompleteness] = useState(0);
  const [operationsGrowingCompleteness, setOperationsGrowingCompleteness] =
    useState(0);
  const [outputGrowingCompleteness, setOutputGrowingCompleteness] = useState(0);

  let stageTypeCode = ''
  switch(stageType) {
    case StageType.Hatching:
      stageTypeCode =  'BROODSTOCK';
      break;
    case StageType.Nursery:
      stageTypeCode = 'POST_LARVAE';
      break;
    case StageType.Growing:
    default:
      stageTypeCode = 'JUVENILES';
      break;
  } 
  const inputDialogTitle = `SUSTELL.PROCESS.DIALOG.STAGE.${stageTypeCode}.TITLE`;

  const initialCalculation = (initialCalcuationData: InitialCalcuationData) => {
    const validationObject = item.stageData[initialCalcuationData.prefix];
    const defaultObject =
      defaultValues[animalType]?.stages[0]?.stageData[
        initialCalcuationData.prefix
      ];
    if (item.newStage && isEqual(validationObject, defaultObject)) return 0;
    return calculateCompleteness(
      stageDataPartShrimp,
      `stages[${stageIndex}].stageData.${initialCalcuationData.prefix}`
    );
  };

  const closeDialog = (closeDialogData: CloseDialogData) => {
    const completeness = calculateCompleteness(
      stageDataPartShrimp,
      `stages[${stageIndex}].stageData.${closeDialogData.prefix}`
    );
    if (formType !== 'view' && closeDialogData.type === 'confirm') {
      closeDialogData.setFunc(completeness);
    } else if (closeDialogData.type === 'reset') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (!completeness) fc.trigger(`${stagePrefix}.stageData.${activeDialog}`);
      else fc.clearErrors(`${stagePrefix}.stageData.${activeDialog}`);
    }
    setActiveDialog('');
  };

  useEffect(() => {
    if (formType === 'edit' && !item.newStage) {
      setInputGrowingCompleteness(
        initialCalculation({ prefix: StageDialogType.INPUT })
      );
      setFeedGrowingCompleteness(
        initialCalculation({ prefix: StageDialogType.FEED })
      );
      setOperationsGrowingCompleteness(
        initialCalculation({ prefix: StageDialogType.OPERATIONS })
      );
      setOutputGrowingCompleteness(
        initialCalculation({ prefix: StageDialogType.OUTPUT })
      );
    }
  }, [formType]);

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        defaultValue={item.type}
      />
      {activeDialog === StageDialogType.INPUT && (
        <SInputGrowingFormDialog
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === StageDialogType.INPUT}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setInputGrowingCompleteness,
              prefix: StageDialogType.INPUT,
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          stageType={stageType}
          animalType={animalType}
        />
      )}
      {activeDialog === StageDialogType.FEED && (
        <FeedsFormDialog
          formVisible={activeDialog === StageDialogType.FEED}
          handleCancel={(type: string) => {
            closeDialog({
              type,
              setFunc: setFeedGrowingCompleteness,
              prefix: StageDialogType.FEED,
            });
            closeDialog({
              type,
              setFunc: setOperationsGrowingCompleteness,
              prefix: StageDialogType.OPERATIONS,
            });
          }}
          formType={formType}
          baseline={baseline}
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
          animalType={animalType}
          stageType={stageType}
          masterDataEnums={masterDataEnums}
        />
      )}
      {activeDialog === StageDialogType.OPERATIONS && (
        <SOperationsFormDialog
          formVisible={activeDialog === StageDialogType.OPERATIONS}
          formType={formType}
          handleCancel={(type: string) => {
            closeDialog({
              type,
              setFunc: setOperationsGrowingCompleteness,
              prefix: StageDialogType.OPERATIONS,
            });
            closeDialog({
              type,
              setFunc: setFeedGrowingCompleteness,
              prefix: StageDialogType.FEED,
            });
          }}
          baseline={baseline}
          itemIndex={stageIndex}
          animalType={animalType}
          stageType={stageType}
          masterDataEnums={masterDataEnums}
          singleIngredients={singleIngredients}
        />
      )}
      {activeDialog === StageDialogType.OUTPUT && (
        <SOutputGrowingDialog
          formVisible={activeDialog === StageDialogType.OUTPUT}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputGrowingCompleteness,
              prefix: StageDialogType.OUTPUT,
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          animalType={animalType}
        />
      )}
      <Box
        style={{
          display: 'flex',
          width: '80%',
        }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <StageButtonBox
          titleCode={inputDialogTitle}
          iconCode="general/check-heart"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={inputGrowingCompleteness}
          error={
            formContext?.errors?.stages &&
            formContext?.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={feedGrowingCompleteness}
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"
          iconCode="general/settings-01"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={operationsGrowingCompleteness}
          error={
            formContext?.errors?.stages &&
            formContext?.errors?.stages[stageIndex]?.stageData?.operations
          }
          handleOpen={() => showDialog(StageDialogType.OPERATIONS)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={outputGrowingCompleteness}
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
      </Box>
    </>
  );
};

export default ShrimpStages;
