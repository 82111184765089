import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { PoultryBaseline, PoultryOutputLaying } from '../../../../models/Baseline/PoultryBaseline';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { StageType } from '../../../../../../graphql/types';
import lookupValues from '../../../../helpers/lookupValues';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
}

const PoultryOutputLayingDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PoultryOutputLaying>();
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const fc = useFormContext<PoultryBaseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnSmallQuantityUnits = unitLong(userUOM.unitBarnSmallQuantityUnits as string);

  useEffect(() => {
    if(formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = ()=>{
    if (currResetValue.current){
      const resetObject = {...fc.getValues()} as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel('reset');
  }

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant="wide"
    > 
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.EGGS_TITLE'})}
            </span>
          </div>
          <DsmFieldset >
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.totalSold`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_SOLD"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_SOLD.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_SOLD.TOOLTIP"})}
              required
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.totalSold`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeight`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.AVERAGE_EGGS_WEIGHT"})}
              adornment={barnSmallQuantityUnits}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.AVERAGE_EGGS_WEIGHT.TOOLTIP"})}
              required
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.averageWeight`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.priceEggs`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_EGGS"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_EGGS.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_EGGS.TOOLTIP"})}
              type="number"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              defaultValue={fc.getValues(`${fieldItemPrefix}.priceEggs`) || lookupValues.defaultPrice[StageType.Laying].priceEggs}
            />
          </DsmFieldset>
        </div>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.HENS_TITLE'})}
            </span>
          </div>
          <DsmFieldset >
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.totalHensToStage`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_HENS_TO_STAGE"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.TOTAL_HENS_TO_STAGE.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              required
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.totalHensToStage`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageHensWeight`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.AVERAGE_HENS_WEIGHT"})}
              adornment={barnOutputMassUnit}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.AVERAGE_HENS_WEIGHT.TOOLTIP"})}
              required
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.averageHensWeight`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.priceHens`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_HENS"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_HENS.PLACEHOLDER"})}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.PRICE_HENS.TOOLTIP"})}
              type="number"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              defaultValue={fc.getValues(`${fieldItemPrefix}.priceHens`) || lookupValues.defaultPrice[StageType.Laying].priceHens}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.mortalityAtPercent`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.MORTALITY_RATE"})}
              adornment='%'
              disabled={formType === FormType.View}
              required
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.mortalityAtPercent`)}
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.weightAtMortality`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.LAYING.WEIGHT_AT_MORTALITY"})}
              adornment={barnOutputMassUnit}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={fc.getValues(`${fieldItemPrefix}.weightAtMortality`)}
            />
          </DsmFieldset>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await fc.trigger(`stages.${itemIndex}.stageData.output`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM" })}/>
    </DialogContainer>
  );
};

export default PoultryOutputLayingDialog;