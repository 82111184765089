import React, { useEffect, useState } from 'react';
import { Checkbox, List, ListItem, ListItemText, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { getDefaultIngredientsAddonsNames } from '../../../sustell_15/utils/compound-feed-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  selectAllCheckBox: {
    fontWeight: 'bold',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const CustomerIngredientsAddonsList = (props) => {
  const { ingredientAddonsList, ingredientList, setIngredientList, assignedIngredients } = props;
  const [ingredients, setIngredients] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false); 
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    const ingredients = [...ingredientAddonsList];
    setIngredients(ingredients.sort((a, b) => a.localeCompare(b)));
    
    const resultMap = new Map();
    ingredients.forEach(ingredient => {
      const existsIningredientList = ingredientList.some(ingredientListItem => ingredientListItem=== ingredient);
      resultMap.set(ingredient, existsIningredientList);
    });

    const resultObject = Object.fromEntries(resultMap);
    const initialState = !Object.values(resultObject).includes(false)
    setChecked(resultObject)
    setCheckedAll(initialState);
  }, [])

  useEffect(() => {
    let allChecked = true;
    Object.keys(checked).forEach(ingredient => {
      if (checked[ingredient] === false) {
        allChecked = false;
      }
    });
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);
  
  const handleToggle = (ingredient) => {
    const currentIndex = ingredientList?.findIndex((ing) => ing === ingredient);
    const newChecked = [...ingredientList];
    if (currentIndex === -1) newChecked.push(ingredient);
    else newChecked.splice(currentIndex, 1);
    setIngredientList(newChecked);
  };

  const toggleCheck = (ingredient) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[ingredient] = !prevState[ingredient];
      return newState;
    });
    handleToggle(ingredient);
  };

  const selectAll = (value) => {
    if(value) setIngredientList(ingredients);
    else setIngredientList(assignedIngredients);
    
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((ingredient) => {
        newState[ingredient] = value;
      });
      return newState;
    });
  };

  const defaultIngredientsAddonsNames = getDefaultIngredientsAddonsNames();

  const getTitle = (ingredient) => {
    if(defaultIngredientsAddonsNames.includes(ingredient))
      return  intl.formatMessage({id: "CUSTOMERS.FORM.FIELD.DEFAULT_INGREDIENTS_TOOLTTIP_CHECKED" });
    if(assignedIngredients && assignedIngredients?.includes(ingredient))
      return intl.formatMessage({id: "CUSTOMERS.FORM.FIELD.INGREDIENTS_TOOLTTIP_CHECKED" });
      
    return intl.formatMessage({id: "CUSTOMERS.FORM.FIELD.INGREDIENTS_TOOLTTIP_NOT_CHECKED" });
  }
  
  return (
    <>
      <Typography>
        {intl.formatMessage({id: "CUSTOMERS.CARD.INGREDIENTS_LIST.CF_ADDONS" })}
      </Typography>
      <List className={classes.root}>
        <ListItem dense>
          <Checkbox
            onChange={(event) => selectAll(event.target.checked)}
            checked={checkedAll}
            edge="start"
          />
          <ListItemText 
            className={classes.selectAllCheckBox} 
            primary={intl.formatMessage({id: "USERS.GROUPS.SELECT_ALL" })}
            classes={{ primary: classes.selectAllCheckBox }}          />
        </ListItem>
        {ingredients.map((ingredient, index) => {
          const labelId = `checkbox-list-label-${ingredient}-${index}`;
          return (
            <Tooltip 
              key={`${labelId}-tooltip`}
              title={getTitle(ingredient)}
            >
              <ListItem dense>
                  <Checkbox
                    onChange={() => toggleCheck(ingredient)}
                    checked={ingredientList?.includes(ingredient) || defaultIngredientsAddonsNames.includes(ingredient)}
                    edge="start"
                    disabled={assignedIngredients?.includes(ingredient) || defaultIngredientsAddonsNames.includes(ingredient)}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                <ListItemText id={labelId} primary={ingredient} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </>
  );
}

export default CustomerIngredientsAddonsList;