import {
  ChemicalType,
  Currency,
  FacilityType,
  RefrigerantType,
} from '../../../../graphql/types';

type FormPropType = string | undefined;

export enum EnergyCarrierType {
  Coal = 'COAL',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Gasoline = 'GASOLINE',
  NaturalGas = 'NATURAL_GAS',
  PropaneOrLpg = 'PROPANE_OR_LPG',
}

export enum RenewableType {
  ELECTRICITY_PHOTOVOLTAIC = 'ELECTRICITY_PHOTOVOLTAIC',
  ELECTRICITY_WIND = 'ELECTRICITY_WIND',
}

export type TypeAmountField<EnumType> = {
  amount: number | string;
  type: EnumType;
};

export interface AuxiliaryInput {
  chemicals: TypeAmountField<ChemicalType>[];
  refrigerants: TypeAmountField<RefrigerantType>[];
}

export interface SlaughterhouseFacilityForm {
  id: string | null;
  name: string;
  oldName?: string;
  type: FacilityType;
  location: string;
  targetSpecies: FacilitySpecies;
  totalLiveweight: number | string;
  currency: Currency;
  waterUse: FormPropType;
  wasteWaterAmount: FormPropType;
  electricity: FormPropType;
  gasUse: FormPropType;
  auxiliaryInputs: AuxiliaryInput;
  additionalEnergyTypes: TypeAmountField<EnergyCarrierType>[];
  selfGeneratedRenewables: TypeAmountField<RenewableType>[];
  benchmark: boolean;
}

export enum FacilitySpecies {
  MarineFish = 'MARINE_FISH',
  Pig = 'PIG',
  Poultry = 'POULTRY',
  Beef = 'BEEF',
}

export interface FacilityTableItem {
  id: string;
  name: string;
  targetSpecies: FacilitySpecies[];
  location: string;
  type: FacilityType;
  currency: Currency;
  lastUpdatedBy: string;
  updatedAt: Date;
  benchmark: boolean;
}
