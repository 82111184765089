import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/styles';

export const DsmButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#007E4B',
    '&:hover': {
      backgroundColor: '#007E4B !important',
    },
    fontSize: '15px',
    fontWeight: '500',
  },
}))(Button);

export const DsmButtonV2 = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#0070BA',
    border: '1px solid #0070BA',
    '&:hover': {
      backgroundColor: '#0070BA !important',
      color: '#FFFFFF !important',
    },
    '&:disabled': {
      backgroundColor: '#F0F0F0 !important',
      border: '1px solid #F0F0F0',
    },
    fontSize: '15px',
    fontWeight: '500',
    height: '40px',
    textTransform: 'none',
  },
}))(Button);

export const useButtonStyles = makeStyles(() => ({
  secondaryButton: {
    background: '#FFFFFF',
    color: '#0070BA',
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      color: '#0070BA !important',
    },
  },
}));
