import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import InfoToolTip from '../../Helpers/InfoToolTip';
import ReactHookFormSelect from '../../Helpers/ReactHookFormSelect';
// import { useIntl } from 'react-intl';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

const RowSelectWithMetrics = props => {
  let {
    name,
    label,
    control,
    items,
    tooltip,
    variant,
    baseline,
    disabled,
  } = props;
  
  const intl = useIntl();
  let originalDisplayName = baseline;
  if (baseline && items?.findIndex(e => e.value === baseline) > -1) {
    originalDisplayName = items.filter(e => e.value === baseline)[0]?.display_name;
  }
  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: 0 }}>
        <Grid item xs={11}>
          <ReactHookFormSelect
            name={name}
            label={label}
            control={control}
            variant={variant}
            fullWidth
            style={{ marginBottom: 0 }}
            //onBlur={onBlur}
            //onChange={onChange}
            disabled={disabled}
          >
            {items.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.display_name}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
          <div style={{width: 'max-content'}}>
            { intl.formatMessage({id: "INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE"}) } <strong>{originalDisplayName}</strong>
          </div>
        </Grid>
        <Grid item xs={1} className="d-flex">
          <div style={{ paddingTop: 16 }}>
            {tooltip && <InfoToolTip tooltip={tooltip} />}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RowSelectWithMetrics;
