import { Grid, MenuItem } from '@material-ui/core';
import { FC, useEffect, useRef } from 'react';

import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import {
  avgLiveWeightMaxs,
  avgLiveWeightMins,
} from '../../../../../modules/Farms/validationSchemaRestrictions';
import InfoToolTip from '../../../../../modules/Helpers/InfoToolTip';
import ReactHookFormSelect from '../../../../../modules/Helpers/ReactHookFormSelect';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  defaultUnits,
  explicitConvertValue,
  unitLong,
} from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import {
  BaselineDialogProps,
  FormType,
  LHManure,
  LayingHensForm,
} from '../../common';

const LHManureFormDialog: FC<BaselineDialogProps> = (props) => {
  const {
    formVisible,
    itemIndex = 0,
    formType = 'add',
    handleCancel,
    handleSave = handleCancel,
    manureManagementSystems,
  } = props;

  const intl = useIntl();
  // TODO: leave profile, UOM until converted to TS
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<LHManure | null>(null);

  let minAvgLiveWeightMortality = avgLiveWeightMins['Laying hens'];
  let maxAvgLiveWeightMortality = avgLiveWeightMaxs['Laying hens'];
  const precision = userProfile.getUnitBarnOutputMassPrecision();

  if (userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass) {
    if (minAvgLiveWeightMortality)
      minAvgLiveWeightMortality = Number(
        explicitConvertValue(
          minAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
    if (maxAvgLiveWeightMortality)
      maxAvgLiveWeightMortality = Number(
        explicitConvertValue(
          maxAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
  }
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';

  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE' },
    { br: ' ' }
  );

  const fieldItemPrefix = `stages.${itemIndex}.stageData.manure`;
  const methods = useFormContext<LayingHensForm>();
  const getValues = (key?: string): ReturnType<typeof methods.getValues> =>
    key ? methods.getValues(key) : methods.getValues();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...getValues(fieldItemPrefix),
      } as LHManure; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as LayingHensForm;
      if (resetObject.stages[itemIndex]?.stageData?.manure) {
        resetObject.stages[itemIndex].stageData.manure = {
          ...currResetValue.current,
        };
        methods.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  return (
    <DialogContainer
      variant="wide"
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="manureIcon"
      formTitle={formTitle}
    >
      <Grid container direction="row" spacing={3} justifyContent="space-between">
        <Grid item container direction="column" xs={6} spacing={3}>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.averageAnimalsPresent`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.BARN.AVERAGE_ANIMALS_PRESENT',
              })} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.AVERAGE_ANIMALS_PRESENT.TOOLTIP',
              })}
              disabled={formType === 'view'}
              defaultValue={getValues(
                `${fieldItemPrefix}.averageAnimalsPresent`
              )}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={11}>
              <ReactHookFormSelect
                name={`${fieldItemPrefix}.manureManagementSystem`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM',
                })}
                control={methods.control}
                variant="outlined"
                fullWidth
                disabled={formType === FormType.View}
                defaultValue={
                  ((getValues(fieldItemPrefix) as LHManure)
                    ?.manureManagementSystem as string) || ''
                }
              >
                {manureManagementSystems?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.display_name}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item className="d-flex">
              <div
                style={{ paddingTop: '15px', paddingLeft: '6px' }}
                key="lh-manure-laying-hens-manure-management-tooltip"
              >
                <InfoToolTip
                  tooltip={
                    <div key="lh-manure-laying-hens-manure-management-tooltip2">
                      {intl.formatMessage(
                        {
                          id: 'BASELINE.FORM.BARN.HENS.MANURE_MANAGEMENT_SYSTEM.TOOLTIP',
                        },
                        { br: <br /> }
                      )}
                    </div>
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: '-1.75rem' }}>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.solidManureStorage`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SOLID_MANURE',
              })} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SOLID_MANURE.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === 'view'}
              defaultValue={getValues(`${fieldItemPrefix}.solidManureStorage`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.slurryManureStorage`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SLURRY_MANURE',
              })} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SLURRY_MANURE.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === 'view'}
              defaultValue={getValues(`${fieldItemPrefix}.slurryManureStorage`)}
            />
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={6} spacing={3}>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.ashContent`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.ASH' })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.ASH.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === 'view'}
              defaultValue={getValues(`${fieldItemPrefix}.ashContent`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.strawForBedding`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.STRAW_FOR_BEDDING',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.STRAW_FOR_BEDDING.TOOLTIP',
              })}
              addornment={intl.formatMessage(
                { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === 'view'}
              defaultValue={getValues(`${fieldItemPrefix}.strawForBedding`)}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix}.sawDust`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.SAW_DUST' })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SAW_DUST.TOOLTIP',
              })}
              addornment={intl.formatMessage(
                { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === 'view'}
              defaultValue={getValues(`${fieldItemPrefix}.sawDust`)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default LHManureFormDialog;
