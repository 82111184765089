import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { defaultValuesForMortalityData } from '../../validationSchemaRestrictions';
import {
  mapCommonProductionDataOutSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustell';
import { floatValueInDefaultUnit, setFloatValue } from './floatHelperFunctions'; 

const ANIMAL_TYPE = 'Laying hens';

const mapLayingHensProductionDataOutSustell = (formData, isDraft = false ) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  let payloadObj = mapCommonProductionDataOutSustell(formData, isDraft);
  payloadObj = mapResourceUseSustell(formData, payloadObj);

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if(payloadObj.stages[index]!==undefined){
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const { stageData } = stageGeneralData;
        stageData.animal_group.animal_type = ANIMAL_TYPE;

        const manureBlockData = formStageData?.manure;    
        // if draft saving is triggered immediatelly after adding new stage, manure block might not be still available in the form data
        if(manureBlockData) {
          stageData.animal_group.number_of_animals = {
              value:
                parseFloat(formStageData.manure?.averageAnimalsPresent) || null,
          };
        }
        
        // process input block
        // (purchasedAnimalsLayingHens, mortalityRate, averageLiveweightMortality)
        const inputBlockData = formStageData?.input;
        
        // if draft saving is triggered immediatelly after adding new stage, input block might not be still available in the form data
        if(inputBlockData){
          stageData.purchased_laying_hens = {
            number_of_purchased_animals: {
              value: parseFloat(inputBlockData.purchasedAnimalsLayingHens),
            },
          };
          // set default values for mortality
          stageData.mortality = {
            mortality_rate: {
              value: !isDraft ? defaultValuesForMortalityData[ANIMAL_TYPE].mortalityRate : null,
            },
            average_live_weight_mortalities: {
              value: !isDraft ? defaultValuesForMortalityData[ANIMAL_TYPE].averageLiveweightMortality : null,
            },
          };

          // if existing this will replace defaults with properly calculated input values
          setFloatValue(
            stageData.mortality.mortality_rate,
            'value',
            inputBlockData,
            'mortalityRate'
          );
    
          setFloatValue(
            stageData.mortality.average_live_weight_mortalities,
            'value',
            inputBlockData,
            'averageLiveWeightMortalities',
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          );
        }
        
        // process output block
        // (eggs, liveWeight )
        const outputBlockData = formStageData?.output;
        
        // if draft saving is triggered immediatelly after adding new stage, output block might not be still added to the form data
        if(outputBlockData){
          stageData.eggs = {
            total_weight: {
              value: floatValueInDefaultUnit(
                outputBlockData.eggs,
                userUOM.unitOutputMass,
                defaultMetric.unitOutputMass,
                outputMassPrecision
              ),
            },
          };
          stageData.spent_hens = {
            total_weight: {
              value: floatValueInDefaultUnit(
                outputBlockData.liveWeight,
                userUOM.unitOutputMass,
                defaultMetric.unitOutputMass,
                outputMassPrecision
              ),
            },
          };
        }

        // set housing block of data
        stageData.housing_description = {
          housing_type: 'housed',
        };
        stageData.housing_system = formDataForOneStage.productionSystem;
      }
      
    });
  payloadObj.stages.forEach( item => {
    const { stageData } = item;
    item.stageData = JSON.stringify(stageData);
  })
  return payloadObj;
};

export default mapLayingHensProductionDataOutSustell