/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useState } from "react";
import { useIntl } from "../../../_metronic/i18n/customUseIntl";
import SustellFarmBaseForm from "../components/FarmFlow/SustellFarmBase_v2";
import { FormType } from "../components/FarmFlow/common";
import SustellFarmSteps from "./FarmFlow/SustellFarmSteps_v2";
// import { DsmHeader } from "@dsm-dcs/design-system-react";

import { AnimalType } from '../../../graphql/types';
import { isMarineFish } from "../helpers/animals";

interface FarmFormPageProps {
  location?:{
    state?: {
      farmName?:string,
      farmCountry?: string,
      farmCity?:string,
      animalType?:AnimalType
      formType?:FormType
    }
  }
  match: {
    params:{
      customerID:string,
      farmID:string
    }
  }
};

export const FarmFormPage: FC<FarmFormPageProps> = (props) => {
  const intitialFormType = props.location?.state?.formType || FormType.View;
  const intl = useIntl();
  const { customerID, farmID } = props.match.params;
  const [formType, setFormType] = useState<FormType>(farmID ==='new' || farmID ==='NEW' ? FormType.Add : intitialFormType );
  const [completed, setCompleted] =  useState<Array<string>>([]);
  const farmType = props.location?.state?.animalType || AnimalType.Pig;

  const formTitle =
    formType === FormType.Add
      ? intl.formatMessage({ id: "SUSTELL.FARM.PAGE.TITLE.NEW" })
      : intl.formatMessage({ id: "SUSTELL.FARM.PAGE.TITLE.EDIT" });
  
  const switchToEdit = () => {
    setFormType(FormType.Edit)
  }

  return (
    <>
      <h1 style={{color: 'var(--dsm-color-primary)'}}>{formTitle}</h1>
      {!isMarineFish(farmType) ? (
        <p style={{width: "70%"}}>
          {intl.formatMessage({ id: "SUSTELL.FARM.PAGE.DESCRIPTION"}, { br: <br/>})}
        </p>) : ( 
        <p style={{width: "70%"}}>
          {intl.formatMessage({ id: "SUSTELL.FARM.PAGE.SHORT_DESCRIPTION"}, { br: <br/>})}
        </p>
      )}
      <SustellFarmSteps
        active = '1'
        completed = { completed }
        farmID = { farmID }
        farmType = { farmType }
        customerID = { customerID }
      />
      <SustellFarmBaseForm
        formType = { formType }
        customerId = { customerID }
        farmId = { farmID }
        farmInit = { props.location?.state }
        switchToEdit = { switchToEdit }
      />      
    </>
  );
};

export default FarmFormPage;