import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalmonBaseline } from '../../../../models/Baseline';
import { SalmonIntervention } from '../../../../models/Intervention';
import StageButtonBox from '../../StageButtonBox';
import {
  InterventionStageProps,
  MasterDataEnumType,
} from '../CommonDataParts/InterventionTypes';
import SInterventionFeedsFormDialog from './SInterventionFeedsFormDialog';
import SInterventionInputFormDialog from './SInterventionInputFormDialog';
import SInterventionOperationsFormDialog from './SInterventionOperationsFormDialog';
import SInterventionOutputFormDialog from './SInterventionOutputFormDialog';

interface SalmonInterventionStageProps extends InterventionStageProps {
  intervention: SalmonIntervention | null;
  baseline: SalmonBaseline;
  // eslint-disable-next-line react/require-default-props
  masterDataEnums: MasterDataEnumType | null;
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  OPERATIONS = 'operations',
  OUTPUT = 'output',
}

const SalmonInterventionStages = ({
  stageIndex,
  baseline,
  compoundFeeds,
  masterDataEnums,
  intervention,
  formType,
}: SalmonInterventionStageProps) => {
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(
    null
  );

  const showDialog = (dialog: StageDialogType | null) => {
    setActiveDialog(dialog);
  };

  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.origin`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.productionSystem`}
        value={`${baseline?.stages?.[stageIndex]?.productionSystem}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.index`}
        value={`${stageIndex}`}
      />

      <SInterventionInputFormDialog
        formVisible={activeDialog === 'input'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <SInterventionFeedsFormDialog
        formVisible={activeDialog === 'feed'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        compoundFeeds={compoundFeeds}
        nutrientTypes={masterDataEnums?.NutrientType}
        intervention={intervention}
        formType={formType}
      />
      <SInterventionOperationsFormDialog
        formVisible={activeDialog === 'operations'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        masterDataEnums={masterDataEnums}
        intervention={intervention}
        formType={formType}
      />
      <SInterventionOutputFormDialog
        formVisible={activeDialog === 'output'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE"
          iconCode="SALMON_INPUT"
          showCompletness={false}
          // this usafe assignment/access shouldn't be a problem as it represesnts a form hierachy
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"
          iconCode="operationsIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.operations
          }
          handleOpen={() => showDialog(StageDialogType.OPERATIONS)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="SALMON"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        <StageButtonBox
          optional
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          showCompletness={false}
          error={null}
          handleOpen={() => {}}
          disabled
        />
      </Grid>
    </>
  );
};

export default SalmonInterventionStages;
