import { useSelector } from 'react-redux';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Link, useLocation } from 'react-router-dom';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { headerMenusStyles } from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import QuickUserToggler from '../../../../_metronic/layout/components/extras/QuiclUserToggler';
import NotificationDropdown from '../../../../_metronic/layout/components/extras/dropdowns/notifications/NotificationDropdown';
import Can from '../../../modules/Auth/Can';
import useAdobeDataLayer from '../../../sustell_15/analytics/adobeDataLayer';

export function MainMenu() {
  const classes = headerMenusStyles();
  const selectedCustomer = useSelector((state) => state.selectedCustomer);
  const intl = useIntl();
  const location = useLocation();
  const { ctaClickEvent } = useAdobeDataLayer();

  const isActive = (url) => {
    switch (url) {
      case '/homepage':
        return location.pathname === url;
      case url.match(/^\/customers\/.*\/myfeeds$/)?.input:
        return (
          location.pathname === url ||
          location.pathname.startsWith(
            `/customers/${selectedCustomer?.value?.id}/compoundfeeds`
          ) ||
          location.pathname.startsWith(`/feed-footprints`)
        );
      case url.match(/^\/customers\/.*\/myfarms$/)?.input:
        return (
          location.pathname === url ||
          location.pathname.startsWith(
            `/customers/${selectedCustomer?.value?.id}/farm`
          ) ||
          location.pathname.startsWith(`/footprints`)
        );
      case url.match(/^\/mydashboard/)?.input:
        return (
          location.pathname === url ||
          location.pathname.startsWith(`/mydashboard`)
        );
      default:
        return false;
    }
  };

  const urlActiveClass = (url) => (isActive(url) ? 'active' : null);

  return (
    <div className={`container ${classes.mainMenu}`}>
      {Can("access", "Management pages") && (
        <Link
          to="/homepage"
          onClick={() =>
            ctaClickEvent('/homepage', 'link', 'Home', 'Main Menu', 'Header')
          }
          className={`${classes.mainMenuItem} ${urlActiveClass('/homepage')}`}
        >
          {intl.formatMessage({ id: 'SUSTELL.HOME' })}
        </Link>
      )}
      {Can("access", "Management pages") && (
        <Link
          to={`/customers/${selectedCustomer?.value?.id}/myfarms`}
          onClick={() =>
            ctaClickEvent(
              `/customers/${selectedCustomer?.value?.id}/myfarms`,
              'link',
              'My Farms',
              'Main Menu',
              'Header'
            )
          }
          className={`${classes.mainMenuItem} ${urlActiveClass(
            `/customers/${selectedCustomer?.value?.id}/myfarms`
          )}`}
        >
          {intl.formatMessage({ id: 'SUSTELL.MY_FARMS' })}
        </Link>
      )}
      {Can("access", "Management pages") && (
        <Link
          to={`/customers/${selectedCustomer?.value?.id}/myfeeds`}
          onClick={() =>
            ctaClickEvent(
              `/customers/${selectedCustomer?.value?.id}/myfeeds`,
              'link',
              'My Feeds',
              'Main Menu',
              'Header'
            )
          }
          className={`${classes.mainMenuItem} ${urlActiveClass(
            `/customers/${selectedCustomer?.value?.id}/myfeeds`
          )}`}
        >
          {intl.formatMessage({ id: 'SUSTELL.MY_FEEDS' })}
        </Link>
      )}
      {Can('access', 'Dashboard') && (
        <Link
          to={`/mydashboard/overview`}
          onClick={() => ctaClickEvent(
            `/mydashboard/overview`,
            'link',
            'My Dashboard',
            'Main Menu',
            'Header'
          )}
          className={`${classes.mainMenuItem} ${urlActiveClass(
            '/mydashboard/overview'
          )}`}
        >
          {intl.formatMessage({ id: 'SUSTELL.MY_DASHBOARD' })}
        </Link>
      )}
      <NotificationDropdown />
      {(Can('access', 'Admin pages') || Can('read', 'Customer')) && (
        <Link
          to="/admin/customers"
          onClick={() =>
            ctaClickEvent(
              `/admin/customers`,
              'link',
              'My Admin',
              'Main Menu',
              'Header'
            )
          }
          className={classes.mainMenuItemIcon}
        >
          <SupervisorAccountIcon className={`${classes.mainMenuItemProfile}`} />
        </Link>
      )}
      <QuickUserToggler className={classes.mainMenuItemIcon} />
    </div>
  );
}
