import { v4 as uuidv4 } from 'uuid';
import { defaultUnitsV2 } from '../../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import {
  floatValueInUserUnits,
  mapCommonProductionDataIn,
  mapResourceUse,
  getUserTransportUnit,
  getDefaultTransportUnit,
} from './inCommonMapperSustellV2';
import { InputAnimalOrigin } from '../../../../../graphql/generated/blonk/shrimp';

const ResourceUseType = {
  Coal: 'coalUse',
  Diesel: 'dieselUse',
  ElectricityPhotovoltaic: 'electricityPhotovoltaicUse',
  ElectricityWind: 'electricityWindUse',
  Gasoline: 'gasolineUse',
  HeavyFuelOil: 'heavyFuelOilUse',
  PropaneOrLpg: 'propaneOrLpgUse',
};

const mapInputForOneStage = (inputBlockData, stageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const { stock, input } = stageData;

  inputBlockData.totalPondArea = stageData.pondProductionArea;
  inputBlockData.emptyPeriod = stageData.emptyPeriod;

  // stock
  if (stock?.weightAtStart || stock?.weightAtStart === 0)
    inputBlockData.totalWeightAtStartDate = floatValueInUserUnits(
      stock.weightAtStart,
      userUOM.unitOutputMass,
      defaultUnitsV2.unitOutputMass,
      outputMassPrecision
    );
  if (stock?.weightAtEnd || stock?.weightAtEnd === 0)
    inputBlockData.totalWeightAtEndDate = floatValueInUserUnits(
      stock.weightAtEnd,
      userUOM.unitOutputMass,
      defaultUnitsV2.unitOutputMass,
      outputMassPrecision
    );
  if (stock?.priceAtStart || stock?.priceAtStart === 0)
    inputBlockData.priceAtStartDate = stock.priceAtStart;
  if (stock?.priceAtEnd || stock?.priceAtEnd === 0)
    inputBlockData.priceAtEndDate = stock.priceAtEnd;

  // input - supply
  input?.forEach((item) => {
    if (item.origin === InputAnimalOrigin.ProductionSystem) {
      inputBlockData.selection.internalSelected = true;
      const inputData = {
        id: item.id || uuidv4(),
        totalWeight: floatValueInUserUnits(
          item.weight,
          userUOM.unitOutputMass,
          defaultUnitsV2.unitOutputMass,
          outputMassPrecision
        ),
        originBaselineId: item.originBaselineId,
        comingFromStage: item.originStageId,
        comingFromFarm: item.originFarmId,
        outputName: item.originOutputName,
      };

      if (
        item.transport?.[0]?.distance ||
        item.transport?.[0]?.distance === 0
      ) {
        inputData.distanceTraveled = floatValueInUserUnits(
          item.transport[0]?.distance,
          getUserTransportUnit(item.transport?.[0]?.mode),
          getDefaultTransportUnit(item.transport?.[0]?.mode)
        );
        inputData.transportMode = item.transport?.[0]?.mode;
      }

      inputBlockData.internalSources.push(inputData);
    }
    if (item.origin === InputAnimalOrigin.BackgroundData) {
      inputBlockData.selection.externalSelected = true;
      const externalSource = {
        id: item.id || uuidv4(),
        totalWeight: floatValueInUserUnits(
          item.weight,
          userUOM.unitOutputMass,
          defaultUnitsV2.unitOutputMass,
          outputMassPrecision
        ),
      };

      if (
        item.transport?.[0]?.distance ||
        item.transport?.[0]?.distance === 0
      ) {
        externalSource.distanceTraveled = floatValueInUserUnits(
          item.transport[0]?.distance,
          getUserTransportUnit(item.transport?.[0]?.mode),
          getDefaultTransportUnit(item.transport?.[0]?.mode)
        );
        externalSource.transportMode = item.transport?.[0]?.mode;
      }

      inputBlockData.externalSources.push(externalSource);
    }
  });
};

const mapOperationsForOneStage = (operationsBlockData, stageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const {
    chemicalsUse,
    resourceUse,
    pondWaterProperties,
    materialsUse,
    feedingProgram,
  } = stageData;
  const { fertilizers } = feedingProgram;

  // chemicalsUse
  chemicalsUse?.forEach((item) => {
    const chemicalType = {
      id: item.id || uuidv4(),
      amount: floatValueInUserUnits(
        item.amount,
        userUOM.unitBarnOutputMass,
        defaultUnitsV2.unitBarnOutputMass
      ),
      type: item.type,
    };
    operationsBlockData.chemicalTypes.push(chemicalType);
  });

  // resourceUse
  if (resourceUse?.electricityUse || resourceUse?.electricityUse === 0)
    operationsBlockData.resourceUse.electricityUse = floatValueInUserUnits(
      resourceUse.electricityUse,
      userUOM.unitElectricityUse,
      defaultUnitsV2.unitElectricityUse
    );
  if (resourceUse?.gasUse || resourceUse?.gasUse === 0)
    operationsBlockData.resourceUse.gasUse = floatValueInUserUnits(
      resourceUse.gasUse,
      userUOM.unitNaturalGasUse,
      defaultUnitsV2.unitNaturalGasUse
    );
  if (resourceUse?.waterUse || resourceUse?.waterUse === 0)
    operationsBlockData.resourceUse.waterUse = floatValueInUserUnits(
      resourceUse.waterUse,
      userUOM.unitWaterUse,
      defaultUnitsV2.unitWaterUse
    );

  if (resourceUse?.electricityPhotovoltaicUse) {
    const resourceItem = {
      type: ResourceUseType.ElectricityPhotovoltaic,
      value: floatValueInUserUnits(
        resourceUse.electricityPhotovoltaicUse,
        userUOM.unitElectricityUse,
        defaultUnitsV2.unitElectricityUse
      ),
    };
    operationsBlockData.resourceUse.selfGeneratedRenewables.push(resourceItem);
  }

  if (resourceUse?.electricityWindUse) {
    const resourceItem = {
      type: ResourceUseType.ElectricityWind,
      value: floatValueInUserUnits(
        resourceUse.electricityWindUse,
        userUOM.unitElectricityUse,
        defaultUnitsV2.unitElectricityUse
      ),
    };
    operationsBlockData.resourceUse.selfGeneratedRenewables.push(resourceItem);
  }

  if (resourceUse?.dieselUse) {
    const resourceItem = {
      type: ResourceUseType.Diesel,
      value: floatValueInUserUnits(
        resourceUse.dieselUse,
        userUOM.unitDieselUse,
        defaultUnitsV2.unitDieselUse
      ),
    };
    operationsBlockData.resourceUse.energyTypes.push(resourceItem);
  }

  if (resourceUse?.gasolineUse) {
    const resourceItem = {
      type: ResourceUseType.Gasoline,
      value: floatValueInUserUnits(
        resourceUse.gasolineUse,
        userUOM.unitGasolineUse,
        defaultUnitsV2.unitGasolineUse
      ),
    };
    operationsBlockData.resourceUse.energyTypes.push(resourceItem);
  }

  if (resourceUse?.heavyFuelOilUse) {
    const resourceItem = {
      type: ResourceUseType.HeavyFuelOil,
      value: floatValueInUserUnits(
        resourceUse.heavyFuelOilUse,
        userUOM.unitHeavyFuelOilUse,
        defaultUnitsV2.unitHeavyFuelOilUse
      ),
    };
    operationsBlockData.resourceUse.energyTypes.push(resourceItem);
  }

  if (resourceUse?.propaneOrLpgUse) {
    const resourceItem = {
      type: ResourceUseType.PropaneOrLpg,
      value: floatValueInUserUnits(
        resourceUse.propaneOrLpgUse,
        userUOM.unitNaturalGasUse,
        defaultUnitsV2.unitNaturalGasUse
      ),
    };
    operationsBlockData.resourceUse.energyTypes.push(resourceItem);
  }

  if (resourceUse?.coalUse) {
    const resourceItem = {
      type: ResourceUseType.Coal,
      value: floatValueInUserUnits(
        resourceUse.coalUse,
        userUOM.unitHeatUse,
        defaultUnitsV2.unitHeatUse
      ),
    };
    operationsBlockData.resourceUse.energyTypes.push(resourceItem);
  }

  // pondWaterProperties
  if (
    pondWaterProperties?.waterInAmount ||
    pondWaterProperties?.waterInAmount === 0
  )
    operationsBlockData.resourceUse.waterEnteringPondIn = floatValueInUserUnits(
      pondWaterProperties.waterInAmount,
      userUOM.unitWaterUse,
      defaultUnitsV2.unitWaterUse
    );
  if (
    pondWaterProperties?.waterInNitrogen ||
    pondWaterProperties?.waterInNitrogen === 0
  )
    operationsBlockData.resourceUse.nitrogenConcentrationIn =
      pondWaterProperties.waterInNitrogen;
  if (
    pondWaterProperties?.waterOutAmount ||
    pondWaterProperties?.waterOutAmount === 0
  )
    operationsBlockData.resourceUse.waterEnteringPondOut =
      floatValueInUserUnits(
        pondWaterProperties.waterOutAmount,
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );
  if (
    pondWaterProperties?.waterOutNitrogen ||
    pondWaterProperties?.waterOutNitrogen === 0
  )
    operationsBlockData.resourceUse.nitrogenConcentrationOut =
      pondWaterProperties.waterOutNitrogen;
  if (pondWaterProperties?.salinity)
    operationsBlockData.resourceUse.watersalinity =
      pondWaterProperties.salinity;

  // ice
  operationsBlockData.iceAmount = floatValueInUserUnits(
    stageData.ice,
    userUOM.unitBarnOutputMass,
    defaultUnitsV2.unitBarnOutputMass
  );

  // materialsUse
  materialsUse?.forEach((item) => {
    if (item && item.type) {
      const materialType = {
        id: item.id || uuidv4(),
        type: item.type,
        amount: floatValueInUserUnits(
          item.amount,
          userUOM.unitBarnOutputMass,
          defaultUnitsV2.unitBarnOutputMass
        ),
        lifetimeOfMaterial: item.lifeTime,
      };

      if (
        item.transport?.[0]?.distance ||
        item.transport?.[0]?.distance === 0
      ) {
        materialType.distanceTraveled = floatValueInUserUnits(
          item.transport[0]?.distance,
          getUserTransportUnit(item.transport?.[0]?.mode),
          getDefaultTransportUnit(item.transport?.[0]?.mode)
        );
        materialType.transportMode = item.transport?.[0]?.mode;
      }

      operationsBlockData.materialTypes.push(materialType);
    }
  });

  // fertilizers
  fertilizers?.forEach((item) => {
    if (item.type) {
      const fertilizerType = {
        id: item.id || uuidv4(),
        amount: floatValueInUserUnits(
          item.quantity,
          userUOM.unitBarnOutputMass,
          defaultUnitsV2.unitBarnOutputMass
        ),
        type: item.type,
      };
      operationsBlockData.fertilizerTypes.push(fertilizerType);
    }
  });
};

const mapOutputForOneStage = (outputBlockData, stageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { output } = stageData;

  output?.forEach((item) => {
    const outputData = {
      id: item.id || uuidv4(),
      totalWeight: floatValueInUserUnits(
        item.weight,
        userUOM.unitBarnOutputMass,
        defaultUnitsV2.unitBarnOutputMass
      ),
      calculateLca: item.calculateLca,
      name: item.name,
      price: item.price || item.price === 0 ? item.price : undefined,
      soldExternally: item.soldExternally ? 'Yes' : 'No',
      proteinContent: item.proteinContent,
    };
    outputBlockData.outputs.push(outputData);
  });
};

const mapShrimpProductionDataInSustell = (inData, isDraft = false) => {
  const formData = mapCommonProductionDataIn(inData, isDraft);
  formData.resourceUse = {};
  mapResourceUse(inData.resourceUse, formData.resourceUse, [
    'electricityUse',
    'gasUse',
    'waterUse',
  ]);

  if (inData.stages && Array.isArray(inData.stages))
    inData.stages.forEach((stage, index) => {
      const stageFormData = formData.stages[index]?.stageData;
      if (stageFormData) {
        const stageData = JSON.parse(stage.stageData);

        stageFormData.input.internalSources = [];
        stageFormData.input.externalSources = [];
        stageFormData.input.selection = {
          internalSelected: false,
          externalSelected: false,
        };
        stageFormData.input.stageDuration = stage.stageDuration;
        if (!stageFormData.operations) stageFormData.operations = {};
        stageFormData.operations.chemicalTypes = [];
        stageFormData.operations.resourceUse = {};
        stageFormData.operations.resourceUse.selfGeneratedRenewables = [];
        stageFormData.operations.resourceUse.energyTypes = [];
        stageFormData.operations.materialTypes = [];
        stageFormData.operations.fertilizerTypes = [];
        if (!stageFormData.output) stageFormData.output = {};
        stageFormData.output.outputs = [];

        mapInputForOneStage(stageFormData.input, stageData);
        mapOperationsForOneStage(stageFormData.operations, stageData);
        mapOutputForOneStage(stageFormData.output, stageData);
      }
    });

  return formData;
};

export default mapShrimpProductionDataInSustell;
