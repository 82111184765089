import { CompoundFeed, CompoundFeedV2, FootPrintType, Maybe } from "../../../../graphql/types";
import { FootprintModel } from "../Footprint";
import { FootprintProps } from "../Footprint/Footprint";
import { FootprintCategoryName } from "../Footprint/internal";
import { DashboardModel } from "./internal";
import {  DashboardFilterData, DatasetInfo, AnalysisGroupsFarm, DashboardType, AnalysisGroupsFeed, DistributionByAnalysisGroups, DashboardImpactType } from "./DashboardTypes";


/** Container of footprints that will be presented  */
class DashboardFootprintModel extends FootprintModel {

  readonly filterData: DashboardFilterData;

  readonly #additionalData: DatasetInfo | CompoundFeed | CompoundFeedV2;

  readonly totalOutputInKg: number | undefined | null;

  #dashboard: DashboardModel;

  constructor(options: FootprintProps, filterData: DashboardFilterData, additionalData: DatasetInfo | CompoundFeed | CompoundFeedV2) {
    super(options);
    this.totalOutputInKg = options?.total_output;
    this.#additionalData = additionalData;
    this.filterData = filterData;
  }

  get longName() {
    if (this.#dashboard.type === DashboardType.Feeds) {
      return this.name;
    } 
    const farmName = (this.#additionalData as DatasetInfo)?.farm?.name || '';
    return `${farmName} - ${this.name}`; 
  }

  get databaseFoundation() {
    if (this.type !== FootPrintType.CompoundFeed) {
      return undefined;
    }
    return this.filterData.databaseFoundation[0].id;
  }

  get additionalData () {
      return this.#additionalData;    
  }

  get additionalFeedsData() {
    return this.#additionalData as CompoundFeed;
  }

  get isSimulation() {
    return this.#dashboard.simulation.references.indexOf(this.reference) > -1 
       || ( this.#dashboard.simulation.references.length === 0 
            && this.#dashboard.simulation.simulations.length > 0
            && this.#dashboard.simulation.simulations.some(sim => sim.change !== 0 )
      );
  }

  /**
   * 
   * @param dashboard which will contain global data (per dashboard) used for filtering, sorting etc footprint values
   */
  setDashboard(dashboard: DashboardModel) {
    this.#dashboard = dashboard;
  }

  getDistributionByImpactCateg (key :FootprintCategoryName) {
    let totalKgMultiplier = 1
    if ((this.type !== FootPrintType.CompoundFeed) 
      && this.#dashboard.impactType === DashboardImpactType.Absolute 
      && this.totalOutputInKg && this.totalOutputInKg !== null
      && this.totalOutputInKg > 0) {
      totalKgMultiplier = this.totalOutputInKg;
    }

    if (!this.isSimulation) {
      return this.categories.find(c => c.key === key)?.distribution.map(el => ({
        ...el,
        amount: (el.amount || 0) * totalKgMultiplier}));
    }
    
    
    return this.categories.find(c => c.key === key)
                  ?.distribution
                  .map(d => {
      let { amount } = d;
        const change = this.#dashboard
                          .simulation
                          .simulations
                          .find(sim => sim.analysisGroup === d.analysisGroup);

        if (change && amount) {
          amount += (amount * (change?.change || 0)) / 100;
        }
        amount = (amount || 0) * totalKgMultiplier;
      return {
        analysisGroup: d.analysisGroup,
        percentage: d.percentage,
        amount
      }
    }) || [];
  }

  /**
   * For current impact category returns distribution values
   * with applied simulation for Feed/Farm footprints
   */
  get distributionByAnalysisGroups (): DistributionByAnalysisGroups {
    const retVal: DistributionByAnalysisGroups = {
      Resources: 0,
      [AnalysisGroupsFeed.Ingredients]: 0,
      [AnalysisGroupsFeed.Transport]: 0,
      [AnalysisGroupsFarm.Ration]: 0,
      [AnalysisGroupsFarm.Farm]: 0,
      [AnalysisGroupsFarm.PurchasedAnimals]: 0,
      [AnalysisGroupsFarm.AuxiliaryInputs]: 0,
      [AnalysisGroupsFarm.Processing]: 0,
      Total: 0
    };

    const groups = this.#dashboard.type === DashboardType.Farm ? AnalysisGroupsFarm : AnalysisGroupsFeed;

    const distWithSimulation = this.getDistributionByImpactCateg(this.#dashboard.activeImpactCategory.key);
    let total = 0;
    Object.values(groups).forEach(analysisGroup => {
      const value = distWithSimulation?.find(d => d.analysisGroup === analysisGroup)?.amount || 0;
      retVal[analysisGroup as keyof DistributionByAnalysisGroups] = value ;
      total += value;
    });
    retVal['Total' as const] = total;
    // console.log(retVal)
    return retVal;
  }

  get dateRange(): {start: Maybe<Date> | undefined, end: Maybe<Date> | undefined} {
    if (this.type === FootPrintType.CompoundFeed) {
      // For cf we have only year
      const {year} = this.#additionalData as CompoundFeed | CompoundFeedV2;
      const date = new Date(Number(year), 0);
      return {
        start: date,
        end: date
      }
    } 
    // Both baseline and all interventions have same valid dates
    const baseline = (this.#additionalData as DatasetInfo).activeBaseline;
    return {
      start: baseline?.validFrom,
      end: baseline?.validTo
    }
  }


}

export default DashboardFootprintModel;