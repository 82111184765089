/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from 'react';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { Box } from '@material-ui/core';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';



const ClimateMapHolder = ({
  formVisible,
  handleSave,
  handleClose,
  setZoneInfo
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  
  const [selZone, setSelZone]=useState('');
  const preview = useRef();

  const saveLocation = () =>{
    handleSave(selZone);
  }

  const onSelectedColor = (rgb) => {

    const clickedZone = setZoneInfo(rgb[0],rgb[1],rgb[2]);
    if(clickedZone){
      preview.current.style.backgroundColor = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
      setSelZone(clickedZone);
    }else{
      preview.current.style.backgroundColor = `rgb(255,255,255)`;
      setSelZone('');
    }
    
  }

  return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {formVisible &&
        <>
        <Box>
      {intl.formatMessage({ id: "SUSTELL.GEOGRAPHY.SELECTED.CLIMATE_ZONE" })}: <Box ref={preview} style={{ minWidth: '20px', display: 'inline-block' }}>{selZone}</Box>
        </Box><MapHolder onSelectedColor={onSelectedColor} /><DsmButtonControlGroup
            cancelHandler={handleClose}
            fullWidth
            saveHandler={selZone ? saveLocation : undefined}
            saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })} />
        </>
        }  
      </>
  );
};

const MapHolder = ({onSelectedColor}) => {
  const canvas  = useRef();
  const zoomIn  = useRef();
  const zoomOut = useRef();

  useEffect(()=>{
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    canvas.current.style.cursor = 'crosshair';
    canvas.current.style.overflow = 'scroll';
    canvas.current.style.transformOrigin = 'top left';
    const startScale = 1; //608/1739;
    const imgWidth = 3540;
    const imgHeight = 1739;
    const width = 1232;
    const height = 608;

    let scale = startScale;

    const ctx = canvas.current.getContext('2d'); 
    const i = new Image();

    i.src = toAbsoluteUrl('/media/WorldZones.png');
    i.onload = () => {
      ctx.drawImage(i, 0, 0,imgWidth,imgHeight,0,0,width,height);
    }

    zoomIn.current.addEventListener('click', () => {
      console.log("zoomIn called")
      scale += .1;
      canvas.current.style.scale=scale;

    });

    zoomOut.current.addEventListener('click', () => {
      if(scale-0.1>startScale)
        scale -= 0.1;
      else
        scale = startScale;
      canvas.current.style.scale=scale;
    });

    const pickUpColor = (e)=>{
      const viewportOffset = canvas.current.getBoundingClientRect();
      const { top, left } = viewportOffset;
      const mousePos = { x: e.clientX-left, y: e.clientY-top };
      // console.log(top, left, mousePos.x, mousePos.y);
      const pixel = ctx.getImageData(mousePos.x/scale,mousePos.y/scale,1,1);
      const r = pixel.data[0];
      const g = pixel.data[1];
      const b = pixel.data[2];
      return [r,g,b]
    }
    
    canvas.current.addEventListener('click', (e)=>{
      console.log(window.visualViewport.scale);
      const rgb = pickUpColor(e);
      onSelectedColor(rgb);
    })
  },[]);

  return (
    <Box style={{position: 'relative'}}>
      <Box style={{position: 'relative', overflow:'scroll'}}>
        <canvas
          ref={canvas}
          width="1232" height="608"
          style={{ background: '#CFCFCF'}}
        />
      </Box>
      <Box style={{ display:'flex', flexDirection:'column'}}>
        <DsmButton ref={zoomIn} variant='text' >
          <DsmIcon name='general/plus-square' />
        </DsmButton>
        <Box/>
        <DsmButton ref={zoomOut} variant='text' >
          <DsmIcon name='general/minus-square'/>
        </DsmButton>      
      </Box>
    </Box>
  );
};

export default ClimateMapHolder;