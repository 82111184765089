import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, PoultryFarms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import {
  PoultryBaseline,
  PoultryInputBreeding,
} from '../../../../models/Baseline/PoultryBaseline';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import PoultryInputPartExternalSource from './PoultryInputPartExternalSource';
import PoultryInputPartInternalSource from './PoultryInputPartInternalSource';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
  marginTop: '10px',
  cursor: 'pointer',
};

const headingRadioButtonTitle = {
  ...headingTitle,
  marginLeft: '10px',
};

export interface PoultryInputDialogProps extends BaselineDialogProps {
  farms: PoultryFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
  animalType: string;
  validate: (param?: string | null) => void;
}

const PoultryInputBreedingDialog: FC<PoultryInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  animalType,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  validate,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<PoultryInputBreeding>();
  const fc = useFormContext<PoultryBaseline>();
  const [henSourceSelected, setHenSourceSelected] = useState<boolean>(true);
  const [roosterSourceSelected, setRoosterSourceSelected] =
    useState<boolean>(true);
  const henInitialSourceSelected = useRef<boolean>(true);
  const roosterInitialSourceSelected = useRef<boolean>(true);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.input) {
        resetObject.stages[itemIndex].stageData.input = {
          ...currResetValue.current,
        } as PoultryInputBreeding;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
    if (henInitialSourceSelected.current)
      fc.setValue(`${fieldItemPrefix}.henExternalSource`, null);
    else fc.setValue(`${fieldItemPrefix}.henInternalSource`, null);
    if (roosterInitialSourceSelected.current)
      fc.setValue(`${fieldItemPrefix}.roosterExternalSource`, null);
    else fc.setValue(`${fieldItemPrefix}.roosterInternalSource`, null);
  };

  const handleSaveClick = async () => {
    handleCancel();
    await fc.trigger(fieldItemPrefix);
    if (henSourceSelected) {
      fc.setValue(`${fieldItemPrefix}.henExternalSource`, null);
      fc.clearErrors(`${fieldItemPrefix}.henExternalSource`);
    } else {
      fc.setValue(`${fieldItemPrefix}.henInternalSource`, null);
      fc.clearErrors(`${fieldItemPrefix}.henInternalSource`);
    }
    if (roosterSourceSelected) {
      fc.setValue(`${fieldItemPrefix}.roosterExternalSource`, null);
      fc.clearErrors(`${fieldItemPrefix}.roosterExternalSource`);
    } else {
      fc.setValue(`${fieldItemPrefix}.roosterInternalSource`, null);
      fc.clearErrors(`${fieldItemPrefix}.roosterInternalSource`);
    }
    validate('confirm');
  };

  useEffect(() => {
    if (formVisible) {
      const henIntSelected: boolean = fc.getValues(
        `${fieldItemPrefix}.henSelected`
      );
      const roosterIntSelected: boolean = fc.getValues(
        `${fieldItemPrefix}.roosterSelected`
      );
      if (henIntSelected === false) {
        // to go to else if null or undefined
        setHenSourceSelected(false);
        henInitialSourceSelected.current = false;
        fc.setValue(`${fieldItemPrefix}.henInternalSource`, {
          farmId: '',
          originStageId: '',
          numberAnimals: '',
          transportDistance: '',
        });
      } else {
        setHenSourceSelected(true);
        henInitialSourceSelected.current = true;
        fc.setValue(`${fieldItemPrefix}.henExternalSource`, {
          numberAnimals: '',
          averageWeight: '',
          transportDistance: '',
        });
      }
      if (roosterIntSelected === false) {
        setRoosterSourceSelected(false);
        roosterInitialSourceSelected.current = false;
        fc.setValue(`${fieldItemPrefix}.roosterInternalSource`, {
          farmId: '',
          originStageId: '',
          numberAnimals: '',
          transportDistance: '',
        });
      } else {
        setRoosterSourceSelected(true);
        roosterInitialSourceSelected.current = true;
        fc.setValue(`${fieldItemPrefix}.roosterExternalSource`, {
          numberAnimals: '',
          averageWeight: '',
          transportDistance: '',
        });
      }
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
      })}
      variant="wide"
      introText={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.BREEDING_DESCRIPTION',
      })}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.START_DATE',
          })}
          tooltip={intl.formatMessage(
            { id: 'SUSTELL.STAGE.POULTRY.INPUT.START_DATE.TOOLTIP' },
            { stageType: 'breeding' }
          )}
          disabled={formType === FormType.View}
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.startDate`)}
        />
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.END_DATE',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage(
            { id: 'SUSTELL.STAGE.POULTRY.INPUT.END_DATE.TOOLTIP' },
            { stageType: 'breeding' }
          )}
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.endDate`)}
        />
      </DsmGrid>
      <p style={headingTitle}>
        {intl.formatMessage({ id: 'SUSTELL.STAGE.POULTRY.INPUT.HEN_TITLE' })}
      </p>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <input
              type="radio"
              name={`${fieldItemPrefix}.henSelected`}
              id="henInternalSource"
              disabled={formType === FormType.View}
              defaultChecked={henSourceSelected}
              onClick={() => {
                fc.setValue(`${fieldItemPrefix}.henSelected`, true);
                setHenSourceSelected(true);
                fc.clearErrors(`${fieldItemPrefix}.henExternalSource`);
              }}
            />
            <label htmlFor="henInternalSource" style={headingRadioButtonTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            <PoultryInputPartInternalSource
              farms={farms}
              farmId={farmId}
              farmName={farmName}
              allowedStagesForFarm={[StageType.Growing]}
              productionProcessName={productionProcessName}
              stageIndex={itemIndex}
              propName="henInternalSource"
              stageType={StageType.Breeding}
              intlPrefix={`${StageType.Breeding}.HEN`}
              formType={formType}
              sourceSelected={henSourceSelected}
              animalType={animalType}
            />
          </div>
        </div>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <input
              type="radio"
              name={`${fieldItemPrefix}.henSelected`}
              id="henExternalSource"
              disabled={formType === FormType.View}
              defaultChecked={!henSourceSelected}
              onClick={() => {
                fc.setValue(`${fieldItemPrefix}.henSelected`, false);
                setHenSourceSelected(false);
                fc.clearErrors(`${fieldItemPrefix}.henInternalSource`);
              }}
            />
            <label htmlFor="henExternalSource" style={headingRadioButtonTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            <PoultryInputPartExternalSource
              stageIndex={itemIndex}
              propName="henExternalSource"
              intlPrefix={`${StageType.Breeding}.HEN`}
              formType={formType}
              sourceSelected={!henSourceSelected}
              stageType={StageType.Breeding}
            />
          </div>
        </div>
      </DsmGrid>
      <p style={headingTitle}>
        {intl.formatMessage({
          id: 'SUSTELL.STAGE.POULTRY.INPUT.ROOSTER_TITLE',
        })}
      </p>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <input
              type="radio"
              name={`${fieldItemPrefix}.roosterSelected`}
              id="roosterInternalSource"
              disabled={formType === FormType.View}
              defaultChecked={roosterSourceSelected}
              onClick={() => {
                fc.setValue(`${fieldItemPrefix}.roosterSelected`, true);
                setRoosterSourceSelected(true);
                fc.clearErrors(`${fieldItemPrefix}.roosterExternalSource`);
              }}
            />
            <label
              htmlFor="roosterInternalSource"
              style={headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            <PoultryInputPartInternalSource
              farms={farms}
              farmId={farmId}
              farmName={farmName}
              allowedStagesForFarm={[StageType.Growing]}
              productionProcessName={productionProcessName}
              stageIndex={itemIndex}
              propName="roosterInternalSource"
              stageType={StageType.Breeding}
              intlPrefix={`${StageType.Breeding}.ROOSTER`}
              formType={formType}
              sourceSelected={roosterSourceSelected}
              animalType={animalType}
            />
          </div>
        </div>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <input
              type="radio"
              name={`${fieldItemPrefix}.roosterSelected`}
              id="roosterExternalSource"
              disabled={formType === FormType.View}
              defaultChecked={!roosterSourceSelected}
              onClick={() => {
                fc.setValue(`${fieldItemPrefix}.roosterSelected`, false);
                setRoosterSourceSelected(false);
                fc.clearErrors(`${fieldItemPrefix}.roosterInternalSource`);
              }}
            />
            <label
              htmlFor="roosterExternalSource"
              style={headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            <PoultryInputPartExternalSource
              stageIndex={itemIndex}
              propName="roosterExternalSource"
              intlPrefix={`${StageType.Breeding}.ROOSTER`}
              formType={formType}
              sourceSelected={!roosterSourceSelected}
              stageType={StageType.Breeding}
            />
          </div>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => handleSaveClick()}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryInputBreedingDialog;
