import lookupValues from "../../../../../sustell_15/helpers/lookupValues";
import {
  BaselineFeed,
  BaselineFeedWithOrigin,
} from "../../../../../sustell_15/models/Baseline";
import {
  BeefBreedingStageData,
  BeefGrowingStageData,
  BeefInputBreeding,
} from "../../../../../sustell_15/models/Baseline/BeefBaseline";
import {
  CattleBeddingSystem,
  CattleEmission,
  CattleFeed,
  CattleHousingAndManure,
  CattleManureManagementSystem,
  ExternalSource,
  FreshGrass,
  InternalSource,
  MothersMilk,
} from "../../../../../sustell_15/models/Baseline/CattleBaseline";
import {
  DairyV2DairyCowStageData,
  DairyV2InputContinuousGrowing,
  DairyV2Input,
  DairyV2ContinuousGrowingStageData,
} from "../../../../../sustell_15/models/Baseline/DairyV2Baseline";
import {
  CattleAnimalInputDBModel,
  CattleBeddingDBModel,
  CattleFeedingProgramDBModel,
  CattleManureManagementSystemDBModel,
  CattleStockChangeDBModel,
  CompoundFeedDatabase,
  CompoundFeedInfoDBModel,
  EmissionMitigationDBModel,
  FeedingProgramCalfDBModel,
  FreshGrassDBModel,
  MothersMilkDBModel,
  SingleIngredientUseDBModel,
  TimeSpentDBModel,
} from "../../../../../sustell_15/models/TempBackendModels/TempCattleDB";
import {
  formatMassInput,
  formatTerrestrialTransportInput,
} from "../outMapperHelpers";

function mapFreshGrass(
  incomingData?: Array<FreshGrass>
): Array<FreshGrassDBModel> {
  if (!incomingData?.length) return [];

  const mappedFreshGrass = incomingData
    ?.filter(
      (freshGrass) => Boolean(freshGrass.type) && Boolean(freshGrass.amount)
    )
    .map(
      (freshGrass: FreshGrass) =>
        ({
          type: String(freshGrass.type),
          amount: formatMassInput(Number(freshGrass.amount)),
          crudeProteinContent: Number(freshGrass.crudeProteinContent),
          dryMatterContent: Number(freshGrass.dryMatterContent),
          digestibility: Number(freshGrass.digestibility),
          grossEnergyContent: freshGrass.grossEnergyContent
            ? Number(freshGrass.grossEnergyContent)
            : undefined,
          urinaryEnergyContent: Number(
            lookupValues.compoundFeedNutrientsDefault["Beef system"]
              .urinaryEnergyContent
          ),
          ashContent: freshGrass.ashContent
            ? Number(freshGrass.ashContent)
            : undefined,
          neutralDetergentFiber: freshGrass.neutralDetergentFiber
            ? Number(freshGrass.neutralDetergentFiber)
            : undefined,
        } as FreshGrassDBModel)
    );

  return mappedFreshGrass;
}

export function mapCompoundFeeds(
  incomingData?: Array<BaselineFeed> | null
): Array<CompoundFeedInfoDBModel> {
  if (!incomingData || !incomingData.length) {
    return [];
  }

  return incomingData
    .filter(
      (compoundFeed) =>
        Boolean(compoundFeed?.feedType) && Number(compoundFeed?.kgPerAnimal) > 0
    )
    ?.map(
      (compoundFeed) =>
        ({
          quantity: formatMassInput(compoundFeed.kgPerAnimal),
          compoundFeedId: compoundFeed.feedType,
        } as CompoundFeedInfoDBModel)
    );
}

function mapSingleIngredients(
  incomingData?: Array<BaselineFeedWithOrigin>
): Array<SingleIngredientUseDBModel> {
  if (!incomingData || !incomingData.length) {
    return [];
  }

  return incomingData
    .filter(
      (singleIngredient) =>
        Boolean(singleIngredient?.feedType) &&
        Number(singleIngredient?.kgPerAnimal) > 0
    )
    .map((singleIngredient) => ({
      quantity: formatMassInput(singleIngredient.kgPerAnimal),
      silage: singleIngredient?.isSilage || false,
      ingredient: {
        id: singleIngredient.id,
        name: singleIngredient.feedType,
        origin: singleIngredient.origin,
      },
    }));
}

export function mapFeedingProgram(
  incomingData: CattleFeed
): CattleFeedingProgramDBModel {
  return {
    databaseFoundation: incomingData?.databaseFoundation
      ? (incomingData?.databaseFoundation as CompoundFeedDatabase)
      : ("" as CompoundFeedDatabase),
    dietCharacterisation: incomingData?.dietCharacterisation,
    freshGrass: mapFreshGrass(incomingData?.freshGrass),
    compoundFeeds: mapCompoundFeeds(incomingData?.compoundFeeds),
    singleIngredients: mapSingleIngredients(incomingData?.singleIngredients),
  };
}

export function mapMothersMilk(incomingData?: MothersMilk): MothersMilkDBModel {
  return {
    amount: incomingData?.amount
      ? formatMassInput(Number(incomingData?.amount))
      : null,
    crudeProteinContent: Number(incomingData?.crudeProteinContent),
    digestibility: Number(incomingData?.digestibility),
    dryMatterContent: Number(incomingData?.dryMatterContent),
  };
}

export function mapFeedingProgramWithMothersMilk(
  incomingData: CattleFeed
): FeedingProgramCalfDBModel {
  return {
    ...mapFeedingProgram(incomingData),
    averageMilkFat: incomingData?.averageMilkFat
      ? Number(incomingData.averageMilkFat)
      : undefined,
    mothersMilk: mapMothersMilk(incomingData?.mothersMilk),
  };
}

export function mapCattleBeddingSystems(
  beddingSystem?: Array<CattleBeddingSystem>
): Array<CattleBeddingDBModel> | undefined {
  if (!beddingSystem || !beddingSystem.length) return [];

  const mappedBeddingSystems = beddingSystem.reduce(
    (mappedSystems: Array<CattleBeddingDBModel>, beddingSystem) => {
      const { beddingAmount, beddingType } = beddingSystem;

      if (!beddingAmount || Number.isNaN(beddingAmount)) return mappedSystems;

      return [
        ...mappedSystems,
        { type: beddingType, amount: formatMassInput(beddingAmount) },
      ] as CattleBeddingDBModel[];
    },
    []
  );

  return mappedBeddingSystems.length ? mappedBeddingSystems : undefined;
}

export function mapCattleTimeSpend(
  incomingData: CattleHousingAndManure
): TimeSpentDBModel {
  return {
    timeInFeedlot: Number(incomingData.timeInFeedlot),
    timeInHousing: Number(incomingData.timeInHousing),
    timeOnLargeGrazingAreas: Number(incomingData.timeGrazingLargeAreas),
    timeOnSmallGrazingAreas: Number(incomingData.timeGrazingPastures),
    timeInHousingTied: incomingData.timeInHousingTied ? Number(incomingData.timeInHousingTied) : 0,
  };
}

export function mapCattleEmissionsAndMitigations(
  incomingEmissions?: CattleEmission
): Array<EmissionMitigationDBModel> {
  if (!incomingEmissions) return [];
  const entries = Object.entries(incomingEmissions);
  if (!entries.length) return [];

  return entries.reduce(
    (acc: Array<EmissionMitigationDBModel>, [key, value]) => {
      if (!value || Number.isNaN(value)) return acc;

      return [
        ...acc,
        {
          emissionType: key,
          change: parseFloat(value as string),
        },
      ];
    },
    []
  );
}

export const mapCattleMmsToDBModel = (
  mmsSystem: CattleManureManagementSystem
): CattleManureManagementSystemDBModel => {
  const storagePeriod = mmsSystem?.storagePeriod as string;
  const mmsShare = mmsSystem.share;
  const type = mmsSystem.mmsType;
  const phase = mmsSystem.manurePhase;

  const baseMms = phase
    ? {
        id: mmsSystem.id,
        share: Number(mmsShare),
        mmsViewModelType: type,
        manurePhase: phase,
      }
    : {
        id: mmsSystem.id,
        share: Number(mmsShare),
        mmsViewModelType: type,
      };

  if (storagePeriod) {
    // TODO - Move this logic to Blonk Mapper when refactoring data model  to domain
    const formatedStoragePeriod = type.toUpperCase().includes("DEEP_BEDDING")
      ? storagePeriod.replace(/ /, "_").replace(/ /, "")
      : storagePeriod.replace(/ /g, "");

    return {
      ...baseMms,
      mmsType: `${type}_${formatedStoragePeriod.toUpperCase()}`,
      storagePeriod,
    } as CattleManureManagementSystemDBModel;
  }

  return {
    ...baseMms,
    mmsType: type.toUpperCase(),
  } as CattleManureManagementSystemDBModel;
};

export function mapCattleManureSystems(
  incomingData: Array<CattleManureManagementSystem>
): Array<CattleManureManagementSystemDBModel> {
  if (!incomingData || !incomingData.length) return [];

  return incomingData.filter((mms) => !!mms.mmsType).map(mapCattleMmsToDBModel);
}

export function mapCattleStockChange(
  incomingData:
    | BeefInputBreeding
    | DairyV2Input
    | DairyV2InputContinuousGrowing
): CattleStockChangeDBModel {
  const commonStockData = {
    averageWeightCowsStart: formatMassInput(incomingData.averageWeightAtStart),
    cowsPresentAtEnd: Number(incomingData.animalsPresentAtEnd),
    cowsPresentAtStart: Number(incomingData.animalsPresentAtStart),
  };

  if ("averageWeightAtEnd" in incomingData) {
    const averageWeightAtEnd = Number(incomingData.averageWeightAtEnd);
    return {
      ...commonStockData,
      averageWeightCowsEnd: averageWeightAtEnd > 0 ? averageWeightAtEnd : undefined,
    };
  }

  return commonStockData;
}

export function mapInternalSource(
  incomingData: Array<InternalSource>
): Array<CattleAnimalInputDBModel> {
  const internalSources: Array<CattleAnimalInputDBModel> = [];

  if (!incomingData) return internalSources;

  incomingData?.forEach((internalSource) => {
    if (
      internalSource &&
      internalSource.numberAnimals &&
      Number(internalSource.numberAnimals) > 0
    ) {
      internalSources.push({
        id: String(internalSource.id),
        origin: "PRODUCTION_SYSTEM",
        baselineRef: String(internalSource.baselineRef),
        originStageId: String(internalSource.originStageId),
        originStageName: String(internalSource.stageName),
        originFarmName: String(internalSource.farmName),
        originFarmId: String(internalSource.farmId),
        stageType: String(internalSource.stageType),
        animalType: internalSource.animalType === "CALF" ? "CALF" : "COW",
        numberOfAnimals: Number(internalSource.numberAnimals) || 0,
        shrinkingRate: Number(internalSource?.shrinkingRate) || undefined,
        transportDistance: internalSource.distanceTruck
          ? formatTerrestrialTransportInput(internalSource.distanceTruck)
          : undefined,
      });
    }
  });

  return internalSources;
}

export function mapExternalSource(
  incomingData: Array<ExternalSource>
): Array<CattleAnimalInputDBModel> {
  const externalSources: Array<CattleAnimalInputDBModel> = [];

  if (!incomingData) return externalSources;

  incomingData.forEach((externalSource) => {
    if (
      externalSource &&
      externalSource.numberAnimals &&
      Number(externalSource.numberAnimals) > 0
    ) {
      externalSources.push({
        id: String(externalSource.id),
        origin: "BACKGROUND_DATA",
        numberOfAnimals: Number(externalSource.numberAnimals),
        transportDistance: externalSource.distanceTruck
          ? formatTerrestrialTransportInput(externalSource.distanceTruck)
          : undefined,
        shrinkingRate: Number(externalSource?.shrinkingRate) || undefined,
      });
    }
  });

  return externalSources;
}

export function getStartDateFromStage(
  incomingData:
    | BeefGrowingStageData
    | BeefBreedingStageData
    | DairyV2DairyCowStageData
    | DairyV2ContinuousGrowingStageData
) {
  return incomingData.input.startDate ? 
    incomingData.input.startDate as string 
    : undefined;
}
