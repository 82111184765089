import { IntlShape } from "react-intl";
import { dashToSpacesStringLowerCase } from "./string-utils";
import { listEntryDataSorter } from "../helpers/sortingFunctions";

const apply = (obj: object, path: string) => {
  let s = path;
  let o = obj;
  s = s.replace(/\[(\w+)]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  let i = 0;
  const n = a.length;
  for (; i < n; i+= 1) {
    const k = a[i];
    if (k in o) {
      o = o[k as keyof typeof o];
    } else {
      return null;
    }
  }
  return o;
};

export const getObjectPropByPath = (obj: object, path: string) =>
  apply(obj, path);

  
export const flattenObject = (obj: object | null | undefined, parentKey?:string) => {
  let result : {[k: string]: string}= {};
  if (obj) {
    Object.entries(obj).forEach(([key, value]: [string, object]) => {
      // const value = obj[key];
      const objKey = parentKey ? `${parentKey  }.${  key}` : key;
      if (typeof value === 'object') {
        result = { ...result, ...flattenObject(value, objKey) };
      } else {
        result[objKey] = value;
      }
    });
  }
  return result;
};

export const enumToOptionsArray = (obj: object | null | undefined): {text:string, value: string}[] => {
  const result : {text:string, value: string}[] = [];
  if (obj) {
    Object.entries(obj).forEach(([key, value]: [string, string]) => {
      // const value = obj[key];
      result.push({text:key,value: value || key})
    });
  }
  return result;
}

export const enumToOptionsArrayWithTranslatedStrings = (obj: object | null | undefined, intl:IntlShape, prefixString: string | undefined): {text:string, value: string}[] => {
  // used for enumerations generated from graphQL - value usually holds upper case key, e.g. LIQUID_COVER_1MONTH
  const result : {text:string, value: string}[] = [];
  if (obj) {
    Object.entries(obj).forEach(([key, value]: [string, string]) => {
      const tranlationKey = value || key;
      const translationId = `${prefixString || ''}.${tranlationKey.toUpperCase()}`;
      result.push({value:value || key, text: intl.formatMessage({id:translationId, defaultMessage: dashToSpacesStringLowerCase(value || key) })})
    });
  }
  return result.sort(listEntryDataSorter);
}


export const objectToOptionsArrayWithTranslatedStrings = (obj: object | null | undefined, intl:IntlShape, prefixString: string | undefined): {text:string, value: string}[] => {
  // object property name is used as key to lookup transaltion
  // used for iso codes - country name translation
  const result : {text:string, value: string}[] = [];
  if (obj) {
    Object.entries(obj).forEach(([key, value]: [string, string]) => {
      const translationId = `${prefixString || ''}.${key.toUpperCase()}`;
      result.push({value: key, text: intl.formatMessage({id:translationId, defaultMessage: dashToSpacesStringLowerCase(value || key) })})
    });
  }
  return result;
}