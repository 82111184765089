/* eslint-disable react/require-default-props */
import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DsmAlert,
  DsmButton,
  DsmGrid,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import {
  PrimaryPackagingType,
  SecondaryPackagingType,
  TertiaryPackagingType,
} from '../../../../../../graphql/types';
import { FormType } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import {
  FacilityOutputNames,
  FacilityOutputs,
  ProcessingOutput,
} from '../../../../models/Facility/Processing';
import { CSSClassesList } from '../../../../helpers/helperTypes';

interface PackagingForOutputCategoryProps {
  stageIndex: number;
  outputCategory: FacilityOutputs;
  formType: FormType;
  index?: number | undefined;
  isAddition?: boolean;
  barnOutputMassUnit: string;
  formVisible: boolean;
  removeHandler?: (index?: number | number[]) => void;
}

const PackagingForOutputCategory: FC<PackagingForOutputCategoryProps> = ({
  stageIndex,
  outputCategory,
  formType,
  isAddition = false,
  index,
  barnOutputMassUnit,
  formVisible,
  removeHandler,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();

  const fieldName = FacilityOutputNames[outputCategory] as string;
  const fieldItemPrefix = `stages.${stageIndex}.stageData.processingPackaging.${fieldName}`;
  const {
    fields: primaryPackaging,
    append: appendPrimaryPackaging,
    remove: removePrimaryPackaging,
  } = useFieldArray({
    name: `${fieldItemPrefix}.primary`,
    control: formContext.control,
    keyName: 'keyId',
  });
  const {
    fields: tertiaryPackaging,
    append: appendTertiaryPackaging,
    remove: removeTertiaryPackaging,
  } = useFieldArray({
    name: `${fieldItemPrefix}.tertiary`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const outputCategoryMsg = intl.formatMessage({
    id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.${outputCategory}`,
  });

  useEffect(() => {
    if (formVisible && !primaryPackaging?.length)
      appendPrimaryPackaging({
        type: '',
        amount: '',
      });
    if (formVisible && !tertiaryPackaging?.length)
      appendTertiaryPackaging({
        type: '',
        amount: '',
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const isOutputCheckedForCalc = () => {
    if (outputCategory === FacilityOutputs.freshMeatAndEdibleOffal) {
      return true;
    }
    const categoryKey = Object.entries(FacilityOutputNames).find(
      ([k]) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        k.toString() === outputCategory
    )?.[1];

    const outputFormVal = formContext.getValues(
      `stages.${stageIndex}.stageData.processingOutput`
    ) as ProcessingOutput;

    if (categoryKey) {
      return outputFormVal[categoryKey as keyof typeof outputFormVal]
        ?.calculate;
    }
    return false;
  };

  return (
    <>
      {formType !== FormType.View && isAddition && (
        <DsmButton
          variant="text"
          style={{ position: 'relative', width: '100%' }}
          widthFull={false}
          onClick={() => {
            if (removeHandler) removeHandler(index);
          }}
        >
          <DsmIcon
            name="general/x-close"
            style={{
              position: 'absolute',
              color: 'var(--dsm-color-neutral-darker',
              right: '0',
            }}
          />
        </DsmButton>
      )}
      <h4>{outputCategoryMsg}</h4>
      <br />
      {!isOutputCheckedForCalc() && (
        <>
          <DsmAlert variant="primary" color="cyan" closeable={false}>
            <DsmIcon name="general/info-circle" slot="before" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.ERROR_OUTPUT',
            })}
          </DsmAlert>
          <br />
        </>
      )}
      {primaryPackaging &&
        primaryPackaging.map((item, pckgIndex) => (
          <React.Fragment key={`primary_pckg_${item.keyId || ''}`}>
            {formType !== FormType.View && pckgIndex !== 0 && (
              <DsmButton
                variant="text"
                style={{ position: 'relative', width: '100%' }}
                widthFull={false}
                onClick={() => removePrimaryPackaging(pckgIndex)}
              >
                <DsmIcon
                  name="general/x-close"
                  style={{
                    position: 'absolute',
                    color: 'var(--dsm-color-neutral-darker',
                    right: '0',
                  }}
                />
              </DsmButton>
            )}
            <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
              <ReactHookDsmSelect
                name={`${fieldItemPrefix}.primary.${pckgIndex}.type`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PRIMARY_PACKAGING_TYPE',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
                })}
                disabled={formType === FormType.View}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
                })}
                options={[
                  {
                    text: intl.formatMessage({
                      id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.EMPTY_PACKAGING',
                    }),
                    value: '',
                  },
                  ...enumToOptionsArrayWithTranslatedStrings(
                    PrimaryPackagingType,
                    intl,
                    'SUSTELL.TRANSPORT_MODE.'
                  ),
                ]}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.PRIMARY_PACKAGING_TYPE.TT',
                })}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.primary.${pckgIndex}.type`
                  ) as string
                }
              />
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.primary[${pckgIndex}].amount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.AMOUNT',
                })}
                type="number"
                placeholder={barnOutputMassUnit}
                disabled={formType === FormType.View}
                adornment={barnOutputMassUnit}
                tooltip={intl.formatMessage(
                  { id: 'SUSTELL.STAGES.PROCESSING.WEIGHT.TOOLTIP' },
                  { unit: barnOutputMassUnit || 'kg' }
                )}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.primary.${pckgIndex}.amount`
                  ) as string
                }
              />
            </DsmGrid>
          </React.Fragment>
        ))}

      <DsmButton
        variant="text"
        disabled={formType === FormType.View}
        widthFull={false}
        style={{ width: 'fit-content' }}
        onClick={() => appendPrimaryPackaging({ type: '', amount: '' })}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage(
          { id: 'SUSTELL.STAGES.PROCESSING.ADD_FOR_ANOTHER' },
          { tier: 'primary' }
        )}
      </DsmButton>
      <br />
      {/* Secondary is just one */}
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmSelect
          name={`${fieldItemPrefix}.secondary.type`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.SECONDARY_PACKAGING_TYPE',
          })}
          placeholder={intl.formatMessage({
            id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
          })}
          options={[
            {
              text: intl.formatMessage({
                id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.EMPTY_PACKAGING',
              }),
              value: '',
            },
            ...enumToOptionsArrayWithTranslatedStrings(
              SecondaryPackagingType,
              intl,
              'SUSTELL.TRANSPORT_MODE.'
            ),
          ]}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.SECONDARY_PACKAGING_TYPE.TT',
          })}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.secondary.type`) as string
          }
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.secondary.amount`}
          label={intl.formatMessage({
            id: 'SUSTELL.AMOUNT',
          })}
          placeholder={barnOutputMassUnit}
          disabled={formType === FormType.View}
          adornment={barnOutputMassUnit}
          tooltip={intl.formatMessage(
            { id: 'SUSTELL.STAGES.PROCESSING.WEIGHT.TOOLTIP' },
            { unit: barnOutputMassUnit || 'kg' }
          )}
          type="number"
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.secondary.amount`
            ) as string
          }
        />
      </DsmGrid>
      {tertiaryPackaging &&
        tertiaryPackaging.map((item, pckgIndex) => (
          <React.Fragment key={`tertiary_pckg_${item.keyId || ''}`}>
            {formType !== FormType.View && pckgIndex !== 0 && (
              <DsmButton
                variant="text"
                style={{ position: 'relative', width: '100%' }}
                widthFull={false}
                onClick={() => removeTertiaryPackaging(pckgIndex)}
              >
                <DsmIcon
                  name="general/x-close"
                  style={{
                    position: 'absolute',
                    color: 'var(--dsm-color-neutral-darker',
                    right: '0',
                  }}
                />
              </DsmButton>
            )}
            <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
              <ReactHookDsmSelect
                name={`${fieldItemPrefix}.tertiary[${pckgIndex}].type`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.TERTIARY_PACKAGING_TYPE',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.TRANSPORT_MODE.TRUCK.PLACEHOLDER',
                })}
                disabled={formType === FormType.View}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
                })}
                options={[
                  {
                    text: intl.formatMessage({
                      id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.EMPTY_PACKAGING',
                    }),
                    value: '',
                  },
                  ...enumToOptionsArrayWithTranslatedStrings(
                    TertiaryPackagingType,
                    intl,
                    'SUSTELL.TRANSPORT_MODE.'
                  ),
                ]}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.TERTIARY_PACKAGING_TYPE.TT',
                })}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.tertiary[${pckgIndex}].type`
                  ) as string
                }
              />
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.tertiary[${pckgIndex}].amount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.AMOUNT',
                })}
                placeholder={barnOutputMassUnit}
                disabled={formType === FormType.View}
                adornment={barnOutputMassUnit}
                tooltip={intl.formatMessage(
                  { id: 'SUSTELL.STAGES.PROCESSING.WEIGHT.TOOLTIP' },
                  { unit: barnOutputMassUnit || 'kg' }
                )}
                type="number"
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.tertiary[${pckgIndex}].amount`
                  ) as string
                }
              />
            </DsmGrid>
          </React.Fragment>
        ))}
      <DsmButton
        variant="text"
        disabled={formType === FormType.View}
        widthFull={false}
        style={{ width: 'fit-content' }}
        onClick={() => appendTertiaryPackaging({ type: '', amount: '' })}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage(
          { id: 'SUSTELL.STAGES.PROCESSING.ADD_FOR_ANOTHER' },
          { tier: 'tertiary' }
        )}
      </DsmButton>
    </>
  );
};

export default PackagingForOutputCategory;
