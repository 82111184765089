import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { MenuItem, Grid } from '@material-ui/core';

import InfoToolTip from '../../../../../modules/Helpers/InfoToolTip';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import ReactHookFormSelect from '../../../../../modules/Helpers/ReactHookFormSelect';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { BaselinePropertyValue, BroilerBaseline, BroilerManure } from '../../../../models/Baseline';

const BManureFormDialog: FC<BaselineDialogProps> = ({
  formVisible,
  itemIndex = 0,
  formType = FormType.Add,
  handleCancel,
  handleSave = handleCancel,
  manureManagementSystems
}) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE' },
    { br: '' }
  );

  const currResetValue = useRef<BroilerManure>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.manure`;
  // const { control, onBlur, onChange, getValues, reset } = useFormContext();
  const formContext = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = { ...formContext.getValues(fieldItemPrefix) } as BroilerManure; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as BroilerBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.manure) {
        resetObject.stages[itemIndex].stageData.manure = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel();
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="manureIcon"
      formTitle={formTitle}
    >
      <Grid container direction="row" spacing={3} justifyContent="space-between">
        <Grid item container direction="column" xs={6} spacing={3}>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix }.averageAnimalsPresent`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.BARN.AVERAGE_ANIMALS_PRESENT',
              })} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.AVERAGE_ANIMALS_PRESENT.TOOLTIP',
              })}
              disabled={formType === FormType.View}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix  }.averageAnimalsPresent`
              ) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={11}>
              <ReactHookFormSelect
                name={`${fieldItemPrefix  }.manureManagementSystem`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM',
                })}
                control={formContext.control}
                variant="outlined"
                fullWidth
                // onBlur={formContext.onBlur}
                // onChange={formContext.onChange}
                disabled={formType === FormType.View}
                defaultValue={formContext.getValues(
                  `${fieldItemPrefix }.manureManagementSystem`
                ) as string | undefined}
              >
                {manureManagementSystems?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.display_name}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={1} className="d-flex">
              <div style={{ paddingTop: '15px', paddingLeft: '7px' }}>
                <InfoToolTip
                  tooltip={
                    <div>
                      {intl.formatMessage(
                        {
                          id: 'BASELINE.FORM.BARN.BROILERS.MANURE_MANAGEMENT_SYSTEM.TOOLTIP',
                        },
                        { br: <br /> }
                      )}
                    </div>
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: '-1.75rem' }}>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix  }.solidManureStorage`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SOLID_MANURE',
              })} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SOLID_MANURE.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={formContext.getValues(`${fieldItemPrefix  }.solidManureStorage`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix  }.slurryManureStorage`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SLURRY_MANURE',
              })} *`}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SLURRY_MANURE.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={formContext.getValues(`${fieldItemPrefix  }.slurryManureStorage`) as BaselinePropertyValue}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={3}>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix  }.ashContent`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.ASH' })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.ASH.TOOLTIP',
              })}
              addornment="%"
              disabled={formType === FormType.View}
              defaultValue={formContext.getValues(`${fieldItemPrefix  }.ashContent`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix  }.strawForBedding`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.STRAW_FOR_BEDDING',
              })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.STRAW_FOR_BEDDING.TOOLTIP',
              })}
              addornment={intl.formatMessage(
                { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              defaultValue={formContext.getValues(`${fieldItemPrefix  }.strawForBedding`) as BaselinePropertyValue}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithInfo
              name={`${fieldItemPrefix  }.sawDust`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.BARN.SAW_DUST' })}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.SAW_DUST.TOOLTIP',
              })}
              addornment={intl.formatMessage(
                { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              defaultValue={formContext.getValues(`${fieldItemPrefix  }.sawDust`) as BaselinePropertyValue}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default BManureFormDialog;