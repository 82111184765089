import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { API } from 'aws-amplify';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { deleteAnimalSystem } from '../../../../../../graphql/mutations';
import { Farm, AnimalSystem } from '../../../../../../graphql/types';
import { FormType } from '../../common';
import AnimalSystemAddForm from './AnimalSystemAddForm';
import AnimalSystemComponent from './AnimalSystemComponent';
import useAdobeDataLayer from '../../../../analytics/adobeDataLayer';
import DeleteConfirmationDialog from '../../../../../sustell_15/components/helpers/DeleteConfirmationDialog';
import Can from '../../../../../modules/Auth/Can';

type AnimalSystemProcessProps = {
  farm: Farm;
  customerID: string;
};

const AnimalSystemProcess = ({
  farm,
  customerID,
}: AnimalSystemProcessProps) => {
  const intl = useIntl();
  const history = useHistory();
  const [formVisible, setFormVisible] = useState(true);
  const [formType, setFormType] = useState(FormType.Add);
  const [prodProcessesData, setProdProcessesData] = useState<
    AnimalSystem[]
  >([]);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const { id: farmID, name: farmName } = farm;
  const { ctaClickEvent, formStartEvent } = useAdobeDataLayer();
  const showProductionProcessForm = () => {
    setFormType(FormType.Add);
    setFormVisible(true);
    ctaClickEvent(
      window.location.href,
      'button',
      'Add production process',
      'Production processes',
      'Farm Details'
    );
    formStartEvent('Add production process', 'add');
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleFormSave = (savedData: AnimalSystem[]) => {
    const updatedProdProcesses = prodProcessesData
      ? [...prodProcessesData]
      : [];

    setProdProcessesData(updatedProdProcesses?.concat(savedData).sort());
    handleFormClose();
  };

  const openProductionProcessDatasets = (prodProcessId: string) => {
    ctaClickEvent(
      window.location.href,
      'link',
      'View production process',
      'Production processes',
      'Farm Details'
    );
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${prodProcessId}`,
      {
        farmName,
        farmCountry: farm.country,
      }
    );
  };

  const deleteProdProcess = () => {
    if (idToDelete) {
      const deletePP = async () =>
        API.graphql({
          query: deleteAnimalSystem,
          variables: {
            animalSystemId: idToDelete,
          },
        });

      deletePP()
        .then(() => {
          const ppIndex = prodProcessesData.findIndex(
            (pp) => pp.id === idToDelete
          );
          const productionProcesses = [...prodProcessesData];
          productionProcesses.splice(ppIndex, 1);
          setProdProcessesData(productionProcesses);
          setIdToDelete(null);
        })
        .catch((error) => console.log(error));
    }
  };

  React.useEffect(() => {
    setProdProcessesData(farm?.animalSystems?.items as AnimalSystem[]);
    const item = farm?.animalSystems?.items;
    if (item && item.length < 1) setFormVisible(true);
    else {
      setFormVisible(false);
    }
  }, [farm]);

  const createBaseline = (processID: string) => {
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${processID}/nds/NEW`
    );
  };

  return (
    <>
      <DeleteConfirmationDialog
        open={!!idToDelete}
        confirmHandler={deleteProdProcess}
        cancelHandler={() => setIdToDelete(null)}
        customText={intl.formatMessage({
          id: 'SUSTELL.DELETE_PRODUCTION_PROCESSES.CONFIRM',
        })}
        itemType={''}
      />
      <>
        {prodProcessesData && prodProcessesData.length > 0 && (
          <Grid item xs={12}>
            {prodProcessesData.map((item) => (
              <AnimalSystemComponent
                key={item.id}
                processItem={item}
                farm={farm}
                openHandler={openProductionProcessDatasets}
                deleteHandler={() => setIdToDelete(item.id)}
                createBaselineHandler={createBaseline}
                customerID={customerID}
              />
            ))}
          </Grid>
        )}
        {!formVisible && !farm?.benchmark && Can('create', 'Farm') && (
          <DsmButton
            onClick={() => showProductionProcessForm()}
            variant="text"
            color="primary"
            id="prod-proc-add-btn"
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.ANIMAL.SYSTEM.ADD.ANOTHER.ANIMAL.SYSTEM',
            })}
          </DsmButton>
        )}
        {formVisible && !farm?.benchmark && Can('create', 'Farm') && (
          <AnimalSystemAddForm
            formType={formType}
            formVisible={formVisible}
            formData={prodProcessesData}
            farmID={farmID}
            handleClose={handleFormClose}
            handleFormSave={handleFormSave}
          />
        )}
      </>
    </>
  );
};

export default AnimalSystemProcess;
