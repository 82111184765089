import React, { FC, useEffect, useRef, useState } from 'react';
import { FieldError, useFormContext, useFieldArray } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import {
  DsmFieldset,
  DsmIcon,
  DsmButton,
  DsmGrid,
} from '@dsm-dcs/design-system-react';
import { v4 as uuidv4 } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType, ListEntry } from '../../../common';
import {
  BeddingType,
  SurfaceType,
  ManureManagementSystemType,
  MaterialType,
  ManureForm,
} from '../../../../../../../graphql/generated/blonk/pigs';
import { processAndStageStylesV2 } from '../../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../../modules/Helpers/UserProfilePrefs';
import { resolvePath } from '../../../../../../modules/Helpers/resolvePathFn';
import { CSSClassesList } from '../../../../../helpers/helperTypes';
import { getManureForm } from '../../../../../helpers/manureManagementSystemsLookups';
import {
  ManureManagementSystem,
  PigBaseline,
  PigFatteningStageData,
  PigManure,
} from '../../../../../models/Baseline/PigBaseline';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../../utils/obj-utils';
import { getSingleEnumEntryTranslation } from '../../../../../utils/translation-utils';
import { unitLong } from '../../../../../utils/unit-utils';
import DsmButtonControlGroup from '../../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../../CommonDataParts/DialogContainer2';
import MMSBlockComponent from './MMSBlockComponent';
import MMSDescriptionDialog from './MMSDescriptionDialog';

type ReactChangedType = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const getBaseTypeForMonthEndingMMS = (mms: string) => {
  let result = mms;
  const matches = mms.match(/(_OVER|_UNDER)?_(\d){1,}MONTH$/);
  if (matches) {
    result = mms.substring(0, matches.index);
    // eslint-disable-next-line prefer-destructuring
  }
  return result;
};

const PHousingManureFormDialog: FC<BaselineDialogProps> = ({
  formVisible,
  itemIndex = 0,
  formType = FormType.Add,
  handleCancel,
  handleSave = handleCancel,
}) => {
  const intl = useIntl();

  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE' },
    { br: ' ' }
  );
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<PigManure>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const formContext = useFormContext();
  const { control } = formContext;
  const {
    fields: mmsSystems,
    append: appendMMS,
    remove: removeMMS,
  } = useFieldArray({
    name: `${fieldItemPrefix}.manureSystems`,
    control,
    keyName: 'keyId',
  });
  const {
    fields: beddingSystems,
    append: appendBedding,
    remove: removeBedding,
  } = useFieldArray({
    name: `${fieldItemPrefix}.beddingSystems`,
    control,
    keyName: 'keyId',
  });
  const {
    fields: materials,
    append: appendMaterial,
    remove: removeMaterial,
  } = useFieldArray({
    name: `${fieldItemPrefix}.materials`,
    control,
    keyName: 'keyId',
  });
  const [, forceUpdate] = useState({});

  const [activeList, setActiveList] = useState<Array<ListEntry>>([]);

  const materialsWithEmptyOption = () => {
    const materialOptions = enumToOptionsArrayWithTranslatedStrings(
      MaterialType,
      intl,
      'SUSTELL.PIGS.ENUMS.MATERIAL_TYPE'
    );
    const defaultOption = { text: 'No material', value: '' };
    return [defaultOption].concat(materialOptions);
  };

  const emptyMaterialSelected = (index: number) =>
    !formContext.getValues(
      `${fieldItemPrefix}.materials.${index}.materialType`
    );

  const resetMaterialAmount = (index: number) => {
    formContext.setValue(
      `${fieldItemPrefix}.materials.${index}.materialAmount`,
      '',
      { shouldValidate: true, shouldDirty: true }
    );
  };

  const materialsChanged = (e: ReactChangedType, index: number) => {
    forceUpdate({}); // Unfortunately the only consistent way to rerender the disabling of the useFieldArray parts. useWatch would not update on every change if initial value
    if (!e.target.value) resetMaterialAmount(index);
  };

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as PigBaseline;
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as PigFatteningStageData;
      if (stageData?.housing) {
        stageData.housing = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  const addMMS = () => {
    appendMMS({
      id: uuidv4(),
      mmsType: '',
      mmsHoldingDuration: '',
      share: '',
    });
  };

  // sets share to 100% if for the given kinde (solid or slurry) only one left
  const setShare = () => {
    const curentMMS = (formContext.getValues(
      `stages.${itemIndex}.stageData.housing.manureSystems`
    ) || []) as ManureManagementSystem[];

    const solid = curentMMS?.filter(
      (item) => item.localManureForm === ManureForm.Solid
    );
    // if only one, set share to 100
    if (solid?.length === 1) {
      const firstIndex = curentMMS?.findIndex(
        (item) => item.localManureForm === ManureForm.Solid
      );
      formContext.setValue(
        `stages.${itemIndex}.stageData.housing.manureSystems.${firstIndex}.share`,
        100
      );
    }

    const liquid = curentMMS?.filter(
      (item) => item.localManureForm === ManureForm.LiquidSlurry
    );
    // if only one, set share to 100
    if (liquid?.length === 1) {
      const firstIndex = curentMMS?.findIndex(
        (item) => item.localManureForm === ManureForm.LiquidSlurry
      );
      formContext.setValue(
        `stages.${itemIndex}.stageData.housing.manureSystems.${firstIndex}.share`,
        100
      );
    }
  };

  const removeMMSHandler = (index: number) => {
    removeMMS(index);
    setShare();
    // If on remove only one left set it's share to 100
    // const currentMMS = formContext.getValues(`stages.${itemIndex}.stageData.housing.manureSystems`) || [];
    // if(currentMMS.length === 1) {
    //   formContext.setValue(`stages.${itemIndex}.stageData.housing.manureSystems.0.share`,100);
    // }
  };

  const addBedding = () => {
    appendBedding({
      beddingType: '',
      beddingAmount: '',
    });
  };

  const addMaterial = () => {
    appendMaterial({
      materialType: '',
      materialAmount: '',
    });
  };

  const mms = enumToOptionsArrayWithTranslatedStrings(
    ManureManagementSystemType,
    intl,
    'SUSTELL.PIGS.ENUMS.MMS'
  );

  const getFilteredList = (entries: ListEntry[]) => {
    const uniqueMMS: ListEntry[] = entries.reduce(
      (accumulator: ListEntry[], current: ListEntry) => {
        if (!accumulator.find((item) => item.value === current.value)) {
          accumulator.push(current);
        }
        return accumulator;
      },
      []
    );
    return uniqueMMS;
  };

  const filteredMMSList = getFilteredList(
    mms.map((item) => {
      const base = getBaseTypeForMonthEndingMMS(item.value);
      return {
        value: base,
        text:
          base === item.value
            ? item.text
            : getSingleEnumEntryTranslation(
                base,
                intl,
                'SUSTELL.PIGS.ENUMS.MMS'
              ),
      };
    })
  );

  const onSurfaceChange = () => {
    const value =
      formContext.getValues(`${fieldItemPrefix}.surfaceType`) ||
      SurfaceType.SlattedFloor;
    // console.log("onSurfaceChange",value)
    let newFilteredList: Array<ListEntry> = [];
    if (value === SurfaceType.DeepBedding)
      newFilteredList = filteredMMSList?.filter((item) =>
        item.value.startsWith('DEEP_BEDDING')
      );
    if (value === SurfaceType.SlattedFloor || value === SurfaceType.SolidFloor)
      newFilteredList = filteredMMSList?.filter(
        (item) => !item.value.startsWith('DEEP_BEDDING')
      );
    setActiveList(newFilteredList);
  };

  const beddingsWithEmptyOption = () => {
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      'SUSTELL.PIGS.ENUMS.BEDDING_TYPE'
    );
    const defaultOption = { text: 'No bedding', value: '' };
    return [defaultOption].concat(beddings);
  };

  const emptyBeddingSelected = (index: number) =>
    !formContext.getValues(
      `${fieldItemPrefix}.beddingSystems.${index}.beddingType`
    );

  const resetBeddingAmount = (index: number) => {
    formContext.setValue(
      `${fieldItemPrefix}.beddingSystems.${index}.beddingAmount`,
      '',
      { shouldValidate: true }
    );
  };

  const beddingsChanged = (e: ReactChangedType, index: number) => {
    forceUpdate({});
    if (!e.target.value) resetBeddingAmount(index);
  };

  // should only be called once on entry
  useEffect(() => {
    onSurfaceChange();

    if (mmsSystems.length === 0) {
      addMMS();
    }
    if (beddingSystems.length === 0) {
      addBedding();
    }
    if (materials.length === 0) {
      addMaterial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classBorderBeddings =
    beddingSystems.length > 1 ? classes.additionalEntriesBlockHolder : '';
  const classBorderMaterials =
    materials.length > 1 ? classes.additionalEntriesBlockHolder : '';

  const updateManureFormOnMMSChange = () => {
    const currValues = formContext.getValues(
      `${fieldItemPrefix}.manureSystems`
    ) as ManureManagementSystem[];
    const currentMMS = currValues?.map(
      (item: ManureManagementSystem) =>
        (item.mmsHoldingDuration
          ? item.mmsHoldingDuration
          : item.mmsType) as ManureManagementSystemType
    );
    const manureForm = getManureForm(currentMMS);
  };

  const showDescription = () => {
    setOpenDescriptionDialog(true);
  };

  const closeDescriptionDialog = () => {
    setOpenDescriptionDialog(false);
  };

  const manureSystemsError: FieldError | null = resolvePath(
    formContext.errors,
    `${fieldItemPrefix}.manureSystems`,
    null
  ) as FieldError | null;
  const beddingSystemsError: FieldError | null = resolvePath(
    formContext.errors,
    `${fieldItemPrefix}.beddingSystem`,
    null
  ) as FieldError | null;
  const materialsError: FieldError | null = resolvePath(
    formContext.errors,
    `${fieldItemPrefix}.materials`,
    null
  ) as FieldError | null;

  useEffect(() => {
    if (formVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      introText={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.INTRO',
      })}
    >
      {openDescriptionDialog && (
        <MMSDescriptionDialog
          open
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.manureForm`}
      />
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <ReactHookDsmSelect
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.MANURE.SURFACE_TYPE',
          })}
          name={`${fieldItemPrefix}.surfaceType`}
          options={enumToOptionsArrayWithTranslatedStrings(
            SurfaceType,
            intl,
            'SUSTELL.PIGS.ENUMS.SURFACE_TYPE'
          )}
          changeHandler={onSurfaceChange}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.SURFACE_TYPE.TOOLTIP',
          })}
          required
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.surfaceType`) ||
            SurfaceType.SlattedFloor
          }
        />
        <div />
      </DsmGrid>
      {!!manureSystemsError && manureSystemsError.type === 'min' && (
        <DsmGrid className={classes.dsmGridOneColumn}>
          <Alert severity="error">
            <AlertTitle>
              {intl.formatMessage({ id: 'GENERAL.ERROR' })}
            </AlertTitle>
            {manureSystemsError.message}
          </Alert>
        </DsmGrid>
      )}
      {mmsSystems &&
        mmsSystems?.map((item, index: number) => (
          <MMSBlockComponent
            key={item.keyId}
            filteredMMSList={activeList}
            manureManagementSystems={mms}
            formType={formType}
            stageIndex={itemIndex}
            itemIndex={index}
            removeHandler={removeMMSHandler}
            mmsChangeHandler={updateManureFormOnMMSChange}
          />
        ))}
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton
          variant="text"
          onClick={addMMS}
          disabled={formType === FormType.View}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_MMS',
          })}
        </DsmButton>
        <DsmButton variant="text" onClick={showDescription}>
          <DsmIcon slot="before" name="general/eye" />
          {intl.formatMessage({
            id: 'DATABASE_FOUNDATION_DEFINITIONS',
          })}
        </DsmButton>
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.Nreplacement`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.N_REPLACEMENT',
          })}
          disabled={formType === FormType.View}
          adornment="%"
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.Nreplacement`
          )}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.N_REPLACEMENT.TOOLTIP',
          })}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.Preplacement`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.P_REPLACEMENT',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.P_REPLACEMENT.TOOLTIP',
          })}
          type="number"
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.Preplacement`
          )}
        />
      </DsmGrid>
      <div style={{ paddingTop: 'var(--dsm-spacing-px-4' }}>
        {!!beddingSystemsError && beddingSystemsError.type === 'min' && (
          <DsmGrid className={classes.dsmGridOneColumn}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'GENERAL.ERROR' })}
              </AlertTitle>
              {beddingSystemsError.message}
            </Alert>
          </DsmGrid>
        )}
        {beddingSystems &&
          beddingSystems?.map((item, index) => (
            <div className={classBorderBeddings} key={item.keyId}>
              {removeBedding && beddingSystems.length > 1 && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => removeBedding(index)}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING_TYPE',
                  })}
                  name={`${fieldItemPrefix}.beddingSystems.${index}.beddingType`}
                  options={beddingsWithEmptyOption()}
                  disabled={formType === FormType.View}
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.beddingSystems.${index}.beddingType`
                  )}
                  changeHandler={(e: ReactChangedType) =>
                    beddingsChanged(e, index)
                  }
                />
                <div>
                  <DsmFieldset>
                    <ReactHookDsmInput
                      label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING.AMOUNT',
                      })}
                      name={`${fieldItemPrefix}.beddingSystems.${index}.beddingAmount`}
                      adornment={barnOutputMassUnit}
                      type="number"
                      disabled={
                        formType === FormType.View ||
                        emptyBeddingSelected(index)
                      }
                      defaultValue={formContext.getValues(
                        `${fieldItemPrefix}.beddingSystems.${index}.beddingAmount`
                      )}
                    />
                    <div />
                  </DsmFieldset>
                </div>
              </DsmGrid>
            </div>
          ))}
      </div>
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton
          variant="text"
          onClick={addBedding}
          disabled={formType === FormType.View}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_BEDDING',
          })}
        </DsmButton>
      </DsmGrid>
      {!!materialsError && materialsError.type === 'min' && (
        <DsmGrid className={classes.dsmGridOneColumn}>
          <Alert severity="error">
            <AlertTitle>
              {intl.formatMessage({ id: 'GENERAL.ERROR' })}
            </AlertTitle>
            {materialsError.message}
          </Alert>
        </DsmGrid>
      )}
      {materials &&
        materials?.map((item, index: number) => (
          <div className={classBorderMaterials} key={item.keyId}>
            {removeMaterial && materials.length > 1 && (
              <DsmButton
                variant="text"
                style={{ position: 'relative', width: '100%' }}
                onClick={() => removeMaterial(index)}
              >
                <DsmIcon
                  name="general/x-close"
                  style={{
                    position: 'absolute',
                    color: 'var(--dsm-color-neutral-darker',
                    right: '0',
                  }}
                />
              </DsmButton>
            )}
            <DsmGrid className={classes.dsmGridTwoColumn}>
              <ReactHookDsmSelect
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.PIGS.HOUSING.CLEANING_MATERIAL',
                })}
                name={`${fieldItemPrefix}.materials.${index}.materialType`}
                options={materialsWithEmptyOption()}
                disabled={formType === FormType.View}
                defaultValue={formContext.getValues(
                  `${fieldItemPrefix}.materials.${index}.materialType`
                )}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.PIGS.HOUSING.CLEANING_MATERIAL.TOOLTIP',
                })}
                changeHandler={(e: ReactChangedType) => {
                  materialsChanged(e, index);
                }}
              />
              <div>
                <DsmFieldset>
                  <ReactHookDsmInput
                    label={intl.formatMessage({
                      id: 'SUSTELL.STAGE.PIGS.HOUSING.CLEANING_MATERIAL.AMOUNT',
                    })}
                    name={`${fieldItemPrefix}.materials.${index}.materialAmount`}
                    adornment={barnOutputMassUnit}
                    type="number"
                    defaultValue={formContext.getValues(
                      `${fieldItemPrefix}.materials.${index}.materialAmount`
                    )}
                    disabled={
                      formType === FormType.View || emptyMaterialSelected(index)
                    }
                  />
                  <div />
                </DsmFieldset>
              </div>
            </DsmGrid>
          </div>
        ))}
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <DsmButton
          variant="text"
          onClick={addMaterial}
          disabled={formType === FormType.View}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_MATERIAL',
          })}
        </DsmButton>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.housing`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PHousingManureFormDialog;
