/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { PoultryGrowingStageData, PoultryInputGrowing, PoultryIntervention } from '../../../../models/Intervention/PoultryIntervention';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { ExternalSource, InternalSource } from '../../../../models/Baseline/PoultryBaseline';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import PoultryInputPartExternalSource from './PoultryInputPartExternalSource';
import PoultryInputPartInternalSource from './PoultryInputPartInternalSource';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
  marginTop: '10px',
  marginLeft: '8px'
}

const container = {
  paddingLeft: '8px',
  marginRight: '8px',
  marginBottom: '8px'
}

const PoultryInputGrowingDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel,
  baseline,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<PoultryInputGrowing>();
  const fc = useFormContext<PoultryIntervention>();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      if (!currResetValue.current) {
        const input = baseline?.stages?.[itemIndex]?.stageData?.input as PoultryInputGrowing;
        currResetValue.current = {
          startDate : input?.startDate,
          endDate: input?.endDate,
          durationOfProductionCycle: input?.durationOfProductionCycle
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = {...fc.getValues()} as PoultryIntervention;
      const stageData = resetObject?.stages?.[itemIndex]?.stageData as PoultryGrowingStageData;
      if (stageData?.input) {
        stageData.input = {...currResetValue.current} as PoultryInputGrowing;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }

  const handleSaveClick = async () => handleCancel();

  const checkIfInternalSourcesExist = () => {
    const internalSources: number | undefined = baseline?.stages?.[itemIndex]?.stageData?.input?.internalSources?.length
    return internalSources || null;
  }

  const checkIfExternalSourcesExist = () => {
    const externalSources: number | undefined = baseline?.stages?.[itemIndex]?.stageData?.input?.externalSources?.length
    return externalSources || null;
  }

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      formTitle={intl.formatMessage({id: "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"})}
      variant="demiWide"
    > 
      <DsmGrid style={container} className={classes.dsmGridTwoColumn}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.START_DATE"})}
          required
          disabled
          defaultValue={baseline?.stages?.[itemIndex]?.stageData?.input?.startDate as string}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.END_DATE"})}
          required
          disabled
          defaultValue={baseline?.stages?.[itemIndex]?.stageData?.input?.endDate as string}
        />
      </DsmGrid>
      <DsmGrid style={{...container, 'width': '492px'}} className={classes.dsmGridOneColumn}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.durationOfProductionCycle`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.PRODUCTION_CYCLE"})}
          type="number"
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.PRODUCTION_CYCLE.TOOLTIP"})}
          metricUnit=''
          baseline={baseline?.stages?.[itemIndex]?.stageData?.input?.durationOfProductionCycle}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.emptyPeriod`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.EMPTY_PERIOD"})}
          type="number"
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.EMPTY_PERIOD.TOOLTIP"})}
          metricUnit=''
          baseline={baseline?.stages?.[itemIndex]?.stageData?.input?.emptyPeriod}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.animalsPresentAtStart`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_START_DATE"})}
          type="number"
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_START_DATE.TOOLTIP"})}
          metricUnit=''
          baseline={baseline?.stages?.[itemIndex]?.stageData?.input?.animalsPresentAtStart}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.animalsPresentAtEnd`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_END_DATE"})}
          type="number"
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_END_DATE.TOOLTIP"})}
          metricUnit=''
          baseline={baseline?.stages?.[itemIndex]?.stageData?.input?.animalsPresentAtEnd}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeightAtStart`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_START_DATE"})}
          type="number"
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_START_DATE.TOOLTIP"})}
          precision={barnOutputMassPrecision}
          metricUnit={barnOutputMassUnit}
          baseline={baseline?.stages?.[itemIndex]?.stageData?.input?.averageWeightAtStart}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeightAtEnd`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_END_DATE"})}
          type="number"
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_END_DATE.TOOLTIP"})}
          precision={barnOutputMassPrecision}
          metricUnit={barnOutputMassUnit}
          baseline={baseline?.stages?.[itemIndex]?.stageData?.input?.averageWeightAtEnd}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridOneColumnIntervention}>
        { checkIfInternalSourcesExist() && (<div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE'})}
            </span>
          </div>
          <div>
            {baseline?.stages?.[itemIndex]?.stageData?.input?.internalSources.map((item: InternalSource, index: number | undefined) => 
              (<PoultryInputPartInternalSource
                stageIndex={itemIndex}
                itemIndex={index}
                propName='internalSources'
                intlPrefix={StageType.Growing}
                formType={formType}
                item={item}
              />)
            )}
          </div>
        </div> )}
        { checkIfExternalSourcesExist() && (<div>
          <div>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE'})}
            </span>
          </div>
          <div>
            {baseline?.stages?.[itemIndex]?.stageData?.input?.externalSources.map((item: ExternalSource, index: number | undefined) => 
              (<PoultryInputPartExternalSource
                stageIndex={itemIndex}
                itemIndex={index}
                propName='externalSources'
                intlPrefix={StageType.Growing}
                formType={formType}
                item={item}
              />)
            )}
          </div>
        </div> )}
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => handleSaveClick()}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM"})}/>
    </DialogContainer>
  );
};

export default PoultryInputGrowingDialog;
