import {FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from "@material-ui/core";

import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup"
import SustellDynamicInput from "../../../../../modules/Farms/Baseline/SustellDynamicInput";
import SustellBoatInput from "../../../../../modules/Farms/Baseline/SustellBoatInput";
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import lookupValues from "../../../../helpers/lookupValues";
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { BaselineDialogProps, FormType } from '../../common';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';
import { SalmonBaseline, SalmonOperations } from '../../../../models/Baseline/SalmonBaseline';

interface SOperationsFormDialogProps extends Omit<BaselineDialogProps, 'baseline'> {
  masterDataEnums: MasterDataEnumType | null;
  baseline: SalmonBaseline;
}

const SOperationsFormDialog: FC<SOperationsFormDialogProps> = ({ 
  formType=FormType.Add, 
  itemIndex=0, 
  formVisible=false,
  handleCancel, 
  masterDataEnums,
  baseline
}) => {

  const intl = useIntl();
  const currResetValue = useRef('');

  const dynamicItem = { type: '', amount: '' };
  const boatItem = { boatName: '', type: '', amount: '' };

  const fieldItemPrefix = `stages.${itemIndex}.stageData.operations`; 

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { reset, getValues } = useFormContext<SalmonBaseline>();

  const baselineOperations = baseline?.stages?.[itemIndex]?.stageData?.operations;
 
  const [materialsFarmConstruction, setMaterialsFarmConstruction] = useState(
    baselineOperations?.materialsFarmConstruction &&
      baselineOperations?.materialsFarmConstruction?.length > 0
      ? baselineOperations.materialsFarmConstruction
      : [{ ...dynamicItem }]
  );

  const [anitibioticsUse, setAnitibioticsUse] = useState(
    baselineOperations?.anitibioticsUse &&
      baselineOperations?.anitibioticsUse?.length > 0
      ? baselineOperations.anitibioticsUse
      : [{ ...dynamicItem }]
  );

  const [liceTreatment, setLiceTreatment] = useState(
    baselineOperations?.liceTreatment &&
      baselineOperations?.liceTreatment?.length > 0
      ? baselineOperations.liceTreatment
      : [{ ...dynamicItem }]
  );

  const [transport, setTransport] = useState(
    baselineOperations?.transport && baselineOperations?.transport?.length > 0
      ? baselineOperations.transport
      : [{ ...boatItem }]
  );
  
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"}) ;
  
  useEffect(() => {
    if(formVisible) {
      const serializedData = JSON.stringify(getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  useEffect(()=>{
    setTransport(baseline?.stages?.[itemIndex]?.stageData?.operations?.transport || [{ ...boatItem }])
    setMaterialsFarmConstruction(baseline?.stages?.[itemIndex]?.stageData?.operations?.materialsFarmConstruction || [{ ...dynamicItem }])
    setAnitibioticsUse(baseline?.stages?.[itemIndex]?.stageData?.operations?.anitibioticsUse || [{ ...dynamicItem }])
    setLiceTreatment(baseline?.stages?.[itemIndex]?.stageData?.operations?.liceTreatment || [{ ...dynamicItem }])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[baseline])

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...getValues()} as SalmonBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.operations){
        const val = JSON.parse(currResetValue.current) as SalmonOperations;
        resetObject.stages[itemIndex].stageData.operations = val;
        reset(resetObject,{
          errors: true
        });
         // reset dynamic list fields
        // to previously valid (confirmed)
        setMaterialsFarmConstruction(val?.materialsFarmConstruction || []);
        setAnitibioticsUse(val?.anitibioticsUse || []);
        setLiceTreatment(val?.liceTreatment || []);
        setTransport(val?.transport || []);
      }
    }
    if (handleCancel) handleCancel();
  }

  return (
    <DialogContainer
      formVisible ={ formVisible }
      handleClose = { handleResetClick }
      formTitle = {formTitle}
      variant = 'wide'
      iconCode="OPERATIONS"
    > 
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            <SustellBoatInput key={`${fieldItemPrefix  }.boats`}
              label={intl.formatMessage({id: "BASELINE.FORM.SALMON.BOATS" })}
              availableOptions={
                  getTranslatedEnumOrDefaultLookupValues(
                    masterDataEnums?.EnergyCarrierType || [],
                    lookupValues.fuelTypes,
                    intl,
                    "MASTERDATA.ENUM.ENERGY_CARRIER_TYPE"
                  )
                }
              // existingItems={props.baseline?.transport ? props.baseline.transport : [boatItem]}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="transport"
              // handleLoadMasterData={props.handleLoadMasterData}
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.SALMON.BOATS.FUEL_USE_FOR.TOOLTIP"}) }
              formState={formType}
              data={transport}
              setData={setTransport}
            />

            <SustellDynamicInput key={`${fieldItemPrefix  }.materialsFarmConstruction`}
              label={intl.formatMessage({id: "BASELINE.FORM.SALMON.MATERIAL_FARM"})}
              availableOptions={
                  getTranslatedEnumOrDefaultLookupValues(
                    masterDataEnums?.MaterialType || [],
                    lookupValues.materials,
                    intl,
                    "MASTERDATA.ENUM.MATERIALS"
                  )
                }
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="materialsFarmConstruction"
              // handleLoadMasterData={props.handleLoadMasterData}
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.SALMON.MATERIAL_FARM_TT"}) }
              formState={formType}
              data={materialsFarmConstruction}
              setData={setMaterialsFarmConstruction}
              disableAddButtonWhenAllOptionSelected
            />
          </Grid>
	      </Grid>
	      <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
          <SustellDynamicInput key={`${fieldItemPrefix  }.anitibioticsUse`}
              label={intl.formatMessage({id: "BASELINE.FORM.SALMON.ANTIBIOTICS_USE"})}
              availableOptions={
                getTranslatedEnumOrDefaultLookupValues(
                  masterDataEnums?.AntibioticType || [],
                  lookupValues.antibiotics,
                  intl,
                  "MASTERDATA.ENUM.ANITIBIOTIC_USE"
                  )
                }
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="anitibioticsUse"
              // handleLoadMasterData={props.handleLoadMasterData}
              tooltip={ intl.formatMessage( {id: "BASELINE.FORM.SALMON.ANTIBIOTICS_USE.TOOLTIP" }) }
              formState={formType}
              data={anitibioticsUse}
              setData={setAnitibioticsUse}
              disableAddButtonWhenAllOptionSelected
            />

            <SustellDynamicInput key={`${fieldItemPrefix  }.liceTreatment`}
              label={intl.formatMessage({id: "BASELINE.FORM.SALMON.LICE_TREATMENT"})}
              availableOptions={
                  getTranslatedEnumOrDefaultLookupValues(
                    masterDataEnums?.LiceTreatmentType || [],
                    lookupValues.liceTreatment,
                    intl,
                    "MASTERDATA.ENUM.LICE_TREATMENT"
                  )
                }
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="liceTreatment"
              // handleLoadMasterData={props.handleLoadMasterData}
              tooltip={intl.formatMessage({id: "BASELINE.FORM.SALMON.LICE_TREATMENT_TT"})}
              formState={formType}
              data={liceTreatment}
              setData={setLiceTreatment}
              disableAddButtonWhenAllOptionSelected
            />
            
            <Grid item xs={12} >
              <RowTextFieldWithInfo
                // control={control}
                name={`${fieldItemPrefix  }.antifouling`}
                label={intl.formatMessage({id: "BASELINE.FORM.SALMON.ANTIFOULING"})}
                margin="none"
                variant="outlined"
                tooltip={ intl.formatMessage({id: "BASELINE.FORM.SALMON.ANTIFOULING_TT"}) }
                addornment="kg"
                disabled={formType === "view"}
                type="number"
                defaultValue = {getValues(`${fieldItemPrefix  }.antifouling`)}
              />
            </Grid>

          </Grid>
	      </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleCancel }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
          />
        </Grid>
	      </Grid>
    </DialogContainer>
  );
};

export default SOperationsFormDialog;