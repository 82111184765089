import * as Yup from 'yup';
import { defaultUnits } from '../../../../sustell_15/utils/unit-utils';
import {
  baselineValidationSchemaGeneralPartTimeCycle,
  baselineValidationSchemaAvgTemperaturePart,
  usedResourcesPart,
} from './baselineValidationSchemaGeneralPart';
import {
  numericRequiredWithMinMax,
  numericRequiredWithMin,
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  averageLiveweightMortality,
  feedItemsTest,
} from './validationObjectBuilderFunctions';


// stage fields validation rules
const stageDataPartPigFattening = ({
  intl,
  userUOM = defaultUnits,
  minValueOutput = 1,
}) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          stageData: Yup.object({
            output: Yup.object({
              liveWeight: numericRequiredWithMin(intl, minValueOutput),
              inorganicNFertilizerReplacementRate: numericOptionalWithMinMax(
                intl,
                0,
                100
              ),
              inorganicPFertilizerReplacementRate: numericOptionalWithMinMax(
                intl,
                0,
                100
              ),
            }),
            manure: Yup.object({
              averageAnimalsPresent: numericRequiredWithMin(intl, 0),
              manureManagementSystem: Yup.string(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              solidManureStorage: numericRequiredWithMinMax(intl, 0, 100),
              slurryManureStorage: numericRequiredWithMinMax(intl, 0, 100),
              strawForBedding: numericOptionalWithMin(intl, 0),
              sawDust: numericOptionalWithMin(intl, 0),
            }),
            input: Yup.object({
              purchasedPiglets: numericRequiredWithMin(intl, 0),
              averageLiveWeight: numericRequiredWithMin(intl, 0),
              mortalityRate: numericRequiredWithMinMax(intl, 0, 99),
              averageLiveWeightMortalities: averageLiveweightMortality(
                'Pig fattening',
                intl,
                userUOM
              ),
            }),
            feed: Yup.object({
              totalFeedIntake: Yup.number()
                .transform((changed, original) =>
                  original === '' ? undefined : changed
                )
                .required(
                  intl.formatMessage({
                    id: 'VALIDATION.FIELD.REQUIRED_CALCULATED',
                  })
                )
                .moreThan(
                  0,
                  intl.formatMessage(
                    { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN_CALCULATED' },
                    { value: 0 }
                  )
                ),
              digestibility: numericOptionalWithMinMax(intl, 0, 100),
              grossEnergyIntake: numericOptionalWithMin(intl, 0),
              nitrogenContent: numericOptionalWithMinMax(intl, 0, 100),
              compoundFeeds: feedItemsTest(intl, 0),
              singleIngredients: feedItemsTest(intl, 0),
            }),
            emissions: Yup.object({
              methaneEntericFermentation: numericOptionalWithMinMax(
                intl,
                -100,
                100
              ),
              methane: numericOptionalWithMinMax(intl, -100, 100),
              nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
              nitrousOxideIndirect: numericOptionalWithMinMax(intl, -100, 100),
              amonia: numericOptionalWithMinMax(intl, -100, 100),
              nitricOxide: numericOptionalWithMinMax(intl, -100, 100),
              nonMethaneVolatile: numericOptionalWithMinMax(intl, -100, 100),
              PM10: numericOptionalWithMinMax(intl, -100, 100),
              PM25: numericOptionalWithMinMax(intl, -100, 100),
              totalSuspendedParticles: numericOptionalWithMinMax(
                intl,
                -100,
                100
              ),
            }),
          }),
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

// merge all necessary parts to baseSchema
const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema
    .concat(baselineValidationSchemaGeneralPartTimeCycle(confObj)
    .concat(baselineValidationSchemaAvgTemperaturePart(confObj)));
  const combinedSchema = Yup.object({info:infoObject})
    .concat(Yup.object({resourceUse:usedResourcesPart(confObj)}))
    .concat(stageDataPartPigFattening(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;