import { flattenObject, getObjectPropByPath } from '../utils/obj-utils';

interface InputData {
  value: string;
  display_name: string;
}

interface ReturnData {
  [key: string]: {
    typeMessage: string;
    value: string;
  };
}

// Calculation errors messages i18n keys are returned
const calculationErrorDataMapping = {
  error: {
    messageKey: 'CALCULATION_ERROR_MESSAGE.ERROR',
    emission_interventions: {
      messageKey: 'CALCULATION_ERROR_MESSAGE.EMISSION_INTERVENTIONS',
    },
    production_system: {
      messageKey: 'CALCULATION_ERROR_MESSAGE.PRODUCTION_SYSTEM',
      farm: {
        messageKey: 'CALCULATION_ERROR_MESSAGE.FARM',
        geography: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.GEOGRAHY',
          average_annual_temperature: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.AVG_TEMP',
          },
        },
        resource_use: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.RESOURCE_USE',
          gas_use: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.GAS_USE',
          },
          diesel_use: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.DIESEL_USE',
          },
          electricity_use: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.ELECTRICITY_USE',
          },
          water_use: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.FARM.WATER_USE',
          },
        },
        eggs: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.EGGS',
          total_weight: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.EGGS.TOTAL_WEIGHT',
          },
        },
        milk_produced: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.MILK_PRODUCED',
        },
        spent_hens: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.SPENT_HENS',
          total_weight: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.SPENT_HENS.TOTAL_WEIGHT',
          },
        },
        livestock: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.LIVESTOCK',
          total_weight: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.LIVESTOCK.TOTAL_WEIGHT',
          },
        },
        fattened_pigs: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.FATTENED_PIGS',
          total_weight: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.FATTENED_PIGS.TOTAL_WEIGHT',
          },
        },
        broilers_to_slaughter: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.BROILERS_TO_SLAUGHTER',
          total_weight: {
            messageKey:
              'CALCULATION_ERROR_MESSAGE.BROILERS_TO_SLAUGHTER.TOTAL_WEIGHT',
          },
        },
        housing_systems: {
          messageKey: 'CALCULATION_ERROR_MESSAGE.HOUSING_SYSTEMS',
          ration: {
            messageKey: 'CALCULATION_ERROR_MESSAGE.HOUSING_SYSTEMS.RATION',
            single_ingredient_uses: {
              messageKey:
                'CALCULATION_ERROR_MESSAGE.HOUSING_SYSTEMS.RATION.SINGLE_INGREDIENT',
              entity_id: {
                messageKey:
                  'CALCULATION_ERROR_MESSAGE.HOUSING_SYSTEMS.RATION.SINGLE_INGREDIENT.ENTITY_ID',
              },
            },
            compound_feed_uses: {
              messageKey:
                'CALCULATION_ERROR_MESSAGE.HOUSING_SYSTEMS.RATION.COMPOUND_FEED',
              entity_id: {
                messageKey:
                  'CALCULATION_ERROR_MESSAGE.HOUSING_SYSTEMS.RATION.COMPOUND_FEED.ENTITY_ID',
              },
            },
          },
        },
      },
    },
  },
};

export const getErrorMessageMapping = (keyRaw: string) => {
  // Remove indexes and form key suitable for reading calculatioErrorDataMapping
  const key = keyRaw.replace(/\.\d+/g, '');

  const keyPartials = key.split('.');
  // Try to read message from path
  while (keyPartials.length > 0) {
    const k = `${keyPartials.join('.')}.messageKey`;
    const message = getObjectPropByPath(calculationErrorDataMapping, k) as unknown as string;
    if (message) {
      return message;
    }
    // If not message found go one level up in obj hierarchy
    keyPartials.pop();
  }
  // If nothing found reurn the most generic error
  return 'CALCULATION_ERROR_MESSAGE.ERROR';
};

export const getCalculationErrors = (
  errorData: object,
  singleIngredients: InputData[] | undefined,
  compoundFeeds: InputData[] | undefined,
  unknownIngredientTranslation = 'Unknown ingredient with ID: ',
  unknownCFTranslation = 'Unknown compound feed with an ID: '
) => {
  const retVal: ReturnData = {};
  const flatErrData = flattenObject(errorData);
  Object.entries(flatErrData).forEach((entry: [string, string]) => {
    const [key, value] = entry;
    let updatedValue = value;
    const typeMessage = getErrorMessageMapping(key);
    // For compound feeds try to map id to the name
    if (
      key.indexOf('compound_feed_uses') > -1 &&
      key.indexOf('entity_id') > -1 &&
      value.match('^"(.*?)"') &&
      compoundFeeds
    ) {
      const matchArray = value.match('^"(.*?)"');
      if (matchArray && matchArray.length > 1) {
        const id = matchArray[1];
        const name = compoundFeeds?.find((cf) => id === cf.value)?.display_name;
        if (!name) {
          updatedValue = `${unknownCFTranslation} ${value}`;
        } else {
          updatedValue = value.replace(id, name);
        }
      }
      // For single ingredients try to map id to the name
    } else if (
      key.indexOf('single_ingredient_uses') > -1 &&
      key.indexOf('entity_id') > -1 &&
      value.match('^"(.*?)"') &&
      singleIngredients
    ) {
      const matchArray = value.match('^"(.*?)"');
      if (matchArray && matchArray.length > 1) {
        const id = matchArray[1];
        const name = singleIngredients?.find(
          (cf) => id === cf.value
        )?.display_name;
        if (!name) {
          updatedValue = `${unknownIngredientTranslation} ${value}`;
        } else {
          updatedValue = value.replace(id, name);
        }
      }
    }
    retVal[key] = { typeMessage, value: updatedValue };
  });
  return retVal;
};
