import { FC, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Grid } from "@material-ui/core";
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup";

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { SalmonBaseline, SalmonInput } from '../../../../models/Baseline/SalmonBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { BaselineDialogProps, FormType } from '../../common';

const SInputFormDialog: FC<BaselineDialogProps> = ({ 
  formType=FormType.Add,
  itemIndex=0, 
  formVisible=false,
  handleCancel, 
} ) => {

  const intl = useIntl();
  const currResetValue = useRef('')

  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`; 

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { reset, getValues, setValue, control } = useFormContext<SalmonBaseline>();
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE"}) ;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const numOfSmolts = useWatch({
    control,
    name: `${fieldItemPrefix  }.numberOfStockedAnimals`
  });
  
  const avgWeightOfSmolt = useWatch({
    control,
    name: `${fieldItemPrefix  }.averageLiveWeightStockedAnimals`
  });

  if(numOfSmolts && avgWeightOfSmolt){
    setValue(`${fieldItemPrefix  }.weightOfStockedAnimals`, Math.round(Number(numOfSmolts)*Number(avgWeightOfSmolt)*100) / 100 );
  // We canot have just else {... here because it would set value for stages.0.stageData.input.weightOfStockedAnimals
  // immediately, which means already having  value for the first stage, before it's actually added..so after the real stage is apended
  // if in some moment getValues() include weightOfStockedAnimals field (here on handleResetClick() or saveStage() - for second stage)
  // it would return two times two stages.0.stageData...
  } else if ((numOfSmolts && !avgWeightOfSmolt) || (!numOfSmolts && avgWeightOfSmolt)) {
    setValue(`${fieldItemPrefix  }.weightOfStockedAnimals`, "" );
  }

  useEffect(() => {
    if(formVisible){
      const serializedData = JSON.stringify(getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    // console.log("resetValue input:", currResetValue.current);
    if(currResetValue.current){
      const resetObject = {...getValues()} as SalmonBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.input){
        const val = JSON.parse(currResetValue.current) as SalmonInput;
        resetObject.stages[itemIndex].stageData.input = val;
        reset(resetObject,{
          errors: true
        });
      }
    }
    if (handleCancel) handleCancel();
  }

  return (
    <DialogContainer
      formVisible ={ formVisible }
      handleClose = { handleResetClick }
      formTitle = {formTitle}
      iconCode="SALMON_INPUT"
    > 
      
      <Grid container direction="column" spacing={3} justifyContent="space-between" >
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.numberOfStockedAnimals`}
            type="number"
            label={`${intl.formatMessage({id: "BASELINE.FORM.SALMON.NUMBER_SMOLT_STOCKED"})} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({id: "BASELINE.FORM.SALMON.NUMBER_SMOLT_STOCKED_TT"})}
            disabled={formType === "view"}
            defaultValue = {getValues(`${fieldItemPrefix  }.numberOfStockedAnimals`)}
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.averageLiveWeightStockedAnimals`}
            type="number"
            label={`${intl.formatMessage({id: "BASELINE.FORM.SALMON.AVG_STOCK_ANIMAL_MASS"})} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({id: "BASELINE.FORM.SALMON.AVG_STOCK_ANIMAL_MASS_TT"})}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
            disabled={formType === "view"}
            defaultValue = {getValues(`${fieldItemPrefix  }.averageLiveWeightStockedAnimals`)}
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.weightOfStockedAnimals`}
            type="number"
            label={`${intl.formatMessage({id: "BASELINE.FORM.SALMON.STOCKED_BIOMASS"})} *`}
            margin="none"
            variant="outlined"
            tooltip=""
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
            readonly
            defaultValue = {getValues(`${fieldItemPrefix  }.weightOfStockedAnimals`)}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleCancel }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default SInputFormDialog;