import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import { unitLong } from '../../../sustell_15/utils/unit-utils';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

const SustellNewBoatInput = props => {
  const intl = useIntl();
  const { fieldItemPrefix, subFieldName, index, formState } = props;
  const { control, errors, getValues } = useFormContext();

  const resolvePath = (object, path, defaultValue) =>
    path
      .split(/[.[\]'"]/)
      .filter(p => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  const fieldBoatName =
    fieldItemPrefix + '.' + subFieldName + '[' + index + '].boatName';
  const fieldFuelType =
    fieldItemPrefix + '.' + subFieldName + '[' + index + '].type';
  const fieldFuelAmount =
    fieldItemPrefix + '.' + subFieldName + '[' + index + '].amount';

  const localFuelAmountError = resolvePath(errors, fieldFuelAmount, null);
  const localBoatError = resolvePath(errors, fieldBoatName, null);
  const localFuelTypeError = resolvePath(errors, fieldFuelType, null);
  const itemsLoaded = props.availableOptions?.some(x => x.type !== '');
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  let fuelUOM = userUOM?.unitDieselUse;

  const updateFuelType = fuelType => {
    if (fuelType === 'electricity') fuelUOM = userUOM?.unitElectricityUse;
    else fuelUOM = userUOM?.unitDieselUse;
  };

  const fieldValue = (fieldName) => {
    const value = getValues(fieldName);
    if(value === null || value === undefined) return '';
    return value;
  }

  return (
    <>
      <Grid
        item
        xs={4}
        className="d-flex align-items-center"
        key={
          fieldItemPrefix + '.' + subFieldName + '[' + index + ']_inputPart1'
        }
      >
        <Controller
          name={fieldBoatName}
          defaultValue={fieldValue(fieldBoatName)}
          control={control}
          render={({ onChange, onBlur, value, name, ref }) => {
            return (
              <TextField
                name={fieldBoatName}
                onChange={onChange}
                inputRef={ref}
                style={{ marginBottom: '1.2rem' }}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.SALMON.BOAT_NAME',
                })}
                onBlur={onBlur}
                value={value && itemsLoaded ? value : ''}
                helperText={
                  localBoatError && localBoatError.message
                    ? localBoatError.message
                    : ''
                }
                error={Boolean(localBoatError)}
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={formState === 'view' ? true : false}
              />
            );
          }}
        />
      </Grid>
      <Grid
        item
        xs={3}
        className="d-flex align-items-center"
        key={
          fieldItemPrefix + '.' + subFieldName + '[' + index + ']_inputPart2'
        }
      >
        <FormControl
          variant="outlined"
          style={{ marginBottom: 0, width: '95%', paddingBottom: 0 }}
        >
          <InputLabel error={Boolean(localFuelTypeError)}>
            {intl.formatMessage({ id: 'BASELINE.FORM.SALMON.FUEL_TYPE' })}
          </InputLabel>

          <Controller
            control={control}
            name={fieldFuelType}
            defaultValue={fieldValue(fieldFuelType)}
            render={({ onChange, onBlur, value, name, ref }) => {
              updateFuelType(value);
              return (
                <>
                  <Select
                    fullWidth
                    name={name}
                    label={intl.formatMessage({
                      id: 'BASELINE.FORM.SALMON.FUEL_TYPE',
                    })}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value && itemsLoaded ? value : ''}
                    error={Boolean(localFuelTypeError)}
                    disabled={formState === 'view' ? true : false}
                  >
                    {props.availableOptions.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(localFuelTypeError)}>
                    {localFuelTypeError && localFuelTypeError.message
                      ? localFuelTypeError.message
                      : ''}
                  </FormHelperText>
                </>
              );
            }}
          />
        </FormControl>
      </Grid>

      <Grid
        item
        xs={3}
        className="d-flex align-items-center"
        key={
          fieldItemPrefix + '.' + subFieldName + '[' + index + ']_inputPart3'
        }
      >
        <Controller
          name={fieldFuelAmount}
          defaultValue={fieldValue(fieldFuelAmount)}
          control={control}
          render={({ onChange, onBlur, value, name, ref }) => {
            return (
              <TextField
                name={fieldFuelAmount}
                onChange={onChange}
                inputRef={ref}
                style={{ marginBottom: '1.2rem' }}
                label={
                  intl.formatMessage({ id: 'BASELINE.FORM.SALMON.FUEL_USE' }) +
                  ' (' +
                  unitLong(fuelUOM) +
                  ') *'
                }
                onBlur={onBlur}
                value={value && itemsLoaded ? value : ''}
                helperText={
                  localFuelAmountError && localFuelAmountError.message
                    ? localFuelAmountError.message
                    : ''
                }
                error={Boolean(localFuelAmountError)}
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={formState === 'view' ? true : false}
                type="number"
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
export default SustellNewBoatInput;
