import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';

import { emissionFieldsMapOut } from '../../Intervention/mapper/emissionFields';
import { floatValueInDefaultUnit, setFloatValue } from './floatHelperFunctions'; 
import { v4 as uuidv4 } from 'uuid';

const createCorePayloadSustell = (formData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const defaultCycles = !isDraft ? 1 : undefined;
  const defaultReportingPeriod = 'YEAR';


  const payloadObj = {
    name: formData.info.name,
    year: parseInt(formData.info.year),
    description: formData.info.description,
    reportingPeriod: formData.info.timeUnit || defaultReportingPeriod,
    roundsPerYear: formData.info.numOfCyclesYear || defaultCycles,
    validFrom: formData.info.validFrom,
    validTo: formData.info.validTo,
    resourceUse:{},
    stages:[],
    // not used in old baselines, but needed for vtl and GSI compatibility with new ones
    databaseFoundation: 'AFP_5',
  };

  if(formData.info.oldName)
    payloadObj.oldName = formData.info.oldName;

  if (formData.info.avgAnualTemperature || formData.info.avgAnualTemperature === 0)
    payloadObj.averageAnnualTemperature = floatValueInDefaultUnit(
      formData.info.avgAnualTemperature,
      userUOM.unitAverageAnnualTemperature,
      defaultMetric.unitAverageAnnualTemperature,
      1
    );

  return payloadObj;
};

export const mapResourceUseSustell = (
  formData,
  payloadObj,
  resources = ['diesel', 'gas', 'electricity', 'water']
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (resources?.includes('gas'))
    setFloatValue(
      payloadObj.resourceUse,
      'gasUse',
      formData.resourceUse,
      'gasUse',
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse
    );

  if (resources?.includes('diesel'))
    setFloatValue(
      payloadObj.resourceUse,
      'dieselUse',
      formData.resourceUse,
      'dieselUse',
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse
    );

  if (resources?.includes('electricity'))
    setFloatValue(
      payloadObj.resourceUse,
      'electricityUse',
      formData.resourceUse,
      'electricityUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (resources?.includes('water'))
    setFloatValue(
      payloadObj.resourceUse,
      'waterUse',
      formData.resourceUse,
      'waterUse',
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse
    );

    if (resources?.includes('waterSource'))
      payloadObj.resourceUse.waterSource = resources.waterSource;

  return payloadObj;
};

const mapBeddingForOneStage = (manureBeddingFormData, stageBeddingData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    'strawForBedding' in manureBeddingFormData &&
    !isNaN(parseFloat(manureBeddingFormData.strawForBedding))
  )
    stageBeddingData.straw_for_bedding = {
      value: floatValueInDefaultUnit(
        manureBeddingFormData.strawForBedding,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      )
    };

  if (
    'sawDust' in manureBeddingFormData &&
    !isNaN(parseFloat(manureBeddingFormData.sawDust))
  )
    stageBeddingData.saw_dust = {
      value: floatValueInDefaultUnit(
        manureBeddingFormData.sawDust,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      )
    };

  return stageBeddingData;
};

const mapManureManagementForOneStage = (manureBeddingFormData, stageManureData) => {
  if (manureBeddingFormData.manureManagementSystem)
    stageManureData.manure_management_system =
      manureBeddingFormData.manureManagementSystem;

  if (
    'solidManureStorage' in manureBeddingFormData &&
    !isNaN(parseFloat(manureBeddingFormData.solidManureStorage))
  )
    stageManureData.solid_manure_storage_on_farm = {
      value: parseFloat(manureBeddingFormData.solidManureStorage),
    };

  if (
    'slurryManureStorage' in manureBeddingFormData &&
    !isNaN(parseFloat(manureBeddingFormData.slurryManureStorage))
  )
    stageManureData.slurry_manure_storage_on_farm = {
      value: parseFloat(manureBeddingFormData.slurryManureStorage),
    };

  if (
    'ashContent' in manureBeddingFormData &&
    !isNaN(parseFloat(manureBeddingFormData.ashContent))
  )
    stageManureData.ash_content = {
      value: parseFloat(manureBeddingFormData.ashContent),
    };

  return stageManureData;
};

const mapCompoundFeedForOneStage = (feedFormData, stageFeedData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    feedFormData.compoundFeeds &&
    Array.isArray(feedFormData.compoundFeeds)
  ) {
    stageFeedData.compound_feed_uses = [];
    feedFormData.compoundFeeds.forEach(oneFeedItem => {
      if (oneFeedItem.feedType && 'kgPerAnimal' in oneFeedItem) {
        const value = floatValueInDefaultUnit(
          oneFeedItem.kgPerAnimal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        const oneCompoundInput = {
          entity_id: oneFeedItem.feedType,
          quantity: value,
        };
        stageFeedData.compound_feed_uses.push(oneCompoundInput);
      }
    });
  }
  return stageFeedData;
};

const mapSingleIngridientsForOneStage = (feedFormData, stageFeedData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    feedFormData.singleIngredients &&
    Array.isArray(feedFormData.singleIngredients)
  ) {
    stageFeedData.single_ingredient_uses = [];
    feedFormData.singleIngredients.forEach(oneFeedItem => {
      if (oneFeedItem.feedType && 'kgPerAnimal' in oneFeedItem) {
        const value = floatValueInDefaultUnit(
          oneFeedItem.kgPerAnimal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        const oneSingleIngredientInput = {
          entity_id: oneFeedItem.feedType,
          quantity: value,
        };
        stageFeedData.single_ingredient_uses.push(
          oneSingleIngredientInput
        );
      }
    });
  }

  return stageFeedData;
};

const mapRationForOneStage = (feedFormData, stageFeedData) => {
  // adds nutrition fields to output data
  if (
    'digestibility' in feedFormData &&
    !isNaN(parseFloat(feedFormData.digestibility))
  )
  stageFeedData.digestibility = {
      value: parseFloat(feedFormData.digestibility),
    };

  if (
    'grossEnergyIntake' in feedFormData &&
    !isNaN(parseFloat(feedFormData.grossEnergyIntake))
  )
    stageFeedData.gross_energy_intake = {
      value: parseFloat(feedFormData.grossEnergyIntake),
    };

  if (
    'crudeProteinContent' in feedFormData &&
    !isNaN(parseFloat(feedFormData.crudeProteinContent))
  )
    stageFeedData.crude_protein_content = {
      value: parseFloat(feedFormData.crudeProteinContent),
    };

  if (
    'percentageOfSilage' in feedFormData &&
    !isNaN(parseFloat(feedFormData.percentageOfSilage))
  )
    stageFeedData.silage_in_feed = {
      value: parseFloat(feedFormData.percentageOfSilage),
    };

  if (
    'nitrogenContent' in feedFormData &&
    !isNaN(parseFloat(feedFormData.nitrogenContent))
  )
    stageFeedData.nitrogen_content = {
      value: parseFloat(feedFormData.nitrogenContent),
    };
  if (
    'dryMatterContent' in feedFormData &&
    !isNaN(parseFloat(feedFormData.dryMatterContent))
  )
    stageFeedData.dry_matter_content = {
      value: parseFloat(feedFormData.dryMatterContent),
    };

  mapCompoundFeedForOneStage(feedFormData, stageFeedData);
  mapSingleIngridientsForOneStage(feedFormData, stageFeedData);
};

const mapEmissionForOneStage = (emissionFormData, emissionDataForOneStage) => {
  //now check the emission fields
  emissionFieldsMapOut.forEach((value, key) => {
    if (key in emissionFormData) {
      const valueNum = parseFloat(emissionFormData[key]);
      emissionDataForOneStage.push({
        key: value,
        action: 'update',
        value: {
          value: valueNum,
          change_type: 'relative',
        },
      });
    }
  });
};

const mapCommonStageData = (formData, payloadObj) => {
  if (formData.stages && Array.isArray(formData.stages)) {
    
    for (let i = 0; i < formData.stages.length; i++) {
      const generalDataForStage = formData.stages[i];
      const formDataForStage = generalDataForStage.stageData || {}
      const oneStageData = {
        name: generalDataForStage.name,
        id: generalDataForStage.id || uuidv4(),
        stageData:{
          animal_group:{},
          ration:{}
        }
      };
      if(formDataForStage.feed)
        mapRationForOneStage(formDataForStage.feed, oneStageData.stageData.ration);
      if('emissions' in formDataForStage){
        oneStageData.stageData.emissions=[];
        if(formDataForStage.emissions)
          mapEmissionForOneStage(formDataForStage.emissions, oneStageData.stageData.emissions);
      }
      if('manure' in formDataForStage){
        oneStageData.stageData.bedding = {};
        oneStageData.stageData.manure_management = {};
        if(formDataForStage.manure){
          mapBeddingForOneStage(formDataForStage.manure,oneStageData.stageData.bedding);
          mapManureManagementForOneStage(formDataForStage.manure,oneStageData.stageData.manure_management);
        }
      }
      payloadObj.stages.push(oneStageData);
    }
  }
};

export const mapCommonProductionDataOutSustell = (formData, isDraft = false) => {
  const payloadObj = createCorePayloadSustell(formData, isDraft);
  mapCommonStageData(formData, payloadObj)
  return payloadObj;
};
