import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { DsmFieldset } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../../modules/Helpers/UserProfilePrefs';

import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { PigFatteningBaseline, PigFatteningOutput } from '../../../../../models/Baseline';
import { DialogContainer } from '../../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../../common';

import { processAndStageStylesV2 } from '../../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../../helpers/DsmButtonControlGroup';

const PFOutputFormDialog: FC<BaselineDialogProps> = ( {
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel,
  handleSave=handleCancel
} ) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const classes = processAndStageStylesV2() as any;
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PigFatteningOutput>();

  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  // const { getValues, reset } = useFormContext();
  const formContext = useFormContext();

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as PigFatteningBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.output){
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        formContext.reset(resetObject, {
          errors: true
        });
      }
    }
    handleCancel('reset');
  }

  useEffect(() => {
    if(formVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currResetValue.current = cloneDeep(formContext.getValues(fieldItemPrefix));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);
  
  return (
    <DialogContainer
      formVisible = { formVisible }
      handleClose = { handleResetClick }
      iconCode="arrows/arrow-circle-broken-right"
      formTitle = { formTitle }
    > 
      <DsmFieldset >
        <ReactHookDsmInput
          name={`${fieldItemPrefix  }.averageAgePigs`}
          label={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.AGE.PIGS"})}
          adornment={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.AGE.PLACEHOLDER"}) }
          disabled={formType === FormType.View}
          required
          type="number"
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.averageAgePigs`) }
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix  }.averageWeightPigs`}
          label={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.WEIGHT.PIGS"})}
          adornment={userUOM.unitBarnOutputMass}
          disabled={formType === FormType.View}
          required
          type="number"
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.averageWeightPigs`) }
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix  }.mortalityPigs`}
          label={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.MORTALITY.PIGS"})}
          adornment={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.MORTALITY.PLACEHOLDER"}) }
          disabled={formType === FormType.View}
          required
          type="number"
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.mortalityPigs`) }
        />
      </DsmFieldset>
      <DsmFieldset >
        <ReactHookDsmInput
          name={`${fieldItemPrefix  }.pigsToStage`}
          label={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.PIGS.TO_ANOTHER_STAGE"})}
          adornment={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.TRANSFER_TO_STAGE.PLACEHOLDER"}) }
          tooltip={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.PIGS.TO_ANOTHER_STAGE.TOOLTIP"}) }
          disabled={formType === FormType.View}
          type="number"
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.pigsToStage`) }
        />
      </DsmFieldset>
      <DsmFieldset >
        <ReactHookDsmInput
          name={`${fieldItemPrefix  }.soldPigs`}
          label={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.PIGS.SOLD"})}
          adornment={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.SOLD.PLACEHOLDER"}) }
          disabled={formType === FormType.View}
          type="number"
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.soldPigs`) }
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix  }.priceSoldPigs`}
          label={ intl.formatMessage({ id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.PIGS.PRICE_SOLD"})}
          adornment={intl.formatMessage({id: "SUSTELL.STAGE.PIGS.FATTENING.OUTPUT.PRICE_SOLD.PLACEHOLDER"})}
          type="number"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.priceSoldPigs`) }
        />
      </DsmFieldset>
      <DsmButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ async () => { await formContext.trigger(`stages.${itemIndex}.stageData.output`); handleCancel('confirm') } }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }/>
    </DialogContainer>
  );
};

export default PFOutputFormDialog;