/* eslint-disable react/require-default-props */
import { type FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import { FeedFormFields } from '../types';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';

interface IngredientsTotalSummaryFieldProps {
  inputUnit?: string;
  outputUnit?: string;
  baseSum?: number;
  formFieldName?: string;
}

// TODO: maybe we should turn off this rule(no corresponding defaultProps declaration), look at the answer: https://stackoverflow.com/questions/69687167/proptype-name-is-not-required-but-has-no-corresponding-defaultprops-declarati
const IngredientsTotalSummaryField: FC<IngredientsTotalSummaryFieldProps> = ({
  inputUnit = 'kg',
  outputUnit = 'tonne',
  baseSum = 1,
  formFieldName = 'quantitySum',
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { watch, control, trigger, errors } = useFormContext<FeedFormFields>();

  const quantitySum = watch('quantitySum') ?? 0;
  trigger('quantitySum').catch((e) => console.log(e));
  const totalFeedQuantityErrorMessage = errors.quantitySum?.message;
  const invalidTotalSum = totalFeedQuantityErrorMessage !== undefined;
  const totalWord = intl.formatMessage({ id: 'GENERAL.TOTAL' });
  const feedWord = intl.formatMessage({ id: 'GENERAL.FEED' });

  return (
    <Grid container key="cfIngredientsSummaryField">
      <Grid
        item
        xs={8}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      />
      <Grid
        item
        xs={4}
        style={{
          borderTop: '1px solid #AFBCCF',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Grid container justifyContent="flex-start" style={{ paddingTop: 6 }}>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: 10,
              color: 'red',
            }}
          >
            {totalFeedQuantityErrorMessage}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          style={{ paddingTop: 12 }}
        >
          <Controller
            control={control}
            defaultValue={0}
            name={formFieldName}
            render={({ value, name }) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <input type="hidden" name={name} value={value} />
            )}
          />
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 20,
              color: invalidTotalSum ? 'red' : 'inherit',
            }}
          >
            {totalWord}
            {invalidTotalSum && (
              <InfoOutlinedIcon
                style={{
                  margin: '0 0 0.2em 0.4em',
                  color: 'red',
                }}
              />
            )}
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: 20,
            }}
          >
            {quantitySum} {inputUnit} / {baseSum} {outputUnit}{' '}
            {feedWord.toLowerCase()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IngredientsTotalSummaryField;
