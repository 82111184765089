import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BrolierInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { FormType } from '../../common';
import {
  BroilerIntervention,
  BroilerOutput,
} from '../../../../models/Intervention';

const BInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: BrolierInterventionFormDialogProps) => {
  const intl = useIntl();

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const outputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access  
  const unitOutputMass = userUOM?.unitOutputMass
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ? unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  const currResetValue = useRef<BroilerOutput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...(fc.getValues(fieldItemPrefix) as BroilerOutput),
      }; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as BroilerIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.output) {
        stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output;

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="BROILER"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <Grid container direction="column" spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.liveWeight`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.BROILERS.LIVEWEIGHT',
            })}
            tooltip={
              <div>
                {intl.formatMessage({
                  id: 'INTERVENTION.FORM.OUTPUT.BROILERS.LIVEWEIGHT.TOOLTIP',
                })}
              </div>
            }
            metricUnit={unitOutputMass}
            precision={outputMassPrecision}
            baseline={output?.liveWeight}
            margin="none"
            disabled={formType === 'view'}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default BInterventionOutputFormDialog;
