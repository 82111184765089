import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormHelperText, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import { DsmIcon } from '@dsm-dcs/design-system-react';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute',
  height: '16px',
  width: '16px',
  top: '29px',
  right: '30px',
};

const useStyles = makeStyles({
  root: {
    minHeight: '74px',
    // minWidth: '200px',
    '& input': {
      marginTop: '-4px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      height: '12px',
      lineHeight: '28px',
    },
    '& label': {
      position: 'relative',
      width: '125% !important',
      marginBottom: '9px',
      fontSize: '16px',
      fontWeight: '500',
    },
    '& legend': {
      display: 'none',
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-root': {
      '& div.MuiInputAdornment-positionEnd': {
        '& p': {
          fontSize: '13px',
          marginBottom: '3px',
        },
      },
      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px !important',
    },
  },
});

const ControlledAutocompleteDsm2 = (props) => {
  const {
    name,
    control,
    options,
    getOptionLabel,
    label,
    multiple,
    placeholder = '',
    tooltip = '',
    disabled,
    defaultValue,
    onChange: onChangeEvent = null,
    required,
    // className = '',
    // classes = null,
  } = props;
  const { errors } = useFormContext();
  const classes = useStyles();
  // console.log(errors);
  // console.log('name, vals', { name, control, options, getOptionLabel, label, multiple, disabled, defaultValue });
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Controller
        name={name}
        // eslint-disable-next-line no-shadow
        render={({ onChange, value, ...props }) => (
          <>
            <Autocomplete
              className={classes.root}
              multiple={multiple}
              options={options}
              getOptionLabel={getOptionLabel}
              disableClearable
              popupIcon={<KeyboardArrowDown />}
              value={value}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  className={classes.root}
                  error={!!errors[name]}
                  label={`${label} ${required ? '*' : ''}`}
                  variant="outlined"
                  placeholder={placeholder}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                    variant: 'standard',
                    disableAnimation: true,
                    style: {
                      width: '100%',
                    },
                  }}
                />
              )}
              error={errors[name]}
              onChange={(e, data) => {
                onChange(data);
                if (onChangeEvent) onChangeEvent(data);
              }}
              disabled={disabled}
              // className={className}
              // classes={classes}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />

            <FormHelperText error={!!errors[name]}>
              {errors[name] ? errors[name].message : ''}
            </FormHelperText>
          </>
        )}
        defaultValue={multiple && !defaultValue ? [] : defaultValue}
        control={control}
        onChange={([, data]) => data}
      />
      {tooltip && (
        <Tooltip title={tooltip} placement="left">
          <DsmIcon
            name="general/help-circle"
            style={inlineTooltipClass}
            title=""
          />
        </Tooltip>
      )}
    </div>
  );
};
export default ControlledAutocompleteDsm2;
