import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';

import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { PigFatteningEmission } from '../../../../models/Baseline';
import { PigFatteningIntervention } from '../../../../models/Intervention';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { PigFatteningInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const PFInterventionEmissionsFormDialog = ({
  formVisible,
  formType,
  stageIndex,
  handleCancel,
  baseline,
}: PigFatteningInterventionFormDialogProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;
  const currResetValue = useRef<PigFatteningEmission>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.emissions`;

  const fc = useFormContext();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PigFatteningIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.emissions) {
        stageData.emissions = { ...currResetValue.current };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const welcomeString = intl.formatMessage({ id: 'EMISSIONS.WELCOME' });
  const emissions = baseline?.stages?.[stageIndex]?.stageData?.emissions;

  useEffect(() => {
    if (formVisible) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {};
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      datasetType="intervention"
      handleClose={handleResetClick}
      iconCode="maps-travel/globe-04"
      formTitle={formTitle}
      introText={intl.formatMessage(
        { id: 'EMISSIONS.INTRO' },
        { boldedWelcome: <strong>{welcomeString}</strong>, br: <br /> }
      )}
    >
      <DsmGrid className={classes.dsmGridTwoColumnIntervention} >
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.methaneEntericFermentation`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.methaneEntericFermentation}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.methane`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.METHANE',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.METHANE.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.methane}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitrousOxideDirect`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NOx_DIRECT',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.nitrousOxideDirect}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitrousOxideIndirect`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.nitrousOxideIndirect}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.amonia`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.amonia}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitricOxide`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NOx_MANURE',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NOx_MANURE.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.nitricOxide}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nonMethaneVolatile`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.nonMethaneVolatile}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.PM10`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.PM10',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.PM10.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.PM10}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.PM25`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.PM2_5',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.PM2_5.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.PM25}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.totalSuspendedParticles`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.totalSuspendedParticles}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
         <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.ammoniaHousing`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA_HOUSING',
          })}
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.EMISSIONS.AMMONIA_HOUSING.TOOLTIP',
          })}
          type="number"
          metricUnit="%"
          baseline={emissions?.ammoniaHousing}
          disabled={formType === 'view'}
          minAllowedValue={-100}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={ handleCancel }
        saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
      />
    </DialogContainer>
  );
};

export default PFInterventionEmissionsFormDialog;
