// TODO generate typescript queries and mutations to remove all unsafe disables.
// TODO upgrade to aws-amplify v6 and tanstack V5
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-console */
import {
  useMutation,
  QueryClient,
  useQuery,
  UseQueryResult,
  UseMutationResult, UseInfiniteQueryResult, useInfiniteQuery,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { Dispatch, SetStateAction } from 'react';
import {
  getCompoundFeedList,
  getCustomerBriefBaselinesInterventions,
  listCompoundFeedsV2,
} from '../graphql/queries';
import {
  ModelCompoundFeedConnection,
  ModelCompoundFeedConnectionV2,
  Customer,
  CompoundFeedDeleteResult,
} from '../graphql/types';
import {
  deleteCompoundFeed,
  deleteCompoundFeedV2,
  deleteFarm,
  duplicateFarm,
} from '../graphql/mutations';
import { NotificationDialogType } from '../app/sustell_15/components/helpers/NotificationDialog';
import { DialogProps } from '../app/sustell_15/components/CompoundFeeds/CompoundFeeds.types';
import { GraphQLResult } from '@aws-amplify/api-graphql';

const CUSTOMER_FARMS_KEY = 'customerFarms';
const COMPOUND_FEEDS_KEY = 'compoundFeeds';
const COMPOUND_FEEDS_KEY_V2 = 'compoundFeedsV2';
interface Notification {
  type: NotificationDialogType;
  message: string;
}

type GetCustomerBriefBaselinesInterventions = {
  data: {
    getCustomer: Customer;
  };
};

export const useFetchCustomerFarms = (
  customerId: string
): UseQueryResult<GetCustomerBriefBaselinesInterventions, Error> =>
  useQuery<GetCustomerBriefBaselinesInterventions, Error>({
    queryKey: [CUSTOMER_FARMS_KEY],
    queryFn: async () =>
      (await API.graphql({
        query: getCustomerBriefBaselinesInterventions,
        variables: {
          id: customerId,
        },
      })) as GetCustomerBriefBaselinesInterventions,
  });

type GetCompoundFeeds = {
  data: {
    getCompoundFeedList: ModelCompoundFeedConnection;
  };
};

export type GetCompundFeedsV2 = {
  data: {
    listCompoundFeedsV2: ModelCompoundFeedConnectionV2;
    nextToken?: string;
  };
};

export const useFetchCompoundFeeds = (
  customerId: string
): UseQueryResult<GetCompoundFeeds, Error> =>
  useQuery<GetCompoundFeeds, Error>({
    queryKey: [COMPOUND_FEEDS_KEY],
    queryFn: async () =>
      (await API.graphql({
        query: getCompoundFeedList,
        variables: {
          customerId,
        },
      })) as GetCompoundFeeds,
  });

export const useFetchCompoundFeedsV2 = (
  customerId: string
): UseQueryResult<GetCompundFeedsV2, Error> =>
  useQuery<GetCompundFeedsV2, Error>({
    queryKey: [COMPOUND_FEEDS_KEY_V2],
    queryFn: async () =>
      (await API.graphql({
        query: listCompoundFeedsV2,
        variables: {
          customerId,
        },
      })) as GetCompundFeedsV2,
  });


export type GetAllCompoundFeedsV2 = {
  listCompoundFeedsV2: ModelCompoundFeedConnectionV2;
};

export const useFetchAllCompoundFeedsV2 = (
  customerId: string
): UseInfiniteQueryResult<GetAllCompoundFeedsV2, Error> =>
  useInfiniteQuery<GetAllCompoundFeedsV2, Error>({
    queryKey: [COMPOUND_FEEDS_KEY_V2, customerId],
    queryFn: async ({ pageParam }) => {
      const response = await API.graphql({
        query: listCompoundFeedsV2,
        variables: {
          customerId,
          nextToken: pageParam,
        },
      }) as GraphQLResult<GetAllCompoundFeedsV2>;
      if (!response.data) {
        throw new Error('No data returned');
      }
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.listCompoundFeedsV2.nextToken ?? null;
    },
  });

type FarmIDResult = {
  id: string;
};

type DeleteFarmVariables = { customerId: string; farmId: string };

const deleteFarmOperation = async ({
  customerId,
  farmId,
}: DeleteFarmVariables): Promise<FarmIDResult> => {
  const result = (await API.graphql({
    query: deleteFarm,
    variables: {
      customerId,
      farmId,
    },
  })) as GraphQLResult<{ deleteFarm: FarmIDResult}>;
  return result.data?.deleteFarm as FarmIDResult;
};

export const useDeleteFarm = (
  queryClient: QueryClient
): UseMutationResult<FarmIDResult, Error, DeleteFarmVariables> =>
  useMutation({
    mutationFn: (variables: DeleteFarmVariables) =>
      deleteFarmOperation(variables),
    onSuccess: async () => {
      await queryClient.invalidateQueries([CUSTOMER_FARMS_KEY]);
    },
    onError: (error) => {
      console.error('Error adding item:', error);
    },
  });

const duplicateFarmOperation = async ({
  customerId,
  farmId,
}: DuplicateFarmVariables): Promise<FarmIDResult> => {
  const result = (await API.graphql({
    query: duplicateFarm,
    variables: {
      customerId,
      farmId,
    },
  })) as GraphQLResult<{ duplicateFarm: FarmIDResult }>;
  return result.data?.duplicateFarm as FarmIDResult;
};

type DuplicateFarmVariables = { customerId: string; farmId: string };

export const useDuplicateFarm = (
  queryClient: QueryClient,
  errorMessage: string,
  setNotification: Dispatch<SetStateAction<null | Notification>>
): UseMutationResult<FarmIDResult, Error, DuplicateFarmVariables> =>
  useMutation({
    mutationFn: (variables: DuplicateFarmVariables) =>
      duplicateFarmOperation(variables),
    onSuccess: async () => {
      setNotification({
        type: NotificationDialogType.INFO,
        message: 'Farm duplicated',
      });
      // The cache invalidation MUST happen after the setNotification call
      // the asynchronous "invalidateQueries" operation create a delay to request icons for the modal
      // it results in a delay to load external components.
      await queryClient.invalidateQueries([CUSTOMER_FARMS_KEY]);
    },
    onError: (error) => {
      console.error('Error adding item:', error);
      let message = errorMessage;
      if (error.message) {
        message = error.message;
      }
      setNotification({
        type: NotificationDialogType.ERROR,
        message,
      });
    },
  });

type DeleteCompoudFeedVariables = { customerId: string; feedId: string };

const deleteCompoundFeedOperation = async ({
  customerId,
  feedId,
}: DeleteCompoudFeedVariables): Promise<CompoundFeedDeleteResult> => {
  const result = (await API.graphql({
    query: deleteCompoundFeed,
    variables: {
      customerId,
      feedId,
    },
  })) as GraphQLResult<{
    deleteCompoundFeed: CompoundFeedDeleteResult;
  }>;

  return result.data?.deleteCompoundFeed as CompoundFeedDeleteResult;
};

export const useDeleteCoumpoundFeed = (
  queryClient: QueryClient,
  ctaClickEvent: (
    linkURL: string,
    linkType: string,
    linkName: string,
    linkSection: string,
    linkLocation: string
  ) => Promise<void>,
  title: string,
  operationErrorMessage: string,
  successMessage: string,
  errorMessage: string,
  setNotification: Dispatch<SetStateAction<null | DialogProps>>
): UseMutationResult<
  CompoundFeedDeleteResult,
  Error,
  DeleteCompoudFeedVariables
> =>
  useMutation({
    mutationFn: (variables: DeleteCompoudFeedVariables) =>
      deleteCompoundFeedOperation(variables),
    onSuccess: async (data) => {
      setNotification({
        feedUsageItems: data.usageItems ?? [],
        dialogState: data.status === 'OK' ? 'normal' : 'error',
        dialogTxt: data.status === 'OK' ? successMessage : errorMessage,
        dialogTitle: title,
      });
      // The cache invalidation MUST happen after the setNotification call
      // the asynchronous "invalidateQueries" operation create a delay to request icons for the modal
      // it results in a delay to load external components.
      await queryClient.invalidateQueries([COMPOUND_FEEDS_KEY]);
      if (data.id && data.status === 'OK') {
        ctaClickEvent(
          window.location.href,
          'link',
          'Delete Feed',
          'My Feeds',
          'My Feeds'
        ).catch((e) => console.log(e));
      }
    },
    onError: (error) => {
      console.error('Error adding item:', error);
      setNotification({
        feedUsageItems: null,
        dialogState: 'error',
        dialogTxt: operationErrorMessage,
        dialogTitle: title,
      });
    },
  });

  const deleteCompoundFeedV2Operation = async ({
    customerId,
    feedId,
  }: DeleteCompoudFeedVariables): Promise<CompoundFeedDeleteResult> => {
    const result = await API.graphql({
      query: deleteCompoundFeedV2,
      variables: {
        customerId,
        feedId,
      },
    }) as GraphQLResult<{
      deleteCompoundFeedV2: CompoundFeedDeleteResult;
    }>;
  
    return result.data?.deleteCompoundFeedV2 as CompoundFeedDeleteResult;
  };

export const useDeleteCoumpoundFeedV2 = (
  queryClient: QueryClient,
  ctaClickEvent: (
    linkURL: string,
    linkType: string,
    linkName: string,
    linkSection: string,
    linkLocation: string
  ) => Promise<void>,
  title: string,
  operationErrorMessage: string,
  successMessage: string,
  errorMessage: string,
  setNotification: Dispatch<SetStateAction<null | DialogProps>>
): UseMutationResult<
  CompoundFeedDeleteResult,
  Error,
  DeleteCompoudFeedVariables
> =>
  useMutation({
    mutationFn: (variables: DeleteCompoudFeedVariables) =>
      deleteCompoundFeedV2Operation(variables),
    onSuccess: async (data) => {
      setNotification({
        feedUsageItems: [],
        dialogState: data.id ? 'normal' : 'error',
        dialogTxt: data.id ? successMessage : errorMessage,
        dialogTitle: title,
      });
      // The cache invalidation MUST happen after the setNotification call
      // the asynchronous "invalidateQueries" operation create a delay to request icons for the modal
      // it results in a delay to load external components.
      await queryClient.invalidateQueries([COMPOUND_FEEDS_KEY_V2]);
      if (data.id) {
        ctaClickEvent(
          window.location.href,
          'link',
          'Delete Feed',
          'My Feeds',
          'My Feeds'
        ).catch((e) => console.log(e));
      }
    },
    onError: (error) => {
      console.error('Error adding item:', error);
      setNotification({
        feedUsageItems: null,
        dialogState: 'error',
        dialogTxt: operationErrorMessage,
        dialogTitle: title,
      });
    },
  });
