import React from 'react';
import { FormControl, Checkbox, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const ReactHookFormCheckbox = (props) => {

  const { name,
    defaultValue,
    label,
    control,
    disabled,
    type,
    onChange: changeHandler} = props;

  // const changeHandler = props.onChange;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value, name, ref }) => {
        if(!value && defaultValue) value=defaultValue;
        if (!value) value=false;

        return (

          <Grid container spacing={1}>
            <Grid item xs={11} style={{paddingLeft: '14px'}}>
              <FormControl
                {...props}
              >
                <FormControlLabel  control={
                   (!type || type !== 'switch') ?
                  <Checkbox
                    disabled={disabled}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      if (changeHandler) {
                        changeHandler(e);
                      }
                      onChange(e);
                    }
                    }
                    />
                  :
                    <Switch
                      disabled={disabled}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        if (changeHandler) {
                          changeHandler(e);
                        }
                        onChange(e);
                      }
                    }
                   />
                  
                } label={label}
                />
              </FormControl>
          </Grid>
        </Grid>
        );
      }}
    />
  );
};
export default ReactHookFormCheckbox;
