/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API } from "aws-amplify";
import { CreateFacilityInput, Facility, UpdateFacilityInput } from "../../../graphql/types";
import { createFacility, updateFacility } from "../../../graphql/mutations";

type ResultPromiseType = Promise<{
  data: {
    createFacility?: {
        id: string
      },
      updateFacility?: {
        id: string
      }
    }
}> ;

type VariablesCreateUpdateType = { 
  customerId: string; 
  input: CreateFacilityInput | UpdateFacilityInput; 
  facilityId?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const saveFacility = (
  inputData: CreateFacilityInput | UpdateFacilityInput,
  customerId: string,
  sucessHandler?: (savedData: Facility) => void,
  errorHandler?: (errorMessage: string) => void,
  id?: string,
  oldName?: string,
  // formSubbmisionEvent?: any
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let query = createFacility;
  const variables: VariablesCreateUpdateType = {
    customerId,
    input: inputData as CreateFacilityInput
  };
  if (id && oldName) {
    (inputData as UpdateFacilityInput).oldName = oldName;
    query = updateFacility;
    variables.facilityId = id;
    variables.input = inputData as UpdateFacilityInput;
  }
  // eslint-disable-next-line no-void
  void (async () => {
    try {
      const result = (await (API.graphql({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query,
        variables
      }) as ResultPromiseType));

      // console.log('response: ', result);
      let resultId;
      if (result?.data?.createFacility) {
        resultId = result?.data?.createFacility?.id;
      } else if (result?.data?.updateFacility) {
        resultId = result?.data?.updateFacility?.id;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const savedData = { ...inputData, id:resultId } as unknown as Facility;
      if (sucessHandler) {
        sucessHandler( savedData );
      }
    } catch (reason: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (reason?.errors?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const error = reason.errors[0];
        if (error && errorHandler) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
          errorHandler(error?.message || '');
        }
      }
    }
  })();
};