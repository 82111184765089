// import { multicastChannel } from '@redux-saga/core';
import * as Yup from 'yup';
import { defaultUnits } from '../../../../sustell_15/utils/unit-utils';

import { 
  interventionFieldWithMin, 
  interventionFieldWithMinMax,
  interventionFieldWithMinMaxAndMinNewValue
} from './interventionValidationObjectBuilderFunctions';

import {
  numericRequiredWithGreaterThanMin,
  numericOptionalWithGreaterThanMin,
  numericOptionalWithMinMax
} from '../../Baseline/validationSchema/validationObjectBuilderFunctions';


import{
  interventionValidationResourseUsagePart
} from './interventionValidationSchemaGeneralPart';

// Yup validation schema for intervension form data

const checkRatio = (intl, path, createError, parent, baseline) => {

  // get the stage index
  let currentStageIndex = null;
  const matches = path.match(/stages\[(.*?)\]/);
  if (matches) {
    const indexStr = matches[1];
    if(indexStr || indexStr === '0') 
      currentStageIndex = parseInt(indexStr);
  }

  const { productionSystem } = baseline.stages[currentStageIndex]

  if (productionSystem !== 'Dairy cows') {
    return true;
  }

  const milkValue = parent.milk_newValue ? parent.milk_newValue : baseline.stages[currentStageIndex].stageData?.output?.milk;
  const liveWeightValue = parent.liveWeight_newValue ? parent.liveWeight_newValue : baseline.stages[currentStageIndex].stageData?.output?.liveWeight;

  if(milkValue / liveWeightValue >= 6.05) {
    return true;
  }

  return createError({
    path,
    message: intl.formatMessage(
      {
        id: "VALIDATION.FIELD.RATIO_MILK_LIVEWEIGHT",
      },
      {
        value: 1
      }
    ),
  });
}


const stageDataPartDairy = ({intl, minValueOutput=1, minBarnValueOutput, userUOM=defaultUnits, baseline }) => Yup.object({
  stages: Yup.array()
    .of(
      Yup.object({
        stageData: Yup.object({
          feed: Yup.object({
            compoundFeeds: Yup.array()
              .of(
                Yup.object({
                  feedType: Yup.string(""),
                  kgPerAnimal: interventionFieldWithMin(intl,'kgPerAnimal',0)
                })
              ),
            compoundFeedsAdditions: Yup.array()
              .of(
                Yup.object({
                  feedType: Yup.string(""),
                  kgPerAnimal: numericRequiredWithGreaterThanMin(intl,0)
                })
              ),
             singleIngredients: Yup.array()
              .of(
                Yup.object({
                  feedType: Yup.string(""),
                  kgPerAnimal: interventionFieldWithMin(intl,'kgPerAnimal',0)
                })
              ),
            singleIngredientsAdditions: Yup.array()
              .of(
                Yup.object({
                  feedType: Yup.string(""),
                  kgPerAnimal: numericRequiredWithGreaterThanMin(intl,0)
                })
              ),
            totalFeedIntake: numericOptionalWithGreaterThanMin(intl, 0),
            timeSpentGrazing: interventionFieldWithMinMax(intl, 'timeSpentGrazing', 0, 100),
            timeSpentInOpenYardAreas: interventionFieldWithMinMax(intl, 'timeSpentInOpenYardAreas', 0, 100),
            timeSpentInBuildings: numericOptionalWithMinMax(intl, 0, 100),
            digestibility: interventionFieldWithMinMax(intl, 'digestibility', 0, 100),
            grossEnergyIntake: interventionFieldWithMin(intl, 'grossEnergyIntake', 1),
            crudeProteinContent: interventionFieldWithMinMax(intl, 'crudeProteinContent', 0, 100),
            percentageOfSilage: interventionFieldWithMinMax(intl, 'percentageOfSilage', 0, 100),
          }).default({}),
          manure: Yup.object({
            averageAnimalsPresent: interventionFieldWithMin(intl, 'averageAnimalsPresent', 0),
            manureManagementSystem: Yup.string(intl.formatMessage({id: "VALIDATION.FIELD.INPUT_SELECT" })),
            solidManureStorage: interventionFieldWithMin(intl, 'solidManureStorage', 0),
            slurryManureStorage: interventionFieldWithMin(intl, 'slurryManureStorage', 0),
            strawForBedding: interventionFieldWithMin(intl, 'strawForBedding', 0),
            sawDust: interventionFieldWithMin(intl, 'sawDust', 0),
          }).default({}),
          emissions: Yup.object({
            methaneEntericFermentation: interventionFieldWithMinMax(intl,'methaneEntericFermentation',-100,100),
            methane: interventionFieldWithMinMax(intl,'methane',-100,100),
            nitrousOxideDirect: interventionFieldWithMinMax(intl,'nitrousOxideDirect',-100,100),
            nitrousOxideIndirect: interventionFieldWithMinMax(intl,'nitrousOxideIndirect',-100,100),
            amonia: interventionFieldWithMinMax(intl,'amonia',-100,100),
            nitricOxide: interventionFieldWithMinMax(intl,'nitricOxide',-100,100),
            nonMethaneVolatile: interventionFieldWithMinMax(intl,'nonMethaneVolatile',-100,100),
            nonMethaneVolatileSilage: interventionFieldWithMinMax(intl,'nonMethaneVolatileSilage',-100,100),
            PM25: interventionFieldWithMinMax(intl,'PM25',-100,100),
            is3NOPSelected: Yup.bool(),
            supplement3NOP: Yup.object().when('is3NOPSelected', {
              is: true,
              then: Yup.object({
                _3nopDosePerCowPerDay: interventionFieldWithMin(intl, '_3nopDosePerCowPerDay',0.001)
                  .test('bovaerCalculationError', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                    const warningMsg = resolve(parent['_3nopDosePerCowPerDay_error']);
                    if(warningMsg)
                      return createError({path:path,message: warningMsg});
                    return true;
                  }),
                dmiAmountPerKg: interventionFieldWithMin(intl, 'dmiAmountPerKg',0)
                  .test('bovaerCalculationError', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                    const warningMsg = resolve(parent['dmiAmountPerKg_error']);
                    if(warningMsg)
                      return createError({path:path,message: warningMsg});
                    return true;
                  }),
                fatAmountPercentage: interventionFieldWithMinMax(intl, 'fatAmountPercentage',-100,100)
                  .test('bovaerCalculationError', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                    const warningMsg = resolve(parent['fatAmountPercentage_error']);
                    if(warningMsg)
                      return createError({path:path,message: warningMsg});
                    return true;
                  }),
                ndfAmountPercentage: interventionFieldWithMinMaxAndMinNewValue(intl, 'ndfAmountPercentage',-100,100,0)  
                  .test('bovaerCalculationError', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                    const warningMsg = resolve(parent['ndfAmountPercentage_error']);
                    if(warningMsg)
                      return createError({path:path,message: warningMsg});
                    return true;
                  }),
                numberOfCowsCovered: interventionFieldWithMin(intl,'numberOfCowsCovered',1)
                  .test('minValCowsTest', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                  
                    // get the barn index
                    let index = null;
                    const matches = path.match(/\[(.*?)\]/);
                    if (matches) {
                      const indexStr = matches[1];
                      if(indexStr || indexStr === '0') 
                        index = parseInt(indexStr);
                    }
                    // get num of animals in 3NOP from intervention
                    let animalsIn3NOP = resolve(parent['numberOfCowsCovered_newValue']);
                    
                    // if not set, get from the baseline
                    if((!animalsIn3NOP && animalsIn3NOP !== 0)
                       && (index || index === 0)) {
                        animalsIn3NOP = baseline?.stages[index]?.stageData?.feed?.numberOfCowsCovered
                    }
                      
                    let animalsPresent = null;
                    // get total number of animals from intervention
                    if(this.from && this.from.length > 1) {
                      animalsPresent = this.from[2].value.manure?.averageAnimalsPresent_newValue;
                    }

                    // in not set, get baseline value
                    if((!animalsPresent && animalsPresent !== 0) && (index || index === 0)) {
                      animalsPresent = baseline?.stages[index]?.stageData?.manure?.averageAnimalsPresent;
                    }
                    
                    if(typeof animalsPresent==='number' && typeof animalsIn3NOP==='number' && animalsIn3NOP > animalsPresent) {
                      return createError({path:path,message: intl.formatMessage({ id: 'VALIDATION.DAIRY.NUMBER_OF_COWS_3NOP'})}, {value:0});
                    }

                    return true;
                  })
                  .test('bovaerCalculationError', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                    const warningMsg = resolve(parent['numberOfCowsCovered_error']);
                    if(warningMsg)
                      return createError({path:path,message: warningMsg});
                    return true;
                  }),
                calculatedResult: numericOptionalWithMinMax(intl,-100,100)
                 .test('bovaerCalculationError', '', function (value) {
                  const { resolve, path, createError, parent } = this;
                  const warningMsg = resolve(parent['numberOfCowsCovered_error']);
                  if(warningMsg)
                    return createError({path ,message: warningMsg});
                  return true;
                }),
                calculatedResult_recalculate_error: Yup.string().nullable()
                  .test('bovaerRecalculateError', '', function (value) {
                    const { resolve, path, createError, parent } = this;
                    const warningMsg = resolve(parent['calculatedResult_recalculate_error']);
                    if(warningMsg)
                      return createError({path, message: warningMsg});
                    return true;
                  }),
              })
             }),
          }).default({}),
          output: Yup.object({
            milk: interventionFieldWithMin(intl, 'milk', minValueOutput)
            .test('milkLiveWeightRatio', '', function() {
              const { path, createError, parent } = this;
              return checkRatio(intl, path, createError, parent, baseline);
            }),
            proteinContent: interventionFieldWithMinMax(intl, 'proteinContent', 0, 100),
            fatContent: interventionFieldWithMinMax(intl, 'fatContent', 0, 100),
            liveWeight: interventionFieldWithMin(intl, 'liveWeight', 0)
            .test('milkLiveWeightRatio', '', function() {
              const { path, createError, parent } = this;
              return checkRatio(intl, path, createError, parent, baseline);
            })
            .test('minLivestockToSlautherAllStages', '', function() {
              const { resolve, path, createError, from, parent } = this;
              const allStages = from[3].value.stages;
              let totalLiveWeight = 0;

              allStages.forEach((stage, index) => {
                let val = Number(stage.stageData?.output?.liveWeight_newValue);
                if (!stage.stageData?.output?.liveWeight_newValue && stage.stageData?.output?.liveWeight_newValue !== 0) {
                  val = baseline.stages[index]?.stageData?.output?.liveWeight;
                }
                if (val && !Number.isNaN(val)) {
                  totalLiveWeight += val;
                }
              });

              // get the stage index
              let currentStageIndex = null;
              const matches = path.match(/stages\[(.*?)\]/);
              if (matches) {
                const indexStr = matches[1];
                if(indexStr || indexStr === '0') 
                currentStageIndex = parseInt(indexStr);
              }
              
              const { productionSystem } = baseline.stages[currentStageIndex]

              const liveWeightResult = parent.liveWeight_newValue ? parent.liveWeight_newValue : baseline.stages[currentStageIndex].stageData?.output?.liveWeight;

              if (productionSystem === 'Dairy cows' && (liveWeightResult !== undefined && liveWeightResult < 1)) {
                return createError({
                  path,
                  message: intl.formatMessage(
                    {
                      id: "VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE",
                    }, 
                    {
                      value: 1
                    }
                  ),
                });
              }

              if (totalLiveWeight > 0) {
                return true;
              }
              return createError({
                path,
                message: intl.formatMessage(
                  {
                    id: "BASELINE.FORM.BARN.DAIRY.VALIDATION.LIVEWEIGHT_ALL_STAGES",
                  }, 
                  {
                    min: 1
                  }
                ),
              });
            }),
          }).default({})
        })
      }).required()
    )
});

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const combinedSchema = Yup.object({info:baseSchema})
   .concat(Yup.object({resourceUse:interventionValidationResourseUsagePart(confObj)}))
   .concat(stageDataPartDairy(confObj))
  return combinedSchema;
};

export default assembleValidationSchemaSustell
