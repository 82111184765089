import { FC } from "react";
import { useSubheader } from "../../../_metronic/layout";
import CompoundFeedWizard from "../components/CompoundFeedWizard";
import { Box } from "@material-ui/core";

type CompoundFeedPagePropsAlias = any;

const CompoundFeedPage: FC<CompoundFeedPagePropsAlias> = (props) => {
  const subheader = useSubheader();

  return (
    <Box className="data-hj-suppress">
      <CompoundFeedWizard subHeader={subheader} {...props} />
    </Box>
  );
};

export default CompoundFeedPage;