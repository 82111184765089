import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import {
  availableMmsTypeForMonthlyStoragePeriods,
  availableMmsTypeForOverOrUnderStoragePeriod,
} from "../../../../models/Baseline/DairyV2Baseline";
import {
  CattleHousing,
  CattleManureManagementSystemType,
  ManurePhase,
  StoragePeriod,
} from "../../../../models/Baseline/CattleBaseline";

type DairyV2MMSComponentProps = {
  stageIndex?: number;
  isViewMode?: boolean;
  mmsOptions: Array<{ text: string; value: string }>;
  storageOptions: Array<{ text: string; value: string }>;
  openDescriptionDialog: () => void;
  isDairyCowStage: boolean;
};

type MMSInput = {
  mmsType: string;
  storagePeriod: string;
  share: string;
  id: string;
};

const DairyV2MMSComponent: FC<DairyV2MMSComponentProps> = ({
  stageIndex = 0,
  isViewMode = false,
  mmsOptions,
  storageOptions,
  openDescriptionDialog,
  isDairyCowStage,
}) => {
  const [selectedMmsTypes, setSelectedMmsTypes] = useState<{
    index: number;
    mmsType: CattleManureManagementSystemType;
  }>();
  const [availableStorageOptions, setAvailableStorageOptions] = useState<{
    [key: string | number]: Array<{ text: string; value: string }>;
  }>({});
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const baseItemPrefix = `stages.${stageIndex}.stageData.housing`;
  const fieldItemPrefix = `${baseItemPrefix}.manureSystems`;
  const mmsInputsStyle = { height: "60px" };
  const formContext = useFormContext<CattleHousing>();
  const { control } = formContext;
  const {
    fields: mmsSystems,
    append: appendMMS,
    remove: removeMMS,
  } = useFieldArray({
    name: `${fieldItemPrefix}`,
    control,
    keyName: "keyId",
  });

  const getSelectedMms = () => {
    const selectedMms: Array<MMSInput> =
      formContext.getValues(fieldItemPrefix) || [];
    return selectedMms;
  };

  const isSingleMms = () => {
    const selectedMms = getSelectedMms();
    return selectedMms.length === 1;
  };

  const isMmsEmpty = () => {
    const selectedMms = getSelectedMms();
    return selectedMms.length === 0;
  };

  const setShareValue = (value = "100") =>
    formContext.setValue(`${fieldItemPrefix}.${0}.share`, value);

  const addMMS = () => {
    const emptyMMS: MMSInput = {
      id: uuidv4(),
      mmsType: "",
      storagePeriod: "",
      share: isMmsEmpty() ? "100" : "",
    };

    if (isSingleMms()) {
      setShareValue("");
    }

    appendMMS(emptyMMS);
  };

  const deleteMMS = (index: number) => {
    removeMMS(index);

    if (isSingleMms()) setShareValue();
  };

  useEffect(() => {
    if (mmsSystems.length === 0) addMMS();
  });

  const isMmsTypeAvailableForMonthlyRanges = (
    selectedMmsTypes: CattleManureManagementSystemType
  ) =>
    availableMmsTypeForMonthlyStoragePeriods.some(
      (mmsType) => mmsType === selectedMmsTypes
    );

  const isMmsTypeAvailableForOverOrUnder = (
    selectedMmsTypes: CattleManureManagementSystemType
  ) =>
    availableMmsTypeForOverOrUnderStoragePeriod.some(
      (mmsType) => mmsType === selectedMmsTypes
    );

  const isStorageFieldRequired = (
    seletedMmsType: CattleManureManagementSystemType
  ) =>
    isMmsTypeAvailableForMonthlyRanges(seletedMmsType) ||
    isMmsTypeAvailableForOverOrUnder(seletedMmsType);

  const filterAvailableMmsOptions = ({
    index,
    mmsType,
  }: {
    index: number;
    mmsType: CattleManureManagementSystemType;
  }) => {
    if (!isStorageFieldRequired(mmsType)) {
      formContext.setValue(`${fieldItemPrefix}.${index}.storagePeriod`, "");
      setAvailableStorageOptions((previousOptions) => ({
        ...previousOptions,
        [index]: [],
      }));
      return;
    }

    if (isMmsTypeAvailableForOverOrUnder(mmsType)) {
      setAvailableStorageOptions((previousOptions) => ({
        ...previousOptions,
        [index]: storageOptions.filter(
          (option) =>
            option.value.includes("over") || option.value.includes("under")
        ),
      }));
      return;
    }

    setAvailableStorageOptions((previousOptions) => ({
      ...previousOptions,
      [index]: storageOptions.filter(
        (option) =>
          !option.value.includes("over") && !option.value.includes("under")
      ),
    }));
  };

  useEffect(() => {
    if (selectedMmsTypes && Object.keys(selectedMmsTypes).length) {
      filterAvailableMmsOptions(selectedMmsTypes);
    }
    // eslint-disable-next-line
  }, [selectedMmsTypes]);

  const getStoragePeriodDefaultValue = (index: number): StoragePeriod =>
    formContext.getValues(`${fieldItemPrefix}.${index}.storagePeriod`);

  const getMmsTypeDefaulttValue = (
    index: number
  ): CattleManureManagementSystemType =>
    formContext.getValues(`${fieldItemPrefix}.${index}.mmsType`);

  const updateManurePhase = (
    type: CattleManureManagementSystemType,
    index: number
  ) => {
    if (type === CattleManureManagementSystemType.DailySpread)
      formContext.setValue(
        `${fieldItemPrefix}.${index}.manurePhase`,
        ManurePhase.LiquidSlurry
      );
    else
      formContext.setValue(
        `${fieldItemPrefix}.${index}.manurePhase`,
        undefined
      );
  };

  const gridClassName =
    mmsSystems.length > 1
      ? classes.dsmGridFourColumnFirstBigOthersSmall
      : classes.dsmGridThreeColumnFirstBigOthersSmall;

  return (
    <div>
      {isDairyCowStage && (
        <DsmGrid 
          className={classes.dsmGridThreeColumnFirstBigOthersSmall}
          style={{ paddingTop: "var(--dsm-spacing-px-4)" }}
        >
          <ReactHookDsmInput
            type="number"
            name={`${baseItemPrefix}.timeInHousingTied`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED.TOOLTIP',
            })}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SPENT_IN_HOUSING_TIED.ADORNMENT',
            })}
            disabled={isViewMode}
            defaultValue={
              formContext.getValues(`${baseItemPrefix}.timeInHousingTied`) as string
            }
            required
          />
        </DsmGrid>
      )}
      <p className={classes.subHeadingTitle}>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.DAIRY.MMS.TIME_SPENT_IN_HOUSING.DESCRIPTION",
        })}
      </p>
      {mmsSystems &&
        mmsSystems?.map((item, index) => (
          <div
            key={item.keyId}
            style={{
              marginTop: index === 0 ? '0px' : '15px',
              marginBottom: '15px',
            }}
          >
            <div>
              <input
                ref={formContext.register()}
                type="hidden"
                name={`${fieldItemPrefix}.${index}.manurePhase`}
                defaultValue={
                  formContext.getValues(
                    `${fieldItemPrefix}.${index}.manurePhase`
                  ) || ""
                }
              />
              <DsmGrid className={gridClassName}>
                <ReactHookDsmSelect
                  style={mmsInputsStyle}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE',
                  })}
                  name={`${fieldItemPrefix}.${index}.mmsType`}
                  options={mmsOptions}
                  disabled={isViewMode}
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.${index}.mmsType`
                  )}
                  changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedMmsTypes({
                      index,
                      mmsType: e.target
                        .value as CattleManureManagementSystemType,
                    });
                    updateManurePhase(
                      e.target.value as CattleManureManagementSystemType,
                      index
                    );
                  }}
                  tooltip={intl.formatMessage({
                    id: isDairyCowStage 
                      ? 'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE.TOOLTIP' 
                      : 'SUSTELL.STAGE.DAIRY.MMS.CONTINUOUS_GROWING.MMS_TYPE.TOOLTIP',
                  })}
                  required
                />

                {isStorageFieldRequired(getMmsTypeDefaulttValue(index)) ? (
                  <ReactHookDsmSelect
                    style={mmsInputsStyle}
                    label={intl.formatMessage({
                      id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_STORAGE_PERIOD',
                    })}
                    name={`${fieldItemPrefix}.${index}.storagePeriod`}
                    options={
                      availableStorageOptions[index] ||
                      filterAvailableMmsOptions({
                        index,
                        mmsType: getMmsTypeDefaulttValue(index),
                      })
                    }
                    disabled={isViewMode}
                    defaultValue={formContext.getValues(
                      `${fieldItemPrefix}.${index}.storagePeriod`
                    )}
                    required={isStorageFieldRequired(getMmsTypeDefaulttValue(index))}
                  />
                ) : (
                  <div />
                )}

                {!isSingleMms() && (
                  <ReactHookDsmInput
                    style={mmsInputsStyle}
                    label={intl.formatMessage({
                      id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_SHARE',
                    })}
                    type="text"
                    name={`${fieldItemPrefix}.${index}.share`}
                    disabled={isViewMode}
                    defaultValue={formContext.getValues(
                      `${fieldItemPrefix}.${index}.share`
                    )}
                    required
                    tooltip={intl.formatMessage({
                      id: 'SUSTELL.STAGE.DAIRY.MMS.MMS_SHARE.TOOLTIP',
                    })}
                    adornment="%"
                    changeHandler={() => formContext.trigger(fieldItemPrefix)}
                  />
                )}

                {!isViewMode && deleteMMS && mmsSystems.length > 1 && (
                  <DsmButton
                    variant="text"
                    iconOnly
                    destructive
                    onClick={() => deleteMMS(index)}
                    style={{
                      padding: "0px",
                      margin: "24px 0px 0px 0px",
                    }}
                  >
                    <DsmIcon
                      slot="before"
                      name="general/trash-01"
                      style={{ margin: 0, padding: 0 }}
                    />
                  </DsmButton>
                )}
              </DsmGrid>
            </div>
          </div>
        ))}

      <DsmGrid
        className={classes.dsmGridTwoColumnAndIconBtn}
        style={{
          marginBottom: "var(--dsm-spacing-px-4",
        }}
      >
        <div>
          <DsmButton 
            variant="text" 
            onClick={addMMS}
            disabled={isViewMode}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: "SUSTELL.STAGE.POULTRY.HOUSING.ADD_ANOTHER_MMS",
            })}
          </DsmButton>

          <DsmButton
            variant="text"
            onClick={openDescriptionDialog}
            style={{ marginLeft: "20px" }}
          >
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({ id: "DATABASE_FOUNDATION_DEFINITIONS" })}
          </DsmButton>
        </div>
      </DsmGrid>
    </div>
  );
};

DairyV2MMSComponent.defaultProps = {
  stageIndex: 0,
  isViewMode: false,
};

export default DairyV2MMSComponent;
