import { FC, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
 import { Box, Grid } from '@material-ui/core';
 import { useFormContext } from 'react-hook-form';
 import { BaselineStageProps, FormType } from '../../common';
 import StageButtonBox from '../../StageButtonBox_v2';
 import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import SHInterventionOutputFormDialog from './SHInterventionOutputFormDialog';
import { Facility } from '../../../../../../graphql/types';
import SHInterventionAnimalsFormDialog from './SHInterventionAnimalsFormDialog';
import SHInterventionPackagingFormDialog from './SHInterventionPackagingFormDialog';
 
 type SlaughterhouseDialogType = 'input' | 'output' | 'packaging' | null;
 interface SlaughterhouseInterventionStagesProps
   extends Omit<
     BaselineStageProps,
     'compoundFeeds' | 'manureManagementSystems' | 'singleIngredients' | 'item'
   > {
  //  baseline: PigBaseline;
   customerID: string;
   facilities: Facility[];
   interventionID: string;
   farmName: string;
   processName: string;
   item: {
    id: string;
    facilityId: string;
    animalType: string;
    facilityType: string;
    name: string;
   }
 }
 
 const SlaughterhouseInterventionStages: FC<
   SlaughterhouseInterventionStagesProps
 > = ({
   stageIndex,
   baseline,
   getCompleteness,
   item,
   formType,
   customerID,
   farmName,
   processName,
   facilities,
   interventionID
 }) => {
   const intl = useIntl();
   const history = useHistory();
   const [activeDialog, setActiveDialog] =
     useState<SlaughterhouseDialogType>(null);

     const showDialog = (dialog: SetStateAction<SlaughterhouseDialogType>) => {
     setActiveDialog(dialog);
   };

   const facility = facilities.find(fa => fa.id === item.facilityId);

   const editFacility = (facilityId: string | undefined) => {
    if (!facilityId) {
      // console.log(facilityId, 'is empty');
      return;
    }

     const datasetParams = {
       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
       farmID: baseline.farmId,
       customerID,
       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
       processID: baseline.productionProcessId,
       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
       datasetID: interventionID,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
       baselineID: baseline.id,
       fromIntervention: true,
       farmName,
       processName
     };

     const state = {
       datasetParams,
       customerID,
       formType: FormType.Edit,
     };

     history.push(
       `/profile/customers/${customerID}/facilities/${facilityId}`,
       state
     );
   };

   const formContext = useFormContext();
   const stagePrefix = `stages.${stageIndex}`;

   return (
     <>
       <input
         ref={formContext.register()} 
         type="hidden"
         name={`${stagePrefix}.id`}
         defaultValue={item.id}
       />
       <input
         ref={formContext.register()}
         type="hidden"
         name={`${stagePrefix}.name`}
         defaultValue={item.name}
       />
       <input
         ref={formContext.register()}
         type="hidden"
         name={`${stagePrefix}.animalType`}
         defaultValue={item.animalType}
       />
       <SHInterventionAnimalsFormDialog
         formVisible={activeDialog === 'input'}
         handleCancel={() => setActiveDialog(null)}
         formType={formType}
         // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
         baseline={baseline}
         facilityType={facility?.type}
         stageIndex={stageIndex}
       />
       <SHInterventionOutputFormDialog
         formVisible={activeDialog === 'output'}
         handleCancel={() => setActiveDialog(null)}
         formType={formType}
         // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
         baseline={baseline}
         currency={facility?.currency}
         stageIndex={stageIndex}
       />
       <SHInterventionPackagingFormDialog
         formVisible={activeDialog === 'packaging'}
         handleCancel={() => setActiveDialog(null)}
         formType={formType}
         // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
         baseline={baseline}
         stageIndex={stageIndex}
       />
       <Grid
         container
         direction="row"
         justifyContent="space-between"
         alignItems="center"
       >
         <StageButtonBox
           titleCode={
             item.facilityType === 'CUTTING'
               ? 'SUSTELL.PROCESS.DIALOG.STAGE.CARCASSES.TITLE'
               : 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE'
           }
           descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
           description="SUSTELL.STAGE.INPUT_DATA"
           iconCode="general/check-heart"
           completness={
             getCompleteness
               ? getCompleteness(
                   // eslint-disable-next-line @typescript-eslint/unbound-method
                   formContext.getValues,
                   `${stagePrefix}.stageData.processingInput`
                 )
               : 0
           }
           error={
             // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
             formContext.errors?.stages &&
             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
             formContext.errors?.stages[stageIndex]?.stageData?.processingInput
           }
           handleOpen={() => showDialog('input')}
         />
         <StageButtonBox
           titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
           iconCode="arrows/arrow-circle-broken-right"
           descriptionTitle="SUSTELL.STAGE.OUTPUT"
           description="SUSTELL.STAGE.INPUT_DATA"
           completness={
             getCompleteness
               ? getCompleteness(
                // eslint-disable-next-line @typescript-eslint/unbound-method
                   formContext.getValues,
                   `${stagePrefix}.stageData.processingOutput`
                 )
               : 0
           }
           error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            formContext.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            formContext.errors?.stages[stageIndex]?.stageData?.processingOutput
           }
           handleOpen={() => showDialog('output')}
         />
         <StageButtonBox
           titleCode="SUSTELL.PROCESS.DIALOG.STAGE.PACKAGING.TITLE"
           iconCode="shapes/cube-02"
           descriptionTitle="SUSTELL.PROCESS.DIALOG.STAGE.PACKAGING.DESCRIPTION"
           description="SUSTELL.STAGE.INPUT_DATA"
           completness={
             getCompleteness
               ? getCompleteness(
                // eslint-disable-next-line @typescript-eslint/unbound-method
                   formContext.getValues,
                   `${stagePrefix}.stageData.processingPackaging`
                 )
               : 0
           }
           error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            formContext.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            formContext.errors?.stages[stageIndex]?.stageData?.processingPackaging
           }
           handleOpen={() => showDialog('packaging')}
         />
         <Box style={{ width: '218px' }} />
         <Box style={{ width: '218px' }} />
       </Grid>
     </>
   );
 };
 
 export default SlaughterhouseInterventionStages;