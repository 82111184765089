import * as React from 'react';
import loadsh, { capitalize } from 'lodash';
import {
  Box,
  Tabs,
  Tab,
  Chip,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { footprintTabsStyles } from '../../../../_metronic/layout';
import { FootprintModel } from '../../models/Footprint';
import FootprintDetails from './FootprintDetails';
import FootprintEmissions from './FootprintEmissions';
import {
  AnimalType,
  FootPrintType,
  StageType,
} from '../../../../graphql/types';
import ResultAndErrors from './ResultAndErrors';
import { FootprintStageData } from './useFootprint';
import { FootprintCategoryName } from '../../models/Footprint/FootprintTypes';
import { isDairy, isPoultryFootprint, isShrimp } from '../../helpers/animals';
import lookupValues from '../../helpers/lookupValues';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

interface FootprintTabsProps {
  footprintStages: FootprintStageData[] | undefined;
  footprint: FootprintModel;
  customerID: string;
  type: string;
  baselineFarmID: string;
  comparisonReference?: string;
  setCategoryFilters: (categories: FootprintCategoryName[]) => void;
  setAnalysisGroupFilters: (analysisGroups: string[]) => void;
  setStageFilter: (stageDate: FootprintStageData) => void;
  initialTabOpened?: string | null;
  animalType?: string | undefined;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`footprint-tabpanel-${index}`}
      aria-labelledby={`footprint-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const FootprintTabs = (props: FootprintTabsProps) => {
  const {
    footprintStages,
    footprint,
    setCategoryFilters,
    setAnalysisGroupFilters,
    setStageFilter,
    customerID,
    type,
    baselineFarmID,
    comparisonReference,
    initialTabOpened = '',
    animalType,
  } = props;
  const classes = footprintTabsStyles();
  const intl = useIntl();

  const errorTabIndex = footprint.type !== FootPrintType.CompoundFeed ? 2 : 1;
  const [value, setValue] = React.useState(
    initialTabOpened === 'error' ? errorTabIndex : 0
  );

  // needed to change value for processing meat types select
  const processingCategoryStage = React.useRef<
    FootprintStageData | undefined
  >();

  const mountDefaultValue = (stage: FootprintStageData) =>
    `${stage.stageId}${stage.animalType}`;

  const getDefaultValue = () => {
    // If there is a default stage set return it, else use the older logic
    const defaultStage = footprintStages?.find(
      (stage) => stage.isDefaultStage
    );
    if (defaultStage) {
      return mountDefaultValue(defaultStage);
    }

    if (animalType === AnimalType.Pig) {
      // if processing stages exist then default is the one with highest outputWeight and category fresh meat (category set earlier)
      // priority cutting
      const processingStages = footprintStages?.filter(
        (stage) => stage.stageType === StageType.Processing
      );

      const multipleFacilitiesLength = 2;
      const singleFacilityLength = 1;

      if (processingStages?.length === multipleFacilitiesLength) {
        const defaultProcessingStage = loadsh
          .chain(footprintStages)
          .filter({
            stageType: StageType.Processing,
            originStageType: StageType.Processing,
          })
          .maxBy('outputWeight')
          .value();

        if (defaultProcessingStage) {
          return mountDefaultValue(defaultProcessingStage);
        }
      }

      if (processingStages?.length === singleFacilityLength) {
        const originStageType = isPoultryFootprint(
          processingStages[0].animalType
        )
          ? StageType.Growing
          : StageType.Fattening;

        const defaultProcessingStage = loadsh
          .chain(footprintStages)
          .filter({
            stageType: StageType.Processing,
            originStageType,
          })
          .maxBy('outputWeight')
          .value();

        if (defaultProcessingStage) {
          return mountDefaultValue(defaultProcessingStage);
        }
      }

      let defaultStage = footprintStages?.find(
        (stage) => stage.stageType === StageType.Fattening
      );
      if (defaultStage) {
        return mountDefaultValue(defaultStage);
      }

      defaultStage = loadsh
        .chain(footprintStages)
        .filter({ stageType: StageType.Breeding, animalType: 'Piglet' })
        .maxBy('outputWeight')
        .value();

      if (defaultStage) {
        return mountDefaultValue(defaultStage);
      }
      return null;
    } else {
      const defaultStage = footprintStages?.[0];
      if (defaultStage)
        return `${defaultStage.stageId}${defaultStage.animalType}`;
    }
    return null;
  };

  const [stageValue, setStageValue] = React.useState(getDefaultValue());

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getOutputStageValue = (stage: FootprintStageData | undefined) => {
    if (!stage) return undefined;
    return stage.originStageId
      ? `${stage.stageId}${stage?.originStageId || ''}${
          stage.facilityCategory || ''
        }`
      : undefined;
  };

  // indicator that initial footprint is selected
  const [initialOption, setInitialOption] = React.useState(false);
  React.useEffect(() => {
    if (!initialOption) {
      if (
        !footprintStages?.some((f) => f?.stageType === StageType.Processing) &&
        stageValue
      ) {
        setInitialOption(true);
        const selectedStage = footprintStages?.find((f) =>
          stageValue.includes(f.stageId) && stageValue.includes(f.animalType)
        );
        if (selectedStage) {
          setStageFilter(selectedStage);
        }
      } else if (footprintStages && stageValue) {
        const selectedStage = footprintStages?.find((f) =>
          stageValue.includes(f.stageId)
        );
        if (
          selectedStage?.facilityId &&
          selectedStage?.processingStageData?.at(0)
        ) {
          selectedStage.processingStageData[0].animalType = selectedStage.animalType;
          // if processing, set first meat category as selected
          setStageFilter(selectedStage.processingStageData[0]);
        }
        setInitialOption(true);
      }
    }
    const selectedStage = footprintStages?.find((f) =>
      stageValue?.includes(f.stageId)
    );

    if (!selectedStage) {
      setStageValue(getDefaultValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageValue, footprintStages, initialOption]);

  const stageChangeHandler = (
    event:
      | React.ChangeEvent<{ name?: string | undefined; value: unknown }>
      | { target: { value: unknown } },
    isProcessing = false
  ) => {
    // reset old value
    processingCategoryStage.current = undefined;

    // console.log("EVENT TARGET VALUE", event.target.value);
    const selectedStage = !isProcessing
      ? footprintStages?.find(
          (stage) =>
            `${stage.stageId}${stage.animalType}` === event.target.value &&
            stage.animalType
        )
      : footprintStages
          ?.flatMap((f) => f.processingStageData)
          ?.find((stage) => getOutputStageValue(stage) === event.target.value);

    if (selectedStage) {
      if (!isProcessing) {
        setStageValue(`${selectedStage.stageId}${selectedStage.animalType}`);
        // When processing is selcted from left, set fresh meat for output footprint
        if (
          selectedStage.stageType === StageType.Processing &&
          selectedStage.processingStageData?.at(0)
        ) {
          if (selectedStage?.processingStageData?.[0]) {
            setStageFilter(selectedStage.processingStageData[0]);
          }
        } else {
          setStageFilter(selectedStage);
        }
      } else {
        processingCategoryStage.current = selectedStage;
        setStageFilter(selectedStage);
      }
    }
  };

  const getNameForOutputCategory = (outputName: string) => {
    let retVal;
    Object.entries(lookupValues.processingOutputTypeMappings).forEach(
      ([key, val]) => {
        if (key === outputName) retVal = val;
      }
    );
    return retVal;
  };

  const getProcessingOutputsData = () => {
    const selectedStage = footprintStages?.find(
      (stage) =>
        `${stage.stageId}${stage.animalType}` === stageValue && stage.animalType
    );
    if (
      selectedStage?.processingStageData &&
      selectedStage?.processingStageData.length > 0
    ) {
      selectedStage.processingStageData.map(pd => pd.animalType = selectedStage.animalType)
      return selectedStage.processingStageData;
    }
    return undefined;
  };

  // If baseline stage is changed, filter processing stage footprints
  const getFilteredOutputFootprints = () =>
    (getProcessingOutputsData() || []).filter(
      (item) =>
        item.originStageId &&
        item.originStageId === (stageValue || '').substring(0, 36)
    );

  const filteredOutputFootprints = getFilteredOutputFootprints() || [];

  const getStageViewName = (stage: FootprintStageData) => {
    if (
      stage.stageType === StageType.Fattening &&
      filteredOutputFootprints.length > 0
    ) {
      return `${intl.formatMessage({
        id: 'SUSTELL.ENUMS.STAGE.TYPE.FATTENING',
      })} ${intl.formatMessage({ id: 'GENERAL.AND' })} ${intl.formatMessage({
        id: 'SUSTEL.ENUMS.STAGE.TYPE.PROCESSING',
      })}`;
    }
    return stage.stageName;
  };

  const getStageItemName = (stage: FootprintStageData) => {
    if (stage.stageType === StageType.Breeding) {
      if (stage.animalType === 'Piglet') {
        return `${stage.stageName} - Piglets`;
      }

      if (stage.animalType === 'Sow') {
        return `${stage.stageName} - Sows`;
      }
    }
    if (isDairy(animalType as AnimalType)) {
      return `${stage.stageName} - ${capitalize(stage.animalType?.toLowerCase())}`;
    }
    // this is added after merge
    return getStageViewName(stage);
    // return stage.stageName;
  };

  const getStageName = () => {
    const val = stageValue?.substring(0, 36);
    if (!val) {
      return undefined;
    }
    const stage = footprintStages?.find((f) => f.stageId === val);
    if (stage) {
      return getStageItemName(stage);
    }
    return undefined;
  };

  const proccesingOutputs = getProcessingOutputsData();

  return (
    <Box>
      {footprintStages && footprintStages.length > 0 && (
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Box style={{ width: '40%' }}>
            <InputLabel>
              <b>{`${intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.STAGES.DROPDOWN',
              })}`}</b>
            </InputLabel>
            <Select
              name="stageName"
              defaultValue={getDefaultValue}
              value={stageValue}
              variant="outlined"
              fullWidth
              onChange={(e) => stageChangeHandler(e)}
            >
              {footprintStages.map((stage: FootprintStageData) => {
                if (
                  stage.stageType === StageType.Breeding &&
                  stage.animalType === 'Piglet'
                ) {
                  return (
                    <MenuItem
                      key={`${stage.stageName}${stage.animalType}`}
                      value={`${stage.stageId}${stage.animalType}`}
                    >
                      {stage.stageName} - Piglets
                    </MenuItem>
                  );
                }
                if (
                  stage.stageType === StageType.Breeding &&
                  stage.animalType === 'Sow'
                ) {
                  return (
                    <MenuItem
                      key={`${stage.stageName}${stage.animalType}`}
                      value={`${stage.stageId}${stage.animalType}`}
                    >
                      {stage.stageName} - Sows
                    </MenuItem>
                  );
                }
                if (isPoultryFootprint(stage.animalType) ||
                isShrimp(animalType as AnimalType)) {
                  return (
                    <MenuItem
                      key={`${stage.stageName}${stage.animalType}`}
                      value={`${stage.stageId}${stage.animalType}`}
                    >
                      {stage?.farmName ? `${stage.farmName} - ` : ''}{stage.stageName} - {stage.animalType}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem
                    key={`${stage.stageName}${stage.animalType}`}
                    value={`${stage.stageId}${stage.animalType}`}
                  >
                    {getStageItemName(stage)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {proccesingOutputs?.length && (
            <Box style={{ marginLeft: '30px', width: '40%' }}>
              <InputLabel>
                <b>{`${intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.STAGES.DROPDOWN_SLAUGHTERHOUSE',
                })}`}</b>
              </InputLabel>
              <Select
                name="outputStageName"
                value={getOutputStageValue(
                  processingCategoryStage.current || proccesingOutputs?.at(0)
                )}
                variant="outlined"
                fullWidth
                onChange={(e) => stageChangeHandler(e, true)}
              >
                {(proccesingOutputs || []).map((stage: FootprintStageData) => (
                  <MenuItem
                    key={`${stage.stageName}${stage?.originStageId || ''}${
                      stage.facilityCategory || ''
                    }`}
                    value={`${stage.stageId}${stage?.originStageId || ''}${
                      stage.facilityCategory || ''
                    }`}
                  >
                    {intl.formatMessage({
                      id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.${
                        getNameForOutputCategory(
                          stage.facilityCategory || ''
                        ) || ''
                      }`,
                    })}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </Box>
      )}
      <Box style={{ width: '100%' }}>
        <Box className={classes.tabsBox}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="footprint tabs"
            className={classes.tabs}
          >
            <Tab
              label={intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.TABS.FOOTPRINT.TITLE',
              })}
              value={0}
            />
            {footprint.type !== FootPrintType.CompoundFeed && (
              <Tab
                label={intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TABS.EMISSIONS.TITLE',
                })}
                value={1}
              />
            )}
            <Tab
              label={
                <Box>
                  {intl.formatMessage({
                    id: 'SUSTELL.FOOTPRINT.TABS.RESULTANDQUALITYERRORS.TITLE',
                  })}
                  {footprint.countWarningsErrors > 0 && (
                    <Chip
                      variant="outlined"
                      style={{
                        color: '#E51F22',
                        borderColor: '#FCE9E9',
                        background: '#FCE9E9',
                        marginLeft: '3px',
                      }}
                      label={footprint.countWarningsErrors}
                    />
                  )}
                </Box>
              }
              value={errorTabIndex}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FootprintDetails
            footprint={footprint}
            customerID={customerID}
            type={type}
            setCategoryFilters={setCategoryFilters}
            setAnalysisGroupFilters={setAnalysisGroupFilters}
            baselineFarmID={baselineFarmID}
            comparisonReference={comparisonReference}
            stageName={getStageName()}
          />
        </TabPanel>
        {footprint.type !== FootPrintType.CompoundFeed && (
          <TabPanel value={value} index={1}>
            <FootprintEmissions
              footprint={footprint}
              customerID={customerID}
              type={type}
              comparisonReference={comparisonReference}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={errorTabIndex}>
          <ResultAndErrors footprint={footprint} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default FootprintTabs;
