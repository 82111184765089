import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { floatValueInDefaultUnit } from './floatHelperFunctions';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { TransportMode } from '../../../../../graphql/types';

// eslint-disable-next-line
const userPrefs = UserProfilePrefs.getInstance();
// eslint-disable-next-line
const userUOM = userPrefs.getUserUnitPrefs();
// eslint-disable-next-line
const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

export const TRANSPORT_DISTANCE_UOM_MAP: { [key in TransportMode]: string } = {
  INLAND_SHIP: 'unitTransportDistanceInlandWaterways',
  SEA_SHIP: 'unitTransportDistanceSea',
  TRAIN: 'unitTransportDistanceTerrestrial',
  TRUCK: 'unitTransportDistanceTerrestrial',
};

export function formatMassInput(input: number | string): number {
  return Number(
    floatValueInDefaultUnit(
      input,
      // eslint-disable-next-line
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      outputMassPrecision as number
    )
  );
}

export function formatTerrestrialTransportInput(
  input: number | string
): number {
  return Number(
    floatValueInDefaultUnit(
      input,
      // eslint-disable-next-line
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    )
  );
}

export function formatTransportInput(
  input: number | string,
  transportMode: TransportMode
): number {
  return Number(
    floatValueInDefaultUnit(
      input,
      // eslint-disable-next-line
      userUOM[TRANSPORT_DISTANCE_UOM_MAP[transportMode]],
      // @ts-ignore eslint-disable-next-line
      defaultMetric[TRANSPORT_DISTANCE_UOM_MAP[transportMode]]
    )
  );
}

type AnyObject = { [key: string]: any };

function cleanObject(input: AnyObject): AnyObject {
  Object.keys(input).forEach((key) => {
    if (input[key] === null || input[key] === undefined) {
      // eslint-disable-next-line
      delete input[key];
    } else if (typeof input[key] === 'object') {
      // eslint-disable-next-line
      input[key] = cleanObject(input[key]);
    }
  });
  return input;
}

export function removeNullOrUndefined<T>(input: AnyObject): T {
  const result = JSON.parse(JSON.stringify(input)) as AnyObject;

  return cleanObject(result) as T;
}
