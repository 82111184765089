import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  SalmonBaseline,
  SalmonOutput,
} from '../../../../models/Baseline/SalmonBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';

const SOutputFormDialog: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  handleSave = handleCancel,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<SalmonOutput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getValues, trigger, reset } = useFormContext<SalmonBaseline>();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() };
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {
          ...currResetValue.current,
        };
        reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel('reset');
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
    >
      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.production`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.PRODUCTION',
          })}`}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.PRODUCTION_TT',
          })}
          required
          adornment={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
          }
          disabled={formType === FormType.View}
          type="number"
          defaultValue={getValues(`${fieldItemPrefix}.production`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeight`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.AVG_WEIGHT',
          })}`}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.AVG_WEIGHT_TT',
          })}
          required
          adornment={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
          }
          disabled={formType === FormType.View}
          type="number"
          defaultValue={getValues(`${fieldItemPrefix}.averageWeight`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.mortalities`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.MORTALITIES',
          })}`}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.MORTALITIES_TT',
          })}
          required
          adornment={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
          }
          disabled={formType === FormType.View}
          type="number"
          defaultValue={getValues(`${fieldItemPrefix}.mortalities`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.escapees`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.ESCAPEES',
          })}`}
          required
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.OUTPUT.SALMON.ESCAPEES_TT',
          })}
          adornment={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
          }
          disabled={formType === FormType.View}
          type="number"
          defaultValue={getValues(`${fieldItemPrefix}.escapees`)}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await trigger(`stages.${itemIndex}.stageData.output`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SOutputFormDialog;
