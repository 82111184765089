import {
  FootprintModel, WarningErrorModel
} from '../internal';
import { StageEmissions} from "../FootprintTypes";
import EmissionsStageModel from './EmissionsStage';

interface EmissionModelProps {
  footprint: FootprintModel;
  emissionsByStages: StageEmissions[];
}

/**
 * Container class with emissions by stages and all agreagted emissions
 */
class EmissionsContainer {
  
  readonly footprint: FootprintModel;
  
  /** Emissions per stage */
  emissionStages: EmissionsStageModel[];

  /** All agregated emissions */
  agregatedEmissions: EmissionsStageModel;

  /** Manure management system emissions per stage */
  mmsEmissionStages: EmissionsStageModel[];

  /** All agregated Manure management system emissions */
  mmsAgregatedEmissions: EmissionsStageModel;

  /** Selected manure management system types */
  mmsTypes: string[] = [];

  constructor(data: EmissionModelProps) {
    this.footprint = data.footprint;
    this.emissionStages = data.emissionsByStages?.map(em => new EmissionsStageModel(this, em, false, false));

    this.agregatedEmissions = new EmissionsStageModel(this, undefined, true, false);
    this.emissionStages.forEach(emissionsByStage => this.agregatedEmissions?.appendStage(emissionsByStage, this.agregatedEmissions));
    
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    this.agregatedEmissions.errors = WarningErrorModel.fromEmissionsStages(this.agregatedEmissions , this.footprint);

    this.mmsEmissionStages = data.emissionsByStages?.map(em => new EmissionsStageModel(this, em, false, true));
    this.mmsAgregatedEmissions = new EmissionsStageModel(this, undefined, true, true);
    this.mmsEmissionStages.forEach(emissionsByStage => this.mmsAgregatedEmissions?.appendStage(emissionsByStage, this.mmsAgregatedEmissions));

    (data?.emissionsByStages || []).forEach(mmsEmi => {
      const types = (mmsEmi?.mmsEmissions || []).map(em => em.type);
      this.mmsTypes = this.mmsTypes.concat(types);
    });

  }

  /** Get emissions container of a comparison footprint */
  get comparison(): EmissionsContainer | undefined {
    if (!this.footprint.comparison) { 
      return undefined;
    }
    return this.footprint.comparison.emissionContainer  || undefined;
  }
}

export default EmissionsContainer;

