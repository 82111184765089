
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';

const SustellNewBoatInput = props => {

  const intl = useIntl();
  const { fieldItemPrefix, subFieldName, index, formState, required } = props;
  const { errors, getValues, setValue } = useFormContext();

  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  useWatch({name: `${fieldItemPrefix}.${subFieldName}`});

  const resolvePath = (object, path, defaultValue) =>
    path
      .split(/[.[\]'"]/)
      .filter(p => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  const fieldBoatName =
    `${fieldItemPrefix}.${subFieldName}[${index}].boatName`;
  const fieldFuelType =
    `${fieldItemPrefix}.${subFieldName}[${index}].type`;
  const fieldFuelAmount =
    `${fieldItemPrefix}.${subFieldName}[${index}].amount`;

  const localFuelAmountError = resolvePath(errors, fieldFuelAmount, null);
  const localBoatError = resolvePath(errors, fieldBoatName, null);
  const localFuelTypeError = resolvePath(errors, fieldFuelType, null);

  const fieldValue = (fieldName) => {
    const value = getValues(fieldName);
    if (value === null || value === undefined) return '';
    return value;
  }

  return (
    <DsmGrid
      style={{
        width: "100%",
        grid: "auto/1.3fr 1fr 1.2fr",
        gap: "var(--dsm-spacing-px-6)",
        padding: "0px",
        alignItems: "start",
        rowGap: "var(--dsm-spacing-px-05)"
      }}
    >
      <ReactHookDsmInput
        key={
          `${fieldItemPrefix}.${subFieldName}[${index}]_inputPart1`
        }
        name={fieldBoatName}
        defaultValue={fieldValue(fieldBoatName)}
        label={intl.formatMessage({
          id: 'BASELINE.FORM.SALMON.BOAT_NAME',
        })}
        // value={value && itemsLoaded ? value : ''}
        helperText={
          localBoatError && localBoatError.message
            ? localBoatError.message
            : ''
        }
        error={Boolean(localBoatError)}
        disabled={formState === 'view'}
        required
      />

      <ReactHookDsmSelect
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPart2`}
        name={fieldFuelType}
        defaultValue={fieldValue(fieldFuelType)}
        label={intl.formatMessage({ id: 'BASELINE.FORM.SALMON.FUEL_TYPE' })}
        error={Boolean(localFuelTypeError)}
        disabled={formState === 'view'}
        options={
          props.availableOptions.map(item => (
            { value: item.value, text: item.display_name }
          ))
        }
        required
      />
      <ReactHookDsmInput
        key={
          `${fieldItemPrefix}.${subFieldName}[${index}]_inputPart3`
        }
        name={fieldFuelAmount}
        defaultValue={fieldValue(fieldFuelAmount)}
        label={`${intl.formatMessage({ id: 'BASELINE.FORM.SALMON.FUEL_USE' })}`}
        adornment={getValues(`${fieldItemPrefix}.${subFieldName}[${index}].type`) === 'electricity' ? userUOM?.unitElectricityUse : unitLong(userUOM?.unitDieselUse)}
        error={Boolean(localFuelAmountError)}
        disabled={formState === 'view'}
        type="number"
        required
      />
    </DsmGrid>
  );
};
export default SustellNewBoatInput;
