// eslint-disable-next-line import/no-extraneous-dependencies
import { Maybe } from "graphql/jsutils/Maybe";
import { BaselinePropertyValue, Stage } from "../Baseline/Baseline";
import { PrimaryPackagingType, SecondaryPackagingType, TertiaryPackagingType, TransportMode } from "../../../../graphql/types";

export interface TransportTypeDistance {
  transportMode: TransportMode;
  distance: BaselinePropertyValue;
}

export interface ProcessingInput {
  originStageId?: Maybe<string>;
  quantity: BaselinePropertyValue;
  // Get averageWeight from origin animals stage (in out mapper)
  averageWeight: BaselinePropertyValue;
  transport?: Maybe<TransportTypeDistance[]>;
  name?: string;
  animalType?: Maybe<'COW' | 'CALF'>;
  shrinkingRate?: Maybe<number>;
  // TODO - refactor "stageType" to "originStageType" and update outProcessingMapper and inProcessingMapper
  stageType?: Maybe<string>;
}

interface ProcessingOutputItem {
  weight: BaselinePropertyValue;
  price: BaselinePropertyValue;
  calculate?: boolean | undefined;
}
export interface ProcessingOutput {
  freshMeatAndEdibleOffal?: ProcessingOutputItem;
  foodGradeBones?: ProcessingOutputItem;
  foodGradeFat?: ProcessingOutputItem;
  cat1And2MaterialAndWaste?: ProcessingOutputItem;
  cat3SlaughterByProducts?: ProcessingOutputItem;
  cat3HidesAndSkins?: ProcessingOutputItem;
}

export interface ProcessingPackagingItem<EnumType> {
  type: EnumType;
  amount: BaselinePropertyValue;
}

export interface Packaging {
  primary?: ProcessingPackagingItem<PrimaryPackagingType>[] | null;
  secondary?: ProcessingPackagingItem<SecondaryPackagingType> | null;
  tertiary?: ProcessingPackagingItem<TertiaryPackagingType>[] | null;
}

export interface ProcessingPackaging {
  freshMeatAndEdibleOffal?: Packaging | null;
  foodGradeBones?: Packaging | null;
  foodGradeFat?: Packaging | null;
  cat1And2MaterialAndWaste?: Packaging | null;
  cat3SlaughterByProducts?: Packaging | null;
  cat3HidesAndSkins?: Packaging | null;
  additionalCategories?: Array<{ outputType: FacilityOutputs }>
}

export interface ProcessingStageData {
  processingInput: ProcessingInput;
  processingOutput: ProcessingOutput;
  processingPackaging: ProcessingPackaging;
}

export interface ProcessingStage extends Stage {
  facilityType: string;
  facilityId: string;
  stageData: ProcessingStageData;
}



export enum FacilityOutputs {
  freshMeatAndEdibleOffal = 'FRESH_MEAT_AND_EDIBLE_OFFAL',
  foodGradeBones = 'FOOD_GRADE_BONES',
  foodGradeFat = 'FOOD_GRADE_FAT',
  cat1And2MaterialAndWaste = 'CAT1_AND2_MATERIAL_AND_WASTE',
  cat3SlaughterByProducts = 'CAT3_SLAUGHTER_BY_PRODUCTS',
  cat3HidesAndSkins = 'CAT3_HIDES_AND_SKINS',
}

export enum FacilityOutputNames {
  FRESH_MEAT_AND_EDIBLE_OFFAL = 'freshMeatAndEdibleOffal',
  FOOD_GRADE_BONES = 'foodGradeBones',
  FOOD_GRADE_FAT = 'foodGradeFat',
  CAT1_AND2_MATERIAL_AND_WASTE = 'cat1And2MaterialAndWaste',
  CAT3_SLAUGHTER_BY_PRODUCTS = 'cat3SlaughterByProducts',
  CAT3_HIDES_AND_SKINS = 'cat3HidesAndSkins',
}
