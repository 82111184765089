import { isNil } from "lodash";
import React from "react";
import { UserProfilePrefs } from "./UserProfilePrefs";

export const useNotifications = () => {
  
  const [notifications, setNotifications] = React.useState([]);
  const userPref = UserProfilePrefs.getInstance();
  const attempts = React.useRef(0);

  const isNotificationActive = ({publishAt, expiresAt}) => {
    const publishAtTime = new Date(Date.parse(publishAt)).getTime();
    const expiresAtTime = expiresAt?new Date(Date.parse(expiresAt)).getTime():null;
    const now = new Date().getTime();
    // console.log("isActive 1",publishAt,expiresAt, publishAtTime <= now);
    if (expiresAt) {
      return (
        publishAtTime <= now && now < expiresAtTime
      );
    } else {
       return publishAtTime <= now;
    }
  };

  const isNotificationActiveForMe = ({publishAt, expiresAt}, lastLoginTime) => {
    const publishAtTime = new Date(Date.parse(publishAt)).getTime();
    const expiresAtTime = expiresAt?new Date(Date.parse(expiresAt)).getTime():null;
    const now = new Date().getTime();
    // console.log("isActive 2",publishAt,expiresAtTime,new Date(lastLoginTime));
    if (expiresAt) {
      return (
        publishAtTime <= now && now < expiresAtTime && 
        (lastLoginTime ? publishAtTime > lastLoginTime : true)
      );
    } else {
      return publishAtTime <= now && (lastLoginTime ? publishAtTime > lastLoginTime : true);
    }
  };

  const isFirstTimeLogged = React.useCallback((lastLogin) => {
    // console.log("lastLogin: ", lastLogin);
    if(isNil(lastLogin)) {
      return true;
    }

    if (lastLogin.getFullYear() < new Date().getFullYear() - 5) {
      return true;
    }

    return false;
  },[]);

  const initNotifications = React.useCallback( () => {
    let result = [];
    const notificationData = userPref.getNotifications();
    const lastLogin = userPref?.getUserUnitPrefs()?.lastLogin ? new Date(userPref?.getUserUnitPrefs()?.lastLogin):null;
    const lastLoginTime = lastLogin?lastLogin.getTime():null;
        
    // console.log("INIT NOTIFICATIONS CALLED", notificationData, lastLoginTime)
    if(notificationData){
      if (isFirstTimeLogged(lastLogin)) {
        // console.log("FIRST TIME LOGGED")
        result = notificationData?.filter((notification) => (!isNil(notification.isWelcome) && notification.isWelcome && isNotificationActive(notification)));
        // sort by publishAtDate
        result.sort((a,b) => {return (a.publishAt < b.publishAt) ?  1 : ((a.publishAt > b.publishAt) ? -1 : 0) });
        // get only newest 
        //result = result.slice(0,1);
      } else {
        // get only notifications that are published after my last login
        result = notificationData?.filter( (notification) =>  isNotificationActiveForMe(notification, lastLoginTime))
        if(result.some(item => (!isNil(item.isWelcome)  && item.isWelcome===true) ))
          result = result.filter(item => (!isNil(item.isWelcome) && item.isWelcome===true));
        result.sort((a,b) => {return (a.publishAt < b.publishAt) ?  1 : ((a.publishAt > b.publishAt) ? -1 : 0) });
      }
      if (result && result.length > 0) {
        // console.log("notification for me: ", result);
        setNotifications(result);
      }
    }
  },[userPref, isFirstTimeLogged]);

  React.useEffect(() => {
    // sign in called the load but it may still not be completed
    let checker = setInterval(function(){ 
      if( ( userPref.getNotificationAPICallStatus()==='COMPLETED' && userPref.getProfileAPICallStatus()==='COMPLETED') || attempts.current>20) {
        clearInterval(checker);
        initNotifications();
      }
      attempts.current+=1;
    }, 250);

  }, [userPref, initNotifications]);

  return notifications;
};
