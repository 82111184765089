import React, { MutableRefObject, useEffect, useRef } from 'react';
// import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import {
  Grid
} from "@material-ui/core";
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup";
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import ReactHookPreviewField from '../../../../../modules/Helpers/ReactHookPreviewField';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import SustellInterventionBovaerSection from '../../../../../modules/Farms/Intervention/SustellInterventionBovaerSection';
import { DairyInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { DairyEmission, DairyIntervention } from '../../../../models/Intervention';
import { LatestCalc3NOP } from '../../../../models/Baseline';
import { Maybe } from '../../../../../../graphql/types';


interface DInterventionEmissionsFormDialogProps extends DairyInterventionFormDialogProps{
  is3NOPChecked: boolean;
  // eslint-disable-next-line react/require-default-props
  bovaerOriginalCalcResult?: number | string | null;
  bovaerNewCalcResult: number | string | null;
  intervention: DairyIntervention | null;
  averageAnimalsPresentRef: MutableRefObject<number | string | null>;
  latestCalc: Maybe<LatestCalc3NOP>;
  setLatestCalc: (latestBovaerCalc: LatestCalc3NOP | null) => void;
  country: string;
  // eslint-disable-next-line react/no-unused-prop-types
  updateTotalMethaneFE: () => void;

}

const DInterventionEmissionsFormDialog = ({
  formVisible,
  formType,
  stageIndex,
  handleCancel,
  is3NOPChecked,
  updateTotalMethaneFE,
  bovaerOriginalCalcResult = null,
  bovaerNewCalcResult,
  baseline,
  intervention,
  averageAnimalsPresentRef,
  latestCalc,
  setLatestCalc,
  country
}: DInterventionEmissionsFormDialogProps) => {

  const intl = useIntl();
  const currResetValue = useRef<DairyEmission>();

  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"}) ;
  const fieldItemPrefixEmissions = `stages.${stageIndex}.stageData.emissions`;
  const fc = useFormContext();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.emissions`;

  const boveareValidationFullFieldNames = {
    dryMatterIntakeKg: `${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg`,
    ndfPercentageOfDmi: `${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage`,
    fatPercentageInDmi: `${fieldItemPrefix}.supplement3NOP.fatAmountPercentage`,
    dosage: `${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay`,
    numberOfAnimals: `${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered`
  }

  const on3NOPSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    fc.setValue(`${fieldItemPrefix}.is3NOPSelected`, event.target.checked);
    // remove errors if unchecked
    if (!event.target.checked) {
      fc.setValue(`${fieldItemPrefix}.supplement3NOP.calculatedResult_recalculate_error`, '');
      (async () => fc.trigger(`${fieldItemPrefix }.supplement3NOP`))().then(()=>{}).catch(()=>{});
    }
  }

  useEffect(() => {
    if(formVisible){
      currResetValue.current = {...(fc.getValues(fieldItemPrefixEmissions) as DairyEmission)}; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...fc.getValues() as DairyIntervention};
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if(stageData?.emissions){
        stageData.emissions = {...currResetValue.current};
        fc.reset(resetObject,{
          errors: true
        });
      }
    }
    handleCancel();
  }

  const welcomeString = intl.formatMessage({id: "EMISSIONS.WELCOME"});
  const emissions = baseline?.stages?.[stageIndex]?.stageData?.emissions;

  const getCorrection = () => {

    const bovaerNewCalcResultStr : string | null = bovaerNewCalcResult != null && typeof bovaerNewCalcResult === 'number' ? bovaerNewCalcResult?.toString() : bovaerNewCalcResult as string;

    if(bovaerNewCalcResultStr)
      return `${parseFloat(bovaerNewCalcResultStr).toFixed(2)  }%`

    
    const bovaerOriginalCalcResultStr : string | null = bovaerOriginalCalcResult != null && typeof bovaerOriginalCalcResult === 'number' ? bovaerOriginalCalcResult?.toString() : bovaerOriginalCalcResult as string;

    if(bovaerOriginalCalcResultStr)
      return `${parseFloat(bovaerOriginalCalcResultStr).toFixed(2)  }%`

    return '';

    // {bovaerNewCalcResult && !Number.isNaN(bovaerNewCalcResult) ?
    //   (parseFloat(bovaerNewCalcResult).toFixed(2) + "%") :
    //   ((bovaerOriginalCalcResult && !isNaN(bovaerOriginalCalcResult)) ?
    //       (parseFloat(bovaerOriginalCalcResult).toFixed(2) + "%") :
    //       ''
    //   )
    // }
  }

  return (
    <DialogContainer
      formVisible = { formVisible }
      variant = 'wide'
      datasetType = 'intervention'
      handleClose = { handleResetClick }
      iconCode="emissionIcon"
      formTitle = { formTitle }
      introText = { intl.formatMessage({id: "EMISSIONS.INTRO"}, {boldedWelcome: <strong>{welcomeString}</strong>, br: <br/>}) }
    > 
      <Grid container spacing={2} direction="row" justifyContent="space-between" >
        <Grid item container direction="column" xs={6} spacing={2} >
        <Grid item >
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefixEmissions  }.methaneEntericFermentation`}
              label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION"})}
              margin="none"
              variant="outlined"
              tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP"})}
              metricUnit="%"
              updateTotalMethane={updateTotalMethaneFE}
              baseline={emissions?.methaneEntericFermentation}
              disabled={formType === "view"}
              minAllowedValue={-100}
            />
          </Grid>
          {is3NOPChecked &&
            <Grid style={{marginBottom: '12px', marginTop: '-12px', marginLeft: '8px'}}>
              {intl.formatMessage({ id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.EMMISION_CORRECTION' })}:&nbsp;&nbsp;
              <b>{getCorrection()}</b>
              <span > &nbsp;/&nbsp; {intl.formatMessage({ id:'GENERAL.TOTAL_VALUE'})}:&nbsp;&nbsp;
                <span style={{marginLeft: '-14px'}}>
                <ReactHookPreviewField
                  name={`${fieldItemPrefixEmissions  }.methaneEntericFermentationTotal`}
                  type="number"
                  precision={2}
                  textAlign="right"
                />
                </span>
              </span>
            </Grid>
          }
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefixEmissions  }.methane`}
              label={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.METHANE" }) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.METHANE.TOOLTIP" }) }
              metricUnit="%"
              baseline={emissions?.methane}
              disabled={formType === "view"}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item >
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefixEmissions  }.nitrousOxideDirect`}
              label={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NOx_DIRECT" }) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP"})}
              metricUnit="%"
              baseline={emissions?.nitrousOxideDirect}
              disabled={formType === "view"}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item >
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefixEmissions  }.nitrousOxideIndirect`}
              label={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT" }) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP" }) }
              metricUnit="%"
              baseline={emissions?.nitrousOxideIndirect}
              disabled={formType === "view"}
              minAllowedValue={-100}
            />
          </Grid>
          <Grid item  >
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefixEmissions  }.amonia`}
              label={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.AMMONIA" }) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.AMMONIA.TOOLTIP" }) }
              metricUnit="%"
              baseline={emissions?.amonia}
              disabled={formType === "view"}
              minAllowedValue={-100}
            />
          </Grid>
	        </Grid>
	        <Grid item container direction="column" xs={6} spacing={2} >
	          <Grid item  >
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefixEmissions  }.nitricOxide`}
                label={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NOx_MANURE" }) }
                margin="none"
                variant="outlined"
                tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NOx_MANURE.TOOLTIP" }) }
                metricUnit="%"
                baseline={emissions?.nitricOxide}
                disabled={formType === "view"}
                minAllowedValue={-100}
              />
	          </Grid>
	          <Grid item >
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefixEmissions  }.nonMethaneVolatile`}
                label={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC" }) }
                margin="none"
                variant="outlined"
                tooltip={ intl.formatMessage({id: "INTERVENTION.FORM.EMISSIONS.NON_METHANE_ORGANIC.TOOLTIP" }) }
                metricUnit="%"
                baseline={emissions?.nonMethaneVolatile}
                disabled={formType === "view"}
                minAllowedValue={-100}
              />
	          </Grid>
            <Grid item  >
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefixEmissions  }.nonMethaneVolatileSilage`}
                label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_SILAGE"})}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_SILAGE.TOOLTIP"})}
                metricUnit="%"
                baseline={emissions?.nonMethaneVolatileSilage}
                disabled={formType === "view"}
                minAllowedValue={-100}
              />
            </Grid>
            <Grid item>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefixEmissions  }.PM25`}
                label={ intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5"})}
                margin="none"
                variant="outlined"
                tooltip={ intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5.TOOLTIP"})}
                metricUnit="%"
                baseline={emissions?.PM25}
                disabled={formType === "view"}
                minAllowedValue={-100}
              />
	          </Grid>
            <Grid item>
              <SustellInterventionBovaerSection
                stageIndex={stageIndex}
                is3NOPChecked={is3NOPChecked}
                boveareValidationFullFieldNames={boveareValidationFullFieldNames}
                baseline={baseline?.stages[stageIndex]?.stageData}
                intervention={intervention}
                country={country}
                averageAnimalsPresentRef={averageAnimalsPresentRef}
                updateTotalMethaneFE={updateTotalMethaneFE}
                on3NOPSelectionChanged={on3NOPSelectionChanged}
                latestCalc={latestCalc}
                setLatestCalc={setLatestCalc}
                // formContext={fc}
                formType={formType}
              />
            </Grid>
	        </Grid>
	        <Grid item xs={12}>
	          <ButtonControlGroup
	            cancelHandler={handleResetClick}
	            saveHandler={ handleCancel }
	            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
	          />
	        </Grid>
	      </Grid>
	    </DialogContainer>
	  );
  };

  export default DInterventionEmissionsFormDialog;