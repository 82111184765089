import React, { FC, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { useFormContext } from 'react-hook-form';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';

import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import {
  BaselineDialogProps,
  FormType,
  LayingHensForm,
  LayingHensOutput,
} from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';

const LHOutputFormDialog: FC<BaselineDialogProps> = ({
  formType = 'add',
  itemIndex = 0,
  formVisible,
  handleCancel,
  handleSave = handleCancel,
}) => {
  const intl = useIntl();
  // TODO: leave UserProfilePrefs and userUOM as is until converted to TS
  const userProfile = UserProfilePrefs.getInstance();

  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = React.useRef<LayingHensOutput | null>(null);

  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE' },
    { br: ' ' }
  );
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const methods = useFormContext<LayingHensForm>();
  const getValues = (key?: string): ReturnType<typeof methods.getValues> =>
    key ? methods.getValues(key) : methods.getValues();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...getValues(fieldItemPrefix),
      } as LayingHensOutput; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() };
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {
          ...currResetValue.current,
        };
        methods.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="LAYING_HENS"
      formTitle={formTitle}
    >
      <Grid container direction="column" spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <RowTextFieldWithInfo
            item
            xs={12}
            name={`${fieldItemPrefix}.eggs`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.HENS.EGGS',
            })} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.HENS.EGGS.TOOLTIP',
            })}
            addornment={
              userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
            }
            disabled={formType === FormType.View}
            defaultValue={getValues(`${fieldItemPrefix}.eggs`)}
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithInfo
            item
            xs={12}
            name={`${fieldItemPrefix}.liveWeight`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.HENS.LIVEWEIGHT',
            })} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.HENS.LIVEWEIGHT.TOOLTIP',
            })}
            addornment={
              userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : 'kg'
            }
            disabled={formType === FormType.View}
            defaultValue={getValues(`${fieldItemPrefix}.liveWeight`)}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default LHOutputFormDialog;
