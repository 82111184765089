/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { PoultryBaseline } from '../../../../models/Baseline/PoultryBaseline';
import { ResourceUseV2 } from '../../../../models/Baseline/Baseline';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { AnimalType } from '../../../../../../graphql/types';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import Resources from '../../CommonDataParts/v2.0/Resources';

export interface PoultryHousingHatchingDialogProps extends BaselineDialogProps {
  animalType: AnimalType;
}

const PoultryHousingHatchingDialog: FC<PoultryHousingHatchingDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel,
  animalType
}) => {
  const intl = useIntl();
  const currResetValue = useRef<ResourceUseV2>();
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.HOUSING.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const fc = useFormContext<PoultryBaseline>();

  useEffect(() => {
    if(formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = ()=>{
    if (currResetValue.current) {
      const resetObject = {...fc.getValues()} as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.housing) {
        resetObject.stages[itemIndex].stageData.housing = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel('reset');
  }

  const onChange = () => {
    // eslint-disable-next-line no-void
    void fc.trigger(`stages.${itemIndex}.stageData.housing`);
  }

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant='demiWide'
      introText={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.HOUSING_AND_MANURE.HATCHING.RESOURCES.DESCRIPTION',
      })}
    > 
      <Resources 
        formType={formType} 
        inputType={`stages.${itemIndex}.stageData.housing.resourceUse`} 
        animalType={animalType}
        isBaselineDialog
        onChange={onChange}
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await fc.trigger(`stages.${itemIndex}.stageData.housing`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM" })}/>
    </DialogContainer>
  );
};

export default PoultryHousingHatchingDialog;