/* eslint-disable @typescript-eslint/no-unsafe-return */

import { capitalize } from "lodash";
import { IntlShape } from "react-intl";
import { AnimalType, CompoundFeed, CompoundFeedDatabase, CompoundFeedV2, Customer, FarmBaseline, FootPrintType, Footprint, Maybe } from "../../../../graphql/types";
import { DashboardFilterData, DashboardImpactCategory, DatasetInfo, DateRange } from "../../models/Dashboard/DashboardTypes";
import { getFootprintsBaselinesInterventions } from "../../../../graphql/queries";
import countryIsoCodes from "./isoCodes";
import { DashboardFootprintModel, DashboardModel } from "../../models/Dashboard";
import { footprintURL, FootprintURLProps } from "../../utils/footprint-url-utils";
import { DSM_MID_BLUE } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import { getUIDatabaseFoundation } from "../../utils/compound-feed-utils";
import countries from "../CompoundFeedWizard/countries";
import lookupValues from "../../helpers/lookupValues";
import { CompartmentType, FootprintCategoryKey } from "../../models/Footprint/FootprintTypes";


export const referencesChunks = (footprintReferencesArray: string[], itemsPerChunk: number) => 
  footprintReferencesArray.reduce((resultArray: string[][], item: string, index: number) => { 
    const retVal = resultArray;
    const chunkIndex = Math.floor(index/itemsPerChunk)
    
    if(!retVal[chunkIndex]) {
      retVal[chunkIndex] = [] // start a new chunk
    }
    
    retVal[chunkIndex].push(item)

    return retVal
  }, []);

// ------------------- Design helpers -------------------------------
export const reactSelectMultiStyles = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      color:'white',
    }),
    multiValue: (styles: any) => {
      const color = DSM_MID_BLUE
      return {
        ...styles,
        backgroundColor: color,
      };
    },
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: DSM_MID_BLUE,
        color: 'white',
      },
    })
  };

  
export const IMPACT_CATEGORY_SELECT_COLORS: {[key: string]: string} = {
  air: '#0070BA',
  land: '#007E4B',
  human:'#BD0A79',
  water: '#009B97'
}

export const IMPACT_CATEGORY_ICON_COLORS: {[key: string]: string} = {
  air: '#003478',
  land: '#007E4B',
  human: '#BD0A79',
  water: '#009B97'
  }

export const createImpactCategoryObj = (
  impactCategoryKey: string,
  intl: IntlShape,
  // lookupValues: any,
  dashboard1: DashboardModel
): DashboardImpactCategory => {
  let type;
  Object.entries(lookupValues.impactCategoriesMapping.all).forEach(([key, val]) => {
    if (key === impactCategoryKey && val && val.length > 0) {
      [type] = val;
      return true;
      return true;
    }
  });

  let name = intl.formatMessage({ id: "REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.CLIMATE_CHANGE" });

  if (type === CompartmentType.LAND) {
    name = intl.formatMessage({ id: "REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.LAND_USE" });
  } else if (type === CompartmentType.HUMAN) {
    name = intl.formatMessage({ id: "REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.RESPIRATORY_INORGANICS" });
  } else if (type === CompartmentType.WATER) {
    name = intl.formatMessage({ id: "REPORT.FOOTPRINTS.TILE_WIDGET.SUBTITLE.WATER_SCARCITY" });
  }

  return {
    key: impactCategoryKey as unknown as FootprintCategoryKey,
    name: name === impactCategoryKey ? intl.formatMessage({ id: "GENERAL.TOTAL" }) : name,
    compartmentType: (type || CompartmentType.AIR) as CompartmentType,
    unit: dashboard1.getUnitForImpactCategory(impactCategoryKey) || ''
  };
};

/**
 * 
 * @param footprint - Dashboard footprint object
 * @return path & state for calling history.push
 */
export const getOpenFootprintObj = (customerId: string, customerName: string, footprint: DashboardFootprintModel) => {
  if (footprint.type !== FootPrintType.CompoundFeed) {
    const additionalData = footprint.additionalData as DatasetInfo;

    const pathProps: FootprintURLProps = {
      baselineCustomerID: customerId,
      baselineFarmID: additionalData.farm.id,
      baselineProcessID: additionalData.animalSystem.id || '',
      baselineType: footprint.type === FootPrintType.FarmBaseline ? 'b' : 'i' ,
      baselineReference: footprint.reference
    };

    let name = additionalData.activeBaseline?.name;

    if (footprint.type === FootPrintType.FarmIntervention) {
      name = additionalData.interventions?.find(int => int?.reference === footprint.reference)?.name || 'unknown name'
    }

    const historyStateProps = {
      farmName: additionalData.farm.name,
      processName: additionalData.animalSystem.name,
      dataSetName: name,
    }

    const pathname = footprintURL(pathProps);
    if (!pathProps || !historyStateProps || !pathname) {
      console.error('error creating footprint path farms');
      return {pathname: undefined, historyStateProps: undefined};
    }
    return {pathname, historyStateProps};
  } 

  const additionalData = footprint.additionalData as CompoundFeed;
  const urlProps = {
    baselineCustomerID: customerId,
    baselineType: 'f',
    baselineReference: footprint.reference,
    baselineProcessID: '',
    baselineFarmID: ''
  };
  const pathname = footprintURL(urlProps);
  return {
    pathname,
    historyStateProps: {
      customerName,
      dataSetName: additionalData.name,
    }
  }
}

// ------------------- Farm Dashboard helper functions -------------------------------

interface FarmBaselineExt extends FarmBaseline {
  lastModified: Date;
}

const checkDateRangesOverlap = (
  startDate: Date,
  endDate: Date | undefined,
  validFrom: Date,
  validTo: Date
) => {
  const maxStart = new Date(Math.max(startDate.getTime(), validFrom.getTime()));
  const minEnd = endDate ?
     new Date(Math.min(endDate?.getTime(), validTo.getTime()))
     : true; // Undefined end date is to inf

  return maxStart <= minEnd;
}

/**
 * 
 * @param baselines response from BE baselines
 * @param startDate selected start date (default is today)
 * @param endDate selected end date (default unedfined)
 * @param isCurrent if true - return only active(one) baseline
 * @param isArchived if true - return archived baselines
 * @returns filtered - active baselines for given range
 */
export const getActiveBaselines = (
    baselines: Maybe<Maybe<FarmBaseline>[]> | undefined,
    startDate: Date | undefined = new Date(), 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    endDate: Date | undefined = undefined, // TODO: may be used later
  ): FarmBaseline[] | undefined => {
 
  if (!baselines || baselines === null) {
    return undefined;
  }
  // console.log('baselines', baselines);
  baselines.forEach((baselineExt: FarmBaselineExt) => {
    if (!baselineExt) {
      return;
    }
    const baseline = baselineExt;
    if (baseline.updatedAt) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (!!baseline.updatedAt && baseline.updatedAt instanceof Date)
        ? (baseline.lastModified = baseline.updatedAt)
        : (baseline.lastModified = new Date(baseline.updatedAt));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (!!baseline.createdAt && baseline.createdAt instanceof Date)
        ? (baseline.lastModified = baseline.createdAt)
        : (baseline.lastModified = new Date(baseline.createdAt || 0));
    }
  })


  const sortedBaselines = baselines.sort(
    (baseline_a: FarmBaselineExt, baseline_b: FarmBaselineExt) => {
     const validFromA: Date = new Date(baseline_a.validFrom || 0);
     const validFromB: Date = new Date(baseline_b.validFrom || 0);

     const lastModifiedA: Date = new Date(baseline_a.lastModified || 0);
     const lastModifiedB: Date = new Date(baseline_b.lastModified || 0);

     return (
      validFromB.getTime() - validFromA.getTime() ||
      lastModifiedB.getTime() - lastModifiedA.getTime()
     )
    });
    // console.log('sorted baselines', sortedBaselines);
    if (!sortedBaselines || sortedBaselines === null) {
      return [];
    }

    const activeBaselines: FarmBaseline[] = [];
    sortedBaselines
      .filter( bl => {
        const validFromDate = new Date(bl?.validFrom || 0);
        const validToDate = bl?.validTo ? new Date(bl.validTo) : new Date(8.64e15);

        // returned format does not include time, set to start of day for valid from and to end for valid to
        validFromDate.setHours(0,0,0,0);
        validToDate.setHours(23,59,59,999);

        const isOverlapping = checkDateRangesOverlap(
          startDate,
          endDate,
          validFromDate,
          validToDate
        )

        return isOverlapping;
      })
      .forEach(bl => bl !== null ? activeBaselines.push(bl) : undefined);

    if (activeBaselines.length === 0) {
      return [];
    }
    // console.log('active ', activeBaselines);
    return activeBaselines;
}

export const extractFarmData = (customer: Customer, dateRange: DateRange): DatasetInfo[]  => {
  const activeBaselinesExtended: DatasetInfo[] = [];
  // console.log(customer);
  customer.farms?.items?.forEach(farm => {
    farm?.animalSystems?.items?.forEach(pp => {
      const activeBaselines = getActiveBaselines(pp?.baselines?.items, dateRange.startDate, dateRange.endDate);
      // console.log('active baselines: ', activeBaselines)
      activeBaselines?.forEach((bl, index) => {

        const additional: DatasetInfo = {
          isCurrent: index === 0,
          customerId: customer.id,
          farm: {
            name: farm.name,
            id: farm.id,
            country: farm.country
          },
          animalSystem: {
            id: pp?.id,
            name: pp?.name,
            animalType: pp?.animalType
          },
          activeBaseline: bl,
          interventions: pp?.interventions
                           ?.items
                           ?.filter(int => int?.baseline === bl?.reference) || []
        };
  
        if (activeBaselines) {
          activeBaselinesExtended.push(additional);
        }
      });
      
    })
  });
  return activeBaselinesExtended;
}

export const buildFarmFootprintsQuery = (referencesBaselines: string[], interventionReferences: string[], customerId: string) => ({
    query: getFootprintsBaselinesInterventions,
    variables: {
      customerId,
      interventionReferences: interventionReferences.length > 0 ? interventionReferences : [''],
      referencesBaselines: referencesBaselines.length > 0 ? referencesBaselines : [''],
    }
});

const getAnimalTypeLabel = (animal: string | undefined): string => {
  if (animal) {
    const adjustedAnimal = animal.replaceAll('_', ' ');
    
    if (animal === AnimalType.Broiler || 
      animal === AnimalType.LayingHens ||
      animal === AnimalType.Salmon ||
      animal === AnimalType.Dairy
    ) {
      return (adjustedAnimal + ' 1.0');
    }

    if (animal === AnimalType.PigFattening) {
      return 'Pig 1.0';
    }

    if (animal === AnimalType.BroilerV2 || 
      animal === AnimalType.LayingHensV2 ||
      animal === AnimalType.SalmonV2
    ) {
      return adjustedAnimal.replace('V2', '2.0');
    }

    if (animal === AnimalType.LitopenaeusVannamei || 
      animal === AnimalType.PenaeusMonodon
    ) {
      return ('Shrimp 2.0 - ' + adjustedAnimal);
    }

    return (adjustedAnimal + ' 2.0');
  }

  return 'unknown';
}

export const getDashboardFilterDataFarms = (data: DatasetInfo): DashboardFilterData => {
  const retVal: DashboardFilterData = {
    farms : [{
      name: data.farm.name,
      id: data.farm.id
    }],
    animals: [{
      name: capitalize(getAnimalTypeLabel(data.animalSystem.animalType)),
      id: data.animalSystem.animalType,
    }],
    countries: [{
      name: countryIsoCodes[data.farm.country as unknown as keyof typeof countryIsoCodes],
      id: data.farm.country,
    }],
     databaseFoundation: []
  };
  return retVal;
}

export const getBaselineReferences = (baselines: DatasetInfo[] , isCurrent: boolean, isArchived: boolean): string[] => {
  if (!isCurrent && !isArchived) {
    return [];
  }
  const retVal: string[] = [];

  baselines.forEach(el => {
    if (isCurrent && el.isCurrent && el.activeBaseline?.reference) {
      retVal.push(el.activeBaseline.reference);
    }
    if (isArchived && !el.isCurrent && el.activeBaseline?.reference) {
      retVal.push(el.activeBaseline.reference);
    }
  });

  return retVal;
}

export const getInterventionReferences = (baselines: DatasetInfo[] , isCurrent: boolean, isArchived: boolean) => {
  if (!isCurrent && !isArchived) {
    return [];
  }

  const matchedBaselines: DatasetInfo[] = [];

  baselines.forEach(el => {
    if (isCurrent && el.isCurrent) {
      matchedBaselines.push(el);
    }
    if (isArchived && !el.isCurrent) {
      matchedBaselines.push(el);
    }
  });

  return matchedBaselines
        .flatMap((bl) => bl.interventions)
        .filter((int) => int && int !== null && int.reference)
        .map((int) => int?.reference || '');
}


export const createDashboardFarmFootprints = (footprintsResponse:  {data: {
  baselines: Maybe<Footprint[]> | undefined;
  interventions: Maybe<Footprint[]> | undefined;
}}, activeBaselinesExtended: DatasetInfo[]): DashboardFootprintModel[] => {
  const dashboardFootprints: DashboardFootprintModel[] = [];

  const responseInterventionFootprints =
    footprintsResponse.data.interventions || [];

  footprintsResponse.data.baselines?.forEach(
    (footprint: Footprint) => {
      if (!footprint || !footprint.calculatedImpact) {
        return;
      }
      const baseline = activeBaselinesExtended.find(
        (bl) => bl.activeBaseline?.reference === footprint.reference
      ) as DatasetInfo;
      const name = baseline?.activeBaseline?.name || '';

      const filterData = getDashboardFilterDataFarms(baseline);

      // For each baseline create dashboard footprint model
      const dFooptprintModel = new DashboardFootprintModel(
        {
          name,
          targets: undefined,
          ...footprint,
          type: FootPrintType.FarmBaseline,
        },
        filterData,
        baseline
      );

      dashboardFootprints.push(dFooptprintModel);
  });

  responseInterventionFootprints.forEach(footprint => {
    if (!footprint || footprint === null) {
      return;
    }
    const baseline = activeBaselinesExtended.find(bl => 
      (bl.interventions || [])?.findIndex(int => int?.reference === footprint.reference) > -1
    );

    const intervention = baseline?.interventions?.find(int => int?.reference === footprint.reference);

    if (!baseline || !intervention) {
      console.log('error finding intervention/baseline for foorptint: ', footprint.reference);
      return;
    }

    const filterData = getDashboardFilterDataFarms(baseline);

    const nameInt = `${
      baseline?.animalSystem.name || ''
    } - int(${intervention?.name || 'unknown'})`;
    const intdFooptprintModel = new DashboardFootprintModel(
      {
        name: nameInt,
        targets: undefined,
        ...footprint,
        type: FootPrintType.FarmIntervention,
      },
      filterData,
      baseline
    );
    dashboardFootprints.push(intdFooptprintModel);
  });
  return dashboardFootprints;
}

// ------------------- Feeds Dashboard helper functions -------------------------------

export const getDashboardFilterDataFeed = (data: CompoundFeed): DashboardFilterData => {
  const retVal: DashboardFilterData = {
    farms: [],
    animals: [],
    countries: [
      {
        id: data.feedmill_location.iso_code,
        name: data.feedmill_location.name || 'unknown'
      }
    ],
    databaseFoundation: [
      {
        name: getUIDatabaseFoundation(CompoundFeedDatabase.Afp_5),
        id: CompoundFeedDatabase.Afp_5
      }
    ]
  };

  data.target_species.forEach(animalType => {
    retVal.animals.push({
      name: capitalize(animalType?.replace('_', ' ') || 'unknown'),
      id: animalType !== null ? animalType.toString() : 'unknown',
    });
  });

  return retVal;
}

export const getDashboardFilterDataFeedV2 = (data: CompoundFeedV2): DashboardFilterData => {
  const countryName = countries.find(item => item.value === data.feedmillLocation)?.display_name || data.feedmillLocation;
  const retVal: DashboardFilterData = {
    farms: [],
    animals: [],
    countries: [
      {
        id: data.feedmillLocation,
        name: countryName
      }
    ],
    databaseFoundation: [
      {
        id: data.databaseFoundation || 'unknown',
        name: getUIDatabaseFoundation(data.databaseFoundation)
      }
    ]
  };

  data.targetSpecies.forEach(animalType => {
    retVal.animals.push({
      name: capitalize(animalType?.replace('_', ' ') || 'unknown'),
      id: animalType !== null ? animalType.toString() : 'unknown',
    });
  });

  return retVal;
}

export const createDashboardFeedFootprints = (
  footprintResponse: {
    data: {
      getFootprint: Footprint[];
    };
  },
  feeds: CompoundFeed[],
  feedsV2: CompoundFeedV2[]
): DashboardFootprintModel[] => {
  const dashboardFootprints: DashboardFootprintModel[] = [];
  const footprints =
    footprintResponse.data.getFootprint?.filter((f) => !!f && f !== null) ||
    [];
  footprints.forEach((footprint) => {
    let isFeedV1 = true;
    let feed: CompoundFeed | CompoundFeedV2 | undefined = feeds.find((fe) => fe.id === footprint.reference);
    if (!feed) { 
      feed = feedsV2.find((fe) => fe.id === footprint.reference);
      if (feed) {
        isFeedV1 = false;
      }
    }
    if (!feed || feed === null) {
      return;
    }
    let filterData;
    if (isFeedV1) {
      filterData = getDashboardFilterDataFeed(feed as CompoundFeed);
    } else {
      filterData = getDashboardFilterDataFeedV2(feed as CompoundFeedV2);
    }

    const dFooptprintModel = new DashboardFootprintModel(
      {
        name: feed.name,
        targets: undefined,
        ...footprint,
        type: FootPrintType.CompoundFeed,
      },
      filterData,
      feed
    );
    dashboardFootprints.push(dFooptprintModel);
  });
  return dashboardFootprints;
};
