import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { FC, useEffect, useState } from "react";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { Stage } from "../../../../../../graphql/types";
import StepperButtons from "./StepperButtons";

// placeholder types
export type AnyEvent = any;

interface StageComponentProps {
  showStageForm: () => void;
  forwardStep: (state: boolean) => void;
  backwardStep: (state: boolean) => void;
  stageFormOpen: boolean | undefined;
  selectedStages: Array<Stage>;
  isAllowedToAddNewStage: boolean;
}

export const StageComponent: FC<StageComponentProps> = ({
  stageFormOpen,
  showStageForm,
  forwardStep,
  backwardStep,
  selectedStages, // dynamic array of stages
  isAllowedToAddNewStage, // Hide add stage button if false,
}) => {
  const intl = useIntl();

  const [firstStageType, setFirstStageType] = useState('');

  useEffect(() => {
    if (selectedStages.length) {
      setFirstStageType(selectedStages[0]?.type || '')
    }
  },[selectedStages])

  const gotoNextStep = () => {
    forwardStep(true);
  };
  const selectedStagesNumber = selectedStages?.length || 0;

  useEffect(() => {
    if (selectedStagesNumber === 0 && !stageFormOpen) {
      showStageForm();
    }
  }, [selectedStages, stageFormOpen, selectedStagesNumber, showStageForm]);

  return (
    <div>
      <DsmGrid containerOnly>
        {isAllowedToAddNewStage && (
          <DsmButton
            style={{ marginTop: 'var(--dsm-spacing-px-6' }}
            onClick={() => showStageForm()}
            variant="text"
            color="primary"
            id="prod-proc-add-btn"
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({ id: 'SUSTELL.ADD_STAGE' })}
          </DsmButton>
        )}
      </DsmGrid>
      {/* Buttons for stepping forward */}
      {!stageFormOpen && selectedStagesNumber > 0 && (
        <StepperButtons
          cancelHandler={undefined}
          hideCancelButton
          continueHandler={selectedStagesNumber > 0 ? gotoNextStep : undefined}
          continueLabel={intl.formatMessage({ id: 'SUSTELL.CONTINUE' })}
          backButtonLabel = {intl.formatMessage({ id: 'GENERAL.BACK' })}
          backButtonHandler={backwardStep}
        />
      )}
    </div>
  );
};

export default StageComponent;
