import { Auth, Hub } from 'aws-amplify';
import { UserProfilePrefs } from '../Helpers/UserProfilePrefs';
import {
  fetchCustomersAndSelectDefaultOrFirst,
  resetSelectedCustomer,
} from '../../state/selected-customer/selectedCustomerSlice';
import { store } from '../../state/store';
import useAdobeDataLayer from '../../sustell_15/analytics/adobeDataLayer';

export function initializeProfileListener() {
  let userPrefs = UserProfilePrefs.getInstance();
  const { loginEvent } = useAdobeDataLayer();

  let listener = async (data) => {
    let evType = data?.payload?.event;
    if (evType === 'forgotPasswordSubmit' && window._satellite) {
      window._satellite.track('forgotPassword');
    }
    if (evType === 'signIn') {
      loginEvent();
      localStorage.setItem('notificationsViewed', 'false');
      userPrefs.getUserProfile(true);

      //clear customer context if different user logged in
      //userPrefs.setCustomerContextId(null);
      Auth.currentAuthenticatedUser().then((user) => {
        store.dispatch(fetchCustomersAndSelectDefaultOrFirst());

        userPrefs.setUser(user);
        userPrefs.setUserLoginSettings();

        const currentUsername = user?.username;
        const previousUsername = localStorage.getItem('customerContextUser');
        //if the user who previously logged in is not the same as the user who currently logged in, clear context
        if (currentUsername !== previousUsername)
          userPrefs.setCustomerContextId(null);
        //set current user for the customer context
        localStorage.setItem('customerContextUser', currentUsername);
        // set to show Sample Data dialog
        localStorage.setItem('toShowRequestSampleDataDialog', "ture");
      });
    }
    if (evType === 'signOut' || evType === 'tokenRefresh_failure') {
      store.dispatch(resetSelectedCustomer());
      userPrefs.resetUserProfile();
    }
    if (evType === 'tokenRefresh') {
      userPrefs.getUserProfile();
    }
  };
  Hub.listen('auth', listener); // listen for login/signup events
  return userPrefs;
}
