import {
  DsmGrid,
} from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { AnimalType, InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { CSSClassesList, UserUOMPreferences } from '../../../../helpers/helperTypes';
import {
  InternalSourcesInput,
  PigStage,
} from '../../../../models/Baseline/PigBaseline';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import { unitLong } from '../../../../utils/unit-utils';
import { FormType } from '../../common';

interface InterventionInternalInputProperties {
  stageIndex: number;
  itemIndex: number;
  stages: PigStage[];
  formType: FormType;
  item: InternalSourcesInput;
  breedingStagesList: string[]|undefined;
  baselineStageOriginAnimalType?: Maybe<AnimalType>|string|undefined;
}

const InterventionInternalSourcesInput: FC<
  InterventionInternalInputProperties
> = ({
  stageIndex = 0,
  itemIndex = 0,
  stages,
  item,
  formType,
  breedingStagesList=[],
  baselineStageOriginAnimalType=''
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.internalSources[${itemIndex}]`;
  const intl = useIntl();
  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs() as UserUOMPreferences;
  const distanceUnit = unitLong(userUOM.unitTransportDistanceTerrestrial);

  const isInputStageBreeding = breedingStagesList.includes(item.originStageId as string);
  const newAnimalTooltip = isInputStageBreeding ? "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.NEW_ANIMALS.TOOLTIP" : "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.NEW_ANIMALS.TOOLTIP";

  const getStageName = (stageId: string): string =>
    (stages || []).find((stage) => stage.id === stageId)?.name || '';

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        type="hidden"
        value={item.id}
        ref={formContext.register()}
        name={`${fieldItemPrefix}.id`}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolderIntervention}>
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.numberPigs`}
          label={intl.formatMessage(
            {
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.NEW_ANIMALS',
            },
            { stage: getStageName(item.originStageId as string) }
          )}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.NEW_ANIMALS.TOOLTIP',
          })}
          metricUnit=""
          type="number"
          baseline={item.numberPigs}
          disabled={formType === FormType.View}
        />
        { isInputStageBreeding && 
        <div style={{ marginBottom: "var(--dsm-spacing-px-4)"}}>
          <ReactHookDsmSelect style={{ width: '100%'}}
            name={`${fieldItemPrefix}.originAnimalType`}
            label={ intl.formatMessage( {id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE"}) }
            disabled
            adornment={ intl.formatMessage ({ id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.PLACEHOLDER"})}
            tooltip={ intl.formatMessage({id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.TOOLTIP" })}
            options={ enumToOptionsArrayWithTranslatedStrings(AnimalType, intl, "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE") }
            defaultValue = { baselineStageOriginAnimalType || AnimalType.Piglet}
          />
          <RowControlledTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageAgeOfPigs`}
            label={ intl.formatMessage({ id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVERAGE_AGE_PIGS"}) }
            tooltip={ intl.formatMessage({ id: newAnimalTooltip})}
            metricUnit=""
            type="number"
            baseline={item.averageAgeOfPigs}
            disabled={formType === FormType.View}
          />
        </div>
      }
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.distanceTransport`}
          label={intl.formatMessage(
            {
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.TRANSPORT_DISTANCE',
            },
            { stage: getStageName(item.originStageId as string) }
          )}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.TRANSPORT_DISTANCE.TOOLTIP',
          })}
          type="number"
          metricUnit={distanceUnit}
          baseline={item.distanceTransport}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
    </>
  );
};

export default InterventionInternalSourcesInput;
