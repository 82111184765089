import clsx from 'clsx';
import { FC } from 'react';

import { Box, Typography } from '@material-ui/core';
import { DsmIcon } from '@dsm-dcs/design-system-react';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

export interface StageButtonBoxProps {
  iconCode: string;
  title?: string;
  titleCode?: string;
  completness?: number;
  error?: any;
  descriptionTitle?: string;
  description?: string;
  handleOpen: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  showCompletness?: boolean;
  optional?: boolean;
}

export const StageButtonBox: FC<StageButtonBoxProps> = ({
  iconCode,
  title = '',
  titleCode = 'TITLE.PLACEHOLDER',
  descriptionTitle = '',
  description = '',
  completness = 0,
  error = null,
  handleOpen,
  disabled = false,
  showCompletness = true,
  optional = false,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  let titleString = title;
  if (!titleString)
    titleString = intl.formatMessage(
      { id: titleCode },
      { br: <br /> }
    ) as string;

  return (
    <Box>
      <Box
        className={clsx(
          disabled ? classes.stageBtnBoxDisabled : classes.stageBtnBox,
          !showCompletness || completness === 100 ? 'completed' : '',
          error ? 'error' : ''
        )}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onClick={(e) => {
          if (handleOpen) handleOpen(e);
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box
            className={clsx(
              classes.iconHolder,
              disabled ? 'disabled' : '',
              !showCompletness || completness === 100 ? 'completed' : '',
              error ? 'error' : ''
            )}
          >
            <DsmIcon
              name={iconCode}
              className={clsx(
                classes.iconInTheBox,
                disabled ? 'disabled' : '',
                !showCompletness || completness === 100 ? 'completed' : '',
                error ? 'error' : ''
              )}
            />
          </Box>
          <Typography variant="h5" className={classes.stageBtnTitle}>
            {titleString}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-end">
        {descriptionTitle && <Typography variant="body1" className={classes.stageBtnDescTitle}>
            {intl.formatMessage({ id: descriptionTitle })}
          </Typography>}
          <Typography variant="body1" className={classes.stageBtnDesc}>
            {intl.formatMessage({ id: description })}
          </Typography>
        </Box>
      </Box>
      {!optional && !error && showCompletness && completness === 100 && (
        <Box className={classes.completnessText}>
          <DsmIcon name="general/check" className={classes.iconBeforeText} />
          <div>{intl.formatMessage({ id: 'SUSTELL.STAGE.BOX.COMPLETED' })}</div>
        </Box>
      )}
    </Box>
  );
};

export default StageButtonBox;
