import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, BeefFarms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import BeefInputPartExternalSource from './BeefInputPartExternalSource';
import BeefInputPartInternalSource from './BeefInputPartInternalSource';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { AnimalProductivity } from '../../../../../../graphql/generated/blonk/cattle';
import { defaultValues } from '../../baselineDefaultValues';
import {
  BeefBaseline,
  BeefBreedingStageData,
  BeefInputBreeding,
} from '../../../../models/Baseline/BeefBaseline';
import { Tooltip } from '@material-ui/core';
import DefinitionsDialog from '../../CommonDataParts/v2.0/DefinitionsDialog';
import lookupValues from '../../../../helpers/lookupValues';

const beefInputStageDefaultValues =
  defaultValues.BEEF.stages[0].stageData.input;

export interface BeefInputDialogProps extends BaselineDialogProps {
  farms: BeefFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

const BeefInputBreedingDialog: FC<BeefInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<BeefInputBreeding>();
  const formContext = useFormContext<BeefBaseline>();
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control: formContext.control,
    keyName: 'keyId',
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const addInternalSource = () => {
    appendInternal({ ...beefInputStageDefaultValues.internalSources[0] });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
  };

  const addExternalSource = () => {
    appendExternal({ ...beefInputStageDefaultValues.externalSources[0] });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(formContext.getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as BeefBaseline;
      const breedingStageData = resetObject.stages[itemIndex]
        ?.stageData as BeefBreedingStageData;
      if (breedingStageData.input) {
        breedingStageData.input = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel('reset');
  };

  const handleSaveClick = async () => {
    await formContext.trigger(fieldItemPrefix);
    handleCancel('confirm');
  };

  const cattleProductivityOptions = () =>
    Object.entries(AnimalProductivity).map(([key, value]) => ({
      text: key,
      value,
    }));

  const resetToDefaultValues = () => {
    // reset cattleProductivity
    formContext.setValue(
      `${fieldItemPrefix}.cattleProductivity`,
      beefInputStageDefaultValues.cattleProductivity
    );
    formContext.clearErrors(`${fieldItemPrefix}.cattleProductivity`);
    // reset averageWeightAtBirth
    formContext.setValue(
      `${fieldItemPrefix}.averageWeightAtBirth`,
      beefInputStageDefaultValues.averageWeightAtBirth
    );
    formContext.clearErrors(`${fieldItemPrefix}.averageWeightAtBirth`);
    // reset shrinkingRate from internalSources
    internalSources?.forEach((_, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.internalSources.${index}.shrinkingRate`,
        beefInputStageDefaultValues.internalSources[0].shrinkingRate
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.internalSources.${index}.shrinkingRate`
      );
    });
    externalSources?.forEach((item, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.externalSources.${index}.shrinkingRate`,
        beefInputStageDefaultValues.externalSources[0].shrinkingRate
      );
      formContext.clearErrors(`${fieldItemPrefix}.externalSources.${index}.shrinkingRate`);
    });
  };

  return (
    <div>
      {openDescriptionDialog && (
        <DefinitionsDialog
          open
          prefix='BASELINE.FORM.INPUT.BEEF.CATTLE'
          itemHeader='BASELINE.FORM.INPUT.BEEF.CATTLE'
          items={lookupValues.cattleInputDefinitions}
          handleClose={() => setOpenDescriptionDialog(false)}
        />
      )}
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleResetClick}
        iconCode="general/check-heart"
        formTitle={intl.formatMessage({
          id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
        })}
        introText={intl.formatMessage({
          id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.BEEF_DESCRIPTION',
        })}
        variant="wide"
      >
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmPicker
            name={`${fieldItemPrefix}.startDate`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.START_DATE',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.BREEDING.START_DATE.TOOLTIP'
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(`${fieldItemPrefix}.startDate`)}
          />
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.cattleProductivity`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.CATTLE_PRODUCTIVITY',
            })}
            options={cattleProductivityOptions()}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.CATTLE_PRODUCTIVITY.TOOLTIP'
            })}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.cattleProductivity`
            )}
          />
          <div />
          <DsmButton variant="text" className="mb-5" onClick={() => setOpenDescriptionDialog(true)}>
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({ id: 'DATABASE_FOUNDATION_DEFINITIONS' })}
          </DsmButton>
        </DsmGrid>
        <p className={classes.headingTitle}>
          {intl.formatMessage({ id: 'SUSTELL.STAGE.BEEF.INPUT.COWS' })}
        </p>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.animalsPresentAtStart`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMALS_PRESENT_START',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMALS_PRESENT_START.TOOLTIP'
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.animalsPresentAtStart`
            )}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMALS_PRESENT_START.ADORNMENT',
            })}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.animalsPresentAtEnd`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMALS_PRESENT_END',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMALS_PRESENT_END.TOOLTIP'
            })}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.animalsPresentAtEnd`
            )}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMALS_PRESENT_END.ADORNMENT',
            })}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightAtStart`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_START',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_START.TOOLTIP'
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightAtStart`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_START.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightNewAnimals`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.BREEDING.AVERAGE_WEIGHT_NEW_ANIMALS.TOOLTIP',
            })}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightNewAnimals`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <div>
            <label
              htmlFor="internalSource"
              className={classes.headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.INPUT.INTERNAL_SOURCE',
              })}
            </label>
            <div>
              {internalSources &&
                internalSources?.map((item, index) => (
                  <BeefInputPartInternalSource
                    key={item.keyId}
                    farms={farms}
                    farmId={farmId}
                    farmName={farmName}
                    allowedStagesForFarm={[StageType.Breeding, StageType.Growing]}
                    productionProcessName={productionProcessName}
                    stageIndex={itemIndex}
                    itemIndex={index}
                    fieldPrefix={fieldItemPrefix}
                    removeHandler={removeInternalSource}
                    stageType={StageType.Breeding}
                    formType={formType}
                  />
                ))}
              <DsmButton
                variant="text"
                onClick={addInternalSource}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.STAGE.BEEF.INPUT.ADD_INTERNAL_SOURCE',
                })}
              </DsmButton>
            </div>
          </div>
          <div>
            <label
              htmlFor="externalSources"
              className={classes.headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
            <div>
              {externalSources &&
                externalSources?.map((item, index) => (
                  <BeefInputPartExternalSource
                    key={item.keyId}
                    stageIndex={itemIndex}
                    itemIndex={index}
                    propName="externalSources"
                    removeHandler={removeExternalSource}
                    formType={formType}
                    stageType={StageType.Breeding}
                  />
                ))}
              <DsmButton
                variant="text"
                onClick={addExternalSource}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.STAGE.BEEF.INPUT.ADD_EXTERNAL_SOURCE',
                })}
              </DsmButton>
            </div>
          </div>
        </DsmGrid>
        <p className={classes.headingTitle}>
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.INPUT.CALVES',
          })}
        </p>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightAtBirth`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_BIRTH',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_BIRTH.TOOLTIP'
            })}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightAtBirth`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.BEEF.INPUT.AVERAGE_WEIGHT_BIRTH.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
          <ReactHookDsmInput
            required
            name={`${fieldItemPrefix}.permanencePeriod`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.PERMANENCE_PERIOD',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.PERMANENCE_PERIOD.TOOLTIP'
            })}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.permanencePeriod`
            )}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.PERMANENCE_PERIOD.ADORNMENT',
            })}
          />
        </DsmGrid>
        <div className={classes.resetButtonContainer}>
          <DsmButton
            widthFull
            variant="text"
            onClick={() => resetToDefaultValues()}
            disabled={formType === FormType.View}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{ transform: 'translateY(85%)' }}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => handleSaveClick()}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </DialogContainer>
    </div>
  );
};

export default BeefInputBreedingDialog;
