import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import {
  avgLiveWeightMaxs,
  avgLiveWeightMins,
} from '../../../../../modules/Farms/validationSchemaRestrictions';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  defaultUnits,
  explicitConvertValue,
  unitLong,
} from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';

import {
  LayingHensInput,
  LayingHensIntervention,
} from '../../../../models/Intervention';
import { FormType } from '../../common';
import { LayingHensInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const LHInterventionInputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible = false,
  handleCancel,
  baseline,
}: LayingHensInterventionFormDialogProps) => {
  const intl = useIntl();
  // TODO Fix when converting to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = (userUOM?.unitBarnOutputMass as string)
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass as string)
    : 'kg';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const currResetValue = useRef<LayingHensInput>();

  let minAvgLiveWeightMortality = avgLiveWeightMins['Laying hens'];
  let maxAvgLiveWeightMortality = avgLiveWeightMaxs['Laying hens'];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass) {
    if (minAvgLiveWeightMortality)
      minAvgLiveWeightMortality = Number(
        explicitConvertValue(
          minAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          userUOM.unitBarnOutputMass as string
        )?.toFixed(precision)
      );
    if (maxAvgLiveWeightMortality)
      maxAvgLiveWeightMortality = Number(
        explicitConvertValue(
          maxAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          userUOM.unitBarnOutputMass as string
        )?.toFixed(precision)
      );
  }

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
  });

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...fc.getValues(fieldItemPrefix),
      } as LayingHensInput; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as LayingHensIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.input) {
        stageData.input = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const input = baseline?.stages?.[stageIndex]?.stageData?.input;

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      datasetType="intervention"
      iconCode="LAYING_HENS_INPUT"
    >
      <Grid container direction="column" spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.purchasedAnimalsLayingHens`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.HENS.PURCHASED_ANIMALS',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.BARN.HENS.PURCHASED_ANIMALS.TOOLTIP',
            })}
            metricUnit="number"
            margin="none"
            baseline={input?.purchasedAnimalsLayingHens}
            disabled={formType === 'view'}
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.mortalityRate`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.HENS.MORTALITY',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'INTERVENTION.FORM.BARN.HENS.MORTALITY.TOOLTIP',
            })}
            metricUnit="%"
            baseline={input?.mortalityRate}
            disabled={formType === 'view'}
            maxAllowedValue={99}
            margin="none"
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageLiveWeightMortalities`}
            label={intl.formatMessage(
              { id: 'INTERVENTION.FORM.BARN.AVG_LIVEWEIGHT_MORTALITY' },
              {
                min: minAvgLiveWeightMortality,
                max: maxAvgLiveWeightMortality,
                unit: barnOutputMassUnit,
              }
            )}
            type="number"
            tooltip={intl.formatMessage(
              { id: 'INTERVENTION.FORM.BARN.AVG_LIVEWEIGHT_MORTALITY.TOOLTIP' },
              {
                min: minAvgLiveWeightMortality,
                max: maxAvgLiveWeightMortality,
                unit: barnOutputMassUnit,
              }
            )}
            margin="none"
            metricUnit={barnOutputMassUnit}
            baseline={input?.averageLiveWeightMortalities}
            disabled={formType === 'view'}
            minAllowedValue={minAvgLiveWeightMortality}
            maxAllowedValue={maxAvgLiveWeightMortality}
            precision={precision}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default LHInterventionInputFormDialog;
