import { FC } from 'react';
import { useFormContext } from "react-hook-form";

import { Grid, MenuItem } from "@material-ui/core";

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import ReactHookFormSelect from '../../../../../modules/Helpers/ReactHookFormSelect';
import lookupValues from "../../../../helpers/lookupValues";
import { objectEntriesSorter } from '../../../../helpers/sortingFunctions';
import { DairyBaseline } from '../../../../models/Baseline';
import { BaselineDialogProps, FormType } from '../../common';

const DFeedsVariablePart: FC<BaselineDialogProps> = ({
  itemIndex = 0,
  formType = FormType.Add
}) => {

  const intl = useIntl();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;

  const formContext = useFormContext<DairyBaseline>();

  const watchTotalTimeSpentGrazing = (formContext.watch(`${fieldItemPrefix  }.timeSpentGrazing`) || 0);
  const watchTotalTimeSpentOpenYardAreas = formContext.watch(`${fieldItemPrefix  }.timeSpentInOpenYardAreas`) || 0;

  formContext.setValue(`${fieldItemPrefix  }.timeSpentInBuildings`, 100 - (watchTotalTimeSpentGrazing as number) - (watchTotalTimeSpentOpenYardAreas as number));

  return (
    <>
       <Grid item xs={11}>
        <ReactHookFormSelect
          marginBottom="0"
          name={`${fieldItemPrefix  }.housingType`}
          disabled={formType === "view"}
          control={formContext.control}
          label={intl.formatMessage({ id: "BASELINE.FORM.BARN.DAIRY.HOUSING_TYPE" })}
          variant="outlined"
          tooltip={intl.formatMessage({ id: "BASELINE.FORM.BARN.DAIRY.HOUSING_TYPE.TOOLTIP" })}
          defaultValue={formContext.getValues(`${fieldItemPrefix  }.housingType`)}
          fullWidth
          >{
              Object.entries(lookupValues.housingTypes.Dairy).sort(objectEntriesSorter).map(([i, item]) =>
                <MenuItem key={i} value={i}>{item}</MenuItem>
              )
            }
        </ReactHookFormSelect>
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.timeSpentGrazing`}
          label={ intl.formatMessage( {id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_GRAZING"}) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage( {id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_GRAZING.TOOLTIP"}) }
          addornment="%"
          disabled={formType === "view"}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.timeSpentGrazing`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.timeSpentInOpenYardAreas`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_OPEN_YARDS"}) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_OPEN_YARDS.TOOLTIP"}) }
          addornment="%"
          disabled={formType === "view"}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.timeSpentInOpenYardAreas`)}
        />
      </Grid>
      <Grid item >
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.timeSpentInBuildings`}
          label={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_BUILDINGS"}) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.DAIRY.TIME_SPENT_IN_BUILDINGS.TOOLTIP"}) }
          addornment="%"
          disabled={formType === "view"}
          readonly
          defaultValue={100 - (watchTotalTimeSpentGrazing as number) - (watchTotalTimeSpentOpenYardAreas as number)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.digestibility`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.DIGESTIBILITY',
          })}
          margin="none"
          variant="outlined"
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.DIGESTIBILITY.TOOLTIP',
          })}
          addornment={intl.formatMessage({
            id: 'ADDORNMENT.UNITS.PERCENT_OF_GA',
          })}
          disabled={formType === 'view'}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.digestibility`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.grossEnergyIntake`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.GROSS_ENERGY_INTAKE',
          })}
          margin="none"
          variant="outlined"
          addornment={intl.formatMessage(
            { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
            { unit: 'MJ' }
          )}
          disabled={formType === 'view'}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.grossEnergyIntake`) }
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.crudeProteinContent`}
          type="number"
          label={ `${intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.CRUDE_PROTEIN_CONTENT'})} *`}
          margin="none"
          variant="outlined"
          addornment="% of DM"
          disabled={formType === 'view'}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.crudeProteinContent`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.percentageOfSilage`}
          type="number"
          label={ `${intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.SILAGE_PERCENTAGE'})} *` }
          margin="none"
          variant="outlined"
          addornment={intl.formatMessage({
            id: 'ADDORNMENT.UNITS.PERCENT_OF_GA',
          })}
          disabled={formType === 'view'}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.percentageOfSilage`)}
        />
      </Grid>
    </>
  );
};

export default DFeedsVariablePart;