import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { StageType, TransportMode } from '../../../../../../graphql/types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';

interface SGrowingExternalSourceFormProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  isIntervention?: boolean;
  removeHandler?: Maybe<Function>;
  sourceSelected: boolean;
  stageType: StageType;
}

const SGrowingExternalSourceForm: FC<SGrowingExternalSourceFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  isIntervention,
  removeHandler,
  sourceSelected,
  stageType,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const propName = isIntervention
    ? 'externalSourcesAdditions'
    : 'externalSources';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`;
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg";

  const selectedTransportMode = useWatch({name: `${fieldItemPrefix}.transportMode`})
  const [distanceUnit, setDistanceUnit] = useState(userUOM.unitTransportDistanceTerrestrial)

  let stageTypeCode = ''
  switch(stageType) {
    case StageType.Hatching:
      stageTypeCode =  'BROODSTOCK';
      break;
    case StageType.Nursery:
      stageTypeCode = 'POST_LARVAE';
      break;
    case StageType.Growing:
    default:
      stageTypeCode = 'JUVENILES';
      break;
  } 

  useEffect(() => {
    switch (selectedTransportMode) {
      case TransportMode.InlandShip:
        setDistanceUnit(userUOM.unitTransportDistanceInlandWaterways)        
        break;
      case TransportMode.SeaShip:
        setDistanceUnit(userUOM.unitTransportDistanceSea)        
        break;
      case TransportMode.Train:
      case TransportMode.Truck:
        setDistanceUnit(userUOM.unitTransportDistanceTerrestrial)
        break;
      default:
        break
    }
  },[selectedTransportMode])

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && 
        itemIndex !== undefined && itemIndex !== null && 
        itemIndex !== 0  && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
            disabled={!sourceSelected}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalWeight`}
          label={`${intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT`,
          })}`}
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TOTAL_WEIGHT.TOOLTIP`,
          })}
          adornment={barnOutputMassUnit}
          disabled={formType === FormType.View || !sourceSelected}
          required
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.totalWeight`) as string
          }
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTraveled`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.JUVENILES.SHRIMP.DISTANCE_TRAVELED',
          })}`}
          tooltip={intl.formatMessage({
            id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.EXTERNAL_SOURCE.DISTANCE_TRAVELED.TOOLTIP`,
          })}
          adornment={distanceUnit}
          disabled={formType === FormType.View || !sourceSelected}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.distanceTraveled`
            ) as string
          }
        />
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.transportMode`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE',
            })}`}
            disabled={formType === FormType.View || !sourceSelected}
            tooltip={intl.formatMessage({
              id: `BASELINE.FORM.${stageTypeCode}.SHRIMP.TRANSPORT_MODE.TOOLTIP`,
            })}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.JUVENILES.SHRIMP.TRANSPORT_MODE.PLACEHOLDER',
            })}
            options={Object.keys(TransportMode).map((key) => ({
              value: TransportMode[key as keyof typeof TransportMode],
              text: TransportMode[key as keyof typeof TransportMode]
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            }))}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.transportMode`
              ) as string
            }
          />
        </div>
      </DsmGrid>
    </>
  );
};

export default SGrowingExternalSourceForm;
