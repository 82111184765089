import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  setInterventionFloatValue,
  mapCommonInterventionDataOutSustell,
  mapResourceUse,
} from './outCommonMapperSustell';

const ANIMAL_TYPE = 'Laying hens';

const mapLayingHensInterventionDataOutSustell = (formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const payloadObj = mapCommonInterventionDataOutSustell(formData);
  mapResourceUse(formData, payloadObj);
  
  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if(payloadObj.stages[index]!==undefined){
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const {stageData} = stageGeneralData;
        stageData.animal_group.animal_type = ANIMAL_TYPE;
        
        // process input block
        // (purchasedAnimalsLayingHens, mortalityRate, averageLiveweightMortality)
        const inputBlockData = formStageData.input;
        if(inputBlockData){
          stageData.purchased_laying_hens = {
            number_of_purchased_animals: {
              value: parseFloat(inputBlockData.purchasedAnimalsLayingHens),
              change_type:
                inputBlockData.purchasedAnimalsLayingHens_changeMetric,
            },
          };
          
          if (
            'mortalityRate' in inputBlockData &&
            !Number.isNaN(parseFloat(inputBlockData.mortalityRate))
          ) {
            stageData.mortality = {
              mortality_rate: {
                value: parseFloat(inputBlockData.mortalityRate),
                change_type: inputBlockData.mortalityRate_changeMetric,
              },
            };
          }
  
          if (
            'averageLiveWeightMortalities' in inputBlockData &&
            !Number.isNaN(parseFloat(inputBlockData.averageLiveWeightMortalities))
          ) {
            if (!stageData.mortality) stageData.mortality = {};
            setInterventionFloatValue(
              stageData.mortality,
              'average_live_weight_mortalities',
              inputBlockData,
              'averageLiveWeightMortalities',
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass
            );
          }
        }

        // process output block
        // (eggs, liveWeight )
        const outputBlockData = formStageData.output;
        if(outputBlockData){
          if (!Number.isNaN(parseFloat(outputBlockData.eggs))) {
            if (!stageData.eggs)
              stageData.eggs = {};
            setInterventionFloatValue(
              stageData.eggs,
              'total_weight',
              outputBlockData,
              'eggs',
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass
            );
          }

          if (!Number.isNaN(parseFloat(outputBlockData.liveWeight))) {
            if (!stageData.spent_hens)
              stageData.spent_hens = {};
            setInterventionFloatValue(
              stageData.spent_hens,
              'total_weight',
              outputBlockData,
              'liveWeight',
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass
            );
          }
        }
        // set housing block of data
        stageData.housing_description = {
          housing_type: 'housed',
        };
        stageData.housing_system = formDataForOneStage.productionSystem;
      }
      
    });
    payloadObj.stages.forEach( item => {
      const {stageData} = item;
      item.stageData = JSON.stringify(stageData);
    })
    // console.log("payloadObj", payloadObj)
  return payloadObj;
};

export default mapLayingHensInterventionDataOutSustell;
