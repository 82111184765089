/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, react/jsx-props-no-spreading, react/destructuring-assignment */

import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
// Since rollup cannot deal with namespace being a function,
// this is to interop with TypeScript since `invariant`
// does not export a default
// https://github.com/rollup/rollup/issues/1267

// import {invariantIntlContext} from 'react-intl/lib/utils';
import { PrimitiveType } from 'intl-messageformat';
import {
  RawIntlProvider,
  IntlContext,
  IntlShape, 
  // Omit, 
  MessageDescriptor, 
  createIntl, 
  createIntlCache
} from 'react-intl';
import { allMessages } from './I18nProvider';

const DEFAULT_LANGUAGE='en';

function getDisplayName(Component: React.ComponentType<any>): string {
  return Component.displayName || Component.name || 'Component';
}

const {Consumer: IntlConsumer } = IntlContext;

export const Provider = RawIntlProvider; // this conects to IntlProvider inserted at the root of the App
export const Context = IntlContext;

export interface Opts<
  IntlPropName extends string = 'intl',
  ForwardRef extends boolean = false
> {
  intlPropName?: IntlPropName;
  forwardRef?: ForwardRef;
  enforceContext?: boolean;
}

export type WrappedComponentProps<IntlPropName extends string = 'intl'> = {
  [k in IntlPropName]: IntlShape;
};

export type WithIntlProps<P> = Omit<P, keyof WrappedComponentProps> & {
  forwardedRef?: React.Ref<any>;
};

export function injectIntl<
  IntlPropName extends string,
  P extends WrappedComponentProps<IntlPropName> = WrappedComponentProps<any>
>(
  WrappedComponent: React.ComponentType<P>,
  options?: Opts<IntlPropName, false>
): React.FC<WithIntlProps<P>> & {
  WrappedComponent: React.ComponentType<P>;
};

export function injectIntl<
  IntlPropName extends string = 'intl',
  P extends WrappedComponentProps<IntlPropName> = WrappedComponentProps<any>,
  T extends React.ComponentType<P> = any
>(
  WrappedComponent: React.ComponentType<P>,
  options?: Opts<IntlPropName, true>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<WithIntlProps<P>> & React.RefAttributes<T>
> & {
  WrappedComponent: React.ComponentType<P>;
};

export function injectIntl<
  IntlPropName extends string = 'intl',
  P extends WrappedComponentProps<IntlPropName> = WrappedComponentProps<any>,
  ForwardRef extends boolean = false,
  T extends React.ComponentType<P> = any
>(
  WrappedComponent: React.ComponentType<P>,
  options?: Opts<IntlPropName, ForwardRef>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<WithIntlProps<P>> & React.RefAttributes<T>
> & {
  WrappedComponent: React.ComponentType<P>;
} {
  const {intlPropName = 'intl', forwardRef = false, enforceContext = true} =
    options || {};

  const WithIntl: React.FC<P & {forwardedRef?: React.Ref<any>}> & {
    WrappedComponent: React.ComponentType<P>;
  } = props => (
    <IntlConsumer>
      {(intl): React.ReactNode => {
        if (enforceContext) {
          if(intl===undefined || intl ===null)
            throw new Error('[React Intl] Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry.');
        }
        
        // force override of formatMessage
        const currLang = intl.locale;
        const cache = createIntlCache();
        const defaultIntl = createIntl(
          {
            locale: DEFAULT_LANGUAGE,
            messages: allMessages[DEFAULT_LANGUAGE] as Record<string, string>,
          },
          cache
        );
        
        const currentLanguage:Record<string, string> = (allMessages[currLang as keyof typeof allMessages] || {}) as Record<string, string>;
    
        // Format message with fallback to default language
        const formatMessage = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType | React.ReactElement | any>): string => {
          const messageId = descriptor.id;
          if( (messageId && currentLanguage[messageId]) || descriptor.defaultMessage )
            return intl.formatMessage(descriptor, values);
          
            // current language does not contain translation for specific string
          return defaultIntl.formatMessage(descriptor, values)
        };
        
        return (
          <WrappedComponent
            {...props}
            {...{
              [intlPropName]:{...intl, formatMessage },
            }}
            ref={forwardRef ? props.forwardedRef : null}
          />
        );
      }}
    </IntlConsumer>
  );
  WithIntl.displayName = `injectIntl(${getDisplayName(WrappedComponent)})`;
  WithIntl.WrappedComponent = WrappedComponent;

  if (forwardRef) {
    return hoistNonReactStatics(
      React.forwardRef<T, P>((props: P, ref) => (
        <WithIntl {...props} forwardedRef={ref} />
      )),
      WrappedComponent
    ) as any;
  }

  return hoistNonReactStatics(WithIntl, WrappedComponent) as any;
}