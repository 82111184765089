import { useRef, useEffect } from 'react';

/**
 * Helper hook to run on unload (for setting draft)
 */
const useUnload = (fn: any) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    const onUnload = (...args: any[]) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

export default useUnload;