/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Box, Typography } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import {
  processAndStageStylesV2,
  useSubheader,
} from '../../../../_metronic/layout';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  ClimateType,
  Farm,
  ModelProductionProcessConnection,
} from '../../../../graphql/types';
import { FormType } from '../../components/FarmFlow/common';
import { CSSClassesList } from '../../helpers/helperTypes';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import SustellFarmInfoWithMap from '../../components/FarmFlow/CommonDataParts/v2.0/SustellFarmInfoWithMap';
import AnimalSystemProcess from '../../components/FarmFlow/CommonDataParts/v2.0/AnimalSystemProcess';
import { fetchProductionProcessBaselinesWithDetailedInfo } from '../../apicalls/farmFunctions';
import Can from '../../../modules/Auth/Can';

// eslint-disable-next-line import/prefer-default-export
const SustellFarmPage = (props: {
  location: any;
  match: { params: { customerID: string; farmID: string } };
}) => {
  const emptyProductionProcess: ModelProductionProcessConnection = {
    items: [],
  };

  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const subheader = useSubheader();
  const { customerID, farmID } = props.match.params;
  const [farm, setFarm] = useState<Farm>({
    id: '',
    name: '',
    country: '',
    city: '',
    climateType: undefined, // or provide a default value based on your type
    size: undefined, // or provide a default value based on your type
    animalSystems: emptyProductionProcess,
    // ... other properties ...
  });
  const [showFarmForm, setShowFarmForm] = useState(props.location?.state?.formType === 'edit');
  const { ctaClickEvent, formStartEvent, myFarmsEvent } = useAdobeDataLayer();

  const updateSetFarm = (farm: Farm) => {
    setFarm(farm);
    // set fetched farm data to component state
    myFarmsEvent(
      farm.name,
      farm.country,
      [],
      farm.city,
      farm.animalSystems?.items?.length
        ? farm.animalSystems?.items?.length
        : 0,
      0
    );
  }

  const fetchFarmData = async (thisFarmId: unknown) => {
    fetchProductionProcessBaselinesWithDetailedInfo(
      thisFarmId,
      updateSetFarm,
      (error: any) => console.log(error)
    );
    subheader.setBreadcrumbs([
      {
        pathname: `/customers/${customerID}/myfarms`,
        title: intl.formatMessage({ id: 'FARMS.CARD.TITLE' }),
      },
      {
        pathname: `/farm/${farm.id}`,
        title: farm.name,
      },
    ]);
  };

  const editAnimalSystem = () => {
    ctaClickEvent(
      window.location.href,
      'link',
      'Edit Farm',
      'My Farms',
      'My Farms'
    );
    setShowFarmForm(true);
    formStartEvent('Edit Farm', 'edit');
  };

  const methods = useForm();
  const getClimateType = (climateType: ClimateType | null | undefined) => {
    // Check if climateType is defined
    if (!climateType) {
      return '';
    }
    return intl.formatMessage({
      id: `${'SUSTELL.GEOGRAPHY.CLIMATE_ZONE'}.${climateType}`,
    });
  };

  const getCountry = (country: string | null | undefined) => {
    // Check if climateType is defined
    if (!country) {
      return '';
    }
    return intl.formatMessage({ id: `${'SUSTELL.GEOGRAPHY'}.${country}` });
  };

  const handleClose = () => {
    setShowFarmForm(false);
  };

  const onSubmit = (submitData: Farm) => {
    const newFarmInfo = farm;
    newFarmInfo.climateType = submitData.climateType;
    newFarmInfo.name = submitData.name;
    newFarmInfo.country = submitData.country;
    newFarmInfo.city = submitData.city;
    setFarm(newFarmInfo);
    handleClose();
  };

  useEffect(() => {
    fetchFarmData(farmID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmID]);
  
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <h1>{farm.name}</h1>
      {showFarmForm && (
        <Box
          sx={{
            paddingBottom: '30px',
            borderBottom: '1px solid var(--dsm-color-neutral) !important',
          }}
        >
          <SustellFarmInfoWithMap
            formType={FormType.Edit}
            farm={farm}
            customerId={customerID}
            farmSubmit={onSubmit}
            handleClose={() => handleClose()}
            farmId={farmID}
          />
        </Box>
      )}
      {!showFarmForm && (
        <>
          <Box display="flex">
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.AnimalSystemFlowInfo}
            >
              {intl.formatMessage({ id: 'SUSTELL.ANIMAL.SYSTEM.TITLE' })}
            </Typography>
            {!farm.benchmark && Can("update", "Farm") && (
              <DsmButton
                onClick={() => editAnimalSystem()}
                variant="text"
                color="primary"
              >
                <DsmIcon
                  name="general/edit-01"
                  style={{
                    marginLeft: ' 15px',
                    height: '18px',
                    width: '18px',
                  }}
                  color="--dsm-color-primary"
                />
              </DsmButton>
            )}
          </Box>
          <Typography style={{ marginTop: '10px' }} variant="body1">
            {intl.formatMessage({ id: 'SUSTELL.ANIMAL.SYSTEM.DESCRIPTION' })}
          </Typography>
          <DsmGrid
            className={classes.dsmGridthreeColumnRowSpacing}
            style={{ marginBotton: 0 }}
          >
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                {farm.name}
              </Typography>
              <Typography>
                {intl.formatMessage({ id: 'SUSTELL.ANIMAL.SYSTEM.FARM.NAME' })}
              </Typography>
              <Typography />
            </div>
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                {farm.city}
              </Typography>
              <Typography>
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.FARM.LOCATION',
                })}
              </Typography>
              <Typography />
            </div>
          </DsmGrid>
          <DsmGrid className={classes.dsmGridthreeColumnRowSpacing}>
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                {getCountry(farm.country)}
              </Typography>
              <Typography>
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.FARM.COUNTRY',
                })}
              </Typography>
              <Typography />
            </div>
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                {getClimateType(farm.climateType)}
              </Typography>
              <Typography>
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.FARM.CLIMATE.TYPE',
                })}
              </Typography>
              <Typography />
            </div>
          </DsmGrid>
        </>
      )}
      <Box
        sx={{
          paddingTop: '10px',
          marginTop: '10px',
        }}
      >
        <div>
          <AnimalSystemProcess farm={farm} customerID={customerID} />
        </div>
      </Box>
    </FormProvider>
  );
};

export default SustellFarmPage;