import { ManureForm } from "../../../graphql/generated/blonk/pigs";
import { ManureManagementSystemType as MMS } from "../../../graphql/generated/blonk/pigs";

export const LiquidSlurryManagementSystemTypes: MMS[] = [
  /**  Daily spread */
  MMS.DailySpread,
  /**  Aerobic treatment; Forced aeration systems */
  MMS.AerobicForced,
  /**  Aerobic treatment; Natural aeration systems */
  MMS.AerobicNatural,
  /**  Anaerobic digester; High leakage, low quality technology, high quality gastight storage technology */
  MMS.AnaerobicHighLeakHighGastight,
  /**  Anaerobic digester; High leakage, low quality technology, low quality gastight storage technology */
  MMS.AnaerobicHighLeakLowGastight,
  /**  Anaerobic digester; High leakage, low quality technology, open storage */
  MMS.AnaerobicHighLeakOpen,
  /**  Anaerobic digester; Low leakage, High quality gastight storage, best complete industrial technology */
  MMS.AnaerobicLowLeakHighGastight,
  /**  Anaerobic digester; Low leakage, High quality industrial technology, low quality gastight storage technology */
  MMS.AnaerobicLowLeakLowGastight,
  /**  Anaerobic digester; Low leakage, High quality industrial technology, open storage */
  MMS.AnaerobicLowLeakOpen,
  /**  Liquid/Slurry; Cover */
  MMS.LiquidCover_1Month,
  MMS.LiquidCover_3Month,
  MMS.LiquidCover_4Month,
  MMS.LiquidCover_6Month,
  MMS.LiquidCover_12Month,
  /**  Liquid/Slurry; With natural crust cover */
  MMS.LiquidCrust_1Month,
  MMS.LiquidCrust_3Month,
  MMS.LiquidCrust_4Month,
  MMS.LiquidCrust_6Month,
  MMS.LiquidCrust_12Month,
  /**  Liquid/Slurry; Without natural crust cover */
  MMS.LiquidNoCrust_1Month,
  MMS.LiquidNoCrust_3Month,
  MMS.LiquidNoCrust_4Month,
  MMS.LiquidNoCrust_6Month,
  MMS.LiquidNoCrust_12Month,
  /**  Pit storage below animal confinements */
  MMS.Pit_1Month,
  MMS.Pit_3Month,
  MMS.Pit_4Month,
  MMS.Pit_6Month,
  MMS.Pit_12Month,
  
  MMS.UncoveredAnaerobicLagoon
];



export const SolidManagementSystemTypes: MMS[] = [
  /**  Composting; Intensive Windrow (Frequent turning) */
  MMS.CompostingIntensiveWindrow,
  MMS.CompostingInVessel,
  /**  Composting; Passive windrow (infrequent turning) */
  MMS.CompostingPassiveWindrow,
  /**  Composting; Static Pile (Forced aeration) */
  MMS.CompostingStaticPile,
  /** Deep bedding */
  MMS.DeepBeddingActiveMixingOver_1Month,
  MMS.DeepBeddingActiveMixingUnder_1Month,
  /**  Cattle and swine deep bedding */
  MMS.DeepBeddingNoMixingOver_1Month,
  MMS.DeepBeddingNoMixingUnder_1Month,
  
  /**  Solid storage; Additives */
  MMS.SolidAdditives,
  /**  Solid storage; Bulking agent addition */
  MMS.SolidBulking,
  /**  Solid storage; Covered/compacted */
  MMS.SolidCovered,
  /**  Solid storage */
  MMS.SolidStorage
];

export const getManureFormForSingleEntry = (
  input: MMS
): ManureForm | undefined => {
  // if not already returned then
  if (SolidManagementSystemTypes.indexOf(input) > -1) return ManureForm.Solid;

  if (LiquidSlurryManagementSystemTypes.indexOf(input) > -1)
    return ManureForm.LiquidSlurry;

  // if no matching
  // check for partial matching for MMS that has split duration part
  if (SolidManagementSystemTypes.some((item) => item.startsWith(input)))
    return ManureForm.Solid;
  if (LiquidSlurryManagementSystemTypes.some((item) => item.startsWith(input)))
    return ManureForm.LiquidSlurry;

  return undefined;
};

export const getManureForm = (input: MMS[]): ManureForm | undefined => {
  console.log("mms input: ",input);
  const hasSolids = input.some( item => SolidManagementSystemTypes.indexOf(item)>-1);
  const hasLiquidSlurry = input.some( item => LiquidSlurryManagementSystemTypes.indexOf(item)>-1);
  // mixed if both are present
  if(hasSolids && hasLiquidSlurry)
    return ManureForm.SolidAndLiquidSlurry;

  // if not already returned then
  if(hasSolids)
    return ManureForm.Solid;

  if(hasLiquidSlurry)
    return ManureForm.LiquidSlurry;
  return;
}