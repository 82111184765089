import { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import * as math from 'mathjs';
import { cloneDeep } from 'lodash';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention//RowControlledTextFieldWithMetrics2';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { MarineFishInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { FormType } from '../../common';
import {
  InterventionPropertyChangeMetric,
  InterventionPropertyValue,
  SalmonInput,
  SalmonIntervention,
} from '../../../../models/Intervention';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';


const MarineFishInterventionInputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible = false,
  baseline,
  handleCancel,
}: MarineFishInterventionFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const unitOutputMass = userUOM?.unitOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const outputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    UserProfilePrefs.getInstance().getUnitOutputMassPrecision() as number;

  const currResetValue = useRef<SalmonInput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE',
  });

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;
  const fc = useFormContext();
  // const { reset, getValues, setValue, control } = useFormContext();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as SalmonIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.input) {
        stageData.input = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const input = baseline?.stages?.[stageIndex]?.stageData?.input;

  const calculateValue = (
    oldValue?: number | string,
    value?: number | string,
    metric?: InterventionPropertyChangeMetric
  ) => {
    let newValueNum: number | null = null;
    if (value !== null && value !== undefined)
      if (typeof value !== 'number') newValueNum = parseFloat(value);
      else newValueNum = value;

    if (
      newValueNum === null ||
      newValueNum === undefined ||
      Number.isNaN(newValueNum)
    )
      return null;
    if (metric === 'set') return newValueNum;

    let oldValueNum: number | null = null;
    if (oldValue !== null && oldValue !== undefined)
      if (typeof oldValue !== 'number') oldValueNum = parseFloat(oldValue);
      else oldValueNum = oldValue;

    if (
      oldValueNum === null ||
      oldValueNum === undefined ||
      Number.isNaN(oldValueNum)
    )
      return null;

    if (metric !== '') {
      if (metric === 'absolute') return oldValueNum + newValueNum;
      return oldValueNum * (1 + newValueNum / 100);
    }

    return null;
  };

  const calclateWeightOfStockedAnimals = (
    numOfSmolts: InterventionPropertyValue,
    numOfSmoltsChgMetrics: InterventionPropertyChangeMetric,
    avgWeightOfSmolt: InterventionPropertyValue,
    avgWeightOfSmoltChgMetrics: InterventionPropertyChangeMetric
  ) => {
    const baselineAverageLiveWeightStockedAnimals =
      input?.averageLiveWeightStockedAnimals;
    const baselineWeightOfStockedAnimals = input?.weightOfStockedAnimals;

    if (
      (input?.numberOfStockedAnimals || input?.numberOfStockedAnimals === 0) &&
      (baselineAverageLiveWeightStockedAnimals ||
        baselineAverageLiveWeightStockedAnimals === 0)
    ) {
      let numOfSmoltsCalc: number | string | null =
        input?.numberOfStockedAnimals;
      if (numOfSmoltsCalc && typeof numOfSmoltsCalc === 'string')
        numOfSmoltsCalc = parseFloat(numOfSmoltsCalc);

      if ((numOfSmolts || numOfSmolts === 0) && numOfSmoltsChgMetrics) {
        numOfSmoltsCalc = calculateValue(
          input?.numberOfStockedAnimals,
          numOfSmolts,
          numOfSmoltsChgMetrics
        );

        if (numOfSmoltsCalc || numOfSmoltsCalc === 0)
          numOfSmoltsCalc = math.round(numOfSmoltsCalc, 2);
      }

      let avgWeightOfSmoltCalc: number | string | null =
        baselineAverageLiveWeightStockedAnimals;
      if (
        (avgWeightOfSmolt || avgWeightOfSmolt === 0) &&
        avgWeightOfSmoltChgMetrics
      ) {
        avgWeightOfSmoltCalc = calculateValue(
          baselineAverageLiveWeightStockedAnimals,
          avgWeightOfSmolt,
          avgWeightOfSmoltChgMetrics
        );

        if (avgWeightOfSmoltCalc || avgWeightOfSmoltCalc === 0)
          avgWeightOfSmoltCalc = math.round(
            avgWeightOfSmoltCalc,
            outputMassPrecision
          );
      }

      let weightOfStockedAnimals: number | '' = '';
      if (
        (numOfSmoltsCalc || numOfSmoltsCalc === 0) &&
        (avgWeightOfSmoltCalc || avgWeightOfSmoltCalc === 0)
      )
        weightOfStockedAnimals = math.round(
          (numOfSmoltsCalc as number) * (avgWeightOfSmoltCalc as number),
          outputMassPrecision
        );

      fc.setValue(
        `${fieldItemPrefix}.weightOfStockedAnimals`,
        weightOfStockedAnimals
      );
    } else
      fc.setValue(
        `${fieldItemPrefix}.weightOfStockedAnimals`,
        baselineWeightOfStockedAnimals
      );
  };

  const numOfSmolts = useWatch({
    control: fc.control,
    name: `${fieldItemPrefix}.numberOfStockedAnimals`,
    defaultValue: '',
  });

  const numOfSmoltsChgMetrics = useWatch({
    control: fc.control,
    name: `${fieldItemPrefix}.numberOfStockedAnimals_changeMetric`,
    defaultValue: 'relative',
  });

  const avgWeightOfSmolt = useWatch({
    control: fc.control,
    name: `${fieldItemPrefix}.averageLiveWeightStockedAnimals`,
    defaultValue: '',
  });

  const avgWeightOfSmoltChgMetrics = useWatch({
    control: fc.control,
    name: `${fieldItemPrefix}.averageLiveWeightStockedAnimals_changeMetric`,
    defaultValue: 'relative',
  });

  calclateWeightOfStockedAnimals(
    numOfSmolts,
    numOfSmoltsChgMetrics as InterventionPropertyChangeMetric,
    avgWeightOfSmolt,
    avgWeightOfSmoltChgMetrics as InterventionPropertyChangeMetric
  );

  useEffect(() => {
    if (formVisible && input) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {weightOfStockedAnimals: 0};
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, input]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      variant="wide"
      iconCode="spiecies/aquaculture"
    >
      <DsmGrid className={classes.dsmGridOneColumnIntervention}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.numberOfStockedAnimals`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.NUMBER_SMOLT_STOCKED',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.SALMON.NUMBER_SMOLET_STOCKED.TOOLTIP',
          })}
          baseline={input?.numberOfStockedAnimals}
          metricUnit="number"
          disabled={formType === 'view'}
        />
        
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageLiveWeightStockedAnimals`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.AVG_STOCK_ANIMAL_MASS',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.SALMON.AVG_STOCK_ANIMAL_MASS.TOOLTIP',
          })}
          metricUnit={unitOutputMass}
          baseline={input?.averageLiveWeightStockedAnimals}
          disabled={formType === 'view'}
          precision={precision}
        />
        
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.weightOfStockedAnimals`}
          type="number"
          label={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.STOCKED_BIOMASS',
          })}
          margin="none"
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.STOCKED_BIOMASS_TT',
          })}
          defaultValue={input?.weightOfStockedAnimals}
          adornment={unitOutputMass}
          disabled={formType === 'view'}
          readonly
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default MarineFishInterventionInputFormDialog;
