import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import { Box }  from '@material-ui/core';
import { homeButtonsStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import useAdobeDataLayer from '../../../sustell_15/analytics/adobeDataLayer';


export const HomeButton = ({ bkgImg, icon, title='Title', intro='',translationKeyTitle, translationKeyText, href='/homepage'}) => {

  const { ctaClickEvent } = useAdobeDataLayer();

  const intl = useIntl();
  title = translationKeyTitle ? intl.formatMessage({id:translationKeyTitle}) : title;
  const introText = translationKeyText ? intl.formatMessage({id:translationKeyText}) : intro;
  
  const onClickLink = () => {
    ctaClickEvent(href, 'link', title, 'Home Page', 'Home Page');
  }
  
  const customStyle = makeStyles(theme => ({
    homeBtnBox: {
      // background: '#9f9f9f',
      backgroundImage: `url(${bkgImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      maxWidth: '540px',
      aspectRatio: '540/358'
    },
  })
  )();

  const classes = homeButtonsStyles();
  return (
    <React.Fragment>
      <Link to={href} onClick={onClickLink}>
        <Box className={`${customStyle.homeBtnBox} ${classes.containerHomeBtnBox}`}>
          <Box>
            <Box className={`${classes.homeBtn} ${classes.homeBtnTitle}`} >
              {title}
            </Box>
            <Box className={`${classes.homeBtn} ${classes.homeBtnIntro}`}>
              {introText}
            </Box>
          </Box>
          <img src={icon} alt={title}/>
        </Box>
      </Link>
    </React.Fragment>
  )
};





