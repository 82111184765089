import { Box, Typography } from '@material-ui/core';
import { footprintBoxStyles } from '../../../../_metronic/layout';
import { ExactNumber } from '../helpers/ExactNumber';
import { IconProvider } from '../helpers/IconProvider';
interface FootprintCategoryBoxProps {
  categoryName: string;
  categoryValue: number;
  categoryConversationText: string;
  categoryUnitDescription: string;
  categoryDescription?: string;
  isSmallBox?: boolean;
}

export const FootprintCategoryBox = (props: FootprintCategoryBoxProps) => {
  const classes = footprintBoxStyles();
  return (
    <Box>
      <Box alignItems="flex-start" display="flex">
        <Box
          justifyContent="space-between"
          className={classes.footprintCategoryBox}
          style={props.isSmallBox ? { width: '120px', height: '150px' } : {}}
        >
          <Typography className={classes.footprintCategoryTitle}>
            {props.categoryName}
          </Typography>
          <Typography
            className={
              props.isSmallBox
                ? classes.footprintCategoryValeForSmall
                : classes.footprintCategoryVale
            }
          >
            <ExactNumber value={props.categoryValue} /> CO2
          </Typography>
          <Typography
            className={
              props.isSmallBox
                ? classes.footprintCategoryConversationTextForSmall
                : classes.footprintCategoryConversationText
            }
          >
            {props.categoryConversationText}
          </Typography>
          <Typography className={classes.footprintCategoryUnitDescription}>
            {props.categoryUnitDescription}
          </Typography>
        </Box>
        <IconProvider
          iconCode="air"
          color="rgb(255, 255, 255, 0.1)"
          width="140px"
          height="150px"
          isTransform={true}
          rightVal={props.isSmallBox? '68%': '22%'}
        />
      </Box>
      {props.categoryDescription && (
        <Typography className={classes.footprintCategoryDescription}>
          {props.categoryDescription}
        </Typography>
      )}
    </Box>
  );
};
