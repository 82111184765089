import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { floatValueInDefaultUnit } from '../../Baseline/mapper/floatHelperFunctions';
import { emissionFieldsMapOut } from './emissionFields';

const createCorePayload = (formData) => {
  const payloadObj = {
    name: formData.info?.name,
    description: formData.info?.description,
    category: formData.info?.interventionCategory,
    baseline: formData.baselineId,
    resourceUse: {},
    stages: []
  };
  if(formData.info.oldName)
    payloadObj.oldName = formData.info.oldName;

  return payloadObj;
};

export const setInterventionFloatValue = (
  obj,
  propertyName,
  formData,
  formPropertyName,
  userUnit,
  defaultUnit,
  precision = 3
) => {
  if (formPropertyName in formData) {
    let changeValue = parseFloat(formData[formPropertyName]);
    if (
      (changeValue || changeValue === 0) &&
      (formData[`${formPropertyName}_changeMetric`] === 'set' ||
        formData[`${formPropertyName}_changeMetric`] === 'absolute')
    )
      changeValue = floatValueInDefaultUnit(
        changeValue,
        userUnit,
        defaultUnit,
        precision
      );
    obj[propertyName] = {
      value: changeValue,
      change_type: formData[`${formPropertyName}_changeMetric`],
    };
  }

  return obj;
};

const mapManureManagementForOneStage = (manureFormData, stageDataObj) => {
  const stageData = stageDataObj;
  if (manureFormData.manureManagementSystem)
    stageData.manure_management.manure_management_system = {
      value: manureFormData.manureManagementSystem,
      change_type: 'set',
    };

  if (
    'solidManureStorage' in manureFormData &&
    !Number.isNaN(parseFloat(manureFormData.solidManureStorage))
  )
    stageData.manure_management.solid_manure_storage_on_farm = {
      value: parseFloat(manureFormData.solidManureStorage),
      change_type: manureFormData.solidManureStorage_changeMetric,
    };

  if (
    'slurryManureStorage' in manureFormData &&
    !Number.isNaN(parseFloat(manureFormData.slurryManureStorage))
  )
    stageData.manure_management.slurry_manure_storage_on_farm = {
      value: parseFloat(manureFormData.slurryManureStorage),
      change_type: manureFormData.slurryManureStorage_changeMetric,
    };

  if (
    'ashContent' in manureFormData &&
    !Number.isNaN(parseFloat(manureFormData.ashContent))
  )
    stageData.manure_management.ash_content = {
      value: parseFloat(manureFormData.ashContent),
      change_type: manureFormData.ashContent_changeMetric,
    };
  
  // console.log("stageData >> ", stageData);
  return stageData;
};

const mapBeddingForOneStage = (manureFormData, stageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    'strawForBedding' in manureFormData &&
    !Number.isNaN(parseFloat(manureFormData.strawForBedding))
  )
    setInterventionFloatValue(
      stageData.bedding,
      'straw_for_bedding',
      manureFormData,
      'strawForBedding',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  if (
    'sawDust' in manureFormData &&
    !Number.isNaN(parseFloat(manureFormData.sawDust))
  )
    setInterventionFloatValue(
      stageData.bedding,
      'saw_dust',
      manureFormData,
      'sawDust',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  return stageData;
};

const mapCompoundFeedForOneStage = (stageFormDataFeeds, stageDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageData = stageDataObj;
  if (
    stageFormDataFeeds.compoundFeeds &&
    Array.isArray(stageFormDataFeeds.compoundFeeds)
  ) {
    stageData.ration.compound_feed_uses = [];
    stageFormDataFeeds.compoundFeeds?.forEach(oneFeedItem => {
      if(oneFeedItem.kgPerAnimal || oneFeedItem.kgPerAnimal===0){
        const oneCompoundInput = {
          entity_id: oneFeedItem.feedType,
          quantity: {},
        };

        setInterventionFloatValue(
          oneCompoundInput,
          'quantity',
          oneFeedItem,
          'kgPerAnimal',
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );

        stageData.ration.compound_feed_uses.push(oneCompoundInput);
      }
    });
  }

  // loop through rations arrays added in the intervention
  if (
    stageFormDataFeeds.compoundFeedsAdditions &&
    Array.isArray(stageFormDataFeeds.compoundFeedsAdditions)
  ) {
    if (!stageData.ration.compound_feed_uses_additions)
      stageData.ration.compound_feed_uses_additions = [];
      stageFormDataFeeds.compoundFeedsAdditions?.forEach(addnlFeedItem => {
        if(addnlFeedItem.kgPerAnimal){
          const oneCompoundInput = {
            entity_id: addnlFeedItem.feedType,
            quantity: {},
          };

          setInterventionFloatValue(
            oneCompoundInput,
            'quantity',
            addnlFeedItem,
            'kgPerAnimal',
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          );
          // fix change type
          oneCompoundInput.quantity.change_type = 'set';

          stageData.ration.compound_feed_uses_additions.push(oneCompoundInput);
        }
    });
  }
  return stageData;
};

const mapSingleIngridientsForOneStage = (stageFormDataFeeds, stageDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageData = stageDataObj;
  if (
    stageFormDataFeeds.singleIngredients &&
    Array.isArray(stageFormDataFeeds.singleIngredients)
  ) {
    stageData.ration.single_ingredient_uses = [];
    stageFormDataFeeds.singleIngredients?.forEach(oneFeedItem => {
      if(oneFeedItem.kgPerAnimal || oneFeedItem.kgPerAnimal===0){
        const oneSingleIngredientInput = {
          entity_id: oneFeedItem.feedType,
          quantity: {},
        };

        setInterventionFloatValue(
          oneSingleIngredientInput,
          'quantity',
          oneFeedItem,
          'kgPerAnimal',
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );

        stageData.ration.single_ingredient_uses.push(
          oneSingleIngredientInput
        );
      }
    });
  }

  // newly added single ingredients
  if (
    stageFormDataFeeds.singleIngredientsAdditions &&
    Array.isArray(stageFormDataFeeds.singleIngredientsAdditions)
  ) {
    stageData.ration.single_ingredient_uses_additions = [];

    stageFormDataFeeds.singleIngredientsAdditions?.forEach(addnlFeedItem => {
      if(addnlFeedItem.kgPerAnimal){
        const oneSingleIngredientInput = {
          entity_id: addnlFeedItem.feedType,
          quantity: {},
        };

        setInterventionFloatValue(
          oneSingleIngredientInput,
          'quantity',
          addnlFeedItem,
          'kgPerAnimal',
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        // fix change type
        oneSingleIngredientInput.quantity.change_type = 'set';

        stageData.ration.single_ingredient_uses_additions.push(
          oneSingleIngredientInput
        );
      }
    });
  }

  return stageData;
};

const mapRationForOneStage = (stageFormDataFeeds, stageDataObj) => {
  const stageData = stageDataObj;
  if (
    'digestibility' in stageFormDataFeeds &&
    !Number.isNaN(parseFloat(stageFormDataFeeds.digestibility))
  )
    stageData.ration.digestibility = {
      value: parseFloat(stageFormDataFeeds.digestibility),
      change_type: stageFormDataFeeds.digestibility_changeMetric,
    };

  if (
    'grossEnergyIntake' in stageFormDataFeeds &&
    !Number.isNaN(parseFloat(stageFormDataFeeds.grossEnergyIntake))
  )
    stageData.ration.gross_energy_intake = {
      value: parseFloat(stageFormDataFeeds.grossEnergyIntake),
      change_type: stageFormDataFeeds.grossEnergyIntake_changeMetric,
    };

  if (
    'crudeProteinContent' in stageFormDataFeeds &&
    !Number.isNaN(parseFloat(stageFormDataFeeds.crudeProteinContent))
  )
    stageData.ration.crude_protein_content = {
      value: parseFloat(stageFormDataFeeds.crudeProteinContent),
      change_type: stageFormDataFeeds.crudeProteinContent_changeMetric,
    };

  if (
    'percentageOfSilage' in stageFormDataFeeds &&
    !Number.isNaN(parseFloat(stageFormDataFeeds.percentageOfSilage))
  )
    stageData.ration.silage_in_feed = {
      value: parseFloat(stageFormDataFeeds.percentageOfSilage),
      change_type: stageFormDataFeeds.percentageOfSilage_changeMetric,
    };

  if (
    'nitrogenContent' in stageFormDataFeeds &&
    !Number.isNaN(parseFloat(stageFormDataFeeds.nitrogenContent))
  )
    stageData.ration.nitrogen_content = {
      value: parseFloat(stageFormDataFeeds.nitrogenContent),
      change_type: stageFormDataFeeds.nitrogenContent_changeMetric,
    };

  if (
    'dryMatterContent' in stageFormDataFeeds &&
    !Number.isNaN(parseFloat(stageFormDataFeeds.dryMatterContent))
  )
    stageData.ration.dry_matter_content = {
      value: parseFloat(stageFormDataFeeds.dryMatterContent),
      change_type: stageFormDataFeeds.dryMatterContent_changeMetric,
    };

  mapCompoundFeedForOneStage(stageFormDataFeeds, stageData);

  mapSingleIngridientsForOneStage(stageFormDataFeeds, stageData);

  return stageData;
};

const mapEmissionForOneStage = (oneStageEmissionsFormData, stageDataEmissions) => {
  // check the emission fields
  emissionFieldsMapOut.forEach((value, key) => {
    if (key in oneStageEmissionsFormData && (oneStageEmissionsFormData[key] || oneStageEmissionsFormData[key]===0 )) {
      // originally the change_type was directly set to percentage to send to blonk
      // now emisssion interventions also has set, absolute, and relative option
      const valueEmission = parseFloat(oneStageEmissionsFormData[key]);
      const changeType = oneStageEmissionsFormData[`${key}_changeMetric`];
      const emissionObj = {
        key: value,
        action: 'update',
        value: {
          value: valueEmission,
          change_type: changeType || 'percentage',
        },
      };
      stageDataEmissions.push(emissionObj);
    }
  });
};

const mapStageData = (formData, payloadObj) => {
  if (formData.stages && Array.isArray(formData.stages)) {
    const { stages } = payloadObj;

    formData.stages.forEach((formDataOneStage) => {
      const formStageData = formDataOneStage.stageData;
      const stageFullData = {
        id: formDataOneStage.id,
        name: formDataOneStage.name,
        stageData:{
          animal_group: {
            animal_type: formDataOneStage.animalType,
            number_of_animals:{
              value:null
            }
          },
          manure_management: {},
          bedding: {},
          ration: {},
          emissions:[]
        }
      };

      const {stageData} = stageFullData;

      if (formStageData.manure?.averageAnimalsPresent || formStageData.manure?.averageAnimalsPresent===0) {
        stageData.animal_group.number_of_animals.value =
          formStageData.manure?.averageAnimalsPresent;
        stageData.animal_group.number_of_animals.change_type =
          formStageData.manure?.averageAnimalsPresent_changeMetric;
      }
      
      if(formStageData.manure) {
        mapManureManagementForOneStage(formStageData.manure, stageData);
        mapBeddingForOneStage(formStageData.manure, stageData);
      }
      if(formStageData.feed)
        mapRationForOneStage(formStageData.feed, stageData);
      if(formStageData.emissions)
        mapEmissionForOneStage(formStageData.emissions, stageData.emissions);

      stages.push(stageFullData);
    });
  }
};

export const mapResourceUse = (
  formData,
  payloadObj,
  resources = ['diesel', 'gas', 'electricity', 'water']
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (resources?.includes('gas') && (formData.resourceUse?.gasUse || formData.resourceUse?.gasUse===0))
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'gasUse',
      formData.resourceUse,
      'gasUse',
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse
    );

  if (resources?.includes('diesel') && (formData.resourceUse?.dieselUse || formData.resourceUse?.dieselUse===0))
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'dieselUse',
      formData.resourceUse,
      'dieselUse',
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse
    );

  if (resources?.includes('electricity') && (formData.resourceUse?.electricityUse || formData.resourceUse?.electricityUse===0))
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'electricityUse',
      formData.resourceUse,
      'electricityUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (resources?.includes('water') && (formData.resourceUse?.waterUse || formData.resourceUse?.waterUse===0))
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'waterUse',
      formData.resourceUse,
      'waterUse',
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse
    );

  return payloadObj;
};


export const mapCommonInterventionDataOutSustell = (formData) => {
  const payloadObj = createCorePayload(formData);
  mapStageData(formData, payloadObj);
  return payloadObj;
};

