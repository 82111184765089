/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { DsmModal } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DsmButtonV2 } from './DsmButton';
import { processAndStageStylesV2 } from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CSSClassesList } from '../../helpers/helperTypes';

interface WarningDialogProps {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  body: string;
  stageName?: string;
}

const WarningDialog: FC<WarningDialogProps> = ({
  isOpen,
  closeModal,
  title,
  body,
  stageName,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;

  return (
    <DsmModal
      open={isOpen}
      onDsmClosed={(event) => {
        if (!event.detail) {
          closeModal();
        }
      }}
    >
      <div
        id="icon"
        slot="content"
        className={classes.modalIconHolder}
      >
        <ErrorOutlineIcon className={classes.modalIcon}/>
      </div>
      <div id="title" slot="content" className={classes.modalTitle}>
        {intl.formatMessage({ id: title })}
      </div>
      <p id="body" slot="content">
        {intl.formatMessage({ id: body }, {a: stageName})}
      </p>
      <DsmButtonV2 variant="secondary" slot="actions" onClick={closeModal}>
        {intl.formatMessage({ id: 'GENERAL.OK' })}
      </DsmButtonV2>
    </DsmModal>
  );
};

export default WarningDialog;