/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ChemicalType,
  Facility,
  RefrigerantType,
} from '../../../../../graphql/types';
import { floatValueInDefaultUnit } from '../../../../modules/Farms/Baseline/mapper/floatHelperFunctions';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import {
  EnergyCarrierType,
  FacilitySpecies,
  RenewableType,
  SlaughterhouseFacilityForm,
  TypeAmountField,
} from '../../../models/Facility/FacilityTypes';
import { defaultUnitsV2 as defaultMetric } from '../../../utils/unit-utils';
import { getUnitForEnergyType } from './outFacilityMapper';

const mapAuxiliaryInputsIn = (facility: Facility, userUOM: any) => {
  const chemicals: TypeAmountField<ChemicalType>[] = [];
  const refrigerants: TypeAmountField<RefrigerantType>[] = [];

  (facility.auxiliaryInputs.chemicals || []).forEach((item) => {
    const amount = floatValueInDefaultUnit(
      item.amount,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    ) as number;

    chemicals.push({
      type: item.type,
      amount,
    });
  });

  (facility.auxiliaryInputs.refrigerants || []).forEach((item) => {
    const amount = floatValueInDefaultUnit(
      item.amount,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    ) as number;

    refrigerants.push({
      type: item.type,
      amount,
    });
  });

  return {
    chemicals,
    refrigerants,
  };
};

const mapResourceUseIn = (facility: Facility, userUOM: any) => {
  let gasUse;
  let electricity;

  const additionalEnergyTypes: TypeAmountField<EnergyCarrierType>[] = [];

  const { waterUse } = facility.resourceUse;
  const waterUseConverted = floatValueInDefaultUnit(
    waterUse,
    defaultMetric.unitWaterUse,
    userUOM.unitWaterUse
  );

  if (facility.resourceUse.electricityUse) {
    const units = getUnitForEnergyType(EnergyCarrierType.Electricity, userUOM);
    if (units) {
      const amount = floatValueInDefaultUnit(
        facility.resourceUse.electricityUse,
        units.defaultUnit,
        units.userUnit
      );
      electricity = amount;
    }
  }

  if (facility.resourceUse.gasUse) {
    const units = getUnitForEnergyType(EnergyCarrierType.NaturalGas, userUOM);
    if (units) {
      const amount = floatValueInDefaultUnit(
        facility.resourceUse.gasUse,
        units.defaultUnit,
        units.userUnit
      );
      gasUse = amount;
    }
  }

  const selfGeneratedRenewables = [];
  if (facility.resourceUse.electricityPhotovoltaicUse) {
    const amount = floatValueInDefaultUnit(
      facility.resourceUse.electricityPhotovoltaicUse,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );
    selfGeneratedRenewables.push({
      type: RenewableType.ELECTRICITY_PHOTOVOLTAIC,
      amount,
    });
  }

  if (facility.resourceUse.electricityWindUse) {
    const amount = floatValueInDefaultUnit(
      facility.resourceUse.electricityWindUse,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );
    selfGeneratedRenewables.push({
      type: RenewableType.ELECTRICITY_WIND,
      amount,
    });
  }

  Object.values(EnergyCarrierType).forEach((energyType) => {
    const units = getUnitForEnergyType(energyType, userUOM);
    let amounNotConverted;
    // Electricity and natural gas are on top level
    switch (energyType) {
      case EnergyCarrierType.Coal:
        amounNotConverted = facility.resourceUse.coalUse;
        break;
      case EnergyCarrierType.Diesel:
        amounNotConverted = facility.resourceUse.dieselUse;
        break;
      case EnergyCarrierType.Gasoline:
        amounNotConverted = facility.resourceUse.gasolineUse;
        break;
      case EnergyCarrierType.PropaneOrLpg:
        amounNotConverted = facility.resourceUse.propaneOrLpgUse;
        break;
      default:
        break;
    }
    if (units && amounNotConverted) {
      const amount = floatValueInDefaultUnit(
        amounNotConverted,
        units.defaultUnit,
        units.userUnit
      );
      additionalEnergyTypes.push({
        type: energyType,
        amount,
      });
    }
  });

  return {
    gasUse,
    electricity,
    additionalEnergyTypes,
    waterUse: waterUseConverted,
    selfGeneratedRenewables,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const mapFacilityDataIn = (
  facility: Facility
): SlaughterhouseFacilityForm => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const totalLiveweight = floatValueInDefaultUnit(
    facility.totalLiveweight,
    defaultMetric.unitBarnOutputMass,
    userUOM.unitBarnOutputMass
  ) as number;

  const wasteWaterAmount = floatValueInDefaultUnit(
    facility.auxiliaryInputs.wastewaterAmount,
    defaultMetric.unitWaterUse,
    userUOM.unitWaterUse
  );

  const {
    gasUse,
    electricity,
    additionalEnergyTypes,
    waterUse,
    selfGeneratedRenewables,
  } = mapResourceUseIn(facility, userUOM);

  const auxiliaryInputs = mapAuxiliaryInputsIn(facility, userUOM);

  const retVal: SlaughterhouseFacilityForm = {
    id: facility.id,
    currency: facility.currency,
    location: facility.location,
    type: facility.type,
    name: facility.name,
    targetSpecies: facility.targetSpecies.at(0) as FacilitySpecies,
    oldName: facility.name,
    selfGeneratedRenewables,
    waterUse,
    totalLiveweight,
    wasteWaterAmount,
    auxiliaryInputs,
    gasUse,
    electricity,
    additionalEnergyTypes,
    benchmark: facility.benchmark as boolean
  };

  return retVal;
};
