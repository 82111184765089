import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Grid } from '@material-ui/core';

import StageButtonBox from "../../StageButtonBox";
import SInputFormDialog from "./SInputFormDialog";
import SOutputFormDialog from "./SOutputFormDialog";
import SFeedsFormDialog from "./SFeedsFormDialog";
import SOperationsFormDialog from "./SOperationsFormDialog";
import { BaselineStageProps } from "../../common";
import { SalmonBaseline, SalmonStageItem } from "../../../../models/Baseline/SalmonBaseline";
import { MasterDataEnumType } from "../../Intervention/CommonDataParts/InterventionTypes";
import { MasterDataOption, Maybe } from "../../../../../../graphql/types";

interface SalmonStagesProps extends Omit<BaselineStageProps, 'baseline' | 'item' | 'compoundFeeds'> {
  baseline: SalmonBaseline;
  masterDataEnums: MasterDataEnumType;
  item: SalmonStageItem;
  compoundFeeds: Maybe<Array<MasterDataOption>>;
}

const SalmonStages: FC<SalmonStagesProps> = ({
  item, 
  stageIndex,
  baseline,
  getCompleteness,
  compoundFeeds,
  masterDataEnums,
  formType
}) => {
  const [activeDialog, setActiveDialog] = useState<string | null>('');

  const showDialog = (dialog: React.SetStateAction<string>) => {
    setActiveDialog(dialog);
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, errors, getValues } = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;

  return (
    <>
      <input 
        ref={register()} 
        type="hidden" 
        name={`${stagePrefix}.id`} 
        defaultValue={item.id}
      />
      <input 
        ref={register()} 
        type="hidden" 
        name={`${stagePrefix}.name`} 
        defaultValue={item.name}
      />
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        defaultValue={item?.stageData?.animalType}
      />
      <SInputFormDialog
        formVisible={activeDialog === 'input'}
        formType={formType} 
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        itemIndex={stageIndex}
      />
      <SFeedsFormDialog formVisible={ activeDialog==='feed' } 
        handleCancel={ () => setActiveDialog(null)} 
        formType={formType} 
        baseline={baseline} 
        itemIndex={stageIndex}
        compoundFeeds={compoundFeeds}  
        masterDataEnums={masterDataEnums}
      />
      <SOperationsFormDialog
        formVisible={activeDialog === 'operations'}
        formType={formType} 
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        itemIndex={stageIndex}
        masterDataEnums={masterDataEnums}
      />
      <SOutputFormDialog
        formVisible={activeDialog === 'output'}
        formType={formType} 
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        itemIndex={stageIndex}
      />  
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE"
          iconCode="SALMON_INPUT"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.input`)
              : 0
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          error={errors?.stages && errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog('input')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.feed`)
              : 0
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          error={errors?.stages && errors?.stages[stageIndex]?.stageData?.feed}
          handleOpen={() => showDialog('feed')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"
          iconCode="operationsIcon"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.operations`)
              : 0
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          error={errors?.stages?.[stageIndex]?.stageData?.operations}
          handleOpen={() => showDialog('operations')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE" 
          iconCode="SALMON"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.output`)
              : 0
          }
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            errors?.stages && errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog('output')}
        />
        <StageButtonBox
          optional
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          disabled
          error={undefined}
          handleOpen={() => undefined }
        />
      </Grid>
    </>
  );
};

export default SalmonStages;