import { useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import SustellNewBoatInput from './SustellNewBoatInput';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute',
  height: '16px',
  width: '16px',
  top: '28px',
  right: '7px',
};

const SustellBoatInput = (props) => {
  const {
    label,
    availableOptions,
    subFieldName,
    tooltip,
    formState,
    data,
    fieldItemPrefix,
  } = props;

  const manualChange = useRef(false);
  const { setValue, getValues } = useFormContext();
  const { fields: transport, append: appendTransport, remove: removeTransport } = useFieldArray({ name: `${fieldItemPrefix}.${subFieldName}` });
  useWatch({name: `${fieldItemPrefix}.${subFieldName}`});

  const intl = useIntl();

  useEffect(() => {
    if (manualChange.current) {
      // updates the underlying fields in form after manual
      // adding or removing feeds - important on removal!
      // but should not be triggered when components refresh by itself (change in selectbox or similar)
      setValue(`${fieldItemPrefix}.${subFieldName}`, data);
      manualChange.current = false;
    }
  }, [data, subFieldName, setValue, fieldItemPrefix]);

  const lastBoat = () => {
    return getValues(`${fieldItemPrefix}.${subFieldName}`)?.length === 1;
  } 

  const append = () => {
    appendTransport({ boatName: '', type: '', amount: ''});
  };

  const remove = (index) => {
    removeTransport(index);
  };

  return (
    <DsmGrid containerOnly style={{ marginBottom: 'var(--dsm-spacing-px-6)' }}>
      {transport.map((item, index) => (
        <DsmGrid
          key={item.id}
          style={{
            position: 'relative',
            grid: formState !== FormType.View ? 'auto/auto 20px 24px' : 'auto/auto 24px',
            gap: 'var(--dsm-spacing-px-4)',
            padding: '0px',
            alignItems: 'start',
            rowGap: 'var(--dsm-spacing-px-05)',
          }}
        >
          <SustellNewBoatInput
            label={label}
            availableOptions={availableOptions}
            index={index}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName={subFieldName}
            typeValue={item.type}
            formState={formState}
          />
          { formState !== FormType.View &&
            <DsmButton
              variant="text"
              iconOnly
              destructive
              onClick={() => remove(index)}
              style={{ padding: '0px', marginTop: '24px' }}
              disabled={formState === FormType.View || lastBoat()}
            >
              <DsmIcon slot="before" name="general/trash-01" />
            </DsmButton>
          }
          {tooltip && (
            <Tooltip
              title={tooltip}
              placement="left"
              key={'inputPart4' + item.id}
            >
              <DsmIcon
                name="general/help-circle"
                style={inlineTooltipClass}
                title=""
              />
            </Tooltip>
          )}
        </DsmGrid>
      ))}
      <DsmButton
        variant="text"
        onClick={(e) => append()}
        disabled={formState === FormType.View}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({ id: 'GENERAL.ADD.ANOTHER' })}{' '}
        {intl.formatMessage({ id: 'BASELINE.FORM.SALMON.BOAT' }).toLowerCase()}
      </DsmButton>
    </DsmGrid>
  );
};
export default SustellBoatInput;
