/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-duplicates */
import { createSlice } from "@reduxjs/toolkit";
import { DashboardFilters, DashboardType, DateRange, Simulation } from "../../sustell_15/models/Dashboard/DashboardTypes";
import { SortOptions } from "../../sustell_15/models/Dashboard/DashboardTypes";
import { DashboardImpactType } from "../../sustell_15/models/Dashboard/DashboardTypes";
import { SelectBaselinesInterventions } from "../../sustell_15/models/Dashboard/DashboardTypes";
import { DashboardModel } from "../../sustell_15/models/Dashboard";
import { FootprintCategoryName } from "../../sustell_15/models/Footprint/FootprintTypes";

export type DashboardFiltersDraftState = {
  // // Only one active customer have draft for dashboard, if loaded options draft customerId is 
  // // different than selected customerId, clear draft to avoid invalid filters setting
  customerId: string | undefined;
  selectedType: DashboardType,
  dateRange: DateRange | undefined,
  selectedBaselinesInterventions: SelectBaselinesInterventions[] | undefined,
  impactType: DashboardImpactType | undefined,
  filters: DashboardFilters | undefined,
  impactCategoryKey: FootprintCategoryName | undefined,
  simulationOptions: Simulation | undefined,
  sort: SortOptions | undefined,
  footprintCompare:  {baseline: string | undefined, compare: string | undefined} | undefined,
}

const initialState: DashboardFiltersDraftState = {
  customerId: undefined,
  selectedType: DashboardType.Farm,
  dateRange: undefined,
  selectedBaselinesInterventions: undefined,
  impactType: undefined,
  filters: undefined,
  impactCategoryKey: undefined,
  simulationOptions: undefined,
  sort: undefined,
  footprintCompare: undefined
};

type UpdateStateAction = {
  type: 'UpdateStateAction',
  payload: DashboardModel | undefined
}

export const dashboardFiltersDraftSlice = createSlice({
  name: 'dashboardFiltersDraft',
  initialState: {value: initialState},
  reducers: {
    setDashboardOptionsDraft: (state, action: UpdateStateAction): void => {
      if (!action.payload) {
        return;
      }
      // state = JSON.parse(JSON.stringify(initialState)) as DashboardFiltersDraftState;

      const dashboard = action.payload;

      state.value = {
        customerId: dashboard.customerId,
        selectedType: dashboard.type || DashboardType.Farm,
        dateRange: dashboard.dateRange,
        selectedBaselinesInterventions: dashboard.selectedBaselinesInterventions,
        impactType: dashboard.impactType,
        filters: dashboard.filters,
        impactCategoryKey: dashboard.activeImpactCategory.key,
        simulationOptions: dashboard.simulation,
        sort: dashboard.sortOptions,
        footprintCompare: dashboard.comparisonFootprints ? {
          baseline: dashboard.comparisonFootprints.at(0)?.reference,
          compare: dashboard.comparisonFootprints.at(1)?.reference,
        } : undefined
      }
    },
    clearDashboardOptionsDraft: (state): void => {
      state.value = JSON.parse(JSON.stringify(initialState)) as DashboardFiltersDraftState;
    }
  }
});

export const { setDashboardOptionsDraft, clearDashboardOptionsDraft } = dashboardFiltersDraftSlice.actions;

export default dashboardFiltersDraftSlice.reducer;