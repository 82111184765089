import { Grid } from '@material-ui/core';
import { FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { BaselineDialogProps, FormType, LayingHensForm } from '../../common';

const LHFeedsVariablePart: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
}) => {
  const intl = useIntl();
  const methods = useFormContext<LayingHensForm>();
  const getValues = (key?: string): ReturnType<typeof methods.getValues> =>
    key ? methods.getValues(key) : methods.getValues();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;

  return (
    <>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix}.digestibility`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.METABOLIZABLE_ENERGY',
          })}
          margin="none"
          variant="outlined"
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.METABOLIZABLE_ENERGY.TOOLTIP',
          })}
          addornment={intl.formatMessage({
            id: 'ADDORNMENT.UNITS.PERCENT_OF_GA',
          })}
          disabled={formType === FormType.View}
          defaultValue={getValues(`${fieldItemPrefix}.digestibility`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix}.nitrogenContent`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT',
          })}
          margin="none"
          variant="outlined"
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT.TOOLTIP',
          })}
          addornment="%"
          disabled={formType === FormType.View}
          defaultValue={getValues(`${fieldItemPrefix}.nitrogenContent`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix}.dryMatterContent`}
          type="number"
          label={`${intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.DRY_MATTER_CONTENT'})} *`}
          margin="none"
          variant="outlined"
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.DRY_MATTER_CONTENT.TOOLTIP',
          })}
          addornment="%"
          disabled={formType === FormType.View}
          defaultValue={getValues(`${fieldItemPrefix}.dryMatterContent`)}
        />
      </Grid>
    </>
  );
};

export default LHFeedsVariablePart;
