import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { processAndStageStyles } from '../../../../../_metronic/layout';
import {
  dialogsStyle,
  dialogsStyleDouble,
  dialogsStyleDoubleIntervention,
  dialogsStyleFootprint,
  dialogsStyleIntervention,
} from '../../../../../_metronic/layout/_core/MaterialThemeProvider';
import { IconProvider } from '../../helpers/IconProvider';

export const DialogContainer = (props) => {
  const {
    formVisible,
    variant = 'normal', //'normal' or 'wide'
    formTitle = '',
    iconCode = 'default',
    introText = '',
    introTextStyle,
    dialogContentStyles = {
      marginTop: '20px',
      paddingBottom: '1.25rem',
    },
    handleClose,
    datasetType = 'baseline',
    iconColor = '#007E4B',
    iconWidth = '60px',
    iconBackgroundSize = iconWidth,
    iconBackgroundColor = null,
    borderRadius = '0px',
    withIcon = true,
    width,
  } = props;
  const classes = processAndStageStyles();

  const singleStyle =
    datasetType === 'baseline'
      ? dialogsStyle
      : datasetType === 'footprint'
      ? dialogsStyleFootprint
      : dialogsStyleIntervention;
  const doubleStyle =
    datasetType === 'baseline'
      ? dialogsStyleDouble
      : dialogsStyleDoubleIntervention;

  return (
    <Dialog
      open={formVisible}
      maxWidth={false}
      PaperProps={
        variant === 'wide'
          ? doubleStyle
          : {
              style: {
                ...singleStyle.style,
                width: width ? width : singleStyle.style.width,
              },
            }
      }
      onClose={handleClose}
    >
      <IconButton
        aria-label="close"
        className={classes.dialogCloseBtn}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" disableTypography>
        {withIcon && (
          <div
            className={classes.iconContainer}
            style={{
              backgroundColor: iconBackgroundColor,
              width: iconBackgroundSize,
              height: iconBackgroundSize,
              borderRadius: borderRadius,
            }}
          >
            <IconProvider
              iconCode={iconCode}
              color={iconColor}
              width={iconWidth}
            />
          </div>
        )}
        <Box
          paddingTop={withIcon ? '20px' : '0px'}
          style={{ whiteSpace: 'pre-line' }}
        >
          {formTitle}
        </Box>
      </DialogTitle>
      <DialogContent style={dialogContentStyles}>
        {introText && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={classes.dialogDescriptionText}
              style={{ ...introTextStyle }}
            >
              {introText}
            </Typography>
          </Grid>
        )}
        {props.children}
      </DialogContent>
    </Dialog>
  );
};
