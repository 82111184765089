import { Grid } from "@material-ui/core";
import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { avgLiveWeightMaxs, avgLiveWeightMins } from '../../../../../modules/Farms/validationSchemaRestrictions';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { BaselinePropertyValue, BroilerBaseline, BroilerInput } from '../../../../models/Baseline';
import { defaultUnits, explicitConvertValue, unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup";
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { BaselineDialogProps, FormType } from '../../common';

const BInputFormDialog: FC<BaselineDialogProps> = ({
  handleCancel,
  formType=FormType.Add,
  itemIndex=0,
  formVisible=false 
}) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision();
 
  let minAvgLiveWeightMortality = avgLiveWeightMins.Broiler;
  let maxAvgLiveWeightMortality = avgLiveWeightMaxs.Broiler;
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if(userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass){
    if(minAvgLiveWeightMortality)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      minAvgLiveWeightMortality = Number(explicitConvertValue(minAvgLiveWeightMortality, defaultUnits.unitBarnOutputMass, userUOM.unitBarnOutputMass)?.toFixed(precision));
    if(maxAvgLiveWeightMortality)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      maxAvgLiveWeightMortality = Number(explicitConvertValue(maxAvgLiveWeightMortality, defaultUnits.unitBarnOutputMass, userUOM.unitBarnOutputMass)?.toFixed(precision));
  }
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"}) ;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`; 

  const currResetValue = useRef<BroilerInput>();
  const formContext = useFormContext();

  useEffect(() => {
    if(formVisible) {
      currResetValue.current={...formContext.getValues(fieldItemPrefix)} as BroilerInput; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    // console.log("resetValue input:", currResetValue.current);
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as BroilerBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.input){
        resetObject.stages[itemIndex].stageData.input = {...currResetValue.current};
        formContext.reset(resetObject, { errors: true });
      }
    }

    if(handleCancel)
      handleCancel();
  }

  return (
    <DialogContainer
      formVisible ={ formVisible }
      handleClose = { handleResetClick }
      iconCode="BROILER_INPUT"
      formTitle = {formTitle}
    > 
      <Grid container direction="column" spacing={3} justifyContent="space-between" >
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.purchasedOneDayChickens`}
            type="number"
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.BROILERS.PURCHASED_ANIMALS"})} *`}
            margin="none"
            variant="outlined"
            tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.BROILERS.PURCHASED_ANIMALS.TOOLTIP"}) }
            disabled={formType === FormType.View}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.purchasedOneDayChickens`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.mortalityRate`}
            type="number"
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.HENS.MORTALITY"})} *`}
            margin="none"
            variant="outlined"
            tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.HENS.MORTALITY.TOOLTIP"}) }
            addornment="%"
            disabled={formType === FormType.View}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.mortalityRate`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix  }.averageLiveWeightMortalities`}
            type="number"
            label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.HENS.AVG_LIVEWEIGHT_MORTALITY"}, {min: minAvgLiveWeightMortality, max: maxAvgLiveWeightMortality, unit: barnOutputMassUnit })} *`}
            margin="none"
            variant="outlined"
            tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.HENS.AVG_LIVEWEIGHT_MORTALITY.TOOLTIP"}, {min: minAvgLiveWeightMortality, max: maxAvgLiveWeightMortality, unit: barnOutputMassUnit }) }
            addornment={ barnOutputMassUnit }
            disabled={formType === FormType.View}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.averageLiveWeightMortalities`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleCancel }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default BInputFormDialog;