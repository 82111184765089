import { DsmGrid, DsmSectionHeader } from '@dsm-dcs/design-system-react';
import { Tabs, Typography } from '@material-ui/core';
import { ChangeEvent, FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import {
  footprintTabsStyles,
  processAndStageStylesV2,
} from '../../../../../../_metronic/layout';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { FormType } from '../../common';
import StepperButtons from './StepperButtons';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';

import { AnimalType } from '../../../../../../graphql/types';
import { StyledTab } from '../../../CompoundFeedWizard/utils';
import Resources from './Resources';
import { isBeef, isDairy, isShrimp } from '../../../../helpers/animals';

type AnyEvent = any;
type DsmStyles = any;

interface BaselineProfileProps {
  animalType: AnimalType;
  formType: FormType;
  cancelHandler?: (e: AnyEvent | undefined) => void;
  forwardHandler?: (e: AnyEvent | undefined) => void;
}

export const BaselineProfile: FC<BaselineProfileProps> = ({
  formType = FormType.View,
  animalType,
  cancelHandler = undefined,
  forwardHandler = undefined,
}) => {
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const intl = useIntl();
  const fieldPrefix = 'info';
  const currYear = new Date().getFullYear();
  const years = Object.entries(
    Array.from({ length: 15 }, (_, i) => currYear - i)
  ).map(([i, yearNum]) => ({
    text: yearNum.toString(),
    value: yearNum.toString(),
  }));
  const classes: DsmStyles = processAndStageStylesV2();
  const { trigger, getValues } = useFormContext();
  const [activeTab, setActiveTab] = useState<number>(0);
  const classesTabs = footprintTabsStyles();
  const [datasetName, setDatasetName] = useState('');

  const checkAndForward = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const result = await trigger(['info', 'resourceUse']);
      if (result || animalType === 'DAIRY') {
        if (forwardHandler) forwardHandler(true);
      }
    })();
  };

  const skip = () => {
    if (forwardHandler) forwardHandler(false);
  };

  // When in edit/view mode the tabs are shown instead of steps
  const handleTabChange = (_e: ChangeEvent, i: number) => {
    setActiveTab(i);
  };

  const changeHandler = () => {
    setDatasetName(getValues(`${fieldPrefix}.name`));
  };

  const getResourceDescription = (): string => {
    if (isShrimp(animalType)) return 'SUSTELL.PROCESS.DIALOG.RESOURCES.SHRIMP.DESCRIPTION';
    if (isBeef(animalType) || isDairy(animalType)) return 'SUSTELL.PROCESS.DIALOG.RESOURCES.CATTLE.DESCRIPTION';
    return 'SUSTELL.BASELINE.DETAILS.RESOURCES.DESCRIPTION';
  }

  return (
    <>
      {(formType === FormType.Edit || formType === FormType.View) && (
        <>
          <DsmSectionHeader
            style={{ marginTop: `var(--dsm-spacing-px-6)` }}
            header={
              datasetName ? datasetName : getValues(`${fieldPrefix}.name`)
            }
          />
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className={classesTabs.tabs}
          >
            <StyledTab
              label={intl.formatMessage({ id: 'GENERAL.BASELINE.DETAILS' })}
              value={0}
              id={`tab-baseline-profile`}
              key={`tab-baseline-profile`}
            />
            <StyledTab
              label={intl.formatMessage({ id: 'GENERAL.RESOURCES' })}
              value={1}
              id={`tab-baseline-resources`}
              key={`tab-baseline-resources`}
            />
          </Tabs>
        </>
      )}
      {/* visible either in add mode or in view/edit when appropriate tab selected */}
      {(formType === FormType.Add || activeTab === 0) && (
        <div
          style={
            formType !== FormType.Add
              ? { background: '#F9FAFB', padding: '30px' }
              : { background: '#FFFFFF' }
          }
        >
          <Typography
            variant="h6"
            color="textPrimary"
            style={formType === FormType.Add ? { marginTop: '3rem' } : {}}
          >
            {intl.formatMessage({ id: 'SUSTELL.BASELINE.DETAILS' })}
          </Typography>
          <Typography style={{ marginTop: '10px' }}>
            {intl.formatMessage(
              {
                id: 'SUSTELL.BASELINE.DETAILS.INTRO',
              },
              { br: <br /> }
            )}
          </Typography>

          <DsmGrid
            className={classes.dsmGridFourFixedColumns}
            style={{ marginTop: `var(--dsm-spacing-px-4)` }}
          >
            <ReactHookDsmInput
              name={`${fieldPrefix}.name`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.FIELD.FARM.DATASET.NAME',
              })}
              required
              disabled={formType === FormType.View}
              changeHandler={changeHandler}
            />
            <ReactHookDsmSelect
              name={`${fieldPrefix}.year`}
              options={years}
              placeholder={intl.formatMessage({
                id: 'BASELINE.FORM.FIELD.FARM.DATASET.YEAR.SELECT',
              })}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.FIELD.FARM.DATASET.YEAR',
              })}
              required
              disabled={formType === FormType.View}
            />
            <ReactHookDsmPicker
              name={`${fieldPrefix}.validFrom`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.FIELD.START_DATE',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.BASELINE.START_DATE.TOOLTIP',
              })}
              required
              disabled={formType === FormType.View}
            />
            <ReactHookDsmPicker
              name={`${fieldPrefix}.validTo`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.FIELD.END_DATE',
              })}`}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.BASELINE.END_DATE.TOOLTIP',
              })}
              required
              disabled={formType === FormType.View}
            />
          </DsmGrid>
          <DsmGrid className={classes.dsmGridTwoColumn}>
            <ReactHookDsmInput
              name={`${fieldPrefix}.description`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.FIELD.DESCRIPTION',
              })}`}
              multiline
              disabled={formType === FormType.View}
            />
            {animalType === 'DAIRY' && (
              <ReactHookDsmInput
                name={`${fieldPrefix}.avgAnualTemperature`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.FIELD.AVG_TEMPERATURE',
                })}
                required
                adornment={
                  userUOM?.unitAverageAnnualTemperature
                    ? unitLong(userUOM.unitAverageAnnualTemperature)
                    : intl.formatMessage({
                        id: 'BASELINE.FORM.FIELD.AVG_TEMPERATURE.ADDORNMENT',
                      })
                }
                type="number"
                disabled={formType === FormType.View}
                changeHandler={changeHandler}
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.FIELD.AVG_TEMPERATURE.TOOLTIP',
                })}
              />
            )}
            {isShrimp(animalType) && (
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmInput
                  name={`${fieldPrefix}.age`}
                  label={intl.formatMessage({
                    id: 'BASELINE.FORM.FIELD.AGE_FARM',
                  })}
                  required
                  adornment={
                    intl.formatMessage({
                          id: 'BASELINE.FORM.FIELD.AGE_FARM.ADDORNMENT',
                        })
                  }
                  disabled={formType === FormType.View}
                  changeHandler={changeHandler}
                  tooltip={intl.formatMessage({
                    id: 'BASELINE.FORM.FIELD.AGE_FARM.TOOLTIP',
                  })}
                />
              </DsmGrid>
            )}
          </DsmGrid>
        </div>
      )}
      {(formType === FormType.Add || activeTab === 1) && (
        <div
          style={
            formType !== FormType.Add
              ? { background: '#F9FAFB', padding: '30px' }
              : { background: '#FFFFFF', marginTop: '30px' }
          }
        >
          <Typography variant="h6" color="textPrimary">
            {intl.formatMessage({
              id: isShrimp(animalType) 
                ? 'SUSTELL.PROCESS.DIALOG.RESOURCES.SHRIMP.TITLE' 
                : 'SUSTELL.PROCESS.DIALOG.RESOURCES.TITLE',
            })}
          </Typography>
          <Typography style={{ marginTop: '10px' }}>
            {intl.formatMessage(
              {
                id: getResourceDescription(),
              },
              { br: <br /> }
            )}
          </Typography>
          {animalType !== 'DAIRY' && (
            <Resources
              formType={formType}
              inputType="resourceUse"
              animalType={animalType}
            />
          )}
          {animalType === 'DAIRY' && (
            <DsmGrid
              className={classes.dsmGridFourFixedColumns}
              style={{ marginTop: `var(--dsm-spacing-px-4)` }}
            >
              <ReactHookDsmInput
                name="resourceUse.electricityUse"
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.ELECTRICITY',
                })}
                adornment={
                  userUOM?.unitElectricityUse
                    ? unitLong(userUOM.unitElectricityUse)
                    : 'MJ'
                }
                disabled={formType === FormType.View}
                changeHandler={changeHandler}
                tooltip={intl.formatMessage({
                  id: isShrimp(animalType) 
                    ? 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP'
                    : 'BASELINE.FORM.RESOURCES.ELECTRICITY.TOOLTIP',
                })}
              />
              <ReactHookDsmInput
                name="resourceUse.gasUse"
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.GAS',
                })}
                adornment={
                  userUOM?.unitNaturalGasUse
                    ? unitLong(userUOM.unitNaturalGasUse)
                    : 'MJ'
                }
                disabled={formType === FormType.View}
                changeHandler={changeHandler}
                tooltip={intl.formatMessage({
                  id: isShrimp(animalType) 
                  ? 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP'
                  : 'BASELINE.FORM.RESOURCES.GAS.TOOLTIP',
                })}
                type="number"
              />
              <ReactHookDsmInput
                name="resourceUse.dieselUse"
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.DIESEL',
                })}
                adornment={
                  userUOM?.unitDieselUse
                    ? unitLong(userUOM.unitDieselUse)
                    : 'MJ'
                }
                disabled={formType === FormType.View}
                changeHandler={changeHandler}
                tooltip={intl.formatMessage({
                  id: isShrimp(animalType) 
                  ? 'BASELINE.FORM.RESOURCES.DIESEL.SHRIMP.TOOLTIP'
                  : 'BASELINE.FORM.RESOURCES.DIESEL.TOOLTIP',
                })}
                type="number"
              />
              <ReactHookDsmInput
                name="resourceUse.waterUse"
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.WATER',
                })}
                adornment={
                  userUOM?.unitWaterUse
                    ? unitLong(userUOM.unitWaterUse)
                    : 'liters'
                }
                disabled={formType === FormType.View}
                changeHandler={changeHandler}
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.WATER.TOOLTIP',
                })}
                type="number"
              />
            </DsmGrid>
          )}
        </div>
      )}
      {formType === FormType.Add && (
        <StepperButtons
          continueLabel={intl.formatMessage({ id: 'SUSTELL.CONTINUE' })}
          cancelHandler={cancelHandler}
          skipHandler={skip}
          continueHandler={checkAndForward}
        />
      )}
    </>
  );
};

export default BaselineProfile;
