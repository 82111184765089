import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { PoultryBaseline, PoultryEmission } from '../../../../models/Baseline/PoultryBaseline';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';


const PoultryEmissionsFormDialog: FC<BaselineDialogProps> = ({
  formType,
  itemIndex,
  handleCancel,
  formVisible=false
}) => {
  const intl = useIntl();
  const fc = useFormContext();
  const currResetValue = useRef<PoultryEmission>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"}) ;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions`; 
  const welcomeString = intl.formatMessage({id: "EMISSIONS.WELCOME"});

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) as PoultryEmission || {};
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = {...fc.getValues()} as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.emissions) {
        resetObject.stages[itemIndex].stageData.emissions = {...currResetValue.current};
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }, [fc, handleCancel, itemIndex]);

  return (
    <DialogContainer
      variant = 'wide'
      iconCode="maps-travel/globe-04"
      formVisible = { formVisible }
      formTitle = { formTitle }
      handleClose = { handleResetClick }
      introText = { intl.formatMessage({id: "EMISSIONS.INTRO"}, {boldedWelcome: <strong>{welcomeString}</strong>, br: <br/>}) }
    > 
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing} >
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix}.methane`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.methane`) as string}
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix}.nitricOxide`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.nitricOxide`) as string}
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix}.nitrousOxideDirect`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.nitrousOxideDirect`) as string}
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix}.nonMethaneVolatile`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_ORGANIC"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.nonMethaneVolatile`) as string}
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix}.nitrousOxideIndirect`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.nitrousOxideIndirect`) as string}
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix}.PM10`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.PM10`) as string}
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix}.amonia`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.AMMONIA"})}
          tooltip={intl.formatMessage({id:  "BASELINE.FORM.EMISSIONS.AMMONIA.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.amonia`) as string}
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix}.PM25`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.PM25`) as string}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await fc.trigger(`stages.${itemIndex}.stageData.emissions`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM" })}
      />
	    </DialogContainer>
	);
};

export default PoultryEmissionsFormDialog;