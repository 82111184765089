import * as math from 'mathjs';
import { string } from 'mathjs';

const MAX_METRIC_TONNE_INGREDIENTS = 1000;
const MAX_SHORT_TON_INGREDIENTS = 2000;
const TON_CONVERSION_RATIO = 1.102311311;

export interface DefaultUnits {
  unitFarmSize: string;
  unitAverageAnnualTemperature: string;
  unitOutputMass: string;
  unitElectricityUse: string;
  unitNaturalGasUse: string;
  unitDieselUse: string;
  unitGasolineUse: string;
  unitHeavyFuelOilUse: string;
  unitWaterUse: string;
  unitHeatUse: string;
  unitBarnOutputMass: string;
  unitBarnSmallQuantityUnits: string;
  unitEnvImpactPer: string;
  unitIngredientQuantityInputMass: string;
  unitCompoundFeedOutputMass: string;
  unitCompoundFeedElectricity: string;
  unitTransportDistanceTerrestrial: string;
  unitTransportDistanceSea: string;
  unitTransportDistanceInlandWaterways: string;
  unitCompoundFeedWaterUse: string;
  unitCompoundFeedNutritionalDataContent: string;
  unitNutritionalGrossEnergy: string;
}

// units used by Blonk for caclulation
export const defaultUnits: DefaultUnits = {
  unitFarmSize: 'hectare',
  unitAverageAnnualTemperature: 'degC',
  unitOutputMass: 'kg',
  unitElectricityUse: 'MJ',
  unitNaturalGasUse: 'MJ',
  unitDieselUse: 'MJ',
  unitGasolineUse: 'MJ',
  unitHeavyFuelOilUse: 'MJ',
  unitWaterUse: 'l',
  unitHeatUse: 'MJ',
  unitBarnOutputMass: 'kg',
  unitBarnSmallQuantityUnits: 'g',
  unitEnvImpactPer: 'kg',
  unitIngredientQuantityInputMass: 'kg',
  unitCompoundFeedOutputMass: 'tonne',
  unitCompoundFeedElectricity: 'kWh',
  unitTransportDistanceTerrestrial: 'km',
  unitTransportDistanceSea: 'km',
  unitTransportDistanceInlandWaterways: 'km',
  unitCompoundFeedWaterUse: 'm3',
  unitCompoundFeedNutritionalDataContent: 'gperkg',
  unitNutritionalGrossEnergy: 'MJ',
};

// units used by Blonk for caclulation
export const defaultUnitsV2: DefaultUnits = {
  unitFarmSize: 'hectare',
  unitAverageAnnualTemperature: 'degC',
  unitOutputMass: 'kg',
  unitElectricityUse: 'kWh',
  unitNaturalGasUse: 'MJ',
  unitDieselUse: 'MJ',
  unitGasolineUse: 'MJ',
  unitHeavyFuelOilUse: 'MJ',
  unitWaterUse: 'm3',
  unitHeatUse: 'MJ',
  unitBarnOutputMass: 'kg',
  unitBarnSmallQuantityUnits: 'g',
  unitEnvImpactPer: 'kg',
  unitIngredientQuantityInputMass: 'kg',
  unitCompoundFeedOutputMass: 'tonne',
  unitCompoundFeedElectricity: 'kWh',
  unitTransportDistanceTerrestrial: 'km',
  unitTransportDistanceSea: 'km',
  unitTransportDistanceInlandWaterways: 'km',
  unitCompoundFeedWaterUse: 'm3',
  unitCompoundFeedNutritionalDataContent: 'gperkg',
  unitNutritionalGrossEnergy: 'MJ',
};

// initial units for users
export const intitalUserUnits = {
  ...defaultUnits,
  unitElectricityUse: 'kWh',
  unitNaturalGasUse: 'kWh',
  unitDieselUse: 'kWh',
  unitGasolineUse: 'kWh',
  unitHeavyFuelOilUse: 'kWh',
};

export const unitLongDecription = {
  l: 'litres',
  m3: 'm³',
  thm: 'US Therm',
  gal: 'US Gallons',
  ton: 'Short (US) Ton',
  cuf: 'Cubic Feets',
  degF: 'Degrees Fahrenheit',
  degC: 'Degrees Celsius',
  diesellitre: 'litres',
  dieselUSGallon: 'US Gallons',
  gasolinelitre: 'litres',
  gasolineUSgallon: 'US Gallons',
  heavyFuelOillitre: 'litres',
  heavyFuelOilUSgallon: 'US Gallons',
  gasm3: 'm³',
  gasft3: 'Cubic Feets',
  usgal: 'US Gallons',
  mile: 'Mile',
  nauticalmile: 'N. Mile',
  gperkg: 'g/kg',
  ozperlb: 'oz/lb',
};

math.createUnit('thm', '105.263157894736842 MJ');
math.createUnit('usgal', '3.787878787878788 l');
math.createUnit('diesellitre', '35.971223021582734 MJ');
math.createUnit('dieselUSGallon', '136.986301369863014 MJ');
math.createUnit('gasolinelitre', '32 MJ');
math.createUnit('gasolineUSgallon', '126.8329828 MJ');
math.createUnit('heavyFuelOillitre', '40 MJ');
math.createUnit('heavyFuelOilUSgallon', '158.997542 MJ');
math.createUnit('gasm3', '36.63003663003663 MJ');
math.createUnit('gasft3', '1.036376826614157 MJ');
math.createUnit('lbs', '0.45359237 kg', { override: true });
math.createUnit('lb', '0.45359237 kg', { override: true });
math.createUnit('ton', `${MAX_SHORT_TON_INGREDIENTS} lb`, { override: true });
math.createUnit('nauticalmile', '1.852 km');
math.createUnit('kCal', '0.004184 MJ');
math.createUnit({
  gperkg: {
    definition: '1 g/kg',
  },
  ozperlb: {
    definition: '1 oz/lb',
  },
});

export const unitLong = (unit: string) => {
  const longDesc = unitLongDecription[unit as keyof typeof string];

  if (longDesc) return longDesc;

  // if not found long desc, check if the UoM is composite
  // in this case check for each part
  if (!unit?.includes('/')) return unit;

  const parts = unit?.split('/');

  const names = (parts || []).map((unitPart) =>
    unitLongDecription[unitPart as keyof typeof string]
      ? unitLongDecription[unitPart as keyof typeof string]
      : unitPart
  );

  return names.join('/');
};

/**
 * This function will convert Mass from a Mass percentage value for Ingredients.
 * Why this only can be used for ingredients is due to the kg / tonne (metric) and lb / ton (US Short) format.
 * The Number(format) approach makes sure we do not show trailing zeros. So 1.0000 becomes 1 and 1.0001 stays 1.0001.
 * @param value
 * @param unit
 * @returns
 */
export const unitIngredientPercentage = (
  value: number,
  unit: string
): number => {
  switch (unit) {
    case 'lb':
      return Number(
        math.format((value / MAX_SHORT_TON_INGREDIENTS) * 100, {
          notation: 'fixed',
          precision: 5,
        })
      );
    default:
      return Number(
        math.format((value / MAX_METRIC_TONNE_INGREDIENTS) * 100, {
          notation: 'fixed',
          precision: 5,
        })
      );
  }
};

/**
 * This function will convert Mass from a Mass percentage value for Ingredients.
 * Why this only can be used for ingredients is due to the kg / tonne (metric) and lb / ton (US Short) format.
 * The Number(format) approach makes sure we do not show trailing zeros. So 1.0000 becomes 1 and 1.0001 stays 1.0001.
 * @param value
 * @param unit
 * @returns
 */
export const unitIngredientFromPercentage = (
  value: number,
  unit: string
): number => {
  switch (unit) {
    case 'lb':
      return Number(
        math.format((value / 100) * MAX_SHORT_TON_INGREDIENTS, {
          notation: 'fixed',
          precision: 5,
        })
      );
    default:
      return Number(
        math.format((value / 100) * MAX_METRIC_TONNE_INGREDIENTS, {
          notation: 'fixed',
          precision: 5,
        })
      );
  }
};

export function explicitConvertValue(
  value: number | null | undefined,
  fromUnit: string,
  toUnit: string,
  isTonConversion = false
): number | null {
  try {
    if (value != null && value !== undefined) {
      if (isTonConversion && toUnit === 'lb') {
        return Number(
          math.format(
            math.unit(value / TON_CONVERSION_RATIO, fromUnit).toNumber(toUnit),
            {
              notation: 'fixed',
              precision: 5,
            }
          )
        );
      }
      if (isTonConversion && fromUnit === 'lb' && toUnit === 'kg') {
        return Number(
          math.format(
            math.unit(value * TON_CONVERSION_RATIO, fromUnit).toNumber(toUnit),
            {
              notation: 'fixed',
              precision: 5,
            }
          )
        );
      }
      return Number(
        math.format(math.unit(value, fromUnit).toNumber(toUnit), {
          notation: 'fixed',
          precision: 5,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
  return null;
}

const getUnitsForField = (field: string, customDefaultUnits: DefaultUnits) => {
  if (customDefaultUnits)
    return customDefaultUnits[field as keyof typeof string];

  return defaultUnits[field as keyof typeof string];
};

export function convertValue(
  field: string,
  value: number,
  desiredUnit: string,
  customDefaultUnits = defaultUnits
) {
  if (!field) {
    return value;
  }

  const defaultUnit = getUnitsForField(field, customDefaultUnits);

  if (!desiredUnit || !defaultUnit || desiredUnit === defaultUnit) return value;

  let convertedValue = value;
  try {
    convertedValue = math.unit(value, defaultUnit).toNumber(desiredUnit);
  } catch (error) {
    console.log(error);
  }

  return convertedValue;
}
