import React, { DetailedHTMLProps } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, InputAdornment, TextField } from '@material-ui/core';

import InfoToolTip from '../../Helpers/InfoToolTip';
import { resolvePath } from '../../Helpers/resolvePathFn';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

interface TimeSpentInBuildingsTextFieldProps {
  name: string;
  label: string;
  // eslint-disable-next-line react/require-default-props
  tooltip?:
    | string
    | DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  // eslint-disable-next-line react/require-default-props
  precision?: number;
  // eslint-disable-next-line react/require-default-props
  addornment?: string;
  // eslint-disable-next-line react/require-default-props
  margin?: 'none' | 'dense' | 'normal';
  // eslint-disable-next-line react/require-default-props
  variant?: 'outlined' | 'filled' | 'standard';
  // eslint-disable-next-line react/require-default-props
  type?: React.InputHTMLAttributes<unknown>['type'];
  // eslint-disable-next-line react/require-default-props
  baseline?: number | string | null;
  disabled: boolean;
}

const TimeSpentInBuildingsTextField = ({
  name,
  label,
  tooltip = '',
  precision = 2,
  addornment = '%',
  margin = 'normal',
  variant = 'outlined',
  type = 'text',
  baseline = null,
  disabled,
}:TimeSpentInBuildingsTextFieldProps) => {
  const intl = useIntl();
  const fc = useFormContext();

  let numOldValue: number | null = null;
  if (typeof baseline === 'number') numOldValue = baseline;
  else if (typeof baseline === 'string') numOldValue = parseFloat(baseline);

  if (Number.isNaN(numOldValue)) numOldValue = null;
  
  // TODO fix this when coverted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const localError = resolvePath(fc.errors, name, null);

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Controller
          control={fc.control}
          name={name}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ onChange, onBlur, value, ref }) => (
            <>
              <TextField
                label={label}
                margin={margin}
                variant={variant}
                value={value as string}
                fullWidth
                inputRef={ref}
                type={type}
                error={!!localError}
                  // TODO Fix when resolvePath is noverted to TS
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                helperText={localError ? localError.message : ''}
                disabled={disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{addornment}</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
              <div>
                {intl.formatMessage({
                  id: 'INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE',
                })}{' '}
                <strong>
                  {numOldValue
                    ? `${numOldValue.toFixed(precision) 
                      } %`
                    : ' - '}
                </strong>
              </div>
            </>
          )}
        />
      </Grid>
      <Grid item xs={1} className="d-flex" style={{ paddingTop: '32px' }}>
        <InfoToolTip tooltip={tooltip} />
      </Grid>
    </Grid>
  );
};
export default TimeSpentInBuildingsTextField;
