import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { FormatDateOptions } from 'react-intl';

import { useHistory } from 'react-router';
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';

import { DsmButton, DsmIcon, DsmModal } from '@dsm-dcs/design-system-react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Can from '../Auth/Can';

import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { listFacilitiesBrief } from '../../../graphql/queries';
import { FacilityTableItem } from '../../sustell_15/models/Facility/FacilityTypes';
import { footprintCategoryTableStyles } from '../../../_metronic/layout';
import { FormType } from '../../sustell_15/components/FarmFlow/common';
import countryIsoCodes from '../../sustell_15/components/dashboard/isoCodes';
import { deleteFacility } from '../../../graphql/mutations';
import {
  NotificationDialog,
  NotificationDialogType,
} from '../../sustell_15/components/helpers/NotificationDialog';
import { dateDisplayOptionsShort } from '../../sustell_15/utils/datetime-utils';

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: '12px !important',
    borderBottom: '1px solid #f0f0f0',
  },
  body: {
    border: 'none',
  },
  root: {
    '&:nth-of-type(1)': {
      Width: '298px',
    },
  },
}))(TableCell);

type TableColumn =
  | 'name'
  | 'species'
  | 'location'
  | 'usage'
  | 'modifiedby'
  | 'lastmodified';

enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

interface TableSort {
  column?: TableColumn | undefined;
  direction?: SortDirection | undefined;
}

interface NotificatinBrief {
  message: string;
  type: NotificationDialogType;
}

const FacilitiesTable: FC = () => {
  const intl = useIntl();
  // For now using MUI table, design system table does not have sort option
  const classes = footprintCategoryTableStyles();

  const customerId = useSelector(
    (state: any) => state.selectedCustomer?.value
  )?.id;

  // const {customerID: customerId} = match.params;
  const [facilities, setFacilities] = useState<FacilityTableItem[]>([]);
  const [sort, setSort] = useState<TableSort>();
  const [openDeleteDialogFor, setOpenDeleteDialogFor] = useState<string | null>(
    null
  );
  const [notification, setNotification] = useState<NotificatinBrief | null>(
    null
  );
  const history = useHistory();

  const loadFacilities = () => {
    (
      API.graphql({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: listFacilitiesBrief,
        variables: {
          customerId: customerId,
        },
      }) as Promise<{
        data: {
          listFacilities: {
            items: FacilityTableItem[];
          };
        };
      }>
    ).then(
      (response) => {
        setFacilities(response.data.listFacilities.items || []);
      },
      (reason) => {
        console.log('err, ', reason);
        // TODO: err handler
      }
    );
  };

  const viewEditFacility = (
    facilityId: string,
    formType: FormType.View | FormType.Edit,
    duplicate = false
  ) => {
    history.push(`/profile/customers/${customerId}/facilities/${facilityId}`, {
      formType,
      duplicate,
    });
  };

  const openDeleteDialog = (facilityId: string) => {
    setOpenDeleteDialogFor(facilityId);
  };

  const handleDeleteFacility = (facilityId: string) => {
    (
      API.graphql({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: deleteFacility,
        variables: {
          customerId: customerId,
          facilityId,
        },
      }) as Promise<{
        data: {
          deleteFacility: {
            id: string;
          };
        };
      }>
    ).then(
      (response) => {
        const copyFacilities = JSON.parse(
          JSON.stringify(facilities)
        ) as FacilityTableItem[];
        const indexToRemove = facilities.findIndex(
          (f) => f.id === response.data.deleteFacility.id
        );
        copyFacilities.splice(indexToRemove, 1);
        setFacilities(copyFacilities);
        const not = {
          message: intl.formatMessage({
            id: 'SUSTELL.FACILITIES.DELETE.SUCCESS',
          }),
          type: NotificationDialogType.INFO,
        };
        setNotification(not);
      },
      (reason) => {
        console.log('err, ', reason);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const not = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          message: reason?.errors?.at(0)?.message,
          type: NotificationDialogType.ERROR,
        };
        setNotification(not);
      }
    );
  };

  const sortTableBy = (column: TableColumn) => {
    let newDirection;
    if (!sort?.direction) {
      newDirection = SortDirection.Desc;
    } else if (sort.direction === SortDirection.Desc) {
      newDirection = SortDirection.Asc;
    } else {
      newDirection = undefined;
    }
    if (sort?.column !== column || sort.direction !== newDirection) {
      setSort({
        column,
        direction: newDirection,
      });
    }
  };

  const getSortedFacilities = (): Array<FacilityTableItem> => {
    const filteredList = [
      ...facilities
        ?.filter((item) => item.benchmark)
        ?.sort((a, b) => {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
          return 0;
        }),
      ...facilities
        ?.filter((item) => !item.benchmark)
        ?.sort((a, b) => {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
          return 0;
        }),
    ];
    return filteredList.sort((a, b) => {
      let val1;
      let val2;
      if (sort?.column === 'name') {
        val1 = a.name;
        val2 = b.name;
      } else if (sort?.column === 'location') {
        val1 = a.location;
        val2 = b.location;
      } else if (sort?.column === 'species') {
        val1 = a.targetSpecies?.at(0) || '';
        val1 = a.targetSpecies?.at(0) || '';
      } else if (sort?.column === 'usage') {
        val1 = a.type.toString();
        val2 = b.type.toString();
      } else if (sort?.column === 'lastmodified') {
        val1 = new Date(a.updatedAt).getTime().toString();
        val2 = new Date(b.updatedAt).getTime().toString();
      } else if (sort?.column === 'modifiedby') {
        val1 = a.lastUpdatedBy;
        val2 = b.lastUpdatedBy;
      }
      if (sort?.direction === SortDirection.Asc) {
        return (val1 || '').localeCompare(val2 || '');
      }
      if (sort?.direction === SortDirection.Desc) {
        return (val2 || '').localeCompare(val1 || '');
      }
      return 0;
    });
  };
  useEffect(() => {
    loadFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NotificationDialog
        open={!!notification}
        message={notification?.message || null}
        type={
          notification?.type ? notification.type : NotificationDialogType.ERROR
        }
        closeNotificationDialog={() => setNotification(null)}
      />
      <DsmModal
        open={!!openDeleteDialogFor}
        header={intl.formatMessage({ id: 'SUSTELL.FACILITIES.DELETE' })}
        icon="general/trash-02"
        centred
      >
        <p slot="content">
          {intl.formatMessage({ id: 'SUSTELL.FACILITIES.DELETE1' })}
        </p>
        <>
          <DsmButton
            slot="actions"
            destructive
            style={{ marginLeft: '60px' }}
            onClick={() => {
              handleDeleteFacility(openDeleteDialogFor!);
              setOpenDeleteDialogFor(null);
            }}
          >
            {intl.formatMessage({
              id: 'GENERAL.DELETE',
            })}
          </DsmButton>
          <DsmButton
            variant="secondary"
            slot="actions"
            onClick={() => {
              setOpenDeleteDialogFor(null);
            }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          >
            {intl.formatMessage({
              id: 'GENERAL.CANCEL',
            })}
          </DsmButton>
        </>
      </DsmModal>

      <TableContainer>
        <Table
          aria-label="customized table facilities"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ cursor: 'pointer', width: '34%' }}
                onClick={() => sortTableBy('name')}
              >
                {intl.formatMessage({ id: 'SUSTELL.STAGE.FACILITY_NAME' })}
                {sort?.column === 'name' &&
                sort.direction === SortDirection.Desc ? (
                  <ArrowDownwardOutlined />
                ) : (
                  <ArrowUpwardOutlined
                    htmlColor={sort?.column !== 'name' ? 'gray' : ''}
                  />
                )}
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }}>
                {' '}
                &nbsp;
              </StyledTableCell>

              <StyledTableCell
                style={{ cursor: 'pointer', width: '34%' }}
                onClick={() => sortTableBy('lastmodified')}
              >
                {intl.formatMessage({ id: 'GENERAL.LAST_MODIFIED' })}
                {sort?.column === 'lastmodified' &&
                sort.direction === SortDirection.Desc ? (
                  <ArrowDownwardOutlined />
                ) : (
                  <ArrowUpwardOutlined
                    htmlColor={sort?.column !== 'name' ? 'gray' : ''}
                  />
                )}
              </StyledTableCell>

              <StyledTableCell
                style={{ cursor: 'pointer', width: '34%' }}
                onClick={() => sortTableBy('modifiedby')}
              >
                {intl.formatMessage({ id: 'GENERAL.MODIFIED_BY' })}
                {sort?.column === 'modifiedby' &&
                sort.direction === SortDirection.Desc ? (
                  <ArrowDownwardOutlined />
                ) : (
                  <ArrowUpwardOutlined
                    htmlColor={sort?.column !== 'name' ? 'gray' : ''}
                  />
                )}
              </StyledTableCell>

              <StyledTableCell
                style={{ cursor: 'pointer' }}
                onClick={() => sortTableBy('location')}
              >
                {intl.formatMessage({ id: 'GENERAL.COUNTRY' })}
                {sort?.column === 'location' &&
                sort.direction === SortDirection.Desc ? (
                  <ArrowDownwardOutlined />
                ) : (
                  <ArrowUpwardOutlined
                    htmlColor={sort?.column !== 'location' ? 'gray' : ''}
                  />
                )}
              </StyledTableCell>

              <StyledTableCell
                style={{ cursor: 'pointer' }}
                onClick={() => sortTableBy('species')}
              >
                {intl.formatMessage({ id: 'GENERAL.ANIMAL_SYSTEM_TYPE' })}
                {sort?.column === 'species' &&
                sort.direction === SortDirection.Desc ? (
                  <ArrowDownwardOutlined />
                ) : (
                  <ArrowUpwardOutlined
                    htmlColor={sort?.column !== 'species' ? 'gray' : ''}
                  />
                )}
              </StyledTableCell>

              <StyledTableCell
                style={{ cursor: 'pointer' }}
                onClick={() => sortTableBy('usage')}
              >
                {intl.formatMessage({ id: 'SUSTELL.FACILITIES.INFO.USAGE' })}
                {sort?.column === 'usage' &&
                sort.direction === SortDirection.Desc ? (
                  <ArrowDownwardOutlined />
                ) : (
                  <ArrowUpwardOutlined
                    htmlColor={sort?.column !== 'usage' ? 'gray' : ''}
                  />
                )}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {facilities &&
              getSortedFacilities().map((item) => (
                <TableRow
                  style={{ background: item.benchmark ? '#F0F7FB' : '' }}
                  key={item.id}
                >
                  <StyledTableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => viewEditFacility(item.id, FormType.View)}
                  >
                    {item.benchmark && (
                      <DsmIcon
                        name="general/pin-02"
                        style={{
                          display: 'inline-block',
                          width: '18px',
                          marginRight: '10px',
                          verticalAlign: 'middle',
                        }}
                      />
                    )}
                    {item.name}
                  </StyledTableCell>

                  <StyledTableCell>
                    <PopupState variant="popover" popupId="popup-menu-feed">
                      {(popupState) => (
                        <>
                          <Button
                            id="more-actions"
                            aria-controls="fade-menu"
                            aria-haspopup="true"
                            style={{ marginTop: '8px' }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindTrigger(popupState)}
                          >
                            <DsmIcon
                              name="general/dots-vertical"
                              style={{ height: '24px', width: '24px' }}
                            />
                          </Button>

                          <Menu
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindMenu(popupState)}
                            style={{ marginTop: '36px' }}
                          >
                            {Can('update', 'Facility') && !item.benchmark && (
                              <MenuItem
                                style={{ fontWeight: '500' }}
                                onClick={() =>
                                  viewEditFacility(item.id, FormType.Edit)
                                }
                              >
                                <DsmIcon
                                  name="general/edit-01"
                                  style={{
                                    marginRight: ' 5px',
                                    height: '16px',
                                    width: '16px',
                                  }}
                                />
                                {intl.formatMessage({ id: 'GENERAL.EDIT' })}
                              </MenuItem>
                            )}
                            {Can('update', 'Facility') && (
                              <MenuItem
                                style={{ fontWeight: '500' }}
                                onClick={() =>
                                  viewEditFacility(item.id, FormType.Edit, true)
                                }
                              >
                                <DsmIcon
                                  name="general/copy-02"
                                  style={{
                                    marginRight: ' 5px',
                                    height: '16px',
                                    width: '16px',
                                  }}
                                />
                                {intl.formatMessage({
                                  id: 'GENERAL.DUPLICATE',
                                })}
                              </MenuItem>
                            )}
                            {Can('update', 'Facility') && (
                              <MenuItem
                                style={{
                                  fontWeight: '500',
                                  color: 'red',
                                }}
                                onClick={() => {
                                  popupState.close();
                                  openDeleteDialog(item.id);
                                }}
                              >
                                <DsmIcon
                                  name="general/trash-01"
                                  style={{
                                    marginRight: ' 5px',
                                    height: '16px',
                                    width: '16px',
                                  }}
                                />
                                {intl.formatMessage({ id: 'GENERAL.DELETE' })}
                              </MenuItem>
                            )}
                          </Menu>
                        </>
                      )}
                    </PopupState>
                  </StyledTableCell>

                  <StyledTableCell>
                    {item.updatedAt
                      ? intl.formatDate(
                          item.updatedAt,
                          dateDisplayOptionsShort as FormatDateOptions
                        )
                      : ''}
                  </StyledTableCell>

                  <StyledTableCell>{item.lastUpdatedBy}</StyledTableCell>

                  <StyledTableCell>
                    {Object.entries(countryIsoCodes).find(
                      ([key]) => key === item.location
                    )?.[1] || ''}
                  </StyledTableCell>

                  <StyledTableCell>
                    {item.targetSpecies
                      .map((species) =>
                        intl.formatMessage({ id: `SUSTELL.SPECIES.${species}` })
                      )
                      .join(', ')}
                  </StyledTableCell>

                  <StyledTableCell>
                    {intl.formatMessage({ id: `SUSTELL.STAGE.${item.type}` })}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FacilitiesTable;
