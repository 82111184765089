import { CompoundFeedFormExtended } from "../../sustell_15/components/CompoundFeedWizard/types";
import { store } from "../store";
import { DashboardFiltersDraftState } from "./dashboardFiltersDraftSlice";

export const getDashboardOptionsState = (): DashboardFiltersDraftState | undefined => {
  if (!store){
    console.error('store is not defiend');
    return undefined;
  }
  const state = JSON.parse(JSON.stringify(store.getState().dashboardDrafts.value)) as DashboardFiltersDraftState;
  if (state.dateRange) {
    if (state.dateRange.startDate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.dateRange.startDate = new Date(state.dateRange.startDate + '');
    }
    if (state.dateRange.endDate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.dateRange.endDate = new Date(state.dateRange.endDate + '');
    }
  }
  return state;
}

// TODO: convert this later to thunk func to try to fetch the draft from API and move from store
/** Helper function to get draft with key directly from store */
const getFeedDraftByKey = (key: string): CompoundFeedFormExtended | undefined => {
  if (!store) {
    console.error('store is not defiend');
    return;
  }
  
  return store.getState().feedDrafts.value[key]
};

export default getFeedDraftByKey;