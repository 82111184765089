/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { ExternalSource } from '../../../../models/Baseline/PoultryBaseline';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';

interface PoultryInputPartExternalSourceProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  smallUnits?: boolean;
  formType: FormType;
  item: ExternalSource;
  intlPrefix: string;
  propName: string;
}

const PoultryInputPartExternalSource: FC<PoultryInputPartExternalSourceProps> = ({
  stageIndex = 0,
  itemIndex,
  smallUnits = false,
  formType,
  item,
  intlPrefix,
  propName='externalSources'
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();
  const fieldItemPrefix = (itemIndex || itemIndex === 0) ? `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}` : `stages.${stageIndex}.stageData.input.${propName}`;
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const barnSmallQuantityUnits = unitLong(userUOM.unitBarnSmallQuantityUnits as string);
  const distanceUnit = unitLong(userUOM.unitTransportDistanceTerrestrial as string);

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        type="hidden"
        value={item.id}
        ref={fc.register()}
        name={`${fieldItemPrefix}.id`}
      />
      <DsmGrid style={{'width': '500px'}} className={classes.additionalEntriesBlockHolderInterventionPoultry}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.numberAnimals`}
          label={intl.formatMessage({id:  `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE`})}
          tooltip={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE.TOOLTIP`})}
          type="number"
          metricUnit=''
          baseline={item?.numberAnimals}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT`})}
          tooltip={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT.TOOLTIP`})}
          metricUnit={smallUnits ? barnSmallQuantityUnits : barnOutputMassUnit}
          type="number"
          baseline={item?.averageWeight}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.transportDistance`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.DISTANCE"})}
          tooltip={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.DISTANCE.TOOLTIP`})}
          type="number"
          metricUnit={distanceUnit}
          precision={barnOutputMassPrecision}
          baseline={item?.transportDistance}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
    </>
  );
};

export default PoultryInputPartExternalSource;
