import { LinearProgress, LinearProgressProps, withStyles } from "@material-ui/core";

type StyledLinearProgressProps = {
    barcolor: string;
    secondbarcolor?: string
};
  
const StyledLinearProgressComponent = withStyles({
barColorPrimary: {
    backgroundColor: ({barcolor}: StyledLinearProgressProps) => barcolor,
},
dashedColorPrimary: {
    backgroundImage: 'none',
},
colorPrimary: {
    backgroundColor: ({secondbarcolor}: StyledLinearProgressProps) => secondbarcolor,
}
})(LinearProgress);

const StyledLinearProgress = ({
    variant,
    color,
    style,
    value,
    valueBuffer,
    secondbarcolor,
    barcolor
}: LinearProgressProps & StyledLinearProgressProps) => (
        <StyledLinearProgressComponent
            variant={variant}
            color={color}
            style={style}
            value={value}
            valueBuffer={valueBuffer}
            secondbarcolor={secondbarcolor}
            barcolor={barcolor}
        />
    )

StyledLinearProgress.defaultProps = {
    secondbarcolor: 'transparent'
}

export default StyledLinearProgress;