/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
// import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { floatValueInDefaultUnit } from '../../Baseline/mapper/floatHelperFunctions';
import { emissionFieldsMapOutV2 } from './emissionFields';
import { setInterventionFloatValue } from './outCommonMapperSustell';
import { StageType } from '../../../../../graphql/types';
import mapOutProcessingStage from './outProcessingMapperSustell';
import { AnimalProductivity } from '../../../../../graphql/generated/blonk/pigs';

const productivity = AnimalProductivity.High;

export const createCorePayload = (formData) => {
  const payloadObj = {
    name: formData.info?.name,
    description: formData.info?.description,
    category: formData.info?.interventionCategory,
    baseline: formData.baselineId,
    resourceUse: {},
    // materialUse: {},
    // beddingUse: {},
    stages: [],
  };
  if (formData.info.oldName) payloadObj.oldName = formData.info.oldName;

  return payloadObj;
};

export const mapResourceUseSustell = (
  formData,
  payloadObj,
  resources = [
    'electricity_use',
    'gas_use',
    'water_use',
    'diesel_use',
    'gasoline_use',
    'heavy_fuel_oil_use',
    'propaneOrLpg_use',
    'coal_use',
    'electricityPhotovoltaic_use',
    'electricityWind_use',
  ]
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  if (
    // resources?.includes('electricity_use') &&
    (formData.resourceUse?.electricityUse ||
      formData.resourceUse?.electricityUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'electricityUse',
      formData.resourceUse,
      'electricityUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (
    // resources?.includes('electricityPhotovoltaic_use') &&
    (formData.resourceUse?.electricityPhotovoltaicUse ||
      formData.resourceUse?.electricityPhotovoltaicUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'electricityPhotovoltaicUse',
      formData.resourceUse,
      'electricityPhotovoltaicUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (
    // resources?.includes('electricityWind_use') &&
    (formData.resourceUse?.electricityWindUse ||
      formData.resourceUse?.electricityWindUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'electricityWindUse',
      formData.resourceUse,
      'electricityWindUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (
    // resources?.includes('gas_use') &&
    (formData.resourceUse?.gasUse || formData.resourceUse?.gasUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'gasUse',
      formData.resourceUse,
      'gasUse',
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse
    );

  if (
    // resources?.includes('water_use') &&
    (formData.resourceUse?.waterUse || formData.resourceUse?.waterUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'waterUse',
      formData.resourceUse,
      'waterUse',
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse
    );

  if (
    // resources?.includes('diesel_use') &&
    (formData.resourceUse?.dieselUse || formData.resourceUse?.dieselUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'dieselUse',
      formData.resourceUse,
      'dieselUse',
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse
    );
  if (
    // resources?.includes('gasoline_use') &&
    (formData.resourceUse?.gasolineUse ||
      formData.resourceUse?.gasolineUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'gasolineUse',
      formData.resourceUse,
      'gasolineUse',
      userUOM.unitGasolineUse,
      defaultMetric.unitGasolineUse
    );

  if (
    (formData.resourceUse?.heavyFuelOilUse ||
      formData.resourceUse?.heavyFuelOilUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'heavyFuelOilUse',
      formData.resourceUse,
      'heavyFuelOilUse',
      userUOM.unitHeavyFuelOilUse,
      defaultMetric.unitHeavyFuelOilUse
    );

  if (
    // resources?.includes('propaneOrLpg_use') &&
    (formData.resourceUse?.propaneOrLpgUse ||
      formData.resourceUse?.propaneOrLpgUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'propaneOrLpgUse',
      formData.resourceUse,
      'propaneOrLpgUse',
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse
    );

  if (
    // resources?.includes('coal_use') &&
    (formData.resourceUse?.coalUse || formData.resourceUse?.coalUse === 0)
  )
    setInterventionFloatValue(
      payloadObj.resourceUse,
      'coalUse',
      formData.resourceUse,
      'coalUse',
      userUOM.unitHeatUse,
      defaultMetric.unitHeatUse
    );

    formData?.resourceUse?.energyTypes?.forEach((energyType) => {
      if (
        // resources?.includes(energyType.type) &&
        energyType.type === "dieselUse" &&
        (energyType.value || energyType.value === 0)
      ) {
        payloadObj.resourceUse[energyType.type] =
          userUOM.unitDieselUse && defaultMetric.unitDieselUse
            ? {
                value: floatValueInDefaultUnit(
                  energyType.value,
                  userUOM.unitDieselUse,
                  defaultMetric.unitDieselUse
                ),
                change_type: "set",
              }
            : { value: energyType.value, change_type: "set" };
      }
  
      if (
        // resources?.includes(energyType.type) &&
        energyType.type === "gasolineUse" &&
        (energyType.value || energyType.value === 0)
      ) {
        payloadObj.resourceUse[energyType.type] =
          userUOM.unitGasolineUse && defaultMetric.unitGasolineUse
            ? {
                value: floatValueInDefaultUnit(
                  energyType.value,
                  userUOM.unitGasolineUse,
                  defaultMetric.unitGasolineUse
                ),
                change_type: "set",
              }
            : { value: energyType.value, change_type: "set" };
      }
  
      if (
        // resources?.includes(energyType.type) &&
        energyType.type === "heavyFuelOilUse" &&
        (energyType.value || energyType.value === 0)
      ) {
        payloadObj.resourceUse[energyType.type] =
          userUOM.unitHeavyFuelOilUse && defaultMetric.unitHeavyFuelOilUse
            ? {
                value: floatValueInDefaultUnit(
                  energyType.value,
                  userUOM.unitHeavyFuelOilUse,
                  defaultMetric.unitHeavyFuelOilUse
                ),
                change_type: "set",
              }
            : { value: energyType.value, change_type: "set" };
      }

    if (
      // resources?.includes(energyType.type) 
     energyType.type === 'coalUse'
    && (energyType.value || energyType.value === 0)
    )
      payloadObj.resourceUse[energyType.type] =
        userUOM.unitHeatUse && defaultMetric.unitHeatUse
          ? {
              value: floatValueInDefaultUnit(
                energyType.value,
                userUOM.unitHeatUse,
                defaultMetric.unitHeatUse
              ),
              change_type: 'set',
            }
          : { value: energyType.value, change_type: 'set' };
    if (
      // resources?.includes(energyType.type) &&
      energyType.type === 'propaneOrLpgUse'  &&
        (energyType.value || energyType.value === 0)
    )
      payloadObj.resourceUse[energyType.type] =
        userUOM.unitNaturalGasUse && defaultMetric.unitNaturalGasUse
          ? {
              value: floatValueInDefaultUnit(
                energyType.value,
                userUOM.unitNaturalGasUse,
                defaultMetric.unitNaturalGasUse
              ),
              change_type: 'set',
            }
          : { value: energyType.value, change_type: 'set' };
  });

  formData?.resourceUse?.selfGeneratedRenewables?.forEach(
    (selfGeneratedRenewable) => {
      if (
        // resources?.includes(selfGeneratedRenewable.type)
        selfGeneratedRenewable.value || selfGeneratedRenewable.value === 0
      )
        payloadObj.resourceUse[selfGeneratedRenewable.type] =
          userUOM.unitElectricityUse && defaultMetric.unitElectricityUse
            ? {
                value: floatValueInDefaultUnit(
                  selfGeneratedRenewable.value,
                  userUOM.unitElectricityUse,
                  defaultMetric.unitElectricityUse
                ),
                change_type: 'set',
              }
            : { value: selfGeneratedRenewable.value, change_type: 'set' };
    }
  );

  return payloadObj;
};

export const mapCompoundFeedForOneStage = (stageFormDataFeeds, feedingProgram) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    stageFormDataFeeds.compoundFeeds &&
    Array.isArray(stageFormDataFeeds.compoundFeeds)
  ) {
    feedingProgram.compoundFeedUses = [];
    stageFormDataFeeds.compoundFeeds?.forEach((oneFeedItem) => {
      if (oneFeedItem.kgPerAnimal || oneFeedItem.kgPerAnimal === 0) {
        const oneCompoundInput = {
          compoundFeedId: oneFeedItem.feedType, // TODO check prop name for baseline
          quantity: {},
        };

        setInterventionFloatValue(
          oneCompoundInput,
          'quantity',
          oneFeedItem,
          'kgPerAnimal',
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );

        feedingProgram.compoundFeedUses.push(oneCompoundInput);
      }
    });
  }

  // loop through rations arrays added in the intervention
  if (
    stageFormDataFeeds.compoundFeedsAdditions &&
    Array.isArray(stageFormDataFeeds.compoundFeedsAdditions)
  ) {
    if (!feedingProgram.compoundFeedUsesAdditions)
      feedingProgram.compoundFeedUsesAdditions = [];
    stageFormDataFeeds.compoundFeedsAdditions?.forEach((addnlFeedItem) => {
      if (addnlFeedItem.kgPerAnimal) {
        const value = floatValueInDefaultUnit(
          addnlFeedItem.kgPerAnimal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        const oneCompoundInput = {
          compoundFeedId: addnlFeedItem.feedType,
          quantity: value,
        };

        feedingProgram.compoundFeedUsesAdditions.push(
          oneCompoundInput
        );
      }
    });
  }
};

export const mapSingleIngridientsForOneStage = (stageFormDataFeeds, feedingProgram) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    stageFormDataFeeds.singleIngredients &&
    Array.isArray(stageFormDataFeeds.singleIngredients)
  ) {
    feedingProgram.singleIngredientUses = [];
    stageFormDataFeeds.singleIngredients?.forEach((oneFeedItem) => {
      if (
        oneFeedItem.kgPerAnimal ||
        oneFeedItem.kgPerAnimal === 0 ||
        oneFeedItem.origin !== oneFeedItem.origin_old
      ) {
        const oneSingleIngredientInput = {
          ingredient: {
            id: oneFeedItem.id,
            name: oneFeedItem.feedType,
          },
         
        };

        // set origin only if different than the baseline's to avoid copying baseline value
        // needed when baseline is changed and then interventions area being recalculated
        if (oneFeedItem.origin !== oneFeedItem.origin_old)
          oneSingleIngredientInput.ingredient.origin = oneFeedItem.origin;

        if (oneFeedItem.kgPerAnimal || oneFeedItem.kgPerAnimal === 0) {
          oneSingleIngredientInput.quantity = {};
          setInterventionFloatValue(
            oneSingleIngredientInput,
            'quantity',
            oneFeedItem,
            'kgPerAnimal',
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass
          );
        }

        feedingProgram.singleIngredientUses.push(
          oneSingleIngredientInput
        );
      }
    });
  }

  // newly added single ingredients
  if (
    stageFormDataFeeds.singleIngredientsAdditions &&
    Array.isArray(stageFormDataFeeds.singleIngredientsAdditions)
  ) {
    feedingProgram.singleIngredientUsesAdditions = [];

    stageFormDataFeeds.singleIngredientsAdditions?.forEach((addnlFeedItem) => {
      if (addnlFeedItem.kgPerAnimal) {
        const value = floatValueInDefaultUnit(
          addnlFeedItem.kgPerAnimal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );

        const oneSingleIngredientInput = {
          ingredient: {
            id: addnlFeedItem.id || uuidv4(),
            name: addnlFeedItem.feedType,
            origin: addnlFeedItem.origin,
          },
          quantity: value,
        };

        feedingProgram.singleIngredientUsesAdditions.push(
          oneSingleIngredientInput
        );
      }
    });
  }
};

const mapFeedingProgramForOneStage = (stageFormDataFeeds, stageData) => {
  mapCompoundFeedForOneStage(stageFormDataFeeds, stageData.feedingProgram);
  mapSingleIngridientsForOneStage(stageFormDataFeeds, stageData.feedingProgram);
};

const mapEmissionForOneStage = (stageDataFormEmission, stageDataEmission) => {
  // check the emission fields
  emissionFieldsMapOutV2.forEach((value, key) => {
    if (
      key in stageDataFormEmission &&
      (stageDataFormEmission[key] || stageDataFormEmission[key] === 0)
    ) {
      // originally the change_type was directly set to percentage to send to blonk
      // now emisssion interventions also has set, absolute, and relative option
      const valueEmission = parseFloat(stageDataFormEmission[key]);
      const changeType = stageDataFormEmission[`${key}_changeMetric`];
      const emissionObj = {
        emissionType: value,
        change: {
          value: valueEmission,
          change_type: changeType || 'percentage',
        },
      };
      stageDataEmission.push(emissionObj);
    }
  });
};

const mapStageData = (formData, payloadObj) => {
  if (formData.stages && Array.isArray(formData.stages)) {
    const {stages} = payloadObj;
    formData.stages.forEach((formDataOneStage) => {
      let stageFullData;
      if (formDataOneStage.type === StageType.Processing) {
        stageFullData = mapOutProcessingStage(formDataOneStage);
      } else {
        const formStageData = formDataOneStage.stageData;
        stageFullData = {
          id: formDataOneStage.id,
          name: formDataOneStage.name,
          type: formDataOneStage.type,
          startDate: formDataOneStage.startDate,
          endDate: formDataOneStage.endDate,
          stageData: {
            animalProductivity: productivity,
            feedingProgram: {
              compoundFeedUses: [],
              singleIngredientUses: [],
            },
            emissionMitigations: [],
          },
        };
  
        const { stageData } = stageFullData;
  
        if (formStageData?.feed)
          mapFeedingProgramForOneStage(formStageData.feed, stageData);
        if (formStageData?.emissions)
          mapEmissionForOneStage(
            formStageData.emissions,
            stageData.emissionMitigations
          );
      }
      

      stages.push(stageFullData);
    });
  }
};

export const mapCommonInterventionDataOutSustell = (formData) => {
  const payloadObj = createCorePayload(formData);
  mapStageData(formData, payloadObj);
  return payloadObj;
};
