/* eslint-disable no-underscore-dangle */
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useFormContext, Controller, DeepMap, FieldError } from 'react-hook-form';
import {
  Divider,
  Box,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import _ from 'lodash';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import {
  unitLong,
  defaultUnits,
  explicitConvertValue,
} from '../../../../utils/unit-utils';
import ReactHookFormCheckbox from '../../../../../modules/Helpers/ReactHookFormCheckbox';
import { getObjectPropByPath } from '../../../../utils/obj-utils';
import ReactHookPreviewField from '../../../../../modules/Helpers/ReactHookPreviewField';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import calculate3NOPForBovaer, { InputData3NOP } from '../../../../utils/calculation-utils';
import { FormType } from '../../common';
import { Maybe } from '../../../../../../graphql/types';
import { DairyBaseline, LatestCalc3NOP, Supplement3NOP } from '../../../../models/Baseline/DairyBaseline';

interface SustellBovaerInputProps {
  name: string;
  // control: Control<Record<string, any>>;
  disabled: boolean;
  formType: FormType;
  animalType: 'DAIRY';
  country: string;
  itemIndex: number;
  // eslint-disable-next-line react/require-default-props
  latestBovaerCalc?: Maybe<LatestCalc3NOP>;
  setLatestBovaerCalc: (latestBovaerCalc?: SetStateAction<LatestCalc3NOP | null>) => void;
}

const SustellBovaerInput: FC<SustellBovaerInputProps> = ({
  name,
  disabled,
  // control,
  formType,
  animalType,
  country,
  itemIndex,
  latestBovaerCalc=undefined,
  setLatestBovaerCalc,
}) => {
  const intl = useIntl();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const unitPerAnimal = userUOM?.unitBarnSmallQuantityUnits
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ? userUOM.unitBarnSmallQuantityUnits
    : defaultUnits.unitBarnSmallQuantityUnits;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ? userUOM.unitBarnOutputMass
    : defaultUnits.unitBarnOutputMass;

  const validationObject: any = {
    dryMatterIntakeKg: { min: 7, max: 40, unit: 'kg' },
    ndfPercentageOfDmi: { min: 20, max: 50, unit: '%' },
    fatPercentageInDmi: { min: 0, max: 6, unit: '%' },
    dosage: { min: 60, max: 90, unit: 'g' },
    numberOfAnimals: { min: 1, max: 3000, unit: 'num' },
  }
    
  const showRanges = (field: string) => {
    let min: number = validationObject[field]['min'];
    let max: number = validationObject[field]['max'];
    if (field === 'dosage' && validationObject['dosage']['unit'] !== userUOM?.unitBarnSmallQuantityUnits) {
      min = Number(explicitConvertValue(min, validationObject['dosage']['unit'], userUOM?.unitBarnSmallQuantityUnits)?.toFixed(4));
      max = Number(explicitConvertValue(max, validationObject['dosage']['unit'], userUOM?.unitBarnSmallQuantityUnits)?.toFixed(4));
    }
    if (field === 'dryMatterIntakeKg' && validationObject['dryMatterIntakeKg']['unit'] !== userUOM?.unitBarnOutputMass) {
      min = Number(explicitConvertValue(min, validationObject['dryMatterIntakeKg']['unit'], userUOM?.unitBarnOutputMass)?.toFixed(4));
      max = Number(explicitConvertValue(max, validationObject['dryMatterIntakeKg']['unit'], userUOM?.unitBarnOutputMass)?.toFixed(4));
    }
    if (field === 'dosage') return `${min}, <${max+1} - scaled value used (formula: (dose in g) * 1000 / (DMI in kg))`
    return `${min}, <${max+1}`
  }

  // used for the (general) calculation errors returned by the API
  const [calcError, setCalcError] = useState('');
  const [loading, setLoading] = useState(false);
  // save latest calculation to inform user to recalculate if inputs change

  const fullFieldNames: {[key: string]: string}  = {
    is3NOPSelected: `${name}.is3NOPSelected`,
    _3nopDosePerCowPerDay: `${name}.supplement3NOP._3nopDosePerCowPerDay`,
    dmiAmountPerKg: `${name}.supplement3NOP.dmiAmountPerKg`,
    fatAmountPercentage: `${name}.supplement3NOP.fatAmountPercentage`,
    ndfAmountPercentage: `${name}.supplement3NOP.ndfAmountPercentage`,
    numberOfCowsCovered: `${name}.supplement3NOP.numberOfCowsCovered`,
    calculatedResult: `${name}.supplement3NOP.calculatedResult`,
  };

  const boveareValidationFullFieldNames: {[key: string]: string} = {
    dryMatterIntakeKg: `${name}.supplement3NOP.dmiAmountPerKg`,
    ndfPercentageOfDmi: `${name}.supplement3NOP.ndfAmountPercentage`,
    fatPercentageInDmi: `${name}.supplement3NOP.fatAmountPercentage`,
    dosage: `${name}.supplement3NOP._3nopDosePerCowPerDay`,
    numberOfAnimals: `${name}.supplement3NOP.numberOfCowsCovered`,
  };

  const avgAnimalName = `stages.${itemIndex}.stageData.manure.averageAnimalsPresent`;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    getValues,
    setValue,
    trigger,
    control,
    formState: reactFormState,
  } = useFormContext<DairyBaseline>();

  const [checked, setChecked] = useState<boolean>(
    getValues(fullFieldNames.is3NOPSelected)|| false
  );

  const toNum = (val: string | number) => (typeof val !== 'number' ? parseFloat(val) : val);

  const calculateValues = async (
    dosage: number | string,
    fatPercentageInDmi: number | string,
    ndfPercentageOfDmi: number | string,
    numberOfAnimals: number | string,
    dryMatterIntakeKg: number | string,
    totalNumberOfAnimals: number | string,
  ) => {
    const input = {
      animalType,
      country,
      ndfPercentageOfDmi,
      numberOfAnimals,
      dryMatterIntakeKg,
    } as {[key: string]: number | string | undefined | null};

    // if mandatory fields not set, skip calc
    if (
      !input.country ||
      (!input.ndfPercentageOfDmi && input.ndfPercentageOfDmi !== 0) ||
      (!input.numberOfAnimals && input.numberOfAnimals !== 0) ||
      (!input.dryMatterIntakeKg && input.dryMatterIntakeKg !== 0)
    )
      return null;

    // check optiona fileds
    if (dosage || dosage === 0) input.dosage = dosage;
    if (fatPercentageInDmi || fatPercentageInDmi === 0) {
      if (typeof fatPercentageInDmi === 'string') {
        input.fatPercentageInDmi = parseFloat(fatPercentageInDmi);
      }
      else {
        input.fatPercentageInDmi = fatPercentageInDmi;
      }
    }

    // If needed convert ot g
    if (
      (input.dosage || input.dosage === 0) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM?.unitBarnSmallQuantityUnits &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM?.unitBarnSmallQuantityUnits !==
        defaultUnits.unitBarnSmallQuantityUnits
    ) {
      input.dosage = 
        explicitConvertValue(
          toNum(input.dosage),
          unitPerAnimal as string,
          defaultUnits.unitBarnSmallQuantityUnits
        )?.toFixed(4);
    }
    // If needed convert to kg
    if (
      (input.dryMatterIntakeKg || input.dryMatterIntakeKg === 0) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM?.unitBarnOutputMass &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM?.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass
    ) {
      input.dryMatterIntakeKg = explicitConvertValue(
          toNum(input.dryMatterIntakeKg),
          barnOutputMassUnit as string,
          defaultUnits.unitBarnOutputMass
        )?.toFixed(4)
    }
    const result = await calculate3NOPForBovaer(input as unknown as InputData3NOP, toNum(totalNumberOfAnimals));
    return result;
  };

  const clearAllCaclulationErrors = async () => {
    const errorFields = [];

    // clear remote calc related errors, if any for 3NOP fields

    if (getValues(`${fullFieldNames._3nopDosePerCowPerDay  }_error`)) {
      setValue(`${fullFieldNames._3nopDosePerCowPerDay  }_error`, '');
      errorFields.push(fullFieldNames._3nopDosePerCowPerDay);
    }

    if (getValues(`${fullFieldNames.dmiAmountPerKg  }_error`)) {
      setValue(`${fullFieldNames.dmiAmountPerKg  }_error`, '');
      errorFields.push(fullFieldNames.dmiAmountPerKg);
    }

    if (getValues(`${fullFieldNames.fatAmountPercentage  }_error`)) {
      setValue(`${fullFieldNames.fatAmountPercentage  }_error`, '');
      errorFields.push(fullFieldNames.fatAmountPercentage);
    }

    if (getValues(`${fullFieldNames.ndfAmountPercentage  }_error`)) {
      setValue(`${fullFieldNames.ndfAmountPercentage  }_error`, '');
      errorFields.push(fullFieldNames.ndfAmountPercentage);
    }

    if (getValues(`${fullFieldNames.numberOfCowsCovered  }_error`)) {
      setValue(`${fullFieldNames.numberOfCowsCovered  }_error`, '');
      errorFields.push(fullFieldNames.numberOfCowsCovered);
    }

    if (getValues(`${fullFieldNames.calculatedResult  }_error`)) {
      setValue(`${fullFieldNames.calculatedResult  }_error`, '');
      errorFields.push(fullFieldNames.calculatedResult);
    }

    if (errorFields.length > 0) await trigger(errorFields);

    // clear warning message if any
    if (calcError) setCalcError('');
  };

  const processErrors = async (errors?: Maybe<string[]>) => {
    const errorFields: string[] = [];
    // errors/warnings relatet to invalid data - shown as validation errors for ceratin field
    // caclulation perfromed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (errors || []).forEach((error: string) => {
      const indexOfSpace = error.indexOf(' ');

      if (indexOfSpace > -1) {
        // first word in the message is the name of the field
        // rest is the error text
        // get the key for i18n
        let i18nKey = error.substring(indexOfSpace + 1);
        i18nKey =
          `BOVAER.VALIDATION.${  i18nKey.toUpperCase().replace(/ /g, '_')}`;
        const field = error.substring(0, indexOfSpace);
        const message = intl.formatMessage({ id: i18nKey });

        // if field identifyed set filed error
        if (boveareValidationFullFieldNames[field]) {
          setValue(`${boveareValidationFullFieldNames[field]  }_error`, `${message} ${showRanges(field)}`);
          errorFields.push(boveareValidationFullFieldNames[field]);
        } else {
          // otherwise set calculation error
          setValue(
            `${fullFieldNames.calculatedResult  }_error`,
            message || error
          );
          errorFields.push(fullFieldNames.calculatedResult);
        }
      } else {
        setValue(`${fullFieldNames.calculatedResult  }_error`, error);
        errorFields.push(fullFieldNames.calculatedResult);
      }
    });

    if (errorFields.length > 0) await trigger(errorFields);
  };

  // Set custom error field to prevent save without recalulation
  // trigger validation, if !== '' create error
  const showRecalculateError = (show = true) => {
    // eslint-disable-next-line no-void
    void (async () => {
      if (show) {
        setValue(
          `${fullFieldNames.calculatedResult}_recalculate_error`,
          intl.formatMessage({ id: 'BOVAER.ERROR.CALCULATE_AGAIN' })
        );
      } else {
        setValue(`${fullFieldNames.calculatedResult}_recalculate_error`, '');
      }
      await trigger(`${name}.supplement3NOP.calculatedResult_recalculate_error`);
    })();
  };

  const calculateByFieldValues = async () => {
    const bovaerFields = getValues(Object.values(fullFieldNames as object));

    // use values from form, as it's possible that all values in state are not refreshed since on some load baseline can be
    // undefined when state is defined
    const vals = getObjectPropByPath(bovaerFields as object, `${name}.supplement3NOP`) as {[key: string]: string | number};

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const averageAnimalsPresent = toNum(getValues(avgAnimalName));

    // clear errors from the previous calculation
    await clearAllCaclulationErrors();

    if (vals === null) {
      return null;
    }

    try {
      Object.keys(vals).forEach(k => {
        if (typeof vals[k] !== 'number') {
          vals[k] = toNum(vals[k]);
        }
      });
      const res= await calculateValues(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        vals._3nopDosePerCowPerDay, vals.fatAmountPercentage, vals.ndfAmountPercentage, vals.numberOfCowsCovered,  vals.dmiAmountPerKg,
        averageAnimalsPresent
      );
      // set fields (validation) errors, returned by the caclulation engine
      const calcInfo = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        input: { ...vals },
        result: res?.data?.calculateBovaer3NOP,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      delete calcInfo.input.calculatedResult;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      calcInfo.input.averageAnimalsPresent = toNum(averageAnimalsPresent as string | number);
      setLatestBovaerCalc(calcInfo);
      // remove changed indicator
      showRecalculateError(false);

      // set fields (validation) errors, returned by the caclulation engine
      await processErrors(res?.data?.calculateBovaer3NOP?.warnings);
      return res?.data?.calculateBovaer3NOP?.reductionPercentage;
    } catch (err) {
      console.log('Calc error', err);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      if (err?.errors && err?.errors?.length > 0) setCalcError(err.errors[0]?.message);

      return null;
    }
  };

  const validateFields = async () => {
    // trigger yup validation
    await trigger([
      avgAnimalName,
      `${name}.supplement3NOP.numberOfCowsCovered`,
      `${name}.supplement3NOP.dmiAmountPerKg`,
      `${name}.supplement3NOP.ndfAmountPercentage`,
      `${name}.supplement3NOP.fatAmountPercentage`,
      `${name}.supplement3NOP._3nopDosePerCowPerDay`,
    ]);

    // if anay of field used for the calculation has an error and if error is not from the bovaer 3NOP remote calc
    // then a new calculation can be done

    const validationErrors = reactFormState?.errors;
    // console.log("Errors", JSON.stringify(validationErrors, null, 2))

    // no barn fields errors
    if (!validationErrors?.stages) return true;

    const errors = validationErrors?.stages[itemIndex];

    if (!errors) return true;

    if (errors?.stageData?.manure?.averageAnimalsPresent) return false;

    // Needed to cast this, because in formContext when type for errors is created the DeepMap type does
    // not go beyond possible null in types and we have optional emissions and 3NOP fields
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const bovaerErrors = (errors?.stageData?.emissions as any)?.supplement3NOP  as DeepMap<Supplement3NOP, FieldError>;
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      bovaerErrors?._3nopDosePerCowPerDay &&
      bovaerErrors?._3nopDosePerCowPerDay
        ?.type !== 'bovaerCalculationError'
    )
      return false;

    if (
      bovaerErrors?.dmiAmountPerKg &&
      bovaerErrors?.dmiAmountPerKg?.type !==
        'bovaerCalculationError'
    )
      return false;

    if (
      bovaerErrors?.fatAmountPercentage &&
      bovaerErrors?.fatAmountPercentage
        ?.type !== 'bovaerCalculationError'
    )
      return false;

    if (
      bovaerErrors?.ndfAmountPercentage &&
      bovaerErrors?.ndfAmountPercentage
        ?.type !== 'bovaerCalculationError'
    )
      return false;

    if (
      bovaerErrors?.numberOfCowsCovered &&
      bovaerErrors?.numberOfCowsCovered
        ?.type !== 'bovaerCalculationError'
    )
      return false;

    return true;
  };

  const validateAndCalculate = async () => {
    // clear all results
    setValue(fullFieldNames.calculatedResult, '');

    const validationOK = await validateFields();
    if (!validationOK) {
      return;
    }
    setLoading(true);
    const result = await calculateByFieldValues();
    setValue(fullFieldNames.calculatedResult, result);
    setLoading(false);
    await trigger(fullFieldNames.calculatedResult);
  };

  const getCurrentInputValues = () => {
    const bovaerFields: {[key: string]: number} = getValues(
      Object.values(boveareValidationFullFieldNames as object)
    );
    const currentValues = getObjectPropByPath(
      bovaerFields,
      `${name}.supplement3NOP`
    ) as {[key: string]: number | string} ;
    currentValues.averageAnimalsPresent = getValues(avgAnimalName);
    Object.keys(currentValues).forEach((key) => {
      currentValues[key] = toNum(currentValues[key]);
    });
    return currentValues;
  };

  const inputParamChanges = async () => {
    const currentValues = getCurrentInputValues();

    // Check if params are changed after the calc, don't check if its first time
    if (
      !_.isEqual(currentValues, latestBovaerCalc?.input) &&
      latestBovaerCalc
    ) {
      showRecalculateError();
    } else {
      showRecalculateError(false);
    }
  };

  // Only once trigger to check for changes on first render
  useEffect(() => {
    // eslint-disable-next-line no-void
    void inputParamChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setValue(`${name}.is3NOPSelected`, isChecked);
    setChecked(isChecked);
    showRecalculateError(isChecked);
  };

  const calculate = async () => {
    // needed to triger validation, before invoking caclulation
    await validateAndCalculate();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReactHookFormCheckbox
          name={fullFieldNames.is3NOPSelected}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.TITLE',
          })}
          variant="outlined"
          onChange={onSelectionChanged}
          disabled={formType === 'view'}
          defaultValue={getValues(fullFieldNames.is3NOPSelected)} 
          control={undefined} 
          type={undefined} 
          onBlur={undefined}
          value={undefined}        />
        {checked && (
          <Grid>
            {calcError && (
              <Alert severity="warning">
                <AlertTitle>
                  {intl.formatMessage({
                    id: 'BOVAER.VALIDATION.CALCULATION_PROBLEM',
                  })}{' '}
                </AlertTitle>
                {calcError}
              </Alert>
            )}
            <RowTextFieldWithInfo
              name={fullFieldNames._3nopDosePerCowPerDay}
              label={
                `${intl.formatMessage(
                  { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP_DOSE.LABEL' },
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  { unit: unitPerAnimal }
                )  }*`
              }
              margin="none"
              control={control}
              addornment={intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP_DOSE.ADDORNMENT' },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                { unit: unitPerAnimal }
              )}
              disabled={disabled}
              onChange={() =>
                inputParamChanges()
              }
              tooltip={intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP_DOSE_TT' },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                { unit: unitPerAnimal }
              )}
              defaultValue={getValues(fullFieldNames._3nopDosePerCowPerDay)}
            />
            <Controller
              control={control}
              name={`${fullFieldNames._3nopDosePerCowPerDay  }_error`}
              defaultValue=""
              // eslint-disable-next-line @typescript-eslint/no-shadow
              render={({ value, name }) => <input type="hidden" name={name} value={value as string | undefined} />}
            />
            <RowTextFieldWithInfo
              name={fullFieldNames.dmiAmountPerKg}
              label={
                `${intl.formatMessage(
                  { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI' },
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  { unit: unitLong(barnOutputMassUnit) }
                )  }*`
              }
              margin="none"
              control={control}
              addornment={intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI.ADDORNMENT' },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                { unit: unitLong(barnOutputMassUnit) }
              )}
              disabled={disabled}
              onChange={() =>inputParamChanges()}
              tooltip={intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI_TT' },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                { unit: unitLong(barnOutputMassUnit) }
              )}
              defaultValue={getValues(fullFieldNames.dmiAmountPerKg)}
            />
            <Controller
              control={control}
              name={`${fullFieldNames.dmiAmountPerKg  }_error`}
              defaultValue=""
              // eslint-disable-next-line @typescript-eslint/no-shadow
              render={({ value, name }) => <input type="hidden" name={name} value={value as string | undefined} />}
            />
            <RowTextFieldWithInfo
              name={fullFieldNames.fatAmountPercentage}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI_FAT',
              })}
              margin="none"
              control={control}
              addornment={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI_FAT_ADDONMENT',
              })}
              disabled={disabled}
              onChange={() =>
                inputParamChanges()
              }
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI_FAT_TT',
              })}
              defaultValue={getValues(fullFieldNames.fatAmountPercentage)}
            />
            <Controller
              control={control}
              name={`${fullFieldNames.fatAmountPercentage  }_error`}
              defaultValue=""
              // eslint-disable-next-line @typescript-eslint/no-shadow
              render={({ value, name }) => <input type="hidden" name={name} value={value as string | undefined} />}
            />
            <RowTextFieldWithInfo
              name={fullFieldNames.ndfAmountPercentage}
              label={
                `${intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NDF',
                })  }*`
              }
              margin="none"
              control={control}
              addornment={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NDF_ADDORNMENT',
              })}
              disabled={disabled}
              onChange={() =>
                inputParamChanges()
              }
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NDF_TT',
              })}
              defaultValue={getValues(fullFieldNames.ndfAmountPercentage)}
            />
            <Controller
              control={control}
              name={`${fullFieldNames.ndfAmountPercentage  }_error`}
              defaultValue=""
              // eslint-disable-next-line @typescript-eslint/no-shadow
              render={({ value, name }) => <input type="hidden" name={name} value={value as string | undefined} />}
            />
            <RowTextFieldWithInfo
              name={fullFieldNames.numberOfCowsCovered}
              label={
                `${intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NUM_OF_COWS',
                })  }*`
              }
              margin="none"
              control={control}
              addornment={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NUM_OF_COWS_ADDORNEMNT',
              })}
              disabled={disabled}
              onChange={() =>
                inputParamChanges()
              }
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NUM_OF_COWS_TT',
              })}
              defaultValue={getValues(fullFieldNames.numberOfCowsCovered)}
            />
            <Controller
              control={control}
              name={`${fullFieldNames.numberOfCowsCovered  }_error`}
              defaultValue=""
              // eslint-disable-next-line @typescript-eslint/no-shadow
              render={({ value, name }) => <input type="hidden" name={name} value={value as string | undefined} />}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              mr="42px"
              mt="12px"
            >
              <Controller
                control={control}
                name={`${fullFieldNames.calculatedResult  }_recalculate_error`}
                defaultValue=""
                // eslint-disable-next-line @typescript-eslint/no-shadow
                render={({ value, name }) => (
                    <Box pt="6px" color="red">
                      <input type="hidden" name={name} value={value as string | undefined} />
                      {value}
                    </Box>
                  )}
              />
              <Button
                variant="outlined"
                color="secondary"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={() => calculate()}
                disabled={formType === 'view'}
              >
                {intl.formatMessage({ id: 'BOVAER.ERROR.CALCULATE' })}
              </Button>
            </Box>
            <Box style={{ paddingRight: '32px' }}>
              <span>
                {intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.EMMISION_CORRECTION',
                })}
              </span>
              <br />
              <span style={{ paddingLeft: '16px' }}>
                {intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.METHANE_FROM',
                })}
              </span>
              <span style={{ float: 'right' }}>
                <ReactHookPreviewField
                  name={fullFieldNames.calculatedResult}
                  hidden={loading}
                  addornment="%"
                  textAlign="right" 
                  label={undefined}
                  disabled={undefined}
                  margin={undefined}
                  type={undefined}
                  changeHandler={undefined}    
                  />
                {loading && <CircularProgress color="secondary" size="20px" />}
              </span>
              <br />
              <Divider /> <br />
            </Box>
            <Controller
              control={control}
              name={`${fullFieldNames.calculatedResult  }_error`}
              defaultValue=""
              // eslint-disable-next-line @typescript-eslint/no-shadow
              render={({ value, name }) => <input type="hidden" name={name} value={value as string | undefined} />}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SustellBovaerInput;
