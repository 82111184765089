// import { multicastChannel } from '@redux-saga/core';
import * as Yup from 'yup';
import { defaultUnits } from '../../../../sustell_15/utils/unit-utils';

import { interventionValidationResourseUsagePart } from './interventionValidationSchemaGeneralPart';

import {
  interventionFieldWithMin,
  interventionFieldWithMinMax,
  averageLiveWeightMortalitiesFn,
  feedItemsInterventionTest,
  feedAdditionalItemsInterventionTest,
} from './interventionValidationObjectBuilderFunctions';

import { numericOptionalWithGreaterThanMin } from '../../Baseline/validationSchema/validationObjectBuilderFunctions';

// Yup validation schema for intervension form data

const stageDataPartHens = ({ intl, userUOM = defaultUnits, minValueOutput = 1 }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          stageData:Yup.object({
            input: Yup.object( {
              purchasedOneDayChickens: interventionFieldWithMin(
                intl,
                'purchasedOneDayChickens',
                1
              ),
              mortalityRate: interventionFieldWithMinMax(
                intl,
                'mortalityRate',
                0,
                99,
                'VALIDATION.NUMERIC.RANGE_ERROR'
              ),
              averageLiveWeightMortalities: averageLiveWeightMortalitiesFn(
                'Broiler',
                intl,
                userUOM
              ),
            })
            .default({}),
            feed: Yup.object( {
              digestibility: interventionFieldWithMinMax(
                intl,
                'digestibility',
                0,
                100
              ),
              nitrogenContent: interventionFieldWithMinMax(
                intl,
                'nitrogenContent',
                0,
                100
              ),
              dryMatterContent: interventionFieldWithMinMax(
                intl,
                'dryMatterContent',
                0,
                100
              ),
              totalFeedIntake: numericOptionalWithGreaterThanMin(intl, 0),
              compoundFeeds: feedItemsInterventionTest(intl, 0),
              compoundFeedsAdditions: feedAdditionalItemsInterventionTest(intl, 0),
              singleIngredients: feedItemsInterventionTest(intl, 0),
              singleIngredientsAdditions: feedAdditionalItemsInterventionTest(
                intl,
                0
              ),
            })
            .default({}),
            manure: Yup.object( {
              averageAnimalsPresent: interventionFieldWithMin(
                intl,
                'averageAnimalsPresent',
                0
              ),
              manureManagementSystem: Yup.string(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              solidManureStorage: interventionFieldWithMinMax(
                intl,
                'solidManureStorage',
                0,
                100
              ),
              slurryManureStorage: interventionFieldWithMinMax(
                intl,
                'slurryManureStorage',
                0,
                100
              ),
              ashContent: interventionFieldWithMinMax(intl, 'ashContent', 0, 100),
              strawForBedding: interventionFieldWithMinMax(intl, 'strawForBedding', 0, 100),
              sawDust: interventionFieldWithMinMax(intl, 'sawDust', 0, 100),
            })
            .default({}),
            emissions: Yup.object( {
              methane: interventionFieldWithMinMax(intl, 'methane', -100, 100),
              nitrousOxideDirect: interventionFieldWithMinMax(
                intl,
                'nitrousOxideDirect',
                -100,
                100
              ),
              nitrousOxideIndirect: interventionFieldWithMinMax(
                intl,
                'nitrousOxideIndirect',
                -100,
                100
              ),
              amonia: interventionFieldWithMinMax(intl, 'amonia', -100, 100),
              nitricOxide: interventionFieldWithMinMax(
                intl,
                'nitricOxide',
                -100,
                100
              ),
              nonMethaneVolatile: interventionFieldWithMinMax(
                intl,
                'nonMethaneVolatile',
                -100,
                100
              ),
              PM10: interventionFieldWithMinMax(intl, 'PM10', -100, 100),
              PM25: interventionFieldWithMinMax(intl, 'PM25', -100, 100),
              totalSuspendedParticles: interventionFieldWithMinMax(
                intl,
                'totalSuspendedParticles',
                -100,
                100
              ),
            })
            .default({}),
            output: Yup.object( {
              liveWeight: interventionFieldWithMin(intl, 'liveWeight', minValueOutput),
            })
            .default({})
          }
          )
          .default({})
        })
      )
  }); 

// merge all necessary parts to baseSchema

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const combinedSchema = Yup.object({info:baseSchema})
   .concat(Yup.object({resourceUse:interventionValidationResourseUsagePart(confObj)}))
   .concat(stageDataPartHens(confObj))
  return combinedSchema;
};

export default assembleValidationSchemaSustell;