import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonInterventionDataIn,
  interventionFloatValueInUserUnits,
} from './inCommonMapperSustell';

const math = require('mathjs');

const mapPigFatteningInterventionDataInSustell = (inData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();
  const formData = mapCommonInterventionDataIn(inData);

  inData.stages.forEach( (stage, index) => {
    const stageData = JSON.parse(stage.stageData) || {};
    if(formData.stages[index]){
      const stageOutputFormPart = formData.stages[index]?.stageData?.output;
      if(stageOutputFormPart){
        if (
          stageData?.fattened_pigs?.total_weight?.value ||
          stageData?.fattened_pigs?.total_weight?.value === 0
        ) {
          let liveWeight = stageData?.fattened_pigs?.total_weight?.value;
          const changeMetric = stageData?.fattened_pigs?.total_weight.change_type || 'relative';
          liveWeight = interventionFloatValueInUserUnits(
          liveWeight,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          changeMetric,
          outputMassPrecision
          );
          stageOutputFormPart.liveWeight = liveWeight;
          stageOutputFormPart.liveWeight_changeMetric = changeMetric;
        }

        if (
          stageData?.inorganic_n_fertilizer_replacement_rate?.value ||
          stageData?.inorganic_n_fertilizer_replacement_rate?.value === 0
        ) {
          stageOutputFormPart.inorganicNFertilizerReplacementRate = math.round(
            stageData.inorganic_n_fertilizer_replacement_rate.value,
            2
          );
          stageOutputFormPart.inorganicNFertilizerReplacementRate_changeMetric =
            stageData?.inorganic_n_fertilizer_replacement_rate?.change_type ||
            'relative';
        }

        if (
          stageData?.inorganic_p_fertilizer_replacement_rate?.value ||
          stageData?.inorganic_p_fertilizer_replacement_rate?.value === 0
        ) {
          stageOutputFormPart.inorganicPFertilizerReplacementRate = math.round(
            stageData.inorganic_p_fertilizer_replacement_rate.value,
            2
          );
          stageOutputFormPart.inorganicPFertilizerReplacementRate_changeMetric =
            stageData?.inorganic_p_fertilizer_replacement_rate?.change_type ||
            'relative';
        }
      }

      // map input data
      const stageInputFormPart = formData.stages[index]?.stageData?.input;
      if(stageInputFormPart){
        if (
          stageData?.purchased_piglets?.number_of_animals?.value ||
          stageData?.purchased_piglets?.number_of_animals?.value === 0
        ) {
          stageInputFormPart.purchasedPiglets =
            stageData?.purchased_piglets?.number_of_animals?.value;
          stageInputFormPart.purchasedPiglets_changeMetric =
            stageData?.purchased_piglets?.number_of_animals
              ?.change_type || 'relative';
        }
  
        if (
          stageData?.purchased_piglets?.average_live_weight?.value ||
          stageData?.purchased_piglets?.average_live_weight?.value === 0
        ) {
          let value =
            stageData.purchased_piglets.average_live_weight.value;
          const avgLiveWeightChangeMetric =
            stageData?.purchased_piglets?.average_live_weight
              ?.change_type || 'relative';
          value = interventionFloatValueInUserUnits(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            avgLiveWeightChangeMetric,
            precision
          );
          stageInputFormPart.averageLiveWeight = value;
          stageInputFormPart.averageLiveWeight_changeMetric = avgLiveWeightChangeMetric;
        }
  
        stageInputFormPart.mortalityRate =
          stageData?.mortality?.mortality_rate?.value;
        stageInputFormPart.mortalityRate_changeMetric =
          stageData?.mortality?.mortality_rate?.change_type ||
          'relative';
  
        let avgMorVal =
          stageData?.mortality?.average_live_weight_mortalities?.value;
        const chgMetric =
          stageData?.mortality?.average_live_weight_mortalities?.change_type;
        avgMorVal = interventionFloatValueInUserUnits(
          avgMorVal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          chgMetric,
          precision
        );
  
        stageInputFormPart.averageLiveWeightMortalities = avgMorVal;
        stageInputFormPart.averageLiveWeightMortalities_changeMetric =
          chgMetric || 'relative';
      }

      formData.stages[index].productionSystem = stageData?.housing_description?.housing_type;
    }
  });

  

  return formData;
};

export default mapPigFatteningInterventionDataInSustell
