import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { Maybe } from '../../../../../../graphql/types';
import { beefAshContentValue } from '../../baselineDefaultValues';

interface BeefFeedFreshGrassComponentProps {
  fieldItemPrefix: string;
  formType: FormType;
  itemIndex: number;
  // eslint-disable-next-line react/require-default-props
  removeHandler?: Maybe<(index: number) => void>;
  isTargetAnimalCalves: boolean;
}

const BeefFeedFreshGrassComponent: FC<BeefFeedFreshGrassComponentProps> = ({
  fieldItemPrefix,
  formType,
  itemIndex,
  removeHandler,
  isTargetAnimalCalves,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldPrefix = `${fieldItemPrefix}.${itemIndex}`;
  const formContext = useFormContext();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const compoundFeedElectricityUnit = unitLong(
    userUOM.unitCompoundFeedElectricity as string
  );
  const freshGrassTypeWatch = useWatch({
    name: `${fieldPrefix}.type`,
  });

  return (
    <div
      style={{
        border: '1px solid var(--dsm-color-neutral-lighter)',
        padding: '16px 16px 0px 16px',
        marginBottom: '16px',
      }}
    >
      {formType !== FormType.View &&
        removeHandler &&
        itemIndex !== undefined &&
        itemIndex !== null &&
        itemIndex !== 0 && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmInput
            type="text"
            name={`${fieldPrefix}.type`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS_NAME',
            })}
            tooltip={intl.formatMessage({
              id: isTargetAnimalCalves 
                ? 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS_NAME.CALVES.TOOLTIP' 
                : 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS_NAME.TOOLTIP',
            })}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS_NAME.ADORNMENT',
            })}
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldPrefix}.type`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldPrefix}.amount`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.AMOUNT',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.AMOUNT.TOOLTIP',
            })}
            adornment={barnOutputMassUnit}
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldPrefix}.amount`) as string
            }
            required={!!freshGrassTypeWatch}
          />
          {freshGrassTypeWatch && (
            <ReactHookDsmInput
              type="number"
              name={`${fieldPrefix}.digestibility`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.DIGESTIBILITY',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.DIGESTIBILITY.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.DIGESTIBILITY.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS.DIGESTIBILITY.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(`${fieldPrefix}.digestibility`) as string
              }
              required
            />
          )}
          {freshGrassTypeWatch && (
            <ReactHookDsmInput
              type="number"
              name={`${fieldPrefix}.grossEnergyContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.GROSS_ENERGY_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.GROSS_ENERGY_CONTENT.TOOLTIP',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.BEEF.FEED.GROSS_ENERGY_CONTENT.ADORNMENT',
                }
              )}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS.GROSS_ENERGY_CONTENT.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(
                  `${fieldPrefix}.grossEnergyContent`
                ) as string
              }
            />
          )}
        </DsmGrid>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          {freshGrassTypeWatch && (
            <ReactHookDsmInput
              type="number"
              name={`${fieldPrefix}.crudeProteinContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.CRUDE_PROTEIN_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.CRUDE_PROTEIN_CONTENT.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.CRUDE_PROTEIN_CONTENT.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS.CRUDE_PROTEIN_CONTENT.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(
                  `${fieldPrefix}.crudeProteinContent`
                ) as string
              }
              required
            />
          )}
          {freshGrassTypeWatch && (
            <ReactHookDsmInput
              type="number"
              name={`${fieldPrefix}.dryMatterContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.DRY_MATTER_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.DRY_MATTER_CONTENT.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.DRY_MATTER_CONTENT.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS.DRY_MATTER_CONTENT.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(
                  `${fieldPrefix}.dryMatterContent`
                ) as string
              }
              required
            />
          )}
          {freshGrassTypeWatch && (
            <ReactHookDsmInput
              type="number"
              name={`${fieldPrefix}.ashContent`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.ASH_CONTENT',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.ASH_CONTENT.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.ASH_CONTENT.ADORNMENT',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.FEED.FRESH_GRASS.ASH_CONTENT.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              defaultValue={
                formContext.getValues(`${fieldPrefix}.ashContent`) as string || String(beefAshContentValue)
              }
            />
          )}
        </DsmGrid>
      </DsmGrid>
    </div>
  );
};

export default BeefFeedFreshGrassComponent;
