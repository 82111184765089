import { BeddingType, BreedingHousingType, GrowingHousingType, LayingHousingType, ManureManagementSystemType } from "../../../../graphql/generated/blonk/poultry";
import { Maybe } from "../../../../graphql/types";
import { Baseline, BaselineFeed, BaselineFeedWithOrigin, BaselinePropertyValue, ResourceUseV2 } from "./Baseline";

export interface Stage {
  id: string,
  farmId: string,
  name: string,
  type: string,
  productionSystem: string,
}

export interface InternalSource {
  id: BaselinePropertyValue;
  farmId: BaselinePropertyValue;
  farmName: BaselinePropertyValue;
  originStageId: BaselinePropertyValue;
  stageName: BaselinePropertyValue;
  originAnimalType: BaselinePropertyValue;
  stageType: BaselinePropertyValue;
  numberAnimals: BaselinePropertyValue;
  averageWeight: BaselinePropertyValue;
  transportDistance?: Maybe<BaselinePropertyValue>;
}

export interface ExternalSource {
  id: BaselinePropertyValue;
  origin: BaselinePropertyValue;
  numberAnimals: BaselinePropertyValue;
  averageWeight: BaselinePropertyValue;
  transportDistance: Maybe<BaselinePropertyValue>;
}

export interface BeddingSystem {
  beddingType: BeddingType;
  beddingAmount: BaselinePropertyValue;
}

export interface ManureManagementSystem {
  id: string;
  mmsType: ManureManagementSystemType | string;
  mmsHoldingDuration?: Maybe<ManureManagementSystemType>;
  share: BaselinePropertyValue;
}

export interface PoultryInputBreeding {
  startDate: BaselinePropertyValue;
  endDate: BaselinePropertyValue;
  henInternalSource?: Maybe<InternalSource>;
  henExternalSource?: Maybe<ExternalSource>;
  roosterInternalSource?: Maybe<InternalSource>;
  roosterExternalSource?: Maybe<ExternalSource>;
}

export interface PoultryInputGrowing {
  startDate: BaselinePropertyValue;
  endDate: BaselinePropertyValue;
  durationOfProductionCycle: BaselinePropertyValue;
  emptyPeriod?: Maybe<BaselinePropertyValue>;
  animalsPresentAtStart?: Maybe<BaselinePropertyValue>;
  animalsPresentAtEnd?: Maybe<BaselinePropertyValue>;
  averageWeightAtStart?: Maybe<BaselinePropertyValue>;
  averageWeightAtEnd?: Maybe<BaselinePropertyValue>;
  internalSelected?: Maybe<BaselinePropertyValue>;
  externalSelected?: Maybe<BaselinePropertyValue>;
  internalSources: InternalSource[];
  externalSources: ExternalSource[];
}

export interface PoultryInputLaying {
  startDate: BaselinePropertyValue;
  endDate: BaselinePropertyValue;
  internalSource?: Maybe<InternalSource>;
  externalSource?: Maybe<ExternalSource>;
}

export interface PoultryInputHatching {
  startDate: BaselinePropertyValue;
  endDate: BaselinePropertyValue;
  eggsPresentAtStart?: Maybe<BaselinePropertyValue>;
  eggsPresentAtEnd?: Maybe<BaselinePropertyValue>;
  internalSource?: Maybe<InternalSource>;
  externalSource?: Maybe<ExternalSource>;
}

export interface PoultryBreedingFeed {
  henFeed: PoultryFeed;
  roosterFeed: PoultryFeed
}

export interface PoultryFeed {
  compoundFeeds?: Maybe<BaselineFeed[]>;
  singleIngredients?: Maybe<BaselineFeedWithOrigin[]>;
}

export interface PoultryHousingAndManure extends ResourceUseV2 {
  housingType: GrowingHousingType | LayingHousingType | BreedingHousingType;
  timeInFreeRange?: Maybe<BaselinePropertyValue>;
  timeInHousing?: Maybe<BaselinePropertyValue>;
  manureSystems: ManureManagementSystem[];
  beddingSystems: BeddingSystem[];
}

export interface PoultryOutputBreeding {
  totalEggsToStage: BaselinePropertyValue;
  averageWeight: BaselinePropertyValue;
  totalEggsSold: BaselinePropertyValue;
  priceEggs?: Maybe<BaselinePropertyValue>;
  totalHensSold: BaselinePropertyValue;
  averageHensWeight: BaselinePropertyValue;
  mortalityHensAtPercent: BaselinePropertyValue;
  weightHensAtMortality?: Maybe<BaselinePropertyValue>;
  totalRoostersSold: BaselinePropertyValue;
  averageRoostersWeight: BaselinePropertyValue;
  mortalityRoostersAtPercent: BaselinePropertyValue;
  weightRoostersAtMortality?: Maybe<BaselinePropertyValue>;
  price?: Maybe<BaselinePropertyValue>;
}

export interface PoultryOutputGrowing {
  totalToStage: BaselinePropertyValue;
  totalSold: BaselinePropertyValue;
  averageWeight: BaselinePropertyValue;
  mortalityAtPercent: BaselinePropertyValue;
  weightAtMortality?: Maybe<BaselinePropertyValue>;
}

export interface PoultryOutputLaying {
  totalSold: BaselinePropertyValue;
  averageWeight: BaselinePropertyValue;
  priceEggs?: Maybe<BaselinePropertyValue>;
  totalHensToStage: BaselinePropertyValue;
  averageHensWeight: BaselinePropertyValue;
  priceHens?: Maybe<BaselinePropertyValue>;
  mortalityAtPercent: BaselinePropertyValue;
  weightAtMortality?: Maybe<BaselinePropertyValue>;
}

export interface PoultryOutputHatching {
  totalToStage: BaselinePropertyValue;
  averageWeight: BaselinePropertyValue;
  totalSold: BaselinePropertyValue;
  price?: Maybe<BaselinePropertyValue>;
  lossRate: BaselinePropertyValue;
  priceOfLossesSold?:  Maybe<BaselinePropertyValue>;
  totalEggShellsSold?:  Maybe<BaselinePropertyValue>;
}

export interface PoultryEmission {
  methane?: Maybe<BaselinePropertyValue>;
  nitrousOxideDirect?: Maybe<BaselinePropertyValue>;
  nitrousOxideIndirect?: Maybe<BaselinePropertyValue>;
  amonia?: Maybe<BaselinePropertyValue>;
  nitricOxide?: Maybe<BaselinePropertyValue>;
  nonMethaneVolatile?: Maybe<BaselinePropertyValue>;
  PM10?: Maybe<BaselinePropertyValue>;
  PM25?: Maybe<BaselinePropertyValue>;
  totalSuspendedParticles?: Maybe<BaselinePropertyValue>;
}

export interface PoultryStageData {  
  emissions?: PoultryEmission;
}

export interface PoultryBreedingStageData extends PoultryStageData {
  input: PoultryInputBreeding;
  feed: PoultryBreedingFeed;
  housing: PoultryHousingAndManure;
  output: PoultryOutputBreeding;
}

export interface PoultryGrowingStageData extends PoultryStageData {
  input: PoultryInputGrowing;
  feed: PoultryFeed;
  housing: PoultryHousingAndManure;
  output: PoultryOutputGrowing;
}

export interface PoultryLayingStageData extends PoultryStageData {
  input: PoultryInputLaying;
  feed: PoultryFeed;
  housing: PoultryHousingAndManure;
  output: PoultryOutputLaying;
}

export interface PoultryHatchingStageData extends PoultryStageData {
  input: PoultryInputHatching;
  output: PoultryOutputHatching;
  housing: ResourceUseV2;
}

export interface PoultryStage extends Stage {
  stageData: PoultryGrowingStageData | PoultryLayingStageData | PoultryBreedingStageData | PoultryHatchingStageData;
}

export interface PoultryBaseline extends Baseline {
  stages: PoultryStage[];
}

export enum LayingManureManagementSystemType {
  /**  Aerobic treatment; Forced aeration systems */
  AerobicForced = 'AEROBIC_FORCED',
  /**  Aerobic treatment; Natural aeration systems */
  AerobicNatural = 'AEROBIC_NATURAL',
  /**  Composting; Intensive Windrow (Frequent turning) */
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  /**  Cattle and swine deep bedding */
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  /**  Composting; Passive windrow (infrequent turning) */
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  /**  Composting; Static Pile (Forced aeration) */
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  DailySpreadSlurry = 'DAILY_SPREAD_SLURRY',
  /**  Daily spread */
  DailySpreadSolid = 'DAILY_SPREAD_SOLID',
  /**  Liquid/Slurry; Cover */
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  /**  Liquid/Slurry; With natural crust cover */
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  /**  Liquid/Slurry; Without natural crust cover */
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  /**  Pit storage below animal confinements */
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  /**  Poultry Manure with and without litter  */
  PoultryManureWithOrWithoutLitter = 'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER',
  /**  Solid storage; Additives */
  SolidAdditives = 'SOLID_ADDITIVES',
  /**  Solid storage; Bulking agent addition */
  SolidBulking = 'SOLID_BULKING',
  /**  Solid storage; Covered/compacted */
  SolidCovered = 'SOLID_COVERED',
  /**  Solid storage */
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export enum BreedingManureManagementSystemType {
  /**  Aerobic treatment; Forced aeration systems */
  AerobicForced = 'AEROBIC_FORCED',
  /**  Aerobic treatment; Natural aeration systems */
  AerobicNatural = 'AEROBIC_NATURAL',
  /**  Composting; Intensive Windrow (Frequent turning) */
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  /**  Cattle and swine deep bedding */
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  /**  Composting; Passive windrow (infrequent turning) */
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  /**  Composting; Static Pile (Forced aeration) */
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  DailySpreadSlurry = 'DAILY_SPREAD_SLURRY',
  /**  Daily spread */
  DailySpreadSolid = 'DAILY_SPREAD_SOLID',
  /**  Liquid/Slurry; Cover */
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  /**  Liquid/Slurry; With natural crust cover */
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  /**  Liquid/Slurry; Without natural crust cover */
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  /**  Pit storage below animal confinements */
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  /**  Poultry Manure with and without litter  */
  PoultryManureWithOrWithoutLitter = 'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER',
  /**  Solid storage; Additives */
  SolidAdditives = 'SOLID_ADDITIVES',
  /**  Solid storage; Bulking agent addition */
  SolidBulking = 'SOLID_BULKING',
  /**  Solid storage; Covered/compacted */
  SolidCovered = 'SOLID_COVERED',
  /**  Solid storage */
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export enum GrowingManureManagementSystemType {
  /**  Daily spread */
  DailySpreadSolid = 'DAILY_SPREAD_SOLID',
  /**  Poultry Manure with and without litter  */
  PoultryManureWithOrWithoutLitter = 'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER'
}
