import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DsmFieldset } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import {
  PoultryBaseline,
  PoultryOutputGrowing,
} from '../../../../models/Baseline/PoultryBaseline';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import { StageType } from '../../../../../../graphql/types';
import lookupValues from '../../../../helpers/lookupValues';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

const PoultryOutputHatchingDialog: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PoultryOutputGrowing>();
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const fc = useFormContext<PoultryBaseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnSmallQuantityUnits = unitLong(
    userUOM.unitBarnSmallQuantityUnits as string
  );
  const initialFlagsSet = useRef<boolean>(false); // Track if the field default value flags are set on either first focus
  const initialPrice = useRef<boolean>(false);
  const initialPriceOfLosesSoldDefaultValue = useRef<boolean>(false);

  const setInitialDefaultValues = () => {
    if (!initialFlagsSet.current) {
      initialPrice.current =
        fc.getValues(`${fieldItemPrefix}.price`) ===
        lookupValues.defaultPrice[StageType.Hatching].price;
      initialPriceOfLosesSoldDefaultValue.current =
        fc.getValues(`${fieldItemPrefix}.priceOfLossesSold`) ===
        lookupValues.defaultPrice[StageType.Hatching].priceOfLossesSold;
      initialFlagsSet.current = true;
    }
  };

  const overwriteEggPriceIfDefaultPriceOfLosesSoldChanges = () => {
    if (
      initialPriceOfLosesSoldDefaultValue.current &&
      initialPrice.current &&
      fc.getValues(`${fieldItemPrefix}.priceOfLossesSold`) !==
        lookupValues.defaultPrice[StageType.Hatching].priceOfLossesSold
    ) {
      initialPrice.current = false;
      fc.setValue(`${fieldItemPrefix}.price`, '');
    }
  };

  const overwritePriceOfLosesSoldIfDefaultEggPriceChanges = () => {
    if (
      initialPriceOfLosesSoldDefaultValue.current &&
      initialPrice.current &&
      fc.getValues(`${fieldItemPrefix}.price`) !==
        lookupValues.defaultPrice[StageType.Hatching].price
    ) {
      initialPriceOfLosesSoldDefaultValue.current = false;
      fc.setValue(`${fieldItemPrefix}.priceOfLossesSold`, '');
    }
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, { errors: true });
      }
    }
    handleCancel('reset');
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
    >
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalToStage`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_TO_STAGE',
          })}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_TO_STAGE.PLACEHOLDER',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_TO_STAGE.TOOLTIP',
          })}
          required
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.totalToStage`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.AVERAGE_WEIGHT',
          })}
          adornment={barnSmallQuantityUnits}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.AVERAGE_WEIGHT.TOOLTIP',
          })}
          required
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.averageWeight`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalSold`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_SOLD',
          })}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_SOLD.PLACEHOLDER',
          })}
          disabled={formType === FormType.View}
          required
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.totalSold`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.price`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.PRICE',
          })}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.PRICE.PLACEHOLDER',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.PRICE.TOOLTIP',
          })}
          disabled={formType === FormType.View}
          type="number"
          changeHandler={() =>
            overwritePriceOfLosesSoldIfDefaultEggPriceChanges()
          }
          onFocusHandler={() => setInitialDefaultValues()}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.price`) ??
            lookupValues.defaultPrice[StageType.Hatching].price
          }
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.lossRate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.LOSS',
          })}
          adornment={barnOutputMassUnit}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.LOSS.TOOLTIP',
          })}
          type="number"
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.lossRate`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.priceOfLossesSold`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.PRICE_LOSS',
          })}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.PRICE_LOSS.PLACEHOLDER',
          })}
          type="number"
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.PRICE_LOSS.TOOLTIP',
          })}
          changeHandler={() =>
            overwriteEggPriceIfDefaultPriceOfLosesSoldChanges()
          }
          onFocusHandler={() => setInitialDefaultValues()}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          defaultValue={
            fc.getValues(`${fieldItemPrefix}.priceOfLossesSold`) ??
            lookupValues.defaultPrice[StageType.Hatching].priceOfLossesSold
          }
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalEggShellsSold`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_EGG_SHELLS_SOLD',
          })}
          adornment={barnOutputMassUnit}
          type="number"
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.OUTPUT.HATCHING.TOTAL_EGG_SHELLS_SOLD.TOOLTIP',
          })}
          defaultValue={fc.getValues(`${fieldItemPrefix}.totalEggShellsSold`)}
        />
      </DsmFieldset>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await fc.trigger(`stages.${itemIndex}.stageData.output`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryOutputHatchingDialog;
