export const getImpactData = (inData, formData) => {
  let impactSummary = null;
  // console.log("inData", inData);
  if(inData.impactSummary){
    // console.log("using impactSummary");
    impactSummary = JSON.parse(inData.impactSummary);
  }else if(inData.calculatedImpact){
    // console.log("using calculatedImpact");
    const calculatedImpact = JSON.parse(inData.calculatedImpact);
    impactSummary = {
      air:{
        value: calculatedImpact["Climate change"]?.impact?.value,
        unit: calculatedImpact["Climate change"]?.impact?.unit
      },
      land:{
        value: calculatedImpact["Land use"]?.impact?.value,
        unit: calculatedImpact["Land use"]?.impact?.unit
      },
      human:{
        value: calculatedImpact["Respiratory inorganics"]?.impact?.value,
        unit: calculatedImpact["Respiratory inorganics"]?.impact?.unit
      },
      water:{
        value: calculatedImpact["Water scarcity"]?.impact?.value,
        unit: calculatedImpact["Water scarcity"]?.impact?.unit
      },
    }
  }
  if( typeof formData === 'object')
    formData.impactSummary = impactSummary;
  return impactSummary;
}