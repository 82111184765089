import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  defaultValuesForMortalityData,
  nameMapping,
} from '../../validationSchemaRestrictions';
import {
  floatValueInUserUnits,
  mapCommonProductionDataIn,
  mapResourceUse,
} from './inCommonMapperSustell';

const ANIMAL_TYPE='Pig fattening';

const mapPigFatteningProductionDataInSustell = (
  inData,
  isDraft = false
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  // console.log("inData: ",inData);

  const formData = mapCommonProductionDataIn(inData, isDraft);
  mapResourceUse(inData.resourceUse, formData.resourceUse);

  if(inData.stages && Array.isArray(inData.stages)){
    inData.stages.forEach( (stage, index) => {
      const stageFormData = formData.stages[index]?.stageData;
      if(stageFormData){
        const stageData =JSON.parse(stage.stageData)
        
        // map output
        stageFormData.output.liveWeight = floatValueInUserUnits(
          stageData?.fattened_pigs?.total_weight?.value,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        
        let val = stageData?.inorganic_n_fertilizer_replacement_rate?.value;
        const defaultInorganicNFertilizerReplacementRate = !isDraft ? 50 : null;
        // set to default value if not draft
        stageFormData.output.inorganicNFertilizerReplacementRate = val || val === 0 ? val : defaultInorganicNFertilizerReplacementRate ; 

        const defaultInorganicPFertilizerReplacementRate = !isDraft ? 100 : null;
        val = stageData?.inorganic_p_fertilizer_replacement_rate?.value;
        stageFormData.output.inorganicPFertilizerReplacementRate = val || val === 0 ? val : defaultInorganicPFertilizerReplacementRate;

        stageFormData.animalType = nameMapping[stageData?.animal_group.animal_type];

        stageFormData.housingType = stageData?.housing_description?.housing_type;

        stageFormData.input.purchasedPiglets = stageData.purchased_piglets?.number_of_animals.value;

        stageFormData.input.averageLiveWeight = floatValueInUserUnits(
          stageData?.purchased_piglets?.average_live_weight?.value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          3
        );
        
        const defaultMortalityRate = 
          !isDraft && defaultValuesForMortalityData[ANIMAL_TYPE]
          ? defaultValuesForMortalityData[ANIMAL_TYPE].mortalityRate
          : undefined;

        stageFormData.input.mortalityRate =
          stageData?.mortality?.mortality_rate?.value ||
          stageData?.mortality?.mortality_rate?.value === 0
            ? stageData?.mortality?.mortality_rate?.value
            : defaultMortalityRate;
        
        // set loaded value or default
        const defaultAverageLIveweightMortality = 
          !isDraft && defaultValuesForMortalityData[ANIMAL_TYPE]
          ? defaultValuesForMortalityData[ANIMAL_TYPE].averageLiveweightMortality
          : undefined;
        
        stageFormData.input.averageLiveWeightMortalities = 
          stageData?.mortality?.average_live_weight_mortalities?.value ||
          defaultAverageLIveweightMortality;
        
        // do conversion
        if (
          stageFormData.input.averageLiveWeightMortalities ||
          stageFormData.input.averageLiveWeightMortalities === 0
        ) {
          stageFormData.input.averageLiveWeightMortalities = floatValueInUserUnits(
            stageFormData.input.averageLiveWeightMortalities,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            outputMassPrecision
          );
        }

        stageFormData.input.housingType =
          stageData?.housing_description?.housing_type;
      
      }
    })
  }


  return formData;
};

export default mapPigFatteningProductionDataInSustell