import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const forbiddenChars = (
  intl: IntlShape,
  forbiddenCharacters: string[],
  i18nCode = 'VALIDATION.FIELD.FORBIDDEN_CHARS',
  testName = 'forbiddenChars'
) =>
  Yup.string().test(
    testName,
    intl.formatMessage({ id: i18nCode }, { value: forbiddenCharacters.join(', ') }),
    (value) => {
      if (value)
        return forbiddenCharacters.every((forbidden) => !value.includes(forbidden));
      return true;
    }
  );
