import React, { useRef } from "react";
import * as Yup from "yup";
import axios from "axios";

import {
  Button,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Card,
  Switch,
  FormControlLabel,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { UserGroups } from "./../Users/Users";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  createNotification,
  updateNotification,
} from "../../../graphql/mutations";
import { getSignedUrl } from "../../../graphql/queries";
import { API } from "aws-amplify";
import Editor from "./Editor";
// import { useIntl } from 'react-intl';
import { useIntl } from "../../../_metronic/i18n/customUseIntl";
import { formSubmission } from '../../sustell_15/analytics/adobeDataLayer';

const useStyles = makeStyles({
  uploadContainer: {
    marginRight: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    "&>*:not(:last-child)": {
      marginRight: 8,
    },
    "&>*": {
      marginBottom: 8,
    },
  },
});

export const NotificationForm = ({ onClose, notification }) => {
  const intl = useIntl();
  const [selectedFiles, setSelectedFiles] = React.useState(() => []);
  const [progressBarValues, setProgressBarValues] = React.useState( new Map() );
  const [allSelected, setAllSelected] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  
  const updateProgressBarValues = (k,v) => {
    setProgressBarValues(new Map(progressBarValues.set(k,v)));
  }

  //following must not be reset on eventual rerender
  const formValues = useRef([]);
  const filesRemainingToFinish = useRef(0);
  const attachmentS3Keys = useRef([]);
  

  const classes = useStyles();

  // time wasn't set correctly as ISO string is just trimmed, and timezone offset not accounted for
  let publishAtDate = notification?.publishAt ? new Date(notification?.publishAt) : null;
  if(publishAtDate)
    publishAtDate = new Date(publishAtDate.getTime()-publishAtDate.getTimezoneOffset()*60000);

  let expiresAtDate = notification?.expiresAt ? new Date(notification?.expiresAt) : null;
  if(expiresAtDate)
    expiresAtDate = new Date(expiresAtDate.getTime()-expiresAtDate.getTimezoneOffset()*60000);
  
  const formik = useFormik({
    initialValues: {
      id: notification?.id || "",
      title: notification?.title || "",
      groups: notification?.groups || [],
      publishAt: publishAtDate
        ? publishAtDate.toISOString().split(".")[0]
        : "",
      expiresAt: expiresAtDate
        ? expiresAtDate.toISOString().split(".")[0]
        : "",
      active: notification?.id ? !!notification?.active : true,
      isWelcome: notification?.isWelcome ? !!notification?.isWelcome : false,
      category: notification?.category || "Notification",
      message: notification?.message || "",
    },
    validationSchema: Yup.object({
      title: Yup.string("Enter a title")
        .required("Title is required")
        .min(3, "Title must contain at least 3 characters."),
      groups: Yup.array().required("Group is required"),
      publishAt: Yup.date()
        .typeError("Invalid date format")
        .required("Start date is required"),
      expiresAt: Yup.date()
        .typeError("Invalid date format")
        .nullable(true)
        .optional(),
    }),
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      formValues.current = values;
      filesRemainingToFinish.current = selectedFiles.length;
      saveNotification();
    },
  });

  const handleChipDelete = (chipToDelete) => () => {
    setSelectedFiles((files) =>
      files.filter((file) => file.chipkey !== chipToDelete.chipkey)
    );
  };

  const saveNotification = ()=> {
      setIsSaving(true);
      if(selectedFiles.length>0){
        selectedFiles.forEach(item => {
          uploadFile(item);
        })
      }
      else 
        saveNotificationToDb();
  }

  const saveNotificationToDb = ()=> {
    formSubmission(notification ? 'Edit Notification': 'New Notification', notification ? 'edit': 'new');
    (async () => {
      const values = formValues.current;
      
      try {
        let inputData = {
          title: values.title,
          message: values.message,
          startDateTime: new Date(values.publishAt).toISOString(),
          expireDateTime: values.expiresAt
            ? new Date(values.expiresAt).toISOString()
            : null,
          notificationType: values.category,
          targetGroups: values.groups,
          active: values.active,
          isWelcome: values.isWelcome,
          attachementKeys: attachmentS3Keys.current.length > 0 ? attachmentS3Keys.current : (notification?.attachementKeys)
        }

        let variables = {
          input: inputData,
        };

        //for edit of existing notification
        if (values.id) {
          variables.notificationID = values.id;
        }
        
        let result = await API.graphql({
          query: values.id ? updateNotification : createNotification,
          variables: variables,
        });
        onClose(result?.data);
      } catch (err) {
        console.log("error: ", err);
        if (err.errors?.length > 0) {
          let error = err.errors[0];
          alert( error.message );
        }
      }
    })();
  };

  const onFileChangeHandler = (ev) => {
    if (ev.target.files) {
      let currFiles = [...selectedFiles];
      for (let i = 0; i < ev.target.files.length; i++) {
        const file = ev.target.files[i];
        if (file.type.startsWith("image/") || file.type === "application/pdf"){
          //don't allow subsequent reselction of same name files (inside one click it's handled automatically)
          if(!currFiles.some(item => item.chipkey === file.name )){
            //clone file so subsequent click on 'add file(s)' would not remove file reference
            //allows for multiple click to add files
            let newFile=new File([file], file.name, {type: file.type});
            currFiles.push({
              chipkey: newFile.name,
              name: newFile.name,
              file: newFile,
            });
          }
        }
        else alert("Not allowed file type of file: " + file.name);
      }
      setSelectedFiles(currFiles);
    }
  };

  const uploadFile = async (item) => {
    // console.log("called getSignedUrl", item.name);
    try {
      const result = await API.graphql({
        query: getSignedUrl,
        variables: {
          key: item.name,
          method: "PUT",
        },
      });
      if (result.data?.getSignedUrl) {
        item.key = result.data.getSignedUrl.key;
        item.signedUrl = result.data.getSignedUrl.signedUrl;
      }

      // now do a PUT request to the pre-signed URL
      setProgressBarValues(progressBarValues);
      axios.put( item.signedUrl, item.file, {
          onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                // console.log(progressEvent.loaded + ' ' + progressEvent.total);
                let progressValue = Math.round(progressEvent.loaded/progressEvent.total * 100);
                updateProgressBarValues(item.chipkey, progressValue);
            }
          }
        }).then( (response) => {
          if(response.status === 200){
            attachmentS3Keys.current.push(item.key)
          }
          registerFinishedUpload();
        }
      );
    } catch (err) {
      console.log('error: ', err);
      registerFinishedUpload();
      if (err.errors?.length > 0) {
        let error = err.errors[0];
        alert("Attachement "+item.name+" failed to upload.\n"+error.message);
      }
    }
  };

  const registerFinishedUpload = () => {
    filesRemainingToFinish.current = filesRemainingToFinish.current-1;
    if(filesRemainingToFinish.current === 0)
      saveNotificationToDb();
  }

  return (
    <>
      <Row>
        <Col md={{ span: 8 }}>
          <Card style={{ height: "100%" }}>
            <CardHeader
              title={notification ? intl.formatMessage({id: "NOTIFICATIONS.EDIT_NOTIFICATION"}) : intl.formatMessage({id:"NOTIFICATIONS.NEW_NOTIFICATION" })}
              titleTypographyProps={{ color: "secondary", variant: "h4" }}
            />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  id="title"
                  name="title"
                  label={ intl.formatMessage({ id: "NOTIFICATIONS.TITLE"})}
                  placeholder={ intl.formatMessage({ id: "NOTIFICATIONS.TITLE"})}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formik.values.title}
                  disabled = { isSaving }
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  style={{ marginBottom: 12 }}
                />
                <TextField
                  id="publishAt"
                  name="publishAt"
                  label={ intl.formatMessage({ id: "NOTIFICATIONS.PUBLISH_TIME"})}
                  value={formik.values.publishAt}
                  disabled = { isSaving }
                  type="datetime-local"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.publishAt && Boolean(formik.errors.publishAt)
                  }
                  helperText={
                    formik.touched.publishAt && formik.errors.publishAt
                  }
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <TextField
                  id="expiresAt"
                  name="expiresAt"
                  label={ intl.formatMessage({ id: "NOTIFICATIONS.EXPIRATION_TIME"})}
                  value={formik.values.expiresAt}
                  disabled = { isSaving }
                  type="datetime-local"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.expiresAt && Boolean(formik.errors.expiresAt)
                  }
                  helperText={
                    formik.touched.expiresAt && formik.errors.expiresAt
                  }
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 12 }}
                >
                  <InputLabel htmlFor="groups">Groups</InputLabel>
                  <Select
                    multiple
                    fullWidth
                    input={
                      <OutlinedInput
                        label={ intl.formatMessage({ id: "USERS.GROUPS"})}
                        name="groups"
                        id="groups"
                        value={formik.values.groups}
                        disabled = { isSaving }
                        onChange={e => {
                          if(e.target?.value.includes("all")) {
                            if(allSelected) {
                              formik.setFieldValue("groups", []);
                            } else {
                              formik.setFieldValue("groups", UserGroups);
                            }
                            setAllSelected((val) => !val);
                            return;
                          }

                          formik.setFieldValue("groups", e.target.value);
                        }}
                      />
                    }
                  >
                    <MenuItem
                      value={"all"}
                      style={{
                        marginBottom: 12,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        borderBlockColor: "gray",
                      }}
                    >
                      {allSelected ? intl.formatMessage({id: "USERS.GROUPS.SELECT_NONE"}) : intl.formatMessage({id:"USERS.GROUPS.SELECT_ALL"})}
                    </MenuItem>
                    {Object.entries(UserGroups).map(([i, label]) => (
                      <MenuItem key={i} value={label}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.groups && Boolean(formik.errors.groups) && (
                    <FormHelperText error={true}>
                      {formik.errors.groups}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box className={classes.uploadContainer}>
                  <Button
                    variant="contained"
                    color="default"
                    component="label"
                    multiple
                    startIcon={<CloudUploadIcon />}
                    disabled = { isSaving }
                  >
                    { intl.formatMessage({id: "NOTIFICATIONS.ADD_FILES"})}
                    <input
                      name="attachments"
                      type="file"
                      accept="image/*|application/pdf"
                      hidden
                      multiple
                      onChange={onFileChangeHandler}
                      
                    />
                  </Button>
                  {selectedFiles.map((data) => {
                    return (
                      
                      <li key={data.chipkey} style={{margin:'0.5em', display:'inline-block'}}>
                        <Chip
                          label={data.name}
                          onDelete={handleChipDelete(data)}
                          disabled = { isSaving }
                        />
                        { isSaving &&
                          <LinearProgress variant="determinate" value={ progressBarValues.get(data.chipkey) ? progressBarValues.get(data.chipkey) : 0 } style={{margin: "10px 5px"}}/>
                        }
                      </li>
                    );
                  })}
                </Box>
                { notification?.attachementKeys?.length>0 && 
                  <Box>
                    Existing attachments (will be replaced if new items are selected):<br/>
                  <ul >
                    { notification?.attachementKeys?.map((data) => {
                      return (
                        <li key={data}>
                          { data.substring(21) }
                        </li>
                      );
                      })
                    }
                    </ul>
                  </Box>
                }               
                <FormControlLabel
                  control={
                    <Switch
                      id="active"
                      name="active"
                      checked={formik.values.active}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      disabled = { isSaving }
                    />
                  }
                  label={intl.formatMessage({id: "NOTIFICATIONS.ACTIVE"})}
                />
                <FormControlLabel
                  control={
                    <Switch
                      id="isWelcome"
                      name="isWelcome"
                      checked={formik.values.isWelcome}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      disabled = { isSaving }
                    />
                  }
                  label={intl.formatMessage({id: "NOTIFICATIONS.WELCOME_MESSAGE"})+"?"}
                />
              </form>
              <Editor    
                  value={formik.values.message}
                  onChange={(value) => {
                    formik.setFieldValue("message", value);
                  }} 
                  disabled = { isSaving }
              />
            </CardContent>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col md={{ span: 8 }} style={{ textAlign: "right" }}>
          <Button
            style={{ marginRight: 8 }}
            variant="contained"
            color="primary"
            disableElevation
            type="button"
            disabled = { isSaving }
            onClick={formik.handleSubmit}
          >
            { intl.formatMessage({id: "GENERAL.SAVE"}) }
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="button"
            disabled = { isSaving }
            onClick={onClose}
          >
            { intl.formatMessage({id: "GENERAL.CANCEL"}) }
          </Button>
        </Col>
      </Row>
    </>
  );
};
