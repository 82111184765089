/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { InterventionStageProps } from '../CommonDataParts/InterventionTypes';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import { ListEntry, PoultryFarms } from '../../common';
import { PoultryBaseline, PoultryInputBreeding, PoultryInputGrowing, PoultryInputHatching, PoultryInputLaying } from '../../../../models/Baseline/PoultryBaseline';
import StageButtonBox from '../../StageButtonBox_v2';
import PoultryEmissionsDialog from './PoultryEmissionsDialog';
import PoultryOutputGrowingDialog from './PoultryOutputGrowingDialog';
import PoultryOutputLayingDialog from './PoultryOutputLayingDialog';
import PoultryOutputBreedingDialog from './PoultryOutputBreedingDialog';
import PoultryOutputHatchingDialog from './PoultryOutputHatchingDialog';
import PoultryFeedGrowingAndLayingDialog from './PoultryFeedGrowingAndLayingDialog';
import PoultryFeedBreedingDialog from './PoultryFeedBreedingDialog';
import PoultryInputLayingDialog from './PoultryInputLayingDialog';
import PoultryInputHatchingDialog from './PoultryInputHatchingDialog';
import PoultryInputBreedingDialog from './PoultryInputBreedingDialog';
import PoultryInputGrowingDialog from './PoultryInputGrowingDialog';
import PoultryHousingHatchingDialog from './PoultryHousingHatchingDialog';
import PoultryHousingAndManureDialog from './PoultryHousingAndManureDialog';

interface PoultryInterventionStageProps extends Omit<InterventionStageProps, 'manureManagementSystems'> {
  baseline: PoultryBaseline;
  origins: ListEntry[];
  animalType: AnimalType.BroilerV2 | AnimalType.LayingHensV2 | AnimalType.Turkey;
  farms: PoultryFarms[];
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  HOUSING = 'housing',
  EMISSIONS = 'emissions',
  OUTPUT = 'output',
}

const PoultryInterventionStages = ({
  stageIndex,
  baseline,
  singleIngredients,
  compoundFeeds,
  origins,
  farms,
  formType,
  animalType,
}: PoultryInterventionStageProps) => {
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(null);
  const showDialog = (dialog: StageDialogType |  null) => { setActiveDialog(dialog); };
  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;
  const stageType: StageType = baseline?.stages?.[stageIndex]?.type as StageType;

  const getSelectableStages = (): string[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const currentBaselineValues = baseline?.stages ? baseline?.stages?.map((stage: { id: string; }) => stage.id) : [];
    return [...farms.map((farm) => farm.stageId), ...currentBaselineValues] as string[];
  }

  const checkInternalSourcesBreeding = () => {
    const allStageIds = getSelectableStages();
    
    const input = baseline.stages[stageIndex].stageData.input as PoultryInputBreeding;
    if (input.henInternalSource && !allStageIds.includes(input?.henInternalSource?.originStageId as string))
      fc.setValue(`${stagePrefix}.stageData.input.henInternalSource.farmDeleted`, true);
    if (input.roosterInternalSource && !allStageIds.includes(input?.roosterInternalSource?.originStageId as string))
      fc.setValue(`${stagePrefix}.stageData.input.roosterInternalSource.farmDeleted`, true);
  }

  const checkInternalSourcesLayingAndHatching = () => {
    const allStageIds = getSelectableStages();
    const input = baseline.stages[stageIndex].stageData.input as PoultryInputLaying | PoultryInputHatching;
    if (input.internalSource && !allStageIds.includes(input?.internalSource?.originStageId as string)){
      fc.setValue(`${stagePrefix}.stageData.input.internalSource.farmDeleted`, true);
    }
  }

  const checkInternalSourcesGrowing = () => {
    const allStageIds = getSelectableStages();
    const input = baseline.stages[stageIndex].stageData.input as PoultryInputGrowing;
    input?.internalSources.forEach((internalSource, index: number) => {
      if (internalSource && !allStageIds.includes(internalSource.originStageId as string))
        fc.setValue(`${stagePrefix}.stageData.input.internalSources[${index}].farmDeleted`, true);
    })
  }

  // Check if selected stages are existing or not
  // In case of some baseline deleted, the loaded data needs
  // to be removed so the user needs to select a different stage
  useEffect(() => {
    if(farms?.length && baseline) {
      if (stageType === StageType.Breeding) {
        checkInternalSourcesBreeding();
      } else if (stageType === StageType.Growing) {
        checkInternalSourcesGrowing();
      } else if (stageType === StageType.Laying) {
        checkInternalSourcesLayingAndHatching();
      } else if (stageType === StageType.Hatching) {
        checkInternalSourcesLayingAndHatching();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms, baseline]);

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        value={stageType}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.index`}
        value={stageIndex}
      />

      { stageType === StageType.Breeding && activeDialog === 'input' &&
        <PoultryInputBreedingDialog 
          formVisible={activeDialog === 'input'} 
          formType={formType}
          itemIndex={stageIndex}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
        />
      }

      { stageType === StageType.Growing && activeDialog === 'input' &&
        <PoultryInputGrowingDialog 
          formVisible={activeDialog === 'input'} 
          formType={formType}
          itemIndex={stageIndex}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
        />
      }

      { stageType === StageType.Laying && activeDialog === 'input' &&
        <PoultryInputLayingDialog 
          formVisible={activeDialog === 'input'} 
          formType={formType}
          itemIndex={stageIndex}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
        />
      }

      { stageType === StageType.Hatching && activeDialog === 'input' &&
        <PoultryInputHatchingDialog 
          formVisible={activeDialog === 'input'} 
          formType={formType}
          itemIndex={stageIndex}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
        />
      }

      { stageType === StageType.Breeding && activeDialog === 'feed' &&
        <PoultryFeedBreedingDialog 
          formVisible={activeDialog === 'feed'} 
          formType={formType}
          handleCancel={() => setActiveDialog(null)} 
          baseline={baseline} 
          stageIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      }

      { stageType === StageType.Growing && activeDialog === 'feed' &&
        <PoultryFeedGrowingAndLayingDialog 
          formVisible={activeDialog === 'feed'} 
          formType={formType}
          handleCancel={() => setActiveDialog(null)} 
          baseline={baseline} 
          stageIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      }

      { stageType === StageType.Laying && activeDialog === 'feed' &&
        <PoultryFeedGrowingAndLayingDialog 
          formVisible={activeDialog === 'feed'} 
          formType={formType}
          handleCancel={() => setActiveDialog(null)} 
          baseline={baseline} 
          stageIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      }

      { stageType !== StageType.Hatching && activeDialog === 'housing' && 
        <PoultryHousingAndManureDialog
          formVisible={activeDialog === 'housing'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          itemIndex={stageIndex}
          animalType={animalType}
          formType={formType}
          stageType={stageType}
        />
      }

      { stageType === StageType.Hatching && activeDialog === 'housing' && 
        <PoultryHousingHatchingDialog
          formVisible={activeDialog === 'housing'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          itemIndex={stageIndex}
          animalType={animalType}
          formType={formType}
        />
      }

      { stageType === StageType.Breeding && activeDialog === 'output' && 
        <PoultryOutputBreedingDialog
          formVisible={activeDialog === 'output'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      }

      { stageType === StageType.Laying && activeDialog === 'output' && 
        <PoultryOutputLayingDialog
          formVisible={activeDialog === 'output'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      }

      { stageType === StageType.Growing && activeDialog === 'output' && 
        <PoultryOutputGrowingDialog
          formVisible={activeDialog === 'output'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      }

      { stageType === StageType.Hatching && activeDialog === 'output' && 
        <PoultryOutputHatchingDialog
          formVisible={activeDialog === 'output'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      }

      {activeDialog === 'emissions' && (
        <PoultryEmissionsDialog
          formVisible={activeDialog === 'emissions'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline as unknown as PoultryBaseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}

      <Box
        style={{ display: 'flex', width: stageType === StageType.Hatching ? '60%' : '100%' }}
        flexDirection="row"
        justifyContent='space-between'
      >
        {stageType !== StageType.Hatching && <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />}
        {stageType === StageType.Hatching && <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.HATCHING.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.HATCHING_EGGS_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />}
        {stageType !== StageType.Hatching && <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed}
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />}
        {stageType !== StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="general/building-06"
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.housing}
          handleOpen={() => showDialog(StageDialogType.HOUSING)}
        />)}
        {stageType === StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.HOUSING.TITLE"
          iconCode="general/building-06"
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.housing}
          handleOpen={() => showDialog(StageDialogType.HOUSING)}
        />)}
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.output}
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        {stageType !== StageType.Hatching && <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="maps-travel/globe-04"
          optional
          descriptionTitle="SUSTELL.STAGE.MITTIGATION"
          description="SUSTELL.STAGE.INPUT_DATA"
          handleOpen={() => showDialog(StageDialogType.EMISSIONS)}
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.emissions}
        />}
      </Box>
    </>
  );
};

export default PoultryInterventionStages;
