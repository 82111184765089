import { API, Auth, Hub } from 'aws-amplify';
import { getUserSettings, listMyNotifications } from '../../../graphql/queries';
import { updateUserSettings } from '../../../graphql/mutations';

import { intitalUserUnits } from '../../sustell_15/utils/unit-utils';
import { initialDBSelection } from '../../sustell_15/utils/database-pref-utils';

// eslint-disable-next-line import/prefer-default-export
export const UserProfilePrefs = (() => {
  let instance = null;

  const DSM_EMAIL_SUFFIX =
    process.env.REACT_APP_DSM_EMAIL_SUFFIX || '@dsm.com, @dsm-firmenich.com';

  const DSM_EMAIL_SUFFIXES =
    DSM_EMAIL_SUFFIX?.split(',')?.map((suffix) =>
      suffix?.trim()?.toLocaleLowerCase()
    ) || [];

  const init = () => {
    const prefsListeners = new Map();
    let unitPrefs = null;
    let footprintPrefs = null;
    let notifications = null;
    let lastLoginTime = null;
    let notificationAPICallStatus = 'NONE';
    let notificationAPICall = null;
    let profileAPICallStatus = 'NONE';
    let profileAPICall = null;
    let user = null;
    let databasePref = null;
    let defaultCustomer = null;

    async function loadUserProfile(refreshLoginTime) {
      const variables = {};
      if (refreshLoginTime) variables.setLastLogin = true;

      if (!profileAPICall) {
        profileAPICallStatus = 'IN PROGRESS';
        profileAPICall = await API.graphql({
          query: getUserSettings,
          variables,
        })
          .then((result) => {
            lastLoginTime = result.data?.getUserSettings?.lastLogin;
            defaultCustomer = result.data?.getUserSettings?.defaultCustomer;
            profileAPICallStatus = 'COMPLETED';
            profileAPICall = null;
            return result.data.getUserSettings;
          })
          .catch((err) => {
            profileAPICallStatus = 'FAILED';
            profileAPICall = null;
            console.error('error: ', err);
            return null;
          });
      }
      return profileAPICall;
    }

    const getMyNotifications = async (notificationCallback) => {
      if (!notificationAPICall) {
        notificationAPICallStatus = 'IN PROGRESS';
        notificationAPICall = API.graphql({
          query: listMyNotifications,
        })
          .then((result) => {
            notifications = result.data?.listMyNotifications?.items?.map(
              (item) => ({
                id: item.id,
                title: item.title,
                message: item.message,
                publishAt: new Date(item.startDateTime),
                expiresAt: item.expireDateTime
                  ? new Date(item.expireDateTime)
                  : undefined,
                category: item.notificationType,
                attachementKeys: item.attachementKeys,
                isWelcome: item.isWelcome,
              })
            );

            if (notificationCallback) notificationCallback(notifications);

            notificationAPICallStatus = 'COMPLETED';
            return notifications;
          })
          .catch((err) => {
            notificationAPICallStatus = 'FAILED';
            console.error('error: ', err);
            if (err.errors?.length > 0) {
              const error = err.errors[0];
              if (error.errorType === 'ValidationError') {
                // eslint-disable-next-line no-alert
                alert(error.message);
              }
            }
          });
      }
      return notificationAPICall;
    };

    const getFootprintPrefs = () => footprintPrefs || {};

    const setFootprintPrefs = (prefObj) => {
      if (!prefObj) footprintPrefs = {};
      else {
        footprintPrefs =
          (typeof prefObj === 'string' ? JSON.parse(prefObj) : prefObj) || {};
      }
    };

    const setUnitPrefs = (prefObj) => {
      // eslint-disable-next-line no-param-reassign
      if (prefObj === null) prefObj = {};

      // Set units to default when they are not available in user settings.
      Object.entries(intitalUserUnits).forEach(([key, value]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!prefObj.hasOwnProperty(key) || prefObj[key] === null) {
          // eslint-disable-next-line no-param-reassign
          prefObj[key] = value;
        }
      });

      unitPrefs = { ...prefObj };
      // notify components that listen to this;
      Hub.dispatch('userUnitPrefsChannel', {
        event: 'prefChanged',
        data: unitPrefs,
        message: '',
      });
    };

    const setDatabasePref = (prefObj) => {
      // eslint-disable-next-line no-param-reassign
      if (prefObj === null) prefObj = {};

      // Set database selection to default when they are not available in user settings.
      Object.entries(initialDBSelection).forEach(([key, value]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!prefObj.hasOwnProperty(key) || prefObj[key] === null) {
          // eslint-disable-next-line no-param-reassign
          prefObj[key] = value;
        }
      });

      databasePref = { ...prefObj };
      // notify components that listen to this;
      Hub.dispatch('userUnitPrefsChannel', {
        event: 'prefChanged',
        data: databasePref,
        message: '',
      });
    };

    const setDefaultCustomer  = (prefObj) => {
      defaultCustomer = prefObj;
    }

    // this one MUST be replaced
    const getUnitPrefs = () => {
      // after manual browser refresh or server restart profile values can be lost
      if (unitPrefs === null) {
        loadUserProfile()
          .then((result) => {
            setFootprintPrefs(result?.footprint);
            // delete fooprint before setting unit prefs
            // eslint-disable-next-line no-param-reassign
            delete result.footprint;
            if (result?.databaseType)
              setDatabasePref({ databaseType: result?.databaseType });
            // eslint-disable-next-line no-param-reassign
            delete result.databaseType;
            setUnitPrefs(result);
            return result;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return unitPrefs || intitalUserUnits;
    };

    const getDatabasePref = () => {
      // after manual browser refresh or server restart profile values can be lost
      if (databasePref === null) {
        loadUserProfile()
          .then((result) => {
            setFootprintPrefs(result?.footprint);
            // delete fooprint before setting unit prefs
            // eslint-disable-next-line no-param-reassign
            delete result.footprint;
            if (result?.databaseType)
              setDatabasePref({ databaseType: result?.databaseType });
            // eslint-disable-next-line no-param-reassign
            delete result.databasetype;
            setUnitPrefs(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return databasePref?.databaseType ? databasePref : initialDBSelection;
    };

    const setUserLoginSettings = () => {
      // after manual browser refresh or server restart profile values can be lost
      loadUserProfile()
        .then((result) => {
          let numberOfLoginTimes = 1;
          if (result?.numberOfLoginTimes) {
            numberOfLoginTimes =
              result?.numberOfLoginTimes + numberOfLoginTimes;
          }
          (async () => {
            try {
              await API.graphql({
                query: updateUserSettings,
                variables: {
                  input: { numberOfLoginTimes },
                  segment: 'BENCHMARKS',
                },
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('error: ', err);
            }
          })()
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getDefaultCustomerPref = () => {
      // after manual browser refresh or server restart profile values can be lost
      if (defaultCustomer === null) {
        loadUserProfile()
          .then((result) => {
            setDefaultCustomer(result?.defaultCustomer)
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return defaultCustomer;
    };

    const resetUnitPrefs = () => {
      unitPrefs = null;
    };

    const resetUserProfile = () => {
      unitPrefs = null;
      databasePref = null;
      defaultCustomer = null;
      notifications = null;
      lastLoginTime = null;
      notificationAPICallStatus = 'NONE';
      profileAPICallStatus = 'NONE';
      user = null;
    };

    const fetchUserUnitPrefs = (force = false, setTimestamp = false) => {
      if (unitPrefs === null || force) {
        loadUserProfile(setTimestamp).then((result) => {
          setFootprintPrefs(result?.footprint);
          // delete fooprint before setting unit prefs
          // eslint-disable-next-line no-param-reassign
          delete result.footprint;
          if (result?.databaseType)
            setDatabasePref({ databaseType: result.databaseType });
          // eslint-disable-next-line no-param-reassign
          delete result.databaseType;
          setUnitPrefs(result);
        });
      }
    };

    const fetchUserDatabasePrefs = (force = false, setTimestamp = false) => {
      if (databasePref === null || force) {
        loadUserProfile(setTimestamp).then((result) => {
          setFootprintPrefs(result?.footprint);
          // delete fooprint before setting unit prefs
          // eslint-disable-next-line no-param-reassign
          delete result.footprint;
          if (result?.databaseType)
            setDatabasePref({ databaseType: result?.databaseType });
          // eslint-disable-next-line no-param-reassign
          delete result.databaseType;
          setUnitPrefs(result);
        });
      }
    };

    const getMinOutputMass = () => {
      if (unitPrefs && unitPrefs.unitOutputMass !== 'kg') {
        if (unitPrefs.unitOutputMass === 'tonne') return 0.001;
        if (unitPrefs.unitOutputMass === 'ton') return 0.0012;
        if (unitPrefs.unitOutputMass === 'lbs') return 2.2;
      }
      return 1;
    };

    const getMinBarnOutputMass = () => {
      if (unitPrefs && unitPrefs.unitBarnOutputMass !== 'kg') {
        if (unitPrefs.unitBarnOutputMass === 'tonne') return 0.001;
        if (unitPrefs.unitBarnOutputMass === 'ton') return 0.0012;
        if (unitPrefs.unitBarnOutputMass === 'lbs') return 2.2;
      }
      return 1;
    };

    const getTempRange = () => {
      if (unitPrefs && unitPrefs.unitAverageAnnualTemperature === 'degF') {
        return { minTemp: -4, maxTemp: 140 };
      }
      // celsius limits
      return { minTemp: -20, maxTemp: 60 };
    };

    const getUnitOutputPrecision = () => {
      return 5;
    };

    const getUnitBarnOutputPrecision = () => {
      return 5;
    };

    const getUnitResourcePrecision = () => {
      return 5;
    };

    const getNotifications = () => notifications;

    const getLastLogin = () => lastLoginTime;

    // eslint-disable-next-line default-param-last
    const getUserProfile = (setTimestamp = false, notificationCallback) => {
      // should be called only once after login
      fetchUserUnitPrefs(false, setTimestamp);
      fetchUserDatabasePrefs(false, setTimestamp);
      getMyNotifications(notificationCallback);
    };

    const getNotificationAPICallStatus = () => notificationAPICallStatus;

    const getProfileAPICallStatus = () => profileAPICallStatus;

    const registerUnitPrefChangedListener = (
      componentName,
      callbackFunction
    ) => {
      // register for future changes of unitPrefs
      prefsListeners.set(componentName, callbackFunction);
      // check if unitPrefs and databasePref already set, return current value immediatelly
      if (unitPrefs) callbackFunction(unitPrefs);
      if (databasePref) callbackFunction(databasePref);
    };

    const unregisterUnitPrefChangedListener = (componentName) => {
      // remove listeners when not needed
      prefsListeners.delete(componentName);
    };

    const getCustomerContextId = () =>
      localStorage.getItem('customerContextId');

    const setCustomerContextId = (id) => {
      if (id) {
        localStorage.setItem('customerContextId', id);
      } else {
        localStorage.removeItem('customerContextId');
      }
    };

    const isInCustomerContextMode = () => {
      const customerContextId = getCustomerContextId();
      return customerContextId !== null && customerContextId !== undefined;
    };

    // eslint-disable-next-line no-return-assign
    const setUser = (newUser) => (user = newUser);

    const isDSMEmployee = () => {
      const email = user?.attributes?.email?.toLowerCase();

      return DSM_EMAIL_SUFFIXES?.some((suffix) => email?.endsWith(suffix));
    };

    const fetchAuthenticatedUser = async () => {
      // if user not set, fetch it
      if (!user) user = await Auth.currentAuthenticatedUser();
    };

    (() => {
      Hub.listen('userUnitPrefsChannel', (data) => {
        const { payload } = data;
        if (payload.event === 'prefChanged') {
          prefsListeners.forEach((func) => {
            func(payload.data);
          });
        }
      });
    })();

    return {
      getUserUnitPrefs: getUnitPrefs,
      getMinOutputMass,
      getMinBarnOutputMass,
      fetchUserUnitPrefs,
      resetUnitPrefs,
      resetUserProfile,
      setUserUnitPrefs: setUnitPrefs,
      getUnitOutputMassPrecision: getUnitOutputPrecision,
      getUnitBarnOutputMassPrecision: getUnitBarnOutputPrecision,
      getTempRange,
      getUnitResourcePrecision,
      getNotifications,
      getLastLogin,
      reloadNotifications: getMyNotifications,
      getUserProfile,
      getNotificationAPICallStatus,
      getProfileAPICallStatus,
      registerUnitPrefChangedListener,
      unregisterUnitPrefChangedListener,
      getCustomerContextId,
      setCustomerContextId,
      isInCustomerContextMode,
      isDSMEmployee,
      fetchAuthenticatedUser,
      setUser,
      getFootprintPrefs,
      setFootprintPrefs,
      getUserDatabasePrefs: getDatabasePref,
      setUserDatabasePrefs: setDatabasePref,
      setUserLoginSettings,
      getDefaultCustomerPrefs : getDefaultCustomerPref,
    };
  };

  return {
    // Get the Singleton instance if one exists
    getInstance: () => {
      if (!instance) {
        // create if not present
        instance = init();
      }

      return instance;
    },
    destroyInstance: () => {
      instance = null;
    },
  };
})();
