/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  FormType,
  ListEntry,
} from '../../sustell_15/components/FarmFlow/common';
import { UserProfilePrefs } from './UserProfilePrefs';
import ReactHookDsmSelect from './ReactHookDsmSelect2';
import ReactHookDsmInput from './ReactHookDsmInput2';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { EnergyCarrierType } from '../../sustell_15/models/Facility/FacilityTypes';
import { getUnitForEnergyType } from '../../sustell_15/components/Facilities/mapper/outFacilityMapper';
import { TransportMode } from '../../../graphql/types';

interface SustellTypeAmountFieldsProps {
  labelSelect: string;
  labelInput: string;
  placeholderSelect: string;
  typeFieldName?: string;
  amountFieldName?: string;
  deafultValueType: string;
  defaultValueAmount: string | number | undefined;
  options?: Array<ListEntry>;
  index: number;
  formType: FormType;
  fieldItemPrefix: string;
  keyName?: string;
  placeholderUnit?: string;
  tooltipType?: string;
  tooltipUse?: string;
  changeHandler?: () => void;
}

const SustellTypeAmountFields: FC<SustellTypeAmountFieldsProps> = ({
  labelSelect,
  labelInput,
  typeFieldName = 'type',
  amountFieldName = 'amount',
  placeholderSelect,
  options = [],
  index,
  formType,
  deafultValueType,
  defaultValueAmount,
  keyName,
  fieldItemPrefix,
  placeholderUnit = ' ',
  tooltipType,
  tooltipUse,
  changeHandler,
}) => {
  const formContext = useFormContext();
  const intl = useIntl();

  const fieldTypeName = `${fieldItemPrefix}[${index}].${typeFieldName}`;
  const fieldAmountName = `${fieldItemPrefix}[${index}].${amountFieldName}`;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  useWatch({ name: `${fieldItemPrefix}` });

  const transportModeUnitsMap = new Map<TransportMode, string>([
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.InlandShip, userUOM.unitTransportDistanceInlandWaterways],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.SeaShip, userUOM.unitTransportDistanceSea],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.Train, userUOM.unitTransportDistanceTerrestrial],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [TransportMode.Truck, userUOM.unitTransportDistanceTerrestrial],
  ]);

  const getUnit = (type: string | undefined): string | undefined => {
    if (!type) return undefined;
    if (Object.values<string>(EnergyCarrierType).includes(type)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return getUnitForEnergyType(type as EnergyCarrierType, userUOM)?.userUnit;
    }
    if (Object.values<string>(TransportMode).includes(type)) {
      return transportModeUnitsMap.get(type as TransportMode);
    }
    return undefined;
  };

  return (
    <>
      <ReactHookDsmSelect
        changeHandler={() => changeHandler && changeHandler()}
        label={labelSelect}
        placeholder={placeholderSelect}
        name={fieldTypeName}
        options={options}
        required
        disabled={formType === FormType.View}
        defaultValue={deafultValueType}
        tooltip={tooltipType}
        adornment=" "
        key={`key-for${typeFieldName}-${keyName || ''}`}
      />
      <ReactHookDsmInput
        changeHandler={() => changeHandler && changeHandler()}
        defaultValue={defaultValueAmount}
        key={`key-for${amountFieldName}-${keyName || ''}`}
        name={fieldAmountName}
        label={labelInput}
        type="number"
        required
        tooltip={tooltipUse}
        adornment={intl.formatMessage(
          { id: 'SUSTELL.INPUT.TYPE_AMOUNT.UNIT_PER_YEAR' },
          {
            unit:
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              getUnit(formContext.getValues(fieldTypeName)) || placeholderUnit,
          }
        )}
        disabled={formType === FormType.View}
      />
    </>
  );
};

export default SustellTypeAmountFields;
