import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';

import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { ExternalSourcesInput } from '../../../../models/Baseline/PigBaseline';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';

interface InterventionExternalSourcesInputProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  item: ExternalSourcesInput;
}

const InterventionExternalSourcesInput: FC<
  InterventionExternalSourcesInputProps
> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  // stageType,
  item,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;

  const formContext = useFormContext();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.externalSources.${itemIndex}`;

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitBarnOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass as string)
    : ('kg' as string);

  const distanceUnit = unitLong(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    userUOM.unitTransportDistanceTerrestrial as string
  );

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        type="hidden"
        value={item.id}
        ref={formContext.register()}
        name={`${fieldItemPrefix}.id`}
      />

      <DsmGrid className={classes.additionalEntriesBlockHolderIntervention}>
        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.numberPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NEW_ANIMALS',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.NEW_ANIMALS.TOOLTIP',
          })}
          type="number"
          metricUnit=""
          baseline={item.numberPigs}
          disabled={formType === FormType.View}
        />

        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageAgeOfPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVG_AGE',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.AVG_AGE.TOOLTIP',
          })}
          metricUnit={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.AVG_AGE.UNIT',
          })}
          type="number"
          baseline={item.averageAgeOfPigs}
          disabled={formType === FormType.View}
        />

        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeightOfPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVG_WEIGHT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.AVG_WEIGHT.TOOLTIP',
          })}
          type="number"
          metricUnit={barnOutputMassUnit}
          precision={barnOutputMassPrecision}
          baseline={item.averageWeightOfPigs}
          disabled={formType === FormType.View}
        />

        <RowControlledTextFieldWithMetrics
          name={`${fieldItemPrefix}.distanceTransport`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.TRANSPORT_DISTANCE',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERVENTION.TRANSPORT_DISTANCE.TOOLTIP',
          })}
          type="number"
          metricUnit={distanceUnit}
          baseline={item.distanceTransport}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
    </>
  );
};

export default InterventionExternalSourcesInput;
