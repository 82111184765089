import { EmissionsContainer, EmissionEntryModel } from "../internal";
import { StageEmissions } from "../FootprintTypes";
import WarningErrorModel from "../WarningError";

/** Emissons per stage / aggregated emission data */
class EmissionsStageModel {

  readonly parentContainer: EmissionsContainer;

  readonly id: string;

  numberOfAnimals: number;

  name: string;

  emissions: EmissionEntryModel[] = [];

  isAgregatedData = false;

  errors: WarningErrorModel[] = [];

  isMMSystemEmissions: boolean = false;

  constructor(emissionConainer: EmissionsContainer, data?: StageEmissions, isAgregatedData?: boolean, isMMSEmissions?: boolean) {
    this.numberOfAnimals = data?.baseline_number_of_animals || 0;
    this.name = data?.name || '';
    this.parentContainer = emissionConainer;
    this.id = data?.id || '';
    if (isMMSEmissions) {
      this.isMMSystemEmissions = isMMSEmissions;
      (data?.mmsEmissions || []).forEach(mmsEmi => {
        const mmsEmiModels = (mmsEmi?.emissions || []).map(em => new EmissionEntryModel(em, this));
        this.emissions.push(...mmsEmiModels);
      });
    } else {
      this.emissions = (data?.emissions || []).map(em => new EmissionEntryModel(em, this));
    }
    if (isAgregatedData !== undefined) {
      this.isAgregatedData = isAgregatedData;
    }
    this.errors = WarningErrorModel.fromEmissionsStages(this, this.parentContainer.footprint);
  }

  // TODO: change param to match the stage if needed later
  /** Get emission group that is compared to this, if  stage name is not provided look at all data */
  getComparison(stageName?: string): EmissionsStageModel | undefined {
    if (!this.parentContainer.comparison) {
      return undefined;
    }
    if (this.isAgregatedData && !this.isMMSystemEmissions) {
      return this.parentContainer.comparison.agregatedEmissions;
    }
    if (this.isAgregatedData && this.isMMSystemEmissions) {
      return this.parentContainer.comparison.mmsAgregatedEmissions;
    }
    if (!stageName) {
      return undefined;
    }
    return this.parentContainer.comparison.emissionStages.find(stage => stage.name === stageName);
  }

  /** Append values for stage */
  appendStage(stage: EmissionsStageModel, changeParent: EmissionsStageModel) {
    this.numberOfAnimals += stage.numberOfAnimals;
    (stage.emissions || []).forEach(emissionEntry => {
      const entry: EmissionEntryModel = emissionEntry.clone();
      entry.parent = changeParent; // set parent to all agregated for right comparison match

      const existing = (this.emissions || []).find(item => item.emission === entry.emission);
      
      // If it doesn't exist append it
      if (!existing) {
        this.emissions.push(entry);
      // Otherwise add values for all emissions
      } else {
        existing.appendEntryValues(emissionEntry);
      }
    });
  }
}

export default EmissionsStageModel;