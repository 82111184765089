import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { resolvePath } from "./resolvePathFn";

const ReactHookFormSelect = ({
  name,
  defaultValue='',
  label,
  control,
  children,
  marginBottom='20px',
  ...props
}) => {
  const labelId = `${name}-label`;
  const changeHandler = props.onChange;
  const optionsAvailable = children?.length > 0;
  const { errors } = useFormContext();
  const localError = resolvePath(errors, name, null);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value, name, ref }) => {
        // console.log("SELECT ",name, value," ",defaultValue);
        // if(!value && defaultValue) value=defaultValue;
        return (
          <FormControl {...props} style={{ ...{marginBottom: marginBottom}, ...props.style }}>
            <InputLabel id={labelId} error={localError ? true : false}>{label}</InputLabel>
            <Select
              name={name}
              label={label}
              value={value && optionsAvailable ? value : ''}
              onBlur={onBlur}
              error={localError ? true : false}
              onChange={(e) => {
                if (changeHandler) changeHandler(e);
                onChange(e);
              }
              }
            >
              {children}
            </Select>
            <FormHelperText error={localError ? true : false} >{ localError ? (localError.message) : '' }</FormHelperText>
          </FormControl>
        )
      }
      }
    />
  );
};
export default ReactHookFormSelect;