import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from "@material-ui/core";

import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup";

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselinePropertyValue, PigFatteningBaseline, PigFatteningOutput } from '../../../../models/Baseline';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { BaselineDialogProps, FormType } from '../../common';

const PFOutputFormDialog: FC<BaselineDialogProps> = ( {
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel,
  handleSave=handleCancel
} ) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PigFatteningOutput>();

  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  // const { getValues, reset } = useFormContext();
  const formContext = useFormContext();

  useEffect(() => {
    if(formVisible) {
      currResetValue.current={...formContext.getValues(fieldItemPrefix)} as PigFatteningOutput; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as PigFatteningBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.output){
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        formContext.reset(resetObject, {
          errors: true
        });
      }
    }
    handleCancel();
  }

  return (
    <DialogContainer
      formVisible = { formVisible }
      handleClose = { handleResetClick }
      iconCode="PIG_FATTENING"
      formTitle = { formTitle }
    > 
      <Grid container direction="column" spacing={3} justifyContent="space-between" >
        <Grid item xs={12} >
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.liveWeight`}
              label={ `${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.PIGS.LIVEWEIGHT"})} *` }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.PIGS.LIVEWEIGHT.TOOLTIP"}) }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.liveWeight`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={12} >  
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.inorganicNFertilizerReplacementRate`}
              label={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.PIGS.INORGANIC_N_REPL_RATE"}) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.PIGS.INORGANIC_N_REPL_RATE.TOOLTIP"}) }
              addornment="%"
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.inorganicNFertilizerReplacementRate`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={12} >  
          <RowTextFieldWithInfo
              item xs={12}
              name={`${fieldItemPrefix}.inorganicPFertilizerReplacementRate`}
              label={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.PIGS.INORGANIC_P_REPL_RATE"}) }
              margin="none"
              variant="outlined"
              tooltip={ intl.formatMessage({id: "BASELINE.FORM.OUTPUT.PIGS.INORGANIC_P_REPL_RATE.TOOLTIP"}) }
              addornment="%"
              disabled={formType === "view"}
              defaultValue = {formContext.getValues(`${fieldItemPrefix  }.inorganicPFertilizerReplacementRate`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleSave }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" })  }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default PFOutputFormDialog;