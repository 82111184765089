import lookupValues from '../../helpers/lookupValues';

export type CalculatedImpactDataCategoryComponent = {
  name: string;
  impact: number;
  impactAbsolute: number;
  label: string;
  lookupKey: string;
  analysisGroup?: string;
  subCategories?: SubCategory[];
};

export interface SubCategory {
  subCategoryName: string;
  subCategoryGroup: string;
  subCategoryData: {
    name: string;
    label: string;
    impact: number;
    impactAbsolute: number;
  }[];
}

export type CalculatedImpactDataCategory = {
  components: CalculatedImpactDataCategoryComponent[];
  impact: { unit: string; value: number };
};

export enum CompartmentType {
  AIR = 'Air',
  LAND = 'Land',
  HUMAN = 'Human',
  WATER = 'Water',
}

export enum FootprintFilterType {
  CATEGORY = 'Category',
  ANALYSIS_GROUP = 'Analysis group',
}

// FIXME: Or should we use enums? https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
export enum FootprintCategoryKey {
  CLIMATE_CHANGE = 'Climate change',
  CLIMATE_CHANGE_BIOGENIC = 'Climate change - biogenic',
  CLIMATE_CHANGE__BIOGENIC = 'Climate change - Biogenic',
  CLIMATE_CHANGE_FOSSIL = 'Climate change - fossil',
  CLIMATE_CHANGE__FOSSIL = 'Climate change - Fossil',
  CLIMATE_CHANGE__LAND_USE_AND_LU_CHANGE = 'Climate change - Land use and LU change',
  CLIMATE_CHANGE_LAND_USE = 'Climate change - land use and transform.',
  // CLIMATE_CHANGE_PEAT_OXIDATION = 'Climate change - Peat oxidation',

  LAND_USE = 'Land use',
  RESOURCE_USE_FOSSILS = 'Resource use, fossils',
  RESOURCE_USE_ENERGY_CARRIERS = 'Resource use, energy carriers',
  RESOURCE_USE_MINERAL_AND_METALS = 'Resource use, mineral and metals',
  RESOURCE_USE_MINERALS_AND_METALS = 'Resource use, minerals and metals',
  EUTROPHICATION_TERRESTRIAL = 'Eutrophication terrestrial',
  EUTROPHICATION_TERRESTRIAL_ = 'Eutrophication, terrestrial',
  ACIDIFICATION = 'Acidification',
  ACIDIFICATION_TERRESTRIAL_AND_FRESHWATER = 'Acidification terrestrial and freshwater',

  PARTICULATE_MATTER = 'Particulate matter',
  IONISING_RADIATION = 'Ionising radiation',
  IONISING_RADIATION_HH = 'Ionising radiation, HH',
  OZONE_DEPLETION = 'Ozone depletion',
  CANCER_HUMAN_HEALTH_EFFECTS = 'Cancer human health effects',
  HUMAN_TOXICITY_CANCER = 'Human toxicity, cancer',
  HUMAN_TOXICITY_CANCER__INORGANICS = 'Human toxicity, cancer - inorganics',
  HUMAN_TOXICITY_CANCER__ORGANICS = 'Human toxicity, cancer - organics',
  RESPIRATORY_INORGANICS = 'Respiratory inorganics',
  HUMAN_TOXICITY_NONCANCER = 'Human toxicity, non-cancer',
  HUMAN_TOXICITY_NONCANCER__ORGANICS = 'Human toxicity, non-cancer - organics',
  HUMAN_TOXICITY_NONCANCER__INORGANICS = 'Human toxicity, non-cancer - inorganics',
  NON_CANCER_HUMAN_HEALTH_EFFECTS = 'Non-cancer human health effects',
  PHOTOCHEMICAL_OZONE_FORMATION = 'Photochemical ozone formation',
  PHOTOCHEMICAL_OZONE_FORMATION_HH = 'Photochemical ozone formation, HH',

  WATER_USE = 'Water use',
  WATER_SCARCITY = 'Water scarcity',
  EUTROPHICATION_FRESH_WATER = 'Eutrophication freshwater',
  EUTROPHICATION_FRESHWATER = 'Eutrophication, freshwater',
  EUTROPHICATION_MARINE = 'Eutrophication marine',
  EUTROPHICATION_MARINE_ = 'Eutrophication, marine',
  ECOTOXICITY_FRESHWATER = 'Ecotoxicity, freshwater',
  ECOTOXICITY_FRESHWATER_ = 'Ecotoxicity freshwater',
  ECOTOXICITY_FRESHWATER__ORGANICS = 'Ecotoxicity, freshwater - organics',
}

// the key is from FOOTPRINT_CATEGORIES value, while values are
// keys FOOTPRINT_CATEGORIES that corresond to that value in FOOTPRINT_CATEGORIES
// i.e. reversed FOOTPRINT_CATEGORIES
// one with single name ar not needed
export const SAME_FOOTPRINT_CATEGORIES = {
  'Climate change - Biogenic': [
    'Climate change - biogenic',
    'Climate change - Biogenic',
  ],
  'Climate change - Fossil': [
    'Climate change - fossil',
    'Climate change - Fossil',
  ],
  'Climate change - Land use and LU change': [
    'Climate change - Land use and LU change',
    'Climate change - land use and transform.',
  ],
  'Resource use, fossils': [
    'Resource use, fossils',
    'Resource use, energy carriers',
  ],
  'Resource use, minerals and metals': [
    'Resource use, mineral and metals',
    'Resource use, minerals and metals',
  ],
  'Eutrophication, terrestrial': [
    'Eutrophication terrestrial',
    'Eutrophication, terrestrial',
  ],
  'Cancer human health effects': [
    'Cancer human health effects',
    'Human toxicity, cancer',
  ],
  Acidification: ['Acidification', 'Acidification terrestrial and freshwater'],
  'Particulate matter': ['Particulate matter', 'Respiratory inorganics'],
  'Ionising radiation': ['Ionising radiation', 'Ionising radiation, HH'],
  'Human toxicity, non-cancer': [
    'Human toxicity, non-cancer',
    'Non-cancer human health effects',
  ],
  'Photochemical ozone formation, HH': [
    'Photochemical ozone formation',
    'Photochemical ozone formation, HH',
  ],
  'Water use': ['Water use', 'Water scarcity'],
  'Eutrophication, freshwater': [
    'Eutrophication, freshwater',
    'Eutrophication freshwater',
  ],
  'Eutrophication, marine': ['Eutrophication, marine', 'Eutrophication marine'],
  'Ecotoxicity, freshwater': [
    'Ecotoxicity, freshwater',
    'Ecotoxicity freshwater',
  ],
};

export const FOOTPRINT_CATEGORIES = {
  'Climate change': 'Climate change',
  'Climate change - biogenic': 'Climate change - Biogenic',
  'Climate change - Biogenic': 'Climate change - Biogenic',
  'Climate change - fossil': 'Climate change - Fossil',
  'Climate change - Fossil': 'Climate change - Fossil',
  'Climate change - Land use and LU change':
    'Climate change - Land use and LU change',
  'Climate change - land use and transform.':
    'Climate change - Land use and LU change',
  'Land use': 'Land use',
  'Resource use, fossils': 'Resource use, fossils',
  'Resource use, energy carriers': 'Resource use, fossils',
  'Resource use, mineral and metals': 'Resource use, minerals and metals',
  'Resource use, minerals and metals': 'Resource use, minerals and metals',
  'Eutrophication terrestrial': 'Eutrophication, terrestrial',
  'Eutrophication, terrestrial': 'Eutrophication, terrestrial',
  Acidification: 'Acidification',
  'Acidification terrestrial and freshwater': 'Acidification',
  'Particulate matter': 'Particulate matter',
  'Respiratory inorganics': 'Particulate matter',
  'Ionising radiation': 'Ionising radiation',
  'Ionising radiation, HH': 'Ionising radiation',
  'Ozone depletion': 'Ozone depletion',
  'Cancer human health effects': 'Cancer human health effects',
  'Human toxicity, cancer - organics': 'Human toxicity, cancer - organics',
  'Human toxicity, cancer - inorganics': 'Human toxicity, cancer - inorganics',
  'Human toxicity, cancer - metals': 'Human toxicity, cancer - metals',
  'Human toxicity, non-cancer - organics':
    'Human toxicity, non-cancer - organics',
  'Human toxicity, non-cancer - inorganics':
    'Human toxicity, non-cancer - inorganics',
  'Human toxicity, non-cancer - metals': 'Human toxicity, non-cancer - metals',
  'Human toxicity, cancer': 'Cancer human health effects',
  'Human toxicity, non-cancer': 'Human toxicity, non-cancer',
  'Non-cancer human health effects': 'Human toxicity, non-cancer',
  'Photochemical ozone formation': 'Photochemical ozone formation, HH',
  'Photochemical ozone formation, HH': 'Photochemical ozone formation, HH',
  'Water use': 'Water use',
  'Water scarcity': 'Water use',
  'Eutrophication freshwater': 'Eutrophication, freshwater',
  'Eutrophication, freshwater': 'Eutrophication, freshwater',
  'Eutrophication marine': 'Eutrophication, marine',
  'Eutrophication, marine': 'Eutrophication, marine',
  'Ecotoxicity, freshwater': 'Ecotoxicity, freshwater',
  'Ecotoxicity freshwater': 'Ecotoxicity, freshwater',
  'Ecotoxicity, freshwater - organics': 'Ecotoxicity, freshwater - organics',
  'Ecotoxicity, freshwater - inorganics':
    'Ecotoxicity, freshwater - inorganics',
  'Ecotoxicity, freshwater - metals': 'Ecotoxicity, freshwater - metals',
};

type ImpactCategoryKey = keyof typeof FOOTPRINT_CATEGORIES;
export type FootprintCategoryName =
  typeof FOOTPRINT_CATEGORIES[ImpactCategoryKey];

export const getFootprintCategoryTitleIndex = (
  key: string
):
  | {
      title: FootprintCategoryName;
      index: number;
    }
  | undefined => {
  const index = Object.keys(FOOTPRINT_CATEGORIES).indexOf(key);
  const title = FOOTPRINT_CATEGORIES[key as ImpactCategoryKey];
  if (index < 0 || !title) {
    return undefined;
  }
  return {
    title,
    index,
  };
};

export const getCompartmentsForImpactCategory = (
  category: FootprintCategoryName
) =>
  Object.entries(lookupValues.impactCategoriesMapping.all).find(
    ([key, _]) => key === category
  )?.[1] || [];

// new categories (namings)
// ECOTOXICITY_FRESHWATER = 'Ecotoxicity, freshwater',
// ECOTOXICITY_FRESHWATER__ORGANICS = 'Ecotoxicity, freshwater - organics',
// CLIMATE_CHANGE__FOSSIL = 'Climate change - Fossil',
// HUMAN_TOXICITY_NONCANCER__ORGANICS = 'Human toxicity, non-cancer - organics',
// ACIDIFICATION = 'Acidification',
// WATER_USE = 'Water use',
// HUMAN_TOXICITY_CANCER__INORGANICS = 'Human toxicity, cancer - inorganics',
// HUMAN_TOXICITY_NONCANCER__INORGANICS = 'Human toxicity, non-cancer - inorganics',
// RESOURCE_USE_FOSSILS = 'Resource use, fossils',
// CLIMATE_CHANGE__LAND_USE_AND_LU_CHANGE = 'Climate change - Land use and LU change',
// HUMAN_TOXICITY_CANCER = 'Human toxicity, cancer',
// PHOTOCHEMICAL_OZONE_FORMATION = 'Photochemical ozone formation',
// HUMAN_TOXICITY_NONCANCER = 'Human toxicity, non-cancer',
// EUTROPHICATION_FRESHWATER = 'Eutrophication, freshwater',
// EUTROPHICATION_MARINE = 'Eutrophication, marine',
// RESOURCE_USE_MINERALS_AND_METALS = 'Resource use, minerals and metals',
// EUTROPHICATION_TERRESTRIAL = 'Eutrophication, terrestrial',
// PARTICULATE_MATTER = 'Particulate matter',
// IONISING_RADIATION = 'Ionising radiation',
// CLIMATE_CHANGE__BIOGENIC = 'Climate change - Biogenic',
// HUMAN_TOXICITY_CANCER__ORGANICS = 'Human toxicity, cancer - organics',

export type FootprintDistributionElement = {
  analysisGroup: string;
  analysisGroupLabel: string;
  percentage: number;
  amount?: number; // Used for dashboard, amount from total value
};

// -------EMISSIONS---------
export interface StageEmissions {
  baseline_number_of_animals: number;
  comparison_number_of_animals?: number;
  name: string;
  id: string;
  emissions: EmissionEntry[];
  mmsEmissions: MmsEmissionEntry[];
}

export interface MmsEmissionEntry {
  type: string;
  emissions: EmissionEntry[];
}

export interface EmissionEntry {
  unit?: string;
  emission: string;
  tier_level?: string;
  is_valid: boolean;
  value?: number;
  errors?: ErrorEntry[];
  used_intermediates: UsedIntermediateEntry[];
  source?: string;
  used_data: UsedDataEntry[];
  source_link?: string;
}

export interface ErrorEntry {
  error_message: string;
  error_type?: string;
}

export interface UsedDataEntry {
  name: string;
  displayName?: string;
  value: string;
  change?: number;
  comparedValue?: string;
}

export interface UsedIntermediateEntry {
  unit: string;
  is_valid: boolean;
  type: string;
  change?: number;
  value?: number | string;
  errors?: ErrorEntry[];
  comparedValue?: number | string;
}

export const emissionSourceLinksMap: Map<string | undefined, string> = new Map([
  [
    'IPCC 2006',
    'https://www.ipcc-nggip.iges.or.jp/public/2006gl/pdf/4_Volume4/V4_10_Ch10_Livestock.pdf',
  ],
  [
    'EMEP/EEA 2016',
    'https://www.eea.europa.eu/publications/emep-eea-guidebook-2016/part-b-sectoral-guidance-chapters/4-agriculture/3-b-manure-management-2016/view',
  ],
  ['LEAP 2016', 'http://www.fao.org/3/a-i6421e.pdf'],
  [
    'ReCiPe 2008',
    'https://www.rivm.nl/sites/default/files/2018-11/ReCiPe%202008_A%20lcia%20method%20which%20comprises%20harmonised%20category%20indicators%20at%20the%20midpoint%20and%20the%20endpoint%20level_First%20edition%20Characterisation.pdf',
  ],
  [
    'Nemecek and Kagi 2007',
    'https://db.ecoinvent.org/reports/15_Agriculture.pdf',
  ],
]);

export enum CalcType {
  Total = 'Total',
  PerAnimal = 'PerAnimal',
}

export enum PeriodType {
  Round = 'Round',
  Year = 'Year',
}
export interface EmissionsPeriod {
  unit: PeriodType;
  number_of_rounds_per_year: {
    unit: string;
    value: number;
  };
}

export interface WarningEntry {
  warnings: string[];
  name: string;
}

export interface WarningCollection {
  collection: WarningEntry[];
  type: string;
}

export interface WarningCollectionV2 {
  message: string;
  type: string;
}
export interface WarningResponse {
  collections: WarningCollection[] | WarningCollectionV2[];
  warnings: string[];
  name: string;
  proxyDataUsed?: boolean;
}

export interface CalculationErrorEntry {
  key: string;
  typeMessage: string;
  value?: string;
  comparedValue?: string;
}

export interface EmissionOptions {
  periodType: PeriodType;
  calcType: CalcType;
}

export interface SimulationDistributionChange {
  analysisGroup: string;
  change: number;
}

export interface ErrorInfoV2 {
  msg: string;
  loc?: (string | number)[];
}

export interface ErrorInfoWithCodeV2 {
  messageCode: string;
  externalMsg?: string;
  location?: (string | number)[];
  messageValues: {
    [key: string]: string | number | object | object[];
  };
}
export interface ErrorResponseItemV2 {
  errorInfo?: (ErrorInfoV2 | ErrorInfoWithCodeV2)[];
  errorType?: string;
  message?: string;
}
export interface ValidationErrorCode {
  message: string;
  code: string;
}

export interface MessageTextCode {
  // allready prepared text message
  text: string;
  // i18n code
  code?: string | null;
  // values for the code
  values?: {
    [key: string]: string;
  } | null;
}

export const BLONK_ERROR_CODE_PREFIX = 'BLONK_VALIDATION_ERROR';
