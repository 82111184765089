import { FC, useState } from 'react';

import {
  DsmButton,
  DsmGrid,
  DsmIcon,
  DsmSectionHeader,
} from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { AnimalType, Facility, StageType } from '../../../../graphql/types';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../modules/Helpers/ReactHookDsmSelect2';
import { enumToOptionsArrayWithTranslatedStrings } from '../../utils/obj-utils';
import StageActionsMenu from '../pageparts/StageActionsMenu';
import { FormType } from './common';
import {
  filterFacilitiesByType,
  isProcessingStage,
  mountFacilityOptions,
} from '../../helpers/facilities';
import { 
  filterStageTypesByAnimalType,
  isShrimp, 
} from '../../helpers/animals';

// TODO Typescript depends on MaterialThemeProvider.js
type StylesAny = any;

interface StagesListComponentProps {
  formType: FormType;
  stageDeletehandler: (index: number, name: string, type: StageType) => void;
  selectedStages: Array<any>;
  facilities?: Facility[];
  animalType: AnimalType;
  isMandatoryFirstStage: (index: number) => boolean;
}

const StagesListComponent: FC<StagesListComponentProps> = ({
  stageDeletehandler = undefined,
  formType = FormType.View,
  selectedStages,
  facilities,
  animalType,
  isMandatoryFirstStage,
}) => {
  const intl = useIntl();
  const { getValues, setValue } = useFormContext();
  const [stageOpenIndex, setOpenStageIndex] = useState<number>(-1);

  const cancelInlineEditHandler = () => {
    if (stageOpenIndex !== -1) {
      // console.log("VALUE::::",stageOpenIndex, getValues(`stages[${inlineStageEditorOpened}].name`));
      setOpenStageIndex(-1);
    }
  };

  const saveInlineEditHandler = () => {
    if (stageOpenIndex !== -1) {
      const newName = getValues(`stages[${stageOpenIndex}].tempName`);

      if (newName) {
        setValue(`stages[${stageOpenIndex}].name`, newName);
        selectedStages[stageOpenIndex].name = newName;
      }

      const newFacilityId = getValues(
        `stages[${stageOpenIndex}].tempFacilityId`
      );
      if (newFacilityId) {
        setValue(`stages[${stageOpenIndex}].facilityId`, newFacilityId);
        selectedStages[stageOpenIndex].facilityId = newFacilityId;
      }

      const newPondName = getValues(`stages[${stageOpenIndex}].tempPondName`);

      if (newPondName) {
        setValue(`stages[${stageOpenIndex}].pondName`, newPondName);
        selectedStages[stageOpenIndex].pondName = newPondName;
      }

      setOpenStageIndex(-1);
    }
  };

  const getFacilityOptionsByType = (facilityId: string) => {
    if (!facilities?.length) return [];
    const facilityType = facilities.find((f) => f.id === facilityId)?.type;
    if (!facilityType) return [];

    const filteredFacilities = filterFacilitiesByType(facilities, facilityType);
    return mountFacilityOptions(filteredFacilities, intl);
  };

  const isStageEdition = (currentStageIndex: number): boolean =>
    stageOpenIndex === currentStageIndex;

  return (
    <div>
      {selectedStages?.map((stage, index) => (
        <div key={stage.keyId}>
          {!isStageEdition(index) && (
            <DsmGrid
              style={{
                grid: 'auto/1fr auto',
                justifyContent: 'space-between',
                padding: '0',
                alignItems: 'end',
              }}
            >
              <DsmSectionHeader
                style={{ marginTop: `var(--dsm-spacing-px-16)` }}
                header={stage.name}

                // description={intl.formatMessage({
                //   id: 'SUSTELL.PRODUCTION_PROCESS.DETAILS',
                // })}
              />
              <StageActionsMenu
                formType={formType}
                deleteHandler={
                  stageDeletehandler && !isMandatoryFirstStage(index)
                    ? () => stageDeletehandler(index, stage.name, stage.type)
                    : undefined
                }
                editHandler={() => setOpenStageIndex(index)}
              />
            </DsmGrid>
          )}

          {isStageEdition(index) && (
            <DsmGrid
              style={{
                grid: 'auto/2fr 1fr',
                padding: '0px',
                alignItems: 'center',
                marginTop: 'var(--dsm-spacing-px-16)',
              }}
            >
              <DsmGrid
                style={isShrimp(animalType) ? {
                  grid: 'auto/1fr 1fr 1fr',
                  padding: '0px',
                  alignItems: 'start',
                }: {
                  grid: 'auto/1fr 1fr',
                  padding: '0px',
                  alignItems: 'start',
                }}
              >
                <ReactHookDsmSelect
                  name={`stages[${index}].type`}
                  label={intl.formatMessage({ id: 'SUSTELL.STAGE.TYPE' })}
                  disabled
                  required
                  options={enumToOptionsArrayWithTranslatedStrings(
                    filterStageTypesByAnimalType(animalType),
                    intl,
                    (isShrimp(animalType) ? 'SUSTELL.SHRIMP.ENUMS.STAGE.TYPE' : 'SUSTELL.ENUMS.STAGE.TYPE')
                  )}
                  defaultValue={getValues(`stages[${index}].type`)}
                />

                <ReactHookDsmInput
                  name={`stages[${index}].tempName`}
                  label={intl.formatMessage({ id: 'SUSTELL.FIELD_STAGE.NAME' })}
                  required
                  disabled={formType === FormType.View}
                  defaultValue={getValues(`stages[${index}].name`)}
                />

                {isProcessingStage(stage.type) && (
                  <ReactHookDsmSelect
                    name={`stages[${index}].tempFacilityId`}
                    label={intl.formatMessage({
                      id: 'SUSTELL.STAGE.FACILITY_NAME',
                    })}
                    disabled={formType === 'view'}
                    defaultValue={getValues(`stages[${index}].facilityId`)}
                    options={getFacilityOptionsByType(stage.facilityId)}
                    adornment=" "
                    tooltip={intl.formatMessage({
                      id: 'SUSTELL.STAGE.FACILITY_NAME.TT',
                    })}
                  />
                )}
                {isShrimp(animalType) && (
                  <ReactHookDsmInput
                    name={`stages[${index}].tempPondName`}
                    label={intl.formatMessage({
                      id: 'SUSTELL.FIELD_STAGE.POND_NAME',
                    })}
                    disabled={formType === FormType.View}
                    defaultValue={getValues(`stages[${index}].pondName`)}
                    adornment=" "
                    tooltip={intl.formatMessage({
                      id: 'SUSTELL.FIELD_STAGE.POND_NAME.TOOLTIP',
                    })}
                  />
                )}
              </DsmGrid>
              <DsmGrid
                style={{
                  grid: 'auto/1fr auto',
                  padding: '0px',
                  columnGap: 'var(--dsm-spacing-px-2',
                  justifyItems: 'end',
                }}
              >
                <DsmButton
                  variant="secondary"
                  destructive={false}
                  onClick={cancelInlineEditHandler}
                >
                  {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                </DsmButton>
                <DsmButton variant="primary" onClick={saveInlineEditHandler}>
                  {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                  <DsmIcon slot="after" name="arrows/chevron-right" />
                </DsmButton>
              </DsmGrid>
            </DsmGrid>
          )}
        </div>
      ))}
    </div>
  );
};

export default StagesListComponent;
