import { FC } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { DsmIcon } from "@dsm-dcs/design-system-react";

type ErrorMessageComponentProps = {
  onClose: () => void;
  messages: Array<string>;
};

const ErrorMessageComponent: FC<ErrorMessageComponentProps> = ({
  onClose,
  messages,
}) => (
  <div
    style={{
      display: "flex",
      backgroundColor: "var(--dsm-color-error-lightest)",
      border: "1px solid var(--dsm-color-error)",
      borderRadius: "var(--dsm-spacing-px-1)",
      margin: 0,
      padding: "var(--dsm-spacing-px-4)",
      justifyContent: "space-between",
    }}
  >
    <div style={{ display: "flex" }}>
      <ErrorOutlineIcon
        fontSize="medium"
        color="error"
        style={{ alignSelf: "center", marginRight: "var(--dsm-spacing-px-3)" }}
      />
      <div>
        {messages.map((message) => (
          <p style={{ margin: 0, padding: 0, color: "var(--dsm-color-error)" }}>
            {message}
          </p>
        ))}
      </div>
    </div>
    <DsmIcon
      name="general/x-close"
      size="sm"
      style={{ color: "var(--dsm-color-error)", cursor: "pointer" }}
      onClick={onClose}
    />
  </div>
);

export default ErrorMessageComponent;