// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { cloneDeep } from 'lodash';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, PoultryFarms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import {
  PoultryBaseline,
  PoultryInputLaying,
} from '../../../../models/Baseline/PoultryBaseline';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import PoultryInputPartExternalSource from './PoultryInputPartExternalSource';
import PoultryInputPartInternalSource from './PoultryInputPartInternalSource';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
  marginTop: '10px',
  marginLeft: '10px',
  cursor: 'pointer',
};

export interface PoultryInputDialogProps extends BaselineDialogProps {
  farms: PoultryFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
  validate: (param?: string | null) => void;
  animalType: string;
}

const PoultryInputLayingDialog: FC<PoultryInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  validate,
  animalType,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<PoultryInputLaying>();
  const fc = useFormContext<PoultryBaseline>();
  const [sourceSelected, setSourceSelected] = useState<boolean>(true);
  const initialSourceSelected = useRef<boolean>(true);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.input) {
        resetObject.stages[itemIndex].stageData.input = {
          ...currResetValue.current,
        } as PoultryInputLaying;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
    if (initialSourceSelected.current)
      fc.setValue(`${fieldItemPrefix}.externalSource`, null);
    else fc.setValue(`${fieldItemPrefix}.internalSource`, null);
  };

  const handleSaveClick = async () => {
    handleCancel();
    await fc.trigger(fieldItemPrefix);
    if (sourceSelected) {
      fc.setValue(`${fieldItemPrefix}.externalSource`, null);
      fc.clearErrors(`${fieldItemPrefix}.externalSource`);
    } else {
      fc.setValue(`${fieldItemPrefix}.internalSource`, null);
      fc.clearErrors(`${fieldItemPrefix}.internalSource`);
    }
    validate('confirm');
  };

  useEffect(() => {
    if (formVisible) {
      const selected: boolean = fc.getValues(`${fieldItemPrefix}.selected`);
      if (selected === false) {
        setSourceSelected(false);
        initialSourceSelected.current = false;
        fc.setValue(`${fieldItemPrefix}.internalSource`, {
          id: uuidv4(),
          farmId: '',
          originStageId: '',
          numberAnimals: '',
          transportDistance: '',
        });
      } else {
        setSourceSelected(true);
        initialSourceSelected.current = true;
        fc.setValue(`${fieldItemPrefix}.externalSource`, {
          id: uuidv4(),
          numberAnimals: '',
          averageWeight: '',
          transportDistance: '',
        });
      }
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
      })}
      variant="wide"
      introText={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.BREEDING_DESCRIPTION',
      })}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.START_DATE',
          })}
          tooltip={intl.formatMessage(
            {
              id: 'SUSTELL.STAGE.POULTRY.INPUT.START_DATE.TOOLTIP',
            },
            { stageType: 'laying' }
          )}
          disabled={formType === FormType.View}
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.startDate`)}
        />
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.END_DATE',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage(
            {
              id: 'SUSTELL.STAGE.POULTRY.INPUT.END_DATE.TOOLTIP',
            },
            { stageType: 'laying' }
          )}
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.endDate`)}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div
            style={{
              marginBottom: 'var(--dsm-spacing-px-4)',
            }}
          >
            <input
              type="radio"
              name={`${fieldItemPrefix}.selected`}
              id="internalSource"
              defaultChecked={sourceSelected}
              onClick={() => {
                fc.setValue(`${fieldItemPrefix}.selected`, true);
                setSourceSelected(true);
                fc.clearErrors(`${fieldItemPrefix}.externalSource`);
              }}
            />
            <label htmlFor="internalSource" style={headingTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            <PoultryInputPartInternalSource
              farms={farms}
              farmId={farmId}
              farmName={farmName}
              allowedStagesForFarm={[StageType.Growing]}
              productionProcessName={productionProcessName}
              stageIndex={itemIndex}
              propName="internalSource"
              stageType={StageType.Laying}
              intlPrefix={StageType.Laying}
              formType={formType}
              sourceSelected={sourceSelected}
              animalType={animalType}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              marginBottom: 'var(--dsm-spacing-px-4)',
            }}
          >
            <input
              type="radio"
              name={`${fieldItemPrefix}.selected`}
              id="externalSource"
              defaultChecked={!sourceSelected}
              onClick={() => {
                fc.setValue(`${fieldItemPrefix}.selected`, false);
                setSourceSelected(false);
                fc.clearErrors(`${fieldItemPrefix}.internalSource`);
              }}
            />
            <label htmlFor="externalSource" style={headingTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            <PoultryInputPartExternalSource
              stageIndex={itemIndex}
              propName="externalSource"
              intlPrefix={StageType.Laying}
              formType={formType}
              sourceSelected={!sourceSelected}
              stageType={StageType.Laying}
            />
          </div>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => handleSaveClick()}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryInputLayingDialog;
