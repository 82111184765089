import { API } from 'aws-amplify';
import { calculateBovaer3NOP } from '../../../graphql/queries';
import { BovaerAnimalType, Maybe } from '../../../graphql/types';

export interface InputData3NOP {
  animalType?: BovaerAnimalType;
  country?: string;
  ndfPercentageOfDmi?: number;
  numberOfAnimals: number;
  dryMatterIntakeKg: number;
  dosage?: Maybe<number>;
}
const calculate3NOPForBovaer = async (
  input: InputData3NOP,
  averageAnimalsPresent: number
) => {
  // First convert dosage to PPM (all validations are checked before the call)
  const inputWithDosage = input;
  if(input.dosage != null) {
    const dosage = Math.round((input.dosage * 1000) / input.dryMatterIntakeKg);
    inputWithDosage.dosage = dosage;
  }
  const queryWithVars = {
    query: calculateBovaer3NOP,
    variables: {
      input: inputWithDosage,
    },
  };
  const result = await (API.graphql(queryWithVars) as Promise<{
    data: {
      calculateBovaer3NOP: {
        reductionPercentage: number;
        warnings?: Maybe<string[]>;
      };
    };
  }>);

  if (result.data?.calculateBovaer3NOP?.reductionPercentage) {
    // Correct for the number of cows on 3nop and switch from reduction to correction (negative)
    result.data.calculateBovaer3NOP.reductionPercentage *=
      (-1 * input.numberOfAnimals) / averageAnimalsPresent;
  }

  return result;
};

export default calculate3NOPForBovaer;
