import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonInterventionDataIn,
  interventionFloatValueInUserUnits
} from './inCommonMapperSustell';
import { emissionFieldsMapIn } from './emissionFields';
const math = require('mathjs');

const map3NOPForOneStage = (oneStageFormData, oneStageIntervention, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();
  const emissionsFormData = oneStageFormData.emissions;
  
  emissionsFormData.is3NOPSelected = (isDraft && oneStageIntervention.is3NOPSelected) || !!(oneStageIntervention?.supplement3NOP?.calculatedResult);
  if (
    (isDraft && oneStageIntervention.is3NOPSelected) || 
    (oneStageIntervention.supplement3NOP && oneStageIntervention.supplement3NOP?.calculatedResult)
  ) {
    emissionsFormData.supplement3NOP = {};
    const supp = oneStageIntervention.supplement3NOP;
    // emissionsFormData.is3NOPSelected = true;

    if (supp?.calculatedResult?.value || supp?.calculatedResult?.value === 0) {
      emissionsFormData.supplement3NOP.calculatedResult =
        supp?.calculatedResult?.value;
      emissionsFormData.supplement3NOP.calculatedResult_changeMetric = 'set';
    }
    if (
      supp?.numberOfCowsCovered?.value ||
      supp?.numberOfCowsCovered?.value === 0
    ) {
      emissionsFormData.supplement3NOP.numberOfCowsCovered =
        supp?.numberOfCowsCovered?.value;
      emissionsFormData.supplement3NOP.numberOfCowsCovered_changeMetric =
        supp?.numberOfCowsCovered?.change_type;
    }
    if (
      supp?.ndfAmountPercentage?.value ||
      supp?.ndfAmountPercentage?.value === 0
    ) {
      emissionsFormData.supplement3NOP.ndfAmountPercentage =
        supp?.ndfAmountPercentage?.value;
      emissionsFormData.supplement3NOP.ndfAmountPercentage_changeMetric =
        supp?.ndfAmountPercentage?.change_type;
    }
    
    if (
      supp?.fatAmountPercentage?.value ||
      supp?.fatAmountPercentage?.value === 0
    ) {
      emissionsFormData.supplement3NOP.fatAmountPercentage =
        supp?.fatAmountPercentage?.value;
      emissionsFormData.supplement3NOP.fatAmountPercentage_changeMetric =
        supp?.fatAmountPercentage?.change_type;
    }

    if (supp?.dmiAmountPerKg?.value || supp?.dmiAmountPerKg?.value === 0) {
      emissionsFormData.supplement3NOP.dmiAmountPerKg =
        interventionFloatValueInUserUnits(
          supp?.dmiAmountPerKg?.value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          supp?.dmiAmountPerKg?.change_type,
          precision
        );
      emissionsFormData.supplement3NOP.dmiAmountPerKg_changeMetric =
        supp?.dmiAmountPerKg?.change_type;
    }

    if (
      supp?._3nopDosePerCowPerDay?.value ||
      supp?._3nopDosePerCowPerDay?.value === 0
    ) {
      emissionsFormData.supplement3NOP._3nopDosePerCowPerDay =
        interventionFloatValueInUserUnits(
          supp?._3nopDosePerCowPerDay?.value,
          userUOM.unitBarnSmallQuantityUnits,
          defaultMetric.unitBarnSmallQuantityUnits,
          supp?._3nopDosePerCowPerDay?.change_type,
          precision
        );
      emissionsFormData.supplement3NOP._3nopDosePerCowPerDay_changeMetric =
        supp?._3nopDosePerCowPerDay?.change_type;
    }
  }

  return oneStageFormData;
};

// add 3NOP calc
const mapEmissionForOneStage = (
  oneStageFormData,
  stageData,
) => {
  // Restore the original intervention that was made on emission field only, for the sake of correct form input
  if (stageData?.methaneEFReplaceIntervention) {
    const emission = stageData?.emissions?.find(
      el => el.key === 'methane_enteric_fermentation'
    );

    if (emission) {
      const oneTypeOfEmission = stageData.methaneEFReplaceIntervention;
      const changeMetric = oneTypeOfEmission.value?.change_type;
      oneStageFormData.replaceMethaneFEEmission = {};
      const fieldName = emissionFieldsMapIn.get(oneTypeOfEmission.key);

      oneStageFormData.emissions[fieldName] = oneTypeOfEmission.value?.value;
      // this adapts all previously saved intervention, that uses 'percentage' as change type to display correctly the value as set
      oneStageFormData.emissions[`${fieldName}_changeMetric`] =
        !changeMetric || changeMetric === 'percentage' ? 'set' : changeMetric;
    }
  }
};

const mapDairyInterventionDataInSustell = (inData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();
  const formData = mapCommonInterventionDataIn(inData);

  inData.stages.forEach( (stage, index) => {
    const stageData = JSON.parse(stage.stageData) || {};
    if(formData.stages[index]){
      const stageOutputFormPart = formData.stages[index]?.stageData?.output;
      if(stageOutputFormPart){
        const milkProduced = stageData?.milk_produced;

        if (milkProduced?.amount?.value || milkProduced?.amount?.value === 0) {
          let milkValue = math.round(milkProduced?.amount?.value, 3);
          const milkChangeMetric = milkProduced?.amount?.change_type || 'relative';
          milkValue = interventionFloatValueInUserUnits(
            milkValue,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            milkChangeMetric,
            outputMassPrecision
          );
          stageOutputFormPart.milk = milkValue;
          stageOutputFormPart.milk_changeMetric = milkChangeMetric;
        }

        if (
          milkProduced?.protein_content?.value ||
          milkProduced?.protein_content?.value === 0 ||
          milkProduced?.proteint_content?.value ||
          milkProduced?.proteint_content?.value === 0
        ) {
          stageOutputFormPart.proteinContent = milkProduced?.protein_content?.value || milkProduced?.proteint_content?.value;
          stageOutputFormPart.proteinContent_changeMetric =
            milkProduced?.protein_content?.change_type || milkProduced?.proteint_content?.change_type || 'relative';
        }

        if (
          milkProduced?.fat_content?.value ||
          milkProduced?.fat_content?.value === 0
        ) {
          stageOutputFormPart.fatContent = milkProduced?.fat_content?.value;
          stageOutputFormPart.fatContent_changeMetric =
            milkProduced?.fat_content?.change_type || 'relative';
        }

        if (
          stageData?.livestock?.total_weight?.value ||
          stageData?.livestock?.total_weight?.value === 0
        ) {
          let liveWeight = math.round(stageData?.livestock?.total_weight?.value, 3);
          const changeMetric =
            stageData?.livestock?.total_weight.change_type || 'relative';
          liveWeight = interventionFloatValueInUserUnits(
            liveWeight,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            changeMetric,
            outputMassPrecision
          );
          stageOutputFormPart.liveWeight = liveWeight;
          stageOutputFormPart.liveWeight_changeMetric = changeMetric;
        }
      }

      // map input data - N/A for Dairy
      const stageFeedFormPart = formData.stages[index]?.stageData?.feed;
      if(stageFeedFormPart ){
          
        stageFeedFormPart.housingType = stageData?.housing_description?.housing_type;

        if (
          stageData?.time_spent_distribution?.grazing?.value ||
          stageData?.time_spent_distribution?.grazing?.value === 0
        ) {
          stageFeedFormPart.timeSpentGrazing =
            stageData?.time_spent_distribution?.grazing?.value;
          stageFeedFormPart.timeSpentGrazing_changeMetric =
            stageData?.time_spent_distribution?.grazing?.change_type ||
            'relative';
        }

        if (
          stageData?.time_spent_distribution?.yard?.value ||
          stageData?.time_spent_distribution?.yard?.value === 0
        ) {
          stageFeedFormPart.timeSpentInOpenYardAreas =
            stageData?.time_spent_distribution?.yard?.value;
          stageFeedFormPart.timeSpentInOpenYardAreas_changeMetric =
            stageData?.time_spent_distribution?.yard?.change_type ||
            'relative';
        }

        if (
          stageData?.time_spent_distribution?.building?.value ||
          stageData?.time_spent_distribution?.building?.value === 0
        ) {
          stageFeedFormPart.timeSpentInBuildings =
            stageData?.time_spent_distribution?.building?.value;
          stageFeedFormPart.timeSpentInBuildings_changeMetric =
            stageData?.time_spent_distribution?.building?.change_type ||
            'relative';
        }

        map3NOPForOneStage(formData.stages[index]?.stageData, stageData, isDraft);

        mapEmissionForOneStage(
          formData.stages[index]?.stageData,
          stageData
        );
      }
    }
  });
  return formData;
};

export default mapDairyInterventionDataInSustell
