import { capitalize } from "lodash";
import { FootPrintType, StageType } from '../../../../graphql/types';
import safeAdd from '../../helpers/calcHelpers';
import { FacilityTableItem } from '../Facility/FacilityTypes';
import { convertAndMergeImpactCategories } from './footprintHelper';
import {
  FootprintCategoryModel,
  EmissionsContainer,
  StageEmissions,
  EmissionsPeriod,
  WarningResponse,
  getFootprintCategoryTitleIndex,
  FootprintCategoryName,
} from './internal';
import WarningErrorModel from './WarningError';

// Input data types. TODO runtime checks
interface FilterProps {
  categories?: FootprintCategoryName[];
  analysisGroups?: string[];
}

export interface FootprintProps {
  name: string;
  reference: string;
  targets?: string | null;
  description?: string | null;
  calculatedImpact?: string | null;
  emissions?: string | null;
  warnings?: string | null;
  reporting_period?: string | null;
  errors?: string | null;
  total_output?: number | null;
  type: FootPrintType;
  stageEmission?: StageEmissions | null;
  stageType?: StageType | null;
  isStagesImpact?: boolean;
}

class FootprintModel {
  readonly name: string;

  readonly reference: string;

  readonly description?: string;

  #targets?: string;

  readonly type: FootPrintType;

  warnings: WarningErrorModel[] = [];

  #facility: FacilityTableItem | null = null;

  calculationErrors: WarningErrorModel[] = [];

  stageEmission?: StageEmissions;

  stageType?: StageType;

  isStagesImpact?: boolean;

  #filters: { categories: FootprintCategoryName[]; analysisGroups: string[] } =
    {
      categories: [],
      analysisGroups: [],
    };

  #categories: FootprintCategoryModel[] = [];

  emissionContainer?: EmissionsContainer;

  comparison?: FootprintModel;

  periodBaseline: EmissionsPeriod;

  constructor(data: FootprintProps) {
    this.name = data.name;
    this.reference = data.reference;
    this.description = data.description || undefined;
    this.#targets = data.targets || undefined;
    this.stageEmission = data.stageEmission || undefined;

    this.type = data.type;
    this.stageType = data.stageType || undefined;
    this.isStagesImpact = data.isStagesImpact || false;
    this.#categories = data.calculatedImpact
      ? Object.entries(convertAndMergeImpactCategories(data.calculatedImpact))
          .filter(([key, _]) => {
            const cat = getFootprintCategoryTitleIndex(key)?.title;
            if (!cat) {
              console.log('no mapping for category: ', key);
            }
            return !!cat;
          })
          .map(
            ([key, value]) =>
              new FootprintCategoryModel({
                footprint: this,
                key: getFootprintCategoryTitleIndex(key)?.title || 'unknown',
                unit: value.impact.unit,
                value: value.impact.value,
                components: value.components,
                targets: this.#targets,
              })
          )
          .sort(
            (a, b) =>
              (getFootprintCategoryTitleIndex(a?.key || '')?.index || 0) -
              (getFootprintCategoryTitleIndex(b?.key || '')?.index || 0)
          )
      : [];
    if (
      data.reporting_period &&
      data.reporting_period !== null &&
      data.reporting_period !== ''
    ) {
      this.periodBaseline = JSON.parse(
        data.reporting_period
      ) as EmissionsPeriod;
    }
    const emissionsArray = !this.stageEmission
      ? (JSON.parse(data.emissions || '[]') as StageEmissions[])
      : [];
    this.emissionContainer = new EmissionsContainer({
      footprint: this,
      emissionsByStages: this.stageEmission
        ? [this.stageEmission]
        : emissionsArray,
    });

    if (
      data.errors &&
      data.errors !== '' &&
      data.errors !== null &&
      data.errors !== '{}'
    ) {
      this.calculationErrors = WarningErrorModel.fromFootprintErrors(
        data.errors,
        this
      );
    }
    this.warnings = WarningErrorModel.fromFootprintWarnings(
      JSON.parse(data.warnings || '{}') as WarningResponse,
      this
    );
  }

  /**
   * Get a un filtered categories
   */
  get unFilteredCategories(): readonly FootprintCategoryModel[] {
    return this.#categories;
  }

  /**
   * Get a list of (filtered) categories for this footprint
   */
  get categories(): readonly FootprintCategoryModel[] {
    return this.categoryFilters.length
      ? this.#categories.filter((cat: FootprintCategoryModel) =>
          this.categoryFilters.includes(cat.key)
        )
      : this.#categories;
  }

  /**
   * Add filters for categories and/or analysis groups.
   * @param filter: FilterProps the filters to add
   */
  setFilters = (filter: FilterProps) => {
    if (filter.categories) this.#filters.categories = filter.categories;
    if (filter.analysisGroups)
      this.#filters.analysisGroups = filter.analysisGroups;

    // if the comparison footprint is set, pass the filter settings
    this.comparison?.setFilters(filter);
  };

  /**
   * set category targets
   * @param targets
   */
  updateCategoryTargets = (targets: string) => {
    this.categories.map((cat) => cat.setTargetValue(targets));
  };

  setStageType = (type: StageType) => {
    this.stageType = type;
  };

  private updateFootprintEmissions = (emissions: StageEmissions) => {
    return new EmissionsContainer({
      footprint: this,
      emissionsByStages: [emissions],
    });
  };
  /**
   * update emissions for footprint stage
   * @param emissions - emissions data
   */
  updateEmissions = (emissions: StageEmissions) => {
    this.emissionContainer = this.updateFootprintEmissions(emissions);
  };

  /**
   * update compare emissions for footprint stage
   * @param emissions - emissions data
   */
  updateCompareEmissions = (emissions: StageEmissions | undefined) => {
    if (!this.comparison) {
      return;
    }
    if (emissions) {
      this.comparison.emissionContainer =
        this.updateFootprintEmissions(emissions);
    } else {
      this.comparison.emissionContainer = undefined;
    }
  };

  /**
   * update categories for footprint stageimpact
   * @param calculatedImpact - footprint categories data
   */
  updateCategories = (calculatedImpact: string) => {
    this.#categories = this.updateFootprintCategories(calculatedImpact);
  };

  private updateFootprintCategories = (calculatedImpact: string) => {
    return calculatedImpact
      ? Object.entries(
          // JSON.parse(calculatedImpact) as {
          //   [key: string]: CalculatedImpactDataCategory;
          // }
          convertAndMergeImpactCategories(calculatedImpact)
        )
          .map(
            ([key, value]) =>
              new FootprintCategoryModel({
                footprint: this,
                key,
                unit: value.impact.unit,
                value: value.impact.value,
                components: value.components,
                targets: this.#targets,
              })
          )
          .sort(
            (a, b) =>
              (getFootprintCategoryTitleIndex(a?.key || '')?.index || 0) -
              (getFootprintCategoryTitleIndex(b?.key || '')?.index || 0)
          )
      : [];
  };

  /**
   * update categories for footprint stageimpact
   * @param calculatedImpact - footprint categories data
   */
  updateCompareCategories = (calculatedImpact: string) => {
    if (!this.comparison) {
      return;
    }
    this.comparison.#categories =
      this.updateFootprintCategories(calculatedImpact);
  };

  setFacility = (facility: FacilityTableItem | null) => {
    this.#facility = facility;
    if (facility) {
      this.#categories?.forEach(category => {
        category.components?.forEach(component => {
          if (component.name.startsWith('Facility') && this.#facility?.name) {
            component.setName(capitalize(this.#facility.type));
            component.setAnalysysGroup('Processing');
          }
        });
      });
    }
  }

  get categoryFilters(): readonly FootprintCategoryName[] {
    return this.#filters.categories;
  }

  get analysisGroupFilters(): readonly string[] {
    return this.#filters.analysisGroups;
  }

  /** Return total number of errors and warnings - put in tabs chip */
  get countWarningsErrors(): number {
    const errorsBaselineCount = this.calculationErrors.reduce(
      (count, current) => count + current.entries.length,
      0
    );
    const errorsComparisonCount = (
      this.comparison?.calculationErrors || []
    ).reduce((count, current) => count + current.entries.length, 0);

    const emmisionsBaselineCount = (
      this.emissionContainer?.agregatedEmissions?.errors || []
    ).reduce((count, current) => count + current.entries.length, 0);
    const emmisionsComparisonCount = (
      this.comparison?.emissionContainer?.agregatedEmissions?.errors || []
    ).reduce((count, current) => count + current.entries.length, 0);

    const warningsBaselineCount = this.warnings.reduce(
      (count, current) => count + current.entries.length,
      0
    );
    const warningsComparisonCount = (this.comparison?.warnings || []).reduce(
      (count, current) => count + current.entries.length,
      0
    );

    let retVal = safeAdd(0, errorsBaselineCount);
    retVal = safeAdd(retVal, errorsComparisonCount);
    retVal = safeAdd(retVal, emmisionsBaselineCount);
    retVal = safeAdd(retVal, emmisionsComparisonCount);
    retVal = safeAdd(retVal, warningsBaselineCount);
    retVal = safeAdd(retVal, warningsComparisonCount);
    // check is proxy data being used
    if (this.type === FootPrintType.CompoundFeed) {
      retVal = safeAdd(retVal, this.warnings?.[0]?.proxyDataUsed ? 1 : 0);
      retVal = safeAdd(
        retVal,
        this.comparison?.warnings?.[0]?.proxyDataUsed ? 1 : 0
      );
    }

    return retVal || 0;
  }
  /**
   * Set a footprint as a comparison
   */
  // addComparison = (comparison: FootprintModel) => {
  //   this.comparison = comparison;
  // };

  // comparison?: Footprint;
  // addTarget(category_id: string, value: float): void;
}

export default FootprintModel;
