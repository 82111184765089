import React from "react";
import { TextField } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import { resolvePath } from "./resolvePathFn";

const ReactHookTextField = ({
  name,
  label,
  defaultValue='',
  disabled=false,
  margin='none',
  variant,
  type='',
  multiline=false,
  InputProps={},
  changeHandler=undefined,
  onFocusHandler=undefined,
  ...props
}) => {

  const { errors, control, formState } = useFormContext();
  const localError = resolvePath(errors, name, null);
  // defaultValue when '' force replacement of saved 0, 
  // but without it, label is not working => loading value from reset to check
  const currResetValue = resolvePath(control.defaultValuesRef.current, name, null);
  // but must not set 0 if user deletes it => checking if field is touched
  const isTouched = resolvePath(formState.dirtyFields, name, false);

  // console.log("Curr reset value: ", currResetValue);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value, ref }) => {
        // allow zero to be displayed when needed
        if ((value === '' || value === undefined) && currResetValue === 0 && !isTouched)
          onChange(0);
        return (
          <TextField
            fullWidth
            label={label}
            margin={margin}
            variant={variant}
            inputRef={ref}
            onChange={
              (e) => {
                onChange(e);
                if(changeHandler) changeHandler(e);
              }
            }
            onBlur={onBlur}
            value={value || value === 0 || value === '0' ? value : ''}
            type={type}
            multiline={multiline}
            minRows={4}
            maxRows={6}
            error={localError ? true : false}
            helperText={localError ? (localError.message) : '' }
            InputProps={InputProps}
            disabled={disabled}
            onFocus={(e)=> {
              if(onFocusHandler) onFocusHandler(e)
            }}
          />
        )
      }
      }

    />
  );
};
export default ReactHookTextField;