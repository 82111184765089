/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cloneDeep } from 'lodash';
import { FC, useRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { PoultryBreedingStageData, PoultryInputBreeding, PoultryIntervention } from '../../../../models/Intervention/PoultryIntervention';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import PoultryInputPartExternalSource from './PoultryInputPartExternalSource';
import PoultryInputPartInternalSource from './PoultryInputPartInternalSource';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
  marginTop: '10px',
  marginLeft: '8px'
}

const container = {
  paddingLeft: '8px',
  marginRight: '8px',
  marginBottom: '8px'
}

const PoultryInputBreedingDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel,
  baseline,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<PoultryInputBreeding>();
  const fc = useFormContext<PoultryIntervention>();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      if (!currResetValue.current) {
        const input = baseline?.stages?.[itemIndex]?.stageData?.input as PoultryInputBreeding;
        currResetValue.current = {
          startDate : input?.startDate,
          endDate: input?.endDate
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = {...fc.getValues()} as PoultryIntervention;
      const stageData = resetObject?.stages?.[itemIndex]?.stageData as PoultryBreedingStageData;
      if (stageData?.input) {
        stageData.input = {...currResetValue.current} as PoultryInputBreeding;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }

  const handleSaveClick = async () => handleCancel();

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      formTitle={intl.formatMessage({id: "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"})}
      variant="demiWide"
    > 
      <DsmGrid style={container} className={classes.dsmGridTwoColumn}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.START_DATE"})}
          required
          disabled
          defaultValue={baseline?.stages?.[itemIndex]?.stageData?.input?.startDate as string}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.END_DATE"})}
          required
          disabled
          defaultValue={baseline?.stages?.[itemIndex]?.stageData?.input?.endDate as string}
        />
      </DsmGrid>
      <p style={headingTitle}>
        {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.HEN_TITLE'})}
      </p>
      <DsmGrid className={classes.dsmGridOneColumnIntervention}>
        { baseline?.stages?.[itemIndex]?.stageData?.input?.henInternalSource && (<div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE'})}
            </span>
          </div>
          <div>
            <PoultryInputPartInternalSource
              stageIndex={itemIndex}
              propName='henInternalSource'
              intlPrefix={`${StageType.Breeding}.HEN`}
              formType={formType}
              item={baseline?.stages?.[itemIndex]?.stageData?.input?.henInternalSource}
            />
          </div>
        </div> )}
        { baseline?.stages?.[itemIndex]?.stageData?.input?.henExternalSource && (<div>
          <div>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE'})}
            </span>
          </div>
          <div>
            <PoultryInputPartExternalSource
              stageIndex={itemIndex}
              propName='henExternalSource'
              intlPrefix={`${StageType.Breeding}.HEN`}
              formType={formType}
              item={baseline?.stages?.[itemIndex]?.stageData?.input?.henExternalSource}
            />
          </div>
        </div> )}
      </DsmGrid>
      <p style={headingTitle}>
        {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.ROOSTER_TITLE'})}
      </p>
      <DsmGrid className={classes.dsmGridOneColumnIntervention}>
        { baseline?.stages?.[itemIndex]?.stageData?.input?.roosterInternalSource && (<div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE'})}
            </span>
          </div>
          <div>
            <PoultryInputPartInternalSource
              stageIndex={itemIndex}
              propName='roosterInternalSource'
              intlPrefix={`${StageType.Breeding}.ROOSTER`}
              formType={formType}
              item={baseline?.stages?.[itemIndex]?.stageData?.input?.roosterInternalSource}
            />
          </div>
        </div> )}
        { baseline?.stages?.[itemIndex]?.stageData?.input?.roosterExternalSource && (<div>
          <div>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE'})}
            </span>
          </div>
          <div>
            <PoultryInputPartExternalSource
              stageIndex={itemIndex}
              propName='roosterExternalSource'
              intlPrefix={`${StageType.Breeding}.ROOSTER`}
              formType={formType}
              item={baseline?.stages?.[itemIndex]?.stageData?.input?.roosterExternalSource}
            />
          </div>
        </div> )}
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => handleSaveClick()}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM"})}/>
    </DialogContainer>
  );
};

export default PoultryInputBreedingDialog;
