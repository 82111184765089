import { CircularProgress } from "@material-ui/core";
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";

export const SignOut = async () => {
    const history = useHistory()

    await Auth.signOut()
    history.push('/')
    window.location.reload()

    return (
        <div style={{justifyContent: 'center'}}>
            <CircularProgress />
        </div>
    )
}