import { CompoundFeedDatabase, Maybe } from "../../../graphql/types";
import lookupValues from "../helpers/lookupValues";
import { CFDatabaseFoundation } from "../models/CompoundFeed";

export const getUIDatabaseFoundation = (db?: Maybe<CompoundFeedDatabase>) => {
  if (db === CompoundFeedDatabase.Gfli) return CFDatabaseFoundation.GFLI;
  if (db === CompoundFeedDatabase.Afp_6_3) return CFDatabaseFoundation.AFP_6_3;
  // default
  return CFDatabaseFoundation.AFP_5;
};

export const getDatabase = (db?: Maybe<CFDatabaseFoundation>) => {
  if (db === CFDatabaseFoundation.GFLI) return CompoundFeedDatabase.Gfli;
  if (db === CFDatabaseFoundation.AFP_6_3) return CompoundFeedDatabase.Afp_6_3;
  // default
  return CompoundFeedDatabase.Afp_5;
};

export const getDefaultIngredientsAddonsNames = () : string[] => {
  const names : string[] = [];
  lookupValues.defaultIngredientsAddons.forEach(item => {
    names.push(...item.ingredients.map(ingredeint => ingredeint.name));
  });

  return names;
}


