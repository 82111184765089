import lookupValues from '../../helpers/lookupValues';

import { FootprintCategoryModel, SubCategory } from './internal';

interface FootprintCategoryComponentModelProps {
  name: string;
  lookupKey: string;
  percentage?: number;
  amount?: number;
  category: FootprintCategoryModel;
  analysisGroup?: string;
  subCategories?: SubCategory[];
}

class FootprintCategoryComponentModel {
  name: string;

  readonly lookupKey: string;

  readonly label: string;

  readonly percentage?: number;

  readonly amount?: number;

  readonly category: FootprintCategoryModel;

  readonly subCategories?: SubCategory[];

  analysisGroup: string;

  constructor(data: FootprintCategoryComponentModelProps) {
    this.lookupKey = data.lookupKey;
    this.percentage = data.percentage;
    this.amount = data.amount;
    this.category = data.category;
    this.subCategories = data.subCategories;
    const analysisGroupsMapping =
      lookupValues.analysisGroupsMapping[
        this.lookupKey as keyof typeof lookupValues.analysisGroupsMapping
      ];

    const name =
      lookupValues.categoryNamingMapping[
        data.name as keyof typeof lookupValues.categoryNamingMapping
      ] || data.name;
    this.name = name;

    this.label = analysisGroupsMapping
      ? analysisGroupsMapping.label
      : this.name;

    if (data.analysisGroup) this.analysisGroup = data.analysisGroup;
    else if (analysisGroupsMapping)
      this.analysisGroup = analysisGroupsMapping.analysisGroup;
    else if (this.name.match(/from .* to .*/))
      // The format of ingredients is always X, from <origin> to <destination>
      // FIXME: Should we test if this is a feed footprint?
      this.analysisGroup = 'Ingredients';
    else if (this.name === 'Packaging' || this.name.startsWith('Facility')) this.analysisGroup = 'Processing';
    else this.analysisGroup = 'Unknown';
  }

  /**
   * Returns the direct comparison for this CategoryComponent from the compared FootprintCategory
   * Will return undefined if the category has no compared category or no equal component
   * can be found on the compared category.
   */
  get comparison(): FootprintCategoryComponentModel | undefined {
    if (!this.category.comparison) return undefined;
    const comparedComponent = this.category.comparison.components.find(
      (comparisonComp) => comparisonComp.name === this.name
    );

    // We create a new component based on the old one with a corrected percentage (based on the baseline)
    return (
      comparedComponent &&
      new FootprintCategoryComponentModel({
        ...comparedComponent,
        percentage:
          comparedComponent.amount &&
          (100 * comparedComponent.amount) / this.category.totalAmount,
      })
    );
  }

  /**
   * Returns the comparison percentage of two components
   * Retruns 0 if there is no compared footprint
   */
  get comparisonChangePercentage(): number {
    if (this.comparison?.amount && this.amount) {
      return Math.round((1 - this.comparison.amount / this.amount) * -100);
    }
    return 0;
  }

  setAnalysysGroup(analysisGroup: string) {
    this.analysisGroup = analysisGroup;
  }

  setName(name: string) {
    this.name = name;
  }
}

export default FootprintCategoryComponentModel;
