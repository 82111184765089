/*
  Commonly used validation builder functions
*/

import * as Yup from 'yup';
import {
  avgLiveWeightMins,
  avgLiveWeightMaxs,
} from '../../validationSchemaRestrictions';
import {
  defaultUnits,
  explicitConvertValue,
} from '../../../../sustell_15/utils/unit-utils';

export const numericRequiredWithMinMax = (intl, minValue, maxValue) =>
  Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
    .min(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MIN_VALUE' },
        { value: minValue }
      )
    )
    .max(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MAX_VALUE' },
        { value: maxValue }
      )
    );

// Min and Max are boundaries but value can not take the min or max value
export const numericRequiredGreaterThanMinLessThanMax = (
  intl,
  minValue,
  maxValue
) =>
  Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
    .moreThan(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
        { value: minValue }
      )
    )
    .lessThan(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_LESS_THAN' },
        { value: maxValue }
      )
    );

export const numericRequiredWithMin = (intl, minValue = 0) =>
  Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
    .min(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MIN_VALUE' },
        { value: minValue }
      )
    );

export const numericRequiredWithGreaterThanMin = (intl, minValue = 0) =>
  Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
    .moreThan(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
        { value: minValue }
      )
    );

export const numericRequiredWithMax = (intl, maxValue = 100) =>
  Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
    .max(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MAX_VALUE' },
        { value: maxValue }
      )
    );

export const numericRequiredWithLessThanMax = (intl, maxValue = 100) =>
  Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
    .lessThan(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_LESS_THAN' },
        { value: maxValue }
      )
    );

// optional fields
export const numericOptionalWithMinMax = (intl, minValue, maxValue) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' ? undefined : changed;
    })
    .nullable(true)
    .min(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MIN_VALUE' },
        { value: minValue }
      )
    )
    .max(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MAX_VALUE' },
        { value: maxValue }
      )
    )
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }));

export const numericOptionalGreaterThanMinLessThanMax = (
  intl,
  minValue,
  maxValue
) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' ? undefined : changed;
    })
    .nullable(true)
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .moreThan(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
        { value: minValue }
      )
    )
    .lessThan(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_LESS_THAN' },
        { value: maxValue }
      )
    );

export const numericOptionalWithMin = (intl, minValue = 0) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' ? undefined : changed;
    })
    .nullable(true)
    .min(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MIN_VALUE' },
        { value: minValue }
      )
    )
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }));

export const numericOptionalWithGreaterThanMin = (intl, minValue = 0) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' ? undefined : changed;
    })
    .nullable(true)
    .moreThan(
      minValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
        { value: minValue }
      )
    )
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }));

export const numericOptionalWithMax = (intl, maxValue = 100) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' ? undefined : changed;
    })
    .nullable(true)
    .max(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.FIELD.MAX_VALUE' },
        { value: maxValue }
      )
    )
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }));

export const numericOptionalWithLessThanMax = (intl, maxValue = 100) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' ? undefined : changed;
    })
    .nullable(true)
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .lessThan(
      maxValue,
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.INPUT_LESS_THAN' },
        { value: maxValue }
      )
    );

// used for range testing, also checkcs if field is empty or non-numeric
export const rangeTestFunction = (
  intl,
  testName,
  minValue = 0,
  maxValue = 100
) =>
  Yup.mixed().test(
    testName,
    intl.formatMessage(
      { id: 'VALIDATION.NUMERIC.RANGE_ERROR' },
      { min: minValue, max: maxValue }
    ),
    function (value) {
      return (
        value !== '' && !isNaN(value) && value >= minValue && value <= maxValue
      );
    }
  );

export const averageLiveweightMortality = (
  animalType,
  intl,
  userUOM = defaultUnits
) =>
  Yup.mixed().test(
    'averageLiveWeightMortalities',
    intl.formatMessage({ id: 'VALIDATION.NUMERIC.VALID_NUMBER' }),
    function (value) {
      let min = avgLiveWeightMins[animalType];
      let max = avgLiveWeightMaxs[animalType];

      if (min && userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass)
        min = Number(
          explicitConvertValue(
            min,
            defaultUnits.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          ).toFixed(3)
        );
      if (max && userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass)
        max = Number(
          explicitConvertValue(
            max,
            defaultUnits.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          ).toFixed(3)
        );

      const minOK = min ? value >= min : true; // if min is not supplied treat as OK
      const maxOK = max ? value <= max : true; // if max is not supplied treat as OK

      if (value !== '' && !isNaN(value) && minOK && maxOK) return true;
      else {
        // for pigs no upper bound given
        let errorMsg = intl.formatMessage({
          id: 'VALIDATION.NUMERIC.VALID_NUMBER',
        });
        if (min && max)
          errorMsg = intl.formatMessage(
            { id: 'VALIDATION.NUMERIC.RANGE_ERROR' },
            { min: min, max: max }
          );
        else if (min)
          errorMsg = intl.formatMessage(
            { id: 'VALIDATION.NUMERIC.INPUT_GREATER_OR_EQUAL_TO' },
            { value: min }
          );
        else if (max)
          errorMsg = intl.formatMessage(
            { id: 'VALIDATION.NUMERIC.INPUT_LESS_OR_EQUAL_TO' },
            { value: max }
          );
        return this.createError({ path: this.path, message: errorMsg });
      }
    }
  );

export const feedItemsTest = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      kgPerAnimal: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MIN_VALUE' },
            { value: minValue }
          )
        ),
      feedType: Yup.string('').when('kgPerAnimal', {
        is: (value) => !Number.isNaN(value) && value > 0,
        then: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' })
        ),
      }),
    })
  );

export const naturalFeedsItemsTest = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      quantity: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .when('type', {
          is: (value) => value !== undefined && value !== '',
          then: Yup.number()
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
            .min(
              minValue,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_VALUE' },
                { value: minValue }
              )
            )
            .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
        }),
      crudeProteinContent: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .max(
          100,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MAX_VALUE' },
            { value: 100 }
          )
        ),
    })
  );

export const feedItemsTestMandatory = (intl, minValue = 0) =>
  Yup.array()
    .of(
      Yup.object({
        kgPerAnimal: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .moreThan(
            minValue,
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
              { value: minValue }
            )
          ),
        feedType: Yup.string('').required(
          intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' })
        ),
      })
    )
    .min(1);

export const shrimpFeedItemsTestMandatory = (intl, minValue = 0) =>
  Yup.array()
    .of(
      Yup.object({
        kgPerAnimal: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .moreThan(
            minValue,
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
              { value: minValue }
            )
          ),
        feedType: Yup.string('').required(
          intl.formatMessage({ id: 'BASELINE.FORM.FEED.SHRIMP.QUANTITY_ERROR' })
        ),
      })
    )
    .min(1);

export const feedItemsTestWithOrigin = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      feedType: Yup.string(''),
      kgPerAnimal: Yup.number()
        .transform((changed, original) => {
          return original === '' ? undefined : changed;
        })
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MIN_VALUE' },
            { value: minValue }
          )
        ),
      origin: Yup.string().when('kgPerAnimal', {
        is: (val) => !Number.isNaN(val) && val > 0,
        then: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
      }),
    })
  );

export const sumCheck = (relatedFields, capValue) => {
  let sum = 0;
  let retVal = true;
  if (relatedFields) {
    // console.log(relatedFields)
    sum = relatedFields?.reduce((prev, curr) => {
      const prevVal = typeof prev === 'number' && prev === prev ? prev : 0;
      const currVal = typeof curr === 'number' && curr === curr ? curr : 0;
      return prevVal + currVal;
    }, 0);
  }
  if (sum > capValue) retVal = false;
  return retVal;
};

export const fertilizerItemsTest = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      type: Yup.string('').when('amount', {
        is: (val) => !Number.isNaN(val) && val > 0,
        then: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
      }),
      amount: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .nullable(true)
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MIN_VALUE' },
            { value: minValue }
          )
        ),
    })
  );

export const fertilizerItemsTestMandatory = (intl, minValue = 0) =>
  Yup.array()
    .of(
      Yup.object({
        type: Yup.string('').required(
          intl.formatMessage({ id: 'BASELINE.FORM.FEED.SHRIMP.QUANTITY_ERROR' })
        ),
        amount: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .required(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .moreThan(
            minValue,
            intl.formatMessage(
              { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
              { value: minValue }
            )
          ),
      })
    )
    .min(1);
