import { FC, useState, SetStateAction } from "react";
import { useDispatch } from "react-redux";

import { Box, Divider, Grid, InputLabel, Typography, Dialog, DialogActions, DialogTitle, DialogContent, Tabs, Tab, ButtonGroup, Button } from "@material-ui/core";
import Select from "react-select";
import { DsmButton, DsmDatePicker, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { DSM_BLUE, DSM_DARK_GREY, footprintDetailsStyles, footprintTabsStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import { DashboardFilters, DashboardImpactType, DashboardType, DateRange, SelectBaselinesInterventions } from "../../models/Dashboard/DashboardTypes";
import { AnimalType } from "../../../../graphql/types";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { reactSelectMultiStyles } from "./helper";
import { clearDashboardOptionsDraft, setDashboardOptionsDraft } from "../../../state/drafts/dashboardFiltersDraftSlice";
import useAdobeDataLayer from "../../analytics/adobeDataLayer";
import { getDateDeltaYears } from "../../utils/datetime-utils";

const getIntialDateRange = (): DateRange => ({
  startDate: getDateDeltaYears(new Date(), -10),
  endDate: getDateDeltaYears(new Date(), 10),
  key: 'selection'
})

interface DashboardHeaderProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
  setDashboardDateRange: (range: DateRange) => void;
  setDashboardType: (type: SetStateAction<DashboardType>) => void;
  setBaselineInterventionOptions: (options: SelectBaselinesInterventions[]) => void;
  loading: boolean;
}

const DashboardHeader: FC<DashboardHeaderProps> = ({
  dashboard,
  renderDashboard,
  setDashboardDateRange,
  setDashboardType,
  setBaselineInterventionOptions,
  loading,
}) => {
  const intl = useIntl();
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [selectionRange, setSelectionRange] = 
    useState<DateRange>(getIntialDateRange());
  const classesTabs = footprintTabsStyles();
  const classes = footprintDetailsStyles();
  const dispatch = useDispatch();
  const { filtersEvent } = useAdobeDataLayer();

  const handleFiltersChange = (value: (string | AnimalType |  undefined)[], field: 'Farm' | 'Animal' | 'Country') => {
    // console.log(value)
    const filters: DashboardFilters = {...dashboard?.filters};
    if (value.some(val => !val)) {
      // eslint-disable-next-line no-param-reassign
      value = [];
    }
    let refinementType = '';
    if (field === 'Farm') {
      filters.farms = value as string[];
      refinementType= 'Farm';
    } else if (field === 'Animal') {
      filters.animals = value as AnimalType[];
      refinementType= 'Target species';
    } else if (field === 'Country') {
      filters.countries = value as string[];
      refinementType= 'Region';
    } 
    if (dashboard) {
      dashboard.setFilters(filters);
      dispatch(setDashboardOptionsDraft(dashboard));
      renderDashboard();
      // eslint-disable-next-line no-void
      void filtersEvent(field, value as string[], refinementType, 'Dashboard Refinement', 'Enabled');
    }
  }

  const handleChangeImpactType = (type: DashboardImpactType) => {
    dashboard?.setImpactType(type);
    dispatch(setDashboardOptionsDraft(dashboard));
    renderDashboard();
  }

  const handleSelectDateRange = (dateString: string, isEnd=false) => {

    const date = new Date(dateString);

    const copySelectionRange = {...selectionRange};
    if (!isEnd) {
      copySelectionRange.startDate = date;
    } else {
      copySelectionRange.endDate = date;
    }
    setSelectionRange(copySelectionRange);
  }

  const applyDateRange = () => {
    setDashboardDateRange(selectionRange);
    setDateDialogOpen(false);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: DashboardType) => {
    setDashboardType(newValue);
  };

  const allFilterData = dashboard?.allDistinctFilterData || undefined;
  const farmsItems = [
    {label: intl.formatMessage({id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_FARMS"}), value: undefined}, 
    ...((allFilterData?.farms || []).map(el => ({label: el.name, value: el.id}) || []) )
  ];

  const animalsItems = [
    {label: intl.formatMessage({id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_ANIMALS"}), value: undefined}, 
    ...((allFilterData?.animals || []).map(el => ({label: el.name, value: el.id}) || []) )
  ];
  
  const countriesItems = [
    {label: intl.formatMessage({id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_REGIONS"}), value: undefined}, 
    ...((allFilterData?.countries || []).map(el => ({label: el.name, value: el.id}) || []) )
  ];

  const baselineInterventionOptionItems: {label: string, value: SelectBaselinesInterventions}[] = [
    {
      label:  intl.formatMessage({id: "SUSTELL.CURRENT.BASELINES"}), value: SelectBaselinesInterventions.CurrentBaselines,
    },
    {
      label:  intl.formatMessage({id: "SUSTELL.ARCHIVED.BASELINES"}), value: SelectBaselinesInterventions.ArchivedBaselines,
    },
    {
      label:  intl.formatMessage({id: "SUSTELL.CURRENT.INTERVENTIONS"}), value: SelectBaselinesInterventions.CurrentInterventions,
    },
    {
      label: intl.formatMessage({id: "SUSTELL.ARCHIVED.INTERVENTIONS"}) , value: SelectBaselinesInterventions.ArchivedInterventions,
    }
  ];

  const clearFilters = () => {
    let resetDateRange = false;
    if (
      dashboard?.dateRange.startDate?.toDateString() !== getIntialDateRange().startDate?.toDateString() 
      || dashboard?.dateRange.endDate?.toDateString() !== getIntialDateRange().endDate?.toDateString()
    ) {
      resetDateRange = true;
    }
    dashboard?.clearAllOptions();
    dispatch(clearDashboardOptionsDraft());
    renderDashboard();
    
    if (resetDateRange) {
      // this will trigger new API call
      setDashboardDateRange(getIntialDateRange());
    }
  }

  const gridSize = dashboard?.type === DashboardType.Feeds ? 4 : 3;

  return (
    <>
      <Dialog
            open={dateDialogOpen}
            onClose={() => setDateDialogOpen(false)}
            aria-labelledby="alert-dialog-date-range-title"
            aria-describedby="alert-dialog-date-range-content"
            fullWidth
            PaperProps={{
              style: {
                maxWidth: '760px',
                height: '480px'
              }
            }}
          >
        <DialogTitle id="alert-dialog-date-range-title" style={{alignSelf: 'center'}}>
          <Typography variant="h5" color="textPrimary">
            {intl.formatMessage({id: "GENERAL.PICK_DATE_RANGE"})}
            <br />
          </Typography>
        </DialogTitle>
        <DialogContent
          id="alert-dialog-date-range-content"
        >
        <DsmGrid
          style={{ marginBottom: 'var(--dsm-spacing-px-4', grid: "auto/1fr 1fr" }}
        >
          <DsmDatePicker
            onDsmChange={e => handleSelectDateRange(e.detail)}
            date={dashboard?.dateRange.startDate?.toISOString()?.substring(0, 10)}
          />

          <DsmDatePicker
            onDsmChange={e => handleSelectDateRange(e.detail, true)}
            date={dashboard?.dateRange.endDate?.toISOString()?.substring(0, 10)}
          />
        </DsmGrid>
        </DialogContent>
        <DialogActions>
          <DsmButton onClick={() => setDateDialogOpen(false)}>
            { intl.formatMessage({id: "GENERAL.CANCEL" }) }
          </DsmButton>
          <DsmButton variant="secondary"  onClick={() => applyDateRange()}>
            { intl.formatMessage({id: "GENERAL.APPLY"}) }
          </DsmButton>
        </DialogActions>
      </Dialog>
      <Box mt="20px">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" style={{color: DSM_BLUE}}>
              {intl.formatMessage({ id: "SUSTELL.DASHBOARD.TITLE" })}
            </Typography>
            <Typography
              color="inherit"
              style={{ marginTop: '10px', color: DSM_DARK_GREY }}
              variant="body1"
            >
              { intl.formatMessage({ id: "SUSTELL.DASHBOARD.DESCRIPTION" }) }
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Tabs 
            value={dashboard?.type || DashboardType.Farm}
            onChange={handleTabChange}
            aria-label="dashboard-type-tabs"
            className={classesTabs.tabs}
          >
            <Tab label={intl.formatMessage({id: "SUSTELL.DASHBOARD.TAB.FARM"})} value={DashboardType.Farm} />
            <Tab label={intl.formatMessage({id: "SUSTELL.DASHBOARD.TAB.FEED"})} value={DashboardType.Feeds} style={{marginLeft: '20px'}} />
          </Tabs>
          </Grid>
        </Grid>    
      </Box>
      <Divider />
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{marginTop: '20px'}}>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'end'}}> 

          <DsmButton 
            variant="secondary"  
            onClick={() => clearFilters()} 
            style={{marginRight: 'auto'}}
            disabled={!dashboard?.hasFilters()}
          >
            <DsmIcon slot="before" name="general/x-close" />
            {intl.formatMessage({id: "SUSTELL.DASHBOARD.CLEAR_FILTERS"})}
          </DsmButton>
          
            <DsmButton variant="secondary"  onClick={() => setDateDialogOpen(true)} style={{marginRight: '12px'}}>
              <DsmIcon slot="before" name="time/calendar" />
              { intl.formatMessage({ id: "SUSTELL.DASHBOARD.BUTTON.DATE_RANGE"  }) }
           </DsmButton>
              <ButtonGroup>
                <Button
                  id="dashboardButtonAbsoluteImpact"
                  size="small"
                  color="secondary"
                  variant="outlined"
                  className={classes.buttonStyles}
                  style={{
                      background: (dashboard?.impactType === DashboardImpactType.Absolute ? '#0070BA' : ''),
                      color: (dashboard?.impactType === DashboardImpactType.Absolute ? 'white' : ''),
                      fontSize: '13px'
                    }}
                  onClick={() => handleChangeImpactType(DashboardImpactType.Absolute)}
                  disabled={dashboard?.type === DashboardType.Feeds}
                >
                    { intl.formatMessage({id: "SUSTELL.DASHBOARD.BUTTON.ABSOLUTE_IMPACT" }) }
                </Button>
                <Button
                  id="dashboardButtonPerKgImpact"
                  size="small"
                  color="secondary"
                  variant="outlined"
                  className={classes.buttonStyles}
                  style={{
                    background: (dashboard?.impactType === DashboardImpactType.PerUnit ? '#0070BA' : ''),
                    color: (dashboard?.impactType === DashboardImpactType.PerUnit ? 'white' : ''),
                    fontSize: '13px'
                   }}
                  onClick={() => handleChangeImpactType(DashboardImpactType.PerUnit)}
                >
                  { intl.formatMessage({id:"SUSTELL.DASHBOARD.BUTTON.IMPACT_PER_UNIT" }, {unit: "KG"})}
                </Button>
            </ButtonGroup>
          </Grid>
      </Grid>
    <Box mt="32px" style={{display: (loading && dashboard?.type === DashboardType.Feeds) ? 'none' : ''}}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} >
      { (dashboard?.type === DashboardType.Farm) &&

        <Grid item xs={gridSize}>
            <>
              <InputLabel
                    style={{ fontWeight: 500 }}
              >
                {intl.formatMessage({
                  id: 'FARM',
                })}
              </InputLabel>
              <Select
                placeholder={intl.formatMessage({
                  id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_FARMS",
                })}
                isMulti
                isSearchable
                isClearable
                value={
                  dashboard?.filters?.farms?.map(filter => {
                   const el = farmsItems.find(item => item.value === filter);
                   return el;
                  }) || []
                }
                onChange={(newVal, ) => handleFiltersChange(newVal.map(el => el?.value), 'Farm')}
                options={ farmsItems }
                styles={reactSelectMultiStyles}
              />
            </>
        </Grid>
      }

        <Grid item xs={gridSize}>
          <InputLabel
                  style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: "GENERAL.ANIMAL_SYSTEM_TYPE",
              })}
            </InputLabel>
            <Select
              placeholder={intl.formatMessage({
                id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_ANIMALS",
              })}
              isSearchable
              isClearable
              isMulti
              value={
                dashboard?.filters?.animals?.map(filter => {
                 const el = animalsItems.find(item => item.value === filter);
                 return el;
                }) || []
              }
              onChange={(newVal, ) => handleFiltersChange(newVal.map(el => el?.value), 'Animal')}
              options={ animalsItems }
              styles={reactSelectMultiStyles}
            />
          </Grid> 
        <Grid item xs={gridSize}>
          <InputLabel
                  style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: "GENERAL.REGION",
              })}
            </InputLabel>
            <Select
              placeholder={intl.formatMessage({
                id: "SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_REGIONS",
              })}
              isSearchable
              isClearable
              isMulti
              value={
                dashboard?.filters?.countries?.map(filter => {
                 const el = countriesItems.find(item => item.value === filter);
                 return el;
                }) || []
              }
              onChange={(newVal, ) => handleFiltersChange(newVal.map(el => el?.value), 'Country')}
              options={countriesItems}
              styles={reactSelectMultiStyles}
            />
        </Grid>
        { (dashboard?.type === DashboardType.Feeds) ?
          <Grid item xs={gridSize} />
          :
          <Grid item xs={gridSize}>
             <InputLabel
                  style={{ fontWeight: 500 }}
            >
              {intl.formatMessage({
                id: "SUSTELL.DASHBOARD.SELECT_BASELINES_INTERVENTIONS",
              })}
            </InputLabel>
            <Select
              isSearchable
              isClearable
              isMulti
              onChange={(newVal, ) => {
                if (newVal.length === 0) {
                  setBaselineInterventionOptions([baselineInterventionOptionItems[0].value]);
                } else {
                setBaselineInterventionOptions(newVal.map(el => el.value))
                }
              }}
              value={
                baselineInterventionOptionItems.filter(option => dashboard?.selectedBaselinesInterventions?.includes(option.value))
              }
              defaultValue={baselineInterventionOptionItems[0]}
              options={baselineInterventionOptionItems}
              styles={reactSelectMultiStyles}
            />
          </Grid>
        }
      </Grid>
    </Box>
    </>
  )
}
 
export default DashboardHeader;
