import React, { FC, useEffect, useState } from 'react';
import { DsmIcon } from '@dsm-dcs/design-system-react';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import * as math from 'mathjs';
import { UseFieldArrayMethods, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { CFDatabaseFoundation } from '../../../models/CompoundFeed';
import {
  FeedFormFields,
  FormType,
  IngredientField,
  IngredientInputType,
  IngredientNamesAndOriginsV2,
  IngredientUses,
  UserProfileAlias,
} from '../types';
import IngredientsInputRow from './IngredientsInputRow';
import IngredientsTotalSummaryField from './IngredientsTotalSummaryField';
import {
  unitIngredientFromPercentage,
  unitIngredientPercentage,
} from '../../../utils/unit-utils';

interface FeedIngredientsProps {
  formType: FormType;
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinations?: IngredientUses[];
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinationsCategories?: IngredientUses[];
  userProfile: UserProfileAlias;
  showProxyDialog: boolean;
  setShowProxyDialog: (show: React.SetStateAction<boolean>) => void;
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2;
  databaseFoundation: CFDatabaseFoundation | undefined;
  ingredientUsesFields: UseFieldArrayMethods<Record<string, any>, 'key'>;
}

export const emptyItem: IngredientField = {
  id: '',
  name: '',
  custom_name: '',
  origin: '',
  quantity: undefined,
  quantityPercentage: undefined,
  proxyOrigin: false,
  customIngredient: false,
  addonType: undefined,
  inbound_transport_input: {
    inland_ship: undefined,
    sea_ship: undefined,
    train: undefined,
    truck: undefined,
  },
};

const FeedIngredients: FC<FeedIngredientsProps> = ({
  formType,
  ingredientOriginCombinations = [],
  ingredientOriginCombinationsCategories = [],
  userProfile,
  ingredientOriginsNamesV2,
  databaseFoundation,
  showProxyDialog,
  setShowProxyDialog,
  ingredientUsesFields,
}) => {
  const intl = useIntl();
  const [, forceUpdate] = useState<object>({});
  const [ingredientInputType, setIngredientInputType] =
    useState<IngredientInputType>(IngredientInputType.ABSOLUTE);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { setValue, getValues, control } = useFormContext<FeedFormFields>();
  const ingredientUsesWatch = useWatch({control, name: 'ingredient_uses'}) as IngredientUses[]

  // TODO: for userUOM until converted to TS
  const userUOM = userProfile.getUserUnitPrefs();
  const INPUTUNIT = userUOM.unitIngredientQuantityInputMass;
  const OUTPUTUNIT = userUOM.unitCompoundFeedOutputMass;
  // This is used for output display in the error message, to align that all errors are in the user's input unit

  // On database change remove all ingredients
  useEffect(() => {
    const itemCount = ingredientUsesFields.fields.length;
    if (itemCount < 1) {
      return;
    }
    const indexArr = [...Array(itemCount).keys()];
    ingredientUsesFields.remove(indexArr);
    ingredientUsesFields.append({ ...emptyItem });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseFoundation]);

  const updateQuantityAndPercentage = (
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    if (!e && !index) {
      return;
    }
    e?.stopPropagation();
    if (ingredientInputType === IngredientInputType.ABSOLUTE) {
      setValue(
        `ingredient_uses.${index}.quantityPercentage`,
        unitIngredientPercentage(
          Number(e?.target?.value),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          INPUTUNIT
        )
      );
    }
    if (ingredientInputType === IngredientInputType.PERCENTAGE) {
      setValue(
        `ingredient_uses.${index}.quantity`,
        unitIngredientFromPercentage(
          Number(e?.target?.value),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          INPUTUNIT
        )
      );
    }
    const ingredients = getValues().ingredient_uses ?? [];
    const sumTotal = Number(
      math.format(
        ingredients.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue?.quantity || 0) ?? 0,
          0
        ),
        {
          notation: 'fixed',
          precision: 5,
        }
      )
    );
    setValue('quantitySum', sumTotal);
  };

  const removeHandler = (index?: number | number[] | undefined) => {
    ingredientUsesFields.remove(index);
    let roundedSummary = 0;
    ingredientUsesWatch.forEach((ingredient, i) => {
      if (i !== index) {
        roundedSummary += Number(ingredient?.quantity || 0);
      }
    });
    setValue(`quantitySum`, Number(roundedSummary.toFixed(5)));
  };

  useEffect(() => {
    // After rerender to avoid disabled fields
    forceUpdate({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientUsesFields]);

  return (
    <div style={{ marginTop: `var(--dsm-spacing-px-4)` }}>
      <Grid container justifyContent="flex-end">
        <RadioGroup
          name="controlled-radio-buttons-group"
          value={ingredientInputType}
          onChange={(event) => {
            setIngredientInputType(
              event.target.value as unknown as IngredientInputType
            );
          }}
          row
        >
          <FormControlLabel
            value={IngredientInputType.ABSOLUTE}
            control={<Radio />}
            label={
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {intl.formatMessage(
                  { id: 'COMPOUND_FEED.INGREDIENT.INPUT_ABSOLUTE' },
                  { unit: INPUTUNIT as string }
                )}
              </Typography>
            }
          />
          <FormControlLabel
            value={IngredientInputType.PERCENTAGE}
            control={<Radio />}
            label={
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {intl.formatMessage({
                  id: 'COMPOUND_FEED.INGREDIENT.INPUT_PERCENTAGE',
                })}
              </Typography>
            }
            style={{
              fontSize: 14,
              fontWeight: 500,
            }}
          />
        </RadioGroup>
      </Grid>
      {ingredientUsesFields.fields.map((field, index) => (
        <IngredientsInputRow
          formType={formType}
          ingredientOriginCombinations={ingredientOriginCombinations}
          ingredientOriginCombinationsCategorires={
            ingredientOriginCombinationsCategories
          }
          ingredientOriginsNamesV2={ingredientOriginsNamesV2}
          databaseFoundation={databaseFoundation}
          field={field}
          index={index}
          removeHandler={removeHandler}
          updateQuantityAndPercentage={updateQuantityAndPercentage}
          key={field.key}
          showProxyDialog={showProxyDialog}
          setShowProxyDialog={setShowProxyDialog}
          ingredientInputType={ingredientInputType}
        />
      ))}
      <IngredientsTotalSummaryField
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        inputUnit={INPUTUNIT}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        outputUnit={OUTPUTUNIT}
      />
      <Grid item container>
        <Button
          href=""
          style={{
            textDecoration: 'underline',
            color: '#0070BA',
            textTransform: 'none',
          }}
          onClick={() => ingredientUsesFields.append({...emptyItem})}
          disabled={formType === 'view'}
        >
          <DsmIcon
            slot="before"
            name="general/plus-circle"
            style={{ paddingRight: '5px', height: '24px', width: '24px' }}
          />
          {intl.formatMessage({ id: 'COMPOUND_FEED.ADD_INGREDIENT' })}
        </Button>
      </Grid>
    </div>
  );
};

export default FeedIngredients;
