import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {Route} from "react-router-dom";
import { fetchAndSetSelectedCustomer, setSelectedCustomer } from "../../../../app/state/selected-customer/selectedCustomerSlice";
import { store } from "../../../../app/state/store";
import {Content} from "./Content";

export function ContentRoute({ children, component, render, ...props }) {
  const customerID = props?.computedMatch?.params?.customerID;
  const selectedCustomer = useSelector((state) => state.selectedCustomer?.value);
  // ** Top level check if customer should be fetched..footprint pages are excluded
  useEffect(() => {
    if (customerID && selectedCustomer?.id !== customerID && props.path.indexOf('footprint') === -1) {
      store.dispatch(fetchAndSetSelectedCustomer(customerID, setSelectedCustomer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID])


  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
