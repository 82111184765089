import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType, ListEntry } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';

interface SGrowingChemicalFormProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  isIntervention?: boolean;
  removeHandler?: Maybe<Function>;
  required?: boolean;
  options: ListEntry[];
}

const SGrowingChemicalForm: FC<SGrowingChemicalFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  isIntervention,
  removeHandler,
  required = false,
  options
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const propName = isIntervention
    ? 'chemicalTypeAdditions'
    : 'chemicalTypes';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.operations.${propName}.${itemIndex}`;

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.BackgroundData}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <DsmGrid className={classes.dsmGridTwoColumn}>
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE',
            })}
            placeholder={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE.PLACEHOLDER',
            })}
            name={`${fieldItemPrefix}.type`}
            options={options}
            required={required}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE.TOOLTIP',
            })}
            adornment=" "
            defaultValue={formContext.getValues(`${fieldItemPrefix}.type`)}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.amount`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE_AMOUNT',
            })}`}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE_AMOUNT.TOOLTIP',
            })}
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE_AMOUNT.PLACEHOLDER',
            })}
            type="number"
            disabled={formType === FormType.View}
            required={required}
            defaultValue={formContext.getValues(`${fieldItemPrefix}.amount`)}
          />
        </DsmGrid>
      </DsmGrid>
    </>
  );
};

export default SGrowingChemicalForm;
