// This is disabled to avoid used of defaultProps property and to use classic destructing with default values for props
/* eslint-disable react/require-default-props */
import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  MutableRefObject,
  DetailedHTMLProps,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';

import InfoToolTip from '../../Helpers/InfoToolTip';
import ReactHookFormSelect from '../../Helpers/ReactHookFormSelect';
import lookupValues from '../../../sustell_15/helpers/lookupValues';
import { resolvePath } from '../../Helpers/resolvePathFn';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

interface RowControlledTextFieldWithMetricsProps {
  name: string;
  label: string;
  tooltip?:
    | string
    | DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  updateTotalMethane?: () => void;
  metricUnit: string;
  baseline: number | string | null | undefined;
  updateTotalFeedIntake?: (name: string, value: number | null) => void;
  disabled: boolean;
  readonly?: boolean;
  minAllowedValue?: number;
  maxAllowedValue?: number;
  precision?: number;
  onlySetMetricIfNoBaseline?: boolean | null;
  marginBottom?: number | string;
  inputRef?: MutableRefObject<number | string | null>;
  updateRef?: (
    name: string,
    inputRef: MutableRefObject<number | string | null>,
    newValue: number | string
  ) => void;
  margin?: 'none' | 'dense' | 'normal';
  variant?: 'outlined' | 'filled' | 'standard';
  type?: React.InputHTMLAttributes<unknown>['type'];
  displayFormattedUnit?: boolean;
  originalMetricUnit?: string;
}

const RowControlledTextFieldWithMetrics = ({
  name,
  label,
  tooltip,
  updateTotalMethane,
  metricUnit,
  baseline,
  updateTotalFeedIntake,
  disabled,
  readonly,
  minAllowedValue = 0,
  maxAllowedValue,
  precision = 2,
  onlySetMetricIfNoBaseline = false,
  marginBottom = 20,
  inputRef = undefined,
  updateRef = undefined,
  margin = 'normal',
  variant = 'outlined',
  type = 'text',
  displayFormattedUnit = true,
  originalMetricUnit = '',
}: RowControlledTextFieldWithMetricsProps) => {
  const intl = useIntl();

  const fc = useFormContext();

  let numOldValue: number | null = null;
  if (typeof baseline === 'number') numOldValue = baseline;
  else if (typeof baseline === 'string') numOldValue = parseFloat(baseline);

  if (Number.isNaN(numOldValue)) numOldValue = null;

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const localError = resolvePath(fc.errors, name, null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currResetValue = resolvePath(
    fc.control.defaultValuesRef.current,
    name,
    null
  );

  // TODO Fix this when resolvePath is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isTouched = resolvePath(fc.formState.dirtyFields, name, false);

  const [metricValue, setMetricValue] = useState<string | null>();
  const initialRender = useRef(true);

  const getCorrectDisplayText = (
    formattedUnit: string , 
    originalUnit: string
  ) => {
    return displayFormattedUnit ? formattedUnit : originalUnit;
  }

  let addornmentTxt =
    metricValue === 'relative' || metricValue === undefined 
    ? '%' 
    : getCorrectDisplayText(metricUnit, originalMetricUnit);

  const calculateNewValues = (newValueFromForm: string): number | '' => {
    const metric = fc.getValues(`${name}_changeMetric`) as string;
    // addornmentTxt must also be updated here if values are set after loading data
    addornmentTxt =
      metric === 'relative' || metric === undefined 
      ? '%' 
      : getCorrectDisplayText(metricUnit, originalMetricUnit);

    const newValue = parseFloat(newValueFromForm);

    if (Number.isNaN(newValue)) {
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, numOldValue);
      fc.setValue(`${name}_newValue`, '');
      if (inputRef && updateRef && inputRef.current !== '') {
        updateRef(name, inputRef, '');
      }
      if (updateTotalMethane) updateTotalMethane();
      return '';
    }
    if (metric === 'set') {
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, newValue);
      fc.setValue(`${name}_newValue`, newValue);
      if (inputRef && updateRef && inputRef.current !== newValue) {
        updateRef(name, inputRef, newValue);
      }
      if (updateTotalMethane) updateTotalMethane();
      return newValue;
    }
    if ((numOldValue || numOldValue === 0) && metric !== '') {
      let result: string | number = '';
      if (metric === 'absolute') {
        result = numOldValue + newValue;
        if (updateTotalFeedIntake) updateTotalFeedIntake(name, result);
        fc.setValue(`${name}_newValue`, result);
        if (inputRef && updateRef && inputRef.current !== result) {
          updateRef(name, inputRef, result);
        }
        if (updateTotalMethane) updateTotalMethane();
        return result;
      }
      // relative
      result = numOldValue * (1 + newValue / 100);
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, result);
      fc.setValue(`${name}_newValue`, result);
      if (inputRef && updateRef && inputRef.current !== result) {
        updateRef(name, inputRef, result);
      }
      if (updateTotalMethane) updateTotalMethane();
      return result;
    }

    return '';
  };

  const checkMetricUnit = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value && !Number.isNaN(e.target.value) && numOldValue === null)
      fc.setValue(`${name}_changeMetric`, 'set');
  };

  const getMetricOptions = () => {
    // if baseline not defined, only set option is possible
    if (onlySetMetricIfNoBaseline)
      return baseline || baseline === 0
        ? lookupValues.interventionValueMetrics
        : { set: lookupValues.interventionValueMetrics.set };

    return lookupValues.interventionValueMetrics;
  };

  const getDefaultValue = () => {
    if (onlySetMetricIfNoBaseline)
      return baseline || baseline === 0 ? 'relative' : 'set';

    return 'relative';
  };

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else {
      // eslint-disable-next-line no-void
      void fc.trigger(name);
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [metricValue, fc, fc.trigger, name]);

  return (
    <Grid container spacing={1} style={{ marginBottom }}>
      <Grid item xs={8} className="align-items-top">
        <Controller
          control={fc.control}
          name={name}
          defaultValue=""
          render={({ onChange, onBlur, value, ref }) => {
            // allows zeroes to be visible
            let localVal: string =
              typeof value === 'string' ? value : String(value);

            if (localVal === '' && currResetValue === 0 && !isTouched)
              localVal = '0';

            const localNew = calculateNewValues(localVal);
            const style =
              !Number.isNaN(localNew) &&
              (Number(localNew) < minAllowedValue ||
                (Number(localNew) > 100 &&
                  (metricUnit === 'percentage' || metricUnit === '%')) ||
                (maxAllowedValue && Number(localNew) > maxAllowedValue))
                ? { color: 'red' }
                : {};
            const metricUnitShort =
              metricUnit === 'number'
                ? ''
                : metricUnit.replace(/p[o,a,e]r animal/, getCorrectDisplayText('p.a.', '')); // will work for english, spanish, portuguese and french
            const minValue = metricValue === 'relative' ? -100 : 0;

            return (
              <>
                <TextField
                  style={{ marginTop: 0 }}
                  label={label}
                  margin={margin}
                  variant={variant}
                  value={localVal || localVal === '0' ? localVal : ''}
                  fullWidth
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                    checkMetricUnit(e);
                  }}
                  onBlur={onBlur}
                  type={type}
                  error={!!localError}
                  // TODO Fix when resolvePath is noverted to TS
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  helperText={localError ? localError.message : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {addornmentTxt}
                      </InputAdornment>
                    ),
                    readOnly: readonly,
                    inputProps: {
                      min: minValue,
                    },
                  }}
                  disabled={disabled}
                />
                <div style={{ width: 'max-content' }}>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.CHANGE.ORIGINAL_VALUE',
                  })}{' '}
                  <strong>
                    {typeof numOldValue === 'number'
                      ? `${numOldValue.toFixed(precision)} ${metricUnitShort}`
                      : ' - '}
                  </strong>
                  /{' '}
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.CHANGE.NEW_VALUE',
                  })}{' '}
                  <strong style={style}>
                    {' '}
                    {typeof localNew === 'number' && !Number.isNaN(localNew)
                      ? `${localNew.toFixed(precision)} ${metricUnitShort}`
                      : ' - '}{' '}
                  </strong>
                </div>
              </>
            );
          }}
        />
        <Controller
          control={fc.control}
          name={`${name}_newValue`}
          defaultValue=""
          // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unsafe-assignment
          render={({ value, name }) => (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <input type="hidden" name={name} value={value} />
          )}
        />
      </Grid>
      <Grid item xs={3} className="d-flex align-items-top">
        <ReactHookFormSelect
          name={`${name}_changeMetric`}
          label={intl.formatMessage({
            id: 'INTERVENTION.FORM.CHANGE.CHANGE_TYPE',
          })}
          control={fc.control}
          variant="outlined"
          defaultValue={getDefaultValue()}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setMetricValue(e.target.value)
          }
          fullWidth
          disabled={!!disabled}
        >
          {/* besiede i was label */}
          {Object.entries(getMetricOptions()).map(([i]) => (
            <MenuItem key={i} value={i}>
              {intl.formatMessage({
                id: `INTERVENTION.FORM.CHANGE.CHANGE_TYPE.VALUE.${i}`,
              })}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid
        item
        xs={1}
        // pr={0} this property doesn't exist
        className="d-flex align-items-top"
        style={{ paddingTop: '20px' }}
      >
        {tooltip !== undefined && tooltip !== '' && (
          <InfoToolTip tooltip={tooltip} />
        )}
      </Grid>
    </Grid>
  );
};

// RowControlledTextFieldWithMetrics.defaultProps = defaultProps;

export default RowControlledTextFieldWithMetrics;
