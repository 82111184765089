import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import lookupValues from '../../helpers/lookupValues';
import { FootprintCategoryModel, FootprintModel, FootprintFilterType } from '../../models/Footprint';
import {
  CompartmentType,
  FootprintCategoryName,
} from '../../models/Footprint/FootprintTypes';
import { updateUserSettings } from '../../../../graphql/mutations';
import { UserSettings } from '../../../../graphql/types';
import { UserProfilePrefs } from "../../../modules/Helpers/UserProfilePrefs";
import useAdobeDataLayer from '../../analytics/adobeDataLayer';

interface CategoriesByCompartments {
  compartment: CompartmentType;
  categories: FootprintCategoryModel[];
}

interface FootprintFiltersProps {
  footprint: FootprintModel;
  setCategoryFilters: (categories: FootprintCategoryName[]) => void;
  setAnalysisGroupFilters: (analysisGroups: string[]) => void;
}

const useFootprintFilters = ({
  footprint,
  setCategoryFilters,
  setAnalysisGroupFilters,
}: FootprintFiltersProps) => {
  const [, updateRenderState] = useState<object>();
  const forceUpdate = () => updateRenderState({});
  const { filtersEvent } = useAdobeDataLayer();

  /**
   * Clear all filteres and show original list for a @param type filter
   */
  const clearAllFilters = (type: FootprintFilterType) => {
    if(type === FootprintFilterType.CATEGORY)
      setCategoryFilters([]);
    else
      setAnalysisGroupFilters([]);
    forceUpdate();
  };

  /**
   * Set or remove category or analysis filters (defiend by @param type) based on @param checked and @param value
   */
  const updateFilters = (
    checked: boolean,
    value: FootprintCategoryName | string,
    type: FootprintFilterType,
    categorySection: string
  ) => {
    const filtersEventFn = () => { filtersEvent(categorySection, value, 'Customize View', 'Footprints Refinement', checked ? 'Enabled': 'Disabled') };
    if (type === FootprintFilterType.CATEGORY) {
      if (
        checked &&
        !footprint.categoryFilters.includes(value)
      ) {
        const newFilter = footprint.categoryFilters.concat(
          value
        );
        setCategoryFilters(newFilter);
      } else {
        const updateFootprintCategoryKeys = footprint.categoryFilters.filter(
          (item) => item !== value
        );
        setCategoryFilters(updateFootprintCategoryKeys);
      }
      filtersEventFn()
    }
    // type is FootprintFilterType.ANALYSIS_GROUP
    else if (checked && !footprint.analysisGroupFilters.includes(value)) {
      const newFilter = footprint.analysisGroupFilters.concat(value);
      setAnalysisGroupFilters(newFilter);
      filtersEventFn()
    } else {
      const updateFootprintAnalysisGroupsKeys =
        footprint.analysisGroupFilters.filter((item: string) => item !== value);
      setAnalysisGroupFilters(updateFootprintAnalysisGroupsKeys);
      filtersEventFn()
    }
    forceUpdate();
  };

  /**
   * Internally used by  "footprintFiltersMasterData"
   * to get @param FootprintCategoryNameTypes[]
   */
  const getCategoryKeyByCompartment = (comaprtment: string) => {
    const footprintCategoryAllKeys = Object.keys(
      lookupValues.impactCategoriesMapping.all
    ).filter((key) =>
      lookupValues.impactCategoriesMapping.all[
        key as keyof typeof lookupValues.impactCategoriesMapping.all
      ].includes(comaprtment)
    );
    return footprint.unFilteredCategories.filter((cat) =>
      footprintCategoryAllKeys.includes(cat.key)
    );
  };

  /**
   * Return all static analysis groups and impact categories
   * @returns {{impactCategories: CategoriesByCompartments[], analysisGroups: string[]}}
   *
   */
  const footprintFiltersMasterData = (): {
    impactCategories: CategoriesByCompartments[];
    analysisGroups: string[];
  } => {
    const formattedMasterData: CategoriesByCompartments[] = [];
    lookupValues.compartments.forEach((comp) => {
      const compObj = {
        compartment: comp as CompartmentType,
        categories: getCategoryKeyByCompartment(comp),
      };
      formattedMasterData.push(compObj);
    });

    return {
      impactCategories: formattedMasterData,
      analysisGroups: lookupValues.analysisGroups,
    };
  };

  /**
   * Save footprint filter settings for the current user
   */
  const saveFooprintFilterSettings = async (filterType: FootprintFilterType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const userProfile = UserProfilePrefs.getInstance();
    // TODO fix belove comments, when UserProfilePrefs is moved to TS
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // const userProfilePrefs = UserProfilePrefs.getInstance();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    let footprintPrefs = userProfile.getFootprintPrefs();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!footprintPrefs) footprintPrefs = {};

    // saving category filters
    if(filterType === FootprintFilterType.CATEGORY)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!footprint.categoryFilters)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        footprintPrefs.categoryFilters = [];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      else footprintPrefs.categoryFilters = [...footprint.categoryFilters];
    // saving analysis group filters
    else
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!footprint.analysisGroupFilters)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        footprintPrefs.analysisGroupFilters = [];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      else footprintPrefs.analysisGroupFilters = [...footprint.analysisGroupFilters];
    
    // Update footprint prefs for the current user
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.setFootprintPrefs(footprintPrefs);

    try {
      const result = await (API.graphql({
        query: updateUserSettings,
        variables: {
          input: { footprint: JSON.stringify(footprintPrefs) },
          segment: 'FOOTPRINT', // updating footprint prefs
        },
      }) as Promise<{
        data: {
          updateUserSettings: UserSettings | null;
        };
      }>);

      return result?.data?.updateUserSettings;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    // TODO Fix these comments, after transforming UserProfilePrefs to TS
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const userProfile = UserProfilePrefs.getInstance();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    let footprintPrefs = userProfile.getFootprintPrefs();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!footprintPrefs) footprintPrefs = {};

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (footprintPrefs.categoryFilters)
      setCategoryFilters(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        footprintPrefs.categoryFilters as FootprintCategoryName[]
      );
    else setCategoryFilters([]);

     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
     if (footprintPrefs.analysisGroupFilters)
     setAnalysisGroupFilters(
       // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
       footprintPrefs.analysisGroupFilters as string[]
     );
   else setAnalysisGroupFilters([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footprint]);

  return {
    footprintFiltersMasterData,
    clearAllFilters,
    updateFilters,
    saveFooprintFilterSettings,
  };
};

export default useFootprintFilters;
