import * as Yup from 'yup';
import {
  baselineValidationSchemaAvgTemperaturePart,
  usedResourcesPart,
} from './baselineValidationSchemaGeneralPart';
import {
  numericRequiredWithMinMax,
  numericRequiredWithMin,
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  rangeTestFunction,
  feedItemsTest
} from './validationObjectBuilderFunctions';

// stage part validation
const stageDataPartials = (intl) =>
  Yup.object({
    manure: Yup.object({
      averageAnimalsPresent: numericRequiredWithMin(intl, 0),
      solidManureStorage: numericRequiredWithMinMax(intl, 0, 100),
      manureManagementSystem: Yup.string(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      slurryManureStorage: numericRequiredWithMinMax(intl, 0, 100),
      strawForBedding: numericOptionalWithMin(intl, 0),
      sawDust: numericOptionalWithMin(intl, 0),
    }),
    feed: Yup.object({
      compoundFeeds: feedItemsTest(intl, 0),
      singleIngredients: feedItemsTest(intl, 0),
      totalFeedIntake: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .required(
          intl.formatMessage({
            id: 'VALIDATION.FIELD.REQUIRED_CALCULATED',
          })
        )
        .moreThan(
          0,
          intl.formatMessage(
            { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN_CALCULATED' },
            { value: 0 }
          )
        ),
      timeSpentGrazing: numericOptionalWithMinMax(intl, 0, 100),
      timeSpentInOpenYardAreas: numericOptionalWithMinMax(intl, 0, 100),
      timeSpentInBuildings: numericOptionalWithMinMax(intl, 0, 100),
      digestibility: numericOptionalWithMinMax(intl, 0, 100),
      grossEnergyIntake: numericOptionalWithMin(intl, 0),
      crudeProteinContent: rangeTestFunction(
        intl,
        'crudeProteinContent',
        0,
        100
      ),
      percentageOfSilage: rangeTestFunction(intl, 'percentageOfSilage', 0, 100),
    }),
    emissions:Yup.object({
      methaneEntericFermentation: numericOptionalWithMinMax(intl, -100, 100),
      methane: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideIndirect: numericOptionalWithMinMax(intl, -100, 100),
      amonia: numericOptionalWithMinMax(intl, -100, 100),
      nitricOxide: numericOptionalWithMinMax(intl, -100, 100),
      nonMethaneVolatile: numericOptionalWithMinMax(intl, -100, 100),
      nonMethaneVolatileSilage: numericOptionalWithMinMax(intl, -100, 100),
      PM25: numericOptionalWithMinMax(intl, -100, 100),
      is3NOPSelected: Yup.bool(),
      supplement3NOP: Yup.object().when('is3NOPSelected', {
        is: true,
        then: Yup.object({
          _3nopDosePerCowPerDay: numericRequiredWithMin(intl, 0.001).test(
            'bovaerCalculationError',
            '',
            function (value) {
              const { resolve, path, createError, parent } = this;
              const warningMsg = resolve(parent['_3nopDosePerCowPerDay_error']);
              if (warningMsg)
                return createError({ path: path, message: warningMsg });
              return true;
            }
          ),
          dmiAmountPerKg: numericRequiredWithMin(intl, 0).test(
            'bovaerCalculationError',
            '',
            function (value) {
              const { resolve, path, createError, parent } = this;
              const warningMsg = resolve(parent['dmiAmountPerKg_error']);
              if (warningMsg)
                return createError({ path, message: warningMsg });
              return true;
            }
          ),
          fatAmountPercentage: numericOptionalWithMinMax(intl, 0, 100).test(
            'bovaerCalculationError',
            '',
            function (value) {
              const { resolve, path, createError, parent } = this;
              const warningMsg = resolve(parent['fatAmountPercentage_error']);
              if (warningMsg)
                return createError({ path, message: warningMsg });
              return true;
            }
          ),
          ndfAmountPercentage: numericRequiredWithMinMax(intl, 0, 100).test(
            'bovaerCalculationError',
            '',
            function (value) {
              const { resolve, path, createError, parent } = this;
              const warningMsg = resolve(parent['ndfAmountPercentage_error']);
              if (warningMsg)
                return createError({ path, message: warningMsg });
              return true;
            }
          ),
          numberOfCowsCovered: numericRequiredWithMin(intl, 1)
            .test('minValCowsTest', '', function (value) {
              const { resolve, path, createError, parent } = this;
              // For unit tests
              if (!this?.from) {
                return;
              }
              let animalsPresent = this.from[2].value?.manure?.averageAnimalsPresent;
              if (animalsPresent || animalsPresent === '0')
                animalsPresent = parseInt(animalsPresent);
              const val = resolve(parent['numberOfCowsCovered']);
              if (
                typeof animalsPresent === 'number' &&
                typeof val === 'number' &&
                val > animalsPresent
              )
                return createError(
                  {
                    path: path,
                    message: intl.formatMessage({
                      id: 'VALIDATION.DAIRY.NUMBER_OF_COWS_3NOP',
                    }),
                  },
                  { value: 0 }
                );
              else return true;
            })
            .test('bovaerCalculationError', '', function (value) {
              const { resolve, path, createError, parent } = this;
              const warningMsg = resolve(parent['numberOfCowsCovered_error']);
              if (warningMsg)
                return createError({ path, message: warningMsg });
              return true;
            }),
          calculatedResult: numericRequiredWithMinMax(intl, -100, 100),
          calculatedResult_recalculate_error: Yup.string().nullable()
            .test('bovaerRecalculateError', '', function (value) {
              const { resolve, path, createError, parent } = this;
              const warningMsg = resolve(parent['calculatedResult_recalculate_error']);
              // console.log('yup validate', path, from)
              if(warningMsg)
                return createError({path, message: warningMsg});
              return true;
            }),
        })
        
      }),
    }),
});

const stageDataPartDairy = ({ intl, minValueOutput = 1 }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
            ),
          productionSystem: Yup.string(),
          stageData:
            Yup.object()
            .when('productionSystem',{
              is: 'Dairy cows',
              then: Yup.object({
                output:Yup.object({
                  milk: numericRequiredWithMin(intl, minValueOutput)
                  .test('milkLiveWeightRatio', '', function(value) {
                    if(value / this.parent.liveWeight >= 6.05) {
                      return true;
                    }

                    const { path, createError } = this;

                    return createError({
                      path,
                      message: intl.formatMessage(
                        {
                          id: "VALIDATION.FIELD.RATIO_MILK_LIVEWEIGHT",
                        }, 
                        {
                          value: 1
                        }
                      ),
                    });
                  }),
                  proteinContent: numericRequiredWithMinMax(intl,0,100),
                  fatContent: numericRequiredWithMinMax(intl,0,100),
                  liveWeight: numericRequiredWithMin(intl, 1)
                  .test('minLivestockToSlautherAllStages', '', function(value) {
                    const { path, createError, from } = this;
                    const allStages = from[3].value.stages;
                    let totalLiveWeight = 0;

                    allStages.forEach(stage => {
                      const val = Number(stage.stageData?.output?.liveWeight);
                      if (val && !Number.isNaN(val)) {
                        totalLiveWeight += val;
                      }
                    });
                    if (totalLiveWeight >= 1) {
                      return true;
                    }
                    return createError({
                      path,
                      message: intl.formatMessage(
                        {
                          id: "BASELINE.FORM.BARN.DAIRY.VALIDATION.LIVEWEIGHT_ALL_STAGES",
                        }, 
                        {
                          min: 1
                        }
                      ),
                    });
                  })
                })
              }).concat(stageDataPartials(intl)),
              otherwise: Yup.object({
                output:Yup.object({
                  liveWeight: numericRequiredWithMin(intl, 0)
                  .test('minLivestockToSlautherAllStages1', '', function(value) {
                    const { path, createError, from } = this;
                    const allStages = from[3].value.stages;
                    let totalLiveWeight = 0;

                    allStages.forEach(stage => {
                      const val = Number(stage.stageData?.output?.liveWeight);
                      if (val && !Number.isNaN(val)) {
                        totalLiveWeight += val;
                      }
                    });
                    if (totalLiveWeight >= 1) {
                      return true;
                    }
                    return createError({
                      path,
                      message: intl.formatMessage(
                        {
                          id: "BASELINE.FORM.BARN.DAIRY.VALIDATION.LIVEWEIGHT_ALL_STAGES",
                        }, 
                        {
                          min: 1
                        }
                      ),
                    });
                  })
                })
              }).concat(stageDataPartials(intl)),
            })
        })
      )
      .required()
      .min(1, intl.formatMessage( {id: "SUSTELL.STAGE.MIN.REQUIRED"}) )
});

// merge all necessary parts to baseSchema
export const assembleValidationSchemaSustell = (baseSchema, confObj) => {
    const infoObject = baseSchema
      .concat(baselineValidationSchemaAvgTemperaturePart(confObj));
    const combinedSchema = Yup.object({info:infoObject})
      .concat(Yup.object({resourceUse:usedResourcesPart(confObj)}))
      .concat(stageDataPartDairy(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;