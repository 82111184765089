import React from "react";
import {IntlProvider} from "react-intl";
import {useLang} from "./Metronici18n";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/es";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/es";

// import deMessages from "./messages/de";
import enMessages from "./messages/en1.json";
import esMessages from "./messages/es1.json";
import ptMessages from "./messages/pt1.json";
// import jaMessages from "./messages/ja";
// import zhMessages from "./messages/zh";

export const allMessages = {
  // de: deMessages,
  en: enMessages,
  es: esMessages,
  pt: ptMessages,
  // ja: jaMessages,
  // zh: zhMessages
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}