import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  setInterventionFloatValue,
  mapCommonInterventionDataOutSustell,
  mapResourceUse,
} from './outCommonMapperSustell';

const ANIMAL_TYPE = 'Dairy';

const map3NOPForOneStage = (formDataForOneStage, stageDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageData = stageDataObj;

  if (formDataForOneStage.emissions.supplement3NOP) {
    const supp = formDataForOneStage.emissions.supplement3NOP;
    
    if (!stageData.supplement3NOP) stageData.supplement3NOP = {};
    if (supp.calculatedResult || supp.calculatedResult === 0) {
      stageData.supplement3NOP.calculatedResult = {
        value: supp.calculatedResult,
        change_type: supp.calculatedResult_changeMetric,
      };
    }
    if (supp.numberOfCowsCovered || supp.numberOfCowsCovered === 0) {
      stageData.supplement3NOP.numberOfCowsCovered = {
        value: supp.numberOfCowsCovered,
        change_type: supp.numberOfCowsCovered_changeMetric,
      };
    }
    if (supp.ndfAmountPercentage || supp.ndfAmountPercentage === 0) {
      stageData.supplement3NOP.ndfAmountPercentage = {
        value: supp.ndfAmountPercentage,
        change_type: supp.ndfAmountPercentage_changeMetric,
      };
    }
    if (supp.fatAmountPercentage || supp.fatAmountPercentage === 0) {
      stageData.supplement3NOP.fatAmountPercentage = {
        value: supp.fatAmountPercentage,
        change_type: supp.fatAmountPercentage_changeMetric,
      };
    }

    setInterventionFloatValue(
      stageData.supplement3NOP,
      'dmiAmountPerKg',
      supp,
      'dmiAmountPerKg',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      3
    );

    setInterventionFloatValue(
      stageData.supplement3NOP,
      '_3nopDosePerCowPerDay',
      supp,
      '_3nopDosePerCowPerDay',
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits,
      3
    );
  }

  return formDataForOneStage;
};

// add 3NOP calc
const mapEmissionForOneStage = (
  formDataEmissionsForStage,
  stageDataReceived
) => {
  const stageData = stageDataReceived; // only reference - will allow changes to original object, but will stop eslint complaining about changing the input parameter
  if (!stageData.emissions) stageData.emissions = [];

  if (
    formDataEmissionsForStage.emissions?.is3NOPSelected &&
    formDataEmissionsForStage.emissions?.methaneEntericFermentationTotal &&
    !Number.isNaN(formDataEmissionsForStage.emissions?.methaneEntericFermentationTotal)
  ) {
    stageData.methaneEFReplaceIntervention = {
      action: 'update',
      value: {
        value: parseFloat(formDataEmissionsForStage.emissions?.methaneEntericFermentationTotal),
        change_type: 'set',
      },
      key: 'methane_enteric_fermentation',
    };
  }

  // Check if there's emission reduction for Methane from enteric fermentation, on data in restore original
  // find emmission index
  const emissionIndex = stageData?.emissions?.findIndex(
    el => el.key === 'methane_enteric_fermentation'
  );
  
  if (
    emissionIndex > -1 &&
    stageData.methaneEFReplaceIntervention
  ) {
    const emission = { ... stageData.emissions[emissionIndex]}; // find original methane_enteric_fermentation
    stageData.emissions[emissionIndex] = stageData.methaneEFReplaceIntervention; // replace with recalculated value
    stageData.methaneEFReplaceIntervention = emission; // store original value for reloading
  } else if (stageData.methaneEFReplaceIntervention) {
    stageData.emissions.push(stageData.methaneEFReplaceIntervention);
    // When original data is not set put this as initial
    stageData.methaneEFReplaceIntervention = {
      action: 'update',
      value: {
        value: undefined,
        change_type: 'relative',
      },
      key: 'methane_enteric_fermentation',
    };
  }
};

const mapDairyInterventionDataOutSustell = (formData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const payloadObj = mapCommonInterventionDataOutSustell(formData);
  mapResourceUse(formData, payloadObj);
  
  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if(payloadObj.stages[index]!==undefined){
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const {stageData} = stageGeneralData;
        stageData.animal_group.animal_type = ANIMAL_TYPE;
        
        // no input block for Dairy
        
        // process output block
        // (eggs, liveWeight )
        const outputBlockData = formStageData?.output;
        if(outputBlockData){
          stageData.milk_produced = {
            protein_content: {
              value: parseFloat(outputBlockData.proteinContent),
              change_type: outputBlockData.proteinContent_changeMetric,
            },
            fat_content: {
              value: parseFloat(outputBlockData.fatContent),
              change_type: outputBlockData.fatContent_changeMetric,
            },
          };

          setInterventionFloatValue(
            stageData.milk_produced,
            'amount',
            outputBlockData,
            'milk',
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            3
          );
        
          if (!stageData.livestock)
            stageData.livestock = {};
        
          setInterventionFloatValue(
            stageData.livestock,
            'total_weight',
            outputBlockData,
            'liveWeight',
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            3
          );
        }

        // set housing block of data
        // set addditional fields from 
        const feedBlockData = formStageData?.feed;
        if(feedBlockData){
          stageData.housing_description = {
            housing_type: feedBlockData.housingType,
          };
          stageData.time_spent_distribution = {
            grazing: {
              value: parseFloat(feedBlockData.timeSpentGrazing),
              change_type: feedBlockData.timeSpentGrazing_changeMetric,
            },
            yard: {
              value: parseFloat(feedBlockData.timeSpentInOpenYardAreas),
              change_type: feedBlockData.timeSpentInOpenYardAreas_changeMetric,
            },
            building: {
              value: parseFloat(feedBlockData.timeSpentInBuildings),
              change_type: 'set',
            },
          };
        }

        // if 3NOP is not checked dont save input data, and don't update emissions, unless draft - then save the state of checkbox
        if(isDraft)
          stageData.is3NOPSelected = formStageData?.emissions?.is3NOPSelected || false;
        
        if (formStageData?.emissions?.is3NOPSelected) {
          map3NOPForOneStage(formStageData, stageData);
        }

        // update calculation by including 3NOP
        mapEmissionForOneStage(
          formStageData,
          stageData,
        );
      }
      
    });
    payloadObj.stages.forEach( item => {
      const {stageData} = item;
      item.stageData = JSON.stringify(stageData);
    })
  return payloadObj;
};

export default mapDairyInterventionDataOutSustell;
