import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { FormatDateOptions, IntlShape } from 'react-intl';
import { DashboardFootprintModel, DashboardModel } from "../../models/Dashboard";
import { AnalysisGroupsFarm, AnalysisGroupsFeed, DashboardFilterData, DashboardType, SelectBaselinesInterventions } from '../../models/Dashboard/DashboardTypes';
import { FootprintCategoryKey, FootprintCategoryModel, FootprintModel } from '../../models/Footprint';
import { dateOnlyDisplayOptionsShort } from '../../utils/datetime-utils';
import { FOOTPRINT_CATEGORIES, SAME_FOOTPRINT_CATEGORIES } from '../../models/Footprint/FootprintTypes';

// Excel sheet name must be <= 31 characters and unique. Shorten too large categories

const SHORTEN_CATEGORY_NAMES = {
  "Climate change - Land use and LU change": 'Clim. chg. - Land use, LU chg.',
  "Climate change - Peat oxidation": "Climate ch. Peat oxidation",
  "Resource use, minerals and metals": 'Res. use, minerals and metals',
  "Human toxicity, cancer - organics": "Human toxic, cancer organics",
  "Human toxicity, cancer - inorganics": "Human toxic, cancer inorganics",
  "Human toxicity, cancer - metals": "Human toxic, cancer metals",
  "Human toxicity, non-cancer - organics": "Human toxic, noncancer organics",
  "Human toxicity, non-cancer - inorganics": "Human toxic, noncancer inorganics",
  "Human toxicity, non-cancer - metals": "Human toxic, noncancer metals",
  "Photochemical ozone formation, HH": "Photochem ozone formation,HH",
  "Ecotoxicity, freshwater - organics": "Ecotoxic freshwater organics",
  "Ecotoxicity, freshwater - inorganics": "Ecotoxic freshwater inorganics",
  "Ecotoxicity, freshwater - metals": "Ecotoxic freshwater metals"
};

const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];


const addSummarySheet = (dashboard: DashboardModel, workbook: Excel.Workbook, intl: IntlShape) => {
  
  const sheet = workbook.addWorksheet(
    intl.formatMessage({ id: 'GENERAL.SUMMARY' })
  );
  let rowIndex = 1;

  const now = new Date();
  sheet.getCell(`A${rowIndex}`).value =  intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.DATE_OF"});
  sheet.getCell(`B${rowIndex}`).value = dashboard.dateRange?.startDate ? intl.formatDate(now, dateOnlyDisplayOptionsShort as FormatDateOptions) : '';
  rowIndex += 1;

  sheet.getRow(rowIndex).font = { bold: true };
  sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id:  "REPORT.DATASET_TABLE.CUSTOMER"});
  sheet.getCell(`B${rowIndex}`).value = dashboard.customerName;
  rowIndex += 1;

  sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id: "GENERAL.TYPE"});
  sheet.getCell(`B${rowIndex}`).value = dashboard.type === DashboardType.Feeds ? intl.formatMessage({id: "COMPOUNDFEEDS.CARD.TITLE"}) :intl.formatMessage({id:  "FARMS"});
  rowIndex += 1;

  sheet.getCell(`A${rowIndex}`).value =  intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.START_DATE_FROM_DASHBOARD"});
  sheet.getCell(`B${rowIndex}`).value = dashboard.dateRange?.startDate ? intl.formatDate(dashboard.dateRange?.startDate, dateOnlyDisplayOptionsShort as FormatDateOptions) : '';
  rowIndex += 1;

  sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.END_DATE_FROM_DASHBOARD"});
  sheet.getCell(`B${rowIndex}`).value = dashboard.dateRange?.endDate ? intl.formatDate(dashboard.dateRange?.endDate, dateOnlyDisplayOptionsShort as FormatDateOptions) : '';
  rowIndex += 1;

  const filterData: DashboardFilterData = dashboard.allDistinctFilterData;

  if (dashboard.type === DashboardType.Farm) {
    const value = dashboard.filters?.farms?.reduce(
      (acc, farmId) => 
        [acc, filterData.farms.find(f => f.id === farmId)?.name || ''].join(', '),
        ''
    )
    sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id:"FARMS"});
    sheet.getCell(`B${rowIndex}`).value = value?.substring(1) ||  intl.formatMessage({id:"COMPOUNDFEEDS.ALL_TYPES"});
    rowIndex += 1;
  }

  const value = dashboard.filters?.animals?.reduce(
    (acc, id) => 
      [acc, filterData.animals.find(f => f.id === id)?.name || ''].join(', '),
      ''
  )
  sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id: "GENERAL.SPECIES"});
  sheet.getCell(`B${rowIndex}`).value = value?.substring(1) ||  intl.formatMessage({id:"COMPOUNDFEEDS.ALL_TYPES"});
  rowIndex += 1;

  sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id: "GENERAL.COUNTRIES"});
  sheet.getCell(`B${rowIndex}`).value = dashboard.filters?.countries?.join(', ') ||  intl.formatMessage({id:"COMPOUNDFEEDS.ALL_TYPES"});
  rowIndex += 1;

  if (dashboard.type === DashboardType.Farm) {
    const baselineInterventionOptionItems: {label: string, value: SelectBaselinesInterventions}[] = [
      {
        label:  intl.formatMessage({id: "SUSTELL.CURRENT.BASELINES"}), value: SelectBaselinesInterventions.CurrentBaselines,
      },
      {
        label:  intl.formatMessage({id: "SUSTELL.ARCHIVED.BASELINES"}), value: SelectBaselinesInterventions.ArchivedBaselines,
      },
      {
        label:  intl.formatMessage({id: "SUSTELL.CURRENT.INTERVENTIONS"}), value: SelectBaselinesInterventions.CurrentInterventions,
      },
      {
        label: intl.formatMessage({id: "SUSTELL.ARCHIVED.INTERVENTIONS"}) , value: SelectBaselinesInterventions.ArchivedInterventions,
      }
    ];
    const values = dashboard.selectedBaselinesInterventions?.map(opt =>  baselineInterventionOptionItems.find(el => el.value === opt)?. label || '')
    sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id:"SUSTELL.DASHBOARD.SIMULATION.SELECTED_BASELINES_INTERVENTIONS"});
    sheet.getCell(`B${rowIndex}`).value = values?.join(', ') || intl.formatMessage({id:"SUSTELL.CURRENT.BASELINES"});
    rowIndex += 1;
  }
  if (dashboard.simulation.references.length > 0 || dashboard.simulation.simulations.length > 0) {
    rowIndex += 1;
    sheet.getCell(`A${rowIndex}`).value = intl.formatMessage({id: "SUSTELL.DASHBOARD.SIMULATIONS"});
    sheet.getRow(rowIndex).font = { bold: true };
    rowIndex += 1;
    sheet.getCell(`A${rowIndex}`).value = dashboard.type === DashboardType.Feeds ? intl.formatMessage({id:"COMPOUNDFEEDS.CARD.TITLE"}) : intl.formatMessage({id:"REPORT.DATASET_TABLE.DATASETS"});
    const values = dashboard.simulation.references.map(ref => dashboard.filteredSortedFootprints.find(f => f.reference === ref)?.name || '')
    sheet.getCell(`B${rowIndex}`).value = values.length > 0 ? values.join(', ') : intl.formatMessage({id:"SUSTELL.DASHBOARD.DETAILS.SELECT.ALL_FOOTPRINTS"});
    rowIndex += 1;
    dashboard.simulation.simulations.forEach(sim => {
      sheet.getCell(`A${rowIndex}`).value = sim.analysisGroup;
      sheet.getCell(`B${rowIndex}`).value = `${sim.change} %`;
      rowIndex += 1;
    })
  }

  sheet.getColumn(1).width = 35;
  sheet.getColumn(2).width = 80;
  return sheet;
}

const getSheetName = (impactCategoryUnifedName: string): string =>
  SHORTEN_CATEGORY_NAMES[
    impactCategoryUnifedName as keyof typeof SHORTEN_CATEGORY_NAMES
  ] || impactCategoryUnifedName;
 

const getTargetByUnifedCategory = (
  dashboard: DashboardModel,
  unifiedImpactCategory: string
): number | undefined => {
  let target: number | undefined;
  // loop through all same categories, but with different names, only one should exist and may have a target value
  (
    SAME_FOOTPRINT_CATEGORIES[
      unifiedImpactCategory as keyof typeof SAME_FOOTPRINT_CATEGORIES
    ] || [unifiedImpactCategory]
  ).forEach((impactCategory) => {
    const impactTarget = dashboard.getTargetByCategory(
      impactCategory as FootprintCategoryKey
    );
    // if target for given imapact name is set, use it as resulting target
    if (impactTarget != null)
      target = impactTarget;
  });

  return target;
};

const getDistributionByUnifiedImpactCategory = (
  dashboardFootprint: DashboardFootprintModel,
  unifiedImpactCategory: string
) => {
  let distribution:
    | {
        amount: number;
        analysisGroup: string;
        percentage: number;
      }[]
    | undefined;
  // loop through all same categories, but with different names, only one should exist and may have a value
  (
    SAME_FOOTPRINT_CATEGORIES[
      unifiedImpactCategory as keyof typeof SAME_FOOTPRINT_CATEGORIES
    ] || [unifiedImpactCategory]
  ).forEach((impactCategory) => {
    const impactDistribution = dashboardFootprint.getDistributionByImpactCateg(
      impactCategory as FootprintCategoryKey
    );
    // if distribution for given imapact name is set, use it as resulting distribution
    if (impactDistribution && (!distribution || distribution?.length === 0))
      distribution = impactDistribution;
  });

  return distribution;
};

const getImpactCategoryUnit = (
  dashboardFootprint: DashboardFootprintModel,
  unifiedImpactCategory: string
) => {
  let unit: string | undefined;
  // loop through all same categories, but with different names, only one should exist and may have a value
  (
    SAME_FOOTPRINT_CATEGORIES[
      unifiedImpactCategory as keyof typeof SAME_FOOTPRINT_CATEGORIES
    ] || [unifiedImpactCategory]
  ).forEach((impactCategory) => {
    const impactUnit = dashboardFootprint.categories.find(
      (categ) => categ.key === impactCategory
    )?.unit;
    // if unit for given imapact name is set, use it as resulting unit
    if (impactUnit) unit = impactUnit;
  });

  return unit;
};

const getImpactCategory = (
  dashboardFootprint: DashboardFootprintModel,
  unifiedImpactCategory: string
) => {
  let category: FootprintCategoryModel | undefined;
  // loop through all same categories, but with different names, only one should exist and may have a value
  (
    SAME_FOOTPRINT_CATEGORIES[
      unifiedImpactCategory as keyof typeof SAME_FOOTPRINT_CATEGORIES
    ] || [unifiedImpactCategory]
  ).forEach((impactCategory) => {
    const currentCat = (dashboardFootprint as FootprintModel).categories.find(
      (c) => (impactCategory as FootprintCategoryKey) === c.key
    );
    // if category for given imapact name is set, use it as resulting category
    if (currentCat) category = currentCat;
  });

  return category;
};


const addCategoriesSheets = (dashboard: DashboardModel, workbook: Excel.Workbook, intl: IntlShape) => {
  const sheetsPerCategory = new Map<string, Excel.Worksheet> ();

  const analysisGroups = dashboard?.type === DashboardType.Feeds ? AnalysisGroupsFeed : AnalysisGroupsFarm;
  const categories = new Set(Object.values(FOOTPRINT_CATEGORIES));

  // Create sheets for all impact categories
  categories.forEach(impactCategory => {

    const categorySheet = workbook.addWorksheet(getSheetName(impactCategory));
    categorySheet.getRow(1).font = { bold: true };
    categorySheet.getRow(2).font = { bold: true };
    categorySheet.getCell(`A${1}`).value = impactCategory;
    categorySheet.getCell(`B${1}`).value = intl.formatMessage({id:"BASELINE.FORM.FIELD.START_DATE"});
    categorySheet.getCell(`C${1}`).value = intl.formatMessage({id:"BASELINE.FORM.FIELD.END_DATE"});
    categorySheet.getCell(`D${1}`).value = intl.formatMessage({id:"GENERAL.TOTAL"});

    let indexHead = 4;
    Object.values(analysisGroups).forEach(group => {
      const columnLetter = alphabet[indexHead];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      categorySheet.getCell(`${columnLetter}${1}`).value = group;
      indexHead += 1;
    })
    categorySheet.getCell(`${alphabet[indexHead]}${1}`).value = intl.formatMessage({id:"GENERAL.UNIT_PER_KG"});

    categorySheet.getCell(`A${2}`).value = intl.formatMessage({id:"SUSTELL.FOOTPRINT.TABLE.COLUMNS.PERSONALTARGET"});
    // categorySheet.getCell(`B${2}`).value = dashboard.getTargetByCategory(impactCategory as FootprintCategoryKey) || '';
    categorySheet.getCell(`B${2}`).value = getTargetByUnifedCategory(dashboard, impactCategory) || '';

    
    sheetsPerCategory.set(impactCategory, categorySheet);
    let rowIndex = 3;
    dashboard.filteredSortedFootprints.forEach(dashboardFootprint => {

      const distribution = getDistributionByUnifiedImpactCategory(dashboardFootprint, impactCategory);
      const unit =  getImpactCategoryUnit(dashboardFootprint, impactCategory)|| '';

      categorySheet.getCell(`A${rowIndex}`).value = dashboardFootprint.name;
      const {start, end} = dashboardFootprint.dateRange;

      categorySheet.getCell(`B${rowIndex}`).value = start ? intl.formatDate(start, dateOnlyDisplayOptionsShort as FormatDateOptions) : 'unknown';
      categorySheet.getCell(`C${rowIndex}`).value = end ? intl.formatDate(end, dateOnlyDisplayOptionsShort as FormatDateOptions) : 'unknown';
      
      let columnIndex = 3;
      let columnLetter = alphabet[columnIndex];

      const totalAmount = distribution?.reduce((acc, item) => (
        acc +
        (item.amount && !Number.isNaN(item.amount)
          ? Number(item.amount)
          : 0)
      ), 0);
      categorySheet.getCell(`${columnLetter}${rowIndex}`).value = totalAmount;
      columnIndex += 1;
      columnLetter = alphabet[columnIndex];

      Object.values(analysisGroups).forEach(group => {
        let valueForGroup: number | string | undefined 
          = distribution?.find(distEl => distEl.analysisGroup === group)?.amount;
        if (!valueForGroup) {
          valueForGroup = 0;
        }
        categorySheet.getCell(`${columnLetter}${rowIndex}`).value = valueForGroup;
        columnIndex += 1;
        columnLetter = alphabet[columnIndex];
      })

      categorySheet.getCell(`${columnLetter}${rowIndex}`).value = unit;
      if (dashboardFootprint.isSimulation) {
        categorySheet.getCell(`A${rowIndex}`).value = `(${intl.formatMessage({id: "SUSTELL.DASHBOARD.SIMULATION"})}) ${dashboardFootprint.name}`;
        categorySheet.getRow(rowIndex).font = { bold: true };
        rowIndex += 1;
        const categoryOriginal = getImpactCategory(dashboardFootprint, impactCategory);
        if (categoryOriginal) {
          const distributionOriginal = categoryOriginal.distribution;

          categorySheet.getCell(`A${rowIndex}`).value = `(${intl.formatMessage({id: "GENERAL.ORIGINAL_VALUE"})} ${dashboardFootprint.name}`;
          columnIndex = 1;
          columnLetter = alphabet[columnIndex];
          const totalAmountOriginal = distributionOriginal?.reduce((acc, item) => (
            acc +
            (item.amount && !Number.isNaN(item.amount)
              ? Number(item.amount)
              : 0)
          ), 0);
          categorySheet.getCell(`${columnLetter}${rowIndex}`).value = totalAmountOriginal;
          columnIndex += 1;
          columnLetter = alphabet[columnIndex];

          Object.values(analysisGroups).forEach(group => {
            let valueForGroup: number | string | undefined 
              = distributionOriginal?.find(distEl => distEl.analysisGroup === group)?.amount;
            if (!valueForGroup) {
              valueForGroup = 0;
            }
            categorySheet.getCell(`${columnLetter}${rowIndex}`).value = valueForGroup;
            columnIndex += 1;
            columnLetter = alphabet[columnIndex];
          })
          categorySheet.getCell(`${columnLetter}${rowIndex}`).value = unit;
        }
      }
      rowIndex += 1;
    });

    categorySheet.getColumn(1).width = 45;
    categorySheet.getColumn(2).width = 22;
    categorySheet.getColumn(3).width = 22;
    categorySheet.getColumn(4).width = 22;
    categorySheet.getColumn(5).width = 22;
    categorySheet.getColumn(6).width = 22;
    categorySheet.getColumn(7).width = 22;
    categorySheet.getColumn(8).width = 22;
    categorySheet.getColumn(9).width = 22;
  });
}

const dashboardExportExcel = async (intl: IntlShape, dashboard: DashboardModel, assetDownloadEvent: Function) => {
  const workbook = new Excel.Workbook();

  addSummarySheet(dashboard, workbook, intl);

  addCategoriesSheets(dashboard, workbook, intl);
  const fileName = 'dashboard.xlsx';
  await workbook.xlsx.writeBuffer().then((resultData) => {
    const blob = new Blob([resultData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, fileName);
  });
  assetDownloadEvent(fileName, `Downloads/${fileName}`);
}

export default dashboardExportExcel;