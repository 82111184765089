import lookupValues from "../../sustell_15/helpers/lookupValues";

export const CustomerIngredientTypes = {
  CF_INGREDIENTS: 'CF_INGREDIENTS',
  SINGLE_INGREDIENTS: 'SINGLE_INGREDIENTS'
}

// * For local testing use 2 ingredients,
// * For all other environments use full lists
export const getCustomerIngredients = (type, ids=[]) => {
  if (!type || (type !== CustomerIngredientTypes.CF_INGREDIENTS && type !== CustomerIngredientTypes.SINGLE_INGREDIENTS)) {
    console.error('Please pass the ingredient type: { CustomerIngredientTypes.CF_INGREDIENTS | CustomerIngredientTypes.SINGLE_INGREDIENTS }');
    return null;
  }
  const isLocalEnv =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENVIRONMENT_TITLE === 'DevTest system' ||
    process.env.REACT_APP_ENVIRONMENT_TITLE === 'DevTest - Sustell 1.5' ||
    process.env.REACT_APP_ENVIRONMENT_TITLE === 'Testing system - Sustell 1.5';
  let ingredients = null;
  if (type === CustomerIngredientTypes.SINGLE_INGREDIENTS) {
    ingredients = lookupValues.ingredients.singleIngredients;
  } else {
    ingredients = lookupValues.ingredients.compoundFeedIngredients;
  }
  let retVal = Object.keys(ingredients)
    .filter(key => {
      if (isLocalEnv && !ingredients[key]?.localTest) {
        return false;
      }
      if (!isLocalEnv && ingredients[key]?.localTest) {
        return false;
      }
      if (ids?.length > 0 && !ids.includes(key)) {
        return false;
      }
      return true;
    })
    .map(key => {
      return {
        id: key,
        name: ingredients[key].name
      };
  })
  return retVal;
}
