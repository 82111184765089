import * as Yup from 'yup';
import {
  numericOptionalGreaterThanMinLessThanMax,
  numericOptionalWithGreaterThanMin,
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  numericRequiredGreaterThanMinLessThanMax,
  numericRequiredWithGreaterThanMin,
  numericRequiredWithMin,
  sumCheck
} from './validationObjectBuilderFunctions';

const usedResourcesSalmon = ({ intl }) =>
  Yup.object({
    electricityUse: numericOptionalWithMin(intl, 0),
    waterUse: numericOptionalWithMin(intl, 0),
  });

const stageDataPartSalmon = ({ intl }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          stageData: Yup.object({
            output: Yup.object().shape(
              {
                production: numericRequiredWithMin(intl, 0),
                averageWeight: numericRequiredWithGreaterThanMin(intl, 0).test(
                  'dependValTest',
                  '',
                  function (value) {
                    if (!value || value < 0) return true;
                    const { path, createError, from } = this;
                    let averageLiveWeightStockedAnimals = 0;
                    if (from[1]?.value?.input?.averageLiveWeightStockedAnimals)
                      averageLiveWeightStockedAnimals = Number(
                        from[1]?.value?.input?.averageLiveWeightStockedAnimals
                      );

                    if (averageLiveWeightStockedAnimals > value * 0.1) {
                      return createError({
                        path: path,
                        message: intl.formatMessage(
                          {
                            id: 'VALIDATION.FIELD.MAX_VALUE_OF_AVG_WEIGHT2',
                          },
                          { max: Math.round(value * 0.1 * 1000) / 1000 }
                        ),
                      });
                    }

                    return true;
                  }
                ),

                mortalities: numericRequiredWithMin(intl, 0).test(
                  'sumCheck',
                  intl.formatMessage(
                    { id: 'VALIDATION.NUMERIC.SUM_MORTALITIES_ESCAPEES' }
                  ),
                  function () {
                    return sumCheck(
                      [
                        this.parent.mortalities,
                        this.parent.escapees
                      ],
                      this.parent.production
                    );
                  }
                ),
                escapees: numericRequiredWithMin(intl, 0).test(
                  'sumCheck',
                  intl.formatMessage(
                    { id: 'VALIDATION.NUMERIC.SUM_MORTALITIES_ESCAPEES' }
                  ),
                  function () {
                    return sumCheck(
                      [
                        this.parent.mortalities,
                        this.parent.escapees
                      ],
                      this.parent.production
                    );
                  }
                ),
                animalBiomassStartOfYear: numericRequiredWithMin(intl, 0),
                liveWeight: numericRequiredWithMin(intl, 0),
                animalBiomasEndOfYear: numericRequiredWithMin(intl, 0),
              },
              ['averageWeight', 'input.averageLiveWeightStockedAnimals']
            ), // resolves circular referencing,

            input: Yup.object({
              numberOfStockedAnimals: numericRequiredWithMin(intl, 0),
              averageLiveWeightStockedAnimals: numericRequiredWithMin(
                intl,
                0
              ).test('dependValTest', '', function (value) {
                if (!value || value < 0) return true;
                const { path, createError, from } = this;
                // TODO: check, this is what their API returns:
                // "If the weight of the juvenile is more than 10% of the final bodyweight of
                // the harvested salmon then primary data for juvenile production is required.",
                let avgWeightValue = 0;
                if (from[1]?.value?.output?.averageWeight)
                  avgWeightValue = Number(
                    from[1]?.value?.output?.averageWeight
                  );
                if (avgWeightValue > 1 && value > avgWeightValue * 0.1) {
                  return createError({
                    path: path,
                    message: intl.formatMessage(
                      { id: 'VALIDATION.FIELD.MAX_VALUE_OF_AVG_WEIGHT' },
                      { max: Math.round(avgWeightValue * 0.1 * 1000) / 1000 }
                    ),
                  });
                } else return true;
              }),
              // this is calculated filed, no need to be validated
              // weightOfStockedAnimals: numericRequiredWithMin(intl, 0),
            }),
            feed: Yup.object({
              totalFeedIntake: Yup.number()
                .transform((changed, original) =>
                  original === '' ? undefined : changed
                )
                .required(
                  intl.formatMessage({
                    id: 'VALIDATION.FIELD.REQUIRED_CALCULATED',
                  })
                )
                .moreThan(
                  0,
                  intl.formatMessage(
                    { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN_CALCULATED' },
                    { value: 0 }
                  )
                ),
              efcr: numericOptionalWithMinMax(intl, 0.9, 2),
              // mandatory nutritional content
              requiredNutritionalContent: Yup.object()
                .shape({
                  crude_protein: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ).test(
                    'sumCheck',
                    intl.formatMessage(
                      { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
                      { max: 100 }
                    ),
                    function () {
                      return sumCheck(
                        [
                          this.parent.crude_protein,
                          this.parent.crude_lipid,
                          this.parent.crude_fibre,
                          this.parent.starch,
                          this.parent.sugar,
                          this.parent.phosphorus,
                        ],
                        100
                      );
                    }
                  ),
                  crude_lipid: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ).test(
                    'sumCheck',
                    intl.formatMessage(
                      { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
                      { max: 100 }
                    ),
                    function () {
                      return sumCheck(
                        [
                          this.parent.crude_protein,
                          this.parent.crude_lipid,
                          this.parent.crude_fibre,
                          this.parent.starch,
                          this.parent.sugar,
                          this.parent.phosphorus,
                        ],
                        100
                      );
                    }
                  ),
                  crude_fibre: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ).test(
                    'sumCheck',
                    intl.formatMessage(
                      { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
                      { max: 100 }
                    ),
                    function () {
                      return sumCheck(
                        [
                          this.parent.crude_protein,
                          this.parent.crude_lipid,
                          this.parent.crude_fibre,
                          this.parent.starch,
                          this.parent.sugar,
                          this.parent.phosphorus,
                        ],
                        100
                      );
                    }
                  ),
                  starch: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ).test(
                    'sumCheck',
                    intl.formatMessage(
                      { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
                      { max: 100 }
                    ),
                    function () {
                      return sumCheck(
                        [
                          this.parent.crude_protein,
                          this.parent.crude_lipid,
                          this.parent.crude_fibre,
                          this.parent.starch,
                          this.parent.sugar,
                          this.parent.phosphorus,
                        ],
                        100
                      );
                    }
                  ),
                  sugar: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ).test(
                    'sumCheck',
                    intl.formatMessage(
                      { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
                      { max: 100 }
                    ),
                    function () {
                      return sumCheck(
                        [
                          this.parent.crude_protein,
                          this.parent.crude_lipid,
                          this.parent.crude_fibre,
                          this.parent.starch,
                          this.parent.sugar,
                          this.parent.phosphorus,
                        ],
                        100
                      );
                    }
                  ),
                  phosphorus: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ).test(
                    'sumCheck',
                    intl.formatMessage(
                      { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
                      { max: 100 }
                    ),
                    function () {
                      return sumCheck(
                        [
                          this.parent.crude_protein,
                          this.parent.crude_lipid,
                          this.parent.crude_fibre,
                          this.parent.starch,
                          this.parent.sugar,
                          this.parent.phosphorus,
                        ],
                        100
                      );
                    }
                  ),
                  dm_feed: numericRequiredGreaterThanMinLessThanMax(
                    intl,
                    0,
                    100
                  ),
                })
                .required(),
              optionalNutritionalContent: Yup.object().shape({
                gross_energy: numericOptionalWithGreaterThanMin(intl, 0),
                digestible_energy: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                digestible_nitrogen: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                digestible_phosphorus: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                digestible_carbon: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                soluble_nitrogen: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                soluble_phosphorus: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                soluble_carbon: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
                feed_spill: numericOptionalGreaterThanMinLessThanMax(
                  intl,
                  0,
                  100
                ),
              }),
            }),
            operations: Yup.object({
              antifouling: numericOptionalWithMinMax(intl, 0, 2),
              transport: Yup.array()
                .of(
                  Yup.object({
                    boatName: Yup.string().required(
                      intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' })
                    ),
                    type: Yup.string().required(
                      intl.formatMessage({
                        id: 'VALIDATION.FIELD.INPUT_SELECT',
                      })
                    ),
                    amount: numericRequiredWithMin(intl, 0),
                  })
                )
                .test('dieselSum', '', function (values) {
                  const { path, createError } = this;
                  let totalDiesel = 0;

                  values?.forEach((item, index) => {
                    if (item.type === 'diesel') {
                      let val = item.amount || 0;
                      totalDiesel += val;
                    }
                  });
                  if (totalDiesel <= 0) {
                    //show the error message for the first diesel boat, or in case there is no diesel boat, show for the first one
                    let errorIndex = values?.findIndex(
                      (el) => el.type === 'diesel'
                    );
                    let errorElPath = path + `[${errorIndex}].amount`;
                    if (errorIndex === -1) {
                      errorIndex = 0;
                      errorElPath = path + `[${errorIndex}].type`;
                    }
                    // console.log("errorPath",errorElPath);
                    return createError({
                      path: errorElPath,
                      message: intl.formatMessage({
                        id: 'BASELINE.FORM.SALMON.BOATS.VALIDATION.MANDATORY_DIESEL_USE',
                      }),
                    });
                  }
                  return true;
                }),
              materialsFarmConstruction: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(
                    intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
                  ),
                  amount: numericRequiredWithMin(intl, 0),
                })
              ),
              anitibioticsUse: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(
                    intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
                  ),
                  amount: numericRequiredWithMin(intl, 0),
                })
              ),
              liceTreatment: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(
                    intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
                  ),
                  amount: numericRequiredWithMin(intl, 0),
                })
              ),
            }),
          }),
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({info:infoObject})
    .concat(Yup.object({resourceUse:usedResourcesSalmon(confObj)}))
    .concat(stageDataPartSalmon(confObj));
  return combinedSchema;

};

export default assembleValidationSchemaSustell;