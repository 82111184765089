/* eslint-disable no-console */
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { Tooltip } from '@material-ui/core';
import { ReactElement, MouseEvent, Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import Can from '../../../modules/Auth/Can';

interface TableActionsProps<T> {
  item: T;
  itemId: string;
  canSubject: string;
  editDisabled: boolean;
  handleEdit: (item: T) => Promise<void>;
  isDuplicateLoading: boolean;
  handleDuplicate: (item: T) => Promise<void>;
  isDeleteLoading: boolean;
  handleDelete: Dispatch<SetStateAction<string>>;
  // eslint-disable-next-line react/require-default-props
  moreActions?: ReactElement;
}

const TableActions = <T,>({
  canSubject,
  editDisabled,
  item,
  itemId,
  handleEdit,
  isDuplicateLoading,
  handleDuplicate,
  isDeleteLoading,
  handleDelete,
  moreActions,
}: TableActionsProps<T>) => {
  const intl = useIntl();

  const handleEditClick = (
    event: MouseEvent<HTMLDsmButtonElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    handleEdit(item).catch((e) => console.error(e));
  };

  const handleDuplicateClick = (
    event: MouseEvent<HTMLDsmButtonElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    handleDuplicate(item).catch((e) => console.error(e));
  };

  const handleDeleteClick = (
    event: MouseEvent<HTMLDsmButtonElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    handleDelete(itemId);
  };

  return (
    <>
      {Can('update', canSubject) && (
        <Tooltip title={intl.formatMessage({ id: 'GENERAL.EDIT' })}>
          <DsmButton
            variant="text"
            iconOnly
            style={{ marginLeft: '12px' }}
            disabled={editDisabled}
            onClick={(event) => (!editDisabled ? handleEditClick(event) : null)}
          >
            <DsmIcon
              slot="before"
              name="general/edit-01"
              style={
                editDisabled
                  ? { color: 'rgba(0, 0, 0, 0.38)' }
                  : { color: 'black' }
              }
            />
          </DsmButton>
        </Tooltip>
      )}
      {Can('create', canSubject) && (
        <Tooltip title={intl.formatMessage({ id: 'GENERAL.COPY' })}>
          <DsmButton
            variant="text"
            iconOnly
            disabled={isDuplicateLoading}
            style={{ marginLeft: '12px' }}
            onClick={(event) => handleDuplicateClick(event)}
          >
            <DsmIcon
              slot="before"
              style={{ color: isDuplicateLoading ? 'lightgray' : 'black' }}
              name="general/copy-03"
            />
          </DsmButton>
        </Tooltip>
      )}
      {Can('delete', canSubject) && (
        <Tooltip title={intl.formatMessage({ id: 'GENERAL.DELETE' })}>
          <DsmButton
            variant="text"
            iconOnly
            destructive
            disabled={isDeleteLoading}
            style={{ marginLeft: '12px' }}
            onClick={(event) => handleDeleteClick(event)}
          >
            <DsmIcon slot="before" name="general/trash-01" />
          </DsmButton>
        </Tooltip>
      )}
      {moreActions && moreActions}
    </>
  );
};
export default TableActions;
