import { FC, Fragment } from 'react';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { FormType, ListEntry } from '../../common';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { PoultryIntervention } from '../../../../models/Intervention/PoultryIntervention';
import { BaselineFeedWithOrigin } from '../../../../models/Baseline';
import {
  CompoundFeedDatabase,
  MasterDataOption,
  Maybe,
} from '../../../../../../graphql/types';
import { PoultryFeed } from '../../../../models/Baseline/PoultryBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds_v2';
import SustellSingleIngredientFeed from '../../../../../modules/Farms/Baseline/SustellSingleIngredientFeeds_v2';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import SustellInterventionSingleIngredientFeedComponent from '../../../../../modules/Farms/Intervention/SustellInterventionSingleIngredientFeedComponent';

interface CompoundFeed {
  display_name: string;
  value: string;
  databaseFoundation: CompoundFeedDatabase;
}

interface FeedsFormCommonDataPartProps {
  formType: FormType;
  itemIndex: number;
  // eslint-disable-next-line react/require-default-props
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  singleIngredients?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  origins?: ListEntry[];
  databaseFoundation: CompoundFeedDatabase;
  feedBaseline: PoultryFeed;
  fieldItemPrefix: string;
}

const FeedsFormCommonDataPart: FC<FeedsFormCommonDataPartProps> = ({
  formType,
  itemIndex,
  compoundFeeds,
  singleIngredients,
  origins,
  databaseFoundation,
  feedBaseline,
  fieldItemPrefix,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const fc = useFormContext<PoultryIntervention>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass as string)
    : ('kg' as string);

  const getCompoundFeedsList = (databaseOption: CompoundFeedDatabase) => {
    const feeds = (compoundFeeds as unknown as CompoundFeed[]) || [];
    return feeds.filter(
      (feed: CompoundFeed) => feed.databaseFoundation === databaseOption
    );
  };

  const {
    fields: feedsAdditions,
    append: appendFeedsAdditions,
    remove: removeFeedsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.compoundFeedsAdditions`,
    control: fc.control,
    keyName: 'keyId',
  });

  const {
    fields: ingredientsAdditions,
    append: appendIngredientsAdditions,
    remove: removeIngredientsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.singleIngredientsAdditions`,
    control: fc.control,
    keyName: 'keyId',
  });

  const getBaselineFeedLabel = (id: string) => {
    const feedDisplayName = compoundFeeds?.find(
      (item) => item.value === id
    )?.display_name;
    return feedDisplayName || 'unknown';
  };

  return (
    <div>
      <DsmGrid
        className={`${classes.dsmGridTwoColumn2To3} ${classes.topVerticalSpacer}`}
      >
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <h6>{intl.formatMessage({ id: 'COMPOUNDFEEDS.CARD.TITLE' })}</h6>
          {(feedBaseline?.compoundFeeds || [])
            ?.filter((item) => !!item.feedType)
            ?.map((feedItem, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment
                key={`InterventionCompoundFeed_${feedItem.feedType}_${i}`}
              >
                <input
                  type="hidden"
                  value={feedItem.feedType}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].feedType`}
                />
                <RowControlledTextFieldWithMetrics
                  name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
                  label={getBaselineFeedLabel(feedItem.feedType)}
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
                  })}
                  metricUnit={intl.formatMessage(
                    { id: 'ADDORNMENT.UNITS.WITH_PER_ANIMAL_SUFIX' },
                    { unit: barnOutputMassUnit }
                  )}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  baseline={feedItem.kgPerAnimal}
                  disabled={formType === 'view'}
                  minAllowedValue={0}
                  displayFormattedUnit={false}
                  originalMetricUnit={barnOutputMassUnit}
                />
              </Fragment>
            ))}

          <SustellRationFeeds
            key="compoundFeedsAdditions"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED',
            })}
            availableFeedItems={getCompoundFeedsList(databaseFoundation)}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="compoundFeedsAdditions"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
            })}
            formState={formType}
            compoundFeed
            feedData={feedsAdditions}
            fieldsAppenRemoveObject={{
              fields: feedsAdditions,
              append: appendFeedsAdditions,
              remove: removeFeedsAdditions,
            }}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <h6>
            {intl.formatMessage({
              id: 'PRODUCTION.DATA.BARN.SINGLE_INGREDIENTS',
            })}
          </h6>
          {(feedBaseline?.singleIngredients || [])
            ?.filter((item) => !!item.feedType && !!item.origin)
            ?.map((singleIngredientItem: BaselineFeedWithOrigin, i: number) => (
              <SustellInterventionSingleIngredientFeedComponent
                formType={formType}
                singleIngredient={singleIngredientItem}
                ingredientIndex={i}
                fieldItemPrefix={fieldItemPrefix}
                availableOrigins={origins}
              />
            ))}

          <SustellSingleIngredientFeed
            key="singleIngredients"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.SINGLE_INGREDIENT',
            })}
            barnItemIndex={itemIndex}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            availableFeedItems={singleIngredients}
            availableOrigins={origins}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="singleIngredientsAdditions"
            tooltip={
              <div key="feeds-form-dialog-singleIngredients">
                {intl.formatMessage(
                  {
                    id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP',
                  },
                  { br: <br /> }
                )}
              </div>
            }
            formState={formType}
            feedData={ingredientsAdditions}
            fieldsAppenRemoveObject={{
              fields: ingredientsAdditions,
              append: appendIngredientsAdditions,
              remove: removeIngredientsAdditions,
            }}
          />
        </DsmGrid>
      </DsmGrid>
    </div>
  );
};

export default FeedsFormCommonDataPart;
