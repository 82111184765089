import React from 'react';
import { Button, Box } from '@material-ui/core';
import { injectIntl } from '../../../_metronic/i18n/customInjectIntl';
import Can from '../../modules/Auth/Can';

import { DsmIcon } from '@dsm-dcs/design-system-react';
import {
  DsmButtonV2,
  DsmButton,
} from '../../sustell_15/components/helpers/DsmButton';

class ButtonControlGroupComponent extends React.Component {
  render() {
    const intl = this.props.intl;
    /*
        Component will always render cancel button
        Props:
            - cancelHandler - function to handle cancel btn click
            - cancelLabel (optional)
            - saveHandler - function to handle save btn click - if not passed save btn will not be rendered
            - saveLabel (optional)
            - additionalControls - list of object in the form of
                                   {btnClkHandler:functionObj,btnLabel:labelString}
                                   Will render additional buttons after cancel (and save)
    */

    let {
      cancelHandler,
      cancelLabel,
      saveHandler,
      saveLabel,
      additionalControls,
      draftHandler,
    } = this.props;
    if (!cancelLabel)
      cancelLabel = intl.formatMessage({ id: 'GENERAL.CANCEL' });
    if (!saveLabel) saveLabel = intl.formatMessage({ id: 'GENERAL.SAVE' });

    let additionalButtons = null;
    if (additionalControls) {
      additionalButtons = additionalControls.map((item, i) => (
        <Box ml={1} key={'addl_btn_' + i}>
          <Button
            variant="contained"
            color={
              i + 1 === additionalControls.length && saveHandler === null
                ? 'primary'
                : 'default'
            }
            disableElevation
            onClick={item.btnClkHandler}
          >
            {item.btnLabel}
          </Button>
        </Box>
      ));
    }
    return (
      <Box mt={3} mb={2} display="flex" justifyContent="flex-end">
        {draftHandler && (
          <Box ml={1}>
            <Button
              variant={saveHandler === null ? 'contained' : 'outlined'}
              type="button"
              disableElevation
              onClick={draftHandler}
            >
              {intl.formatMessage({ id: 'GENERAL.DISCARD_DRAFT' })}
            </Button>
          </Box>
        )}
        {cancelHandler && (
          <Box ml={1}>
            <Button variant="outlined" type="button" onClick={cancelHandler}>
              {cancelLabel}
            </Button>
          </Box>
        )}
        {additionalButtons}
        {saveHandler && (
          <Box ml={1}>
            <DsmButton
              variant="contained"
              color="primary"
              disableElevation
              type="button"
              onClick={saveHandler}
            >
              {saveLabel}
            </DsmButton>
          </Box>
        )}
      </Box>
    );
  }
}

export const ButtonControlGroup = injectIntl(ButtonControlGroupComponent);

class ButtonControlGroupComponentCF extends React.Component {
  render() {
    const intl = this.props.intl;

    /*
        Component will always render cancel button
        Props:
            - cancelHandler - function to handle cancel btn click
            - cancelLabel (optional)
            - saveHandler - function to handle save btn click - if not passed save btn will not be rendered
            - saveLabel (optional)
            - additionalControls - list of object in the form of
                                   {btnClkHandler:functionObj,btnLabel:labelString}
                                   Will render additional buttons after cancel (and save)
    */

    let {
      cancelHandler,
      cancelLabel,
      saveHandler,
      saveLabel,
      formType,
      additionalControls,
      draftHandler,
      nextHandler,
      nextLabel,
      editFeedHandler,
      prevHandler,
      prevLabel,
      isLastStep,
      isFirstStep = true,
    } = this.props;

    if (!cancelLabel) {
      cancelLabel = intl.formatMessage({ id: 'GENERAL.CANCEL' });
    }
    if (!saveLabel) {
      saveLabel = intl.formatMessage({ id: 'GENERAL.SAVE' });
    }
    if (!nextLabel) {
      nextLabel = intl.formatMessage({ id: 'GENERAL.SAVE_AND_CONTINUE' });
    }
    if (!prevLabel) {
      prevLabel = intl.formatMessage({ id: 'GENERAL.BACK' });
    }

    let additionalButtons = null;
    if (additionalControls) {
      additionalButtons = additionalControls.map((item, i) => (
        <Box ml={1} key={'addl_btn_' + i}>
          <Button
            variant="contained"
            color={
              i + 1 === additionalControls.length && saveHandler === null
                ? 'primary'
                : 'default'
            }
            disableElevation
            onClick={item.btnClkHandler}
          >
            {item.btnLabel}
          </Button>
        </Box>
      ));
    }
    return (
      <Box mt={3} mb={2} display="flex">
        {cancelHandler && (
          <Box display="flex">
            <DsmButtonV2
              variant="secondary"
              type="button"
              style={
                {
                  background: '#FFFFFF',
                  color: '#0070BA',
                  '::hover': {
                    backgroundColor: '#FFFFFF !important',
                  },
                }
              }
              onClick={cancelHandler}
            >
              {cancelLabel}
            </DsmButtonV2>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginLeft: 'auto' }}
        >
          {draftHandler && (
            <Box ml={1} style={{ display: 'none' }}>
              <Button
                variant={saveHandler === null ? 'contained' : 'outlined'}
                size="large"
                type="button"
                disableElevation
                onClick={draftHandler}
              >
                {intl.formatMessage({ id: 'GENERAL.DRAFT.ACTION.SAVE' })}
              </Button>
            </Box>
          )}
          {additionalButtons}
          {nextHandler && !isFirstStep && formType === 'new' && (
            <Box ml={1}>
              <DsmButtonV2
                variant="outlined"
                disableElevation
                type="button"
                onClick={prevHandler}
                style={{
                  background: '#FFFFFF',
                  color: '#0070BA',
                }}
              >
                <DsmIcon
                  slot="before"
                  name="arrows/arrow-left"
                  style={{ paddingRight: '5px', height: '24px', width: '24px' }}
                />
                {prevLabel}
              </DsmButtonV2>
            </Box>
          )}
          {nextHandler && !isLastStep && formType === 'new' && (
            <Box ml={1}>
              <DsmButtonV2
                variant="primary"
                disableElevation
                type="button"
                onClick={nextHandler}
              >
                {nextLabel}
                <DsmIcon
                  slot="after"
                  name="arrows/arrow-right"
                  style={{ paddingLeft: '5px', height: '24px', width: '24px' }}
                />
              </DsmButtonV2>
            </Box>
          )}
          {formType === 'view' &&
            editFeedHandler &&
            Can('update', 'Compound Feed') && ( // add handler
              <Box ml={1}>
                <DsmButtonV2
                  variant="primary"
                  disableElevation
                  type="button"
                  onClick={editFeedHandler}
                >
                  <DsmIcon
                    slot="before"
                    name="general/edit-02"
                    style={{
                      paddingRight: '5px',
                      height: '24px',
                      width: '24px',
                    }}
                  />
                  {intl.formatMessage({ id: 'COMPOUNDFEED_WIZARD.EDIT_FEED' })}
                </DsmButtonV2>
              </Box>
            )}
          {((saveHandler && isLastStep) || formType === 'edit') && (
            <Box ml={1}>
              <DsmButtonV2
                variant="primary"
                disableElevation
                type="button"
                onClick={saveHandler}
              >
                {intl.formatMessage({ id: 'GENERAL.SAVE_AND_CONTINUE' })}
                <DsmIcon
                  slot={'after'}
                  name={'general/save-01'}
                  style={{ paddingLeft: '5px', height: '24px', width: '24px' }}
                />
              </DsmButtonV2>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export const ButtonControlGroupCompoundFeed = injectIntl(
  ButtonControlGroupComponentCF
);
