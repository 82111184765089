function assembleFootprintURL(
  customerID: string,
  baselineReference: string | undefined,
  footprintType = 'PRODUCTION_SYSTEM',
  comparisonCustomerID: string | null = null,
  projectID: string | null = null,
  baselineType = 'b',
  comparisonReference: string | null = null,
  comparisonType = 'b'
) {
  let retURL =
    footprintType === 'PRODUCTION_SYSTEM'
      ? '/footprints/'
      : '/feed-footprints/';
  if (projectID) retURL += `projects/${projectID}/`;
  if (customerID) {
    retURL += `${customerID}/`;
    if (baselineReference) {
      retURL += `${baselineType}/${baselineReference}/`;
      if (comparisonReference) {
        retURL += `${comparisonType}/${comparisonReference}`;
        if (comparisonCustomerID) retURL += `/${comparisonCustomerID}`;
      }
    }
  }
  retURL =
    retURL.charAt(retURL.length - 1) === '/' ? retURL.slice(0, -1) : retURL;
  return retURL;
}

export default assembleFootprintURL;
