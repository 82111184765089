import React, { useEffect, useState } from 'react';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';

import { Tabs, Tab, Grid, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { UserPersonalData } from './UserPersonalData';
import DatabaseSelection from './databaseSelection';
import UnitsOfMeasurementV2 from './UnitsOfMeasurementV2';
import useStyles from './profileStyles/myProfileStyles';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import FacilitiesTable from './FacilitiesTable';
import Can from '../Auth/Can';
import DefaultCustomer from './defaultCustomer';
import { Customer } from '../../../graphql/types';
import { listCustomers } from '../../../graphql/queries';
import paginateQuery from '../../sustell_15/helpers/paginate';
import useAdobeDataLayer from '../../sustell_15/analytics/adobeDataLayer';

interface TabsComponentProps {
  customerId: string | undefined;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ customerId }) => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { formStartEvent, ctaClickEvent } = useAdobeDataLayer();

  const loadCustomers = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    paginateQuery(
      {
        query: listCustomers,
      },
      'listCustomers'
    ).then(
      (response) => {
        setCustomers(response);
      },
      (reason: any) => {
        console.log('Error fetching customers: ', reason);
      }
    );
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadCustomers();
  }, []);

  const tabsContent = [
    intl.formatMessage({ id: 'PROFILE.PERSONAL_INFORMATION' }),
    intl.formatMessage({ id: 'PROFILE.UNITS.TITLE' }),
    intl.formatMessage({ id: 'PROFILE.DB' }),
    intl.formatMessage({ id: 'PROFILE.FACILITIES.TITLE' }),
    customers.length > 1 &&
      intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.TITLE' }),
  ];
  const [activeView, setActiveView] = useState(0);
  const [title, setTitle] = useState(
    intl.formatMessage({
      id: 'PROFILE.PERSONAL_INFORMATION',
    })
  );
  const [description, setDescription] = useState(
    intl.formatMessage({
      id: 'PROFILE.PERSONAL_INFORMATION.INTRO',
    })
  );

  const getHeaderDetails = (activeTab: number) => {
    switch (activeTab) {
      case 0:
        setTitle(
          intl.formatMessage({
            id: 'PROFILE.PERSONAL_INFORMATION',
          })
        );
        setDescription(
          intl.formatMessage({
            id: 'PROFILE.PERSONAL_INFORMATION.INTRO',
          })
        );
        ctaClickEvent(
          `/profile`,
          'link',
          'Personal information',
          'Tabs Menu',
          'Tabs Header'
        );
        formStartEvent('Edit User PersonalData', 'edit');
        break;
      case 1:
        setTitle(intl.formatMessage({ id: 'PROFILE.UNITS.TITLE' }));
        setDescription(intl.formatMessage({ id: 'PROFILE.UNITS.INTRO' }));
        ctaClickEvent(
          `/profile`,
          'link',
          'Units of measurement',
          'Tabs Menu',
          'Tabs Header'
        );
        formStartEvent('Edit Units of measurements', 'edit');
        break;
      case 2:
        setTitle(intl.formatMessage({ id: 'PROFILE.DB.SELECT' }));
        setDescription(intl.formatMessage({ id: 'PROFILE.DB.INTRO' }));
        ctaClickEvent(
          `/profile`,
          'link',
          'Database selection',
          'Tabs Menu',
          'Tabs Header'
        );
        formStartEvent('Edit Database selection', 'edit');
        break;
      case 3:
        setTitle(intl.formatMessage({ id: 'PROFILE.FACILITIES.TITLE' }));
        setDescription(intl.formatMessage({ id: 'PROFILE.FACILITIES.INTRO' }));
        ctaClickEvent(
          window.location.href,
          'link',
          'My facilities',
          'Tabs Menu',
          'Tabs Header'
        );
        break;
      case 4:
        setTitle(intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.TITLE' }));
        setDescription(
          intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.INTRO' })
        );
        ctaClickEvent(
          `/profile`,
          'link',
          'Default customer',
          'Tabs Menu',
          'Tabs Header'
        );
        formStartEvent('Edit Default customer', 'edit');
        break;

      default:
        setTitle('');
        setDescription('');
    }
  };

  const handleTabChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: number
  ) => {
    if (newValue === 3 && customerId) {
      history.push(`/profile/customers/${customerId}/facilities`);
    } else if (location.pathname.endsWith('facilities')) {
      history.push('/profile');
    }
    setActiveView(newValue);
    getHeaderDetails(newValue);
  };

  useEffect(() => {
    if (location.pathname.endsWith('facilities') && activeView !== 3) {
      setActiveView(3);
      getHeaderDetails(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={classes.profileSubContainer}>
      <Grid>
        <Typography className={classes.profileTitle}>{title}</Typography>
        <Typography className={classes.profileDescription}>
          {description}
        </Typography>
        {activeView === 3 && Can('create', 'Facility') && (
          <DsmButton
            style={{ marginLeft: '86%' }}
            onClick={() => {
              ctaClickEvent(
                window.location.href,
                'button',
                'Add new facility',
                'Tabs Menu',
                'Tabs Header'
              )
              history.push(
                `/profile/customers/${customerId || ''}/facilities/NEW`
              );
            }}
          >
            <DsmIcon slot="before" name="general/plus" />
            {intl.formatMessage({ id: 'SUSTELL.FACILITIES.TITLE_NEW' })}
          </DsmButton>
        )}
      </Grid>
      <Tabs
        className={classes.profileTabs}
        value={activeView}
        onChange={handleTabChange}
      >
        {tabsContent.map((tab, index) => (
          <Tab
            key={`${tab}-key`}
            className={classes.profileTab}
            label={tab}
            value={index}
          />
        ))}
      </Tabs>

      {/* Render content based on the active view */}
      {activeView === 0 && <UserPersonalData />}
      {activeView === 1 && <UnitsOfMeasurementV2 />}
      {activeView === 2 && <DatabaseSelection />}
      {activeView === 3 && <FacilitiesTable />}
      {activeView === 4 && <DefaultCustomer customers={customers} />}
    </div>
  );
};
export default TabsComponent;
