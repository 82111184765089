import React,  {useEffect} from "react";
import useStyles from "./profileStyles/myProfileStyles";
import useUserData from "../../../app/modules/Auth/getAuthUserData";
import { useSubheader} from "../../../../src/_metronic/layout";
import { useIntl } from "../../../_metronic/i18n/customUseIntl";
import { useLocation } from "react-router";

const ProfileHeader: React.FC = () => {
  const classes = useStyles();  
  const user = useUserData();
  const location = useLocation();
  let userEmail = (user && user['attributes'] && user['attributes']['email']) ? user['attributes']['email'] : '';
  let fName = (user && user['attributes'] && user['attributes']['given_name'])? user['attributes']['given_name']: ' ';
  let lName = (user && user['attributes'] && user['attributes']['family_name'])? user['attributes']['family_name']: ' ';
  let userName = fName + ' ' + lName;
  let userInitials = userEmail ? userEmail[0]: ' '
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const subheader = useSubheader();
  const intl = useIntl();
  
  useEffect(() => {
    const breadCrumbs: { pathname?: string; title: string }[] = [];
    breadCrumbs.push({
      pathname: `/profile`,
      title: intl.formatMessage({ id: 'PROFILE.MY_PROFILE' }),
    });
    // Disable eslint due to non-converted (js) subheader
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    subheader?.setBreadcrumbs(breadCrumbs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname]);
  return (
    <div>
    <header className={classes.profileHeaderContainer}>
       {user && (<div className={classes.userContainer}>
       <div className={classes.userInitials}>{userInitials}</div>
        <div className={classes.userNameEmail}>
          <div className={classes.userName}>{userName}</div>
          <div className={classes.userEmail}>{userEmail}</div>
        </div>
      </div>)}

    </header>
    <hr className={classes.lineBorder}
      />
    </div>
  );
};

export default ProfileHeader;
