import { Box, Button, Grid, TextField } from '@material-ui/core';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../FarmFlow/CommonDataParts/DialogContainer';
import {
  DSM_LIGHTER_ORANGE,
  feedIngredientsStyles,
} from '../../../../../_metronic/layout/_core/MaterialThemeProvider';
import { IconProvider } from '../../helpers/IconProvider';

interface ProxyDialogProps {
  originCountry?: string;
  proxyCountry?: string;
  formVisible: boolean;
  handleClose: () => void;
}

const ProxyDialog = ({
  originCountry,
  proxyCountry,
  formVisible,
  handleClose,
}: ProxyDialogProps) => {
  const intl = useIntl();
  const classes = feedIngredientsStyles();

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleClose}
      datasetType="footprint"
      iconCode="proxyIngredientIcon"
      iconColor="#EE7203"
      iconWidth="19px"
      iconBackgroundColor={DSM_LIGHTER_ORANGE as string}
      iconBackgroundSize="48px"
      borderRadius="24px"
      width="605px"
      formTitle={intl.formatMessage({
        id: 'COMPOUNDFEED_WIZARD.PROXY_DIALOG.TITLE',
      })}
    >
      <Box className={classes.proxyDialogTextBox}>
        {intl.formatMessage(
          {
            id: 'COMPOUNDFEED_WIZARD.PROXY_DIALOG.MESSAGE',
          },
          { br: <br /> }
        )}
        &nbsp;
        <IconProvider
          iconCode="proxyIngredientIcon"
          alt="Proxy ingredient"
          height="20px"
          color="#EE7203"
        />
      </Box>
      {originCountry && (
        <>
          <Box mt={2} mb={0} className={classes.proxyDialogSubtitleBox}>
            {intl.formatMessage({
              id: 'COMPOUND_FEED.INGREDIENT.REQUESTED_ORIGIN',
            })}
          </Box>
          <Box mt={0}>
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  defaultValue={originCountry}
                  value={originCountry}
                  className={classes.proxyOriginText}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {proxyCountry && (
        <>
          <Box mt={2} mb={0} className={classes.proxyDialogSubtitleBox}>
            {intl.formatMessage({
              id: 'COMPOUND_FEED.INGREDIENT.SELECTED_ORIGIN',
            })}
          </Box>
          <Box mt={0}>
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  defaultValue={proxyCountry}
                  value={proxyCountry}
                  className={classes.proxyOriginText}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Box mt={4} className={classes.buttonsBox}>
        <Button
          fullWidth
          variant="contained"
          type="button"
          onClick={handleClose}
          className={classes.buttonStyles}
        >
          {intl.formatMessage({
            id: 'COMPOUNDFEED_WIZARD.PROXY_DIALOG.CONFIRM',
          })}
        </Button>
      </Box>
    </DialogContainer>
  );
};

ProxyDialog.defaultProps = {
  originCountry: undefined,
};

export default ProxyDialog;
