/* eslint-disable react/require-default-props */
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { FormType } from "../../sustell_15/components/FarmFlow/common";
import { UserProfilePrefs } from "./UserProfilePrefs";
import ReactHookDsmSelect from "./ReactHookDsmSelect2";
import ReactHookDsmInput from "./ReactHookDsmInput2";
import { useIntl } from "../../../_metronic/i18n/customUseIntl";
import lookupValues, { LookupValues } from "../../sustell_15/helpers/lookupValues";
import { getUnitForResourceTypeName } from "../../sustell_15/components/CompoundFeedWizard/mapper_v2/UnitsConverterV2";

interface SustellResourceAdditionalItemProps {
  labelSelect: string;
  labelInput: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stylesInput?: any;
  placeholderSelect: string;
  typeFieldName?: string;
  amountFieldName?: string;
  deafultValueType: string;
  defaultValueAmount: string | number | undefined;
  index: number;
  formType: FormType;
  fieldItemPrefix: string;
  placeholderUnit?: string;
  tooltipType?: string;
  tooltipUse?: string;
  isFeeds?: boolean;
  isRenewables?: boolean;
  unavailableOptions?: string[];
  onChange?: () =>  void;
  required?: boolean;
}

const SustellResourceAdditionalItem: FC<SustellResourceAdditionalItemProps> = ({
  labelSelect,
  labelInput,
  typeFieldName='type',
  amountFieldName='amount',
  placeholderSelect,
  index,
  formType,
  deafultValueType,
  defaultValueAmount,
  isFeeds=false,
  fieldItemPrefix,
  placeholderUnit=' ',
  tooltipType,
  tooltipUse,
  isRenewables=false,
  unavailableOptions=[],
  stylesInput={},
  onChange,
  required=false,
}) => {

  const formContext = useFormContext();
  const intl = useIntl();

  const fieldTypeName = `${fieldItemPrefix}[${index}].${typeFieldName}`;
  const fieldAmountName = `${fieldItemPrefix}[${index}].${amountFieldName}`;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const items = useWatch({name: `${fieldItemPrefix}`});

  const getAvailableOptions = (currOptionType?: any) => {
   if (Array.isArray(items)) {
    const existingItems = (items || [])?.map((item: { type: string; }) => item?.type?.replace('_use', 'Use')) || [];
    const typesList = isRenewables ? lookupValues.selfGeneratedRenewablesList : lookupValues.energyTypesList;
    const available = typesList
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      .filter(item => !existingItems.includes(item.fieldName) || item.fieldName === currOptionType?.replace('_use', 'Use'))
      .filter(item => !unavailableOptions.includes(item.fieldName))
      .map(item => ({
        value: isFeeds ? item.fieldName.replace('Use', '_use') : item.fieldName,
        text: intl.formatMessage({id: `MASTERDATA.ENUM.ENERGY_CARRIER_TYPE.${item.enumVal}`})
        })
      );
    return available;
   }
   return [];
  }


  const getUnit = (type: string | undefined): string | undefined => {
    if (!type) return undefined;
    
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const unit: string = getUnitForResourceTypeName(type, userUOM)?.userUnit || undefined;

    const unitLabel = (lookupValues as LookupValues)?.units?.[type.replace('_use', 'Use')]?.[unit]?.label
    
    return unitLabel ?? unit
  }

  return (
    <>
      <ReactHookDsmSelect
        label={labelSelect}
        placeholder={placeholderSelect}
        name={fieldTypeName}
        options={getAvailableOptions(formContext.getValues(fieldTypeName))}
        required={required}
        disabled={formType === FormType.View}
        defaultValue={deafultValueType}
        tooltip={tooltipType}
        adornment=" "
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        style={stylesInput}
        changeHandler={() => {if (onChange) onChange()}}
      />
      <ReactHookDsmInput
        defaultValue={defaultValueAmount}
        name={fieldAmountName}
        label={labelInput}
        type="number"
        required={required}
        tooltip={tooltipUse}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        adornment={getUnit(formContext.getValues(fieldTypeName)) || placeholderUnit}
        disabled={formType === FormType.View}
        changeHandler={() => {if (onChange) onChange()}}
      />
    </>
  );
};

export default SustellResourceAdditionalItem;