import React, { Suspense, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { getLoginSettings } from '../graphql/queries';
import { SignOut } from './pages/SignOut';
import Can from './modules/Auth/Can';
import { AdminPage } from './pages/AdminPage';
import { NotificationPage } from './pages/NotificationPage';
import { HelpCenterPage } from './pages/HelpCenterPage';
import NotificationsModal from '../_metronic/layout/components/extras/dropdowns/notifications/NotificationsModal';
import { useNotifications } from './modules/Helpers/use-notifications';
import ProfileCardV2 from './modules/UserProfile/components/ProfileCardV2';
import { SustellHomePage } from './sustell_15/pages/SustellHomePage';
import SustellMyFarmsPage from './sustell_15/pages/FarmFlow/SustellMyFarmsPage';
import SustellFarmPage from './sustell_15/pages/FarmFlow/SustellFarmPage_v2';
import { AdminCustomersPage } from './pages/AdminCustomersPage';
import FootPrintPage from './sustell_15/pages/FootPrintPage';
import SustellDatasetMainPage2 from './sustell_15/pages/FarmFlow/SustellDatasetMainPage_v2';
import SustellDashboardPage from './sustell_15/pages/SustellDashboardPage';
import CompoundFeedsPage from './sustell_15/pages/CompoundFeedsPage';
import CompoundFeedPage from './sustell_15/pages/CompoundFeedPage';
import FarmFormPage from './sustell_15/pages/FarmFormPage';
import SustellInterventionMainPage_v2 from './sustell_15/pages/FarmFlow/SustellInterventionMainPage_v2';
import { SustellProdProcessDatasetsPageV2 } from './sustell_15/pages/FarmFlow/SustellProdProcessDatasetsPage_V2';
import AccessSampleDataDialog from './sustell_15/components/Benchmark/AccessSampleDataDialog';
import { useIntl } from '../_metronic/i18n/customUseIntl';
import FacilityFormPage from './sustell_15/pages/FacilityFormPage';
import HelpCenter from './sustell_15/pages/HelpCenter';

export default function BasePage() {
  const intl = useIntl();
  const notifications = useNotifications();
  const [open, setOpen] = React.useState(
    localStorage.getItem('notificationsViewed') !== 'true'
  );
  const [requestAccessDialog, setRequestAccessDialog] = React.useState(false);
  const selectedCustomer = useSelector(
    (state) => state.selectedCustomer?.value
  );
  useEffect(() => {
    const loadUserProfile = async () => {
      // This field will set true when user login next time
      localStorage.setItem('toShowRequestSampleDataDialog', 'false');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      API.graphql({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: getLoginSettings,
      }).then(
        (response) => {
          const loginSettings = response.data.getUserSettings;
          if (
            (loginSettings?.numberOfLoginTimes &&
              loginSettings?.numberOfLoginTimes >= 3) ||
            loginSettings?.sampleDataAccessPopup
          ) {
            setRequestAccessDialog(false);
          } else {
            setRequestAccessDialog(true);
          }
        },
        (reason) => {
          console.log('Error fetching selected database: ', reason);
        }
      );
    };
    if (localStorage.getItem('toShowRequestSampleDataDialog') !== 'false') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {/*  safeguarding direct access to urls */}
          {!Can('access', 'Admin pages') && (
            <Redirect exact from="/admin/users" to="/homepage" />
          )}
          {!Can('access', 'Admin pages') && (
            <Redirect exact from="/admin/users/*" to="/homepage" />
          )}
          {!Can('access', 'Admin pages') && !Can('read', 'Customer') && (
            <Redirect exact from="/admin/customers" to="/homepage" />
          )}
          {!Can('access', 'Management pages') && (
            <Redirect exact from="/" to="/homepage" />
          )}
           {Can("onlyAccess", "Dashboard") && (
            <Redirect exact from="/homepage" to="/mydashboard" />
          )}
          {Can('access', 'Admin pages') && (
            <Redirect exact from="/admin" to="/admin/users" />
          )}
          {Can('access', 'Management pages') && (
            <Redirect exact from="/" to="/homepage" />
          )}
          {!Can('access', 'Dashboard') && (
            <Redirect exact from="/mydashboard*" to="/homepage" />
          )}
          <Redirect exact from="/v2*" to="/v2*" />
          <ContentRoute path="/homepage" component={SustellHomePage} />
          <ContentRoute path="/admin/users" component={AdminPage} />
          <ContentRoute
            path="/admin/customers"
            component={AdminCustomersPage}
          />
          <ContentRoute
            path="/admin/notifications"
            component={NotificationPage}
          />
          <ContentRoute path="/admin/helpcenter" component={HelpCenterPage} />
          <ContentRoute
            exact
            path="/customers/:customerID/myfeeds"
            component={CompoundFeedsPage}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/myfarms"
            component={SustellMyFarmsPage}
          />
          {/* TODO: remove old dashboard when not needed anymore and change path for new to be 'mydashboard' */}
          <ContentRoute
            exact
            path="/mydashboard"
            component={SustellDashboardPage}
          />
          <ContentRoute
            exact
            path="/mydashboard/:dashboardName"
            component={SustellDashboardPage}
          />
          <ContentRoute
            exact
            path="/footprints/:customerID/:baselineFarmID/:baselineProcessID/:baselineType?/:baselineReference?/:comparisonFarmID?/:comparisonProcessID?/:comparisonType?/:comparisonReference?/:comparisonCustomerID?"
            component={FootPrintPage}
          />
          <ContentRoute
            exact
            path="/feed-footprints/:customerID/:baselineType?/:baselineReference?/:comparisonType?/:comparisonReference?/:comparisonCustomerID?"
            component={FootPrintPage}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/farm/:farmID"
            component={SustellFarmPage}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/farm/:farmID"
            component={FarmFormPage}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/farm/:farmID/pp/:processID"
            component={SustellProdProcessDatasetsPageV2}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/farm/:farmID/pp/:processID/nds/:datasetID"
            component={SustellDatasetMainPage2}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/farm/:farmID/pp/:processID/nint/:interventionID"
            component={SustellInterventionMainPage_v2}
          />
          <ContentRoute
            exact
            path="/customers/:customerID/compoundfeeds/:feedID?/:formType?"
            component={CompoundFeedPage}
          />
          <ContentRoute exact path="/signout" component={SignOut} />
          <ContentRoute
            exact
            path="/profile/customers/:customerID/facilities/:facilityID"
            component={FacilityFormPage}
          />
          <ContentRoute
            exact
            path="/profile/customers/:customerID/facilities"
            component={ProfileCardV2}
          />
          <ContentRoute path="/profile" component={ProfileCardV2} />
          <ContentRoute path="/helpcenter" component={HelpCenter} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
      <NotificationsModal
        data={notifications}
        open={open && notifications?.length > 0}
        onClose={() => {
          setOpen(false);
          localStorage.setItem('notificationsViewed', 'true');
        }}
      />
      {selectedCustomer?.id && (
        <AccessSampleDataDialog
          open={requestAccessDialog}
          title={intl.formatMessage({
            id: 'POST.LOGIN.REQUEST.ACCESS.SAMPLE.DATA',
          })}
          onClose={() => {
            setRequestAccessDialog(false);
          }}
          isShowPostLogin
          customerID={selectedCustomer.id}
        />
      )}
    </>
  );
}
