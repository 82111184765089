import { Maybe, Scalars } from '../../../../graphql/types';

/**
 * Helper function to sort by key of item in a case-insensitive manner
 * @param items
 * @param key
 * @param isAscending
 * @returns
 */
function sortItems<T>(items: T[], key: keyof T, isAscending = true): T[] {
  return items.sort((a, b) => {
    const valueA = String(a[key]).toLowerCase();
    const valueB = String(b[key]).toLowerCase();
    if (valueA < valueB) return isAscending ? -1 : 1;
    if (valueA > valueB) return isAscending ? 1 : -1;
    return 0;
  });
}

interface ItemWithBenchmark {
  benchmark?: Maybe<Scalars['Boolean']>;
}

function filterByBenchmarkSortByName<T extends ItemWithBenchmark>(
  items: T[],
  key: keyof T,
  isAscending = true
): T[] {
  return [
    ...sortItems(
      items.filter((item) => item.benchmark),
      key,
      isAscending
    ),
    ...sortItems(
      items.filter((item) => !item.benchmark),
      key,
      isAscending
    ),
  ];
}

export default filterByBenchmarkSortByName;
