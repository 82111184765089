import { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import SustellDynamicInput from '../../../../../modules/Farms/Baseline/SustellDynamicInput';
import SustellBoatInput from '../../../../../modules/Farms/Baseline/SustellBoatInput';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import lookupValues from '../../../../helpers/lookupValues';
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import {
  MasterDataEnumType,
  SalmonInterventionFormDialogProps,
} from '../CommonDataParts/InterventionTypes';
import {
  BaselineDynamicItem,
  BaselineTransport,
  TransportType,
} from '../../../../models/Baseline';
import {
  SalmonIntervention,
  SalmonOperations,
} from '../../../../models/Intervention';

interface SInterventionOperationsFormDialogProps
  extends SalmonInterventionFormDialogProps {
  intervention: SalmonIntervention | null;
  // eslint-disable-next-line react/require-default-props
  masterDataEnums?: MasterDataEnumType | null;
}

const SInterventionOperationsFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible = false,
  handleCancel,
  masterDataEnums = null,
  baseline,
  intervention,
}: SInterventionOperationsFormDialogProps) => {
  const intl = useIntl();
  const currResetValue = useRef<string>();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const resourcePrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    UserProfilePrefs.getInstance().getUnitResourcePrecision() as number;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass as string)
    : 'kg';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const unitOutputMass = userUOM?.unitOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  const existingAdditionsSet = useRef(false);

  const fieldItemPrefix = `stages.${stageIndex}.stageData.operations`;

  const fc = useFormContext();

  const [transportAddition, setTransportAddition] = useState<
    BaselineTransport[] | null
  >([]);
  const [
    materialsFarmConstructionAddition,
    setMaterialsFarmConstructionAddition,
  ] = useState<BaselineDynamicItem[] | null>([]);
  const [anitibioticsUseAddition, setAnitibioticsUseAddition] = useState<
    BaselineDynamicItem[] | null
  >([]);
  const [liceTreatmentAddition, setLiceTreatmentAddition] = useState<
    BaselineDynamicItem[] | null
  >([]);

  useEffect(() => {
    // we need to refresh when previously saved is returned by API call, but only once
    const operations =
      intervention?.stages?.[stageIndex]?.stageData?.operations;

    if (existingAdditionsSet.current === false) {
      if (
        operations?.transportAddition?.some(
          (item) => item.type !== TransportType.NONE
        )
      ) {
        setTransportAddition(operations.transportAddition);
        existingAdditionsSet.current = true;
      }

      if (
        operations?.materialsFarmConstructionAddition?.some(
          (item) => item.type !== ''
        )
      ) {
        setMaterialsFarmConstructionAddition(
          operations.materialsFarmConstructionAddition
        );
        existingAdditionsSet.current = true;
      }

      if (
        operations?.anitibioticsUseAddition?.some((item) => item.type !== '')
      ) {
        setAnitibioticsUseAddition(operations.anitibioticsUseAddition);
        existingAdditionsSet.current = true;
      }

      if (operations?.liceTreatmentAddition?.some((item) => item.type !== '')) {
        setLiceTreatmentAddition(operations.liceTreatmentAddition);
        existingAdditionsSet.current = true;
      }
    }
  }, [intervention, stageIndex]);

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE',
  });

  useEffect(() => {
    if (formVisible) {
      const serializedData = JSON.stringify(fc.getValues(fieldItemPrefix));
      currResetValue.current = serializedData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as SalmonIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData;
      if (stageData?.operations) {
        const val = JSON.parse(currResetValue.current) as SalmonOperations;
        stageData.operations = val;
        fc.reset(resetObject, {
          errors: true,
        });
        // reset dynamic list fields
        // to previously valid (confirmed)
        setTransportAddition(val?.transportAddition || null);
        setMaterialsFarmConstructionAddition(
          val?.materialsFarmConstructionAddition || null
        );
        setAnitibioticsUseAddition(val?.anitibioticsUseAddition || null);
        setLiceTreatmentAddition(val?.liceTreatmentAddition || null);
      }
    }
    if (handleCancel) handleCancel();
  };

  const availableFuelTypes = getTranslatedEnumOrDefaultLookupValues(
    masterDataEnums?.['EnergyCarrierType' as keyof typeof masterDataEnums] ||
      [],
    lookupValues.fuelTypes,
    intl,
    'MASTERDATA.ENUM.ENERGY_CARRIER_TYPE'
  );

  const operations = baseline?.stages?.[stageIndex]?.stageData?.operations;

  const availableMaterials = getTranslatedEnumOrDefaultLookupValues(
    masterDataEnums?.['MaterialType' as keyof typeof masterDataEnums] || [],
    lookupValues.materials,
    intl,
    'MASTERDATA.ENUM.MATERIALS'
  );

  const availableMaterialsFiltered = availableMaterials.filter(
      (item) =>
        !operations?.materialsFarmConstruction?.some(
          (material) => material.type === item.value
        )
    );

  const avialableAntibiotics = getTranslatedEnumOrDefaultLookupValues(
    masterDataEnums?.['AntibioticType' as keyof typeof masterDataEnums] || [],
    lookupValues.antibiotics,
    intl,
    'MASTERDATA.ENUM.ANITIBIOTIC_USE'
  );

  const avialableAntibioticsFiltered = avialableAntibiotics.filter(
    (item) =>
      !operations?.anitibioticsUse?.some(
        (anitibiotic) => anitibiotic.type === item.value
      )
  );

  const availableLiceTreatments = getTranslatedEnumOrDefaultLookupValues(
    masterDataEnums?.['LiceTreatmentType' as keyof typeof masterDataEnums] ||
      [],
    lookupValues.liceTreatment,
    intl,
    'MASTERDATA.ENUM.LICE_TREATMENT'
  );

  const availableLiceTreatmentsFiltered = availableLiceTreatments.filter(
    (item) =>
      !operations?.liceTreatment?.some(
        (liceTreatment) => liceTreatment.type === item.value
      )
  );

  const getMetricUnit = (item: BaselineTransport) => {
    let fuelUnit;
    if (item.type === 'electricity')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      fuelUnit = userUOM.unitElectricityUse
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          unitLong(userUOM.unitElectricityUse)
        : 'MJ';
    else
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      fuelUnit = userUOM.unitDieselUse ? unitLong(userUOM.unitDieselUse) : 'MJ';

    return fuelUnit;
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      variant="wide"
      iconCode="OPERATIONS"
      datasetType="intervention"
    >
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ marginTop: 0 }}
          >
            {operations?.transport?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`${fieldItemPrefix}_transport_${i}`}>
                <input
                  type="hidden"
                  value={item.boatName}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.transport[${i}].boatName`}
                />
                <input
                  type="hidden"
                  value={item.type}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.transport[${i}].type`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.transport[${i}].amount`}
                  label={intl.formatMessage(
                    { id: 'INTERVENTION.FORM.SALMON.FUEL_USE_FOR' },
                    {
                      fuelType: availableFuelTypes.find(
                        (el) => el.value === item.type
                      )?.display_name,
                      boatName: item.boatName,
                    }
                  )}
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.SALMON.BOAT.TOOLTIP',
                  })}
                  // addornment="%"
                  metricUnit={getMetricUnit(item)}
                  precision={resourcePrecision}
                  // defaultValue={item.amount}
                  baseline={item.amount}
                  disabled={formType === 'view'}
                />
              </Grid>
            ))}

            <SustellBoatInput
              // key={fieldItemPrefix + '.transportAddition'}
              label={intl.formatMessage({ id: 'BASELINE.FORM.SALMON.BOATS' })}
              availableOptions={availableFuelTypes}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="transportAddition"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.BOATS.FUEL_USE_FOR.TOOLTIP',
              })}
              formState={formType}
              data={transportAddition}
              setData={setTransportAddition}
            />

           {operations?.materialsFarmConstruction?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`${fieldItemPrefix}_mat_${i}`}>
                <input
                  type="hidden"
                  value={item.type}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.materialsFarmConstruction[${i}].type`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.materialsFarmConstruction[${i}].amount`}
                  label={
                    availableMaterials.find((el) => el.value === item.type)
                      ?.display_name || ''
                  }
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.SALMON.MATERIAL_FARM.TOOLTIP',
                  })}
                  metricUnit={barnOutputMassUnit}
                  precision={precision}
                  // defaultValue={item.amount}
                  baseline={item.amount}
                  disabled={formType === 'view'}
                />
              </Grid>
            ))}

            <SustellDynamicInput
              key={`${fieldItemPrefix}.materialsFarmConstructionAddition`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.MATERIAL_FARM',
              })}
              availableOptions={availableMaterialsFiltered}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="materialsFarmConstructionAddition"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.MATERIAL_FARM_TT',
              })}
              formState={formType}
              data={materialsFarmConstructionAddition}
              setData={setMaterialsFarmConstructionAddition}
              disableAddButtonWhenAllOptionSelected
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            {operations?.anitibioticsUse?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`${fieldItemPrefix}_ab_${i}`}>
                <input
                  type="hidden"
                  value={item.type}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.anitibioticsUse[${i}].type`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.anitibioticsUse[${i}].amount`}
                  label={
                    avialableAntibiotics.find((el) => el.value === item.type)
                      ?.display_name || ''
                  }
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.SALMON.ANTIBIOTICS_USE.TOOLTIP',
                  })}
                  // addornment="%"
                  metricUnit={barnOutputMassUnit}
                  precision={precision}
                  // defaultValue={item.amount}
                  baseline={item.amount}
                  disabled={formType === 'view'}
                />
              </Grid>
            ))}

            <SustellDynamicInput
              key={`${fieldItemPrefix}.anitibioticsUseAddition`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE',
              })}
              availableOptions={avialableAntibioticsFiltered}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="anitibioticsUseAddition"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE.TOOLTIP',
              })}
              formState={formType}
              data={anitibioticsUseAddition}
              setData={setAnitibioticsUseAddition}
              disableAddButtonWhenAllOptionSelected
            />

            {operations?.liceTreatment?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={`${fieldItemPrefix}_lice_${i}`}>
                <input
                  type="hidden"
                  value={item.type}
                  ref={fc.register()}
                  name={`${fieldItemPrefix}.liceTreatment[${i}].type`}
                />
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.liceTreatment[${i}].amount`}
                  label={
                    availableLiceTreatments.find((el) => el.value === item.type)
                      ?.display_name || ''
                  }
                  type="number"
                  tooltip={intl.formatMessage({
                    id: 'INTERVENTION.FORM.SALMON.LICE_TREATMENT.TOOLTIP',
                  })}
                  // addornment="%"
                  metricUnit={barnOutputMassUnit}
                  precision={precision}
                  // defaultValue={item.amount}
                  baseline={item.amount}
                  disabled={formType === 'view'}
                />
              </Grid>
            ))}

            <SustellDynamicInput
              key={`${fieldItemPrefix}.liceTreatmentAddition`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.LICE_TREATMENT',
              })}
              availableOptions={availableLiceTreatmentsFiltered}
              fieldItemPrefix={fieldItemPrefix}
              subFieldName="liceTreatmentAddition"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.LICE_TREATMENT_TT',
              })}
              formState={formType}
              data={liceTreatmentAddition}
              setData={setLiceTreatmentAddition}
              disableAddButtonWhenAllOptionSelected
            />

            <Grid item xs={12}>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.antifouling`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.SALMON.ANTIFOULING',
                })}
                type="number"
                tooltip={intl.formatMessage({
                  id: 'INTERVENTION.FORM.SALMON.ANTIFOULING.TOOLTIP',
                })}
                // addornment="%"
                metricUnit={unitOutputMass}
                baseline={operations?.antifouling}
                disabled={formType === 'view'}
                precision={precision}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default SInterventionOperationsFormDialog;
