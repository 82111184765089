import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  DSM_BLUE,
  DSM_DARK_GREEN,
  DSM_LIGHTER_BLUE,
  DSM_LIGHTER_CERISE,
  DSM_LIGHTER_GREEN,
  DSM_LIGHTER_OCEAN_GREEN,
  DSM_MAGENTA,
  DSM_OCEAN_GREEN,
} from '../../../../../_metronic/layout';

export const FootprintIconSpecificStylesMap = {
  AIR: {
    textColor: DSM_BLUE,
    background: DSM_LIGHTER_BLUE,
    filter:
      'brightness(0) saturate(100%) invert(10%) sepia(85%) saturate(5071%) hue-rotate(207deg) brightness(81%) contrast(101%)',
  },
  LAND: {
    textColor: DSM_DARK_GREEN,
    background: DSM_LIGHTER_GREEN,
    filter:
      'brightness(0) saturate(100%) invert(21%) sepia(93%) saturate(2623%) hue-rotate(150deg) brightness(93%) contrast(100%)',
  },
  HUMAN: {
    textColor: DSM_MAGENTA,
    background: DSM_LIGHTER_CERISE,
    filter:
      'brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(3278%) hue-rotate(307deg) brightness(113%) contrast(109%)',
  },
  WATER: {
    textColor: DSM_OCEAN_GREEN,
    background: DSM_LIGHTER_OCEAN_GREEN,
    filter:
      'brightness(0) saturate(100%) invert(43%) sepia(97%) saturate(3514%) hue-rotate(154deg) brightness(94%) contrast(101%)',
  },
};

const useTableIconStyles = makeStyles({
  iconRoot: {
    verticalAlign: 'middle',
    borderRadius: '20px',
    width: '40px',
    height: '40px',
    padding: '10px 10px 5px 5px',
    marginTop: '10px',
  },
  imageIcon: {
    display: 'flex',
    maxHeight: '100%',
  },
});

const FootprintTableIcon = (props: {
  image: string;
  compartment: string;
  isIconBackGround: boolean;
}) => {
  const classes = useTableIconStyles();
  const { compartment, isIconBackGround, image } = props;
  const iconBackground =
    FootprintIconSpecificStylesMap[
      compartment.toUpperCase() as keyof typeof FootprintIconSpecificStylesMap
    ].background;
  const imageColor =
    FootprintIconSpecificStylesMap[
      compartment.toUpperCase() as keyof typeof FootprintIconSpecificStylesMap
    ].filter;
  return (
    <Icon
      classes={{ root: classes.iconRoot }}
      style={{
        background: isIconBackGround ? iconBackground : 'none',
        marginTop: isIconBackGround ? '10px' : 0,
      }}
    >
      <img
        alt=""
        className={classes.imageIcon}
        style={{ filter: imageColor }}
        src={image}
      />
    </Icon>
  );
};

export default FootprintTableIcon;