/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { BaselineStageProps, PoultryFarms } from '../../common';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import { PoultryBaseline } from '../../../../models/Baseline/PoultryBaseline';
import { defaultValues } from "../../baselineDefaultValues";
import { stageDataPartPoultry } from "../../../../../modules/Farms/Baseline/validationSchema/poultryBaselineValidation";
import { Origin } from '../types';
import StageButtonBox from '../../StageButtonBox_v2';
import PoultryEmissionsFormDialog from './PoultryEmissionsDialog';
import PoultryOutputGrowingDialog from './PoultryOutputGrowingDialog';
import PoultryInputGrowingDialog from './PoultryInputGrowingDialog';
import PoultryFeedGrowingAndLayingDialog from './PoultryFeedGrowingAndLayingDialog';
import PoultryFeedBreedingDialog from './PoultryFeedBreedingDialog';
import PoultryInputLayingDialog from './PoultryInputLayingDialog';
import PoultryInputHatchingDialog from './PoultryInputHatchingDialog';
import PoultryHousingHatchingDialog from './PoultryHousingHatchingDialog';
import PoultryInputBreedingDialog from './PoultryInputBreedingDialog';
import PoultryOutputHatchingDialog from './PoultryOutputHatchingDialog';
import PoultryHousingAndManureDialog from './PoultryHousingAndManureDialog';
import PoultryOutputLayingDialog from './PoultryOutputLayingDialog';
import PoultryOutputBreedingDialog from './PoultryOutputBreedingDialog';

interface PoultryStagesProps extends Omit<BaselineStageProps, 'baseline'> {
  farms: PoultryFarms[],
  farmId: string;
  farmName: string;
  productionProcessName: string;
  baseline: PoultryBaseline;
  origins: Origin[];
  animalType: AnimalType.BroilerV2 | AnimalType.LayingHensV2 | AnimalType.Turkey;
  calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

interface CloseDialogData {
  type: string,
  setFunc: React.Dispatch<React.SetStateAction<number>>,
  prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions'
}

interface InitialCalcuationData {
  prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions'
}

const PoultryStages: FC<PoultryStagesProps> = ({
  farms,
  farmName,
  farmId,
  productionProcessName,
  stageIndex,
  baseline,
  compoundFeeds,
  singleIngredients,
  manureManagementSystems,
  origins,
  item,
  formType,
  stageType = StageType.Fattening,
  animalType,
  calculateCompleteness,
}) => {
  const fc = useFormContext();
  const [activeDialog, setActiveDialog] = useState<string>('');

  const showDialog = (dialog: SetStateAction<string>) => {
    setActiveDialog(dialog);
  };

  const stagePrefix = `stages.${stageIndex}`;
  const [inputBreedingCompleteness, setInputBreedingCompleteness] = useState(0);
  const [inputGrowingCompleteness, setInputGrowingCompleteness] = useState(0);
  const [inputLayingCompleteness, setInputLayingCompleteness] = useState(0);
  const [inputHatchingCompleteness, setInputHatchingCompleteness] = useState(0);
  const [feedBreedingCompleteness, setFeedBreedingCompleteness] = useState(0);
  const [feedGrowingCompleteness, setFeedGrowingCompleteness] = useState(0);
  const [feedLayingCompleteness, setFeedLayingCompleteness] = useState(0);
  const [housingAndManureCompletness, setHousingAndManureCompletness] = useState(0);
  const [housingHatchingCompleteness, setHousingHatchingCompleteness] = useState(0);
  const [outputBreedingCompleteness, setOutputBreedingCompleteness] = useState(0);
  const [outputGrowingCompleteness, setOutputGrowingCompleteness] = useState(0);
  const [outputLayingCompleteness, setOutputLayingCompleteness] = useState(0);
  const [outputHatchingCompleteness, setOutputHatchingCompleteness] = useState(0);
  const [emissionCompleteness, setEmissionCompleteness] = useState(0);

  const feedCompletenessPercentage = () => {
    switch(stageType) {
      case StageType.Breeding:
        return feedBreedingCompleteness;
      case StageType.Growing:
        return feedGrowingCompleteness;
      case StageType.Laying:
        return feedLayingCompleteness;
      default:
        return 0;
    }
  }

  const inputCompletenessPercentage = () => {
    switch(stageType) {
      case StageType.Breeding:
        return inputBreedingCompleteness;
      case StageType.Growing:
        return inputGrowingCompleteness;
      case StageType.Laying:
        return inputLayingCompleteness;
      case StageType.Hatching:
        return inputHatchingCompleteness;
      default:
        return 0;
    }
  }

  const outputCompletenessPercentage = () => {
    switch(stageType) {
      case StageType.Breeding:
        return outputBreedingCompleteness;
      case StageType.Growing:
        return outputGrowingCompleteness;
      case StageType.Laying:
        return outputLayingCompleteness;
      case StageType.Hatching:
        return outputHatchingCompleteness;
      default:
        return 0;
    }
  }

  const initialCalculation = (initialCalcuationData: InitialCalcuationData, created = false) => {
    const validationObject = item.stageData[initialCalcuationData.prefix];
    const defaultObject = defaultValues[animalType]?.stages[0]?.stageData[initialCalcuationData.prefix];
    if (item.newStage && isEqual(validationObject, defaultObject) && !created) return 0;
    return calculateCompleteness(stageDataPartPoultry, `stages[${stageIndex}].stageData.${initialCalcuationData.prefix}`);
  }

  const closeDialog = (closeDialogData: CloseDialogData) => {
    const completeness = calculateCompleteness(stageDataPartPoultry, `stages[${stageIndex}].stageData.${closeDialogData.prefix}`);
    if (formType !== 'view' && closeDialogData.type === 'confirm') {
      closeDialogData.setFunc(completeness);
    } else if (closeDialogData.type === 'reset') {
     // eslint-disable-next-line @typescript-eslint/no-floating-promises
     if (!completeness) fc.trigger(`${stagePrefix}.stageData.${activeDialog}`);
     else fc.clearErrors(`${stagePrefix}.stageData.${activeDialog}`);
   }
   setActiveDialog('');
  }

  const getSelectableStages = (): string[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const currentBaselineValues = fc.getValues()?.stages ? fc.getValues()?.stages.map((stage: { id: string; }) => stage.id) : [];
    return [...farms.map((farm) => farm.stageId), ...currentBaselineValues] as string[];
  }

  const checkInternalSourcesBreeding = () => {
    const allStageIds = getSelectableStages();
    const henOriginStageId = fc.getValues(`${stagePrefix}.stageData.input.henInternalSource.originStageId`) as string;
    if (!allStageIds.includes(henOriginStageId) && henOriginStageId) {
      fc.setValue(`${stagePrefix}.stageData.input.henInternalSource.farmId`, '');
      fc.setValue(`${stagePrefix}.stageData.input.henInternalSource.originStageId`, '');
      fc.trigger(`${stagePrefix}.stageData.input.henInternalSource`);
    }
    const roosterOriginStageId = fc.getValues(`${stagePrefix}.stageData.input.roosterInternalSource.originStageId`) as string;
    if (!allStageIds.includes(roosterOriginStageId) && roosterOriginStageId) {
      fc.setValue(`${stagePrefix}.stageData.input.roosterInternalSource.farmId`, '');
      fc.setValue(`${stagePrefix}.stageData.input.roosterInternalSource.originStageId`, '');
      fc.trigger(`${stagePrefix}.stageData.input.roosterInternalSource`);
    }
  }

  const checkInternalSourcesLayingAndHatching = () => {
    const allStageIds = getSelectableStages();
    const originStageId = fc.getValues(`${stagePrefix}.stageData.input.internalSource.originStageId`) as string;
    if (!allStageIds.includes(originStageId) && originStageId) {
      fc.setValue(`${stagePrefix}.stageData.input.internalSource.farmId`, '');
      fc.setValue(`${stagePrefix}.stageData.input.internalSource.originStageId`, '');
      fc.trigger(`${stagePrefix}.stageData.input.internalSource`);
    }
  }

  const checkInternalSourcesGrowing = () => {
    const allStageIds = getSelectableStages();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    fc.getValues(`${stagePrefix}.stageData.input.internalSources`)?.forEach((internalSource: { originStageId: string; }, index: number) => {
      if (!allStageIds.includes(internalSource.originStageId) && internalSource.originStageId) {
        fc.setValue(`${stagePrefix}.stageData.input.internalSources[${index}].farmId`, '');
        fc.setValue(`${stagePrefix}.stageData.input.internalSources[${index}].originStageId`, '');
        fc.trigger(`${stagePrefix}.stageData.input.internalSources[${index}]`);
      }
    })
  }

  useEffect(() => {
    if ((formType === 'edit' && !item.newStage) || fc.getValues(`${stagePrefix}.created`)) {
      const created = !!fc.getValues(`${stagePrefix}.created`);
      if (stageType === StageType.Breeding) {
        setInputBreedingCompleteness(initialCalculation({prefix: 'input'}, created));
        setFeedBreedingCompleteness(initialCalculation({prefix: 'feed'}, created));
        setHousingAndManureCompletness(initialCalculation({prefix: 'housing'}, created));
        setOutputBreedingCompleteness(initialCalculation({prefix: 'output'}, created));
        setEmissionCompleteness(initialCalculation({prefix: 'emissions'}, created));
      } else if (stageType === StageType.Growing) {
        setInputGrowingCompleteness(initialCalculation({prefix: 'input'}, created));
        setFeedGrowingCompleteness(initialCalculation({prefix: 'feed'}, created));
        setHousingAndManureCompletness(initialCalculation({prefix: 'housing'}, created));
        setOutputGrowingCompleteness(initialCalculation({prefix: 'output'}, created));
        setEmissionCompleteness(initialCalculation({prefix: 'emissions'}, created));
      } else if (stageType === StageType.Laying) {
        setInputLayingCompleteness(initialCalculation({prefix: 'input'}, created));
        setFeedLayingCompleteness(initialCalculation({prefix: 'feed'}, created));
        setHousingAndManureCompletness(initialCalculation({prefix: 'housing'}, created));
        setOutputLayingCompleteness(initialCalculation({prefix: 'output'}, created));
        setEmissionCompleteness(initialCalculation({prefix: 'emissions'}, created));
      } else if (stageType === StageType.Hatching) {
        setInputHatchingCompleteness(initialCalculation({prefix: 'input'}, created));
        setHousingHatchingCompleteness(initialCalculation({prefix: 'housing'}, created))
        setOutputHatchingCompleteness(initialCalculation({prefix: 'output'}, created));
      }
    }
    fc.setValue(`${stagePrefix}.created`, 'created');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  // Check if selected stages are existing or not
  // In case of some baseline deleted, the loaded data needs
  // to be removed so the user needs to select a different stage
  useEffect(() => {
    if(farms?.length) {
      if (stageType === StageType.Breeding) {
        checkInternalSourcesBreeding();
      } else if (stageType === StageType.Growing) {
        checkInternalSourcesGrowing();
      } else if (stageType === StageType.Laying) {
        checkInternalSourcesLayingAndHatching();
      } else if (stageType === StageType.Hatching) {
        checkInternalSourcesLayingAndHatching();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageType, farms, formType]);

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        defaultValue={item.type}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.created`}
        defaultValue={fc.getValues(`${stagePrefix}.created`) || ''}
      />
      { stageType === StageType.Breeding && activeDialog === 'input' &&
        <PoultryInputBreedingDialog 
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === 'input'} 
          formType={formType}
          handleCancel={() => setActiveDialog('')} 
          validate={(type: string) => closeDialog({type, setFunc: setInputBreedingCompleteness, prefix: 'input'})}
          baseline={baseline} 
          itemIndex={stageIndex}
          animalType={animalType}
        />
      }
      { stageType === StageType.Growing && activeDialog === 'input' &&
        <PoultryInputGrowingDialog 
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === 'input'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setInputGrowingCompleteness, prefix: 'input'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
          animalType={animalType}
        />
      }
      { stageType === StageType.Laying && activeDialog === 'input' &&
        <PoultryInputLayingDialog 
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === 'input'} 
          formType={formType}
          handleCancel={() => setActiveDialog('')} 
          validate={(type: string) => closeDialog({type, setFunc: setInputLayingCompleteness, prefix: 'input'})}
          baseline={baseline} 
          itemIndex={stageIndex}
          animalType={animalType}
        />
      }
      { stageType === StageType.Hatching && activeDialog === 'input' &&
        <PoultryInputHatchingDialog 
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === 'input'} 
          formType={formType}
          handleCancel={() => setActiveDialog('')} 
          validate={(type: string) => closeDialog({type, setFunc: setInputHatchingCompleteness, prefix: 'input'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
          animalType={animalType}
        />
      }
      { stageType === StageType.Breeding && activeDialog === 'feed' &&
        <PoultryFeedBreedingDialog 
          formVisible={activeDialog === 'feed'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setFeedBreedingCompleteness, prefix: 'feed'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      }
      { stageType === StageType.Growing && activeDialog === 'feed' &&
        <PoultryFeedGrowingAndLayingDialog 
          formVisible={activeDialog === 'feed'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setFeedGrowingCompleteness, prefix: 'feed'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      }
      { stageType === StageType.Laying && activeDialog === 'feed' &&
        <PoultryFeedGrowingAndLayingDialog 
          formVisible={activeDialog === 'feed'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setFeedLayingCompleteness, prefix: 'feed'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      }
      { stageType !== StageType.Hatching && activeDialog === 'housing' &&
        <PoultryHousingAndManureDialog 
          formVisible={ activeDialog==='housing' } 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setHousingAndManureCompletness, prefix: 'housing'})} 
          baseline={baseline} itemIndex={stageIndex}
          manureManagementSystems={manureManagementSystems}
          animalType={animalType}
          stageType={stageType}
        />
      }
      { stageType === StageType.Hatching && activeDialog === 'housing' &&
        <PoultryHousingHatchingDialog 
          formVisible={activeDialog === 'housing'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setHousingHatchingCompleteness, prefix: 'housing'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
          animalType={animalType}
        />
      }
      { stageType === StageType.Breeding && activeDialog === 'output' &&
        <PoultryOutputBreedingDialog 
          formVisible={activeDialog === 'output'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setOutputBreedingCompleteness, prefix: 'output'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
        />
      }
      { stageType === StageType.Growing && activeDialog === 'output' &&
        <PoultryOutputGrowingDialog 
          formVisible={activeDialog === 'output'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setOutputGrowingCompleteness, prefix: 'output'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
        />
      }
      { stageType === StageType.Laying && activeDialog === 'output' &&
        <PoultryOutputLayingDialog 
          formVisible={activeDialog === 'output'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setOutputLayingCompleteness, prefix: 'output'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
        />
      }
      { stageType === StageType.Hatching && activeDialog === 'output' &&
        <PoultryOutputHatchingDialog 
          formVisible={activeDialog === 'output'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setOutputHatchingCompleteness, prefix: 'output'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
        />
      }
      { activeDialog === 'emissions' &&
        <PoultryEmissionsFormDialog 
          formVisible={activeDialog === 'emissions'} 
          formType={formType}
          handleCancel={(type: string) => closeDialog({type, setFunc: setEmissionCompleteness, prefix: 'emissions'})} 
          baseline={baseline} 
          itemIndex={stageIndex}
        />
      }
      <Box
        style={{ display: 'flex', width: stageType === StageType.Hatching ? '60%' : '100%' }}
        flexDirection="row"
        justifyContent='space-between'
      >
        {stageType !== StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={inputCompletenessPercentage()}
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog('input')}
        />)}
        {stageType === StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.HATCHING.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.HATCHING_EGGS_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={inputCompletenessPercentage()}
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog('input')}
        />)}
        {stageType !== StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={feedCompletenessPercentage()}
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed}
          handleOpen={() => showDialog('feed')}
        />)}
        {stageType !== StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="general/building-06"
          completness={housingAndManureCompletness}
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.housing}
          handleOpen={() => showDialog('housing')}
        />)}
        {stageType === StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.HOUSING.TITLE"
          iconCode="general/building-06"
          completness={housingHatchingCompleteness}
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.housing}
          handleOpen={() => showDialog('housing')}
        />)}
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={outputCompletenessPercentage()}
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.output}
          handleOpen={() => showDialog('output')}
        />
        {stageType !== StageType.Hatching && (<StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="maps-travel/globe-04"
          descriptionTitle="SUSTELL.STAGE.MITTIGATION"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={emissionCompleteness}
          handleOpen={() => showDialog('emissions')}
          error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.emissions}
        />)}
      </Box>
    </>
  );
};

export default PoultryStages;
