import { FC } from "react";
import { HelpOutlineOutlined } from "@material-ui/icons";

import { Box, Chip, Tooltip, Typography } from "@material-ui/core";
import { ExactNumber } from "../../helpers/ExactNumber";
import { useIntl } from "../../../../../_metronic/i18n/customUseIntl";
import { DashboardImpactType } from "../../../models/Dashboard/DashboardTypes";

interface ImpactTargetInfoProps {
  impactCategoryName: string;
  // eslint-disable-next-line react/require-default-props
  categoryDescription?: string;
  unit: string;
  value: number | undefined;
  targetValue: number | undefined;
  type: 'Total' | 'Average';
  impactType: DashboardImpactType | undefined;
}

const ImpactTargetInfo: FC<ImpactTargetInfoProps> = ({
  impactCategoryName,
  categoryDescription = '',
  unit,
  value,
  targetValue,
  impactType,
  type
}) => {
    const intl = useIntl();

    const startText = type === 'Total' ?
      intl.formatMessage({id: "GENERAL.TOTAL"}) : intl.formatMessage({id: "GENERAL.AVERAGE"})

    return (
    <>
      <Box style={{maxWidth: '323px', maxHeight: '180px', paddingRight: '10px'}}>
        <Box display="flex" flexDirection="row">
          <Box style={{maxWidth: '280px', fontWeight: 600, fontSize: '16px', lineHeight: '24px'}}>
            {`${startText} ${impactCategoryName}`}
          </Box>
          <Box style={{width: '32px'}} mt="2px" ml="2px">
            <Tooltip title={intl.formatMessage({id: type === 'Average' ? 
                "SUSTELL.DASHBOARD.TARGET.AVERAGE_TOOLTIP" : "SUSTELL.DASHBOARD.TARGET.TOTAL_TOOLTIP"})}
              > 
              <HelpOutlineOutlined fontSize="medium"/>
            </Tooltip>
          </Box>
        </Box>
        <Typography style={{fontWeight: 400, fontSize: '12px', lineHeight: '16px', color: '#808080', marginTop: '6px'}} color="textPrimary">
          {categoryDescription}
        </Typography>
      </Box>
      <Box style={{maxWidth: '323px'}}>
        <Typography style={{
            fontWeight: 600,
            color: '#0070BA',
            fontSize: '34px',
            lineHeight: '38px'
          }} 
          align="center"
          color="textPrimary"
        >
          {value !== 0 ? (value !== undefined ? <ExactNumber value={value} /> : '-') : 0}
        </Typography>
        <Typography style={{
            fontWeight: 600,
            color: '#0070BA',
            fontSize: '15px',
            lineHeight: '19px'
          }} 
          align="center"
          color="textPrimary"
        >
         {unit}
         { (type === 'Average' && impactType !== DashboardImpactType.Absolute) ? 
          ` / ${intl.formatMessage({id: "SUSTELL.DASHBOARD.TARGETS_PER_KG"})}` 
          : ''
         }
         <br />
         <br />
        
        </Typography>
        { (targetValue && targetValue !== 0) ?
           <Chip
            variant="outlined" 
            style={{
              color: targetValue >  (value || 0) ?  '#45AC34' : '#E51F22',
              borderColor:  targetValue >  (value || 0) ?  '#45AC34' : '#E51F22',
              background:  targetValue >  (value || 0) ?  '#ECF7EB' : '#FCE9E9',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '16px',
              height: '22px',
              display: 'block'
            }}
            label={
              <span>
                {intl.formatMessage({id: "SUSTELL.FOOTPRINT.TABLE.COLUMNS.PERSONALTARGET"})} <ExactNumber value={targetValue} /> {unit}
              </span>
            } 
          />
          : ''
        }
      </Box>
    </>
    );
  }

export default ImpactTargetInfo;