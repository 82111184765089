import { FC, useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { UserProfilePrefs } from '../../../../../../modules/Helpers/UserProfilePrefs';
import {
  unitLong,
  defaultUnits,
  explicitConvertValue,
} from '../../../../../utils/unit-utils';
import {
  avgLiveWeightMaxs,
  avgLiveWeightMins,
} from '../../../../../../modules/Farms/validationSchemaRestrictions';
import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../../common';
import {
  DSM_DISABLED_LABEL,
  processAndStageStylesV2,
} from '../../../../../../../_metronic/layout';
import { DialogContainer } from '../../../CommonDataParts/DialogContainer2';
import ReactHookDsmInput from '../../../../../../modules/Helpers/ReactHookDsmInput2';
import InternalSourcesInput from './InternalSourcesInput';
import ExternalSourcesInput from './ExternalSourcesInput';
import DsmButtonControlGroup from '../../../../helpers/DsmButtonControlGroup';
import { StageType } from '../../../../../../../graphql/types';
import ReactHookDsmPicker from '../../../../../../modules/Helpers/ReactHookDsmDatePicker';
import {
  PigBaseline,
  PigBreedingInput,
  PigBreedingStageData,
} from '../../../../../models/Baseline/PigBaseline';
import { AnimalType } from '../../../../../../../graphql/generated/blonk/pigs';
import {
  CSSClassesList,
  UserUOMPreferences,
} from '../../../../../helpers/helperTypes';

const PBAnimalInputFormDialog: FC<BaselineDialogProps> = ({
  handleCancel,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs() as UserUOMPreferences;
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;

  let minAvgLiveWeightMortality = avgLiveWeightMins['Pig fattening'];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let maxAvgLiveWeightMortality: number | undefined =
    avgLiveWeightMaxs['Pig fattening']; // No max provided

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass) {
    if (minAvgLiveWeightMortality)
      minAvgLiveWeightMortality = Number(
        explicitConvertValue(
          minAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
    if (maxAvgLiveWeightMortality)
      maxAvgLiveWeightMortality = Number(
        explicitConvertValue(
          maxAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
  }
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;

  const currResetValue = useRef<PigBreedingInput>();
  const formContext = useFormContext<PigBaseline>();
  const { control } = useFormContext<PigBaseline>();
  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control,
    keyName: 'keyId',
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control,
    keyName: 'keyId',
  });

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as PigBaseline;
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as PigBreedingStageData;
      if (stageData?.input) {
        stageData.input = { ...currResetValue.current } as PigBreedingInput;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel('reset');
  };

  const addInternalSource = () => {
    appendInternal({
      id: uuidv4(),
      numberOfNewPigs: '',
      originStageId: '',
      distanceTransport: '',
    });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
  };

  const addExternalSource = () => {
    appendExternal({
      id: uuidv4(),
      numberOfNewPigs: '',
      averageAgeOfPigs: '',
      averageWightOfPigs: '',
      distanceTransport: '',
    });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
  };

  useEffect(() => {
    if (internalSources.length === 0 && externalSources.length === 0) {
      addInternalSource();
      addExternalSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const stageOriginAnimalType = useWatch({
    name: `stages.${itemIndex}.stageData.input.originAnimalType`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.originAnimalType`
      ) || '',
  }) as AnimalType;

  const allStages = formContext.getValues('stages') || [];
  let stages = allStages
    .filter((item) => item.type !== StageType.Processing)
    .map((item) => ({ text: item.name, value: item.id }));
  const breedingStagesList = allStages
    .filter((item) => item.type === StageType.Breeding)
    .map((item) => item.id);
  const fatteningStagesList = allStages
    .filter((item) => item.type === StageType.Fattening)
    .map((item) => item.id);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (stageOriginAnimalType === AnimalType.Piglet) {
    // no fattening stages should be allowed - so further filtering should be done
    stages = stages.filter((item) => !fatteningStagesList.includes(item.value));
  } else {
    // after change return to full list
    stages = allStages.map((item) => ({ text: item.name, value: item.id }));
  }
  // console.log("stages: ", stageOriginAnimalType, "=>", stages.length);

  const labelDisabled =
    formType === FormType.View ? { color: DSM_DISABLED_LABEL as string } : {};

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      variant="wide"
      formTitle={formTitle}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({ id: 'BASELINE.FORM.FIELD.START_DATE' })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.START_DATE.TOOLTIP",
          })}
          adornment={intl.formatMessage({
            id: 'BASELINE.FORM.FIELD.START_DATE.TOOLTIP',
          })}
          required
          defaultValue={formContext.getValues(`${fieldItemPrefix}.startDate`)}
        />
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({ id: 'BASELINE.FORM.FIELD.END_DATE' })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.END_DATE.TOOLTIP",
          })}
          adornment={intl.formatMessage({
            id: 'BASELINE.FORM.FIELD.END_DATE.TOOLTIP',
          })}
          required
          defaultValue={formContext.getValues(`${fieldItemPrefix}.endDate`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.pigsPresentAtStart`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_START',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER',
          })}
          required
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.pigsPresentAtStart`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.pigsPresentAtEnd`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_END',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER',
          })}
          required
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.pigsPresentAtEnd`
          )}
          type="number"
        />
        <h4 style={labelDisabled}>
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERNAL_SOURCE',
          })}
        </h4>
        <h4 style={labelDisabled}>
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.EXTERNAL_SOURCE',
          })}
        </h4>
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          {internalSources &&
            internalSources?.map((item, index) => (
              <InternalSourcesInput
                key={item.keyId}
                stageIndex={itemIndex}
                itemIndex={index}
                stages={stages}
                stageOriginAnimalType={stageOriginAnimalType}
                breedingStagesList={breedingStagesList}
                formType={formType}
                stageType={StageType.Breeding}
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeInternalSource}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addInternalSource}
            disabled={
              internalSources.length >= stages.length ||
              formType === FormType.View
            }
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_INTERNAL',
            })}
          </DsmButton>
        </div>
        <div>
          {externalSources &&
            externalSources?.map((item, index) => (
              <ExternalSourcesInput
                key={item.keyId}
                stageIndex={itemIndex}
                itemIndex={index}
                formType={formType}
                stageType={StageType.Breeding}
                barnOutputMassUnit={barnOutputMassUnit}
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeExternalSource}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addExternalSource}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_EXTERNAL',
            })}
          </DsmButton>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.input`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PBAnimalInputFormDialog;
