import { FC, useEffect, useRef } from 'react';
import { useFormContext } from "react-hook-form";

import { Grid } from "@material-ui/core";

import { ButtonControlGroup } from "../../../helpers/ButtonControlGroup"
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs'; 
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { BaselinePropertyValue, BroilerBaseline, BroilerOutput } from '../../../../models/Baseline';

const BOutputFormDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible = false,
  handleCancel,
  handleSave=handleCancel
}) => {

  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`; 
  const formContext = useFormContext();

  const currResetValue = useRef<BroilerOutput>();
  useEffect(() => {
    if(formVisible){
      currResetValue.current={...formContext.getValues(fieldItemPrefix)} as BroilerOutput ; // must 'spread' otherwise just reference
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  const handleResetClick = ()=>{
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as BroilerBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.output){
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        formContext.reset(resetObject, { errors: true });
      }
    }
    
    if(handleCancel)
      handleCancel();
  }
  // methods.handleSubmit(onSubmit)

  return (
    <DialogContainer
      formVisible = { formVisible }
      handleClose = { handleResetClick }
      iconCode="BROILER"
      formTitle = { formTitle }
    > 
      <Grid container direction="column" spacing={3} justifyContent="space-between" >
        <Grid item xs={12} >
          <RowTextFieldWithInfo
            item xs={12}
            name={`${fieldItemPrefix}.liveWeight`}
            label={`${intl.formatMessage({id: "BASELINE.FORM.OUTPUT.BROILERS.LIVEWEIGHT"})} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({id: "BASELINE.FORM.OUTPUT.BROILERS.LIVEWEIGHT.TOOLTIP"}) }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            addornment={ userUOM?.unitOutputMass ? unitLong(userUOM.unitOutputMass) : "kg"}
            disabled={formType === "view"}
            defaultValue = {formContext.getValues(`${fieldItemPrefix  }.liveWeight`) as BaselinePropertyValue}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ handleSave }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" })  }
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );

  
};

export default BOutputFormDialog;