import { StageType } from '../../../../../graphql/types';
import { isProcessingStage } from '../../../../sustell_15/helpers/facilities';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { nameMapping } from '../../validationSchemaRestrictions';
import { floatValueInUserUnits } from './inCommonMapperSustell';
import {
  mapCommonProductionDataIn,
  mapResourceUse,
} from './inCommonMapperSustellV2';

const mapMarineFishProductionDataInSustell = (inData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();
  const formData = mapCommonProductionDataIn(inData, isDraft);

  formData.resourceUse = {};
  mapResourceUse(inData.resourceUse, formData.resourceUse, [
    'electricityUse',
    'dieselUse',
    'waterUse',
  ]);

  if (inData.stages && Array.isArray(inData.stages)) {
    inData.stages.forEach((stage, index) => {
      if (!isProcessingStage(stage?.type)) {
        // Set growing stage if not filled to maintain compatibility with older versions
        if (!stage?.type) formData.stages[index].type = StageType.Growing;

        const stageFormData = formData.stages[index]?.stageData;
        if (stageFormData) {
          const stageData = JSON.parse(stage.stageData);

          // map output
          const outputFormData = stageFormData.output;
          outputFormData.production = floatValueInUserUnits(
            stageData?.production?.amount,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            outputMassPrecision
          );
          outputFormData.averageWeight = floatValueInUserUnits(
            stageData?.production?.averageWeight,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            outputMassPrecision
          );

          outputFormData.mortalities = floatValueInUserUnits(
            stageData?.lostAnimals?.mortalities,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            outputMassPrecision
          );
          outputFormData.escapees = floatValueInUserUnits(
            stageData?.lostAnimals?.escapees,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            outputMassPrecision
          );

          // map input
          const inputFormData = stageFormData.input;
          inputFormData.numberOfStockedAnimals =
            stageData?.stocking?.stockedAnimalsNumber;
          inputFormData.weightOfStockedAnimals = floatValueInUserUnits(
            stageData?.stocking?.stockedAnimalsWeight,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            outputMassPrecision
          );
          inputFormData.averageLiveWeightStockedAnimals = floatValueInUserUnits(
            stageData?.stocking?.averageWeight,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            outputMassPrecision
          );

          // map operations
          if (!stageFormData.operations) stageFormData.operations = {};

          const operationsFormData = stageFormData.operations;

          operationsFormData.antifouling = floatValueInUserUnits(
            stageData?.antifouling,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            precision
          );

          operationsFormData.materialsFarmConstruction = [];
          if (stageData?.materials && Array.isArray(stageData.materials)) {
            stageData.materials.forEach((item) => {
              const value = floatValueInUserUnits(
                item?.amount,
                userUOM.unitBarnOutputMass,
                defaultMetric.unitBarnOutputMass,
                precision
              );
              operationsFormData.materialsFarmConstruction.push({
                type: item?.type,
                amount: value,
              });
            });
          }

          // if draft loaded without any lice materials use data, add default placeholder to show drop down correctly
          if (operationsFormData.materialsFarmConstruction.length === 0) {
            operationsFormData.materialsFarmConstruction.push({
              type: '',
              amount: '',
            });
          }

          operationsFormData.liceTreatment = [];
          if (
            stageData?.liceTreatment &&
            Array.isArray(stageData.liceTreatment)
          ) {
            stageData.liceTreatment.forEach((item) => {
              const value = floatValueInUserUnits(
                item?.amount,
                userUOM.unitBarnOutputMass,
                defaultMetric.unitBarnOutputMass,
                precision
              );
              operationsFormData.liceTreatment.push({
                type: item?.type,
                amount: value,
              });
            });
          }

          operationsFormData.transport = [];
          if (stageData?.transport && Array.isArray(stageData.transport)) {
            stageData.transport.forEach((item) => {
              let value = Number(item?.amount);

              if (
                item.type === 'electricity' &&
                userUOM.unitElectricityUse !== defaultMetric.unitElectricityUse
              ) {
                value = floatValueInUserUnits(
                  value,
                  userUOM.unitElectricityUse,
                  defaultMetric.unitElectricityUse,
                  precision
                );
              } else if (
                item.type === 'diesel' &&
                userUOM.unitDieselUse !== defaultMetric.unitDieselUse
              ) {
                value = floatValueInUserUnits(
                  value,
                  userUOM.unitDieselUse,
                  defaultMetric.unitDieselUse,
                  precision
                );
              }

              operationsFormData.transport.push({
                boatName: item?.boatName,
                type: item.type,
                amount: value,
              });
            });
          }

          // if draft loaded without any transport data, add default placeholder
          if (operationsFormData.transport.length === 0) {
            operationsFormData.transport.push({
              boatName: '',
              type: '',
              amount: '',
            });
          }

          stageFormData.feed.nutrientComposition = {};
          stageFormData.feed.antibioticsUse = [];
          if (stageData?.antibiotics && Array.isArray(stageData.antibiotics)) {
            stageData.antibiotics.forEach((item) => {
              const value = floatValueInUserUnits(
                item?.amount,
                userUOM.unitBarnOutputMass,
                defaultMetric.unitBarnOutputMass,
                precision
              );
              stageFormData.feed.antibioticsUse.push({
                type: item?.type,
                amount: value,
              });
            });
          }
          if (stageData?.feedingProgram?.nutrientComposition) {
            const { nutrientComposition } = stageData.feedingProgram;

            if (
              nutrientComposition.crudeProtein ||
              nutrientComposition.crudeProtein === 0
            )
              stageFormData.feed.nutrientComposition.crudeProtein =
                nutrientComposition.crudeProtein;

            if (
              nutrientComposition.crudeLipid ||
              nutrientComposition.crudeLipid === 0
            )
              stageFormData.feed.nutrientComposition.crudeLipid =
                nutrientComposition.crudeLipid;

            if (
              nutrientComposition.crudeFibre ||
              nutrientComposition.crudeFibre === 0
            )
              stageFormData.feed.nutrientComposition.crudeFibre =
                nutrientComposition.crudeFibre;

            if (nutrientComposition.starch || nutrientComposition.starch === 0)
              stageFormData.feed.nutrientComposition.starch =
                nutrientComposition.starch;

            if (nutrientComposition.sugar || nutrientComposition.sugar === 0)
              stageFormData.feed.nutrientComposition.sugar =
                nutrientComposition.sugar;

            if (
              nutrientComposition.phosphorous ||
              nutrientComposition.phosphorous === 0
            )
              stageFormData.feed.nutrientComposition.phosphorous =
                nutrientComposition.phosphorous;

            if (
              nutrientComposition.feedSpill ||
              nutrientComposition.feedSpill === 0
            )
              stageFormData.feed.nutrientComposition.feedSpill =
                nutrientComposition.feedSpill;

            if (
              nutrientComposition.dryMatter ||
              nutrientComposition.dryMatter === 0
            )
              stageFormData.feed.nutrientComposition.dryMatter =
                nutrientComposition.dryMatter;

            if (
              nutrientComposition.solubleNitrogen ||
              nutrientComposition.solubleNitrogen === 0
            )
              stageFormData.feed.nutrientComposition.solubleNitrogen =
                nutrientComposition.solubleNitrogen;

            if (
              nutrientComposition.solublePhosphorous ||
              nutrientComposition.solublePhosphorous === 0
            )
              stageFormData.feed.nutrientComposition.solublePhosphorous =
                nutrientComposition.solublePhosphorous;

            if (
              nutrientComposition.solubleCarbon ||
              nutrientComposition.solubleCarbon === 0
            )
              stageFormData.feed.nutrientComposition.solubleCarbon =
                nutrientComposition.solubleCarbon;

            if (
              nutrientComposition.digestibleNitrogen ||
              nutrientComposition.digestibleNitrogen === 0
            )
              stageFormData.feed.nutrientComposition.digestibleNitrogen =
                nutrientComposition.digestibleNitrogen;

            if (
              nutrientComposition.digestibleCarbon ||
              nutrientComposition.digestibleCarbon === 0
            )
              stageFormData.feed.nutrientComposition.digestibleCarbon =
                nutrientComposition.digestibleCarbon;

            if (
              nutrientComposition.digestiblePhosphorous ||
              nutrientComposition.digestiblePhosphorous === 0
            )
              stageFormData.feed.nutrientComposition.digestiblePhosphorous =
                nutrientComposition.digestiblePhosphorous;
          }

          stageFormData.animalType =
            nameMapping[stageData.animal_group.animal_type] ||
            stageData.animal_group.animal_type;
          stageFormData.input.housingType =
            stageData?.housing_description?.housing_type;
        }
      }
    });
  }
  return formData;
};

export default mapMarineFishProductionDataInSustell;
