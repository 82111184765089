import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonInterventionDataIn,
  interventionFloatValueInUserUnits
} from './inCommonMapperSustell';

const mapBroilerInterventionDataInSustell = (inData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();
  const formData = mapCommonInterventionDataIn(inData);

  inData.stages.forEach( (stage, index) => {
    const stageData = JSON.parse(stage.stageData) || {};
    if(formData.stages[index]){
      const stageOutputFormPart = formData.stages[index]?.stageData?.output;
      if(stageOutputFormPart){      
        if (
          stageData?.broilers_to_slaughter?.total_weight?.value ||
          stageData?.broilers_to_slaughter?.total_weight?.value === 0
        ) {
          let liveWeight = stageData.broilers_to_slaughter.total_weight.value;
          const liveWeightChangeMetric =
            stageData?.broilers_to_slaughter?.total_weight?.change_type || 'relative';
          liveWeight = interventionFloatValueInUserUnits(
            liveWeight,
            userUOM.unitOutputMass,
            defaultMetric.unitOutputMass,
            liveWeightChangeMetric,
            outputMassPrecision
          );
          stageOutputFormPart.liveWeight = liveWeight;
          stageOutputFormPart.liveWeight_changeMetric = liveWeightChangeMetric;
        }
      }

      // map input data
      const stageInputFormPart = formData.stages[index]?.stageData?.input;
      if(stageInputFormPart){
        if (
          stageData?.purchased_one_day_chickens?.number_of_animals
            ?.value ||
            stageData?.purchased_one_day_chickens?.number_of_animals
            ?.value === 0
        ) {
          stageInputFormPart.purchasedOneDayChickens =
            stageData?.purchased_one_day_chickens?.number_of_animals?.value;
          stageInputFormPart.purchasedOneDayChickens_changeMetric =
            stageData?.purchased_one_day_chickens
              ?.number_of_animals?.change_type || 'relative';
        }

        stageInputFormPart.mortalityRate =
          stageData?.mortality?.mortality_rate?.value;
        stageInputFormPart.mortalityRate_changeMetric =
          stageData?.mortality?.mortality_rate?.change_type ||
          'relative';

        let avgMorVal =
          stageData?.mortality?.average_live_weight_mortalities?.value;
        const chgMetric =
          stageData?.mortality?.average_live_weight_mortalities?.change_type;
        avgMorVal = interventionFloatValueInUserUnits(
          avgMorVal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          chgMetric,
          precision
        );

        stageInputFormPart.averageLiveWeightMortalities = avgMorVal;
        stageInputFormPart.averageLiveWeightMortalities_changeMetric = chgMetric || 'relative';
      }
      formData.stages[index].productionSystem = stageData?.housing_description?.housing_type;
    }
      
  });
  return formData;
};

export default mapBroilerInterventionDataInSustell
