import React, { useEffect, useRef } from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import DeleteForever from '@material-ui/icons/DeleteForever';

import InfoToolTip from '../../Helpers/InfoToolTip';
import SustellNewBoatInput from './SustellNewBoatInput';
import { resolvePath } from '../../Helpers/resolvePathFn';
// import { useIntl } from 'react-intl';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

const boatItem = { boatName: '', type: '', amount: '' };

const SustellBoatInput = props => {
  const {
    label,
    availableOptions,
    subFieldName,
    tooltip,
    formState,
    data,
    setData,
    fieldItemPrefix,
  } = props;

  const manualChange = useRef(false);
  const { setValue, getValues } = useFormContext();

  const intl = useIntl();

  useEffect(() => {
    if (manualChange.current) {
      // updates the underlying fields in form after manual
      // adding or removing feeds - important on removal!
      // but should not be triggered when components refresh by itself (change in selectbox or similar)
      setValue(fieldItemPrefix + '.' + subFieldName, data);
      manualChange.current = false;
    }
  }, [data, subFieldName, setValue, fieldItemPrefix]);

  const append = () => {
    //get the internal rect-hook-form state
    let formValues = resolvePath(
      getValues(),
      fieldItemPrefix + '.' + subFieldName,
      []
    );
    if (formValues === undefined) formValues = [];
    manualChange.current = true;
    setData([...formValues, { ...boatItem }]);
  };

  const remove = index => {
    const formValues = resolvePath(
      getValues(),
      fieldItemPrefix + '.' + subFieldName,
      []
    );
    manualChange.current = true;
    setData([...formValues.slice(0, index), ...formValues.slice(index + 1)]);
  };

  return (
    <>
      <Grid item container key={fieldItemPrefix + '.' + subFieldName}>
        {(data || []).map((item, index) => {
          return (
            <Grid
              container
              spacing={1}
              key={'inputItem_' + fieldItemPrefix + '.' + subFieldName + index}
              alignItems="center"
            >
              <SustellNewBoatInput
                label={label}
                availableOptions={availableOptions}
                index={index}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName={subFieldName}
                typeValue={item.type}
                formState={formState}
              />
              <Grid item xs={1}>
                {formState !== 'view' && (
                  <IconButton
                    style={{ marginBottom: '5px' }}
                    aria-label="Remove input"
                    onClick={() => remove(index)}
                  >
                    <DeleteForever />
                  </IconButton>
                )}
              </Grid>
              <Grid
                item
                xs={1}
                pr={0}
                className="d-flex align-items-center"
                key={'inputPart4' + item.id}
              >
                <InfoToolTip tooltip={tooltip} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {formState !== 'view' && (
        <Grid container spacing={1}>
          <Grid item xs={12} align="right" style={{ marginBottom: '16px' }}>
            <Button variant="outlined" onClick={e => append()}>
              {intl.formatMessage({ id: 'GENERAL.ADD' })} {label}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SustellBoatInput;
