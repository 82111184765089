import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { addDateProperties } from '../../../../sustell_15/utils/datetime-utils';
import { emissionFieldsMapIn } from './emissionFields';
import { getImpactData } from '../../../../sustell_15/components/helpers/ImpactNumbers';
import { explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';

const math = require('mathjs');

export const interventionFloatValueInUserUnits = (
  value,
  userUnit,
  defaultUnit,
  changeMetric,
  precision = 5
) => {
  if (changeMetric === 'set' || changeMetric === 'absolute')
    if (value || value === 0) {
      if (userUnit !== defaultUnit)
        return math.round(
          explicitConvertValue(value, defaultUnit, userUnit),
          precision
        );
      return math.round(value, precision);
    }
  return value;
};

const createCoreForm = (inData) => {
  // console.log("CATEGORY:",inData.category, inData)
  const result = {
    id: inData.id,
    farmId: inData.farmId,
    productionProcessId: inData.productionProcessId,
    baseline: inData.baseline,
    info:{
      name: inData.name,
      oldName: inData.oldName || inData.name,
      description: inData.description,
      interventionCategory: inData.category || '',
    },
    resourceUse:{},
    stages:[],
    updatedAt: inData.updatedAt,
    lastUpdatedBy: inData.lastUpdatedBy,
  };
  // add date fileds
  addDateProperties(result, 'updatedAt');

  return result;
};

const mapManureManagementForOneStage = (
  manureManagement, // oneStageIntervention,
  formDataObj
) => {
  const formData = formDataObj;
  if (manureManagement?.manure_management_system)
      formData.manureManagementSystem =
      manureManagement?.manure_management_system.value;

  if (
    manureManagement.solid_manure_storage_on_farm?.value ||
    manureManagement.solid_manure_storage_on_farm?.value === 0
  ) {
      formData.solidManureStorage =
      manureManagement?.solid_manure_storage_on_farm?.value;
      formData.solidManureStorage_changeMetric =
      manureManagement?.solid_manure_storage_on_farm?.change_type || 'relative';
  }

  if (
    manureManagement.slurry_manure_storage_on_farm?.value ||
    manureManagement.slurry_manure_storage_on_farm?.value === 0
  ) {
      formData.slurryManureStorage =
      manureManagement?.slurry_manure_storage_on_farm?.value;
      formData.slurryManureStorage_changeMetric =
      manureManagement?.slurry_manure_storage_on_farm?.change_type ||
      'relative';
  }

  if (
    manureManagement?.ash_content?.value ||
    manureManagement?.ash_content?.value === 0
  ) {
    formData.ashContent = manureManagement?.ash_content?.value;
    formData.ashContent_changeMetric =
      manureManagement?.ash_content?.change_type || 'relative';
  }

  return formData;
};

const mapBeddingForOneStage = (bedding, formDataObj) => {
  const formData = formDataObj;
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  if (
    bedding?.straw_for_bedding?.value ||
    bedding?.straw_for_bedding?.value === 0
  ) {
    let strawForBedding = bedding.straw_for_bedding.value;
    const chgMetric = bedding.straw_for_bedding?.change_type;
    strawForBedding = interventionFloatValueInUserUnits(
      strawForBedding,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      chgMetric,
      precision
    );
    formData.strawForBedding = strawForBedding;
    formData.strawForBedding_changeMetric =
      bedding?.straw_for_bedding?.change_type || 'relative';
  }

  if (bedding?.saw_dust?.value || bedding?.saw_dust?.value === 0) {
    let sawDust = bedding.saw_dust.value;
    const chgMetric = bedding.saw_dust?.change_type;
    sawDust = interventionFloatValueInUserUnits(
      sawDust,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      chgMetric,
      precision
    );
    formData.sawDust = sawDust;
    formData.sawDust_changeMetric =
      bedding?.saw_dust?.change_type || 'relative';
  }

  return formData;
};

const mapCompoundFeedForOneStage = (ration, formDataObj) => {
  const formData = formDataObj;
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  // check feeds ration
  if (
    ration?.compound_feed_uses &&
    Array.isArray(ration.compound_feed_uses)
  ) {
    const compoundFeeds = [];
    ration.compound_feed_uses.forEach(oneFeedData => {
      const feedChangeMetric = oneFeedData.quantity?.change_type || 'relative';
      let value = oneFeedData.quantity?.value;
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      const oneCompoundFeed = {
        feedType: oneFeedData.entity_id,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      compoundFeeds.push(oneCompoundFeed);
    });
    formData.compoundFeeds = compoundFeeds;
  }

  // check feeds additional ration
  if (
    ration?.compound_feed_uses_additions &&
    Array.isArray(ration.compound_feed_uses_additions)
  ) {
    const compoundFeedsAdditions = [];
    ration.compound_feed_uses_additions.forEach(
      addnlFeedData => {
        const feedChangeMetric =
          addnlFeedData.quantity?.change_type || 'relative'; // but for addiotional feeds it must be 'set'
        let value = addnlFeedData.quantity?.value;
        if (value && !isNaN(value))
          value = interventionFloatValueInUserUnits(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            feedChangeMetric,
            precision
          );
        const oneCompoundFeed = {
          feedType: addnlFeedData.entity_id,
          kgPerAnimal: value,
          kgPerAnimal_changeMetric: feedChangeMetric,
        };
        compoundFeedsAdditions.push(oneCompoundFeed);
      }
    );
    formData.compoundFeedsAdditions = compoundFeedsAdditions;
  }
};

const mapSingleIngridientsForOneStage = (
  ration,
  formDataObj
) => {
  const formData = formDataObj;
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  if (
    ration?.single_ingredient_uses &&
    Array.isArray(ration.single_ingredient_uses)
  ) {
    const singleIngredients = [];
    ration.single_ingredient_uses.forEach(oneFeedData => {
      const feedChangeMetric = oneFeedData.quantity?.change_type || 'relative';
      let value = oneFeedData.quantity?.value;
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      const oneSingleFeed = {
        feedType: oneFeedData.entity_id,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      singleIngredients.push(oneSingleFeed);
    });
    formData.singleIngredients = singleIngredients;
  }

  if (
    ration?.single_ingredient_uses_additions &&
    Array.isArray(ration.single_ingredient_uses_additions)
  ) {
    const singleIngredientsAdditions = [];
    ration.single_ingredient_uses_additions.forEach(
      addnlFeedData => {
        const feedChangeMetric =
          addnlFeedData.quantity?.change_type || 'relative'; // although it must be 'set'
        let value = addnlFeedData.quantity?.value;
        if (value && !isNaN(value))
          value = interventionFloatValueInUserUnits(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            feedChangeMetric,
            precision
          );
        const oneSingleFeed = {
          feedType: addnlFeedData.entity_id,
          kgPerAnimal: value,
          kgPerAnimal_changeMetric: feedChangeMetric,
        };
        singleIngredientsAdditions.push(oneSingleFeed);
      }
    );
    formData.singleIngredientsAdditions = singleIngredientsAdditions;
  }
};

const mapRationForOneStage = (ration, formDataObj) => {
  const formData = formDataObj;
  if (ration?.digestibility?.value || ration?.digestibility?.value === 0) {
    formData.digestibility = ration.digestibility.value;
    formData.digestibility_changeMetric =
      ration.digestibility.change_type || 'relative';
  }

  if (
    ration?.gross_energy_intake?.value ||
    ration?.gross_energy_intake?.value === 0
  ) {
    formData.grossEnergyIntake = ration.gross_energy_intake.value;
    formData.grossEnergyIntake_changeMetric =
      ration.gross_energy_intake.change_type || 'relative';
  }

  if (
    ration?.crude_protein_content?.value ||
    ration?.crude_protein_content?.value === 0
  ) {
    formData.crudeProteinContent = ration.crude_protein_content.value;
    formData.crudeProteinContent_changeMetric =
      ration.crude_protein_content.change_type || 'relative';
  }

  if (ration?.silage_in_feed?.value || ration?.silage_in_feed?.value === 0) {
    formData.percentageOfSilage = ration.silage_in_feed.value;
    formData.percentageOfSilage_changeMetric =
      ration.silage_in_feed.change_type || 'relative';
  }

  if (
    ration?.nitrogen_content?.value ||
    ration?.nitrogen_content?.value === 0
  ) {
    formData.nitrogenContent = ration.nitrogen_content.value;
    formData.nitrogenContent_changeMetric =
      ration.nitrogen_content.change_type || 'relative';
  }

  if (
    ration?.dry_matter_content?.value ||
    ration?.dry_matter_content?.value === 0
  ) {
    formData.dryMatterContent = ration.dry_matter_content.value;
    formData.dryMatterContent_changeMetric =
      ration.dry_matter_content.change_type || 'relative';
  }

  mapCompoundFeedForOneStage(ration, formData);
  mapSingleIngridientsForOneStage(ration, formData);

  return formData;
};

const mapEmissionsForOneStage = (inputEmissions, formDataObj) => {
  // check if emission data exists for this barn
  const formData = formDataObj;
  if (
    inputEmissions &&
    Array.isArray(inputEmissions)
  ) {
    // first try with the same index - should be at same position
    inputEmissions.forEach ( emission => {
        // Restore the original intervention that was made on emission field only, for the sake of correct form input
        const fieldName = emissionFieldsMapIn.get(emission.key);
        const changeMetric = emission.value?.change_type;
        formData[fieldName] = emission.value?.value;
        // this adapts all previously saved intervention, that uses 'percentage' as change type to display correctly the value as set
        formData[`${fieldName}_changeMetric`] =
          !changeMetric || changeMetric === 'percentage' ? 'set' : changeMetric;
    });
  }
};

const mapStageData = (interventionStages, formData) => {
  
  if (interventionStages && Array.isArray(interventionStages)) {
    interventionStages.forEach( oneStageIntervention => {
      const stageData = JSON.parse(oneStageIntervention.stageData) || {};
      const oneStageFormData = {
        id: oneStageIntervention?.id,
        name: oneStageIntervention?.name,
        stageData:{
          input:{},
          feed:{
            totalFeedIntake_changeMetric: 'set',
            compoundFeeds: [],
            compoundFeedsAdditions: [],
            singleIngredients: [],
            singleIngredientsAdditions: []
          },
          manure:{
            averageAnimalsPresent: stageData?.animal_group?.number_of_animals?.value,
            averageAnimalsPresent_changeMetric: stageData?.animal_group?.number_of_animals?.change_type || 'relative',
          },
          emissions:{},
          output:{}
        }
      };

      mapManureManagementForOneStage(stageData?.manure_management, oneStageFormData.stageData.manure);
      mapBeddingForOneStage(stageData?.bedding, oneStageFormData.stageData.manure);
      mapRationForOneStage(stageData?.ration, oneStageFormData.stageData.feed);
      mapEmissionsForOneStage(stageData?.emissions, oneStageFormData.stageData.emissions);

      formData.stages.push(oneStageFormData);
    });
  }
  return formData;
};

// resources is a list of resources to map ['diesel', 'gas' 'electricity', 'water']
export const mapResourceUse = (
  resourceUseData,
  formDataObj,
  resources = ['diesel', 'gas', 'electricity', 'water']
) => {
  const formData = formDataObj;
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitResourcePrecision();
  
  const gas = resourceUseData.gasUse;
  if (gas && resources?.includes('gas')) {
    formData.gasUse = interventionFloatValueInUserUnits(
      gas.value,
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse,
      gas.change_type,
      precision
    );
    formData.gasUse_changeMetric = gas.change_type || 'relative';
  }

  const diesel = resourceUseData.dieselUse;
  if (diesel && resources?.includes('diesel')) {
    formData.dieselUse = interventionFloatValueInUserUnits(
      diesel.value,
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse,
      diesel.change_type,
      precision
    );
    formData.dieselUse_changeMetric = diesel.change_type || 'relative';
  }

  const water = resourceUseData.waterUse;
  if (water && resources?.includes('water')) {
    let waterVal = water.value;
    waterVal = interventionFloatValueInUserUnits(
      waterVal,
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse,
      water.change_type,
      precision
    );
    formData.waterUse = waterVal;
    formData.waterUse_changeMetric = water.change_type || 'relative';
  }

  const electricity = resourceUseData.electricityUse;
  if (electricity && resources?.includes('electricity')) {
    let elVal = electricity.value;
    elVal = interventionFloatValueInUserUnits(
      elVal,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse,
      electricity.change_type,
      precision
    );
    formData.electricityUse = elVal;
    formData.electricityUse_changeMetric =
      electricity.change_type || 'relative';
  }

  return formData;
};

export const mapCommonInterventionDataIn = (inData) => {
  const formData = createCoreForm(inData);
  mapResourceUse(inData.resourceUse, formData.resourceUse)
  mapStageData(inData.stages, formData);
  getImpactData(inData, formData)
  return formData;
};


