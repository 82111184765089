import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { Prompt } from "react-router";
import CustomerIngredientsAddonsList from "./components/CustomerIngredientsAddonsList";
import Can from "../Auth/Can";
import {
  listAPITokens,
  listPartners,
  getCustomer,
} from "../../../graphql/queries";
import { CustomerIngredientsList } from "./components/CustomerIngredientsList";
import { CustomerIngredientTypes } from "./ingredientList";
import { useIntl } from "../../../_metronic/i18n/customUseIntl";

// eslint-disable-next-line import/prefer-default-export
export const CustomerBaseForm = (props) => {
  const intl = useIntl();
  const {
    values: {
      name,
      id,
      token,
      tokenName,
      tokenValue,
      changeToken,
      farmsNumberLimit,
      SAPCustomerId,
      type,
      license,
      licenseStartDate,
      licenseEndDate,
      contractNumber,
      feedsVisible,
      partnerApiAccess,
    },
    dirty,
    errors,
    formMode,
    touched,
    handleBlur,
    ingredientsLoading,
    ingredientsAddonsLoading,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    compoundFeedIngredientsAddons,
    setCompoundFeedIngredientsAddons,
    compoundFeedIngredients,
    setCompoundFeedIngredients,
    singleIngredients,
    setSingleIngredients,
    assignedSingleIngredients,
    assignedCompoundFeedIngredients,
    assignedCompoundFeedIngredientsAddons,
    compoundFeedIngredientsAddonsList,
  } = props;
  const [availableTokens, setAvailableTokens] = useState([]);
  const [availablePartners, setAvailablePartners] = useState([]);
  const [changeReferenceModal, setChangeReferenceModal] = useState(false);

  const [partners, setPartners] = useState([]);
  const [allowedQueries, setAllowedQueries] = useState([]);
  const [allowedMutations, setAllowedMutations] = useState([]);

  const change = (fieldName, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(fieldName, true, false);
    if (fieldName === "token") {
      if (e.target.value === "onboard" || e.target.value === "new")
        setFieldValue(
          "tokenName",
          name.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase()
        );
      else setFieldValue("tokenName", "");
      setFieldValue("tokenValue", "");
      setFieldValue("changeToken", false);
    }
  };

  const handlePartnerChanges = (e) => {
    if (e.target.checked) {
      setFieldValue("canAccessPartner", partners.concat(e.target.value));
      setPartners(partners.concat(e.target.value));
    } else {
      const updatedPartners = partners.filter((p) => p !== e.target.value);
      setFieldValue("canAccessPartner", updatedPartners);

      setPartners(updatedPartners);
      const updatedAllowedQueries = allowedQueries.filter(
        (q) => q.partnerId !== e.target.value
      );
      setFieldValue("allowedPartnerQueries", updatedAllowedQueries);
      setAllowedQueries(updatedAllowedQueries);
      const updatedAllowedMutations = allowedMutations.filter(
        (q) => q.partnerId !== e.target.value
      );
      setFieldValue("allowedPartnerMutations", updatedAllowedMutations);
      setAllowedMutations(updatedAllowedMutations);
    }
  };

  const handlePartnerQueryChanges = (e) => {
    const partnerId = e.target?.value?.split("#")[0];
    const query = e.target?.value?.split("#")[1];
    if (e.target.checked) {
      if (partnerId && query) {
        const partner = allowedQueries.find((q) => q.partnerId === partnerId);
        if (partner) {
          const updatedAllowedQueries = allowedQueries.map((q) =>
            q.partnerId === partnerId
              ? { ...q, queries: q.queries.concat(query) }
              : q
          );
          setFieldValue("allowedPartnerQueries", updatedAllowedQueries);
          setAllowedQueries(updatedAllowedQueries);
        } else {
          const partner = {
            partnerId,
            queries: [query],
          };
          setFieldValue(
            "allowedPartnerQueries",
            allowedQueries.concat([partner])
          );

          setAllowedQueries(allowedQueries.concat([partner]));
        }
      }
    } else {
      const updatedAllowedQueries = allowedQueries.map((q) =>
        q.partnerId === partnerId
          ? { ...q, queries: q.queries.filter((qu) => qu !== query) }
          : q
      );
      setFieldValue("allowedPartnerQueries", updatedAllowedQueries);
      setAllowedQueries(updatedAllowedQueries);
    }
  };

  const handlePartnerMutationChanges = (e) => {
    const partnerId = e.target?.value?.split("#")[0];
    const mutation = e.target?.value?.split("#")[1];
    if (e.target.checked) {
      if (partnerId && mutation) {
        const partner = allowedMutations.find((q) => q.partnerId === partnerId);
        if (partner) {
          const updatedAllowedMutations = allowedMutations.map((q) =>
            q.partnerId === partnerId
              ? { ...q, mutations: q.mutations.concat(mutation) }
              : q
          );
          setFieldValue("allowedPartnerMutations", updatedAllowedMutations);
          setAllowedMutations(updatedAllowedMutations);
        } else {
          const partner = {
            partnerId,
            mutations: [mutation],
          };
          setFieldValue(
            "allowedPartnerMutations",
            allowedMutations.concat([partner])
          );
          setAllowedMutations(allowedMutations.concat([partner]));
        }
      }
    } else {
      const updatedAllowedMutations = allowedMutations.map((q) =>
        q.partnerId === partnerId
          ? { ...q, mutations: q.mutations.filter((qu) => qu !== mutation) }
          : q
      );
      setFieldValue("allowedPartnerMutations", updatedAllowedMutations);
      setAllowedMutations(updatedAllowedMutations);
    }
  };

  useEffect(() => {
    API.graphql({
      query: listAPITokens,
    }).then((response) => {
      setAvailableTokens(response.data.listAPITokens.items);
    });

    API.graphql({
      query: listPartners,
    }).then((response) => {
      setAvailablePartners(response.data.listPartners.items);
    });
    API.graphql({
      query: getCustomer,
      variables: { id },
    }).then((customer) => {
      setPartners(customer.data?.getCustomer?.canAccessPartner ?? []);
      setFieldValue(
        "canAccessPartner",
        customer.data?.getCustomer?.canAccessPartner ?? []
      );
      setAllowedQueries(
        customer.data?.getCustomer?.allowedPartnerQueries ?? []
      );
      setFieldValue(
        "allowedPartnerQueries",
        customer.data?.getCustomer?.allowedPartnerQueries ?? []
      );
      setAllowedMutations(
        customer.data?.getCustomer?.allowedPartnerMutations ?? []
      );
      setFieldValue(
        "allowedPartnerMutations",
        customer.data?.getCustomer?.allowedPartnerMutations ?? []
      );
    });
  }, []);

  return (
    <>
      <Prompt
        when={dirty}
        message={intl.formatMessage({
          id: "CUSTOMERS.FORM.VALIDATION.UNSAVED_CHANGES",
        })}
      />
      <Dialog
        open={changeReferenceModal}
        onClose={(e) => this.closeDeleteFarmModal(e)}
        aria-labelledby="alert-dialog-reference-customer-title"
        aria-describedby="alert-dialog-reference-customer-description"
      >
        <DialogTitle id="alert-dialog-reference-customer-title">
          {intl.formatMessage({
            id: "CUSTOMERS.FORM.FIELD.REFERENCE.MODAL.TITLE",
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-reference-customer-description">
            {intl.formatMessage({
              id: "CUSTOMERS.FORM.FIELD.REFERENCE.MODAL.MESSAGE",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setChangeReferenceModal(false)}
            color="primary"
          >
            {intl.formatMessage({ id: "GENERAL.CANCEL" })}
          </Button>
          <Button
            onClick={() => {
              setFieldValue("reference", true);
              setChangeReferenceModal(false);
            }}
            style={{ color: "red" }}
            autoFocus
          >
            {intl.formatMessage({ id: "GENERAL.YES" })}
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={11}>
            <TextField
              id="name"
              name="name"
              label={intl.formatMessage({ id: "CUSTOMERS.FORM.FIELD.NAME" })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.NAME",
              })}
              value={name}
              onChange={change.bind(null, "name")}
              helperText={touched.name ? errors.name : ""}
              error={touched.name && Boolean(errors.name)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11}>
            <TextField
              id="id"
              name="id"
              label={intl.formatMessage({ id: "CUSTOMERS.FORM.FIELD.ID" })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.ID",
              })}
              value={id}
              fullWidth
              margin="normal"
              variant="outlined"
              disabled
            />
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11} className="d-flex align-items-center mt-7">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="token">
                {intl.formatMessage({ id: "CUSTOMERS.FORM.FIELD.TOKEN" })}
              </InputLabel>
              <Select
                fullWidth
                value={token}
                onChange={change.bind(null, "token")}
                onBlur={handleBlur}
                error={touched.token && Boolean(errors.token)}
                input={
                  <OutlinedInput
                    label={intl.formatMessage({
                      id: "CUSTOMERS.FORM.FIELD.TOKEN",
                    })}
                    name="token"
                    id="token"
                    value={token}
                  />
                }
              >
                <MenuItem
                  key="default"
                  value="default"
                  style={{ fontWeight: "bold" }}
                >
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.TOKEN.DEFAULT",
                  })}
                </MenuItem>
                <MenuItem key="new" value="new" style={{ fontWeight: "bold" }}>
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.TOKEN.ADD_NEW_TOKEN",
                  })}
                </MenuItem>
                {formMode !== "edit" && (
                  <MenuItem
                    key="onboard"
                    value="onboard"
                    style={{ fontWeight: "bold" }}
                  >
                    {intl.formatMessage({
                      id: "CUSTOMERS.FORM.FIELD.TOKEN.ONBOARD_CUSTOMER",
                    })}
                  </MenuItem>
                )}
                {availableTokens?.map((availableToken) => (
                  <MenuItem key={availableToken.key} value={availableToken.key}>
                    {availableToken.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        {token !== "new" &&
          token !== "" &&
          token !== "default" &&
          token !== "onboard" && (
            <Row>
              <Col md={11}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="changeToken"
                      name="changeToken"
                      checked={changeToken}
                      onChange={change.bind(null, "changeToken")}
                    />
                  }
                  label={intl.formatMessage(
                    { id: "CUSTOMERS.FORM.FIELD.CHANGE_TOKEN" },
                    { token }
                  )}
                />
              </Col>
              <Col md={1} className="d-flex align-items-center" />
            </Row>
          )}
        {(token === "new" || token === "onboard") && !changeToken && (
          <Row>
            <Col md={11}>
              <TextField
                id="tokenName"
                name="tokenName"
                label={intl.formatMessage({
                  id: "CUSTOMERS.FORM.FIELD.TOKEN_NAME",
                })}
                placeholder={intl.formatMessage({
                  id: "CUSTOMERS.FORM.FIELD.TOKEN_NAME",
                })}
                value={tokenName}
                onChange={change.bind(null, "tokenName")}
                helperText={touched.tokenName ? errors.tokenName : ""}
                error={touched.tokenName && Boolean(errors.tokenName)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Col>
          </Row>
        )}
        {(token === "onboard" || formMode === "edit") &&
          !ingredientsLoading && (
            <Row>
              <Col md={5}>
                {compoundFeedIngredients !== null && (
                  <CustomerIngredientsList
                    type={CustomerIngredientTypes.CF_INGREDIENTS}
                    setIngredientList={setCompoundFeedIngredients}
                    ingredientList={compoundFeedIngredients}
                    assignedIngredients={assignedCompoundFeedIngredients}
                  />
                )}
              </Col>
              <Col md={1} />
              <Col md={5}>
                {singleIngredients !== null && (
                  <CustomerIngredientsList
                    type={CustomerIngredientTypes.SINGLE_INGREDIENTS}
                    setIngredientList={setSingleIngredients}
                    ingredientList={singleIngredients}
                    assignedIngredients={assignedSingleIngredients}
                  />
                )}
              </Col>
            </Row>
          )}
        {!ingredientsAddonsLoading && (
          <Row>
            <Col md={5}>
              <CustomerIngredientsAddonsList
                ingredientAddonsList={compoundFeedIngredientsAddonsList}
                setIngredientList={setCompoundFeedIngredientsAddons}
                ingredientList={compoundFeedIngredientsAddons}
                assignedIngredients={assignedCompoundFeedIngredientsAddons}
              />
            </Col>
            <Col md={1} />
          </Row>
        )}
        {(ingredientsLoading || ingredientsAddonsLoading) && (
          <Row>
            <Col md={5}>
              {intl.formatMessage({
                id: "CUSTOMERS.CARD.INGREDIENTS_LIST.LOADING",
              })}
            </Col>
            <Col md={5}>
              <CircularProgress
                title={intl.formatMessage({
                  id: "CUSTOMERS.CARD.INGREDIENTS_LIST.LOADING",
                })}
              />
            </Col>
          </Row>
        )}
        {(token === "new" || changeToken) && (
          <Row>
            <Col md={11}>
              <TextField
                id="tokenValue"
                name="tokenValue"
                type="password"
                label={intl.formatMessage({
                  id: "CUSTOMERS.FORM.FIELD.TOKEN_VALUE",
                })}
                placeholder={intl.formatMessage({
                  id: "CUSTOMERS.FORM.FIELD.TOKEN_VALUE",
                })}
                value={tokenValue}
                onChange={change.bind(null, "tokenValue")}
                helperText={touched.tokenValue ? errors.tokenValue : ""}
                error={touched.tokenValue && Boolean(errors.tokenValue)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={11}>
            <TextField
              id="farmsNumberLimit"
              name="farmsNumberLimit"
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.FARMS_NUMBER_LIMIT",
              })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.FARMS_NUMBER_LIMIT",
              })}
              value={farmsNumberLimit}
              type="number"
              onChange={change.bind(null, "farmsNumberLimit")}
              helperText={
                touched.farmsNumberLimit ? errors.farmsNumberLimit : ""
              }
              error={
                touched.farmsNumberLimit && Boolean(errors.farmsNumberLimit)
              }
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!Can("setFarmsNumberLimit", "Customer")}
            />
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11}>
            <TextField
              id="SAPCustomerId"
              name="SAPCustomerId"
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.SAP_CUSTOMER_ID",
              })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.SAP_CUSTOMER_ID",
              })}
              value={SAPCustomerId}
              onChange={change.bind(null, "SAPCustomerId")}
              helperText={touched.SAPCustomerId ? errors.SAPCustomerId : ""}
              error={touched.SAPCustomerId && Boolean(errors.SAPCustomerId)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Col>
        </Row>
        <Row>
          <Col md={11} className="d-flex align-items-center mt-7">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="type">
                {intl.formatMessage({
                  id: "CUSTOMERS.FORM.FIELD.CUSTOMER_TYPE",
                })}
              </InputLabel>
              <Select
                fullWidth
                value={type}
                onChange={change.bind(null, "type")}
                onBlur={handleBlur}
                error={touched.token && Boolean(errors.token)}
                input={
                  <OutlinedInput
                    label={intl.formatMessage({
                      id: "CUSTOMERS.FORM.FIELD.CUSTOMER_TYPE",
                    })}
                    name="type"
                    id="type"
                    value={token}
                  />
                }
              >
                <MenuItem key="FARMER" value="FARMER">
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.CUSTOMER_TYPE.FARMER",
                  })}
                </MenuItem>
                <MenuItem key="FEED_MILL" value="FEED_MILL">
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.CUSTOMER_TYPE.FEED_MILL",
                  })}
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11} className="d-flex align-items-center mt-7">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="license">
                {intl.formatMessage({
                  id: "CUSTOMERS.FORM.FIELD.LICENSE_TYPE",
                })}
              </InputLabel>
              <Select
                fullWidth
                value={license}
                onChange={change.bind(null, "license")}
                onBlur={handleBlur}
                error={touched.token && Boolean(errors.token)}
                input={
                  <OutlinedInput
                    label={intl.formatMessage({
                      id: "CUSTOMERS.FORM.FIELD.LICENSE_TYPE",
                    })}
                    name="license"
                    id="license"
                    value={license}
                  />
                }
              >
                <MenuItem key="BASIC" value="BASIC">
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.LICENSE_TYPE.BASIC",
                  })}
                </MenuItem>
                <MenuItem key="PREMIUM" value="PREMIUM">
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.LICENSE_TYPE.PREMIUM",
                  })}
                </MenuItem>
                <MenuItem key="ENTERPRISE" value="ENTERPRISE">
                  {intl.formatMessage({
                    id: "CUSTOMERS.FORM.FIELD.LICENSE_TYPE.ENTERPRISE",
                  })}
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11}>
            <TextField
              id="licenseStartDate"
              name="licenseStartDate"
              type="date"
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.LICENSE_START_DATE",
              })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.LICENSE_START_DATE",
              })}
              value={licenseStartDate}
              onChange={change.bind(null, "licenseStartDate")}
              helperText={
                touched.licenseStartDate ? errors.licenseStartDate : ""
              }
              error={
                touched.licenseStartDate && Boolean(errors.licenseStartDate)
              }
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={11}>
            <TextField
              id="licenseEndDate"
              name="licenseEndDate"
              type="date"
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.LICENSE_END_DATE",
              })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.LICENSE_END_DATE",
              })}
              value={licenseEndDate}
              onChange={change.bind(null, "licenseEndDate")}
              helperText={touched.licenseEndDate ? errors.licenseEndDate : ""}
              error={touched.licenseEndDate && Boolean(errors.licenseEndDate)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={11}>
            <TextField
              id="contractNumber"
              name="contractNumber"
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.CONTRACT_NUMBER",
              })}
              placeholder={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.CONTRACT_NUMBER",
              })}
              value={contractNumber}
              onChange={change.bind(null, "contractNumber")}
              helperText={touched.contractNumber ? errors.contractNumber : ""}
              error={touched.contractNumber && Boolean(errors.contractNumber)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Col>
        </Row>
        {/* <Row>
                <Col md={11}>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                id = "reference"
                                name = "reference"
                                checked = {reference}
                                onChange={(e) => openChangeReferenceModal(e)}
                                disabled={!Can("setReference", "Customer")}
                            />
                        }
                        label={intl.formatMessage({id:"CUSTOMERS.FORM.FIELD.IS_REFERENCE"}) } />
                </Col>
                <Col md={1} className="d-flex align-items-center"></Col>
            </Row> */}
        <Row>
          <Col md={11}>
            <FormControlLabel
              control={
                <Checkbox
                  id="feedsVisible"
                  name="feedsVisible"
                  checked={feedsVisible}
                  onChange={change.bind(null, "feedsVisible")}
                  disabled={!Can("setFeedsVisible", "Customer")}
                />
              }
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.FEEDS_VISIBLE",
              })}
            />
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11}>
            <FormControlLabel
              control={
                <Checkbox
                  id="partnerApiAccess"
                  name="partnerApiAccess"
                  checked={partnerApiAccess}
                  onChange={change.bind(null, "partnerApiAccess")}
                />
              }
              label={intl.formatMessage({
                id: "CUSTOMERS.FORM.FIELD.PARTNER_API_ACCESS",
              })}
            />
          </Col>
          <Col md={1} className="d-flex align-items-center" />
        </Row>
        <Row>
          <Col md={11}>
            {(partnerApiAccess || partners.length > 0) &&
              availablePartners.length > 0 &&
              availablePartners.map((partner) => (
                <>
                  {partner.name && (
                    <>
                      <FormControlLabel
                        value={partner.id}
                        label={partner.name}
                        key={partner.id}
                        checked={partners.includes(partner.id)}
                        control={<Checkbox />}
                        onChange={handlePartnerChanges}
                        style={{ width: "150px", paddingLeft: "20px" }}
                      />
                      <div
                        style={{
                          paddingLeft: "40px",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {intl.formatMessage({
                          id: "CUSTOMERS.FORM.FIELD.PARTNER_API_QUERIES",
                        })}
                      </div>
                      <DsmGrid
                        style={{
                          grid: "auto/1fr 1fr",
                          padding: "0px",
                          alignItems: "start",
                          gap: "0",
                        }}
                      >
                        {partner?.allowedQueries?.map((query) => (
                          <FormControlLabel
                            value={`${partner.id}#${query}`}
                            label={query}
                            key={query}
                            checked={
                              !!allowedQueries.find(
                                (q) =>
                                  q?.partnerId === partner.id &&
                                  q.queries?.includes(query)
                              )
                            }
                            control={<Checkbox />}
                            onChange={handlePartnerQueryChanges}
                            style={{ paddingLeft: "50px" }}
                            disabled={!partners.includes(partner.id)}
                          />
                        ))}
                      </DsmGrid>
                      <div
                        style={{
                          paddingLeft: "40px",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {intl.formatMessage({
                          id: "CUSTOMERS.FORM.FIELD.PARTNER_API_MUTATIONS",
                        })}
                      </div>
                      <DsmGrid
                        style={{
                          grid: "auto/1fr 1fr",
                          padding: "0px",
                          alignItems: "start",
                          gap: "0",
                        }}
                      >
                        {partner?.allowedMutations?.map((mutation) => (
                          <FormControlLabel
                            value={`${partner.id}#${mutation}`}
                            label={mutation}
                            key={mutation}
                            checked={
                              !!allowedMutations.find(
                                (m) =>
                                  m?.partnerId === partner.id &&
                                  m.mutations?.includes(mutation)
                              )
                            }
                            control={<Checkbox />}
                            onChange={handlePartnerMutationChanges}
                            style={{ paddingLeft: "50px" }}
                            disabled={!partners.includes(partner.id)}
                          />
                        ))}
                      </DsmGrid>
                    </>
                  )}
                  {!partner.name && (
                    <div
                      style={{
                        paddingLeft: "40px",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#FF0000"
                      }}
                    >
                      {intl.formatMessage({
                        id: "CUSTOMERS.FORM.FIELD.PARTNER_API_NO_PARTNER_NAME",
                      }, {id: partner.id})}
                    </div>
                  )}
                </>
              ))}
          </Col>
        </Row>
      </form>
    </>
  );
};
