import { TableFooter, TableRow, TablePagination } from '@material-ui/core';
import { type FC, ChangeEvent, MouseEvent } from 'react';
import { useIntl } from 'react-intl';

interface TableFooterRowProps {
  customElements: number;
  page: number;
  rowsPerPage: number;
  onTablePageChange: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) => void;
  onTableRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const TableFooterRow: FC<TableFooterRowProps> = ({
  customElements,
  page,
  rowsPerPage,
  onTablePageChange,
  onTableRowsPerPageChange,
}) => {
  const intl = useIntl();
  return (
    <TableFooter>
      <TableRow>
          <TablePagination
          count={customElements}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={intl.formatMessage({
            id: 'GENERAL.TABLE_PAGINATION.ROWS_PER_PAGE',
          })}
          page={page}
          onPageChange={onTablePageChange}
          rowsPerPageOptions={[5, 8, 10, 15, 20]}
          onRowsPerPageChange={onTableRowsPerPageChange}
          labelDisplayedRows={({ from, to }) =>
            intl.formatMessage(
              { id: 'GENERAL.TABLE_PAGINATION.PAGE_OF_PAGES' },
              { from, to, count: customElements }
            )
          }
          style={{ borderBottom: 'none' }}
        />
      </TableRow>
    </TableFooter>
  );
};

export default TableFooterRow;
