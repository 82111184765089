import { Chip, withStyles } from "@material-ui/core";
import { DSM_LIGHTEST_LIME_GREEN, DSM_LIGHTEST_RED, DSM_LIME_GREEN, DSM_RED } from "../../../../../_metronic/layout";

const StyledChip = withStyles({
    outlinedPrimary: {
      backgroundColor: DSM_LIGHTEST_LIME_GREEN,
      border: `1px solid ${DSM_LIME_GREEN}`,
      color: DSM_LIME_GREEN,
      height: '20px',
    },
    outlinedSecondary: {
      backgroundColor: DSM_LIGHTEST_RED,
      border: `1px solid ${DSM_RED}`,
      color: DSM_RED,
      height: '20px',
    },
    label: {
      fontSize: '12px',
      fontWeight: 500,
    },
  })(Chip);

export default StyledChip;