import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FC } from "react";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { footprintCategoryTableStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import { EmissionEntryModel } from "../../models/Footprint/internal"
import { DialogContainer } from "../FarmFlow/CommonDataParts/DialogContainer";
import ChipChangePercentage from "../helpers/ChipChange";
import { ExactNumber } from "../helpers/ExactNumber";
import { translateKeyMapping } from "../../utils/string-utils";

interface FootprintEmissionDialogProps {
  emissionEntry: EmissionEntryModel;
  formVisible: boolean;
  handleClose: () => void;
  hasComparison: boolean;
  baselineDatasetName: string;
  // eslint-disable-next-line react/require-default-props
  comparisonDatasetName?: string;
}

export const EmissionsStyledTableCell = withStyles(() => ({
  head: {
    fontSize: '12px !important',
    borderBottom: '1px solid #f0f0f0',
  },
  body: {
    border: 'none',
  },
  root: {
    '&:nth-of-type(1)': {
      Width: '298px',
    },
  },
}))(TableCell);

export const EmissionStyledTableRow = withStyles(() => ({
  root: {
    height: '72px',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9FAFB',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(TableRow);

const FootprintEmissionDialog: FC<FootprintEmissionDialogProps> = ({
  emissionEntry,
  formVisible, 
  handleClose,
  hasComparison=false,
  baselineDatasetName,
  comparisonDatasetName='comparison'

}) => {
  const intl = useIntl();
  const classes = footprintCategoryTableStyles();

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleClose}
      datasetType="footprint"
      withIcon={false}
      formTitle= {intl.formatMessage({id: `BASELINE.FORM.EMISSIONS.${translateKeyMapping(emissionEntry.emission)}`})}
    >
      <span>{intl.formatMessage({id: "REPORT.EMISSIONS.DETAILS.TIER_LEVEL"})}: {emissionEntry.tierLevel}  {intl.formatMessage({id: "REPORT.EMISSIONS.DETAILS.SOURCE"})}: 
        <a href={emissionEntry?.sourceLink ? emissionEntry.sourceLink : '#'}>
          <strong> {emissionEntry.source}</strong>
        </a>
      </span>

    {/* Used data table */}
    <TableContainer style={{marginTop: '12px'}}>
        <Table aria-label="used data table" className={classes.table}>
          <TableHead>
            <TableRow>
              <EmissionsStyledTableCell>
                {intl.formatMessage({id: "GENERAL.TYPE"})}
              </EmissionsStyledTableCell>
              <EmissionsStyledTableCell align={hasComparison ? 'left' : 'center'}>
              { hasComparison ?
                baselineDatasetName :
                intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.VALUE',
                })
              }
              </EmissionsStyledTableCell>
              { hasComparison &&
                <EmissionsStyledTableCell>
                  {comparisonDatasetName}
                </EmissionsStyledTableCell>
              }
              { hasComparison &&
                <EmissionsStyledTableCell align="center">
                   {intl.formatMessage({id: "REPORT.FOOTPRINTS.CHANGE"})}
                </EmissionsStyledTableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {emissionEntry.usedData &&
              (emissionEntry.usedData || []).map((usedDataEntry) => {
                const {value, comparedValue, change, displayName} = usedDataEntry;
                return (
                <EmissionStyledTableRow key={displayName}>
                  <EmissionsStyledTableCell
                    style={{ width: '50%'}}
                  >
                    <Box style={{ display: 'flex' }}>
                      <Typography
                        className={classes.tableCellTitle}
                        style={{ paddingLeft: '10px', fontWeight: '500' }}
                      >
                        {displayName}
                      </Typography>
                    </Box>
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell width="17%" align={hasComparison ? 'left' : 'center'}>
                    <Typography
                      className={classes.tableCellTitle}
                      style={{ fontWeight: '500' }}
                    >
                      { !Number.isNaN(Number(value)) ?
                        <ExactNumber value={Number(value)} /> :
                        value
                      }
                    </Typography>
                  </EmissionsStyledTableCell>
                    { hasComparison &&
                      <EmissionsStyledTableCell width="17%">
                        <Typography
                          className={classes.tableCellTitle}
                          style={{ fontWeight: '500' }}
                         >
                        { (comparedValue && !Number.isNaN(Number(comparedValue))) ?
                          <ExactNumber value={Number(comparedValue)} /> :
                          comparedValue
                        }
                      </Typography>
                    </EmissionsStyledTableCell>
                    }
                    { hasComparison &&
                    <EmissionsStyledTableCell align="center">
                      { (change && Number.isFinite(change) || change === 0) ?
                          <ChipChangePercentage value={change} /> :
                          <Typography
                            className={classes.tableCellTitle}
                            style={{ fontWeight: '400' }}
                          >
                           n/a
                          </Typography>
                      }
                    </EmissionsStyledTableCell>
                    }
                </EmissionStyledTableRow>
                )}
              )}
          </TableBody>
        </Table>
    </TableContainer>
    <br />
    <h3>Intermediates*</h3>
    <br />
    <Typography>
    * {intl.formatMessage({id: "REPORT.EMISSIONS.DETAILS.EXPLANATION"})}
     </Typography>
    <br />
    {/* Used intermediates table */}
    <TableContainer style={{marginTop: '12px'}}>
        <Table aria-label="used intermediates table" className={classes.table}>
          <TableHead>
            <TableRow>
              <EmissionsStyledTableCell>
              {intl.formatMessage({id: "GENERAL.TYPE"})}
              </EmissionsStyledTableCell>
              <EmissionsStyledTableCell align={hasComparison ? 'left' : 'center'}>
              { hasComparison ?
                baselineDatasetName :
                intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.VALUE',
                })
              }
              </EmissionsStyledTableCell>
              { hasComparison &&
                <EmissionsStyledTableCell>
                  {comparisonDatasetName}
                </EmissionsStyledTableCell>
              }
              { hasComparison &&
                <EmissionsStyledTableCell align="center">
                  {intl.formatMessage({id: "REPORT.FOOTPRINTS.CHANGE"})}
                </EmissionsStyledTableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {emissionEntry.usedIntermediates &&
              (emissionEntry.usedIntermediates || []).map((usedIntermediate) => {
                const {value, comparedValue, change, type, unit} = usedIntermediate;
                return (
                <EmissionStyledTableRow key={`intermediates-${type}`}>
                  <EmissionsStyledTableCell
                    style={{ width: '50%'}}
                  >
                    <Box style={{ display: 'flex' }}>
                      <Typography
                        className={classes.tableCellTitle}
                        style={{ paddingLeft: '10px', fontWeight: '500' }}
                      >
                        {type}
                      </Typography>
                    </Box>
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell width="17%" align={hasComparison ? 'left' : 'center'}>
                    <Typography
                      className={classes.tableCellTitle}
                      style={{ fontWeight: '500' }}
                    >
                      { !Number.isNaN(Number(value)) ?
                          <ExactNumber value={Number(value)} /> :
                          value
                      }
                    </Typography>
                    <Typography
                        className={classes.tableCellDescription}
                        style={{ fontWeight: '400' }}
                      >
                        {unit}
                    </Typography>
                  </EmissionsStyledTableCell>
                    { hasComparison &&
                      <EmissionsStyledTableCell width="17%">
                        <Typography
                          className={classes.tableCellTitle}
                          style={{ fontWeight: '500' }}
                        >
                        { (comparedValue && !Number.isNaN(Number(comparedValue))) ?
                          <ExactNumber value={Number(comparedValue)} /> :
                          comparedValue
                        }
                      </Typography>
                      <Typography
                          className={classes.tableCellDescription}
                          style={{ fontWeight: '400' }}
                        >
                          {unit}
                      </Typography>
                    </EmissionsStyledTableCell>
                    }
                    { hasComparison &&
                      <EmissionsStyledTableCell align="center">
                        { (change && Number.isFinite(change) || change === 0) ?
                          <ChipChangePercentage value={change} /> :
                          <Typography
                            className={classes.tableCellTitle}
                            style={{ fontWeight: '400' }}
                          >
                          n/a
                          </Typography>
                        }
                      </EmissionsStyledTableCell>
                    }

                </EmissionStyledTableRow>
                )}
              )}
          </TableBody>
        </Table>
    </TableContainer>

    </DialogContainer>
  )
}

export default FootprintEmissionDialog;