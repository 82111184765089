import { FC, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { DialogContainer } from '../../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../../common';
import { PigFatteningBaseline, PigFatteningEmission } from '../../../../../models/Baseline';
import ReactHookDsmInput from '../../../../../../modules/Helpers/ReactHookDsmInput2';
import { processAndStageStylesV2 } from '../../../../../../../_metronic/layout';
import DsmButtonControlGroup from '../../../../helpers/DsmButtonControlGroup';


const PFEmissionsFormDialog: FC<BaselineDialogProps> = ({
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  formVisible=false
}) => {

  const intl = useIntl();
  const formContext = useFormContext();

  const classes = processAndStageStylesV2() as any;
  
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"}) ;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions`; 
  const welcomeString = intl.formatMessage({id: "EMISSIONS.WELCOME"});

  const currResetValue = useRef<PigFatteningEmission>();

  const handleResetClick = useCallback( ()=>{
    // console.log("resetValue emission:", currResetValue.current);
    if(currResetValue.current){
      const resetObject = {...formContext.getValues()} as PigFatteningBaseline;
      if(resetObject.stages[itemIndex]?.stageData?.emissions){
        resetObject.stages[itemIndex].stageData.emissions = {...currResetValue.current};
        formContext.reset(resetObject, { errors: true });
      }
    }
    
    if(handleCancel)  
      handleCancel('reset');
  }, [formContext, handleCancel, itemIndex]);

  useEffect(() => {
    if (formVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currResetValue.current = cloneDeep(formContext.getValues(fieldItemPrefix))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formVisible]);

  return (
    <DialogContainer
      formVisible = { formVisible }
      variant = 'wide'
      handleClose = { handleResetClick }
      iconCode="maps-travel/globe-04"
      formTitle = { formTitle }
      introText = { intl.formatMessage({id: "EMISSIONS.INTRO"}, {boldedWelcome: <strong>{welcomeString}</strong>, br: <br/>}) }
    > 
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing} >
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix  }.methaneEntericFermentation`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION"})}
          adornment="%"
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP"})}
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.methaneEntericFermentation`) as string}
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix  }.nitricOxide`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_MANURE.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitricOxide`) as string }
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix  }.methane`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.METHANE_METHANE.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.methane`) as string }
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix  }.nonMethaneVolatile`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NON_METHANE_ORGANIC"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nonMethaneVolatile`) as string}
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix  }.nitrousOxideDirect`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_DIRECT.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrousOxideDirect`) as string}
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix  }.PM10`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM10.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.PM10`)as string}
          
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix  }.nitrousOxideIndirect`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.NOx_INDIRECT.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrousOxideIndirect`) as string}
          
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix  }.PM25`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.PM2_5.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.PM25`) as string}
          
        />
        <ReactHookDsmInput
          type='number'
          name={`${fieldItemPrefix  }.amonia`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.AMMONIA"})}
          tooltip={intl.formatMessage({id:  "BASELINE.FORM.EMISSIONS.AMMONIA.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.amonia`) as string}
          
        />
        <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix  }.totalSuspendedParticles`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.TOTAL_SUSP_PARTICLES.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.totalSuspendedParticles`) as string }
          
        />
         <ReactHookDsmInput 
          type='number'
          name={`${fieldItemPrefix  }.ammoniaHousing`}
          label={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.AMMONIA_HOUSING"})}
          tooltip={intl.formatMessage({id: "BASELINE.FORM.EMISSIONS.AMMONIA_HOUSING.TOOLTIP"})}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.ammoniaHousing`) as string }
          
        />
      </DsmGrid>
      <DsmButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={ async () => { await formContext.trigger(`stages.${itemIndex}.stageData.emissions`); handleCancel('confirm') } }
            saveLabel={ intl.formatMessage({id: "GENERAL.CONFIRM" }) }
          />
	    </DialogContainer>
	);
};

export default PFEmissionsFormDialog;