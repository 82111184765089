import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { TransportMode } from '../../../../../graphql/types';
import { floatValueInUserUnits } from './inCommonMapperSustell';

// eslint-disable-next-line
const userPrefs = UserProfilePrefs.getInstance();
// eslint-disable-next-line
const userUOM = userPrefs.getUserUnitPrefs();
// eslint-disable-next-line
const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();


export const TRANSPORT_DISTANCE_UOM_MAP: { [key in TransportMode]: string } = {
  INLAND_SHIP: 'unitTransportDistanceInlandWaterways',
  SEA_SHIP: 'unitTransportDistanceSea',
  TRAIN: 'unitTransportDistanceTerrestrial',
  TRUCK: 'unitTransportDistanceTerrestrial',
};


export function formatInMassInput(input?: number | string): number {
  if (!input || Number.isNaN(Number(input))) return 0;
  return Number(
    floatValueInUserUnits(
      input,
      // eslint-disable-next-line
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      outputMassPrecision as number
    )
  );
}

export function formatInTerrestrialTransportInput(input: number | string): number {
  return Number(
    floatValueInUserUnits(
      input,
      // eslint-disable-next-line
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    )
  );
}

export function formatInTransportInput(
  input: number | string,
  transportMode: TransportMode
): number {
  return Number(
    floatValueInUserUnits(
      input,
      // eslint-disable-next-line
      userUOM[TRANSPORT_DISTANCE_UOM_MAP[transportMode]],
      // @ts-ignore eslint-disable-next-line
      defaultMetric[TRANSPORT_DISTANCE_UOM_MAP[transportMode]]
    )
  );
}

