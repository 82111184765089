import {
  Grid,
} from "@material-ui/core";
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import { BroilerBaseline } from '../../../../models/Baseline/BroilerBaseline';
import { BaselineDialogProps, FormType } from '../../common';

const BFeedsVariablePart: FC<Omit<BaselineDialogProps, 'handleCancel'>> = ({
  formType=FormType.Add ,
  itemIndex=0
}) =>  {

  const intl = useIntl();
  const formContext = useFormContext<BroilerBaseline>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  
  return (
    <>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.digestibility`}
          label= { intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.METABOLIZABLE_ENERGY"}) } 
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.METABOLIZABLE_ENERGY.TOOLTIP"}) } 
          addornment={ intl.formatMessage({ id: "ADDORNMENT.UNITS.PERCENT_OF_GA" })}
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.digestibility`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.nitrogenContent`}
          label={ intl.formatMessage({id:"BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT"}) }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id:"BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT.TOOLTIP"}) }
          addornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.nitrogenContent`)}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithInfo
          name={`${fieldItemPrefix  }.dryMatterContent`}
          type="number"
          label={ `${intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.DRY_MATTER_CONTENT" })} *` }
          margin="none"
          variant="outlined"
          tooltip={ intl.formatMessage({id: "BASELINE.FORM.BARN.RATION.DRY_MATTER_CONTENT.TOOLTIP" }) }
          addornment="%"
          disabled={formType === FormType.View}
          defaultValue = {formContext.getValues(`${fieldItemPrefix  }.dryMatterContent`)}
        />
      </Grid>
    </>
  );
};

export default BFeedsVariablePart;