import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';

import StageButtonBox from '../../StageButtonBox';
import BInterventionInputFormDialog from './BInterventionInputFormDialog';
import BInterventionManureFormDialog from './BInterventionManureFormDialog';
import BInterventionEmissionsFormDialog from './BInterventionEmissionsFormDialog';
import BInterventionOutputFormDialog from './BInterventionOutputFormDialog';
import InterventionFeedsFormDialog from '../CommonDataParts/InterventionFeedsFormDialog';
import BInterventionFeedsVariablePart from './BInterventionFeedsVariablePart';
import { InterventionStageProps } from '../CommonDataParts/InterventionTypes';
import {
  BroilerIntervention,
} from '../../../../models/Intervention';
import { BroilerBaseline } from '../../../../models/Baseline';

interface BroilerInterventionStageProps extends InterventionStageProps {
  intervention: BroilerIntervention | null;
  baseline: BroilerBaseline;
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  MANURE = 'manure',
  EMISSIONS = 'emissions',
  OUTPUT = 'output'
}

const BroilerInterventionStages = ({
  stageIndex,
  baseline,
  manureManagementSystems,
  singleIngredients,
  compoundFeeds,
  intervention,
  formType,
}: BroilerInterventionStageProps) => {

  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(null);

  const showDialog = (dialog: StageDialogType | null) => {
    setActiveDialog(dialog);
  };

  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.productionSystem`}
        value={`${baseline?.stages?.[stageIndex]?.productionSystem}`}
      />
      <BInterventionInputFormDialog
        formVisible={activeDialog === StageDialogType.INPUT}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <InterventionFeedsFormDialog
        formVisible={activeDialog === StageDialogType.FEED}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        intervention={intervention}
        formType={formType}
      >
        <BInterventionFeedsVariablePart
          stageIndex={stageIndex}
          baseline={baseline}
          formType={formType}
        />
      </InterventionFeedsFormDialog>
      <BInterventionManureFormDialog
        formVisible={activeDialog === StageDialogType.MANURE}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        manureManagementSystems={manureManagementSystems}
        formType={formType}
      />
      <BInterventionEmissionsFormDialog
        formVisible={activeDialog === StageDialogType.EMISSIONS}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <BInterventionOutputFormDialog
        formVisible={activeDialog === StageDialogType.OUTPUT}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="BROILER_INPUT"
          showCompletness={false}
          error={
            // this usafe assignment/access shouldn't be a problem as it represesnts a form hierachy
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="manureIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.manure
          }
          handleOpen={() => showDialog(StageDialogType.MANURE)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="BROILER"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        <StageButtonBox
          showCompletness={false}
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.emissions
          }
          handleOpen={() => showDialog(StageDialogType.EMISSIONS)}
        />
      </Grid>
    </>
  );
};

export default BroilerInterventionStages;