/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { cloneDeep } from 'lodash';
import { useEffect, useRef } from 'react';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { PoultryInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { PoultryBreedingStageData, PoultryIntervention, PoultryOutputBreeding as PoultryOutputInterventionBreeding } from '../../../../models/Intervention/PoultryIntervention';
import { PoultryOutputBreeding } from '../../../../models/Baseline/PoultryBaseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
}

const PoultryOutputBreedingDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PoultryInterventionFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const barnSmallQuantityUnits = unitLong(userUOM.unitBarnSmallQuantityUnits as string);
  const currResetValue = useRef<PoultryOutputInterventionBreeding>();
  const formTitle = intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',});
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData as PoultryBreedingStageData || {};
      if (stageData?.output) {
        stageData.output = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output as PoultryOutputBreeding;

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant='wide'
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridTwoColumnIntervention}>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <span style={headingTitle}>
              {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.HATCHING_TITLE'})}
            </span>
          </div>
          <DsmGrid className={classes.dsmGridOneColumnIntervention}>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalEggsToStage`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_TO_STAGE'})}
              type="number"
              tooltip=' '
              metricUnit=''
              baseline={output?.totalEggsToStage}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalEggsSold`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_EGGS_SOLD'})}
              type="number"
              tooltip=' '
              metricUnit=''
              precision={barnOutputMassPrecision}
              baseline={output?.totalEggsSold}
              disabled={formType === FormType.View}
            />
          </DsmGrid>
          <div>
            <div style={{marginBottom: "var(--dsm-spacing-px-4)", marginTop: "var(--dsm-spacing-px-4)"}}>
              <span style={headingTitle}>
                {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.HENS_TITLE'})}
              </span>
            </div>
            <DsmGrid className={classes.dsmGridOneColumnIntervention}>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.totalHensSold`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_HENS_SOLD"})}
                type="number"
                tooltip=' '
                metricUnit=''
                baseline={output?.totalHensSold}
                disabled={formType === FormType.View}
              />
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageHensWeight`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_HENS_WEIGHT"})}
                type="number"
                tooltip=' '
                metricUnit={barnOutputMassUnit}
                baseline={output?.averageHensWeight}
                disabled={formType === FormType.View}
              />
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.mortalityHensAtPercent`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.MORTALITY_HENS_RATE"})}
                type="number"
                tooltip=' '
                metricUnit='%'
                baseline={output?.mortalityHensAtPercent}
                disabled={formType === FormType.View}
              />
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.weightHensAtMortality`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.WEIGHT_HENS_AT_MORTALITY"})}
                type="number"
                tooltip=' '
                metricUnit={barnOutputMassUnit}
                baseline={output?.weightHensAtMortality}
                disabled={formType === FormType.View}
              />
           </DsmGrid>
          </div>
        </div>
        <div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)", height: '26px'}} />
          <DsmGrid className={classes.dsmGridOneColumnIntervention}>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageWeight`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_EGGS_WEIGHT'})}
              type="number"
              tooltip=' '
              metricUnit={barnSmallQuantityUnits}
              baseline={output?.averageWeight}
              disabled={formType === FormType.View}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.priceEggs`}
              label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE_EGGS'})}
              type="number"
              tooltip=' '
              metricUnit={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE_EGGS.PLACEHOLDER"})}
              precision={barnOutputMassPrecision}
              baseline={output?.priceEggs}
              disabled={formType === FormType.View}
            />
          </DsmGrid>
          <div>
            <div style={{marginBottom: "var(--dsm-spacing-px-4)", marginTop: "var(--dsm-spacing-px-4)"}}>
              <span style={headingTitle}>
                {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.ROOSTERS_TITLE'})}
              </span>
            </div>
            <DsmGrid className={classes.dsmGridOneColumnIntervention}>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.totalRoostersSold`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.TOTAL_ROOSTERS_SOLD"})}
                type="number"
                tooltip=' '
                metricUnit=''
                baseline={output?.totalRoostersSold}
                disabled={formType === FormType.View}
              />
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageRoostersWeight`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.AVERAGE_ROOSTERS_WEIGHT"})}
                type="number"
                tooltip=' '
                metricUnit={barnOutputMassUnit}
                baseline={output?.averageRoostersWeight}
                disabled={formType === FormType.View}
              />
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.mortalityRoostersAtPercent`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.MORTALITY_ROOSTERS_RATE"})}
                type="number"
                tooltip=' '
                metricUnit='%'
                baseline={output?.mortalityRoostersAtPercent}
                disabled={formType === FormType.View}
              />
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.weightRoostersAtMortality`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.WEIGHT_ROOSTERS_AT_MORTALITY"})}
                type="number"
                tooltip=' '
                metricUnit={barnOutputMassUnit}
                baseline={output?.weightRoostersAtMortality}
                disabled={formType === FormType.View}
              />
           </DsmGrid>
          </div>
        </div>
      </DsmGrid>
      <div style={{marginTop: "var(--dsm-spacing-px-4)"}}>
        <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
          <span style={headingTitle}>
            {intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.HENS_AND_ROOSTERS_TITLE'})}
          </span>
        </div>
        <div className={classes.additionalEntriesBlockHolder}>
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <DsmFieldset>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.price`}
                label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE"})}
                type="number"
                tooltip=' '
                metricUnit={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.BREEDING.PRICE.PLACEHOLDER"})}
                baseline={output?.price}
                disabled={formType === FormType.View}
              />
            </DsmFieldset>
          </DsmGrid>
        </div>
      </div>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryOutputBreedingDialog;
