/**
 * For production process find and return active baseline and archived baselines sorted by last
 * changed date
 * @param {*} animalSystem
 * @returns [activeBaseline, archivedBaselines]
 */
// eslint-disable-next-line import/prefer-default-export
export const organiseBaselines = animalSystem => {
  if (!animalSystem.baselines) {
    throw new Error(
      'There are no baselines inside prod process: ',
      animalSystem
    );
  }
  const baselines = [...animalSystem.baselines];

  baselines.forEach(baseline => {
    // set lastModified on all baselines for the ease of comparisson
    if (baseline.updatedAt) {
      baseline.updatedAt instanceof Date
        ? (baseline.lastModified = baseline.updatedAt)
        : (baseline.lastModified = new Date(baseline.updatedAt));
    } else {
      baseline.createdAt instanceof Date
        ? (baseline.lastModified = baseline.createdAt)
        : (baseline.lastModified = new Date(baseline.createdAt));
    }

    // set interventions for each baseline
    baseline.interventions = animalSystem?.interventions?.items?.filter(item => item.baseline === baseline?.id )
  });

  const sortedBaselines = baselines.sort(
    (baseline_a, baseline_b) =>
      baseline_b.validFrom - baseline_a.validFrom ||
      baseline_b.lastModified - baseline_a.lastModified
  );

  const today = new Date();

  const currentBaselineIndex = sortedBaselines.findIndex(
    baseline => baseline.validFrom - today > 0
  );

  let activeBaseline = null;
  if (currentBaselineIndex < 0) {
    console.warn(
      'No current baseline, seting the first in sorted array as current'
    );
    activeBaseline = sortedBaselines.splice(0, 1)[0];
  } else {
    activeBaseline = sortedBaselines.splice(currentBaselineIndex, 1)[0];
  }

  return [activeBaseline, sortedBaselines];
};
