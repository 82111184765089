import { FC } from 'react';
import { FormType } from './types';
import Resources from '../FarmFlow/CommonDataParts/v2.0/Resources';
import * as baseLineformType from '../FarmFlow/common';
import { CFDatabaseFoundation } from '../../models/CompoundFeed';
import { getDatabase } from '../../utils/compound-feed-utils';

interface OnSiteActivitiesProps {
  formType: FormType;
  databaseFoundation: CFDatabaseFoundation | undefined
}

const OnSiteActivities: FC<OnSiteActivitiesProps> = ({ formType, databaseFoundation }) => (
    <div style={{ marginTop: `var(--dsm-spacing-px-4)` }}>
      <Resources
        formType={formType as unknown as baseLineformType.FormType}
        databaseFoundation={getDatabase(databaseFoundation)}
        inputType="on_site_activities_input"
      />
    </div>
);

export default OnSiteActivities;
