import {
  AnimalType,
  ReportingPeriod,
  Maybe,
  WaterSourceType,
  CompoundFeedDatabase,
} from '../../../../graphql/types';

// can be string in form of number (i.e. '1') or ''
export type BaselinePropertyValue = number | string;

export type AnimalSourceOrigin = 'BACKGROUND_DATA' | 'PRODUCTION_SYSTEM';

export interface BaselineFeed {
  feedType: string;
  kgPerAnimal: BaselinePropertyValue;
  isSilage?: boolean
}

export interface BaselineFeedWithOrigin extends BaselineFeed {
  origin: string;
  id: string;
}

export enum TransportType {
  DIESEL = 'diesel',
  ELECTRICITY = 'electricity',
  NONE = '',
}

export interface BaselineTransport {
  type: TransportType;
  amount: BaselinePropertyValue;
  boatName: string;
}

export interface BaselineDynamicItem {
  type: string;
  amount: BaselinePropertyValue;
}

export interface Info {
  name: string;
  oldName: string
  year: number | '';
  timeUnit: ReportingPeriod;
  numOfCyclesYear: number | '';
  validFrom: Date | string;
  validTo: Date | string;
  description: string;
  databaseFoundation: CompoundFeedDatabase;
}

export interface ResourceUse {
  electricityUse?: Maybe<BaselinePropertyValue>;
  dieselUse?: Maybe<BaselinePropertyValue>;
  gasUse?: Maybe<BaselinePropertyValue>;
  waterUse?: Maybe<BaselinePropertyValue>;
  waterSource: Maybe<WaterSourceType> | '';
}

// export interface ResourceUseV2 extends ResourceUse {
//   coalUse?: Maybe<BaselinePropertyValue>;
//   gasolineUse?: Maybe<BaselinePropertyValue>;
//   propaneOrLpgUse?: Maybe<BaselinePropertyValue>;
//   electricityPhotovoltaicUse?: Maybe<BaselinePropertyValue>;
//   electricityWindUse?: Maybe<BaselinePropertyValue>;
// }

interface ResourceItem {
  type: string;
  value: BaselinePropertyValue;
}
export interface ResourceUseV2 {
  electricityUse?: Maybe<BaselinePropertyValue>;
  dieselUse?: Maybe<BaselinePropertyValue>;
  waterUse?: Maybe<BaselinePropertyValue>;
  gasUse?: Maybe<BaselinePropertyValue>;
  waterSource: Maybe<WaterSourceType> | '';
  selfGeneratedRenewables: ResourceItem[];
  energyTypes: ResourceItem[];
}

export interface Baseline {
  animalType: AnimalType;
  copyFrom: string;
  info: Info;
  resourceUse: Maybe<ResourceUse>;
}

export interface BaselineV2 {
  animalType: AnimalType;
  copyFrom: string;
  info: Info;
  resourceUse: Maybe<ResourceUseV2>;
}

export interface Stage {
  farmId: string;
  name: string;
  id: string;
  productionSystem: string;
  type?: Maybe<string>;
}

export interface CommonFeed {
  totalFeedIntake?: Maybe<BaselinePropertyValue>;
  compoundFeeds?: Maybe<BaselineFeed[]>;
  singleIngredients?: Maybe<BaselineFeed[]>;
}

// needed for common feed part

export interface GenericStageData {
  feed: CommonFeed;
}

export interface GenericStage extends Stage {
  stageData: GenericStageData;
}

export interface GenericBaseline extends Baseline {
  stages: GenericStage[];
}
