import { Box,Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
      width: "100%",
      textOverflow:"ellipsis",
      overflow:"hidden",
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 18,
      '&:hover': {
        background: "#e8e8e8",
        cursor: "pointer",
    },
   },
});

export default function BaseNotificationDropdownCard({title, message, onClick}) {
  const classes = useStyles();

  function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
  }

  return (
    <Box component="div" className={classes.root} onClick={onClick}>
         <Typography variant="h6">
            {title}
        </Typography>
        <Box component="div" style={{     
            lineHeight: '1.5em',
            height: "3em",    
            overflow: "hidden",
            textOverflow: "ellipsis"
        }} >
         {removeTags(message)}
        </Box>
    </Box>
  );
}
