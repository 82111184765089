import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  DsmButton,
  DsmFieldset,
  DsmGrid,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { PigInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { FormType } from '../../common';
import {
  PigIntervention,
  PigHousingV2,
  PigStageDataFattening,
  PigStageDataBreeding,
  ManureManagementSystemIntervention,
} from '../../../../models/Intervention';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
  BeddingType,
  ManureForm,
  ManureManagementSystemType,
  MaterialType,
  SurfaceType,
} from '../../../../../../graphql/generated/blonk/pigs';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import RowSelectWithMetricsV2 from '../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  ManureManagementSystem,
  PigManure,
  PigStageData
} from '../../../../models/Baseline/PigBaseline';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import { getSingleEnumEntryTranslation } from '../../../../utils/translation-utils';
import InterventionMMSBlockComponent from './InterventionMMSBlockComponent';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import MMSBlockComponent from '../../Baseline/PigFattening/v2.0/MMSBlockComponent';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import MMSDescriptionDialog from '../../Baseline/PigFattening/v2.0/MMSDescriptionDialog';

interface ListEntry {
  value: string;
  text: string;
}

type ReactChangedType = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const getBaseTypeForMonthEndingMMS = (mms: string) => {
  let result = mms;
  const matches = mms.match(/(_OVER|_UNDER)?_(\d){1,}MONTH$/);
  if (matches) {
    result = mms.substring(0, matches.index);
  }
  return result;
};

const InterventionHousingManureFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PigInterventionFormDialogProps) => {
  const intl = useIntl();
  const manure = (baseline?.stages?.[stageIndex]?.stageData as PigStageData)
    ?.housing as PigManure;

  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE' },
    { br: ' ' }
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;
  const [baselineMMS, setBaselineMMS] = useState(manure.manureSystems);

  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);


  const currResetValue = useRef<PigManure>();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.housing`;
  const formContext = useFormContext<PigIntervention>();
  const { control } = formContext;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass as string;

  const {
    fields: mmsSystemsAdditions,
    append: appendMMSAdditions,
    remove: removeMMSAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.manureSystemsAdditions`,
    control,
    keyName: 'keyId',
  });

  const {
    fields: beddingSystemsAdditions,
    append: appendBeddingAdditions,
    remove: removeBeddingAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.beddingSystemsAdditions`,
    control,
    keyName: 'keyId',
  });

  const {
    fields: materialsAdditions,
    append: appendMaterialAdditions,
    remove: removeMaterialAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.materialsAdditions`,
    control,
    keyName: 'keyId',
  });
  const [, forceUpdate] = useState({});

  const [activeList, setActiveList] = useState<Array<ListEntry>>([]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as PigIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.housing) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        stageData.housing = { ...currResetValue.current };
        formContext.reset(resetObject, {
          errors: true,
        });
      }
    }

    if (handleCancel) handleCancel();

    // validate no mms error
    (async () => formContext.trigger(fieldItemPrefix))()
      .then(() => {})
      .catch(() => {});
  };

  const handleConfirmClick = () => {
    if (handleCancel) handleCancel();

    // trigger validation for dialog, to remove/show errors
    // validate no mms error
    (async () => formContext.trigger(fieldItemPrefix))()
      .then(() => {})
      .catch(() => {});
  };

  // sets share to 100% if for the given kinde (solid or slurry) only one left
  const setShare = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const curentMMS = (formContext.getValues(
      `stages.${stageIndex}.stageData.housing.manureSystems`
    ) || []) as ManureManagementSystemIntervention[];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const additionMMS = (formContext.getValues(
      `stages.${stageIndex}.stageData.housing.manureSystemsAdditions`
    ) || []) as ManureManagementSystem[];

    const solidBaseline = curentMMS?.filter(
      (item) => item.localManureForm === ManureForm.Solid
    );
    const solidAddition = additionMMS?.filter(
      (item) => item.localManureForm === ManureForm.Solid
    );
    // if only one solid addition, set share to 100
    if (!solidBaseline?.length && solidAddition?.length === 1) {
      const firstIndex = additionMMS?.findIndex(
        (item) => item.localManureForm === ManureForm.Solid
      );
      formContext.setValue(
        `stages.${stageIndex}.stageData.housing.manureSystemsAdditions.${firstIndex}.share`,
        100
      );
    }

    const liquidBaseline = curentMMS?.filter(
      (item) => item.localManureForm === ManureForm.LiquidSlurry
    );
    const liquidAdition = additionMMS?.filter(
      (item) => item.localManureForm === ManureForm.LiquidSlurry
    );
    // if only one, set share to 100
    if (!liquidBaseline?.length && liquidAdition?.length === 1) {
      const firstIndex = additionMMS?.findIndex(
        (item) => item.localManureForm === ManureForm.LiquidSlurry
      );
      formContext.setValue(
        `stages.${stageIndex}.stageData.housing.manureSystemsAdditions.${firstIndex}.share`,
        100
      );
    }
  };

  const removeMMSHandler = (index: number) => {
    removeMMSAdditions(index);

    setShare();

    // validate no mms error
    (async () => formContext.trigger(fieldItemPrefix))()
      .then(() => {})
      .catch(() => {});
  };

  const mms = enumToOptionsArrayWithTranslatedStrings(
    ManureManagementSystemType,
    intl,
    'SUSTELL.PIGS.ENUMS.MMS'
  );

  const getFilteredList = (entries: ListEntry[]) => {
    const uniqueMMS: ListEntry[] = entries.reduce(
      (accumulator: ListEntry[], current: ListEntry) => {
        if (!accumulator.find((item) => item.value === current.value)) {
          accumulator.push(current);
        }
        return accumulator;
      },
      []
    );
    return uniqueMMS;
  };

  const filteredMMSList = getFilteredList(
    mms.map((item) => {
      const base = getBaseTypeForMonthEndingMMS(item.value);
      return {
        value: base,
        text:
          base === item.value
            ? item.text
            : getSingleEnumEntryTranslation(
                base,
                intl,
                'SUSTELL.PIGS.ENUMS.MMS'
              ),
      };
    })
  );

  const onSurfaceChange = () => {
    const value =
      formContext.getValues(`${fieldItemPrefix}.surfaceType`) ||
      SurfaceType.SlattedFloor;
    let newFilteredList: Array<ListEntry> = [];
    if (value === SurfaceType.DeepBedding)
      newFilteredList = filteredMMSList?.filter((item) =>
        item.value.startsWith('DEEP_BEDDING')
      );
    if (value === SurfaceType.SlattedFloor || value === SurfaceType.SolidFloor)
      newFilteredList = filteredMMSList?.filter(
        (item) => !item.value.startsWith('DEEP_BEDDING')
      );

    setActiveList(newFilteredList);
  };

  // When surface type is changed, the baseline manure data is deleted,
  // however old (baseline) form values for manure are kept and submited
  // this reset localManureForm, mmsHoldingDuration mmsType and share to prevent saving them with intervention
  // out mapper will check for this stuff
  const resetBaselineManureFormValues = () => {
    const formVals = formContext.getValues();
    if (formVals?.stages?.[stageIndex]?.stageData?.housing?.manureSystems) {
      const stageData: PigStageDataFattening | PigStageDataBreeding =
        formVals.stages[stageIndex].stageData!;
      if (stageData.housing) {
        stageData.housing.manureSystems = [];
        formContext.reset(formVals);
      }
    }
  };

  // On load of baseline, compare housing type of intervention and baseline
  //  to set proper data => baselines or intervention ones
  // if surface type different, no need to show baseline's manure system, it will not be used any more
  useEffect(() => {
    const interventionSurface = formContext.getValues(
      `${fieldItemPrefix}.surfaceType`
    );
    const baselineSurface =
      (baseline?.stages?.[stageIndex]?.stageData as PigStageData).housing?.surfaceType;

    if (
      interventionSurface &&
      baselineSurface &&
      interventionSurface !== baselineSurface
    ) {
      setBaselineMMS([]);
      // reset form data for manure systems, otherwise data will stay in submit
      resetBaselineManureFormValues();
    } else {
      setBaselineMMS(manure.manureSystems);
    }

    // trigger available MMS types change
    onSurfaceChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline]);

  useEffect(() => {
    // if value not set for intervention, get it from the baseline, and set it
    const surfaceType = formContext.getValues(`${fieldItemPrefix}.surfaceType`);
    if (!surfaceType) {
      formContext.setValue(
        `${fieldItemPrefix}.surfaceType`,
        (baseline?.stages?.[stageIndex]?.stageData as PigStageData)?.housing?.surfaceType
      );
    }

    const manureForm = formContext.getValues(`${fieldItemPrefix}.manureForm`);
    if (!manureForm)
      formContext.setValue(
        `${fieldItemPrefix}.manureForm`,
        (baseline?.stages?.[stageIndex]?.stageData as PigStageData)?.housing?.manureForm
      );

    onSurfaceChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline, stageIndex]);

  // On changing housing type remove all additions and also if
  // selected housing type is different then baseline remove baseline intervention fields
  const housingChanged = (event: ReactChangedType) => {
    const interventionSurface = event.target.value;
    const baselineSurface =
      (baseline?.stages?.[stageIndex]?.stageData as PigStageData).housing?.surfaceType;

    const allIndexes = (mmsSystemsAdditions || []).map((_el, i) => i);
    removeMMSAdditions(allIndexes);

    if (interventionSurface !== baselineSurface) {
      setBaselineMMS([]);
      // reset form data for manure systems, otherwise data will stay in submit
      resetBaselineManureFormValues();
    } else {
      setBaselineMMS(manure.manureSystems);
    }

    // trigger available MMS types change
    onSurfaceChange();

    // trigger vaildation to avoid problem that first clic on add manure will not work after changing housing
    (async () => formContext.trigger(`${fieldItemPrefix}.surfaceType`))()
      .then(() => {})
      .catch(() => {});

  };

  const mmsChanged = () => {
    // validate no mms error
    // (async () => formContext.trigger(`${fieldItemPrefix}.surfaceType`))()
    //   .then(() => {})
    //   .catch(() => {});

    // validate no mms error
    (async () => formContext.trigger(fieldItemPrefix))()
      .then(() => {})
      .catch(() => {});
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classBorderBeddings =
    beddingSystemsAdditions.length > 1
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ? classes.additionalEntriesBlockHolder
      : '';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // const classBorderMaterials =  materialsAdditions.length > 1 ? classes.additionalEntriesBlockHolder : '';

  const getTypeLabel = (
    type: BeddingType | MaterialType,
    materials = false
  ) => {
    if (!materials) {
      return (
        enumToOptionsArrayWithTranslatedStrings(
          BeddingType,
          intl,
          'SUSTELL.PIGS.ENUMS.BEDDING_TYPE'
        )?.find((item) => item.value === type)?.text || 'unknown'
      );
    }
    return (
      enumToOptionsArrayWithTranslatedStrings(
        MaterialType,
        intl,
        'SUSTELL.PIGS.ENUMS.MATERIAL_TYPE'
      )?.find((item) => item.value === type)?.text || 'unknown'
    );
  };

  const materialsWithEmptyOption = () => {
    const materialOptions = enumToOptionsArrayWithTranslatedStrings(
      MaterialType,
      intl,
      'SUSTELL.PIGS.ENUMS.MATERIAL_TYPE'
    );
    const defaultOption = { text: 'No material', value: '' };
    return [defaultOption].concat(materialOptions);
  };

  const emptyMaterialSelected = (index: number) =>
    !formContext.getValues(
      `${fieldItemPrefix}.materialsAdditions[${index}].materialType`
    );

  const resetMaterialAmount = (index: number) => {
    formContext.setValue(
      `${fieldItemPrefix}.materialsAdditions[${index}].materialAmount`,
      '',
      { shouldValidate: true }
    );
  };

  const materialsChanged = (e: ReactChangedType, index: number) => {
    forceUpdate({}); // Unfortunately the only consistent way to rerender the disabling of the useFieldArray parts. useWatch would not update on every change if initial value
    if (!e.target.value) resetMaterialAmount(index);
  };

  const beddingsWithEmptyOption = () => {
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      'SUSTELL.PIGS.ENUMS.BEDDING_TYPE'
    );
    const defaultOption = { text: 'No bedding', value: '' };
    return [defaultOption].concat(beddings);
  };

  const emptyBeddingSelected = (index: number) =>
    !formContext.getValues(
      `${fieldItemPrefix}.beddingSystemsAdditions[${index}].beddingType`
    );

  const resetBeddingAmount = (index: number) => {
    formContext.setValue(
      `${fieldItemPrefix}.beddingSystemsAdditions[${index}].beddingAmount`,
      '',
      { shouldValidate: true }
    );
  };

  const beddingsChanged = (e: ReactChangedType, index: number) => {
    forceUpdate({});
    if (!e.target.value) resetBeddingAmount(index);
  };

  const addAdditionMMS = () => {
    appendMMSAdditions({
      id: uuidv4(),
      mmsType: '',
      mmsHoldingDuration: '',
      share: '',
    });
  }
  
  const showDescription = () => {
    setOpenDescriptionDialog(true);
  };

  const closeDescriptionDialog = () => {
    setOpenDescriptionDialog(false);
  };

  useEffect(() => {
    if (formVisible && baseline) {
      if (!formContext.getValues(fieldItemPrefix)) {
        currResetValue.current = {
          surfaceType: manure?.surfaceType,
          manureSystems: manure?.manureSystems,
          Nreplacement: manure.Nreplacement,
          Preplacement: manure.Preplacement,
          manureForm: manure.manureForm,
          beddingSystems: manure.beddingSystems,
          materials: manure.materials
        };
      } else {
        currResetValue.current = cloneDeep(formContext.getValues(fieldItemPrefix));
      }
      // validate for MMS, some errors are noti visible on open is some cases
      (async () => formContext.trigger(fieldItemPrefix))()
        .then(() => {})
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, baseline]);

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="wide"
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      introText = { intl.formatMessage({id: "SUSTELL.PROCESS.DIALOG.STAGE.MANURE.INTRO"}) }
    >
      {openDescriptionDialog && (
        <MMSDescriptionDialog
          open
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      <DsmGrid className={classes.dsmGridTwoColumn}>
      <input
          ref={formContext.register()}
          type="hidden"
          name={`${fieldItemPrefix}.manureForm_old`}
          value={(baseline?.stages?.[stageIndex]?.stageData as PigStageData).housing?.manureForm}
        />
        <input
          ref={formContext.register()}
          type="hidden"
          name={`${fieldItemPrefix}.surfaceType_old`}
          value={(baseline?.stages?.[stageIndex]?.stageData as PigStageData).housing?.surfaceType}
        />
        {/* <input hidden name={`${fieldItemPrefix}.surfaceType`} /> */}
        <RowSelectWithMetricsV2
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.MANURE.SURFACE_TYPE',
          })}
          name={`${fieldItemPrefix}.surfaceType`}
          disabled={formType === FormType.View}
          // items={manureManagementSystems}
          options={enumToOptionsArrayWithTranslatedStrings(
            SurfaceType,
            intl,
            'SUSTELL.PIGS.ENUMS.SURFACE_TYPE'
          )}
          baseline={manure?.surfaceType}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.SURFACE_TYPE.TOOLTIP',
          })}
          changeHandler={housingChanged}
        />
      </DsmGrid>
      {baselineMMS &&
        baselineMMS?.map((item, index: number) => (
          <InterventionMMSBlockComponent
            // eslint-disable-next-line react/no-array-index-key
            key={`baselineMMs${index}`}
            filteredMMSList={activeList}
            manureManagementSystems={mms}
            formType={formType}
            stageIndex={stageIndex}
            itemIndex={index}
            manureSystem={item}
          />
        ))}
      <br />
      {mmsSystemsAdditions &&
        mmsSystemsAdditions?.map((item, index: number) => (
          <MMSBlockComponent
            key={item.keyId}
            filteredMMSList={activeList}
            manureManagementSystems={mms}
            formType={formType}
            stageIndex={stageIndex}
            itemIndex={index}
            removeHandler={removeMMSHandler}
            mmsChangeHandler={mmsChanged}
            isAdditions
          />
        ))}
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={addAdditionMMS}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_MMS',
          })}
        </DsmButton>
        <DsmButton variant="text" onClick={showDescription}>
          <DsmIcon slot="before" name="general/eye" />
          {intl.formatMessage({
            id: 'DATABASE_FOUNDATION_DEFINITIONS',
          })}
        </DsmButton>
      </DsmGrid>

      <DsmGrid className={classes.dsmGridOneColumn}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.Nreplacement`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.N_REPLACEMENT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.N_REPLACEMENT.TOOLTIP',
          })}
          metricUnit={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.N_REPLACEMENT.UNIT',
          })}
          baseline={manure?.Nreplacement}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
      <br />
      <DsmGrid className={classes.dsmGridOneColumn}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.Preplacement`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.P_REPLACEMENT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.P_REPLACEMENT.TOOLTIP',
          })}
          metricUnit={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.P_REPLACEMENT.UNIT',
          })}
          baseline={manure?.Preplacement}
          disabled={formType === FormType.View}
        />
      </DsmGrid>

      {manure.beddingSystems.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <DsmGrid
          className={classes.dsmGridOneColumn}
          key={`beddingSystems_${i}`}
        >
          <br />
          <input
            type="hidden"
            value={item.beddingType}
            ref={formContext.register()}
            name={`${fieldItemPrefix}.beddingSystems[${i}].beddingType`}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.beddingSystems[${i}].beddingAmount`}
            label={`${intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING_TYPE',
            })} - ${getTypeLabel(item.beddingType)}`}
            metricUnit={barnOutputMassUnit}
            baseline={item?.beddingAmount}
            disabled={formType === FormType.View}
          />
        </DsmGrid>
      ))}

      <div style={{ paddingTop: 'var(--dsm-spacing-px-4' }}>
        {beddingSystemsAdditions &&
          beddingSystemsAdditions?.map((item, index) => (
            <div className={classBorderBeddings} key={item.keyId}>
              {formType !== FormType.View &&
                removeBeddingAdditions &&
                beddingSystemsAdditions.length > 0 && (
                  <DsmButton
                    variant="text"
                    style={{ position: 'relative', width: '100%' }}
                    onClick={() => removeBeddingAdditions(index)}
                  >
                    <DsmIcon
                      name="general/x-close"
                      style={{
                        position: 'absolute',
                        color: 'var(--dsm-color-neutral-darker',
                        right: '0',
                      }}
                    />
                  </DsmButton>
                )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING_TYPE',
                  })}
                  name={`${fieldItemPrefix}.beddingSystemsAdditions[${index}].beddingType`}
                  options={beddingsWithEmptyOption()}
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.beddingSystemsAdditions[${index}].beddingType`
                  )}
                  disabled={formType === FormType.View}
                  displayEmpty
                  changeHandler={(e: ReactChangedType) =>
                    beddingsChanged(e, index)
                  }
                />
                <div>
                  <DsmFieldset>
                    <ReactHookDsmInput
                      label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING.AMOUNT',
                      })}
                      name={`${fieldItemPrefix}.beddingSystemsAdditions[${index}].beddingAmount`}
                      adornment={barnOutputMassUnit}
                      type="number"
                      defaultValue={formContext.getValues(
                        `${fieldItemPrefix}.beddingSystemsAdditions[${index}].beddingAmount`
                      )}
                      disabled={
                        formType === FormType.View ||
                        emptyBeddingSelected(index)
                      }
                    />
                    <div />
                  </DsmFieldset>
                </div>
              </DsmGrid>
            </div>
          ))}
      </div>

      <br />
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={() => {
            appendBeddingAdditions({
              beddingType: '',
              beddingAmount: '',
            });
          }}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_BEDDING',
          })}
        </DsmButton>
      </DsmGrid>

      {(manure?.materials || []).map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <DsmGrid className={classes.dsmGridOneColumn} key={`materials_${i}`}>
          <br />
          <input
            type="hidden"
            value={item.materialType}
            ref={formContext.register()}
            name={`${fieldItemPrefix}.materials[${i}].materialType`}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.materials[${i}].materialAmount`}
            label={`${intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.HOUSING.CLEANING_MATERIAL',
            })} - ${getTypeLabel(item.materialType, true)}`}
            metricUnit={barnOutputMassUnit}
            baseline={item?.materialAmount}
            disabled={formType === FormType.View}
          />
        </DsmGrid>
      ))}

      <div style={{ paddingTop: 'var(--dsm-spacing-px-4' }}>
        {materialsAdditions &&
          materialsAdditions?.map((item, index) => (
            <div className={classBorderBeddings} key={item.keyId}>
              {formType !== FormType.View && materialsAdditions.length > 0 && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => removeMaterialAdditions(index)}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.PIGS.HOUSING.CLEANING_MATERIAL',
                  })}
                  name={`${fieldItemPrefix}.materialsAdditions[${index}].materialType`}
                  disabled={formType === FormType.View}
                  options={materialsWithEmptyOption()}
                  defaultValue={formContext.getValues(
                    `${fieldItemPrefix}.materialsAdditions[${index}].materialType`
                  )}
                  displayEmpty
                  changeHandler={(e: ReactChangedType) =>
                    materialsChanged(e, index)
                  }
                />
                <div>
                  <DsmFieldset>
                    <ReactHookDsmInput
                      label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.PIGS.HOUSING.CLEANING_MATERIAL.AMOUNT',
                      })}
                      name={`${fieldItemPrefix}.materialsAdditions[${index}].materialAmount`}
                      adornment={barnOutputMassUnit}
                      type="number"
                      defaultValue={formContext.getValues(
                        `${fieldItemPrefix}.materialsAdditions[${index}].materialAmount`
                      )}
                      disabled={
                        formType === FormType.View ||
                        emptyMaterialSelected(index)
                      }
                    />
                    <div />
                  </DsmFieldset>
                </div>
              </DsmGrid>
            </div>
          ))}
      </div>

      <br />
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={() => {
            appendMaterialAdditions({
              materialType: '',
              materialAmount: '',
            });
          }}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_MATERIAL',
          })}
        </DsmButton>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleConfirmClick}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default InterventionHousingManureFormDialog;
