/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { getCustomer, listCustomers } from '../../../graphql/queries';
import paginateQuery from '../../sustell_15/helpers/paginate';
import { UserProfilePrefs } from '../../modules/Helpers/UserProfilePrefs';

export const fetchCustomersAndSelectDefaultOrFirst = createAsyncThunk(
  'selectedCustomer/fetchCustomersAndSelectDefaultOrFirst',
  async () => {
    try {
      const params = {
        query: listCustomers,
      };
      const result = await paginateQuery(params, 'listCustomers');
      if (!result[0]) {
        throw new Error('No customers loaded');
      }

      // If the user has access to more than one customer, check if a default customer is set
      if (result.length > 1) {
        const userProfile = UserProfilePrefs.getInstance();
        const defaultCustomer = userProfile.getDefaultCustomerPrefs();
        if (defaultCustomer) {
          // Default customer found, check if listCustomers contains the default customer and select
          const foundDefaultCustomer = result.find(
            (c) => c.id === defaultCustomer
          );
          if (foundDefaultCustomer) return foundDefaultCustomer;
        }
      }

      // User does not has access to multiple customers or no default customer set, return first customer
      return result[0];
    } catch (err) {
      console.log('error: thunks fun 2', err);
      throw new Error('Error fetching customers');
    }
  }
);

const initialState = {
  value: {},
  loading: false,
};

export const selectedCustomerSlice = createSlice({
  name: 'selectedCustomer',
  initialState,
  reducers: {
    setSelectedCustomer: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // const {id, feedsVisible, name} = action.payload; // action.payload expect loaded customer
      // console.log('setse', action);
      state.value = action.payload;
      state.loading = false;
      state.error = false;
    },
    resetSelectedCustomer: (state, aciton) => {
      state.value = {};
      state.loading = false;
      state.error = false;
    },
    setError: (state, action) => {
      state.value = {};
      state.loading = false;
      state.error = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersAndSelectDefaultOrFirst.pending, (state) => {
      // console.log('pending, ', state);
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      fetchCustomersAndSelectDefaultOrFirst.fulfilled,
      (state, { payload }) => {
        // console.log('fulfilled, ', state, payload);
        state.loading = false;
        state.value = payload;
        state.error = false;
      }
    );
    builder.addCase(fetchCustomersAndSelectDefaultOrFirst.rejected, (state) => {
      // console.log('rejected, ', state);
      state.value = {};
      state.loading = false;
      state.error = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedCustomer, resetSelectedCustomer, setError } =
  selectedCustomerSlice.actions;

export default selectedCustomerSlice.reducer;

// ** Thunk function - use case: (url is directly pasted into browser and the state doesn't match the customerID from url
// ** getCustomer -> set selected customer state -> getState)
export const fetchAndSetSelectedCustomer =
  (customerId) => (dispatch, getState) => {
    // console.log(customerId, uuid.validate(customerId))
    if (!customerId || customerId === 'undefined') {
      console.log('No customer id provided'); // TODO implement redirect, and check if valid uuid
      return;
    }

    const state = getState();
    if (state?.selectedCustomer?.errorKey) {
      console.log('err exist: ', state?.selectedCustomer?.errorKey);
      return;
    }

    if (customerId && state?.selectedCustomer?.value?.id === customerId) {
      console.log('Customer is the same, no resaon to fetch', customerId);
      return;
    }

    API.graphql({
      query: getCustomer,
      variables: {
        id: customerId,
      },
    })
      .then((result) => {
        if (result.data.getCustomer) {
          dispatch(setSelectedCustomer(result.data.getCustomer));
        } else {
          dispatch(setError());
        }
      })
      .catch((err) => {
        console.log('error: thunks fun', err);
        dispatch(setError());
      });
  };
