/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { defaultUnitsV2 as defaultMetric, explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';
import { setInterventionFloatValue } from './outCommonMapperSustell';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { OUTPUT_PACKAGING_PROPS, TRANSPORT_DISTANCE_UOM_MAP } from '../../Baseline/mapper/outProcessingMapperSustell';

const mapProcessingInput = (formInputData, stageInputObject) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const stageInputData = stageInputObject;

  stageInputData.originStageId = formInputData.originStageId;

  setInterventionFloatValue(
    stageInputData,
    'quantity',
    formInputData,
    'quantity'
  );

  stageInputData.transport = [];
  stageInputData.transportAdditions = (formInputData.transportAdditions || [])
    .filter(item => item.transportMode && item.distance)
    .map(item => ({
      transportMode: item.transportMode,
      distance: explicitConvertValue(
        item.distance,
        userUOM[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]],
        defaultMetric[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]]
      )
  }));

  if (formInputData.transport?.length) {
    formInputData.transport
      .filter((item) => item.distance && item.transportMode)
      .forEach((item) => {
        const newItem = {transportMode: item.transportMode, distance: null};

        setInterventionFloatValue(
          newItem,
          'distance',
          item,
          'distance', 
          userUOM[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]],
          defaultMetric[TRANSPORT_DISTANCE_UOM_MAP[item.transportMode]]
        );

        stageInputData.transport.push(newItem);
      }
    );
  }

  return stageInputData;
}

const mapProcessingOutput = (formOutputData, dbOutputData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  
  // process each property from OUTPUT_PACKAGING_PROPS
  OUTPUT_PACKAGING_PROPS.forEach((prop) => {
    if (formOutputData?.[prop]) {
      dbOutputData[prop] = {};
      if (
        formOutputData?.[prop]?.weight ||
        formOutputData?.[prop]?.weight === 0
      ) {
        setInterventionFloatValue(
          dbOutputData[prop],
          'weight',
          formOutputData?.[prop],
          'weight', 
          // TODO: later set conversion for prices
          // userUOM.unitBarnOutputMass,
          // defaultMetric.unitBarnOutputMass
        );
      }

      if (
        formOutputData?.[prop]?.price ||
        formOutputData?.[prop]?.price === 0
      ) {
        setInterventionFloatValue(
          dbOutputData[prop],
          'price',
          formOutputData?.[prop],
          'price'
        );
      }

      // if empty, delete it
      if (!Object.keys(dbOutputData[prop])?.length) delete dbOutputData[prop];

    }
  });
}

const interventionPackagingIsEmpty = (formPackagingForOutputCategory) => (
  !formPackagingForOutputCategory?.primary?.length &&
  !formPackagingForOutputCategory?.secondary?.length &&
  !formPackagingForOutputCategory?.tertiary?.length &&
  !formPackagingForOutputCategory?.primaryAdditions?.length &&
  !formPackagingForOutputCategory?.tertiaryAdditions?.length &&
  !formPackagingForOutputCategory?.secondaryAdditions?.length
  )

const mapInterventionPackagingItem = (formItem) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const newItem = {
    type: formItem.type,
    amount: null
  }
  
  setInterventionFloatValue(
    newItem,
    'amount',
    formItem,
    'amount',
    userUOM.unitBarnOutputMass,
    defaultMetric.unitBarnOutputMass
  );

  return newItem;
}

const mapPackagingSet = (formPackagingSetData, dbPackagingSetData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (formPackagingSetData.primary?.length) {
    dbPackagingSetData.primary = formPackagingSetData.primary
      ?.filter((item) => item.type && item.amount)
      .map((item) =>  mapInterventionPackagingItem(item));
  }

  if (formPackagingSetData.secondary) {
    dbPackagingSetData.secondary = [formPackagingSetData.secondary]
      .filter((item) => item.type && item.amount)
      .map((item) =>  mapInterventionPackagingItem(item));
  }

  if (formPackagingSetData.tertiary?.length) {
    dbPackagingSetData.tertiary = formPackagingSetData.tertiary
      ?.filter((item) => item.type && item.amount)
      .map((item) =>  mapInterventionPackagingItem(item));
  }
  // additions
  if (formPackagingSetData.secondaryAdditions?.length) {
    dbPackagingSetData.secondaryAdditions = formPackagingSetData.secondaryAdditions
      ?.filter((item) => item.type && item.amount)
      .map((item) =>  ({
        type: item.type,
        amount: explicitConvertValue(
          item.amount,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        )
      }));
  }

  if (formPackagingSetData.primaryAdditions?.length) {
    dbPackagingSetData.primaryAdditions = formPackagingSetData.primaryAdditions
      ?.filter((item) => item.type && item.amount)
      .map((item) =>  ({
        type: item.type,
        amount: explicitConvertValue(
          item.amount,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        )
      }));
  }

  if (formPackagingSetData.tertiaryAdditions?.length) {
    dbPackagingSetData.tertiaryAdditions = formPackagingSetData.tertiaryAdditions
      ?.filter((item) => item.type && item.amount)
      .map((item) =>  ({
        type: item.type,
        amount: explicitConvertValue(
          item.amount,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        )
      }));
  }
}

const mapProcessingPackaging = (formPackagingData, dbPackagingData) => {
  OUTPUT_PACKAGING_PROPS.forEach((prop) => {
    if (formPackagingData?.[prop]) {
      dbPackagingData[prop] = {};
      mapPackagingSet(formPackagingData[prop], dbPackagingData[prop]);
      // if empty, delete it
      if (interventionPackagingIsEmpty(dbPackagingData[prop]))
        delete dbPackagingData[prop];
    }
  });
}

const mapOutProcessingStage = (formStage) => {
  if (!formStage) return {};

  const retVal = {
    facilityId: formStage.facilityId,
    type: 'PROCESSING',
    name: formStage.name,
    id: formStage.id || uuidv4(),
    stageData: {
      processingInput: {},
      processingOutput: {},
      processingPackaging: {},
    },
  };

  if (formStage.stageData?.processingInput) {
    mapProcessingInput(
      formStage.stageData.processingInput,
      retVal.stageData.processingInput
    );
  }
  if (formStage.stageData?.processingOutput) {
    mapProcessingOutput(
      formStage.stageData.processingOutput,
      retVal.stageData.processingOutput
    );
  }

  if (formStage.stageData?.processingPackaging) {
    mapProcessingPackaging(
      formStage.stageData.processingPackaging,
      retVal.stageData.processingPackaging
    );
  }

  return retVal;
}

export default mapOutProcessingStage;