import { ListEntry } from "../components/FarmFlow/common";

interface MasterDataSorterProps {
  display_name: string;
}

interface NameSorterProps {
  name: string;
}

interface FootprintDistributionSorterProps {
  analysisGroup: string;
}

export const masterDataSorter = (
  a: MasterDataSorterProps,
  b: MasterDataSorterProps
) => {
  if (a.display_name?.toLowerCase() < b.display_name?.toLowerCase()) return -1;
  if (a.display_name?.toLowerCase() > b.display_name?.toLowerCase()) return 1;
  return 0;
};

export const listEntryDataSorter = (
  a: ListEntry,
  b: ListEntry
) => {
  if (a.text?.toLowerCase() < b.text?.toLowerCase()) return -1;
  if (a.text?.toLowerCase() > b.text?.toLowerCase()) return 1;
  return 0;
};

export const objectEntriesSorter = (a: string[], b: string[]) => {
  if (a[1]?.toLowerCase() < b[1].toLowerCase()) return -1;
  if (a[1]?.toLowerCase() > b[1]?.toLowerCase()) return 1;
  return 0;
};

export const stringSorter = (a: string, b: string) => {
  if (a?.toLowerCase() < b.toLowerCase()) return -1;
  if (a?.toLowerCase() > b?.toLowerCase()) return 1;
  return 0;
};

export const nameSorter = (a: NameSorterProps, b: NameSorterProps) => {
  if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
  if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
  return 0;
};

export const footprintDistributionSorter = (
  a: FootprintDistributionSorterProps,
  b: FootprintDistributionSorterProps
) => {
  if (a.analysisGroup?.toLowerCase() < b.analysisGroup?.toLowerCase())
    return -1;
  if (a.analysisGroup?.toLowerCase() > b.analysisGroup?.toLowerCase()) return 1;
  return 0;
};
