import {
  Tooltip
} from "@material-ui/core";

const InfoToolTip = (props) =>  {
  return (
    <>
      <Tooltip title={props.tooltip} placement="left" style={{marginTop: props?.marginTop ? props.marginTop : 0}}>
        <svg width="2em" height="2em" viewBox="0 0 20 20" className="bi bi-info-square-fill" fill="#007E4B" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
        </svg>
      </Tooltip>
    </>
  );
};
export default InfoToolTip;

export const InfoToolTipOutlined = (props) => {
  return (
    <>
      {props.prefixLabel}
      <Tooltip title={props.tooltip} placement="left">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={props.color} className="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </Tooltip>
    </>
  );
}