import React, { useMemo, useState } from 'react';
import useStyles from '../../../modules/UserProfile/profileStyles/myProfileStyles';
import { useSelector } from 'react-redux';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DsmIcon } from '@dsm-dcs/design-system-react';
import { Shadows } from "@material-ui/core/styles/shadows";
import { makeStyles } from '@material-ui/styles';
import { DSM_MID_BLUE } from '../../../../_metronic/layout';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core';

interface UnderlinedLinkProps {
  href: string;
  children: React.ReactNode;
}

const theme = createMuiTheme({
  shadows: Array(1).fill("none") as Shadows,
})

const FAQs: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const selectedCustomer = useSelector((state: { selectedCustomer: { value: { id: string } } }) => state.selectedCustomer?.value);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const FAQsAccordionStyles = makeStyles((theme) => ({
    profileSubContainer: {
      gap: 0,
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Fira Sans, sans-serif',
      alignSelf: 'stretch',
      fontStyle: 'normal',
      lineHeight: '24px',
    },
    link: {
      textDecoration: 'underline',
      color: DSM_MID_BLUE
    },
    accordion: {
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    accordionOuterSummaryContent: {
      fontSize: '16px',
      color: DSM_MID_BLUE,
      fontWeight: 500,
      display: 'flex',
      flexGrow: 1,
      transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    accordionInnerSummaryContent: {
      fontSize: '14px',
      color: DSM_MID_BLUE,
      fontWeight: 500,
      display: 'flex',
      flexGrow: 1,
      transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    accordionInnerDetails: {
      fontSize: '14px',
      fontWeight: 400,
    },
  }));

  const useFAQsStyles = FAQsAccordionStyles();

  const UnderlinedLink: React.FC<UnderlinedLinkProps> = ({ href, children }) => (
    <a href={href} className={useFAQsStyles.link} target="_blank">
      {children}
    </a>
  );

  const faqData = useMemo(() => [
    {
      description: intl.formatMessage(
        { id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.DESCRIPTION' },
        {
          br: <br />,
          u: <u>{intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE' })}</u>,
        }
      ),
    },
    {
      faqsTopics: [
        {
          name: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED' }),
          questions: [
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.GET_STARTED.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.GET_STARTED.ANSWER' },
                {
                  myFeeds: <UnderlinedLink href={`/customers/${selectedCustomer.id}/myfeeds`}>{intl.formatMessage({ id: 'COMPOUNDFEEDS.MY_FEEDS' })}</UnderlinedLink>,
                  myFarms: <UnderlinedLink href={`/customers/${selectedCustomer.id}/myfarms`}>{intl.formatMessage({ id: 'FARMS.CARD.TITLE' })}</UnderlinedLink>,
                  helpCenter: <UnderlinedLink href="/helpcenter">{intl.formatMessage({ id: 'HELP_CENTER' })}</UnderlinedLink>,
                })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.CUSTOMIZE_SUSTELL.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.CUSTOMIZE_SUSTELL.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.SUSTAINABILITY_GOALS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.SUSTAINABILITY_GOALS.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.DIFFER_OTHER_LCA.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.DIFFER_OTHER_LCA.ANSWER' },
                {
                  a: <UnderlinedLink href={"https://www.sustell.com/en_US/home.html"}>Sustell™</UnderlinedLink>,
                }
              )
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.SUSTELL_TRIAL.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.SUSTELL_TRIAL.ANSWER' },
              )
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.SPECIES_ANALYZED.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.GETTING_STARTED.SPECIES_ANALYZED.ANSWER' },
                {
                  a: <UnderlinedLink href={"https://www.sustell.com/en_US/home.html"}>Sustell™</UnderlinedLink>
                }
              )
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL' }),
          questions: [
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.IMPORT_DATA.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.IMPORT_DATA.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.DATABASES.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.DATABASES.ANSWER' },
                {
                  a: <UnderlinedLink href={"https://www.sustell.com/en_US/home.html"}>Sustell™</UnderlinedLink>
                }
              )
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.BETTER_DATABASE.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.BETTER_DATABASE.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.ADD_FEED_INGREDIENT.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.ADD_FEED_INGREDIENT.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.MISSING_FEED_INGREDIENT.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.MISSING_FEED_INGREDIENT.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.DATA_SECURITY.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.DATA_SECURITY.ANSWER' },
                {
                  a: <UnderlinedLink href={"https://www.sustell.com/en_US/home.html"}>Sustell™</UnderlinedLink>
                }
              )
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.GHG_REDUCTIONS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.USING_SUSTELL.GHG_REDUCTIONS.ANSWER' })
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS' }),
          questions: [
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.ACCURACY.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.ACCURACY.ANSWER' },
                {
                  br: <br />,
                  a: <UnderlinedLink href={"https://www.sustell.com/content/dam/dsm/sustell/documents/Global%20-%20Sustell%20-%20Assurance%20Statement%20-%20EN%20-%202022.pdf"}>
                    {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.ACCURACY.ASSURANCE_STATEMENT' })}
                  </UnderlinedLink>,
                })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.COUNTRY_ACCURACY.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.COUNTRY_ACCURACY.ANSWER' },
                {
                  br: <br />,
                  a: <UnderlinedLink href={"https://www.sustell.com/en_US/home.html"}>Sustell™</UnderlinedLink>
                })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.PERSONALIZE_RESULTS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.PERSONALIZE_RESULTS.ANSWER' },
                {
                  a: <UnderlinedLink href={`/profile/`}>{intl.formatMessage({ id: 'PROFILE.DROPDOWN.MY_PROFILE' })}</UnderlinedLink>
                })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.INTERVENTIONS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.INTERVENTIONS.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.RESULTS_MEANING.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.RESULTS_MEANING.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.RESULTS_VS_DASHBOARD.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.RESULTS_VS_DASHBOARD.ANSWER' },
                {
                  br: <br />,
                  a: <UnderlinedLink href={`/helpcenter/`}>{intl.formatMessage({ id: 'HELP_CENTER' })}</UnderlinedLink>
                })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.WHOLE_FARM_CARBON_FOOTPRINT.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.WHOLE_FARM_CARBON_FOOTPRINT.ANSWER' }, { br: <br /> })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.BENCHMARKS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.BENCHMARKS.ANSWER' }, { br: <br /> })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.CARBON_CREDITS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.SUSTELL_RESULTS.CARBON_CREDITS.ANSWER' })
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLE_SHOOTING' }),
          questions: [
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.WARNING_MESSAGE.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.WARNING_MESSAGE.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.ERROR_MESSAGE.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.ERROR_MESSAGE.ANSWER' },
                {
                  a: <UnderlinedLink href={`/helpcenter/`}>{intl.formatMessage({ id: 'HELP_CENTER' })}</UnderlinedLink>
                })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.CONTACT.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.CONTACT.ANSWER' },
                {
                  br: <br />,
                  a: <UnderlinedLink href={"https://service.sustell.com/helpcenter"}>
                    {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.TECHNICAL_SUPPORT_TROUBLESHOOTING.CONTACT.TECHNICAL_SUPPORT_FORM' })}
                  </UnderlinedLink>
                }),
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS' }),
          questions: [
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.CHANGE_PASSWORD.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.CHANGE_PASSWORD.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.SUBSCRIPTION_START.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.SUBSCRIPTION_START.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.CHANGE_CANCEL_SUBSCRIPTION.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.CHANGE_CANCEL_SUBSCRIPTION.ANSWER' })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.SUBSCRIPTION_END.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.SUBSCRIPTION_END.ANSWER' }, { br: <br /> })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.SUBSCRIPTION_CANCEL_DATA.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.SUBSCRIPTION_CANCEL_DATA.ANSWER' },
              )
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.TRIAL_TO_PAID.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.TRIAL_TO_PAID.ANSWER' },
              )
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.TRIAL_END.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.TRIAL_END.ANSWER' }, { br: <br /> })
            },
            {
              title: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.DSM_FIRMENICH_PRODUCTS.TITLE' }),
              answer: intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS.ACCOUNT_MANAGEMENT_SUBSCRIPTIONS.DSM_FIRMENICH_PRODUCTS.ANSWER' })
            },
          ],
        },
      ],
    }
  ], [intl, selectedCustomer]);

  const accordionClicked = (id: string) => {
    if (expandedAccordions.includes(id))
      setExpandedAccordions(expandedAccordions.filter((accordionId) => accordionId !== id));
    else setExpandedAccordions([...expandedAccordions, id]);
  };

  const description = faqData[0].description;
  const faqsTopics = faqData[1].faqsTopics;

  return (
    <ThemeProvider theme={theme}>
      <Typography className={classes.profileDescription}>{description}</Typography>

      <div className={useFAQsStyles.profileSubContainer}>
        {faqsTopics?.map((topic, topicIndex) => (
          <Accordion
            key={topic.name}
            onChange={() => accordionClicked(`topic-${topicIndex}`)}
            expanded={expandedAccordions.includes(`topic-${topicIndex}`)}
            className={useFAQsStyles.accordion}
          >
            <AccordionSummary expandIcon={<DsmIcon slot="before" name="arrows/chevron-down" />}>
              <Typography className={useFAQsStyles.accordionOuterSummaryContent}>{topic.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={useFAQsStyles.profileSubContainer}>
              {topic.questions.map((question, questionIndex) => (
                <Accordion
                  key={question.title}
                  onChange={() => accordionClicked(`topic-${topicIndex}-question-${questionIndex}`)}
                  expanded={expandedAccordions.includes(`topic-${topicIndex}-question-${questionIndex}`)}
                  className={useFAQsStyles.accordion}
                >
                  <AccordionSummary expandIcon={<DsmIcon slot="before" name="arrows/chevron-down" />}>
                    <Typography className={useFAQsStyles.accordionInnerSummaryContent}>
                      {question.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={useFAQsStyles.accordionInnerDetails}>
                      {question.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </ThemeProvider>
  );
}

export default FAQs;
