import Drawer from "../../_metronic/layout/components/drawer/Drawer";
import Can from "../modules/Auth/Can";

import { Col } from "react-bootstrap";
import { useSubheader } from "../../_metronic/layout";

// import { useIntl } from "react-intl";
import { useIntl } from "../../_metronic/i18n/customUseIntl";
import { AdminCustomersDashboard } from "../modules/UserProfile/AdminCustomersDashboard";

export const AdminCustomersPage = () => {
  const intl = useIntl();
  const subheader = useSubheader();

  if (Can("access", "Admin pages") || Can("read", "Customer")) {
    subheader.setTitle(intl.formatMessage({id: "CUSTOMERS.CARD.TITLE"}));
  }

  return (Can("access", "Admin pages") || Can("read", "Customer")) ? (
    <Drawer>
      <Col>
        <AdminCustomersDashboard />     
      </Col>
    </Drawer>
  ) : (
    <></>
  );
};
