import { DialogContainer } from '../FarmFlow/CommonDataParts/DialogContainer2';
import AccessSampleData from './AccessSampleData';

interface AccessSampleDataDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  isShowPostLogin: boolean;
  customerID: string;
}

const AccessSampleDataDialog = ({
  open,
  title,
  onClose,
  isShowPostLogin,
  customerID,
}: AccessSampleDataDialogProps) => (
    <DialogContainer
      formVisible={open}
      handleClose={onClose}
      iconCode="editor/dotpoints-01"
      formTitle={title}
      width="806px"
      dialogContentStyles={{}}
    >
      <AccessSampleData
        onClose={onClose}
        isShowPostLogin={isShowPostLogin}
        customerID={customerID}
      />
    </DialogContainer>
  );

export default AccessSampleDataDialog;
