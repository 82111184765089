export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type ApiToken = {
  __typename?: 'APIToken';
  key?: Maybe<Scalars['String']>;
};

export type AddonIngredient = {
  __typename?: 'AddonIngredient';
  name: Scalars['String'];
  origin: Scalars['String'];
};

export type AllowedPartnerMutation = {
  __typename?: 'AllowedPartnerMutation';
  mutations?: Maybe<Array<Scalars['String']>>;
  partnerId: Scalars['String'];
};

export type AllowedPartnerMutationsInput = {
  mutations?: InputMaybe<Array<Scalars['String']>>;
  partnerId: Scalars['String'];
};

export type AllowedPartnerQueriesInput = {
  partnerId: Scalars['String'];
  queries?: InputMaybe<Array<Scalars['String']>>;
};

export type AllowedPartnerQuery = {
  __typename?: 'AllowedPartnerQuery';
  partnerId: Scalars['String'];
  queries?: Maybe<Array<Scalars['String']>>;
};

export type AnimalSystem = {
  __typename?: 'AnimalSystem';
  animalType: AnimalType;
  baselines?: Maybe<ModelFarmBaselineConnection>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  farmId: Scalars['ID'];
  farmingMethod: FarmingMethod;
  id: Scalars['ID'];
  interventions?: Maybe<ModelFarmInterventionConnection>;
  name: Scalars['String'];
  productionType?: Maybe<ProductionType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};


export type AnimalSystemBaselinesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type AnimalSystemInterventionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type AnimalSystemId = {
  __typename?: 'AnimalSystemID';
  id: Scalars['ID'];
};

export enum AnimalType {
  Beef = 'BEEF',
  Broiler = 'BROILER',
  BroilerV2 = 'BROILER_V2',
  Dairy = 'DAIRY',
  DairyV2 = 'DAIRY_V2',
  LayingHens = 'LAYING_HENS',
  LayingHensV2 = 'LAYING_HENS_V2',
  LitopenaeusVannamei = 'LITOPENAEUS_VANNAMEI',
  PenaeusMonodon = 'PENAEUS_MONODON',
  Pig = 'PIG',
  PigFattening = 'PIG_FATTENING',
  Salmon = 'SALMON',
  SalmonV2 = 'SALMON_V2',
  SeaBass = 'SEA_BASS',
  SeaBream = 'SEA_BREAM',
  Turkey = 'TURKEY'
}

export type AuxiliaryFacilityInputs = {
  chemicals?: InputMaybe<Array<ChemicalInput>>;
  refrigerants?: InputMaybe<Array<RefrigerantInput>>;
  wastewaterAmount: Scalars['Float'];
};

export type AuxiliaryInputsFacility = {
  __typename?: 'AuxiliaryInputsFacility';
  chemicals?: Maybe<Array<ChemicalItem>>;
  refrigerants?: Maybe<Array<RefrigerantItem>>;
  wastewaterAmount: Scalars['Float'];
};

export type BeddingUse = {
  __typename?: 'BeddingUse';
  barleyStrawUse?: Maybe<Scalars['Float']>;
  maizeStoverUse?: Maybe<Scalars['Float']>;
  oatStrawUse?: Maybe<Scalars['Float']>;
  riceHuskUse?: Maybe<Scalars['Float']>;
  sawDustUse?: Maybe<Scalars['Float']>;
  sunflowerHuskUse?: Maybe<Scalars['Float']>;
  wheatStrawUse?: Maybe<Scalars['Float']>;
  woodShavingsUse?: Maybe<Scalars['Float']>;
};

export type BeddingUseInput = {
  barleyStrawUse?: InputMaybe<Scalars['Float']>;
  maizeStoverUse?: InputMaybe<Scalars['Float']>;
  oatStrawUse?: InputMaybe<Scalars['Float']>;
  riceHuskUse?: InputMaybe<Scalars['Float']>;
  sawDustUse?: InputMaybe<Scalars['Float']>;
  sunflowerHuskUse?: InputMaybe<Scalars['Float']>;
  wheatStrawUse?: InputMaybe<Scalars['Float']>;
  woodShavingsUse?: InputMaybe<Scalars['Float']>;
};

export type BeddingUseIntervention = {
  __typename?: 'BeddingUseIntervention';
  barleyStrawUse?: Maybe<InterventionFloat>;
  maizeStoverUse?: Maybe<InterventionFloat>;
  oatStrawUse?: Maybe<InterventionFloat>;
  riceHuskUse?: Maybe<InterventionFloat>;
  sawDustUse?: Maybe<InterventionFloat>;
  sunflowerHuskUse?: Maybe<InterventionFloat>;
  wheatStrawUse?: Maybe<InterventionFloat>;
  woodShavingsUse?: Maybe<InterventionFloat>;
};

export type BeddingUseInterventionInput = {
  barleyStrawUse?: InputMaybe<InterventionFloatInput>;
  maizeStoverUse?: InputMaybe<InterventionFloatInput>;
  oatStrawUse?: InputMaybe<InterventionFloatInput>;
  sawDustUse?: InputMaybe<InterventionFloatInput>;
  wheatStrawUse?: InputMaybe<InterventionFloatInput>;
  woodShavingsUse?: InputMaybe<InterventionFloatInput>;
};

export type BenchmarkRequestInput = {
  animalType: AnimalType;
  regions: Array<InputMaybe<Scalars['String']>>;
  type: BenchmarkRequestType;
};

export enum BenchmarkRequestType {
  Farm = 'FARM',
  Feed = 'FEED'
}

export type BenchmarkResponse = {
  __typename?: 'BenchmarkResponse';
  results: Array<Maybe<Scalars['ID']>>;
};

export type BovaerCalculations = {
  __typename?: 'BovaerCalculations';
  baseline: Scalars['Float'];
  reduction: Scalars['Float'];
  reductionPercentage: Scalars['Float'];
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BovaerCalculationsInput = {
  animalType: BovaerAnimalType;
  country?: InputMaybe<Scalars['String']>;
  dosage?: InputMaybe<Scalars['Float']>;
  dryMatterIntakeKg: Scalars['Float'];
  fatPercentageInDmi?: InputMaybe<Scalars['Float']>;
  ndfPercentageOfDmi: Scalars['Float'];
  numberOfAnimals: Scalars['Float'];
};

export type ChemicalInput = {
  amount: Scalars['Float'];
  type: ChemicalType;
};

export type ChemicalItem = {
  __typename?: 'ChemicalItem';
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  ChemicalOrganic = 'CHEMICAL_ORGANIC',
  EthanolFromMaize = 'ETHANOL_FROM_MAIZE',
  Glycerine = 'GLYCERINE',
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  Isopropanol = 'ISOPROPANOL',
  NitrogenLiquid = 'NITROGEN_LIQUID',
  OxygenLiquid = 'OXYGEN_LIQUID',
  PhosphoricAcid = 'PHOSPHORIC_ACID',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumChlorate = 'SODIUM_CHLORATE',
  SodiumChlorideAsBrineSolution = 'SODIUM_CHLORIDE_AS_BRINE_SOLUTION',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE',
  SulfuricAcid = 'SULFURIC_ACID'
}

export enum ClimateType {
  BorealDry = 'BOREAL_DRY',
  BorealMoist = 'BOREAL_MOIST',
  CoolTemperateDry = 'COOL_TEMPERATE_DRY',
  CoolTemperateMoist = 'COOL_TEMPERATE_MOIST',
  TropicalDry = 'TROPICAL_DRY',
  TropicalMoist = 'TROPICAL_MOIST',
  TropicalMontane = 'TROPICAL_MONTANE',
  TropicalWet = 'TROPICAL_WET',
  WarmTemperateDry = 'WARM_TEMPERATE_DRY',
  WarmTemperateMoist = 'WARM_TEMPERATE_MOIST'
}

export type CompoundFeed = {
  __typename?: 'CompoundFeed';
  benchmark?: Maybe<Scalars['Boolean']>;
  calculationInProgress?: Maybe<Scalars['Boolean']>;
  created_date?: Maybe<Scalars['String']>;
  feedmill_location: GeographyNoCode;
  id: Scalars['ID'];
  ingredient_uses: Array<Maybe<IngredientUses>>;
  is_reference?: Maybe<Scalars['Boolean']>;
  modified_date?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  on_site_activities?: Maybe<Array<Maybe<TypeUnitValue>>>;
  outbound_transport?: Maybe<Array<Maybe<TypeUnitValue>>>;
  target_species: Array<Maybe<Scalars['String']>>;
  year: IntValue;
};

export type CompoundFeedAddonsV2 = {
  __typename?: 'CompoundFeedAddonsV2';
  addonType: Scalars['String'];
  ingredients: Array<AddonIngredient>;
};

export enum CompoundFeedDatabase {
  Afp_5 = 'AFP_5',
  Afp_6_3 = 'AFP_6_3',
  Gfli = 'GFLI'
}

export type CompoundFeedDeleteResult = {
  __typename?: 'CompoundFeedDeleteResult';
  id: Scalars['ID'];
  status: Scalars['String'];
  usageItems?: Maybe<Array<Maybe<FeedUsageData>>>;
};

export type CompoundFeedId = {
  __typename?: 'CompoundFeedID';
  id: Scalars['ID'];
};

export type CompoundFeedIngredientName = {
  __typename?: 'CompoundFeedIngredientName';
  group?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CompoundFeedInputComponentsData = {
  __typename?: 'CompoundFeedInputComponentsData';
  feedmill_locations?: Maybe<Array<Maybe<GeographyNoCode>>>;
  inbound_transport_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  ingredient_origin_combination?: Maybe<Array<Maybe<IngredientOriginCombination>>>;
  on_site_activities_types?: Maybe<Array<Maybe<TypeUnitPair>>>;
  outbound_transport_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  target_species?: Maybe<Array<Maybe<MasterDataOption>>>;
};

export type CompoundFeedName = {
  __typename?: 'CompoundFeedName';
  name: Scalars['String'];
};

export type CompoundFeedV2 = {
  __typename?: 'CompoundFeedV2';
  benchmark?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  customerId: Scalars['ID'];
  databaseFoundation?: Maybe<CompoundFeedDatabase>;
  feedmillLocation: Scalars['String'];
  id: Scalars['ID'];
  ingredientUses?: Maybe<Array<IngredientUsesV2>>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nutritionalAnalysisData?: Maybe<Array<Maybe<NutritionalAnalysis>>>;
  onSiteActivities?: Maybe<Array<Maybe<OnSiteActivity>>>;
  outboundTransport?: Maybe<Array<Maybe<Transport>>>;
  targetSpecies: Array<Maybe<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type CopyBaselineInput = {
  baselineReferenceId: Scalars['ID'];
  customerId: Scalars['ID'];
  databaseFoundation: Scalars['String'];
  productionProcessId: Scalars['ID'];
};

export type CopyResult = {
  __typename?: 'CopyResult';
  newBaselineReference?: Maybe<Scalars['ID']>;
};

export type CreateAnimalSystemInput = {
  animalType: AnimalType;
  farmingMethod: FarmingMethod;
  name: Scalars['String'];
  productionType?: InputMaybe<ProductionType>;
};

export type CreateCompoundFeedInput = {
  feedmill_location: GeographyInput;
  ingredient_uses: Array<InputMaybe<IngredientUsesInput>>;
  name: Scalars['String'];
  on_site_activities?: InputMaybe<Array<InputMaybe<TypeValueFloatInput>>>;
  outbound_transport?: InputMaybe<Array<InputMaybe<TypeValueFloatInput>>>;
  target_species: Array<InputMaybe<Scalars['String']>>;
  year: IntValueInput;
};

export type CreateCompoundFeedInputV2 = {
  databaseFoundation?: InputMaybe<CompoundFeedDatabase>;
  feedmillLocation: Scalars['String'];
  ingredientUses?: InputMaybe<Array<IngredientUsesV2Input>>;
  name: Scalars['String'];
  nutritionalAnalysisData?: InputMaybe<Array<InputMaybe<NutritionalAnalysisInput>>>;
  onSiteActivities?: InputMaybe<Array<InputMaybe<OnSiteActivityInput>>>;
  outboundTransport?: InputMaybe<Array<InputMaybe<TransportInput>>>;
  targetSpecies: Array<InputMaybe<Scalars['String']>>;
  year: Scalars['Int'];
};

export type CreateCustomerInput = {
  SAPCustomerId?: InputMaybe<Scalars['String']>;
  allowedPartnerMutations?: InputMaybe<Array<InputMaybe<AllowedPartnerMutationsInput>>>;
  allowedPartnerQueries?: InputMaybe<Array<InputMaybe<AllowedPartnerQueriesInput>>>;
  canAccessPartner?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contractNumber?: InputMaybe<Scalars['String']>;
  farmsNumberLimit: Scalars['Int'];
  feedsVisible?: InputMaybe<Scalars['Boolean']>;
  ingredients?: InputMaybe<CustomerIngredientsInput>;
  license?: InputMaybe<LicenseType>;
  licenseEndDate?: InputMaybe<Scalars['AWSDate']>;
  licenseStartDate?: InputMaybe<Scalars['AWSDate']>;
  name: Scalars['String'];
  partnerApiAccess?: InputMaybe<Scalars['Boolean']>;
  reference?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  tokenName?: InputMaybe<Scalars['String']>;
  tokenValue?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomerType>;
};

export type CreateFacilityInput = {
  auxiliaryInputs: AuxiliaryFacilityInputs;
  currency: Currency;
  location: Scalars['String'];
  name: Scalars['String'];
  resourceUse: ResourceUseInput;
  targetSpecies: Array<Scalars['String']>;
  totalLiveweight: Scalars['Float'];
  type: FacilityType;
};

export type CreateFarmBaselineInput = {
  age?: InputMaybe<Scalars['Float']>;
  averageAnnualTemperature?: InputMaybe<Scalars['Float']>;
  beddingUse?: InputMaybe<BeddingUseInput>;
  databaseFoundation?: InputMaybe<CompoundFeedDatabase>;
  description?: InputMaybe<Scalars['String']>;
  materialUse?: InputMaybe<MaterialUseInput>;
  name: Scalars['String'];
  reportingPeriod: ReportingPeriod;
  resourceUse: ResourceUseInput;
  roundsPerYear: Scalars['Float'];
  stages: Array<StageInput>;
  validFrom?: InputMaybe<Scalars['AWSDate']>;
  validTo?: InputMaybe<Scalars['AWSDate']>;
  year: Scalars['Int'];
};

export type CreateFarmInput = {
  city: Scalars['String'];
  climateType?: InputMaybe<ClimateType>;
  country: Scalars['String'];
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
};

export type CreateIncidentInput = {
  attachementKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description: Scalars['String'];
  incidentID: Scalars['String'];
  incidentLink?: InputMaybe<Scalars['String']>;
  incidentLocation: Scalars['String'];
  incidentType: Scalars['String'];
  speciesType: Scalars['String'];
  userCompanyName: Scalars['String'];
  userEmailAddress: Scalars['String'];
  userName: Scalars['String'];
};

export type CreateInterventionInput = {
  baseline: Scalars['String'];
  beddingUse?: InputMaybe<BeddingUseInterventionInput>;
  category: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  materialUse?: InputMaybe<MaterialUseInterventionInput>;
  name: Scalars['String'];
  resourceUse: ResourceUseInterventionInput;
  stages: Array<StageInput>;
};

export type CreateNotificationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachementKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  expireDateTime?: InputMaybe<Scalars['AWSDateTime']>;
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  notificationType: Scalars['String'];
  startDateTime: Scalars['AWSDateTime'];
  targetGroups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
};

export type CreateUserInput = {
  customerAccess?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  farmAccess?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName: Scalars['String'];
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  status: Scalars['String'];
};

export enum Currency {
  Aud = 'AUD',
  Brl = 'BRL',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type Customer = {
  __typename?: 'Customer';
  SAPCustomerId?: Maybe<Scalars['String']>;
  allowedPartnerMutations?: Maybe<Array<Maybe<AllowedPartnerMutation>>>;
  allowedPartnerQueries?: Maybe<Array<Maybe<AllowedPartnerQuery>>>;
  canAccessPartner?: Maybe<Array<Maybe<Scalars['String']>>>;
  compoundFeedIngredientsAddons?: Maybe<Array<Scalars['String']>>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  dashboardTargets?: Maybe<Scalars['AWSJSON']>;
  farms?: Maybe<ModelFarmConnection>;
  farmsNumberLimit?: Maybe<Scalars['Int']>;
  feedsVisible?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  license?: Maybe<LicenseType>;
  licenseEndDate?: Maybe<Scalars['AWSDate']>;
  licenseStartDate?: Maybe<Scalars['AWSDate']>;
  name: Scalars['String'];
  partnerApiAccess?: Maybe<Scalars['Boolean']>;
  reference?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<CustomerType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};


export type CustomerFarmsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type CustomerDashboardTargets = {
  __typename?: 'CustomerDashboardTargets';
  targets?: Maybe<Scalars['AWSJSON']>;
};

export type CustomerId = {
  __typename?: 'CustomerID';
  id: Scalars['ID'];
};

export type CustomerIngredients = {
  __typename?: 'CustomerIngredients';
  compoundFeedIngredients?: Maybe<Array<Scalars['ID']>>;
  compoundFeedIngredientsAddons?: Maybe<Array<Scalars['String']>>;
  singleIngredients?: Maybe<Array<Scalars['ID']>>;
};

export type CustomerIngredientsInput = {
  compoundFeedIngredients: Array<Scalars['ID']>;
  compoundFeedIngredientsAddons?: InputMaybe<Array<Scalars['String']>>;
  singleIngredients: Array<Scalars['ID']>;
};

export enum CustomerType {
  Farmer = 'FARMER',
  FeedMill = 'FEED_MILL'
}

export type DashboardUrl = {
  __typename?: 'DashboardUrl';
  url?: Maybe<Scalars['String']>;
};

export type DatasetInput = {
  includeSubordinate?: InputMaybe<Scalars['Boolean']>;
  reference: Scalars['ID'];
  type: DatasetType;
};

export type DatasetList = {
  __typename?: 'DatasetList';
  customerId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stageName: Scalars['String'];
};

export enum DatasetType {
  FarmBaseline = 'FARM_BASELINE',
  FarmIntervention = 'FARM_INTERVENTION'
}

export type EnumOptions = {
  __typename?: 'EnumOptions';
  enumName: Scalars['String'];
  enumValues?: Maybe<Array<MasterDataOption>>;
};

export type Facility = {
  __typename?: 'Facility';
  auxiliaryInputs: AuxiliaryInputsFacility;
  benchmark?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  currency: Currency;
  customerId: Scalars['ID'];
  id: Scalars['ID'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  name: Scalars['String'];
  resourceUse: ResourceUse;
  targetSpecies: Array<Scalars['String']>;
  totalLiveweight: Scalars['Float'];
  type: FacilityType;
  updatedAt?: Maybe<Scalars['String']>;
};

export type FacilityId = {
  __typename?: 'FacilityID';
  id: Scalars['ID'];
};

export enum FacilityType {
  Cutting = 'CUTTING',
  Slaughtering = 'SLAUGHTERING',
  SlaughteringAndCutting = 'SLAUGHTERING_AND_CUTTING'
}

export type Farm = {
  __typename?: 'Farm';
  animalSystems?: Maybe<ModelProductionProcessConnection>;
  benchmark?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  climateType?: Maybe<ClimateType>;
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};


export type FarmAnimalSystemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  productionProcessId?: InputMaybe<Scalars['ID']>;
};

export type FarmBaseline = {
  __typename?: 'FarmBaseline';
  age?: Maybe<Scalars['Float']>;
  averageAnnualTemperature?: Maybe<Scalars['Float']>;
  beddingUse?: Maybe<BeddingUse>;
  calculationInProgress?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  databaseFoundation?: Maybe<CompoundFeedDatabase>;
  description?: Maybe<Scalars['String']>;
  farmId: Scalars['ID'];
  impactSummary?: Maybe<Scalars['AWSJSON']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  materialUse?: Maybe<MaterialUse>;
  name: Scalars['String'];
  productionProcessId: Scalars['ID'];
  recalculateFootprint?: Maybe<Scalars['Boolean']>;
  reference: Scalars['ID'];
  reportingPeriod: ReportingPeriod;
  resourceUse?: Maybe<ResourceUse>;
  roundsPerYear: Scalars['Float'];
  stages?: Maybe<Array<Maybe<Stage>>>;
  target?: Maybe<Scalars['AWSJSON']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  validFrom?: Maybe<Scalars['AWSDate']>;
  validTo?: Maybe<Scalars['AWSDate']>;
  year: Scalars['Int'];
};

export type FarmId = {
  __typename?: 'FarmID';
  id: Scalars['ID'];
};

export enum FarmingMethod {
  Conventional = 'CONVENTIONAL'
}

export type FeedTagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type FeedUsageData = {
  __typename?: 'FeedUsageData';
  farmId: Scalars['ID'];
  name: Scalars['String'];
  reference: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

export enum FootPrintType {
  CompoundFeed = 'COMPOUND_FEED',
  FarmBaseline = 'FARM_BASELINE',
  FarmIntervention = 'FARM_INTERVENTION'
}

export type Footprint = {
  __typename?: 'Footprint';
  calculatedImpact?: Maybe<Scalars['AWSJSON']>;
  calculationInProgress?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['AWSDateTime'];
  emissions?: Maybe<Scalars['AWSJSON']>;
  errors?: Maybe<Scalars['AWSJSON']>;
  reference: Scalars['ID'];
  reporting_period?: Maybe<Scalars['AWSJSON']>;
  stagesImpact?: Maybe<Scalars['AWSJSON']>;
  total_output?: Maybe<Scalars['Float']>;
  type: FootPrintType;
  warnings?: Maybe<Scalars['AWSJSON']>;
};

export type FootprintTarget = {
  __typename?: 'FootprintTarget';
  reference: Scalars['ID'];
  target: Scalars['AWSJSON'];
  type: FootPrintType;
};

export type Geography = {
  __typename?: 'Geography';
  iso_code: IsoCode;
  name?: Maybe<Scalars['String']>;
};

export type GeographyInput = {
  iso_code: Scalars['String'];
};

export type GeographyNoCode = {
  __typename?: 'GeographyNoCode';
  iso_code: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Incident = {
  __typename?: 'Incident';
  incidentID: Scalars['String'];
};

export type Ingredient = {
  __typename?: 'Ingredient';
  ingredient_name: IngredientName;
  origin: Scalars['String'];
};

export type IngredientInput = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
};

export type IngredientName = {
  __typename?: 'IngredientName';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IngredientNameInput = {
  id: Scalars['ID'];
};

export type IngredientOrProxyIngredient = {
  __typename?: 'IngredientOrProxyIngredient';
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
};

export type IngredientOriginCombination = {
  __typename?: 'IngredientOriginCombination';
  exclusive_origins?: Maybe<Array<Maybe<Geography>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IngredientTransportDistance = {
  __typename?: 'IngredientTransportDistance';
  distance?: Maybe<Scalars['Float']>;
  mode?: Maybe<TransportMode>;
};

export type IngredientUses = {
  __typename?: 'IngredientUses';
  id?: Maybe<Scalars['ID']>;
  inbound_transport?: Maybe<Array<Maybe<TypeUnitValue>>>;
  ingredient: Ingredient;
  quantity: Scalars['Float'];
  requested_ingredient: RequestedIngredient;
};

export type IngredientUsesInput = {
  id?: InputMaybe<Scalars['String']>;
  inbound_transport?: InputMaybe<Array<InputMaybe<TypeValueFloatInput>>>;
  quantity: Scalars['Float'];
  requested_ingredient: RequestedIngredientInput;
};

export type IngredientUsesV2 = {
  __typename?: 'IngredientUsesV2';
  customIngredient?: Maybe<Scalars['Boolean']>;
  customName?: Maybe<Scalars['String']>;
  inboundTransport?: Maybe<Array<Maybe<Transport>>>;
  ingredient: IngredientV2;
  isProxy?: Maybe<Scalars['Boolean']>;
  originalOrigin?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
};

export type IngredientUsesV2Input = {
  customIngredient?: InputMaybe<Scalars['Boolean']>;
  customName?: InputMaybe<Scalars['String']>;
  inboundTransport?: InputMaybe<Array<InputMaybe<TransportInput>>>;
  ingredient: IngredientV2Input;
  isProxy?: InputMaybe<Scalars['Boolean']>;
  originalOrigin?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
};

export type IngredientV2 = {
  __typename?: 'IngredientV2';
  addonType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type IngredientV2Input = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<FeedTagInput>>>;
};

export type IntValue = {
  __typename?: 'IntValue';
  value: Scalars['Int'];
};

export type IntValueInput = {
  value?: InputMaybe<Scalars['Int']>;
};

export type Intervention = {
  __typename?: 'Intervention';
  baseline: Scalars['ID'];
  beddingUse?: Maybe<BeddingUseIntervention>;
  calculationInProgress?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  customerId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  farmId: Scalars['ID'];
  impactSummary?: Maybe<Scalars['AWSJSON']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  materialUse?: Maybe<MaterialUseIntervention>;
  name: Scalars['String'];
  productionProcessId: Scalars['ID'];
  recalculateFootprint?: Maybe<Scalars['Boolean']>;
  reference: Scalars['ID'];
  resourceUse?: Maybe<ResourceUseIntervention>;
  stages?: Maybe<Array<Maybe<Stage>>>;
  target?: Maybe<Scalars['AWSJSON']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type InterventionFloat = {
  __typename?: 'InterventionFloat';
  change_type: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type InterventionFloatInput = {
  change_type: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
};

export type InterventionId = {
  __typename?: 'InterventionID';
  id: Scalars['ID'];
};

export type InterventionWaterSourceTypeInput = {
  value?: InputMaybe<WaterSourceType>;
};

export type IsoCode = {
  __typename?: 'IsoCode';
  code: Scalars['String'];
};

export enum LicenseType {
  Basic = 'BASIC',
  Enterprise = 'ENTERPRISE',
  Premium = 'PREMIUM'
}

export type MasterData = {
  __typename?: 'MasterData';
  animal_type?: Maybe<Array<MasterDataOption>>;
  compound_feed_uses?: Maybe<Array<MasterDataOption>>;
  emissions?: Maybe<Array<MasterDataOption>>;
  enumerations?: Maybe<Array<EnumOptions>>;
  farming_method?: Maybe<Array<MasterDataOption>>;
  housing_type?: Maybe<Array<MasterDataOption>>;
  iso_code?: Maybe<Array<MasterDataOption>>;
  manure_management_system?: Maybe<Array<MasterDataOption>>;
  single_ingredient_uses?: Maybe<Array<MasterDataOption>>;
};

export type MasterDataOption = {
  __typename?: 'MasterDataOption';
  display_name: Scalars['String'];
  value: Scalars['String'];
};

export type Match = {
  __typename?: 'Match';
  isProxy?: Maybe<Scalars['Boolean']>;
  requestedIngredient?: Maybe<IngredientOrProxyIngredient>;
  selectedIngredient?: Maybe<IngredientOrProxyIngredient>;
};

export type MaterialUse = {
  __typename?: 'MaterialUse';
  detergentUse?: Maybe<Scalars['Float']>;
  disinfectantUse?: Maybe<Scalars['Float']>;
  sulfuricAcidUse?: Maybe<Scalars['Float']>;
};

export type MaterialUseInput = {
  detergentUse?: InputMaybe<Scalars['Float']>;
  disinfectantUse?: InputMaybe<Scalars['Float']>;
  sulfuricAcidUse?: InputMaybe<Scalars['Float']>;
};

export type MaterialUseIntervention = {
  __typename?: 'MaterialUseIntervention';
  detergentUse?: Maybe<InterventionFloat>;
  disinfectantUse?: Maybe<InterventionFloat>;
  sulfuricAcidUse?: Maybe<InterventionFloat>;
};

export type MaterialUseInterventionInput = {
  detergentUse?: InputMaybe<InterventionFloatInput>;
  disinfectantUse?: InputMaybe<InterventionFloatInput>;
  sulfuricAcidUse?: InputMaybe<InterventionFloatInput>;
};

export type ModelApiTokenConnection = {
  __typename?: 'ModelAPITokenConnection';
  items?: Maybe<Array<Maybe<ApiToken>>>;
};

export type ModelCompoundFeedConnection = {
  __typename?: 'ModelCompoundFeedConnection';
  items?: Maybe<Array<Maybe<CompoundFeed>>>;
};

export type ModelCompoundFeedConnectionV2 = {
  __typename?: 'ModelCompoundFeedConnectionV2';
  items?: Maybe<Array<Maybe<CompoundFeedV2>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelCustomerConnection = {
  __typename?: 'ModelCustomerConnection';
  items?: Maybe<Array<Maybe<Customer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFacilityConnection = {
  __typename?: 'ModelFacilityConnection';
  items?: Maybe<Array<Maybe<Facility>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFarmBaselineConnection = {
  __typename?: 'ModelFarmBaselineConnection';
  items?: Maybe<Array<Maybe<FarmBaseline>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFarmConnection = {
  __typename?: 'ModelFarmConnection';
  items?: Maybe<Array<Maybe<Farm>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFarmInterventionConnection = {
  __typename?: 'ModelFarmInterventionConnection';
  items?: Maybe<Array<Maybe<Intervention>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelInterventionConnection = {
  __typename?: 'ModelInterventionConnection';
  items?: Maybe<Array<Maybe<Intervention>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelListCustomersWithBaselinesAndInterventionsWithFeedZero = {
  __typename?: 'ModelListCustomersWithBaselinesAndInterventionsWithFeedZero';
  baselinesList?: Maybe<Array<Maybe<DatasetList>>>;
  interventionsList?: Maybe<Array<Maybe<DatasetList>>>;
};

export type ModelNotificationConnection = {
  __typename?: 'ModelNotificationConnection';
  items?: Maybe<Array<Maybe<Notification>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPartnersConnection = {
  __typename?: 'ModelPartnersConnection';
  items?: Maybe<Array<Maybe<Partner>>>;
};

export type ModelProductionProcessConnection = {
  __typename?: 'ModelProductionProcessConnection';
  items?: Maybe<Array<Maybe<AnimalSystem>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelResourceUsageConnection = {
  __typename?: 'ModelResourceUsageConnection';
  items?: Maybe<Array<Maybe<ResourceUsage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTransportDistanceConnection = {
  __typename?: 'ModelTransportDistanceConnection';
  items?: Maybe<Array<Maybe<TypeUnitValue>>>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type ModelUserConnection = {
  __typename?: 'ModelUserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  benchmarkRequests?: Maybe<BenchmarkResponse>;
  copyBaselineToV2?: Maybe<CopyResult>;
  createAnimalSystem?: Maybe<AnimalSystem>;
  createCompoundFeed?: Maybe<CompoundFeedId>;
  createCompoundFeedV2?: Maybe<CompoundFeedId>;
  createCustomer?: Maybe<CustomerId>;
  createFacility?: Maybe<FacilityId>;
  createFarm?: Maybe<Farm>;
  createFarmBaseline?: Maybe<FarmBaseline>;
  createIncident?: Maybe<Incident>;
  createIntervention?: Maybe<Intervention>;
  createNotification?: Maybe<Notification>;
  createUser?: Maybe<CreateUserResponse>;
  deleteAnimalSystem?: Maybe<AnimalSystemId>;
  deleteCompoundFeed?: Maybe<CompoundFeedDeleteResult>;
  deleteCompoundFeedV2?: Maybe<CompoundFeedId>;
  deleteFacility?: Maybe<FacilityId>;
  deleteFarm?: Maybe<FarmId>;
  deleteIntervention?: Maybe<CustomerId>;
  deleteNotification?: Maybe<Notification>;
  deleteProductionDataset?: Maybe<FarmId>;
  deleteUser?: Maybe<UserId>;
  duplicateFarm?: Maybe<FarmId>;
  recalculateFootprint?: Maybe<Array<RecalculateFootprintResult>>;
  resendActivation?: Maybe<UserId>;
  updateAnimalSystem?: Maybe<AnimalSystem>;
  updateCompoundFeed?: Maybe<CompoundFeedId>;
  updateCompoundFeedV2?: Maybe<CompoundFeedId>;
  updateCustomer?: Maybe<CustomerId>;
  updateCustomerDashboardTargets?: Maybe<CustomerDashboardTargets>;
  updateFacility?: Maybe<FacilityId>;
  updateFarm?: Maybe<Farm>;
  updateFarmBaseline?: Maybe<FarmBaseline>;
  updateFootprintTarget?: Maybe<FootprintTarget>;
  updateIntervention?: Maybe<InterventionId>;
  updateNotification?: Maybe<Notification>;
  updateUser?: Maybe<UserId>;
  updateUserSettings?: Maybe<UserSettings>;
};


export type MutationBenchmarkRequestsArgs = {
  customerId: Scalars['ID'];
  input?: InputMaybe<BenchmarkRequestInput>;
};


export type MutationCopyBaselineToV2Args = {
  input: CopyBaselineInput;
};


export type MutationCreateAnimalSystemArgs = {
  farmId: Scalars['ID'];
  input: CreateAnimalSystemInput;
};


export type MutationCreateCompoundFeedArgs = {
  customerId: Scalars['ID'];
  input: CreateCompoundFeedInput;
};


export type MutationCreateCompoundFeedV2Args = {
  customerId: Scalars['ID'];
  input: CreateCompoundFeedInputV2;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateFacilityArgs = {
  customerId: Scalars['ID'];
  input: CreateFacilityInput;
};


export type MutationCreateFarmArgs = {
  customerId: Scalars['ID'];
  input: CreateFarmInput;
};


export type MutationCreateFarmBaselineArgs = {
  animalSystemId: Scalars['ID'];
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
  input: CreateFarmBaselineInput;
};


export type MutationCreateIncidentArgs = {
  input: CreateIncidentInput;
};


export type MutationCreateInterventionArgs = {
  animalSystemId: Scalars['ID'];
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
  input: CreateInterventionInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteAnimalSystemArgs = {
  animalSystemId: Scalars['ID'];
};


export type MutationDeleteCompoundFeedArgs = {
  customerId: Scalars['ID'];
  feedId: Scalars['ID'];
};


export type MutationDeleteCompoundFeedV2Args = {
  customerId: Scalars['ID'];
  feedId: Scalars['ID'];
};


export type MutationDeleteFacilityArgs = {
  customerId: Scalars['ID'];
  facilityId: Scalars['ID'];
};


export type MutationDeleteFarmArgs = {
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
};


export type MutationDeleteInterventionArgs = {
  SK: Scalars['String'];
  customerId: Scalars['ID'];
};


export type MutationDeleteNotificationArgs = {
  notificationID: Scalars['ID'];
};


export type MutationDeleteProductionDatasetArgs = {
  farmId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDuplicateFarmArgs = {
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
};


export type MutationRecalculateFootprintArgs = {
  customerId: Scalars['ID'];
  input: RecalculateFootprintInput;
};


export type MutationResendActivationArgs = {
  email: Scalars['String'];
};


export type MutationUpdateAnimalSystemArgs = {
  farmId: Scalars['ID'];
  input: UpdateAnimalSystemInput;
};


export type MutationUpdateCompoundFeedArgs = {
  customerId: Scalars['ID'];
  feedId: Scalars['ID'];
  input: CreateCompoundFeedInput;
};


export type MutationUpdateCompoundFeedV2Args = {
  customerId: Scalars['ID'];
  feedId: Scalars['ID'];
  input: UpdateCompoundFeedInputV2;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerDashboardTargetsArgs = {
  input?: InputMaybe<UpdateCustomerDashboardTargetsInput>;
};


export type MutationUpdateFacilityArgs = {
  customerId: Scalars['ID'];
  facilityId: Scalars['ID'];
  input: UpdateFacilityInput;
};


export type MutationUpdateFarmArgs = {
  customerId: Scalars['ID'];
  input: UpdateFarmInput;
};


export type MutationUpdateFarmBaselineArgs = {
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
  input: UpdateFarmBaselineInput;
};


export type MutationUpdateFootprintTargetArgs = {
  input?: InputMaybe<UpdateFootprintTargetInput>;
};


export type MutationUpdateInterventionArgs = {
  animalSystemId: Scalars['ID'];
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
  input: UpdateInterventionInput;
  oldName: Scalars['String'];
};


export type MutationUpdateNotificationArgs = {
  input: CreateNotificationInput;
  notificationID: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['ID'];
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
  segment: UserSettingsSegment;
};

export type Notification = {
  __typename?: 'Notification';
  active?: Maybe<Scalars['Boolean']>;
  attachementKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  expireDateTime?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  isWelcome?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  notificationType: Scalars['String'];
  startDateTime: Scalars['AWSDateTime'];
  targetGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type NotificationId = {
  __typename?: 'NotificationID';
  id: Scalars['ID'];
};

export type NutritionalAnalysis = {
  __typename?: 'NutritionalAnalysis';
  type: NutritionalAnalysisType;
  unit?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type NutritionalAnalysisInput = {
  type: NutritionalAnalysisType;
  unit?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export enum NutritionalAnalysisType {
  AshContent = 'ASH_CONTENT',
  ContainsMilk = 'CONTAINS_MILK',
  CrudeProteinContent = 'CRUDE_PROTEIN_CONTENT',
  DigestibleEnergyContent = 'DIGESTIBLE_ENERGY_CONTENT',
  DryMatterContent = 'DRY_MATTER_CONTENT',
  GrossEnergyContent = 'GROSS_ENERGY_CONTENT',
  MetabolizableEnergyContent = 'METABOLIZABLE_ENERGY_CONTENT',
  Milk = 'MILK',
  NeutralDetergentFiber = 'NEUTRAL_DETERGENT_FIBER',
  PhosphorusContent = 'PHOSPHORUS_CONTENT',
  PContent = 'P_CONTENT',
  UrinaryEnergyContent = 'URINARY_ENERGY_CONTENT'
}

export type OnSiteActivity = {
  __typename?: 'OnSiteActivity';
  amount: Scalars['Float'];
  resourceType: ResourceUseType;
};

export type OnSiteActivityInput = {
  amount: Scalars['Float'];
  resourceType: ResourceUseType;
};

export type Origin = {
  __typename?: 'Origin';
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type Partner = {
  __typename?: 'Partner';
  allowedMutations?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedQueries?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

export type Product = {
  __typename?: 'Product';
  name: Scalars['String'];
};

export type ProductInput = {
  name: Scalars['String'];
};

export enum ProductionType {
  CircularTanks = 'CIRCULAR_TANKS',
  ConcreteTanks = 'CONCRETE_TANKS',
  Extensive = 'EXTENSIVE',
  Greenhouse = 'GREENHOUSE',
  Intensive = 'INTENSIVE',
  Liner = 'LINER',
  Other = 'OTHER',
  RecirculationPonds = 'RECIRCULATION_PONDS',
  RecirculationTanks = 'RECIRCULATION_TANKS',
  SemiIntensive = 'SEMI_INTENSIVE',
  SemiLined = 'SEMI_LINED'
}

export enum Productivity {
  High = 'HIGH',
  Low = 'LOW'
}

export type Query = {
  __typename?: 'Query';
  calculateBovaer3NOP: BovaerCalculations;
  getCompoundFeedData?: Maybe<CompoundFeed>;
  getCompoundFeedIngredientNames?: Maybe<Array<Maybe<CompoundFeedIngredientName>>>;
  getCompoundFeedIngredientOrProxy?: Maybe<Array<Maybe<Match>>>;
  getCompoundFeedInputComponentData?: Maybe<CompoundFeedInputComponentsData>;
  getCompoundFeedList?: Maybe<ModelCompoundFeedConnection>;
  getCompoundFeedV2?: Maybe<CompoundFeedV2>;
  getCompoundFeedV2Addons?: Maybe<Array<Maybe<CompoundFeedAddonsV2>>>;
  getCompoundFeedV2CustomerAddons?: Maybe<Array<Maybe<CompoundFeedAddonsV2>>>;
  getCustomer?: Maybe<Customer>;
  getCustomerIngredients?: Maybe<CustomerIngredients>;
  getDashboardEmbedURL?: Maybe<DashboardUrl>;
  getFacility?: Maybe<Facility>;
  getFarm?: Maybe<Farm>;
  getFarmBaseline?: Maybe<FarmBaseline>;
  getFarmBaselineByReference?: Maybe<FarmBaseline>;
  getFootprint?: Maybe<Array<Maybe<Footprint>>>;
  getFootprintDashboard?: Maybe<Array<Maybe<Footprint>>>;
  getIncidentSignedUrl?: Maybe<SignedUrl>;
  getIngredientTransportDistance?: Maybe<Array<Maybe<IngredientTransportDistance>>>;
  getIntervention?: Maybe<Intervention>;
  getInterventionByReference?: Maybe<Intervention>;
  getMasterData: MasterData;
  getOrigins?: Maybe<Array<Maybe<Origin>>>;
  getResourceUsage?: Maybe<ModelResourceUsageConnection>;
  getSignedUrl?: Maybe<SignedUrl>;
  getSingleIngredientNames?: Maybe<Array<Maybe<SingleIngredientName>>>;
  getUser?: Maybe<User>;
  getUserSettings?: Maybe<UserSettings>;
  listAPITokens?: Maybe<ModelApiTokenConnection>;
  listAllNotifications?: Maybe<ModelNotificationConnection>;
  listCompoundFeedsV2?: Maybe<ModelCompoundFeedConnectionV2>;
  listCustomers?: Maybe<ModelCustomerConnection>;
  listCustomersWithBaselinesAndInterventionsWithFeedZero?: Maybe<ModelListCustomersWithBaselinesAndInterventionsWithFeedZero>;
  listFacilities?: Maybe<ModelFacilityConnection>;
  listFarmInterventions?: Maybe<ModelInterventionConnection>;
  listFarms?: Maybe<Array<Maybe<Farm>>>;
  listMyNotifications?: Maybe<ModelNotificationConnection>;
  listPartners?: Maybe<ModelPartnersConnection>;
  listUsers?: Maybe<ModelUserConnection>;
  retrieveDefaultDistances?: Maybe<ModelTransportDistanceConnection>;
  search?: Maybe<SearchResult>;
};


export type QueryCalculateBovaer3NopArgs = {
  input: BovaerCalculationsInput;
};


export type QueryGetCompoundFeedDataArgs = {
  customerId: Scalars['ID'];
  feedId: Scalars['ID'];
};


export type QueryGetCompoundFeedIngredientNamesArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetCompoundFeedIngredientOrProxyArgs = {
  customerId: Scalars['ID'];
  databaseFoundation?: InputMaybe<CompoundFeedDatabase>;
  requestedIngredients?: InputMaybe<Array<InputMaybe<RequestedIngredientV2Input>>>;
};


export type QueryGetCompoundFeedInputComponentDataArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetCompoundFeedListArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetCompoundFeedV2Args = {
  customerId: Scalars['ID'];
  feedId: Scalars['ID'];
};


export type QueryGetCompoundFeedV2AddonsArgs = {
  databaseFoundation: CompoundFeedDatabase;
};


export type QueryGetCompoundFeedV2CustomerAddonsArgs = {
  customerId: Scalars['ID'];
  databaseFoundation: CompoundFeedDatabase;
};


export type QueryGetCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryGetCustomerIngredientsArgs = {
  id: Scalars['ID'];
};


export type QueryGetDashboardEmbedUrlArgs = {
  dashboardName: Scalars['String'];
};


export type QueryGetFacilityArgs = {
  customerId: Scalars['ID'];
  facilityId: Scalars['ID'];
};


export type QueryGetFarmArgs = {
  id: Scalars['ID'];
};


export type QueryGetFarmBaselineArgs = {
  farmId: Scalars['ID'];
  name: Scalars['String'];
};


export type QueryGetFarmBaselineByReferenceArgs = {
  farmId: Scalars['ID'];
  reference: Scalars['String'];
};


export type QueryGetFootprintArgs = {
  customerId: Scalars['ID'];
  references: Array<Scalars['ID']>;
  type: FootPrintType;
};


export type QueryGetFootprintDashboardArgs = {
  customerId: Scalars['ID'];
  references: Array<Scalars['ID']>;
  type: FootPrintType;
};


export type QueryGetIncidentSignedUrlArgs = {
  key: Scalars['String'];
  method: Scalars['String'];
};


export type QueryGetIngredientTransportDistanceArgs = {
  customerId: Scalars['ID'];
  databaseFoundation?: InputMaybe<CompoundFeedDatabase>;
  feedmillLocationIsoCode?: InputMaybe<Scalars['String']>;
  ingredient?: InputMaybe<IngredientInput>;
};


export type QueryGetInterventionArgs = {
  animalSystemId: Scalars['ID'];
  customerId: Scalars['ID'];
  farmId: Scalars['ID'];
  name: Scalars['String'];
};


export type QueryGetInterventionByReferenceArgs = {
  farmId: Scalars['ID'];
  reference: Scalars['String'];
};


export type QueryGetMasterDataArgs = {
  animalType: AnimalType;
  customerId: Scalars['ID'];
};


export type QueryGetOriginsArgs = {
  customerId: Scalars['ID'];
};


export type QueryGetResourceUsageArgs = {
  customerId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  resourceId: Scalars['ID'];
  type: ResourceUsageType;
};


export type QueryGetSignedUrlArgs = {
  key: Scalars['String'];
  method: Scalars['String'];
};


export type QueryGetSingleIngredientNamesArgs = {
  animalType?: InputMaybe<AnimalType>;
  customerId: Scalars['ID'];
};


export type QueryGetUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserSettingsArgs = {
  setLastLogin?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListAllNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListCompoundFeedsV2Args = {
  customerId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListFacilitiesArgs = {
  customerId: Scalars['ID'];
};


export type QueryListFarmInterventionsArgs = {
  customerId: Scalars['ID'];
  farmId?: InputMaybe<Scalars['ID']>;
};


export type QueryListFarmsArgs = {
  customerId: Scalars['ID'];
};


export type QueryListMyNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  notificationType?: InputMaybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  filterAttribute?: InputMaybe<Scalars['String']>;
  filterValue?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryRetrieveDefaultDistancesArgs = {
  customerId: Scalars['ID'];
  feedMillLocation: Scalars['String'];
  ingredientID: Scalars['ID'];
  ingredientOrigin: Scalars['String'];
};


export type QuerySearchArgs = {
  q: Scalars['String'];
};

export type RecalculateFootprintInput = {
  datasets: Array<DatasetInput>;
};

export type RecalculateFootprintResult = {
  __typename?: 'RecalculateFootprintResult';
  errorMessage?: Maybe<Scalars['String']>;
  reference: Scalars['ID'];
  status: RecalculateFootprintStatusType;
  type: DatasetType;
};

export enum RecalculateFootprintStatusType {
  Error = 'ERROR',
  Started = 'STARTED'
}

export type RefrigerantInput = {
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export type RefrigerantItem = {
  __typename?: 'RefrigerantItem';
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export enum ReportingPeriod {
  Round = 'ROUND',
  Year = 'YEAR'
}

export type RequestedIngredient = {
  __typename?: 'RequestedIngredient';
  custom_name?: Maybe<Scalars['String']>;
  ingredient_name?: Maybe<IngredientName>;
  origin?: Maybe<Scalars['String']>;
};

export type RequestedIngredientInput = {
  custom_name?: InputMaybe<Scalars['String']>;
  ingredient_name: IngredientNameInput;
  origin: Scalars['String'];
};

export type RequestedIngredientV2Input = {
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
};

export type ResourceStages = {
  __typename?: 'ResourceStages';
  use?: Maybe<Scalars['ID']>;
  used: Scalars['ID'];
};

export type ResourceUsage = {
  __typename?: 'ResourceUsage';
  animalType: AnimalType;
  baseline?: Maybe<Scalars['ID']>;
  farmId: Scalars['ID'];
  internal?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy: Scalars['String'];
  name: Scalars['String'];
  productionProcessId: Scalars['ID'];
  reference: Scalars['ID'];
  resourceId: Scalars['ID'];
  stages?: Maybe<Array<Maybe<ResourceStages>>>;
  type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  year?: Maybe<Scalars['Int']>;
};

export enum ResourceUsageType {
  CompoundFeed = 'COMPOUND_FEED',
  Facility = 'FACILITY',
  LinkedStage = 'LINKED_STAGE'
}

export type ResourceUse = {
  __typename?: 'ResourceUse';
  coalUse?: Maybe<Scalars['Float']>;
  dieselUse?: Maybe<Scalars['Float']>;
  electricityPhotovoltaicUse?: Maybe<Scalars['Float']>;
  electricityUse?: Maybe<Scalars['Float']>;
  electricityWindUse?: Maybe<Scalars['Float']>;
  gasUse?: Maybe<Scalars['Float']>;
  gasolineUse?: Maybe<Scalars['Float']>;
  heavyFuelOilUse?: Maybe<Scalars['Float']>;
  propaneOrLpgUse?: Maybe<Scalars['Float']>;
  waterSource?: Maybe<WaterSourceType>;
  waterUse?: Maybe<Scalars['Float']>;
};

export type ResourceUseInput = {
  coalUse?: InputMaybe<Scalars['Float']>;
  dieselUse?: InputMaybe<Scalars['Float']>;
  electricityPhotovoltaicUse?: InputMaybe<Scalars['Float']>;
  electricityUse?: InputMaybe<Scalars['Float']>;
  electricityWindUse?: InputMaybe<Scalars['Float']>;
  gasUse?: InputMaybe<Scalars['Float']>;
  gasolineUse?: InputMaybe<Scalars['Float']>;
  heavyFuelOilUse?: InputMaybe<Scalars['Float']>;
  propaneOrLpgUse?: InputMaybe<Scalars['Float']>;
  waterSource?: InputMaybe<WaterSourceType>;
  waterUse?: InputMaybe<Scalars['Float']>;
};

export type ResourceUseIntervention = {
  __typename?: 'ResourceUseIntervention';
  coalUse?: Maybe<InterventionFloat>;
  dieselUse?: Maybe<InterventionFloat>;
  electricityPhotovoltaicUse?: Maybe<InterventionFloat>;
  electricityUse?: Maybe<InterventionFloat>;
  electricityWindUse?: Maybe<InterventionFloat>;
  gasUse?: Maybe<InterventionFloat>;
  gasolineUse?: Maybe<InterventionFloat>;
  heavyFuelOilUse?: Maybe<InterventionFloat>;
  propaneOrLpgUse?: Maybe<InterventionFloat>;
  waterSource?: Maybe<WaterSourceChange>;
  waterUse?: Maybe<InterventionFloat>;
};

export type ResourceUseInterventionInput = {
  coalUse?: InputMaybe<InterventionFloatInput>;
  dieselUse?: InputMaybe<InterventionFloatInput>;
  electricityPhotovoltaicUse?: InputMaybe<InterventionFloatInput>;
  electricityUse?: InputMaybe<InterventionFloatInput>;
  electricityWindUse?: InputMaybe<InterventionFloatInput>;
  gasUse?: InputMaybe<InterventionFloatInput>;
  gasolineUse?: InputMaybe<InterventionFloatInput>;
  heavyFuelOilUse?: InputMaybe<InterventionFloatInput>;
  propaneOrLpgUse?: InputMaybe<InterventionFloatInput>;
  waterSource?: InputMaybe<InterventionWaterSourceTypeInput>;
  waterUse?: InputMaybe<InterventionFloatInput>;
};

export enum ResourceUseType {
  Coal = 'COAL',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  ElectricityWind = 'ELECTRICITY_WIND',
  Gasoline = 'GASOLINE',
  HeavyFuelOil = 'HEAVY_FUEL_OIL',
  NaturalGas = 'NATURAL_GAS',
  Propane = 'PROPANE',
  PropaneOrLpg = 'PROPANE_OR_LPG',
  Water = 'WATER'
}

export type SearchResult = {
  __typename?: 'SearchResult';
  results: Array<Maybe<Scalars['ID']>>;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type SignedUrl = {
  __typename?: 'SignedUrl';
  key?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type SingleIngredientName = {
  __typename?: 'SingleIngredientName';
  group?: Maybe<Scalars['String']>;
  isMilk?: Maybe<Scalars['Boolean']>;
  isSilage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  targetSpecies: Array<Maybe<Scalars['String']>>;
};

export type Stage = {
  __typename?: 'Stage';
  endDate?: Maybe<Scalars['AWSDate']>;
  facilityId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pondName?: Maybe<Scalars['String']>;
  stageData: Scalars['AWSJSON'];
  stageDuration?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['AWSDate']>;
  type?: Maybe<StageType>;
};

export type StageInput = {
  animalProductivity?: InputMaybe<Productivity>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  facilityId?: InputMaybe<Scalars['String']>;
  facilityType?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pondName?: InputMaybe<Scalars['String']>;
  stageData: Scalars['AWSJSON'];
  stageDuration?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['AWSDate']>;
  type?: InputMaybe<StageType>;
};

export enum StageType {
  Breeding = 'BREEDING',
  ContinuousGrowing = 'CONTINUOUS_GROWING',
  DairyCow = 'DAIRY_COW',
  Fattening = 'FATTENING',
  Growing = 'GROWING',
  Hatching = 'HATCHING',
  Laying = 'LAYING',
  Nursery = 'NURSERY',
  Processing = 'PROCESSING'
}

export type SupplementsInput3Nop = {
  _3nopDosePerCowPerDay: Scalars['Float'];
  calculatedInput: Scalars['Float'];
  dmiAmountPerKg: Scalars['Float'];
  fatAmountPercentage: Scalars['Float'];
  name?: InputMaybe<Scalars['String']>;
  ndfAmountPercentage: Scalars['Float'];
  numberOfCowsCovered: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  value: Scalars['String'];
};

export enum TertiaryPackagingType {
  Pallet = 'PALLET',
  PlasticFilm = 'PLASTIC_FILM'
}

export type TextValuePair = {
  __typename?: 'TextValuePair';
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Transport = {
  __typename?: 'Transport';
  distance: Scalars['Float'];
  mode: TransportMode;
};

export type TransportInput = {
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type TypeUnitPair = {
  __typename?: 'TypeUnitPair';
  type: Scalars['String'];
  unit: Scalars['String'];
};

export type TypeUnitValue = {
  __typename?: 'TypeUnitValue';
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type TypeValueFloatInput = {
  type: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type UpdateAnimalSystemInput = {
  animalType: AnimalType;
  farmingMethod: FarmingMethod;
  id: Scalars['ID'];
  name: Scalars['String'];
  old_name: Scalars['String'];
  productionType?: InputMaybe<ProductionType>;
};

export type UpdateCompoundFeedInputV2 = {
  databaseFoundation?: InputMaybe<CompoundFeedDatabase>;
  feedmillLocation: Scalars['String'];
  ingredientUses?: InputMaybe<Array<IngredientUsesV2Input>>;
  name: Scalars['String'];
  nutritionalAnalysisData?: InputMaybe<Array<InputMaybe<NutritionalAnalysisInput>>>;
  oldName: Scalars['String'];
  onSiteActivities?: InputMaybe<Array<InputMaybe<OnSiteActivityInput>>>;
  outboundTransport?: InputMaybe<Array<InputMaybe<TransportInput>>>;
  targetSpecies: Array<InputMaybe<Scalars['String']>>;
  year: Scalars['Int'];
};

export type UpdateCustomerDashboardTargetsInput = {
  customerId: Scalars['ID'];
  dashboardTargets: Scalars['AWSJSON'];
};

export type UpdateCustomerInput = {
  SAPCustomerId?: InputMaybe<Scalars['String']>;
  allowedPartnerMutations?: InputMaybe<Array<InputMaybe<AllowedPartnerMutationsInput>>>;
  allowedPartnerQueries?: InputMaybe<Array<InputMaybe<AllowedPartnerQueriesInput>>>;
  canAccessPartner?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contractNumber?: InputMaybe<Scalars['String']>;
  farmsNumberLimit: Scalars['Int'];
  feedsVisible?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ingredients?: InputMaybe<CustomerIngredientsInput>;
  license?: InputMaybe<LicenseType>;
  licenseEndDate?: InputMaybe<Scalars['AWSDate']>;
  licenseStartDate?: InputMaybe<Scalars['AWSDate']>;
  name: Scalars['String'];
  old_name: Scalars['String'];
  partnerApiAccess?: InputMaybe<Scalars['Boolean']>;
  reference?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  tokenName?: InputMaybe<Scalars['String']>;
  tokenValue?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomerType>;
};

export type UpdateFacilityInput = {
  auxiliaryInputs: AuxiliaryFacilityInputs;
  currency: Currency;
  location: Scalars['String'];
  name: Scalars['String'];
  oldName: Scalars['String'];
  resourceUse: ResourceUseInput;
  targetSpecies: Array<Scalars['String']>;
  totalLiveweight: Scalars['Float'];
  type: FacilityType;
};

export type UpdateFarmBaselineInput = {
  age?: InputMaybe<Scalars['Float']>;
  averageAnnualTemperature?: InputMaybe<Scalars['Float']>;
  beddingUse?: InputMaybe<BeddingUseInput>;
  databaseFoundation?: InputMaybe<CompoundFeedDatabase>;
  description?: InputMaybe<Scalars['String']>;
  initialV2Copy?: InputMaybe<Scalars['Boolean']>;
  materialUse?: InputMaybe<MaterialUseInput>;
  name: Scalars['String'];
  oldName: Scalars['String'];
  reportingPeriod: ReportingPeriod;
  resourceUse: ResourceUseInput;
  roundsPerYear: Scalars['Float'];
  stages: Array<StageInput>;
  validFrom?: InputMaybe<Scalars['AWSDate']>;
  validTo?: InputMaybe<Scalars['AWSDate']>;
  year: Scalars['Int'];
};

export type UpdateFarmInput = {
  city: Scalars['String'];
  climateType?: InputMaybe<ClimateType>;
  country: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  old_name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
};

export type UpdateFootprintTargetInput = {
  reference: Scalars['ID'];
  target: Scalars['AWSJSON'];
  type: FootPrintType;
};

export type UpdateInterventionInput = {
  baseline: Scalars['String'];
  beddingUse?: InputMaybe<BeddingUseInterventionInput>;
  category: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  materialUse?: InputMaybe<MaterialUseInterventionInput>;
  name: Scalars['String'];
  resourceUse: ResourceUseInterventionInput;
  stages: Array<StageInput>;
};

export type UpdateUserInput = {
  customerAccess?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  farmAccess?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateUserSettingsInput = {
  databaseType?: InputMaybe<Scalars['String']>;
  defaultCustomer?: InputMaybe<Scalars['ID']>;
  footprint?: InputMaybe<Scalars['AWSJSON']>;
  numberOfLoginTimes?: InputMaybe<Scalars['Int']>;
  sampleDataAccessPopup?: InputMaybe<Scalars['Boolean']>;
  unitAverageAnnualTemperature?: InputMaybe<Scalars['String']>;
  unitBarnOutputMass?: InputMaybe<Scalars['String']>;
  unitBarnSmallQuantityUnits?: InputMaybe<Scalars['String']>;
  unitCompoundFeedNutritionalDataContent?: InputMaybe<Scalars['String']>;
  unitCompoundFeedOutputMass?: InputMaybe<Scalars['String']>;
  unitCompoundFeedWaterUse?: InputMaybe<Scalars['String']>;
  unitDieselUse?: InputMaybe<Scalars['String']>;
  unitElectricityUse?: InputMaybe<Scalars['String']>;
  unitEnvImpactPer?: InputMaybe<Scalars['String']>;
  unitFarmSize?: InputMaybe<Scalars['String']>;
  unitGasolineUse?: InputMaybe<Scalars['String']>;
  unitHeatUse?: InputMaybe<Scalars['String']>;
  unitHeavyFuelOilUse?: InputMaybe<Scalars['String']>;
  unitIngredientQuantityInputMass?: InputMaybe<Scalars['String']>;
  unitNaturalGasUse?: InputMaybe<Scalars['String']>;
  unitNutritionalGrossEnergy?: InputMaybe<Scalars['String']>;
  unitOutputMass?: InputMaybe<Scalars['String']>;
  unitTransportDistanceInlandWaterways?: InputMaybe<Scalars['String']>;
  unitTransportDistanceSea?: InputMaybe<Scalars['String']>;
  unitTransportDistanceTerrestrial?: InputMaybe<Scalars['String']>;
  unitWaterUse?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['AWSDateTime'];
  customerAccess?: Maybe<Array<Maybe<UserResourceAccess>>>;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  farmAccess?: Maybe<Array<Maybe<UserResourceAccess>>>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type UserId = {
  __typename?: 'UserID';
  id: Scalars['ID'];
};

export type UserResourceAccess = {
  __typename?: 'UserResourceAccess';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  databaseType?: Maybe<Scalars['String']>;
  defaultCustomer?: Maybe<Scalars['ID']>;
  footprint?: Maybe<Scalars['AWSJSON']>;
  lastLogin?: Maybe<Scalars['AWSDateTime']>;
  numberOfLoginTimes?: Maybe<Scalars['Int']>;
  sampleDataAccessPopup?: Maybe<Scalars['Boolean']>;
  unitAverageAnnualTemperature?: Maybe<Scalars['String']>;
  unitBarnOutputMass?: Maybe<Scalars['String']>;
  unitBarnSmallQuantityUnits?: Maybe<Scalars['String']>;
  unitCompoundFeedNutritionalDataContent?: Maybe<Scalars['String']>;
  unitCompoundFeedOutputMass?: Maybe<Scalars['String']>;
  unitCompoundFeedWaterUse?: Maybe<Scalars['String']>;
  unitDieselUse?: Maybe<Scalars['String']>;
  unitElectricityUse?: Maybe<Scalars['String']>;
  unitEnvImpactPer?: Maybe<Scalars['String']>;
  unitFarmSize?: Maybe<Scalars['String']>;
  unitGasolineUse?: Maybe<Scalars['String']>;
  unitHeatUse?: Maybe<Scalars['String']>;
  unitHeavyFuelOilUse?: Maybe<Scalars['String']>;
  unitIngredientQuantityInputMass?: Maybe<Scalars['String']>;
  unitNaturalGasUse?: Maybe<Scalars['String']>;
  unitNutritionalGrossEnergy?: Maybe<Scalars['String']>;
  unitOutputMass?: Maybe<Scalars['String']>;
  unitTransportDistanceInlandWaterways?: Maybe<Scalars['String']>;
  unitTransportDistanceSea?: Maybe<Scalars['String']>;
  unitTransportDistanceTerrestrial?: Maybe<Scalars['String']>;
  unitWaterUse?: Maybe<Scalars['String']>;
};

export enum UserSettingsSegment {
  Benchmarks = 'BENCHMARKS',
  Database = 'DATABASE',
  DefaultCustomer = 'DEFAULT_CUSTOMER',
  Footprint = 'FOOTPRINT',
  Units = 'UNITS'
}

export type WaterSourceChange = {
  __typename?: 'WaterSourceChange';
  value?: Maybe<WaterSourceType>;
};

export enum WaterSourceType {
  GroundWater = 'GROUND_WATER',
  SurfaceWater = 'SURFACE_WATER'
}

export enum BovaerAnimalType {
  Beef = 'BEEF',
  Dairy = 'DAIRY'
}
