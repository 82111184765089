import { Typography } from "@material-ui/core"
import { chartLegendStyles, DSM_DARK_GREY } from "../../../../_metronic/layout"

export type ChartLegendType = {
    label: string
    color: string
}

type ChartLegendProps = {
    legend: ChartLegendType[]
}

const ChartLegend = ({legend}: ChartLegendProps) => {
    const classes = chartLegendStyles()
    return (
        <div>
            {
                legend.map(({label, color}) => (
                        <div key={label} className={`${classes.legendContainer}`}>
                            <div className={`${classes.legendDot}`} style={{backgroundColor: color}} />
                            <Typography style={{marginLeft: '8px', fontWeight: 400, fontSize: '14px', color: DSM_DARK_GREY}}>{label}</Typography>
                        </div>
                    )
                )
            }
        </div>
    )
}

export default ChartLegend