import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from '../../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../../_metronic/layout';
import {
  AnimalType,
  InputAnimalOrigin,
} from '../../../../../../../graphql/generated/blonk/pigs';
import { StageType } from '../../../../../../../graphql/types';
import ReactHookDsmInput from '../../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../../modules/Helpers/ReactHookDsmSelect2';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../../helpers/helperTypes';
import {
  InternalSourcesInput as InternalSourcesInputType,
  PigBaseline,
} from '../../../../../models/Baseline/PigBaseline';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../../utils/obj-utils';
import { FormType, ListEntry } from '../../../common';

interface InternalInputProperties {
  stageIndex: number;
  itemIndex: number;
  stages: Array<ListEntry>;
  stageOriginAnimalType?: AnimalType | string | undefined;
  breedingStagesList: Array<string>;
  formType: FormType;
  stageType: StageType;
  isIntervention?: boolean;
  distanceUnit: string;
  removeHandler?: Maybe<Function>;
  baselineStageOriginAnimalType?: Maybe<AnimalType> | string | undefined;
}

const InternalSourcesInput: FC<InternalInputProperties> = ({
  stageIndex = 0,
  itemIndex = 0,
  stageType,
  stages,
  stageOriginAnimalType = '',
  breedingStagesList,
  formType,
  isIntervention = false,
  baselineStageOriginAnimalType = '',
  distanceUnit,
  removeHandler,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext();

  const propName = isIntervention
    ? 'internalSourcesAdditions'
    : 'internalSources';
  const fieldItemPrefix = `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`;
  const intl = useIntl();
  const newAnimalTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.NEW_ANIMALS.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.NEW_ANIMALS.TOOLTIP';
  const fromStageTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.FROM_STAGE.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.FROM_STAGE.TOOLTIP';
  const distanceTooltip =
    stageType === StageType.Breeding
      ? 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.BREEDING.TRANSPORT_DISTANCE.TOOLTIP'
      : 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.TRANSPORT_DISTANCE.TOOLTIP';

  const inputStageId = useWatch({
    name: `${fieldItemPrefix}.originStageId`,
    defaultValue: formContext.getValues(`${fieldItemPrefix}.originStageId`) as
      | string
      | undefined,
  }) as string;

  const updateStageOriginAnimalType = (e: ReactChangedType) => {
    // console.log(e);
    const newOriginAnimalType = e?.target?.value as string | undefined;
    formContext.setValue(
      `stages.${stageIndex}.stageData.input.originAnimalType`,
      newOriginAnimalType
    );
  };

  const isInputStageBreeding = breedingStagesList.includes(inputStageId);
  // console.log("stageOriginAnimalType",stageOriginAnimalType,isInputStageBreeding);
  if (isInputStageBreeding)
    formContext.setValue(
      `${fieldItemPrefix}.originAnimalType`,
      stageOriginAnimalType
    );

  const usedInputStages = (
    (formContext.getValues(
      `stages.${stageIndex}.stageData.input.${propName}`
    ) || []) as InternalSourcesInputType[]
  ).map((item) => item.originStageId);

  /* input stages should be filtered to display only stagess not already used, and not this stage itself */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const availableInputStages = stages?.filter(
    (item) =>
      item.value !== formContext.getValues(`stages.${stageIndex}.id`) &&
      (!usedInputStages.includes(item.value) || item.value === inputStageId)
  );

  // console.log("baselineStageOriginAnimalType in component",baselineStageOriginAnimalType, isIntervention);

  const revalidatePigsToAnotherStage = () => {
    const formData = formContext.getValues() as PigBaseline;

    // if input stage is changed, do a revalidation of pigsToStage field for all stages
    formData?.stages?.forEach((stage, index) => {
      if (stage.type !== StageType.Processing)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        formContext.trigger(`stages.${index}.stageData.output.pigsToStage`);
    });
  };

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.hasBreedingInput`}
        value={isInputStageBreeding ? 'true' : 'false'}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && removeHandler && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.originStageId`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE',
            })}
            disabled={formType === FormType.View}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FROM_STAGE.PLACEHOLDER',
            })}
            tooltip={intl.formatMessage({ id: fromStageTooltip })}
            options={availableInputStages}
            required
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.originStageId`
              ) as string
            }
            changeHandler={revalidatePigsToAnotherStage}
          />
        </div>
        {isInputStageBreeding && (
          <>
            <ReactHookDsmSelect
              name={`${fieldItemPrefix}.originAnimalType`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE',
              })}
              disabled={
                (isIntervention &&
                  baselineStageOriginAnimalType !== '' &&
                  baselineStageOriginAnimalType !== undefined) ||
                formType === FormType.View
              }
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.PLACEHOLDER',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE.TOOLTIP',
              })}
              options={enumToOptionsArrayWithTranslatedStrings(
                AnimalType,
                intl,
                'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ANIMAL_TYPE'
              )}
              changeHandler={updateStageOriginAnimalType}
              required
              defaultValue={stageOriginAnimalType}
            />

            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageAgeOfPigs`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVERAGE_AGE_PIGS',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.AVERAGE_AGE_PIGS.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({ id: newAnimalTooltip })}
              required
              type="number"
              defaultValue={
                formContext.getValues(`${fieldItemPrefix}.averageAgeOfPigs`) as
                  | string
                  | number
              }
            />
          </>
        )}
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberPigs`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NEW_ANIMALS',
          })}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({ id: newAnimalTooltip })}
          required
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.numberPigs`) as
              | string
              | number
          }
          type="number"
        />

        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTransport`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.ANIMAL_INPUT.TRANSPORT_DISTANCE',
          })}
          disabled={formType === FormType.View}
          adornment={distanceUnit}
          tooltip={intl.formatMessage({ id: distanceTooltip })}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.distanceTransport`) as
              | string
              | number
          }
          type="number"
        />
      </DsmGrid>
    </>
  );
};

export default InternalSourcesInput;
