import { React, useEffect } from 'react';
import { DsmButton, DsmCheckbox, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import { FormType } from '../../../sustell_15/components/FarmFlow/common';
import SustellNewSingleIngredientFeed from './NewSingleIngredientInput_v2';


const SustellSingleIngredientFeed = (props) => {
  const {
    label,
    availableFeedItems,
    availableOrigins,
    subFieldName,
    formState,
    updateTotalFeedIntake,
    animalType,
    stageType,
    fieldItemPrefix,
    singleIngredients,
    fieldsAppenRemoveObject = undefined,
    showSilageCheck = false,
  } = props;

  const formContext = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `${fieldItemPrefix}.${subFieldName}`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const getFields = () => {
    if (!fieldsAppenRemoveObject?.fields) {
      return fields;
    }
    return fieldsAppenRemoveObject?.fields;
  };

  const getAppend = () => {
    if (!fieldsAppenRemoveObject?.append) {
      return append;
    }
    return fieldsAppenRemoveObject?.append;
  };

  const getRemove = () => {
    if (!fieldsAppenRemoveObject?.remove) {
      return remove;
    }
    return fieldsAppenRemoveObject?.remove;
  };
  const classes = processAndStageStylesV2();
  const intl = useIntl();

  // For now there is no edit CF icon available
  // console.log('origins', availableOrigins);
  // const selectedCustomer = useSelector((state) => state.selectedCustomer?.value);
  // const [dsmFeedsVisible, setDsmFeedsVisible] = useState(false) ;

  // useEffect(() => {
  //   if (UserProfilePrefs.getInstance().isDSMEmployee() === undefined) {
  //     UserProfilePrefs.getInstance().fetchAuthenticatedUser().then(() => {
  //       setDsmFeedsVisible(!(!selectedCustomer?.feedsVisible && UserProfilePrefs.getInstance().isDSMEmployee()));
  //     })
  //   } else {
  //     setDsmFeedsVisible(!(!selectedCustomer?.feedsVisible && UserProfilePrefs.getInstance().isDSMEmployee()));
  //   }
  // }, []);

  useEffect(() => {
    if (updateTotalFeedIntake) updateTotalFeedIntake();
  }, [updateTotalFeedIntake]);

  const addItem = () => {
    getAppend()({ feedType: '', kgPerAnimal: '', origin: '', isSilage: undefined });
  };

  const removeItem = (selIndex) => {
    getRemove()(selIndex);
  };

  const getSilageDefaultValue = (index) => {
    // Ensure getting the updated value from formContext (if pass item via args it's not updated.)
    const item = formContext.getValues(`${fieldItemPrefix}.singleIngredients.${index}`)
    if (!item) return false

    const defaultValue = singleIngredients?.find((singleIngredient) => singleIngredient.text === item.feedType)
    if (!defaultValue) return false

    return item?.isSilage === undefined ? defaultValue.isSilage : item?.isSilage
  }

  const isShowSilageCheck = (index) => {
    // Ensure getting the updated value from formContext (if pass item via args it's not updated.)
    const item = formContext.getValues(`${fieldItemPrefix}.singleIngredients.${index}`)

    const defaultValue = singleIngredients?.find((singleIngredient) => singleIngredient.text === item.feedType)
    if (!defaultValue) return showSilageCheck

    return defaultValue.isMilk ? false : showSilageCheck
  }

  return (
    <div>
      {getFields().map(
        (item, index) =>
          !(formState === FormType.View && !item.feedType) && (
            <div style={{ marginBottom: showSilageCheck ? '16px' : '0px' }}>
              <SustellNewSingleIngredientFeed
                key={item.keyId}
                label={label}
                availableFeedItems={availableFeedItems}
                availableOrigins={availableOrigins}
                index={index}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName={subFieldName}
                feedTypeValue={item.feedType}
                updateTotalFeedIntake={updateTotalFeedIntake}
                formState={formState}
                animalType={animalType}
                stageType={stageType}
                removeHandler={() => removeItem(index)}
              />
    
              {isShowSilageCheck(index) && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <DsmCheckbox
                    name={`${fieldItemPrefix}.silage`}
                    defaultValue={getSilageDefaultValue(index)}
                    checked={getSilageDefaultValue(index)}
                    disabled={formState === FormType.View}
                    onDsmChange={(e) => {
                      formContext.setValue(
                        `${fieldItemPrefix}.singleIngredients.${index}.isSilage`,
                        e?.detail?.checked
                      );

                      
                    }}
                    label={intl.formatMessage({
                      id: `SUSTELL.STAGE.BEEF.FEED.SILAGE`,
                    })}
                  />
                  <Tooltip
                    title={intl.formatMessage({
                      id: `SUSTELL.STAGE.BEEF.FEED.SILAGE.TOOLTIP`,
                    })}
                    placement="left"
                    className='ml-3'
                  >
                    <DsmIcon
                      name="general/help-circle"
                      className={classes.inlineTooltipClass}
                      title=""
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          )
      )}
      <DsmButton
        variant="text"
        onClick={addItem}
        disabled={formState === FormType.View}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        Add another {label.toLowerCase()}
      </DsmButton>
    </div>
  );
};
export default SustellSingleIngredientFeed;
