import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric, explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonInterventionDataIn,
  interventionFloatValueInUserUnits,
} from './inCommonMapperSustell';
import lookupValues from '../../../../sustell_15/helpers/lookupValues';

const math = require('mathjs');

const mapInput = (stageData, stageInputFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  const stocking = stageData?.stocking;

  if (
    stocking?.number_of_stocked_animals?.value ||
    stocking?.number_of_stocked_animals?.value === 0
  ) {
    stageInputFormData.numberOfStockedAnimals =
      stocking.number_of_stocked_animals?.value;
    stageInputFormData.numberOfStockedAnimals_changeMetric =
      stocking.number_of_stocked_animals?.change_type || 'relative';
  }

  if (
    stocking?.average_weight?.value ||
    stocking?.average_weight?.value === 0
  ) {
    stageInputFormData.averageLiveWeightStockedAnimals = interventionFloatValueInUserUnits(
      stocking?.average_weight.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stocking.average_weight.change_type,
      outputMassPrecision
    );
    stageInputFormData.averageLiveWeightStockedAnimals_changeMetric =
      stocking.average_weight.change_type || 'relative';
  }

  if (
    stocking?.weight_of_stocked_animals ||
    stocking?.weight_of_stocked_animals === 0
  ) {
    stageInputFormData.weightOfStockedAnimals = interventionFloatValueInUserUnits(
      stocking?.weight_of_stocked_animals,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      'set',
      outputMassPrecision
    );
  }
};

const mapOutput = (stageData, stageOutputFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  const production = stageData?.production;

  if (production?.production?.value || production?.production?.value === 0) {
    stageOutputFormData.production = interventionFloatValueInUserUnits(
      production?.production?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.production_changeMetric,
      outputMassPrecision
    );
    stageOutputFormData.production_changeMetric =
      production?.production?.change_type || 'relative';
  }

  if (
    production?.average_weight?.value ||
    production?.average_weight?.value === 0
  ) {
    stageOutputFormData.averageWeight = interventionFloatValueInUserUnits(
      production?.average_weight?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.production_changeMetric,
      outputMassPrecision
    );
    stageOutputFormData.averageWeight_changeMetric =
      production?.average_weight?.change_type || 'relative';
  }

  const animals = stageData?.animals_present;
  if (
    animals?.animal_biomass_start_of_year?.value ||
    animals?.animal_biomass_start_of_year?.value === 0
  ) {
    stageOutputFormData.animalBiomassStartOfYear_changeMetric =
      animals?.animal_biomass_start_of_year?.change_type || 'relative';
    stageOutputFormData.animalBiomassStartOfYear = interventionFloatValueInUserUnits(
      animals?.animal_biomass_start_of_year?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.animalBiomassStartOfYear_changeMetric,
      outputMassPrecision
    );
  }

  if (
    animals?.average_live_weight_animals_present?.value ||
    animals?.average_live_weight_animals_present?.value === 0
  ) {
    stageOutputFormData.liveWeight_changeMetric =
      animals?.average_live_weight_animals_present?.change_type || 'relative';
    stageOutputFormData.liveWeight = interventionFloatValueInUserUnits(
      animals?.average_live_weight_animals_present?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.liveWeight_changeMetric,
      outputMassPrecision
    );
  }

  if (
    animals?.animal_biomass_end_of_year?.value ||
    animals?.animal_biomass_end_of_year?.value === 0
  ) {
    stageOutputFormData.animalBiomasEndOfYear_changeMetric =
      animals?.animal_biomass_end_of_year?.change_type || 'relative';
    stageOutputFormData.animalBiomasEndOfYear = interventionFloatValueInUserUnits(
      animals?.animal_biomass_end_of_year?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.animalBiomasEndOfYear_changeMetric,
      outputMassPrecision
    );
  }

  const lostAnimals = stageData?.lost_animals;
  if (
    lostAnimals?.mortalities?.value ||
    lostAnimals?.mortalities?.value === 0
  ) {
    stageOutputFormData.mortalities_changeMetric =
      lostAnimals?.mortalities?.change_type || 'relative';
    stageOutputFormData.mortalities = interventionFloatValueInUserUnits(
      lostAnimals?.mortalities?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.mortalities_changeMetric,
      outputMassPrecision
    );
  }

  if (lostAnimals?.escapees?.value || lostAnimals?.escapees?.value === 0) {
    stageOutputFormData.escapees_changeMetric =
      lostAnimals?.escapees?.change_type || 'relative';

    stageOutputFormData.escapees = interventionFloatValueInUserUnits(
      lostAnimals?.escapees?.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOutputFormData.escapees_changeMetric,
      outputMassPrecision
    );
  }
};

const mapUsedFeed = (stageData, stageFeedFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const feed = stageData?.feed;

  stageFeedFormData.compoundFeeds = [];
  if (feed?.feeds_used && Array.isArray(feed?.feeds_used)) {
    let compoundFeeds = [];
    feed?.feeds_used.forEach(oneFeedData => {
      const feedChangeMetric = oneFeedData.quantity?.change_type || 'relative';
      let value = oneFeedData.quantity?.value;
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      let oneCompoundFeed = {
        feedType: oneFeedData.entity_id,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      compoundFeeds.push(oneCompoundFeed);
    });
    stageFeedFormData.compoundFeeds = compoundFeeds;
  }

  //check feeds additional ration
  if (feed?.feeds_used_addition && Array.isArray(feed?.feeds_used_addition)) {
    let compoundFeedsAdditions = [];
    feed?.feeds_used_addition.forEach(addnlFeedData => {
      const feedChangeMetric = addnlFeedData.quantity?.change_type || 'set';
      let value = addnlFeedData.quantity?.value;
      if (value && !isNaN(value))
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          feedChangeMetric,
          precision
        );
      let oneCompoundFeed = {
        feedType: addnlFeedData.entity_id,
        kgPerAnimal: value,
        kgPerAnimal_changeMetric: feedChangeMetric,
      };
      compoundFeedsAdditions.push(oneCompoundFeed);
    });
    stageFeedFormData.compoundFeedsAdditions = compoundFeedsAdditions;
  }
};

const mapNutrient = (stageData, stageFeedFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const feed = stageData?.feed;

  const requiredNutritionalContent = {};
  const optionalNutritionalContent = {};

  if (feed) {
    lookupValues.nutrient_type?.forEach(item => {
      const intervItem = feed?.nutrient_composition?.find(
        iItem => iItem.nutrient_type === item.value
      );
      let value = intervItem?.amount;
      // this way, as type souldn't be undefined
      const type = item.value;
      const changeType =
        intervItem?.change_type || (intervItem ? 'relative' : 'set');

      if (type === 'gross_energy') {
        if (
          (value || value === 0) &&
          (changeType === 'set' || changeType === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          //only convert value when changeMetric is set and value exists
          value = math.round(
            explicitConvertValue(
              value,
              `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitBarnOutputMass}`,
              `${userUOM.unitNutritionalGrossEnergy }/${userUOM.unitBarnOutputMass}`
            ),
            3
          );
        }
      }

      if (item.mandatory) {
        requiredNutritionalContent[type] = value;
        requiredNutritionalContent[type + '_changeMetric'] = changeType;
      } else {
        optionalNutritionalContent[type] = value;
        optionalNutritionalContent[type + '_changeMetric'] = changeType;
      }
    });

    stageFeedFormData.requiredNutritionalContent = requiredNutritionalContent;
    stageFeedFormData.optionalNutritionalContent = optionalNutritionalContent;
  }
};

const mapFeed = (stageData, stageFeedFormData) => {
  mapUsedFeed(stageData, stageFeedFormData);
  mapNutrient(stageData, stageFeedFormData);

  const feed = stageData?.feed;
  if (feed?.efcr?.value || feed?.efcr?.value === 0) {
    stageFeedFormData.efcr = feed.efcr?.value;
    stageFeedFormData.efcr_changeMetric = feed.efcr?.change_type || 'relative';
  }
};

const mapTransport = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const transport = [];
  if (stageData?.transport && Array.isArray(stageData?.transport)) {
    stageData.transport.forEach(oneTransport => {
      let value = oneTransport.amount;
      if (oneTransport.type === 'electricity') {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitElectricityUse,
          defaultMetric.unitElectricityUse,
          oneTransport.change_type,
          3
        );
      } else {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitDieselUse,
          defaultMetric.unitDieselUse,
          oneTransport.change_type,
          3
        );
      }
      const oneTransportFormData = {
        boatName: oneTransport.boatName,
        type: oneTransport.type,
        amount: value,
        amount_changeMetric: oneTransport.change_type,
      };

      transport.push(oneTransportFormData);
    });
  }
  stageOperationsFormData.transport = transport;

  const transportAddition = [];
  if (
    stageData?.transport_addition &&
    Array.isArray(stageData.transport_addition)
  ) {
    stageData.transport_addition.forEach(oneTransport => {
      let value = oneTransport.amount;
      if (oneTransport.type === 'electricity') {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitElectricityUse,
          defaultMetric.unitElectricityUse,
          'set',
          3
        );
      } else {
        value = interventionFloatValueInUserUnits(
          value,
          userUOM.unitDieselUse,
          defaultMetric.unitDieselUse,
          'set',
          3
        );
      }

      const oneTransportFormData = {
        boatName: oneTransport.boatName,
        type: oneTransport.type,
        amount: value,
      };

      transportAddition.push(oneTransportFormData);
    });
  }
  stageOperationsFormData.transportAddition = transportAddition;
};

const mapLiceTretament = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const liceTretaments = [];
  if (
    stageData?.lice_treatment_use &&
    Array.isArray(stageData.lice_treatment_use)
  ) {
    stageData.lice_treatment_use.forEach(oneLiceTreatment => {
      const value = interventionFloatValueInUserUnits(
        oneLiceTreatment.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        oneLiceTreatment.change_type,
        precision
      );

      const oneLiceTreatmentFormData = {
        type: oneLiceTreatment.type,
        amount: value,
        amount_changeMetric: oneLiceTreatment.change_type,
      };

      liceTretaments.push(oneLiceTreatmentFormData);
    });
  }
  stageOperationsFormData.liceTreatment = liceTretaments;

  const liceTreatmentAddition = [];
  if (
    stageData?.lice_treatment_use_addition &&
    Array.isArray(stageData.lice_treatment_use_addition)
  ) {
    stageData.lice_treatment_use_addition.forEach(oneLiceTreatment => {
      const value = interventionFloatValueInUserUnits(
        oneLiceTreatment.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        'set',
        precision
      );

      const oneLiceFormData = {
        type: oneLiceTreatment.type,
        amount: value,
      };

      liceTreatmentAddition.push(oneLiceFormData);
    });
  }
  stageOperationsFormData.liceTreatmentAddition = liceTreatmentAddition;
};

const mapAntibioticsUse = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const antibiotics = [];
  if (stageData?.antibiotics_use && Array.isArray(stageData.antibiotics_use)) {
    stageData.antibiotics_use.forEach(oneAntibiotic => {
      const value = interventionFloatValueInUserUnits(
        oneAntibiotic.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        oneAntibiotic.change_type,
        precision
      );

      const oneAntibioticFormData = {
        type: oneAntibiotic.type,
        amount: value,
        amount_changeMetric: oneAntibiotic.change_type,
      };

      antibiotics.push(oneAntibioticFormData);
    });
  }
  stageOperationsFormData.anitibioticsUse = antibiotics;

  const antibioticsAddition = [];
  if (
    stageData?.antibiotics_use_addition &&
    Array.isArray(stageData.antibiotics_use_addition)
  ) {
    stageData.antibiotics_use_addition.forEach(oneAntibiotic => {
      const value = interventionFloatValueInUserUnits(
        oneAntibiotic.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        'set',
        precision
      );

      const oneAntibioticFormData = {
        type: oneAntibiotic.type,
        amount: value,
      };

      antibioticsAddition.push(oneAntibioticFormData);
    });
  }
  stageOperationsFormData.anitibioticsUseAddition = antibioticsAddition;
};

const mapMaterialsForFarmConstruction = (
  stageData,
  stageOperationsFormData
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  const materials = [];
  if (stageData?.materials && Array.isArray(stageData.materials)) {
    stageData.materials.forEach(oneMaterial => {
      const value = interventionFloatValueInUserUnits(
        oneMaterial.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        oneMaterial.change_type,
        precision
      );

      const oneMaterialFormData = {
        type: oneMaterial.type,
        amount: value,
        amount_changeMetric: oneMaterial.change_type || 'relative',
      };

      materials.push(oneMaterialFormData);
    });
  }
  stageOperationsFormData.materialsFarmConstruction = materials;

  const materialsAddition = [];
  if (
    stageData?.materials_addition &&
    Array.isArray(stageData.materials_addition)
  ) {
    stageData.materials_addition.forEach(oneMaterial => {
      const value = interventionFloatValueInUserUnits(
        oneMaterial.amount,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        'set',
        precision
      );

      const oneMaterialFormData = {
        type: oneMaterial.type,
        amount: value,
      };

      materialsAddition.push(oneMaterialFormData);
    });
  }
  stageOperationsFormData.materialsFarmConstructionAddition = materialsAddition;
};

const mapOperations = (stageData, stageOperationsFormData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  
  if (stageData?.antifouling?.value || stageData?.antifouling?.value === 0) {
      stageOperationsFormData.antifouling_changeMetric =
      stageData.antifouling.change_type || 'relative';
      stageOperationsFormData.antifouling = interventionFloatValueInUserUnits(
      stageData.antifouling.value,
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      stageOperationsFormData.antifouling_changeMetric,
      outputMassPrecision
    );
  }

  mapTransport(stageData, stageOperationsFormData);
  mapLiceTretament(stageData, stageOperationsFormData);
  mapAntibioticsUse(stageData, stageOperationsFormData);
  mapMaterialsForFarmConstruction(stageData, stageOperationsFormData);
};

const mapSalmonInterventionDataInSustell = (inData) => {
  const formData = mapCommonInterventionDataIn(inData);

  if (!formData.resourceUse) formData.resourceUse = {};

  formData.resourceUse.waterSource = inData.resourceUse?.waterSource?.value?.toLowerCase();

  inData.stages.forEach((stage, index) => {
    const stageData = JSON.parse(stage.stageData) || {};
    if (formData.stages[index]) {
      const stageInputFormPart = formData.stages[index]?.stageData?.input;
      if (stageInputFormPart) mapInput(stageData, stageInputFormPart);

      const stageFeedFormPart = formData.stages[index]?.stageData?.feed;
      if (stageFeedFormPart) mapFeed(stageData, stageFeedFormPart);

      if (formData.stages[index]?.stageData) {
        formData.stages[index].stageData.operations = {};
        const stageOperationsFormPart =
          formData.stages[index]?.stageData?.operations;
        mapOperations(stageData, stageOperationsFormPart);
      }

      const stageOutputFormPart = formData.stages[index]?.stageData?.output;
      if (stageOutputFormPart) mapOutput(stageData, stageOutputFormPart);

      formData.stages[index].productionSystem =
        stageData?.housing_description?.housing_type;
    }
  });
  return formData;
};

export default mapSalmonInterventionDataInSustell