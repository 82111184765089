/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { DsmModalCustomEvent } from '@dsm-dcs/design-system';
import { DsmButton, DsmIcon, DsmModal } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import useStyles from '../../../modules/UserProfile/profileStyles/myProfileStyles';

interface DeleteConfirmationDialogProps {
  open: boolean;
  confirmHandler: () => void;
  cancelHandler: () => void;
  loading?: boolean;
  itemName?: string;
  itemType: string;
  dependencies?: any;
  customText: string;
}

const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  loading = false,
  confirmHandler,
  cancelHandler,
  itemName,
  itemType,
  dependencies,
  customText,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const dependencyEmpty = () => !dependencies || dependencies?.length === 0;

  const dependencyMessage = () => (
    <>
      {intl.formatMessage(
        { id: `SUSTELL.DELETE.UNABLE_${itemType.toUpperCase()}` },
        { deleteItem: itemName, br: <br /> }
      )}
      <br />
      {dependencies?.map(
        (
          dep:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        ) => (
          <React.Fragment key={`dep_item_${itemName}_${dep}`}>
            <span>{dep}</span>
            <br />
          </React.Fragment>
        )
      )}
    </>
  );

  return (
    <div>
      {dependencyEmpty() ? (
        <DsmModal
          open={open}
          style={{ width: '494px !important' }}
          onDsmClosed={(event: DsmModalCustomEvent<boolean>) => {
            if (!event.detail) {
              cancelHandler();
            }
          }}
        >
          <div
            id="icon"
            slot="content"
            className={classes.deleteModalIconHolder}
          >
            <DsmIcon
              name="general/trash-01"
              className={classes.deleteModalIcon}
            />
          </div>
          <div id="title" slot="content" className={classes.deleteModalTitle}>
            {intl.formatMessage(
              { id: 'SUSTELL.DELETE.TITLE' },
              { deleteItemType: itemType }
            )}
          </div>
          <div
            id="subHeard"
            slot="content"
            style={{ fontStyle: 'normal', fontWeight: 500 }}
          >
            {intl.formatMessage(
              { id: 'SUSTELL.DELETE.CONFIRM' },
              { deleteItemType: itemType }
            )}
          </div>
          <div id="body" slot="content">
            {customText}
          </div>
          <DsmButton
            variant="secondary"
            width-full="true"
            slot="actions"
            onClick={cancelHandler}
          >
            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          </DsmButton>
          <DsmButton
            variant="primary"
            destructive
            width-full="true"
            slot="actions"
            disabled={loading}
            onClick={confirmHandler}
          >
            <DsmIcon
              slot="before"
              name="general/trash-01"
              style={{ paddingRight: '5px', height: '20px', width: '20px' }}
            />
            <span className={classes.saveBtnTxt}>
              {intl.formatMessage({ id: 'GENERAL.DELETE' })}
            </span>
          </DsmButton>
        </DsmModal>
      ) : (
        <DsmModal
          id="deleteConfirmationModal"
          open={open}
          header={intl.formatMessage(
            { id: 'SUSTELL.DELETE.TITLE' },
            { deleteItemType: itemType }
          )}
          icon="general/trash-01"
          style={{ width: '480px !important' }}
          onDsmClosed={(event: DsmModalCustomEvent<boolean>) => {
            if (!event.detail) {
              cancelHandler();
            }
          }}
        >
          <div slot="content">{dependencyMessage()}</div>
          <DsmButton
            variant="primary"
            width-full="true"
            slot="actions"
            onClick={cancelHandler}
          >
            {intl.formatMessage({ id: 'GENERAL.OK' })}
          </DsmButton>
        </DsmModal>
      )}
    </div>
  );
};

export default DeleteConfirmationDialog;
