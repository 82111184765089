import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { floatValueInDefaultUnit } from '../../Baseline/mapper/floatHelperFunctions';
import lookupValues from '../../../../sustell_15/helpers/lookupValues';
import {
  setInterventionFloatValue,
  mapCommonInterventionDataOutSustell,
  mapResourceUse,
} from './outCommonMapperSustell';

const math = require('mathjs');

const ANIMAL_TYPE = 'Salmon';

const mapProduction = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (!payloadObj.production) payloadObj.production = {};

  setInterventionFloatValue(
    payloadObj.production,
    'production',
    formData,
    'production',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.production,
    'average_weight',
    formData,
    'averageWeight',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapStocking = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  // if (!payloadObj.stocking)
  //   payloadObj.stocking = {};

  let weightOfStockedAnimalsValue = parseFloat(formData.weightOfStockedAnimals);
  if (
    (weightOfStockedAnimalsValue || weightOfStockedAnimalsValue === 0) &&
    userUOM.unitOutputMass !== defaultMetric.unitOutputMass
  ) {
    // only convert value when changeMetric is set and value exists
    weightOfStockedAnimalsValue = floatValueInDefaultUnit(
      weightOfStockedAnimalsValue,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      3
    );
  }

  payloadObj.stocking = {
    number_of_stocked_animals: {
      value: parseFloat(formData.numberOfStockedAnimals),
      change_type: formData.numberOfStockedAnimals_changeMetric,
    },
    weight_of_stocked_animals: weightOfStockedAnimalsValue,
  };
  setInterventionFloatValue(
    payloadObj.stocking,
    'average_weight',
    formData,
    'averageLiveWeightStockedAnimals',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapAntifouling = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (formData.antifouling || formData.antifouling === 0) {
    setInterventionFloatValue(
      payloadObj,
      'antifouling',
      formData,
      'antifouling',
      userUOM.unitOutputMass,
      defaultMetric.unitOutputMass,
      3
    );
  }
};

const mapLostAnimals = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (!payloadObj.lost_animals) payloadObj.lost_animals = {};

  setInterventionFloatValue(
    payloadObj.lost_animals,
    'mortalities',
    formData,
    'mortalities',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.lost_animals,
    'escapees',
    formData,
    'escapees',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapAnimalsPresent = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (!payloadObj.animals_present) payloadObj.animals_present = {};

  setInterventionFloatValue(
    payloadObj.animals_present,
    'animal_biomass_start_of_year',
    formData,
    'animalBiomassStartOfYear',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.animals_present,
    'average_live_weight_animals_present',
    formData,
    'liveWeight',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );

  setInterventionFloatValue(
    payloadObj.animals_present,
    'animal_biomass_end_of_year',
    formData,
    'animalBiomasEndOfYear',
    userUOM.unitOutputMass,
    defaultMetric.unitOutputMass,
    3
  );
};

const mapAntibioticsUse = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  if (formData.anitibioticsUse && Array.isArray(formData.anitibioticsUse)) {
    formData.anitibioticsUse.forEach(item => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            3
          );
        }

        if(value || value === 0) {
          const input = {
            type: item.type,
            amount: value,
            change_type: item.amount_changeMetric,
          };
          payloadObj.antibiotics_use.push(input);
        }
      }
    });
  }

  // additional antibiotics
  if (
    formData.anitibioticsUseAddition &&
    Array.isArray(formData.anitibioticsUseAddition)
  ) {
    const anitibioticsUseAddition = [];
    formData.anitibioticsUseAddition.forEach(item => {
      const changeMetric = item.quantity?.change_type || 'set'; // but for addiotional feeds it must be 'set'
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute') &&
        userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
      ) {
        value = floatValueInDefaultUnit(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          3
        );
      }

      if(value || value === 0) {
        const input = {
          type: item.type,
          amount: value,
        };
        anitibioticsUseAddition.push(input);
      }
    });
    payloadObj.antibiotics_use_addition = anitibioticsUseAddition;
  }
};

const mapTransport = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (formData.transport && Array.isArray(formData.transport)) {
    formData.transport.forEach(item => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute')
        ) {
          if (item.type === 'electricity') {
            if (userUOM.unitElectricityUse !== defaultMetric.unitElectricityUse)
              value = floatValueInDefaultUnit(
                value,
                userUOM.unitElectricityUse,
                defaultMetric.unitElectricityUse,
                3
              );
          } else if (userUOM.unitDieselUse !== defaultMetric.unitDieselUse)
            value = floatValueInDefaultUnit(
              value,
              userUOM.unitDieselUse,
              defaultMetric.unitDieselUse,
              3
            );
        }

        if(value || value === 0) {
          const input = {
            boatName: item?.boatName,
            type: item.type,
            amount: value,
            change_type: item.amount_changeMetric,
          };

          payloadObj.transport.push(input);
        }
      }
    });
  }

  // additional transportAddition
  if (formData.transportAddition && Array.isArray(formData.transportAddition)) {
    const transportAddition = [];
    formData.transportAddition.forEach(item => {
      const changeMetric = item.quantity?.change_type || 'set';
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute')
      ) {
        if (item.type === 'electricity') {
          if (userUOM.unitElectricityUse !== defaultMetric.unitElectricityUse)
            value = floatValueInDefaultUnit(
              value,
              userUOM.unitElectricityUse,
              defaultMetric.unitElectricityUse,
              3
            );
        } else if (userUOM.unitDieselUse !== defaultMetric.unitDieselUse)
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitDieselUse,
            defaultMetric.unitDieselUse,
            3
          );
      }

      if(value || value === 0) {
        const input = {
          boatName: item?.boatName,
          type: item.type,
          amount: value,
        };
        transportAddition.push(input);
      }
    });
    payloadObj.transport_addition = transportAddition;
  }
};

const mapMaterialsForFarmConstruction = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (
    formData.materialsFarmConstruction &&
    Array.isArray(formData.materialsFarmConstruction)
  ) {
    formData.materialsFarmConstruction.forEach(item => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          //only convert value when changeMetric is set and value exists
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            3
          );
        }

        if(value || value === 0) {
          const input = {
            type: item.type,
            amount: value,
            change_type: item.amount_changeMetric,
          };
          payloadObj.materials.push(input);
        }
      }
    });
  }

  // additional materials
  if (
    formData.materialsFarmConstructionAddition &&
    Array.isArray(formData.materialsFarmConstructionAddition)
  ) {
    const materialsFarmConstructionAddition = [];
    formData.materialsFarmConstructionAddition.forEach(item => {
      const changeMetric = item.quantity?.change_type || 'set'; // but for addiotional feeds it must be 'set'
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute') &&
        userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
      ) {
        //only convert value when changeMetric is set and value exists
        value = floatValueInDefaultUnit(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          3
        );
      }

      if(value || value === 0) {
        const input = {
          type: item.type,
          amount: value,
        };
        materialsFarmConstructionAddition.push(input);
      }
    });
    payloadObj.materials_addition = materialsFarmConstructionAddition;
  }
};

const mapLiceTreatment = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  if (formData.liceTreatment && Array.isArray(formData.liceTreatment)) {
    formData.liceTreatment.forEach(item => {
      if (item.type && 'amount' in item) {
        let value = parseFloat(item.amount);
        if (
          (value || value === 0) &&
          (item.amount_changeMetric === 'set' ||
            item.amount_changeMetric === 'absolute') &&
          userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
        ) {
          value = floatValueInDefaultUnit(
            value,
            userUOM.unitBarnOutputMass,
            defaultMetric.unitBarnOutputMass,
            3
          );
        }
        if(value || value === 0) {
          const input = {
            type: item.type,
            amount: value,
            change_type: item.amount_changeMetric,
          };
          payloadObj.lice_treatment_use.push(input);
        }
      }
    });
  }

  if (
    formData.liceTreatmentAddition &&
    Array.isArray(formData.liceTreatmentAddition)
  ) {
    const liceTreatmentAddition = [];
    formData.liceTreatmentAddition.forEach(item => {
      const changeMetric = item.quantity?.change_type || 'set'; // but for addiotional feeds it must be 'set'
      let value = parseFloat(item.amount);
      if (
        (value || value === 0) &&
        (changeMetric === 'set' || changeMetric === 'absolute') &&
        userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass
      ) {
        // only convert value when changeMetric is set and value exists
        value = floatValueInDefaultUnit(
          value,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          3
        );
      }

      if(value || value === 0) {
        const input = {
          type: item.type,
          amount: value,
        };
        liceTreatmentAddition.push(input);
      }
    });
    payloadObj.lice_treatment_use_addition = liceTreatmentAddition;
  }
};

const mapNutrient = (formData, payloadObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  lookupValues.nutrient_type
    .filter(item => item.mandatory)
    .forEach(nutrientType => {
      if (
        formData.requiredNutritionalContent && (
        formData.requiredNutritionalContent[nutrientType.value] ||
        formData.requiredNutritionalContent[nutrientType.value] === 0)
      ) {
        payloadObj.feed.nutrient_composition.push({
          nutrient_type: nutrientType.value,
          amount: parseFloat(
            formData.requiredNutritionalContent[nutrientType.value]
          ),
          change_type:
            formData.requiredNutritionalContent[
              `${nutrientType.value}_changeMetric`
            ],
        });
      }
    });

  lookupValues.nutrient_type
    .filter(item => !item.mandatory)
    .forEach(nutrientType => {
      if (
        formData.optionalNutritionalContent && 
        (formData.optionalNutritionalContent[nutrientType.value] ||
        formData.optionalNutritionalContent[nutrientType.value] === 0)
      ) {
        let value = parseFloat(
          formData.optionalNutritionalContent[nutrientType.value]
        );
        const metric =
          formData.optionalNutritionalContent[
            `${nutrientType.value}_changeMetric`
          ];

        if (nutrientType.value === 'gross_energy') {
          if (
            (value || value === 0) &&
            (metric === 'set' || metric === 'absolute') &&
            (userUOM.unitNutritionalGrossEnergy !==
              defaultMetric.unitNutritionalGrossEnergy ||
              userUOM.unitBarnOutputMass !== defaultMetric.unitBarnOutputMass)
          ) {
            // only convert value when changeMetric is set and value exists
            value = math.round(
              math
                .unit(
                  value,
                  userUOM.unitNutritionalGrossEnergy +
                    '/' +
                    userUOM.unitBarnOutputMass
                )
                .to(
                  defaultMetric.unitNutritionalGrossEnergy +
                    '/' +
                    defaultMetric.unitBarnOutputMass
                )
                .toNumber(),
              3
            );
          }
        }

        if(value || value === 0) {
          payloadObj.feed.nutrient_composition.push({
            nutrient_type: nutrientType.value,
            amount: value,
            change_type: metric,
          });
        }
      }
    });
};

const mapFeed = (formData, payloadObj) => {
  // add additional mapping for compound feed
  payloadObj?.ration?.compound_feed_uses?.forEach(compoundFeed => {
    payloadObj.feed.feeds_used.push({ ...compoundFeed });
  });

  payloadObj?.ration?.compound_feed_uses_additions?.forEach(compoundFeed => {
    payloadObj.feed.feeds_used_addition.push({
      ...compoundFeed,
    });
  });

  mapNutrient(formData, payloadObj);

  if (formData.efcr || formData.efcr === 0) {
    payloadObj.feed.efcr = {
      value: parseFloat(formData.efcr),
      change_type: formData.efcr_changeMetric,
    };
  }
};

const mapSalmonInterventionDataOutSustell = formData => {
  const payloadObj = mapCommonInterventionDataOutSustell(formData);
  mapResourceUse(formData, payloadObj, ['electricity', 'water']);
  if (!payloadObj.resourceUse) payloadObj.resourceUse = {};

  payloadObj.resourceUse.waterSource = {
    value: formData?.resourceUse?.waterSource
      ? formData?.resourceUse?.waterSource?.toUpperCase()
      : null,
  };

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if (payloadObj.stages[index]) {
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const {stageData} = stageGeneralData;
        stageData.animal_group.animal_type = ANIMAL_TYPE;

        if (formStageData?.input) {
          mapStocking(formStageData?.input, stageData);
        }

        if (formStageData?.output) {
          mapProduction(formStageData?.output, stageData);
          mapAnimalsPresent(formStageData?.output, stageData);
          mapLostAnimals(formStageData?.output, stageData);
        }

        stageData.feed = {
          feeds_used: [],
          feeds_used_addition: [],
          nutrient_composition: [],
        };

        if (formStageData?.feed) mapFeed(formStageData?.feed, stageData);

        // salmon does not use ration
        if (stageData.ration) delete stageData.ration;

        stageData.antibiotics_use = [];
        stageData.antibiotics_use_addition = [];
        stageData.transport = [];
        stageData.transport_addition = [];
        stageData.materials = [];
        stageData.materials_addition = [];
        stageData.lice_treatment_use = [];
        stageData.lice_treatment_use_addition = [];

        if (formStageData?.operations) {
          mapAntifouling(formStageData?.operations, stageData);
          mapAntibioticsUse(formStageData?.operations, stageData);
          mapTransport(formStageData?.operations, stageData);
          mapMaterialsForFarmConstruction(formStageData?.operations, stageData);
          mapLiceTreatment(formStageData?.operations, stageData);
        }

        // // set housing block of data
        // stageData.housing_description = {
        //   housing_type: 'housed',
        // };
        // stageData.housing_system = formDataForOneStage.productionSystem;
      }
    });

  payloadObj.stages.forEach(item => {
    const {stageData} = item;
    item.stageData = JSON.stringify(stageData);
  });
  // console.log("payloadObj", payloadObj)
  return payloadObj;
};

export default mapSalmonInterventionDataOutSustell
