import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric, explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';
import {
  mapCommonProductionDataOutSustell,
} from './outCommonMapperSustell';
import { floatValueInDefaultUnit } from './floatHelperFunctions'; 

const math = require('mathjs');

const ANIMAL_TYPE = 'Salmon';

const mapSalmonProductionDataOutSustell = (formData, isDraft = false ) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();

  const payloadObj = mapCommonProductionDataOutSustell(formData, isDraft);

  // map resources
  // was farm_activities payloadObj.farm_activities = {
  payloadObj.resourceUse = {
    electricityUse: floatValueInDefaultUnit(
      formData.resourceUse.electricityUse,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    ),
    waterUse: floatValueInDefaultUnit(
      formData.resourceUse.waterUse,
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse
    ),
    waterSource: formData.resourceUse.waterSource?.toUpperCase() || '' 
  };

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if(payloadObj.stages[index]!==undefined){
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const {stageData} = stageGeneralData;
        stageData.animal_group.animal_type = ANIMAL_TYPE;
        
        // process input block
        // (purchasedAnimalsLayingHens, mortalityRate, averageLiveweightMortality)
        const inputBlockData = formStageData?.input;
        if(inputBlockData){
          stageData.stocking = {
            number_of_stocked_animals: parseFloat(inputBlockData.numberOfStockedAnimals),
            weight_of_stocked_animals: floatValueInDefaultUnit(
              inputBlockData.weightOfStockedAnimals,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            average_weight: floatValueInDefaultUnit(
              inputBlockData.averageLiveWeightStockedAnimals,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };
        }

        // process output block
        const outputBlockData = formStageData?.output;

        if(outputBlockData){
          stageData.production = {
            production: floatValueInDefaultUnit(
              outputBlockData.production,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            average_weight: floatValueInDefaultUnit(
              outputBlockData.averageWeight,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };

        
          stageData.lost_animals = {
            mortalities: floatValueInDefaultUnit(
              outputBlockData.mortalities,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            escapees: floatValueInDefaultUnit(
              outputBlockData.escapees,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };

          stageData.animals_present = {
            animal_biomass_start_of_year: floatValueInDefaultUnit(
              outputBlockData.animalBiomassStartOfYear,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            average_live_weight_animals_present: floatValueInDefaultUnit(
              outputBlockData.liveWeight,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
            animal_biomass_end_of_year: floatValueInDefaultUnit(
              outputBlockData.animalBiomasEndOfYear,
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass,
              outputMassPrecision
            ),
          };
        }

        // set housing block of data
        stageData.housing_system = formDataForOneStage.productionSystem;

        stageData.feed = {
          feeds_used: [],
          nutrient_composition: []
        }
        stageData.transport = [];
        // stageData.emissions = [];
        stageData.materials = [];
        stageData.antibiotics_use = [];
        stageData.lice_treatment_use = [];

        // map activities - operations
        const operationsBlockData = formStageData?.operations;
        if(operationsBlockData){
          if (operationsBlockData.antifouling || operationsBlockData.antifouling === 0) {
            stageData.antifouling = floatValueInDefaultUnit(
              operationsBlockData.antifouling,
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass
            );
          }

          // materials
          if (
            operationsBlockData.materialsFarmConstruction &&
            Array.isArray(operationsBlockData.materialsFarmConstruction)
          ) {
            operationsBlockData.materialsFarmConstruction.forEach(item => {
              if (item.type && 'amount' in item) {
                const input = {
                  type: item.type,
                  amount: floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitBarnOutputMass,
                    defaultMetric.unitBarnOutputMass
                  ),
                };
                stageData.materials.push(input);
              }
            });
          }
        
          
        
          if (operationsBlockData.anitibioticsUse && Array.isArray(operationsBlockData.anitibioticsUse)) {
            operationsBlockData.anitibioticsUse.forEach(item => {
              if (item.type && 'amount' in item) {
                const input = {
                  type: item.type,
                  amount: floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitBarnOutputMass,
                    defaultMetric.unitBarnOutputMass
                  ),
                };
                stageData.antibiotics_use.push(input);
              }
            });
          }
        
          if (operationsBlockData.liceTreatment && Array.isArray(operationsBlockData.liceTreatment)) {
            operationsBlockData.liceTreatment.forEach(item => {
              if (item.type && 'amount' in item) {
                const input = {
                  type: item.type,
                  amount: floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitBarnOutputMass,
                    defaultMetric.unitBarnOutputMass
                  ),
                };
                stageData.lice_treatment_use.push(input);
              }
            });
          }
        
          if (operationsBlockData.transport && Array.isArray(operationsBlockData.transport)) {
            operationsBlockData.transport.forEach(item => {
              if (item.type && 'amount' in item) {
                let value;
                if (item.type === 'electricity')
                  value = floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitElectricityUse,
                    defaultMetric.unitElectricityUse
                  );
                else
                  value = floatValueInDefaultUnit(
                    item.amount,
                    userUOM.unitDieselUse,
                    defaultMetric.unitDieselUse
                  );
        
                const input = {
                  boatName: item?.boatName,
                  type: item.type,
                  amount: value,
                };
                stageData.transport.push(input);
              }
            });
          }
        }
        // feed items
        // additional feed processing for Salmon
        const feedBlockData = formStageData?.feed;
        if(feedBlockData){
          if (feedBlockData.efcr || feedBlockData.efcr === 0) {
            stageData.feed.efcr = parseFloat(feedBlockData.efcr);
          }
          // add additional mapping for compund feed
          // map stageData.ration to feed 
          stageData?.ration?.compound_feed_uses?.forEach(compoundFeed => {
            stageData.feed.feeds_used.push({
              compound_feed_id: compoundFeed.entity_id,
              amount: compoundFeed.quantity,
            });
          })
          // salmon does not use ration
          if(stageData.ration)
            delete stageData.ration;
        
          if (feedBlockData.requiredNutritionalContent) {
            const nutritionalItems = Object.keys(feedBlockData.requiredNutritionalContent);
            // console.log(nutritionalItems);
            nutritionalItems.forEach(nutritionalItem => {
              if (
                feedBlockData.requiredNutritionalContent[nutritionalItem] ||
                feedBlockData.requiredNutritionalContent[nutritionalItem] === 0
              ) {
                stageData.feed.nutrient_composition.push({
                  nutrient_type: nutritionalItem,
                  amount: feedBlockData.requiredNutritionalContent[nutritionalItem],
                });
              }
            })
          }
      
          if (feedBlockData.optionalNutritionalContent) {
            const nutritionalItems = Object.keys(feedBlockData.optionalNutritionalContent);
            // console.log(nutritionalItems);
            nutritionalItems.forEach(nutritionalItem => {
              if (
                feedBlockData.optionalNutritionalContent[nutritionalItem] ||
                feedBlockData.optionalNutritionalContent[nutritionalItem] === 0
              ) {
                let value = parseFloat(
                  feedBlockData.optionalNutritionalContent[nutritionalItem]
                );
                if (nutritionalItem === 'gross_energy')
                  value =
                    userUOM.unitNutritionalGrossEnergy ===
                      defaultMetric.unitNutritionalGrossEnergy &&
                      userUOM.unitBarnOutputMass === defaultMetric.unitBarnOutputMass
                      ? value
                      : math.round(
                          explicitConvertValue(
                            value,
                            `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitBarnOutputMass}`,
                            `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitBarnOutputMass}`
                          ),
                          3
                        );
                stageData.feed.nutrient_composition.push({
                  nutrient_type: nutritionalItem,
                  amount: value,
                });
              }
            });
          }
        } // end of feedBlockData processing
      }
    });
  payloadObj.stages.forEach( item => {
    const {stageData} = item;
    item.stageData = JSON.stringify(stageData);
  })
  return payloadObj;
};

export default mapSalmonProductionDataOutSustell;