/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import * as Yup from 'yup';
import { defaultUnitsV2 } from '../../../../sustell_15/utils/unit-utils';
import { interventionValidationResourseUsagePartV2 } from './interventionValidationSchemaGeneralPart';
import {
  feedItemsInterventionTest,
  calcInterventionValue,
  interventionFieldWithMinMax,
  interventionFieldWithMin,
  interventionFieldWithMoreThanMin,
} from './interventionValidationObjectBuilderFunctions';
import { StageType } from '../../../../../graphql/types';
import {
  numericOptionalWithMinMax,
  numericOptionalWithGreaterThanMin,
  numericRequiredWithGreaterThanMin,
  feedItemsTest,
  feedItemsTestWithOrigin,
} from '../../Baseline/validationSchema/validationObjectBuilderFunctions';
import { resourceItems } from '../../Baseline/validationSchema/baselineValidationSchemaGeneralPart';
import { processingStageData } from './processingInterventionValidation';
import { FacilitySpecies } from '../../../../sustell_15/models/Facility/FacilityTypes';

const farmDeleted = (intl) => ({
  is: (val) => !!val,
  then: Yup.string().required(
    intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
  ),
  otherwise: Yup.string().notRequired(),
});

export const stageInputBreeding = (intl) =>
  Yup.object({
    henInternalSource: Yup.object({
      farmDeleted: Yup.boolean().notRequired(),
      farmId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      originStageId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
    henExternalSource: Yup.object({
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      averageWeight: interventionFieldWithMoreThanMin(intl, 'averageWeight', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
    roosterInternalSource: Yup.object({
      farmDeleted: Yup.boolean().notRequired(),
      farmId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      originStageId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
    roosterExternalSource: Yup.object({
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      averageWeight: interventionFieldWithMoreThanMin(intl, 'averageWeight', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
  }).default({});

export const stageInputGrowing = (intl) =>
  Yup.object({
    durationOfProductionCycle: interventionFieldWithMoreThanMin(
      intl,
      'durationOfProductionCycle',
      0
    ),
    emptyPeriod: interventionFieldWithMin(intl, 'emptyPeriod', 0),
    animalsPresentAtStart: interventionFieldWithMin(
      intl,
      'animalsPresentAtStart',
      0
    ),
    animalsPresentAtEnd: interventionFieldWithMin(
      intl,
      'animalsPresentAtEnd',
      0
    ),
    averageWeightAtStart: interventionFieldWithMoreThanMin(
      intl,
      'averageWeightAtStart',
      0
    ),
    averageWeightAtEnd: interventionFieldWithMoreThanMin(
      intl,
      'averageWeightAtEnd',
      0
    ),
    internalSources: Yup.array().of(
      Yup.object({
        farmDeleted: Yup.boolean().notRequired(),
        farmId: Yup.string().when('farmDeleted', farmDeleted(intl)),
        originStageId: Yup.string().when('farmDeleted', farmDeleted(intl)),
        numberAnimals: interventionFieldWithMoreThanMin(
          intl,
          'numberAnimals',
          0
        ),
        transportDistance: interventionFieldWithMin(
          intl,
          'transportDistance',
          0
        ),
      }).default({})
    ),
    externalSources: Yup.array().of(
      Yup.object({
        numberAnimals: interventionFieldWithMoreThanMin(
          intl,
          'numberAnimals',
          0
        ),
        averageWeight: interventionFieldWithMoreThanMin(
          intl,
          'averageWeight',
          0
        ),
        transportDistance: interventionFieldWithMin(
          intl,
          'transportDistance',
          0
        ),
      }).default({})
    ),
  }).default({});

export const stageInputLaying = (intl) =>
  Yup.object({
    internalSource: Yup.object({
      farmDeleted: Yup.boolean().notRequired(),
      farmId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      originStageId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
    externalSource: Yup.object({
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      averageWeight: interventionFieldWithMoreThanMin(intl, 'averageWeight', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
  }).default({});

export const stageInputHatching = (intl) =>
  Yup.object({
    eggsPresentAtStart: interventionFieldWithMin(intl, 'eggsPresentAtStart', 0),
    eggsPresentAtEnd: interventionFieldWithMin(intl, 'eggsPresentAtEnd', 0),
    internalSource: Yup.object({
      farmDeleted: Yup.boolean().notRequired(),
      farmId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      originStageId: Yup.string().when('farmDeleted', farmDeleted(intl)),
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
    externalSource: Yup.object({
      numberAnimals: interventionFieldWithMoreThanMin(intl, 'numberAnimals', 0),
      averageWeight: interventionFieldWithMoreThanMin(intl, 'averageWeight', 0),
      transportDistance: interventionFieldWithMin(intl, 'transportDistance', 0),
    }).default({}),
  }).default({});

const stageFeed = (intl, userUOM = defaultUnitsV2) =>
  Yup.object({
    compoundFeeds: feedItemsInterventionTest(intl, 0),
    compoundFeedsAdditions: feedItemsTest(intl),
    singleIngredients: feedItemsInterventionTest(intl, 0),
    singleIngredientsAdditions: feedItemsTestWithOrigin(intl, 0),
  }).default({});

export const stageHousing = (intl, baseline) =>
  Yup.object({
    resourceUse: Yup.object({
      electricityUse: interventionFieldWithMin(intl, 'electricityUse', 0),
      gasUse: interventionFieldWithMin(intl, 'gasUse', 0),
      dieselUse: interventionFieldWithMin(intl, 'dieselUse', 0),
      waterUse: interventionFieldWithMin(intl, 'waterUse', 0),
      coalUse: interventionFieldWithMin(intl, 'coalUse', 0),
      propaneOrLpgUse: interventionFieldWithMin(intl, 'propaneOrLpgUse', 0),
      gasolineUse: interventionFieldWithMin(intl, 'gasolineUse', 0),
      heavyFuelOilUse: interventionFieldWithMin(intl, 'heavyFuelOilUse', 0),
      electricityPhotovoltaicUse: interventionFieldWithMin(
        intl,
        'electricityPhotovoltaicUse',
        0
      ),
      electricityWindUse: interventionFieldWithMin(
        intl,
        'electricityWindUse',
        0
      ),
      selfGeneratedRenewables: resourceItems(intl),
      energyTypes: resourceItems(intl),
    }).default({}),
    housingType: Yup.string().notRequired(),
    timeInFreeRange: numericOptionalWithMinMax(intl, 0, 100),
    timeInHousing: numericOptionalWithMinMax(intl, 0, 100),
    manureSystemsAdditions: Yup.array().of(
      Yup.object({
        mmsType: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        mmsHoldingDuration: Yup.string().when('mmsType', {
          is: (val) =>
            val === 'LIQUID_COVER' ||
            val === 'LIQUID_CRUST' ||
            val === 'LIQUID_NO_CRUST' ||
            val === 'PIT' ||
            val === 'DEEP_BEDDING_ACTIVE_MIXING' ||
            val === 'DEEP_BEDDING_NO_MIXING',
          then: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          otherwise: Yup.string().nullable(),
        }),
        share: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .test('sumOK', '', function test(value, testContext) {
            const { path, createError } = this;
            if (!value && value !== 0)
              return createError({
                path,
                message: intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }),
              });
            if (value === 0)
              return createError({
                path,
                message: intl.formatMessage(
                  { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN' },
                  { value: '0' }
                ),
              });
            const [, parent2] = testContext.from;
            const MMSSystemsList = parent2?.value?.manureSystems || [];
            const MMSSystemsListAdditions =
              parent2?.value?.manureSystemsAdditions || [];
            const index = Number(testContext?.from?.[3]?.value?.index);
            const sumMMS = MMSSystemsList.reduce((acc, item, mmsIndex) => {
              const baselineVal =
                baseline.stages?.[index]?.stageData?.housing?.manureSystems?.[
                  mmsIndex
                ].share;
              return (
                acc +
                calcInterventionValue(
                  baselineVal,
                  item.share,
                  item.share_changeMetric
                )
              );
            }, 0);
            const sumMMSAdditions = MMSSystemsListAdditions.reduce(
              (acc, item) => {
                if (item.share && !Number.isNaN(item.share))
                  return acc + Number(item.share);
                return acc;
              },
              0
            );
            if (sumMMS + sumMMSAdditions !== 100)
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'SUSTELL.STAGE.POULTRY.MANURE.MMS.PERCENT_SUM_ERROR',
                }),
              });
            return true;
          }),
      }).default({})
    ),
    manureSystems: Yup.array().of(
      Yup.object({
        mmsType: Yup.string(),
        mmsHoldingDuration: Yup.string().when('mmsType', {
          is: (val) =>
            val === 'LIQUID_COVER' ||
            val === 'LIQUID_CRUST' ||
            val === 'LIQUID_NO_CRUST' ||
            val === 'PIT' ||
            val === 'DEEP_BEDDING_ACTIVE_MIXING' ||
            val === 'DEEP_BEDDING_NO_MIXING',
          then: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          otherwise: Yup.string().nullable(),
        }),
        share: Yup.number()
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
          .test('sumOK', '', function test(value, testContext) {
            const { path, createError } = this;
            if (!value && value !== 0) return true;
            const [, parent2] = testContext.from;
            const MMSSystemsList = parent2?.value?.manureSystems || [];
            const MMSSystemsListAdditions =
              parent2?.value?.manureSystemsAdditions || [];
            const index = Number(testContext?.from?.[3]?.value?.index);
            const sumMMS = MMSSystemsList.reduce((acc, item, mmsIndex) => {
              const baselineVal =
                baseline.stages?.[index]?.stageData?.housing?.manureSystems?.[
                  mmsIndex
                ].share;
              return (
                acc +
                calcInterventionValue(
                  baselineVal,
                  item.share,
                  item.share_changeMetric
                )
              );
            }, 0);
            const sumMMSAdditions = MMSSystemsListAdditions.reduce(
              (acc, item) => {
                if (item.share && !Number.isNaN(item.share))
                  return acc + Number(item.share);
                return acc;
              },
              0
            );
            if (sumMMS + sumMMSAdditions !== 100)
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'SUSTELL.STAGE.POULTRY.MANURE.MMS.PERCENT_SUM_ERROR',
                }),
              });
            return true;
          }),
      }).default({})
    ),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        beddingAmount: interventionFieldWithMin(intl, 'beddingAmount', 0),
      }).default({})
    ),
    beddingSystemsAdditions: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      }).default({})
    ),
  }).default({});

export const stageHousingHatching = (intl) =>
  Yup.object({
    resourceUse: Yup.object({
      electricityUse: interventionFieldWithMin(intl, 'electricityUse', 0),
      gasUse: interventionFieldWithMin(intl, 'gasUse', 0),
      dieselUse: interventionFieldWithMin(intl, 'dieselUse', 0),
      waterUse: interventionFieldWithMin(intl, 'waterUse', 0),
      coalUse: interventionFieldWithMin(intl, 'coalUse', 0),
      propaneOrLpgUse: interventionFieldWithMin(intl, 'propaneOrLpgUse', 0),
      gasolineUse: interventionFieldWithMin(intl, 'gasolineUse', 0),
      heavyFuelOilUse: interventionFieldWithMin(intl, 'heavyFuelOilUse', 0),
      electricityPhotovoltaicUse: interventionFieldWithMin(
        intl,
        'electricityPhotovoltaicUse',
        0
      ),
      electricityWindUse: interventionFieldWithMin(
        intl,
        'electricityWindUse',
        0
      ),
      selfGeneratedRenewables: resourceItems(intl),
      energyTypes: resourceItems(intl),
    }).default({}),
  }).default({});

export const stageOutputBreeding = (intl, baseline) =>
  Yup.object({
    totalEggsToStage: interventionFieldWithMin(
      intl,
      'totalEggsToStage',
      0
    ).test('checkIfTogetherAboveZero', '', function test(_, testContext) {
      const parent = testContext.from[0].value;
      const { path, createError } = this;
      const index = Number(testContext?.from?.[2]?.value?.index);
      const totalEggsToStage = calcInterventionValue(
        baseline.stages?.[index]?.stageData?.output.totalEggsToStage,
        parent.totalEggsToStage,
        parent.totalEggsToStage_changeMetric
      );
      const totalEggsSold = calcInterventionValue(
        baseline.stages?.[index]?.stageData?.output.totalEggsSold,
        parent.totalEggsSold,
        parent.totalEggsSold_changeMetric
      );
      if (totalEggsToStage + totalEggsSold === 0) {
        const message = intl.formatMessage({
          id: 'SUSTELL.STAGE.POULTRY.INPUT.BREEDING.BREEDING_OUTPUT_ABOVE_ZERO',
        });
        return createError({ path, message });
      }
      return true;
    }),
    averageWeight: interventionFieldWithMoreThanMin(intl, 'averageWeight', 0),
    totalEggsSold: interventionFieldWithMin(intl, 'totalEggsSold', 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        const index = Number(testContext?.from?.[2]?.value?.index);
        const totalEggsToStage = calcInterventionValue(
          baseline.stages?.[index]?.stageData?.output.totalEggsToStage,
          parent.totalEggsToStage,
          parent.totalEggsToStage_changeMetric
        );
        const totalEggsSold = calcInterventionValue(
          baseline.stages?.[index]?.stageData?.output.totalEggsSold,
          parent.totalEggsSold,
          parent.totalEggsSold_changeMetric
        );
        if (totalEggsToStage + totalEggsSold === 0) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.BREEDING.BREEDING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    priceEggs: interventionFieldWithMoreThanMin(intl, 'priceEggs', 0),
    totalHensSold: interventionFieldWithMoreThanMin(intl, 'totalHensSold', 0),
    averageHensWeight: interventionFieldWithMoreThanMin(
      intl,
      'averageHensWeight',
      0
    ),
    mortalityHensAtPercent: interventionFieldWithMinMax(
      intl,
      'mortalityHensAtPercent',
      0,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    weightHensAtMortality: interventionFieldWithMin(
      intl,
      'weightHensAtMortality',
      0
    ),
    totalRoostersSold: interventionFieldWithMoreThanMin(
      intl,
      'totalRoostersSold',
      0
    ),
    averageRoostersWeight: interventionFieldWithMoreThanMin(
      intl,
      'averageRoostersWeight',
      0
    ),
    mortalityRoostersAtPercent: interventionFieldWithMinMax(
      intl,
      'mortalityRoostersAtPercent',
      0,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    weightRoostersAtMortality: interventionFieldWithMin(
      intl,
      'weightRoostersAtMortality',
      0
    ),
    price: interventionFieldWithMin(intl, 'price', 0),
  }).default({});

export const stageOutputGrowing = (intl, baseline) =>
  Yup.object({
    totalToStage: interventionFieldWithMin(intl, 'totalToStage', 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        const index = Number(testContext?.from?.[2]?.value?.index);
        const totalToStage = calcInterventionValue(
          baseline.stages?.[index]?.stageData?.output.totalToStage,
          parent.totalToStage,
          parent.totalToStage_changeMetric
        );
        const totalSold = calcInterventionValue(
          baseline.stages?.[index]?.stageData?.output.totalSold,
          parent.totalSold,
          parent.totalSold_changeMetric
        );
        if (totalToStage + totalSold === 0) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.GROWING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    averageWeight: interventionFieldWithMin(intl, 'averageWeight', 0),
    totalSold: interventionFieldWithMin(intl, 'totalSold', 0).test(
      'checkIfTogetherAboveZero',
      '',
      function test(_, testContext) {
        const parent = testContext.from[0].value;
        const { path, createError } = this;
        const index = Number(testContext?.from?.[2]?.value?.index);
        const totalToStage = calcInterventionValue(
          baseline.stages?.[index]?.stageData?.output.totalToStage,
          parent.totalToStage,
          parent.totalToStage_changeMetric
        );
        const totalSold = calcInterventionValue(
          baseline.stages?.[index]?.stageData?.output.totalSold,
          parent.totalSold,
          parent.totalSold_changeMetric
        );
        if (totalToStage + totalSold === 0) {
          const message = intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.GROWING.GROWING_OUTPUT_ABOVE_ZERO',
          });
          return createError({ path, message });
        }
        return true;
      }
    ),
    mortalityAtPercent: interventionFieldWithMinMax(
      intl,
      'mortalityAtPercent',
      0,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    weightAtMortality: interventionFieldWithMin(intl, 'weightAtMortality', 0),
  }).default({});

export const stageOutputLaying = (intl) =>
  Yup.object({
    totalSold: interventionFieldWithMoreThanMin(intl, 'totalSold', 0),
    averageWeight: interventionFieldWithMoreThanMin(intl, 'averageWeight', 0),
    priceEggs: interventionFieldWithMoreThanMin(intl, 'priceEggs', 0),
    totalHensToStage: interventionFieldWithMoreThanMin(
      intl,
      'totalHensToStage',
      0
    ),
    averageHensWeight: interventionFieldWithMoreThanMin(
      intl,
      'averageHensWeight',
      0
    ),
    priceHens: interventionFieldWithMoreThanMin(intl, 'priceHens', 0),
    mortalityAtPercent: interventionFieldWithMinMax(
      intl,
      'mortalityAtPercent',
      0,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    weightAtMortality: interventionFieldWithMin(intl, 'weightAtMortality', 0),
  }).default({});

export const stageOutputHatching = (intl) =>
  Yup.object({
    totalToStage: interventionFieldWithMin(intl, 'totalToStage', 0),
    averageWeight: interventionFieldWithMin(intl, 'averageWeight', 0),
    totalSold: interventionFieldWithMin(intl, 'totalSold', 0),
    price: interventionFieldWithMin(intl, 'price', 0),
    lossRate: interventionFieldWithMin(intl, 'lossRate', 0),
    priceOfLossesSold: interventionFieldWithMin(intl, 'priceOfLossesSold', 0),
    totalEggShellsSold: interventionFieldWithMin(intl, 'totalEggShellsSold', 0),
  }).default({});

const stageEmissions = (intl, userUOM = defaultUnitsV2) =>
  Yup.object({
    methane: interventionFieldWithMinMax(
      intl,
      'methane',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nitrousOxideDirect: interventionFieldWithMinMax(
      intl,
      'nitrousOxideDirect',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nitrousOxideIndirect: interventionFieldWithMinMax(
      intl,
      'nitrousOxideIndirect',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    amonia: interventionFieldWithMinMax(
      intl,
      'amonia',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nitricOxide: interventionFieldWithMinMax(
      intl,
      'nitricOxide',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    nonMethaneVolatile: interventionFieldWithMinMax(
      intl,
      'nonMethaneVolatile',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    PM10: interventionFieldWithMinMax(
      intl,
      'PM10',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    PM25: interventionFieldWithMinMax(
      intl,
      'PM25',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
    totalSuspendedParticles: interventionFieldWithMinMax(
      intl,
      'totalSuspendedParticles',
      -100,
      100,
      'VALIDATION.NUMERIC.RANGE_ERROR'
    ),
  }).default({});

const stageDataPoultry = ({
  intl,
  baseline,
  userUOM = defaultUnitsV2,
  minValueOutput = 1,
}) =>
  Yup.object({
    stages: Yup.array().of(
      Yup.object({
        id: Yup.string(),
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
          .min(
            3,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_LENGTH' },
              { count: 3 }
            )
          ),
        type: Yup.string()
          .oneOf([
            StageType.Breeding,
            StageType.Growing,
            StageType.Laying,
            StageType.Hatching,
            StageType.Processing,
          ])
          .required(),
        stageData: Yup.object()
          .when('type', {
            is: StageType.Breeding,
            then: Yup.object({
              input: stageInputBreeding(intl),
              feed: Yup.object({
                henFeed: stageFeed(intl),
                roosterFeed: stageFeed(intl),
              }),
              housing: stageHousing(intl, baseline),
              output: stageOutputBreeding(intl, baseline),
              emissions: stageEmissions(intl, userUOM),
            }).default({}),
          })
          .when('type', {
            is: StageType.Growing,
            then: Yup.object({
              input: stageInputGrowing(intl),
              feed: stageFeed(intl),
              housing: stageHousing(intl, baseline),
              output: stageOutputGrowing(intl, baseline),
              emissions: stageEmissions(intl, userUOM),
            }).default({}),
          })
          .when('type', {
            is: StageType.Laying,
            then: Yup.object({
              input: stageInputLaying(intl),
              feed: stageFeed(intl),
              housing: stageHousing(intl, baseline),
              output: stageOutputLaying(intl),
              emissions: stageEmissions(intl, userUOM),
            }).default({}),
          })
          .when('type', {
            is: StageType.Hatching,
            then: Yup.object({
              input: stageInputHatching(intl),
              housing: stageHousingHatching(intl),
              output: stageOutputHatching(intl),
            }).default({}),
          })
          .when('type', {
            is: StageType.Processing,
            then: processingStageData(intl, baseline),
          })
          .default({}),
      })
    ),
  });

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const combinedSchema = Yup.object({ info: baseSchema })
    .concat(
      Yup.object({
        resourceUse: interventionValidationResourseUsagePartV2(confObj),
      })
    )
    .concat(stageDataPoultry(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
