import React from "react";
// import { injectIntl } from 'react-intl';
import { withRouter } from "react-router";
import { API } from 'aws-amplify';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  TableFooter,
  TablePagination
} from "@material-ui/core";
import Edit from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import dsmTheme from "../MUITheme";
import { getUser } from "../../../graphql/queries";
import { injectIntl } from "../../../_metronic/i18n/customInjectIntl";
import { ctaClick, formStart } from '../../sustell_15/analytics/adobeDataLayer';

class UserData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterAttribute: 'lastName',
      rowsPerPage: 20,
    };

    const findSelectedUserPage = () => {
      if(this.props.selectedUser) {
        const users = this.props.userData?.sort((a,b) => a.email?.toLowerCase()<b.email?.toLowerCase() ? -1 : (a.email?.toLowerCase()>b.email?.toLowerCase() ? 1 : 0))
        if(users) {
          const index = users.findIndex((element) => element.id === this.props.selectedUser.id);
          if(index > 0)
            return Math.floor(index / this.state.rowsPerPage)
        }
      }
      return 0;
    }

    this.state.currPage = findSelectedUserPage();

    this.onNewUserBtnClick = this.onNewUserBtnClick.bind(this);
    this.onTablePageChange = this.onTablePageChange.bind(this);
    this.onTableRowsPerPageChange = this.onTableRowsPerPageChange.bind(this);
  }


 

  refreshUserDetails(item, e) {
    e.preventDefault();
  }

  onNewUserBtnClick(event) {
    ctaClick(window.location.href, 'button', 'Add User', 'Admin', 'Users');
    this.props.onNewUserBtnClick(event);
    this.props.onShowUserForm(event, 'new');
    formStart('Add User', 'new');
  }

  async fetchUserData(userId) {
    try {
      const result = await API.graphql({
        query: getUser,
        variables: {
          userId: userId,
        },
      });

      return {
        id: userId,
        ...result.data.getUser
      }
    } catch (err) {
      console.log("error: ", err);
    }
  }

  editUser(item, e) {
    if (
      !this.props.editorsActive
    ) {
      this.fetchUserData(item.id).then(selected => {
        ctaClick(window.location.href, 'icon', 'Edit User', 'Admin', 'Users');
        this.props.onSelectionChangedHandler(selected);
        this.props.onShowUserForm(e, 'edit');
        formStart('Edit User', 'edit');
      }).catch(err => {
        console.log("error: ", err);
      })
    }
  }

  viewUser(item, e) {
    if (
      !this.props.editorsActive
    ) {
      this.fetchUserData(item.id).then(selected => {
        ctaClick(window.location.href, 'icon', 'View User', 'Admin', 'Users');
        this.props.onSelectionChangedHandler(selected);
        this.props.onShowUserForm(e, 'view');
        formStart('VIew User', 'view');
      }).catch(err => {
        console.log("error: ", err);
      })
    }
  }

  setFilterAttribute(attribute) {
    this.setState({ filterAttribute: attribute });
  }

  handleFilter(e) {
    e.preventDefault();
    this.props.onUsersFilterChanged(this.state.filterAttribute, e.target.filterValue.value);
  }

  onTablePageChange(event, page){
    this.setState({
      currPage: page
    });
  }

  onTableRowsPerPageChange(event){
    const newVal = event.target.value;
    this.setState({
      currPage: 0,
      rowsPerPage: newVal
    });
  }

  render() {
    const intl = this.props.intl;
    const listItems = this.props.userData.sort((a, b) => {
      if(a.email < b.email) { return -1; }
      if(a.email > b.email) { return 1; }
      return 0;
    }).map((item, index) => (
      <TableRow
        key={item.id}
        // onClick={(e) => this.refreshUserDetails(item, e)}
        selected={item.id === this.props.selectedUser?.id}
        hover={true}
        style={{ cursor: "pointer" }}
        onClick={(e) => this.viewUser(item, e)}
      >
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.firstName}</TableCell>
        <TableCell>{item.lastName}</TableCell>
        <TableCell>{(item.groups || []).join(", ")}</TableCell>
        <TableCell>{item.status}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          {item.enabled ? <CheckIcon /> : <CloseIcon />}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="Edit farm"
            onClick={(e) => { e.stopPropagation(); this.editUser(item, e) }}
            size="small"
          >
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
    ));

    // Switched to the frontend filter
    // For the backend following keys should be used
    // family_name,
    // given_name
    // email
    // phone_number
    // 'cognito:user_status'
    // filtering by user group on backend will require backend modification
    const filterAttributes = {
      lastName: intl.formatMessage({id:"GENERAL.LAST_NAME"}),
      firstName: intl.formatMessage({id:"GENERAL.FIRST_NAME"}),
      email: intl.formatMessage({id:"GENERAL.EMAIL"}),
      phoneNumber: intl.formatMessage({id:"GENERAL.PHONE"}),
      groups: intl.formatMessage({id:"GENERAL.GROUP"}),
      status: intl.formatMessage({id:"GENERAL.STATUS"})
    };

    return (
      <>
        <ThemeProvider theme={dsmTheme}>
          <Card>
            <CardHeader
              title={ intl.formatMessage({id:"USERS"})}
              titleTypographyProps={{ color: "secondary", variant: "h4" }}
              action={
                <Button
                  variant="outlined"
                  onClick={(e) => this.onNewUserBtnClick(e)}
                >
                  { intl.formatMessage({id:"USERS.NEW_USER"})}
                </Button>
              }
            />
            <CardContent>
              <form onSubmit={e => {
                e.preventDefault();
                this.handleFilter(e);
              }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={2}>
                    <FormControl variant="outlined" fullWidth >
                      <InputLabel htmlFor="filterAttribute">{ intl.formatMessage({ id: "GENERAL.FILTER_ATTR"})}</InputLabel>
                      <Select
                        fullWidth
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        input={
                          <OutlinedInput
                            label={ intl.formatMessage({ id: "GENERAL.FILTER_ATTR"})}
                            name="filterAttribute"
                            id="filterAttribute"
                            onChange={(e) => { this.setFilterAttribute(e.target.value) }}
                            value={this.state.filterAttribute}
                          />
                        }
                      >
                        {Object.entries(filterAttributes).map(([i, label]) => (
                          <MenuItem key={i} value={i}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={ intl.formatMessage({id: "GENERAL.FILTER"})}
                      name="filterValue"
                      id="filterValue"
                      InputProps={{
                        style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      type="submit"
                      style={{ marginLeft: '5px', paddingTop: '18.7px', paddingBottom: '18.7px', lineHeight: '1.125'}}
                    >{intl.formatMessage({id:"GENERAL.FILTER"})}</Button>
                  </Grid>
                </Grid>
              </form>

              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>{ intl.formatMessage({id: "GENERAL.EMAIL"})}</TableCell>
                    <TableCell>{ intl.formatMessage({id: "GENERAL.FIRST_NAME"})}</TableCell>
                    <TableCell>{ intl.formatMessage({id: "GENERAL.LAST_NAME"})}</TableCell>
                    <TableCell>{ intl.formatMessage({id: "GENERAL.GROUP"})}</TableCell>
                    <TableCell>{ intl.formatMessage({id: "GENERAL.STATUS"})}</TableCell>
                    <TableCell >{ intl.formatMessage({id: "GENERAL.ENABLED"})}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                { 
                  listItems.filter( (item, index) => (index >= this.state.currPage*this.state.rowsPerPage && index<this.state.currPage*this.state.rowsPerPage+this.state.rowsPerPage)) 
                }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                      count = { listItems?.length || 0 }
                      rowsPerPage = { this.state.rowsPerPage }
                      labelRowsPerPage = { this.props.intl.formatMessage({id: "GENERAL.TABLE_PAGINATION.ROWS_PER_PAGE"})}
                      page = { this.state.currPage }
                      onChangePage = { this.onTablePageChange }
                      rowsPerPageOptions = { [15,20,30,40] }
                      onChangeRowsPerPage = { this.onTableRowsPerPageChange }
                      labelDisplayedRows = { ({ from, to, count }) => this.props.intl.formatMessage({id: "GENERAL.TABLE_PAGINATION.PAGE_OF_PAGES"},{from: from, to: to, count:count }) }
                    /> 
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </Card>
        </ThemeProvider>
      </>
    );
  }
}

export default withRouter(injectIntl(UserData));
