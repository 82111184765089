import { Grid } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  DairyIntervention,
  DairyOutput,
} from '../../../../models/Intervention';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { FormType } from '../../common';
import { DairyInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const DAIRY_COWS = 'Dairy cows';

const DInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: DairyInterventionFormDialogProps) => {
  const intl = useIntl();

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const outputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const unitOutputMass = userUOM?.unitOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  const currResetValue = useRef<DairyOutput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...(fc.getValues(fieldItemPrefix) as DairyOutput),
      }; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as DairyIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.output) {
        stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output;
  const productionSystem =
    baseline?.stages?.[stageIndex]?.productionSystem || '';

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="DAIRY"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <Grid container direction="column" spacing={2} justifyContent="space-between">
        {productionSystem === DAIRY_COWS && (
          <>
            <Grid item xs={12}>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.milk`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.DAIRY.MILK',
                })}
                variant="outlined"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.DAIRY.MILK.TOOLTIP',
                })}
                metricUnit={unitOutputMass}
                margin="none"
                precision={outputMassPrecision}
                baseline={output?.milk}
                disabled={formType === 'view'}
                // defaultValue = { getValues(fieldItemPrefix + ".milk") }
              />
            </Grid>
            <Grid item xs={12}>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.proteinContent`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.DAIRY.PROTEIN_CONTENT',
                })}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.DAIRY.PROTEIN_CONTENT.TOOLTIP',
                })}
                metricUnit="%"
                precision={outputMassPrecision}
                baseline={output?.proteinContent}
                disabled={formType === 'view'}
                // defaultValue = { getValues(fieldItemPrefix + ".proteinContent") }
              />
            </Grid>
            <Grid item xs={12}>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.fatContent`}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.DAIRY.FAT_CONTENT',
                })}
                margin="none"
                variant="outlined"
                tooltip={intl.formatMessage({
                  id: 'BASELINE.FORM.OUTPUT.DAIRY.FAT_CONTENT.TOOLTIP',
                })}
                metricUnit="%"
                baseline={output?.fatContent}
                disabled={formType === 'view'}
                // defaultValue = { getValues(fieldItemPrefix + ".fatContent") }
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.liveWeight`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.DAIRY.LIVEWEIGHT',
            })}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.DAIRY.LIVEWEIGHT.TOOLTIP',
            })}
            metricUnit={unitOutputMass}
            precision={outputMassPrecision}
            baseline={output?.liveWeight}
            disabled={formType === 'view'}
            //  defaultValue = { getValues(fieldItemPrefix + ".liveWeight") }
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default DInterventionOutputFormDialog;
