import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';

import { useFormContext } from 'react-hook-form';
import StageButtonBox from '../../StageButtonBox';
import LHInputFormDialog from './LHInputFormDialog';
import LHOutputFormDialog from './LHOutputFormDialog';
import LHManureFormDialog from './LHManureFormDialog';
import { FeedsFormDialog } from '../../CommonDataParts/FeedsFormDialog';
import LHEmissionsFormDialog from './LHEmissionsFormDialog';
import LHFeedsVariablePart from './LHFeedsVariablePart';
import { BaselineStageProps } from '../../common';

const LayingHensStage: FC<BaselineStageProps> = ({
  stageIndex,
  baseline,
  getCompleteness,
  manureManagementSystems,
  singleIngredients,
  compoundFeeds,
  item,
  formType,
}) => {
  const [activeDialog, setActiveDialog] = useState<string | null>('');

  const showDialog = (dialog: React.SetStateAction<string | null>) => {
    setActiveDialog(dialog);
  };

  const methods = useFormContext();
  const getValues = (key: string) => methods.getValues(key) as number;
  const register = () => methods.register();
  const { errors } = methods;
  const stagePrefix = `stages.${stageIndex}`;

  return (
    <>
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        defaultValue={item.animalType}
      />
      <input
        ref={register()}
        type="hidden"
        name={`${stagePrefix}.housingSystem`}
        defaultValue={item.housingSystem}
      />
      <LHInputFormDialog
        formVisible={activeDialog === 'input'}
        handleCancel={() => setActiveDialog(null)}
        formType={formType}
        baseline={baseline}
        itemIndex={stageIndex}
      />
      <FeedsFormDialog
        formVisible={activeDialog === 'feed'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        itemIndex={stageIndex}
        formType={formType}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
      >
        <LHFeedsVariablePart
          itemIndex={stageIndex}
          formType={formType}
          handleCancel={() => undefined}
        />
      </FeedsFormDialog>
      <LHManureFormDialog
        formVisible={activeDialog === 'manure'}
        handleCancel={() => setActiveDialog(null)}
        formType={formType}
        baseline={baseline}
        itemIndex={stageIndex}
        manureManagementSystems={manureManagementSystems}
      />
      <LHEmissionsFormDialog
        formVisible={activeDialog === 'emissions'}
        handleCancel={() => setActiveDialog(null)}
        formType={formType}
        baseline={baseline}
        itemIndex={stageIndex}
      />
      <LHOutputFormDialog
        formVisible={activeDialog === 'output'}
        handleCancel={() => setActiveDialog(null)}
        formType={formType}
        baseline={baseline}
        itemIndex={stageIndex}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="LAYING_HENS_INPUT"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.input`)
              : 0
          }
          error={errors?.stages && errors?.stages[stageIndex]?.stageData?.input}
          handleOpen={() => showDialog('input')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.feed`)
              : 0
          }
          error={errors?.stages && errors?.stages[stageIndex]?.stageData?.feed}
          handleOpen={() => showDialog('feed')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="manureIcon"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.manure`)
              : 0
          }
          error={
            errors?.stages && errors?.stages[stageIndex]?.stageData?.manure
          }
          handleOpen={() => showDialog('manure')}
        />
         <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="LAYING_HENS"
          completness={
            getCompleteness
              ? getCompleteness(getValues, `${stagePrefix}.stageData.output`)
              : 0
          }
          error={
            errors?.stages && errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog('output')}
        />
        <StageButtonBox
          optional
          completness={0}
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          error={
            errors?.stages && errors?.stages[stageIndex]?.stageData?.emissions
          }
          handleOpen={() => showDialog('emissions')}
        />
      </Grid>
    </>
  );
};

export default LayingHensStage;
