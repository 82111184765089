import { FC } from 'react';
 
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import { CSSClassesList } from '../../helpers/helperTypes';
import { DialogContainer }from '../FarmFlow/CommonDataParts/DialogContainer2';
import React from 'react';
 
 type IngredientDescriptionDialogProps = {
   open: boolean;
   handleClose: () => void;
 };
 
 // a static dialog loading and showing descirption text for MMS Systems
 const IngredientDescriptionDialog: FC<IngredientDescriptionDialogProps> = ({
   open=false,
   handleClose
 }) => {
 
   const intl = useIntl();
   const classes = processAndStageStylesV2() as CSSClassesList;
   const formTitle = intl.formatMessage({"id": "SUSTELL.STAGE.FEED.INGREDIENTS.TITLE"}) ;
   const formRefs = intl.formatMessage({"id": "SUSTELL.STAGE.FEED.INGREDIENTS.DESCRIPTION"}) ;
   const prefix="SUSTELL.STAGE.FEED.INGREDIENTS";
 
   // list of descritption item keys

   type Items = {
    [key: string]: string;
   };
  
    const items: Items = {
        "AFP": "https://blonksustainability.nl/tools-and-databases/agri-footprint",
        "GFLI": "https://globalfeedlca.org"
    };

     return (
     <DialogContainer
       formVisible = { open }
       variant = 'ultrawide'
       handleClose = { handleClose }
       withIcon={false}
       formTitle = { formTitle }
       introText = { formRefs }
     > 
       <DsmGrid className={classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesTitle} >
         <div><h6>{ intl.formatMessage({id: "SUSTELL.STAGE.FEED.INGREDIENTS.DATABASE"}) }</h6></div>
         <div className={`${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesTitle} ${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesDescription}`}>
          <h6>{ intl.formatMessage({id: "GENERAL.DESCRIPTION"}) }</h6></div>
        <div className={`${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesTitle} ${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesReference}`}>
          <h6>{ intl.formatMessage({id: "GENERAL.REFERENCE"}) }</h6></div>
       </DsmGrid>
       <DsmGrid className={classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesBody} >
        {Object.keys(items).map((key) => (
          <React.Fragment key={key}>
            <div><strong>{intl.formatMessage({ id: `${prefix}.${key}.TITLE` }, { br: <br /> })}</strong></div>
            <div className={`${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesTitle} ${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesDescription}`}>
              {intl.formatMessage({ id: `${prefix}.${key}.DESCRIPTION` }, { br: <br /> })}
            </div>
            <div className={`${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesTitle} ${classes.dsmGridthreeColumn_2_3_NoRowSpacingWithLinesReference}`}>
              <a href={items[key]}>{intl.formatMessage({ id: `${items[key]}` }, { br: <br /> })}</a></div>
          </React.Fragment>
        ))}
       </DsmGrid>
     </DialogContainer>
 	);
 };
 
	 export default IngredientDescriptionDialog;