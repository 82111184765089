import React from "react";
// import { injectIntl } from 'react-intl';
import { API } from "aws-amplify";
import {
  Button,
  Box,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  ThemeProvider,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput
} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Formik } from "formik";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Col, Row } from "react-bootstrap";
import { createUser, updateUser, resendActivation } from "../../../graphql/mutations";
import { getUserGroup } from "./Users";
import { UserBaseForm } from "./UserBaseForm";
import dsmTheme from "../MUITheme";
import { injectIntl } from "../../../_metronic/i18n/customInjectIntl";
import { formSubmission, ctaClick, formStart } from '../../sustell_15/analytics/adobeDataLayer';

class UserFormComponent extends React.Component {
  constructor(props) {
    super(props);

    let customersChecked = this.props.customerData?.map((customer) => {
      return {
        id: customer.id,
        name: customer.name,
        checked: this.props.selectedUser?.customerAccess?.find(item => item.id === customer.id) ? true : false,
      }
    });

    let singleGroup = "";

    if (this.props?.selectedUser) {
      singleGroup = getUserGroup(this.props.selectedUser?.groups);
    }

    this.state = {
      id: this.props.selectedUser?.id || "",
      email: this.props.selectedUser?.email || "",
      firstName: this.props.selectedUser?.firstName || "",
      lastName: this.props.selectedUser?.lastName || "",
      enabled: this.props.selectedUser?.hasOwnProperty('enabled') ? this.props.selectedUser.enabled : true,
      isInError: false,
      errorMsg: "",
      searchText: '',
      parentComponent: props.parent,
      user: null,
      customersChecked: customersChecked,
      allChecked: !customersChecked.some(item => !item.checked) || false,
      formState: props.formState || 'view',
      resendDialogActive: false,
      showCustomersTable: singleGroup && singleGroup !== 'Administrators'
    };

    this.submitMyForm = null;

    this.validationSchema = Yup.object({
      email: Yup.string("Enter an email address")
        .required("Email address is required")
        .email("Email address in not in valid format")
        .min(3, "User email address must contain at least 3 characters."),
      firstName: Yup.string("Enter a first name")
        .required("First name is required")
        .min(3, "First name address must contain at least 3 characters."),
      lastName: Yup.string("Enter a last name")
        .required("Last name is required")
        .min(3, "Last name must contain at least 3 characters."),
      groups: Yup.string("Group is required")
        .required("Group is required")
    });



    this.initialValues = {
      email: this.props.selectedUser?.email || "",
      firstName: this.props.selectedUser?.firstName || "",
      lastName: this.props.selectedUser?.lastName || "",
      groups: singleGroup || "",
      enabled: this.props.selectedUser?.hasOwnProperty('enabled') ? this.props.selectedUser.enabled : true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelSave = this.cancelSave.bind(this);
    this.triggerSubmitForm = this.triggerSubmitForm.bind(this);
    this.bindSubmitForm = this.bindSubmitForm.bind(this);
  }

  handleGroupChange = (e) => {
    if (!['Administrators', 'Support'].includes(e.target.value)){
      this.setState({ showCustomersTable: true })
    }
    else {
      this.setState({ showCustomersTable: false })
    }
  }

  handleSubmit(submitData) {
    formSubmission(this.state.formState === 'edit' ? 'Edit User': 'Add User', this.state.formState);
    const custommersAdded = this.state.customersChecked
      .filter(c => c.checked === true)
      .map(item => item.id);
    const { email, firstName, lastName, groups, enabled } = submitData;

    const user = {
      customerAccess: custommersAdded,
      email,
      firstName,
      lastName,
      enabled,
      groups: [groups],
    };

    // console.log(user);
    const lastEnabledState = this.props.selectedUser?.enabled;
    this.setState({
      email,
      firstName,
      lastName,
      groups,
      enabled,
    });

    (async () => {
      const selectedUser = this.props.selectedUser;
      // console.log("API called");
      if (lastEnabledState === enabled) delete user.enabled; //no change in enabled state - so don't send
      try {
        if (user.groups.includes('Viewer')) user.groups = [];

        const result = await API.graphql({
          query: selectedUser ? updateUser : createUser,
          variables: selectedUser
            ? {
                userId: selectedUser.id,
                input: user,
              }
            : {
                input: user,
              },
        });
        this.setState({ isInError: false });

        if (!selectedUser) {
          let newUserData = result.data.createUser; //object containg only the id of inserted entity
          delete user.enabled;
          Object.assign(newUserData, { ...user });

          // fix to be a list, not string
          newUserData.groups = newUserData.groups ? [newUserData.groups] : [];

          this.setState({ name: '' });
          this.props.changeHandler(newUserData);
        } else {
          // changes saved - return changed data to list

          // / fix to be a list, not string
          const editedUser = { ...user };
          editedUser.groups = editedUser.groups ? [editedUser.groups] : [];

          this.props.changeHandler({
            id: this.props.selectedUser?.id,
            ...editedUser,
          });
        }

        // console.log(JSON.stringify(result.data));
      } catch (err) {
        if (err.errors?.length > 0) {
          let error = err.errors[0];
          this.setState({ errorMsg: error.message });
          this.setState({ isInError: true });
        }
      }
    })();
  }

  cancelSave() {
    this.props.changeHandler(null);
  }

  triggerSubmitForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  //allow sending submit "signal" to Formik form
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  /*
      isChecked is not really needed, as this.state.customersChecked already has
      all ids and checked value pairs (alongside name)
  */
  // isChecked = (id) => {
  //   const item = this.state.customersChecked.find(e => e.id === id);
  //   if (item && item.checked) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  updateChecked = (id, checked) => {
    let items = this.state.customersChecked;
    for (var i in items) {
      if (items[i].id === id) {
        items[i].checked = checked;
        break;
      }
    }

    this.setState({
      allChecked: !items.some(item => !item.checked),
      customersChecked: items,
    });
  }

  checkAll = (value) => {
    let items = this.state.customersChecked;
    for (var i in items) {
      items[i].checked = value;
    }

    this.setState({
      customersChecked: items,
      allChecked: value
    })
  }

  changeModeToEdit = () => {
    ctaClick(window.location.href, 'button', 'Edit User', 'Admin', 'Users');
    this.setState({ formState: 'edit' });
    formStart('Edit User', 'edit');
  }

  onResendClick = () => {
    if (this.props.selectedUser) {
      ctaClick(window.location.href, 'button', 'Resend Invite', 'Admin', 'Users');
      (async () => {
        const email = this.props.selectedUser.email;
        try {
          const result = await API.graphql({
            query: resendActivation,
            variables: {
              email: email,
            }
          });
          let returnedValue = result.data.resendActivation;
          // console.log(returnedValue);
          if (returnedValue.id === this.state.id)
            this.setState({ isInError: false, resendDialogActive: true });
          else
            this.setState({ isInError: true, resendDialogActive: true });
        } catch (err) {
          if (err.errors?.length > 0) {
            let error = err.errors[0];
            this.setState({ isInError: true, resendDialogActive: true, errorMsg: error.message })
          }
        }
      })();
    }
  }

  closeResendDialog = () => {
    this.setState({ isInError: false, resendDialogActive: false, errorMsg: "" });
  }


  render() {
    const intl = this.props.intl;

    const {searchText} = this.state || '';
    const listItems = this.state.customersChecked
      .filter(customer => 
        !searchText || customer.name.toLowerCase().includes(searchText.toLowerCase()))
      .map((item, index) => {
      return (
        <TableRow
          key={item.id}
          // selected={item.id === this.props.selectedCustomer?.id}
          // onClick={(e) => this.refreshCustomerDetails(item, e)}
          aria-checked={true}
          role="checkbox"
          checked={item.checked}
          hover={true}
          style={{ cursor: "pointer" }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              disabled={this.state.formState === 'view'}
              checked={item.checked}
              onChange={(event) => { this.updateChecked(item.id, event.target.checked) }}
            />
          </TableCell>
          <TableCell>{item.name}</TableCell>
        </TableRow>)
    });

    return (
      <>
        <ThemeProvider theme={dsmTheme}>
          <Dialog
            open={this.state.resendDialogActive}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.isInError &&
                <>
                  <ErrorOutlineIcon
                    fontSize="large"
                    color="error"
                  />&nbsp;&nbsp;{ intl.formatMessage({id: "GENERAL.ERROR"})}
                </>
              }
              {!this.state.isInError &&
                <>
                  <CheckCircleOutlineIcon
                    fontSize="large"
                    style={{ color: "green" }}
                  />&nbsp;&nbsp;{ intl.formatMessage({id: "GENERAL.SUCCESS"})}
                </>
              }

            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.isInError ? intl.formatMessage({id:"USERS.EMAIL.RESENT.ERROR"}) + this.state.errorMsg : intl.formatMessage({id:"USERS.EMAIL.RESENT"})}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeResendDialog} color="primary">
                { intl.formatMessage({id: "GENERAL.CLOSE"})}
              </Button>
            </DialogActions>
          </Dialog>
          <Row>
            <Col>
              <Card style={{ height: '100%' }}>
                <CardHeader
                  title={this.props.selectedUser ? (this.state.formState === 'view' ? 'View' : 'Edit') + " user" : intl.formatMessage({id:"USERS.NEW_USER"}) }
                  titleTypographyProps={{ color: "secondary", variant: "h4" }}
                />
                <CardContent>
                  {this.state.isInError && (
                    <Alert severity="error">
                      <AlertTitle>{intl.formatMessage({id: "GENERAL.ERROR"})}</AlertTitle>
                      {this.state.errorMsg}
                    </Alert>
                  )}
                  <Formik
                    initialValues={this.initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {(formikProps) => {
                      //this provides outside binding for externalized submit buttons
                      this.bindSubmitForm(formikProps.submitForm);

                      return (
                        <UserBaseForm
                          {...formikProps}
                          // formState={this.props.selectedUser ? "update" : "new"}
                          handleCancel={this.cancelSave}
                          onGroupChange={this.handleGroupChange}
                          formState={this.state.formState}
                        />
                      );
                    }}
                  </Formik>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            </Col>
            <Col>
              {this.state.showCustomersTable &&
                <Card style={{ height: '100%' }}>
                  <CardHeader
                    title={ intl.formatMessage({id:"USERS.SELECT_CUSTOMERS"})}
                    titleTypographyProps={{ color: "secondary", variant: "h4" }}
                  />
                  <CardContent>
                  <FormControl variant="outlined" fullWidth >
                    <InputLabel htmlFor="searchCustomer">
                      {  this.props.intl.formatMessage({ id: "GENERAL.SEARCH"})}
                    </InputLabel>
                    <OutlinedInput
                      label={ this.props.intl.formatMessage({ id: "GENERAL.SEARCH"})}
                      name="searchCustomer"
                      id="searchCustomer"
                      onChange={(e) => { this.setState({searchText: e.target.value}) }}
                      value={this.state.searchText}
                    />
                  </FormControl>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ padding: 0, paddingLeft: 4 }}>
                            <Checkbox
                              disabled={this.state.formState === 'view'}
                              checked={this.state.allChecked}
                              onChange={(event) => { this.checkAll(event.target.checked) }}
                            />
                          </TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{listItems}</TableBody>
                    </Table>
                  </CardContent>
                </Card>}
            </Col>
          </Row>
          <Box mt={3} mb={2} display="flex" justifyContent="flex-end">
            <Box ml={1}>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={this.cancelSave}
              >
                {this.state.formState === 'view' ? intl.formatMessage({id:"GENERAL.CLOSE"}) : intl.formatMessage({id:"GENERAL.CANCEL"}) }
              </Button>
            </Box>
            {this.state.formState !== 'view' &&
              <Box ml={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="button"
                  onClick={this.triggerSubmitForm}
                >
                  {intl.formatMessage({id:"GENERAL.SAVE"})}
                </Button>
              </Box>
            }
            {this.state.formState === 'view' &&
              <>
                {this.props.selectedUser?.status === 'FORCE_CHANGE_PASSWORD' &&
                  <Box ml={1}>
                    <Button
                      variant="outlined"
                      onClick={this.onResendClick}
                    >
                      Resend Invitation
                    </Button>
                  </Box>
                }
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="button"
                    onClick={this.changeModeToEdit}
                  >
                    Edit
                  </Button>
                </Box>
              </>
            }
          </Box>
        </ThemeProvider>
      </>
    );
  }
}

export const UserForm = injectIntl(UserFormComponent);