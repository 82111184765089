/* eslint-disable-next-line no-restricted-imports */
import { createTheme } from '@material-ui/core/styles';

const dsmTheme = createTheme({
    typography: {
      fontFamily: [
        'Fira Sans',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      primary: {
        main: '#45ac34',
        contrastText: "#fff"
      },
      secondary: {
        main: '#009b97',
        contrastText: "#fff"
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          boxShadow: '0px 0px 30px 0px #0000001A !important'
        }
      },
      MuiTableRow:{
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: "rgb(214,233,233) !important",
          }
        },
        hover: {
          '&:hover':{
            background: "#f6f6f6 !important"
          },
        },
      },
      MuiTableCell:{
        root:{
          fontSize: '1rem',
          lineHeight: '1.1rem'
          /* borderBottom: 'none' */
        }
      },
      MuiFormControl:{
        root:{
          marginBottom: '1rem'
        }
      },
      MuiCard:{
        root:{
          marginBottom: '1rem'
        }
      },
      MuiCardHeader:{
        title:{
          fontSize: '1.5em'
        }
      },
      MuiLinearProgress:{
        colorPrimary:{
          backgroundColor: 'unset'
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          color: "white",
          backgroundColor: "#333"
        }
      },
      MuiOutlinedInput:{
        input:{
          '&.Mui-disabled':{
            color: "#030303 !important"
          }
        }
      }
    },
});

export default dsmTheme;