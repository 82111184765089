/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useEffect, useState } from 'react';
import {
  DsmButton,
  DsmGrid,
  DsmIcon,
  DsmModal,
} from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import FeedsFormCommonDataPart from '../../CommonDataParts/FeedsFormCommonPart';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import {
  DatabaseFoundation,
  FeedStatus,
} from '../../../../../../graphql/generated/blonk/cattle';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import { DsmButtonV2, useButtonStyles } from '../../../helpers/DsmButton';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../types';
import {
  BeefBreedingStageData,
  BeefGrowingStageData,
  BeefStage,
} from '../../../../models/Baseline/BeefBaseline';
import { BaselineFeed } from '../../../../models/Baseline';
import { AnimalType, Maybe, StageType } from '../../../../../../graphql/types';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import BeefFeedFreshGrassComponent from './BeefFeedFreshGrassComponent';
import { defaultValues } from '../../baselineDefaultValues';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import BeefFeedMothersMilkComponent from './BeefFeedMothersMilkComponent';
import { getInitialDatabaseValue } from '../../../../helpers/initialValues';
import { TargetAnimal } from '../../../../models/Baseline/CattleBaseline';
import { Tooltip } from '@material-ui/core';

interface SelectedDatabase {
  open: boolean;
  defaultValue: DatabaseFoundation;
  selectedValue: DatabaseFoundation | null;
}

type BeefFeedComponentProps = {
  formType: FormType;
  fieldItemPrefix: string;
  stageIndex?: number;
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  formVisible: boolean | undefined;
  origins: Maybe<Array<Origin>>;
  targetAnimal: TargetAnimal;
  stageType?: StageType
};

const beefFeedStageDefaultValues = defaultValues.BEEF.stages[0].stageData.feed;

const BeefFeedComponent: FC<BeefFeedComponentProps> = ({
  formType,
  fieldItemPrefix,
  stageIndex = 0,
  compoundFeeds,
  singleIngredients,
  formVisible,
  origins,
  targetAnimal,
  stageType = StageType.Breeding
}) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const buttonClasses = useButtonStyles();
  const userProfile = UserProfilePrefs.getInstance();
  const [compoundFeedsData, setCompoundFeedData] = useState(compoundFeeds);
  const [singleIngredientsData, setSingleIngredientsData] =
    useState(singleIngredients);
  const isTargetAnimalCalves = targetAnimal === TargetAnimal.CALVES;

  const {
    fields: freshGrass,
    append: appendFreshGrass,
    remove: removeFreshGrass,
  } = useFieldArray({
    name: `${fieldItemPrefix}.freshGrass`,
    control: formContext.control,
    keyName: 'keyId',
  });

  useEffect(() => {
    setCompoundFeedData(compoundFeeds);
    setSingleIngredientsData(singleIngredients);
  }, [compoundFeeds, singleIngredients]);

  const baselineDatabaseFoundation =
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      'info',
      userProfile
    );
  const defalutDatabaseFoundation = baselineDatabaseFoundation;
  const [
    openDatabaseSelectionWaringDialog,
    setOpenDatabaseSelectionWaringDialog,
  ] = useState<SelectedDatabase>({
    open: false,
    defaultValue: defalutDatabaseFoundation,
    selectedValue: null,
  });

  const handleChangeDatabaseFoundation = (event: ReactChangedType) => {
    if (event.stopPropagation) event.stopPropagation();
    const option = event.target.value as DatabaseFoundation | null;
    if (baselineDatabaseFoundation !== option) {
      setOpenDatabaseSelectionWaringDialog({
        open: true,
        selectedValue: option,
        defaultValue: defalutDatabaseFoundation,
      });
    }
  };

  const closeDialog = () => {
    setOpenDatabaseSelectionWaringDialog({
      open: false,
      selectedValue: null,
      defaultValue: defalutDatabaseFoundation,
    });
  };

  const getCompoundFeedsList = (databaseOption: DatabaseFoundation) =>
    compoundFeeds?.filter(
      (feed: CompoundFeedNameDatabase) =>
        feed.databaseFoundation === databaseOption
    );

  const handleDiscard = () => {
    getCompoundFeedsList(openDatabaseSelectionWaringDialog.defaultValue);
    formContext.setValue(
      `info.databaseFoundation`,
      openDatabaseSelectionWaringDialog.defaultValue
    );
    closeDialog();
  };

  const updateBreedingFeeds = (stage: BeefStage, index: number) => {
    const cowsFeeds = (stage.stageData as BeefBreedingStageData).feed.cows
      .compoundFeeds;
    const updatedCowsFeeds: BaselineFeed[] | undefined = cowsFeeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.cows.compoundFeeds`,
      updatedCowsFeeds
    );
    const calvesFeeds = (stage.stageData as BeefBreedingStageData).feed.calves
      .compoundFeeds;
    const updatedCalvesFeeds: BaselineFeed[] | undefined = calvesFeeds?.map(
      () => ({ feedType: '', kgPerAnimal: 0 })
    );
    formContext.setValue(
      `stages.${index}.stageData.feed.calves.compoundFeeds`,
      updatedCalvesFeeds
    );
  };

  const updateFeeds = (stage: BeefStage, index: number) => {
    const feeds = (stage.stageData as BeefGrowingStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const handleConfirm = () => {
    closeDialog();
    getCompoundFeedsList(baselineDatabaseFoundation);
    const stages = formContext.getValues('stages');
    stages.forEach((stage: BeefStage, i: number) => {
      if (i === stageIndex) {
        if (stage.type === StageType.Breeding) updateBreedingFeeds(stage, i);
        else updateFeeds(stage, i);
      }
    });
  };

  const resetToDefaultValues = () => {
    if (isTargetAnimalCalves) {
      formContext.setValue(
        `${fieldItemPrefix}.averageMilkFat`,
        beefFeedStageDefaultValues.calves.averageMilkFat
      );
    }
    freshGrass?.forEach((_, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.freshGrass.${index}.ashContent`,
        beefFeedStageDefaultValues.cows.freshGrass[0].ashContent
      )
    })
  };

  const dietCharacterisationOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      FeedStatus,
      intl,
      'SUSTELL.STAGE.BEEF.FEED'
    );

  return (
    <div>
      {openDatabaseSelectionWaringDialog.open && (
        <DsmModal
          open={openDatabaseSelectionWaringDialog.open}
          icon="general/info-square"
          header={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_HEADER',
          })}
          onDsmClosed={() => closeDialog()}
        >
          <p slot="content">
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_BODY',
            })}
          </p>
          <>
            <DsmButtonV2
              slot="actions"
              onClick={handleDiscard}
              className={buttonClasses.secondaryButton}
            >
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </DsmButtonV2>
            <DsmButtonV2
              variant="secondary"
              slot="actions"
              onClick={handleConfirm}
            >
              {intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
            </DsmButtonV2>
          </>
        </DsmModal>
      )}
      <DsmGrid
        className={
          isTargetAnimalCalves
            ? classes.dsmGridThreeColumnNoRowSpacing
            : classes.dsmGridTwoColumnNoRowSpacing
        }
        style={{
          grid: isTargetAnimalCalves
            ? 'auto/280px 520px 280px'
            : 'auto/280px 520px',
          marginTop: '16px',
          marginBottom: isTargetAnimalCalves ? '0px' : '17px',
        }}
      >
        <ReactHookDsmSelect
          name="info.databaseFoundation"
          label={`${intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION',
          })}`}
          disabled={formType === FormType.View || formType === FormType.Edit}
          defaultValue={defalutDatabaseFoundation}
          options={getDatabaseOptions()}
          changeHandler={(e: ReactChangedType) =>
            handleChangeDatabaseFoundation(e)
          }
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP',
          })}
          required
        />
        <ReactHookDsmSelect
          name={`${fieldItemPrefix}.dietCharacterisation`}
          label={`${intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.FEED.DIET_CHARACTERISATION',
          })}`}
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.dietCharacterisation`
            ) as string
          }
          options={dietCharacterisationOptions()}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.BEEF.FEED.${stageType}.DIET_CHARACTERISATION.${targetAnimal.toLocaleUpperCase()}.TOOLTIP`,
          })}
          required
        />
        {isTargetAnimalCalves && (
          <ReactHookDsmInput
            type="text"
            name={`${fieldItemPrefix}.averageMilkFat`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.AVERAGE_MILK_FAT',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.AVERAGE_MILK_FAT.TOOLTIP',
            })}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.FEED.AVERAGE_MILK_FAT.ADORNMENT',
            })}
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.averageMilkFat`
              ) as string
            }
          />
        )}
      </DsmGrid>
      <FeedsFormCommonDataPart
        formType={formType}
        formVisible={formVisible}
        itemIndex={stageIndex}
        fieldItemPrefix={stageType === StageType.Breeding ? `feed.${targetAnimal}` : 'feed'}
        compoundFeeds={compoundFeedsData}
        singleIngredients={singleIngredientsData}
        origins={origins}
        keyCompoundFeeds={`${fieldItemPrefix}CompoundFeeds`}
        keySingleIngredients={`${fieldItemPrefix}SingleIngredients`}
        animalType={AnimalType.Beef}
      />
      {isTargetAnimalCalves && (
        <BeefFeedMothersMilkComponent
          fieldItemPrefix={`${fieldItemPrefix}.mothersMilk`}
          formType={formType}
        />
      )}
      {freshGrass &&
        freshGrass.map((item, index) => (
          <BeefFeedFreshGrassComponent
            key={item.keyId}
            fieldItemPrefix={`${fieldItemPrefix}.freshGrass`}
            formType={formType}
            itemIndex={index}
            removeHandler={(index) => removeFreshGrass(index)}
            isTargetAnimalCalves={isTargetAnimalCalves}
          />
        ))}
      <DsmButton
        variant="text"
        onClick={() =>
          appendFreshGrass({
            ...defaultValues.BEEF.stages[0].stageData.feed.cows.freshGrass,
          })
        }
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({ id: 'GENERAL.ADD.ANOTHER' })}{' '}
        {intl.formatMessage({ id: 'SUSTELL.STAGE.BEEF.FEED.GRASS' })}
      </DsmButton>
      <div className={classes.resetButtonContainer}>
        <DsmButton
          widthFull
          variant="text"
          onClick={() => resetToDefaultValues()}
          disabled={formType === FormType.View}
          className="mt-3"
        >
          <DsmIcon slot="before" name="arrows/refresh-cw-01" />
          <u style={{ textDecoration: "none" }}>
            {intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES",
            })}
          </u>
        </DsmButton>
        <Tooltip
          title={intl.formatMessage({
            id: "SUSTELL.RESET_DEFAULT.TOOLTIP",
          })}
          placement="left"
          className="ml-3"
          style={{ transform: "translateY(85%)" }}
        >
          <DsmIcon
            name="general/help-circle"
            className={classes.inlineTooltipClass}
            title=""
          />
        </Tooltip>
      </div>
    </div>
  );
};

BeefFeedComponent.defaultProps = {
  stageIndex: 0,
  stageType: StageType.Breeding
};

export default BeefFeedComponent;
