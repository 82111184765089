import Drawer from "../../_metronic/layout/components/drawer/Drawer";
import Can from "../modules/Auth/Can";

import { Col } from "react-bootstrap";
import { useSubheader } from "../../_metronic/layout";

import { Notifications } from "../modules/notifications/Notifications";
// import { useIntl } from "react-intl";
import { useIntl } from "../../_metronic/i18n/customUseIntl";

export const NotificationPage = () => {
  const intl = useIntl();
  const subheader = useSubheader();
  if (Can("access", "Admin pages")) {
    subheader.setTitle(intl.formatMessage({id:"NOTIFICATIONS" }));
  }

  return Can("access", "Admin pages") ? (
    <Drawer>
      <Col>{<Notifications />}</Col>
    </Drawer>
  ) : (
    <></>
  );
};
