import { Chip } from "@material-ui/core";
import { ExactNumber } from "./ExactNumber";

// eslint-disable-next-line react/require-default-props
const ChipChangePercentage = ({value, addornemnt='%'}: {value:number, addornemnt?: string}) => {
  let color = '#45AC34';
  let background = '#ECF7EB';
  if (value > 0) {
    color = '#E51F22';
    background = '#FCE9E9';
  }
  return (value === 0) ? 
        <Chip 
          variant="outlined" 
          label={`0 ${  addornemnt}`} 
        /> :
        <Chip 
          variant="outlined" 
          style={{color, borderColor: color, background}}
          label={(<><ExactNumber value={value} />&nbsp;<span>{addornemnt}</span></>)} 
        />
        ;
}

export default ChipChangePercentage;