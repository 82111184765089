import React, { useEffect, useRef } from 'react';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import SustellNewDynamicInput from './SustellNewDynamicInput';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute',
  height: '16px',
  width: '16px',
  top: '28px',
  right: '7px',
};

const SustellDynamicInput = (props) => {
  const {
    label,
    availableOptions,
    subFieldName,
    tooltip,
    formState,
    disableAddButtonWhenAllOptionSelected = false,
    atLeastOneRequired = false,
    data,
    setData,
    fieldItemPrefix,
    required = false,
  } = props;

  const manualChange = useRef(false);
  const { getValues, setValue } = useFormContext();
  const {
    fields: dynamicFields,
    append: appendDynamicFields,
    remove: removeDynamicFields,
  } = useFieldArray({ name: `${fieldItemPrefix}.${subFieldName}` });
  useWatch({ name: `${fieldItemPrefix}.${subFieldName}` });

  const intl = useIntl();

  const getFilteredOptions = (index) => {
    const values = getValues(`${fieldItemPrefix}.${subFieldName}`);
    const removedItemsList = [
      ...values.slice(0, index),
      ...values.slice(index + 1),
    ];
    return availableOptions.filter(
      (option) => !removedItemsList.some((item) => item.type === option.value)
    );
  };

  useEffect(() => {
    if (manualChange.current) {
      // updates the underlying fields in form after manual
      // adding or removing feeds - important on removal!
      // but should not be triggered when components refresh by itself (change in selectbox or similar)
      setValue(`${fieldItemPrefix}.${subFieldName}`, data);
      manualChange.current = false;
    }
  }, [data, subFieldName, setValue, setData, fieldItemPrefix]);

  const append = () => {
    appendDynamicFields({ type: '', amount: '' });
  };

  const remove = (index) => {
    removeDynamicFields(index);
  };

  const selectItem = (index, value) => {
    const newSelectedItems = [...formValues];
    newSelectedItems[index].type = value;
    setData(newSelectedItems);
  };

  const inputItem = (index, value) => {
    const newSelectedItems = [...formValues];
    newSelectedItems[index].amount = value;
    setData(newSelectedItems);
  };

  const disableAddButton = () => {
    if (!disableAddButtonWhenAllOptionSelected) return false;
    const dataLen =
      getValues(`${fieldItemPrefix}.${subFieldName}`)?.length || 0;
    const optionsLen = availableOptions?.length || 0;
    return dataLen >= optionsLen;
  };

  const disableDeleteButton = () => {
    if (!atLeastOneRequired) return false;
    const dataLen = getValues(`${fieldItemPrefix}.${subFieldName}`)?.length;
    return dataLen === 1;
  };

  return (
    <DsmGrid containerOnly style={{ marginBottom: 'var(--dsm-spacing-px-6)' }}>
      {dynamicFields.map((item, index) => (
        <DsmGrid
          key={item.id}
          style={{
            width: '80%',
            position: 'relative',
            grid:
              formState !== FormType.View
                ? 'auto/auto 20px 24px'
                : 'auto/auto 24px',
            gap: 'var(--dsm-spacing-px-4)',
            padding: '0px',
            alignItems: 'start',
            rowGap: 'var(--dsm-spacing-px-05)',
          }}
        >
          <SustellNewDynamicInput
            label={label}
            availableOptions={getFilteredOptions(index)}
            index={index}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName={subFieldName}
            typeValue={item.type}
            formState={formState}
            onChangeSelect={(value) => selectItem(index, value)}
            onChangeInput={(value) => inputItem(index, value)}
            required={required}
          />
          {formState !== FormType.View && (
            <DsmButton
              variant="text"
              iconOnly
              destructive
              onClick={() => remove(index)}
              style={{ padding: '0px', marginTop: '24px' }}
              disabled={formState === FormType.View || disableDeleteButton()}
            >
              <DsmIcon slot="before" name="general/trash-01" />
            </DsmButton>
          )}
          {tooltip && (
            <Tooltip
              title={tooltip}
              placement="left"
              key={`inputPart4${item.id}`}
            >
              <DsmIcon
                name="general/help-circle"
                style={inlineTooltipClass}
                title=""
              />
            </Tooltip>
          )}
        </DsmGrid>
      ))}

      <DsmButton
        variant="text"
        onClick={(e) => append()}
        disabled={formState === FormType.View || disableAddButton()}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({ id: 'GENERAL.ADD.ANOTHER' })}{' '}
        {label?.toLowerCase()}
      </DsmButton>
    </DsmGrid>
  );
};
export default SustellDynamicInput;
