/*
  Commonly used validation builder functions
*/

import * as Yup from 'yup';
import {
  avgLiveWeightMins,
  avgLiveWeightMaxs,
} from '../../validationSchemaRestrictions';
import {
  defaultUnits,
  explicitConvertValue,
} from '../../../../sustell_15/utils/unit-utils';

import { numericRequiredWithGreaterThanMin } from '../../Baseline/validationSchema/validationObjectBuilderFunctions';

//local function
const optionalNumericObjectBuilder = (intl) => {
  return Yup.number()
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
    .transform((changed, original) => {
      return original === '' || original === null ? null : changed;
    })
    .nullable(true);
};

// intervention fields with set minimum value
// minimum value is always checked against calculated newValue after intervention data is applied
export const interventionFieldWithMin = (
  intl,
  fieldName,
  minValue = 0,
  minMsgKey = 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE'
) => {
  return optionalNumericObjectBuilder(intl)
    .when(`${fieldName}_changeMetric`, {
      is: 'set',
      then: Yup.number()
        .nullable(true)
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .min(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE' },
            { value: minValue }
          )
        ),
    })
    .when(`${fieldName}_changeMetric`, {
      is: (val) => val === 'absolute' || val === 'relative',
      then: Yup.number()
        .nullable(true)
        .test('absMinValueTest', '', function (value) {
          const { resolve, path, createError } = this;
          let newVal = resolve(Yup.ref(`${fieldName}_newValue`));
          // console.log('absMinValueTest', value, newVal, fieldName);

          if (typeof newVal === 'number' && newVal < minValue)
            return createError({
              path: path,
              message: intl.formatMessage(
                { id: minMsgKey },
                { value: minValue }
              ),
            });
          else return true;
        })
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })),
    });
};

// intervention fields with set minimum value that is a boundary - value must be more than set minimum
// minimum value is always checked against calculated newValue after intervention data is applied
export const interventionFieldWithMoreThanMin = (
  intl,
  fieldName,
  minValue = 0
) => {
  return optionalNumericObjectBuilder(intl)
    .when(`${fieldName}_changeMetric`, {
      is: 'set',
      then: Yup.number()
        .nullable(true)
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .moreThan(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MORE_THAN_VALUE' },
            { value: minValue }
          )
        ),
    })
    .when(`${fieldName}_changeMetric`, {
      is: (val) => val === 'absolute' || val === 'relative',
      then: Yup.number()
        .nullable(true)
        .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }))
        .test('absMinValueTest', '', function (value) {
          const { resolve, path, createError, parent } = this;
          let newVal = resolve(parent[`${fieldName}_newValue`]);
          if (typeof newVal === 'number' && newVal <= minValue)
            return createError({
              path: path,
              message: intl.formatMessage(
                { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MORE_THAN_VALUE' },
                { value: minValue }
              ),
            });
          else return true;
        }),
    });
};

// intervention fields with set minimum and maximim value
// min and max values are always checked against calculated newValue after intervention data is applied
// default values are for standard percentage ranfe 0-100, and according range warning message
export const interventionFieldWithMinMax = (
  intl,
  fieldName,
  minValue = 0,
  maxValue = 100,
  rangeMsgKey = 'VALIDATION.INTERVENTION.RESULT.FIELD.PERCENTAGE_RANGE'
) => {
  return optionalNumericObjectBuilder(intl)
    .when(`${fieldName}_changeMetric`, {
      is: 'set',
      then: Yup.number()
        .nullable(true)
        .min(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE' },
            { value: minValue }
          )
        )
        .max(
          maxValue,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MAX_VALUE' },
            { value: maxValue }
          )
        ),
    })
    .when(`${fieldName}_changeMetric`, {
      is: (val) => val === 'absolute' || val === 'relative',
      then: Yup.number()
        .nullable(true)
        .test('rangeValueTest', '', function (value) {
          const { resolve, path, createError, parent } = this;
          let newVal = resolve(parent[`${fieldName}_newValue`]);
          if (
            typeof newVal === 'number' &&
            (newVal < minValue || newVal > maxValue)
          )
            return createError({
              path: path,
              message: intl.formatMessage(
                { id: rangeMsgKey },
                { min: minValue, max: maxValue }
              ),
            });
          else return true;
        }),
    });
};

export const interventionFieldWithMinMaxAndMinNewValue = (
  intl,
  fieldName,
  minValue,
  maxValue,
  minResultValue = 0
) => {
  return optionalNumericObjectBuilder(intl)
    .when(`${fieldName}_changeMetric`, {
      is: 'set',
      then: Yup.number()
        .nullable(true)
        .min(
          minValue,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE' },
            { value: minValue }
          )
        )
        .max(
          maxValue,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MAX_VALUE' },
            { value: maxValue }
          )
        ),
    })
    .when(`${fieldName}_changeMetric`, {
      is: (val) => val === 'absolute' || val === 'relative',
      then: Yup.number()
        .nullable(true)
        .test('absMinValueTest', '', function (value) {
          const { resolve, path, createError, parent } = this;
          let newVal = resolve(parent[`${fieldName}_newValue`]);
          if (typeof newVal === 'number' && newVal < minResultValue)
            return createError({
              path: path,
              message: intl.formatMessage(
                { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE' },
                { value: minResultValue }
              ),
            });
          else return true;
        }),
    });
};

export const averageLiveWeightMortalitiesFn = (
  animalType,
  intl,
  userUOM = defaultUnits,
  fieldName = 'averageLiveWeightMortalities'
) =>
  Yup.number()
    .transform((changed, original) => {
      return original === '' || original === null ? null : changed;
    })
    .nullable(true)
    .test('averageLiveWeightMortalities', '', function (value) {
      let minVal = avgLiveWeightMins[animalType];
      let maxVal = avgLiveWeightMaxs[animalType];
      if (
        minVal &&
        userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass
      )
        minVal = Number(
          explicitConvertValue(
            minVal,
            defaultUnits.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          ).toFixed(3)
        );
      if (
        maxVal &&
        userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass
      )
        maxVal = Number(
          explicitConvertValue(
            maxVal,
            defaultUnits.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          ).toFixed(3)
        );
      const { resolve, path, createError, parent } = this;
      let newVal = resolve(parent[`${fieldName}_newValue`]);
      // console.log("NEWVAL, MIN, MAX: ", this.from[1].value.animalType, newVal, minVal, maxVal);
      if (typeof newVal === 'number' && newVal < minVal)
        return createError({
          path: path,
          message: intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE' },
            { value: minVal }
          ),
        });
      else if (typeof newVal === 'number' && maxVal && newVal > maxVal)
        return createError({
          path: path,
          message: intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MAX_VALUE' },
            { value: maxVal }
          ),
        });
      else return true;
    })
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }));

export const feedItemsInterventionTest = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      feedType: Yup.string(''),
      kgPerAnimal: interventionFieldWithMin(intl, 'kgPerAnimal', minValue),
    })
  );

// validate value of feed amount only if feed type is selected
export const feedAdditionalItemsInterventionTest = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      feedType: Yup.string(''),
      // avoid validation if feed type is not selected
      kgPerAnimal: Yup.number()
        .transform((changed, original) =>
          original === '' ? undefined : changed
        )
        .when('feedType', {
          is: (feedType) => !!feedType,
          then: numericRequiredWithGreaterThanMin(intl, minValue),
        }),
    })
  );

export const feedItemsInterventionTestWithOrigin = (intl, minValue = 0) =>
  Yup.array().of(
    Yup.object({
      feedType: Yup.string(''),
      origin: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
      ),
      kgPerAnimal: interventionFieldWithMin(intl, 'kgPerAnimal', minValue),
    })
  );


  export const calcInterventionValue = (
    baselineValue,
    changeValue,
    cahangeType,
  ) => {
    let numNewVal = changeValue;
    let numOldVal = baselineValue;
    let change = cahangeType || 'relative';

    if (numNewVal != null && typeof numNewVal === 'string')
      numNewVal = Number(numNewVal);

    if (numOldVal != null && typeof numOldVal === 'string')
      numOldVal = Number(numOldVal);

    // if old val (baseline val) is not defined, the change type is set
    if (!numOldVal && numOldVal !== 0) change = 'set';

    if (change === 'set') return numNewVal;

    if (change === 'absolute') return (numNewVal || 0) + (numOldVal || 0);

    // relative
    return (numOldVal || 0) * (1 + (numNewVal || 0) / 100);
  };