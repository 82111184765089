// the component is used as up front Text box containing title and possibly some descriptive text
// component may recive title or translationKeyTitle (translation key)
// omponent may recive component or translationKeyText (translation key)
// if translation key is sent as prop it will be used

import React from "react";
import { Box, Typography}  from '@material-ui/core';
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";

export const TextBox = ({title="Title", content="", titleColor='textPrimary', color='inherit', translationKeyTitle, translationKeyText, ...props}) => {
  const intl = useIntl();
  title = translationKeyTitle ? intl.formatMessage({id:translationKeyTitle}) : title;
  content = translationKeyText ? intl.formatMessage({id:translationKeyText}) : content;

  if (!title && !content) 
    return null;
  
  return (
    <Box mb='32px'>
    { title &&
      <Typography variant="h6" color={titleColor} >
        {title}
      </Typography>
    }
    { content &&
        <Typography color={color} style={{marginTop: '10px'}} variant="body1">
          {content}
        </Typography>
    }
    </Box>
  )
};