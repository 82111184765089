import { useEffect } from 'react';
import packageJson from '../../../../package.json';
import { Auth } from 'aws-amplify';
import { AnimalType, StageType } from '../../../graphql/types';

interface Engagement {
  linkInfo?: {
    linkURL: string;
    linkType: string;
    linkName: string;
    linkSection: string;
    linkLocation: string;
  };
  assetDownload?: {
    assetName: string;
    assetLocation: string;
  };
}
interface DigitalData {
  page?: {
    pageInfo?: {
      language: string;
      pageId: string;
      version: string;
      referringURL: string;
      breadcrumb: string[];
      currentURL: string;
      siteSection: string;
      errorPage: boolean;
    };
    user?: {
      profileInfo: {
        userRole: string[];
        loginStatus: string;
        encryptedLoginID: string;
      };
    };
  };
  engagement?: Engagement;
  Form?: {
    formInfo: {
      formName: string;
      formType: string;
      formErrorCount?: number;
      animalType?: AnimalType | string;
      stageType?: StageType | string;
    };
  };
  refinements?: {
    refinementInfo: {
      refinementCategory: string;
      refinementValue: string | string[];
      refinementType: string;
      refinementName: string;
      refinementStatus: string;
    };
  };
  Farms?: {
    farmInfo: {
      farmName: string;
      farmCountry: string;
      farmSpecie: string[];
      farmCity: string;
      productionProcessesCount: number;
      farmCount: number;
    };
  };
  Feeds?: {
    feedinfo: {
      feedName: string;
      feedYear: string;
      feedMillLocation: string;
      feedSpecie: string[];
      feedCount: number;
    };
  };
}

declare global {
  interface Window {
    digitalData: DigitalData;
    clickEvent: Engagement;
    _satellite: {
      track: Function;
    };
  }
}

const SITE_SECTION_MAPPERS = {
  homepage: 'Home',
  myfarms: 'My Farms',
  farm: 'My Farms',
  myfeeds: 'My Feeds',
  compoundfeeds: 'My Feeds',
  mydashboard: 'My Dashboard',
  admin: 'Admin',
  profile: 'Profile',
  footprints: 'Footprints',
  'feed-footprints': 'Footprints',
};

const ROUTE_MAPPERS = {
  homepage: 'Home',
  admin: 'Admin',
  notifications: 'Notifications',
  helpcenter: 'Helpcenter',
  users: 'Users',
  customers: 'Customers',
  myfeeds: 'Feeds List',
  myfarms: 'Farms List',
  mydashboard: 'Dashboard',
  footprints: 'Footprint',
  'feed-footprints': 'Feed Footprint',
  farm: 'Farm Details',
  compoundfeeds: 'Feed Details',
  profile: 'My Profile',
  pp: 'Production Process Details',
  'ds/NEW': 'Add New Baseline',
  ds: 'Baseline Dataset Details',
  'int/NEW': 'Add New Intervention',
  int: 'Intervention Dataset Details',
  b: 'Baseline Footprint',
  i: 'Intervention Footprint',
  f: 'Feed Footprint',
};

const getSelectedLanguage = () => {
  return window.localStorage.i18nConfig
    ? JSON.parse(window.localStorage.i18nConfig).selectedLang
    : 'en';
};

const getLocationData = () => {
  return {
    pageName: window.location.pathname,
    referringURL: `${window.location.origin}/${localStorage.getItem(
      'prev_url'
    )}`,
    currentURL: window.location.href,
  };
};

const getBreadCrumbData = (pageId: string) => {
  const element = document.querySelector('#kt_subheader');
  if (element?.textContent) {
    return element.textContent.split('/');
  }
  return [pageId];
};

const getLoginUserData = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return {
      userRole: user
        ? user.signInUserSession.accessToken.payload['cognito:groups']
        : [],
      loginStatus: user ? 'LoggedIn' : 'Guest',
      encryptedLoginID: user ? user.username : '',
    };
  } catch {
    return {
      userRole: [],
      loginStatus: 'Guest',
      encryptedLoginID: '',
    };
  }
};

const getPageId = (pathName: string) => {
  const urlParms = pathName.split('/');
  const staticParms = urlParms.filter((item) => !item.includes('-'));
  const keys = Object.keys(ROUTE_MAPPERS).filter((eachEntry) =>
    staticParms.includes(eachEntry)
  );
  const lastKey = keys && keys[keys.length - 1];
  return {
    pageId: lastKey ? ROUTE_MAPPERS[lastKey as keyof typeof ROUTE_MAPPERS] : '',
  };
};

const getSiteSection = (pathName: string) => {
  const key = Object.keys(SITE_SECTION_MAPPERS).find((eachEntry) =>
    pathName.includes(eachEntry)
  );
  return {
    siteSection: key
      ? SITE_SECTION_MAPPERS[key as keyof typeof SITE_SECTION_MAPPERS]
      : '',
  };
};

const updateSatelliteEvent = (eventName: string) => {
  if (window._satellite) {
    window._satellite.track(eventName);
  }
};

export const pageObjectEvent = async (pathName?: string) => {
  const userInfo = (await getLoginUserData()) as {
    userRole: string[];
    loginStatus: string;
    encryptedLoginID: string;
  };
  let pageId = getPageId(
    pathName ? pathName : getLocationData().pageName
  ).pageId;
  let siteSection = getSiteSection(
    pathName ? pathName : getLocationData().pageName
  ).siteSection;
  if (userInfo?.loginStatus && userInfo?.loginStatus === 'Guest') {
    pageId = 'Login Page';
    siteSection = 'Login Page';
  }
  const eventData: DigitalData = {
    page: {
      pageInfo: {
        language: getSelectedLanguage(),
        pageId,
        version: packageJson.version,
        referringURL: getLocationData().referringURL,
        breadcrumb: getBreadCrumbData(pageId),
        currentURL: getLocationData().currentURL,
        siteSection,
        errorPage: false,
      },
      user: {
        profileInfo: {
          userRole: userInfo?.userRole,
          loginStatus: userInfo?.loginStatus,
          encryptedLoginID: userInfo?.encryptedLoginID,
        },
      },
    },
  };
  return eventData;
};

/**
 * This event triggers Upon Successful submission of Form
 */
export const formSubmission = async (
  formName: string,
  formType: string,
  animalType?: AnimalType | string,
  stageType?: StageType | string
) => {
  const eventData: DigitalData = {
    Form: {
      formInfo: {
        formName,
        formType,
        animalType,
        stageType,
      },
    },
  };
  window.digitalData = {
    ...(await pageObjectEvent()),
    ...eventData,
  };
  if (window.clickEvent) {
    window.digitalData = {
      ...window.digitalData,
      ...{ engagement: window.clickEvent },
    };
  }
  updateSatelliteEvent('formSubmit');
};

/**
 * This event triggers Upon start of the form
 */
export const formStart = async (
  formName: string,
  formType: string,
  animalType?: AnimalType | string,
  stageType?: StageType | string
) => {
  const eventData: DigitalData = {
    Form: {
      formInfo: {
        formName,
        formType,
        animalType,
        stageType,
      },
    },
  };
  window.digitalData = {
    ...(await pageObjectEvent()),
    ...eventData,
  };
  if (window.clickEvent) {
    window.digitalData = {
      ...window.digitalData,
      ...{ engagement: window.clickEvent },
    };
  }
  updateSatelliteEvent('formStart');
};

/**
 * This event triggers Upon unsuccessful submission of Form
 */
export const formErrors = async (
  formName: string,
  formType: string,
  formErrorCount: number,
  animalType?: AnimalType | string,
  stageType?: StageType | string
) => {
  const eventData: DigitalData = {
    Form: {
      formInfo: {
        formName,
        formType,
        formErrorCount,
        animalType,
        stageType,
      },
    },
  };
  window.digitalData = { ...(await pageObjectEvent()), ...eventData };
  updateSatelliteEvent('formErrors');
};

/**
 * This event triggers Upon Link/CTA clicks
 */
export const ctaClick = async (
  linkURL: string,
  linkType: string,
  linkName: string,
  linkSection: string,
  linkLocation: string
) => {
  const engagement = {
    linkInfo: {
      linkURL,
      linkType,
      linkName,
      linkSection,
      linkLocation,
    },
  };
  const eventData: DigitalData = {
    engagement,
  };
  window.clickEvent = engagement;
  window.digitalData = { ...(await pageObjectEvent()), ...eventData };
  updateSatelliteEvent('linkClick');
};

const useAdobeDataLayer = () => {
  /**
   * This event triggers when browser loads the app any point of time
   */
  const pageLoadEvent = async () => {
    window.digitalData = await pageObjectEvent();
  };

  /**
   * This event triggers when navigate url from one state to another
   */
  const historyChangeEvent = async (pathName: string) => {
    window.digitalData = {
      ...(await pageObjectEvent(pathName)),
    };
    if (window.clickEvent) {
      window.digitalData = {
        ...window.digitalData,
        ...{ engagement: window.clickEvent },
      };
    }
    updateSatelliteEvent('pageViewed');
  };

  /**
   * This event triggers upon successful Login
   */
  const loginEvent = async () => {
    window.digitalData = await pageObjectEvent();
    updateSatelliteEvent('loginSuccessful');
  };

  /**
   * This event triggers Upon Link/CTA clicks
   */
  const ctaClickEvent = async (
    linkURL: string,
    linkType: string,
    linkName: string,
    linkSection: string,
    linkLocation: string
  ) => {
    await ctaClick(linkURL, linkType, linkName, linkSection, linkLocation);
  };

  /**
   * This event triggers Upon start of the form
   */
  const formStartEvent = async (
    formName: string,
    formType: string,
    animalType?: AnimalType | string,
    stageType?: StageType | string
  ) => {
    formStart(formName, formType, animalType, stageType);
  };

  /**
   * This event triggers Upon unsuccessful submission of Form
   */
  const formErrorsEvent = async (
    formName: string,
    formType: string,
    formErrorCount: number,
    animalType?: AnimalType | string,
    stageType?: StageType | string
  ) => {
    formErrors(formName, formType, formErrorCount, animalType, stageType);
  };

  /**
   * This event triggers Upon Successful submission of Form
   */
  const formSubmissionEvent = async (
    formName: string,
    formType: string,
    animalType?: AnimalType | string,
    stageType?: StageType | string
  ) => {
    await formSubmission(formName, formType, animalType, stageType);
  };

  /**
   * This event triggers Upon filter selection/update
   */
  const filtersEvent = async (
    refinementCategory: string,
    refinementValue: string | string[],
    refinementType: string,
    refinementName: string,
    refinementStatus: string
  ) => {
    const eventData: DigitalData = {
      refinements: {
        refinementInfo: {
          refinementCategory: refinementCategory,
          refinementValue: refinementValue,
          refinementType: refinementType,
          refinementName: refinementName,
          refinementStatus: refinementStatus,
        },
      },
    };
    window.digitalData = { ...(await pageObjectEvent()), ...eventData };
    updateSatelliteEvent('refinementsUpdated');
  };

  /**
   * This event triggers my farms/views
   */
  const myFarmsEvent = async (
    farmName: string,
    farmCountry: string,
    farmSpecie: string[],
    farmCity: string,
    productionProcessesCount: number,
    farmCount: number
  ) => {
    const eventData: DigitalData = {
      Farms: {
        farmInfo: {
          farmName: farmName,
          farmCountry: farmCountry,
          farmSpecie: farmSpecie,
          farmCity: farmCity,
          productionProcessesCount: productionProcessesCount,
          farmCount: farmCount,
        },
      },
    };
    window.digitalData = {
      ...(await pageObjectEvent()),
      ...eventData,
    };
    if (window.clickEvent) {
      window.digitalData = {
        ...window.digitalData,
        ...{ engagement: window.clickEvent },
      };
    }
  };

  /**
   * This event triggers my feeds/views
   */
  const myFeedsEvent = async (
    feedName: string,
    feedYear: string,
    feedMillLocation: string,
    feedSpecie: string[],
    feedCount: number
  ) => {
    const eventData: DigitalData = {
      Feeds: {
        feedinfo: {
          feedName: feedName,
          feedYear: feedYear,
          feedMillLocation: feedMillLocation,
          feedSpecie: feedSpecie,
          feedCount: feedCount,
        },
      },
    };
    window.digitalData = {
      ...(await pageObjectEvent()),
      ...eventData,
    };
    if (window.clickEvent) {
      window.digitalData = {
        ...window.digitalData,
        ...{ engagement: window.clickEvent },
      };
    }
  };

  /**
   * This event triggers when download files
   */
  const assetDownloadEvent = async (
    assetName: string,
    assetLocation: string
  ) => {
    const assetDownloadEng = {
      assetDownload: {
        assetName,
        assetLocation,
      },
    };
    const eventData: DigitalData = {
      engagement: assetDownloadEng,
    };
    window.digitalData = {
      ...(await pageObjectEvent()),
      ...eventData,
    };
    if (window.clickEvent) {
      window.digitalData = {
        ...window.digitalData,
        ...{ engagement: { ...window.clickEvent, ...assetDownloadEng } },
      };
    }
    updateSatelliteEvent('assetDownload');
  };

  const adobeAnalytics = () => {
    window.digitalData = window.digitalData || {};
  };

  useEffect(() => {
    adobeAnalytics();
  }, []);

  return {
    pageLoadEvent,
    historyChangeEvent,
    loginEvent,
    ctaClickEvent,
    formStartEvent,
    formErrorsEvent,
    formSubmissionEvent,
    filtersEvent,
    myFarmsEvent,
    myFeedsEvent,
    assetDownloadEvent,
  };
};

export default useAdobeDataLayer;
