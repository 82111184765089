import { FC, MutableRefObject } from "react";

import { Box } from "@material-ui/core";
import ImpactCategorySelect from "./ImpactCategorySelect";
import CompareTargetExportControls from "./CompareTargetExportControls";
import ImpactGaugesOverview from "./Target";
import DashboardDatasetChart from "./DashboardDatasetChart";
import DashboardOverviewTable from "./DashboardOverviewTable";
import DashboardSortControl from "./DashBoardSortControl";
import DashboardModel from "../../models/Dashboard/Dashboard";

interface DashboardDetailsProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
  dashboardPageRef: MutableRefObject<any>;
}

const DashboardDetails: FC<DashboardDetailsProps> = ({
  dashboard,
  renderDashboard,
  dashboardPageRef,
}) => (
    <Box mt="32px" >
      <ImpactCategorySelect  
        dashboard={dashboard}
        renderDependantComponents={renderDashboard}
      />
      <CompareTargetExportControls
        dashboard={dashboard}
        renderDashboard={renderDashboard}
        dashboardPageRef={dashboardPageRef}
      />
      <ImpactGaugesOverview 
        dashboard={dashboard}
       />
      <Box
        style={
          {
            border: '1px solid #F0F0F0',  borderRadius: '4px',
            padding: '20px'
          }
       }
        > 
        <DashboardSortControl
          dashboard={dashboard}
          renderDashboard={renderDashboard}
        />
        <DashboardDatasetChart
          dashboard={dashboard}
          renderDashboard={renderDashboard}
        />
       </Box>
       <DashboardOverviewTable
        dashboard={dashboard}
        renderDashboard={renderDashboard}
       />
    </Box>
  )

export default DashboardDetails;