/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useEffect } from 'react';
import { Redirect, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import useUserData from './modules/Auth/getAuthUserData';
import useAdobeDataLayer from '../app/sustell_15/analytics/adobeDataLayer';
import { usePrevLocation } from '../app/sustell_15/helpers/previousLocation';

export function Routes(props) {
  const userData = useUserData();
  const isAuthorized = null !== userData;
  const { historyChangeEvent } = useAdobeDataLayer();
  const location = useLocation();
  const prevLocation = usePrevLocation(location)
  localStorage.setItem('prev_url', prevLocation.pathname);

  const history = useHistory();

  useEffect(() => {
    history.listen((location) => {
        historyChangeEvent(location.pathname)
      });
  }, []);

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
