import React from 'react';

import { Grid } from '@material-ui/core';

import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { LayingHensBaseline } from '../../../../models/Baseline';

interface LHInterventionFeedsVariablePartProps {
  formType: FormType;
  stageIndex: number;
  baseline: LayingHensBaseline;
}

const LHInterventionFeedsVariablePart = ({
  formType = FormType.Add,
  stageIndex = 0,
  baseline,
}: LHInterventionFeedsVariablePartProps) => {
 
  const intl = useIntl();
  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  const feed = baseline?.stages?.[stageIndex]?.stageData?.feed;

  return (
    <>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.digestibility`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.METABOLIZABLE_ENERGY',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.BARN.RATION.METABOLIZABLE_ENERGY.TOOLTIP',
          })}
          metricUnit="%"
          baseline={feed?.digestibility}
          disabled={formType === 'view'}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.nitrogenContent`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.NITROGEN_CONTENT',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.BARN.RATION.NITROGEN_CONTENT.TOOLTIP',
          })}
          metricUnit="%"
          baseline={feed?.nitrogenContent}
          disabled={formType === 'view'}
        />
      </Grid>
      <Grid item>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.dryMatterContent`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.DRY_MATTER_CONTENT',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'INTERVENTION.FORM.BARN.RATION.DRY_MATTER_CONTENT.TOOLTIP',
          })}
          metricUnit="%"
          baseline={feed?.dryMatterContent}
          disabled={formType === 'view'}
        />
      </Grid>
    </>
  );
};

export default LHInterventionFeedsVariablePart;
