import lookupValues from '../../helpers/lookupValues';
import {
  CalculatedImpactDataCategoryComponent,
  CompartmentType,
  FootprintCategoryComponentModel,
  FootprintModel,
  FootprintDistributionElement,
  FootprintCategoryName,
} from './internal';

interface FootprintCategoryModelProps {
  key: FootprintCategoryName;
  description?: string;
  value?: number;
  unit: string;
  footprint: FootprintModel;
  components: CalculatedImpactDataCategoryComponent[];
  targets?: string;
}

class FootprintCategoryModel {
  readonly key: FootprintCategoryName;

  readonly description?: string;

  readonly value?: number;

  readonly unit: string;

  readonly compartments: CompartmentType[];

  readonly footprint: FootprintModel;

  #components: FootprintCategoryComponentModel[] = [];

  #totalAmount: number;

  #targets?: string;

  constructor(data: FootprintCategoryModelProps) {
    this.key = data.key;
    // this.description = footprintCategoryDescriptions[this.key];
    this.value = data.value;
    this.unit = data.unit;
    // this.target = data.targets ? this.getTargetValue(data.targets) : undefined;
    this.compartments = lookupValues.impactCategoriesMapping.all[
      this.key as keyof typeof lookupValues.impactCategoriesMapping.all
    ] as CompartmentType[];

    this.footprint = data.footprint;
    this.#targets = data.targets ? data.targets : undefined;
    this.#components = data.components
      .map(
        (value) =>
          new FootprintCategoryComponentModel({
            name: value.name,
            lookupKey: value.lookupKey,
            percentage: value.impact,
            amount: value.impactAbsolute,
            analysisGroup: value.analysisGroup,
            category: this,
            subCategories: value.subCategories,
          })
      )
      .filter((c) => c.amount) // Only show non-zero components?
      .sort((c1, c2) => (c2.amount || 0) - (c1.amount || 0));
  }

  /**
   * @param targets
   *  set target value
   */
  setTargetValue(targets: string) {
    this.#targets = targets;
  }

  /**
   *
   * @returns target value based on category key
   */
  get targetValue(): number | undefined {
    if (this.#targets) {
      const targetsObj = JSON.parse(this.#targets) as {
        [key: string]: number | undefined;
      };
      const targetKey = Object.keys(targetsObj).find((k) => k === this.key);
      if (targetKey) {
        return targetsObj[targetKey] as number;
      }
    }
    return undefined;
  }

  /**
   *
   * @returns targetMatch value based on target value
   */
  get targetMatch(): number | undefined {
    if (this.value && this.targetValue) {
      return Math.round(
        ((this.value - this.targetValue) / this.targetValue) * 100
      );
    }
    return undefined;
  }

  get componentsUnfiltered(): FootprintCategoryComponentModel[] {
    let combinedComponents = this.#components;

    if (this.comparison) {
      // Find any components in the comparison that cannot be found here
      const unmatchedComponents = this.comparison.#components.filter(
        (possiblyUnmatchedComponent) =>
          this.#components.findIndex(
            (component) => component.name === possiblyUnmatchedComponent.name
          ) === -1
      );
      // Add them to the combined list
      combinedComponents = this.#components.concat(
        unmatchedComponents.map(
          (unmatchedComponent) =>
            new FootprintCategoryComponentModel({
              name: unmatchedComponent.name,
              lookupKey: unmatchedComponent.lookupKey,
              category: this,
              analysisGroup: unmatchedComponent.analysisGroup,
            })
        )
      );
    }
    return combinedComponents;
  }

  /**
   * Get the list of (filtered) components for this category.
   * This list might change when a comparison is set due to extra components
   * in the compared footprint category.
   */
  get components(): FootprintCategoryComponentModel[] {
    const combinedComponents = this.componentsUnfiltered;

    return this.footprint.analysisGroupFilters.length
      ? combinedComponents.filter((com: FootprintCategoryComponentModel) =>
          this.footprint.analysisGroupFilters.includes(com.analysisGroup)
        )
      : combinedComponents;
  }

  /**
   * Get the distribution of the (filtered) components over the analysis groups
   */
  get distribution(): FootprintDistributionElement[] {
    return this.components.reduce(
      (
        distrib: FootprintDistributionElement[],
        component: FootprintCategoryComponentModel
      ) => {
        const distIndex = distrib.findIndex(
          (d) => d.analysisGroup === component.analysisGroup
        );

        const dist =
          distIndex >= 0
            ? distrib.splice(distIndex, 1)[0]
            : { analysisGroup: component.analysisGroup, percentage: 0,  analysisGroupLabel: component.label};
        dist.percentage += component.percentage || 0;
        dist.amount = (dist.percentage * (this?.value || 0)) / 100;

        distrib.push(dist);
        return distrib;
      },
      []
    );
  }

  /**
   * Returns the direct comparison for this category from the compared Footprint
   * Returns undefined if there is no compared footprint
   * or no matching category in the compared footprint.
   */
  get comparison(): FootprintCategoryModel | undefined {
    if (!this.footprint.comparison) return undefined;
    return this.footprint.comparison.categories.find(
      (comparisonCat) => comparisonCat.key === this.key
    );
  }

  /**
   * Returns the comparison percentageof two datasets
   * Retruns 0 if there is no compared footprint
   */
  get comparisonChangePercentage(): number {
    if (this.comparison?.value && this.value) {
      return Math.round((1 - this.comparison.value / this.value) * -100);
    }
    return 0;
  }

  // It calculates total amount of unfiltered components
  get totalAmount(): number {
    return this.value || 0;
  }

  /**
   * If analysis fitler is on, returns amount of filtered components, otherwise total value
   */
  get amount(): number {
    return this.footprint.analysisGroupFilters.length
      ? this.components.reduce(
          (total, component) => total + (component.amount || 0),
          0
        )
      : this.totalAmount;
  }
}

export default FootprintCategoryModel;
