export const emissionFieldsMapIn = new Map([
  ['methane_enteric_fermentation', 'methaneEntericFermentation'],
  ['methane_from_manure_and_pretreatment', 'methane'],
  ['nitrous_oxide_direct_from_manure_and_pretreatment', 'nitrousOxideDirect'],
  [
    'nitrous_oxide_indirect_from_manure_and_pretreatment',
    'nitrousOxideIndirect',
  ],
  ['amonia_from_manure_and_pretreatment', 'amonia'],
  ['nitric_oxide_from_manure_and_pretreatment', 'nitricOxide'],
  ['non_methane_volatile_compunds_from_animal_housing', 'nonMethaneVolatile'],
  ['non_methane_volatile_silage_feeding', 'nonMethaneVolatileSilage'],
  ['pm_10_from_animal_housing', 'PM10'],
  ['pm_2.5_from_animal_housing', 'PM25'],
  ['total_suspended_particles', 'totalSuspendedParticles'],
  ['nitrogen_dioxide', 'nitrogenDioxide'],
  ['phosphorus', 'phosphorus'],
  ['nitrogen', 'nitrogen'],
  ['methane', 'methaneFish'],
  ['ammonia_housing', 'ammoniaHousing'],
]);

export const emissionFieldsMapOut = new Map([
  ['methaneEntericFermentation', 'methane_enteric_fermentation'],
  ['methane', 'methane_from_manure_and_pretreatment'],
  ['nitrousOxideDirect', 'nitrous_oxide_direct_from_manure_and_pretreatment'],
  [
    'nitrousOxideIndirect',
    'nitrous_oxide_indirect_from_manure_and_pretreatment',
  ],
  ['amonia', 'amonia_from_manure_and_pretreatment'],
  ['nitricOxide', 'nitric_oxide_from_manure_and_pretreatment'],
  ['nonMethaneVolatile', 'non_methane_volatile_compunds_from_animal_housing'],
  ['nonMethaneVolatileSilage', 'non_methane_volatile_silage_feeding'],
  ['PM10', 'pm_10_from_animal_housing'],
  ['PM25', 'pm_2.5_from_animal_housing'],
  ['totalSuspendedParticles', 'total_suspended_particles'],
  ['nitrogenDioxide', 'nitrogen_dioxide'],
  ['phosphorus', 'phosphorus'],
  ['nitrogen', 'nitrogen'],
  ['methaneFish', 'methane'],
  ['ammoniaHousing', 'ammonia_housing'],
]);

export const emissionFieldsMapInV2 = new Map([
  ['METHANE_ENTERIC_FERMENTATION', 'methaneEntericFermentation'],
  ['METHANE_MMS', 'methane'],
  ['NITROUS_OXIDE_DIRECT', 'nitrousOxideDirect'],
  ['NITROUS_OXIDE_LEACHING', 'nitrousOxideIndirect'],
  ['AMMONIA_STORAGE', 'amonia'],
  ['AMMONIA_HOUSING', 'ammoniaHousing'],
  ['NITROGEN_OXIDES_STORAGE', 'nitricOxide'],
  ['NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS', 'nonMethaneVolatile'],
  ['PM10', 'PM10'],
  ['PM2_5', 'PM25'],
  ['TSP', 'totalSuspendedParticles']
]);

export const emissionFieldsMapInPoultryV2 = new Map([
  ['METHANE_MMS', 'methane'],
  ['NITROUS_OXIDE_DIRECT', 'nitrousOxideDirect'],
  ['NITROUS_OXIDE_LEACHING', 'nitrousOxideIndirect'],
  ['AMMONIA_STORAGE', 'amonia'],
  ['NITROGEN_OXIDES_STORAGE', 'nitricOxide'],
  ['NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS', 'nonMethaneVolatile'],
  ['PM10', 'PM10'],
  ['PM2_5', 'PM25'],
  ['TSP', 'totalSuspendedParticles']
]);

export const emissionFieldsMapOutV2 = new Map([
  ['methaneEntericFermentation', 'METHANE_ENTERIC_FERMENTATION'],
  ['methane', 'METHANE_MMS'],
  ['nitrousOxideDirect', 'NITROUS_OXIDE_DIRECT'],
  ['nitrousOxideIndirect','NITROUS_OXIDE_LEACHING'],
  ['amonia', 'AMMONIA_STORAGE'],
  ['ammoniaHousing', 'AMMONIA_HOUSING'],
  ['nitricOxide', 'NITROGEN_OXIDES_STORAGE'],
  ['nonMethaneVolatile', 'NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS'],
  ['PM10', 'PM10'],
  ['PM25', 'PM2_5'],
  ['totalSuspendedParticles', 'TSP'],
]);

export const emissionFieldsMapOutPoultryV2 = new Map([
  ['methane', 'METHANE_MMS'],
  ['nitrousOxideDirect', 'NITROUS_OXIDE_DIRECT'],
  ['nitrousOxideIndirect','NITROUS_OXIDE_LEACHING'],
  ['amonia', 'AMMONIA_STORAGE'],
  ['nitricOxide', 'NITROGEN_OXIDES_STORAGE'],
  ['nonMethaneVolatile', 'NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS'],
  ['PM10', 'PM10'],
  ['PM25', 'PM2_5'],
  ['totalSuspendedParticles', 'TSP'],
]);
