import React, {useState, useEffect, FC} from "react";
import { useFormContext } from "react-hook-form";
import { Grid } from '@material-ui/core';
import StageButtonBox from "../../StageButtonBox";
import DOutputFormDialog from "./DOutputFormDialog";
import DManureFormDialog from "./DManureFormDialog";
import { FeedsFormDialog } from "../../CommonDataParts/FeedsFormDialog";
import DEmissionsFormDialog from "./DEmissionsFormDialog";
import DFeedsVariablePart from "./DFeedsVariablePart";
import { BaselineStageProps } from "../../common";
import { DairyBaseline, DairyEmissions, DairyStagesItem, Input3NOP, LatestCalc3NOP } from "../../../../models/Baseline/DairyBaseline";

interface DairyStagesProps extends Omit<BaselineStageProps, 'baseline' | 'item' > {
  baseline: DairyBaseline;
  item: DairyStagesItem;
}

const DairyStages: FC<DairyStagesProps> = ({
  stageIndex,
  baseline,
  getCompleteness,
  manureManagementSystems,
  singleIngredients,
  compoundFeeds,
  item,
  formType,
  stageType,
}) => {
  const [activeDialog, setActiveDialog] = useState<string | null>('');
  
  // Latest sucessful calculation parameters, use to detect input changes after calc
  const [latestBovaerCalc, setLatestBovaerCalc] = useState<LatestCalc3NOP | null>(null);

  // set initial input parameters if exist
  useEffect(() => {
    if (
      baseline?.stages &&
      (stageIndex || stageIndex === 0) &&
      baseline?.stages?.length > stageIndex &&
      baseline?.stages[stageIndex]?.stageData?.emissions
    ) {
      const emissionPart = baseline?.stages[stageIndex]?.stageData?.emissions as DairyEmissions;
      const input: Input3NOP = {
        dmiAmountPerKg: emissionPart?.supplement3NOP?.dmiAmountPerKg,
        ndfAmountPercentage: emissionPart?.supplement3NOP?.ndfAmountPercentage,
        fatAmountPercentage: emissionPart?.supplement3NOP?.fatAmountPercentage,
        _3nopDosePerCowPerDay:
          // eslint-disable-next-line no-underscore-dangle
          emissionPart?.supplement3NOP?._3nopDosePerCowPerDay,
        numberOfCowsCovered: emissionPart?.supplement3NOP?.numberOfCowsCovered,
        averageAnimalsPresent: baseline?.stages[stageIndex]?.stageData?.manure?.averageAnimalsPresent
      };

      setLatestBovaerCalc({ input });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline, stageIndex]);

  const showDialog = (dialog: React.SetStateAction<string | null>) => {
    setActiveDialog(dialog);
  };

  const reset3NOP = () => {
    if (!formContext.getValues(`stages.${stageIndex}.stageData.emissions.is3NOPSelected`)) {
      formContext.setValue(`stages.${stageIndex}.stageData.emissions.supplement3NOP`, {
        _3nopDosePerCowPerDay: '',
        dmiAmountPerKg: '',
        fatAmountPercentage: '',
        ndfAmountPercentage: '',
        numberOfCowsCovered: '',
        calculatedResult: '',
      });
    }
  }

  const formContext = useFormContext<DairyBaseline>();
  const stagePrefix = `stages.${stageIndex}`;
  
  return (
    <>
      <input 
        ref={formContext.register()} 
        type="hidden" 
        name={`${stagePrefix}.id`} 
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        defaultValue={item.id}
        />
      <input 
        ref={formContext.register()} 
        type="hidden" 
        name={`${stagePrefix}.name`} 
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.animalType`}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        defaultValue={item.stageData?.animalType}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.productionSystem`}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        defaultValue={item.productionSystem}
      />
      <FeedsFormDialog formVisible={ activeDialog==='feed' } 
        handleCancel={ () => setActiveDialog(null)} 
        formType={formType} 
        baseline={baseline} 
        itemIndex={stageIndex}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        compoundFeeds={compoundFeeds}  
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        singleIngredients={singleIngredients}
        >
        <DFeedsVariablePart 
          itemIndex={stageIndex}
          formType={formType}
          handleCancel={() => undefined}
        />
      </FeedsFormDialog>
      <DManureFormDialog
        formVisible={activeDialog === 'manure'}
        formType={formType} 
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        itemIndex={stageIndex}
        latestBovaerCalc={latestBovaerCalc}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        manureManagementSystems={manureManagementSystems}
      />
     <DEmissionsFormDialog
        formVisible={activeDialog === 'emissions'}
        formType={formType} 
        handleCancel={() => { 
          setActiveDialog(null);
          reset3NOP();
        }}
        baseline={baseline}
        itemIndex={stageIndex}
        latestBovaerCalc={latestBovaerCalc}
        setLatestBovaerCalc={setLatestBovaerCalc}
      />
      <DOutputFormDialog
        formVisible={activeDialog === 'output'}
        formType={formType} 
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        itemIndex={stageIndex}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        stageType={stageType || ''}
      /> 
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          disabled
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE" 
          iconCode="" 
          error={undefined}
          handleOpen={() => undefined}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          // eslint-disable-next-line @typescript-eslint/unbound-method
          completness={getCompleteness ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.feed`) : 0 } 
          error={formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.feed}
          handleOpen={() => showDialog('feed')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="manureIcon"
          completness={
            getCompleteness
              // eslint-disable-next-line @typescript-eslint/unbound-method
              ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.manure`)
              : 0
          }
          error={
            formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.manure
          }
          handleOpen={() => showDialog('manure')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="DAIRY"
          completness={
            getCompleteness
              // eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-member-access
              ? getCompleteness(formContext.getValues, `${stagePrefix}.stageData.output`, item.productionSystem!=='Dairy cows')
              : 0
          }
          error={
            formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog('output')}
        />
        <StageButtonBox
          optional
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          error={
            formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.emissions
          }
          handleOpen={() => showDialog('emissions')}
        />
      </Grid>
    </>
  );
};

export default DairyStages;