import React, { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { FormType } from '../FarmFlow/common';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import ReactHookDsmSelect from '../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import { enumToOptionsArrayWithTranslatedStrings } from '../../utils/obj-utils';
import { ChemicalType, RefrigerantType } from '../../../../graphql/types';
import { UserProfilePrefs } from '../../../modules/Helpers/UserProfilePrefs';

interface FacilityResourcesProps {
  formType: FormType;
}

type DsmTypes = any;

const FacilityAuxiliaryInputs: FC<FacilityResourcesProps> = ({ 
  formType,
 }) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const classes: DsmTypes = processAndStageStylesV2();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const outputMassUnit = userUOM.unitBarnOutputMass;
  
  const {
    fields: chemicals,
    append: appendChemicals,
    remove: removeChemicals,
  } = useFieldArray({
    name: `auxiliaryInputs.chemicals`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const {
    fields: refigerants,
    append: appendRefigerant,
    remove: removeRefigerant,
  } = useFieldArray({
    name: `auxiliaryInputs.refrigerants`,
    control: formContext.control,
    keyName: 'keyId',
  });

  useEffect(() => {
    if (formType === FormType.Add) {
      appendRefigerant({type: '', amount: ''});
      appendChemicals({type: '', amount: ''});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);


  return (
    <>
      <br />
      <h3>
        {intl.formatMessage({ id: 'SUSTELL.FACILITIES.AUXILIARY.TITLE' })}
      </h3>
      <p>
        {intl.formatMessage({ id: 'SUSTELL.FACILITIES.AUXILIARY.DESCRIPTION' })}
      </p>

      {chemicals &&
        chemicals?.map((field, index) => (
          <React.Fragment key={field.keyId}>
            <DsmGrid 
              className={classes.additionalEntriesBlockHolder}
              style={{width: '66%'}}
            >
              {((formType !== FormType.View)) && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => {
                    removeChemicals(index);
                  }}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  key={`chemicals-type-${field.keyId || ''}`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.FACILITIES.AUXILIARY.CHEMICAL_TYPE',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.FACILITIES.AUXILIARY.CHEMICAL_TYPE.PLACEHOLDER',
                  })}
                  adornment=' '
                  tooltip={intl.formatMessage({id: "SUSTELL.FACILITIES.AUXILIARY.CHEMICAL_TYPE.TT"})}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  options={ enumToOptionsArrayWithTranslatedStrings(ChemicalType ,intl, 'SUSTELL.FACILITIES.AUXILIARY.CHEMICAL_TYPE')}
                  name={`auxiliaryInputs.chemicals[${index}].type`}
                  changeHandler={() => {}}
                  disabled={formType === FormType.View}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={field.type}
                />
                <ReactHookDsmInput
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={field.amount}
                  key={`chemicals-amount-${field.keyId || ''}`}
                  name={`auxiliaryInputs.chemicals[${index}].amount`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.FACILITIES.AUXILIARY.CHEMICAL_AMOUNT',
                  })}
                  type="number"
                  tooltip={intl.formatMessage({id: "SUSTELL.FACILITIES.AUXILIARY.CHEMICAL_AMOUNT.TT"})}
                  disabled={formType === FormType.View}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  adornment={intl.formatMessage({id: "SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER"}, {unit: outputMassUnit})}
                />
              </DsmGrid>
            </DsmGrid>
          </React.Fragment>
        ))}
      <DsmButton
        variant="text"
        disabled={formType === FormType.View}
        onClick={() => {
          appendChemicals({ type: '', amount: '' });
        }}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({ id: 'SUSTELL.FACILITIES.ADD_ANOTHER_CHEMICAL' })}
      </DsmButton>
      <br />

      {refigerants &&
        refigerants?.map((field, index) => (
          <React.Fragment key={field.keyId}>
            <DsmGrid className={classes.additionalEntriesBlockHolder} style={{width: '66%'}}>
              {formType !== FormType.View && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => {
                    removeRefigerant(index);
                  }}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={field.type}
                  key={`refrigerants-type-${field.keyId || ''}`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.FACILITIES.AUXILIARY.REFRIGERANT_TYPE',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.FACILITIES.AUXILIARY.REFRIGERANT_TYPE.PLACEHOLDER',
                  })}
                  adornment=' '
                  tooltip={intl.formatMessage({id: "SUSTELL.FACILITIES.AUXILIARY.REFRIGERANT_TYPE.TT"})}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  options={ enumToOptionsArrayWithTranslatedStrings(RefrigerantType ,intl, 'SUSTELL.FACILITIES.AUXILIARY.REFRIGERANT_TYPE')}
                  name={`auxiliaryInputs.refrigerants[${index}].type`}
                  changeHandler={() => {}}
                  disabled={formType === FormType.View}
                />
                <ReactHookDsmInput
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={field.amount}
                  key={`refrigerants-amount-${field.keyId || ''}`}
                  name={`auxiliaryInputs.refrigerants[${index}].amount`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.FACILITIES.AUXILIARY.REFRIGERANT_AMOUNT',
                  })}
                  type="number"
                  tooltip={intl.formatMessage({id: "SUSTELL.FACILITIES.AUXILIARY.REFRIGERANT_AMOUNT.TT"})}
                  disabled={formType === FormType.View}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  adornment={intl.formatMessage({id: "SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER"}, {unit: outputMassUnit})}
                />
              </DsmGrid>
            </DsmGrid>
          </React.Fragment>
        ))}
      <DsmButton
        variant="text"
        disabled={formType === FormType.View}
        onClick={() => {
          appendRefigerant({ type: '', amount: '' });
        }}
      >
        <DsmIcon slot="before" name="general/plus-circle" />
        {intl.formatMessage({
          id: 'SUSTELL.FACILITIES.ADD_ANOTHER_REFRIGERANT',
        })}
      </DsmButton>
      <br />
    </>
  );
};

export default FacilityAuxiliaryInputs;
