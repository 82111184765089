import { FC, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

import Select, { MultiValue } from "react-select";
import { Box, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from "@material-ui/core";
import { CloseRounded, InfoOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { footprintDetailsStyles } from "../../../../_metronic/layout";
import { AnalysisGroupsFarm, AnalysisGroupsFeed, DashboardType, Simulation } from "../../models/Dashboard/DashboardTypes";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { reactSelectMultiStyles } from "./helper";
import { setDashboardOptionsDraft } from "../../../state/drafts/dashboardFiltersDraftSlice";

interface SimulationBoxProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
  handleClose: () => void;
}

type SelectMultiValue = MultiValue<{
  value: string | undefined;
  label: string | undefined;
}>

const SimulationBox: FC<SimulationBoxProps> = ({
  dashboard,
  renderDashboard,
  handleClose,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [, forceReload] = useState<object>({});
  const [simulation, setSimulation] = useState<Simulation>({
    references: [],
    simulations: []
  });

  useEffect(() => {
    if (dashboard?.hasSimulations) {
      setSimulation(dashboard.simulation);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard]);

  const classes = footprintDetailsStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRender = useCallback(
    debounce(() => {
      if (dashboard) {
        forceReload({});
        dispatch(setDashboardOptionsDraft(dashboard));
        renderDashboard();
      }
    }, 500),
    []
  );

  const prodProcessesChanged = (valueProcesses: SelectMultiValue) => {
    // if both all proeceses and a single processes are selected
    // dont apply simulation at all
    if (
      valueProcesses.some((item) => item.value === undefined) &&
      valueProcesses.some((item) => !!item.value)
    ) {
      return;
    }

    const simCopy = JSON.parse(JSON.stringify(simulation)) as Simulation;
    if (valueProcesses.every((item) => !!item.value)) {
      simCopy.references = valueProcesses
        .filter((el) => el.value && typeof el.value === 'string')
        .map((el) => el.value) as string[];
    } else {
      simCopy.references = [];
    }
    setSimulation(simCopy);
    if (dashboard) {
      dashboard.setSimulation(simCopy);
      forceReload({});
      dispatch(setDashboardOptionsDraft(dashboard));
      renderDashboard();
    }
  };


  const handleSimulationChanged = (
    value: undefined | string,
    optionType: AnalysisGroupsFarm | AnalysisGroupsFeed
  ) => {
    if (!dashboard) {
      return;
    }
    const copySimulations = JSON.parse(JSON.stringify(simulation)) as Simulation;
    const existing = copySimulations.simulations.find(
      (el) => el.analysisGroup === optionType
    );
    if (existing) {
      existing.change = Number(value || 0);
    } else {
      copySimulations.simulations.push({
        analysisGroup: optionType,
        change: Number(value || 0),
      });
    }

    setSimulation(copySimulations);
    dashboard.setSimulation(copySimulations);
    debouncedRender();
  };

  const handleCloseSimulationBox = () => {
    dashboard?.clearSimulation();
    dispatch(setDashboardOptionsDraft(dashboard));
    renderDashboard();
    handleClose();
  };

  const analysisGroups = dashboard?.type === DashboardType.Feeds ? AnalysisGroupsFeed : AnalysisGroupsFarm;

  const footprintsOptions = [
    {
      label: intl.formatMessage({
        id: 'SUSTELL.DASHBOARD.SIMULATION.ALL_PROD_PROCESSES',
      }),
      value: undefined,
    },
    ...(dashboard?.unfilteredFootprints.map((f) => ({
      value: f.reference,
      label: f.name,
    })) || []),
  ];

  return(
      <Box mt="32px" mb="32px" p="32px" style={{background: '#F0F7FB', borderRadius: '4px'}}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" color="textPrimary" style={{fontWeight: 500}}>
            { intl.formatMessage({id: "SUSTELL.DASHBOARD.SIMULATION"}) }
          </Typography>
          <IconButton
            style={{
              background: '#E51F22',
              height: '40px',
              width: '40px',
              borderRadius: '4px'
            }}
            className={classes.closeButtonStyle}
            onClick={() => handleCloseSimulationBox()}
          >
            <CloseRounded htmlColor="white" />
          </IconButton>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{marginTop: '32px'}}>
          <Grid item xs={6}>
            <InputLabel
                style={{ fontWeight: 500 }}
            >
             {intl.formatMessage({id:"SUSTELL.DASHBOARD.SIMULATION.APPLY_TO" })}
            </InputLabel>
            <Select
              placeholder= {intl.formatMessage({id: "SUSTELL.DASHBOARD.SIMULATION.ALL_PROD_PROCESSES" })}
              isMulti
              isSearchable
              isClearable
              value={
                  dashboard?.simulation.references.map(ref => {
                    const el =  footprintsOptions.find(option => option.value === ref);
                    return el;
                  })
              }
              options={footprintsOptions}
              onChange={(newVal: SelectMultiValue) => prodProcessesChanged(newVal)}
              styles={reactSelectMultiStyles}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3} style={{marginTop: '32px'}} key="simulation-box-filters">
          {
            Object.values(analysisGroups).map(analysisGroup => 
              <Grid item xs={3} key={`simulation-box-${analysisGroup as string}`}>
                <InputLabel
                    style={{ fontWeight: 500 }}
                >
                {analysisGroup} (%)
                </InputLabel>
                <TextField
                  style={{background: 'white'}}
                  defaultValue={0}
                  placeholder="0"
                  type="number"
                  size="small"
                  margin="none"
                  value={dashboard?.simulation.simulations.find(sim => sim.analysisGroup === analysisGroup)?.change}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><InfoOutlined /></InputAdornment>,
                  }}
                  onChange={e => handleSimulationChanged(e.target.value, analysisGroup as (AnalysisGroupsFarm | AnalysisGroupsFeed))}
                  variant="outlined"
                />
              </Grid>
            )
          }
          { (dashboard?.type === DashboardType.Feeds) &&
            <Grid item xs={3} />
          }
        </Grid>
    </Box>    
  );
}

export default SimulationBox;