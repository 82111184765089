import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import {
  numericOptionalWithGreaterThanMin,
  numericOptionalWithMin,
  numericRequiredWithGreaterThanMin,
} from '../../../modules/Farms/Baseline/validationSchema/validationObjectBuilderFunctions';
import { SlaughterhouseFacilityForm } from '../../models/Facility/FacilityTypes';
import { forbiddenChars } from '../../helpers/validations';

export type EnergyTypeValidation = Pick<
  SlaughterhouseFacilityForm,
  'electricity' | 'gasUse' | 'selfGeneratedRenewables' | 'additionalEnergyTypes'
>;

export const isAmountAndTypeFilled = (
  items?: Array<{ amount: unknown; type: unknown }>
) => {
  if (!items || !items.length) return false;
  return items.some(({ amount, type }) => amount && type);
};

const validateEnergyTypes = (
  values: EnergyTypeValidation,
  createError: (params: Yup.CreateErrorOptions) => Yup.ValidationError,
  intl: IntlShape
) => {
  const {
    gasUse,
    electricity,
    additionalEnergyTypes,
    selfGeneratedRenewables,
  } = values;

  const isAnyAdditionalEnergyFilled =
    additionalEnergyTypes && isAmountAndTypeFilled(additionalEnergyTypes);
  const isAnyRenewablesFilled =
    selfGeneratedRenewables && isAmountAndTypeFilled(selfGeneratedRenewables);

  if (
    !electricity &&
    !gasUse &&
    !isAnyAdditionalEnergyFilled &&
    !isAnyRenewablesFilled
  ) {
    const electricityError = createError({
      path: 'electricity',
      message: intl.formatMessage({
        id: 'VALIDATION.FACILITY.ENERGY_TYPES.REQUIRED',
      }),
    });
    const gasUseError = createError({
      path: 'gasUse',
      message: intl.formatMessage({
        id: 'VALIDATION.FACILITY.ENERGY_TYPES.REQUIRED',
      }),
    });

    return new Yup.ValidationError([electricityError, gasUseError]);
  }
  return true;
};

const slaughterHouseFacilityFormValidation = (intl: IntlShape) => {
  const facilitySchema = Yup.object({
    name: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
      .min(
        3,
        intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
      )
      .max(
        256,
        intl.formatMessage(
          { id: 'VALIDATION.FIELD.MAX_LENGTH' },
          { count: 256 }
        )
      ).concat(
        forbiddenChars(
          intl,
          ['"', "'"],
          'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
        )
      ),
    type: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
    ),
    location: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
    ),
    totalLiveweight: numericRequiredWithGreaterThanMin(intl, 0),
    currency: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
    ),
    wasteWaterAmount: numericRequiredWithGreaterThanMin(intl, 0),
    waterUse: numericRequiredWithGreaterThanMin(intl, 0),
    targetSpecies: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
    ),
    auxiliaryInputs: Yup.object({
      chemicals: Yup.array().of(
        Yup.object({
          amount: Yup.number()
            .when('type', {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              is: (val: any) => val,
              then: numericRequiredWithGreaterThanMin(intl, 0),
              otherwise: numericOptionalWithGreaterThanMin(intl, 0),
            })
            .test('0indexOptional', '', function (vals: any):
              | Yup.ValidationError
              | boolean {
              const { path, createError } = this;
              const indexStart = path.lastIndexOf('[') + 1;
              const indexEnd = path.lastIndexOf(']');
              const itemIndex = path.substring(indexStart, indexEnd);

              // Don't validate first element of arr only as it is initialy shown on form
              // and it's not mandatory, or if type is selected
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (itemIndex !== '0' && !vals) {
                return createError({
                  path,
                  message: intl.formatMessage({
                    id: 'VALIDATION.FIELD.REQUIRED',
                  }),
                });
              }
              return true;
            }),
          type: Yup.string().test('0indexOptional', '', function (vals: any):
            | Yup.ValidationError
            | boolean {
            const { path, createError } = this;
            const indexStart = path.lastIndexOf('[') + 1;
            const indexEnd = path.lastIndexOf(']');
            const itemIndex = path.substring(indexStart, indexEnd);

            // Don't validate first element of arr only as it is initialy shown on form
            // and it's not mandatory
            if (itemIndex !== '0' && !vals) {
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'VALIDATION.FIELD.REQUIRED',
                }),
              });
            }
            return true;
          }),
        })
      ),
      refrigerants: Yup.array().of(
        Yup.object({
          amount: Yup.number()
            .when('type', {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              is: (val: any) => val,
              then: numericRequiredWithGreaterThanMin(intl, 0),
              otherwise: numericOptionalWithGreaterThanMin(intl, 0),
            })
            .test('0indexOptional', '', function (vals: any):
              | Yup.ValidationError
              | boolean {
              const { path, createError } = this;
              const indexStart = path.lastIndexOf('[') + 1;
              const indexEnd = path.lastIndexOf(']');
              const itemIndex = path.substring(indexStart, indexEnd);
              if (itemIndex !== '0' && !vals) {
                return createError({
                  path,
                  message: intl.formatMessage({
                    id: 'VALIDATION.FIELD.REQUIRED',
                  }),
                });
              }
              return true;
            }),
          type: Yup.string().test('0indexOptional', '', function (vals: any):
            | Yup.ValidationError
            | boolean {
            const { path, createError } = this;
            const indexStart = path.lastIndexOf('[') + 1;
            const indexEnd = path.lastIndexOf(']');
            const itemIndex = path.substring(indexStart, indexEnd);
            if (itemIndex !== '0' && !vals) {
              return createError({
                path,
                message: intl.formatMessage({
                  id: 'VALIDATION.FIELD.REQUIRED',
                }),
              });
            }
            return true;
          }),
        })
      ),
    }),
    gasUse: numericOptionalWithMin(intl, 0),
    electricity: numericOptionalWithMin(intl, 0),
    additionalEnergyTypes: Yup.array().of(
      Yup.object({
        amount: numericRequiredWithGreaterThanMin(intl, 0),
        type: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
      })
    ),
    selfGeneratedRenewables: Yup.array().of(
      Yup.object({
        amount: numericRequiredWithGreaterThanMin(intl, 0),
        type: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
      })
    ),
  });

  return facilitySchema.test(
    'required',
    '',
    function energyTypeValidation(values) {
      return validateEnergyTypes(
        values as unknown as EnergyTypeValidation,
        this.createError,
        intl
      );
    }
  );
};

export const slaughterhouseFacilityDefaultValues = {
  id: '',
  name: '',
  type: undefined,
  totalLiveweight: '',
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  waterUse: '',
  wasteWaterAmount: '',
  electricity: '',
  gasUse: '',
  additionalEnergyTypes: [],
  selfGeneratedRenewables: [],
};

export default slaughterHouseFacilityFormValidation;
