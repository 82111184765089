import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useDispatch } from 'react-redux';
import { FC, useState, MutableRefObject, useEffect } from "react";
import Select from 'react-select';

import { Box, Button, Checkbox, Grid,
  InputLabel } from "@material-ui/core";
import { DsmButton, DsmIcon, DsmModal } from "@dsm-dcs/design-system-react";

import {
  CheckCircle,
  Close,
  RadioButtonUnchecked,
  SwapHoriz,
} from '@material-ui/icons';
import { DsmModalCustomEvent } from '@dsm-dcs/design-system';
import { footprintDetailsStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import DashboardModel from "../../models/Dashboard/Dashboard";
import DashboardTargetDialog from "./DashboardTargetDialog";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import SimulationBox from "./SimulationBox";
import dashboardExportExcel from "./dashboardExportExcel";
import { setDashboardOptionsDraft } from '../../../state/drafts/dashboardFiltersDraftSlice';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { DashboardType } from '../../models/Dashboard/DashboardTypes';
import Can from '../../../modules/Auth/Can';

interface CompareTargetExportControlsProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
  dashboardPageRef: MutableRefObject<HTMLElement>;
}

const CompareTargetExportControls: FC<CompareTargetExportControlsProps> = ({
  dashboard,
  renderDashboard,
  dashboardPageRef,
}) => {
  const intl = useIntl();
  const classes = footprintDetailsStyles(); 
  const [personalTargetDialogVisible, setPersonalTargetDialogVisible] = useState<boolean>(false);
  const [isCompare, setIsCompare] = useState<boolean>(false);
  const [isSimulation, setIsSimulation] = useState<boolean>(false);
  const [, forceUpdate] = useState<object>({});
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [checkedPDF, setCheckedPDF] = useState<boolean>(false);
  const [checkedExcel, setCheckedExcel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { ctaClickEvent, assetDownloadEvent } = useAdobeDataLayer();

  useEffect(() => {
    if (dashboard?.hasSimulations) {
      setIsSimulation(true);
    } else if (!dashboard?.hasSimulations && isSimulation) {
      setIsSimulation(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard?.hasSimulations]);

  const exportAsPDF = async () => {

    const heightPx = dashboardPageRef.current.clientHeight;
    const widthPx = dashboardPageRef.current.clientWidth;
    try {
    const canvas = await html2canvas(dashboardPageRef.current, {
      onclone: (documentClone) => {
      // Remove modal in case it isn't closed yet
      const mainWrapper = (documentClone.getElementById('mainDashboardWrapper') as HTMLElement)
      if (mainWrapper) {
        mainWrapper.style.boxShadow = 'unset !important';
      }
      documentClone.querySelector('.modal__backdrop')?.remove();
      documentClone.querySelector('.modal__wrapper')?.remove();
      const iconCategory = documentClone.getElementById('dashboardImpcatCategoryIcon');
      const iconCategoryArrow = documentClone.getElementById('dashboardImpcatCategoryArrow');
      if (!iconCategory || iconCategory === null || iconCategoryArrow === null) {
        return;
      }
      // Remove compartment icon and arrow (doesn't show nice on export)
      iconCategory.remove();
      iconCategory.style.backgroundColor = 'transparent'
      iconCategoryArrow.remove();
      const impactPerBtn = (documentClone.getElementById('dashboardButtonPerKgImpact')?.querySelector('.makeStyles-buttonStyles-34 .MuiButton-label') as HTMLElement)
      const absoluteImpactBtn = (documentClone.getElementById('dashboardButtonAbsoluteImpact')?.querySelector('.makeStyles-buttonStyles-34 .MuiButton-label') as HTMLElement)
      
      if (impactPerBtn && impactPerBtn !== null && absoluteImpactBtn && absoluteImpactBtn !== null) {
        impactPerBtn.innerHTML = impactPerBtn.innerText.replaceAll(' ', '&#160;');
        absoluteImpactBtn.innerHTML = absoluteImpactBtn.innerText.replaceAll(' ', '&#160;');
      }
      // Remove ' dashboard' from tabs, new line is put if there is a blank space
      const tabs = Array.from(documentClone.querySelectorAll('.MuiTab-wrapper') as unknown as HTMLCollectionOf<HTMLElement>);
      if (tabs && tabs.length > 1) {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        tabs[0].innerText = intl.formatMessage({id: "FARM"});
        tabs[1].innerText = intl.formatMessage({id: "GENERAL.FEED"});
      }

      // Remove previous, next pagination items (doesn't show well)
      const paginations = Array.from(documentClone.querySelectorAll('.MuiPagination-ul') as unknown as HTMLCollectionOf<HTMLElement>);
      paginations.forEach(pag => {
        pag.children[0].remove();
        const lastIndex = pag.children.length - 1;
        pag.children[lastIndex].remove();
      });
      },
      scale: 1.1,
      useCORS: false
    })

      const imgData = canvas.toDataURL('image/jpeg');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'px', [widthPx, heightPx]);
      
      pdf.addImage(imgData, 'JPEG', 0, 0, widthPx, heightPx, 'MEDIUM');
      const fileName = 'Sustell-Dashboard.pdf';
      pdf.save(fileName);
      assetDownloadEvent(fileName, `Downloads/${fileName}`);
    } catch (e) {
      console.log(e);
    }
  }

  const exportExcel = () => {
    if (!dashboard) {
      return;
    }
    // eslint-disable-next-line no-void
    void dashboardExportExcel(intl, dashboard, assetDownloadEvent);
  }

  const handleSimulation = () => {
    setIsSimulation(true);
    ctaClickEvent(
      window.location.href,
      'button',
      'Run Simulation',
      `${dashboard?.type} Dashboard`,
      `${dashboard?.type} Dashboard`
    );
  }

  const handleCompare = () => {
    setIsCompare(true);
    ctaClickEvent(
      window.location.href,
      'button',
      'Compare',
      `${dashboard?.type} Dashboard`,
      `${dashboard?.type} Dashboard`
    );
  }

  const handleCloseCompare = () => {
    setIsCompare(false);
    dashboard?.clearCompare();
    dispatch(setDashboardOptionsDraft(dashboard));
    renderDashboard();
  }

  const handleSelectCompare = (footprintReference: string | undefined, isComparison = false) => {
    if (!dashboard) {
      return;
    }

    if (!footprintReference && isComparison ) {
      dashboard.setComparisonFootprint(undefined);
    } else if (!footprintReference && !isComparison) {
      dashboard.clearCompare();
    }
    else {
      const footprint = dashboard.filteredSortedFootprints.find(f => f.reference === footprintReference);
      if (!footprint) {
        return;
      }

      if (isComparison) {
        if (footprintReference === dashboard.comparisonFootprints[0].reference) {
          return;
        }
        dashboard?.setComparisonFootprint(footprint);
      } else {
        dashboard?.setBaselineFootprint(footprint);
      }
    }
    dispatch(setDashboardOptionsDraft(dashboard));
    renderDashboard();
    // forceUpdate({});
  }

  const handleSwapComparison = () => {
    if (dashboard?.comparisonFootprints.length === 2) {
      const oldBaseline = dashboard.comparisonFootprints.at(0);
      const oldComparison = dashboard.comparisonFootprints.at(1);
      dashboard.clearCompare();
      renderDashboard();

      if (oldBaseline && oldComparison) {
        dashboard.setBaselineFootprint(oldComparison);
        dashboard.setComparisonFootprint(oldBaseline);
        renderDashboard();
        forceUpdate({});
      }
    dispatch(setDashboardOptionsDraft(dashboard));

    }
  }

  const handleOpenPersonalTargetDialog = () => {
    setPersonalTargetDialogVisible(true);
    ctaClickEvent(
      window.location.href,
      'button',
      'Set Target',
      `${dashboard?.type} Dashboard`,
      `${dashboard?.type} Dashboard`
    );
  }

  const handleOpenExportDialog = () => {
    setExportModalOpen(true);
    ctaClickEvent(
      window.location.href,
      'button',
      'Export',
      `${dashboard?.type} Dashboard`,
      `${dashboard?.type} Dashboard`
    );
  }

  const generate = () => {
    setExportModalOpen(false);
    if (checkedExcel) {
      exportExcel();
      ctaClickEvent(
        window.location.href,
        'button',
        'Export to Excel',
        `${dashboard?.type} Dashboard`,
        `${dashboard?.type} Dashboard`
      );
    }
    if (checkedPDF) {
      exportAsPDF().then(() => undefined).catch(() => undefined);
      ctaClickEvent(
        window.location.href,
        'button',
        'Export to PDF',
        `${dashboard?.type} Dashboard`,
        `${dashboard?.type} Dashboard`
      );
    }
  }

  const getDatasets = () => {
    if (!dashboard) {
      return undefined;
    }
    if (dashboard.type === DashboardType.Farm) {
      return dashboard?.filteredSortedFootprints.map(f => ({label: f.name, value: f.reference}));
    }

    let databaseToFilter: string | undefined;
    if (dashboard.comparisonFootprints.at(0) && !dashboard.comparisonFootprints.at(1)) {
      databaseToFilter = dashboard.comparisonFootprints.at(0)?.databaseFoundation;
    } 
    else if (!dashboard.comparisonFootprints.at(0) && dashboard.comparisonFootprints.at(1)) {
      databaseToFilter = dashboard.comparisonFootprints.at(1)?.databaseFoundation;
    }

    if (!databaseToFilter) {
      return dashboard?.filteredSortedFootprints.map(f => ({label: f.name, value: f.reference}));
    } 
    return dashboard?.filteredSortedFootprints.filter(f => f.databaseFoundation === databaseToFilter).map(f => ({label: f.name, value: f.reference}));

  }

  return (
    <>
      {personalTargetDialogVisible && dashboard && (
        <DashboardTargetDialog
          dashboard={dashboard}
          handleClose={() => setPersonalTargetDialogVisible(false)}
          formVisible={personalTargetDialogVisible}
          renderDashboard={renderDashboard}
        />
      )}

      <DsmModal
        id="dashboardExportModal"
        open={exportModalOpen}
        header={intl.formatMessage({id: "REPORT.FOOTPRINTS.EXPORT"})}
        icon="files/file-download-01"
        style={{ width: '480px !important' }}
        onDsmClosed={(event: DsmModalCustomEvent<boolean>) => {
          if (!event.detail) {
            setExportModalOpen(false)
          }
        }}
      >
        <div slot="content">{intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.DESCRIPTION"})}</div>
        <div slot="content">
          <Grid
            container
            style={{
              backgroundColor: checkedPDF ? '#F0F7FB' : '',
              padding: '8px',
              borderRadius: '8px',
              width: '352px',
              marginTop: '8px',
              marginLeft: '0px',
              cursor: 'pointer',
              border: checkedPDF ? '1px solid #0070BA' : '1px solid #F0F0F0'
            }}
            onClick={() => setCheckedPDF(!checkedPDF)}
          >
            <Grid item xs={1}>
              <div
                style={{
                  backgroundColor: '#CCE3F1',
                  padding: '8px',
                  borderRadius: '28px',
                  height: '32px',
                  width: '32px',
                  marginRight: '4px',
                }}
              >
                <DsmIcon
                  name="files/file-05"
                  style={{ height: '16px', width: '16px', color: '#0070BA' }}
                />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div style={{ marginLeft: '8px' }}>
                <strong>{intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.PDF_TITLE"})}</strong>
                <br />
                {intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.PDF_DESCRIPTION"})}
              </div>
            </Grid>
            <Grid item xs={1}>
              <div style={{ marginRight: '10px' }}>
                <Checkbox
                  icon={<RadioButtonUnchecked style={{color: '#0070BA'}}/>}
                  checkedIcon={<CheckCircle />}
                  checked={checkedPDF}
                />
              </div>
            </Grid>
          </Grid>


          {(Can('export', 'Dashboard') && (
          <Grid
            container
            style={{
              backgroundColor: checkedExcel ? '#F0F7FB' : '',
              padding: '8px',
              borderRadius: '8px',
              width: '352px',
              marginTop: '8px',
              marginLeft: '0px',
              cursor: 'pointer',
              border: checkedExcel ? '1px solid #0070BA' : '1px solid #F0F0F0'
            }}
            onClick={() => setCheckedExcel(!checkedExcel)}
          >
            <Grid item xs={1}>
              <div
                style={{
                  backgroundColor: '#CCE3F1',
                  padding: '8px',
                  borderRadius: '28px',
                  height: '32px',
                  width: '32px',
                  marginRight: '4px',
                }}
              >
                <DsmIcon
                  name="charts/bar-chart-square-02"
                  style={{ height: '16px', width: '16px', color: '#0070BA' }}
                />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div style={{ marginLeft: '8px' }}>
                <strong>{intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.EXCEL_TITLE"})}</strong>
                <br />
                {intl.formatMessage({id: "SUSTELL.DASHBOARD.EXPORT.EXCEL_DESCRIPTION"})}
              </div>
            </Grid>
            <Grid item xs={1}>
              <div style={{ marginRight: '10px' }}>
                <Checkbox
                  icon={<RadioButtonUnchecked style={{color: '#0070BA'}}/>}
                  checkedIcon={<CheckCircle />}
                  checked={checkedExcel}
                />
              </div>
            </Grid>
          </Grid>))}
        </div>
        <DsmButton variant="secondary" width-full="true" slot="actions" onClick={() => setExportModalOpen(false)}>
          {intl.formatMessage({id: "GENERAL.CANCEL"})}
        </DsmButton>
        <DsmButton 
          variant="primary"
          width-full="true"
          slot="actions"
          disabled={!checkedExcel && !checkedPDF}
          onClick={() => generate()}
        >
          {intl.formatMessage({id: "GENERAL.GENERATE"})}
        </DsmButton>
      </DsmModal>

      <Box mt="24px" mb="24px">
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
        >
          <DsmButton
            variant="secondary"
            onClick={() => handleCompare()}
            style={{ marginRight: '12px' }}
          >
            {intl.formatMessage({ id: 'SUSTELL.FOOTPRINT.BUTTONS.COMPARE' })}
          </DsmButton>



          {(Can('export', 'Dashboard') && (
          <DsmButton
            variant="secondary"
            style={{ marginRight: '12px' }}
            onClick={() => handleOpenPersonalTargetDialog()}
          >
            {intl.formatMessage({ id: 'SUSTELL.DASHBOARD.BUTTON.SET.TARGET' })}
          </DsmButton>))}

          <DsmButton variant="secondary" onClick={() => handleSimulation()}>
            {intl.formatMessage({
              id: 'SUSTELL.DASHBOARD.BUTTON.RUN_SIMULATION',
            })}
          </DsmButton>

          <DsmButton
            variant="secondary"
            style={{ marginLeft: 'auto' }}
            onClick={() => handleOpenExportDialog()}
          >
            <DsmIcon slot="before" name="general/download-01" />
            {intl.formatMessage({ id: 'SUSTELL.FOOTPRINT.BUTTONS.EXPORT' })}
          </DsmButton>
        </Grid>
        {isCompare && (
          <Grid
            container
            spacing={1}
            style={{ marginTop: 15, marginBottom: 55 }}
          >
            <Grid item xs={5}>
              <InputLabel
                htmlFor="age-customized-select"
                style={{ fontWeight: 500 }}
              >
                {intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.DATASETA',
                })}
              </InputLabel>
              <Select
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.SUGGESTIONS',
                })}
                isSearchable
                isClearable
                value={
                  dashboard?.comparisonFootprints.at(0)
                    ? {
                        value: dashboard?.comparisonFootprints.at(0)?.reference,
                        label: dashboard?.comparisonFootprints.at(0)?.name,
                      }
                    : undefined
                }
                onChange={(e) => handleSelectCompare(e?.value)}
                options={getDatasets()}
              />
            </Grid>
            <Grid item xs={1} className={classes.compareButtonsStyles}>
              <Button
                variant="outlined"
                className={classes.swapButtonStyle}
                onClick={() => handleSwapComparison()}
              >
                <SwapHoriz />
              </Button>
            </Grid>
            <Grid item xs={5}>
              <InputLabel
                htmlFor="age-customized-select"
                style={{ fontWeight: 500 }}
              >
                {intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.DATASETB',
                })}
              </InputLabel>
              <Select
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.COMPARE.SELECT.SUGGESTIONS',
                })}
                isSearchable
                isClearable
                value={
                  dashboard?.comparisonFootprints.at(1)
                    ? {
                        value: dashboard?.comparisonFootprints.at(1)?.reference,
                        label: dashboard?.comparisonFootprints.at(1)?.name,
                      }
                    : undefined
                }
                onChange={(e) => handleSelectCompare(e?.value, true)}
                options={getDatasets()}
              />
            </Grid>
            <Grid item xs={1} className={classes.compareButtonsStyles}>
              <Button
                variant="outlined"
                className={classes.closeButtonStyle}
                onClick={handleCloseCompare}
              >
                <Close />
              </Button>
            </Grid>
          </Grid>
        )}

        {isSimulation && (
          <SimulationBox
            dashboard={dashboard}
            handleClose={() => setIsSimulation(false)}
            renderDashboard={renderDashboard}
          />
        )}
      </Box>
    </>
  );
}

export default CompareTargetExportControls;