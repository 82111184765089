import { interventionValidationSchemaGeneralPart } from './interventionValidationSchemaGeneralPart';
import assembleValidationSchemaSustellHens from './layingHensInterventionValidation';
import assembleValidationSchemaSustellBroiler from './broilersInterventionValidation';
import assembleValidationSchemaSustellPigFattening from './pigFatteningInterventionValidation';
import assembleValidationSchemaSustellSalmon from './salmonInterventionValidation'; 
import assembleValidationSchemaSustellDairy from './dairyInterventionValidation';
import assembleValidationSchemaSustellPigV2 from './pigV2InterventionSchema';
import assembleValidationSchemaSustellMarineFish from './marineFishInterventionValidation'; 
import assembleValidationSchemaSustellPoultry from './poultryInterventionValidation'; 

const getValidationSchemaSustell = confObj => {
  const { animalContext } = confObj;
  // minimal validation for general part of baseline is added to all
  let validationSchema = interventionValidationSchemaGeneralPart(confObj);
  // console.log("schema animal type:",animalContext);
  switch (animalContext) {
    case 'LAYING_HENS':
      validationSchema = assembleValidationSchemaSustellHens(
        validationSchema,
        confObj
      );
      break;
    case 'DAIRY':
      validationSchema = assembleValidationSchemaSustellDairy(
        validationSchema,
        confObj
      );
      break;
    case 'PIG_FATTENING':
      validationSchema = assembleValidationSchemaSustellPigFattening(
        validationSchema,
        confObj
      );
      break;
    case 'BROILER':
      validationSchema = assembleValidationSchemaSustellBroiler(
        validationSchema, 
        confObj
      );
      break;
    case 'SALMON':
      validationSchema = assembleValidationSchemaSustellSalmon(validationSchema, confObj);
      break;
    case 'SALMON_V2':
    case 'SEA_BASS':
    case 'SEA_BREAM':
      validationSchema = assembleValidationSchemaSustellMarineFish(validationSchema, confObj);
      break;
    case 'LAYING_HENS_V2':
    case 'BROILER_V2':
    case 'TURKEY':
      validationSchema = assembleValidationSchemaSustellPoultry(validationSchema, confObj);
      break;
    case 'PIG':
      validationSchema = assembleValidationSchemaSustellPigV2(validationSchema, confObj);
      break;
    default:
      return validationSchema;
  }
  // console.log(validationSchema)
  return validationSchema;
};

export default getValidationSchemaSustell;
