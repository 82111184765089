/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/require-default-props */
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { InputAnimalOrigin } from "../../../../../../graphql/generated/blonk/pigs";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { FormType } from "../../common";
import { InternalSource} from "../../../../models/Baseline/PoultryBaseline";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';

interface PoultryInputPartInternalSourceProps {
  stageIndex: number;
  itemIndex?: number;
  propName: string;
  intlPrefix: string;
  formType: FormType;
  smallUnits?: boolean;
  item: InternalSource;
}
 
const PoultryInputPartInternalSource: FC<PoultryInputPartInternalSourceProps> = ({
  stageIndex=0,
  itemIndex,
  propName='internalSources',
  intlPrefix,
  smallUnits=false,
  formType,
  item
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const barnSmallQuantityUnits = unitLong(userUOM.unitBarnSmallQuantityUnits as string);
  const distanceUnit = unitLong(userUOM.unitTransportDistanceTerrestrial as string);
  const fieldItemPrefix = (itemIndex || itemIndex === 0) ? `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}` : `stages.${stageIndex}.stageData.input.${propName}`;

  const defaultFarm = (): string | undefined => {
    const farmDeleted: boolean = fc.getValues(`${fieldItemPrefix}.farmDeleted`);
    if (farmDeleted) return '';
    return item.farmId as string | undefined;
  }

  const defaultStage = (): string | undefined => {
    const farmDeleted: boolean = fc.getValues(`${fieldItemPrefix}.farmDeleted`);
    if (farmDeleted) return '';
    return item.originStageId as string | undefined;
  }

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={item.id}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={item.stageType}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.farmName`}
        value={fc.getValues(`${fieldItemPrefix}.farmName`)}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageName`}
        value={fc.getValues(`${fieldItemPrefix}.stageName`)}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolderInterventionPoultry}>
        <DsmGrid className={classes.dsmGridTwoColumn}>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <ReactHookDsmSelect style={{width: "100%"}}
              name={`${fieldItemPrefix}.farmId`}
              label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.FARM"})}
              adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.FARM.ADORNMENT"})}
              tooltip={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.FARM.TOOLTIP'})}
              disabled
              options={[{text: item.farmName as string, value: item.farmId as string}]}
              defaultValue={defaultFarm()}
            />
          </div>
          <div style={{marginBottom: "var(--dsm-spacing-px-4)"}}>
            <ReactHookDsmSelect style={{width: "100%"}}
              name={`${fieldItemPrefix}.originStageId`}
              label={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.STAGE`})}
              adornment={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.STAGE.ADORNMENT`})}
              tooltip={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.INPUT.STAGE.TOOLTIP'})}
              disabled
              options={[{text: item.stageName as string, value: item.originStageId as string}]}
              defaultValue={defaultStage()}
            />
          </div>
        </DsmGrid>
        <DsmGrid style={{'width': '484px'}} className={classes.dsmGridOneColumn}>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageWeight`}
            label={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT`})}
            tooltip={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.AVERAGE_WEIGHT.TOOLTIP`})}
            metricUnit={smallUnits ? barnSmallQuantityUnits : barnOutputMassUnit}
            type="number"
            baseline={item.averageWeight}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.numberAnimals`}
            label={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE`})}
            tooltip={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.ANIMALS_ENTERING_STAGE.TOOLTIP`})}
            metricUnit=''
            type="number"
            baseline={item.numberAnimals}
            disabled={formType === FormType.View}
          />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.transportDistance`}
            label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.INPUT.DISTANCE"})}
            tooltip={intl.formatMessage({id: `SUSTELL.STAGE.POULTRY.INPUT.${intlPrefix}.DISTANCE.TOOLTIP`})}
            type="number"
            metricUnit={distanceUnit}
            precision={barnOutputMassPrecision}
            baseline={item.transportDistance}
            disabled={formType === FormType.View}
          />
        </DsmGrid>
      </DsmGrid>
    </>
  );
};

export default PoultryInputPartInternalSource;