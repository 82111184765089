/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useLang, setLanguage } from "../../../../i18n";
import { FormattedMessage } from 'react-intl';
import { Button, Menu, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles } from "@material-ui/styles";

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/svg/flags/260-united-kingdom.svg"),
  },
  {
    lang: "es",
    name: "Spanish",
    flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
  },
  {
    lang: "pt",
    name: "Portuguese (Brazil)",
    flag: toAbsoluteUrl("/media/svg/flags/255-brazil.svg"),
  },
];


const StyledButton = withStyles({
  label: {
    textTransform: 'none',
    fontWeight: '400'
  },
})(Button);

export function LanguageSelectorDropdown(props) {
  const { variant = 'normal' }=props;
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);
  return (
    <React.Fragment>
    { variant==='normal' && 
      <Select
        label={<FormattedMessage id="GENERAL.LANGUAGE" />}
        variant='outlined'
        fullWidth
        value={currentLanguage.lang}
        onChange={(e) => setLanguage(e.target.value)}
        input={
          <OutlinedInput
            id="language"
            name="language"
            label={<FormattedMessage id="GENERAL.LANGUAGE" />}
          />
        }
      >
        {
          languages.map(item => 
          <MenuItem key={item.lang} value={item.lang}>{item.name}</MenuItem>
          )
        }
      </Select>
    }
    {variant === 'small' &&
      <PopupState variant="popover" popupId="popup-menu-lang">
      {(popupState) => (
        <React.Fragment>
          <StyledButton id="more-actions" aria-controls="fade-menu" aria-haspopup="true"  {...bindTrigger(popupState)} style={{marginLeft: '20px'}}>
              { currentLanguage.name } <ArrowDropDownIcon size="small"/>
          </StyledButton>
          <Menu {...bindMenu(popupState)} >
          {
            languages.map(item => 
              <MenuItem key={item.lang} value={item.lang} onClick={ ()=> setLanguage(item.lang)}>{item.name}</MenuItem>
            )
          }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
    }
    </React.Fragment>
  );
}

