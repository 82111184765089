import { FC, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';

import { useFormContext } from 'react-hook-form';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';

import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import RowTextFieldWithInfo from '../../../../../modules/Farms/Intervention/RowTextFieldWithInfo';
import {
  unitLong,
  defaultUnits,
  explicitConvertValue,
} from '../../../../utils/unit-utils';
import {
  avgLiveWeightMaxs,
  avgLiveWeightMins,
} from '../../../../../modules/Farms/validationSchemaRestrictions';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import {
  BaselineDialogProps,
  FormType,
  LayingHensForm,
  LHInput,
} from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';

const LHInputFormDialog: FC<BaselineDialogProps> = ({
  formType = 'add',
  itemIndex = 0,
  formVisible = false,
  handleCancel,
}) => {
  const intl = useIntl();
  // TODO: leave profile, UOM until converted to TS
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const precision = userProfile.getUnitBarnOutputMassPrecision();
  const currResetValue = useRef<LHInput | null>(null);

  let minAvgLiveWeightMortality = avgLiveWeightMins['Laying hens'];
  let maxAvgLiveWeightMortality = avgLiveWeightMaxs['Laying hens'];

  if (userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass) {
    if (minAvgLiveWeightMortality)
      minAvgLiveWeightMortality = Number(
        explicitConvertValue(
          minAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
    if (maxAvgLiveWeightMortality)
      maxAvgLiveWeightMortality = Number(
        explicitConvertValue(
          maxAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
  }
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
  });

  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const methods = useFormContext<LayingHensForm>();
  const getValues = (key?: string): ReturnType<typeof methods.getValues> =>
    key ? methods.getValues(key) : methods.getValues();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...getValues(fieldItemPrefix),
      } as LHInput; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as LayingHensForm;
      if (resetObject.stages[itemIndex]?.stageData?.input) {
        resetObject.stages[itemIndex].stageData.input = {
          ...currResetValue.current,
        };
        methods.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      iconCode="LAYING_HENS_INPUT"
    >
      <Grid container direction="column" spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix}.purchasedAnimalsLayingHens`}
            type="number"
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.BARN.HENS.PURCHASED_ANIMALS',
            })} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.HENS.PURCHASED_ANIMALS.TOOLTIP',
            })}
            disabled={formType === FormType.View}
            defaultValue={getValues(
              `${fieldItemPrefix}.purchasedAnimalsLayingHens`
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix}.mortalityRate`}
            type="number"
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.BARN.HENS.MORTALITY',
            })} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.HENS.MORTALITY.TOOLTIP',
            })}
            addornment="%"
            disabled={formType === FormType.View}
            defaultValue={getValues(`${fieldItemPrefix}.mortalityRate`)}
          />
        </Grid>
        <Grid item xs={12}>
          <RowTextFieldWithInfo
            name={`${fieldItemPrefix}.averageLiveWeightMortalities`}
            type="number"
            label={`${intl.formatMessage(
              { id: 'BASELINE.FORM.BARN.HENS.AVG_LIVEWEIGHT_MORTALITY' },
              {
                min: minAvgLiveWeightMortality,
                max: maxAvgLiveWeightMortality,
                unit: barnOutputMassUnit,
              }
            )} *`}
            margin="none"
            variant="outlined"
            tooltip={intl.formatMessage(
              {
                id: 'BASELINE.FORM.BARN.HENS.AVG_LIVEWEIGHT_MORTALITY.TOOLTIP',
              },
              {
                min: minAvgLiveWeightMortality,
                max: maxAvgLiveWeightMortality,
                unit: barnOutputMassUnit,
              }
            )}
            addornment={barnOutputMassUnit}
            disabled={formType === FormType.View}
            defaultValue={getValues(
              `${fieldItemPrefix}.averageLiveWeightMortalities`
            )}
          />
        </Grid>
        <Grid item xs={11}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default LHInputFormDialog;
