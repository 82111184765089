/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from "@reduxjs/toolkit";
import { BaselineMappedDataType } from "../../sustell_15/components/FarmFlow/Baseline/types";

// Use object instead of Map (suggestion from redux team, non-serializable types should not be put inside states)
export type DraftsMapType = {
  [draftKey: string]: BaselineMappedDataType
}

export const initialState: {value: DraftsMapType} = {
  value: {},
}

export interface SetBaselineDraftAction {
  readonly type: "baselineDrafts/setBaselineDraftAction";
  payload: {key: string, newBaselineValue: BaselineMappedDataType};
}

export interface DeleteDraftAction {
  readonly type: "baselineDrafts/clearBaselineDraftAction";
  payload: {key: string};
}

export const baselineDraftsSlice = createSlice({
  name: 'baselineDrafts',
  initialState,
  reducers: {
    setBaselineDraftAction: (state, action: SetBaselineDraftAction) => {
      // console.log('draft save baseline');
      const newState = action.payload.newBaselineValue;
      // eslint-disable-next-line no-param-reassign
      state.value[action.payload.key] = newState;
    },
    // On form sucesful submit we should clear the existing draft
    clearBaselineDraftAction: (state, action: DeleteDraftAction) => {
      if (!action.payload.key) {
        console.warn('cannot clear baseline draft for undefined key')
      }
      // eslint-disable-next-line no-param-reassign
      delete state.value[action.payload.key];
    },
  }
})

export const { setBaselineDraftAction, clearBaselineDraftAction } = baselineDraftsSlice.actions;

export default baselineDraftsSlice.reducer;
