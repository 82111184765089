/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  createCoreForm,
  mapEmissionsForOneStage,
  mapFeedingProgramForOneStage,
  mapResourceUse,
} from './inCommonMapperSustellV2';
import { StageType } from '../../../../../graphql/types';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { floatValueInUserUnits } from '../../Baseline/mapper/inCommonMapperSustellV2';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { getBaseTypeForMonthEndingMMS } from '../../../../sustell_15/utils/string-utils';
import mapProcessingInStage from './inProcessingMapperSustell';

const initializeEmptyStages = (interventionStages, formData) => {
  if (interventionStages && Array.isArray(interventionStages)) {
    interventionStages.forEach((oneStageIntervention) => {
      const stageType = oneStageIntervention?.type;
      const stageData = oneStageIntervention.stageData || {};
      if (stageType === StageType.Processing) {
        const oneStageFormData = {
          id: oneStageIntervention?.id,
          type: 'PROCESSING',
          facilityId: oneStageIntervention?.facilityId,
          name: oneStageIntervention?.name,
          stageData: {
            processingInput: {},
            processingOutput: {},
            processingPackaging: {}
          },
        };
        // eslint-disable-next-line no-param-reassign
        oneStageIntervention.stageData = stageData;
        oneStageFormData.stageData = mapProcessingInStage(oneStageIntervention.stageData, oneStageFormData.stageData);
        formData.stages.push(oneStageFormData);
      } else {
        const oneStageFormData = {
          id: oneStageIntervention?.id,
          name: oneStageIntervention?.name,
          stageData: {
            input: {},
            housing: { resourceUse: {} },
            output: {},
          },
        };
        if (stageType === StageType.Laying || stageType === StageType.Growing) {
          oneStageFormData.stageData.emissions = {};
          oneStageFormData.stageData.feed = {
            compoundFeeds: [],
            compoundFeedsAdditions: [],
            singleIngredients: [],
            singleIngredientsAdditions: [],
          };
          oneStageFormData.stageData.housing = {
            resourceUse: {},
            manureManagementSystems: [],
            manureManagementSystemsAdditions: [],
            beddingUse: [],
            beddingUseAdditions: [],
          };
        }
        if (stageType === StageType.Breeding) {
          oneStageFormData.stageData.emissions = {};
          oneStageFormData.stageData.feed = {};
          oneStageFormData.stageData.feed.henFeed = {
            compoundFeeds: [],
            compoundFeedsAdditions: [],
            singleIngredients: [],
            singleIngredientsAdditions: [],
          };
          oneStageFormData.stageData.feed.roosterFeed = {
            compoundFeeds: [],
            compoundFeedsAdditions: [],
            singleIngredients: [],
            singleIngredientsAdditions: [],
          };
          oneStageFormData.stageData.housing = {
            resourceUse: {},
            manureManagementSystems: [],
            manureManagementSystemsAdditions: [],
            beddingUse: [],
            beddingUseAdditions: [],
          };
        }
        formData.stages.push(oneStageFormData);
      }
    });
  }
};

const mapSource = (stageData, source, userUOM, kgUnitsDefault = true) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  const src = source;
  if (stageData.number) {
    src.numberAnimals = Number(stageData.number.value);
    src.numberAnimals_changeMetric = stageData.number.change_type;
  }
  if (stageData.averageWeight) {
    src.averageWeight = floatValueInUserUnits(
      stageData.averageWeight.value,
      defaultUnit,
      defaultUserUnit
    );
    src.averageWeight_changeMetric = stageData.averageWeight.change_type;
  }
  if (stageData.transportDistance) {
    src.transportDistance = floatValueInUserUnits(
      stageData.transportDistance.value,
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    );
    src.transportDistance_changeMetric =
      stageData.transportDistance.change_type;
  }
};

const mapInputForOneStageBreeding = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const input = formData;
  input.henInternalSource = {};
  input.henExternalSource = {};
  input.roosterInternalSource = {};
  input.roosterExternalSource = {};

  stageData.henInput.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      input.henInternalSource = {};
      mapSource(stage, input.henInternalSource, userUOM, false);
    } else {
      input.henExternalSource = {};
      mapSource(stage, input.henExternalSource, userUOM, false);
    }
  });
  stageData.roosterInput.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      input.roosterInternalSource = {};
      mapSource(stage, input.roosterInternalSource, userUOM, false);
    } else {
      input.roosterExternalSource = {};
      mapSource(stage, input.roosterExternalSource, userUOM, false);
    }
  });
};

const mapInputForOneStageGrowing = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const input = formData;
  input.internalSources = [];
  input.externalSources = [];

  stageData.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      const intSource = {};
      mapSource(stage, intSource, userUOM);
      input.internalSources.push(intSource);
    } else {
      const extSource = {};
      mapSource(stage, extSource, userUOM);
      input.externalSources.push(extSource);
    }
  });
};

const mapInputForOneStageLaying = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const input = formData;
  input.internalSource = {};
  input.externalSource = {};

  stageData.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      input.internalSource = {};
      mapSource(stage, input.internalSource, userUOM, false);
    } else {
      input.externalSource = {};
      mapSource(stage, input.externalSource, userUOM, false);
    }
  });
};

const mapInputForOneStageHatching = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const input = formData;
  input.internalSource = {};
  input.externalSource = {};

  stageData.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      input.internalSource = {};
      mapSource(stage, input.internalSource, userUOM);
    } else {
      input.externalSource = {};
      mapSource(stage, input.externalSource, userUOM);
    }
  });
};

const mapInputForOneStage = (stageType, stageData, formData) => {
  if (stageType === StageType.Breeding)
    mapInputForOneStageBreeding(stageData, formData.input);
  else if (stageType === StageType.Growing)
    mapInputForOneStageGrowing(stageData.input, formData.input);
  else if (stageType === StageType.Laying)
    mapInputForOneStageLaying(stageData.input, formData.input);
  else mapInputForOneStageHatching(stageData.input, formData.input);
};

const mapStockChange = (stageType, stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const input = formData;

  if (stageData.averageProductionRoundLength) {
    input.durationOfProductionCycle = Number(
      stageData.averageProductionRoundLength.value
    );
    input.durationOfProductionCycle_changeMetric =
      stageData.averageProductionRoundLength.change_type;
  }
  if (stageData.totalEmptyDays) {
    input.emptyPeriod = Number(stageData.totalEmptyDays.value);
    input.emptyPeriod_changeMetric = stageData.totalEmptyDays.change_type;
  }
  if (stageType === StageType.Hatching) {
    if (stageData.initialAnimalStock) {
      input.eggsPresentAtStart = Number(stageData.initialAnimalStock.value);
      input.eggsPresentAtStart_changeMetric =
        stageData.initialAnimalStock.change_type;
    }
    if (stageData.finalAnimalStock) {
      input.eggsPresentAtEnd = Number(stageData.finalAnimalStock.value);
      input.eggsPresentAtEnd_changeMetric =
        stageData.finalAnimalStock.change_type;
    }
  }
  if (stageType === StageType.Growing) {
    if (stageData.initialAnimalStock) {
      input.animalsPresentAtStart = Number(stageData.initialAnimalStock.value);
      input.animalsPresentAtStart_changeMetric =
        stageData.initialAnimalStock.change_type;
    }
    if (stageData.finalAnimalStock) {
      input.animalsPresentAtEnd = Number(stageData.finalAnimalStock.value);
      input.animalsPresentAtEnd_changeMetric =
        stageData.finalAnimalStock.change_type;
    }
  }
  if (stageData.initialAnimalAverageWeight) {
    input.averageWeightAtStart = floatValueInUserUnits(
      stageData.initialAnimalAverageWeight.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    input.averageWeightAtStart_changeMetric =
      stageData.initialAnimalAverageWeight.change_type;
  }
  if (stageData.finalAnimalAverageWeight) {
    input.averageWeightAtEnd = floatValueInUserUnits(
      stageData.finalAnimalAverageWeight.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    input.averageWeightAtEnd_changeMetric =
      stageData.finalAnimalAverageWeight.change_type;
  }
};

const mapHousingTypeForOneStage = (stageData, fromData) => {
  const formOutputData = fromData;
  formOutputData.housingType = stageData.housingType;
  if (stageData.housingType === 'FREE_RANGE')
    formOutputData.timeInFreeRange = stageData.freeRangeTimePercent?.value;
}

const mapManureManagemenetSystemsForOneStage = (stageData, formData) => {
  const formOutputData = formData;
  formOutputData.manureSystems = [];
  if ('manureManagementSystems' in stageData) {
    stageData.manureManagementSystems.forEach((item) => {
      const baseName = item.mmsType ? getBaseTypeForMonthEndingMMS(item.mmsType) : undefined;
      const newItem = {};
      newItem.id = item.id;
      newItem.share = item.share?.value;
      newItem.share_changeMetric = item.share?.change_type;
      newItem.mmsType = baseName;
      newItem.mmsHoldingDuration =
        baseName === item.mmsType ? '' : item.mmsType;
      formOutputData.manureSystems.push(newItem);
    });
  }
};

const mapManureManagemenetSystemsAdditionsForOneStage = (stageData, formData) => {
  const formOutputData = formData;
  formOutputData.manureSystemsAdditions = [];
  if ('manureManagementSystemsAdditions' in stageData) {
    stageData.manureManagementSystemsAdditions.forEach((item) => {
      const baseName = getBaseTypeForMonthEndingMMS(item.mmsType);
      const newItem = {};
      newItem.id = item.id;
      newItem.share = item.share;
      newItem.mmsType = baseName;
      newItem.mmsHoldingDuration =
        baseName === item.mmsType ? '' : item.mmsType;
      formOutputData.manureSystemsAdditions.push(newItem);
    });
  }
};

const mapBeddingForOneStage = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formData;
  formHousingData.beddingSystems = [];
  if (stageData) {
    Object.keys(stageData).forEach((key) => {
      let value = '';
      if (stageData[key])
        value = floatValueInUserUnits(
          Number(stageData[key].value),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf('Use');
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);
      const beddingType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();

      const item = {
        beddingType,
        beddingAmount: value,
        beddingAmount_changeMetric: stageData[key].change_type,
      };
      formHousingData.beddingSystems.push(item);
    });
  }
}

const mapBeddingForOneStageAdditions = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formData;

  formHousingData.beddingSystemsAdditions = [];
  if (stageData) {
    Object.keys(stageData).forEach((key) => {
      let value = '';
      if (stageData[key])
        value = floatValueInUserUnits(
          Number(stageData[key]),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf('Use');
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);
      const beddingType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();

      const item = {
        beddingType,
        beddingAmount: value,
      };
      formHousingData.beddingSystemsAdditions.push(item);
    });
  }
}

const mapFeedForOneStage = (stageType, stageData, formData) => {
  if (stageType === StageType.Breeding && stageData?.henFeedingProgram) {
    mapFeedingProgramForOneStage(
      stageData?.henFeedingProgram,
      formData?.feed?.henFeed
    );
  }
  if (stageType === StageType.Breeding && stageData?.roosterFeedingProgram) {
    mapFeedingProgramForOneStage(
      stageData?.roosterFeedingProgram,
      formData?.feed?.roosterFeed
    );
  }
  if (stageType !== StageType.Breeding && stageData?.feedingProgram) {
    mapFeedingProgramForOneStage(stageData?.feedingProgram, formData?.feed);
  }
};

const mapOutputForOneStageBreeding = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const output = formData;

  if (stageData.totalToStage) {
    output.totalEggsToStage = Number(stageData.totalToStage.value);
    output.totalEggsToStage_changeMetric = stageData.totalToStage.change_type;
  }
  if (stageData.averageWeight) {
    output.averageWeight = floatValueInUserUnits(
      stageData.averageWeight.value,
      defaultMetric.unitBarnSmallQuantityUnits,
      userUOM.unitBarnSmallQuantityUnits
    );
    output.averageWeight_changeMetric = stageData.averageWeight.change_type;
  }
  if (stageData.totalSold) {
    output.totalEggsSold = Number(stageData.totalSold.value);
    output.totalEggsSold_changeMetric = stageData.totalSold.change_type;
  }
  if (stageData.price) {
    output.priceEggs = Number(stageData.price.value);
    output.priceEggs_changeMetric = stageData.price.change_type;
  }
  if (stageData.spentHenSold) {
    output.totalHensSold = Number(stageData.spentHenSold.value);
    output.totalHensSold_changeMetric = stageData.spentHenSold.change_type;
  }
  if (stageData.spentHenAverageWeight) {
    output.averageHensWeight = floatValueInUserUnits(
      stageData.spentHenAverageWeight.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.averageHensWeight_changeMetric =
      stageData.spentHenAverageWeight.change_type;
  }
  if (stageData.spentHenMortalityPercent) {
    output.mortalityHensAtPercent = Number(
      stageData.spentHenMortalityPercent.value
    );
    output.mortalityHensAtPercent_changeMetric =
      stageData.spentHenMortalityPercent.change_type;
  }
  if (stageData.spentHenWeightAtMortality) {
    output.weightHensAtMortality = floatValueInUserUnits(
      stageData.spentHenWeightAtMortality.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.weightHensAtMortality_changeMetric =
      stageData.spentHenWeightAtMortality.change_type;
  }
  if (stageData.spentRoosterSold) {
    output.totalRoostersSold = Number(stageData.spentRoosterSold.value);
    output.totalRoostersSold_changeMetric =
      stageData.spentRoosterSold.change_type;
  }
  if (stageData.spentRoosterAverageWeight) {
    output.averageRoostersWeight = floatValueInUserUnits(
      stageData.spentRoosterAverageWeight.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.averageRoostersWeight_changeMetric =
      stageData.spentRoosterAverageWeight.change_type;
  }
  if (stageData.spentRoosterMortalityPercent) {
    output.mortalityRoostersAtPercent = Number(
      stageData.spentRoosterMortalityPercent.value
    );
    output.mortalityRoostersAtPercent_changeMetric =
      stageData.spentRoosterMortalityPercent.change_type;
  }
  if (stageData.spentRoosterWeightAtMortality) {
    output.weightRoostersAtMortality = floatValueInUserUnits(
      stageData.spentRoosterWeightAtMortality.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.weightRoostersAtMortality_changeMetric =
      stageData.spentRoosterWeightAtMortality.change_type;
  }
  if (stageData.spentHenPrice && stageData.spentRoosterPrice) {
    output.price = Number(stageData.spentHenPrice.value);
    output.price_changeMetric = stageData.spentHenPrice.change_type;
  }
};

const mapOutputForOneStageGrowing = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const output = formData;

  if (stageData.totalToStage) {
    output.totalToStage = Number(stageData.totalToStage.value);
    output.totalToStage_changeMetric = stageData.totalToStage.change_type;
  }
  if (stageData.averageWeight) {
    output.averageWeight = floatValueInUserUnits(
      stageData.averageWeight.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.averageWeight_changeMetric = stageData.averageWeight.change_type;
  }
  if (stageData.totalSold) {
    output.totalSold = Number(stageData.totalSold.value);
    output.totalSold_changeMetric = stageData.totalSold.change_type;
  }
  if (stageData.mortalityPercent) {
    output.mortalityAtPercent = Number(stageData.mortalityPercent.value);
    output.mortalityAtPercent_changeMetric =
      stageData.mortalityPercent.change_type;
  }
  if (stageData.weightAtMortality) {
    output.weightAtMortality = floatValueInUserUnits(
      stageData.weightAtMortality.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.weightAtMortality_changeMetric =
      stageData.weightAtMortality.change_type;
  }
};

const mapOutputForOneStageLaying = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const output = formData;

  if (stageData.totalSold) {
    output.totalSold = Number(stageData.totalSold.value);
    output.totalSold_changeMetric = stageData.totalSold.change_type;
  }
  if (stageData.averageWeight) {
    output.averageWeight = floatValueInUserUnits(
      stageData.averageWeight.value,
      defaultMetric.unitBarnSmallQuantityUnits,
      userUOM.unitBarnSmallQuantityUnits
    );
    output.averageWeight_changeMetric = stageData.averageWeight.change_type;
  }
  if (stageData.price) {
    output.priceEggs = Number(stageData.price.value);
    output.priceEggs_changeMetric = stageData.price.change_type;
  }
  if (stageData.spentHenSold) {
    output.totalHensToStage = Number(stageData.spentHenSold.value);
    output.totalHensToStage_changeMetric = stageData.spentHenSold.change_type;
  }
  if (stageData.spentHenAverageWeight) {
    output.averageHensWeight = floatValueInUserUnits(
      stageData.spentHenAverageWeight.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.averageHensWeight_changeMetric =
      stageData.spentHenAverageWeight.change_type;
  }
  if (stageData.spentHenPrice) {
    output.priceHens = Number(stageData.spentHenPrice.value);
    output.priceHens_changeMetric = stageData.spentHenPrice.change_type;
  }
  if (stageData.spentHenMortalityPercent) {
    output.mortalityAtPercent = Number(
      stageData.spentHenMortalityPercent.value
    );
    output.mortalityAtPercent_changeMetric =
      stageData.spentHenMortalityPercent.change_type;
  }
  if (stageData.spentHenWeightAtMortality) {
    output.weightAtMortality = floatValueInUserUnits(
      stageData.spentHenWeightAtMortality.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.weightAtMortality_changeMetric =
      stageData.spentHenWeightAtMortality.change_type;
  }
};

const mapOutputForOneStageHatching = (stageData, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const output = formData;

  if (stageData.totalToStage) {
    output.totalToStage = Number(stageData.totalToStage.value);
    output.totalToStage_changeMetric = stageData.totalToStage.change_type;
  }
  if (stageData.totalSold) {
    output.totalSold = Number(stageData.totalSold.value);
    output.totalSold_changeMetric = stageData.totalSold.change_type;
  }
  if (stageData.averageWeight) {
    output.averageWeight = floatValueInUserUnits(
      stageData.averageWeight.value,
      defaultMetric.unitBarnSmallQuantityUnits,
      userUOM.unitBarnSmallQuantityUnits
    );
    output.averageWeight_changeMetric = stageData.averageWeight.change_type;
  }
  if (stageData.price) {
    output.price = Number(stageData.price.value);
    output.price_changeMetric = stageData.price.change_type;
  }
  if (stageData.totalWeightLosses) {
    output.lossRate = floatValueInUserUnits(
      stageData.totalWeightLosses.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.lossRate_changeMetric = stageData.totalWeightLosses.change_type;
  }
  if (stageData.lossesPrice) {
    output.priceOfLossesSold = Number(stageData.lossesPrice.value);
    output.priceOfLossesSold_changeMetric = stageData.lossesPrice.change_type;
  }
  if (stageData.totalWeightEggShellSoldOrWaste) {
    output.totalEggShellsSold = floatValueInUserUnits(
      stageData.totalWeightEggShellSoldOrWaste.value,
      defaultMetric.unitBarnOutputMass,
      userUOM.unitBarnOutputMass
    );
    output.totalEggShellsSold_changeMetric =
      stageData.totalWeightEggShellSoldOrWaste.change_type;
  }
};

const mapOutputForOneStage = (stageType, stageData, formData) => {
  if (stageType === StageType.Breeding)
    mapOutputForOneStageBreeding(stageData.output, formData.output);
  else if (stageType === StageType.Growing)
    mapOutputForOneStageGrowing(stageData.output, formData.output);
  else if (stageType === StageType.Laying)
    mapOutputForOneStageLaying(stageData.output, formData.output);
  else mapOutputForOneStageHatching(stageData.output, formData.output);
};

const mapPoultryInterventionDataInSustell = (inDataObj) => {
  const inData = JSON.parse(JSON.stringify(inDataObj));
  inData.stages.forEach((stage) => {
    if (stage.stageData && typeof stage.stageData === 'string') {
      // eslint-disable-next-line no-param-reassign
      stage.stageData = JSON.parse(stage.stageData) || {};
    }
  });
  const formData = createCoreForm(inData);
  initializeEmptyStages(inData.stages, formData);

  // Map intervention resources
  if (inData.resourceUse)
    mapResourceUse(inData.resourceUse, formData.resourceUse);

  inData.stages.forEach((stage, index) => {
    if(stage.type !== StageType.Processing) {
      const stageFormData = formData.stages[index]?.stageData;
      const stageData = stage.stageData || {};
      // Map stock change
      if (stageData.stockChange)
        mapStockChange(stage.type, stageData.stockChange, stageFormData.input);
      // Map input
      mapInputForOneStage(stage.type, stageData, stageFormData);
      // Map resources
      if (stageData.resourceUse)
        mapResourceUse(stageData.resourceUse, stageFormData.housing.resourceUse);
      // Map housing type
      if (stageData.housingType)
        mapHousingTypeForOneStage(stageData, stageFormData.housing);
      // Map manure managements systems
      if (stageData.manureManagementSystems)
        mapManureManagemenetSystemsForOneStage(stageData, stageFormData.housing);
      if (stageData.manureManagementSystemsAdditions)
        mapManureManagemenetSystemsAdditionsForOneStage(stageData, stageFormData.housing);
      // Map bedding
      if (stageData.beddingUse)
        mapBeddingForOneStage(stageData.beddingUse, stageFormData.housing);
      if (stageData.beddingUseAdditions)
        mapBeddingForOneStageAdditions(
          stageData.beddingUseAdditions,
          stageFormData.housing
        );
      // Map feeds
      mapFeedForOneStage(stage.type, stageData, stageFormData);
      // Map outputs
      if (stageData.output)
        mapOutputForOneStage(stage.type, stageData, stageFormData);
      // Map emissions
      mapEmissionsForOneStage(
        stageData?.emissionMitigations,
        stageFormData.emissions
      );
    }
  });
  return formData;
};

export default mapPoultryInterventionDataInSustell;
