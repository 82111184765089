import {
  type FC,
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  MouseEvent,
} from 'react';
import { useHistory } from 'react-router';
import { FormatDateOptions } from 'react-intl';
import { Table, TableBody, TableContainer, TableRow } from '@material-ui/core';
import { QueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { v4 as uuidv4 } from 'uuid';
import { FormType } from './common';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { dateDisplayOptionsShort } from '../../utils/datetime-utils';
import { isMarineFish, isPig, isPoultry } from '../../helpers/animals';
import { AnimalType } from '../../../../graphql/types';
import { footprintCategoryTableStyles } from '../../../../_metronic/layout';
import {
  NotificationDialog,
  NotificationDialogType,
} from '../helpers/NotificationDialog';
import DeleteConfirmationDialog from '../helpers/DeleteConfirmationDialog';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import TableHeader from '../Table/TableRowHeader';
import TableLoadingSkeleton from '../Table/TableLoadingSkeleton';
import TableFooterRow from '../Table/TableFooterRow';
import { useDeleteFarm, useDuplicateFarm } from '../../../../api';
import TableRowItem from '../Table/TableRowItem';
import TableSearchHeader from '../Table/TableSearchHeader';
import { FarmTableItemModel } from '../../pages/FarmFlow/FarmFlow.types';
import filterByBenchmarkSortByName from '../Table/utils';

interface MyFarmsV2Props {
  farmItems: FarmTableItemModel[];
  loading: boolean;
  customerId: string;
  customerName: string;
  queryClient: QueryClient;
}

interface Notification {
  type: NotificationDialogType;
  message: string;
}

const MyFarmsV2: FC<MyFarmsV2Props> = ({
  farmItems,
  customerId,
  customerName,
  loading,
  queryClient,
}) => {
  const intl = useIntl();
  const classes = footprintCategoryTableStyles();
  const history = useHistory();
  const { ctaClickEvent, formStartEvent } = useAdobeDataLayer();
  const [searchValue, setSearchValue] = useState<string>('');
  const [notification, setNotification] = useState<null | Notification>(null);
  const [deleteFarmId, setDeleteFarmId] = useState<string>('');
  const [data, setData] = useState(farmItems);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const farmDeleteModelOpen = deleteFarmId !== '';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { mutateAsync: deleteFarm, isLoading: isDeleteLoading } =
    useDeleteFarm(queryClient);

  const duplicationErrorMessage = intl.formatMessage({
    id: 'SUSTELL.MY_FARMS.DUPLICATE.ERROR',
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { mutateAsync: duplicateFarm, isLoading: isDuplicateLoading } =
    useDuplicateFarm(queryClient, duplicationErrorMessage, setNotification);

  const columns = useMemo<ColumnDef<FarmTableItemModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: intl.formatMessage({ id: 'FARM.NAME' }),
      },
      {
        accessorFn: (row) => row.id,
        id: 'id',
        enableSorting: false,
      },
      {
        accessorKey: 'updatedAt',
        cell: (info) =>
          intl.formatDate(
            info.getValue() as string,
            dateDisplayOptionsShort as FormatDateOptions
          ),
        header: intl.formatMessage({ id: 'GENERAL.LAST_MODIFIED' }),
      },
      {
        accessorKey: 'country',
        header: intl.formatMessage({ id: 'GENERAL.COUNTRY' }),
      },
      {
        accessorKey: 'city',
        header: intl.formatMessage({ id: 'GENERAL.LOCATION' }),
      },
      {
        accessorKey: 'baselinesCount',
        header: intl.formatMessage({ id: 'GENERAL.BASELINES' }),
        maxSize: 50,
      },
      {
        accessorKey: 'interventionsCount',
        header: intl.formatMessage({ id: 'INTERVENTIONS.CARD.TITLE' }),
      },
      {
        accessorKey: 'animalSystems',
        header: intl.formatMessage({ id: 'REPORT.MENU.ANIMAL_SYSTEM' }),
        cell: ({ row }) =>
          row.original.animalSystems
            .map((animalSystem) =>
              row.original.version === 1
                ? `${animalSystem} 1.0`
                : `${animalSystem} 2.0`
            )
            .join(', '),
      },
      {
        accessorKey: 'actions',
        header: '',
      },
    ],
    [intl]
  );

  const table = useReactTable({
    columns,
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
  });

  useEffect(() => {
    if (searchValue) {
      table.setPageIndex(0);
    }
    if (farmItems) {
      setData(
        filterByBenchmarkSortByName(farmItems, 'name').filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase().trim())
        )
      );
    }
  }, [farmItems, table, searchValue]);

  const navigateToFarm = async (farmId: string, isEdit = false) => {
    if (!isEdit) {
      await ctaClickEvent(
        window.location.href,
        'link',
        'View Farm',
        'My Farms',
        'My Farms'
      );
    }
    const path = `/customers/${customerId}/farm/${farmId}`;
    const animalType =
      farmItems
        ?.find((item) =>
          item.animalTypes.some(
            (type) =>
              type && (isPig(type) || isMarineFish(type) || isPoultry(type))
          )
        )
        ?.animalTypes.at(0) || AnimalType.Pig;

    const historyObj = {
      customerName,
      animalType,
      formType: FormType.View,
    };

    if (isEdit) {
      historyObj.formType = FormType.Edit;
    }

    history.push(path, historyObj);
  };

  const openFarm = async (event: MouseEvent, item: FarmTableItemModel) => {
    event.preventDefault();
    await navigateToFarm(item.id, false);
  };

  const editFarm = async (item: FarmTableItemModel) => {
    await ctaClickEvent(
      window.location.href,
      'link',
      'Edit Farm',
      'My Farms',
      'My Farms'
    );
    await formStartEvent('Edit Farm', 'edit');
    await navigateToFarm(item.id, true);
  };

  const handleDeleteClick = () => {
    ctaClickEvent(
      window.location.href,
      'link',
      'Delete Farm',
      'My Farms',
      'My Farms'
      // eslint-disable-next-line no-console
    ).catch((e) => console.error(e));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    deleteFarm({ customerId, farmId: deleteFarmId }).finally(() =>
      setDeleteFarmId('')
    );
  };

  const handleDuplicateFarm = async (item: FarmTableItemModel) => {
    await ctaClickEvent(
      window.location.href,
      'link',
      'Duplicate Farm',
      'My Farms',
      'My Farms'
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    await duplicateFarm({ customerId, farmId: item.id });
  };

  const onTablePageChange = (
    _: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) => {
    table.setPageIndex(page);
  };

  const onTableRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal = event.target.value;
    table.setPageSize(Number(newVal));
  };

  return (
    <>
      <NotificationDialog
        open={!!notification}
        message={notification !== null ? notification.message : null}
        type={
          notification !== null
            ? notification.type
            : NotificationDialogType.ERROR
        }
        closeNotificationDialog={() => setNotification(null)}
      />
      <DeleteConfirmationDialog
        open={farmDeleteModelOpen}
        confirmHandler={handleDeleteClick}
        cancelHandler={() => setDeleteFarmId('')}
        itemType={intl.formatMessage({
          id: 'SUSTELL.DELETE.FARM',
        })}
        customText={intl.formatMessage({
          id: 'DELETE.WARNING.CUSTOMERS.FARM.QUESTION',
        })}
      />
      <TableSearchHeader
        title={intl.formatMessage({ id: 'SUSTELL.ALL_FARMS' })}
        description={intl.formatMessage({
          id: 'SUSTELL.ALL_FARMS_DESCRIPTION',
        })}
        showCompareFootprints={false}
        isCompareFootprintsDisabled={false}
        compareButtonText=""
        searchPlaceholder={intl.formatMessage({
          id: 'GENERAL.SEARCH',
        })}
        showSearch
        search={(value) => setSearchValue(value)}
      />
      <TableContainer>
        <Table aria-label="customized farm table" className={classes.table}>
          <TableHeader<FarmTableItemModel> items={table.getHeaderGroups()} />
          <TableBody>
            {loading ? (
              <TableLoadingSkeleton
                description={intl.formatMessage({
                  id: 'GENERAL.LOADING_FARMS',
                })}
              />
            ) : (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={uuidv4()}
                  style={{
                    background: row.original.benchmark ? '#F0F7FB' : '',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableRowItem<FarmTableItemModel>
                      key={uuidv4()}
                      cell={cell}
                      row={row}
                      itemId={row.original.id}
                      rowSelection={{}}
                      isBenchmark={row.original.benchmark || false}
                      canSubject="Farm"
                      editDisabled={row.original.benchmark || false}
                      handleView={openFarm}
                      handleEdit={editFarm}
                      isDuplicateLoading={isDuplicateLoading}
                      handleDuplicate={handleDuplicateFarm}
                      isDeleteLoading={isDeleteLoading}
                      handleDelete={setDeleteFarmId}
                    />
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooterRow
            customElements={table.getRowCount()}
            page={table.getState().pagination.pageIndex}
            rowsPerPage={table.getState().pagination.pageSize}
            onTablePageChange={onTablePageChange}
            onTableRowsPerPageChange={onTableRowsPerPageChange}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default MyFarmsV2;
