import React, { FC } from "react";
import  ProfileHeader from "../profileHeader"
import useStyles from "../profileStyles/myProfileStyles";
import SectionHeaderWithTabs from "../sectionHeadWithTabs";
import { useSelector } from "react-redux";


const ProfileCardV2: React.FC = () => {
    const classes = useStyles();  
    const customerId = useSelector(
      (state: any) => state.selectedCustomer?.value
    )?.id;

  return (
    <div className={classes.profileContainer}>
      <ProfileHeader/>
      <SectionHeaderWithTabs customerId={customerId}/>
    </div>
  );
}

export default ProfileCardV2;