import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as math from 'mathjs';
import _ from 'lodash';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import RowControlledTextFieldWithMetrics from './RowControlledTextFieldWithMetrics';
import { unitLong, defaultUnits, explicitConvertValue } from '../../../sustell_15/utils/unit-utils';
import ReactHookFormCheckbox from '../../Helpers/ReactHookFormCheckbox';
import ReactHookPreviewField from '../../Helpers/ReactHookPreviewField';
import calculate3NOPForBovaer, { InputData3NOP } from '../../../sustell_15/utils/calculation-utils';
import { getObjectPropByPath } from '../../../sustell_15/utils/obj-utils';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  DairyStageData,
  LatestCalc3NOP,
} from '../../../sustell_15/models/Baseline';
import { DairyIntervention } from '../../../sustell_15/models/Intervention';
import { FormType } from '../../../sustell_15/components/FarmFlow/common';
import {
  BovaerAnimalType,
  BovaerCalculations,
  BovaerCalculationsInput,
  Maybe,
} from '../../../../graphql/types';

interface SustellInterventionBovaerSectionProps {
  stageIndex: number;
  on3NOPSelectionChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  is3NOPChecked: boolean;
  boveareValidationFullFieldNames: {
    dryMatterIntakeKg: string;
    ndfPercentageOfDmi: string;
    fatPercentageInDmi: string;
    dosage: string;
    numberOfAnimals: string;
  };
  baseline: DairyStageData;
  intervention: DairyIntervention | null;
  updateTotalMethaneFE: () => void;
  averageAnimalsPresentRef: MutableRefObject<number | string | null>;
  latestCalc: Maybe<LatestCalc3NOP>;
  setLatestCalc: (latestBovaerCalc?: LatestCalc3NOP | null) => void;
  formType: FormType;
  country: string;
}

interface BovaerCalculationsInputData extends BovaerCalculationsInput {
  averageAnimalsPresent?: number;
}

const SustellInterventionBovaerSection = ({
  stageIndex,
  on3NOPSelectionChanged,
  is3NOPChecked,
  boveareValidationFullFieldNames,
  baseline,
  intervention,
  updateTotalMethaneFE,
  averageAnimalsPresentRef,
  latestCalc,
  setLatestCalc,
  formType,
  country,
}: SustellInterventionBovaerSectionProps) => {

  const validationObject: any = {
    dryMatterIntakeKg: { min: 7, max: 40, unit: 'kg' },
    ndfPercentageOfDmi: { min: 20, max: 50, unit: '%' },
    fatPercentageInDmi: { min: 0, max: 6, unit: '%' },
    dosage: { min: 60, max: 90, unit: 'g' },
    numberOfAnimals: { min: 1, max: 3000, unit: 'num' },
  }
    
  const showRanges = (field: string) => {
    let min: number = validationObject[field]['min'];
    let max: number = validationObject[field]['max'];
    if (field === 'dosage' && validationObject['dosage']['unit'] !== userUOM?.unitBarnSmallQuantityUnits) {
      min = Number(explicitConvertValue(min, validationObject['dosage']['unit'], userUOM?.unitBarnSmallQuantityUnits)?.toFixed(4));
      max = Number(explicitConvertValue(max, validationObject['dosage']['unit'], userUOM?.unitBarnSmallQuantityUnits)?.toFixed(4));
    }
    if (field === 'dryMatterIntakeKg' && validationObject['dryMatterIntakeKg']['unit'] !== userUOM?.unitBarnOutputMass) {
      min = Number(explicitConvertValue(min, validationObject['dryMatterIntakeKg']['unit'], userUOM?.unitBarnOutputMass)?.toFixed(4));
      max = Number(explicitConvertValue(max, validationObject['dryMatterIntakeKg']['unit'], userUOM?.unitBarnOutputMass)?.toFixed(4));
    }
    if (field === 'dosage') return `${min}, <${max+1} - scaled value used (formula: (dose in g) * 1000 / (DMI in kg))`
    return `${min}, <${max+1}`
  }

  const fc = useFormContext();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.emissions`;
  const intl = useIntl();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : 'kg';

  const toNum = (val: number | string) => {
    if (typeof val !== 'number') {
      // eslint-disable-next-line no-param-reassign
      val = parseFloat(val);
    }
    return val;
  };

  // This is state for loading spinner on place where 3NOP result is positioned
  const [loading, setLoading] = useState<boolean>(false);

  // keeps track of the latest bovaer params values used for calculation
  const bovaerOriginalCalcResult =
    baseline?.emissions?.supplement3NOP?.calculatedResult;

  // used for the (general) calculation errors returned by the API
  const [calcError, setCalcError] = useState('');

  // Get form field names for new values
  const getFormFieldsNamesNewValues = () => {
    const newValueFieldNames = { ...boveareValidationFullFieldNames };
    Object.keys(newValueFieldNames).forEach(
      // eslint-disable-next-line no-return-assign
      (key) =>
        (newValueFieldNames[key as keyof typeof newValueFieldNames] = `${
          newValueFieldNames[key as keyof typeof newValueFieldNames]
        }_newValue`)
    );
    return newValueFieldNames;
  };

  const formFieldsNamesNewValues = getFormFieldsNamesNewValues();

  // Get newValues from form
  const getNewValues = () => {
    const newValueFieldNames = formFieldsNamesNewValues;
    const bovaerFields = fc.getValues(Object.values(newValueFieldNames));
    const newVals: { [key: string]: number } | null = getObjectPropByPath(
      bovaerFields,
      `${fieldItemPrefix}.supplement3NOP`
    ) as { [key: string]: number };

    if (newVals != null)
      Object.keys(newVals).forEach(
        // eslint-disable-next-line no-return-assign
        (key) =>
          (newVals[key as keyof typeof newVals] = toNum(
            newVals[key as keyof typeof newVals] as string | number
          ))
      );

    return newVals;
  };

  // This is used to make API input object from newValues/baseline 3NOP properties
  // returns object structured as API input params for the ease of comparisson
  const getApiInputParams = () => {
    // Get calculated - new values from form
    const newVals = getNewValues();

    // Also get baseline values, if no  new value exist, use baseline
    const oldVals = baseline?.emissions?.supplement3NOP || {};
    // eslint-disable-next-line no-return-assign
    Object.keys(oldVals).forEach((key) => {
      if (oldVals[key as keyof typeof oldVals] != null)
        oldVals[key as keyof typeof oldVals] = toNum(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          oldVals[key as keyof typeof oldVals]!
        );
    });

    const input: BovaerCalculationsInputData = {
      animalType: BovaerAnimalType.Dairy,
      country,
      ndfPercentageOfDmi: toNum(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newVals?.ndfAmountPercentage_newValue || oldVals.ndfAmountPercentage!
      ),
      numberOfAnimals: toNum(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newVals?.numberOfCowsCovered_newValue || oldVals.numberOfCowsCovered!
      ),
      dryMatterIntakeKg:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        toNum(newVals?.dmiAmountPerKg_newValue || oldVals.dmiAmountPerKg!),
    };

    const dosage =
      // eslint-disable-next-line no-underscore-dangle
      newVals?._3nopDosePerCowPerDay_newValue || oldVals?._3nopDosePerCowPerDay;
    const fatPercentageInDmi =
      newVals?.fatAmountPercentage_newValue || oldVals?.fatAmountPercentage;

    // check optional fileds
    if (dosage || dosage === 0) input.dosage = toNum(dosage);
    if (fatPercentageInDmi || fatPercentageInDmi === 0)
      input.fatPercentageInDmi = toNum(fatPercentageInDmi);

    // For dosage and DMI - check if units of measeurement are different
    // than API expect and convert them to expected
    if (
      (input.dosage || input.dosage === 0) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM.unitBarnSmallQuantityUnits !==
        defaultUnits.unitBarnSmallQuantityUnits
    ) {
      input.dosage = parseFloat(
        math
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          .unit(input.dosage, userUOM.unitBarnSmallQuantityUnits)
          .toNumber(defaultUnits.unitBarnSmallQuantityUnits)
          .toFixed(4)
      );
    }

    if (
      (input.dryMatterIntakeKg || input.dryMatterIntakeKg === 0) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass
    ) {
      input.dryMatterIntakeKg = parseFloat(
        math
          .unit(input.dryMatterIntakeKg, barnOutputMassUnit)
          .toNumber(defaultUnits.unitBarnOutputMass)
          .toFixed(4)
      );
    }
    return input;
  };

  // in this field insrt the text that will be shown as error and trigger validation
  const showRecalculateError = (show = true) => {
    if (show) {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP.calculatedResult_recalculate_error`,
        intl.formatMessage({ id: 'BOVAER.ERROR.CALCULATE_AGAIN' })
      );
    } else {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP.calculatedResult_recalculate_error`,
        ''
      );
    }
    (async () => fc.trigger(`${fieldItemPrefix}.supplement3NOP.calculatedResult_recalculate_error`))()
      .then(() => {})
      .catch(() => {});
  };

  // Cheks if input params are changed after the last resulting calculatio
  const paramsChangedAfterCalc = (
    currentValues: BovaerCalculationsInputData
  ) => {
    if (
      !_.isEqual(currentValues, latestCalc?.input) &&
      is3NOPChecked &&
      latestCalc
    ) {
      showRecalculateError();
      return true;
    }
    showRecalculateError(false);
    return false;
  };

  // Detect changes in input parameters and show error message to recalculate
  // _name, _value
  const onInputValuesChanges = () => {
    const currentValues = getApiInputParams();

    if(averageAnimalsPresentRef.current != null)
      currentValues.averageAnimalsPresent = toNum(
        averageAnimalsPresentRef.current
      );
    paramsChangedAfterCalc(currentValues);
  };

  // If intervention with existing 3NOP calc is opened, set initial latestCalc to
  // params for input in order to detect changed input values and prevent saving
  // with not calculated result
  useEffect(() => {
    if (
      !latestCalc &&
      is3NOPChecked &&
      (stageIndex || stageIndex === 0) &&
      ((intervention?.stages &&
        intervention?.stages?.[stageIndex]?.stageData?.feed) ||
        baseline?.emissions?.supplement3NOP?.calculatedResult)
    ) {
      const input = getApiInputParams();
      // also set avg anials present since it's in equation now
      if(averageAnimalsPresentRef.current != null)
        input.averageAnimalsPresent = toNum(averageAnimalsPresentRef.current);
      setLatestCalc({ input });
      onInputValuesChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseline?.emissions?.supplement3NOP?.calculatedResult,
    intervention,
    stageIndex,
    is3NOPChecked,
  ]);

  const ndfRef = useRef(null);
  const fatRef = useRef(null);
  const dosageRef = useRef<number | string>(null);
  const numberOfAnimalsRef = useRef(null);
  const dmiRef = useRef(null);

  const updateRef = (
    name: string,
    ref: MutableRefObject<number | string>,
    value: number | string
  ) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
    // onInputValuesChanges(name, value);
    onInputValuesChanges();
  };

  const clearAll3NOPCaclulationErrors = async () => {
    const errorFields = [];

    // clear remote calc related errors, if any for 3NOP fields
    if (
      fc.getValues(
        `${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay_error`
      )
    ) {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay_error`,
        ''
      );
      errorFields.push(
        `${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay`
      );
    }

    if (
      fc.getValues(`${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg_error`)
    ) {
      fc.setValue(`${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg_error`, '');
      errorFields.push(`${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg`);
    }

    if (
      fc.getValues(
        `${fieldItemPrefix}.supplement3NOP.fatAmountPercentage_error`
      )
    ) {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP.fatAmountPercentage_error`,
        ''
      );
      errorFields.push(`${fieldItemPrefix}.supplement3NOP.fatAmountPercentage`);
    }

    if (
      fc.getValues(
        `${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage_error`
      )
    ) {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage_error`,
        ''
      );
      errorFields.push(`${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage`);
    }

    if (
      fc.getValues(
        `${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered_error`
      )
    ) {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered_error`,
        ''
      );
      errorFields.push(`${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered`);
    }

    if (
      fc.getValues(`${fieldItemPrefix}.supplement3NOP.calculatedResult_error`)
    ) {
      fc.setValue(
        `${fieldItemPrefix}.supplement3NOP.calculatedResult_error`,
        ''
      );
      errorFields.push(`${fieldItemPrefix}.supplement3NOP.calculatedResult`);
    }

    if (errorFields.length > 0) await fc.trigger(errorFields);

    // clear warning message if any
    if (calcError) setCalcError('');
  };
  const any3NOPFieldFilled = () => {
    // check is any of the values used for the calculation changed
    const bovaerFields = fc.getValues(
      Object.values(boveareValidationFullFieldNames)
    ) as DairyIntervention;

    if (bovaerFields?.stages && bovaerFields.stages.length > stageIndex) {
      const boaverFieldValues = Object.values(
        bovaerFields.stages?.[stageIndex]?.stageData?.emissions
          ?.supplement3NOP || {}
      );

      const exist = (boaverFieldValues || []).findIndex(
        (item) => item || item === 0 || item === '0'
      );
      return (
        exist > -1 ||
        averageAnimalsPresentRef.current !==
          latestCalc?.input?.averageAnimalsPresent
      );
    }
    return false;
  };

  const validate3NOPFields = async () => {
    // trigger yup validation
    await fc.trigger([
      `${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered`,
      `${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg`,
      `${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage`,
      `${fieldItemPrefix}.supplement3NOP.fatAmountPercentage`,
      `${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay`,
    ]);

    // if anay of field used for the calculation has an error and if error is not from the bovaer 3NOP remote calc
    // then a new calculation can be done
    const validationErrors = fc.formState?.errors;
    // no barns errors
    if (!validationErrors?.barnInputs) return true;

    // const barnErrors = validationErrors?.barnInputs[stageIndex];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const stageErrors =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      validationErrors?.stages?.[stageIndex]?.stageData?.emissions;
    if (!stageErrors) return true;

    if (
      // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?._3nopDosePerCowPerDay &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-underscore-dangle
      stageErrors?.supplement3NOP?._3nopDosePerCowPerDay?.type !==
        'bovaerCalculationError'
    )
      return false;

    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.dmiAmountPerKg &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.dmiAmountPerKg?.type !==
        'bovaerCalculationError'
    )
      return false;

    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.fatAmountPercentage &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.fatAmountPercentage?.type !==
        'bovaerCalculationError'
    )
      return false;

    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.ndfAmountPercentage &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.ndfAmountPercentage?.type !==
        'bovaerCalculationError'
    )
      return false;

    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.numberOfCowsCovered &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      stageErrors?.supplement3NOP?.numberOfCowsCovered?.type !==
        'bovaerCalculationError'
    )
      return false;

    return true;
  };

  // This function is called when validations are passed
  const call3NOPCalculation = async () => {
    const input = getApiInputParams();

    // If mandatory fields not set, skip calculation
    // Form validation should raise required error
    if (
      !input.country ||
      (!input.ndfPercentageOfDmi && input.ndfPercentageOfDmi !== 0) ||
      (!input.numberOfAnimals && input.numberOfAnimals !== 0) ||
      (!input.dryMatterIntakeKg && input.dryMatterIntakeKg !== 0)
    ) {
      return null;
    }
    if (!averageAnimalsPresentRef.current) {
      // eslint-disable-next-line consistent-return
      return;
    }
    // After all validations, call API with new parametres
    const inputBeforeCall = { ...input }; // this is needed because dosage converts in fun

    const result = await calculate3NOPForBovaer(
      input as InputData3NOP,
      toNum(averageAnimalsPresentRef.current)
    );
    // Set input params and result as latest change
    // also set avg anials present since it's in equation now
    inputBeforeCall.averageAnimalsPresent = toNum(
      averageAnimalsPresentRef.current
    );
    setLatestCalc({ input: inputBeforeCall, result });
    showRecalculateError(false);
    return result;
  };

  const process3NOPErrors = async (errors: string[]) => {
    const errorFields: string[] = [];
    // errors/warnings relatet to invalid data - shown as validation errors for ceratin field
    // caclulation perfromed
    (errors || []).forEach((error) => {
      const indexOfSpace = error.indexOf(' ');

      if (indexOfSpace > -1) {
        // first word in the message is the name of the field
        // rest is the error text
        // get the key for i18n
        let i18nKey = error.substring(indexOfSpace + 1);
        i18nKey = `BOVAER.VALIDATION.${i18nKey
          .toUpperCase()
          .replace(/ /g, '_')}`;
        const field = error.substring(0, indexOfSpace);
        const message = intl.formatMessage({ id: i18nKey });

        // if field identifyed set filed error
        if (
          boveareValidationFullFieldNames[
            field as keyof typeof boveareValidationFullFieldNames
          ]
        ) {
          fc.setValue(
            `${
              boveareValidationFullFieldNames[
                field as keyof typeof boveareValidationFullFieldNames
              ]
            }_error`,
            `${message} ${showRanges(field)}`
          );
          errorFields.push(
            boveareValidationFullFieldNames[
              field as keyof typeof boveareValidationFullFieldNames
            ]
          );
        } else {
          // otherwise set calculation error
          fc.setValue(
            `${fieldItemPrefix}.supplement3NOP.calculatedResult_error`,
            message || error
          );
          errorFields.push(
            `${fieldItemPrefix}.supplement3NOP.calculatedResult`
          );
        }
      } else {
        fc.setValue(
          `${fieldItemPrefix}.supplement3NOP.calculatedResult_error`,
          error
        );
        errorFields.push(`${fieldItemPrefix}.supplement3NOP.calculatedResult`);
      }
    });

    if (errorFields.length > 0) await fc.trigger(errorFields);
  };

  const calculate3NOP = async () => {
    setLoading(true);
    try {
      // clear errors from the previous calculation
      await clearAll3NOPCaclulationErrors();

      const calcRes = await call3NOPCalculation();
      const calc3NOP = calcRes?.data?.calculateBovaer3NOP as BovaerCalculations;
      // if has errors or warnings
      await process3NOPErrors((calc3NOP?.warnings as string[]) || []);

      const result =
        calcRes?.data?.calculateBovaer3NOP?.reductionPercentage || null;

      if (
        result?.toString() !==
        parseFloat(
          fc.getValues(
            `${fieldItemPrefix}.supplement3NOP.calculatedResult`
          ) as string
        ).toFixed(4)
      ) {
        fc.setValue(
          `${fieldItemPrefix}.supplement3NOP.calculatedResult`,
          result || result === 0 ? result.toFixed(4) : ''
        );
      }
      setLoading(false);
      updateTotalMethaneFE();
      await fc.trigger(`${fieldItemPrefix}.supplement3NOP.calculatedResult`);
    } catch (err) {
      setLoading(false);
      console.log('Calc error', err);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (err?.errors?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setCalcError(err.errors?.[0]?.message as string);
        // reset any previously calculated result
        fc.setValue(`${fieldItemPrefix}.supplement3NOP.calculatedResult`, '');
      }
    }
  };

  const update3NOPCalculation = async () => {
    // all values are still not set, no need to caclulate
    // occurs during loading page, before baseline and/or interventions are fetched
    // these are values from baseline or values from intervetion if set

    // if none of 3NOP input fields is not filled, no need to perform calculation
    if (!any3NOPFieldFilled()) {
      // reset any previously calculated result
      fc.setValue(`${fieldItemPrefix}.supplement3NOP.calculatedResult`, '');
      return;
    }

    // calculation can be performed only if there are no errors
    // and some of the fields in the intervention are changed
    const validationOK = await validate3NOPFields();
    // if contains errors, and calc dependant values are not changed
    // delete calculation result
    if (!validationOK) {
      fc.setValue(`${fieldItemPrefix}.supplement3NOP.calculatedResult`, '');
      return;
    }

    // if doesn't contain errors and values are not changed, but calculatedResult is empty
    // then do caclulation to calculate result
    if (
      validationOK &&
      !fc.getValues(`${fieldItemPrefix}.supplement3NOP.calculatedResult`)
    ) {
      await calculate3NOP();
      return;
    }
    // if errors, clear previously result
    if (!validationOK) {
      fc.setValue(`${fieldItemPrefix}.supplement3NOP.calculatedResult`, '');
      return;
    }

    await calculate3NOP();
  };

  // Main calculate function, called when button is clicked, will do all the validations
  // and if valid call the 3NOP API, set result on form field and update latest calculatiom
  const calculate = () => {
    update3NOPCalculation()
      .then(() => {})
      .catch(() => {});
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          border: '1px solid #E4E4E4',
          borderRadius: '5px',
          paddingTop: '16px',
        }}
      >
        <ReactHookFormCheckbox
          style={{ paddingLeft: '16px' }}
          name={`${fieldItemPrefix}.is3NOPSelected`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.TITLE',
          })}
          // control={fc.control}
          variant="outlined"
          onChange={on3NOPSelectionChanged}
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.is3NOPSelected`) as string}
        />
        {is3NOPChecked && (
          <Grid
            style={{
              borderTop: '1px solid #E4E4E4',
              borderBottom: '1px solid #E4E4E4',
              paddingTop: '16px',
              background: '#FDFAFA',
              paddingLeft: '12px',
            }}
          >
            {calcError && (
              <Alert severity="warning">
                <AlertTitle>
                  {intl.formatMessage({
                    id: 'BOVAER.VALIDATION.CALCULATION_PROBLEM',
                  })}{' '}
                </AlertTitle>
                {calcError}
              </Alert>
            )}
            <RowControlledTextFieldWithMetrics
              name={`${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay`}
              label={intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP_DOSE.LABEL' },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                { unit: userUOM.unitBarnSmallQuantityUnits as string }
              )}
              inputRef={dosageRef}
              updateRef={updateRef}
              metricUnit={`${
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                userUOM.unitBarnSmallQuantityUnits as string
              } per cow per day`}
              baseline={
                // eslint-disable-next-line no-underscore-dangle
                baseline?.emissions?.supplement3NOP?._3nopDosePerCowPerDay
              }
              disabled={formType === FormType.View}
            />
            <Controller
              control={fc.control}
              name={`${fieldItemPrefix}.supplement3NOP._3nopDosePerCowPerDay_error`}
              defaultValue=""
              render={({ value, name }) => (
                <input type="hidden" name={name} value={value as string} />
              )}
            />
            <RowControlledTextFieldWithMetrics
              name={`${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg`}
              label={intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI' },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                { unit: userUOM.unitBarnOutputMass as string }
              )}
              inputRef={dmiRef}
              updateRef={updateRef}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              metricUnit={`per ${userUOM.unitBarnOutputMass as string}`}
              baseline={baseline?.emissions?.supplement3NOP?.dmiAmountPerKg}
              disabled={formType === FormType.View}
            />
            <Controller
              control={fc.control}
              name={`${fieldItemPrefix}.supplement3NOP.dmiAmountPerKg_error`}
              defaultValue=""
              render={({ value, name }) => (
                <input type="hidden" name={name} value={value as string} />
              )}
            />
            <RowControlledTextFieldWithMetrics
              name={`${fieldItemPrefix}.supplement3NOP.fatAmountPercentage`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.DMI_FAT',
              })}
              inputRef={fatRef}
              updateRef={updateRef}
              metricUnit="percentage"
              baseline={
                baseline?.emissions?.supplement3NOP?.fatAmountPercentage
              }
              disabled={formType === FormType.View}
            />
            <Controller
              control={fc.control}
              name={`${fieldItemPrefix}.supplement3NOP.fatAmountPercentage_error`}
              defaultValue=""
              render={({ value, name }) => (
                <input type="hidden" name={name} value={value as string} />
              )}
            />
            <RowControlledTextFieldWithMetrics
              name={`${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NDF',
              })}
              inputRef={ndfRef}
              updateRef={updateRef}
              metricUnit="percentage"
              baseline={
                baseline?.emissions?.supplement3NOP?.ndfAmountPercentage
              }
              disabled={formType === FormType.View}
            />
            <Controller
              control={fc.control}
              name={`${fieldItemPrefix}.supplement3NOP.ndfAmountPercentage_error`}
              defaultValue=""
              render={({ value, name }) => (
                <input type="hidden" name={name} value={value as string} />
              )}
            />
            <RowControlledTextFieldWithMetrics
              name={`${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.NUM_OF_COWS',
              })}
              inputRef={numberOfAnimalsRef}
              updateRef={updateRef}
              metricUnit="number"
              baseline={
                baseline?.emissions?.supplement3NOP?.numberOfCowsCovered
              }
              disabled={formType === FormType.View}
              marginBottom={0}
            />
            <Controller
              control={fc.control}
              name={`${fieldItemPrefix}.supplement3NOP.numberOfCowsCovered_error`}
              defaultValue=""
              render={({ value, name }) => (
                <input type="hidden" name={name} value={value as string} />
              )}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              mr="47px"
              mb="16px"
            >
              <Controller
                control={fc.control}
                name={`${fieldItemPrefix}.supplement3NOP.calculatedResult_recalculate_error`}
                defaultValue=""
                render={({ value, name }) => (
                  <Box pt="6px" color="red">
                    <input type="hidden" name={name} value={value as string} />
                    {value}
                  </Box>
                )}
              />
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                disabled={formType === FormType.View}
                onClick={() => calculate()}
              >
                {intl.formatMessage({ id: 'BOVAER.ERROR.CALCULATE' })}
              </Button>
            </Box>
            <Grid style={{ paddingRight: '30px' }}>
              <span>
                {intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.EMMISION_CORRECTION',
                })}
              </span>{' '}
              -
              <span style={{ paddingLeft: '12px' }}>
                {intl.formatMessage({
                  id: 'BASELINE.FORM.BARN.DAIRY.3-NOP.METHANE_FROM',
                })}
              </span>{' '}
              <br />
              <span style={{ float: 'right' }}>
                {intl.formatMessage({ id: 'GENERAL.ORIGINAL_VALUE' })}:{' '}
                <b>
                  {!bovaerOriginalCalcResult ||
                  Number.isNaN(bovaerOriginalCalcResult)
                    ? '-'
                    : `${parseFloat(bovaerOriginalCalcResult as string).toFixed(
                        2
                      )} %`}
                </b>
                <span style={{ marginLeft: '8px' }}>
                  {intl.formatMessage({ id: 'GENERAL.NEW_VALUE' })}:
                  <ReactHookPreviewField
                    name={`${fieldItemPrefix}.supplement3NOP.calculatedResult`}
                    hidden={loading}
                    precision={2}
                    addornment="%"
                    textAlign="right"
                  />
                  {loading && (
                    <CircularProgress color="secondary" size="20px" />
                  )}
                  <Controller
                    control={fc.control}
                    name={`${fieldItemPrefix}.supplement3NOP.calculatedResult_error`}
                    defaultValue=""
                    render={({ value, name }) => (
                      <input
                        type="hidden"
                        name={name}
                        value={value as string}
                      />
                    )}
                  />
                </span>
              </span>
              <br />
              <Divider /> <br />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SustellInterventionBovaerSection;

