import React from "react";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export function ScrollTop() {
  return (
      <div id="kt_scrolltop" className="scrolltop">
        <span className="svg-icon">
        <ArrowUpwardIcon style={{ color: "white" }} fontSize="large" />
        </span>{" "}
      </div>
  );
}
