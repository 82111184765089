import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import {
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";

import DeleteForever from '@material-ui/icons/DeleteForever';
import InfoToolTip from '../../Helpers/InfoToolTip';
import SustellNewRationInput from './NewRationInput';
import { resolvePath } from "../../Helpers/resolvePathFn";
// import { useSelector } from "react-redux";
// import { UserProfilePrefs } from "../../Helpers/UserProfilePrefs";

const feedItem = { feedType: '', kgPerAnimal: '' };

const SustellRationFeeds = (props) => {
  const { 
    label, 
    availableFeedItems, 
    subFieldName, 
    tooltip, 
    formState, 
    updateTotalFeedIntake, 
    animalType, 
    fieldItemPrefix,
    feedData,
    setFeedData
  } = props;
  
  const manualChange = useRef(false);
  const { getValues, setValue } = useFormContext();

  // For now there is no edit CF icon available

  // const selectedCustomer = useSelector((state) => state.selectedCustomer?.value);
  // const [dsmFeedsVisible, setDsmFeedsVisible] = useState(false) ;


  // useEffect(() => {
  //   if (UserProfilePrefs.getInstance().isDSMEmployee() === undefined) {
  //     UserProfilePrefs.getInstance().fetchAuthenticatedUser().then(() => {
  //       setDsmFeedsVisible(!(!selectedCustomer?.feedsVisible && UserProfilePrefs.getInstance().isDSMEmployee()));
  //     })
  //   } else {
  //     setDsmFeedsVisible(!(!selectedCustomer?.feedsVisible && UserProfilePrefs.getInstance().isDSMEmployee()));
  //   }
  // }, []);

  useEffect(() => {
    if(manualChange.current){
      // updates the underlying fields in form after manual 
      // adding or removing feeds - important on removal!
      // but should not be triggered when components refresh by itself (change in selectbox or similar)
      setValue(`${fieldItemPrefix  }.${  subFieldName}`, feedData);
      manualChange.current = false;
    }
    if(updateTotalFeedIntake) updateTotalFeedIntake();
  }, [ feedData, fieldItemPrefix, subFieldName, setValue, updateTotalFeedIntake]);


  const append = () => {
    let formValues = resolvePath(getValues(), `${fieldItemPrefix}.${subFieldName}`, []);
    manualChange.current = true;
    if(!formValues)
      formValues = [];
    setFeedData([...formValues, { ...feedItem } ]);
  };

  const remove = (index) => {
    const formValues = resolvePath(getValues(),  `${fieldItemPrefix}.${subFieldName}`, []);
    manualChange.current = true;
    setFeedData([...formValues.slice(0,index), ...formValues.slice(index+1) ])
  };

  // opens home page, with selected customer, that shall display compound feeds for that customer
  // const openCompoundFeed = () => {
  //   let url = window.location.origin;
  //   if(url && !url.endsWith('/'))
  //     url += '/';

  //   if(customerId)  {
  //     url += `customers/${customerId}?${new URLSearchParams({scrollTo: "feed"})}`
  //   }

  //   window.open(url, "_blank")
  // }

  return (
    <React.Fragment>
      {(feedData || []).map((item, index) => {
        return (
          !(formState === "view" && !item.feedType) && 
            <Grid item container key={'inputItem_' + subFieldName + index} justifyContent="space-between" alignItems="center">
              <SustellNewRationInput
                label={label}
                availableFeedItems={availableFeedItems}
                index={index}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName={subFieldName}
                feedTypeValue={item.feedType}
                updateTotalFeedIntake={updateTotalFeedIntake}
                formState={formState}
                animalType={animalType}
              />
              <Grid item >
                {formState !== "view" &&
                  <IconButton style={{marginBottom:'5px'}}
                    aria-label="Remove input"
                    onClick={() => remove(index)}
                  >
                    <DeleteForever />
                  </IconButton>
                }
              </Grid>
              <Grid item key={"inputPart4" + item.id}>
                <InfoToolTip tooltip={tooltip} />
              </Grid>
            </Grid>
        )
      })
      }
      {formState !== "view" &&
        <Grid item xs={12} align="right" style={{marginBottom: '16px'}}>
          <Button variant="outlined" onClick={(e) => append()}>Add {label}</Button>
        </Grid>
      } 
    </React.Fragment>
  );
};
export default SustellRationFeeds;