import { Box, Button, Grid } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../FarmFlow/CommonDataParts/DialogContainer';
import { feedIngredientsStyles } from '../../../../../_metronic/layout/_core/MaterialThemeProvider';
import { IconProvider } from '../../helpers/IconProvider';
import ControlledAutocomplete from '../../../../modules/Helpers/ControlledAutocomplete';
import {
  CustomIngredient,
  CustomIngredientError,
  FormType,
  IngredientUses,
} from '../types';
import RowTextFieldWithInfo from '../../../../modules/Farms/Intervention/RowTextFieldWithInfo';

interface CustomIngredientDialogProps {
  formVisible: boolean;
  rowIndex: number;
  handleClose: (removeIngredient?: boolean, rowIndex?: number) => void;
  handleConfirm: (customIngredient: CustomIngredient) => void;
  // eslint-disable-next-line react/require-default-props
  formType?: FormType;
  // eslint-disable-next-line react/require-default-props
  data?: CustomIngredient | null;
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinationsCategories?: IngredientUses[] | null;
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinations?: IngredientUses[] | null;
}

const CustomIngredientDialog = ({
  formVisible,
  handleClose,
  rowIndex,
  handleConfirm,
  data = null,
  formType = FormType.New,
  ingredientOriginCombinationsCategories = null,
  ingredientOriginCombinations = null,
}: CustomIngredientDialogProps) => {
  const intl = useIntl();
  const classes = feedIngredientsStyles();

  const getIngredientLabel = (ingredientId: string) =>
    ingredientOriginCombinationsCategories?.find(
      (ingredient: IngredientUses) => ingredient.id === ingredientId
    )?.name || '';

  const methods = useForm<CustomIngredient>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        categoryId: Yup.string().required(
          intl.formatMessage({
            id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.CATEGORY.REQUIRED',
          })
        ),
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
          .min(
            3,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_LENGTH' },
              { count: 3 }
            )
          )
          .max(
            100,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MAX_LENGTH' },
              { count: 100 }
            )
          )
          .test('uniqueIngedientName', '', (value, testContext) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const form = testContext.parent as CustomIngredient;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const id = testContext.resolve(form.id) as string;

            const existingItem = ingredientOriginCombinations?.find(
              (item) => item.name === value
            );

            // if item with the same name allready exists
            if (existingItem)
              if (formType === FormType.Edit) {
                // edit mode
                // check is this the same item being changed,
                // not same, unique error
                if (existingItem.id !== id)
                  return testContext.createError({
                    path: testContext.path,
                    message: intl.formatMessage({
                      id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.NAME.VALIDATION.UNIQUE',
                    }),
                  });
              }
              // new mode and item with the give name allready exists, error
              else
                return testContext.createError({
                  path: testContext.path,
                  message: intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.NAME.VALIDATION.UNIQUE',
                  }),
                });

            return true;
          }),
      })
    ),
    defaultValues: {
      categoryId: '',
      name: '',
      id: null,
    },
  });

  useEffect(() => {
    if (data) methods.reset(data);
    else methods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  const onSubmit = (submitData: CustomIngredient) => {
    if (handleConfirm) handleConfirm(submitData);
    setHasValidationError(false);
  };

  const onErrors = (error: CustomIngredientError) => {
    setHasValidationError(true);
  };

  const title =
    (formType === FormType.New &&
      intl.formatMessage({
        id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.TITLE.ADD',
      })) ||
    (formType === FormType.Edit &&
      intl.formatMessage({
        id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.TITLE.CHANGE',
      })) ||
    (formType === FormType.View &&
      intl.formatMessage({
        id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.TITLE.VIEW',
      }));


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>

    <DialogContainer
      formVisible={formVisible}
      handleClose={async() => {
        await methods.trigger();
        const name = methods.getValues('name');
        const categoryId = methods.getValues('categoryId');
        // Only for new ingredients remove if name is not unique
        if (
          (
            methods.errors &&
            Object.keys(methods.errors).length !== 0 &&
            name
          ) ||
          (!data?.name && !name && !categoryId)
        ) {
          handleClose(true, rowIndex);
        } else {
          handleClose();
        }
      }
      }
      datasetType="footprint"
      iconCode="proxyIngredientIcon"
      iconColor="#EE7203"
      width="605px"
      formTitle={title}
    >
      {hasValidationError && (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'SUSTELL.ERROR.MANDATORY_FIELDS' })}
              </AlertTitle>
            </Alert>
          </Grid>
        </Grid>
      )}
      <Box className={classes.proxyDialogTextBox}>
        {intl.formatMessage(
          {
            id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.MESSAGE',
          },
          { br: <br /> }
        )}
      </Box>
          <Box mb={4} mt={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={8} className={classes.proxyDialogSubtitleBox}>
                {intl.formatMessage({
                  id: 'COMPOUND_FEED.CUSTOM_DIALOG.INGREDIENT_CATEGORY',
                })}
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={methods.control}
                  defaultValue=""
                  name="id"
                  render={({ value, name }) => (
                    <input type="hidden" name={name} value={value} />
                  )}
                />
                <ControlledAutocomplete
                  name="categoryId"
                  multiple={false}
                  control={methods.control}
                  options={ingredientOriginCombinationsCategories?.map(
                    (item: IngredientUses) => item.id
                  )}
                  getOptionLabel={getIngredientLabel}
                  disabled={formType === FormType.View}
                  defaultValue=""
                  label={`${intl.formatMessage({
                    id: 'COMPOUND_FEED.CUSTOM_DIALOG.INGREDIENT_CATEGORY',
                  })} *`}
                />
              </Grid>
              <Grid item xs={8} className={classes.proxyDialogSubtitleBox}>
                {intl.formatMessage({
                  id: 'COMPOUND_FEED.CUSTOM_DIALOG.INGREDIENT_NAME',
                })}
              </Grid>
              <Grid item xs={9}>
                <RowTextFieldWithInfo
                  name="name"
                  label={`${intl.formatMessage({
                    id: 'COMPOUND_FEED.CUSTOM_DIALOG.INGREDIENT_NAME',
                  })} *`}
                  margin="none"
                  variant="outlined"
                  type="text"
                  defaultValue=""
                  disabled={formType === FormType.View}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.proxyDialogTextBox}>
            {intl.formatMessage(
              {
                id: 'COMPOUNDFEED_WIZARD.CUSTOM_INGREDIENT_DIALOG.MESSAGE_INDICATOR',
              },
              { br: <br /> }
            )}
            &nbsp;
            <IconProvider
              iconCode="proxyIngredientIcon"
              alt="Proxy ingredient"
              height="20px"
              color="#EE7203"
            />
          </Box>

          <Box className={classes.buttonsBox}>
            <Button
              fullWidth
              variant="contained"
              type="button"
              onClick={(event) => {
                if (formType !== FormType.View)
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  methods.handleSubmit(onSubmit, onErrors)(event);
                else if (handleClose) handleClose();
              }}
              className={classes.buttonStyles}
            >
              {formType === FormType.New &&
                intl.formatMessage({
                  id: 'COMPOUNDFEED_WIZARD.CUSTOM_DIALOG.ADD',
                })}

              {formType === FormType.Edit &&
                intl.formatMessage({
                  id: 'COMPOUNDFEED_WIZARD.CUSTOM_DIALOG.CHANGE',
                })}

              {formType === FormType.View &&
                intl.formatMessage({
                  id: 'COMPOUNDFEED_WIZARD.CUSTOM_DIALOG.CLOSE',
                })}
            </Button>
          </Box>
    </DialogContainer>
    </form>

    </FormProvider>

  );
};

export default CustomIngredientDialog;
