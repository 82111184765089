import React from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@material-ui/core';

import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
// import { useIntl } from 'react-intl';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';

export function ConfirmDialog(props) {

  const [open, setOpen] = React.useState(true);
  const intl = useIntl();
  let {
    title=intl.formatMessage({id: "UNSAVEDSTATE.DIALOG.TITLE"}), 
    message=intl.formatMessage({id: "UNSAVEDSTATE.DIALOG.MESSAGE"}), 
    setConfirm, 
    confirmCallback } = props;
  
  const allowTransition = () => {
    setOpen(false);
    setConfirm(false)
    confirmCallback(true);
  };

  const blockTransition = () => {
    setConfirm(false);
    confirmCallback(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <>
            <WarningOutlinedIcon
              fontSize="large"
              color="error"
            />&nbsp;&nbsp;{title}
          </>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={blockTransition} color="primary">
            {intl.formatMessage({id: "GENERAL.CANCEL" }) }
          </Button>
          <Button onClick={allowTransition} color="primary" autoFocus>
          {intl.formatMessage({id: "GENERAL.YES" }) }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
