import { FC, useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { Maybe } from 'graphql/jsutils/Maybe';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, ListEntry } from '../../common';
import {
  ShrimpBaseline,
  ShrimpOperations,
} from '../../../../models/Baseline/ShrimpBaseline';
import Resources from '../../CommonDataParts/v2.0/Resources';
import { AnimalType, MasterDataOption, StageType } from '../../../../../../graphql/types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { defaultUnitsV2, unitLong } from '../../../../utils/unit-utils';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import SGrowingFertilizerForm from './SGrowingFertilizerForm';
import SGrowingMaterialForm from './SGrowingMaterialForm';
import SGrowingChemicalForm from './SGrowingChemicalForm';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';

interface SOperationsFormDialogProps
  extends Omit<BaselineDialogProps, 'baseline'> {
  masterDataEnums: MasterDataEnumType | null;
  baseline: ShrimpBaseline;
  animalType: AnimalType;
  stageType: StageType;
  singleIngredients?: any;
}

const SOperationsFormDialog: FC<SOperationsFormDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  handleCancel,
  animalType,
  stageType,
  masterDataEnums,
  singleIngredients
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  const currResetValue = useRef<ShrimpOperations>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const fieldItemPrefix = `stages.${itemIndex}.stageData.operations`;

  const feedsToFertilizer: string[] = [
    'Sugar beet molasses'
  ];

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, reset, trigger, getValues } =
    useFormContext<ShrimpBaseline>();

  const {
    fields: fertilizerTypes,
    append: addFertilizerType,
    remove: removeFertilizerType,
  } = useFieldArray({
    name: `${fieldItemPrefix}.fertilizerTypes`,
    control,
    keyName: 'keyId',
  });

  const addFertilizer = () => {
    addFertilizerType({
      type: '',
      amount: '',
    });
  };

  const removeFertilizer = (index: number) => {
    removeFertilizerType(index);
  };

  const {
    fields: materialTypes,
    append: addMaterialType,
    remove: removeMaterialType,
  } = useFieldArray({
    name: `${fieldItemPrefix}.materialTypes`,
    control,
    keyName: 'keyId',
  });

  const addMaterial = () => {
    addMaterialType({
      type: '',
      amount: '',
      transportMode: '',
      distanceTraveled: '',
      lifetimeOfMaterial: '',
    });
  };

  const removeMaterial = (index: number) => {
    removeMaterialType(index);
  };

  const {
    fields: chemicalTypes,
    append: addChemicalType,
    remove: removeChemicalType,
  } = useFieldArray({
    name: `${fieldItemPrefix}.chemicalTypes`,
    control,
    keyName: 'keyId',
  });

  const addChemical = () => {
    addChemicalType({
      type: '',
      amount: '',
    });
  };

  const removeChemical = (index: number) => {
    removeChemicalType(index);
  };

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE',
  });

  const introText = intl.formatMessage({
    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.DESCRIPTION',
  });

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep<ShrimpOperations>(getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  useEffect(() => {
    if (fertilizerTypes.length === 0) {
      addFertilizer();
    }
    if (materialTypes.length === 0) {
      addMaterial();
    }
    if (chemicalTypes.length === 0) {
      addChemical();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as ShrimpBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.operations) {
        resetObject.stages[itemIndex].stageData.operations = currResetValue.current;
        reset(resetObject, {
          errors: true,
        });
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  const formatItem = (option: MasterDataOption) => {
    let formatedValue = {
      value: option.value,
      text: option.value
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
    };
    
    return formatedValue;
  }

  const getSalinityOptions = () => {
    let salinityOptions: ListEntry[] = [];

    masterDataEnums?.Salinity?.forEach((option) => {
      let newValue = formatItem(option);
      salinityOptions.push(newValue);
    });

    salinityOptions.sort((optionA, optionB) => 
      optionA.value.localeCompare(optionB.value)
    );

    return salinityOptions;
  };

  const getFertilizerOptions = () => {
    let fertilizerTypeOptions: ListEntry[] = [];

    masterDataEnums?.FertilizerType?.forEach((option) => {
      let newValue = formatItem(option);
      fertilizerTypeOptions.push(newValue);
    });

    if (singleIngredients) {
      const ingredient = singleIngredients.find((ingredient: any) => 
        feedsToFertilizer.includes(ingredient?.value)
      );
      if (ingredient) fertilizerTypeOptions.push(ingredient);
    }

    fertilizerTypeOptions.sort((optionA, optionB) => 
      optionA.value.localeCompare(optionB.value)
    );

    return fertilizerTypeOptions;
  };

  const getMaterialOptions = () => {
    const materialTypeOptions: ListEntry[] = [];

    let materialType: Maybe<MasterDataOption[]> | undefined = []
    switch(stageType) {
      case StageType.Hatching:
        materialType = masterDataEnums?.HatcheryStageMaterialType;
        break;
      default:
        materialType = masterDataEnums?.GrowingStageMaterialType;
        break;
    }
    materialType?.forEach((option) => {
      const newValue = formatItem(option);
      materialTypeOptions.push(newValue);
    });

    materialTypeOptions.sort((optionA, optionB) => 
      optionA.value.localeCompare(optionB.value)
    );

    return materialTypeOptions;
  };

  const getTransportOptions = () => {
    let transportModeOptions: ListEntry[] = [];

    masterDataEnums?.TransportMode?.forEach((option) => {
      let newValue = formatItem(option);
      transportModeOptions.push(newValue);
    });

    transportModeOptions.sort((optionA, optionB) => 
      optionA.value.localeCompare(optionB.value)
    );

    return transportModeOptions;
  };

  const getChemicalOptions = () => {
    let chemicalOptions: ListEntry[] = [];

    masterDataEnums?.ShrimpProductionChemicalType?.forEach((option) => {
      let newValue = formatItem(option);
      chemicalOptions.push(newValue);
    });

    chemicalOptions.sort((optionA, optionB) => 
      optionA.value.localeCompare(optionB.value)
    );

    return chemicalOptions;
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      formTitle={formTitle}
      introText={introText}
      iconCode="general/settings-01"
      width="1024px"
    >
      <DsmGrid
        className={classes.dsmGridOneColumnNoRowgap}
        style={{ rowGap: '0px' }}
      >
        <h4 style={{ margin: 0 }}>
          {intl.formatMessage({ id: 'GENERAL.RESOURCES' })}
        </h4>
        <Resources
          formType={formType}
          inputType={`stages.${itemIndex}.stageData.operations.resourceUse`}
          animalType={animalType}
          isBaselineDialog
        />
        <DsmGrid className={classes.dsmGridTwoColumn}>
          <ReactHookDsmInput
            name={`stages.${itemIndex}.stageData.operations.resourceUse.waterUse`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER.TOOLTIP',
            })}
            adornment={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              userUOM?.unitWaterUse
                ? unitLong(userUOM.unitWaterUse)
                : defaultUnitsV2.unitWaterUse
            }
            disabled={formType === FormType.View}
            defaultValue={
              getValues(`${fieldItemPrefix}.resourceUse.waterUse`)
            }
          />
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.SALINITY',
            })}
            placeholder={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.SALINITY.PLACEHOLDER',
            })}
            name={`stages.${itemIndex}.stageData.operations.resourceUse.watersalinity`}
            options={getSalinityOptions()}
            disabled={formType === FormType.View}
            required
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.SALINITY.TOOLTIP',
            })}
            adornment=" "
            defaultValue={
              getValues(`${fieldItemPrefix}.resourceUse.watersalinity`)
            }
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridFourColumn}>
          <ReactHookDsmInput
            name={`stages.${itemIndex}.stageData.operations.resourceUse.waterEnteringPondIn`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_IN_POND',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_IN_POND.TOOLTIP',
            })}
            type="number"
            adornment={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              userUOM?.unitWaterUse
                ? unitLong(userUOM.unitWaterUse)
                : defaultUnitsV2.unitWaterUse
            }
            disabled={formType === FormType.View}
            defaultValue={
              getValues(`${fieldItemPrefix}.resourceUse.waterEnteringPondIn`)
            }
          />
          <ReactHookDsmInput
            name={`stages.${itemIndex}.stageData.operations.resourceUse.nitrogenConcentrationIn`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.TOOLTIP',
            })}
            type="number"
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.PLACEHOLDER',
            })}
            disabled={formType === FormType.View}
            defaultValue={
              getValues(`${fieldItemPrefix}.resourceUse.nitrogenConcentrationIn`)
            }
          />
          <ReactHookDsmInput
            name={`stages.${itemIndex}.stageData.operations.resourceUse.waterEnteringPondOut`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_OUT_POND',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_OUT_POND.TOOLTIP',
            })}
            type="number"
            adornment={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              userUOM?.unitWaterUse
                ? unitLong(userUOM.unitWaterUse)
                : defaultUnitsV2.unitWaterUse
            }
            disabled={formType === FormType.View}
            defaultValue={
              getValues(`${fieldItemPrefix}.resourceUse.waterEnteringPondOut`)
            }
          />
          <ReactHookDsmInput
            name={`stages.${itemIndex}.stageData.operations.resourceUse.nitrogenConcentrationOut`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.TOOLTIP',
            })}
            type="number"
            adornment={intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.PLACEHOLDER',
            })}
            disabled={formType === FormType.View}
            defaultValue={
              getValues(`${fieldItemPrefix}.resourceUse.nitrogenConcentrationOut`)
            }
          />
        </DsmGrid>
        <div>
          {fertilizerTypes &&
            fertilizerTypes?.map((item, index) => (
              <SGrowingFertilizerForm
                key={item.keyId}
                stageIndex={itemIndex}
                itemIndex={index}
                formType={formType}
                removeHandler={removeFertilizer}
                options={getFertilizerOptions()}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addFertilizer}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ADD_FERTILIZER_TYPE',
            })}
          </DsmButton>
        </div>
        <br />
        <h4>
          {intl.formatMessage({
            id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIALS',
          })}
        </h4>
        <div>
          {materialTypes &&
            materialTypes?.map((item, index) => (
              <SGrowingMaterialForm
                key={item.keyId}
                stageIndex={itemIndex}
                itemIndex={index}
                formType={formType}
                removeHandler={removeMaterial}
                materialOptions={getMaterialOptions()}
                transportOptions={getTransportOptions()}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addMaterial}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ADD_MATERIALS_TYPE',
            })}
          </DsmButton>
        </div>
        <br />
        <h4>
          {intl.formatMessage({
            id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICALS',
          })}
        </h4>
        <div>
          {chemicalTypes &&
            chemicalTypes?.map((item, index) => (
              <SGrowingChemicalForm
                key={item.keyId}
                stageIndex={itemIndex}
                itemIndex={index}
                formType={formType}
                removeHandler={removeChemical}
                options={getChemicalOptions()}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addChemical}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ADD_CHEMICAL_TYPE',
            })}
          </DsmButton>
        </div>
        <br/>
        <DsmGrid className={classes.dsmGridFourColumn}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.iceAmount`}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT',
          })}`}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT.TOOLTIP',
          })}
          adornment={intl.formatMessage({
            id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT.PLACEHOLDER',
          })}
          type="number"
          disabled={formType === FormType.View}
          defaultValue={
            getValues(`${fieldItemPrefix}.iceAmount`)
          }
        /></DsmGrid>
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => {
            await trigger(`stages.${itemIndex}.stageData.operations`);
            handleCancel('confirm');
          }}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </DsmGrid>
    </DialogContainer>
  );
};

export default SOperationsFormDialog;
