// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, get } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, PoultryFarms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import {
  ExternalSource,
  InternalSource,
  PoultryBaseline,
  PoultryInputGrowing,
} from '../../../../models/Baseline/PoultryBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import PoultryInputPartInternalSource from './PoultryInputPartInternalSource';
import PoultryInputPartExternalSource from './PoultryInputPartExternalSource';

const headingTitle = {
  fontWeight: 'bold',
  fontSize: '18px',
  color: 'black',
  marginTop: '10px',
  marginLeft: '10px',
  cursor: 'pointer',
};

export interface PoultryInputDialogProps extends BaselineDialogProps {
  farms: PoultryFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
  animalType: string;
}

const PoultryInputGrowingDialog: FC<PoultryInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  animalType,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<PoultryInputGrowing>();
  const fc = useFormContext<PoultryBaseline>();
  const { control } = fc;
  const [internalSourceSelected, setInternalSourceSelected] =
    useState<boolean>(true);
  const [externalSourceSelected, setExternalSourceSelected] =
    useState<boolean>(true);
  const initialInternalSourceSelected = useRef<boolean>(true);
  const initialExternalSourceSelected = useRef<boolean>(true);
  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control,
    keyName: 'keyId',
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control,
    keyName: 'keyId',
  });

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.input) {
        resetObject.stages[itemIndex].stageData.input = {
          ...currResetValue.current,
        } as PoultryInputGrowing;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (!initialInternalSourceSelected.current)
      fc.setValue(`${fieldItemPrefix}.internalSources`, []);
    if (!initialExternalSourceSelected.current)
      fc.setValue(`${fieldItemPrefix}.externalSources`, []);
    if (handleCancel) handleCancel('reset');
  };

  const handleSaveClick = async () => {
    const currFormValues = fc.getValues();
    fc.reset(currFormValues, { errors: true });
    if (!internalSourceSelected)
      fc.setValue(`${fieldItemPrefix}.internalSources`, []);
    if (!externalSourceSelected)
      fc.setValue(`${fieldItemPrefix}.externalSources`, []);
    await fc.trigger(fieldItemPrefix);
    handleCancel('confirm');
  };

  const addInternalSource = () => {
    appendInternal({
      id: uuidv4(),
      farmId: '',
      originStageId: '',
      numberAnimals: '',
      transportDistance: '',
    });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
  };

  const addExternalSource = () => {
    appendExternal({
      id: uuidv4(),
      numberAnimals: '',
      averageWeight: '',
      transportDistance: '',
    });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
  };

  useEffect(() => {
    if (formVisible) {
      const intSources: InternalSource[] = fc.getValues(
        `${fieldItemPrefix}.internalSources`
      );
      const extSources: ExternalSource[] = fc.getValues(
        `${fieldItemPrefix}.externalSources`
      );
      if (intSources?.length === 0) {
        setInternalSourceSelected(false);
        initialInternalSourceSelected.current = false;
        addInternalSource();
      }
      if (extSources?.length === 0) {
        setExternalSourceSelected(false);
        initialExternalSourceSelected.current = false;
        addExternalSource();
      }
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const triggerSelectionValidation = () => {
    // eslint-disable-next-line no-void
    void fc.trigger(`${fieldItemPrefix}.selection`);
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
      })}
      variant="wide"
      introText={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.BREEDING_DESCRIPTION',
      })}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.START_DATE',
          })}
          tooltip={intl.formatMessage(
            {
              id: 'SUSTELL.STAGE.POULTRY.INPUT.START_DATE.TOOLTIP',
            },
            { stageType: 'growing' }
          )}
          disabled={formType === FormType.View}
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.startDate`)}
        />
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.END_DATE',
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage(
            {
              id: 'SUSTELL.STAGE.POULTRY.INPUT.END_DATE.TOOLTIP',
            },
            { stageType: 'growing' }
          )}
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.endDate`)}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.durationOfProductionCycle`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.PRODUCTION_PERIOD',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.PRODUCTION_CYCLE.ADORNMENT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.PRODUCTION_CYCLE.TOOLTIP',
          })}
          type="number"
          required
          defaultValue={fc.getValues(
            `${fieldItemPrefix}.durationOfProductionCycle`
          )}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.emptyPeriod`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.EMPTY_PERIOD',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.EMPTY_PERIOD.ADORNMENT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.EMPTY_PERIOD.TOOLTIP',
          })}
          defaultValue={fc.getValues(`${fieldItemPrefix}.emptyPeriod`)}
          type="number"
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.animalsPresentAtStart`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_START_DATE',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_START_DATE.ADORNMENT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_START_DATE.TOOLTIP',
          })}
          type="number"
          defaultValue={fc.getValues(
            `${fieldItemPrefix}.animalsPresentAtStart`
          )}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.animalsPresentAtEnd`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_END_DATE',
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_END_DATE.ADORNMENT',
          })}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.ANIMALS_AT_END_DATE.TOOLTIP',
          })}
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.animalsPresentAtEnd`)}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeightAtStart`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_START_DATE',
          })}
          disabled={formType === FormType.View}
          adornment={barnOutputMassUnit}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_START_DATE.TOOLTIP',
          })}
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.averageWeightAtStart`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeightAtEnd`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_END_DATE',
          })}
          disabled={formType === FormType.View}
          adornment={barnOutputMassUnit}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.INPUT.AVERAGE_WEIGHT_AT_END_DATE.TOOLTIP',
          })}
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.averageWeightAtEnd`)}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
        {get(fc.errors, `stages.${itemIndex}.stageData.input.selection`) && (
          <div
            style={{ color: '#E51F22', height: '14px', marginBottom: '25px' }}
          >
            <span>
              {get(
                fc.errors,
                `stages.${itemIndex}.stageData.input.selection.message`
              )}
            </span>
          </div>
        )}
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <input
              type="checkbox"
              name={`${fieldItemPrefix}.selection.internalSelected`}
              defaultChecked={internalSourceSelected}
              id="internalSources"
              disabled={formType === FormType.View}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              onChange={(e) => {
                setInternalSourceSelected(e.target?.checked);
                fc.setValue(
                  `${fieldItemPrefix}.selection.internalSelected`,
                  e.target?.checked
                );
                triggerSelectionValidation();
                if (!e.target?.checked)
                  fc.clearErrors(`${fieldItemPrefix}.internalSources`);
              }}
            />
            <label htmlFor="internalSources" style={headingTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            {internalSources?.map((item, index) => (
              <PoultryInputPartInternalSource
                key={item.keyId}
                farms={farms}
                farmId={farmId}
                farmName={farmName}
                allowedStagesForFarm={[StageType.Growing, StageType.Hatching]}
                productionProcessName={productionProcessName}
                stageIndex={itemIndex}
                itemIndex={index}
                propName="internalSources"
                stageType={StageType.Growing}
                intlPrefix={StageType.Growing}
                formType={formType}
                removeHandler={removeInternalSource}
                sourceSelected={internalSourceSelected}
                triggerSelectionError
                animalType={animalType}
              />
            ))}
            <DsmButton
              variant="text"
              onClick={addInternalSource}
              disabled={formType === FormType.View || !internalSourceSelected}
            >
              <DsmIcon slot="before" name="general/plus-circle" />
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.INTERNAL_SOURCE.ADDITIONAL',
              })}
            </DsmButton>
          </div>
        </div>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <input
              type="checkbox"
              name={`${fieldItemPrefix}.selection.externalSelected`}
              defaultChecked={externalSourceSelected}
              id="externalSources"
              disabled={formType === FormType.View}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              onChange={(e) => {
                setExternalSourceSelected(e.target?.checked);
                fc.setValue(
                  `${fieldItemPrefix}.selection.externalSelected`,
                  e.target?.checked
                );
                triggerSelectionValidation();
                if (!e.target?.checked)
                  fc.clearErrors(`${fieldItemPrefix}.externalSources`);
              }}
            />
            <label htmlFor="externalSources" style={headingTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
          </div>
          <div>
            {externalSources?.map((item, index) => (
              <PoultryInputPartExternalSource
                key={item.keyId}
                stageIndex={itemIndex}
                itemIndex={index}
                propName="externalSources"
                intlPrefix={StageType.Growing}
                formType={formType}
                removeHandler={removeExternalSource}
                sourceSelected={externalSourceSelected}
                stageType={StageType.Growing}
              />
            ))}
            <DsmButton
              variant="text"
              onClick={addExternalSource}
              disabled={formType === FormType.View || !externalSourceSelected}
            >
              <DsmIcon slot="before" name="general/plus-circle" />
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.POULTRY.INPUT.EXTERNAL_SOURCE.ADDITIONAL',
              })}
            </DsmButton>
          </div>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => handleSaveClick()}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryInputGrowingDialog;
