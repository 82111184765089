/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { v4 as uuidv4 } from 'uuid';
import {
  createCorePayload,
  mapResourceUseSustell,
  mapCompoundFeedForOneStage,
  mapSingleIngridientsForOneStage,
} from './outCommonMapperSustellV2';
import { AnimalType, StageType } from '../../../../../graphql/types';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { setInterventionFloatValue } from './outCommonMapperSustell';
import { emissionFieldsMapOutPoultryV2 } from './emissionFields';
import { floatValueInDefaultUnit } from '../../Baseline/mapper/floatHelperFunctions';
import { dashToCamelCaseString } from '../../../../sustell_15/utils/string-utils';
import {
  AnimalInputType,
} from '../../../../../graphql/generated/blonk/poultry';
import mapOutProcessingStage from './outProcessingMapperSustell';

const hasKeysWithNonEmptyValues = (obj, keys) => {
  if (!obj) return false;
  if (Object.keys(obj).length === 0) return false;
  return !keys.every((key) => !obj[key] && obj[key] !== 0);
};

const initStageData = (stageType) => {
  if (stageType === StageType.Breeding) {
    return {
      resourceUse: {},
      henInput: {},
      roosterInput: {},
      henFeedingProgram: { compoundFeedUses: [], singleIngredientUses: [] },
      roosterFeedingProgram: { compoundFeedUses: [], singleIngredientUses: [] },
      manureManagementSystems: [],
      manureManagementSystemsAdditions: [],
      output: {},
      emissionMitigations: [],
    };
  }
  if (stageType !== StageType.Hatching) {
    return {
      resourceUse: {},
      input: {},
      feedingProgram: { compoundFeedUses: [], singleIngredientUses: [] },
      manureManagementSystems: [],
      manureManagementSystemsAdditions: [],
      output: {},
      emissionMitigations: [],
    };
  }
  return { resourceUse: {} };
};

const mapInternalSource = (
  id,
  internalSource,
  userUOM,
  kgUnitsDefault = true
) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  const result = {
    id: id || uuidv4()
  };
  if (internalSource && internalSource.numberAnimals)
    setInterventionFloatValue(
      result,
      'number',
      internalSource,
      'numberAnimals'
    );
  if (internalSource && internalSource.averageWeight)
    setInterventionFloatValue(
      result,
      'averageWeight',
      internalSource,
      'averageWeight',
      defaultUserUnit,
      defaultUnit
    );
  if (internalSource && internalSource.transportDistance)
    setInterventionFloatValue(
      result,
      'transportDistance',
      internalSource,
      'transportDistance',
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    );
  return result;
};

const mapExternalSource = (
  id,
  externalSource,
  userUOM,
  kgUnitsDefault = true
) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  const result = {
    id: id || uuidv4(),
    origin: 'BACKGROUND_DATA',
    number: externalSource.numberAnimals,
  };
  if (externalSource && externalSource.numberAnimals)
    setInterventionFloatValue(
      result,
      'number',
      externalSource,
      'numberAnimals'
    );
  if (externalSource && externalSource.averageWeight)
    setInterventionFloatValue(
      result,
      'averageWeight',
      externalSource,
      'averageWeight',
      defaultUserUnit,
      defaultUnit
    );
  if (externalSource && externalSource.transportDistance)
    setInterventionFloatValue(
      result,
      'transportDistance',
      externalSource,
      'transportDistance',
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    );
  return result;
};

const mapInputForOneStageBreeding = (inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.henInput = {};
  stageData.roosterInput = {};

  stageData.henInput.animals = [];
  stageData.roosterInput.animals = [];
  const keysToCheck = ['numberAnimals', 'transportDistance'];
  const henInternalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.henInternalSource,
    keysToCheck
  );
  const henExternalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.henExternalSource,
    keysToCheck
  );
  const roosterInternalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.roosterInternalSource,
    keysToCheck
  );
  const roosterExternalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.roosterExternalSource,
    keysToCheck
  );
  if (inputStage?.henInternalSource && henInternalSourceChanged) {
    stageData.henInput.animalType = 'BREEDING_PULLET';
    setInterventionFloatValue(
      stageData.henInput,
      'averageWeight',
      inputStage.henInternalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.henInput.animals.push(
      mapInternalSource(inputStage.id, inputStage.henInternalSource, userUOM)
    );
  } else if (inputStage?.henExternalSource && henExternalSourceChanged) {
    stageData.henInput.animalType = 'BREEDING_PULLET';
    setInterventionFloatValue(
      stageData.henInput,
      'averageWeight',
      inputStage.henExternalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.henInput.animals.push(
      mapExternalSource(inputStage.id, inputStage.henExternalSource, userUOM)
    );
  }

  if (inputStage?.roosterInternalSource && roosterInternalSourceChanged) {
    stageData.roosterInput.animalType = 'BREEDING_PULLET';
    setInterventionFloatValue(
      stageData.roosterInput,
      'averageWeight',
      inputStage.roosterInternalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.roosterInput.animals.push(
      mapInternalSource(
        inputStage.id,
        inputStage.roosterInternalSource,
        userUOM
      )
    );
  } else if (
    inputStage?.roosterExternalSource &&
    roosterExternalSourceChanged
  ) {
    stageData.roosterInput.animalType = 'BREEDING_PULLET';
    setInterventionFloatValue(
      stageData.roosterInput,
      'averageWeight',
      inputStage.roosterExternalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.roosterInput.animals.push(
      mapExternalSource(
        inputStage.id,
        inputStage.roosterExternalSource,
        userUOM
      )
    );
  }
};

const mapInputForOneStageGrowing = (inputStage, oneStageData, animalType) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  const internalSources = inputStage?.internalSources || [];
  const isGrowingStageInternalSource = internalSources.some(
    (source) => source?.stageType === StageType.Growing
  );

  stageData.input = {};
  stageData.input.animals = [];
  // If a growing stage is used as internal source for another growing stage
  // the input animal type should be set as the grown animal
  if (animalType === AnimalType.Turkey) {
    stageData.input.animalType = isGrowingStageInternalSource
      ? AnimalInputType.BroilerTurkey
      : AnimalInputType.DayOldTurkey;
  } else {
    stageData.input.animalType = isGrowingStageInternalSource
      ? AnimalInputType.Broiler
      : AnimalInputType.DayOldChick;
  }
  // stageData.input.animalType = 'DAY_OLD_CHICK';

  let totalAverageWeight = 0;
  let numberOfAnimals = 0;

  let internalSourceChanged = false;
  let externalSourceChanged = false;
  const keysToCheck = ['numberAnimals', 'transportDistance'];
  inputStage?.internalSources?.forEach((internalSource) => {
    const mappedInternalSource = mapInternalSource(
      inputStage.id,
      internalSource,
      userUOM
    );
    internalSourceChanged = hasKeysWithNonEmptyValues(internalSource, keysToCheck);
    numberOfAnimals += mappedInternalSource.numberAnimals;
    totalAverageWeight +=
      mappedInternalSource.averageWeight * mappedInternalSource.numberAnimals;
    if (internalSourceChanged) {
      stageData.input.animals.push(mappedInternalSource);
    }
  });
  inputStage?.externalSources?.forEach((externalSource) => {
    const mappedExternalSource = mapExternalSource(
      inputStage.id,
      externalSource,
      userUOM
    );
    externalSourceChanged = hasKeysWithNonEmptyValues(externalSource, keysToCheck);
    numberOfAnimals += mappedExternalSource.numberAnimals;
    totalAverageWeight +=
      mappedExternalSource.averageWeight * mappedExternalSource.numberAnimals;
    if (externalSourceChanged) {
      stageData.input.animals.push(mappedExternalSource);
    }
  });
  if (totalAverageWeight && numberOfAnimals && (internalSourceChanged || externalSourceChanged))
    stageData.input.averageWeight = {
      value: totalAverageWeight / numberOfAnimals,
      change_type: 'set',
    };
};

const mapInputForOneStageLaying = (inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.input = {};
  stageData.input.animals = [];
  const keysToCheck = ['numberAnimals', 'transportDistance'];
  const internalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.internalSource,
    keysToCheck
  );
  const externalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.externalSource,
    keysToCheck
  );
  if (inputStage?.internalSource && internalSourceChanged) {
    stageData.input.animalType = 'LAYING_PULLET';
    setInterventionFloatValue(
      stageData.input,
      'averageWeight',
      inputStage.internalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.input.animals.push(
      mapInternalSource(inputStage.id, inputStage.internalSource, userUOM)
    );
  } else if (inputStage?.externalSource && externalSourceChanged) {
    stageData.input.animalType = 'LAYING_PULLET';
    setInterventionFloatValue(
      stageData.input,
      'averageWeight',
      inputStage.externalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.input.animals.push(
      mapExternalSource(inputStage.id, inputStage.externalSource, userUOM)
    );
  }
};

const mapInputForOneStageHatching = (inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.input = {};
  stageData.input.animals = [];
  const keysToCheck = ['numberAnimals', 'transportDistance'];
  const internalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.internalSource,
    keysToCheck
  );
  const externalSourceChanged = hasKeysWithNonEmptyValues(
    inputStage?.externalSource,
    keysToCheck
  );
  if (inputStage?.internalSource && internalSourceChanged) {
    stageData.input.animalType = 'HATCHING_EGG';
    setInterventionFloatValue(
      stageData.input,
      'averageWeight',
      inputStage.internalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.input.animals.push(
      mapInternalSource(
        inputStage.id,
        inputStage.internalSource,
        userUOM,
        false
      )
    );
  } else if (inputStage?.externalSource && externalSourceChanged) {
    stageData.input.animalType = 'HATCHING_EGG';
    setInterventionFloatValue(
      stageData.input,
      'averageWeight',
      inputStage.externalSource,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
    stageData.input.animals.push(
      mapExternalSource(
        inputStage.id,
        inputStage.externalSource,
        userUOM,
        false
      )
    );
  }
};

const mapInputForOneStage = (stageType, inputStage, oneStageData, animalType) => {
  if (stageType === StageType.Breeding)
    mapInputForOneStageBreeding(inputStage, oneStageData);
  else if (stageType === StageType.Growing)
    mapInputForOneStageGrowing(inputStage, oneStageData, animalType);
  else if (stageType === StageType.Laying)
    mapInputForOneStageLaying(inputStage, oneStageData);
  else mapInputForOneStageHatching(inputStage, oneStageData);
};

const mapStockChangeForOneStage = (stageType, inputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  if (stageType === StageType.Growing || stageType === StageType.Hatching) {
    stageData.stockChange = {};
    if (
      inputStage?.durationOfProductionCycle ||
      inputStage?.durationOfProductionCycle === 0
    )
      setInterventionFloatValue(
        stageData.stockChange,
        'averageProductionRoundLength',
        inputStage,
        'durationOfProductionCycle'
      );
    if (inputStage?.emptyPeriod || inputStage?.emptyPeriod === 0)
      setInterventionFloatValue(
        stageData.stockChange,
        'totalEmptyDays',
        inputStage,
        'emptyPeriod'
      );
    if (inputStage?.eggsPresentAtStart || inputStage?.eggsPresentAtStart === 0)
      setInterventionFloatValue(
        stageData.stockChange,
        'initialAnimalStock',
        inputStage,
        'eggsPresentAtStart'
      );
    if (inputStage?.eggsPresentAtEnd || inputStage?.eggsPresentAtEnd === 0)
      setInterventionFloatValue(
        stageData.stockChange,
        'finalAnimalStock',
        inputStage,
        'eggsPresentAtStart'
      );
    if (
      inputStage?.animalsPresentAtStart ||
      inputStage?.animalsPresentAtStart === 0
    )
      setInterventionFloatValue(
        stageData.stockChange,
        'initialAnimalStock',
        inputStage,
        'animalsPresentAtStart'
      );
    if (inputStage?.animalsPresentAtEnd || inputStage?.animalsPresentAtEnd === 0)
      setInterventionFloatValue(
        stageData.stockChange,
        'finalAnimalStock',
        inputStage,
        'animalsPresentAtEnd'
      );
    if (
      inputStage?.averageWeightAtStart ||
      inputStage?.averageWeightAtStart === 0
    )
      setInterventionFloatValue(
        stageData.stockChange,
        'initialAnimalAverageWeight',
        inputStage,
        'averageWeightAtStart',
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
    if (inputStage?.averageWeightAtEnd || inputStage?.averageWeightAtEnd === 0)
      setInterventionFloatValue(
        stageData.stockChange,
        'finalAnimalAverageWeight',
        inputStage,
        'averageWeightAtEnd',
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
  }
};

const mapRationForOneStage = (feedFormData, stageFeedData) => {
  mapCompoundFeedForOneStage(feedFormData, stageFeedData);
  mapSingleIngridientsForOneStage(feedFormData, stageFeedData);
};

const mapFeedForOneStage = (stageType, feedStage, oneStageData) => {
  if (stageType === StageType.Breeding && feedStage?.henFeed) {
    mapRationForOneStage(
      feedStage.henFeed,
      oneStageData.stageData.henFeedingProgram
    );
  }
  if (stageType === StageType.Breeding && feedStage?.roosterFeed) {
    mapRationForOneStage(
      feedStage.roosterFeed,
      oneStageData.stageData.roosterFeedingProgram
    );
  }
  if (stageType !== StageType.Breeding && feedStage) {
    mapRationForOneStage(feedStage, oneStageData.stageData.feedingProgram);
  }
};

const mapHousingForOneStage = (housingStage, oneStageData) => {
  const { stageData } = oneStageData;
  if (housingStage) {
    if (stageData.housingType !== stageData.housingTypeOld) {
      stageData.housingType = housingStage.housingType;
    }
    if (stageData.housingType === 'FREE_RANGE') {
      stageData.freeRangeTimePercent = {
        value: housingStage.timeInFreeRange,
        change_type: 'set',
      };
    }
  }
};

const mapManureManagemenetSystems = (housingStage, oneStageData) => {
  const { stageData } = oneStageData;
  stageData.manureManagementSystems = [];
  if (housingStage && 'manureSystems' in housingStage) {
    housingStage.manureSystems.forEach((item) => { 
      const outputItem = {
        id: item.id || uuidv4(),
      };
      if (item.mmsType_old !== item.mmsType) {
        outputItem.mmsType = item.mmsHoldingDuration && item.mmsHoldingDuration !== ''
          ? item.mmsHoldingDuration
          : item.mmsType
      }
      if (item.share || item.share === 0) {
        setInterventionFloatValue(outputItem, 'share', item, 'share');
      }
      stageData.manureManagementSystems.push(outputItem);
    });
  }
};

const mapManureManagemenetSystemsAdditions = (housingStage, oneStageData) => {
  const { stageData } = oneStageData;
  stageData.manureManagementSystemsAdditions = [];
  if (housingStage && 'manureSystemsAdditions' in housingStage) {
    housingStage.manureSystemsAdditions.forEach((item) => {
      const outputItem = {
        id: item.id || uuidv4(),
        share:
          (item.share || item.share === 0) && item.share !== ''
            ? item.share
            : 100,
        mmsType:
          item.mmsHoldingDuration && item.mmsHoldingDuration !== ''
            ? item.mmsHoldingDuration
            : item.mmsType,
      };
      stageData.manureManagementSystemsAdditions.push(outputItem);
    });
  }
};

const mapBeddingForOneStage = (housingStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.beddingUse = {};
  housingStage?.beddingSystems?.forEach((item) => {
    if (
      item.beddingType &&
      item.beddingAmount &&
      !Number.isNaN(item.beddingAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.beddingType)}Use`;
      setInterventionFloatValue(
        stageData.beddingUse,
        `${propName}`,
        item,
        'beddingAmount',
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
    }
  });
};

const mapBeddingAdditionsForOneStage = (housingStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.beddingUseAdditions = {};
  housingStage?.beddingSystemsAdditions?.forEach((item) => {
    if (
      item.beddingType &&
      item.beddingAmount &&
      !Number.isNaN(item.beddingAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.beddingType)}Use`;
      const currValue = stageData.beddingUseAdditions[`${propName}`] || 0;
      const value = floatValueInDefaultUnit(
        Number(item.beddingAmount),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      stageData.beddingUseAdditions[`${propName}`] = currValue + value;
    }
  });
};

const mapOutputForOneStageBreeding = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  if (outputStage.totalEggsToStage || outputStage.totalEggsToStage === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalToStage',
      outputStage,
      'totalEggsToStage'
    );
  if (outputStage.averageWeight || outputStage.averageWeight === 0)
    setInterventionFloatValue(
      stageData.output,
      'averageWeight',
      outputStage,
      'averageWeight',
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (outputStage.totalEggsSold || outputStage.totalEggsSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalSold',
      outputStage,
      'totalEggsSold'
    );
  if (outputStage.priceEggs || outputStage.priceEggs === 0)
    setInterventionFloatValue(
      stageData.output,
      'price',
      outputStage,
      'priceEggs'
    );
  if (outputStage.totalHensSold || outputStage.totalHensSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenSold',
      outputStage,
      'totalHensSold'
    );
  if (outputStage.averageHensWeight || outputStage.averageHensWeight === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenAverageWeight',
      outputStage,
      'averageHensWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (
    outputStage.mortalityHensAtPercent ||
    outputStage.mortalityHensAtPercent === 0
  )
    setInterventionFloatValue(
      stageData.output,
      'spentHenMortalityPercent',
      outputStage,
      'mortalityHensAtPercent'
    );
  if (
    outputStage.weightHensAtMortality ||
    outputStage.weightHensAtMortality === 0
  )
    setInterventionFloatValue(
      stageData.output,
      'spentHenWeightAtMortality',
      outputStage,
      'weightHensAtMortality',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.totalRoostersSold || outputStage.totalRoostersSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentRoosterSold',
      outputStage,
      'totalRoostersSold'
    );
  if (
    outputStage.averageRoostersWeight ||
    outputStage.averageRoostersWeight === 0
  )
    setInterventionFloatValue(
      stageData.output,
      'spentRoosterAverageWeight',
      outputStage,
      'averageRoostersWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (
    outputStage.mortalityRoostersAtPercent ||
    outputStage.mortalityRoostersAtPercent === 0
  )
    setInterventionFloatValue(
      stageData.output,
      'spentRoosterMortalityPercent',
      outputStage,
      'mortalityRoostersAtPercent'
    );
  if (
    outputStage.weightRoostersAtMortality ||
    outputStage.weightRoostersAtMortality === 0
  )
    setInterventionFloatValue(
      stageData.output,
      'spentRoosterWeightAtMortality',
      outputStage,
      'weightRoostersAtMortality',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.price || outputStage.price === 0) {
    setInterventionFloatValue(
      stageData.output,
      'spentHenPrice',
      outputStage,
      'price'
    );
    setInterventionFloatValue(
      stageData.output,
      'spentRoosterPrice',
      outputStage,
      'price'
    );
  }
};

const mapOutputForOneStageGrowing = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  if (outputStage.totalToStage || outputStage.totalToStage === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalToStage',
      outputStage,
      'totalToStage'
    );
  if (outputStage.averageWeight || outputStage.averageWeight === 0)
    setInterventionFloatValue(
      stageData.output,
      'averageWeight',
      outputStage,
      'averageWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.totalSold || outputStage.totalSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalSold',
      outputStage,
      'totalSold'
    );
  if (outputStage.mortalityAtPercent || outputStage.mortalityAtPercent === 0)
    setInterventionFloatValue(
      stageData.output,
      'mortalityPercent',
      outputStage,
      'mortalityAtPercent'
    );
  if (outputStage.weightAtMortality || outputStage.weightAtMortality === 0)
    setInterventionFloatValue(
      stageData.output,
      'weightAtMortality',
      outputStage,
      'weightAtMortality',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStageLaying = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  if (outputStage.totalSold || outputStage.totalSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalSold',
      outputStage,
      'totalSold'
    );
  if (outputStage.averageWeight || outputStage.averageWeight === 0)
    setInterventionFloatValue(
      stageData.output,
      'averageWeight',
      outputStage,
      'averageWeight',
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (outputStage.priceEggs || outputStage.priceEggs === 0)
    setInterventionFloatValue(
      stageData.output,
      'price',
      outputStage,
      'priceEggs'
    );
  if (outputStage.totalHensToStage || outputStage.totalHensToStage === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenSold',
      outputStage,
      'totalHensToStage'
    );
  if (outputStage.averageHensWeight || outputStage.averageHensWeight === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenAverageWeight',
      outputStage,
      'averageHensWeight',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.priceHens || outputStage.priceHens === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenPrice',
      outputStage,
      'priceHens'
    );
  if (outputStage.mortalityAtPercent || outputStage.mortalityAtPercent === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenMortalityPercent',
      outputStage,
      'mortalityAtPercent'
    );
  if (outputStage.weightAtMortality || outputStage.weightAtMortality === 0)
    setInterventionFloatValue(
      stageData.output,
      'spentHenWeightAtMortality',
      outputStage,
      'weightAtMortality',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStageHatching = (outputStage, oneStageData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = oneStageData;
  stageData.output = {};
  if (outputStage.totalToStage || outputStage.totalToStage === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalToStage',
      outputStage,
      'totalToStage'
    );
  if (outputStage.totalSold || outputStage.totalSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalSold',
      outputStage,
      'totalSold'
    );
  if (outputStage.averageWeight || outputStage.averageWeight === 0)
    setInterventionFloatValue(
      stageData.output,
      'averageWeight',
      outputStage,
      'averageWeight',
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (outputStage.price || outputStage.price === 0)
    setInterventionFloatValue(stageData.output, 'price', outputStage, 'price');
  if (outputStage.lossRate || outputStage.lossRate === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalWeightLosses',
      outputStage,
      'lossRate',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (outputStage.priceOfLossesSold || outputStage.priceOfLossesSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'lossesPrice',
      outputStage,
      'priceOfLossesSold'
    );
  if (outputStage.totalEggShellsSold || outputStage.totalEggShellsSold === 0)
    setInterventionFloatValue(
      stageData.output,
      'totalWeightEggShellSoldOrWaste',
      outputStage,
      'totalEggShellsSold',
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStage = (stageType, outputStage, oneStageData) => {
  if (stageType === StageType.Breeding)
    mapOutputForOneStageBreeding(outputStage, oneStageData);
  else if (stageType === StageType.Growing)
    mapOutputForOneStageGrowing(outputStage, oneStageData);
  else if (stageType === StageType.Laying)
    mapOutputForOneStageLaying(outputStage, oneStageData);
  else mapOutputForOneStageHatching(outputStage, oneStageData);
};

const mapEmissionForOneStage = (stageDataFormEmission, stageDataEmission) => {
  // check the emission fields
  emissionFieldsMapOutPoultryV2.forEach((value, key) => {
    if (
      key in stageDataFormEmission &&
      (stageDataFormEmission[key] || stageDataFormEmission[key] === 0)
    ) {
      // originally the change_type was directly set to percentage to send to blonk
      // now emisssion interventions also has set, absolute, and relative option
      const valueEmission = parseFloat(stageDataFormEmission[key]);
      const changeType = stageDataFormEmission[`${key}_changeMetric`];
      const emissionObj = {
        emissionType: value,
        change: {
          value: valueEmission,
          change_type: changeType || 'percentage',
        },
      };
      stageDataEmission.push(emissionObj);
    }
  });
};

const mapPoultryInterventionDataOutSustell = (formData, animalType) => {
  let payloadObj = createCorePayload(formData);

  if (formData.stages && Array.isArray(formData.stages)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formData.stages.length; i++) {
      const generalDataForStage = formData.stages[i];
      if (generalDataForStage.type === StageType.Processing) {
        const oneStageData = mapOutProcessingStage(generalDataForStage);
        payloadObj.stages.push(oneStageData);
      } else {
        const formDataForStage = generalDataForStage.stageData || null;
        const oneStageData = {
          id: generalDataForStage?.id || uuidv4(),
          name: generalDataForStage?.name,
          type: generalDataForStage?.type,
          startDate: formDataForStage?.input?.startDate,
          endDate: formDataForStage?.input?.endDate,
          stageData: { ...initStageData(generalDataForStage.type) }, // Init different structure for breeding stage
        };
        if (formDataForStage) {
          // Mapping inputs
          mapInputForOneStage(
            generalDataForStage.type,
            formDataForStage.input,
            oneStageData,
            animalType
          );
          // Mapping stock changes
          mapStockChangeForOneStage(
            generalDataForStage.type,
            formDataForStage.input,
            oneStageData
          );
          // Mapping feeds
          if (generalDataForStage.type !== StageType.Hatching)
            mapFeedForOneStage(
              generalDataForStage.type,
              formDataForStage.feed,
              oneStageData
            );
          // Map resource use on stage level
          if (
            formDataForStage.housing &&
            'resourceUse' in formDataForStage.housing
          )
            mapResourceUseSustell(
              formDataForStage.housing,
              oneStageData.stageData
            );
          if (generalDataForStage.type !== StageType.Hatching)
            mapHousingForOneStage(formDataForStage.housing, oneStageData);
          // Mapping Manure Management Systems
          if (generalDataForStage.type !== StageType.Hatching) {
            mapManureManagemenetSystems(formDataForStage.housing, oneStageData);
            mapManureManagemenetSystemsAdditions(
              formDataForStage.housing,
              oneStageData
            );
          }
          // Mapping Bedding types
          if (generalDataForStage.type !== StageType.Hatching) {
            mapBeddingForOneStage(formDataForStage.housing, oneStageData);
            mapBeddingAdditionsForOneStage(
              formDataForStage.housing,
              oneStageData
            );
          }
          // Mapping outputs
          if ('output' in formDataForStage) {
            mapOutputForOneStage(
              generalDataForStage.type,
              formDataForStage.output,
              oneStageData
            );
          }
          // Mapping emissions
          if (
            'emissions' in formDataForStage &&
            generalDataForStage.type !== StageType.Hatching
          ) {
            oneStageData.stageData.emissionMitigations = [];
            if (formDataForStage.emissions)
              mapEmissionForOneStage(
                formDataForStage.emissions,
                oneStageData.stageData.emissionMitigations
              );
          }
          payloadObj.stages.push(oneStageData);
        }
      }
    }
  }
  payloadObj = mapResourceUseSustell(formData, payloadObj);
  payloadObj.stages.forEach((item) => {
    const { stageData } = item;
    // eslint-disable-next-line no-param-reassign
    item.stageData = JSON.stringify(stageData);
  });
  return payloadObj;
};

export default mapPoultryInterventionDataOutSustell;
