import { CalculatedImpactDataCategory, CalculatedImpactDataCategoryComponent } from "./internal";

const OMITTED_CATEGORIES = [
  'Human toxicity, cancer - organics',
  'Ecotoxicity, freshwater - organics',
  'Human toxicity, cancer - inorganics',
  'Human toxicity, non-cancer - inorganics',
  'Human toxicity, non-cancer - organics'
]
/**
 * 
 * @param calculatedImpact string value of footprint calculated impacts
 * @returns caalcImpact categories with applied logic for merging categories that contain multiple parts (case with CF V2 footprints)
 */
// eslint-disable-next-line import/prefer-default-export
export const convertAndMergeImpactCategories = (calculatedImpact: string) => {
  const impacts = JSON.parse(calculatedImpact || '{}') as {
    [key: string]: CalculatedImpactDataCategory;
  };
  const retVal: { [key: string]: CalculatedImpactDataCategory } = {};
  // Isolate all keys that contains  parts
  const uniqueKeysWithoutPart = new Set(
    Object.keys(impacts)
    .filter(key => key.match(' - part \\d') || key.match(' - p.\\d'))
    .map(key => key.replace(/ - part \d/i, '').replace(/ - p.\d/i, ''))
  );
  uniqueKeysWithoutPart.forEach(k => {
    if (OMITTED_CATEGORIES.includes(k)) {
      return;
    }
    let value = 0;
    let unit = '';
    const components: CalculatedImpactDataCategoryComponent[] = [];
    Object.keys(impacts)
      .filter(key => key.startsWith(`${k  } - part`))
      .forEach(k2 => {
        if (!unit) {
          unit = impacts[k2].impact.unit;
        }
        value += impacts[k2].impact.value;
        impacts[k2].components.forEach(comp => {
          const existingComp = components.find(c1 => c1.lookupKey === comp.lookupKey);
          if (existingComp) {
            // existingComp.impact += comp.impact;
            existingComp.impactAbsolute += comp.impactAbsolute;
          } else {
            components.push(comp);
          }
        })
     });
     // Components impacts represents % values of whole impact, divide by number of categories merged
     // to get real percentages of each category impact
     Object.keys(retVal).forEach(key => {
      const sumAbsoluteImpacts = retVal[key].components
        .reduce((accumulator, object) => accumulator + object.impactAbsolute, 0);
      retVal[key].components.forEach(comp => {
        const impact = (100 * comp.impactAbsolute) / sumAbsoluteImpacts;
      // eslint-disable-next-line no-return-assign, no-param-reassign
        comp.impact = impact;
      });
     })

     retVal[k] = {
      impact :{
        unit,
        value,
      },
      components
     }
  });
  Object.keys(impacts).forEach(k => {
    if ([...uniqueKeysWithoutPart].some(uni => k.startsWith(uni))) {
      return;
    }
    if (OMITTED_CATEGORIES.some(omitKey => k.includes(omitKey))) {
      return;
    }
    retVal[k] = impacts[k];
  })
  return retVal;
}