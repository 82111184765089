import { useIntl as useOriginalIntl, IntlShape, MessageDescriptor, createIntl, createIntlCache } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import { allMessages } from './I18nProvider';

const DEFAULT_LANGUAGE='en';

// eslint-disable-next-line import/prefer-default-export 
export const useIntl = (): IntlShape => {
    const intl = useOriginalIntl();
    const currLang = intl.locale;
    const cache = createIntlCache();
    const defaultIntl = createIntl(
      {
        locale: DEFAULT_LANGUAGE,
        messages: allMessages[DEFAULT_LANGUAGE] as Record<string, string>,
      },
      cache
    );
    
    const currentLanguage:Record<string, string> = (allMessages[currLang as keyof typeof allMessages] || {}) as Record<string, string>;

    // Format message with fallback to default language
    const formatMessage = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType | React.ReactElement | any>): string => {
      const messageId = descriptor.id;
      if( (messageId && currentLanguage[messageId]) || descriptor.defaultMessage )
        return intl.formatMessage(descriptor, values);
      
        // current language does not contain translation for specific string
      return defaultIntl.formatMessage(descriptor, values)
    };

    return { ...intl, formatMessage };
};