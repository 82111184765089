/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { v4 as uuidv4 } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  DsmButton,
  DsmFieldset,
  DsmGrid,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, ListEntry } from '../../common';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import {
  PoultryHatchingStageData,
  PoultryIntervention,
} from '../../../../models/Intervention/PoultryIntervention';
import { InterventionResourceUseV2 } from '../../../../models/Intervention';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
  BeddingSystem,
  BreedingManureManagementSystemType,
  GrowingManureManagementSystemType,
  LayingManureManagementSystemType,
  ManureManagementSystem,
} from '../../../../models/Baseline/PoultryBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import {
  BeddingType,
  BreedingHousingType,
  GrowingHousingType,
  LayingHousingType,
} from '../../../../../../graphql/generated/blonk/poultry';
import { resolvePath } from '../../../../../modules/Helpers/resolvePathFn';
import {
  capitalizeString,
  dashToSpacesString,
  getBaseTypeForMonthEndingMMS,
} from '../../../../utils/string-utils';
import InterventionResources from '../CommonDataParts/InterventionResources';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import PoultryInterventionMMSBlockComponent from './PoultryInterventionMMSBlockComponent';
import PoultryMMSBlockComponent from '../../Baseline/Poultry/PoultryMMSBlockComponent';
import { getSingleEnumEntryTranslation } from '../../../../utils/translation-utils';
import PoultryMMSDescriptionDialog from '../../Baseline/Poultry/PoultryMMSDescriptionDialog';

export interface PoultryHousingHatchingDialogProps extends BaselineDialogProps {
  animalType: AnimalType;
  stageType: StageType;
}

const PoultryHousingAndManureDialog: FC<PoultryHousingHatchingDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
  animalType,
  baseline,
  stageType,
}) => {
  const intl = useIntl();
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const classes = processAndStageStylesV2() as CSSClassesList;
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision =
    userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const currResetValue = useRef<InterventionResourceUseV2>();
  const formTitle = intl.formatMessage(
    { id: 'SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE' },
    { br: ' ' }
  );
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const [, forceUpdate] = useState({});
  const fc = useFormContext();
  const [housingInFreeRange, setHousingInFreeRange] = useState<boolean>(false);
  const [activeList, setActiveList] = useState<Array<ListEntry>>([]);
  const [baselineMMS] = useState(
    baseline?.stages?.[itemIndex]?.stageData?.housing
      ?.manureSystems as ManureManagementSystem[]
  );
  const beddingSystemsError: FieldError | null = resolvePath(
    fc.errors,
    `${fieldItemPrefix}.beddingSystemAdditions`,
    null
  ) as FieldError | null;
  const {
    fields: mmsSystemsAdditions,
    append: appendMMSAdditions,
    remove: removeMMSAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.manureSystemsAdditions`,
    control: fc.control,
    keyName: 'keyId',
  });
  const {
    fields: beddingSystemsAdditions,
    append: appendBeddingAdditions,
    remove: removeBeddingAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.beddingSystemsAdditions`,
    control: fc.control,
    keyName: 'keyId',
  });

  const getHousingTypeLabel = (housingType: string) =>
    capitalizeString(dashToSpacesString(housingType));

  const getHousingType = () => {
    switch (stageType) {
      case StageType.Growing:
        return GrowingHousingType;
      case StageType.Laying:
        return LayingHousingType;
      case StageType.Breeding:
        return BreedingHousingType;
      default:
        return [];
    }
  };

  const calculateTimeInHousing = (initial = false) => {
    let timeInFreeRange = 0;
    if (initial)
      timeInFreeRange =
        baseline?.stages?.[itemIndex]?.stageData?.housing?.timeInFreeRange;
    else
      timeInFreeRange = parseInt(
        fc.getValues(`${fieldItemPrefix}.timeInFreeRange`),
        10
      );
    if (
      !Number.isNaN(timeInFreeRange) &&
      timeInFreeRange >= 0 &&
      timeInFreeRange <= 100
    ) {
      fc.setValue(`${fieldItemPrefix}.timeInFreeRange`, timeInFreeRange);
      fc.setValue(`${fieldItemPrefix}.timeInHousing`, 100 - timeInFreeRange);
    } else fc.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
  };

  const changeHousingType = (e: ReactChangedType) => {
    setHousingInFreeRange(e.target.value === 'FREE_RANGE');
    if (e.target.value !== 'FREE_RANGE') {
      fc.setValue(`${fieldItemPrefix}.timeInFreeRange`, '');
      fc.setValue(`${fieldItemPrefix}.timeInHousing`, '');
    } else {
      fc.setValue(`${fieldItemPrefix}.timeInFreeRange`, 100);
      fc.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
    }
    forceUpdate({});
  };

  const setShare = () => {
    const curentMMS =
      fc.getValues(`stages.${itemIndex}.stageData.housing.manureSystems`) || [];
    const additionMMS =
      fc.getValues(
        `stages.${itemIndex}.stageData.housing.manureSystemsAdditions`
      ) || [];
    // if only one, set share to 100
    if (!curentMMS?.length && additionMMS?.length === 1)
      fc.setValue(
        `stages.${itemIndex}.stageData.housing.manureSystemsAdditions.0.share`,
        100
      );
  };

  const removeMMSHandler = (index: number) => {
    removeMMSAdditions(index);
    setShare();
    (async () => fc.trigger(fieldItemPrefix))()
      .then(() => {})
      .catch(() => {});
  };

  const mmsChanged = () => {
    (async () => fc.trigger(fieldItemPrefix))()
      .then(() => {})
      .catch(() => {});
  };

  const getManureManagementSystemTypes = () => {
    switch (stageType) {
      case StageType.Growing:
        return GrowingManureManagementSystemType;
      case StageType.Laying:
        return LayingManureManagementSystemType;
      case StageType.Breeding:
        return BreedingManureManagementSystemType;
      default:
        return [];
    }
  };

  const mms = enumToOptionsArrayWithTranslatedStrings(
    getManureManagementSystemTypes(),
    intl,
    'SUSTELL.POULTRY.ENUMS.MMS'
  );

  const getBeddingTypeLabel = (beddingType: BeddingType) => {
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      'SUSTELL.POULTRY.ENUMS.BEDDING_TYPE'
    );
    const retVal = beddings.find((bedding) => bedding.value === beddingType);
    if (retVal) return retVal.text;
    return '';
  };

  const beddingsWithEmptyOption = () => {
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      'SUSTELL.POULTRY.ENUMS.BEDDING_TYPE'
    );
    const defaultOption = { text: 'No bedding', value: '' };
    return [defaultOption].concat(beddings);
  };

  const resetBeddingAmount = (index: number) =>
    fc.setValue(
      `${fieldItemPrefix}.beddingSystemsAdditions.${index}.beddingAmount`,
      '',
      { shouldValidate: true }
    );

  const emptyBeddingSelected = (index: number) =>
    !fc.getValues(
      `${fieldItemPrefix}.beddingSystemsAdditions.${index}.beddingType`
    );

  const beddingsChanged = (e: ReactChangedType, index: number) => {
    forceUpdate({});
    if (!e.target.value) resetBeddingAmount(index);
  };

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject?.stages?.[itemIndex]
        ?.stageData as PoultryHatchingStageData;
      if (stageData?.housing) {
        stageData.housing = { ...currResetValue.current };
        fc.reset(resetObject, { errors: true });
      }
    }
    handleCancel();
  };

  const getFilteredList = (entries: ListEntry[]) => {
    const uniqueMMS: ListEntry[] = entries.reduce(
      (accumulator: ListEntry[], current: ListEntry) => {
        if (!accumulator.find((item) => item.value === current.value))
          accumulator.push(current);
        return accumulator;
      },
      []
    );
    return uniqueMMS;
  };

  const filteredMMSList = getFilteredList(
    mms.map((item) => {
      const base = getBaseTypeForMonthEndingMMS(item.value);
      return {
        value: base,
        text:
          base === item.value
            ? item.text
            : getSingleEnumEntryTranslation(
                base,
                intl,
                'SUSTELL.POULTRY.ENUMS.MMS'
              ),
      };
    })
  );

  const showDescription = () => setOpenDescriptionDialog(true);
  const closeDescriptionDialog = () => setOpenDescriptionDialog(false);

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
      if (
        baseline?.stages?.[itemIndex]?.stageData?.housing?.housingType ===
        'FREE_RANGE'
      )
        setHousingInFreeRange(true);
      if (fc.getValues(`${fieldItemPrefix}.housingType`) === 'FREE_RANGE')
        setHousingInFreeRange(true);
      calculateTimeInHousing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  // should only be called once on entry
  useEffect(() => {
    setActiveList(filteredMMSList);
    calculateTimeInHousing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      variant="demiWide"
    >
      {openDescriptionDialog && (
        <PoultryMMSDescriptionDialog
          open
          stageType={
            stageType as
              | StageType.Breeding
              | StageType.Growing
              | StageType.Laying
          }
          handleClose={() => closeDescriptionDialog()}
        />
      )}
      <DsmGrid
        className={classes.additionalEntriesBlockHolder}
        style={{ marginBottom: 'var(--dsm-spacing-px-6)' }}
      >
        <InterventionResources
          formType={formType}
          inputType={`stages.${itemIndex}.stageData.housing.resourceUse`}
          animalType={animalType}
          baseline={baseline}
          stageDataResourceUse={
            baseline.stages[itemIndex].stageData.housing.resourceUse
          }
          isInternventionDialog
        />
      </DsmGrid>
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}
      >
        <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
          <input
            ref={fc.register()}
            type="hidden"
            name={`${fieldItemPrefix}.housingTypeOld`}
            value={
              baseline?.stages?.[itemIndex]?.stageData.housing?.housingType
            }
          />
          <ReactHookDsmSelect
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.MANURE.HOUSING_TYPE',
            })}
            name={`${fieldItemPrefix}.housingType`}
            options={enumToOptionsArrayWithTranslatedStrings(
              getHousingType(),
              intl,
              'SUSTELL.POULTRY.ENUMS.HOUSING_TYPE'
            )}
            changeHandler={(e: ReactChangedType) => changeHousingType(e)}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.HOUSING.HOUSING_TYPE.TOOLTIP',
            })}
            required
            disabled={formType === FormType.View}
            defaultValue={
              baseline?.stages?.[itemIndex]?.stageData?.housing?.housingType
            }
          />
          <span>
            Original value &nbsp;
            <span style={{ fontWeight: 'bold' }}>
              {getHousingTypeLabel(
                baseline?.stages?.[itemIndex]?.stageData?.housing?.housingType
              )}
            </span>
          </span>
        </DsmGrid>
      </DsmGrid>
      {housingInFreeRange && (
        <DsmGrid className={classes.dsmGridTwoColumn}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.timeInFreeRange`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.HOUSING.TIME_IN_FREE_RANGE',
            })}
            changeHandler={() => calculateTimeInHousing()}
            adornment="%"
            disabled={formType === FormType.View}
            required
            type="number"
            defaultValue={
              fc.getValues(`${fieldItemPrefix}.timeInFreeRange`) || 100
            }
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.timeInHousing`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.POULTRY.HOUSING.TIME_IN_HOUSING',
            })}
            adornment="%"
            disabled
            type="number"
            defaultValue={fc.getValues(`${fieldItemPrefix}.timeInHousing`) || 0}
          />
        </DsmGrid>
      )}
      {baselineMMS &&
        baselineMMS?.map((item, index: number) => (
          <PoultryInterventionMMSBlockComponent
            // eslint-disable-next-line react/no-array-index-key
            key={`baselineMMs${index}`}
            filteredMMSList={activeList}
            manureManagementSystems={mms}
            formType={formType}
            stageIndex={itemIndex}
            itemIndex={index}
            manureSystem={item}
          />
        ))}
      {mmsSystemsAdditions &&
        mmsSystemsAdditions?.map((item, index: number) => (
          <PoultryMMSBlockComponent
            key={item.keyId}
            filteredMMSList={activeList}
            manureManagementSystems={mms}
            formType={formType}
            stageIndex={itemIndex}
            itemIndex={index}
            removeHandler={removeMMSHandler}
            mmsChangeHandler={mmsChanged}
            isAdditions
          />
        ))}
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={() =>
            appendMMSAdditions({
              id: uuidv4(),
              mmsType: '',
              mmsHoldingDuration: '',
              share: '',
            })
          }
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.PIGS.HOUSING.ADD_ANOTHER_MMS',
          })}
        </DsmButton>
        <DsmButton variant="text" onClick={() => showDescription()}>
          <DsmIcon slot="before" name="general/eye" />
          {intl.formatMessage({ id: 'DATABASE_FOUNDATION_DEFINITIONS' })}
        </DsmButton>
      </DsmGrid>
      {baseline?.stages?.[itemIndex]?.stageData?.housing?.beddingSystems?.map(
        (item: BeddingSystem, i: number) => (
          <DsmGrid
            style={{ paddingBottom: 'var(--dsm-spacing-px-4' }}
            className={classes.dsmGridOneColumn}
            key={`beddingSystems_${i}`}
          >
            <input
              type="hidden"
              value={item.beddingType}
              ref={fc.register()}
              name={`${fieldItemPrefix}.beddingSystems[${i}].beddingType`}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.beddingSystems[${i}].beddingAmount`}
              label={`${intl.formatMessage({
                id: 'SUSTELL.STAGE.PIGS.HOUSING.BEDDING_TYPE',
              })} - ${getBeddingTypeLabel(item.beddingType)}`}
              metricUnit={barnOutputMassUnit}
              precision={barnOutputMassPrecision}
              baseline={item?.beddingAmount}
              disabled={formType === FormType.View}
            />
          </DsmGrid>
        )
      )}
      <div style={{ paddingTop: 'var(--dsm-spacing-px-4' }}>
        {!!beddingSystemsError && beddingSystemsError.type === 'min' && (
          <DsmGrid className={classes.dsmGridOneColumn}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'GENERAL.ERROR' })}
              </AlertTitle>
              {beddingSystemsError.message}
            </Alert>
          </DsmGrid>
        )}
        {beddingSystemsAdditions &&
          beddingSystemsAdditions?.map((item, index) => (
            <div
              className={classes.additionalEntriesBlockHolder}
              key={item.keyId}
            >
              {removeBeddingAdditions && beddingSystemsAdditions.length && (
                <DsmButton
                  variant="text"
                  style={{ position: 'relative', width: '100%' }}
                  onClick={() => removeBeddingAdditions(index)}
                >
                  <DsmIcon
                    name="general/x-close"
                    style={{
                      position: 'absolute',
                      color: 'var(--dsm-color-neutral-darker',
                      right: '0',
                    }}
                  />
                </DsmButton>
              )}
              <DsmGrid className={classes.dsmGridTwoColumn}>
                <ReactHookDsmSelect
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING_TYPE',
                  })}
                  name={`${fieldItemPrefix}.beddingSystemsAdditions.${index}.beddingType`}
                  options={beddingsWithEmptyOption()}
                  disabled={formType === FormType.View}
                  defaultValue={fc.getValues(
                    `${fieldItemPrefix}.beddingSystemsAdditions.${index}.beddingType`
                  )}
                  changeHandler={(e: ReactChangedType) =>
                    beddingsChanged(e, index)
                  }
                />
                <div>
                  <DsmFieldset>
                    <ReactHookDsmInput
                      label={intl.formatMessage({
                        id: 'SUSTELL.STAGE.POULTRY.HOUSING.BEDDING.AMOUNT',
                      })}
                      name={`${fieldItemPrefix}.beddingSystemsAdditions.${index}.beddingAmount`}
                      adornment={barnOutputMassUnit}
                      type="number"
                      disabled={
                        formType === FormType.View ||
                        emptyBeddingSelected(index)
                      }
                      defaultValue={fc.getValues(
                        `${fieldItemPrefix}.beddingSystemsAdditions.${index}.beddingAmount`
                      )}
                    />
                    <div />
                  </DsmFieldset>
                </div>
              </DsmGrid>
            </div>
          ))}
      </div>
      <DsmGrid
        className={classes.dsmGridTwoColumn}
        style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
      >
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={() =>
            appendBeddingAdditions({ beddingType: '', beddingAmount: '' })
          }
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.STAGE.POULTRY.HOUSING.ADD_ANOTHER_BEDDING',
          })}
        </DsmButton>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await fc.trigger(`stages.${itemIndex}.stageData.housing`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryHousingAndManureDialog;
