/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { type FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import Alert from '@material-ui/lab/Alert';
import {
  DsmGrid,
  DsmButton,
  DsmFieldset,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import { Grid, Box, Typography } from '@material-ui/core';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import useAdobeDataLayer from '../../../../analytics/adobeDataLayer';
import { forbiddenChars } from '../../../../helpers/validations';
import { FormType } from '../../common';
import { Farm, ClimateType } from '../../../../../../graphql/types';
import { createFarm, updateFarm } from '../../../../../../graphql/mutations';
import {
  ApiCallErrors,
  ApiCallErrorMessage,
} from '../../../../commonTypes/apiErrors';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { listEntryDataSorter } from '../../../../helpers/sortingFunctions';
import {
  objectToOptionsArrayWithTranslatedStrings,
  enumToOptionsArrayWithTranslatedStrings,
} from '../../../../utils/obj-utils';
import countryIsoCodes from '../../../dashboard/isoCodes';
import ClimateMapHolder from './ClilmateMapHolder';

interface FarmFormProps {
  formType: FormType;
  farmId?: string;
  farm?: Farm;
  customerId: string;
  farmSubmit: (farm: Farm, addMode: boolean) => void;
  handleClose: () => void;
}

type FarmSendData = {
  id?: string;
  name: string;
  old_name?: string;
  country: string;
  city: string;
  size: number;
  climateType: ClimateType;
};

type FarmResponseData = {
  id: string;
};

type FarmCreateUpdateAPIResponse = {
  data?: {
    createFarm?: FarmResponseData;
    updateFarm?: FarmResponseData;
  };
};

// image isn't in indexed colors, so some deviations are possible,
// for some climate zones several detected colors are matched in map
const colorClimateZoneMapper: Map<string, ClimateType> = new Map([
  ['102,154,206', ClimateType.TropicalMontane],
  ['66,138,113', ClimateType.TropicalWet],
  ['66,138,112', ClimateType.TropicalWet],
  ['69,139,120', ClimateType.TropicalWet],
  ['138,206,102', ClimateType.TropicalMoist],
  ['142,208,107', ClimateType.TropicalMoist],
  ['245,245,123', ClimateType.TropicalDry],
  ['116,224,255', ClimateType.WarmTemperateMoist],
  ['255,212,128', ClimateType.WarmTemperateDry],
  ['206,245,123', ClimateType.CoolTemperateMoist],
  ['195,159,216', ClimateType.CoolTemperateDry],
  ['195,159,216', ClimateType.CoolTemperateDry],
  ['159,172,216', ClimateType.BorealMoist],
  ['159,171,216', ClimateType.BorealMoist],
  ['216,216,159', ClimateType.BorealDry],
]);

const SustellFarmInfoWithMap: FC<FarmFormProps> = ({
  formType = FormType.Add,
  farm,
  customerId,
  farmSubmit,
  handleClose,
}) => {
  const intl = useIntl();
  const [mapOpen, setMapOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { formErrorsEvent, formSubmissionEvent } = useAdobeDataLayer();

  const methods = useForm<Farm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
          .min(
            3,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_LENGTH' },
              { count: 3 }
            )
          )
          // prevent use of quotation marks in the name
          .concat(
            forbiddenChars(
              intl,
              ['"', "'"],
              'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
            )
          ),
        country: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        climateType: Yup.string()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .transform((changed, original) =>
            original === '' ? undefined : changed
          )
          .typeError(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          )
          .required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
      })
    ),
    defaultValues: {
      id: '',
      name: '',
      country: '',
      city: '',
      climateType: undefined,
    },
  });

  useEffect(() => {
    if (farm) {
      methods.reset(farm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  const handleCancel = () => {
    methods.reset({});
    handleClose();
  };

  const onSubmit = (submitData: FarmSendData) => {
    (async () => {
      formSubmissionEvent(
        formType === FormType.Add ? 'Create New Farm' : 'Edit Farm',
        formType
      );
      const inputData: FarmSendData = {
        name: submitData.name,
        city: submitData.city,
        size: farm?.size || 0,
        country: submitData.country,
        climateType: submitData.climateType,
      };

      if (formType === FormType.Edit) {
        inputData.id = farm?.id;
        inputData.old_name = farm?.name;
      }

      const result: FarmCreateUpdateAPIResponse = (await API.graphql({
        query: formType === FormType.Add ? createFarm : updateFarm,
        variables: {
          customerId,
          input: inputData,
        },
      })) as GraphQLResult;
      setErrorMessage('');

      const resultFarmData: FarmResponseData | undefined =
        formType === FormType.Add
          ? result.data?.createFarm
          : result.data?.updateFarm;
      if (resultFarmData) {
        const newFarmData: Farm = {
          id: resultFarmData.id,
          name: inputData.name,
          country: inputData.country,
          city: inputData.city,
          size: inputData.size,
          climateType: inputData.climateType,
          updatedAt: new Date(),
        };
        methods.reset({});
        farmSubmit(newFarmData, formType === FormType.Add);
        handleClose();
      } else {
        setErrorMessage(intl.formatMessage({ id: 'GENERAL.ERROR.SAVING' }));
      }
    })().catch((err: ApiCallErrors) => {
      const apiErrors: ApiCallErrors = err;
      if (apiErrors.errors?.length > 0) {
        const error: ApiCallErrorMessage = apiErrors.errors[0];
        if (error.errorType === 'ValidationError') {
          setErrorMessage(error.message);
        }
      }
    });
  };

  const onErrors = () => {
    setErrorMessage(intl.formatMessage({ id: 'VALIDATION.GENERAL.MESSAGE' }));
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent('Edit Farm', formType, formErrors);
  };

  const openMap = () => setMapOpen(true);

  const setClimateType = (value: ClimateType) => {
    if (value) {
      methods.setValue('climateType', value);
      setMapOpen(false);
    }
  };

  const setZoneInfo = (r: number, g: number, b: number) => {
    const strValue = `${r},${g},${b}`;
    const selClimateZone = colorClimateZoneMapper.get(strValue) ?? undefined;
    return selClimateZone;
  };

  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <DsmGrid
        style={{
          grid: 'auto/1fr auto',
          justifyContent: 'space-between',
          padding: '0',
          alignItems: 'baseline',
          columnGap: 'var(--dsm-spacing-px-4)',
        }}
      >
        {/* <DsmSectionHeader 
          style={{marginTop: `var(--dsm-spacing-px-8) solid`, borderBottom: '0px !important'}}
          header={ intl.formatMessage({id:"SUSTELL.FARM.INFORMATION" })}
          description={ intl.formatMessage({id: "SUSTELL.FARM.INFORMATION.INTRO" })}
        /> */}
        <Box style={{ marginTop: `var(--dsm-spacing-px-8)` }}>
          <Typography
            style={{
              fontSize: '20px',
              lineHeight: '32px',
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage({ id: 'SUSTELL.FARM.INFORMATION' })}
          </Typography>
          <Typography style={{ color: 'var(--dsm-color-neutral-darker)' }}>
            {intl.formatMessage({ id: 'SUSTELL.FARM.INFORMATION.INTRO' })}
          </Typography>
        </Box>
      </DsmGrid>
      {errorMessage && (
        <Grid container spacing={1} style={{ marginBottom: 10 }}>
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'GENERAL.ERROR' })}
              </AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
        </Grid>
      )}
      <FormProvider {...methods}>
        <form>
          <input ref={methods.register()} type="hidden" name="id" />
          <input ref={methods.register()} type="hidden" name="oldName" />
          <input ref={methods.register()} type="hidden" name="size" />
          <DsmFieldset
            style={{ width: '70%', marginTop: `var(--dsm-spacing-px-4)` }}
          >
            <ReactHookDsmInput
              name="name"
              label={intl.formatMessage({ id: 'FARM.NAME' })}
              placeholder={intl.formatMessage({ id: 'FARM.NAME' })}
              disabled={formType === FormType.View}
              required
            />
            <ReactHookDsmInput
              name="city"
              label={intl.formatMessage({
                id: 'GENERAL.LOCATION',
              })}
              placeholder={intl.formatMessage({
                id: 'GENERAL.LOCATION',
              })}
              disabled={formType === FormType.View}
            />
          </DsmFieldset>
          <DsmFieldset
            style={{ width: '70%', marginTop: `var(--dsm-spacing-px-4)` }}
          >
            <ReactHookDsmSelect
              name="country"
              label={intl.formatMessage({
                id: 'GENERAL.COUNTRY',
              })}
              disabled={formType === FormType.View}
              placeholder={intl.formatMessage({ id: 'GENERAL.COUNTRY' })}
              options={objectToOptionsArrayWithTranslatedStrings(
                countryIsoCodes,
                intl,
                'SUSTELL.GEOGRAPHY'
              ).sort(listEntryDataSorter)}
              required
            />
            <ReactHookDsmSelect
              name="climateType"
              label={intl.formatMessage({
                id: 'SUSTELL.CLIMATE_TYPE',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.CLIMATE_TYPE',
              })}
              disabled={formType === FormType.View}
              options={enumToOptionsArrayWithTranslatedStrings(
                ClimateType,
                intl,
                'SUSTELL.GEOGRAPHY.CLIMATE_ZONE'
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset style={{ width: '70%' }}>
            <div />
            <DsmButton
              variant="text"
              onClick={openMap}
              disabled={formType === FormType.View}
            >
              <DsmIcon slot="before" name="maps-travel/map-01" />
              {intl.formatMessage({
                id: 'SUSTELL.GEOGRAPHY.CLIMATE_ZONE.CLICK_MAP',
              })}
            </DsmButton>
          </DsmFieldset>
        </form>
        <ClimateMapHolder
          formVisible={mapOpen}
          handleSave={setClimateType}
          handleClose={() => setMapOpen(false)}
          setZoneInfo={setZoneInfo}
        />
        <Box
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 'var(--dsm-spacing-px-8)',
          }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <DsmButton variant="secondary" destructive={false} onClick={handleCancel}>
            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          </DsmButton>
          <Box
            style={{ display: 'flex', width: '30%' }}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <DsmButton
              style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
              variant="primary"
              onClick={methods.handleSubmit(onSubmit, onErrors)}
            >
              {intl.formatMessage({ id: 'SUSTELL.SAVE_CONTINUE' })}
              <DsmIcon slot="after" name="arrows/chevron-right" />
            </DsmButton>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

SustellFarmInfoWithMap.defaultProps = {};

export default SustellFarmInfoWithMap;
