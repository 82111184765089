import { explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';
const math = require('mathjs');

// value should be string or float
export const floatValueInDefaultUnit = (
  value,
  userUnit,
  defaultUnit,
  precision = 5
) => {
  const floatVal = typeof value === 'string' ? parseFloat(value) : value;
  return userUnit === defaultUnit
    ? floatVal
    : math.round(
        explicitConvertValue(floatVal, userUnit, defaultUnit),
        precision
      );
};

export const setFloatValue = (
  obj,
  propertyName,
  formData,
  formPropertyName,
  userUnit,
  defaultUnit,
  precision = 5
) => {
  if (formData && formPropertyName in formData) {
    const val = parseFloat(formData[formPropertyName]);
    if (!Number.isNaN(val)) {
      if (userUnit && defaultUnit)
        obj[propertyName] = floatValueInDefaultUnit(
          val,
          userUnit,
          defaultUnit,
          precision
        );
      else obj[propertyName] = val;
    }
  }

  return obj;
};
