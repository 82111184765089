// import { multicastChannel } from '@redux-saga/core';
import * as Yup from 'yup';
import { defaultUnitsV2 } from '../../../../sustell_15/utils/unit-utils';
import lookupValues from '../../../../sustell_15/helpers/lookupValues';

import {
  interventionFieldWithMin,
  interventionFieldWithMinMax,
  interventionFieldWithMoreThanMin,
  feedItemsInterventionTest,
  feedAdditionalItemsInterventionTest,
} from './interventionValidationObjectBuilderFunctions';

import {
  numericRequiredWithGreaterThanMin,
  numericOptionalWithGreaterThanMin,
} from '../../Baseline/validationSchema/validationObjectBuilderFunctions';

const sumCheck = (relatedFields, capValue) => {
  let sum = 0;
  let retVal = true;
  if (relatedFields) {
    sum = relatedFields?.reduce((prev, curr) => {
      const prevVal = typeof prev === 'number' ? prev : 0;
      const currVal = typeof curr === 'number' ? curr : 0;
      return prevVal + currVal;
    }, 0);
  }
  if (sum > capValue) retVal = false;
  return retVal;
};

// Yup validation schema for intervention form data

const interventionResourcesSalmon = ({ intl }) =>
  Yup.object({
    electricityUse: interventionFieldWithMin(intl, 'electricityUse', 0),
    waterUse: interventionFieldWithMin(intl, 'waterUse', 0),
  });

const getNutritionalValueSustell = (
  resolve,
  propertyName,
  baseline,
  stageIndex
) => {
  const newValue = resolve(Yup.ref(`${propertyName}_newValue`));
  if (!newValue && newValue !== 0 && baseline?.stages && baseline?.stages.length) {
    const nutrientComposition = baseline?.stages[stageIndex]?.stageData?.feed?.nutrientComposition;
    if (nutrientComposition) return nutrientComposition[propertyName];
  }
  return newValue;
};

const getNutritionalValuesSustell = (
  resolve,
  propertyNames,
  baseline,
  stageIndex
) => {
  const retVal = [];
  propertyNames?.forEach((property) => {
    retVal.push(
      getNutritionalValueSustell(resolve, property, baseline, stageIndex)
    );
  });

  return retVal;
};

const nutritionalFieldPercentValidationSustell = (
  intl,
  propertyName,
  baseline,
  lessThan = false
) =>
  Yup.number()
    .nullable(true)
    .transform((changed, original) =>
      original === '' || original === null ? null : changed
    )
    .when(`${propertyName}_changeMetric`, {
      is: 'set',
      then: (function check() {
        const schema = Yup.number()
        .nullable(true)
        .min(
          0,
          intl.formatMessage(
            { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE' },
            { value: 0 }
          )
        )
        if (lessThan) {
          return schema.lessThan(
            100,
            intl.formatMessage(
              { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MAX_VALUE' },
              { value: 100 }
            )
          )
        } else {
          return schema.max(
            100,
            intl.formatMessage(
              { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.MAX_VALUE' },
              { value: 100 }
            )
          )
        }
    })()})
    .when(`${propertyName}_changeMetric`, {
      is: (val) => val === 'absolute' || val === 'relative',
      then: Yup.number()
        .nullable(true)
        .test('rangeValueTest', '', function (value) {
          const { resolve, path, createError, parent } = this;
          const newVal = resolve(parent[`${propertyName}_newValue`]);
          const condition = lessThan ? (newVal < 0 || newVal >= 100) : (newVal < 0 || newVal > 100);
          if (typeof newVal === 'number' && condition) {
            return createError({
              path: path,
              message: intl.formatMessage(
                { id: 'VALIDATION.INTERVENTION.RESULT.FIELD.RANGE' },
                { min: 0, max: 100 }
              ),
            });
          }
          else return true;
        }),
    })
    .test(
      'sumCheck',
      intl.formatMessage(
        { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
        { max: 100 }
      ),
      function () {
        // properties that sum should not be greater than 100
        const propertyNames = [
          'crudeProtein',
          'crudeLipid',
          'crudeFibre',
          'starch',
          'sugar',
          'phosphorous',
        ];

        // stage index
        const stageIndex = this.from?.[3]?.value?.index;
        // if shoud be validate, do validation
        if (
          propertyNames.includes(propertyName) &&
          (stageIndex || stageIndex === 0 || stageIndex === '0')
        ) {
          const { resolve } = this;
          const values = getNutritionalValuesSustell(
            resolve,
            propertyNames,
            baseline,
            stageIndex
          );
          return sumCheck(values, 100);
        }
        return true;
      }
    )
    .typeError(intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }));

const stageDataPartMarineFish = ({
  intl,
  userUOM = defaultUnitsV2,
  minValueOutput = 1,
  baseline,
}) =>
  Yup.object({
    stages: Yup.array().of(
      Yup.object({
        stageData: Yup.object({
          input: Yup.object({
            numberOfStockedAnimals: interventionFieldWithMoreThanMin(
              intl,
              'numberOfStockedAnimals',
              0
            ),
            weightOfStockedAnimals: interventionFieldWithMoreThanMin(
              intl,
              'weightOfStockedAnimals',
              0
            ),
            averageLiveWeightStockedAnimals: interventionFieldWithMoreThanMin(
              intl,
              'averageLiveWeightStockedAnimals',
              0
            )
              .test('bindedValuesTest', '', function (value) {
                const { resolve, path, parent, createError } = this;

                let averageLiveWeightStockedAnimals = resolve(
                  parent['averageLiveWeightStockedAnimals_newValue']
                );

                if (
                  !averageLiveWeightStockedAnimals &&
                  averageLiveWeightStockedAnimals !== 0
                )
                  averageLiveWeightStockedAnimals =
                    baseline?.averageLiveWeightStockedAnimals;

                const index = this.from?.[2]?.value?.index;
                if (index || index === 0 || index === '0') {
                  let liveWeightOnHarvest =
                    this.from?.[1]?.value?.output?.averageWeight_newValue;
                  if (!liveWeightOnHarvest && liveWeightOnHarvest !== 0)
                    liveWeightOnHarvest =
                      baseline?.stages?.[parseInt(index)]?.stageData?.output
                        ?.averageWeight;
                  if (
                    typeof liveWeightOnHarvest === 'number' &&
                    averageLiveWeightStockedAnimals > liveWeightOnHarvest * 0.1
                  ) {
                    const max = liveWeightOnHarvest * 0.1;
                    return createError({
                      path: path,
                      message: intl.formatMessage(
                        { id: 'VALIDATION.FIELD.MAX_VALUE_OF_AVG_WEIGHT' },
                        { max: max.toFixed(3) }
                      ),
                    });
                  }
                }
                return true;
              })
              .typeError(
                intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' })
              ),
          }).default({}),
          feed: Yup.object({
            antibioticsUse: Yup.array().of(
              Yup.object({
                amount: interventionFieldWithMoreThanMin(intl, 'amount', 0),
              })
            ),
            antibioticsUseAddition: Yup.array()
              .of(
                Yup.object({
                  type: Yup.string()
                    .ensure()
                    .required(
                      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                    ),
                  amount: numericRequiredWithGreaterThanMin(intl, 0),
                })
              )
              .test('unique', '', function (value) {
                const { path, createError } = this;
                const existingType = [];
                const errors = [];
                (value || []).forEach((item, index) => {
                  if (item.type)
                    if (existingType.includes(item.type))
                      errors.push(
                        createError({
                          path: path + `[${index}].type`,
                          message: intl.formatMessage(
                            {
                              id: 'VALIDATION.FIELD.TYPE_VALUE_ALLREADY_SET',
                            },
                            {
                              type: lookupValues.antibiotics.find(
                                (el) => el.value === item.type
                              )?.display_name,
                            }
                          ),
                        })
                      );
                    else existingType.push(item.type);
                });

                if (errors.length > 0)
                  return createError({ message: () => errors });

                return true;
              }),
            totalFeedIntake: numericOptionalWithGreaterThanMin(intl, 0),
            compoundFeeds: feedItemsInterventionTest(intl, 0),
            compoundFeedsAdditions: feedAdditionalItemsInterventionTest(
              intl,
              0
            ),
            nutrientComposition: Yup.object()
              .shape({
                crudeProtein: nutritionalFieldPercentValidationSustell(
                  intl,
                  'crudeProtein',
                  baseline
                ),
                crudeLipid: nutritionalFieldPercentValidationSustell(
                  intl,
                  'crudeLipid',
                  baseline
                ),
                crudeFibre: nutritionalFieldPercentValidationSustell(
                  intl,
                  'crudeFibre',
                  baseline
                ),
                starch: nutritionalFieldPercentValidationSustell(
                  intl,
                  'starch',
                  baseline
                ),
                sugar: nutritionalFieldPercentValidationSustell(
                  intl,
                  'sugar',
                  baseline
                ),
                phosphorous: nutritionalFieldPercentValidationSustell(
                  intl,
                  'phosphorous',
                  baseline
                ),

                digestibleCarbon: nutritionalFieldPercentValidationSustell(
                  intl,
                  'digestibleCarbon',
                  baseline,
                  true
                ),
                dryMatter: nutritionalFieldPercentValidationSustell(
                  intl,
                  'dryMatter',
                  baseline,
                  true
                ),
                digestibleNitrogen: nutritionalFieldPercentValidationSustell(
                  intl,
                  'digestibleNitrogen',
                  baseline,
                  true
                ),
                digestiblePhosphorous: nutritionalFieldPercentValidationSustell(
                  intl,
                  'digestiblePhosphorous',
                  baseline,
                  true
                ),
                feedSpill: nutritionalFieldPercentValidationSustell(
                  intl,
                  'feedSpill',
                  baseline,
                  true
                ),
                solubleCarbon: nutritionalFieldPercentValidationSustell(
                  intl,
                  'solubleCarbon',
                  baseline,
                  true
                ),
                solubleNitrogen: nutritionalFieldPercentValidationSustell(
                  intl,
                  'solubleNitrogen',
                  baseline,
                  true
                ),
                solublePhosphorous: nutritionalFieldPercentValidationSustell(
                  intl,
                  'solublePhosphorous',
                  baseline,
                  true
                ),
              })
              .nullable(true)
              .default({}),
          }).default({}),
          operations: Yup.object({
            antifouling: interventionFieldWithMinMax(
              intl,
              'antifouling',
              0,
              2,
              'VALIDATION.INTERVENTION.RESULT.VALUE.FIELD.RANGE'
            ),
            transport: Yup.array()
              .of(
                Yup.object({
                  // boatName: Yup.string(""),
                  amount: interventionFieldWithMin(intl, 'amount', 0),
                })
              )
              .test('totalDiesel', '', function (values) {
                const { resolve, path, createError } = this;
                let totalDiesel = 0;

                let stageIndex = this.from?.[2]?.value?.index;
                if (stageIndex || stageIndex === 0 || stageIndex === '0') {
                  stageIndex = parseInt(stageIndex);
                  values?.forEach((item, index) => {
                    if (item.type === 'diesel') {
                      let val = 0;
                      if (item.amount_newValue || item.amount_newValue === 0 || item.amount || item.amount === 0)
                        val = item.amount_newValue || item.amount;
                      else
                        val =
                          baseline?.stages?.[stageIndex]?.stageData?.operations
                            ?.transport[index]?.amount || 0;
                      totalDiesel += val;
                    }
                  });

                  // If totalDiesel not set, sometime it happens that values are not properly set for the first item in the values param
                  // (e.g. boat type) if any of data are not entered. If the first item is only diesel than, validation error will occur
                  // do sum just for the baseline
                  if (
                    totalDiesel === 0 &&
                    !values?.some((el) => el.type === 'diesel')
                  )
                    baseline?.stages?.[
                      stageIndex
                    ]?.stageData?.operations?.transport?.forEach((item) => {
                      if (item.type === 'diesel') totalDiesel += item.amount;
                    });
                }
                const additions = resolve(Yup.ref(`transportAddition`));
                additions?.forEach((item) => {
                  if (
                    item.type === 'diesel' &&
                    (item.amount || item.amount === 0)
                  )
                    totalDiesel += item.amount;
                });
                if (totalDiesel <= 0) {
                  //show the error message for the first diesel boat, or in case there is no diesel boat, show for the first one
                  let errorIndex = values?.findIndex(
                    (el) => el.type === 'diesel'
                  );
                  if (errorIndex === -1) errorIndex = 0;
                  return createError({
                    path: path + `[${errorIndex}].amount`,
                    message: intl.formatMessage(
                      {
                        id: 'BASELINE.FORM.SALMON.BOATS.VALIDATION.TOTAL_DIESEL_USE',
                      },
                      { min: 0 }
                    ),
                  });
                }

                return true;
              }),
            transportAddition: Yup.array().of(
              Yup.object({
                boatName: Yup.string()
                  .required(
                    intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' })
                  )
                  .min(
                    2,
                    intl.formatMessage(
                      { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                      { count: 2 }
                    )
                  ),
                type: Yup.string()
                  .ensure()
                  .required(
                    intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                  ),
                amount: numericRequiredWithGreaterThanMin(intl, 0),
              })
            ),
            materialsFarmConstruction: Yup.array().of(
              Yup.object({
                amount: interventionFieldWithMoreThanMin(intl, 'amount', 0),
              })
            ),
            materialsFarmConstructionAddition: Yup.array()
              .of(
                Yup.object({
                  type: Yup.string()
                    .ensure()
                    .required(
                      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                    ),
                  amount: numericRequiredWithGreaterThanMin(intl, 0),
                })
              )
              .test('unique', '', function (value) {
                const { path, createError } = this;
                const existingType = [];
                const errors = [];
                (value || []).forEach((item, index) => {
                  if (item.type)
                    if (existingType.includes(item.type))
                      errors.push(
                        createError({
                          path: path + `[${index}].type`,
                          message: intl.formatMessage(
                            {
                              id: 'VALIDATION.FIELD.TYPE_VALUE_ALLREADY_SET',
                            },
                            {
                              type: lookupValues.materials.find(
                                (el) => el.value === item.type
                              )?.display_name,
                            }
                          ),
                        })
                      );
                    else existingType.push(item.type);
                });

                if (errors.length > 0)
                  return createError({ message: () => errors });

                return true;
              }),
            liceTreatment: Yup.array().of(
              Yup.object({
                amount: interventionFieldWithMoreThanMin(intl, 'amount', 0),
              })
            ),
            liceTreatmentAddition: Yup.array()
              .of(
                Yup.object({
                  type: Yup.string()
                    .ensure()
                    .required(
                      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                    ),
                  amount: numericRequiredWithGreaterThanMin(intl, 0),
                })
              )
              .test('unique', '', function (value) {
                const { path, createError } = this;
                const existingType = [];
                const errors = [];
                (value || []).forEach((item, index) => {
                  if (item.type)
                    if (existingType.includes(item.type))
                      errors.push(
                        createError({
                          path: path + `[${index}].type`,
                          message: intl.formatMessage(
                            {
                              id: 'VALIDATION.FIELD.TYPE_VALUE_ALLREADY_SET',
                            },
                            {
                              type: lookupValues.liceTreatment.find(
                                (el) => el.value === item.type
                              )?.display_name,
                            }
                          ),
                        })
                      );
                    else existingType.push(item.type);
                });

                if (errors.length > 0)
                  return createError({ message: () => errors });

                return true;
              }),
          }).default({}),
          output: Yup.object({
            production: interventionFieldWithMoreThanMin(intl, 'production', 0),
            averageWeight: interventionFieldWithMoreThanMin(
              intl,
              'averageWeight',
              0
            ),
            mortalities: interventionFieldWithMin(
              intl,
              'mortalities',
              0,
              'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE'
            ).test(
              'sumCheck',
              intl.formatMessage({
                id: 'VALIDATION.NUMERIC.SUM_MORTALITIES_ESCAPEES',
              }),
              function (_, testContext) {
                const stageIndex = testContext.from?.[2]?.value?.index;
                const stageData = baseline?.stages?.[stageIndex]?.stageData?.output;
                let mortalities = this.resolve(this.parent.mortalities_newValue);
                let escapees = this.resolve(this.parent.escapees_newValue);
                let production = this.resolve(this.parent.production_newValue);
                mortalities = mortalities ? mortalities : stageData?.mortalities;
                escapees = escapees ? escapees : stageData?.escapees;
                production = production ? production : stageData?.production;
                return sumCheck([mortalities, escapees], production);
              }
            ),
            escapees: interventionFieldWithMin(
              intl,
              'escapees',
              0,
              'VALIDATION.INTERVENTION.RESULT.FIELD.MIN_VALUE'
            ).test(
              'sumCheck',
              intl.formatMessage({
                id: 'VALIDATION.NUMERIC.SUM_MORTALITIES_ESCAPEES',
              }),
              function (_, testContext) {
                const stageIndex = testContext.from?.[2]?.value?.index;
                const stageData = baseline?.stages?.[stageIndex]?.stageData?.output;
                let mortalities = this.resolve(this.parent.mortalities_newValue);
                let escapees = this.resolve(this.parent.escapees_newValue);
                let production = this.resolve(this.parent.production_newValue);
                mortalities = mortalities ? mortalities : stageData?.mortalities;
                escapees = escapees ? escapees : stageData?.escapees;
                production = production ? production : stageData?.production;
                return sumCheck([mortalities, escapees], production);
              }
            ),
            animalBiomassStartOfYear: interventionFieldWithMoreThanMin(
              intl,
              'animalBiomassStartOfYear',
              0
            ),
            liveWeight: interventionFieldWithMin(intl, 'liveWeight', 0),
            animalBiomasEndOfYear: interventionFieldWithMoreThanMin(
              intl,
              'animalBiomasEndOfYear',
              0
            ),
          }).default({}),
        }).default({}),
      })
    ),
  });

// merge all necessary parts to baseSchema

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const combinedSchema = Yup.object({ info: baseSchema })
    .concat(Yup.object({ resourceUse: interventionResourcesSalmon(confObj) }))
    .concat(stageDataPartMarineFish(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
