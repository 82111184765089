import { AnimalType, FarmBaseline, Intervention, Maybe } from "../../../../graphql/types";
import { CompartmentType, FootprintCategoryKey, FootprintCategoryName, SimulationDistributionChange } from "../Footprint/internal";

export interface DashboardFilters {
  farms?: string[] | undefined;
  animals?: AnimalType[] | undefined;
  countries?: string[] | undefined;
  databaseFoundation?: string | undefined;
}

export enum FilterFields {
  Farms = 'farms',
  Animals = 'animals',
  Countries = 'countries',
  DatabaseFoundation = 'databaseFoundation'
}

export type FilterFieldType = 'farms' | 'animals' | 'countries' | 'databaseFoundation';

export type DashboardFilterData = {
  [key in FilterFieldType]: { name: string; id: string | undefined; }[];
};

export enum DashboardImpactType {
  Absolute = 'ABSOLUTE',
  PerUnit = 'PER_UNIT'
}

export interface DashboardImpactCategory {
  key: FootprintCategoryName;
  compartmentType: CompartmentType;
  unit?: string;
  name?: string;
}

export interface DateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string | undefined;
}

export type SortOption = 'Asc' | 'Desc' | undefined;

export const initialDashboardImpactCateg: DashboardImpactCategory = {
  key: FootprintCategoryKey.CLIMATE_CHANGE,
  name: 'Total',
  compartmentType: CompartmentType.AIR,
  unit: 'kg CO2 eq'
}

export enum AnalysisGroupsFarm {
  Ration = 'Ration',
  Resources = 'Resources',
  Farm = 'Farm',
  PurchasedAnimals = 'Purchased animals',
  AuxiliaryInputs = 'Auxiliary Inputs',
  Processing = 'Processing'
}

export enum AnalysisGroupsFeed {
  Resources = 'Resources',
  Ingredients = 'Ingredients',
  Transport = 'Transport'
}

export type SortOptions = {
  [key in AnalysisGroupsFarm | AnalysisGroupsFeed | 'Total' | 'Name']?: SortOption;
}

/**
 * 
 * Holds info that is suitable to be attached to footprint 
 * and filter by farms, animals, countries...
 * */ 
export interface DatasetInfo {
  isCurrent?: boolean;
  customerId?: string;
  farm: {
    name: string;
    id: string;
    country: string;
  }
  animalSystem: {
    id: string | undefined;
    name: string | undefined;
    animalType: string | undefined;
  }
  activeBaseline: Maybe<FarmBaseline> | undefined;
  interventionReference?: string;
  interventions: Maybe<Intervention>[] | undefined;
}

export interface FeedInfo {
  country: string;
  animalTypes: AnimalType[];
}

export interface DashboardDistribution {
  farm: number;
  resources: number;
  purchasedAnimals: number;
  ration: number;
  total: number;
}

export type DistributionByAnalysisGroups = {
  [key in (AnalysisGroupsFeed | AnalysisGroupsFarm | 'Total')]: number;
}

export interface Simulation {
  references: string[];
  simulations: SimulationDistributionChange[];
}

export enum DashboardType {
  Farm = 'Farm',
  Feeds = 'Feeds'
}

export enum SelectBaselinesInterventions {
  CurrentBaselines = 'CurrentBaselines',
  ArchivedBaselines = 'ArchivedBaselines',
  CurrentInterventions = 'CurrentInterventions',
  ArchivedInterventions = 'ArchivedInterventions'
}
