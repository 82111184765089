import { Grid, Typography } from "@material-ui/core";
import { DsmIcon } from '@dsm-dcs/design-system-react';
import useStyles from "../../../../modules/UserProfile/profileStyles/myProfileStyles";
import { helpCenterStyles } from "../../../../../_metronic/layout";

interface DocumentCardProps {
    iconName: string;
    documentName: string;
    documentSize: string;
  }

const DocumentCard = ({
    iconName,
    documentName,
    documentSize,
  }: DocumentCardProps) => {

    const classes = useStyles();
    const specificClasses = helpCenterStyles();

    const downloadFile = () => {
      const link = document.createElement('a');
      link.href = '/media/pdfs/' + documentName;
      link.download = documentName
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return (
    <Grid
        container
        className={specificClasses.documentCard}
        onClick={downloadFile}
      >
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={specificClasses.documentIconHolder}>
            <DsmIcon
              name={iconName}
              className={specificClasses.documentIcon}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <div>
            <Typography className={classes.label}>
              {documentName}
            </Typography>
            <Typography className={classes.subLabel}>
              {documentSize}
            </Typography>
          </div>
        </Grid>
      </Grid>
      )};

export default DocumentCard;