import React, { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button, Grid, IconButton } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';

import InfoToolTip from '../../Helpers/InfoToolTip';

import SustellNewDynamicInput from './SustellNewDynamicInput';
// import { useIntl } from 'react-intl';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

const dynamicItem = { type: '', amount: '' };

const SustellDynamicInput = props => {
  const {
    label,
    availableOptions,
    subFieldName,
    tooltip,
    formState,
    disableAddButtonWhenAllOptionSelected = false,
    data,
    setData,
    fieldItemPrefix,
  } = props;

  const manualChange = useRef(false);
  const { setValue, control } = useFormContext();
  let formValues = useWatch({
    control,
    name: `${fieldItemPrefix}.${subFieldName}`,
    defaultValue: data
  })

  const intl = useIntl();

  const getFilteredOptions = (index) => {
    const removedItemsList = [...formValues.slice(0, index), ...formValues.slice(index + 1)];
    return availableOptions.filter(option => !removedItemsList.some(item => item.type === option.value)); 
  }

  useEffect(() => {
    if (manualChange.current) {
      // updates the underlying fields in form after manual
      // adding or removing feeds - important on removal!
      // but should not be triggered when components refresh by itself (change in selectbox or similar)
      setValue(fieldItemPrefix + '.' + subFieldName, data);
      manualChange.current = false;
    }
  }, [data, subFieldName, setValue, setData, fieldItemPrefix]);

  const append = () => {
    //get the internal rect-hook-form state
    if (formValues === undefined) formValues = [];
    manualChange.current = true;
    setData([...formValues, { ...dynamicItem }]);
  };

  const selectItem = (index, value) => {
    const newSelectedItems = [...formValues]
    newSelectedItems[index].type = value
    setData(newSelectedItems)
  };

  const inputItem = (index, value) => {
    const newSelectedItems = [...formValues]
    newSelectedItems[index].amount = value
    setData(newSelectedItems)
  };

  const remove = index => {
    manualChange.current = true;
    setData([...formValues.slice(0, index), ...formValues.slice(index + 1)]);
  };

  const disableAddButton = () => {
    if (!disableAddButtonWhenAllOptionSelected) return false;
    const dataLen = data?.length || 0;
    const optionsLen = availableOptions?.length || 0;

    return dataLen >= optionsLen;
  };

  return (
    <>
      <Grid item container key={fieldItemPrefix + '.' + subFieldName}>
        {(data || []).map((item, index) => {
          return (
            <Grid
              item
              container
              key={'inputItem_' + fieldItemPrefix + '.' + subFieldName + index}
              justifyContent="space-between"
              alignItems="center"
            >
              <SustellNewDynamicInput
                label={label}
                availableOptions={getFilteredOptions(index)}
                index={index}
                fieldItemPrefix={fieldItemPrefix}
                subFieldName={subFieldName}
                typeValue={item.type}
                formState={formState}
                onChangeSelect={(value) => selectItem(index, value)}
                onChangeInput={(value) => inputItem(index, value)}
              />
              <Grid item>
                {formState !== 'view' && (
                  <IconButton
                    style={{ marginBottom: '5px' }}
                    aria-label="Remove input"
                    onClick={() => remove(index)}
                  >
                    <DeleteForever />
                  </IconButton>
                )}
              </Grid>
              <Grid key={'inputPart4' + item.id}>
                <InfoToolTip tooltip={tooltip} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {formState !== 'view' && (
        <Grid container spacing={1}>
          <Grid item xs={12} align="right" style={{ marginBottom: '16px' }}>
            <Button
              variant="outlined"
              onClick={e => append()}
              disabled={disableAddButton()}
            >
              {intl.formatMessage({ id: 'GENERAL.ADD' })} {label}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SustellDynamicInput;
