/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { cloneDeep } from 'lodash';
import { useEffect, useRef } from 'react';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { FormType } from '../../common';
import { PoultryInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { PoultryGrowingStageData, PoultryIntervention, PoultryOutputGrowing as PoultryOutputInterventionGrowing } from '../../../../models/Intervention/PoultryIntervention';
import { PoultryOutputGrowing } from '../../../../models/Baseline/PoultryBaseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';

const PoultryOutputGrowingDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: PoultryInterventionFormDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassPrecision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);
  const currResetValue = useRef<PoultryOutputInterventionGrowing>();
  const formTitle = intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',});
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as PoultryIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData as PoultryGrowingStageData;
      if (stageData?.output) {
        stageData.output = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output as PoultryOutputGrowing;

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridOneColumnIntervention}>
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.totalToStage`}
          label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_TO_STAGE'})}
          type="number"
          tooltip=' '
          metricUnit=''
          baseline={output?.totalToStage}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.AVERAGE_WEIGHT'})}
          type="number"
          tooltip=' '
          metricUnit={barnOutputMassUnit}
          precision={barnOutputMassPrecision}
          baseline={output?.averageWeight}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.totalSold`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_SOLD"})}
          type="number"
          tooltip=' '
          metricUnit=''
          baseline={output?.totalSold}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.mortalityAtPercent`}
          label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.MORTALITY_RATE'})}
          type="number"
          tooltip=' '
          metricUnit={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.MORTALITY_RATE.PLACEHOLDER"})}
          baseline={output?.mortalityAtPercent}
          disabled={formType === FormType.View}
        />
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.weightAtMortality`}
          label={intl.formatMessage({id: 'SUSTELL.STAGE.POULTRY.OUTPUT.WEIGHT_AT_MORTALITY'})}
          type="number"
          tooltip=' '
          metricUnit={barnOutputMassUnit}
          baseline={output?.weightAtMortality}
          disabled={formType === FormType.View}
        />
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default PoultryOutputGrowingDialog;
