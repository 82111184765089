import * as Yup from 'yup';
import {
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  numericRequiredGreaterThanMinLessThanMax,
  numericOptionalGreaterThanMinLessThanMax,
  numericRequiredWithGreaterThanMin,
  numericRequiredWithMin,
  sumCheck,
} from './validationObjectBuilderFunctions';
import { defaultUnitsV2 } from '../../../../sustell_15/utils/unit-utils';
import { StageType } from '../../../../../graphql/types';
import { processingStageData } from './processingBaselineValidation';
import { FacilitySpecies } from '../../../../sustell_15/models/Facility/FacilityTypes';

const usedResourcesMarine = ({ intl }) =>
  Yup.object({
    electricityUse: numericOptionalWithMin(intl, 0),
    waterUse: numericOptionalWithMin(intl, 0),
  });

export const stageOutput = (intl) =>
  Yup.object().shape(
    {
      production: numericRequiredWithMin(intl, 0),
      averageWeight: numericRequiredWithGreaterThanMin(intl, 0).test(
        'dependValTest',
        '',
        function (value) {
          if (!value || value < 0) return true;
          const { path, createError, from } = this;
          let averageLiveWeightStockedAnimals = 0;
          if (from[1]?.value?.input?.averageLiveWeightStockedAnimals)
            averageLiveWeightStockedAnimals = Number(
              from[1]?.value?.input?.averageLiveWeightStockedAnimals
            );
          if (averageLiveWeightStockedAnimals > value * 0.1) {
            return createError({
              path,
              message: intl.formatMessage({
                id: 'BASELINE.FORM.SALMON.MAX_VALUE_OF_AVG_WEIGHT2',
              }),
            });
          }
          return true;
        }
      ),
      mortalities: numericRequiredWithMin(intl, 0).test(
        'sumCheck',
        intl.formatMessage({
          id: 'VALIDATION.NUMERIC.SUM_MORTALITIES_ESCAPEES',
        }),
        function () {
          const test = sumCheck(
            [this.parent.mortalities, this.parent.escapees],
            this.parent.production
          );
          return test;
        }
      ),
      escapees: numericRequiredWithMin(intl, 0).test(
        'sumCheck',
        intl.formatMessage({
          id: 'VALIDATION.NUMERIC.SUM_MORTALITIES_ESCAPEES',
        }),
        function () {
          const test = sumCheck(
            [this.parent.mortalities, this.parent.escapees],
            this.parent.production
          );
          return test;
        }
      ),
    },
    ['averageWeight', 'input.averageLiveWeightStockedAnimals']
  );

export const stageOperations = (intl) =>
  Yup.object({
    antifouling: numericOptionalWithMinMax(intl, 0, 2),
    transport: Yup.array()
      .of(
        Yup.object({
          boatName: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' })
          ),
          type: Yup.string().required(
            intl.formatMessage({
              id: 'VALIDATION.FIELD.INPUT_SELECT',
            })
          ),
          amount: numericRequiredWithMin(intl, 0),
        })
      )
      .test('dieselSum', '', function (values) {
        const { path, createError } = this;
        let totalDiesel = 0;

        values?.forEach((item) => {
          if (item.type === 'diesel') {
            const val = item.amount || 0;
            totalDiesel += val;
          }
        });
        if (totalDiesel <= 0 && values?.length !== 0) {
          // show the error message for the first diesel boat, or in case there is no diesel boat, show for the first one
          let errorIndex = values?.findIndex((el) => el.type === 'diesel');
          let errorElPath = `${path}[${errorIndex}].amount`;
          if (errorIndex === -1) {
            errorIndex = 0;
            errorElPath = `${path}[${errorIndex}].type`;
          }

          return createError({
            path: errorElPath,
            message: intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.BOATS.VALIDATION.MANDATORY_DIESEL_USE',
            }),
          });
        }
        return true;
      }),
    materialsFarmConstruction: Yup.array().of(
      Yup.object({
        type: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        amount: numericRequiredWithGreaterThanMin(intl, 0),
      })
    ),
    liceTreatment: Yup.array().of(
      Yup.object({
        type: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        amount: numericRequiredWithGreaterThanMin(intl, 0),
      })
    ),
  });

export const stageFeed = (intl) =>
  Yup.object({
    antibioticsUse: Yup.array().of(
      Yup.object({
        type: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        amount: numericRequiredWithGreaterThanMin(intl, 0),
      })
    ),
    totalFeedIntake: Yup.number()
      .transform((changed, original) => (original === '' ? undefined : changed))
      .required(
        intl.formatMessage({
          id: 'VALIDATION.FIELD.REQUIRED_CALCULATED',
        })
      )
      .moreThan(
        0,
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.INPUT_GREATER_THAN_CALCULATED' },
          { value: 0 }
        )
      ),
    // mandatory nutritional content
    nutrientComposition: Yup.object().shape({
      crudeProtein: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100).test(
        'sumCheck',
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
          { max: 100 }
        ),
        function () {
          return sumCheck(
            [
              this.parent.crudeProtein,
              this.parent.crudeLipid,
              this.parent.crudeFibre,
              this.parent.starch,
              this.parent.sugar,
              this.parent.phosphorous,
            ],
            100
          );
        }
      ),
      crudeLipid: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100).test(
        'sumCheck',
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
          { max: 100 }
        ),
        function () {
          return sumCheck(
            [
              this.parent.crudeProtein,
              this.parent.crudeLipid,
              this.parent.crudeFibre,
              this.parent.starch,
              this.parent.sugar,
              this.parent.phosphorous,
            ],
            100
          );
        }
      ),
      crudeFibre: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100).test(
        'sumCheck',
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
          { max: 100 }
        ),
        function () {
          return sumCheck(
            [
              this.parent.crudeProtein,
              this.parent.crudeLipid,
              this.parent.crudeFibre,
              this.parent.starch,
              this.parent.sugar,
              this.parent.phosphorous,
            ],
            100
          );
        }
      ),
      starch: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100).test(
        'sumCheck',
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
          { max: 100 }
        ),
        function () {
          return sumCheck(
            [
              this.parent.crudeProtein,
              this.parent.crudeLipid,
              this.parent.crudeFibre,
              this.parent.starch,
              this.parent.sugar,
              this.parent.phosphorous,
            ],
            100
          );
        }
      ),
      sugar: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100).test(
        'sumCheck',
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
          { max: 100 }
        ),
        function () {
          return sumCheck(
            [
              this.parent.crudeProtein,
              this.parent.crudeLipid,
              this.parent.crudeFibre,
              this.parent.starch,
              this.parent.sugar,
              this.parent.phosphorous,
            ],
            100
          );
        }
      ),
      phosphorous: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100).test(
        'sumCheck',
        intl.formatMessage(
          { id: 'VALIDATION.NUMERIC.SUM_MAX_ERROR' },
          { max: 100 }
        ),
        function () {
          return sumCheck(
            [
              this.parent.crudeProtein,
              this.parent.crudeLipid,
              this.parent.crudeFibre,
              this.parent.starch,
              this.parent.sugar,
              this.parent.phosphorous,
            ],
            100
          );
        }
      ),

      dryMatter: numericRequiredGreaterThanMinLessThanMax(intl, 0, 100),
      feedSpill: numericOptionalGreaterThanMinLessThanMax(intl, 0, 100),
      solubleNitrogen: numericOptionalGreaterThanMinLessThanMax(intl, 0, 100),
      solublePhosphorous: numericOptionalGreaterThanMinLessThanMax(
        intl,
        0,
        100
      ),
      solubleCarbon: numericOptionalGreaterThanMinLessThanMax(intl, 0, 100),
      digestibleNitrogen: numericOptionalGreaterThanMinLessThanMax(
        intl,
        0,
        100
      ),
      digestibleCarbon: numericOptionalGreaterThanMinLessThanMax(intl, 0, 100),
      digestiblePhosphorous: numericOptionalGreaterThanMinLessThanMax(
        intl,
        0,
        100
      ),
    }),
  });

export const stageStocking = (intl) =>
  Yup.object({
    numberOfStockedAnimals: numericRequiredWithGreaterThanMin(intl, 0),
    averageLiveWeightStockedAnimals: numericRequiredWithGreaterThanMin(
      intl,
      0
    ).test('dependValTest', '', function (value) {
      if (!value || value < 0) return true;
      const { path, createError, from } = this;
      // TODO: check, this is what their API returns:
      // "If the weight of the juvenile is more than 10% of the final bodyweight of
      // the harvested salmon then primary data for juvenile production is required.",
      let avgWeightValue = 0;
      if (from[1]?.value?.output?.averageWeight)
        avgWeightValue = Number(from[1]?.value?.output?.averageWeight);
      if (avgWeightValue > 1 && value > avgWeightValue * 0.1) {
        return createError({
          path,
          message: intl.formatMessage(
            { id: 'VALIDATION.FIELD.MAX_VALUE_OF_AVG_WEIGHT' },
            { max: Math.round(avgWeightValue * 0.1 * 1000) / 1000 }
          ),
        });
      }
      return true;
    }),
    // this is calculated filed, no need to be validated
    // weightOfStockedAnimals: numericRequiredWithMin(intl, 0),
  });

export const stageDataPartMarine = ({ intl, userUOM = defaultUnitsV2 }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          type: Yup.string()
            .oneOf([StageType.Growing, StageType.Processing])
            .required(),
          stageData: Yup.object()
            .when('type', {
              is: StageType.Growing,
              then: Yup.object({
                output: stageOutput(intl, userUOM),
                input: stageStocking(intl, userUOM),
                feed: stageFeed(intl, userUOM),
                operations: stageOperations(intl, userUOM),
              }),
            })
            .when('type', {
              is: StageType.Processing,
              then: processingStageData(intl, FacilitySpecies.MarineFish),
            }),
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ resourceUse: usedResourcesMarine(confObj) }))
    .concat(stageDataPartMarine(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
