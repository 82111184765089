import {
  Box,
  Divider,
  Grid,
  Typography,
  Checkbox,
  Button,
  makeStyles,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { ColumnFilter, ColumnFiltersState, Table } from '@tanstack/react-table';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { getUIDatabaseFoundation } from '../../utils/compound-feed-utils';
import { CompoundFeedDatabase, Maybe } from '../../../../graphql/types';

interface CompoundFeedsTableFilterProps<T> {
  table: Table<T>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
}

const useStyles = makeStyles(() => ({
  checkbox: {
    color: '#0070BA',
  },
  typography: { color: '#1B1B1B' },
  itemTitleContainer: { padding: '12px 16px' },
  itemQuickFilterContainer: { padding: '8px 0 8px 4px' },
  linkButton: {
    textTransform: 'none',
    padding: 0,
    border: 'none',
    fontSize: 14,
    fontWeight: 400,
    color: '#0070BA',
    textDecorationLine: 'underline',
    '&:hover': {
      background: 'none',
      textDecorationLine: 'underline',
    },
  },
  itemTitle: { color: '#1B1B1B', fontWeight: 500, padding: '12px 16px 0' },
  itemScrollContainer: {
    maxHeight: 150,
    overflowY: 'scroll',
    padding: '0 0 0 4px',
  },
}));

const CompoundFeedsTableFilter = <T,>({
  table,
  columnFilters,
  setColumnFilters,
}: CompoundFeedsTableFilterProps<T>) => {
  const intl = useIntl();
  const classes = useStyles();
  const feedMillColumns = table.getColumn('feedmillName');
  const feedMillItems = feedMillColumns
    ? Array.from(feedMillColumns.getFacetedUniqueValues().keys()).filter(
        (item) => item !== undefined
      )
    : [];
  const databaseFoundationColumns = table.getColumn('databaseFoundation');
  const databaseFoundationItems = databaseFoundationColumns
    ? Array.from(databaseFoundationColumns.getFacetedUniqueValues().keys())
    : [];
  const targetSpeciesColumns = table.getColumn('target_species');
  const targetSpeciesColumnsItems = targetSpeciesColumns
    ? Array.from(
        new Set(
          Array.from(
            targetSpeciesColumns.getFacetedUniqueValues().keys()
          ).flat()
        )
      )
    : [];
  type StringOrUndefined = string | undefined;

  const databaseFilterItem = columnFilters.find(
    (item) => item.id === 'databaseFoundation'
  );
  const databaseFilterValue: StringOrUndefined[] =
    (databaseFilterItem?.value as StringOrUndefined[]) ?? [];

  const feedmillFilterItem = columnFilters.find(
    (item) => item.id === 'feedmillName'
  );
  const feedmillFilterValue: StringOrUndefined[] =
    (feedmillFilterItem?.value as StringOrUndefined[]) ?? [];

  const targetspeciesFilterItem = columnFilters.find(
    (item) => item.id === 'target_species'
  );
  const targetspeciesFilterValue: StringOrUndefined[] =
    (targetspeciesFilterItem?.value as StringOrUndefined[]) ?? [];

  const benchmarkFilterItem = columnFilters.find(
    (item) => item.id === 'benchmark'
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { id, value } = event.target;
    const filterPresent = columnFilters.find((filter) => filter.id === id);
    const filterValue: StringOrUndefined[] =
      (filterPresent?.value as StringOrUndefined[]) ?? [];
    let newFilterItemValue: StringOrUndefined[] = [];
    const newValue = value === '' ? undefined : value;
    if (!filterPresent && !checked) {
      return;
    }
    if (!filterPresent && checked) {
      newFilterItemValue = [newValue];
    }
    if (filterPresent && !checked) {
      newFilterItemValue = filterValue.filter(
        (item: StringOrUndefined) => item !== newValue
      );
    }
    if (filterPresent && checked) {
      newFilterItemValue = [...filterValue, newValue];
    }
    const newFilterItem: ColumnFilter = {
      id,
      value: newFilterItemValue,
    };
    const newFilterItems = newFilterItemValue.length
      ? [...columnFilters.filter((item) => item.id !== id), newFilterItem]
      : [...columnFilters.filter((item) => item.id !== id)];
    setColumnFilters(newFilterItems);
  };

  const handleBenchmarkChange = () => {
    const benchMarkSelected = columnFilters.find(
      (item) => item.id === 'benchmark'
    );
    setColumnFilters(
      benchMarkSelected
        ? [...columnFilters.filter((item) => item.id !== 'benchmark')]
        : [...columnFilters, { id: 'benchmark', value: true }]
    );
  };

  return (
    <Box maxWidth={240} minWidth={240}>
      <Grid container className={classes.itemTitleContainer}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.typography}>
            {intl.formatMessage({
              id: 'GENERAL.FILTERS',
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.linkButton}
            onClick={() => setColumnFilters([])}
          >
            {intl.formatMessage({
              id: 'GENERAL.CLEAR_FILTERS',
            })}
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" orientation="horizontal" />
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.itemQuickFilterContainer}
      >
        <Checkbox
          id="benchmark"
          onChange={handleBenchmarkChange}
          checked={benchmarkFilterItem !== undefined}
          className={classes.checkbox}
        />
        <Typography className={classes.typography}>
          {intl.formatMessage({
            id: 'COMPOUNDFEEDS.BENCHMARK_FEED',
          })}
        </Typography>
      </Grid>
      <Divider variant="fullWidth" orientation="horizontal" />
      <Grid container direction="column">
        <Typography variant="body1" className={classes.itemTitle}>
          {intl.formatMessage({
            id: 'COMPOUNDFEEDS.MY_FEEDS.DATABASE_SELECTED',
          })}
        </Typography>
        <Grid container direction="row" className={classes.itemScrollContainer}>
          {databaseFoundationItems.map((item: Maybe<CompoundFeedDatabase>) => (
            <Grid key={uuidv4()} container direction="row" alignItems="center">
              <Checkbox
                id="databaseFoundation"
                value={item}
                checked={databaseFilterValue.includes(item as string)}
                onChange={handleChange}
                className={classes.checkbox}
              />
              <Typography>{getUIDatabaseFoundation(item)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider variant="fullWidth" orientation="horizontal" />
      <Grid container direction="column">
        <Typography variant="body1" className={classes.itemTitle}>
          {intl.formatMessage({
            id: 'COMPOUNDFEEDS.CARD.TABLE.FEEDMILL_LOCATION',
          })}
        </Typography>
        <Grid container direction="row" className={classes.itemScrollContainer}>
          {feedMillItems.map((item: Maybe<CompoundFeedDatabase>) => (
            <Grid key={item} container direction="row" alignItems="center">
              <Checkbox
                id="feedmillName"
                value={item}
                checked={feedmillFilterValue.includes(item as string)}
                onChange={handleChange}
                className={classes.checkbox}
              />
              <Typography>{item}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider variant="fullWidth" orientation="horizontal" />
      <Grid container direction="column">
        <Typography variant="body1" className={classes.itemTitle}>
          {intl.formatMessage({
            id: 'REPORT.COMPOUND_FEED_DATASETS.GENERAL.ANIMAL_SYSTEM',
          })}
        </Typography>
        <Grid container direction="row" className={classes.itemScrollContainer}>
          {targetSpeciesColumnsItems.map(
            (item: Maybe<CompoundFeedDatabase>) => (
              <Grid
                key={uuidv4()}
                container
                direction="row"
                alignItems="center"
              >
                <Checkbox
                  id="target_species"
                  value={item}
                  checked={targetspeciesFilterValue.includes(item as string)}
                  onChange={handleChange}
                  className={classes.checkbox}
                />
                <Typography>{item && item.replace(' system', '')}</Typography>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompoundFeedsTableFilter;
