import React from 'react';
import { Button, Menu, MenuItem, Fade } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DSM_BLACK } from '../../../../_metronic/layout';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { FormType } from '../FarmFlow/common';
import { CompoundFeedDatabase } from '../../../../graphql/types';

type FadeMenuProps = {
  viewHandler?: (() => void) | null;
  editHandler?: (() => void) | null;
  deleteHandler?: (() => void) | null;
  duplicateHandler?: (() => void) | null;
  copyToV2Handler?: (() => void) | null;
  showDuplicate?: boolean;
  openFootprintHandler?: (() => void) | null;
  iconColor?: string;
  placeHolder?: 'vertical' | 'horizontal' | 'editbutton';
  formType?: FormType;
};

const FadeMenu = ({
  viewHandler,
  editHandler,
  deleteHandler,
  duplicateHandler,
  openFootprintHandler,
  copyToV2Handler,
  showDuplicate = false,
  placeHolder = 'horizontal',
  iconColor = DSM_BLACK as string,
  formType = FormType.View,
}: FadeMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const intl = useIntl();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    if (viewHandler) {
      viewHandler();
    }
  };

  const handleEdit = () => {
    if (editHandler) editHandler();
  };

  const handleDelete = () => {
    if (deleteHandler) deleteHandler();
  };

  const handleOpenFootprint = () => {
    if (openFootprintHandler) openFootprintHandler();
  };

  const handleDuplicate = () => {
    if (duplicateHandler) duplicateHandler();
  };

  return (
    <div>
      {placeHolder === 'editbutton' && (
        <DsmButton
          aria-controls="fade-menu"
          aria-haspopup="true"
          style={{ alignSelf: 'end' }}
          onClick={(e: any) => handleClick(e)}
          variant="secondary"
          disabled={formType === FormType.View}
        >
          {intl.formatMessage({ id: 'GENERAL.EDIT' })}
        </DsmButton>
      )}
      {placeHolder !== 'editbutton' && (
        <Button
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0, height: '36px', color: iconColor, minWidth: '16px'}}
        >
          {placeHolder === 'horizontal' && <MoreHoriz />}
          {placeHolder === 'vertical' && (
            <DsmIcon
              name="general/dots-vertical"
              style={{ height: '24px', width: '24px' }}
            />
          )}
        </Button>
      )}
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {viewHandler && (
          <MenuItem
            disabled={handleView === null}
            onClick={(e) => {
              e.stopPropagation();
              handleView();
              handleClose();
            }}
          >
            <DsmIcon
              name="general/eye"
              style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({ id: 'GENERAL.VIEW' })}
          </MenuItem>
        )}
        {editHandler && (
          <MenuItem
            disabled={handleEdit === null}
            onClick={(e) => {
              e.stopPropagation();
              handleEdit();
              handleClose();
            }}
          >
            <DsmIcon
              name="editor/pencil-01"
              style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({ id: 'GENERAL.EDIT' })}
          </MenuItem>
        )}
        {copyToV2Handler && (
          <MenuItem
            disabled={handleEdit === null}
            onClick={(e) => {
              e.stopPropagation();
              copyToV2Handler();
              handleClose();
            }}
          >
            <DsmIcon
              name="general/copy-07"
              style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({ id: 'BASELINE.COPY_TO_V2' })}
          </MenuItem>
        )}
        {showDuplicate && duplicateHandler && (
          <MenuItem
            disabled={duplicateHandler === null}
            onClick={() => {
              handleDuplicate();
              handleClose();
            }}
          >
            <DsmIcon
              name="general/copy-06"
              style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({ id: 'GENERAL.DUPLICATE' })}
          </MenuItem>
        )}
        {openFootprintHandler && (
          <MenuItem
            onClick={() => {
              handleOpenFootprint();
              handleClose();
            }}
          >
            <DsmIcon
              name="maps-travel/globe-02"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
              }}
            />
            {intl.formatMessage({ id: 'GENERAL.OPEN.FOOTPRINT' })}
          </MenuItem>
        )}
        {deleteHandler && (
          <MenuItem
            style={{ color: 'var(--dsm-color-error' }}
            disabled={handleDelete === null}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
              handleClose();
            }}
          >
            <DsmIcon
              name="general/trash-01"
              style={{
                marginRight: ' 5px',
                height: '16px',
                width: '16px',
              }}
            />
            {intl.formatMessage({ id: 'GENERAL.DELETE' })}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

FadeMenu.defaultProps = {
  viewHandler: undefined,
  editHandler: undefined,
  deleteHandler: undefined,
  duplicateHandler: undefined,
  copyToV2Handler: undefined,
  showDuplicate: false,
  openFootprintHandler: undefined,
  iconColor: undefined,
  placeHolder: 'horizontal',
  formType: FormType.View,
};

export default FadeMenu;
