import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import { PropTypes, TextField, Tooltip } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import { DsmIcon } from "@dsm-dcs/design-system-react";
import { Maybe } from "graphql/jsutils/Maybe";
import { resolvePath } from "./resolvePathFn";


const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  position: 'absolute', 
  height: '16px', 
  width: '16px',
  top:'28px',
  right:'7px'
}

const useStyles = makeStyles({
  root: {
    minHeight: '74px',
    '& input':{
      marginTop: '-4px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      lineHeight: '28px'
    },
    '& label': {
      position: 'relative',
      width: '125% !important',
      marginBottom: '9px',
      fontSize: '16px',
      fontWeight: '500'
    },
    '& legend': {
      display: 'none'
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px !important'
    },
  },
});

interface ReactHookDsmPickerProps {
  name: string;
  label: string;
  defaultValue?:string;
  margin?:PropTypes.Margin;
  type?: React.InputHTMLAttributes<unknown>['type'];
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  adornment?: string;
  readonly?: boolean;
  required?: boolean;
  changeHandler?:Maybe<Function>;
  onFocusHandler?:Maybe<Function>;
}

const ReactHookDsmPicker: FC<ReactHookDsmPickerProps> = ({
  name,
  label,
  defaultValue='',
  disabled=false,
  margin='none',
  type='',
  tooltip='',
  placeholder='',
  required=false,
  changeHandler=undefined,
  onFocusHandler=undefined
}) => {

  const { errors, control, formState } = useFormContext();
  const localError = resolvePath(errors, name, null);
  const labelId = `${name}-label`;
  // defaultValue when '' force replacement of saved 0, 
  // but without it, label is not working => loading value from reset to check
  const currResetValue = resolvePath(control.defaultValuesRef.current, name, null) as string | number | undefined;
  // but must not set 0 if user deletes it => checking if field is touched
  const isTouched = resolvePath(formState.dirtyFields, name, false) as boolean | undefined;
  const classes = useStyles();
  // console.log("Curr reset value: ", currResetValue);
  return (
    <div style={{position: "relative", display: "inline-block"}}>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value, ref }) => {
        // allow zero to be displayed when needed
        if ((value === '' || value === undefined) && currResetValue === 0 && !isTouched)
          onChange(0);
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField
            id={labelId}
            className={classes.root}
            fullWidth
            label={`${label} ${required?'*':''}`}
            margin={margin}
            variant='outlined'
            inputRef={ref}
            onChange={
              (e) => {
                onChange(e);
                if(changeHandler) changeHandler(e);
              }
            }
            size='small'
            onBlur={onBlur}
            value={value || value === 0 || value === '0' ? value : ''}
            error={!!localError}
            helperText={localError ? (localError.message) : '' }
            type='date'
            InputProps={ {
              style: {
                height: "28px",
                lineHeight: "28px",
                background: '#FFFFFF'
              },
            }}
            disabled={disabled}
            InputLabelProps={{ 
              shrink: true,
              variant: 'standard',
              disableAnimation: true,
              style:{
                width: '100%'
              }
            }}
            placeholder={ placeholder }
            onFocus={(e)=> {
              if(onFocusHandler) onFocusHandler(e)
            }}
          />
        )
      }
      }

    />
    {tooltip && 
      <Tooltip title={ tooltip } placement="left">
        <DsmIcon name='general/help-circle' style={inlineTooltipClass} title=""/>
      </Tooltip>
    }
    </div>
  );
};
export default ReactHookDsmPicker;