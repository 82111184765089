import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  nameMapping,
} from '../../validationSchemaRestrictions';
import {
  floatValueInUserUnits,
} from './inCommonMapperSustell';
import { mapCommonProductionDataIn } from './inCommonMapperSustell';
import lookupValues from '../../../../sustell_15/helpers/lookupValues';

const mapSalmonProductionDataInSustell = (
  inData
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  
  
  // console.log("inData: ",inData);

  const formData = mapCommonProductionDataIn(inData);
  // map resources
  formData.resourceUse.electricityUse = floatValueInUserUnits(
    inData?.resourceUse?.electricityUse,
    userUOM.unitElectricityUse,
    defaultMetric.unitElectricityUse,
    4
  );
  formData.resourceUse.waterUse = floatValueInUserUnits(
    inData?.resourceUse?.waterUse,
    userUOM.unitWaterUse,
    defaultMetric.unitWaterUse,
    3
  );
  formData.resourceUse.waterSource = inData.resourceUse?.waterSource;


  if(inData.stages && Array.isArray(inData.stages)){
    inData.stages.forEach( (stage, index) => {
      const stageFormData = formData.stages[index]?.stageData;
      if(stageFormData){
        const stageData =JSON.parse(stage.stageData)
          
        //map output
        const outputFormData = stageFormData.output;
        outputFormData.production = floatValueInUserUnits(
          stageData?.production?.production,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        outputFormData.averageWeight = floatValueInUserUnits(
          stageData?.production?.average_weight,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );

        outputFormData.mortalities = floatValueInUserUnits(
          stageData?.lost_animals?.mortalities,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        outputFormData.escapees = floatValueInUserUnits(
          stageData?.lost_animals?.escapees,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );

        outputFormData.animalBiomassStartOfYear = floatValueInUserUnits(
          stageData?.animals_present?.animal_biomass_start_of_year,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        outputFormData.liveWeight = floatValueInUserUnits(
          stageData?.animals_present?.average_live_weight_animals_present,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        outputFormData.animalBiomasEndOfYear = floatValueInUserUnits(
          stageData?.animals_present?.animal_biomass_end_of_year,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
      
        //map input
        const inputFormData = stageFormData.input;
        inputFormData.numberOfStockedAnimals =
          stageData?.stocking?.number_of_stocked_animals;
        inputFormData.weightOfStockedAnimals = floatValueInUserUnits(
          stageData?.stocking?.weight_of_stocked_animals,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        inputFormData.averageLiveWeightStockedAnimals = floatValueInUserUnits(
          stageData?.stocking?.average_weight,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        
        // map operations
        if(!stageFormData.operations)
          stageFormData.operations = {};
        
        const operationsFormData = stageFormData.operations ;

        operationsFormData.antifouling = floatValueInUserUnits(
          stageData?.antifouling,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass,
          precision
        );
        
        if (stageData?.materials && Array.isArray(stageData.materials)) {
          operationsFormData.materialsFarmConstruction = [];
          stageData.materials.forEach(item => {
            const value = floatValueInUserUnits(
              item?.amount,
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass,
              precision
            );
            operationsFormData.materialsFarmConstruction.push({
              type: item?.type,
              amount: value,
            });
          });
          // if draft loaded without any lice materials use data, add default placeholder to show drop down correctly
          if(operationsFormData.materialsFarmConstruction.length === 0){
            operationsFormData.materialsFarmConstruction.push({ type: '', amount: '' });
          }
        }

        if (stageData?.antibiotics_use && Array.isArray(stageData.antibiotics_use)) {
          operationsFormData.anitibioticsUse = [];
          stageData.antibiotics_use.forEach(item => {
            const value = floatValueInUserUnits(
              item?.amount,
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass,
              precision
            );
            operationsFormData.anitibioticsUse.push({ type: item?.type, amount: value });
          });
          // if draft loaded without any lice snitibiotics use data, add default placeholder to show drop down correctly
          if(operationsFormData.anitibioticsUse.length === 0){
            operationsFormData.anitibioticsUse.push({ type: '', amount: '' });
          }
        }

        if (
          stageData?.lice_treatment_use &&
          Array.isArray(stageData.lice_treatment_use)
        ) {
          operationsFormData.liceTreatment = [];
          stageData.lice_treatment_use.forEach(item => {
            const value = floatValueInUserUnits(
              item?.amount,
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass,
              precision
            );
            operationsFormData.liceTreatment.push({ type: item?.type, amount: value });
          });
          // if draft loaded without any lice treatment data, add default placeholder
          if(operationsFormData.liceTreatment.length === 0){
            operationsFormData.liceTreatment.push({ type: '', amount: '' });
          }
        }

        if (stageData?.transport && Array.isArray(stageData.transport)) {
          operationsFormData.transport = [];
          stageData.transport.forEach(item => {
            let value = Number(item?.amount);
            if (
              item.type === 'electricity' &&
              userUOM.unitElectricityUse !== defaultMetric.unitElectricityUse
            )
              value = floatValueInUserUnits(
                value,
                userUOM.unitElectricityUse,
                defaultMetric.unitElectricityUse,
                precision
              );
            else if (
              item.type === 'diesel' &&
              userUOM.unitDieselUse !== defaultMetric.unitDieselUse
            )
              value = floatValueInUserUnits(
                value,
                userUOM.unitDieselUse,
                defaultMetric.unitDieselUse,
                precision
              );

              operationsFormData.transport.push({
                boatName: item?.boatName,
                type: item.type,
                amount: value,
              });
          });
          // if draft loaded without any transport data, add default placeholder
          if(operationsFormData.transport.length === 0){
            operationsFormData.transport.push({ boatName: '', type: '', amount: '' });
          }
        }

        // map feeds
        stageFormData.feed.efcr = stageData?.feed?.efcr;

        if (
          stageData?.feed?.nutrient_composition &&
          Array.isArray(stageData.feed.nutrient_composition)
        ) {
          stageFormData.feed.requiredNutritionalContent = {};
          stageFormData.feed.optionalNutritionalContent = {};

          stageData.feed.nutrient_composition.forEach(el => {
            let isMandatory = false;
            let value = Number(el?.amount);
            const type = el.nutrient_type;
            const itemDefinition = lookupValues.nutrient_type?.find( 
              item => item.value === type
            );
            if(itemDefinition){
              isMandatory = itemDefinition.mandatory ? true : false;
              if(itemDefinition.unit !== '%'){
                //possibly there will be more exemptions in the future so process one by one
                if(itemDefinition.value === 'gross_energy'){
                  // console.log(
                  //   `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitBarnOutputMass}`,
                  //   `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitBarnOutputMass}`
                  // );
                  value = floatValueInUserUnits(
                    value, 
                    `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitBarnOutputMass}`,
                    `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitBarnOutputMass}`
                  )
                }
              }
            }
            if (isMandatory) stageFormData.feed.requiredNutritionalContent[type] = value;
            else stageFormData.feed.optionalNutritionalContent[type] = value;
          });
        }

        let totalFeed = 0;

        if (
          stageData?.feed?.feeds_used &&
          Array.isArray(stageData?.feed?.feeds_used)
        ) {
          stageFormData.feed.compoundFeeds = [];
          stageData?.feed?.feeds_used.forEach(oneFeedData => {
            // console.log('feed',oneFeedData)
            const value = floatValueInUserUnits(
              Number(oneFeedData?.amount),
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass,
              precision
            );

            if (!isNaN(value)) totalFeed += value;
            stageFormData.feed.compoundFeeds.push({
              feedType: oneFeedData?.compound_feed_id,
              kgPerAnimal: value,
            });
          });
          // console.log("compundFeeds: ",stageFormData.feed.compoundFeeds)
          // if draft was loaded without any compound feed add empty placeholder to show the dropdown
          if(stageFormData.feed.compoundFeeds.length === 0)
            stageFormData.feed.compoundFeeds.push ({ feedType: '', kgPerAnimal: '' });
        }
        stageFormData.feed.totalFeedIntake=totalFeed;

        stageFormData.animalType = nameMapping[stageData.animal_group.animal_type];

        stageFormData.input.housingType =
          stageData?.housing_description?.housing_type;
      
      }
    })
  }


  return formData;
};

export default mapSalmonProductionDataInSustell;
