import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  floatValueInUserUnits,
  mapCommonProductionDataIn,
  mapResourceUse,
} from './inCommonMapperSustell';
import {
  nameMapping,
} from '../../validationSchemaRestrictions';
const ANIMAL_TYPE='Dairy';


const map3NOPForOneBarn = (oneStageData, oneStageFormData, isDraft = false) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

   if (oneStageData?.supplement3NOP) {
    if(!oneStageFormData.emissions) oneStageFormData.emissions = {};
    const oneStageFormEmissionsData = oneStageFormData.emissions;
    oneStageFormEmissionsData.is3NOPSelected = (isDraft && oneStageData.is3NOPSelected) || !!(oneStageData?.supplement3NOP?.calculatedResult);
    oneStageFormEmissionsData.supplement3NOP = oneStageData?.supplement3NOP;

    if (
      oneStageFormEmissionsData.supplement3NOP._3nopDosePerCowPerDay ||
      oneStageFormEmissionsData.supplement3NOP._3nopDosePerCowPerDay === 0
    )
      oneStageFormEmissionsData.supplement3NOP._3nopDosePerCowPerDay = floatValueInUserUnits(
      oneStageFormEmissionsData.supplement3NOP._3nopDosePerCowPerDay,
        userUOM.unitBarnSmallQuantityUnits,
        defaultMetric.unitBarnSmallQuantityUnits,
        precision
      );

    if (
      oneStageFormEmissionsData.supplement3NOP.dmiAmountPerKg ||
      oneStageFormEmissionsData.supplement3NOP.dmiAmountPerKg === 0
    )
      oneStageFormEmissionsData.supplement3NOP.dmiAmountPerKg = floatValueInUserUnits(
        oneStageFormEmissionsData.supplement3NOP.dmiAmountPerKg,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        precision
      );
  }

  return oneStageFormData;
};


// calculate 3NOP in emission if exists
const correctEmissionForOneStage = (stageData, oneStageFormData) => {
  // const oneStageFeedFormData = oneStageFormData.feed;
  const oneStageEmissionsFormData = oneStageFormData.emissions;
  const correctionMethane3NOP =
  oneStageEmissionsFormData?.supplement3NOP?.calculatedResult;
  // 3NOP exists
  if (correctionMethane3NOP) {
    //check if emission data exists for this barn
    if (
      stageData.emissions &&
      Array.isArray(stageData.emissions)
    ) {
      // fix value
      if (stageData.emissions) {
        if (oneStageEmissionsFormData['methaneEntericFermentation'])
          oneStageEmissionsFormData[
            'methaneEntericFermentation'
          ] -= correctionMethane3NOP;
      }
    }
  }
}

const mapDairyProductionDataInSustell = (
  inData,
  isDraft = false
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  
  const formData = mapCommonProductionDataIn(inData, isDraft);
  mapResourceUse(inData.resourceUse, formData.resourceUse);
  
  if(inData.stages && Array.isArray(inData.stages)){
    inData.stages.forEach( (stage, index) => {
      const stageFormData = formData.stages[index]?.stageData;
      if(stageFormData){
        const stageData =JSON.parse(stage.stageData)
        
        // map output
        stageFormData.output.liveWeight = floatValueInUserUnits(
          stageData?.livestock?.total_weight?.value,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );

        stageFormData.output.milk = floatValueInUserUnits(
          stageData.milk_produced?.amount?.value,
          userUOM.unitOutputMass,
          defaultMetric.unitOutputMass,
          outputMassPrecision
        );
        stageFormData.output.proteinContent = stageData?.milk_produced?.protein_content?.value;
        stageFormData.output.fatContent = stageData?.milk_produced?.fat_content?.value;
        
        stageFormData.animalType = nameMapping[ANIMAL_TYPE];
        stageFormData.housingType = stageData?.housing_description?.housing_type;

        stageFormData.feed.housingType = stageData?.housing_description?.housing_type;

        stageFormData.feed.timeSpentGrazing = stageData?.time_spent_distribution?.grazing?.value;
        stageFormData.feed.timeSpentInOpenYardAreas = stageData?.time_spent_distribution?.yard?.value;
        stageFormData.feed.timeSpentInBuildings = stageData?.time_spent_distribution?.building?.value;
        
        map3NOPForOneBarn(stageData,stageFormData, isDraft);
        correctEmissionForOneStage(stageData,stageFormData)
      }
    })
  }


  return formData;
};

export default mapDairyProductionDataInSustell;
