import React from "react";
import PropTypes from "prop-types";
import { Modal, makeStyles, Box, Typography, Button, ThemeProvider } from "@material-ui/core";
import NotificationModalCard from "./NotificationModalCard";
import dsmTheme from "../../../../../../app/modules/MUITheme";
// import { useIntl } from "react-intl";
import { useIntl } from "../../../../../i18n/customUseIntl";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modal: {
    width: 800,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 8,
  },
  header: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 24,
    paddingBottom: 24,
    backgroundColor: "#49AB6C",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  notificationsContainer: {
    // overflow: "scroll",
    maxHeight: 600,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 32,
    paddingBottom: 32,
    "&>*:not(:last-child)": {
      borderBottomStyle: "solid",
      borderBottomColor: "#d9d9d9",
      borderBottomWidth: "1px",
      marginBottom: 12,
    },
  },
  buttonContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 32,
  },
}));

export default function NotificationsModal({data, open, onClose}) {
  const intl = useIntl();
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    setNotifications(data);
  }, [data])
  const classes = useStyles();
  return (
    <ThemeProvider theme={dsmTheme}>
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.root}
    >
      <Box className={classes.modal}>
        <Box className={classes.header}>
          <Typography style={{ color: "white" }} variant="h5">
            { intl.formatMessage({id:"NOTIFICATIONS.USER"})}
          </Typography>
        </Box>
        <Box className={classes.notificationsContainer}>
        {(notifications || []).map(notification => {
            return(<NotificationModalCard
              key={notification.title + notification.publishAt}
              title={notification.title}
              message={notification.message}
              attachments = {notification.attachementKeys}
          /> )})
        }
        </Box>
        <Box className={classes.buttonContainer}>
          <Button variant="contained" color="secondary" fullWidth={false} onClick={onClose}>
          { intl.formatMessage({id:"GENERAL.CLOSE"})}
          </Button>
        </Box>
      </Box>
    </Modal>
    </ThemeProvider>
  );
}

NotificationsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
