export const ExactNumber = ({ value })=>{
  let formatExp = (value) => {
      let [val, exp] = value.split("e");
      return <>{val}&middot;10<sup>{exp}</sup></>

  }
  return (
      value ? ( (value >= 0.01 || value <= -0.01) ? value.toFixed(2) : formatExp(value.toExponential(2))) : ''
  )
}
