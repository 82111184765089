import { type FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import MyFarmsV2 from '../../components/FarmFlow/MyFarms_v2';
import { FormType } from '../../components/FarmFlow/common';
import { isBeef, isDairy, isMarineFish, isPig, isPoultry, isShrimp } from '../../helpers/animals';
import SustellFarmInfoWithMap from '../../components/FarmFlow/CommonDataParts/v2.0/SustellFarmInfoWithMap';
import { DialogContainer } from '../../components/FarmFlow/CommonDataParts/DialogContainer2';
import PageHeader from '../../components/headerV2/PageHeader';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { useFetchCustomerFarms } from '../../../../api';
import { AnimalType, Farm } from '../../../../graphql/types';
import { FarmTableItemModel } from './FarmFlow.types';
import { useSubheader } from '../../../../_metronic/layout';

interface CompoundFeedsPageProps {
  match: {
    params: {
      customerID: string;
    };
  };
}

const SustellMyFarmsPage: FC<CompoundFeedsPageProps> = ({ match }) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { customerID } = match.params;
  const intl = useIntl();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { ctaClickEvent } = useAdobeDataLayer();
  const { data, isLoading } = useFetchCustomerFarms(customerID);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const subheader = useSubheader();

  const breadCrumbsTitle = intl.formatMessage({ id: 'SUSTELL.MY_FARMS' });
  useEffect(() => {
    const breadCrumbs: { pathname?: string; title: string }[] = [];
    breadCrumbs.push({
      pathname: `/customers/${customerID}/myfarms`,
      title: breadCrumbsTitle,
    });
    // Disable eslint due to non-converted (js) subheader
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    subheader.setBreadcrumbs(breadCrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, breadCrumbsTitle]);

  const mapFarmToItem = (farm: Farm) => {
    const baselinesCount =
      farm.animalSystems?.items?.flatMap((f) => f?.baselines?.items)
        .length || 0;
    const interventionsCount =
      farm.animalSystems?.items?.flatMap((f) => f?.interventions?.items)
        .length || 0;
    const animalSystems = new Set(
      farm.animalSystems?.items?.flatMap((f) =>
        intl.formatMessage({ id: `SUSTELL.STAGE.${f?.animalType || ''}` })
      )
    );
    const { id, name, updatedAt, country, city, size, benchmark } = farm;
    const version = farm.animalSystems?.items?.some(
      (item) =>
        item?.animalType &&
        (isPig(item?.animalType) ||
          isMarineFish(item?.animalType) ||
          isPoultry(item?.animalType)) || 
          isShrimp(item?.animalType) || 
          isBeef(item?.animalType) ||
          isDairy(item?.animalType)
    )
      ? 2
      : 1;
    const animalTypes: AnimalType[] =
      (farm.animalSystems?.items
        ?.filter((item) => item !== null && item?.animalType != null)
        .map((item) => item && item.animalType)
        .filter((item) => item !== null) as AnimalType[]) || [];

    const item: FarmTableItemModel = {
      id,
      name,
      updatedAt: updatedAt || new Date(0),
      country,
      city,
      baselinesCount,
      interventionsCount,
      animalSystems: Array.from(animalSystems),
      animalTypes,
      size: size ?? undefined,
      version,
      benchmark: benchmark ?? false,
    };
    return item;
  };

  const items = data?.data?.getCustomer?.farms?.items || [];

  const farms: FarmTableItemModel[] =
    items.map((farm: Farm) => mapFarmToItem(farm)) || [];
  const customerName = data?.data?.getCustomer?.name ?? '';

  const createFarm = async () => {
    await ctaClickEvent(
      window.location.href,
      'button',
      'Create New Farm',
      'My Farms',
      'My Farms'
    );
    setOpenCreateDialog(true);
  };

  const handleFarmAddedUpdated = (farm: Farm, addMode = false) => {
    if (farm) {
      if (addMode) {
        const path = `/customers/${customerID}/farm/${farm.id}`;
        const historyObj = {
          customerName,
          formType: FormType.View,
        };
        history.push(path, historyObj);
      }
      // TODO add invalidata caches
      queryClient
        .invalidateQueries(['customerFarms'])
        .catch((e) => console.error(e));
    }
  };

  return (
    <>
      <DialogContainer
        formVisible={openCreateDialog}
        handleClose={() => setOpenCreateDialog(false)}
        iconCode="shapes/cube-02"
        iconColor="#EE7203"
        variant="ultrawide"
      >
        <SustellFarmInfoWithMap
          formType={FormType.Add}
          customerId={customerID}
          farmSubmit={handleFarmAddedUpdated}
          handleClose={() => setOpenCreateDialog(false)}
        />
      </DialogContainer>
      <PageHeader
        title={intl.formatMessage({
          id: 'SUSTELL.MY_FARMS',
        })}
        description={intl.formatMessage({
          id: 'SUSTELL.MY_FARMS_DESCRIPTION',
        })}
        canSubject="Farm"
        createButtonText={intl.formatMessage({
          id: 'SUSTELL.ADD_FARM',
        })}
        createHandler={createFarm}
      />
      <MyFarmsV2
        loading={isLoading}
        farmItems={farms}
        customerId={customerID}
        customerName={customerName}
        queryClient={queryClient}
      />
    </>
  );
};

export default SustellMyFarmsPage;
