import React, { useEffect, useState } from 'react';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { FormType } from '../../common';

const SustellNewDynamicInput = props => {
  const { subFieldName, label, index, formState, fieldItemPrefix, onChangeSelect: selectHandler, onChangeInput: inputHandler, required } = props;
  const { errors, getValues } = useFormContext();
  const [unit, setUnit] = useState('kg');
  const intl = useIntl();

  const resolvePath = (object, path, defaultValue) =>
    path
      .split(/[.[\]'"]/)
      .filter(p => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);

  const ftName = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].type`;
  const name = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].amount`;

  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const setOtherFieldUnit = value => {
    const selItem = props.availableOptions?.find(x => x.value === value);
    if (selItem) {
      let newUnit =
        selItem.unit ||
        (userUOM?.unitBarnOutputMass
          ? unitLong(userUOM.unitBarnOutputMass)
          : 'kg');
      if (value === 'gross_energy')
        newUnit =
          userUOM?.unitBarnOutputMass && userUOM?.unitNutritionalGrossEnergy
            ? userUOM?.unitNutritionalGrossEnergy +
              '/' + 
              userUOM?.unitBarnOutputMass
            : 'MJ/kg';
      setUnit(newUnit);
    }
  };

  useEffect(() => {
    const selType = getValues(ftName);
    setOtherFieldUnit(selType);
    // only needs to update when options become available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.availableOptions]);

  const localNameError = resolvePath(errors, name, null);
  const localTypeError = resolvePath(errors, ftName, null);
  // const itemsLoaded = props.availableOptions?.some(x => x.type !== '');
  

  const fieldValue = (fieldName) => {
    const value = getValues(fieldName);
    if(value === null || value === undefined) return '';
    return value;
  }

  return (
      <DsmGrid
      style={{ 
        width: "100%",
        grid: "auto/2fr 1fr", 
        gap: "var(--dsm-spacing-px-6)", 
        padding: "0px",
        alignItems: "start",
        rowGap: "var(--dsm-spacing-px-05)"
      }}
      >
        <ReactHookDsmSelect
          key={
            `${fieldItemPrefix  }.${  subFieldName  }[${  index  }]_inputPart1`
          }
          name={ftName}
          label={label}
          disabled={ formState === FormType.View }
          defaultValue={fieldValue(ftName)}
          onChange={e => {
            setOtherFieldUnit(e.target.value);
            if (selectHandler) selectHandler(e.target.value);
            // onChange(e);
          }}
          options = {
            props.availableOptions.map(item => (
              { value: item.value, text: item.display_name }
            )
            )
          }
          error={Boolean(localTypeError)}
          required={required}
        />
        <ReactHookDsmInput
          key={
            `${fieldItemPrefix  }.${  subFieldName  }[${  index  }]_inputPart2`
          }
          name={name}
          defaultValue={fieldValue(name)}
          onChange={e => {
            if (inputHandler) inputHandler(e.target.value)
            // onChange(e)
          }}
          label={ intl.formatMessage( {id: "SUSTELL.AMOUNT" }) }
          disabled={ formState === FormType.View }
          adornment={`${unit}`}
          error={Boolean(localNameError)}
          type="number"
          required={required}
        />
      </DsmGrid>
  );
};
export default SustellNewDynamicInput;
