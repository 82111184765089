import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric, explicitConvertValue } from '../../../../sustell_15/utils/unit-utils';
import { addDateProperties } from '../../../../sustell_15/utils/datetime-utils';
import { getImpactData } from '../../../../sustell_15/components/helpers/ImpactNumbers';

const math = require('mathjs');

export const emissionFieldsMapIn = new Map([
  ['methane_enteric_fermentation', 'methaneEntericFermentation'],
  ['methane_from_manure_and_pretreatment', 'methane'],
  ['nitrous_oxide_direct_from_manure_and_pretreatment', 'nitrousOxideDirect'],
  [
    'nitrous_oxide_indirect_from_manure_and_pretreatment',
    'nitrousOxideIndirect',
  ],
  ['amonia_from_manure_and_pretreatment', 'amonia'],
  ['nitric_oxide_from_manure_and_pretreatment', 'nitricOxide'],
  ['non_methane_volatile_compunds_from_animal_housing', 'nonMethaneVolatile'],
  ['non_methane_volatile_silage_feeding', 'nonMethaneVolatileSilage'],
  ['pm_10_from_animal_housing', 'PM10'],
  ['pm_2.5_from_animal_housing', 'PM25'],
  ['total_suspended_particles', 'totalSuspendedParticles'],
  ['nitrogen_dioxide', 'nitrogenDioxide'],
  ['phosphorus', 'phosphorus'],
  ['nitrogen', 'nitrogen'],
  ['methane', 'methaneFish'],
  ['ammonia_housing', 'ammoniaHousing'],
]);

export const floatValueInUserUnits = (
  value,
  userUnit,
  defaultUnit,
  precision = 5
) => {
  if (value || value === 0) {
    if (userUnit !== defaultUnit)
      return math.round(
        explicitConvertValue(value, defaultUnit, userUnit),
        precision
      );
    return math.round(value, precision);
  }
  return value;
};

const createCoreForm = (inData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const tempValue = floatValueInUserUnits(
    inData.averageAnnualTemperature,
    userUOM.unitAverageAnnualTemperature,
    defaultMetric.unitAverageAnnualTemperature,
    1
  );

  // values in case of "copy from" are set in calling page
  const result = {
    id: inData.id,
    farmId: inData.farmId,
    productionProcessId: inData.productionProcessId,
    info:{
      name: inData.name,
      oldName: inData.oldName || inData.name,
      description: inData.description,
      year: inData.year,
      timeUnit: inData.reportingPeriod,
      numOfCyclesYear: inData.roundsPerYear,
      validFrom: inData.validFrom,
      validTo: inData.validTo,
    },
    resourceUse:{},
    stages:[],
    copyFrom: 'New',
    updatedAt: inData.updatedAt,
    lastUpdatedBy: inData.lastUpdatedBy,
    // used for Footprint box
    impactSummary:{}
  };

  if(tempValue!==undefined)
    result.info.avgAnualTemperature = tempValue;

  // add date fileds
  addDateProperties(result, 'updatedAt');

  return result;
};

const mapManureManagementForOneStage = (stageData, formManureDataIn, isDraft = false) => {
  const formManureData = formManureDataIn;
  formManureData.averageAnimalsPresent = stageData.animal_group?.number_of_animals?.value;
  const manureInput = stageData.manure_management;
  if(manureInput){
  if (manureInput.manure_management_system)
    formManureData.manureManagementSystem = manureInput.manure_management_system;

  if (
    manureInput.solid_manure_storage_on_farm?.value ||
    manureInput.solid_manure_storage_on_farm?.value === 0
  )
    formManureData.solidManureStorage = manureInput.solid_manure_storage_on_farm?.value;

  const slurryManureDefault = !isDraft ? 100 : null;
  formManureData.slurryManureStorage =
    manureInput?.slurry_manure_storage_on_farm?.value ||
    manureInput?.slurry_manure_storage_on_farm?.value ===
    0
    ? manureInput.slurry_manure_storage_on_farm.value
    : slurryManureDefault;

  if (
    manureInput?.ash_content?.value ||
    manureInput?.ash_content?.value === 0
  )
    formManureData.ashContent = manureInput?.ash_content?.value;
  }

  return formManureData;
};

const mapBeddingForOneStage = (stageData, formManureDataIn) => {
  const formManureData = formManureDataIn;
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  if (
    stageData?.bedding?.straw_for_bedding?.value ||
    stageData?.bedding?.straw_for_bedding?.value === 0
  )
    formManureData.strawForBedding = floatValueInUserUnits(
      stageData?.bedding?.straw_for_bedding?.value,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      precision
    );
  if (
    stageData?.bedding?.saw_dust?.value ||
    stageData?.bedding?.saw_dust?.value === 0
  )
    formManureData.sawDust = floatValueInUserUnits(
      stageData?.bedding?.saw_dust?.value,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass,
      precision
    );

  return formManureData;
};

const mapCompoundFeedForOneStage = (stageFeedData, formFeedData ) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  let totalFeed = 0;

  if (
    stageFeedData?.compound_feed_uses &&
    Array.isArray(stageFeedData.compound_feed_uses)
  ) {
    stageFeedData.compound_feed_uses.forEach(oneFeedData => {
      const value = floatValueInUserUnits(
        Number(oneFeedData?.quantity),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        precision
      );

      if (!isNaN(value)) totalFeed += value;
      formFeedData.compoundFeeds.push({
        feedType: oneFeedData?.entity_id,
        kgPerAnimal: value,
      });
    });
    if(formFeedData.compoundFeeds.length === 0)
      formFeedData.compoundFeeds.push ({ feedType: '', kgPerAnimal: '' });
  }

  return totalFeed;
};

const mapSingleIngridientsForOneStage = (stageFeedData, formFeedData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  let totalFeed = 0;

  if (
    stageFeedData?.single_ingredient_uses &&
    Array.isArray(stageFeedData.single_ingredient_uses)
  ) {
    stageFeedData.single_ingredient_uses.forEach(oneFeedData => {
      const value = floatValueInUserUnits(
        Number(oneFeedData?.quantity),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        precision
      );

      if (!isNaN(oneFeedData?.quantity)) totalFeed += value;
      formFeedData.singleIngredients.push({
        feedType: oneFeedData?.entity_id,
        kgPerAnimal: value,
      });
    });
    // needed if draft was saved without any single ingredient
    if(formFeedData.singleIngredients.length === 0)
      formFeedData.singleIngredients.push ({ feedType: '', kgPerAnimal: '' });
  }
  return totalFeed;
};

const mapRationForOneStage = (stageRationData, formFeedDataObject, isDraft = false) => {
  const formFeedData = formFeedDataObject;
  if (
    stageRationData?.digestibility?.value ||
    stageRationData?.digestibility?.value === 0
  )
  formFeedData.digestibility = stageRationData?.digestibility?.value;
  if (
    stageRationData?.gross_energy_intake?.value ||
    stageRationData?.gross_energy_intake?.value === 0
  )
    formFeedData.grossEnergyIntake = stageRationData?.gross_energy_intake?.value;
  if (
    stageRationData?.crude_protein_content?.value ||
    stageRationData?.crude_protein_content?.value === 0
  )
    formFeedData.crudeProteinContent = stageRationData?.crude_protein_content?.value;
  if (
    stageRationData?.silage_in_feed?.value ||
    stageRationData?.silage_in_feed?.value === 0
  )
    formFeedData.percentageOfSilage = stageRationData?.silage_in_feed?.value;
  if (
    stageRationData?.nitrogen_content?.value ||
    stageRationData?.nitrogen_content?.value === 0
  )
    formFeedData.nitrogenContent = stageRationData?.nitrogen_content?.value;

    const dryMatterDefaultValue = !isDraft ? 100 : null;

    formFeedData.dryMatterContent =
    stageRationData?.dry_matter_content?.value ||
    stageRationData?.dry_matter_content?.value === 0
      ? stageRationData?.dry_matter_content?.value
      : dryMatterDefaultValue;

  let totalFeed = 0;
  totalFeed = mapCompoundFeedForOneStage(stageRationData, formFeedData);
  totalFeed += mapSingleIngridientsForOneStage(stageRationData, formFeedData);

  if (totalFeed) formFeedData.totalFeedIntake = totalFeed;

  return formFeedData;
};

const mapEmissionForOneStage = (stageEmmisionData, formEmissionDataObject) => {
  const formEmissionData = formEmissionDataObject;
  // check if emission data exists for this stage
  if ( 
    stageEmmisionData && Array.isArray(stageEmmisionData)
  ) {
    stageEmmisionData.forEach( emission => {
      const fieldName = emissionFieldsMapIn.get(emission.key);
      formEmissionData[fieldName] = emission.value?.value;
    })
  }
};

const mapStageData = (stages, formDataObject, isDraft = false) => {
  const formData = formDataObject;
  const stageInputs = [];
  if (stages && Array.isArray(stages)) {
    stages.forEach((stage) => {
      const stageData = JSON.parse(stage.stageData);
      const oneStageFormData = {
        id: stage?.id,
        name: stage?.name,
        type: stage?.type || stageData.housing_system,
        productionSystem: stage?.type || stageData.housing_system,
        stageData:{
          input:{},
          feed:{
            compoundFeeds: [],
            singleIngredients: []
          },
          manure:{},
          emissions:{},
          output:{}
        }
      };
      // console.log("oneStageFormData", oneStageFormData);
      // some functions require isDraft flag to avoid inserting default values if draft is loaded
      mapManureManagementForOneStage(stageData, oneStageFormData.stageData.manure, isDraft);
      mapBeddingForOneStage(stageData, oneStageFormData.stageData.manure);
      mapRationForOneStage(stageData.ration, oneStageFormData.stageData.feed, isDraft);
      mapEmissionForOneStage(stageData.emissions, oneStageFormData.stageData.emissions);

      stageInputs.push(oneStageFormData);
    });
  }
  formData.stages = stageInputs;
  return formData;
};

export const mapCommonProductionDataIn = (inData, isDraft = false) => {
  const formData = createCoreForm(inData);
  mapStageData(inData.stages, formData, isDraft);
  formData.impactSummary = null;
  getImpactData(inData, formData);
  return formData;
};

export const mapResourceUse = (
  inDataResources,
  formDataResources,
  resources = ['diesel', 'gas', 'electricity', 'water', 'water_source']
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitResourcePrecision();
  
  if (resources?.includes('gas'))
    formDataResources.gasUse = floatValueInUserUnits(
      inDataResources.gasUse,
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse,
      precision
    );

  if (resources?.includes('diesel'))
    formDataResources.dieselUse = floatValueInUserUnits(
      inDataResources.dieselUse,
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse,
      precision
    );

  if (resources?.includes('electricity'))
    formDataResources.electricityUse = floatValueInUserUnits(
      inDataResources.electricityUse,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse,
      precision
    );

  if (resources?.includes('water'))
    formDataResources.waterUse = floatValueInUserUnits(
      inDataResources.waterUse,
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse,
      precision
    );
    
  if (resources?.includes('water_source') && inDataResources.waterSource )
    formDataResources.waterSource = inDataResources.waterSource;

  return formDataResources;
};
