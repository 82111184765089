import { ArcElement, Chart, Tooltip } from 'chart.js';

import { Typography } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  DSM_DARK_GREY,
  chartColors,
  footprintCategoryDetailStyles,
  impactBackgroundIconColors,
  impactColors,
} from '../../../../_metronic/layout';
import { translateKeyMapping } from '../../../sustell_15/utils/string-utils';
import { footprintDistributionSorter } from '../../helpers/sortingFunctions';
import {
  FootprintCategoryModel,
  FootprintDistributionElement,
} from '../../models/Footprint';
import { DialogContainer } from '../FarmFlow/CommonDataParts/DialogContainer';
import { ExactNumber } from '../helpers/ExactNumber';
import ChartLegend, { ChartLegendType } from './ChartLegend';
import TableWithProgressBar from './TableWithProgressBar';
import { AnimalTypeContext } from '../../pages/FootPrintPage';
import { useContext} from 'react';
import { AnimalType } from '../../../../graphql/types';
import { isShrimp, getAdjustedLabel } from '../../helpers/animals';
import { AnalysisGroupsFarm, AnalysisGroupsFeed } from '../../models/Dashboard/DashboardTypes';

type FootprintCategoryDetailProps = {
  footprintCategory: FootprintCategoryModel;
  formVisible: boolean;
  handleClose: () => void;
  selectedAnimalType?: AnimalType;
  origins?: { value: string; text: string }[];
};

const getChartColor = (label: string) => {
  let chartColor: string | undefined;

  switch (label) {
    case AnalysisGroupsFarm.Farm:
    case "Emissions":
      chartColor = chartColors.get('DSM_CYAN_LIGHTER');
      break;
    case AnalysisGroupsFarm.Ration:
    case "Feed":
      chartColor = chartColors.get('DSM_LIME_GREEN');
      break;
    case AnalysisGroupsFarm.AuxiliaryInputs:
      chartColor = chartColors.get('DSM_MAGENTA');
      break;
    case AnalysisGroupsFarm.Resources:
      chartColor = chartColors.get('DSM_DARK_ORANGE');
      break;
    case AnalysisGroupsFarm.PurchasedAnimals:
    case "Juveniles":
    case "Animal inputs":
    case "Animals":
      chartColor = chartColors.get('DSM_BLUE');
      break;
    case AnalysisGroupsFarm.Processing:
      chartColor = chartColors.get('DSM_OCEAN_GREEN');
      break;
    case AnalysisGroupsFeed.Ingredients:
    case "Ingredients & Inbound Transport":
      chartColor = chartColors.get('DSM_DARK_PURPLE');
      break;
    case AnalysisGroupsFeed.Transport:
    case "Outbound Transport":
      chartColor = chartColors.get('DSM_GREEN_DARKEST');
      break;
    default:
      chartColor = DSM_DARK_GREY;
      break;
  }

  return chartColor ?? DSM_DARK_GREY;
}

const getChartData = (
  distribution: FootprintDistributionElement[], 
) => {
  Chart.register(ArcElement, Tooltip);
  const labels: string[] = [];
  const data: number[] = [];
  const legend = [] as ChartLegendType[];
  const adjustedChartColors: string[] = [];
  const sortedDistribution = [...distribution].sort(
    footprintDistributionSorter
  );

  sortedDistribution.forEach(({ analysisGroup, percentage }, _) => {
    const adjustedLabel = analysisGroup;
    labels.push(adjustedLabel);
    data.push(Math.round(percentage));
    const labelColor = getChartColor(analysisGroup);
    legend.push({
      label: adjustedLabel,
      color: labelColor,
    });
    adjustedChartColors.push(labelColor);
  });

  return {
    chart: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: adjustedChartColors,
          borderColor: adjustedChartColors,
          borderWidth: 1,
        },
      ],
    },
    legend,
  };
};

const FootprintCategoryDetail = ({
  footprintCategory,
  formVisible,
  handleClose,
  selectedAnimalType,
  origins
}: FootprintCategoryDetailProps) => {
  const intl = useIntl();
  const {
    key,
    description,
    unit,
    amount,
    compartments,
    components,
    distribution,
  } = footprintCategory;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const animalType: string | null =
    selectedAnimalType ?? useContext(AnimalTypeContext);

  const comparisonDistribution = footprintCategory.comparison?.distribution;

  distribution.forEach((item) => {
    item.analysisGroup = getAdjustedLabel(item.analysisGroup, animalType, intl);
  });
  comparisonDistribution?.forEach((item) => {
    item.analysisGroup = getAdjustedLabel(item.analysisGroup, animalType, intl);
  });
  const compartment = compartments[0].toLowerCase();
  const chartData = getChartData(distribution);

  const isComparison = !!footprintCategory.comparison;
  const comparisonChartData = getChartData((comparisonDistribution || []));

  const classes = footprintCategoryDetailStyles();

  const formTitle = () => (
    <div style={{ marginTop: '20px' }}>
      {key}
      <br />
      {isComparison ? (
        ''
      ) : (
        <>
          <ExactNumber value={amount} />
          {` ${unit}`}
        </>
      )}
    </div>
  );

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleClose}
      variant={isComparison ? 'wide' : 'normal'}
      datasetType="footprint"
      iconCode={compartment}
      iconColor={impactColors[compartment as keyof typeof impactColors]}
      iconWidth="33px"
      formTitle={formTitle}
      introText={intl.formatMessage({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        id: `SUSTELL.FOOTPRINT.DESCRIPTION.${translateKeyMapping(key)}`,
        defaultMessage: description,
      })}
      introTextStyle={{
        color: DSM_DARK_GREY,
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
      }}
      iconBackgroundSize="65px"
      iconBackgroundColor={
        impactBackgroundIconColors[
          compartment as keyof typeof impactBackgroundIconColors
        ]
      }
      borderRadius="52px"
    >
      <Typography variant="body1" style={{ fontWeight: 500 }}>
        {intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.CONTRIBUTION_PER_CATEGORY',
        })}
      </Typography>
      <div className={`${classes.multipleCharts}`}>
        <div className={`${classes.chartContainer}`}>
          {isComparison && (
            <Typography
              variant="body1"
              style={{ fontWeight: 500, fontSize: '16px' }}
            >
              {`${footprintCategory.footprint.name} - `}
              <ExactNumber value={amount} />
              {` ${unit}`}
            </Typography>
          )}
          <div className={`${classes.chartLegend}`}>
            <div className={`${classes.chart}`}>
              <Doughnut
                data={chartData.chart}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
            <ChartLegend legend={chartData.legend} />
          </div>
          {isComparison && (
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: '400',
                marginTop: '20px',
                marginLeft: '20%',
              }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.DATASETA',
              })}
            </Typography>
          )}
        </div>
        {comparisonChartData && (
          <div className={`${classes.chartContainer}`}>
            {isComparison && (
              <Typography
                variant="body1"
                style={{ fontWeight: 500, fontSize: '16px' }}
              >
                {`${footprintCategory.comparison.footprint.name} - `}
                <ExactNumber value={footprintCategory.comparison.amount} />
                {` ${footprintCategory.comparison.unit}`}
              </Typography>
            )}
            <div className={`${classes.chartLegend}`}>
              <div className={`${classes.chart}`}>
                <Doughnut
                  data={comparisonChartData.chart}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
              <ChartLegend legend={comparisonChartData.legend} />
            </div>
            {isComparison && (
              <Typography
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  marginTop: '20px',
                  marginLeft: '20%',
                }}
              >
                {intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TABLE.COLUMNS.DATASETB',
                })}
              </Typography>
            )}
          </div>
        )}
      </div>
      {(isComparison || !isShrimp(animalType as AnimalType)) && (
        <Typography
          variant="body1"
          style={{ fontWeight: 500, marginTop: '20px' }}
        >
          {intl.formatMessage({ id: 'SUSTELL.DETAILED.CONTRIBUTION' })}
        </Typography>
      )}
      {origins && origins.length > 0 && (
        <TableWithProgressBar
          tableData={components}
          componentsColors={chartData.legend}
          isComparison={isComparison}
          animalType={animalType as AnimalType}
          origins={origins}
        />
      )}
    </DialogContainer>
  );
};

export default FootprintCategoryDetail;
