import { Grid } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import {
  SalmonIntervention,
  SalmonOutput,
} from '../../../../models/Intervention';
import { unitLong } from '../../../../utils/unit-utils';
import { ButtonControlGroup } from '../../../helpers/ButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer';
import { FormType } from '../../common';
import { SalmonInterventionFormDialogProps } from '../CommonDataParts/InterventionTypes';

const SInterventionOutputFormDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
}: SalmonInterventionFormDialogProps) => {

  const intl = useIntl();

  // TODO Fix this after coverting UserProfilePrefs to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const outputMassPrecision =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    userProfile.getUnitOutputMassPrecision() as number;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const unitOutputMass = userUOM?.unitOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitOutputMass as string)
    : 'kg';

  const currResetValue = useRef<SalmonOutput>();

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;
  const fc = useFormContext();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = {
        ...(fc.getValues(fieldItemPrefix) as SalmonOutput),
      }; // must 'spread' otherwise just reference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as SalmonIntervention;
      const stageData = resetObject?.stages?.[stageIndex]?.stageData;
      if (stageData?.output) {
        stageData.output = {
          ...currResetValue.current,
        };
        fc.reset(resetObject, {
          errors: true,
        });
      }
    }
    handleCancel();
  };

  const output = baseline?.stages?.[stageIndex]?.stageData?.output;

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="wide"
      iconCode="SALMON"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <Grid container spacing={2} direction="row" justifyContent="space-between">
        <Grid item container direction="column" xs={6} spacing={2}>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.production`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.PRODUCTION',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.PRODUCTION_TT.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.production}
              disabled={formType === 'view'}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageWeight`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.AVG_WEIGHT',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.AVG_WEIGHT.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.averageWeight}
              disabled={formType === 'view'}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.mortalities`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.MORTALITIES',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.MORTALITIES.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.mortalities}
              disabled={formType === 'view'}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.escapees`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.ESCAPEES',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.ESCAPEES.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.escapees}
              disabled={formType === 'view'}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={2}>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.animalBiomassStartOfYear`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.animalBiomassStartOfYear}
              disabled={formType === 'view'}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.liveWeight`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.AVG_SIZE_AGE',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.AVG_SIZE_AGE.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.liveWeight}
              disabled={formType === 'view'}
            />
          </Grid>
          <Grid item>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.animalBiomasEndOfYear`}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS_PRESENT',
              })}
              tooltip={
                <div>
                  {intl.formatMessage({
                    id: 'INTERVENTION.FORM.OUTPUT.SALMON.ANIMAL_BIOMASS_PRESENT.TOOLTIP',
                  })}
                </div>
              }
              metricUnit={unitOutputMass}
              precision={outputMassPrecision}
              baseline={output?.animalBiomasEndOfYear}
              disabled={formType === 'view'}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonControlGroup
            cancelHandler={handleResetClick}
            saveHandler={handleCancel}
            saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default SInterventionOutputFormDialog;
