import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Grid } from '@material-ui/core'

import { SalmonIntervention } from '../../../../models/Intervention/SalmonIntervention';
import { ListEntry } from '../../common';
import {
  InterventionStageProps,
  MasterDataEnumType,
} from '../CommonDataParts/InterventionTypes';
;
import StageButtonBox from '../../StageButtonBox_v2';
import MarineFishInterventionInputFormDialog from './MarineFishInterventionInputFormDialog';
import MarineFishInterventionFeedsFormDialog from './MarineFishInterventionFeedsFormDialog';
import MarineFishInterventionOperationsFormDialog from './MarineFishInterventionOperationsFormDialog';
import MarineFishInterventionOutputFormDialog from './MarineFishInterventionOutputFormDialog';
import { MarineFishBaseline } from '../../../../models/Baseline/MarineFishBaseline';

interface MarineFishInterventionStageProps extends InterventionStageProps {
  intervention: SalmonIntervention | null;
  baseline: MarineFishBaseline;
  // eslint-disable-next-line react/require-default-props
  masterDataEnums: MasterDataEnumType | null;
  origins: ListEntry[];
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  OPERATIONS = 'operations',
  OUTPUT = 'output',
}

const MarineFishInterventionStages = ({
  stageIndex,
  baseline,
  compoundFeeds,
  intervention,
  formType,
  masterDataEnums,
}: MarineFishInterventionStageProps) => {
  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(
    null
  );

  const showDialog = (dialog: StageDialogType | null) => {
    setActiveDialog(dialog);
  };

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.productionSystem`}
        value={`${baseline?.stages?.[stageIndex]?.productionSystem}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.index`}
        value={`${stageIndex}`}
      />
      {activeDialog === 'input' && (
        <MarineFishInterventionInputFormDialog
          formVisible={activeDialog === 'input'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}
      {activeDialog === 'feed' && (
        <MarineFishInterventionFeedsFormDialog
          formVisible={activeDialog === 'feed'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          intervention={intervention}
          formType={formType}
        />
      )}
      {activeDialog === 'operations' && (
        <MarineFishInterventionOperationsFormDialog
          formVisible={activeDialog === 'operations'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          masterDataEnums={masterDataEnums}
          intervention={intervention}
          formType={formType}
        />
      )}
      {activeDialog === 'output' && (
        <MarineFishInterventionOutputFormDialog
          formVisible={activeDialog === 'output'}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.STOCKING.TITLE"
          iconCode="spiecies/aquaculture"
          descriptionTitle="SUSTELL.SALMON.STAGE.STOCKING_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          // showCompletness={true}
          // this usafe assignment/access shouldn't be a problem as it represesnts a form hierachy
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.SALMON.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          // showCompletness={true}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE"
          iconCode="general/tool-02"
          descriptionTitle="SUSTELL.SALMON.STAGE.OPERATIONS"
          description="SUSTELL.STAGE.INPUT_DATA"
          // showCompletness={true}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.operations
          }
          handleOpen={() => showDialog(StageDialogType.OPERATIONS)}
        />
        <StageButtonBox
          titleCode="SUSTELL.SALMON.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.SALMON.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          // showCompletness={true}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        {/* This is empty placeholder to provide same layout for just four boxes */}
        <div style={{ width: '218px' }} />
      </Grid>
    </>
  );
};
export default MarineFishInterventionStages;
