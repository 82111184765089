interface ObjectProps {
  key: string;
  value: string;
}

function formatStorageKey(type = '') {
  let retVal = 'baseline_drafts';
  if (type === 'intervention') retVal = 'intervention_drafts';
  else if (type === 'compound_feed') retVal = 'compound_feed_drafts';
  return retVal;
}

export function removeStorage(key: string, type = '') {
  try {
    const storageKey = formatStorageKey(type);
    const value = localStorage.getItem(storageKey);
    const drafts = JSON.parse(value as string) as ObjectProps[];
    const filtered = drafts.filter(
      (draft: { key: string }) =>
        draft.key !== key && draft.key !== `${key}_PREFS`
    );

    if (filtered.length === 0) {
      localStorage.removeItem(storageKey);
      localStorage.removeItem(`${storageKey}_expiresIn`);
    } else {
      localStorage.setItem(storageKey, JSON.stringify(filtered));
    }
  } catch (e) {
    console.log(
      `removeStorage: Error removing key [${key}] from localStorage: ${JSON.stringify(
        e
      )}`
    );
    return false;
  }
  return true;
}

export function getStorage(key: string, type = '') {
  const now = Date.now();
  const storageKey = formatStorageKey(type);

  let expiresIn = localStorage.getItem(`${storageKey}_expiresIn`) as
    | number
    | null;
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  }
  try {
    const value = localStorage.getItem(storageKey);

    if (!value) {
      return null;
    }

    const drafts = JSON.parse(value) as ObjectProps[];
    return drafts.find((draft: { key: string }) => draft.key === key);
  } catch (e) {
    console.log(
      `getStorage: Error reading key [${key}] from localStorage: ${JSON.stringify(
        e
      )}`
    );
    return null;
  }
}

export function keyExists(key: string, type: string | undefined) {
  const storageKey = formatStorageKey(type);
  const value = localStorage.getItem(storageKey);

  if (!value) {
    return false;
  }

  const valueObject = JSON.parse(value) as ObjectProps[];
  return valueObject.some((draft: { key: string }) => draft.key === key);
}

export function setStorage(
  key: string,
  value: string,
  type: string,
  expires?: number
) {
  const storageKey = formatStorageKey(type);
  let expiresTime = expires;
  if (expires === undefined || expires === null) {
    expiresTime = 24 * 60 * 60;
  }

  const now = Date.now();
  const schedule = now + (expiresTime ? expiresTime * 1000 : 0);
  try {
    const storageValue = localStorage.getItem(storageKey);
    let drafts: ObjectProps[] = [];

    if (storageValue) {
      drafts = JSON.parse(storageValue) as ObjectProps[];
    }

    const objIndex = drafts.findIndex(
      (obj: { key: string }) => obj.key === key
    );
    if (objIndex !== -1) {
      drafts[objIndex].value = value;
    } else {
      drafts.push({ key, value });
    }

    localStorage.setItem(storageKey, JSON.stringify(drafts));
    localStorage.setItem(`${storageKey}_expiresIn`, String(schedule));
  } catch (e) {
    console.log(
      `setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(
        e
      )}`
    );
    return false;
  }
  return true;
}
