/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { FormType, ListEntry, MMSSystemsBlockProps } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { ManureManagementSystem } from '../../../../models/Baseline/PoultryBaseline';
import { ManureManagementSystemType } from '../../../../../../graphql/generated/blonk/poultry';
import { Maybe } from '../../../../../../graphql/types';
import { CSSClassesList, ReactChangedType } from '../../../../helpers/helperTypes';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import RowSelectWithMetricsV2 from '../../../../../modules/Farms/Intervention/RowSelectWithMetricsV2';

interface MMSBlockComponentProps extends MMSSystemsBlockProps {
  manureSystem: ManureManagementSystem;
}

const PoultryInverventionMMSBlockComponent: FC<MMSBlockComponentProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType = FormType.View,
  manureManagementSystems,
  filteredMMSList,
  mmsChangeHandler,
  manureSystem,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${stageIndex}.stageData.housing.manureSystems.${itemIndex}`;
  const [matchingStorages, setMatchingStorages] = useState<ListEntry[]>([]);
  const mmsLoaded = useRef<boolean>(false);
  const fc = useFormContext();

  const showShare = (): boolean => {
    const manureSystems = fc.getValues(`stages.${stageIndex}.stageData.housing.manureSystems`) as ManureManagementSystem[];
    const count = manureSystems?.length || 0;
    const manureSystemsAdditions = fc.getValues(`stages.${stageIndex}.stageData.housing.manureSystemsAdditions`) as ManureManagementSystem[];
    const interventionCount = manureSystemsAdditions?.length || 0;
    mmsLoaded.current = manureSystem && manureSystemsAdditions && (count + interventionCount) > 0;  
    return (count + interventionCount) > 1;
  };

  const filterMatchingStorage = (value: string) => {
    const selBaseName = value;
    if (selBaseName) {
      const newMatchingStorages = manureManagementSystems?.filter((item) => item.value.startsWith(selBaseName) && item.value.endsWith('MONTH')) || [];
      newMatchingStorages.forEach((item) => {
        const currItem = item;
        const firstDigit = item.text.search(/\d/);
        let substringIndex = firstDigit;
        const overIndex = item.text.lastIndexOf(' under');
        const underIndex = item.text.lastIndexOf(' over');
        if (overIndex > -1) substringIndex = overIndex;
        if (underIndex > -1) substringIndex = underIndex;
        currItem.text = item.text.substring(substringIndex).replace('month', ' month');
      });
      setMatchingStorages(newMatchingStorages);
      if (newMatchingStorages.length === 0) fc.setValue(`${fieldItemPrefix}.mmsHoldingDuration`, null);
    }
  };

  const revalidateMMS = () => {
    fc.trigger(`stages.${stageIndex}.stageData.housing.manureSystems`).then(() => {}).catch(() => {});
  };

  // initial set local manure form
  // if not set, setup inputs values
  // for some reason, on first open of dialog formContex.getValues will not return values
  // that are set using value property of that component
  useEffect(() => {
    const mmsForm = fc.getValues(fieldItemPrefix) as ManureManagementSystem;
    if (mmsForm?.id === undefined && manureSystem.id)
      fc.setValue(`${fieldItemPrefix}.id`, manureSystem.id);
    if (mmsForm?.mmsType === undefined && manureSystem.mmsType) {
      fc.setValue(`${fieldItemPrefix}.mmsType`, manureSystem.mmsType);
      filterMatchingStorage(manureSystem.mmsType);
    }
    if (mmsForm?.mmsHoldingDuration === undefined)
      fc.setValue(`${fieldItemPrefix}.mmsHoldingDuration`, manureSystem.mmsHoldingDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manureSystem]);

  // if addition mms is changed, check should share be visible and if not and set share
  useEffect(() => {
    if (!showShare() && mmsLoaded.current) {
      // share input is hidden
      // if baseline is 100, reset share
      if(manureSystem?.share === 100) {
        fc.setValue(`${fieldItemPrefix}.share`, '');
        fc.setValue(`${fieldItemPrefix}.share_changeMetric`, 'relative');
      }
      // case when baseline is not 100 and share is not visible 
      // this will cause error in validation without possibility to change share
      // set intervention to 100
      else {
        fc.setValue(`${fieldItemPrefix}.share`, '100');
        fc.setValue(`${fieldItemPrefix}.share_changeMetric`, 'set');
      }
    }
  });

  const onMMSChangeStorages = () => {
    if (mmsChangeHandler) mmsChangeHandler();
    revalidateMMS();
  };

  const onMMSChange = (e: ReactChangedType) => {
    const eventTarget = e.target;
    filterMatchingStorage(eventTarget.value as string);
    if (mmsChangeHandler) mmsChangeHandler();

    // if value same as the baseline's, set holdingDuration from the baseline
    if (eventTarget.value === manureSystem?.mmsType)
      fc.setValue(`${fieldItemPrefix}.mmsHoldingDuration`, manureSystem?.mmsHoldingDuration);
    // if not, reset value
    else fc.setValue(`${fieldItemPrefix}.mmsHoldingDuration`, '');

    // if not showing share, reset value
    if (!showShare()) {
      // share input is hidden
      // if baseline is 100, reset share
      if(manureSystem?.share === 100) {
        fc.setValue(`${fieldItemPrefix}.share`, '');
        fc.setValue(`${fieldItemPrefix}.share_changeMetric`, 'relative');
      }
      // case when baseline is not 100 and share is not visible 
      // this will cause error in validation without possibility to change share
      // set intervention to 100
      else {
        fc.setValue(`${fieldItemPrefix}.share`, '100');
        fc.setValue(`${fieldItemPrefix}.share_changeMetric`, 'set');
      }
    }
    revalidateMMS();
  };

  const currentMMSCount = ((fc.getValues(`stages.${stageIndex}.stageData.housing.manureSystems`) || [])?.length || 0) as number;
  const className =  currentMMSCount > 1 ? classes.additionalEntriesBlockHolder : '';

  // get the human redable label for duration, for baseline value
  const getDurationText = (baselineMmsType: string, baselineMmsHoldingDuration: Maybe<ManureManagementSystemType> | undefined): string => {
    let retVal: string | undefined | null = baselineMmsHoldingDuration as string;
    if (baselineMmsType && baselineMmsHoldingDuration) {
      const newMatchingStorages = manureManagementSystems?.filter((item) => item.value.startsWith(baselineMmsType) && item.value.endsWith('MONTH')) || [];
      newMatchingStorages.forEach((item) => {
        const currItem = item;
        const firstDigit = item.text.search(/\d/);
        // check if there is over / under in the name
        let substringIndex = firstDigit;
        const overIndex = item.text.lastIndexOf(' under');
        const underIndex = item.text.lastIndexOf(' over');
        if (overIndex > -1) substringIndex = overIndex;
        if (underIndex > -1) substringIndex = underIndex;
        currItem.text = item.text.substring(substringIndex).replace('month', ' month');
      });
      retVal = newMatchingStorages?.find((item) => item.value === baselineMmsHoldingDuration)?.text || baselineMmsHoldingDuration;
    }
    return retVal;
  };

  useEffect(() => {
    filterMatchingStorage(fc.getValues(`${fieldItemPrefix}.mmsType`));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={manureSystem?.id}
      />
      <DsmGrid className={classes.dsmGridTwoColumn}>
        <input
          type="hidden"
          value={manureSystem?.mmsHoldingDuration || manureSystem?.mmsType}  
          ref={fc.register()}
          name={`${fieldItemPrefix}.mmsType_old`}
        />
        <RowSelectWithMetricsV2
          label={intl.formatMessage({id: 'SUSTELL.STAGE.PIGS.MANURE.MMS'})}
          name={`${fieldItemPrefix}.mmsType`}
          disabled={formType === FormType.View}
          options={filteredMMSList}
          baseline={manureSystem?.mmsType}
          changeHandler={onMMSChange}
          defaultValue={fc.getValues(`${fieldItemPrefix}.mmsType`) || manureSystem?.mmsType}
        />
        {matchingStorages && matchingStorages.length > 0 && (
          <RowSelectWithMetricsV2
            label={intl.formatMessage({id: 'SUSTELL.STAGE.PIGS.HOUSING.HOLDING_PERIOD'})}
            name={`${fieldItemPrefix}.mmsHoldingDuration`}
            disabled={formType === FormType.View}
            options={matchingStorages}
            // the baseline mms type can be different than selected one, thats why we need to lookup duration name with this function
            baseline={getDurationText(manureSystem?.mmsType, manureSystem?.mmsHoldingDuration)}
            changeHandler={onMMSChangeStorages}
            defaultValue={fc.getValues(`${fieldItemPrefix}.mmsHoldingDuration`) || manureSystem?.mmsHoldingDuration}
          />
        )}
      </DsmGrid>
      {showShare() && (
        <RowTextFieldWithMetrics
          name={`${fieldItemPrefix}.share`}
          label={intl.formatMessage({id: 'SUSTELL.STAGE.PIGS.HOUSING.MMS.SHARE'})}
          tooltip={intl.formatMessage({id: 'SUSTELL.STAGE.PIGS.HOUSING.MMS.SHARE'})}
          metricUnit={intl.formatMessage({id: 'SUSTELL.STAGE.PIGS.HOUSING.MMS.SHARE.UNIT'})}
          baseline={manureSystem ? manureSystem.share : null}
          disabled={formType === FormType.View}
        />
      )}
    </div>
  );
};

export default PoultryInverventionMMSBlockComponent;
