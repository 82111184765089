/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DsmFieldset } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { PoultryBaseline, PoultryOutputGrowing } from '../../../../models/Baseline/PoultryBaseline';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

const PoultryOutputGrowingDialog: FC<BaselineDialogProps> = ({
  formType=FormType.Add,
  itemIndex=0,
  formVisible,
  handleCancel
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<PoultryOutputGrowing>();
  const formTitle = intl.formatMessage({"id": "SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"});
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const fc = useFormContext<PoultryBaseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);

  useEffect(() => {
    if(formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = ()=>{
    if (currResetValue.current){
      const resetObject = {...fc.getValues()} as PoultryBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.output) {
        resetObject.stages[itemIndex].stageData.output = {...currResetValue.current};
        fc.reset(resetObject, {errors: true});
      }
    }
    handleCancel('reset');
  }

  return (
    <DialogContainer
      formVisible = {formVisible}
      handleClose = {handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle = {formTitle}
    > 
      <DsmFieldset >
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalToStage`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_TO_STAGE"})}
          adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_TO_STAGE.PLACEHOLDER"})}
          disabled={formType === FormType.View}
          focusLostHandler={() => {
            fc.trigger(`${fieldItemPrefix}.totalToStage`); 
            fc.trigger(`${fieldItemPrefix}.totalSold`);
          }}
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_TO_STAGE.TOOLTIP"})}
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.totalToStage`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.averageWeight`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.AVERAGE_WEIGHT"})}
          adornment={barnOutputMassUnit}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.AVERAGE_WEIGHT.TOOLTIP"})}
          required
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.averageWeight`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.totalSold`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_SOLD"})}
          adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.TOTAL_SOLD.PLACEHOLDER"})}
          disabled={formType === FormType.View}
          focusLostHandler={() => {
            fc.trigger(`${fieldItemPrefix}.totalToStage`); 
            fc.trigger(`${fieldItemPrefix}.totalSold`);
          }}
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.totalSold`)}
        />
      </DsmFieldset>
      <DsmFieldset >
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.mortalityAtPercent`}
          label={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.MORTALITY_RATE"})}
          adornment={intl.formatMessage({id: "SUSTELL.STAGE.POULTRY.OUTPUT.MORTALITY_RATE.PLACEHOLDER"})}
          disabled={formType === FormType.View}
          type="number"
          required
          defaultValue={fc.getValues(`${fieldItemPrefix}.mortalityAtPercent`)}
        />
      </DsmFieldset>
      <DsmFieldset>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.weightAtMortality`}
          label={intl.formatMessage({ id: "SUSTELL.STAGE.POULTRY.OUTPUT.WEIGHT_AT_MORTALITY"})}
          adornment={barnOutputMassUnit}
          type="number"
          disabled={formType === FormType.View}
          defaultValue={fc.getValues(`${fieldItemPrefix}.weightAtMortality`)}
        />
      </DsmFieldset>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {await fc.trigger(`stages.${itemIndex}.stageData.output`); handleCancel('confirm')}}
        saveLabel={intl.formatMessage({id: "GENERAL.CONFIRM" })}/>
    </DialogContainer>
  );
};

export default PoultryOutputGrowingDialog;