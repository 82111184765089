/* eslint-disable no-param-reassign */
import { IntlShape } from 'react-intl';
import { CompoundFeedDatabase, CreateCompoundFeedInputV2, IngredientUsesV2Input, Match, UpdateCompoundFeedInputV2 } from "../../../../graphql/types";
import { getOriginOrProxyV2Ingredients } from "./apiHelpers";
import { IngredientNamesAndOriginsV2 } from "./types";

export enum ErrorType {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export interface IngredientValidationCorretion {
  message: string;
  type: ErrorType;
  correction?: {
    rowIndex: number;
    ingredientResponse: Match
  }
}

interface IngredientUseWithRowIndex extends IngredientUsesV2Input {
  rowIndex: number;
}

const createRequestedIngrediensObj = (ingredientUses: IngredientUsesV2Input[]): {origin: string; name:string} [] => {
  const requestedIngredients: {origin: string; name:string} [] = [];
  ingredientUses.forEach((ingUse) => {
    if (!ingUse.ingredient.addonType) {
      const {name} = ingUse.ingredient;
      // For request use origininal set origin, then compare response origin with 
      const origin = ingUse.originalOrigin ? ingUse.originalOrigin : ingUse.ingredient.origin;
      requestedIngredients.push({
        origin,
        name
      })
    }
  });
  return requestedIngredients;
}

const checkSingleIngredients = async (
  ingredients: IngredientUsesV2Input[],
  db: CompoundFeedDatabase,
  customerid: string,
  intl: IntlShape
): Promise<IngredientValidationCorretion[]> => {

  let retVal: IngredientValidationCorretion[] = [];

  const requestedIngredients = createRequestedIngrediensObj(ingredients);
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/no-floating-promises
  try {
    // console.log('requestedIngredients')
    const response = await getOriginOrProxyV2Ingredients(requestedIngredients, customerid, db);
    // If request is successfull, check all origins etc 
    (response || []).forEach((responseItem, index) => {
      const ingredientUse = ingredients[index];
      let errorMessage = intl.formatMessage({id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.INGREDIENT_AT_ROW"}, {
        row: (ingredientUse as IngredientUseWithRowIndex).rowIndex + 1,
        name: ingredientUse.ingredient.name
      });
      // Check proxy
      if (responseItem.isProxy) {
        if (responseItem.selectedIngredient?.name !== ingredientUse.ingredient.name) {
          errorMessage += intl.formatMessage({id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.INGREDIENT_AT_ROW.INVALID_ORIGIN"}, {
            origin: ingredientUse.originalOrigin || 'unknown',
           });
  
          retVal.push({
            message: errorMessage,
            type: ErrorType.ERROR
          });
        }
        else if ((!ingredientUse.isProxy || !ingredientUse.originalOrigin) && responseItem.selectedIngredient?.origin) {
          errorMessage += intl.formatMessage({id: "COMPOUNDFEED_WIZARD.VALIDATION_WARNIGNGS.INGREDIENT_AT_ROW.CHANGED_ORIGIN"}, {
            origin: ingredientUse.ingredient.origin,
            proxyOrigin: responseItem.selectedIngredient?.origin,
            name: responseItem.requestedIngredient?.name,
            selectedName: responseItem.selectedIngredient?.name,
          });

          retVal.push({
            message: errorMessage,
            type: ErrorType.WARNING,
            correction: {
              rowIndex: (ingredientUse as IngredientUseWithRowIndex).rowIndex,
              ingredientResponse: responseItem
            }
          });

        }  
        else if (
          (
            (responseItem.selectedIngredient?.origin !== ingredientUse.ingredient.origin && 
            responseItem.requestedIngredient?.origin !== ingredientUse.originalOrigin) ||
            (!ingredientUse.isProxy || !ingredientUse.originalOrigin)
          ) && responseItem.selectedIngredient?.origin
        ) {
          errorMessage += intl.formatMessage({id: "COMPOUNDFEED_WIZARD.VALIDATION_WARNIGNGS.INGREDIENT_AT_ROW.CHANGED_ORIGIN"}, {
            origin: ingredientUse.ingredient.origin,
            proxyOrigin: responseItem.selectedIngredient?.origin,
            name: responseItem.requestedIngredient?.name,
            selectedName: responseItem.selectedIngredient?.name,
          });

          retVal.push({
            message: errorMessage,
            type: ErrorType.WARNING,
            correction: {
              rowIndex: (ingredientUse as IngredientUseWithRowIndex).rowIndex,
              ingredientResponse: responseItem
            }
          });
        }
        else if (
          responseItem.selectedIngredient?.origin &&
          (responseItem.requestedIngredient?.origin !==
            ingredientUse.originalOrigin ||
            responseItem.selectedIngredient?.origin !==
              ingredientUse.ingredient.origin)
        ) {
          errorMessage += intl.formatMessage({id: "COMPOUNDFEED_WIZARD.VALIDATION_WARNIGNGS.INGREDIENT_AT_ROW.CHANGED_ORIGIN"}, {
            origin: ingredientUse.ingredient.origin,
            proxyOrigin: responseItem.selectedIngredient?.origin,
            name: responseItem.requestedIngredient?.name,
            selectedName: responseItem.selectedIngredient?.name,
          });

          retVal.push({
            message: errorMessage,
            type: ErrorType.WARNING,
            correction: {
              rowIndex: (ingredientUse as IngredientUseWithRowIndex).rowIndex,
              ingredientResponse: responseItem
            }
          });
        }
      }  
      else if (responseItem.selectedIngredient?.origin !== ingredientUse.ingredient.origin || responseItem.selectedIngredient?.name !== ingredientUse.ingredient.name) {
        errorMessage += intl.formatMessage({id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.INGREDIENT_AT_ROW.INVALID_ORIGIN"}, {
          origin: ingredientUse.originalOrigin || 'unknown',
      });

        retVal.push({
          message: errorMessage,
          type: ErrorType.ERROR
        });
      } 
    });
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (err?.errors) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      retVal = err.errors.map((item?: { message: any; }) => ({
       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
       message:  item?.message || '',
       type: ErrorType.ERROR
      }));
    }
  }
  return retVal;
}

const checkAddons = (
  addonUses: IngredientUsesV2Input[],
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2,
  intl: IntlShape
  ): IngredientValidationCorretion[] => {
    let retVal: IngredientValidationCorretion[] = [];
    const errors: string[] = [];
    addonUses.forEach((addonIngUse) => {
      const addonGroup = ingredientOriginsNamesV2?.addons.find(ag => ag.addonType === addonIngUse.ingredient.addonType);
      if (!addonGroup) {
        errors.push(
          intl.formatMessage({
            id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.ADDON_GROUP_INVALID"
          }, {
            addonType: addonIngUse.ingredient.addonType || 'unknown',
            row: (addonIngUse as IngredientUseWithRowIndex).rowIndex + 1,
            name: addonIngUse.ingredient.name
          })
        )
        return;
      }
      const findAddon = addonGroup?.ingredients.find(a => a.name === addonIngUse.ingredient.name);
      if (!findAddon) {
        errors.push(
          intl.formatMessage({
            id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.ADDON_INVALID"
          }, {
            row: (addonIngUse as IngredientUseWithRowIndex).rowIndex + 1,
            name: addonIngUse.ingredient.name
          })
        )
        return;
      }
      const findOrigin = addonGroup.ingredients.find(a => a.name === addonIngUse.ingredient.name && a.origin === addonIngUse.ingredient.origin);
      if (!findOrigin) {
        errors.push(
          intl.formatMessage({
            id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.ADDON_ORIGIN_INVALID"
          }, {
            row: (addonIngUse as IngredientUseWithRowIndex).rowIndex + 1,
            name: addonIngUse.ingredient.name,
            origin: addonIngUse.ingredient.origin
          })
        )
        return;
      }

      if (addonIngUse.originalOrigin) {
        errors.push(
          intl.formatMessage({
            id: "COMPOUNDFEED_WIZARD.VALIDATION_ERRORS.PROXY_USED"
          }, {
            row: (addonIngUse as IngredientUseWithRowIndex).rowIndex + 1,
            name: addonIngUse.ingredient.name
          })
        )
      }
    });

    retVal = errors.map(e => ({
      message: e,
      type: ErrorType.ERROR
    }));

    return retVal;
}

const isAddon = (
  name: string,
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2
) =>
  ingredientOriginsNamesV2?.addons.some(
    (group) =>
      group.ingredients.findIndex((addon) => addon.name === name) > -1 &&
      ingredientOriginsNamesV2.ingredientNames.findIndex(
        (item) => item.name === name
      ) === -1
  );


const validateIngredientOrigins = async (
  feed: CreateCompoundFeedInputV2 | UpdateCompoundFeedInputV2,
  db: CompoundFeedDatabase,
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2,
  customerId: string,
  intl: IntlShape
  ): Promise<{ messages: IngredientValidationCorretion[]; hasErrors: boolean; }> => {
  
  // Save row indexes for later correction
  const ingredientUses = feed.ingredientUses?.map((el, index) => ({...el, rowIndex: index }) as IngredientUseWithRowIndex);

  const singleIngredientsUses = ingredientUses?.filter(ing => !isAddon(ing.ingredient.name, ingredientOriginsNamesV2) && !ing.ingredient.addonType) || [];
  const addonIngredientUses = ingredientUses?.filter(ing => isAddon(ing.ingredient.name, ingredientOriginsNamesV2) || ing.ingredient.addonType) || [];
  
  const ingErrors =  await checkSingleIngredients(singleIngredientsUses, db, customerId, intl);
  const addonErrors = checkAddons(addonIngredientUses, ingredientOriginsNamesV2, intl);

  const allWarningsAndMessages = [...addonErrors, ...ingErrors];
  return {
    messages: allWarningsAndMessages,
    hasErrors: allWarningsAndMessages.some(mes => mes.type === ErrorType.ERROR)
  }
}

export default validateIngredientOrigins;
