import { Grid } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DairyBaseline, LatestCalc3NOP } from '../../../../models/Baseline';
import { DairyIntervention } from '../../../../models/Intervention';
import StageButtonBox from '../../StageButtonBox';
import InterventionFeedsFormDialog from '../CommonDataParts/InterventionFeedsFormDialog';
import { InterventionStageProps } from '../CommonDataParts/InterventionTypes';
import DInterventionEmissionsFormDialog from './DInterventionEmissionsFormDialog';
import DInterventionFeedsVariablePart from './DInterventionFeedsVariablePart';
import DInterventionManureFormDialog from './DInterventionManureFormDialog';
import DInterventionOutputFormDialog from './DInterventionOutputFormDialog';

interface DairyInterventionStageProps extends InterventionStageProps {
  intervention: DairyIntervention | null;
  baseline: DairyBaseline;
  farmCountry: string;
}

enum StageDialogType {
  INPUT = 'input',
  FEED = 'feed',
  MANURE = 'manure',
  EMISSIONS = 'emissions',
  OUTPUT = 'output',
}

const DairyInterventionStages = ({
  stageIndex,
  baseline,
  manureManagementSystems,
  singleIngredients,
  compoundFeeds,
  intervention,
  farmCountry, // needed for 3NOP calculateion
  formType,
}: DairyInterventionStageProps) => {
  
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(
    null
  );
  const fc = useFormContext();

  const stagePrefix = `stages.${stageIndex}`;

  const emissions = baseline?.stages?.[stageIndex]?.stageData?.emissions;

  const bovaerOriginalCalcResult = emissions?.supplement3NOP?.calculatedResult;
  const bovaerNewCalcResult = useWatch({
    control: fc.control,
    name: `${stagePrefix}.stageData.emissions.supplement3NOP.calculatedResult`,
  }) as string;
  // This is to calculate total methane value based on 3NOP, input and interventions on them
  const methaneOriginalValue =
    baseline?.stages?.[stageIndex]?.stageData?.emissions
      ?.methaneEntericFermentation;

  const is3NOPChecked = useWatch({
    control: fc.control,
    name: `${stagePrefix}.stageData.emissions.is3NOPSelected`,
  }) as boolean;

  // save latest calculation to inform user to recalculate if inputs change
  const [latestCalc, setLatestCalc] = useState<LatestCalc3NOP | null>(null);

  const toNum = (val: number | string) => {
    if (typeof val !== 'number') {
      // eslint-disable-next-line no-param-reassign
      val = parseFloat(val);
    }
    return val;
  };

  const updateTotalMethaneFE = (initial?: boolean) => {
    const fieldItemPrefixEmissions = `stages.${stageIndex}.stageData.emissions`;
    const methaneNewValue = fc.getValues(
      `${fieldItemPrefixEmissions}.methaneEntericFermentation_newValue`
    ) as string;

    if (
      (!initial && !is3NOPChecked) ||
      (!bovaerNewCalcResult &&
        !bovaerOriginalCalcResult &&
        !methaneNewValue &&
        !methaneOriginalValue)
    ) {
      fc.setValue(
        `${fieldItemPrefixEmissions}.methaneEntericFermentationTotal`,
        null
      );
      return;
    }
    let methaneFromEmissions = null;
    if (methaneNewValue) {
      methaneFromEmissions = toNum(methaneNewValue);
    } else if (methaneOriginalValue) {
      methaneFromEmissions = toNum(methaneOriginalValue);
    } else if (!methaneNewValue && !methaneOriginalValue) {
      methaneFromEmissions = 0;
    }
    let methaneCorrection = null;
    if (bovaerNewCalcResult) {
      methaneCorrection = toNum(bovaerNewCalcResult);
    } else if (bovaerOriginalCalcResult) {
      methaneCorrection = toNum(bovaerOriginalCalcResult);
    }

    if (Number.isNaN(methaneFromEmissions) || Number.isNaN(methaneCorrection)) {
      fc.setValue(
        `${fieldItemPrefixEmissions}.methaneEntericFermentationTotal`,
        null
      );
      return;
    }

    if (methaneFromEmissions != null && methaneCorrection != null) {
      const totalMethane = methaneFromEmissions + methaneCorrection;
      // if (!Number.isNaN(totalMethane)) {
      fc.setValue(
        `${fieldItemPrefixEmissions}.methaneEntericFermentationTotal`,
        totalMethane
      );
      // }
    }
  };

  const averageAnimalsPresentRef = useRef<number | string | null>(null);
  const manure = baseline?.stages[stageIndex]?.stageData?.manure;

  // set initial total methane
  useEffect(() => {
    updateTotalMethaneFE(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseline, intervention]);

  // When baseline is changed check if there is 3-NOP and check the checkbox if needed
  // was useMemo
  useEffect(() => {
    let initialChecked = emissions?.is3NOPSelected;
    if (
      (stageIndex || stageIndex === 0) &&
      intervention?.stages &&
      intervention?.stages[stageIndex]
    ) {
      initialChecked =
        initialChecked ||
        intervention?.stages[stageIndex]?.stageData?.emissions?.is3NOPSelected;
    }
    fc.setValue(
      `${stagePrefix}.stageData.emissions.is3NOPSelected`,
      initialChecked
    );
    // it won't work properly if fc kept in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emissions, intervention, stagePrefix, stageIndex]);


  useEffect(() => {
    const getAverageAnimalsPresent = () => {
      if (!manure) return null;
      if (stageIndex < 0) return null;

      if (
        intervention &&
        intervention.stages &&
        intervention.stages.length > 0 &&
        intervention.stages[stageIndex]
      ) {
        const baselineVal = manure.averageAnimalsPresent;
        const interventionVal =
          intervention.stages[stageIndex].stageData?.manure
            ?.averageAnimalsPresent;
        const metric =
          intervention.stages[stageIndex].stageData?.manure
            ?.averageAnimalsPresent_changeMetric;

        if (metric && (interventionVal || interventionVal === 0)) {
          if (metric === 'set') return interventionVal;
          if (metric === 'absolute')
            return toNum(baselineVal) + toNum(interventionVal);
          return toNum(baselineVal) * (1 + toNum(interventionVal) / 100);
        }
      }

      if (manure) {
        return manure.averageAnimalsPresent;
      }

      return null;
    };
    averageAnimalsPresentRef.current = getAverageAnimalsPresent();
  }, [intervention, manure, stageIndex]);

  const showDialog = (dialog: StageDialogType | null) => {
    setActiveDialog(dialog);
  };

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.productionSystem`}
        value={`${baseline?.stages?.[stageIndex]?.productionSystem}`}
      />
      <InterventionFeedsFormDialog
        formVisible={activeDialog === 'feed'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        intervention={intervention}
        formType={formType}
      >
        <DInterventionFeedsVariablePart
          formType={formType}
          stageIndex={stageIndex}
          baseline={baseline}
        />
      </InterventionFeedsFormDialog>
      <DInterventionManureFormDialog
        formVisible={activeDialog === 'manure'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        averageAnimalsPresentRef={averageAnimalsPresentRef}
        stageIndex={stageIndex}
        manureManagementSystems={manureManagementSystems}
        formType={formType}
      />
      <DInterventionEmissionsFormDialog
        formVisible={activeDialog === 'emissions'}
        handleCancel={() => setActiveDialog(null)}
        country={farmCountry}
        baseline={baseline}
        is3NOPChecked={is3NOPChecked}
        updateTotalMethaneFE={updateTotalMethaneFE}
        latestCalc={latestCalc}
        setLatestCalc={setLatestCalc}
        bovaerOriginalCalcResult={bovaerOriginalCalcResult}
        averageAnimalsPresentRef={averageAnimalsPresentRef}
        bovaerNewCalcResult={bovaerNewCalcResult}
        intervention={intervention}
        stageIndex={stageIndex}
        formType={formType}
      />
      <DInterventionOutputFormDialog
        formVisible={activeDialog === 'output'}
        handleCancel={() => setActiveDialog(null)}
        baseline={baseline}
        stageIndex={stageIndex}
        formType={formType}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="DAIRY_INPUT"
          showCompletness={false}
          disabled
          error={undefined}
          handleOpen={() => {}}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="feedIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages?.[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="manureIcon"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages?.[stageIndex]?.stageData?.manure
          }
          handleOpen={() => showDialog(StageDialogType.MANURE)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="DAIRY"
          showCompletness={false}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages?.[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        <StageButtonBox
          showCompletness={false}
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="emissionIcon"
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fc.errors?.stages?.[stageIndex]?.stageData?.emissions
          }
          handleOpen={() => showDialog(StageDialogType.EMISSIONS)}
        />
      </Grid>
    </>
  );
};

export default DairyInterventionStages;
