import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { cloneDeep } from 'lodash';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds_v2';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { InterventionDialogProps, MasterDataEnumType } from '../CommonDataParts/InterventionTypes';
import { BaselineDynamicItem, BaselineFeed } from '../../../../models/Baseline';
import { CompoundFeedDatabase, MasterDataOption, Maybe } from '../../../../../../graphql/types';
import {
  CommonInterventionFeed,
  InterventionFeed,
  SalmonIntervention,
  SalmonFeed
} from '../../../../models/Intervention';
import { FormType } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import RowControlledTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { CFDatabaseFoundation } from '../../../../models/CompoundFeed';
import { MarineFishBaseline } from '../../../../models/Baseline/MarineFishBaseline';
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import lookupValues from '../../../../helpers/lookupValues';
import SustellDynamicInput from '../../Baseline/MarineFish/SustellDynamicInput';

interface MarineFishInterventionFeedFormDialogProps
  extends InterventionDialogProps {
  baseline: MarineFishBaseline;
  // eslint-disable-next-line react/require-default-props
  compoundFeeds?: Maybe<Array<MasterDataOption>>;
  // eslint-disable-next-line react/require-default-props
  intervention: SalmonIntervention | null;
  masterDataEnums?: MasterDataEnumType | null;
}

interface CompoundFeed {
  display_name: string;
  value: string;
  databaseFoundation: CompoundFeedDatabase;
}

const MarineFishInterventionFeedsFormDialog = ({
  formVisible,
  stageIndex = 0,
  handleCancel,
  formType = FormType.Add,
  masterDataEnums = null,
  baseline,
  compoundFeeds,
  intervention,
}: MarineFishInterventionFeedFormDialogProps) => {
  const intl = useIntl();
  const currResetValue = useRef<SalmonFeed>();

  const classes = processAndStageStylesV2() as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  
  const getCompoundFeedsList = (databaseOption: CompoundFeedDatabase) => {
    const feeds = (compoundFeeds as unknown as CompoundFeed[]) || [];
    return feeds.filter(
      (feed: CompoundFeed) => feed.databaseFoundation === databaseOption
    );
  };

  const avialableAntibiotics = getTranslatedEnumOrDefaultLookupValues(
    masterDataEnums?.['AntibioticType' as keyof typeof masterDataEnums] || [],
    lookupValues.antibiotics,
    intl,
    'MASTERDATA.ENUM.ANITIBIOTIC_USE'
  );

  const avialableAntibioticsFiltered = avialableAntibiotics.filter(
    (item) =>
      !baseline.stages[stageIndex]?.stageData?.feed?.antibioticsUse?.some(
        (antibiotic) => antibiotic.type === item.value
      )
  );

  const [antibioticsUseAddition, setAntibioticsUseAddition] = useState<
    BaselineDynamicItem[] | null
  >([]);

  const existingAdditionsSet = useRef(false);

  const existingFeedsSet = useRef<boolean>(false);

  const fieldItemPrefix = `stages.${stageIndex}.stageData.feed`;

  // const { getValues, setValue, reset, register, trigger } = useFormContext();
  const fc = useFormContext();

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as SalmonIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData;
      if (stageData?.feed) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        stageData.feed = { ...currResetValue.current };
        fc.reset(resetObject, {
          errors: true,
        });
        // reset compound feeds and single ingredients data
        // to previously valid (confirmed)
        setAntibioticsUseAddition(currResetValue.current?.antibioticsUseAddition || null);
      }
    }
    if (handleCancel) handleCancel();
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : 'kg';

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  const {
    fields: feedsAdditions,
    append: appendFeedsAdditions,
    remove: removeFeedsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.compoundFeedsAdditions`, // TODO: check field names
    control: fc.control,
    keyName: 'keyId',
  });

  const recalculateTotalFeedIntake = useCallback(() => {
    // calculate intervention feed value
    const calculateFeedValue = (
      baselineFeed: BaselineFeed,
      interventionFeeds?: Maybe<InterventionFeed[]>
    ) => {
      const interventionFeed = interventionFeeds?.find(
        (item) => item.feedType === baselineFeed.feedType
      );

      if (interventionFeed) {
        if (interventionFeed.kgPerAnimal_changeMetric === 'set')
          return Number(interventionFeed.kgPerAnimal) || 0;

        if (interventionFeed.kgPerAnimal_changeMetric === 'absolute')
          return (
            (Number(interventionFeed.kgPerAnimal) || 0) +
            (Number(baselineFeed.kgPerAnimal) || 0)
          );

        return (
          (Number(baselineFeed.kgPerAnimal) || 0) *
          (1 + (Number(interventionFeed.kgPerAnimal) || 0) / 100)
        );
      }
      // intervention for feed is not set
      return Number(baselineFeed.kgPerAnimal) || 0;
    };

    let totalIntake = 0;

    const currFormValues = fc.getValues() as SalmonIntervention;

    // take all addditional fields, and calculate sum
    const compoundFeedsAdd =
      currFormValues.stages?.[stageIndex]?.stageData?.feed
        ?.compoundFeedsAdditions;

    if (compoundFeedsAdd) {
      totalIntake += compoundFeedsAdd.reduce(
        (acc: number, item: BaselineFeed) =>
          acc +
          (item.feedType && !Number.isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0),
        0
      );
    }

    const baselineCompoundFeeds =
      baseline?.stages?.[stageIndex]?.stageData?.feed?.compoundFeeds;
    if (baselineCompoundFeeds) {
      const compoundFeedsForm =
        currFormValues.stages?.[stageIndex]?.stageData?.feed?.compoundFeeds;
      totalIntake += baselineCompoundFeeds.reduce(
        (acc: number, item: BaselineFeed) =>
          acc + calculateFeedValue(item, compoundFeedsForm),
        0
      );
    }

    fc.setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntake.toFixed(2));
    if (formVisible)
      fc.trigger(`${fieldItemPrefix}.totalFeedIntake`)
        .then(() => {})
        .catch(() => {});
  }, [fc, stageIndex, fieldItemPrefix, formVisible, baseline]);

  useEffect(() => {
    // we need to refresh when previously saved is returned by API call, but only once
    const feed = intervention?.stages?.[stageIndex]?.stageData?.feed;

    if (existingFeedsSet.current === false) {
      if (feed?.compoundFeedsAdditions?.some((item) => item.feedType !== '')) {
        existingFeedsSet.current = true;
      }

      if (
        feed?.antibioticsUseAddition?.some((item) => item.type !== '')
      ) {
        setAntibioticsUseAddition(feed.antibioticsUseAddition);
        existingAdditionsSet.current = true;
      }

      recalculateTotalFeedIntake();
    }
  }, [intervention, stageIndex, baseline, recalculateTotalFeedIntake]);

  const feed = baseline?.stages?.[stageIndex]?.stageData?.feed;

  const getDatabaseOptions = () =>
    Object.keys(CFDatabaseFoundation).map(
      (key) =>
        CFDatabaseFoundation[key as keyof typeof CFDatabaseFoundation] !==
          CFDatabaseFoundation.AFP_5 && {
          value: key,
          text: CFDatabaseFoundation[key as keyof typeof CFDatabaseFoundation],
        }
    );

  const getBaselineFeedLabel = (id: string) => {
    const feedDisplayName = compoundFeeds?.find(
      (item) => item.value === id
    )?.display_name;
    if (!feedDisplayName) {
      console.log('not found CF for id: ', id);
    }
    return feedDisplayName || 'unknown';
  };

  useEffect(() => {
    if (formVisible) {
      if (!fc.getValues(fieldItemPrefix)) {
        currResetValue.current = {totalFeedIntake: 0};
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleCancel}
      iconCode="shapes/cube-02"
      formTitle={formTitle}
      variant="wide"
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmSelect
          name="databaseFoundation"
          style={{ marginBottom: '9px' }}
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.DATABASE_SELECTION',
          })}`}
          disabled
          defaultValue={baseline?.info?.databaseFoundation}
          options={getDatabaseOptions() as []}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP',
          })}
        />
      </DsmGrid>
      <DsmGrid
        className={classes.dsmGridOneColumnIntervention}
        style={{ width: '70%', marginBottom: 'var(--dsm-spacing-px-6)' }}
      >
        {(feed?.compoundFeeds || [])
          ?.filter((item) => !!item.feedType)
          ?.map((feedItem, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment
              key={`InterventionCompoundFeed_${feedItem.feedType}_${i}`}
            >
              <input
                type="hidden"
                value={feedItem.feedType}
                ref={fc.register()}
                name={`${fieldItemPrefix}.compoundFeeds[${i}].feedType`}
              />

              <RowControlledTextFieldWithMetrics
                name={`${fieldItemPrefix}.compoundFeeds[${i}].kgPerAnimal`}
                label={getBaselineFeedLabel(feedItem.feedType)}
                tooltip={intl.formatMessage({
                  id: 'INTERVENTION.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
                })}
                metricUnit={barnOutputMassUnit}
                precision={precision}
                baseline={feedItem.kgPerAnimal}
                disabled={formType === 'view'}
                minAllowedValue={0}
              />
            </React.Fragment>
          ))}

        <SustellRationFeeds
          key="compoundFeedsAdditions"
          label={intl.formatMessage({ id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED' })}
          availableFeedItems={getCompoundFeedsList(baseline?.info?.databaseFoundation)}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="compoundFeedsAdditions"
          // updateTotalFeedIntake={updateTotalFeedIntake}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
          })}
          formState={formType}
          compoundFeed
          feedData={feedsAdditions}
          setFeedData={(newItem: any) => {
            console.log('setFeedData', newItem);
          }}
          fieldsAppenRemoveObject={{
            fields: feedsAdditions,
            append: appendFeedsAdditions,
            remove: removeFeedsAdditions,
          }}
        />
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.totalFeedIntake`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE',
            })}`}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE.TOOLTIP',
            })}
            adornment={barnOutputMassUnit}
            disabled
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.crudeProtein`}
            name={`${fieldItemPrefix}.nutrientComposition.crudeProtein`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_PROTEIN',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_PROTEIN.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.crudeProtein}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.crudeLipid`}
            name={`${fieldItemPrefix}.nutrientComposition.crudeLipid`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_LIPID',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_LIPID.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.crudeLipid}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.crudeFibre`}
            name={`${fieldItemPrefix}.nutrientComposition.crudeFibre`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_FIBRE',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_FIBRE.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.crudeFibre}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.starch`}
            name={`${fieldItemPrefix}.nutrientComposition.starch`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.STARCH',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.STARCH.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.starch}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.sugar`}
            name={`${fieldItemPrefix}.nutrientComposition.sugar`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SUGAR',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SUGAR.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.sugar}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.phosphorous`}
            name={`${fieldItemPrefix}.nutrientComposition.phosphorous`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.PHOSPHOROUS',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.PHOSPHOROUS.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.phosphorous}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.dryMatter`}
            name={`${fieldItemPrefix}.nutrientComposition.dryMatter`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DRY_MATTER',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DRY_MATTER.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.dryMatter}
            disabled={formType === 'view'}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridOneColumnIntervention}>
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.feedSpill`}
            name={`${fieldItemPrefix}.nutrientComposition.feedSpill`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.FEED_SPILL',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.FEED_SPILL.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.feedSpill}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.solubleNitrogen`}
            name={`${fieldItemPrefix}.nutrientComposition.solubleNitrogen`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_NITROGEN',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_NITROGEN.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.solubleNitrogen}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.solublePhosphorous`}
            name={`${fieldItemPrefix}.nutrientComposition.solublePhosphorous`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_PHOSPHORUS',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_PHOSPHORUS.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.solublePhosphorous}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.solubleCarbon`}
            name={`${fieldItemPrefix}.nutrientComposition.solubleCarbon`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_CARBON',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_CARBON.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.solubleCarbon}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`}
            name={`${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_NITROGEN',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_NITROGEN.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.digestibleNitrogen}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.digestibleCarbon`}
            name={`${fieldItemPrefix}.nutrientComposition.digestibleCarbon`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_CARBON',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_CARBON.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.digestibleCarbon}
            disabled={formType === 'view'}
          />
          <RowTextFieldWithMetrics
            key={`${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`}
            name={`${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_PHOSPHORUS',
            })}
            type="number"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_PHOSPHORUS.TOOLTIP',
            })}
            metricUnit=""
            baseline={feed?.nutrientComposition?.digestiblePhosphorous}
            disabled={formType === 'view'}
          />
        </DsmGrid>
      </DsmGrid>
      <br/>
      {feed?.antibioticsUse?.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <>
          <DsmGrid
            className={classes.dsmGridOneColumnIntervention}
            key={`${fieldItemPrefix}_ab_${i}`}
            style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}
          >
            <input
              type="hidden"
              value={item.type}
              ref={fc.register()}
              name={`${fieldItemPrefix}.antibioticsUse[${i}].type`}
            />
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.antibioticsUse[${i}].amount`}
              label={
                avialableAntibiotics.find((el) => el.value === item.type)
                  ?.display_name || ''
              }
              type="number"
              tooltip={intl.formatMessage({
                id: 'INTERVENTION.FORM.SALMON.ANTIBIOTICS_USE.TOOLTIP',
              })}
              // addornment="%"
              metricUnit={barnOutputMassUnit}
              precision={precision}
              // defaultValue={item.amount}
              baseline={item.amount}
              disabled={formType === 'view'}
            />
          </DsmGrid>
          
        </>
      ))}

      <SustellDynamicInput
        key={`${fieldItemPrefix}.antibioticsUseAddition`}
        label={intl.formatMessage({
          id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE',
        })}
        availableOptions={avialableAntibioticsFiltered}
        fieldItemPrefix={fieldItemPrefix}
        subFieldName="antibioticsUseAddition"
        tooltip={intl.formatMessage({
          id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE.TOOLTIP',
        })}
        formState={formType}
        data={antibioticsUseAddition}
        setData={setAntibioticsUseAddition}
        disableAddButtonWhenAllOptionSelected
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default MarineFishInterventionFeedsFormDialog;
