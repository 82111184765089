import { API } from "aws-amplify";
import { CompoundFeedDatabase, Match } from "../../../../graphql/types";
import { getCompoundFeedIngredientOrProxy } from "../../../../graphql/queries";
import { CUSTOM_INGREDIENT_ID_SEPARATOR } from "./utils";
import { getDatabase } from "../../utils/compound-feed-utils";
import { CFDatabaseFoundation } from "../../models/CompoundFeed";

// eslint-disable-next-line import/prefer-default-export
export const getOriginOrProxyV2Ingredients = async (
  requestedIngredients: 
  {
    name: string,
    origin: string
  } [],
  customerId: string,
  databaseFoundation: CompoundFeedDatabase
): Promise<Match[] | undefined> => {
  const vars = {
    customerId,
    requestedIngredients,
    databaseFoundation,
  };

  try {
    const res = await (API.graphql({
      query: getCompoundFeedIngredientOrProxy,
      variables: vars,
    }) as Promise<{
      data?: { getCompoundFeedIngredientOrProxy?: Match[] };
    }>);

    // console.log('res', res);
    if (res.data?.getCompoundFeedIngredientOrProxy
       && res.data?.getCompoundFeedIngredientOrProxy.length !== requestedIngredients.length) {
        console.log('result is different size than requestingredients')
    }
    return res.data?.getCompoundFeedIngredientOrProxy;
  } catch (err) {
    console.log('err loading proxy', err);
  }
  return undefined;
};

export const getOriginOrProxyV2 = async (
  name: string,
  origin: string,
  customerId: string,
  databaseFoundation: CFDatabaseFoundation | undefined
): Promise<Match | undefined> => {
  if (name.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1 || !databaseFoundation) {
    return;
  }
  const requestedIngredients = [
    {
      name,
      origin,
    },
  ];
  const vars = {
    customerId,
    requestedIngredients,
    databaseFoundation: getDatabase(databaseFoundation),
  };
  try {
    const res = await (API.graphql({
      query: getCompoundFeedIngredientOrProxy,
      variables: vars,
    }) as Promise<{
      data?: { getCompoundFeedIngredientOrProxy?: Match[] };
    }>);
    // console.log('res', res);
    if (res.data?.getCompoundFeedIngredientOrProxy?.length === 1) {
      // eslint-disable-next-line consistent-return
      return res.data?.getCompoundFeedIngredientOrProxy[0];
    }
  } catch (err) {
    console.log('err loading proxy', err);
  }
  return undefined;
};