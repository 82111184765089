import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { InfoOutlined, ErrorOutlineOutlined } from '@material-ui/icons';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { DSM_MID_BLUE } from '../../../../_metronic/layout';

export enum NotificationDialogType {
  INFO = 'info',
  ERROR = 'error',
  HELP_CENTER = 'help_center',
}

type InfoDialogProps = {
  open: boolean;
  message: string | null;
  type: NotificationDialogType;
  closeNotificationDialog: () => void;
};

export const NotificationDialog = ({
  open,
  message,
  type = NotificationDialogType.INFO,
  closeNotificationDialog,
}: InfoDialogProps) => {
  const intl = useIntl();

  let color = type === NotificationDialogType.INFO ? 'primary' : 'error';

  let titleKey =
    type === NotificationDialogType.INFO ? 'GENERAL.INFO' : 'GENERAL.ERROR';

  if (type === NotificationDialogType.HELP_CENTER) {
    color = DSM_MID_BLUE;
    titleKey = 'SUSTELL.HELP_CENTER';
  }

  return (
    <Dialog open={open} aria-labelledby="alert-dialog">
      <DialogTitle id="alert-dialog-title">
        {type === NotificationDialogType.INFO && (
          <InfoOutlined fontSize="large" />
        )}
        {type === NotificationDialogType.HELP_CENTER && (
          <InfoOutlined fontSize="large" style={{ color }} />
        )}
        {type === NotificationDialogType.ERROR && (
          <ErrorOutlineOutlined fontSize="large" color="error" />
        )}
        <Typography
          // color={color}
          variant="inherit"
          style={{ marginLeft: '5px', color }}
        >
          {intl.formatMessage({ id: titleKey })}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ minWidth: '400px' }}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => closeNotificationDialog()}
          color="default"
        >
          {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
