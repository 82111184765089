import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const iconMap = {
  default: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Clock-fast-forward.svg'
  ),
  info: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Info_Icon_White.svg'
  ),
  LAYING_HENS: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Chicken_Layinghen_White.svg'
  ),
  LAYING_HENS_INPUT: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Chicken_icon.svg'
  ),
  PIG_FATTENING: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Pig_output_White.svg'
  ),
  PIG_FATTENING_INPUT: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Pig_input_White.svg'
  ),
  DAIRY: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Dairy_Beef_output_White.svg'
  ),
  DAIRY_INPUT: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Dairy_Beef_input_White.svg'
  ),
  SALMON: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Salmon_output_White.svg'
  ),
  SALMON_INPUT: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Salmon_input_White.svg'
  ),
  BROILER_INPUT: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Chicken_icon.svg'
  ),
  BROILER: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Chicken_Broiler_White.svg'
  ),
  chickenIcon: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Chicken_icon.svg'
  ),
  feedIcon: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Ration_icon.svg'
  ),
  manureIcon: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Manure_icon_White.svg'
  ),
  emissionIcon: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Emissions_Icon_White.svg'
  ),
  resourceIcon: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Resources_Icon_White.svg'
  ),
  air: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Air_icon.svg'
  ),
  water: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Water_icon.svg'
  ),
  land: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Land_icon.svg'
  ),
  human: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Human_icon.svg'
  ),
  forwardArrow: toAbsoluteUrl(
    '/media/sustell_15/icons/production_process_icons/Forward_arrow_icon.svg'
  ),
  newItemIcon: toAbsoluteUrl('/media/sustell_15/icons/create_new_item.svg'),
  // TODO: check for dashboard icons if already exist and add what's missing v
  dashboardAirIcon: toAbsoluteUrl(
    '/media/sustell_15/dashboard/climate-change-wind-02.svg'
  ),
  dashboardLandIcon: toAbsoluteUrl(
    '/media/sustell_15/dashboard/land-use-globe-02.svg'
  ),
  dashboardWaterIcon: toAbsoluteUrl(
    '/media/sustell_15/dashboard/water-waves.svg'
  ),
  dashboardHumanIcon: toAbsoluteUrl('/media/sustell_15/dashboard/human.svg'),
  proxyIngredientIcon: toAbsoluteUrl(
    '/media/sustell_15/icons/ProxyIngredient.svg'
  ),
};

// creates colorable icon from any given SVG image.
// uses image itself to set the size of container, but image is hidden, and the same image
// sized as "contain" is then used to mask the colored background
// color - color of the icon - default is white
// iconCode - must correspond to key from above map
// width, height - dimensions of the icon, if not supplied the original svg size will be used

export const IconProvider = ({
  color = 'white',
  iconCode = 'default',
  alt = 'icon',
  width = '',
  height = '',
  isTransform = false,
  cursor = 'default',
  rightVal='',
}) => {
  const iconUrl = iconMap[iconCode] || '';
  const transform = isTransform ? 'rotate(180deg)' : '';
  const position = isTransform ? 'absolute' : '';
  const right = rightVal ? rightVal : isTransform ? '195px' : '';
  const mask = `url(${iconUrl}) no-repeat center center / contain`;

  return (
    <React.Fragment>
      <div
        style={{
          display: 'inline-block',
          backgroundColor: color,
          backgroundSize: 'contain',
          WebkitMask: mask,
          // WebkitMaskSize: 'contain',
          mask: mask,
          // maskSize: 'contain',
          transform: transform,
          position: position,
          right: right,
          cursor: cursor,
        }}
      >
        <img
          src={iconUrl}
          alt={alt}
          width={width}
          height={height}
          style={{ visibility: 'hidden' }}
        />
      </div>
    </React.Fragment>
  );
};
