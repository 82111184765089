import { AnimalType } from "../../../../graphql/types";
import { iff } from "../../components/CompoundFeedWizard/utils";
import { FootprintCategoryName } from "../Footprint/internal";
import { DashboardFootprintModel } from "./internal";
import {
  DashboardFilterData,
  DashboardFilters,
  DashboardImpactCategory,
  DashboardType,
  AnalysisGroupsFarm,
  initialDashboardImpactCateg,
  Simulation,
  SortOption,
  SortOptions,
  AnalysisGroupsFeed,
  DateRange,
  SelectBaselinesInterventions,
  DashboardImpactType,
} from './DashboardTypes';
import { getDateDeltaYears } from "../../utils/datetime-utils";


interface DashboardModelProps {
  dashboardFootprints: DashboardFootprintModel[];
  type: DashboardType;
  customerId: string;
  customerName: string;
  dateRange: DateRange;
}
/**
 * Wrapper class for dashboard (footprints) and controls like
 * pagination, sorting, filtering...
 */
class DashboardModel {

  /**
   * Farm/Feed dashboard
   */
  readonly type: DashboardType;

  /**
   * Unfiltered list of all footprints that appear on dashboard
   */
  readonly #dashboardFootprints: DashboardFootprintModel[] = [];

  readonly customerId: string;

  readonly customerName: string;

  static readonly ItemsPerPage = 20;


  /**
   * If comparison is set, return only baseline + comparison footprint (or only baseline)
   */
  #footprintCompare: DashboardFootprintModel | undefined;

  #currentPage = 1;

  #totalOutputInKg = 0;

  /**
   * Count of items on applied filters
   */
  #totalItems;

  #filters: DashboardFilters | undefined;

  #sortOptions: SortOptions = {} ;

  #simulationOptions: Simulation = { 
    references: [],
    simulations: []
  };

  #targets: Map<FootprintCategoryName, number> = new Map();

  #dashboardImpactCategory: DashboardImpactCategory = {
    ...initialDashboardImpactCateg
  };

  #dateRange: DateRange | undefined;

  #selectedBaselinesInterventions: SelectBaselinesInterventions[] | undefined;
  
  #impactType: DashboardImpactType = DashboardImpactType.PerUnit;

  constructor(options: DashboardModelProps) {
    this.#dashboardFootprints = options.dashboardFootprints;
    this.#dashboardFootprints.forEach(f => f.setDashboard(this));
    this.#totalItems = options.dashboardFootprints.length;
    this.customerId = options.customerId;
    this.type = options.type;
    this.customerName = options.customerName;
    this.#dateRange = options.dateRange;
    this.#totalOutputInKg = this.filteredSortedFootprints.reduce(
      (acc: number, item: DashboardFootprintModel) =>
        acc + (item.totalOutputInKg ? item.totalOutputInKg : 0),
      0
    );
  }

  /**
   * Returns all options for filtering
   */
  get allDistinctFilterData (): DashboardFilterData {
    const retVal: DashboardFilterData = {
      farms: [],
      countries: [],
      animals: [],
      databaseFoundation: []
    }
    this.unfilteredFootprints.forEach(f => {
      const {animals, farms, countries} = f.filterData;
      
      animals.forEach(item => {
        if (retVal.animals.findIndex(el => el.id === item.id) === -1) {
          retVal.animals.push(item);
        }
      });
      
      farms.forEach(item => {
        if (retVal.farms.findIndex(el => el.id === item.id) === -1) {
          retVal.farms.push(item);
        }
      });
      
      countries.forEach(item => {
        if (retVal.countries.findIndex(el => el.id === item.id) === -1) {
          retVal.countries.push(item);
        }
      });
    })
    return retVal;
  }

  get dateRange (): DateRange {
    if (!this.#dateRange) {
      const dateRange: DateRange = {
        startDate: new Date(),
        endDate: undefined,
        key: undefined
      };
      return dateRange;
    }
    return this.#dateRange;
  }

  setSelectedBaselinesInterventions(selected: SelectBaselinesInterventions[] | undefined) {
    this.#selectedBaselinesInterventions = selected;
  }

  get selectedBaselinesInterventions(): SelectBaselinesInterventions[] | undefined {
    if (this.type === DashboardType.Feeds) {
      return undefined;
    }
    return this.#selectedBaselinesInterventions;
  }

  /**
   * return total value by selected impact category
   * with applied simulations and filters on
   */
  get totalValue (): number {
    const total: number = this.filteredSortedFootprints.reduce(
      (acc: number, item: DashboardFootprintModel) =>
        acc + item.distributionByAnalysisGroups.Total,
      0
    );
    return total;
  }

  get totalAvgInKg(): number {
    return this.#totalOutputInKg / this.#totalItems;
  }

  get totalAvgValue (): number {
    return this.totalValue / this.#totalItems;
  }

  addTarget(categoryKey: FootprintCategoryName, value: number) {
    this.#targets.set(categoryKey, value);
  }

  clearTargetForCategory(categoryKey: FootprintCategoryName) {
    this.#targets.delete(categoryKey);
  }

  get targetByActiveCategory(): number | undefined  {
    return this.#targets.get(this.activeImpactCategory.key);
  }

  getTargetByCategory(categoryKey: FootprintCategoryName): number | undefined {
    return this.#targets.get(categoryKey);
  }

  setActiveImpactCategory(dashboardImpactCateg: DashboardImpactCategory) {
    this.#dashboardImpactCategory = dashboardImpactCateg;
  }

  getUnitForImpactCategory(key: FootprintCategoryName): string | undefined {
    if (!this.#dashboardFootprints || this.#dashboardFootprints.length === 0) {
      return undefined;
    }
    return this.dashboardFootprints.at(0)?.categories.find(categ => categ.key === key)?.unit;
  }

  get activeImpactCategory() {
    return this.#dashboardImpactCategory;
  }

  get unfilteredFootprints() {
    return this.#dashboardFootprints;
  }

  setSimulation(sim: Simulation) {
    this.#simulationOptions = sim;
  }

  /**
   * Set only siumulations that are available for current dashboard type
   * @param simulationOptions simualtion options from draft
   * @param draftDashboardType dashboard type from draft
   */
  restoreSimulations(simulationOptions: Simulation, draftDashboardType: DashboardType): void {
    const availableSimulations = JSON.parse(JSON.stringify(simulationOptions)) as Simulation;
    if (this.type === draftDashboardType) {
      availableSimulations.references = [];
      availableSimulations.simulations = [];
    } else   {
      simulationOptions.references.forEach((ref, index) => {
        if (this.unfilteredFootprints.findIndex(f => f.reference === ref) !== -1) {
          console.log('removing simulation for ref', ref );
          availableSimulations.references.splice(index, 1);
        }
      });
      availableSimulations.simulations.forEach((simItem) => {
        if (
          (this.type === DashboardType.Feeds && 
          (
            simItem.analysisGroup === AnalysisGroupsFarm.Farm ||
            simItem.analysisGroup === AnalysisGroupsFarm.PurchasedAnimals ||
            simItem.analysisGroup === AnalysisGroupsFarm.Ration
          )) ||
          (this.type === DashboardType.Farm && 
          (
            simItem.analysisGroup === AnalysisGroupsFeed.Ingredients ||
            simItem.analysisGroup === AnalysisGroupsFeed.Transport
          ))
        ) {
          // eslint-disable-next-line no-param-reassign
          simItem.change = 0;
        }
      });
    }

    this.#simulationOptions = availableSimulations;
  }

  clearSimulation() {
    this.#simulationOptions = {
      references: [],
      simulations: []
    };
  }

  get hasSimulations() {
    return (this.#simulationOptions.simulations.length > 0);
  }

  adjustTarget(type: DashboardImpactType): void {
    if (this.#impactType !== type && type === DashboardImpactType.PerUnit) {
      this.#targets.forEach((value, key) => {
        this.#targets.set(key, value / (this.#totalOutputInKg / this.#totalItems));
      });
    } else if (this.#impactType !== type && type === DashboardImpactType.Absolute) {
      this.#targets.forEach((value, key) => {
        this.#targets.set(key, value * (this.#totalOutputInKg / this.#totalItems));
      });
    }
  }

  setImpactType(type: DashboardImpactType) {
    if (!(type === DashboardImpactType.Absolute && this.type === DashboardType.Feeds)) {
      this.adjustTarget(type);
      this.#impactType = type;
    }
  }

  get impactType() {
    return this.#impactType;
  }

  get totalPages() {
    return Math.ceil(this.#totalItems / DashboardModel.ItemsPerPage);
  }

  get simulation() {
    return this.#simulationOptions;
  }

  setFilters(filters: DashboardFilters) {
    this.#filters = filters;
  }

  /**
   * Set only filters that are available for current dashboard
   * to avoid showing filters that are not applicable
   * called when restpring draft options
   * @param filters 
   */
  restoreAvailableFilters(filters: DashboardFilters) {
    const availableFilters = {...filters};
    if (this.type === DashboardType.Feeds) {
      delete availableFilters.farms;
    } else {
      delete availableFilters.databaseFoundation;
    }

    filters?.animals?.forEach((animal, index) => {
      if (this.allDistinctFilterData?.animals.findIndex(a => a.id === animal) === -1) {
        availableFilters.animals?.splice(index, 1);
      }
    });

    filters?.countries?.forEach((country, index) => {
      if (this.allDistinctFilterData?.countries.findIndex(c => c.id === country) === -1) {
        availableFilters.countries?.splice(index, 1);
      }
    });

    if (this.type === DashboardType.Farm) {
      filters?.farms?.forEach((farm, index) => {  
        if (this.allDistinctFilterData?.farms.findIndex(f => f.id === farm) === -1) {
          availableFilters.farms?.splice(index, 1);
        } 
      });
    }

    this.#filters = availableFilters;
  }

  get filters() {
    return this.#filters;
  }

  clearFilters() {
    this.#filters = undefined;
  }

  setSortOption(option: SortOption, field: 'Name' | 'Total' | AnalysisGroupsFarm | AnalysisGroupsFeed) {
    this.clearSortOptions();
    this.#sortOptions[field] = option;
  }

  setSortOptions(options: SortOptions) {
    this.#sortOptions = options;
  }

  clearSortOptions() {
    this.#sortOptions = {};
  }

  get totalItems() {
    return this.#totalItems;
  }

  setCurrenPage(pageNo: number) {
    this.#currentPage = pageNo;
  }

  get currentPage() {
    return this.#currentPage;
  }

  get sortOptions() {
    return this.#sortOptions;
  }

  setBaselineFootprint(footprint: DashboardFootprintModel) {
    this.#footprintCompare = footprint;
  }

  setComparisonFootprint(footprint: DashboardFootprintModel | undefined) {
    if (!this.#footprintCompare) {
      console.log('no baseline footprint is set');
      return;
    }
    this.#footprintCompare.comparison = footprint;
  }

  clearCompare() {
    if (this.#footprintCompare?.comparison) {
      this.#footprintCompare.comparison = undefined;
    }
    this.#footprintCompare = undefined;
  }

  /**
   * @return filtered, sorted footprints
   */
  get filteredSortedFootprints() {
    let retVal =  this.#dashboardFootprints.filter(fo => {
      const {farms, countries, animals} = fo.filterData;

      return (
        ( (!this.#filters?.farms || this.#filters?.farms?.length === 0) 
          || 
          (this.#filters?.farms &&  farms.every(f => this.#filters?.farms?.includes(f.id || '')))
        )
          && 
        ( (!this.#filters?.animals || this.#filters?.animals?.length === 0)
          || 
          (this.#filters?.animals && animals.every(f => this.#filters?.animals?.includes(f.id as AnimalType)))
        )
          &&
        ( (!this.#filters?.countries || this.#filters?.countries?.length === 0)
          || 
          (this.filters?.countries && countries.every(f => this.#filters?.countries?.includes(f.id || '')))
        )
      );
    });

    // Total number of items (table and chart) is on filtered items
    this.#totalItems = retVal.length || 0;

    // Chain sort by all available options
    retVal = retVal.sort((f0,f1) => {
      let sort = 0;
      Object.keys(this.#sortOptions).forEach(sortOptKey => {
        const key = sortOptKey as keyof SortOptions;
        let comp0 = 0;
        let comp1 = 0;
        if (key === 'Name') {
          comp0 = f0.name.localeCompare(f1.name);
          comp1 = f1.name.localeCompare(f0.name);
        } else {
          comp0 = f0.distributionByAnalysisGroups[key] -  f1.distributionByAnalysisGroups[key];
          comp1 = f1.distributionByAnalysisGroups[key] -  f0.distributionByAnalysisGroups[key];
        }
        sort +=
         iff(!!this.#sortOptions[key],
          this.#sortOptions[key] === 'Asc' ?
            comp0 : comp1,
            0
          )
      })
      return sort;
    });
    return retVal;
  }

  /**
   * @return filtered, sorted footprints for current page
   */
  get filteredSortedFootprintsPaged() {
   
    // First apply filters for (Farms, Animal types, Countries)
    const retVal = this.filteredSortedFootprints;
    
    // Return chunk of fooprints for the current page
    const startIndex = (this.#currentPage - 1) * DashboardModel.ItemsPerPage;
    
    return retVal.slice(startIndex, startIndex + DashboardModel.ItemsPerPage);
  }

  get comparisonFootprints() {
    if (this.#footprintCompare) {
      this.#totalItems = this.#footprintCompare.comparison ? 2 : 1;
      return this.#footprintCompare.comparison ? 
        [this.#footprintCompare, this.#footprintCompare.comparison as DashboardFootprintModel] 
        :
        [this.#footprintCompare];
    }
    return [];
  }

  get dashboardFootprints() {
    if (this.comparisonFootprints.length > 0) {
      return this.comparisonFootprints;
    }
    return this.filteredSortedFootprintsPaged;
  }

  clearAllOptions () {
    this.clearSortOptions();
    this.clearCompare();
    this.clearSimulation();
    this.clearFilters();
    this.#dateRange = {
      startDate: getDateDeltaYears(new Date(), -10),
      endDate: getDateDeltaYears(new Date(), 10),
      key: 'selection'
    }
  }

  // Use this to indicate that dashboard already has some filters/simulations/compare options
  hasFilters (): boolean {
    return (
      (this.#filters?.countries?.length && this.#filters?.countries?.length > 0) ||
      (this.#filters?.animals?.length && this.#filters?.animals?.length > 0) ||
      (this.#filters?.databaseFoundation?.length && this.#filters?.databaseFoundation?.length > 0) ||
      (this.#filters?.farms?.length && this.#filters?.farms?.length > 0) ||
      this.#simulationOptions.references?.length > 0 ||
      this.#simulationOptions.simulations?.length > 0 || 
      !!this.#footprintCompare || 
      this.#dateRange?.startDate?.toDateString() !==  getDateDeltaYears(new Date(), -10).toDateString() ||
      this.#dateRange?.endDate?.toDateString() !==  getDateDeltaYears(new Date(), 10).toDateString()
     );
  }
}


export default DashboardModel;