import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnits as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import {
  setInterventionFloatValue,
  mapCommonInterventionDataOutSustell,
  mapResourceUse,
} from './outCommonMapperSustell';

const ANIMAL_TYPE = 'Pig';

const mapPigFatteningInterventionDataOutSustell = (formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const payloadObj = mapCommonInterventionDataOutSustell(formData);

  mapResourceUse(formData, payloadObj);

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if(payloadObj.stages[index]!==undefined){
        const formStageData = formDataForOneStage.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const {stageData} = stageGeneralData;
        stageData.animal_group.animal_type = ANIMAL_TYPE;

        // process input block
        // (purchasedPiglets, averageLiveWeight, mortalityRate, averageLiveWeightMortalities)
        const inputBlockData = formStageData.input;
        if(inputBlockData){
          stageData.purchased_piglets = {
            number_of_animals: {
              value: parseFloat(inputBlockData.purchasedPiglets),
              change_type: inputBlockData.purchasedPiglets_changeMetric,
            },
          };
          
          if (
            'averageLiveWeight' in inputBlockData &&
            !Number.isNaN(parseFloat(inputBlockData.averageLiveWeight))
          ) {
            setInterventionFloatValue(
              stageData.purchased_piglets,
              'average_live_weight',
              inputBlockData,
              'averageLiveWeight',
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass
            );
          }
    
          if (
            'mortalityRate' in inputBlockData &&
            !Number.isNaN(parseFloat(inputBlockData.mortalityRate))
          ) {
            stageData.mortality = {
              mortality_rate: {
                value: parseFloat(inputBlockData.mortalityRate),
                change_type: inputBlockData.mortalityRate_changeMetric,
              },
            };
          }
    
          if (
            'averageLiveWeightMortalities' in inputBlockData &&
            !Number.isNaN(parseFloat(inputBlockData.averageLiveWeightMortalities))
          ) {
            if (!stageData.mortality) stageData.mortality = {};
            setInterventionFloatValue(
              stageData.mortality,
              'average_live_weight_mortalities',
              inputBlockData,
              'averageLiveWeightMortalities',
              userUOM.unitBarnOutputMass,
              defaultMetric.unitBarnOutputMass
            );
          }
        }

        // process output block
        // (liveWeight, inorganicPFertilizerReplacementRate, inorganicNFertilizerReplacementRate )
        const outputBlockData = formStageData.output;
        if(outputBlockData){
          if (!Number.isNaN(parseFloat(outputBlockData.liveWeight))) {
            if (!stageData.fattened_pigs)
              stageData.fattened_pigs = {};
            setInterventionFloatValue(
              stageData.fattened_pigs,
              'total_weight',
              outputBlockData,
              'liveWeight',
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass
            );
          }
        
          if (!Number.isNaN(parseFloat(outputBlockData.inorganicPFertilizerReplacementRate))) {
            setInterventionFloatValue(
              stageData,
              'inorganic_p_fertilizer_replacement_rate',
              outputBlockData,
              'inorganicPFertilizerReplacementRate',
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass
            );
          }

          if (!Number.isNaN(parseFloat(outputBlockData.inorganicNFertilizerReplacementRate))) {
            setInterventionFloatValue(
              stageData,
              'inorganic_n_fertilizer_replacement_rate',
              outputBlockData,
              'inorganicNFertilizerReplacementRate',
              userUOM.unitOutputMass,
              defaultMetric.unitOutputMass
            );
          }
        }

        // set housing block of data
        stageData.housing_description = {
          housing_type: 'housed',
        };
        stageData.housing_system = formDataForOneStage.productionSystem;
      }
      
    });
    payloadObj.stages.forEach( item => {
      const {stageData} = item;
      item.stageData = JSON.stringify(stageData);
    })

  return payloadObj;
};

export default mapPigFatteningInterventionDataOutSustell;
