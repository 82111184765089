import { API } from "aws-amplify";

import { Box, Button, TextField, Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { FC, useState } from "react";

import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { footprintCategoryTargetStyles, impactBackgroundIconColors, impactColors } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import DashboardModel from "../../models/Dashboard/Dashboard";
import { translateKeyMapping } from "../../utils/string-utils";
import { DialogContainer } from "../FarmFlow/CommonDataParts/DialogContainer";
import { updateCustomerDashboardTargets } from "../../../../graphql/mutations";
import useAdobeDataLayer from "../../analytics/adobeDataLayer";
import { DashboardImpactType } from "../../models/Dashboard/DashboardTypes";

interface DashboardTargetDialogProps {
  dashboard: DashboardModel | undefined;
  renderDashboard: () => void;
  formVisible: boolean;
  handleClose: () => void;
}

const DashboardTargetDialog: FC<DashboardTargetDialogProps> = ({
  dashboard,
  renderDashboard,
  formVisible,
  handleClose,
}) => {
  const intl = useIntl();
  const classes = footprintCategoryTargetStyles();

  const currentTargetValue = dashboard?.targetByActiveCategory;
  const [target, setTarget] = useState(currentTargetValue);
  const [error, setError] = useState<string | null>(null);

  const { ctaClickEvent } = useAdobeDataLayer();

  const setTargetValue = (value: number | undefined) => {
    setTarget(value);
    setError(null);
  };

  const handleConfirm = (isCleaning = false) => {
    (async () => {
      if (!dashboard || (!target && !isCleaning)) {
        setError(intl.formatMessage({id: "SUSTELL.DASHBOARD.TARGETS_ERROR_MESSAGE"}));
        return;
      }
      let newTarget = target;
      if (dashboard.impactType === DashboardImpactType.Absolute && newTarget) {
        newTarget /= dashboard.totalAvgInKg;
      }
      const dashboardTarget = {
        dashboardType: dashboard.type,
        categoryKey: dashboard.activeImpactCategory.key,
        targetValue: isCleaning ? undefined : newTarget
      }
      ctaClickEvent(
        window.location.href,
        'button',
        isCleaning ? 'Clear current target' : 'Set target value',
        `${dashboard?.type} Dashboard`,
        `${dashboard?.type} Dashboard`
      );
      try {
        await API.graphql({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          query: updateCustomerDashboardTargets,
          variables: {
            input: {
              customerId: dashboard.customerId,
              dashboardTargets: JSON.stringify(dashboardTarget)
            },
          },
        });
        if (typeof target !== 'number' || target === 0 || isCleaning) {
          dashboard.clearTargetForCategory(dashboard.activeImpactCategory.key);
        } else {
          dashboard.addTarget(dashboard.activeImpactCategory.key, target);
        }
        renderDashboard();
        handleClose();
        // await footprintTargetSuccessHandler();
      } catch (err) {
        // console.log(err);
        let errorMessage = intl.formatMessage({id: "SUSTELL.DASHBOARD.TARGETS_ERROR_MESSAGE"});
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err && err?.errors && err?.errors?.length > 0 &&  err.errors[0]?.message) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          errorMessage = err.errors[0]?.message;
        }
        setError(errorMessage);
      }
    })().catch(() => setError(intl.formatMessage({id: "SUSTELL.DASHBOARD.TARGETS_ERROR_MESSAGE"})));
  };

  const handleClearTarget = () => {
    if (!dashboard) {
      return;
    }
    setTarget(undefined);
    handleConfirm(true);
  }

  if (!dashboard) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleClose}
        datasetType="footprint"
        width="512px"
        iconCode={dashboard.activeImpactCategory.compartmentType.toLowerCase()}
        iconColor={impactColors[dashboard.activeImpactCategory.compartmentType.toLowerCase() as keyof typeof impactColors]}
        iconWidth="33px"
        formTitle={intl.formatMessage({
          id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TITLE',
        })}
        introText={intl.formatMessage({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          id: `SUSTELL.FOOTPRINT.DESCRIPTION.${translateKeyMapping(dashboard.activeImpactCategory.key)}`,
          defaultMessage: dashboard.activeImpactCategory.key,
        })}
        introTextStyle={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
        }}
        dialogContentStyles={{
          marginTop: 1,
        }}
        iconBackgroundSize="65px"
        iconBackgroundColor={
          impactBackgroundIconColors[
            dashboard.activeImpactCategory.compartmentType.toLowerCase() as keyof typeof impactBackgroundIconColors
          ]
        }
        borderRadius="52px"
      >
        <Box className={classes.textBoxTitle}>
          {intl.formatMessage({
            id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_TITILE',
          })}
        </Box>
        <TextField
          id="target"
          name="target"
          label={intl.formatMessage({
            id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_LABEL',
          })}
          placeholder={intl.formatMessage({
            id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_LABEL',
          })}
          InputProps={{
            endAdornment: <Tooltip title={ intl.formatMessage({ 
              id: 'SUSTELL.DASHBOARD.TARGET.SET_A_TARGET_TOOLTIP',
            }) } placement="left"><HelpOutline /></Tooltip>,
          }}
          type="number"
          value={target || undefined}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetValue(!e.target.value ? undefined : Number(e.target.value));
          }}
          helperText={
            error || (!target
              ? intl.formatMessage({
                  id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.TEXT_FILED_LABEL_HELP',
                })
              : '')
          }
          error={!!error || false}
          margin="none"
          variant="outlined"
          fullWidth
        />
        <br />
        <br />
        <br />
        <Box className={classes.buttonsBox}>
          { !!currentTargetValue && (
            <Button
              variant="outlined"
              type="button"
              onClick={() => {handleClearTarget()}
            }
              className={classes.clearTargetButton}
            >
              {intl.formatMessage({
                id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.BUTTON_LABEL_CLEAR',
              })}
            </Button>
          )}
          <Button
            fullWidth={!currentTargetValue}
            variant="contained"
            type="button"
            onClick={() => handleConfirm()}
            className={classes.buttonStyles}
          >
            {intl.formatMessage({
              id: 'SUSTELL.FOOTPRINT.TARGET.POPUP.BUTTON_LABEL',
            })}
          </Button>
        </Box>
      </DialogContainer>
  )
}

export default DashboardTargetDialog;