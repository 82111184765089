import _, { capitalize } from "lodash";
import safeAdd from "../../../helpers/calcHelpers";
import { CalcType, EmissionEntry, emissionSourceLinksMap, ErrorEntry, PeriodType, UsedDataEntry,  UsedIntermediateEntry } from "../FootprintTypes";
import { EmissionsStageModel } from "../internal";

/**
 * Contain all calculated values needed for view
 */
class EmissionEntryModel {

  readonly unit?: string;
  
  readonly emission: string;
  
  readonly tierLevel?: string;

  parent: EmissionsStageModel;
  
  readonly isValid: boolean;
  
  value: number;
  
  readonly errors?: ErrorEntry[];
  
  used_data: UsedDataEntry[]

  used_intermediates: UsedIntermediateEntry[];
  
  source?: string;
  
  sourceLink?: string;
  
  constructor(emissionEntry: EmissionEntry, parent: EmissionsStageModel) {
    this.unit = emissionEntry.unit;
    this.emission = emissionEntry.emission;
    this.tierLevel = emissionEntry.tier_level;
    this.isValid = emissionEntry.is_valid;
    this.value = emissionEntry.value || 0;
    this.errors = emissionEntry.errors;
    this.used_intermediates = emissionEntry.used_intermediates;
    this.source = emissionEntry.source;
    this.used_data = emissionEntry.used_data;
    this.sourceLink = emissionSourceLinksMap.get(this.source);
    this.parent = parent;
  }

  clone(): EmissionEntryModel {
    return _.cloneDeep(this) as EmissionEntryModel;
  }

  /** Agregate values from another emission entry */
  appendEntryValues(emissionEntry: EmissionEntryModel) {
    this.value = safeAdd(this.value, emissionEntry.value) || 0;
    (emissionEntry?.used_data || []).forEach(item => {
      const existing: UsedDataEntry | undefined = this.used_data?.find(x => x.name === item.name);
      if (existing) {
        if (!existing.value) {
          existing.value = item.value;
        } else if (Number.isNaN(Number(existing.value)) || Number.isNaN(Number(item.value))) {
            existing.value = existing.value !== item.value ? existing.value += `, ${  item.value}` : existing.value;
          } else {
            existing.value =  (`${Number(existing.value) + Number(item.value)}`);
        }
      } 
    });

    (emissionEntry?.used_intermediates || []).forEach(item => {
      const existing: UsedIntermediateEntry | undefined = (this.used_intermediates || []).find(x => x.type === item.type);
      if (existing) {
        if (!existing.value) {
          existing.value = item.value;
        } else if (Number.isNaN(Number(existing.value)) || Number.isNaN(Number(item.value))) {
            existing.value = existing.value !== item.value ? existing.value += `, ${  item.value ? item.value : ''}` : existing.value;
          } else {
            existing.value =  (`${Number(existing.value) + Number(item.value)}`);
        }
      } 
    });
  }

  /** Direct comparison of this emission */
  get comparison(): EmissionEntryModel | undefined {
    const comparison =  (this.parent.getComparison()?.emissions || []).find(emission => emission.emission === this.emission);
    return comparison;
  }

  get usedData() {
    // Capitalize first letter for view
    this.used_data.forEach(item => {
      const mutatingItem = item;
      mutatingItem.displayName = capitalize(item.name);
    });

    if (this.comparison) {
      return this.used_data.map(item => {
        const retVal = item;
        const comparedEntry = this.comparison?.used_data.find(used => used.name === item.name);
        if (comparedEntry) {
          retVal.comparedValue = comparedEntry.value;
        }
        if (!Number.isNaN(Number(retVal.value)) && !Number.isNaN(Number(retVal.comparedValue))) {
          const change = (Number(retVal.comparedValue) - Number(retVal.value)) / Number(retVal.value) * 100;
          retVal.change = change;
        }
        return retVal;
      });
    }
    return this.used_data;
  }

  get usedIntermediates() {
    if (this.comparison) {
      return this.used_intermediates.map(item => {
        const retVal = item;
        const comparedEntry = this.comparison?.used_intermediates.find(used => used.type === item.type);
        if (comparedEntry) {
          retVal.comparedValue = comparedEntry.value;
        }
        if (!Number.isNaN(Number(retVal.value)) && !Number.isNaN(Number(retVal.comparedValue))) {
          const change = ( Number(retVal.comparedValue) - Number(retVal.value)) / Number(retVal.value) * 100;
          retVal.change = change;
        }
        return retVal;
      });
    }
    return this.used_intermediates;
  }

  /**
   * 
   * @param period selected period type (Round/Year)
   * @param calcType selected calculation type (Total animals/Per average animals present)
   * @returns value for emission based on input
   */
  private getValue(period: PeriodType, calcType: CalcType): number | undefined {
    let retVal: number | undefined = this.value;
    const { periodBaseline } = this.parent.parentContainer.footprint;
    const roundsPerYear = periodBaseline.number_of_rounds_per_year.value;

    if (period === PeriodType.Year && periodBaseline.unit === PeriodType.Year) {
      retVal = this.value;
    } else if (period === PeriodType.Year && periodBaseline.unit === PeriodType.Round) {
      retVal = (roundsPerYear) ? this.value * roundsPerYear : undefined;
    } else if (period === PeriodType.Round && periodBaseline.unit === PeriodType.Year) {
      retVal = (roundsPerYear) ? this.value / roundsPerYear : undefined;
    } 
    if (retVal && calcType === CalcType.PerAnimal) {
      retVal /= this.parent.numberOfAnimals;
    }
    return retVal;
  }

  /** Use this inside view to display everything needed for emission */
  public displayValues(period: PeriodType, calcType: CalcType) {

    const value = this.getValue(period, calcType);

    let intervenedValue;
    let change;
    if (this.comparison) {
      intervenedValue = this.comparison.getValue(period, calcType);
      change = (intervenedValue && value) ? (intervenedValue - value) / value * 100 : undefined;
    }
        
    return {
      value,
      intervenedValue,
      change
    };
  }

}


export default EmissionEntryModel;
