import React, { useMemo } from 'react';
import { Divider } from '@material-ui/core';
import { DSM_CYAN, useHtmlClassService } from '../../../../_metronic/layout';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import contactEmailParts from './utils';
import toAbsoluteUrl from '../../helpers/assetsHelpers';

const Footer = () => {
  const intl = useIntl();
  const uiService = useHtmlClassService();

  const footerClasses = useMemo(
    () => uiService.getClasses('footer', true) as string,
    [uiService]
  );

  const handleContactUsClick = () => {
    const obfuscatedEmail = `${contactEmailParts[0]}${String.fromCharCode(64)}${
      contactEmailParts[1]
    }${String.fromCharCode(46)}${contactEmailParts[2]}`;
    window.location.href = `mailto:${obfuscatedEmail}`;
  };

  return (
    <div
      className={`footer d-flex flex-lg-column align-items-center justify-content-end  ${footerClasses}`}
      id="kt_footer"
      style={{ height: `254px` }}
    >
      <Divider style={{ width: '100%' }} />
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between pb-5 pt-5">
        <div className="text-light d-flex align-items-center justify-content-start order-2 order-md-1">
          <a
            href="https://www.sustell.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logo-sticky w-250px"
              alt="Logo"
              src={toAbsoluteUrl(
                '/media/sustell_15/logos/DSM-Firmenich_Logo_Primary_Digital_RGB_Positive.png'
              )}
            />
          </a>
        </div>
        <div className="order-md-2">
          <button
            type="button"
            className="border-0 bg-transparent"
            onClick={handleContactUsClick}
          >
            <u style={{ color: DSM_CYAN as string }}>
              {intl.formatMessage({ id: 'SUSTELL.CONTACT_US' })}
            </u>
          </button>
        </div>
      </div>
      <div
        id="kt_footer"
        style={{
          width: '100%',
          height: 100,
          backgroundImage: `url(${toAbsoluteUrl(
            '/media/sustell_15/bkgs/subfooter.png'
          )})`,
          backgroundSize: 'cover',
          backgroundPosition: '0% 20%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className="container d-flex flex-column flex-md-row align-items-center justify-content-between"
          style={{ height: 100, color: 'white', fontSize: 13 }}
        >
          © {new Date().getFullYear()} dsm-firmenich. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
