import _, { capitalize } from 'lodash';
import { FootPrintType } from '../../../../graphql/types';
import { getCalculationErrors } from '../../helpers/errorsHelper';
import {
  BLONK_ERROR_CODE_PREFIX,
  ErrorInfoV2,
  ErrorInfoWithCodeV2,
  ErrorResponseItemV2,
  MessageTextCode,
  ValidationErrorCode,
  WarningCollection,
  WarningCollectionV2,
  WarningResponse,
} from './FootprintTypes';
import { EmissionsStageModel, FootprintModel } from './internal';

enum WarningErrorType {
  Emissions = 'Emissions',
  CalculationErrors = 'CalculationErrors',
  Warnings = 'Warnings',
}

class WarningErrorModel {
  #footprint: FootprintModel;

  #type: WarningErrorType;

  key: string;

  /** Value displayed in table */
  displayName: string;

  /** Indicator if display name should be translated (Footprint errors) */
  shouldTranslate = false;

  /** List of errors/warnings that should be shown */
  entries: MessageTextCode[] = [];

  /** indicator is proxy data is being used for the compound feed */
  proxyDataUsed = false;

  private static isV2Warning = (data: WarningResponse): boolean =>
    !!(
      data &&
      data.collections?.length &&
      (data.collections as WarningCollectionV2[])?.[0]?.type &&
      (data.collections as WarningCollectionV2[])?.[0]?.message
    );

  private static getV2DispalyName = (type: string): string => {
    switch (type) {
      case 'InputValidationWarning':
        return 'CALCULATION_WARNING_MESSAGE.INPUT_VLIDATION';
      default:
        return type;
    }
  };

  private static shouldTransalteV2DispalyName = (name: string): boolean =>
    name?.startsWith('CALCULATION_WARNING_MESSAGE');

  /** Init warnings/errors array from footprint warnings API response */
  public static fromFootprintWarnings(
    data: WarningResponse,
    footprint: FootprintModel
  ): WarningErrorModel[] {
    if (footprint.type === FootPrintType.CompoundFeed) {
      const item = new WarningErrorModel();
      item.#footprint = footprint;
      item.#type = WarningErrorType.Warnings;
      item.key = data.name;
      item.entries = data.warnings?.map((warning) => ({ text: warning })) || [];
      item.displayName = capitalize(data.name || '');
      item.proxyDataUsed = data.proxyDataUsed || false;
      return [item];
    }

    const retMap = new Map<string, WarningErrorModel>();

    if (this.isV2Warning(data)) {
      (data.collections as WarningCollectionV2[])?.forEach((item) => {
        const displayName = this.getV2DispalyName(item.type);
        const existing = retMap.get(displayName);
        if (!existing) {
          const warning = new WarningErrorModel();
          warning.#footprint = footprint;
          warning.#type = WarningErrorType.Warnings;
          warning.key = displayName;
          warning.displayName = displayName;
          warning.entries = [{ text: item.message }];
          warning.shouldTranslate =
            this.shouldTransalteV2DispalyName(displayName);
          retMap.set(displayName, warning);
        } else {
          existing.entries.push({ text: item.message });
        }
      });
    } else {
      (data.collections as WarningCollection[])
        ?.filter((item) => item.collection.length > 0)
        .forEach((coll: WarningCollection) => {
          coll.collection.forEach((item) => {
            const displayName = capitalize(`${coll.type} - ${item.name}`);

            const existing = retMap.get(displayName);
            if (!existing) {
              const warning = new WarningErrorModel();
              warning.#footprint = footprint;
              warning.#type = WarningErrorType.Warnings;
              warning.key = displayName;
              warning.displayName = displayName;
              warning.entries = item.warnings?.map((msg) => ({ text: msg }));
              retMap.set(displayName, warning);
            } else {
              existing.entries = existing.entries.concat(
                item.warnings?.map((msg) => ({ text: msg }))
              );
            }
          });
        });
    }

    retMap.forEach((value: WarningErrorModel) => {
      value.entries.sort((a, b) => a.text.localeCompare(b.text));
    });

    return Array.from(retMap.values()).sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }

  private static isV2Error = (errObj: object): boolean => {
    const errorType: string | undefined = _.get(
      errObj,
      'error[0].errorType'
    ) as string | undefined;
    const graphQLQuery: string | undefined = _.get(
      errObj,
      'error[0].path[0]'
    ) as string | undefined;
    // new error handling for v2, that includes error codes will contain the name of grappQL query
    if (
      graphQLQuery === 'getResultByProductionSystemInput' ||
      graphQLQuery === 'getResultBySeaCageProductionSystemInput'
    )
      return true;
    // if not graphQLQuery check for the error type
    if (errorType) {
      return errorType.includes('Pig') || errorType.includes('Fish') || errorType.includes('Poultry');
    }

    return false;
  };

  private static formatValue = (
    message: string | number | object | object[]
  ): string => {
    if (typeof message === 'string') return message;
    if (typeof message === 'number') return message.toString();
    // if not numebr or string, stringify it
    return JSON.stringify(message);
  };

  private static formatValues = (valueObject: {
    [key: string]: string | number | object | object[];
  }): { [key: string]: string } | undefined | null => {
    if (valueObject && Object.keys(valueObject)?.length) {
      const retVal = {} as { [key: string]: string };

      Object.keys(valueObject)?.forEach((key) => {
        retVal[key] = this.formatValue(valueObject[key]);
      });

      return retVal;
    }

    return undefined;
  };

  private static formatMessage = (
    errInfo: ErrorInfoWithCodeV2,
    errorCode: ValidationErrorCode
  ): string => {
    let { message } = errorCode;

    Object.keys(errInfo.messageValues)?.forEach((msgValKey) => {
      message = message.replace(
        new RegExp(`<${msgValKey}>`, 'g'),
        this.formatValue(
          errInfo.messageValues[msgValKey as keyof typeof errInfo.messageValues]
        )
      );
    });

    return message;
  };

  private static formatV2ErrorWithCode = (
    errInfo: ErrorInfoWithCodeV2,
    errorCodes?: ValidationErrorCode[] | null
  ): { key: string; message: MessageTextCode } => {
    let path = errInfo.location || [];
    if (path.length && path[0] === 'farms') path = path.slice(2);
    if (path.length && path[0] === 'seaCage') path = path.slice(1);

    const key: string[] = [];
    path?.forEach((item, index) => {
      // inc by 1 because it starts from 0
      if (typeof item === 'number') key.push(`${(item + 1).toString()},`);
      else if (typeof item !== 'number' && index !== path.length - 1)
        key.push(`${item.toString()},`);
      else key.push(item);
    });

    const codeMsg = errorCodes?.find(
      (item) => item.code === errInfo.messageCode
    );

    // default message from Blonk in casae that error code is missing
    let message = errInfo.externalMsg;

    if (codeMsg) message = this.formatMessage(errInfo, codeMsg);

    return {
      key: key.join(' '),
      message: {
        text: message || '',
        code: codeMsg ? `${BLONK_ERROR_CODE_PREFIX}_${codeMsg.code}` : null,
        values: this.formatValues(errInfo.messageValues),
      },
    };
  };

  private static formatV2Error = (
    errObjs: ErrorResponseItemV2[],
    errorCodes?: ValidationErrorCode[] | null
  ): { key: string; message: MessageTextCode }[] => {
    const retVal = [] as { key: string; message: MessageTextCode }[];

    errObjs?.forEach((errObj) => {
      // if start with farms, remove first two items, farms and 0
      errObj.errorInfo?.forEach((errItem) => {
        // if containing message code, the new error handling with message codes
        if (_.get(errItem, 'messageCode') as string | undefined) {
          const msg = this.formatV2ErrorWithCode(
            errItem as ErrorInfoWithCodeV2,
            errorCodes
          );
          retVal.push(msg);
        }
        // old style, no error codes, or new style with error code, but code is missing
        else {
          let path = (errItem as ErrorInfoV2).loc || [];
          if (path.length && path[0] === 'farms') path = path.slice(2);
          if (path.length && path[0] === 'seaCage') path = path.slice(1);

          const key: string[] = [];
          path?.forEach((item, index) => {
            // inc by 1 because it starts from 0
            if (typeof item === 'number') key.push(`${(item + 1).toString()},`);
            else if (typeof item !== 'number' && index !== path.length - 1)
              key.push(`${item.toString()},`);
            else key.push(item);
          });

          if (key?.length && (errItem as ErrorInfoV2).msg)
            retVal.push({
              key: key.join(' '),
              message: {
                text: (errItem as ErrorInfoV2).msg,
              },
            });
        }
      });

      if (errObj.errorInfo === null && errObj.message) {
        retVal.push({
          key: '',
          message: {
            text: errObj.message,
          },
        });
      }
    });
    return retVal;
  };

  /** Init from errors calc errors API response */
  public static fromFootprintErrors(
    data: string,
    footprint: FootprintModel
  ): WarningErrorModel[] {
    const errObj = JSON.parse(data) as object;
    if (this.isV2Error(errObj)) {
      const formatedErrors = this.formatV2Error(
        (_.get(errObj, 'error') || []) as ErrorResponseItemV2[],
        (_.get(errObj, 'codes') || []) as ValidationErrorCode[]
      );

      const returnMap = new Map<string, WarningErrorModel>();
      formatedErrors?.forEach((errorItem) => {
        const existing = returnMap.get(errorItem.key);
        if (!existing) {
          const item = new WarningErrorModel();
          item.#type = WarningErrorType.CalculationErrors;
          item.#footprint = footprint;
          item.displayName = errorItem.key;
          item.key = errorItem.key;
          item.shouldTranslate = false;
          item.entries = [errorItem.message];
          returnMap.set(item.key, item);
        } else {
          existing.entries.push(errorItem.message);
        }
      });

      returnMap.forEach((value: WarningErrorModel) => {
        value.entries.sort((a, b) => a.text.localeCompare(b.text));
      });

      return Array.from(returnMap.values()).sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      );
    }

    // Left for now as the response errors for Aqua is different structured, to avoid
    // problems with processing this kind of errors the old way
    const aquaErrorStatusCode = _.get(errObj, 'error[0].errorInfo.StatusCode');

    if (aquaErrorStatusCode && aquaErrorStatusCode === 403) {
      const item = new WarningErrorModel();
      item.#type = WarningErrorType.CalculationErrors;
      item.#footprint = footprint;
      item.displayName = 'CALCULATION_ERROR_MESSAGE.ERROR';
      item.key = 'error[0].errorInfo.StatusCode';
      item.shouldTranslate = true;
      item.entries = [
        { text: 'You do not have permission to perform this action' },
      ];
      return [item];
    }
    if (aquaErrorStatusCode) {
      const item = new WarningErrorModel();
      item.#type = WarningErrorType.CalculationErrors;
      item.#footprint = footprint;
      item.displayName = 'CALCULATION_ERROR_MESSAGE.ERROR';
      item.key = 'error[0].errorInfo.StatusCode';
      item.shouldTranslate = true;
      item.entries = [{ text: 'Error occured during calcuclation' }];
      return [item];
    }

    const transfObj: object = getCalculationErrors(
      errObj,
      undefined,
      undefined
    ) as unknown as Map<string, { typeMessage: string; value: string }>;

    const returnMap = new Map<string, WarningErrorModel>();
    Object.entries(transfObj).forEach(
      ([key, entry]: [string, { typeMessage: string; value: string }]) => {
        const existing = returnMap.get(key);
        if (!existing) {
          const item = new WarningErrorModel();
          item.#type = WarningErrorType.CalculationErrors;
          item.#footprint = footprint;
          item.displayName = entry.typeMessage;
          item.key = key;
          item.shouldTranslate = true;
          item.entries = [{ text: entry.value }];
          returnMap.set(key, item);
        } else {
          existing.entries.push({ text: entry.value });
        }
      }
    );
    returnMap.forEach((value: WarningErrorModel) => {
      value.entries.sort((a, b) => a.text.localeCompare(b.text));
    });

    return Array.from(returnMap.values()).sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }

  /** Init from emissions */
  public static fromEmissionsStages(
    data: EmissionsStageModel,
    footprint: FootprintModel
  ): WarningErrorModel[] {
    const returnMap = new Map<string, WarningErrorModel>();

    (data?.emissions || [])
      .filter((entry) => !entry.isValid)
      .forEach((entry) => {
        (entry.errors || []).forEach((err) => {
          const displayName = `${entry.emission} ${
            err.error_type ? err.error_type : ''
          }`;

          const existing = returnMap.get(displayName);
          if (!existing) {
            const item = new WarningErrorModel();
            item.#type = WarningErrorType.Emissions;
            item.#footprint = footprint;
            item.displayName = displayName;
            item.key = displayName;
            item.entries = [{ text: err.error_message }];
            returnMap.set(displayName, item);
          } else {
            existing.entries.push({ text: err.error_message });
          }
        });
      });
    returnMap.forEach((value: WarningErrorModel) => {
      value.entries.sort((a, b) => a.text.localeCompare(b.text));
    });

    return Array.from(returnMap.values()).sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
  }

  /** Direct comparison for this error */
  get comparison(): WarningErrorModel | undefined {
    if (!this.#footprint.comparison) {
      return undefined;
    }
    switch (this.#type) {
      case WarningErrorType.Warnings:
        return (this.#footprint.comparison.warnings || []).find(
          (item) => item.displayName === this.displayName
        );
      case WarningErrorType.CalculationErrors:
        return (this.#footprint.comparison.calculationErrors || []).find(
          (item) => item.displayName === this.displayName
        );
      case WarningErrorType.Emissions:
        return (
          this.#footprint.comparison.emissionContainer?.agregatedEmissions
            ?.errors || []
        ).find((item) => item.displayName === this.displayName);
      default:
        return undefined;
    }
  }
}

export default WarningErrorModel;
